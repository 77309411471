import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';

import { SpecialisationSearchableList } from '../../../components/inputs/searchable/SpecialisationSearchableList';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { IdNameModel } from '~/api/models/common/models/IdNameModel';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import {
  apiAddDoctorVerificationDocuments,
  apiUpdateDoctorSignature,
  apiUpdateDoctorMedicalRegistration,
  apiUpdateSpecialisationArea,
  apiGetDoctorSpecialisationArea,
  apiMarkAsAllied,
} from '~/api/services/medicalRegistration';
import { mb20, mt15, mt10, mb10, ml10, mr10, textRight } from '~/common/commonStyles';
import { ToggleSwitch } from '~/components/buttons/ToggleSwitch';
import { UploadDocumentComponent } from '~/components/buttons/UploadDocumentComponent';
import { Button } from '~/components/commonButton';
import {
  SmallNsRegularSecondaryBlack,
  SmallNsRegularBlack,
  FormErrorMessage,
  SmallNsSemiBoldBlack,
} from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { MandatoryAsterisks } from '~/components/inputs/MandatoryAsterisks';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { Dot } from '~/components/svgImages';
import { ImagePdfFormats, ImageTypes } from '~/constants/documentUploadsConstants';
import RegistrationLayout from '~/layouts/RegistrationLayout';
import { alphaNumericValidation, requiredValidation, yearValidation } from '~/services/validationConfig';
import { colors } from '~/utils/colors';
import { imageUri } from '~/utils/files/documentResultUtil';
import { isDocumentResult } from '~/utils/files/isDocumentResult';
import { labels } from '~/utils/labels';
import { registrationLabels } from '~/utils/labels/registrationLabels';

interface Form {
  isAlliedProfessional: boolean;
  registrationNo: string;
  warrantYear: string;
  areasOfSpecialisation?: IdNameModel<number>[];
  signatureImage: string;
  identificationFiles: (MediaModel | DocumentPickerAsset)[];
  certificateFiles: (MediaModel | DocumentPickerAsset)[];
  cvFiles: (MediaModel | DocumentPickerAsset)[];
}

const DoctorVerification: React.FC = () => {
  const { updateRegistrationStatus } = useRegistrationSteps();
  const [waiting, setWaiting] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm<Form>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      isAlliedProfessional: false,
      registrationNo: '',
      warrantYear: '',
      areasOfSpecialisation: undefined,
      signatureImage: '',
      identificationFiles: [],
      certificateFiles: [],
      cvFiles: [],
    },
  });

  useEffect(() => {
    apiGetDoctorSpecialisationArea()
      .then((res) => {
        setValue('areasOfSpecialisation', res.data.data);
      })
      .catch((e) => {
        ErrorAlert(e);
      });
  }, []);

  const updateSignature = (document: DocumentPickerAsset) => {
    return apiUpdateDoctorSignature(document)
      .then(() => {
        setValue('signatureImage', document.uri);
        trigger();
      })
      .catch((error) => {
        ErrorAlert(error.message);
      });
  };

  const setDoctorVerificationData = async (details: Form) => {
    try {
      setWaiting(true);

      await apiMarkAsAllied(details.isAlliedProfessional);

      if (details.registrationNo || details.warrantYear) {
        await apiUpdateDoctorMedicalRegistration({
          registration_number: details.registrationNo || undefined,
          warrant_year: +details.warrantYear,
        });
      }

      const valueIds = details.areasOfSpecialisation.map((value) => value.id);

      if (valueIds) {
        await apiUpdateSpecialisationArea({ specialisation_area_ids: valueIds });
      }

      const files = [...details.certificateFiles, ...details.cvFiles, ...details.identificationFiles].filter(
        isDocumentResult
      );

      for (const file of files) {
        await apiAddDoctorVerificationDocuments(file);
      }

      await updateRegistrationStatus();
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const isAlliedProfessional = watch('isAlliedProfessional');

  const button = (
    <Button funCallback={handleSubmit(setDoctorVerificationData)} label={labels.continue} disabled={waiting} />
  );

  return (
    <RegistrationLayout title={labels.doctorVerification} buttons={button} childrenMarginTop={0} hideSubtitle>
      <View style={[mb20, { width: '100%' }]}>
        <SmallNsRegularSecondaryBlack style={mt15}>
          Please complete the below form and upload the necessary documents in order to verify you as a healthcare
          professional.
        </SmallNsRegularSecondaryBlack>

        <SmallNsSemiBoldBlack style={mt15}>Are you a registered doctor or allied professional?</SmallNsSemiBoldBlack>
        <Controller
          name="isAlliedProfessional"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <View style={[mb10, mt10, { width: '100%' }]}>
              <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <SmallNsRegularBlack style={[mr10, textRight]}>Doctor</SmallNsRegularBlack>
                <View>
                  <ToggleSwitch
                    value={value}
                    setValue={onChange}
                    switchProps={{ trackColor: { false: colors.purple, true: colors.info }, color: colors.white }}
                  />
                </View>
                <SmallNsRegularBlack style={[ml10]}>Allied Professional</SmallNsRegularBlack>
              </View>
            </View>
          )}
        />

        <View style={mt15}>
          <SmallNsRegularBlack>
            As {isAlliedProfessional ? 'an Allied Professional' : 'a registered Doctor'} please provide copies of the
            below documents and input the necessary information.
          </SmallNsRegularBlack>
        </View>

        <View style={styles.requirement}>
          <View style={styles.dotRow}>
            <View style={styles.dotView}>
              <Dot width={6} height={6} color={colors.info} />
            </View>
            <SmallNsSemiBoldBlack>
              Identification document
              <MandatoryAsterisks isMandatory />
            </SmallNsSemiBoldBlack>
          </View>
          <SmallNsRegularBlack style={[styles.dotMargin, mb10]}>ID Card/Passport/Residency Permit</SmallNsRegularBlack>
          <Controller
            control={control}
            name="identificationFiles"
            render={({ field: { value, onChange }, formState: { isSubmitted }, fieldState: { error } }) => (
              <>
                <UploadDocumentComponent
                  allowedFormats={ImagePdfFormats}
                  uploadedFiles={value}
                  setUploadedFiles={onChange}
                  error={isSubmitted && !!error}
                  imageUri={value?.length ? imageUri(value[value.length - 1]) : undefined}
                />
                {isSubmitted && error?.message ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
              </>
            )}
            rules={{
              required: requiredValidation('Identification document'),
            }}
          />
        </View>

        <View style={styles.requirement}>
          <View style={[styles.dotRow, mb10]}>
            <View style={styles.dotView}>
              <Dot width={6} height={6} color={colors.info} />
            </View>
            <SmallNsSemiBoldBlack>
              {isAlliedProfessional
                ? 'Registration document from the appropriate Professional Council or equivalent repute if applicable (Not mandatory)'
                : 'Certificate of Registration with the Maltese medical Council/Associations'}
              <MandatoryAsterisks isMandatory={!isAlliedProfessional} />
            </SmallNsSemiBoldBlack>
          </View>
          <Controller
            control={control}
            name="certificateFiles"
            render={({ field: { value, onChange }, formState: { isSubmitted }, fieldState: { error } }) => (
              <>
                <UploadDocumentComponent
                  allowedFormats={ImagePdfFormats}
                  uploadedFiles={value}
                  setUploadedFiles={onChange}
                  error={isSubmitted && !!error}
                  imageUri={value?.length ? imageUri(value[value.length - 1]) : undefined}
                />
                {isSubmitted && error?.message ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
              </>
            )}
            rules={{
              required: isAlliedProfessional ? null : requiredValidation('Certificate of registration'),
            }}
          />
          <View style={[mb10, { width: '100%' }]}>
            <Controller
              name="registrationNo"
              control={control}
              render={({ field: { onChange, onBlur, value }, formState: { isSubmitted } }) => (
                <FloatingInput
                  label={isAlliedProfessional ? 'Certification number' : 'Medical Council registration number'}
                  showMandatory={!isAlliedProfessional}
                  value={value}
                  onChangeValue={onChange}
                  onBlur={onBlur}
                  error={isSubmitted && !!errors.registrationNo}
                  errorMessage={isSubmitted && errors.registrationNo?.message}
                />
              )}
              rules={{
                validate: { alphaNumericValidation },
                required: isAlliedProfessional ? null : requiredValidation('Medical Council registration number'),
              }}
            />
          </View>

          <View style={[{ width: '100%' }, mt10]}>
            <SmallNsRegularBlack>
              {isAlliedProfessional
                ? 'Please enter the year when you were certified. This will be used to calculate the years in the medical profession (Not mandatory).'
                : 'Please enter the year when your warrant was awarded. This will be used to calculate the years in the medical profession.'}
            </SmallNsRegularBlack>
            <Controller
              name="warrantYear"
              control={control}
              render={({ field: { onChange, onBlur, value }, formState: { isSubmitted } }) => (
                <FloatingInput
                  label={isAlliedProfessional ? 'Year of certification' : 'Year of warrant'}
                  showMandatory={!isAlliedProfessional}
                  value={value}
                  onChangeValue={onChange}
                  onBlur={onBlur}
                  error={isSubmitted && !!errors.warrantYear}
                  errorMessage={isSubmitted && errors.warrantYear?.message}
                  keyboardType="numeric"
                />
              )}
              rules={{
                validate: { yearValidation: yearValidation({ minChange: -100, maxChange: 1 }) },
                required: isAlliedProfessional ? null : requiredValidation(labels.yearOfWarrant),
              }}
            />
          </View>
        </View>

        <View style={styles.requirement}>
          <View style={styles.dotRow}>
            <View style={styles.dotView}>
              <Dot width={6} height={6} color={colors.info} />
            </View>
            <SmallNsSemiBoldBlack>Curriculum Vitae</SmallNsSemiBoldBlack>
            <MandatoryAsterisks isMandatory />
          </View>
          <Controller
            control={control}
            name="cvFiles"
            render={({ field: { value, onChange }, formState: { isSubmitted }, fieldState: { error } }) => (
              <>
                <UploadDocumentComponent
                  allowedFormats={ImagePdfFormats}
                  uploadedFiles={value}
                  setUploadedFiles={onChange}
                  error={isSubmitted && !!error}
                  hidePreview
                />
                {isSubmitted && error?.message ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
              </>
            )}
            rules={{
              required: requiredValidation('Curriculum Vitae'),
            }}
          />
        </View>

        <View style={styles.requirement}>
          <View style={styles.dotRow}>
            <View style={styles.dotView}>
              <Dot width={6} height={6} color={colors.info} />
            </View>
            <SmallNsSemiBoldBlack>{labels.uploadPNGFormatImage}</SmallNsSemiBoldBlack>
            <MandatoryAsterisks isMandatory />
          </View>

          <View style={[{ width: '100%' }, mt10]}>
            <Controller
              control={control}
              name="signatureImage"
              render={({ field: { value }, formState: { isSubmitted }, fieldState: { error } }) => (
                <>
                  <UploadDocumentComponent
                    uploadTitle="Upload signature"
                    imageUri={value}
                    uploadedFiles={[]}
                    allowedFormats={[ImageTypes.png]}
                    add={updateSignature}
                    error={isSubmitted && !!error}
                  />
                  {isSubmitted && error && error.message ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                </>
              )}
              rules={{
                required: requiredValidation('Signature image'),
              }}
            />
          </View>
        </View>

        <View style={[{ width: '100%' }, styles.requirement]}>
          <View style={styles.dotRow}>
            <View style={styles.dotView}>
              <Dot width={6} height={6} color={colors.info} />
            </View>
            <SmallNsSemiBoldBlack>
              {registrationLabels.specialisationLabel}
              <MandatoryAsterisks isMandatory />
            </SmallNsSemiBoldBlack>
          </View>
          <Controller
            name="areasOfSpecialisation"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <SpecialisationSearchableList
                values={value}
                setValues={onChange}
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
              />
            )}
            rules={{
              required: requiredValidation(labels.areaOfSpecialisation),
            }}
          />
        </View>
      </View>
    </RegistrationLayout>
  );
};

const styles = StyleSheet.create({
  dotView: {
    marginTop: 6,
    marginRight: 12,
  },
  dotRow: {
    flexDirection: 'row',
  },

  dotMargin: {
    marginLeft: 18,
  },

  requirement: {
    marginTop: 25,
  },
});

//make this component available to the app
export default DoctorVerification;
