import { get, post } from '../../axios';
import { SumsubApplicantDataResponse } from '../../models/kyi/responses/SumsubApplicantDataResponse';
import { SumsubTokenResponse } from '../../models/kyi/responses/SumsubTokenResponse';

export const apiSumSubAccessToken = () => {
  return post<SumsubTokenResponse>('/sumsub/token');
};

export const apiSumsubApplicantData = () => {
  return get<SumsubApplicantDataResponse>('/sumsub/applicant/data');
};
