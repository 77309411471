import React from 'react';
import { View, StyleSheet, ViewStyle, StyleProp, FlexAlignType } from 'react-native';

import { H2TtmSemiBoldBlack, H3TtmSemiBoldTheme } from '../components/commonText';
import { colors } from '../utils/colors';

import { mt10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';

interface Props {
  title: string;
  subtitle?: string;
  headerRight?: React.ReactNode;
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  noHeaderPadding?: boolean;
  noContentPadding?: boolean;
  alignHeader?: FlexAlignType;
}

export const TitleLayout: React.FC<Props> = ({
  title,
  subtitle,
  children,
  style,
  noContentPadding,
  noHeaderPadding,
  headerRight,
  alignHeader,
}) => {
  return (
    <View style={[styles.container, style]}>
      <View style={[styles.header, noHeaderPadding ? undefined : styles.headerPadding]}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: alignHeader ?? 'center',
          }}>
          <H2TtmSemiBoldBlack>{title}</H2TtmSemiBoldBlack>
          {headerRight}
        </View>
        {subtitle ? (
          <View style={mt10}>
            <H3TtmSemiBoldTheme>{subtitle}</H3TtmSemiBoldTheme>
          </View>
        ) : null}
      </View>
      <View style={[styles.content, noContentPadding ? null : styles.horizontalPadding]}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    display: 'flex',
  },
  header: {
    marginBottom: 15,
  },
  headerPadding: {
    paddingTop: 20,
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
  },
  content: {
    flex: 1,
  },
  horizontalPadding: {
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
  },
});
