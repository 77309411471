import { Route, useNavigation } from '@react-navigation/native';
import React from 'react';

import { PatientEditableHealthProfile } from '~/components/doctor/patients/healthProfile/PatientEditableHealthProfile';
import { NavType } from '~/navigation/types';
import { HealthProfileDataProvider } from '~/providers/healthProfile/HealthProfileDataProviders';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { id: string }>;
}

export const PatientHealthProfileEditing: React.FC<Props> = ({ route }) => {
  const patientId = +route.params.id;
  const navigation = useNavigation<NavType>();
  return (
    <HealthProfileDataProvider
      accountId={patientId}
      onSaved={() => {
        navigation.navigate(screenName.PatientHealthProfile, { id: patientId });
      }}>
      <PatientEditableHealthProfile
        editing
        onCancel={() => {
          navigation.navigate(screenName.PatientHealthProfile, { id: patientId });
        }}
        backLink={{ screen: screenName.PatientDetails, params: { id: patientId } }}
      />
    </HealthProfileDataProvider>
  );
};
