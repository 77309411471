import { useState } from 'react';

import { HealthRecordModel } from '~/api/models/consultations/models/HealthRecordModel';
import { UpdateHealthRecordModel } from '~/api/models/consultations/models/UpdateHealthRecordModel';
import { CreateHealthRecordRequest } from '~/api/models/consultations/requests/CreateHealthRecordRequest';
import { UpdateHealthRecordRequest } from '~/api/models/consultations/requests/UpdateHealthRecordRequest';
import {
  apiCreateHealthRecord,
  apiGetHealthRecord,
  apiUpdateHealthRecord,
} from '~/api/services/consultations/healthRecord';

interface Props {
  consultationId: number;
}
export function useHealthRecord({ consultationId }: Props) {
  const [loading, setLoading] = useState(false);
  const [healthRecord, setHealthRecordState] = useState<HealthRecordModel>(null);

  const getHealthRecord = async () => {
    try {
      setLoading(true);
      const res = await apiGetHealthRecord({ consultationId });
      setHealthRecordState(res.data);
      setLoading(false);
      return res.data;
    } catch {
      setLoading(false);
    }
  };

  const updateHealthRecord = async (req: UpdateHealthRecordRequest) => {
    await apiUpdateHealthRecord(req);

    return { id: req.healthRecordId };
  };
  const createHealthRecord = async (req: CreateHealthRecordRequest) => {
    const res = await apiCreateHealthRecord(req);
    return { id: res.data.id };
  };

  const setHealthRecord = (params: UpdateHealthRecordModel, healthRecordId?: number) => {
    if (!healthRecordId) {
      return createHealthRecord({ consultationId, params });
    } else {
      return updateHealthRecord({ healthRecordId, params });
    }
  };

  return { healthRecord, getHealthRecord, setHealthRecord, loading };
}
