import { useEffect, useMemo, useState } from 'react';

export interface WizardStep {
  key: string;
  title?: string;
  progressWeight?: number;
  component?: React.ReactElement;
  skip?: () => boolean;
}

export type WizardSteps = WizardStep[];

export interface WizardStepsProps {
  loading?: boolean;
  steps: WizardSteps;
  initialStep?: string;
}

export interface UseWizard {
  state: {
    step: string;
    stepIndex: number;
    hasNext: boolean;
    hasPrevious: boolean;
    component?: React.ReactNode;
  };
  actions: {
    next: () => boolean;
    previous: () => boolean;
    jumpTo: (stepKey: string) => boolean;
  };
  progress: { percentile: number; stepTotal: number };
  reset: () => void;
}

export function useWizardSteps(props: WizardStepsProps): UseWizard {
  const filteredSteps = useMemo(() => props.steps?.filter((step) => !step.skip || !step.skip()) ?? [], [props.steps]);

  const getInitialStep = () => {
    return props.initialStep ?? props.steps?.find((step) => !step.skip || !step.skip())?.key;
  };

  const [step, setStep] = useState<string>();

  const stepIndex = useMemo(() => filteredSteps.findIndex((s) => s.key === step), [step, filteredSteps]);
  const component = useMemo(() => filteredSteps[stepIndex]?.component, [step, stepIndex, filteredSteps]);

  const hasNext = useMemo(() => stepIndex < filteredSteps.length - 1, [stepIndex, filteredSteps]);
  const hasPrevious = useMemo(() => stepIndex > 0, [stepIndex, filteredSteps]);

  const totalProgress = useMemo(
    () => filteredSteps.reduce((sum, step) => sum + (step.progressWeight ?? 1), 0) || 1,
    [filteredSteps]
  );

  const percentileProgress = useMemo(
    () =>
      props.loading
        ? 0
        : filteredSteps.slice(0, stepIndex).reduce((sum, step) => sum + (step.progressWeight ?? 1), 0) / totalProgress,
    [filteredSteps, stepIndex, totalProgress, props.loading]
  );
  useEffect(() => {
    if (!step || filteredSteps?.findIndex((filteredStep) => filteredStep.key === step) !== stepIndex) {
      setStep(getInitialStep());
    }
  }, [filteredSteps]);

  const next = () => {
    if (hasNext) {
      setStep(filteredSteps[stepIndex + 1].key);
      return true;
    }
    return false;
  };

  const previous = () => {
    if (hasPrevious) {
      setStep(filteredSteps[stepIndex - 1].key);
      return true;
    }
    return false;
  };

  const jumpTo = (stepKey: string) => {
    if (filteredSteps.some((step) => step.key === stepKey)) {
      setStep(stepKey);
      return true;
    }
    return false;
  };

  return {
    state: {
      step,
      stepIndex,
      hasNext,
      hasPrevious,
      component,
    },
    actions: {
      next,
      previous,
      jumpTo,
    },
    progress: { percentile: percentileProgress, stepTotal: totalProgress },
    reset: () => {
      setStep(props.initialStep ?? getInitialStep());
    },
  };
}
