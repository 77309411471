export const errorMessages = {
  invalidOTP: 'Please Enter the valid 6 Digit OTP',
  inValidEmailid: 'In valid Email ID',
  emailIdIsBlank: 'Email Id blank',
  chooseProfileImage: 'Please choose/take a photo to continue',
  enterMandatoryFields: 'Please fill the mandatory fields',
  uploadMultipleFiles: 'Please Upload Verifictaion Document. it will be mentioned in above',
  slectTheAreaOfSpecilation: 'Please select the Area of specilation',
  slectClinicList: 'Please select the Clinic List',
  enterRegistrationNumber: 'Please Enter the Medical Council registration number',
  emailIdIsRequired: 'Email Id is required',
  pleaseEnterLegalFirstName: 'Please enter legal first name',
  pleaseEnterLegalLastName: 'Please enter legal last name',
  pleaseEnterYourPostcode: 'Please enter your postcode ',
  pleaseEnterYourAddress: 'Please enter your Address ',
  pleaseSelectYourDateOfBirth: 'Please select your date of birth',
  pleaseSelectYourCountry: 'Please select your country',
  pleaseEnterMedicalCouncileRegNo: 'Please Enter medical council registration number',
  pleaseSelectAreaOfSpecialisation: 'Please select the area of specialisation',
  pleaseSelectClinic: 'Please select the clinic',
  appointmentIsAlreadyCompleted: 'Appointment is already completed',
  pleaseEnterBeneficiaryName: 'Please enter the beneficiary name',
  pleaseEnteribanNo: 'Please enter the IBAN number',
  pleaseEnterbicNo: 'Please enter the BIC number',
  appointmentIsCancelled: 'Appointment is cancelled',
  passcodeChangedSuccessfully: 'Your passcode is changed successfully',
  uploadSignature: 'Please Upload the signature',
  lifeStyleSuccessfully: 'Life style saved successfully',
  prosthesisImplantsGraftsSuccessfully: 'Prosthesis/Implants/Grafts saved successfully',
  vaccinationsaveSuccessfully: 'vaccination saved successfully',
  metricsUpdateSuccess: 'Your health metrics updated successfully.',
  allergiesUpdateSuccess: 'Your health allergies updated successfully.',
  medicalConditionUpdateSuccess: 'Your health medical conditions updated successfully.',
  medicationsUpdateSuccess: 'Your health medications updated successfully.',
  proceduresUpdateSuccess: 'Your health procedures updated successfully.',
  appointmentWasRejected: 'Appointment Is Rejected',
  futureTimeOnlyAllowed: 'Future time only allowed.',
  requestForReportSentSuccessfully: 'Report request sent successfully.',
  signatureRequired: 'You need to include your signature to submit the consultation',
  signatureRequiredModalMessage:
    'In order to submit the consultation you need to allow the inclusion of your signature.',
  patientNotificationMessage: 'Notification sending failed, please attempt again.',
};
