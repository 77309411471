import React from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';

import { PrescriptionsForm } from './PrescriptionsForm';

import { PrescriptionFrequencyEnum } from '~/api/models/consultations/constants/PrescriptionFrequencyEnum';
import { alignItemsRight, flexRow, alignItemsCenter, mt10 } from '~/common/commonStyles';
import { H5TtmSemiBoldInfo, H6NsRegularBlack } from '~/components/commonText';
import { LoadingFetchingList } from '~/components/loading/LoadingFetchingList';
import { RoundedAddIcon } from '~/components/svgImages';
import { usePrescriptionDataContext } from '~/providers/consultation/PrescriptionDataContext';
import { Row, Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

export interface IPrescriptionFrequency {
  id: PrescriptionFrequencyEnum;
  name: string;
  times?: string;
  radioButtonValue: string;
}

export const PrescriptionsEditMode: React.FC = () => {
  const { loading, fields, addNew } = usePrescriptionDataContext();

  return (
    <View>
      {loading ? (
        <LoadingFetchingList fullWidth />
      ) : (
        <>
          <FlatList
            data={fields}
            renderItem={({ index }) => {
              return <PrescriptionsForm index={index} />;
            }}
            keyExtractor={(item) => `${item.id || item.formId}`}
          />
          {!fields.length ? (
            <Row>
              <Column style={alignItemsCenter}>
                <H6NsRegularBlack>No prescriptions currently being edited.</H6NsRegularBlack>
                <TouchableOpacity onPress={addNew} style={[flexRow, alignItemsCenter, mt10]}>
                  <RoundedAddIcon width="15" height="15" color={colors.info} />
                  <H5TtmSemiBoldInfo style={{ marginLeft: 10 }}>Add a new prescription</H5TtmSemiBoldInfo>
                </TouchableOpacity>
              </Column>
            </Row>
          ) : (
            <Row>
              <Column style={alignItemsRight}>
                <TouchableOpacity onPress={addNew} style={[flexRow, alignItemsCenter]}>
                  <RoundedAddIcon width="15" height="15" color={colors.info} />
                  <H5TtmSemiBoldInfo style={{ marginLeft: 10 }}>{labels.addAnotherItem}</H5TtmSemiBoldInfo>
                </TouchableOpacity>
              </Column>
            </Row>
          )}
        </>
      )}
    </View>
  );
};
