import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { CreatePharmacyRequest } from '~/api/models/pharmacy/requests/CreatePharmacyRequest';
import { apiCreatePharmacy, apiUpdatePharmacyImage } from '~/api/services/pharmacy';
import { mt10, mt15 } from '~/common/commonStyles';
import { UploadDocumentComponent } from '~/components/buttons/UploadDocumentComponent';
import { Button } from '~/components/commonButton';
import { FormErrorMessage } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { AddImage } from '~/components/svgImages';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { requiredValidation } from '~/services/validationConfig';
import { labels } from '~/utils/labels';

type CreatePharmacyForm = CreatePharmacyRequest & { pharmacyImage: DocumentPickerAsset };

export const PharmacyRegistrationDetailsScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [pharmacyImageUri, setPharmacyImageUri] = useState('');
  const { updateRegistrationStatus } = useRegistrationSteps();

  const { handleSubmit, control, setValue, clearErrors } = useForm<CreatePharmacyForm>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      registration_number: '',
      address: '',
      post_code: '',
      locality_id: undefined,
      pharmacyImage: undefined,
    },
  });

  const updateProfileImage = async (documentResults: DocumentPickerAsset[]) => {
    const documentResult = documentResults.length ? documentResults[0] : undefined;
    setValue('pharmacyImage', documentResult);
    if (documentResult) clearErrors('pharmacyImage');
    setPharmacyImageUri(documentResult?.uri);
    return Promise.resolve();
  };

  const onSubmit = async (data: CreatePharmacyForm) => {
    try {
      setLoading(true);
      const pharmacy = await apiCreatePharmacy({
        name: data.name,
        registration_number: data.registration_number,
      });

      if (data.pharmacyImage) {
        await apiUpdatePharmacyImage({ id: pharmacy.data.id, document: data.pharmacyImage });
      }

      await updateRegistrationStatus();
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };
  const buttons = [<Button funCallback={handleSubmit(onSubmit)} label={labels.continue} disabled={loading} />];

  return (
    <EnhancedRegistrationLayout
      buttons={buttons}
      title="Pharmacy details"
      subtitle="Please provide the pharmacy's information">
      <View style={{ width: '100%' }}>
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingInput
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error?.message}
              errorMessage={isSubmitted && error?.message}
              label="Pharmacy name"
              showMandatory
            />
          )}
          rules={{
            required: requiredValidation('Pharmacy name'),
          }}
        />

        <Controller
          name="pharmacyImage"
          control={control}
          render={({ fieldState: { error }, formState: { isSubmitted } }) => (
            <View style={[{ width: '100%' }, mt15]}>
              <UploadDocumentComponent
                uploadedFiles={[]}
                hideState
                imageUri={pharmacyImageUri}
                square
                uploadTitle="Upload image"
                icon={<AddImage height={35} width={35} />}
                setUploadedFiles={updateProfileImage}
                error={isSubmitted && !!error}
                imagesOnly
              />
              {isSubmitted && error?.message ? (
                <View style={mt10}>
                  <FormErrorMessage>{error.message}</FormErrorMessage>
                </View>
              ) : null}
            </View>
          )}
          rules={{
            required: requiredValidation('Profile image'),
          }}
        />

        <Controller
          name="registration_number"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingInput
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error?.message}
              errorMessage={isSubmitted && error?.message}
              label="Pharmacy registration number"
            />
          )}
        />
      </View>
    </EnhancedRegistrationLayout>
  );
};
