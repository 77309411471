import { useRegistrationSteps } from '../RegistrationSteps';

import { apiCompleteRoleRegistration } from '~/api/services/authorisation';
import { ErrorAlert } from '~/components/modals/ErrorAlert';

export function useFinaliseRegistration() {
  const { updateRegistrationStatus } = useRegistrationSteps();

  const finaliseRegistration = async () => {
    try {
      await apiCompleteRoleRegistration();
      await updateRegistrationStatus();
    } catch (e) {
      ErrorAlert(e);
    }
  };

  return { finaliseRegistration };
}
