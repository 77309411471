import React, { useMemo } from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';

import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { Grid, Container } from '~/theme/components/grid';
import { isPatientVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

interface Props {
  children: React.ReactNode;
  absoluteFooter?: React.ReactNode;
  footer?: React.ReactNode;
  backlink?: { screen: string; params?: Record<string, any> };
  title?: string;
  subtitle?: string;
  fluid?: boolean;
  noPadding?: boolean;
  flexContent?: boolean;
  noScrollWhenMobile?: boolean;
}

export const AppointmentShowPageLayout: React.FC<Props> = ({
  children,
  footer,
  backlink,
  title,
  subtitle,
  fluid,
  absoluteFooter,
  noPadding,
  flexContent,
  noScrollWhenMobile,
}) => {
  // const { fullScreen } = useAppointmentContext();
  const { isMobile } = useBreakpoints();

  const localBackLink = useMemo(() => {
    return backlink || (isPatientVersion() ? { screen: screenName.Appointments } : { screen: screenName.CalendarPage });
  }, [backlink]);

  const content = (
    <Grid>
      <Container alignSelf="flex-start" style={{ flex: 1 }} fluid={fluid}>
        {children}
      </Container>
    </Grid>
  );

  return (
    <PageLayout
      back={{
        to: localBackLink,
        title: 'Appointment details',
      }}
      hideGradient
      hideBack={isMobile}
      flexContent={flexContent}
      title={title}
      subtitle={subtitle}
      footer={footer}
      noTopPadding
      noScrollPadding={noPadding}
      absoluteFooter={absoluteFooter}
      children={isMobile && noScrollWhenMobile ? null : content}
      scrollContentNode={
        isMobile && noScrollWhenMobile ? (
          <KeyboardAvoidingView
            style={{ display: 'flex', flex: 1 }}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            keyboardVerticalOffset={Platform.OS === 'ios' ? 90 : 75}>
            {content}
          </KeyboardAvoidingView>
        ) : null
      }
    />
  );
};
