import Feather from '@expo/vector-icons/Feather';
import React, { useMemo, useState } from 'react';
import { Controller, useFormState, useWatch } from 'react-hook-form';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { Menu } from 'react-native-paper';

import { DoctorListFilterComponent } from '../components/DoctorListFilterComponent';
import { DoctorListSearchComponent } from '../components/DoctorListSearchComponent';
import { DoctorListSortingComponent, DoctorSortBy } from '../components/DoctorListSortingComponent';
import { DoctorListingAccordions } from '../components/DoctorListingAccordions';
import { DoctorListMobileHeader } from '../mobile/DoctorListMobileHeader';

import { flex1, mb10, mb20, mr10, mr15, mt10, p10, pv10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { Button } from '~/components/commonButton';
import { H3TtmSemiBoldBlack, H6NsRegularBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { RowView } from '~/components/commonViews';
import { TabsComponent } from '~/components/tabs/TabsComponent';
import { useDoctorListingContext } from '~/providers/appointment/DoctorListingContext';
import { Column, Grid, Row } from '~/theme/components/grid';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';

interface Props {
  hideTitle?: boolean;
}
export const DoctorListingAndFiltering: React.FC<Props> = ({ hideTitle }) => {
  const { isDesktop, isMobile } = useBreakpoints();
  const { filterControl, submitQuery } = useDoctorListingContext();
  const [showSorting, setShowSorting] = useState(false);
  const { isDirty } = useFormState({ control: filterControl });

  const sort = useWatch({ control: filterControl, name: 'sort' });
  const sortByLabel = useMemo(() => {
    return DoctorSortBy.find((item) => sort.includes(item.id))?.name;
  }, [sort]);
  const sorting = !isDesktop ? null : (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
          justifyContent: 'flex-end',
        },
        mr15,
      ]}>
      <Menu
        visible={showSorting}
        onDismiss={() => {
          setShowSorting(false);
          submitQuery();
        }}
        contentStyle={p10}
        anchor={
          <TouchableOpacity onPress={() => setShowSorting(true)}>
            <RowView style={pv10}>
              <H6NsRegularBlack style={mr10}>
                Sort by <H6NsSemiBoldBlack>{sortByLabel}</H6NsSemiBoldBlack>
              </H6NsRegularBlack>
              <Feather name={sort.charAt(0) === '-' ? 'chevron-down' : 'chevron-up'} size={14} />
            </RowView>
          </TouchableOpacity>
        }
        children={
          <Controller
            name="sort"
            control={filterControl}
            render={({ field: { value, onChange } }) => (
              <DoctorListSortingComponent value={value} onValueChanged={onChange} />
            )}
          />
        }
      />
    </View>
  );

  return (
    <View style={{ display: 'flex', flex: 1 }}>
      {isDesktop ? (
        <>
          {hideTitle ? null : <H3TtmSemiBoldBlack>{appointmentLabels.listDoctorTitle}</H3TtmSemiBoldBlack>}{' '}
          <View style={mb10}>
            <TabsComponent items={[]} joinedBackground right={sorting} fluid compact />
          </View>
        </>
      ) : (
        <DoctorListMobileHeader />
      )}
      <View style={[{ display: 'flex', flex: 1 }]}>
        <Grid grid={{ gutters: 10 }}>
          <Row style={flex1}>
            {isDesktop ? (
              <Column span={{ lg: 4, xl: 3 }} style={{ height: '100%', maxWidth: 400 }}>
                <View style={mb20}>
                  <Controller
                    name="search"
                    control={filterControl}
                    render={({ field: { value, onChange } }) => (
                      <DoctorListSearchComponent value={value} setValue={onChange} onSubmitSearch={submitQuery} />
                    )}
                  />
                </View>
                <ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingRight: 5, marginBottom: 10 }}>
                  <DoctorListFilterComponent />
                </ScrollView>
                <View style={mt10}>
                  <Button label={labels.apply} funCallback={submitQuery} disabled={!isDirty} />
                </View>
              </Column>
            ) : null}
            <Column style={{ height: '100%' }}>
              {isMobile ? (
                <ScrollView style={flex1} contentContainerStyle={[{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE }]}>
                  <DoctorListingAccordions />
                </ScrollView>
              ) : (
                <View style={{ flex: 1 }}>
                  <DoctorListingAccordions />
                </View>
              )}
            </Column>
          </Row>
        </Grid>
      </View>
    </View>
  );
};
