import React, { useContext } from 'react';
import { FieldArrayWithId } from 'react-hook-form';

import { BaseConsultationDataContext } from './BaseConsultationDataContext';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { PrescriptionFormModel, PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { UseFormWithRulesReturn } from '~/utils/hooks/FormWithRulesHook';

export interface IPrescriptionDataContext extends BaseConsultationDataContext {
  form: UseFormWithRulesReturn<{ prescriptions: PrescriptionFormModel[] }>;
  fields: FieldArrayWithId<{ prescriptions: PrescriptionFormModel[] }, 'prescriptions', 'fieldId'>[];
  loading: boolean;
  prescriptions: PrescriptionModel[];
  consultation: ConsultationModel;
  addNew: () => void;
  removePrescription: (index: number) => void;
}

export const PrescriptionDataContext = React.createContext<IPrescriptionDataContext>({} as IPrescriptionDataContext);
export const usePrescriptionDataContext = (): IPrescriptionDataContext => useContext(PrescriptionDataContext);
