import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useFeatureAccess } from '../subscriptions/FeatureAccessHook';

import { DependantStateEnum } from '~/api/models/dependants/constants/DependantStateEnum';
import { DependantsUnlimitedResponse } from '~/api/models/dependants/responses/DependantsIndexResponse';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { FeatureAccessStateEnum } from '~/api/models/subscriptions/constants/FeatureAccessStateEnum';
import { apiGetDependants } from '~/api/services/dependants';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { SET_DEPENDANTS, allDependantsSelector } from '~/redux/reducers/dependantsReducer';
import { useAppDispatch } from '~/redux/store';
import { usePageFocus } from '~/utils/hooks/FocusHook';

interface Props {
  state?: DependantStateEnum;
  allowStale?: boolean;
  preventOnFocus?: boolean;
}
export function useDependantsHook(props?: Props) {
  const requestPromise = useRef<Promise<DependantsUnlimitedResponse>>(null);
  const dependants = useSelector(allDependantsSelector);
  const { fetchFeatureAccess } = useFeatureAccess();
  const filteredDependants = useMemo(
    () => (props?.state ? dependants?.filter((d) => d.state === props.state) : dependants),
    [dependants, props?.state]
  );
  const dispatch = useAppDispatch();

  const makeRequest = () => {
    return apiGetDependants<DependantsUnlimitedResponse>({
      limit: -1,
      filter: {
        state: props?.state,
      },
    }).then((res) => res.data);
  };

  const getDependants = async () => {
    try {
      requestPromise.current = makeRequest();

      const res = await requestPromise.current;

      dispatch(SET_DEPENDANTS(res));

      return res;
    } catch (e) {
      ErrorAlert(e);
    } finally {
      requestPromise.current = null;
    }
  };

  const getDependantsIfAccess = async () => {
    const features = await fetchFeatureAccess();
    if (
      features?.features.find((feature) => feature.slug === FeatureAccessEnum.FAMILY_GROUP)?.state !==
      FeatureAccessStateEnum.ACTIVE
    ) {
      return;
    }
    if (requestPromise.current) {
      return requestPromise.current;
    }
    return getDependants();
  };

  usePageFocus(() => {
    if (props?.preventOnFocus || (props?.allowStale && dependants)) return;
    getDependantsIfAccess();
  });

  const fetchDependants = async (force?: boolean) => {
    if (!force && dependants) {
      return dependants;
    }

    if (requestPromise.current) {
      return requestPromise.current;
    }

    return getDependantsIfAccess();
  };

  return { dependants: filteredDependants, fetchDependants };
}
