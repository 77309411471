const ClassToMessageMapping = {
  'Class I': {
    subtitle:
      'No limitations. Ordinary physical activity does not cause undue fatigue, dyspnoea or palpitations (asymptomatic LV dysfunction).',
    met: 'MET > 7',
  },
  'Class II': {
    subtitle:
      'Slight limitation of physical activity. Ordinary physical activity results in fatigue, palpitation, dyspnoea or angina pectoris (mild CHF).',
    met: 'MET = 5',
  },
  'Class III': {
    subtitle:
      'Marked limitation of physical activity. Less than ordinary physical activity leads to symptoms (moderate CHF).',
    met: 'MET = 2–3',
  },
  'Class IV': {
    subtitle:
      'Unable to carry on any physical activity without discomfort. Symptoms of CHF present at rest (severe CHF).',
    met: 'MET = 1.6',
  },
};

export interface NyhaQuestionType {
  id: string;
  title: string;
  subtitle?: string;
  met?: string;
}

export function processNyhaQuestion(options: string[]): NyhaQuestionType[] {
  return options.map((option) => ({
    id: option,
    title: option,
    subtitle: ClassToMessageMapping[option]?.subtitle,
    met: ClassToMessageMapping[option]?.met,
  }));
}
