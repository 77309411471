import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { StyleProp, Text, View, ViewStyle, StyleSheet } from 'react-native';

import { usePatientDiagnoses } from '~/api/hooks/patients/PatientDiagnosesHook';
import { DiagnosisModel } from '~/api/models/diagnoses/models/DiagnosisModel';
import { flex1, m5, ml10, ml5, mr5 } from '~/common/commonStyles';
import { DataTable } from '~/components/common/DataTable/DataTable';
import { DataTableColumns, DataTableProps, RowStyle } from '~/components/common/DataTable/DataTableTypes';
import { OutlineButton } from '~/components/commonButton';
import {
  H5NsSemiBoldSecondaryBlack,
  H5TtmRegularInfo,
  H5TtmSemiBoldInfo,
  H6NsRegularBlack,
  H6NsSemiBoldBlack,
  H6NsSemiBoldLink,
} from '~/components/commonText';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { ArrowRight, Person } from '~/components/svgImages';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { NavType } from '~/navigation/types';
import { isPatientVersion } from '~/utils/buildConfig';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { colors } from '~/utils/colors';
import { parseDateTime, DATE_TIME_FORMAT } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

interface Props {
  id?: number;
  listHeaders?: DataTableProps['listHeader'];
  hideHeaders?: boolean;
  selected?: number[];
  contentStyle?: StyleProp<ViewStyle>;
  wrapperStyle?: StyleProp<ViewStyle>;
  onRowClick?: (key: number) => void;
  existingDiagnoses?: boolean;
  rowStyle?: RowStyle;
  searchValue?: string;
  forceInfiniteScroll?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const PatientDiagnosesList: React.FC<Props> = ({
  id,
  listHeaders,
  hideHeaders,
  selected,
  onRowClick,
  contentStyle,
  wrapperStyle,
  existingDiagnoses,
  rowStyle,
  searchValue,
  forceInfiniteScroll,
  style,
}) => {
  const navigation = useNavigation<NavType>();
  const { isMobile } = useBreakpoints();
  const { pagination, loading, diagnoses, setPagination, searchDiagnoses } = usePatientDiagnoses({
    accountId: id,
    isInfiniteScroll: isMobile || forceInfiniteScroll,
  });
  const { showNewBadge } = useNewBadge();

  const patientColumns: DataTableColumns = [
    {
      key: 'reference',
      title: 'Diagnosis Ref no.',
      cell: (cellData) => <Text>Ref No: {cellData}</Text>,
      columnStyle: { maxWidth: 200 },
    },
    {
      key: 'diagnosis',
      title: 'Diagnosis title',
      cell: (cellData, itemData: DiagnosisModel) => (
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <H5NsSemiBoldSecondaryBlack>{extractClinicalTermDescription(itemData.diagnosis)}</H5NsSemiBoldSecondaryBlack>
        </View>
      ),
    },
    //To return diagnosis according to account not user
    {
      key: 'account_data',
      title: 'Patient',
      cell: (cellData) => <ProfileDetailsComponent account={cellData} theme="table" style={flex1} />,
    },
    {
      key: 'updated_at',
      title: 'Last updated',
      cell: (cellData) => <Text>{parseDateTime(cellData, { outputFormat: DATE_TIME_FORMAT })}</Text>,
      columnStyle: { maxWidth: 200 },
    },
    {
      key: 'id',
      title: '',
      cell: (_, item) => <OutlineButton label="View Details" funCallback={() => rowPress(item.id)} />,
      columnStyle: { maxWidth: 200, display: 'flex', flexDirection: 'row-reverse' },
    },
  ];

  const doctorColumns: DataTableColumns = [
    {
      key: 'reference',
      title: 'Diagnosis Ref no.',
      cell: (cellData) => <Text>Ref No: {cellData}</Text>,
      columnStyle: { maxWidth: 200 },
    },
    {
      key: 'diagnosis',
      title: 'Diagnosis title',
      cell: (cellData, itemData: DiagnosisModel) => (
        <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <H5NsSemiBoldSecondaryBlack>{extractClinicalTermDescription(itemData.diagnosis)}</H5NsSemiBoldSecondaryBlack>
        </View>
      ),
    },
    {
      key: 'updated_at',
      title: 'Last updated',
      cell: (cellData) => <Text>{parseDateTime(cellData, { outputFormat: DATE_TIME_FORMAT })}</Text>,
      columnStyle: { maxWidth: 200 },
    },
    {
      key: 'id',
      title: '',
      cell: () => <ArrowRight height={15} width={15} />,
      columnStyle: { maxWidth: 50, display: 'flex', flexDirection: 'row-reverse' },
    },
  ];

  const existingDiagnosesList: DataTableColumns = [
    {
      key: 'reference',
      title: 'Diagnosis Ref no.',
      cell: (cellData) => <Text>Ref No: {cellData}</Text>,
      columnStyle: { maxWidth: 200 },
    },
    {
      key: 'diagnosis',
      title: 'Diagnosis title',
      cell: (cellData, itemData: DiagnosisModel) => (
        <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <H5NsSemiBoldSecondaryBlack>{extractClinicalTermDescription(itemData.diagnosis)}</H5NsSemiBoldSecondaryBlack>
        </View>
      ),
    },
    {
      key: 'id',
      title: '',
      cell: () => <ArrowRight height={10} width={5} />,
      columnStyle: { maxWidth: 15 },
    },
  ];

  const rowPress = (key: number) => {
    if (onRowClick) {
      onRowClick(key);
    } else {
      navigation.navigate(isPatientVersion() ? screenName.PatientVersionDiagnosis : screenName.PatientDiagnosis, {
        id,
        diagnosisId: key,
      });
    }
  };

  useEffect(() => {
    searchDiagnoses(searchValue);
  }, [searchValue]);

  return (
    <DataTable
      tableId="diagnoses"
      nestedScrollEnabled
      numberOfItemsPerPage={pagination?.per_page}
      style={[flex1, style]}
      columns={existingDiagnoses ? existingDiagnosesList : isPatientVersion() ? patientColumns : doctorColumns}
      items={!isMobile && !forceInfiniteScroll ? (loading ? [] : diagnoses ?? []) : diagnoses ?? []}
      totalCount={pagination?.total ?? 0}
      onRowPress={rowPress}
      hideHeaders={hideHeaders}
      page={pagination?.current_page ?? 0}
      onPageChanged={setPagination}
      onItemsPerPageChange={setPagination}
      infiniteScroll={
        isMobile || forceInfiniteScroll ? { loading, estimatedItemSize: { desktop: 60, mobile: 110 } } : null
      }
      loading={loading}
      listHeader={listHeaders}
      emptyNote={isPatientVersion() ? labels.noCurrentDiagnosisPatient : labels.noCurrentDiagnosisDoctor}
      selected={selected}
      rowStyle={rowStyle ? rowStyle : selected ? m5 : null}
      contentStyle={contentStyle}
      wrapperStyle={wrapperStyle}
      mobileBreakpoint={DeviceSizeDefaults.tablet}
      dataTableCardStyle={{ marginBottom: 10 }}
      rowIsNewIdentifier={isPatientVersion() ? (item) => showNewBadge({ value: item, timeKey: 'updated_at' }) : null}
      mobileRenderItem={(item: DiagnosisModel, content) => {
        return isPatientVersion() ? (
          <View style={[{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch' }]}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <View style={{ flex: 1 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 5 }}>
                  <H6NsSemiBoldBlack style={flex1}>{extractClinicalTermDescription(item.diagnosis)}</H6NsSemiBoldBlack>
                  <H5TtmRegularInfo style={[styles.customFontSize, ml10]}>
                    Ref No: <H5TtmSemiBoldInfo style={styles.customFontSize}>{item.reference}</H5TtmSemiBoldInfo>
                  </H5TtmRegularInfo>
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'center', paddingTop: 10 }}>
                  <ProfileImageComponent
                    account={item.account_data}
                    size={49}
                    style={{ marginRight: 10 }}
                    fallback={<Person height={20} width={20} color={colors.purple} />}
                  />
                  <View style={{ flex: 1 }}>
                    <H6NsSemiBoldBlack>
                      {item.account_data?.first_name} {item.account_data?.last_name}
                    </H6NsSemiBoldBlack>
                    <H6NsRegularBlack style={[{ paddingVertical: 2 }, styles.customFontSize]}>
                      ID: {item.account_data?.id}
                    </H6NsRegularBlack>
                    <H6NsRegularBlack style={styles.customFontSize}>
                      Last updated: {parseDateTime(item.updated_at, { outputFormat: DATE_TIME_FORMAT })}
                    </H6NsRegularBlack>
                  </View>
                </View>
              </View>
            </View>
          </View>
        ) : (
          <View style={styles.mobileCard}>
            <View style={mr5}>
              <H6NsSemiBoldLink>Ref: {item.reference}</H6NsSemiBoldLink>
              <H6NsRegularBlack>{content?.updated_at?.content}</H6NsRegularBlack>
            </View>
            <View style={[ml5, flex1]}>
              <H6NsSemiBoldBlack>{extractClinicalTermDescription(item.diagnosis)}</H6NsSemiBoldBlack>
            </View>
          </View>
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  status: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  customFontSize: { fontSize: 12 },
  mobileCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  description: {
    height: 40,
    overflow: 'hidden',
  },
});
