import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle, Text, Platform } from 'react-native';

import { SearchableList } from './SearchableList';
import { InputProps } from '../FloatingInput';

import { ClinicalTermLabelValue, useClinicalTermsSearchHook } from '~/api/hooks/clinicalTerms/ClinicalTermsSearchHook';
import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { LabelValuePair } from '~/common/types/LabelValuePair';
import { colors } from '~/utils/colors';
import { fontFamily } from '~/utils/fontFamily';
import { SmallNsRegularPlaceholder } from '~/components/commonText';

interface BaseProps {
  type: ClinicalTermTypeEnum;
  title?: string;
  disabled?: boolean;
  singleSelect?: boolean;
  style?: StyleProp<ViewStyle>;
  inputOptions?: Partial<InputProps>;
  showMandatory?: boolean;
  error?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
}

interface FreeTextProps extends BaseProps {
  values?: LabelValuePair<number | string>[];
  setValues?: (values: LabelValuePair<number | string>[]) => void;
  allowFreeText: true;
}

interface StrictProps extends BaseProps {
  values?: LabelValuePair<number>[];
  setValues?: (values: LabelValuePair<number>[]) => void;
  allowFreeText?: false;
}

export const ClinicalTermSearchableList: React.FC<FreeTextProps | StrictProps> = ({
  type,
  title,
  values,
  disabled,
  singleSelect,
  style,
  inputOptions = {},
  error,
  errorMessage,
  showMandatory,
  setValues,
  allowFreeText,
  onBlur,
}) => {
  const { clinicalTermSearch, clinicalTermsLabelValue } = useClinicalTermsSearchHook({
    type,
  });

  const onSelect = (value: string, item: LabelValuePair<any>) => {
    if (singleSelect) {
      setValues([item]);
    } else {
      const newValues = [...(values || []), item];
      setValues(newValues);
    }
  };

  const onRemove = (value: number) => {
    const index = values?.findIndex((item) => item.value === value);
    if (index > -1) {
      const newValues = [...values];
      newValues.splice(index, 1);
      setValues(newValues as any);
    }
  };

  const formattedTitle = useMemo(() => title || type?.replace(/-/g, ' '), [title]);
  const hideInput = useMemo(() => disabled && !!values?.length, [disabled, values]);
  const placeholder = useMemo(() => {
    if (hideInput) return undefined;
    return formattedTitle ? `Search for ${formattedTitle.toLowerCase()}` : 'Search';
  }, [formattedTitle, type, disabled, values]);

  const selectionMessage = useMemo(() => {
    if (hideInput || !clinicalTermsLabelValue?.length) return undefined;
    let message = formattedTitle
      ? singleSelect
        ? `Select a ${formattedTitle.toLowerCase()}`
        : `Select one or more ${formattedTitle.toLowerCase()}`
      : singleSelect
      ? 'Select an option'
      : 'Select one or more options';

    if (allowFreeText) {
      message += ` or hit ${Platform.select({
        ios: 'return',
        default: 'enter',
      })} to add your current search term`;
    }
    return message;
  }, [title, type, hideInput, clinicalTermsLabelValue, allowFreeText]);

  const listHeader = useMemo(() => {
    if (!selectionMessage) return undefined;
    return (
      <View style={{ paddingHorizontal: 12, paddingTop: 4 }}>
        <SmallNsRegularPlaceholder>{selectionMessage}</SmallNsRegularPlaceholder>
      </View>
    );
  }, [selectionMessage]);
  return (
    <SearchableList
      style={style}
      values={values || []}
      list={clinicalTermsLabelValue}
      disabled={disabled}
      error={error}
      errorMessage={errorMessage}
      onSearchChange={clinicalTermSearch}
      onSelect={onSelect}
      onRemove={onRemove}
      onBlur={onBlur}
      inputOptions={{
        ...inputOptions,
        placeholder,
      }}
      hideInput={hideInput}
      showMandatory={showMandatory}
      allowFreeText={allowFreeText}
      noResultsText={
        allowFreeText ? 'No results found. You can submit your term instead' : 'No results for your search'
      }
      listHeader={listHeader}
      renderItem={(item: ClinicalTermLabelValue) => (
        <View style={styles.termContainer}>
          <Text style={styles.term}>{item.label}</Text>
          {item.fullySpecifiedName ? (
            <>
              <View style={styles.splitter} />
              <Text style={styles.fsn}>{item.fullySpecifiedName?.term}</Text>
            </>
          ) : null}
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  splitter: {
    marginHorizontal: 4,
    width: 1,
    backgroundColor: colors.purpleGrey,
    height: '100%',
  },
  term: {
    fontFamily: fontFamily.fontRegular,
    flex: 1,
    fontSize: 12,
  },
  termContainer: {
    paddingVertical: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fsn: {
    fontFamily: fontFamily.fontRegular,
    fontSize: 10,
    color: colors.purpleGrey,
    width: 160,
    maxWidth: '50%',
  },
});
