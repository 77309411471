import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Menu } from 'react-native-paper';

import { BillingHistoryFilters } from './components/BillingHistoryFilters';
import { useBillingHistoryContext } from './contexts/BillingHistoryContext';

import { mb5 } from '~/common/commonStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { H6NsRegularBlack } from '~/components/commonText';
import { DownArrow } from '~/components/svgImages';
import { isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';

export const BillingHistoryFilterMenu: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const { form } = useBillingHistoryContext();

  return (
    <IconButton onPress={() => setVisible(true)} style={[styles.buttonStyle, mb5]} hoverStyle={styles.buttonHover}>
      <Menu
        visible={visible}
        onDismiss={() => {
          setVisible(false);
        }}
        style={[styles.menuStyle]}
        anchor={
          <View style={{ width: 234, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <H6NsRegularBlack>Filter</H6NsRegularBlack>
            <DownArrow height={10} width={10} color={colors.purple} />
          </View>
        }>
        <View
          style={[
            isDoctorVersion() ? { height: 170 } : { height: 207 },
            { width: 250, padding: 10, alignItems: 'flex-start' },
          ]}>
          <ScrollView style={{ width: '100%' }}>
            <BillingHistoryFilters form={form} isMenu />
          </ScrollView>
        </View>
      </Menu>
    </IconButton>
  );
};

const styles = StyleSheet.create({
  menuStyle: {
    marginTop: 10,
    marginLeft: 10,
  },
  buttonStyle: {
    padding: 10,
    marginLeft: 10,
  },
  buttonHover: {
    borderColor: colors.lightPurple,
    backgroundColor: colors.lightPurple,
  },
});
