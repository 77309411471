import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { colors } from '../../../../utils/colors';
import { Home, Stethoscope, Calendar } from '../../../svgImages';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';

interface CardProps {
  type: ConsultationTypeEnum;
  style?: StyleProp<ViewStyle>;
}

const Icons = {
  [ConsultationTypeEnum.HOME_VISIT]: <Home height={20} width={20} color={colors.grey} />,
  [ConsultationTypeEnum.ON_DEMAND]: <Stethoscope height={20} width={20} color={colors.grey} />,
  [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: <Calendar height={20} width={20} color={colors.grey} />,
};

export const ConsultationTypeIcons: React.FC<CardProps> = ({ type, style }): JSX.Element => {
  return <View style={style}>{Icons[type]}</View>;
};
