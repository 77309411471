import React, { useEffect, useMemo, useState } from 'react';

import { AppointmentContext, EndConsultationOptions } from './AppointmentContext';
import { useModalManager } from '../modal/ModalManagementContext';

import { useConsultationIdChannel } from '~/api/hooks/channels/useConsultationIdChannel';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { ConsultationEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ConsultationWaitTimeResponse } from '~/api/models/consultations/responses/ConsultationWaitTimeResponse';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { apiDoctorCancelAppointment, apiPatientCancelAppointment } from '~/api/services/appointments';
import { apiGetConsultation, apiUpdateConsultation } from '~/api/services/consultations';
import {
  apiEndHomeVisitNavigation,
  apiPauseHomeVisitNavigation,
  apiStartHomeVisitNavigation,
} from '~/api/services/consultations/navigation';
import {
  apiEndConsultation,
  apiGetEstimatedWaitTime,
  apiStartConsultation,
} from '~/api/services/consultations/waitingRoom';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { ConfirmationAlert, SuccessAlert } from '~/common/commonMethods';
import { AppointmentCancellationModal } from '~/components/dashboard/doctor/AppointmentsList/AppointmentCancellationModal';
import { EndConsultationModal } from '~/components/modals/EndConsultationModal';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { ChannelTypeEnum } from '~/constants/channelTypeEnum';
import { isDoctorVersion, isPatientVersion, whenAppType } from '~/utils/buildConfig';
import { LARAVEL_DATE_TIME, parseDateTime } from '~/utils/dateAndTime';
import { useNavigationAppointments } from '~/utils/hooks/AppNavigationHook';
import { useJoinAppointment } from '~/utils/hooks/appointments/AppointmentDetailsHook';
import { stateEndedOrPassed } from '~/utils/hooks/appointments/AppointmentStateHook';
import { labels } from '~/utils/labels';
import { consultationsLabels } from '~/utils/labels/consultations';
import log from '~/utils/logger';
import { RejectConsultationReasonModal } from '~/components/doctor/booking/RejectConsultationReasonModal';
import { appointmentLabels } from '~/utils/labels/appointments';

interface Props {
  consultationId: number;
  children: React.ReactNode;
  onReady?: (approved?: boolean) => void;
}

export const AppointmentProvider: React.FC<Props> = ({ consultationId, children, onReady }) => {
  const [loading, setLoading] = useState(true);
  const [consultation, setConsultation] = useState<ConsultationModel>(null);
  const [estimatedWait, setEstimated] = useState<ConsultationWaitTimeResponse>();
  const [fullScreen, setFullScreen] = useState(false);
  const { registerModal, openModal, closeModalByName } = useModalManager();
  const { navigate: navigateAppointments } = useNavigationAppointments();
  const { showJoin } = useJoinAppointment(consultation);
  const { isFeatureActive } = useFeatureAccess();

  const appointmentChanged = (newConsultationId: number) => {
    if (consultationId && newConsultationId && +newConsultationId === consultationId) {
      getConsultationRequest(false);
    }
  };

  useEffect(() => {
    registerModal(ModalName.EndConsultation, EndConsultationModal);
    registerModal(ModalName.CancelAppointment, AppointmentCancellationModal);
    registerModal(ModalName.RejectConsultationReasonModal, RejectConsultationReasonModal);

    const event = whenAppType({
      whenDoctor: AppEvents.DOCTOR_APPOINTMENTS_CHANGED,
      whenPatient: AppEvents.PATIENT_APPOINTMENTS_CHANGED,
    });

    if (event) {
      const removeListener = AppEventHandler.addListener(event, appointmentChanged);
      return removeListener;
    }
  }, []);

  const onConsultationEndedChannelEventHandler = async (event) => {
    switch (event) {
      case ConsultationEventsEnum.CONSULTATION_STATE_STARTED:
        getConsultationRequest(false);
        break;
    }
  };

  useConsultationIdChannel({
    consultationID: consultationId,
    eventHandler: onConsultationEndedChannelEventHandler,
  });

  const getConsultationRequest = async (withLoading?: boolean) => {
    try {
      setLoading(withLoading ?? true);
      const res = await apiGetConsultation({ id: consultationId });
      setConsultation(res.data);
    } catch (e) {
      if (typeof e?.message !== 'string' || (e.message as string).indexOf('No query results for model ') !== 0)
        ErrorAlert(e);
      closeModalByName(ModalName.AppointmentDetails);
    }
    setLoading(false);
  };

  const getEstimate = async () => {
    if (!consultation || consultation.state !== ConsultationStateEnum.Scheduled) return;

    const res = await apiGetEstimatedWaitTime({ id: consultation.id });
    setEstimated(res.data);

    return res.data;
  };

  const startConsultation = async () => {
    if (consultation.state === ConsultationStateEnum.Scheduled) {
      await apiStartConsultation({ id: consultation.id });
      const res = await apiGetConsultation({ id: consultationId });
      setConsultation(res.data);
    }
  };

  const submitEndConsultationPrice = async (value: number | undefined, resolve: () => void) => {
    try {
      await apiEndConsultation({
        consultationId: consultation.id,
        params: {
          final_amount: value,
        },
      });

      await getConsultationRequest();
      SuccessAlert(
        [labels.kindlyFillTheDetailsToCompleteTheConsultation],
        labels.consultationEnd,
        labels.completeReport
      );

      resolve();
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const endConsultation = async (options?: EndConsultationOptions) => {
    await new Promise<void>((resolve) => {
      const showEndingConsultation = async () => {
        if (await isFeatureActive(FeatureAccessEnum.CONSULTATION_PRICES)) {
          openModal(ModalName.EndConsultation, {
            consultation,
            onSubmit: async (value: number) => {
              await submitEndConsultationPrice(value, resolve);
            },
          });
        } else {
          await submitEndConsultationPrice(undefined, resolve);
        }
      };

      ConfirmationAlert(options?.overrideMessage ?? ['Are you sure you want to end this consultation?'], {
        title: options?.overrideTitle ?? 'End Consultation',
        okTitle: consultationsLabels.endConsultation,
        cancelTitle: labels.cancel,
        okFunction: () => {
          AppEventHandler.emit(AppEvents.DOCTOR_ENDED_APPOINTMENT, [{ consultationId: consultation.id }]);
          showEndingConsultation();
        },
        cancelFunction: resolve,
      });
    });
  };

  const updateConsultationDiagnosis = async (diagnosisId: number) => {
    const res = await apiUpdateConsultation({
      id: consultation.id,
      params: {
        diagnosis_id: diagnosisId,
        account_id: consultation.patient.id,
        start_at: parseDateTime(consultation.start_at, { outputFormat: LARAVEL_DATE_TIME }),
        end_at: parseDateTime(consultation.end_at, { outputFormat: LARAVEL_DATE_TIME }),
        type: consultation.type,
        reason: consultation.reason,
      },
    });

    setConsultation(res.data);
  };

  const cancelAppointment = () => {
    openModal(ModalName.CancelAppointment, {
      onSubmit: () => {
        tryCancelAppointment();
      },
    });
  };

  const tryCancelAppointment = async () => {
    try {
      if (isDoctorVersion()) {
        openModal(ModalName.RejectConsultationReasonModal, {
          consultation,
          title: labels.cancelAppointment,
          description: appointmentLabels.cancelAppointmentTitle,
          declineApi: apiDoctorCancelAppointment,
          buttonTitle: labels.cancelAppointment,
          onReady: async () => {
            AppEventHandler.emit(AppEvents.DOCTOR_APPOINTMENTS_CHANGED);
            SuccessAlert(['Your appointment was successfully cancelled'], undefined, undefined, navigateAppointments);
            await getConsultationRequest();
            closeModalByName(ModalName.CancelAppointment);
          },
        });
      } else if (isPatientVersion()) {
        await apiPatientCancelAppointment({ id: consultation.id });
        SuccessAlert(['Your appointment was successfully cancelled'], undefined, undefined, navigateAppointments);
        closeModalByName(ModalName.CancelAppointment);
        await getConsultationRequest();
      }
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const isTimeForConsultation = useMemo(() => {
    if (
      !consultation ||
      (consultation.state === ConsultationStateEnum.Ended && consultation.channel?.id !== ChannelTypeEnum.Chat)
    )
      return false;

    switch (consultation.type) {
      case ConsultationTypeEnum.CLINIC:
        return false;
      case ConsultationTypeEnum.ON_DEMAND:
        return (
          (consultation.state !== ConsultationStateEnum.Scheduled || isDoctorVersion() || estimatedWait?.queue < 1) &&
          !stateEndedOrPassed(consultation.state)
        );
      case ConsultationTypeEnum.HOME_VISIT:
      case ConsultationTypeEnum.SCHEDULED_APPOINTMENT:
      default:
        return showJoin;
    }
  }, [consultation?.type, consultation?.state, consultation?.channel, showJoin, estimatedWait?.queue]);

  useEffect(() => {
    if (consultationId) getConsultationRequest();
    else setLoading(false);
  }, [consultationId]);

  useEffect(() => {
    if (consultation?.type === ConsultationTypeEnum.ON_DEMAND) {
      getEstimate();
    } else {
      setEstimated(null);
    }
  }, [consultation]);

  const startNavigation = async () => {
    try {
      if (!consultation?.id) return;
      await apiStartHomeVisitNavigation({ id: consultation.id });
      await getConsultationRequest(false);
    } catch (e) {
      log.error(e);
    }
  };

  const pauseNavigation = async () => {
    try {
      if (!consultation?.id) return;
      await apiPauseHomeVisitNavigation({ id: consultation.id });
      await getConsultationRequest(false);
    } catch (e) {
      log.error(e);
    }
  };

  const endNavigation = async () => {
    try {
      if (!consultation?.id) return;
      await apiEndHomeVisitNavigation({ id: consultation.id });
      await getConsultationRequest(false);
    } catch (e) {
      log.error(e);
    }
  };

  return (
    <AppointmentContext.Provider
      value={{
        consultation,
        estimatedWait,
        fullScreen,
        setFullScreen,
        updateConsultationDiagnosis,
        updateConsultation: getConsultationRequest,
        updateEstimate: getEstimate,
        startConsultation,
        endConsultation,
        cancelAppointment,
        loading,
        endNavigation,
        startNavigation,
        pauseNavigation,
        isTimeForConsultation,
      }}>
      {children}
    </AppointmentContext.Provider>
  );
};
