import { useNavigation } from '@react-navigation/native';
import { useEffect, useRef } from 'react';

export function useBeforeUnload(fn: (e: BeforeUnloadEvent) => void) {
  const cb = useRef(fn);

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    // @ts-ignore
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);
}

export function useBeforeRemove(fn: (e: BeforeUnloadEvent) => void) {
  const cb = useRef(fn);
  const navigation = useNavigation();

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    // @ts-ignore
    const onUnload = (...args) => cb.current?.(...args);

    navigation.addListener('beforeRemove', onUnload);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);
}
