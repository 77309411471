import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Route } from '@react-navigation/native';
import React, { useMemo } from 'react';

import { DoctorAppointmentExtraDetails } from './DoctorAppointmentExtraDetails';
import { DoctorAppointmentShowContent } from './show/DoctorAppointmentShowContent';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationLoading } from '~/components/common/consultations/ConsultationLoading';
import { ConsultationNotFound } from '~/components/common/consultations/ConsultationNotFound';
import { DoctorCall } from '~/components/common/consultations/call/DoctorCall';
import { DoctorChat } from '~/components/common/consultations/chat/DoctorChat';
import { ChannelTypeEnum } from '~/constants/channelTypeEnum';
import { AppointmentPageEnum } from '~/pages/appointment/constants/AppointmentPageEnum';
import { AppointmentChatProvider } from '~/providers/appointment/AppointmentChatProvider';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { AppointmentProvider } from '~/providers/appointment/AppointmentProvider';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { PrivateNoteDataProvider } from '~/providers/consultation/PrivateNoteDataProvider';

interface Props {
  route: Route<string, { consultationId: string }>;
}

const DoctorChatWithContext: React.FC = () => {
  const { consultation } = useAppointmentContext();
  return (
    <AppointmentChatProvider consultation={consultation}>
      <DoctorChat />
    </AppointmentChatProvider>
  );
};

const AppointmentShowWithNavigation: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const { consultation, loading, estimatedWait, isTimeForConsultation } = useAppointmentContext();

  const getCallType = () => {
    if (consultation.channel?.id === ChannelTypeEnum.Chat) {
      return AppointmentPageEnum.InConsultation_Chat;
    } else {
      return AppointmentPageEnum.InConsultation_Call;
    }
  };

  const pageState = useMemo((): AppointmentPageEnum => {
    if (loading) {
      return AppointmentPageEnum.Loading;
    }

    switch (consultation?.state) {
      case ConsultationStateEnum.Started:
      case ConsultationStateEnum.Ended:
      case ConsultationStateEnum.Scheduled:
      case ConsultationStateEnum.PendingPayment:
        switch (consultation.type) {
          case ConsultationTypeEnum.HOME_VISIT:
            return AppointmentPageEnum.HomeVisitDetails;
          case ConsultationTypeEnum.ON_DEMAND:
          case ConsultationTypeEnum.SCHEDULED_APPOINTMENT:
            return getCallType();
          default:
            return AppointmentPageEnum.NotFound;
        }

      case ConsultationStateEnum.Submitted:
        return AppointmentPageEnum.Details;
      default:
        if (loading) return AppointmentPageEnum.Loading;
        else return AppointmentPageEnum.NotFound;
    }
  }, [consultation, loading, estimatedWait]);

  const pageComponent = useMemo(() => {
    switch (pageState) {
      case AppointmentPageEnum.Loading:
        return <ConsultationLoading />;
      case AppointmentPageEnum.InConsultation_Call:
        return (
          <DoctorAppointmentShowContent fluid>
            <DoctorCall />
          </DoctorAppointmentShowContent>
        );
      case AppointmentPageEnum.InConsultation_Chat:
        return (
          <DoctorAppointmentShowContent fluid>
            <AppointmentChatProvider consultation={consultation}>
              <DoctorChat />
            </AppointmentChatProvider>
          </DoctorAppointmentShowContent>
        );
      case AppointmentPageEnum.Details:
        return <DoctorAppointmentShowContent />;
      case AppointmentPageEnum.HomeVisitDetails:
        return (
          <DoctorAppointmentShowContent fluid>
            <DoctorAppointmentExtraDetails />
          </DoctorAppointmentShowContent>
        );
      case AppointmentPageEnum.NotFound:
        return <ConsultationNotFound />;
    }
  }, [pageState]);

  const mobileContent = () =>
    pageState === AppointmentPageEnum.InConsultation_Call ||
    pageState === AppointmentPageEnum.InConsultation_Chat ||
    !pageComponent ? (
      <Tab.Navigator
        screenOptions={{ swipeEnabled: true }}
        tabBar={() => null}
        initialRouteName={isTimeForConsultation ? 'consultation' : 'details'}>
        {pageState === AppointmentPageEnum.InConsultation_Call &&
        (consultation.state === ConsultationStateEnum.PendingPayment ||
          consultation.state === ConsultationStateEnum.Scheduled ||
          isTimeForConsultation) ? (
          <Tab.Screen name="consultation" component={DoctorCall} />
        ) : pageState === AppointmentPageEnum.InConsultation_Chat ? (
          <Tab.Screen name="consultation" component={DoctorChatWithContext} />
        ) : null}
        <Tab.Screen name="details" component={DoctorAppointmentShowContent} />
      </Tab.Navigator>
    ) : (
      pageComponent
    );

  const content = isMobile ? mobileContent() : pageComponent;

  return <PrivateNoteDataProvider consultation={consultation}>{content}</PrivateNoteDataProvider>;
};

const Tab = createMaterialTopTabNavigator();

export const DoctorAppointmentShowPage: React.FC<Props> = ({ route }) => {
  const consultationId = Number(route.params.consultationId);
  return (
    <AppointmentProvider consultationId={consultationId}>
      <AppointmentShowWithNavigation />
    </AppointmentProvider>
  );
};
