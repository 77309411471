import React from 'react';

import { MobileMenuPageComponent } from '~/components/navigation/MobileMenuPageComponent';
import { TitleLayout } from '~/layouts/TitleLayout';
import { colors } from '~/utils/colors';
import { screenName } from '~/utils/screenName';

const menu = [
  {
    title: 'Pharmacy account',
    link: { screen: screenName.PharmacyDetailsAccount },
  },
  {
    title: 'Location management',
    link: { screen: screenName.PharmacyDetailsLocation },
  },
  {
    title: 'User management',
    link: { screen: screenName.PharmacyUserManagement },
  },
];

export const PharmacySettingsMobileScreen: React.FC = () => {
  return (
    <TitleLayout title="Pharmacy settings" style={{ backgroundColor: colors.lightPurple }}>
      <MobileMenuPageComponent menu={menu} />
    </TitleLayout>
  );
};
