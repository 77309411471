import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { TextInput } from 'react-native-paper';

import { MultiSelectDropdown } from './MultiSelectDropdown';
import { DropdownProps } from './interfaces';
import FloatingInput from '../FloatingInput';

import { FormErrorMessage } from '~/components/commonText';
import { DownArrow } from '~/components/svgImages';
import { colors } from '~/utils/colors';
import { isArray } from '~/utils/diffUtil';

export const CustomDropdown = forwardRef<TouchableOpacity, DropdownProps>((props, ref) => {
  const { list, error, setValue, value, label, placeholder, multiSelect, errorMessage, showMandatory, disabled } =
    props;

  const containerRef = useRef<HTMLDivElement>();
  const [selectFocused, setSelectFocused] = useState(false);

  const displayValue = useMemo(() => {
    const valueStr = value?.toString();
    return list?.find((item) => item.value?.toString() === valueStr)?.label || (selectFocused ? ' ' : '');
  }, [value, list, selectFocused]);

  const formattedValue = useMemo(() => {
    if (isArray(value)) {
      return (value as []).map((v) => `${v}`);
    }
    return (value as string | number) ?? undefined;
  }, [value]);

  const valueIsNumber = useMemo(() => {
    const first = list?.length ? list[0] : undefined;
    if (!first) return false;
    return typeof first.value === 'number';
  }, [list]);

  useEffect(() => {
    if (containerRef.current) {
      const inputs = containerRef.current.getElementsByTagName('input');
      if (inputs.length) {
        inputs.item(0).setAttribute('tabIndex', '-1');
      }

      const buttons = containerRef.current.querySelectorAll('[role=button]');
      buttons.forEach((button) => button.setAttribute('tabIndex', '-1'));
    }
  }, []);

  if (multiSelect) {
    return <MultiSelectDropdown {...props} />;
  }

  return (
    <View>
      <div className={`dropdown-container ${selectFocused ? 'focused' : ''}`} ref={containerRef}>
        <FloatingInput
          value={displayValue}
          label={label || ' '}
          error={error}
          showMandatory={showMandatory}
          disabled={disabled}
          style={{ paddingTop: 0 }}
          pointerEvents="none"
          right={
            <TextInput.Icon
              icon={({ color }) => <DownArrow width={12} height={12} color={color} />}
              disabled={disabled}
              style={{ backgroundColor: colors.transparent }}
            />
          }
        />

        {disabled ? null : (
          <select
            onFocus={() => setSelectFocused(true)}
            onBlur={() => setSelectFocused(false)}
            className={`dropdown ${value ? 'filled' : ''}`}
            value={formattedValue}
            onChange={(ev) => {
              setValue(valueIsNumber ? Number(ev.target.value) : ev.target.value);
            }}>
            <option disabled={!!value}>{placeholder ?? label}</option>
            {list.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        )}
      </div>
      {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}
    </View>
  );
});
