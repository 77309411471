import React from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { connect } from 'react-redux';

import { OnDemandAvailabilitiesMobile } from './OnDemandAvailabilitiesMobile';
import { OngoingAppointments } from './OngoingAppointments';
import { DashboardMobileHeader } from '../../components/dashboard/doctor/DashboardHeader/DashboardMobileHeader';
import { colors } from '../../utils/colors';
import { DoctorTodayAppointmentsMobile } from '../doctor/calendar/appointments/DoctorTodayAppointmentsMobile';

import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { OnDemandAvailabilityForm } from '~/api/models/preferences/models/OnDemandAvailabilityFormModel';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { flex1, mb25 } from '~/common/commonStyles';
import { mobileContentPadding } from '~/common/mobileStyles';
import { Gradient } from '~/components/common/Gradient';
import { IncomeCard } from '~/components/dashboard/doctor/IncomeCard';
import { RequestsListMobile } from '~/components/dashboard/doctor/RequestsListMobile';
import { RootState } from '~/redux/reducers';
import { DoctorClinicInfo } from '~/components/dashboard/doctor/DoctorClinicInfo';

const mapStateToProps = (state: RootState) => ({
  userDetails: state.userDetailsReducer.userDetails,
  consultationDetails: state.consultationDetailsReducer.consultationDetails,
});
export interface OnDemandAvailabilitiesRef {
  submit: (onValid: (value: OnDemandAvailabilityForm) => void, onInvalid?: () => void) => () => Promise<void>;
}

const DoctorDashboard: React.FC = () => {
  const { hasFeatureAccess } = useFeatureAccess();

  const incomeAccess = hasFeatureAccess(FeatureAccessEnum.INCOME);
  return (
    <View style={styles.container}>
      <DashboardMobileHeader />
      <ScrollView style={flex1} contentContainerStyle={mobileContentPadding}>
        <DoctorClinicInfo style={[mb25, { marginHorizontal: -10 }]} />
        <OngoingAppointments style={{ marginBottom: 40 }} includeEnded />
        <DoctorTodayAppointmentsMobile />
        <RequestsListMobile style={{ marginBottom: 40 }} />
        {incomeAccess ? (
          <View>
            <IncomeCard />
          </View>
        ) : null}
        <OnDemandAvailabilitiesMobile />
      </ScrollView>
      <Gradient />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.white,
  },
  divider: {
    borderWidth: 0.2,
    borderColor: colors.lightPurple,
  },
});

//make this component available to the app
export default connect(mapStateToProps)(DoctorDashboard);
