import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { PrescriptionRequestStatusMapping } from './constants/PrescriptionRequestStatusMapping';
import {
  ExtraSmallNsRegularBlack,
  SmallNsRegularBlack,
  SmallNsSemiBoldBlack,
  SmallTtmSemiboldTheme,
} from '../commonText';
import { StatusBadge } from '../misc/StatusBadge';

import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { PrescriptionDispenseHistoryModel } from '~/api/models/pharmacy/models/PrescriptionDispenseHistoryModel';
import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import { mb10, textCenter } from '~/common/commonStyles';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props {
  requestHistory?: PrescriptionRequestModel;
  prescriptionValue?: PrescriptionModel;
  dispenseHistory?: PrescriptionDispenseHistoryModel;
  dispenseNowButton?: boolean;
  actionIcons?: React.ReactNode;
}

export const PrescriptionRecordMobile: React.FC<Props> = ({
  prescriptionValue,
  dispenseHistory,
  dispenseNowButton,
  requestHistory,
  actionIcons,
}) => {
  const prescription = useMemo(
    () => prescriptionValue ?? dispenseHistory?.prescription,
    [prescriptionValue, dispenseHistory]
  );

  const createdAt = useMemo(() => {
    if (prescriptionValue) return parseDateTime(prescriptionValue.created_at, { outputFormat: DATE_FORMAT });
    if (dispenseHistory) return parseDateTime(dispenseHistory.created_at, { outputFormat: DATE_FORMAT });
  }, [prescriptionValue, dispenseHistory]);
  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <View style={{ flex: 2 }}>
          <SmallNsRegularBlack style={mb10}>{prescription?.reference_number}</SmallNsRegularBlack>
          <SmallNsSemiBoldBlack numberOfLines={3}>
            {extractClinicalTermDescription(prescription?.product)}
          </SmallNsSemiBoldBlack>
        </View>
        {requestHistory ? (
          <View style={{ flex: 1 }}>
            <View style={{ display: 'flex', alignItems: 'flex-end' }}>
              <StatusBadge
                color={PrescriptionRequestStatusMapping[requestHistory.state]?.color(requestHistory)}
                label={PrescriptionRequestStatusMapping[requestHistory.state]?.label(requestHistory)}
              />
            </View>
          </View>
        ) : prescriptionValue ? (
          <View style={{ flex: 1 }}>
            <View style={styles.button}>
              <SmallTtmSemiboldTheme style={textCenter}>
                {dispenseNowButton ? 'Dispense Now' : 'View'}
              </SmallTtmSemiboldTheme>
            </View>
          </View>
        ) : null}
        {actionIcons ? <View>{actionIcons}</View> : null}
      </View>
      <View style={styles.bottom}>
        <View style={[styles.bottomCell]}>
          <ExtraSmallNsRegularBlack>Patient</ExtraSmallNsRegularBlack>
          <SmallNsSemiBoldBlack>{getAccountName(prescription?.patient)}</SmallNsSemiBoldBlack>
        </View>
        {prescriptionValue ? (
          <View style={[styles.bottomCell, styles.bottomMiddleCell]}>
            <ExtraSmallNsRegularBlack>Prescribed by</ExtraSmallNsRegularBlack>
            <SmallNsSemiBoldBlack>{getAccountName(prescription.doctor)}</SmallNsSemiBoldBlack>
          </View>
        ) : (
          <View style={[styles.bottomCell, styles.bottomMiddleCell]}>
            <ExtraSmallNsRegularBlack>Dispensed at</ExtraSmallNsRegularBlack>
            <SmallNsSemiBoldBlack>{createdAt}</SmallNsSemiBoldBlack>
          </View>
        )}
        {prescriptionValue ? (
          <View style={[styles.bottomCell]}>
            <ExtraSmallNsRegularBlack>Created at</ExtraSmallNsRegularBlack>
            <SmallNsSemiBoldBlack>{createdAt}</SmallNsSemiBoldBlack>
          </View>
        ) : (
          <View style={[styles.bottomCell]}>
            <ExtraSmallNsRegularBlack>Dispensed by</ExtraSmallNsRegularBlack>
            <SmallNsSemiBoldBlack>{getAccountName(dispenseHistory.dispensed_by) || '-'}</SmallNsSemiBoldBlack>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'space-between',
  },
  bottom: {
    borderTopWidth: 1,
    borderTopColor: colors.lightPurple2,
    display: 'flex',
    flexDirection: 'row',
  },
  bottomCell: {
    flex: 1,
    padding: 10,
  },
  bottomMiddleCell: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: colors.lightPurple2,
  },
  button: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.purple,
    paddingHorizontal: 12,
    paddingVertical: 2,
    minHeight: 40,
    justifyContent: 'center',
  },
});
