import GoogleMapReact from 'google-map-react';
import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { DefaultLatLng } from './contants/defaultMapValues';
import { LatLng } from './interfaces/LatLng';
import { MapViewRef } from './interfaces/MapViewRef';
import { Region } from './interfaces/Region';

interface Props {
  children?: React.ReactElement | React.ReactElement[];
  style?: StyleProp<ViewStyle>;
  apiKey?: string;
  region?: Region;
  height: number | string;
  width: number | string;
  onPress?: (latLng: LatLng) => void;
  onLongPress?: (latLng: LatLng) => void;
}

interface IMapContext {
  map?: google.maps.Map;
  maps?: google.maps.MapsLibrary;
}

const sizeToStringConversion = (value: string | number) => (typeof value === 'number' ? `${value}px` : value);

const MapViewContext = React.createContext<IMapContext>({} as IMapContext);
export const useMapViewContext = (): IMapContext => useContext(MapViewContext);

export const GoogleMapView = forwardRef<MapViewRef, Props>(
  ({ children, style, height, width, region, apiKey }, ref) => {
    const [contextValue, setContextValue] = useState<IMapContext>({});

    const handleApiLoaded = (mapValues: { map: google.maps.Map; maps: google.maps.MapsLibrary }) =>
      setContextValue(mapValues);

    useImperativeHandle(ref, () => ({
      addressForCoordinate: (coordinate: LatLng) => {
        return fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinate.lat},${coordinate.lng}&key=${apiKey}`
        )
          .then((res) => res.json())
          .then((res: { results?: google.maps.places.PlaceResult[]; status: string; error_message?: string }) => {
            if (res.status !== 'OK') {
              throw new Error(res.error_message || 'Something went wrong while retrieving your current address');
            }

            if (!res.results?.length) return {};

            const firstResult = res.results[0];

            return {
              formatted_address: firstResult.formatted_address,
              locality: firstResult.address_components?.find((component) => component.types.includes('locality'))
                ?.long_name,
              country: firstResult.address_components?.find((component) => component.types.includes('country'))
                ?.long_name,
            };
          });
      },
    }));

    return (
      <View style={style}>
        <div style={{ width: sizeToStringConversion(width), height: sizeToStringConversion(height) }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: apiKey }}
            defaultCenter={region ? { lat: region?.latitude, lng: region?.longitude } : DefaultLatLng}
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={handleApiLoaded}>
            <MapViewContext.Provider value={contextValue}>{children}</MapViewContext.Provider>
          </GoogleMapReact>
        </div>
      </View>
    );
  }
);

export { Circle } from './webComponents/Circle';
export { Marker } from './webComponents/Marker';
