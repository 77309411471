import React from 'react';

import { PrescriptionModalDispenseHistory } from './PrescriptionModalDispenseHistory';

import { PatientPrescriptionCollectionsResponse } from '~/api/models/pharmacy/responses/PrescriptionCollectionsResponse';
import { isPatientVersion } from '~/utils/buildConfig';

interface Props {
  collections: PatientPrescriptionCollectionsResponse;
}

export const PatientPrescriptionCollectionsComponent: React.FC<Props> = ({ collections }) => {
  if (!isPatientVersion()) return null;
  return <PrescriptionModalDispenseHistory collections={collections} />;
};
