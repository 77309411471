import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../reducers';
import { ADD_TO_QUEUE, REMOVE_FROM_QUEUE } from '../reducers/doctor/approvalRequestsQueueReducer';
import { useAppDispatch } from '../store';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { apiGetConsultation } from '~/api/services/consultations';
import { ApprovalRequestModal } from '~/components/doctor/booking/requestApprovals/ApprovalRequestModal';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';

export function useConsultationQueue() {
  const lastConsultation = useRef<number>();
  const dispatch = useAppDispatch();
  const consultationIds = useSelector(
    (state: RootState) => state.approvalConsultationRequestsQueueReducer.consultationIds
  );

  const { registerModal, openModal, activeModals } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.ApprovalRequest, ApprovalRequestModal);
  }, []);

  useEffect(() => {
    openNextRequest();
  }, [consultationIds, activeModals]);

  const discardFirstRequest = () => {
    dispatch(REMOVE_FROM_QUEUE());
  };

  const openNextRequest = async () => {
    if (consultationIds.length > 0 && activeModals.length === 0) {
      const consultationId = consultationIds[0];
      try {
        const res = await apiGetConsultation({ id: consultationId });

        if (
          res.data?.state === ConsultationStateEnum.RequestSubmitted &&
          (res.data.type === ConsultationTypeEnum.ON_DEMAND || res.data.type === ConsultationTypeEnum.HOME_VISIT)
        ) {
          openModal(ModalName.ApprovalRequest, {
            consultation: res.data,
            onReady: () => {
              setTimeout(() => {
                discardFirstRequest();
              }, 200);
            },
          });
        } else {
          discardFirstRequest();
        }
      } catch {
        discardFirstRequest();
      }
    }
  };

  const addToQueue = async (consultationId: number) => {
    try {
      if (lastConsultation.current === consultationId || consultationIds?.some((id) => id === consultationId)) {
        // TODO: These checks should be in the store, since checking here will not always work due to the async nature of redux
        // Currently the check is handled in both areas
        return;
      } else {
        lastConsultation.current = consultationId;
      }

      dispatch(ADD_TO_QUEUE(consultationId));
    } catch (e) {
      ErrorAlert(e);
    }
  };

  return { addToQueue };
}
