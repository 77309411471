import { View } from 'react-native';

import { IBreakpoints, IDeviceSizes, IGridOptions } from './types';

export const BreakpointDefaults: IBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const GutterDefaults: IBreakpoints = {
  xs: 30,
  sm: 30,
  md: 30,
  lg: 30,
  xl: 30,
};

export const MaxContainerWidthsDefaults: IBreakpoints = {
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
};

export const GridDefaults: IGridOptions = {
  breakpoints: BreakpointDefaults,
  columns: 12,
  component: View,
  gutters: GutterDefaults,
  maxContainerWidths: MaxContainerWidthsDefaults,
  noOuterGutter: true,
};

export const DeviceSizeDefaults: IDeviceSizes = {
  mobile: BreakpointDefaults.sm as number,
  tablet: BreakpointDefaults.lg as number,
};
