import { IdNameModel } from '~/api/models/common/models/IdNameModel';

export function arrayDiff<T>(oldArray: T[], newArray: T[]) {
  if (!oldArray?.length) return { added: [...newArray], removed: [] };
  if (!newArray?.length) return { added: [], removed: [...oldArray] };

  return {
    added: newArray.filter((newItem) => !oldArray.find((oldItem) => oldItem === newItem)),
    removed: oldArray.filter((oldItem) => !newArray.includes(oldItem)),
  };
}

export function arrayToObject<T = any>(arr: T[] | undefined | null, key: string): Record<string, T> {
  return (
    arr?.reduce((prev, value) => {
      prev[value[key]] = value;
      return prev;
    }, {}) ?? {}
  );
}

export const idNameArrayToString = (specialisations: IdNameModel[], max?: number) => {
  if (!specialisations?.length) return '';

  const overMax = !!max && specialisations.length > max;
  const joinedValues = (overMax ? specialisations.slice(0, max - 1) : specialisations).map((s) => s.name)?.join(', ');

  if (overMax) {
    return `${joinedValues} and ${specialisations.length - max} more`;
  }
  return joinedValues;
};

export function paginatedObjectToArray<T = any>(obj: Record<string | number, T[]>) {
  return Object.keys(obj ?? {})
    .sort((a, b) => +a - +b)
    .reduce<T[]>((prev, page) => {
      prev.push(...obj[page]);
      return prev;
    }, []);
}
