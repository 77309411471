import { get, post } from '~/api/axios';
import { IdModel } from '~/api/models/common/models/IdModel';
import { EndConsultationRequest } from '~/api/models/consultations/requests/EndConsultationRequest';
import { GetOnDemandDoctorsQueueRequest } from '~/api/models/consultations/requests/GetOnDemandDoctorsQueueRequest';
import { ConsultationWaitTimeResponse } from '~/api/models/consultations/responses/ConsultationWaitTimeResponse';
import { GetOnDemandDoctorsQueueResponse } from '~/api/models/consultations/responses/GetOnDemandDoctorsQueueResponse';

export const apiGetEstimatedWaitTime = (req: IdModel) => {
  return get<ConsultationWaitTimeResponse>(`/consultations/${req.id}/estimate-start-time`);
};

export const apiStartConsultation = (req: IdModel) => {
  return post(`consultations/${req.id}/start`);
};

export const apiEndConsultation = (req: EndConsultationRequest) => {
  return post(`consultations/${req.consultationId}/end`, req.params);
};

export const apiGetOnDemandDoctorsQueue = (req: GetOnDemandDoctorsQueueRequest) => {
  return get<GetOnDemandDoctorsQueueResponse>('/patient/available-doctors/on-demand/waiting-room/status', req);
};
