import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DashboardStatsResponse } from '~/api/models/dashboard/responses/DashboardStatsResponse';
import { apiGetPharmacyDashboardStats } from '~/api/services/dashboard';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { usePageFocus } from '~/utils/hooks/FocusHook';

export const usePharmacyDashboardStats = () => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<DashboardStatsResponse>([{}, {}, {}]);
  const currentPharmacy = useSelector(currentPharmacySelector);

  const getStats = () => {
    apiGetPharmacyDashboardStats({ id: currentPharmacy.pharmacy.id })
      .then((res) => setStats(res.data))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const removeRequestListener = AppEventHandler.addListener(AppEvents.PHARMACY_REQUESTS_UPDATE, getStats);
    const removeDispensedListener = AppEventHandler.addListener(AppEvents.PHARMACY_DISPENSED_UPDATE, getStats);
    return () => {
      if (removeDispensedListener) removeDispensedListener();
      if (removeRequestListener) removeRequestListener();
    };
  }, []);

  usePageFocus(() => {
    if (currentPharmacy) {
      setLoading(true);
      getStats();
    }
  }, [currentPharmacy?.pharmacy.id]);

  return { stats, loading };
};
