import { post } from '../../axios';
import { SendOtpRequest } from '../../models/otp/requests/SendOtpRequest';

import { SendOtpMobileUpdateRequest } from '~/api/models/otp/requests/SendOtpMobileUpdateRequest';
import { SendOtpUpdateRequest } from '~/api/models/otp/requests/SendOtpUpdateRequest';

export const apiSendOtp = (data: SendOtpRequest) => {
  return post('/otp/send', data);
};

export const apiSendOtpEmailUpdate = (data: SendOtpUpdateRequest) => {
  return post('/otp/send/update', data);
};

export const apiSendOtpMobileUpdate = (data: SendOtpMobileUpdateRequest) => {
  return post('/otp/send/update', data);
};
