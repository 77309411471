import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { DataTableProps } from './DataTableTypes';
import { CommonPagination } from '../../commonPagination';

import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  page: number;
  totalCount: number;
  numberOfItemsPerPage?: number;
  onPageChanged: DataTableProps['onPageChanged'];
  siblings?: number;
  alwaysShow?: boolean;
}

export const Pagination: React.FC<Props> = ({
  page,
  totalCount,
  numberOfItemsPerPage,
  onPageChanged,
  siblings = 2,
  alwaysShow,
}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const totalPages = Math.ceil(totalCount / numberOfItemsPerPage);
  const handlePages = (updatePage: number) => {
    setCurrentPage(updatePage);
    onPageChanged({ page: updatePage, limit: numberOfItemsPerPage });
  };
  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const { isMobile } = useBreakpoints();
  if (Number.isNaN(totalPages) || totalPages === 1) return null;
  return (
    <View style={[styles.container, isMobile ? null : { marginVertical: 20 }]}>
      <CommonPagination
        page={currentPage}
        siblings={siblings}
        totalPages={totalPages}
        handlePagination={handlePages}
        alwaysShow={alwaysShow}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignSelf: 'center',
  },
});
