import { Route } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import React, { useState } from 'react';
import { View } from 'react-native';

import { useDependantLinkedAccounts } from '~/api/hooks/dependants/DependantHook';
import { DependantModel } from '~/api/models/dependants/models/DependantModel';
import { flex1, mb10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { DependantCard } from '~/components/patient/dependants/components/DependantCard';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

interface Props {
  route: Route<string, { id: string }>;
}

export const DependantLinkedAccounts: React.FC<Props> = ({ route }) => {
  const [columnCount, setColumnCount] = useState(null);
  const { isMobile } = useBreakpoints();

  const { linkedAccounts, loading } = useDependantLinkedAccounts({ id: route.params?.id });

  return (
    <PageLayout
      hideBack={!isMobile}
      back={{ to: { screen: screenName.DependantsShow, params: route.params } }}
      scrollContentNode={
        <View
          style={flex1}
          onLayout={(event) => {
            const { width } = event.nativeEvent.layout;
            setColumnCount(Math.max(1, Math.floor(width / 300)));
          }}>
          {loading ? (
            <View>
              <LoadingActivityIndicator />
            </View>
          ) : (
            <FlashList<DependantModel>
              ListHeaderComponent={
                <View style={[mb10, { paddingHorizontal: isMobile ? MOBILE_HORIZONTAL_SPACE : 0 }]}>
                  <H3TtmSemiBoldBlack>{labels.linkedAccounts}</H3TtmSemiBoldBlack>
                </View>
              }
              data={linkedAccounts}
              keyExtractor={(item) => `${item.id}`}
              numColumns={columnCount}
              renderItem={({ item }) => (
                <View style={{ paddingHorizontal: isMobile ? MOBILE_HORIZONTAL_SPACE : 0 }}>
                  <DependantCard dependant={item} vertical={columnCount === 1} />
                </View>
              )}
              estimatedItemSize={100}
            />
          )}
        </View>
      }
    />
  );
};
