import React from 'react';

import { DocumentExportList } from '~/components/doctor/document-management/DocumentExportList';
import { RequestsLayout } from '~/layouts/RequestsLayout';
import { isPharmacyVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const DocumentExports: React.FC = () => {
  const { isMobile } = useBreakpoints();

  return (
    <RequestsLayout hideTabs={isPharmacyVersion() && isMobile}>
      <DocumentExportList />
    </RequestsLayout>
  );
};
