import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { HomeVisitApprovalRequestButtons } from './homeVisit/HomeVisitApprovalRequestButtons';
import { HomeVisitApprovalRequestDetails } from './homeVisit/HomeVisitApprovalRequestDetails';
import { OnDemandApprovalRequestButtons } from './onDemand/OnDemandApprovalRequestButtons';
import { OnDemandApprovalRequestDetails } from './onDemand/OnDemandApprovalRequestDetails';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiGetConsultation } from '~/api/services/consultations';
import { ExpiredConsultationRequestError } from '~/classes/errors/MissingConsultationError';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalContainer } from '~/components/modals/ModalContainer';
import { BaseModalProps } from '~/components/modals/interfaces/ModalProps';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useForm } from 'react-hook-form';
import { RequestApprovalForm } from '~/api/models/consultations/models/RequestApprovalForm';

interface Props extends BaseModalProps {
  consultation: ConsultationModel;
  onReady: (approved?: boolean) => void;
  onHide: () => void;
}

const DEFAULT_FORM: RequestApprovalForm = {
  availabilitySlot: null,
  expectedDeparture: null,
};

export const ApprovalRequestModal: React.FC<Props> = ({ consultation: notificationConsultation, onReady, onHide }) => {
  const [consultation, setConsultation] = useState<ConsultationModel>();
  const [approved, setApproved] = useState<boolean>(null);
  const { isMobile } = useBreakpoints();
  const { control, watch, getValues, handleSubmit } = useForm<RequestApprovalForm>({
    defaultValues: { ...DEFAULT_FORM },
  });

  useEffect(() => {
    if (!notificationConsultation?.id || consultation) return;

    apiGetConsultation({ id: notificationConsultation.id })
      .then((res) => setConsultation(res.data))
      .catch(() => {
        ErrorAlert(new ExpiredConsultationRequestError(notificationConsultation.id));

        onHide();
      });
  }, [notificationConsultation?.id]);

  const handleReady = (approved?: boolean) => {
    if (onReady) onReady(approved);
    AppEventHandler.emit(AppEvents.DOCTOR_APPOINTMENTS_CHANGED);
    onHide();
  };

  const handleIgnore = () => {
    if (onReady) onReady();
    onHide();
  };

  const isOnDemand = consultation?.type === ConsultationTypeEnum.ON_DEMAND;
  const isHomeVisit = consultation?.type === ConsultationTypeEnum.HOME_VISIT;
  const spacing = isMobile ? MOBILE_HORIZONTAL_SPACE : 0;

  const availabilitySlotSelected = !!watch('availabilitySlot');
  const departureSlotSelected = !!watch('expectedDeparture');

  return (
    <ModalContainer
      onHide={onHide}
      hideOnBackground={isHomeVisit}
      showInfoLine
      fullScreen={isMobile}
      edge={isMobile}
      padding="md"
      buttons={[
        <View style={{ paddingHorizontal: spacing, paddingBottom: MOBILE_HORIZONTAL_SPACE }}>
          {isOnDemand ? (
            <OnDemandApprovalRequestButtons consultation={consultation} onReady={handleReady} />
          ) : isHomeVisit ? (
            <HomeVisitApprovalRequestButtons
              consultation={consultation}
              handleSubmit={handleSubmit}
              onReady={handleReady}
              onIgnore={handleIgnore}
              onApprovedChanged={setApproved}
              availabilitySlotSelected={availabilitySlotSelected}
              departureSlotSelected={departureSlotSelected}
            />
          ) : null}
        </View>,
      ]}>
      <View style={[{ paddingHorizontal: spacing, paddingTop: MOBILE_HORIZONTAL_SPACE }]}>
        {isHomeVisit ? (
          <HomeVisitApprovalRequestDetails
            consultation={consultation}
            approved={approved}
            control={control}
            watch={watch}
          />
        ) : isOnDemand ? (
          <OnDemandApprovalRequestDetails consultation={consultation} />
        ) : null}
      </View>
    </ModalContainer>
  );
};
