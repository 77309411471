import { useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { StyleProp, ViewStyle, View, TouchableOpacity, StyleSheet } from 'react-native';

import { H3TtmSemiBoldBlack, H3TtmSemiBoldTheme } from '../commonText';
import { BackArrow } from '../svgImages';

import { mh10, mr0 } from '~/common/commonStyles';
import { INavigate } from '~/common/types/navigation/navigate';
import { NavType } from '~/navigation/types';
import { isNative } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

interface Props {
  onPress?: CallableFunction;
  backLink?: INavigate;
  title?: string;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<ViewStyle>;
  black?: boolean;
  hideArrow?: boolean;
}
export const BackNavigation = (props: Props) => {
  const navigation = useNavigation<NavType>();
  const title = useMemo(
    () =>
      props.onPress || props.backLink || !navigation.canGoBack()
        ? props.title ?? labels.back.toUpperCase()
        : labels.back.toUpperCase(),
    [props.onPress, props.title]
  );
  return (
    <View style={[props.style]}>
      <TouchableOpacity
        style={[styles.rowSpaced, styles.backButton]}
        onPress={() => {
          if (props.onPress) props.onPress();
          else if (navigation.canGoBack() && isNative()) navigation.goBack();
          else if (props.backLink?.screen)
            navigation.navigate(props.backLink.screen as never, props.backLink?.params as never);
          else if (navigation.canGoBack()) navigation.goBack();
        }}>
        {!props.hideArrow ? <BackArrow width={15} height={15} color={colors.purple} /> : null}
        <View style={props.titleStyle}>
          {props.black ? (
            <H3TtmSemiBoldBlack style={[mh10, mr0]}>{title}</H3TtmSemiBoldBlack>
          ) : (
            <H3TtmSemiBoldTheme style={[mh10, mr0]}>{title}</H3TtmSemiBoldTheme>
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  rowSpaced: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  backButton: {
    minHeight: 45,
    marginBottom: 5,
  },
});
