import { DocumentPickerAsset } from 'expo-document-picker';
import { useEffect, useState } from 'react';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { MediaWithStatusModel } from '~/api/models/common/models/MediaWithStatusModel';
import {
  apiAddReferralPdf,
  apiGetReferralGeneratedPDFs,
  apiGetReferralMedia,
  apiRemoveReferralMedia,
} from '~/api/services/consultations/referral';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { isDoctorVersion, isPatientVersion } from '~/utils/buildConfig';

export function useReferralMedia() {
  const [loading, setLoading] = useState(false);
  const [referralMediaFiles, setUploadedFiles] = useState<MediaModel[]>([]);
  const [generatedFiles, setGeneratedFiles] = useState<MediaWithStatusModel>();

  const getFiles = (referralId: number) => {
    if (isDoctorVersion() || isPatientVersion()) {
      setLoading(true);
      return apiGetReferralMedia({ id: referralId })
        .then((response) => {
          const mediaFiles = response.data.data.reduce<MediaModel[]>((previous, data) => {
            if (data.media?.length) previous.push(...data.media);
            return previous;
          }, []);
          setUploadedFiles(mediaFiles);
        })
        .finally(() => setLoading(false));
    }
  };

  const getReferralsMedia = async (referralId: number) => {
    setLoading(true);
    if (referralId) {
      await apiGetReferralGeneratedPDFs({ id: referralId })
        .then((res) => {
          setGeneratedFiles(res.data);
        })
        .catch(ErrorAlert);
    } else {
      setGeneratedFiles(null);
    }
    setLoading(false);
  };

  const addFile = async (props: {
    referral_id: number;
    document: DocumentPickerAsset;
    referral_media_type: string;
  }) => {
    if (props.document) {
      await apiAddReferralPdf(props);
      await getFiles(props.referral_id);
    }
  };

  const removeFile = async (props: { document: MediaModel; referral_id: number }) => {
    await apiRemoveReferralMedia({ referral_id: props.referral_id, media_ids: [props.document.uuid] });
    const index = referralMediaFiles.findIndex((media) => media.uuid === props.document.uuid);
    const uploadedFiles = [...referralMediaFiles];
    uploadedFiles.splice(index, 1);
    setUploadedFiles(uploadedFiles);
  };

  return {
    loading,
    referralMediaFiles,
    generatedFiles,
    getReferralsMedia,
    addFile,
    removeFile,
    getFiles,
  };
}

export function useInitialisedReferralMedia({ referralId }: { referralId: number }) {
  const hook = useReferralMedia();

  useEffect(() => {
    if (referralId) hook.getReferralsMedia(referralId);
  }, [referralId]);

  return { ...hook };
}
