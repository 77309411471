import { AuthVerificationEnum } from '../../authorisation/constants/AuthVerificationEnum';

export enum PharmacyStateEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  DISABLED = 'disabled',
}

export function mapPharmacyStateToAuthVerification(
  pharmacyState?: PharmacyStateEnum
): AuthVerificationEnum | undefined {
  if (pharmacyState === undefined) {
    return undefined;
  }

  switch (pharmacyState) {
    case PharmacyStateEnum.APPROVED:
      return AuthVerificationEnum.APPROVED;
    case PharmacyStateEnum.PENDING:
      return AuthVerificationEnum.PENDING_ADMIN;
    case PharmacyStateEnum.REJECTED:
      return AuthVerificationEnum.REJECTED;
    case PharmacyStateEnum.DISABLED:
      return AuthVerificationEnum.DISABLED;
    default:
      return undefined;
  }
}
