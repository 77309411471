import React from 'react';

import { DocumentImportList } from '~/components/doctor/document-management/DocumentImportList';
import { RequestsLayout } from '~/layouts/RequestsLayout';

export const DocumentImports: React.FC = () => {
  return (
    <RequestsLayout>
      <DocumentImportList />
    </RequestsLayout>
  );
};
