import EventEmitter from 'eventemitter3';

import { NotificationCodeEnum } from './enums/NotificationCodeEnum';
import { NotificationTypeEnum } from './enums/NotificationTypeEnum';
import { DigimedNotificationEventPayload } from './interfaces/DigimedNotificationEventPayload';

import { HAS_UNREAD_NOTIFICATIONS } from '~/redux/reducers/userDetailsReducer';
import store from '~/redux/store';

class PushNotifications {
  private static _instance: PushNotifications;

  private eventEmitter: EventEmitter;

  private constructor() {
    this.eventEmitter = new EventEmitter();
  }

  public static get instance() {
    if (!this._instance) {
      this._instance = new PushNotifications();
    }
    return this._instance;
  }

  public addNotificationCodeListener(
    notificationCode: NotificationCodeEnum,
    method: (payload: DigimedNotificationEventPayload) => void
  ) {
    this.eventEmitter.addListener(`code:${notificationCode}`, method);

    return () => {
      this.eventEmitter.removeListener(`code:${notificationCode}`, method);
    };
  }

  public addNotificationTypeListener(
    notificationType: NotificationTypeEnum,
    method: (payload: DigimedNotificationEventPayload) => void
  ) {
    this.eventEmitter.addListener(`type:${notificationType}`, method);

    return () => {
      this.eventEmitter.removeListener(`type:${notificationType}`, method);
    };
  }

  public addNotificationListener(method: (payload: DigimedNotificationEventPayload) => void) {
    this.eventEmitter.addListener(`notification`, method);

    return () => {
      this.eventEmitter.removeListener(`notification`, method);
    };
  }

  public emit(payload: DigimedNotificationEventPayload) {
    this.eventEmitter.emit(`notification`, payload);
    this.eventEmitter.emit(`code:${payload.userInfo.data.code}`, payload);
    this.eventEmitter.emit(`type:${payload.userInfo.data.type}`, payload);

    store.dispatch(HAS_UNREAD_NOTIFICATIONS(true));
  }
}

export default PushNotifications.instance;
