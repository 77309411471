import isEqual from 'react-fast-compare';

import {
  ChangeOptions,
  ChangeResultType,
  ChangeReturn,
  ChangeType,
  ChangeTypeObject,
  ChangeTypePrimitive,
} from './types';
import isNullOrUndefined from '../types/isNullOrUndefined';

const DefaultChangeOptions: ChangeOptions = {
  key: 'id',
};

export const isPrimitive = (x: any): x is ChangeTypePrimitive => {
  return typeof x === 'string' || typeof x === 'number';
};

export const isArray = (x) => {
  return Object.prototype.toString.call(x) === '[object Array]';
};

export const isObject = (x) => {
  return Object.prototype.toString.call(x) === '[object Object]';
};

export function getObjectChanges<T extends ChangeTypeObject = ChangeTypeObject>(
  obj1: T,
  obj2: T,
  ignoreKeys?: string[]
) {
  const changes: Partial<T> = {};
  const removed: Partial<T> = {};

  for (const key in obj2) {
    if (!ignoreKeys || !ignoreKeys.includes(key)) {
      const value2 = obj2[key];
      const value1 = obj1[key];

      if (isNullOrUndefined(value1) || !isEqual(value1, value2)) {
        changes[key] = value2;
      }
    }
  }

  for (const key in obj1) {
    if (!ignoreKeys || !ignoreKeys.includes(key)) {
      const value2 = obj2[key];

      if (isNullOrUndefined(value2) && !isNullOrUndefined(obj1[key])) {
        removed[key] = obj1[key];
      }
    }
  }
  return {
    changes: Object.keys(changes).length ? changes : undefined,
    removed: Object.keys(removed).length ? removed : undefined,
  };
}

export function compareObject<T extends ChangeTypeObject = ChangeTypeObject>(
  obj1: T | ChangeTypePrimitive,
  obj2: T | ChangeTypePrimitive,
  options?: ChangeOptions<T>
): ChangeReturn<T> {
  if (!isEqual(obj1, obj2) && !isPrimitive(obj1) && !isPrimitive(obj2)) {
    const localOptions = { ...DefaultChangeOptions, ...options };
    if (isNullOrUndefined(obj1) && isNullOrUndefined(obj2)) {
      return {};
    } else if (isNullOrUndefined(obj1)) {
      // No id found
      return { created: [obj2], change: ChangeResultType.CREATED };
    } else if (localOptions.key in obj1) {
      if (isNullOrUndefined(obj2)) {
        return { deleted: [obj1[localOptions.key]], change: ChangeResultType.DELETED };
      } else if (obj1[localOptions.key] === obj2[localOptions.key]) {
        const changesAndRemoved = getObjectChanges<T>(obj1, obj2, options?.ignoreKeys);

        if (!changesAndRemoved.changes && !changesAndRemoved.removed) return {};

        return {
          updated: {
            [obj1[localOptions.key]]: {
              full: obj2,
              changes: changesAndRemoved.changes,
              removed: changesAndRemoved.removed,
            },
          },
          change: ChangeResultType.UPDATED,
        };
      }
    }
    // else key is not defined in obj1, and we cannot determine if there is a change or are completely different objects
  }
  return {};
}

export function compareObjectsArray<T extends ChangeTypeObject = ChangeTypeObject>(
  arr1: (T | ChangeTypePrimitive)[],
  arr2: (T | ChangeTypePrimitive)[],
  options?: ChangeOptions<T>
): ChangeReturn<T> {
  const localOptions = { ...DefaultChangeOptions, ...options };

  if (isArray(arr1)) {
    if (!isArray(arr2)) throw new Error('Mismatched object types');

    if (arr1.length === 0 && arr2.length === 0) {
      return {};
    }

    const returnValue: ChangeReturn<T> = {};
    let changeResultType: ChangeResultType = undefined;

    const setChangeResultType = (type: ChangeResultType) => {
      if (!changeResultType) changeResultType = type;
      else if (type !== changeResultType) changeResultType = ChangeResultType.MULTIPLE;
    };

    for (const item1 of arr1) {
      const itemId1 = isPrimitive(item1) ? item1 : item1[localOptions.key];
      if (isNullOrUndefined(itemId1)) continue;

      const item2 = arr2.find((item) => (isPrimitive(item) ? item === itemId1 : item[localOptions.key] === itemId1));

      if (isNullOrUndefined(item2)) {
        returnValue.deleted = returnValue.deleted ? [...returnValue.deleted, itemId1] : [itemId1];
        setChangeResultType(ChangeResultType.DELETED);
      } else if (!isPrimitive(item1) && !isPrimitive(item2)) {
        const objectComparison = compareObject(item1, item2, localOptions);
        if (objectComparison.created) {
          returnValue.created = returnValue.created
            ? [...returnValue.created, ...objectComparison.created]
            : objectComparison.created;
          setChangeResultType(ChangeResultType.CREATED);
        }
        if (objectComparison.deleted) {
          returnValue.deleted = returnValue.deleted
            ? [...returnValue.deleted, ...objectComparison.deleted]
            : objectComparison.deleted;
          setChangeResultType(ChangeResultType.DELETED);
        }
        if (objectComparison.updated) {
          returnValue.updated = returnValue.updated
            ? { ...returnValue.updated, ...objectComparison.updated }
            : objectComparison.updated;
          setChangeResultType(ChangeResultType.UPDATED);
        }
      }
    }

    // Find the new ones. Where id is not in arr1 or it does not exist
    for (const item2 of arr2) {
      const itemId2 = isPrimitive(item2) ? item2 : item2[localOptions.key];

      const item1 = !isNullOrUndefined(itemId2)
        ? arr1.find((item) => (isPrimitive(item) ? item === itemId2 : item[localOptions.key] === itemId2))
        : undefined;

      if (!item1) {
        returnValue.created = returnValue.created ? [...returnValue.created, item2] : [item2];
        setChangeResultType(ChangeResultType.CREATED);
      }
    }
    return { ...returnValue, change: changeResultType };
  }
}

export function getChanges<T extends ChangeTypeObject = ChangeTypeObject>(
  obj1: ChangeType<T>,
  obj2: ChangeType<T>,
  options?: ChangeOptions<T>
): ChangeReturn<T> {
  if (isObject(obj1)) {
    return compareObject<T>(obj1 as T, obj2 as T, options);
  } else if (isArray(obj1)) {
    return compareObjectsArray<T>(obj1 as T[], obj2 as T[], options);
  }
  return {};
}
