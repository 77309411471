import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { apiSetMarketingMaterial, apiSubmitEmail } from '../../api/services/registration';
import { Button } from '../../components/commonButton';
import FloatingTextBox from '../../components/floatingInputBox';
import { Checkbox } from '../../components/inputs/Checkbox';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import {
  minLengthValidation,
  requiredValidation,
  validationSchema,
  emailValidation,
} from '../../services/validationConfig';
import { labels } from '../../utils/labels';
import { screenName } from '../../utils/screenName';

import { OtpChannelEnum } from '~/api/models/otp/constants/OtpChannelEnum';
import { apiSendOtp } from '~/api/services/otp';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';

const EnterEmailScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      marketing: false,
    },
  });

  const navigation = useNavigation();

  const createEmailAddress = async (details: { email: string; marketing: boolean }) => {
    try {
      setLoading(true);
      await apiSubmitEmail({ email: details.email });
      await apiSetMarketingMaterial({ receive_marketing_material: details.marketing }).catch(() => {});

      await apiSendOtp({ channel: OtpChannelEnum.EMAIL });

      navigation.navigate(screenName.RegistrationEmailOtp as never);
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  return (
    <EnhancedRegistrationLayout
      title={labels.enterYourPrimaryEmail}
      subtitle={labels.emailScreenSubMessage}
      compressed
      flexContent
      buttons={[<Button funCallback={handleSubmit(createEmailAddress)} disabled={loading} label={labels.continue} />]}>
      <View style={{ width: '100%', flex: 1, justifyContent: 'space-between' }}>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingTextBox
              label={labels.emailAddress}
              showMandatory
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
              maxLength={validationSchema.email.maxLength}
              keyboardType="email"
            />
          )}
          rules={{
            required: requiredValidation(labels.emailAddress),
            validate: { emailValidation },
            minLength: minLengthValidation(validationSchema.email.minLength),
          }}
        />
        <Controller
          name="marketing"
          control={control}
          render={({ field: { value, onChange } }) => (
            <View style={styles.mobileFooter}>
              <Checkbox label={labels.receiveMarketingEmails} checked={value} onChange={onChange} />
            </View>
          )}
        />
      </View>
    </EnhancedRegistrationLayout>
  );
};

// //make this component available to the app
export default EnterEmailScreen;

const styles = StyleSheet.create({
  mobileFooter: {
    flexDirection: 'row',
    paddingVertical: 15,
    marginTop: 20,
  },
});
