import { useIsFocused } from '@react-navigation/native';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { FilteredRequest } from '~/api/models/common/requests/FilteredRequest';
import { PaginatedRequest } from '~/api/models/common/requests/PaginatedRequest';
import { PaginatedResponse } from '~/api/models/common/responses/PaginatedResponse';
import { DEFAULT_TABLE_ITEMS_PER_PAGE } from '~/components/common/DataTable/DataTable';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { RootState } from '~/redux/reducers';
import {
  SET_PAGE,
  UPDATE_PAGE_CURRENT,
  UPDATE_PAGE_DATA,
  infiniteScrollSelector,
  paginatedSelector,
  paginationSelector,
} from '~/redux/reducers/tablePaginationReducer';
import ReduxStore, { useAppDispatch } from '~/redux/store';
import { usePageFocus } from '~/utils/hooks/FocusHook';

interface Props<TFilterRequest, TDataResponse> {
  limit?: number;
  tableName: string;
  isInfiniteScroll?: boolean;
  getItems?: (
    params?: FilteredRequest<TFilterRequest> & PaginatedRequest
  ) => Promise<AxiosResponse<TDataResponse, any>>;
  watch?: any[];
}
export function useReduxTablePagination<TFilterRequest, TDataResponse extends PaginatedResponse<any>>({
  limit,
  tableName,
  isInfiniteScroll,
  getItems,
  watch,
}: Props<TFilterRequest, TDataResponse>) {
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState(true);
  const [pagination, setPaginationLocal] = useState<TablePaginationInfo>({
    page: 0,
    limit: limit || DEFAULT_TABLE_ITEMS_PER_PAGE,
  });

  const setPagination = (info: TablePaginationInfo) => {
    if (!isFocused) return;
    if (info.page !== pagination.page) {
      setPaginationLocal(info);
    }
  };
  const items = useSelector(
    isInfiniteScroll ? infiniteScrollSelector(tableName) : paginatedSelector(tableName)
  ) as TDataResponse['data'];
  const paginatedItems = useSelector(paginationSelector(tableName));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (pagination.page !== 0) getListOfItems(pagination);
  }, [pagination.limit, pagination.page]);

  useEffect(() => {
    if (pagination.page !== 1) {
      setPagination({
        page: 1,
        limit: pagination.limit,
      });
    } else {
      getListOfItems(pagination);
    }
  }, []);

  const getListOfItems = (newPagination: TablePaginationInfo) => {
    setLoading(true);
    if (pagination?.limit !== newPagination.limit || pagination?.page !== newPagination.page) {
      setPagination(newPagination);
    }
    return getItems({
      page: newPagination.page,
      limit: newPagination.limit,
    })
      .then((response) => {
        dispatch(SET_PAGE({ tableName, data: response.data.data, pagination: response.data.meta }));
      })
      .catch(ErrorAlert)
      .finally(() => setLoading(false));
  };

  const updateData = async () => {
    const state: RootState = ReduxStore.getState();
    const pageDetails = state.tablePaginationReducer[tableName]?.pagination;

    let lastPagination: PaginationMetaModel = null;
    if (isInfiniteScroll) {
      for (const index of [...Array(pageDetails?.current_page ?? 1).keys()]) {
        const res = await getItems({
          page: index + 1,
          limit: pagination.limit,
        });

        if (!res.data.data?.length) {
          dispatch(
            UPDATE_PAGE_CURRENT({
              tableName,
              pagination: lastPagination,
            })
          );
          break;
        } else {
          dispatch(
            UPDATE_PAGE_DATA({
              tableName,
              page: res.data.meta.current_page,
              data: res.data.data,
            })
          );
        }

        lastPagination = res.data.meta;
      }
    } else {
      await getListOfItems({ page: pageDetails?.current_page, limit: pageDetails?.per_page });
    }
  };

  usePageFocus(() => {
    resetTable();
  }, watch);

  const resetTable = () => {
    if (pagination.page === 1) {
      getListOfItems(pagination);
    } else {
      setPagination({
        page: 1,
        limit: pagination.limit,
      });
    }
  };

  return {
    setPagination,
    items,
    pagination: paginatedItems,
    loading,
    updateData,
    resetTable,
  };
}
