import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';

import { useCurrentPharmacy } from '~/api/hooks/pharmacy/PharmacyHook';
import { apiUpdatePharmacyImage } from '~/api/services/pharmacy';
import { SuccessAlert } from '~/common/commonMethods';
import { mt15, mt10, mt20 } from '~/common/commonStyles';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { UploadDocumentComponent } from '~/components/buttons/UploadDocumentComponent';
import { FormErrorMessage } from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { PharmacyPreviewComponent } from '~/components/pharmacies/PharmacyPreviewComponent';
import { AddImage } from '~/components/svgImages';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { requiredValidation } from '~/services/validationConfig';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

export const PharmacyAccountDetailsScreen: React.FC = () => {
  const { currentPharmacy, updatePharmacies } = useCurrentPharmacy();

  const [pharmacyImageUri, setPharmacyImageUri] = useState('');
  const [loading, setLoading] = useState(false);
  const { isMobile } = useBreakpoints();

  const { handleSubmit, control, setValue, clearErrors, reset, watch } = useForm<{
    pharmacyImage: DocumentPickerAsset;
  }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      pharmacyImage: undefined,
    },
  });

  const updateProfileImage = async (documentResults: DocumentPickerAsset[]) => {
    const documentResult = documentResults.length ? documentResults[0] : undefined;
    setValue('pharmacyImage', documentResult);
    if (documentResult) clearErrors('pharmacyImage');
    setPharmacyImageUri(documentResult?.uri);
    return Promise.resolve();
  };

  const onSubmit = async (data: { pharmacyImage: DocumentPickerAsset }) => {
    try {
      setLoading(true);

      await apiUpdatePharmacyImage({ id: currentPharmacy.pharmacy.id, document: data.pharmacyImage });
      await updatePharmacies();
      SuccessAlert(['Successfully updated the pharmacy logo']);
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const onCancel = () => {
    reset();
    setPharmacyImageUri('');
  };
  return (
    <NestedPageInternalLayout
      title={isMobile ? 'Pharmacy account' : null}
      footer={
        <CancelContinueFooter
          disabled={!watch('pharmacyImage') || loading}
          onConfirm={handleSubmit(onSubmit)}
          onCancel={onCancel}
          confirmTitle={labels.update}
        />
      }>
      <Grid>
        <Container alignSelf="flex-start" maxContainerWidths={760}>
          {currentPharmacy?.pharmacy ? (
            <Row>
              <Column style={{ maxWidth: 500 }}>
                <PharmacyPreviewComponent pharmacy={currentPharmacy.pharmacy} />
              </Column>
            </Row>
          ) : null}
          <Row style={mt20} justifyContent={isMobile ? 'center' : undefined}>
            <Column style={{ maxWidth: 250 }}>
              <Controller
                name="pharmacyImage"
                control={control}
                render={({ fieldState: { error }, formState: { isSubmitted } }) => (
                  <View style={[{ width: '100%' }, mt15]}>
                    <UploadDocumentComponent
                      uploadedFiles={[]}
                      resizeMode="cover"
                      hideState
                      imageUri={pharmacyImageUri}
                      square
                      uploadTitle="Upload image"
                      icon={<AddImage height={35} width={35} />}
                      setUploadedFiles={updateProfileImage}
                      error={isSubmitted && !!error}
                      imagesOnly
                    />
                    {isSubmitted && error?.message ? (
                      <View style={mt10}>
                        <FormErrorMessage>{error.message}</FormErrorMessage>
                      </View>
                    ) : null}
                  </View>
                )}
                rules={{
                  required: requiredValidation('Profile image'),
                }}
              />
            </Column>
          </Row>
        </Container>
      </Grid>
    </NestedPageInternalLayout>
  );
};
