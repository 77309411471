import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { AppointmentBookingWizard } from '~/components/patient/booking/AppointmentBookingWizard';
import { AppointmentBookingProvider } from '~/providers/appointment/AppointmentBookingProvider';
import { RootState } from '~/redux/reducers';
import { CLEAR_INITIAL_BOOKING_DETAILS } from '~/redux/reducers/patient/appointmentReducer';
import { useAppDispatch } from '~/redux/store';
import { usePageFocus } from '~/utils/hooks/FocusHook';

export const AppointmentsBookingScreen: React.FC = () => {
  const [initialValue, setInitialValues] = useState(undefined);
  const dispatch = useAppDispatch();

  const initialBookingValues = useSelector((state: RootState) => state.appointmentReducer.booking);

  usePageFocus(() => {
    setInitialValues(initialBookingValues);
    if (initialBookingValues) dispatch(CLEAR_INITIAL_BOOKING_DETAILS());
  });

  return (
    <AppointmentBookingProvider initialValues={initialValue || initialBookingValues}>
      <AppointmentBookingWizard />
    </AppointmentBookingProvider>
  );
};
