import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Animated, Easing, PanResponder, StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaFrame, Rect, useSafeAreaInsets } from 'react-native-safe-area-context';

import { colors } from '~/utils/colors';

const ANIMATION_TIME = 500;

interface Props {
  children?: React.ReactNode;
  show?: boolean;
  toggleOnBackground?: boolean;
  snapIndex?: number;
  snapPoints?: (number | string)[];
  onSnapChanged?: (snapIndex: number) => void;
  onHide?: () => void;
  onPressDragLine?: () => void;
}

export interface BottomSheetRef {}

export const BottomSheet = forwardRef<BottomSheetRef, Props>(
  ({ show, toggleOnBackground, children, snapIndex, snapPoints, onSnapChanged, onHide, onPressDragLine }, ref) => {
    const [dimensions, setDimensions] = useState<Rect>();

    const safeArea = useSafeAreaFrame();
    const safeAreaInsets = useSafeAreaInsets();

    useEffect(() => {
      setDimensions({
        ...safeArea,
        width: safeArea.width - safeAreaInsets.left - safeAreaInsets.right,
        height: safeArea.height - safeAreaInsets.top - safeAreaInsets.bottom,
      });
    }, [safeArea, safeAreaInsets]);

    const currentSnapPoint = useMemo(() => {
      if (!snapPoints?.length) return null;

      if ((snapIndex ?? -1) < 0 || snapIndex >= snapPoints.length) return snapPoints[0];

      return snapPoints[snapIndex];
    }, [snapPoints, snapIndex]);

    const heightAnim = useRef(new Animated.Value(0)).current;

    const calculateHeight = (value: number | string) => {
      if (typeof value === 'string' && value?.length) {
        const parseValue = (value: string) => {
          const parsedValue = Number(value);
          return isNaN(parsedValue) ? 0 : parsedValue;
        };
        if (value.endsWith('%')) {
          const percentage = parseValue(value.slice(0, -1));
          return (dimensions.height * percentage) / 100;
        } else {
          return parseValue(value);
        }
      } else {
        return ((value as number) || 0) + safeAreaInsets.bottom;
      }
    };

    useEffect(() => {
      Animated.timing(heightAnim, {
        toValue: calculateHeight(currentSnapPoint),
        duration: ANIMATION_TIME,
        useNativeDriver: false,
        easing: Easing.elastic(0.5),
      }).start(() => onSnapChanged && onSnapChanged(snapIndex));
    }, [currentSnapPoint, dimensions, safeAreaInsets.bottom]);

    const panResponder = useRef(
      // TODO:
      PanResponder.create({
        // Ask to be the responder:
        onStartShouldSetPanResponder: (evt, gestureState) => true,
        onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
        onMoveShouldSetPanResponder: (evt, gestureState) => true,
        onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,

        onPanResponderGrant: (evt, gestureState) => {
          console.log('onPanResponderGrant', evt, gestureState);
          // The gesture has started. Show visual feedback so the user knows
          // what is happening!
          // gestureState.d{x,y} will be set to zero now
        },
        onPanResponderMove: (evt, gestureState) => {
          console.log('onPanResponderMove', evt, gestureState);
          // The most recent move distance is gestureState.move{X,Y}
          // The accumulated gesture distance since becoming responder is
          // gestureState.d{x,y}
        },
        onPanResponderTerminationRequest: (evt, gestureState) => true,
        onPanResponderRelease: (evt, gestureState) => {
          console.log('onPanResponderRelease', evt, gestureState);
          // The user has released all touches while this view is the
          // responder. This typically means a gesture has succeeded
        },
        onPanResponderTerminate: (evt, gestureState) => {
          console.log('onPanResponderTerminate', evt, gestureState);
          // Another component has become the responder, so this gesture
          // should be cancelled
        },
        onShouldBlockNativeResponder: (evt, gestureState) => {
          // Returns whether this component should block native components from becoming the JS
          // responder. Returns true by default. Is currently only supported on android.
          return true;
        },
      })
    );

    const content = (
      <Animated.View
        style={[styles.snappingView, styles.sheetStyle, { height: heightAnim, bottom: -safeAreaInsets.bottom - 10 }]}
        pointerEvents="auto">
        <View {...panResponder}>
          <TouchableWithoutFeedback onPress={onPressDragLine}>
            <View style={styles.dragLineContainer}>
              <View style={styles.dragLine} />
            </View>
          </TouchableWithoutFeedback>
          <View style={styles.sheetPadding}>{children}</View>
        </View>
      </Animated.View>
    );

    return toggleOnBackground ? (
      <TouchableWithoutFeedback
        onPress={onPressDragLine}
        style={[styles.touchableBackground, { width: dimensions.width, height: dimensions.height }]}>
        <View style={styles.touchableBackground}>{content}</View>
      </TouchableWithoutFeedback>
    ) : (
      content
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  touchableBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  snappingView: {
    position: 'absolute',
    left: 0,
    right: 0,
    maxHeight: 400,
  },
  sheetStyle: {
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    backgroundColor: colors.lightPurple,
    borderWidth: 1,
    borderColor: colors.lightPurple2,
    shadowColor: colors.lightPurple2,
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 6,
    overflow: 'hidden',
  },
  sheetPadding: {
    paddingBottom: 20,
    paddingHorizontal: 30,
  },
  dragLineContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingTop: 20,
  },
  dragLine: {
    height: 6,
    borderRadius: 6,
    backgroundColor: colors.lightPurple3,
    width: 68,
    marginBottom: 20,
  },
});
