import React from 'react';

import { PrescriptionDetailComponent } from './PrescriptionDetailComponent';
import { PrescriptionDoctorPatientDetails } from './PrescriptionDoctorPatientDetails';
import { PharmacyPrescriptionDispenseHistory } from './dispenseHistory/PharmacyPrescriptionDispenseHistoryComponent';

import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import { mb20 } from '~/common/commonStyles';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { Container, Row, Column } from '~/theme/components/grid';

interface Props {
  request: PrescriptionRequestModel;
  input: React.ReactNode;
}

export const PrescriptionRequestApprovalComponent: React.FC<Props> = ({ request, input }) => {
  return (
    <>
      <Container>
        <Row style={mb20} justifyContent="center">
          <Column span="auto">
            <H3TtmSemiBoldBlack>Approve or Decline request</H3TtmSemiBoldBlack>
          </Column>
        </Row>
      </Container>
      {request?.prescription ? (
        <>
          <PrescriptionDoctorPatientDetails prescription={request.prescription} style={mb20} />
          <PharmacyPrescriptionDispenseHistory prescription={request.prescription} style={mb20} />
          <PrescriptionDetailComponent prescription={request.prescription} />
        </>
      ) : null}
      {/* TODO: Show previous collection? */}
      <Container>
        <Row>
          <Column children={input} />
        </Row>
      </Container>
    </>
  );
};
