import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { Linking, View } from 'react-native';

import { DoctorExportAccountDataComponent } from '../../../components/doctor/settings/deletePermanentlyAccount/DoctorExportAccountDataComponent';
import { PatientExportAccountDataComponent } from '../../../components/settings/deletePermanentlyAccount/PatientExportAccountDataComponent';

import { setObjectAsyncItem, storageKeys } from '~/common/asyncStorage';
import { mb10, mb20 } from '~/common/commonStyles';
import { LinkButton } from '~/components/buttons/LinkButton';
import { Button } from '~/components/commonButton';
import { SmallNsRegularBlack } from '~/components/commonText';
import { ClinicExportAccountDataComponent } from '~/components/settings/deletePermanentlyAccount/ClinicExportAccountDataComponent';
import { PharmacyExportAccountDataComponent } from '~/components/settings/deletePermanentlyAccount/PharmacyExportAccountDataComponent';
import RegistrationLayout from '~/layouts/RegistrationLayout';
import { NavType } from '~/navigation/types';
import { SET_ONBOARDING_COMPLETE } from '~/redux/reducers/appDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { whenAppType } from '~/utils/buildConfig';
import { accountDeletionLabels } from '~/utils/labels/accountDeletionLabels';
import { screenName } from '~/utils/screenName';

export const PermanentlyDeleteAccountGuest: React.FC = () => {
  const { navigate } = useNavigation<NavType>();
  const dispatch = useAppDispatch();

  const goToLogin = async () => {
    navigate(screenName.LoginScreen);
  };

  useEffect(() => {
    dispatch(SET_ONBOARDING_COMPLETE(true));
    setObjectAsyncItem(storageKeys.onboarding, true);
  }, []);

  const buttons = (
    <View>
      <SmallNsRegularBlack style={mb10}>
        Should you wish to delete your account and data, proceed to login with your account and request your account is
        deleted.
      </SmallNsRegularBlack>
      <SmallNsRegularBlack style={mb20}>
        If you have any issues or questions about deleting your data, please contact us via email on{' '}
        <LinkButton
          title="contact@digimedhealth.com"
          style={{ padding: 0 }}
          onPress={() => Linking.openURL('mailto:contact@digimedhealth.com')}
        />
      </SmallNsRegularBlack>
      <Button label="Login" funCallback={goToLogin} />
    </View>
  );

  return (
    <RegistrationLayout
      hideProgress
      hideSubtitle
      title={accountDeletionLabels.permanentlyDeleteAccount}
      childrenMarginTop={10}
      buttons={buttons}>
      {whenAppType({
        whenDoctor: <DoctorExportAccountDataComponent isGuest />,
        whenPatient: <PatientExportAccountDataComponent isGuest />,
        whenPharmacist: <PharmacyExportAccountDataComponent isGuest />,
        whenClinic: <ClinicExportAccountDataComponent isGuest />,
      })}
    </RegistrationLayout>
  );
};
