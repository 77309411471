import { StyleSheet } from 'react-native';

import { colors } from '../../../utils/colors';

const commonTextStyles = StyleSheet.create({
  titleWeight: {
    fontWeight: '500',
  },
  subtitleWeight: {
    fontWeight: 'normal',
  },
});

const webMenuStyles = StyleSheet.create({
  textSize: {
    fontSize: 14,
  },
  itemsListStyle: {
    borderBottomWidth: 1,
    borderBottomColor: colors.lightPurple2,
  },
  lastItemStyle: {
    borderBottomWidth: 0,
  },
  menuStyle: {
    position: 'absolute',
    padding: 5,
    minWidth: 200,
  },
});

const mobileMenuStyles = StyleSheet.create({
  textSize: {
    fontSize: 10,
  },
  titleStyle: {
    fontWeight: '500',
  },
  subtitleStyle: {
    fontWeight: 'normal',
  },
  itemsListStyle: {
    paddingHorizontal: 10,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightPurple2,
  },
  lastItemStyle: {
    paddingHorizontal: 10,
    paddingVertical: 20,
    borderBottomWidth: 0,
  },
  modalView: {
    margin: 20,
    backgroundColor: colors.white,
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    width: 80,
    height: 35,
    borderRadius: 7,
  },
  buttonClose: {
    backgroundColor: colors.lightPurple2,
  },
  container: {},
});

export { commonTextStyles, webMenuStyles, mobileMenuStyles };
