import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';

import { flex1 } from '../../../common/commonStyles';
import { labels } from '../../../utils/labels';
import { DataTable, DataTableProps } from '../../common/DataTable/DataTable';

import { useConsultationListing } from '~/api/hooks/patients/PatientConsultationsHook';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { DiagnosisConsultationDoctorCard } from '~/components/common/DiagnosisConsultationDoctorCard';
import { DiagnosisConsultationPatientCard } from '~/components/common/DiagnosisConsultationPatientCard';
import { AppointmentDetailsModal } from '~/components/modals/AppointmentDetailsModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { doctorColumns, patientColumns } from '~/constants/PatientDiagnosesConsultationsListColumns';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { NavType } from '~/navigation/types';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isPatientVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';
import { screenName } from '~/utils/screenName';

interface Props {
  patientId: number;
  diagnosisId: number;
  listHeaders?: DataTableProps['listHeader'];
}

export const PatientConsultationsList: React.FC<Props> = ({ patientId, diagnosisId, listHeaders }) => {
  const navigation = useNavigation<NavType>();
  const { setPagination, pagination, loading, consultations } = useConsultationListing({
    patientId,
    diagnosisId,
  });
  const { registerModal, openModal } = useModalManager();
  const { isMobile } = useBreakpoints();
  const { showNewBadge } = useNewBadge();
  useEffect(() => {
    registerModal(ModalName.AppointmentDetails, AppointmentDetailsModal);
  }, []);

  const rowPress = (key: number, item) => {
    if (isPatientVersion() && isMobile) {
      openModal(ModalName.AppointmentDetails, { consultationId: key });
    } else {
      navigation.navigate(isPatientVersion() ? screenName.PatientVersionConsultation : screenName.PatientConsultation, {
        id: patientId,
        diagnosisId,
        consultationId: key,
      });
    }
  };
  return (
    <DataTable
      tableId="consultations"
      style={flex1}
      columns={isPatientVersion() ? patientColumns : doctorColumns}
      items={!isMobile ? (loading ? [] : consultations ?? []) : consultations ?? []}
      totalCount={pagination?.total || 0}
      page={pagination?.current_page || 0}
      onPageChanged={setPagination}
      numberOfItemsPerPage={pagination?.per_page}
      onRowPress={rowPress}
      infiniteScroll={isMobile ? { loading, estimatedItemSize: { desktop: 60, mobile: 110 } } : null}
      listHeader={listHeaders}
      loading={loading}
      emptyNote={labels.noCurrentConsultations}
      mobileBreakpoint={DeviceSizeDefaults.tablet}
      rowIsNewIdentifier={isPatientVersion() ? (item) => showNewBadge({ value: item, timeKey: 'updated_at' }) : null}
      mobileRenderItem={(item: ConsultationModel) => {
        return isPatientVersion() ? (
          <DiagnosisConsultationPatientCard item={item} />
        ) : (
          <DiagnosisConsultationDoctorCard item={item} />
        );
      }}
    />
  );
};
