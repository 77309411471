import React from 'react';
import Svg, { Path, G, Rect, Defs } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
}

export const WebsiteSvg: React.FC<Props> = ({ width, height }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 152 82" fill="none">
      <G clipPath="url(#clip0_10589_41650)">
        <Path d="M2.3063 21.1523H1.8623V29.6771H2.3063V21.1523Z" fill="#433489" />
        <Path d="M2.3063 32.8926H1.8623V36.8087H2.3063V32.8926Z" fill="#433489" />
        <Path
          d="M123.394 0.0136719H7.95826C5.36389 0.0136719 3.26074 2.11682 3.26074 4.71119V77.0566C3.26074 79.6509 5.36389 81.7541 7.95826 81.7541H123.394C125.988 81.7541 128.091 79.6509 128.091 77.0566V4.71119C128.091 2.11682 125.988 0.0136719 123.394 0.0136719Z"
          fill="#433489"
        />
        <Path
          d="M4.82324 77.0553L4.82324 4.70998C4.82324 2.98283 6.22628 1.57979 7.95344 1.57979L123.389 1.57979C125.116 1.57979 126.519 2.98283 126.519 4.70998V77.0553C126.519 78.7825 125.116 80.1855 123.389 80.1855L7.95344 80.1855C6.22628 80.1855 4.82324 78.7825 4.82324 77.0553Z"
          fill="white"
        />
        <Path
          d="M106.078 9.49682H114.256C114.878 9.49682 115.388 8.98622 115.388 8.36462C115.388 7.74302 114.878 7.23242 114.256 7.23242H106.078C105.456 7.23242 104.945 7.74302 104.945 8.36462C104.945 8.98622 105.456 9.49682 106.078 9.49682Z"
          fill="#433489"
        />
        <Path
          d="M78.2478 10.1498C79.2335 10.1498 80.0327 9.35072 80.0327 8.36496C80.0327 7.37919 79.2335 6.58008 78.2478 6.58008C77.262 6.58008 76.4629 7.37919 76.4629 8.36496C76.4629 9.35072 77.262 10.1498 78.2478 10.1498Z"
          fill="#433489"
        />
        <Path
          d="M74.4828 8.36496C74.4828 9.35064 73.6836 10.1498 72.698 10.1498C71.7123 10.1498 70.9131 9.35064 70.9131 8.36496C70.9131 7.37928 71.7123 6.58008 72.698 6.58008C73.6836 6.58008 74.4828 7.37928 74.4828 8.36496Z"
          fill="#433489"
        />
        <Path
          d="M18.9066 6.53516H16.6733C16.2809 6.53516 15.9629 6.85321 15.9629 7.24556V9.47888C15.9629 9.87122 16.2809 10.1893 16.6733 10.1893H18.9066C19.299 10.1893 19.617 9.87122 19.617 9.47888V7.24556C19.617 6.85321 19.299 6.53516 18.9066 6.53516Z"
          fill="#433489"
        />
        <Path
          d="M30.6642 8.92854H26.4018C26.091 8.92854 25.8379 8.67546 25.8379 8.36466C25.8379 8.05386 26.091 7.80078 26.4018 7.80078H30.6642C30.975 7.80078 31.2281 8.05386 31.2281 8.36466C31.2281 8.67546 30.975 8.92854 30.6642 8.92854Z"
          fill="#E6E9F4"
        />
        <Path
          d="M38.9855 8.92854H34.7231C34.4123 8.92854 34.1592 8.67546 34.1592 8.36466C34.1592 8.05386 34.4123 7.80078 34.7231 7.80078H38.9855C39.2963 7.80078 39.5493 8.05386 39.5493 8.36466C39.5493 8.67546 39.2963 8.92854 38.9855 8.92854Z"
          fill="#E6E9F4"
        />
        <Path
          d="M47.3107 8.92854H43.0483C42.7375 8.92854 42.4844 8.67546 42.4844 8.36466C42.4844 8.05386 42.7375 7.80078 43.0483 7.80078H47.3107C47.6215 7.80078 47.8745 8.05386 47.8745 8.36466C47.8745 8.67546 47.6215 8.92854 47.3107 8.92854Z"
          fill="#E6E9F4"
        />
        <Path
          d="M55.631 8.92854H51.3686C51.0578 8.92854 50.8047 8.67546 50.8047 8.36466C50.8047 8.05386 51.0578 7.80078 51.3686 7.80078H55.631C55.9418 7.80078 56.1948 8.05386 56.1948 8.36466C56.1948 8.67546 55.9418 8.92854 55.631 8.92854Z"
          fill="#E6E9F4"
        />
        <Path
          opacity="0.4"
          d="M145.107 16.7017L55.1342 19.7807C52.5414 19.8695 50.5114 22.0433 50.6001 24.6362L51.497 50.8435C51.5857 53.4363 53.7596 55.4663 56.3524 55.3776L146.325 52.2986C148.918 52.2098 150.948 50.036 150.859 47.4431L149.962 21.2358C149.874 18.643 147.7 16.613 145.107 16.7017Z"
          fill="#E6E9F4"
        />
        <Path
          opacity="0.4"
          d="M89.7912 38.7229L61.7348 39.6819C60.2297 39.7352 58.9554 38.5453 58.9021 37.0357C58.8488 35.5305 60.0388 34.2562 61.5484 34.203L89.6047 33.2439C91.1099 33.1906 92.3842 34.3806 92.4374 35.8902C92.4907 37.3953 91.3008 38.6696 89.7912 38.7229Z"
          fill="#CAD0ED"
        />
        <Path
          opacity="0.4"
          d="M80.5595 47.3615L62.018 47.9965C60.5129 48.0497 59.2386 46.8598 59.1853 45.3502C59.132 43.8451 60.322 42.5708 61.8316 42.5175L80.373 41.8826C81.8782 41.8293 83.1524 43.0192 83.2057 44.5288C83.259 46.034 82.0691 47.3083 80.5595 47.3615Z"
          fill="#CAD0ED"
        />
        <Rect x="15.6406" y="63.0273" width="28.9116" height="12.3907" rx="3.09767" fill="#E6E9F4" />
        <Rect x="50.748" y="63.0273" width="28.9116" height="12.3907" rx="3.09767" fill="#E6E9F4" />
        <Rect x="86.8877" y="63.0273" width="28.9116" height="12.3907" rx="3.09767" fill="#E6E9F4" />
      </G>
      <Defs>
        <clipPath id="clip0_10589_41650">
          <Rect width="150.039" height="82" fill="white" transform="translate(0.980469)" />
        </clipPath>
      </Defs>
    </Svg>
  );
};
