import React from 'react';

import { EditImageComponent } from './EditImageComponent';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';

export const UserEditImageComponent: React.FC = () => {
  const { userDetails, getUserDetails } = useUserDetails();
  return <EditImageComponent account={userDetails?.account} onUpdate={getUserDetails} />;
};
