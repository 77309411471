import React from 'react';

import { usePushNotifications } from './PushNotificationsHook';

interface Props {
  children: React.ReactNode;
}

export const PushNotificationProvider: React.FC<Props> = ({ children }) => {
  usePushNotifications();

  return <>{children}</>;
};
