import { labels } from '../utils/labels';
import { screenName } from '../utils/screenName';

export const dependantsProfileMenu = [
  { title: labels.personalInformation, link: { screen: screenName.DependantPersonalInfo, params: {} } },
  { title: labels.homeAddress, link: { screen: screenName.DependantAddress, params: {} } },
  {
    title: labels.healthProfile,
    link: { screen: screenName.DependantHealthProfile, params: {} },
  },
  {
    title: labels.linkedAccounts,
    link: { screen: screenName.DependantLinkedAccounts, params: {} },
  },
];
