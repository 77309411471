import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm, useFormState, useWatch } from 'react-hook-form';

import { InsuranceClaimConsultationFormContext } from './InsuranceClaimFormContext';

import { ConsultationFeeTypeEnum } from '~/api/models/appointments/enums/ConsultationFeeTypeEnum';
import { PriceModel } from '~/api/models/common/models/PriceModel';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { InsuranceClaimForm } from '~/api/models/insurance/models/InsuranceClaimForm';
import { InsuranceClaimFormResponse } from '~/api/models/insurance/responses/InsuranceClaimFormResponse';
import { apiGetConsultation } from '~/api/services/consultations';
import {
  apiAddInsuranceClaimDocument,
  apiDeleteInsuranceClaimDocument,
  apiGetInsuranceClaim,
  apiPostInsuranceClaimForm,
  apiPostSubmitInsuranceClaimForm,
  apiPutInsuranceClaimForm,
  apiUpdateInsuranceClaimDocument,
} from '~/api/services/insurance';
import { InprogressAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { useInsuranceClaimFormSteps } from '~/components/patient/insurance/hooks/useInsuranceClaimFormSteps';
import { NavType } from '~/navigation/types';
import { formatAmount } from '~/utils/amountUtil';
import { NUM_DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { isDocumentResult } from '~/utils/files/isDocumentResult';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useWizardSteps } from '~/utils/hooks/WizardStepsHook';
import { screenName } from '~/utils/screenName';

interface Props {
  children: React.ReactNode;
  consultationId: number;
}

export const InsuranceClaimFormProvider: React.FC<Props> = ({ children, consultationId }) => {
  const [loading, setLoading] = useState(false);
  const [consultation, setConsultation] = useState<ConsultationModel>(null);
  const { insuranceWizardSteps } = useInsuranceClaimFormSteps({ consultationId });
  const [consultationFee, setConsultationFee] = useState<PriceModel>(null);
  const [digimedFees, setDigimedFees] = useState<PriceModel[]>(null);

  const { navigate } = useNavigation<NavType>();
  const wizard = useWizardSteps({ steps: insuranceWizardSteps });

  const { control, reset, handleSubmit, getValues, watch } = useForm<InsuranceClaimForm>({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const resetWithData = (claim: InsuranceClaimFormResponse) => {
    const values = getValues();
    reset({
      id: claim?.id,
      consultation_id: consultationId,
      description: claim?.description,
      first_doctor_visit_date: parseDateTime(claim?.first_doctor_visit_date, {
        outputFormat: NUM_DATE_FORMAT,
      }),
      is3rd_party_liable: claim?.is3rd_party_liable ?? false,
      is3rd_party_liable_comments: claim?.is3rd_party_liable_comments ?? '',
      is_cost_recoverable_from_other_insurance: claim?.is_cost_recoverable_from_other_insurance ?? false,
      is_cost_recoverable_from_other_insurance_comments: claim?.is_cost_recoverable_from_other_insurance_comments ?? '',
      insurance_claim_documents:
        claim.insurance_claim_documents?.map((doc) => ({
          id: doc.id,
          amount: doc?.amount?.amount,
          currency: doc?.amount?.currency,
          description: doc.description,
          type: doc.type,
          image: doc.image,
        })) ??
        values.insurance_claim_documents ??
        [],
    });
  };

  const insurance_claim_documents = useWatch({ control, name: 'insurance_claim_documents' });
  const { dirtyFields } = useFormState({ control });

  useEffect(() => {
    apiGetConsultation({ id: consultationId }).then((res) => {
      setConsultation(res.data);
    });
  }, [consultationId]);

  const digimedFeesTotal = useMemo(
    () =>
      digimedFees?.reduce((previous, fee) => {
        previous += fee.amount;
        return previous;
      }, 0) ?? 0,
    [digimedFees]
  );

  const totalClaimedAmount = useMemo(() => {
    let totalAmount = consultationFee?.amount ?? 0;

    totalAmount += digimedFeesTotal;

    if (insurance_claim_documents) {
      for (const document of insurance_claim_documents) {
        if (document.amount) {
          totalAmount += document.amount;
        }
      }
    }

    return parseFloat((totalAmount / 100).toFixed(2));
  }, [consultationFee, digimedFeesTotal, insurance_claim_documents]);

  const loadClaim = async () => {
    try {
      if (!consultation?.insurance_claim_status?.id) return;
      const claimRes = await apiGetInsuranceClaim(consultation.insurance_claim_status.id);
      // const documentsRes = claimRes?.data.id
      //   ? await apiGetInsuranceClaimDocuments({ id: claimRes.data.id }).catch(() => undefined)
      //   : undefined;

      resetWithData({
        ...claimRes.data,
        // @ts-ignore
        insurance_claim_documents: claimRes?.data.insurance_claim_receipts,
      });
    } catch (e) {
      ErrorAlert(e);
    }
  };
  useEffect(() => {
    if (consultation && consultation?.insurance_claim_status?.id) {
      loadClaim();
    }
  }, [consultation]);

  useEffect(() => {
    setConsultationFee(
      consultation?.latest_transaction_price?.fees?.find((fee) => fee.type === ConsultationFeeTypeEnum.CONSULTATION_FEE)
        .price
    );

    setDigimedFees(
      consultation?.latest_transaction_price?.fees
        ?.filter((fee) => fee.type !== ConsultationFeeTypeEnum.CONSULTATION_FEE)
        .map((fee) => fee.price) ?? []
    );
  }, [consultation]);

  const insuranceClaimsFieldArray = useFieldArray({ control, name: 'insurance_claim_documents', keyName: 'fieldId' });

  const createInsuranceClaim = async (data: InsuranceClaimForm) => {
    try {
      if (consultation?.insurance_claim_status?.id == null) {
        const result = await apiPostInsuranceClaimForm({ ...data, consultation_id: consultationId });
        resetWithData(result.data);
      } else {
        const result = await apiPutInsuranceClaimForm(consultation.insurance_claim_status.id, {
          ...data,
          consultation_id: consultationId,
        });
        resetWithData(result.data);
      }

      wizard.actions.next();
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const updateInsuranceDocuments = async (data: InsuranceClaimForm) => {
    const claimId = data?.id;
    if (!claimId) throw new Error('We were not able to link your claim with your documents');

    try {
      for (let index = 0; index < data.insurance_claim_documents?.length; index++) {
        const document = data.insurance_claim_documents[index];
        if (!document.type || !document.image) continue;
        if (document.id) {
          if (
            dirtyFields?.insurance_claim_documents &&
            Object.values(dirtyFields?.insurance_claim_documents[index] ?? []).some((value) => value === true)
          ) {
            await apiUpdateInsuranceClaimDocument({
              documentId: document.id,
              image: isDocumentResult(document.image) ? document.image : undefined,
              params: {
                type: document.type,
                amount: document.amount ? String(document.amount) : undefined,
                currency: document.amount ? document.currency : undefined,
                description: document.description,
              },
            });
          }
        } else {
          await apiAddInsuranceClaimDocument({
            claimId,
            image: isDocumentResult(document.image) ? document.image : undefined,
            params: {
              type: document.type,
              amount: document.amount ? String(document.amount) : undefined,
              currency: document.amount ? document.currency : undefined,
              description: document.description,
            },
          });
        }
      }

      await loadClaim();
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const removeDocument = async (index: number) => {
    const document = insuranceClaimsFieldArray.fields[index];
    if (document.id) {
      await apiDeleteInsuranceClaimDocument({ id: document.id });
    }
    insuranceClaimsFieldArray.remove(index);
    if (document.id) {
      await loadClaim();
    }
  };

  const submitClaim = async (data: InsuranceClaimForm) => {
    try {
      if (!data.id) return;
      setLoading(true);

      await apiPostSubmitInsuranceClaimForm(data.id);

      InprogressAlert(['We are in the process of generating your claim form. We will let you know once it is ready'], {
        title: 'Generating claim form',
        buttonFunction: () => navigate(screenName.Insurance, { screen: screenName.InsuranceClaims }),
      });
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  usePageFocus(() => {
    reset();
    wizard.reset();
  });

  return (
    <InsuranceClaimConsultationFormContext.Provider
      value={{
        control,
        wizard,
        nextPage: handleSubmit(wizard.actions.next),
        createInsuranceClaim: handleSubmit(createInsuranceClaim),
        updateInsuranceDocuments: handleSubmit(updateInsuranceDocuments),
        removeDocument,
        submitClaim: handleSubmit(submitClaim),
        loading,
        consultationId,
        consultation,
        insuranceClaimsFieldArray,
        getValues,
        handleSubmit,
        watch,
        totalClaimedAmount,
        consultationFee,
        digimedFees,
        digimedFeesTotal: formatAmount(digimedFeesTotal, 'EUR'),
      }}>
      {children}
    </InsuranceClaimConsultationFormContext.Provider>
  );
};
