import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View, StyleSheet } from 'react-native';

import { DashboardCard } from '../../common/DashboardCard';

import { flex1 } from '~/common/commonStyles';
import { OutlineButton } from '~/components/commonButton';
import { H4TtwRegularSemiBold, H6NsRegularSecondaryBlack } from '~/components/commonText';
import { Person } from '~/components/svgImages';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';
import { useNavigationHealthProfile } from '~/utils/hooks/AppNavigationHook';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const HealthProfileCompletion: React.FC = (): JSX.Element => {
  const { patientHealthProfileNavigation } = useNavigationHealthProfile();

  return (
    <DashboardCard>
      <View style={styles.container}>
        <View style={styles.header}>
          <Person width={25} height={25} color={colors.darkPurple} />
          <H4TtwRegularSemiBold style={styles.title}>{labels.healthProfile}</H4TtwRegularSemiBold>
        </View>
        <View style={[flex1, { paddingVertical: 10 }]}>
          <H6NsRegularSecondaryBlack style={flex1}>{labels.addHealthProfile}</H6NsRegularSecondaryBlack>
        </View>
        <View style={flex1}>
          <OutlineButton funCallback={patientHealthProfileNavigation} label="CREATE YOUR HEALTH PROFILE" />
        </View>
      </View>
    </DashboardCard>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    flex: 1,
    paddingBottom: 15,
  },
  header: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },
  title: {
    marginLeft: 10,
    color: colors.darkPurple,
  },
});
