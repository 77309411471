import React, { ReactNode, useMemo } from 'react';
import { View } from 'react-native';

import { STATUS_COLOR_MAPPING } from '../constants/StatusColorMapping';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { mb10, mr10 } from '~/common/commonStyles';
import { H5TtmRegularInfo, H6NsRegularSecondaryBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { DoctorClinicName } from '~/components/patient/doctorListing/components/DoctorClinicName';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { idNameArrayToString } from '~/utils/arrayUtil';
import { TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props {
  status?: string;
  consultation?: ConsultationModel;
  children: ReactNode;
}
export const PatientsAppointmentItem: React.FC<Props> = ({ status, consultation, children }) => {
  const doctorOrClinic = useMemo(
    () => consultation?.doctor || consultation?.clinic,
    [consultation?.doctor, consultation?.clinic]
  );
  return (
    <View style={{ flex: 1, width: '100%' }}>
      <View style={[{ ...mb10, flexDirection: 'row', flex: 1, flexGrow: 1, justifyContent: 'space-between' }]}>
        <View style={mr10}>
          <ProfileImageComponent account={doctorOrClinic} />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}>
            <StatusBadge
              style={{ alignSelf: 'flex-start' }}
              label={status ?? STATUS_COLOR_MAPPING[consultation.state]?.label}
              color={STATUS_COLOR_MAPPING[consultation.state]?.color}
              textStyle={{ textTransform: 'capitalize' }}
            />
            <H6NsSemiBoldBlack
              style={{ whiteSpace: 'pre-wrap', marginBottom: 5, marginRight: 5, flex: 1, minWidth: 80 }}>
              {getAccountName(doctorOrClinic)}
            </H6NsSemiBoldBlack>
          </View>
          {consultation?.doctor ? <DoctorClinicName clinic={consultation?.clinic} /> : null}
          <H6NsRegularSecondaryBlack>
            {idNameArrayToString(doctorOrClinic?.areas_of_specialisation, 2)}
          </H6NsRegularSecondaryBlack>
          <View style={{ flexDirection: 'row' }}>
            <H5TtmRegularInfo>
              {parseDateTime(consultation.start_at || consultation.request_submitted_at, { outputFormat: 'D MMMM,' })}
            </H5TtmRegularInfo>
            <H5TtmRegularInfo>
              {parseDateTime(consultation.start_at || consultation.request_submitted_at, { outputFormat: TIME_FORMAT })}
            </H5TtmRegularInfo>
          </View>
        </View>
      </View>
      {children}
    </View>
  );
};
