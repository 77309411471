import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { mr10, mt15 } from '../../common/commonStyles';
import { labels } from '../../utils/labels';
import { Button, OutlineButton, TextButton } from '../commonButton';

import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useKeyboard } from '~/utils/hooks/KeyboardHook';

interface Props {
  style?: StyleProp<ViewStyle>;
  cancelTitle?: string;
  confirmTitle?: string;
  onCancel?: () => void;
  onConfirm: () => void;
  waiting?: boolean;
  disabled?: boolean;
  disabledConfirm?: boolean;
  disabledCancel?: boolean;
  hideContinue?: boolean;
  hideCancel?: boolean;
  stacked?: boolean;
  left?: React.ReactNode;
  cancelDanger?: boolean;
}

export const CancelContinueFooter: React.FC<Props> = ({
  onCancel,
  onConfirm,
  style,
  cancelTitle = labels.cancel,
  confirmTitle = labels.continue,
  waiting,
  hideCancel,
  hideContinue,
  disabled,
  disabledConfirm,
  disabledCancel,
  stacked,
  left,
  cancelDanger,
}) => {
  const { isMobile } = useBreakpoints();
  const { showing: showingKeyboard } = useKeyboard();

  const hideCancelButton = showingKeyboard || hideCancel;

  return (
    <View style={[styles.container, isMobile || stacked ? styles.containerMobile : null, style]}>
      {!isMobile && !stacked && left ? <View style={styles.leftContainer}>{left}</View> : null}
      {hideCancelButton || isMobile || stacked ? null : (
        <View style={[mr10, styles.buttonWidth]}>
          <OutlineButton
            label={cancelTitle}
            funCallback={onCancel}
            disabled={waiting || disabled || disabledCancel}
            danger={cancelDanger}
          />
        </View>
      )}
      {hideContinue ? null : (
        <View style={styles.buttonWidth}>
          <Button label={confirmTitle} funCallback={onConfirm} disabled={waiting || disabled || disabledConfirm} />
        </View>
      )}
      {hideCancelButton || !(isMobile || stacked) ? null : (
        <TextButton
          label={cancelTitle.toUpperCase()}
          style={mt15}
          funCallback={onCancel}
          disabled={waiting || disabled || disabledCancel}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  containerMobile: {
    flexDirection: 'column',
  },
  buttonWidth: {
    minWidth: 120,
  },
  leftContainer: {
    display: 'flex',
    flex: 1,
  },
});
