import { useState } from 'react';

import { ClinicalTermModel } from '~/api/models/clinicalTerms/models/ClinicalTermModel';
import { ReferralFormModel, ReferralModel } from '~/api/models/consultations/models/ReferralModel';
import {
  apiAddReferral,
  apiDeleteReferral,
  apiGetExaminations,
  apiGetReferralIndex,
  apiGetRequestFor,
  apiUpdateExaminations,
  apiUpdateRequestFor,
} from '~/api/services/consultations/referral';
import { isDoctorVersion } from '~/utils/buildConfig';

interface Props {
  consultationId: number;
}

export function useReferrals({ consultationId }: Props) {
  const [loading, setLoading] = useState(false);
  const [referral, setReferralState] = useState<ReferralModel>(null);
  const [referralFor, setReferralFor] = useState<ClinicalTermModel[]>(null);
  const [referralExamination, setReferralExamination] = useState<ClinicalTermModel[]>(null);

  const getConsultationReferral = async (consultationId: number) => {
    try {
      const responseReferral = await apiGetReferralIndex({ filter: { consultation_id: consultationId }, limit: 1 });
      const referral = responseReferral.data.data?.length ? responseReferral.data.data[0] : undefined;
      return referral;
    } catch {}
  };

  const getReferral = async () => {
    try {
      setLoading(true);
      const referral = await getConsultationReferral(consultationId);

      if (!referral) {
        throw new Error();
      }
      setReferralState(referral);

      if (isDoctorVersion()) {
        await apiGetRequestFor(referral.id).then((response) => setReferralFor(response.data));
        await apiGetExaminations(referral.id).then((response) => setReferralExamination(response.data));
      }
      setLoading(false);
      return referral;
    } catch {
      setLoading(false);
    }
  };

  const deleteReferral = async (id: number) => {
    if (id) {
      await apiDeleteReferral({ id });
      setReferralState(null);
      setReferralExamination(null);
      setReferralFor(null);
    } else {
      throw new Error('No current referral to delete');
    }
  };

  const setReferral = async (
    formData: ReferralFormModel,
    dirty: { referral: boolean; examination: boolean; requestFor: boolean },
    referralId?: number
  ) => {
    if (!dirty.referral) return referralId;
    if (referralId && !formData.refer_patient) {
      await deleteReferral(referralId);
      return undefined;
    } else if (formData.refer_patient) {
      // TODO: AUTO SAVE
      await apiAddReferral({
        consultation_id: formData.consultation_id,
        presenting_complaint: formData.presenting_complaint,
        indications: formData.indications,
        special_comment: formData.special_comment,
        last_menstrual_period: formData.last_menstrual_period,
        additional_comments: formData.additional_comments,
      });
      const referral = await getConsultationReferral(formData.consultation_id);

      if (dirty.requestFor)
        await apiUpdateRequestFor(referral.id, { term_ids: formData.request?.map((item) => item.value) || [] });
      if (dirty.examination)
        await apiUpdateExaminations(referral.id, { term_ids: formData.examination?.map((item) => item.value) || [] });

      // await getReferral();
      return referral.id;
    }
  };

  return { referral, referralFor, referralExamination, setReferral, getReferral, loading };
}
