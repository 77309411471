import { differenceInMinutes } from 'date-fns';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { hasToken } from '~/api/axios/interceptors';
import { apiMe } from '~/api/services/authorisation';
import { getParsedAsyncItem, storageKeys } from '~/common/asyncStorage';
import { RootState } from '~/redux/reducers';
import {
  USER_DETAILS,
  UPDATE_LOGIN_DETAILS,
  userDetailsSelector,
  userLastUpdatedSelector,
} from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { getAccountName } from '~/utils/personalDetailsUtils';

export function useUserDetailsRequest() {
  const dispatch = useAppDispatch();
  const loginDetails = useSelector((state: RootState) => state.userDetailsReducer.loginDetails);
  const getUserDetails = async () => {
    const hasAuthToken = await hasToken();
    if (!hasAuthToken) return;
    const response = await apiMe();
    dispatch(USER_DETAILS(response.data));

    const storedLoginDetails = await getParsedAsyncItem(storageKeys.loginDetails, {});
    dispatch(
      UPDATE_LOGIN_DETAILS({ ...storedLoginDetails, ...loginDetails, fullName: getAccountName(response.data?.account) })
    );
    return response;
  };

  return { getUserDetails };
}

interface Props {
  allowStale?: boolean;
}
export function useUserDetails(props?: Props) {
  const userDetails = useSelector(userDetailsSelector);
  const { getUserDetails } = useUserDetailsRequest();

  useEffect(() => {
    if (userDetails && props?.allowStale) return;

    getUserDetails().catch(() => {});
  }, []);

  return { userDetails, getUserDetails };
}

export function useUserDetailsInfo(props?: Props) {
  const { userDetails } = useUserDetails(props);
  const fullName = getAccountName(userDetails?.account);
  return {
    fullName,
    userDetails,
  };
}

export function useUserImage() {
  const { getUserDetails } = useUserDetailsRequest();

  const userDetails = useSelector(userDetailsSelector);
  const userLastUpdated = useSelector(userLastUpdatedSelector);

  const getUserAccount = async () => {
    if (!userLastUpdated || differenceInMinutes(userLastUpdated, new Date()) >= 5) {
      const res = await getUserDetails().catch(() => {});
      if (!res) return userDetails?.account;
      return res.data?.account;
    } else {
      return userDetails?.account;
    }
  };

  useEffect(() => {
    if (!userLastUpdated) return;
    if (differenceInMinutes(userLastUpdated, new Date()) >= 5) {
      getUserDetails().catch(() => {});
    }
  }, [userDetails, userLastUpdated]);

  return { getUserAccount, profileImage: userDetails?.account.profile_image };
}
