import { deleteReq, get, post, put } from '../../axios';
import { AddAddressRequest } from '../../models/addresses/requests/AddAddressRequest';
import { UpdateAddressRequest } from '../../models/addresses/requests/UpdateAddressRequest';
import { AddressIndexResponse } from '../../models/addresses/responses/AddressIndexResponse';
import { IdModel } from '../../models/common/models/IdModel';
import { FilteredRequest } from '../../models/common/requests/FilteredRequest';
import { PaginatedRequest } from '../../models/common/requests/PaginatedRequest';

import { AddressIndexFilter } from '~/api/models/addresses/filters/AddressIndexFilter';

export const apiGetAddressIndex = (params: PaginatedRequest & FilteredRequest<AddressIndexFilter>) => {
  return get<AddressIndexResponse>(`/addresses`, params);
};

export const apiAddAddress = (data: AddAddressRequest) => {
  return post('/addresses', data);
};

export const apiUpdateAddress = (params: { id: number; data: UpdateAddressRequest }) => {
  return put(`/addresses/${params.id}`, params.data);
};

export const apiGetAddress = (params: IdModel) => {
  return get(`/addresses/${params.id}`);
};

export const apiDeleteAddress = (params: IdModel) => {
  return deleteReq(`/addresses/${params.id}`);
};
