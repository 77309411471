import React from 'react';
import { View } from 'react-native';

import { VersionInfoComponent } from './VersionInfoComponent';

import { mt20 } from '~/common/commonStyles';
import { MobileMenuPageComponent } from '~/components/navigation/MobileMenuPageComponent';
import { helpMenuMobile } from '~/constants/helpMenu';
import { TitleLayout } from '~/layouts/TitleLayout';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

export const HelpMobilePage: React.FC = () => {
  return (
    <TitleLayout title={labels.help} style={{ backgroundColor: colors.lightPurple }}>
      <View>
        <MobileMenuPageComponent menu={helpMenuMobile} />
        <View style={mt20}>
          <VersionInfoComponent />
        </View>
      </View>
    </TitleLayout>
  );
};
