import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ShadowButton, TextButton } from './commonButton';

import { LEFTDOTS, usePagination } from '~/api/hooks/Pagination';
import { ml5, mr5 } from '~/common/commonStyles';
import { colors } from '~/utils/colors';

export interface Props {
  page: number;
  totalPages: number;
  siblings?: number;
  handlePagination: (page: number) => void;
  alwaysShow?: boolean;
}

export const CommonPagination: React.FC<Props> = ({ page, totalPages, siblings = 2, handlePagination, alwaysShow }) => {
  const paginationRange = usePagination({
    totalPageCount: totalPages,
    siblingCount: siblings,
    currentPage: page,
  });

  if (!alwaysShow) {
    if (page === 0 || paginationRange?.length < 2) {
      return null;
    }
  }

  const onNext = () => {
    handlePagination(page + 1);
  };

  const onPrevious = () => {
    handlePagination(page - 1);
  };

  const onPress = (pageNumber: string | number) => {
    if (typeof pageNumber === 'string') {
      handlePagination(pageNumber === LEFTDOTS ? page - siblings - 2 : page + siblings + 3);
    } else {
      handlePagination(pageNumber);
    }
  };

  const lastPage = paginationRange[paginationRange?.length - 1];

  return (
    <View style={styles.container}>
      <TextButton funCallback={onPrevious} label="< Previous" disabled={page === 1} style={mr5} />

      {paginationRange.map((pageNumber) => {
        return (
          <ShadowButton
            onPress={() => onPress(pageNumber)}
            label={typeof pageNumber === 'string' ? '...' : `${pageNumber}`}
            key={pageNumber}
            style={[
              styles.button,
              page === pageNumber ? { borderColor: colors.info, backgroundColor: colors.lightGrey } : null,
            ]}
          />
        );
      })}

      <TextButton funCallback={onNext} label="Next >" disabled={page === lastPage} style={ml5} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
  button: {
    minWidth: 32,
    minHeight: 32,
    marginHorizontal: 4,
    alignItems: 'center',
    paddingHorizontal: 4,
    paddingVertical: 5,
    borderWidth: 2,
    borderColor: colors.transparent,
  },
});
