import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { flex1, ml10, mr10 } from '../../../../common/commonStyles';
import { screenName } from '../../../../utils/screenName';
import { H2TtmSemiBoldBlack, H4TtmSemiBoldBlack, H6NsRegularSecondaryBlack } from '../../../commonText';
import { NotificationBell } from '../../common/NotificationBell';

import { useUserDetailsInfo } from '~/api/hooks/accounts/UserDetails';
import { useSubscriptions } from '~/api/hooks/subscriptions/SubscriptionHook';
import { ButtonText } from '~/components/commonViews';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

interface Props {
  title?: string;
  hideImage?: boolean;
  hideNotifications?: boolean;
}
export const PatientMobileHeader: React.FC<Props> = ({ title, hideImage, hideNotifications }) => {
  const { userDetails, fullName } = useUserDetailsInfo({ allowStale: true });
  const navigation = useNavigation();
  const { isFreePlan } = useSubscriptions();
  const headerPaddingVertical = !isFreePlan ? 10 : 0;

  return (
    <View style={[styles.container]}>
      <View style={[styles.header, { paddingBottom: headerPaddingVertical }]}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate(screenName.ProfileScreen as never);
          }}>
          <ProfileImageComponent account={userDetails?.account} />
        </TouchableOpacity>
        <View
          style={[
            flex1,
            { justifyContent: 'space-between' },
            !hideImage ? ml10 : null,
            !hideNotifications ? mr10 : null,
          ]}>
          {title ? (
            <H2TtmSemiBoldBlack numberOfLines={2}>{title}</H2TtmSemiBoldBlack>
          ) : (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(screenName.ProfileScreen as never);
              }}>
              <H2TtmSemiBoldBlack numberOfLines={isFreePlan ? 1 : 2}>{fullName}</H2TtmSemiBoldBlack>
              <H6NsRegularSecondaryBlack>
                {userDetails?.account?.national_id_number || userDetails?.account?.passport_number}
              </H6NsRegularSecondaryBlack>
            </TouchableOpacity>
          )}
        </View>
        <View>{hideNotifications ? null : <NotificationBell />}</View>
      </View>
      {isFreePlan ? (
        <TouchableOpacity
          style={styles.upgradeContainer}
          onPress={() => {
            navigation.navigate(screenName.ProfileSubscriptionPlan as never);
          }}>
          <View style={styles.upgradeCard}>
            <H4TtmSemiBoldBlack>{labels.freeAccount}</H4TtmSemiBoldBlack>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(screenName.ProfileSubscriptionPlan as never);
              }}>
              <ButtonText style={{ color: colors.darkPurple }}>{labels.upgrade}</ButtonText>
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: colors.lightPurple,
    paddingBottom: 10,
    paddingTop: 5,
  },
  header: {
    flexDirection: 'row',
    paddingHorizontal: 25,
    paddingTop: 10,
    alignItems: 'center',
  },
  upgradeContainer: {
    flexDirection: 'row',
    paddingHorizontal: 25,
  },
  upgradeCard: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    backgroundColor: colors.fadedTurquoise,
    paddingHorizontal: 25,
    borderRadius: 6,
    paddingVertical: 10,
  },
});
