import { deleteReq, get, post } from '~/api/axios';
import { HasDigimedAccountRequest } from '~/api/models/accounts/requests/HasDigimedAccountRequest';
import { DoctorStartAssociationResponse } from '~/api/models/accounts/responses/DoctorStartAssociationResponse';
import { HasDigimedAccountResponse } from '~/api/models/accounts/responses/HasDigimedAccountResponse';

export function apiGetHasDigimedAccount(req: HasDigimedAccountRequest) {
  return get<HasDigimedAccountResponse>('/has-digimed-account', req);
}

export function apiDoctorStartAssociation(accountId: number) {
  return post<DoctorStartAssociationResponse>(`/accounts/${accountId}/associate/start`);
}

export function apiAcceptAssociationRequest(token: string) {
  return post('/accounts/associate', { token });
}

export function apiDeclineAssociationRequest(token: string) {
  return deleteReq('/accounts/associate', { token });
}

export function apiValidateAssociationRequest(token: string) {
  return post('/accounts/associate/token-validity', { token });
}
