import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { HomeVisitAvailableSlot } from './model/homeAvailableSlot';
import { AvailableSlotsSelection } from '../../../../common/booking/AvailableSlotsSelection';

import { AvailabilitySlotsResponse } from '~/api/models/common/responses/AvailabilitySlotsResponse';
import { mv20 } from '~/common/commonStyles';
import { splitSlots } from '~/utils/availabilitySlotsUtil';
import { DATE_FORMAT, DATE_TIME_FORMAT, parseDateTime, stringToDate } from '~/utils/dateAndTime';

interface Props {
  appointment: HomeVisitAvailableSlot;
  selected?: HomeVisitAvailableSlot;
  onChange: (value: HomeVisitAvailableSlot) => void;
  errorMessage?: string;
}

export const HomeVisitDepartureTimeSlots: React.FC<Props> = ({ appointment, onChange, errorMessage, selected }) => {
  const [slots, setSlots] = useState<AvailabilitySlotsResponse>();

  useEffect(() => {
    const startTime = stringToDate(`${appointment.date} ${appointment.time_from}`, { parseFormat: DATE_TIME_FORMAT });

    const slots = splitSlots(startTime);
    setSlots({
      [parseDateTime(startTime, { outputFormat: DATE_FORMAT })]: slots,
    });
  }, [appointment]);

  return (
    <View style={mv20}>
      <AvailableSlotsSelection
        loading={false}
        errorMessage={errorMessage}
        onChange={(value) => {
          onChange({ time_to: value.to, time_from: value.from, date: value.date });
        }}
        slots={slots}
        selected={{ from: selected?.time_from, to: selected?.time_to, date: selected?.date }}
      />
    </View>
  );
};
