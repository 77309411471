import { DocumentPickerAsset } from 'expo-document-picker';
import * as WebBrowser from 'expo-web-browser';
import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { colors } from '../../utils/colors';
import { H6NsRegularBlack } from '../commonText';
import { DeleteIcon, EyeIcon } from '../svgImages';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { isDocumentResult } from '~/utils/files/isDocumentResult';
import { isMediaModel } from '~/utils/files/isMediaModel';
import { formatUuidName } from '~/api/utils/healthProfile/formatUuidName';

interface Props {
  file: DocumentPickerAsset | MediaModel;
  hidePreview?: boolean;
  remove?: () => void;
  disabled?: boolean;
  nameIfEmpty?: string;
}

export const DocumentNameWithPreview: React.FC<Props> = ({ file, remove, disabled, hidePreview, nameIfEmpty }) => {
  const fileName = useMemo(() => {
    const name = ('name' in file ? file.name : '') ?? nameIfEmpty;
    return formatUuidName(name);
  }, [file]);

  const disabledPreview = useMemo(() => isDocumentResult(file), [file]);
  const preview = async () => {
    if (disabledPreview) return;
    if (isMediaModel(file)) await WebBrowser.openBrowserAsync(file.url);
  };

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <H6NsRegularBlack style={{ fontStyle: 'italic', width: '100%' }}>{fileName}</H6NsRegularBlack>
      </View>
      <View style={styles.container}>
        {hidePreview || disabledPreview ? null : (
          <TouchableOpacity
            disabled={disabledPreview}
            style={{ display: 'flex', justifyContent: 'center', padding: 8, marginLeft: 5 }}
            onPress={preview}>
            <EyeIcon width={12} height={12} color={disabledPreview ? colors.purpleGrey : colors.purple} />
          </TouchableOpacity>
        )}
        {remove && !disabled ? (
          <TouchableOpacity
            style={{ display: 'flex', justifyContent: 'center', padding: 8, marginLeft: 5 }}
            onPress={remove}>
            <DeleteIcon width={12} height={12} color={colors.danger} />
          </TouchableOpacity>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
