import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { OtpChannelEnum } from '../../api/models/otp/constants/OtpChannelEnum';
import { apiEnrolmentTotp, apiSetPreferred2faChannel } from '../../api/services/registration';
import { Button } from '../../components/commonButton';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import { isPatientVersion } from '../../utils/buildConfig';
import { labels } from '../../utils/labels';
import { screenName } from '../../utils/screenName';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { TotpEnrolmentModel } from '~/api/models/registration/models/TotpEnrolmentModel';
import { AuthenticatorEnrolmentModal } from '~/components/modals/AuthenticatorEnrolmentModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { TwoStepVerificationButton } from '~/components/registration/TwoStepVerificationButton';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { NavType } from '~/navigation/types';
import { useModalManager } from '~/providers/modal/ModalManagementContext';

export const TwoStepSelectionScreen: React.FC = () => {
  const { userDetails } = useUserDetails();
  const [selectedOption, setOption] = useState<OtpChannelEnum | null>(null);
  const [totpEnrolment, setTotpEnrolment] = useState<TotpEnrolmentModel>(null);
  const { updateRegistrationStatus, loading } = useRegistrationSteps();
  const navigation = useNavigation<NavType>();
  const { registerModal, openModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.TotpEnrolment, AuthenticatorEnrolmentModal);
  }, []);

  const isMobileVerified = useMemo(
    () => !!userDetails?.mobile_number_verified_at,
    [userDetails?.mobile_number_verified_at]
  );

  const isEmailVerified = useMemo(() => !!userDetails?.email_verified_at, [userDetails?.email_verified_at]);

  const setTwoStepVerification = (channel: OtpChannelEnum) => {
    apiSetPreferred2faChannel({ channel })
      .then(() => {
        setOption(channel);
      })
      .catch((error) => {
        ErrorAlert(error);
      });
  };

  const setGoogleAuthentication = async () => {
    if (userDetails.two_step_verification_type !== OtpChannelEnum.GOOGLE) {
      await apiEnrolmentTotp()
        .then((value) => {
          setTotpEnrolment(value.data);
          openModal(ModalName.TotpEnrolment, {
            totpEnrolment: value.data,
            onSuccess: () => setOption(OtpChannelEnum.GOOGLE),
          });
        })
        .catch((error) => {
          ErrorAlert(error);
        });
    } else {
      openModal(ModalName.TotpEnrolment, {
        totpEnrolment,
        onSuccess: () => setOption(OtpChannelEnum.GOOGLE),
      });
    }
  };

  const buttons = [
    <Button
      funCallback={() => {
        if (!isPatientVersion()) {
          updateRegistrationStatus();
        } else {
          navigation.navigate(screenName.UploadIdentificationScreen);
        }
      }}
      disabled={!selectedOption || loading}
      label={labels.continue}
    />,
  ];

  return (
    <EnhancedRegistrationLayout
      title={labels.twoStepVerification}
      subtitle={labels.twoStepVerificationSubtitle}
      compressed
      buttons={buttons}>
      <View style={{ width: '100%', minHeight: 180 }}>
        {isMobileVerified ? (
          <TwoStepVerificationButton
            selectedOption={selectedOption}
            setTwoStepVerification={setTwoStepVerification}
            title={labels.securityCodeSentViaSMS}
            value={OtpChannelEnum.SMS}
          />
        ) : null}
        {isEmailVerified ? (
          <TwoStepVerificationButton
            selectedOption={selectedOption}
            setTwoStepVerification={setTwoStepVerification}
            title={labels.securityCodeSentViaEmail}
            value={OtpChannelEnum.EMAIL}
          />
        ) : null}
        <TwoStepVerificationButton
          selectedOption={selectedOption}
          setTwoStepVerification={setGoogleAuthentication}
          title={labels.securityCodeViaAuthenticatorApp}
          value={OtpChannelEnum.GOOGLE}
        />
      </View>
    </EnhancedRegistrationLayout>
  );
};
