export const accountDeletionLabels = {
  //Doctor
  permanentlyDeleteAccount: 'Permanently Delete Account',
  exportAccountInfo: 'Export $appName account data',
  delete: {
    subtitle:
      'If you want to permanently delete your $appName account, kindly go through the information below and complete all actions to download your information.',

    //Doctor
    exportPatientsListMessage:
      'Before deleting your account, please export all patient data and health records that you have generated during your use of the platform. Please refer to our privacy policy for more information about our data retention policies.',
    exportAppointmentsListMessage:
      'Before deleting your account, please export the list of appointments you have generated while using the platform. For further information regarding our data retention policies, kindly refer to our privacy policy.',
    exportFinancialDataMessage:
      'Before you proceed with the deletion of your account, please ensure that all Stripe payouts have been successfully processed and received into your account. Once your account is deleted, you may not have access to historical transaction data or the ability to resolve any outstanding payout issues.',

    //Patient
    exportConsultationReportsMessage:
      'Before deleting your account, please export all health records that you have generated during your use of the platform. Please refer to our privacy policy for more information about our data retention policies.',
    exportHealthProfileMessage:
      'Before deleting your account, we recommend exporting your health profile and that of your dependents. This includes vital information about your allergies, medications, and other relevant health details. Storing this data can be valuable for your future healthcare needs.',
    exportPersonalInformationMessage:
      'Before deleting your account, please export your personal information and that of your dependents that you have generated during your use of the platform. Please refer to our privacy policy for more information about our data retention policies.',
  },
  export: {
    subtitle:
      'If you want to export your $appName account data, kindly go through the information below and complete all actions to download your information.',
    //Doctor
    exportPatientsListMessage:
      'Export all patient data and health records that you have generated during your use of the platform.',
    exportAppointmentsListMessage: 'Export the list of appointments you have generated while using the platform.',
    exportFinancialDataMessage:
      'Export all your financial data that you have generated during your use of the platform.',

    //Patient
    exportConsultationReportsMessage:
      'Export all health records that you have generated during your use of the platform.',
    exportHealthProfileMessage:
      'Export your health profile and that of your dependents. This includes vital information about your allergies, medications, and other relevant health details. Storing this data can be valuable for your future healthcare needs.',
    exportPersonalInformationMessage:
      'Export your personal information and that of your dependents that you have generated during your use of the platform.',
  },
  exportPatientListTitle: 'Export List of Patients and Health Reports',
  exportPatientListButtonLabel: 'Export Patients List',

  exportAppointmentsListTitle: 'Export List of Appointments',
  exportAppointmentsButtonLabel: 'Export Appointments',

  exportFinancialDataTitle: 'Export Financial Data',

  //Patient
  exportConsultationReportsTitle: 'Export Consultation Reports',

  exportHealthProfileTitle: 'Export Health Profile',

  exportPersonalInformationTitle: 'Export Personal Information',

  confirmDeletion: 'Confirm Account Deletion',
};
