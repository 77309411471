import React from 'react';

import { DependantDetailsStep } from '../steps/DependantDetailsStep';
import { RelationshipMediaStep } from '../steps/RelationshipMediaStep';

import { WizardSteps } from '~/utils/hooks/WizardStepsHook';

export const DependantWizardSteps: WizardSteps = [
  { key: 'patient_details', component: <DependantDetailsStep /> },
  { key: 'relationship_details', component: <RelationshipMediaStep /> },
];
