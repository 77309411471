import { useNavigation } from '@react-navigation/native';
import React, { useMemo, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, FlatList } from 'react-native';

import { usePatientDiagnoses } from '~/api/hooks/patients/PatientDiagnosesHook';
import { H5NsSemiBoldSecondaryBlack, H6NsRegularBlack, SmallNsRegularSecondaryBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { NewBadge } from '~/components/misc/NewBadge';
import { ForwardArrow } from '~/components/svgImages';
import { NavType } from '~/navigation/types';
import { isPatientVersion } from '~/utils/buildConfig';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { colors } from '~/utils/colors';
import { DATE_TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

interface Props {
  patientId: number;
}
const DIAGNOSES_LIST_MINIMUM = 3;
const RECORD_HEIGHT = 100;
const EXTRA_CONTENT_HEIGHT = 120;

export const DiagnosisListCard: React.FC<Props> = ({ patientId }) => {
  const [containerHeight, setContainerHeight] = useState<number>(undefined);
  const { diagnoses, loading } = usePatientDiagnoses({ accountId: patientId, isInfiniteScroll: false });
  const { navigate } = useNavigation<NavType>();
  const { showNewBadge } = useNewBadge();

  const rowPress = (key: number) => {
    if (!key) return;
    navigate(screenName.PatientVersionDiagnosis, { diagnosisId: key });
  };

  const recordCount = useMemo(
    () => Math.max(DIAGNOSES_LIST_MINIMUM, Math.floor((containerHeight - EXTRA_CONTENT_HEIGHT) / RECORD_HEIGHT)),
    [diagnoses, containerHeight]
  );

  const slicedDiagnoses = useMemo(() => {
    return diagnoses?.slice(0, recordCount) || [];
  }, [diagnoses, recordCount]);

  const height = useMemo(
    () => (slicedDiagnoses.length ? recordCount * RECORD_HEIGHT + EXTRA_CONTENT_HEIGHT : undefined),
    [recordCount, slicedDiagnoses]
  );

  const onLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    setContainerHeight(height);
  };
  const renderItem = ({ item: renderItem }) => (
    <View style={{ maxHeight: height, paddingHorizontal: 4 }}>
      {showNewBadge({ value: renderItem, timeKey: 'updated_at' }) ? <NewBadge style={styles.newBadge} /> : null}
      <TouchableOpacity onPress={() => rowPress(renderItem.id)}>
        <View style={styles.rowStyle}>
          <View style={{ flex: 1 }}>
            <View style={{ marginBottom: 14, flex: 1 }}>
              <H5NsSemiBoldSecondaryBlack>
                {extractClinicalTermDescription(renderItem.diagnosis)}
              </H5NsSemiBoldSecondaryBlack>
            </View>
            <View>
              <H6NsRegularBlack>Ref No: {renderItem.reference}</H6NsRegularBlack>
            </View>
            <View>
              <H6NsRegularBlack>
                {parseDateTime(renderItem.updated_at, { outputFormat: DATE_TIME_FORMAT })}
              </H6NsRegularBlack>
            </View>
          </View>
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ForwardArrow color={colors.purple} width="15" height="15" />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );

  return (
    <View style={styles.container} onLayout={onLayout}>
      <FlatList
        data={slicedDiagnoses || []}
        ListEmptyComponent={
          loading ? (
            <View>
              <LoadingActivityIndicator />
            </View>
          ) : (
            <SmallNsRegularSecondaryBlack>
              {isPatientVersion() ? labels.noCurrentDiagnosisPatient : labels.noCurrentDiagnosisDoctor}
            </SmallNsRegularSecondaryBlack>
          )
        }
        renderItem={renderItem}
        style={{ flex: 1 }}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
  },
  rowStyle: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    borderBottomColor: colors.transparent,
    borderRadius: 6,
    width: '100%',
    padding: 18,
    marginVertical: 7,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
  },
  newBadge: {
    position: 'absolute',
    left: 7,
    top: 10,
    zIndex: 5,
  },
});
