import React, { useState } from 'react';

import { INavigate } from '~/common/types/navigation/navigate';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { PatientLifestyle } from '~/components/doctor/patients/healthProfile/lifestyle/PatientLifestyle';
import { GridLoadingWrapper } from '~/components/loading/GridLoadingWrapper';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { Grid, Container } from '~/theme/components/grid';
import { labels } from '~/utils/labels';

interface Props {
  edit?: boolean;
  backLink: INavigate;
}

export const HealthProfileLifestyleMobile: React.FC<Props> = ({ edit, backLink }) => {
  const [editing, setEditing] = useState(edit);

  const { submit, waiting, loading, reset } = useHealthProfileDataContext();

  const footer = (
    <CancelContinueFooter
      onCancel={() => {
        reset();
        setEditing(false);
      }}
      onConfirm={() => {
        if (!editing) {
          setEditing(true);
        } else {
          submit().then(() => setEditing(false));
        }
      }}
      hideCancel={!editing}
      confirmTitle={editing ? labels.update : labels.edit}
      waiting={waiting}
    />
  );

  return (
    <Grid>
      <Container style={{ height: '100%' }}>
        <GridLoadingWrapper loading={loading}>
          <PatientsLayout hideHeaderRight back={{ to: backLink }} footer={footer} flexContent={false}>
            <PatientLifestyle locked={!editing} />
          </PatientsLayout>
        </GridLoadingWrapper>
      </Container>
    </Grid>
  );
};
