import React from 'react';

import { HealthRecord, NoteIcon, Pill, SickLeaveCert, Stethoscope } from '~/components/svgImages';
import { colors } from '~/utils/colors';
import { screenName } from '~/utils/screenName';

export const DOCTOR_CONSULTATION_PAGES = [
  {
    title: 'Private Notes',
    link: { screen: screenName.PatientConsultationPrivateNotes },
    icon: <NoteIcon width={16} height={16} color={colors.black} />,
  },
  {
    title: 'Health Record',
    link: { screen: screenName.PatientConsultationHealthRecord },
    icon: <HealthRecord width={16} height={16} />,
  },
  {
    title: 'Prescription',
    link: { screen: screenName.PatientConsultationPrescription },
    icon: <Pill width={16} height={16} />,
  },
  {
    title: 'Sick Leave Certificate',
    link: { screen: screenName.PatientConsultationSickLeave },
    icon: <SickLeaveCert width={16} height={16} />,
  },
  {
    title: 'Referrals',
    link: { screen: screenName.PatientConsultationReferral },
    icon: <Stethoscope width={16} height={16} />,
  },
];

export const PATIENT_CONSULTATION_PAGES = [
  {
    title: 'Health Record',
    link: { screen: screenName.PatientConsultationHealthRecord },
    icon: <HealthRecord width={16} height={16} />,
  },
  {
    title: 'Prescription',
    link: { screen: screenName.PatientConsultationPrescription },
    icon: <Pill width={16} height={16} />,
  },
  {
    title: 'Sick Leave Certificate',
    link: { screen: screenName.PatientConsultationSickLeave },
    icon: <SickLeaveCert width={16} height={16} />,
  },
  {
    title: 'Referrals',
    link: { screen: screenName.PatientConsultationReferral },
    icon: <Stethoscope width={16} height={16} />,
  },
];
