import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import { p10 } from '~/common/commonStyles';
import { colors } from '~/utils/colors';

interface Props {
  style?: StyleProp<ViewStyle>;
}
export const LoadingActivityIndicator: React.FC<Props> = ({ style }) => {
  return (
    <View style={[styles.container, style]}>
      <View style={p10}>
        <ActivityIndicator color={colors.info} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
