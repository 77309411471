import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PrescriptionRequestsPaginatedIndexResponse } from '~/api/models/pharmacy/responses/PrescriptionRequestsIndexResponse';

interface IState {
  prescriptionRequests: PrescriptionRequestsPaginatedIndexResponse;
}

const initialState: IState = {
  prescriptionRequests: undefined,
};

const slice = createSlice({
  name: 'pharmacyPrescriptions',
  initialState,
  reducers: {
    SET_PRESCRIPTION_REQUESTS: (
      state,
      action: PayloadAction<{
        data: PrescriptionRequestsPaginatedIndexResponse;
      }>
    ) => ({
      ...state,
      prescriptionRequests: action.payload.data,
    }),
    LOGOUT: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const { LOGOUT, SET_PRESCRIPTION_REQUESTS } = slice.actions;
export default slice.reducer;
