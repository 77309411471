import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MenuItem, menusVerificationPending } from './menuCommon';

import { useVerificationStatus } from '~/api/hooks/accounts/VerificationStatus';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import {
  HomeOutline,
  PatientGroup,
  Calendar,
  Wallet,
  PackageIcon,
  HealthCare,
  Pill,
  DispenseHistoryIcon,
  RequestHistoryIcon,
  Menu,
  SecondarySettings,
} from '~/components/svgImages';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { isPharmacyVersion, whenAppType } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { digimedFeatureEnabled, DigimedFeatures } from '~/utils/milestoneUtil';
import { screenName } from '~/utils/screenName';
import { PharmacyStateEnum } from '~/api/models/pharmacy/constants/PharmacyStateEnum';

export const useMenusNative = () => {
  const { featureAccess, hasFeatureAccess } = useFeatureAccess();
  const accountStatus = useVerificationStatus();
  const currentPharmacy = useSelector(currentPharmacySelector);

  const doctorMenu: MenuItem[] = [
    {
      label: 'Home',
      image: (color) => <HomeOutline height={20} width={20} color={color} />,
      screenName: screenName.Dashboard,
      hidden: !digimedFeatureEnabled(DigimedFeatures.CalendarAndAppointments),
    },
    {
      label: 'Patients',
      image: (color) => <PatientGroup height={20} width={20} color={color} />,
      screenName: screenName.PatientManagement,
      hidden: !digimedFeatureEnabled(DigimedFeatures.PatientManagementSystem),
    },
    {
      label: 'Calendar',
      image: (color) => <Calendar height={20} width={20} color={color} />,
      screenName: screenName.CalendarPage,
      hidden: !digimedFeatureEnabled(DigimedFeatures.CalendarAndAppointments),
    },
    {
      label: 'Income',
      image: (color) => <Wallet height={20} width={20} color={color} />,
      screenName: screenName.Income,
      hidden: !digimedFeatureEnabled(DigimedFeatures.IncomeAnalysis) || !hasFeatureAccess(FeatureAccessEnum.INCOME),
    },
  ];

  const patientMenu: MenuItem[] = [
    {
      label: 'Home',
      image: (color) => <HomeOutline height={20} width={20} color={color} />,
      screenName: screenName.Dashboard,
    },
    {
      label: 'Doctors',
      image: (color) => <HealthCare height={20} width={20} color={color} />,
      screenName: screenName.Doctors,
    },
    {
      label: 'Appointments',
      image: (color) => <Calendar height={20} width={20} color={color} />,
      screenName: screenName.Appointments,
    },
    {
      label: 'Prescriptions',
      image: (color) => <Pill height={20} width={20} color={color} />,
      screenName: screenName.PrescriptionScreen,
    },
  ];

  const pharmacistMenu: MenuItem[] = [
    {
      label: 'Home',
      image: (color = colors.purple) => <HomeOutline height={20} width={20} color={color} />,
      screenName: screenName.Dashboard,
    },
    {
      label: 'Awaiting collection',
      image: (color = colors.purple) => <PackageIcon height={20} width={20} color={color} />,
      screenName: screenName.PharmacyAwaitingCollection,
    },
    {
      label: 'Dispense History',
      image: (color = colors.purple) => <DispenseHistoryIcon height={20} width={20} color={color} />,
      screenName: screenName.PharmacyDispenseHistory,
    },
    {
      label: 'Request History',
      image: (color = colors.purple) => <RequestHistoryIcon height={20} width={20} color={color} />,
      screenName: screenName.PharmacyRequestHistory,
    },
  ];

  const clinicMenu: MenuItem[] = [
    {
      label: 'Home',
      image: (color) => <HomeOutline height={20} width={20} color={color} />,
      screenName: screenName.Dashboard,
      hidden: !digimedFeatureEnabled(DigimedFeatures.CalendarAndAppointments),
    },
    {
      label: 'Patients',
      image: (color) => <PatientGroup height={20} width={20} color={color} />,
      screenName: screenName.PatientManagement,
      hidden: !digimedFeatureEnabled(DigimedFeatures.PatientManagementSystem),
    },
    {
      label: 'Calendar',
      image: (color) => <Calendar height={20} width={20} color={color} />,
      screenName: screenName.CalendarPage,
      hidden: !digimedFeatureEnabled(DigimedFeatures.CalendarAndAppointments),
    },
    {
      label: 'Income',
      image: (color) => <Wallet height={20} width={20} color={color} />,
      screenName: screenName.Income,
      hidden: !digimedFeatureEnabled(DigimedFeatures.IncomeAnalysis),
      disabled: true,
    },
  ];

  const menu = useMemo(() => {
    if (!accountStatus?.approved) return menusVerificationPending[0];

    const menu = whenAppType({
      whenDoctor: doctorMenu,
      whenPatient: patientMenu,
      whenPharmacist: pharmacistMenu,
      whenClinic: clinicMenu,
    });

    return [
      ...menu.filter((item) => !item.hidden),
      {
        key: 'menu',
        image: (color) => <Menu height={20} width={20} color={color} />,
      },
    ];
  }, [featureAccess, accountStatus?.approved, currentPharmacy?.is_admin, currentPharmacy]);

  if (isPharmacyVersion()) {
    if (!currentPharmacy || currentPharmacy?.pharmacy?.state !== PharmacyStateEnum.APPROVED) {
      return menusVerificationPending;
    }
  }

  return { menu };
};
