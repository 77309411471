import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { Examinations } from './Examinations';
import { OtherForms } from './OtherForms';
import { ReferralForm } from './ReferralForm';
import { ReferralRadioButtons } from './ReferralRadioButtons';
import { RequestFor } from './RequestFor';
import { SelectUploadForm } from './SelectUploadForm';

import { ReferralMediaTypeModel } from '~/api/models/referrals/models/ReferralMediaTypeModel';
import { apiGetReferralMediaTypes } from '~/api/services/consultations/referral';
import { mb20, mv15, mv20 } from '~/common/commonStyles';
import { LoadingFetchingList } from '~/components/loading/LoadingFetchingList';
import { useConsultationReportContext } from '~/contexts/ConsultationReportProvider';
import { useReferralDataContext } from '~/providers/consultation/ReferralDataContext';

export const ReferralsEditMode: React.FC = () => {
  const [mediaTypeList, setMediaTypeList] = useState<ReferralMediaTypeModel[]>([]);

  const { appointmentView } = useConsultationReportContext();
  const {
    form: { watch, setValue },
    loading,
    referral,
    referralMediaFiles,
    addFile,
    removeFile,
  } = useReferralDataContext();

  useEffect(() => {
    apiGetReferralMediaTypes().then((res) => {
      setMediaTypeList(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (watch('refer_patient') === false) {
      setValue('additional_comments', '');
      setValue('indications', '');
      setValue('last_menstrual_period', '');
      setValue('presenting_complaint', '');
      setValue('referral_media_type', '');
      setValue('special_comment', '');
    }
  }, [watch('refer_patient')]);

  if (loading && !referral) return <LoadingFetchingList fullWidth />;
  return (
    <View>
      <View style={mv15}>
        <ReferralRadioButtons />
      </View>

      {watch('refer_patient') ? (
        <>
          <View style={mb20}>
            <ReferralForm />
          </View>
          <View style={mb20}>
            <RequestFor />
          </View>
          <View style={mb20}>
            <Examinations />
          </View>

          <>
            {mediaTypeList.length ? (
              <>
                <View style={mv20}>
                  <OtherForms mediaType={mediaTypeList} />
                </View>

                <View style={mv20}>
                  <SelectUploadForm
                    mediaTypeList={mediaTypeList}
                    referralMediaFiles={referralMediaFiles}
                    addFile={addFile}
                    removeFile={removeFile}
                    horizontalUpload={!appointmentView}
                  />
                </View>
              </>
            ) : null}
          </>
        </>
      ) : null}
    </View>
  );
};
