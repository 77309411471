import React, { useContext } from 'react';

export interface IProfileCompletionContext {
  loading: boolean;
  healthProfile?: boolean;
  upgradedPlan?: boolean;
  familyMembers?: boolean;
  insurance?: boolean;
}

export const ProfileCompletionContext = React.createContext<IProfileCompletionContext>({} as IProfileCompletionContext);
export const useProfileCompletionContext = (): IProfileCompletionContext => useContext(ProfileCompletionContext);
