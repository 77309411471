import React from 'react';

import { ArticlePostThumbnail } from './ArticlePostThumbnail';

import { EducationArticleThumbnailModel } from '~/api/models/educationCentre/models/EducationArticleThumbnailModel';
import { Column, Row } from '~/theme/components/grid';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  items: EducationArticleThumbnailModel[];
}

export const ArticlePostChunk: React.FC<Props> = ({ items }) => {
  const { isMobile } = useBreakpoints();
  return (
    <Row style={{ justifyContent: 'center' }}>
      {items.map((element, index) => {
        return (
          <Column span={{ xs: 12, md: index > 3 ? 4 : 3 }} key={element.id}>
            <ArticlePostThumbnail article={element} imageSize={!isMobile && index > 3 ? 68 : 50} />
          </Column>
        );
      })}
    </Row>
  );
};
