import React from 'react';
import { View } from 'react-native';

import { colors } from '../../utils/colors';
import { labels } from '../../utils/labels';
import { H1TtmSemiBoldBlack, H6NsRegularSecondaryBlack } from '../commonText';
import { AppLogoWithText } from '../svgImages';
import RegistrationStepsComponent from './RegistrationStepsComponent';

export const RegistrationStepperComponent: React.FC = () => {
  return (
    <View
      style={{
        flex: 1,
        display: 'flex',
        padding: 80,
        backgroundColor: colors.lightPurple,
      }}>
      <View style={{ marginBottom: 60 }}>
        <AppLogoWithText width={185} height={undefined} />
      </View>
      <H1TtmSemiBoldBlack>{labels.registrationStepperTitle}</H1TtmSemiBoldBlack>

      <H6NsRegularSecondaryBlack>
        {labels.registrationStepperSubMessage1}
        <H6NsRegularSecondaryBlack style={{ color: 'red' }}>*</H6NsRegularSecondaryBlack>
        {labels.registrationStepperSubMessage2}
      </H6NsRegularSecondaryBlack>
      <RegistrationStepsComponent />
    </View>
  );
};
