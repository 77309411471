import Ionicons from '@expo/vector-icons/Ionicons';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { useSelector } from 'react-redux';

import { mr5 } from '~/common/commonStyles';
import { SmallNsRegularPlaceholder } from '~/components/commonText';
import { consultationSavingSelector } from '~/redux/reducers/consultationDetailsReducer';
import { colors } from '~/utils/colors';

interface Props {
  saving?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const AutoSaveState: React.FC<Props> = ({ saving, style }) => {
  const consultationSaving = useSelector(consultationSavingSelector);
  return (
    <View style={[{ minWidth: 72 }, style]}>
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {saving || consultationSaving ? (
          <>
            <Ionicons name="sync" size={16} style={mr5} color={colors.placeholderGrey} />
            <SmallNsRegularPlaceholder>Saving</SmallNsRegularPlaceholder>
          </>
        ) : (
          <>
            <Ionicons name="cloud-done-outline" size={16} style={mr5} color={colors.placeholderGrey} />
            <SmallNsRegularPlaceholder>Saved</SmallNsRegularPlaceholder>
          </>
        )}
      </View>
    </View>
  );
};
