import { PrescriptionFrequencyEnum } from '~/api/models/consultations/constants/PrescriptionFrequencyEnum';
import { IPrescriptionFrequency } from '~/components/doctor/patients/consultation/tabs/Prescriptions/Edit/PrescriptionsEditMode';

export const PrescriptionFrequencyList: IPrescriptionFrequency[] = [
  {
    id: PrescriptionFrequencyEnum.BD,
    name: `${PrescriptionFrequencyEnum.BD} (twice daily)`,
    times: '8am & 8pm',
    radioButtonValue: `${PrescriptionFrequencyEnum.BD} - 8am & 8pm`,
  },
  {
    id: PrescriptionFrequencyEnum.NOCTE,
    name: `${PrescriptionFrequencyEnum.NOCTE} (evening only)`,
    times: '8pm',
    radioButtonValue: `${PrescriptionFrequencyEnum.NOCTE} - 8pm`,
  },
  {
    id: PrescriptionFrequencyEnum.TDS,
    name: `${PrescriptionFrequencyEnum.TDS} (three times daily)`,
    times: '8am, 2pm, 8pm',
    radioButtonValue: `${PrescriptionFrequencyEnum.TDS} - 8am, 2pm, 8pm`,
  },
  {
    id: PrescriptionFrequencyEnum.AM,
    name: `${PrescriptionFrequencyEnum.AM} (morning only)`,
    times: '8am',
    radioButtonValue: `${PrescriptionFrequencyEnum.AM} - 8am`,
  },
  {
    id: PrescriptionFrequencyEnum.QDS,
    name: `${PrescriptionFrequencyEnum.QDS} (four times daily)`,
    times: '8am, 12pm, 4pm, 8pm',
    radioButtonValue: `${PrescriptionFrequencyEnum.QDS} - 8am, 12pm, 4pm, 8pm`,
  },
  {
    id: PrescriptionFrequencyEnum.PRN,
    name: `${PrescriptionFrequencyEnum.PRN} (as needed)`,
    times: 'As needed',
    radioButtonValue: `${PrescriptionFrequencyEnum.PRN} - As needed`,
  },
];
