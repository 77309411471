import { activateKeepAwakeAsync, deactivateKeepAwake } from 'expo-keep-awake';
import { useRef, useState } from 'react';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { OTConnectionState, OTVideoState } from '~/integrations/openTok/types/OTConnectionState';
import { OTPublisherEvents, OTSessionEvents, OTSubscriberEvents } from '~/integrations/openTok/types/OTEvents';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { isNative } from '~/utils/buildConfig';

export const useConnectionStateHook = () => {
  const [joinedCall, setJoinedCall] = useState(false);
  const [connectionState, setConnectionState] = useState<OTConnectionState>(OTConnectionState.Initial);
  const publisherConnectionsRef = useRef<string[]>([]);
  const subscriberConnectionsRef = useRef<string[]>([]);
  const { showSnackbar } = useSnackbarManager();
  const [subscriberConnectionState, setSubscriberConnectionState] = useState<OTConnectionState>(
    OTConnectionState.Initial
  );
  const [subscriberVideoState, setSubscriberVideoState] = useState<OTVideoState>(OTVideoState.Disabled);
  const [publisherVideoState, setPublisherVideoState] = useState<OTVideoState>(OTVideoState.Disabled);

  const { userDetails } = useUserDetails({ allowStale: true });

  const updateJoinedCall = async (join: boolean) => {
    setJoinedCall(join);

    // Keep device awake during the call
    try {
      if (join) {
        setSubscriberConnectionState(OTConnectionState.Initial);

        await activateKeepAwakeAsync('call');
      } else {
        await deactivateKeepAwake('call');
      }
    } catch {}
  };

  const transitionConnectionState = (state: OTConnectionState) => {
    setConnectionState(state);
    if (state === OTConnectionState.Disconnected && joinedCall) {
      updateJoinedCall(false);
    }
  };

  const connectionCreated: OTSessionEvents['connectionCreated'] = (ev) => {
    const connection = 'connectionId' in ev ? ev : ev.connection;
    const connectionUserId = Number(connection.data);

    if (userDetails.id === connectionUserId) {
      if (!publisherConnectionsRef.current.includes(connection.connectionId)) {
        publisherConnectionsRef.current.push(connection.connectionId);
        if (!subscriberConnectionsRef.current.length) setSubscriberConnectionState(OTConnectionState.Initial);
      }
    } else {
      if (!subscriberConnectionsRef.current.includes(connection.connectionId))
        subscriberConnectionsRef.current.push(connection.connectionId);
      setSubscriberConnectionState(OTConnectionState.Connected);
    }
  };

  const connectionDestroyed = (ev) => {
    const connection = 'connectionId' in ev ? ev : ev.connection;
    const connectionUserId = Number(connection.data);

    if (userDetails.id === connectionUserId) {
      const index = publisherConnectionsRef.current.indexOf(connection.connectionId);
      if (index > -1) {
        const copiedArray = [...publisherConnectionsRef.current];
        copiedArray.splice(index, 1);
        publisherConnectionsRef.current = copiedArray;
      }
    } else {
      const index = subscriberConnectionsRef.current.indexOf(connection.connectionId);
      if (index > -1) {
        const copiedArray = [...subscriberConnectionsRef.current];
        copiedArray.splice(index, 1);
        subscriberConnectionsRef.current = copiedArray;
      }
      if (!subscriberConnectionsRef.current.length) setSubscriberConnectionState(OTConnectionState.Disconnected);
    }
  };

  const sessionEventHandlers: OTSessionEvents = {
    connectionCreated,
    connectionDestroyed,
    sessionReconnecting: () => transitionConnectionState(OTConnectionState.Reconnecting),
    sessionConnected: (ev) => {
      transitionConnectionState(OTConnectionState.Connected);
      const connectionId = 'connection' in ev ? ev.connection?.connectionId : ev.target.connection?.connectionId;
      publisherConnectionsRef.current.push(connectionId);
    },
    sessionDisconnected: () => transitionConnectionState(OTConnectionState.Disconnected),
    sessionReconnected: () => transitionConnectionState(OTConnectionState.Connected),
  };

  const subscriberEventHandlers: OTSubscriberEvents = {
    connected: () => {
      if (subscriberConnectionState === OTConnectionState.Reconnecting)
        setSubscriberConnectionState(OTConnectionState.Connected);
    },
    disconnected: () => {
      setSubscriberConnectionState(OTConnectionState.Reconnecting);
    },
    videoDisableWarning: () => {
      setSubscriberVideoState(OTVideoState.Warning);
    },
    videoDisableWarningLifted: () => {
      setSubscriberVideoState(OTVideoState.Enabled);
    },
    videoDisabled: (ev) => {
      console.log('subscriberEventHandlers, VideoDisabled', ev);
      switch (ev.reason) {
        case 'codecNotSupported':
          setSubscriberVideoState(OTVideoState.NotSupported);
          break;
        case 'quality':
          setSubscriberVideoState(OTVideoState.PoorQuality);
          break;
        case 'publishVideo':
        case 'subscribeToVideo':
        default:
          setSubscriberVideoState(OTVideoState.Disabled);
      }
    },
    videoEnabled: () => setSubscriberVideoState(OTVideoState.Enabled),
  };

  const publisherEventsHandler: OTPublisherEvents = {
    mediaStopped: isNative()
      ? undefined
      : (ev) => {
          showSnackbar(
            'Seems there was an issue with your video or audio. Try refreshing your page and join the call again.',
            {
              isError: true,
              noTimer: true,
            }
          );
        },
    videoDisableWarning: () => {
      setSubscriberVideoState(OTVideoState.Warning);
    },
    videoDisableWarningLifted: () => {
      setPublisherVideoState(OTVideoState.Enabled);
    },
    videoDisabled: (ev) => {
      console.log('VideoDisabled', ev);
      switch (ev.reason) {
        case 'codecNotSupported':
          setPublisherVideoState(OTVideoState.NotSupported);
          break;
        case 'quality':
          setPublisherVideoState(OTVideoState.PoorQuality);
          break;
        case 'publishVideo':
        case 'subscribeToVideo':
        default:
          setPublisherVideoState(OTVideoState.Disabled);
      }
    },
    videoEnabled: () => setPublisherVideoState(OTVideoState.Enabled),
  };
  return {
    connectionState,
    joinedCall,
    subscriberConnectionState,
    subscriberVideoState,
    publisherVideoState,
    sessionEventHandlers,
    subscriberEventHandlers,
    publisherEventsHandler,
    transitionConnectionState,
    setJoinedCall: updateJoinedCall,
  };
};
