import React from 'react';
import { Controller } from 'react-hook-form';

import { RadioButtons } from '~/components/inputs/RadioButtons';
import { RadioButtonsYesNo } from '~/constants/radioButtons/radioButtonOptions';
import { useReferralDataContext } from '~/providers/consultation/ReferralDataContext';

export const ReferralRadioButtons: React.FC = () => {
  const {
    form: { control, rules },
  } = useReferralDataContext();

  return (
    <Controller
      name="refer_patient"
      control={control}
      render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
        <RadioButtons
          horizontal
          items={RadioButtonsYesNo}
          selected={value}
          onValueChange={onChange}
          error={isSubmitted && !!error?.message}
          errorMessage={isSubmitted && error?.message}
          showMandatory={!!rules.refer_patient?.required}
          label="Would you like to refer the patient for further treatment?"
        />
      )}
      rules={rules.refer_patient as any} // TODO: check why there is a mismatch in types
    />
  );
};
