import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { NotificationModel } from '~/api/models/notifications/models/NotificationModel';
import { ExportPatientDataIndexResponse } from '~/api/models/patientData/responses/ExportPatientDataIndexResponse';
import { UploadPatientDataIndexResponse } from '~/api/models/patientData/responses/UploadPatientDataIndexResponse';

interface IState {
  imports: Omit<UploadPatientDataIndexResponse, 'links'>;
  exports: Omit<ExportPatientDataIndexResponse, 'links'>;
  requests: ConsultationModel[];
  notifications: NotificationModel[];
}

const initialState: IState = {
  imports: {
    data: [],
    meta: null,
  },
  exports: {
    data: [],
    meta: null,
  },
  requests: [],
  notifications: [],
};

const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    APPEND_IMPORTS_DATA: (state, action: PayloadAction<UploadPatientDataIndexResponse>) => {
      const data = [...state.imports.data, ...action.payload.data];
      return {
        ...state,
        imports: {
          data,
          meta: action.payload.meta,
        },
      };
    },
    CLEAR_IMPORTS_DATA: (state) => ({
      ...state,
      imports: {
        data: [],
        meta: null,
      },
    }),

    APPEND_EXPORTS_DATA: (state, action: PayloadAction<ExportPatientDataIndexResponse>) => {
      const data = [...state.exports.data, ...action.payload.data];
      return {
        ...state,
        exports: {
          data,
          meta: action.payload.meta,
        },
      };
    },
    CLEAR_EXPORTS_DATA: (state) => ({
      ...state,
      exports: {
        data: [],
        meta: null,
      },
    }),

    APPEND_REQUESTS: (state, action: PayloadAction<ConsultationModel[]>) => {
      return {
        ...state,
        requests: [...action.payload],
      };
    },

    SET_NOTIFICATIONS: (state, action: PayloadAction<NotificationModel[]>) => ({
      ...state,
      notifications: [...action.payload],
    }),

    LOGOUT: () => ({ ...initialState }),
  },
});

export const {
  APPEND_IMPORTS_DATA,
  APPEND_EXPORTS_DATA,
  CLEAR_IMPORTS_DATA,
  CLEAR_EXPORTS_DATA,
  APPEND_REQUESTS,
  SET_NOTIFICATIONS,
  LOGOUT,
} = requestsSlice.actions;

export default requestsSlice.reducer;
