import Feather from '@expo/vector-icons/Feather';
import * as React from 'react';
import { Dimensions, LayoutRectangle, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { BiometryType, BiometryTypes } from 'react-native-biometrics';

import { AnimatedButton } from '../inputs/animated/AnimatedButton';
import { Biometrics, FaceId, TouchID } from '../svgImages';

import { mb10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { usePageFocus } from '~/utils/hooks/FocusHook';

const enterPasscodeFields = [
  [{ title: '1' }, { title: '2' }, { title: '3' }],
  [{ title: '4' }, { title: '5' }, { title: '6' }],
  [{ title: '7' }, { title: '8' }, { title: '9' }],
  [{ title: '', type: 'CLEAR' }, { title: '0' }, { title: '', type: 'image' }],
];

const createPasscodeFields = [
  [{ title: '1' }, { title: '2' }, { title: '3' }],
  [{ title: '4' }, { title: '5' }, { title: '6' }],
  [{ title: '7' }, { title: '8' }, { title: '9' }],
  [{ title: '', type: 'CLEAR' }, { title: '0' }, { title: '', type: 'ENTER' }],
];

const { width } = Dimensions.get('screen');
interface Props {
  touchFaceID?: boolean;
  biometricType?: BiometryType;
  passcode: string;
  showCreatePassCode?: boolean;
  editable?: boolean;
  setPasscode: (passcodeText: string) => void;
  loginWithTouchID?: () => void;
  onButtonPressed: (item: string) => void;
  onEnter?: () => void;
}

export const PasscodeInputMobile: React.FC<Props> = ({
  touchFaceID,
  biometricType,
  passcode,
  showCreatePassCode,
  editable,
  setPasscode,
  loginWithTouchID,
  onButtonPressed,
  onEnter,
}): JSX.Element => {
  const passcodeScreenFields = showCreatePassCode ? createPasscodeFields : enterPasscodeFields;
  const [containerSize, setContainerSize] = React.useState<LayoutRectangle>();

  usePageFocus(() => {
    setPasscode('');
  });

  const buttonWidth = React.useMemo(() => {
    const defaultSize = Math.min(90, (width - MOBILE_HORIZONTAL_SPACE * 2) / 4);
    if (!containerSize) return defaultSize;
    const maxWidth = containerSize.width / 3 - 15;
    const maxHeight = containerSize.height / 4 - 15;

    return Math.max(Math.min(maxWidth, maxHeight, 90), 55);
  }, [containerSize]);

  return (
    <Grid grid={{ gutters: 15 }}>
      <Container
        style={{ maxHeight: 95 * 4, flexGrow: 1, width: '100%' }}
        onLayout={(ev) => setContainerSize(ev.nativeEvent?.layout)}>
        {passcodeScreenFields.map((row, rowIndex) => (
          <Row alignItems="center" justifyContent="center" style={mb10} key={rowIndex}>
            {row.map((item, index) => (
              <Column span="auto" alignSelf="center" key={`${rowIndex}_${index}`}>
                {item.type === 'ENTER' ? (
                  <AnimatedButton
                    onPress={onEnter}
                    loading={!editable}
                    disabled={passcode.length !== 4}
                    style={{ width: buttonWidth, height: buttonWidth }}
                    backgroundStyle={{ borderRadius: buttonWidth * 2 }}
                    children={(disabled) => (
                      <Feather name="check" size={28} color={disabled ? colors.placeholderGrey : colors.white} />
                    )}
                  />
                ) : (
                  <TouchableOpacity
                    disabled={(item.type === 'image' && !touchFaceID) || !editable}
                    style={[
                      { width: buttonWidth, height: buttonWidth },
                      item.title.length ? styles.numberButton : styles.clearButton,
                    ]}
                    onPress={() => {
                      if (item.type === 'CLEAR') {
                        setPasscode('');
                      } else if (item.type === 'image') {
                        loginWithTouchID && loginWithTouchID();
                      } else if (passcode.length < 4) {
                        onButtonPressed(item.title);
                      }
                    }}>
                    {item.type === 'CLEAR' ? (
                      <Feather name="delete" size={28} color={colors.purple} />
                    ) : item.type === 'image' && touchFaceID && biometricType ? (
                      biometricType === BiometryTypes.FaceID ? (
                        <FaceId height={20} width={20} />
                      ) : biometricType === BiometryTypes.TouchID ? (
                        <TouchID height={30} width={30} />
                      ) : (
                        <Biometrics height={30} width={30} />
                      )
                    ) : (
                      <Text style={styles.button} maxFontSizeMultiplier={1.4} minimumFontScale={1}>
                        {item.title}
                      </Text>
                    )}
                  </TouchableOpacity>
                )}
              </Column>
            ))}
          </Row>
        ))}
      </Container>
    </Grid>
  );
};

const styles = StyleSheet.create({
  flatListContent: {
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  flatListColumnWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    fontFamily: 'TitilliumWeb-Regular',
    color: colors.purple,
    fontSize: 30,
  },
  clearButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    aspectRatio: 1,
  },
  numberButton: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#ddd',
    borderBottomWidth: 0,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 3,
    aspectRatio: 1,
  },
});
