import { Route } from '@react-navigation/native';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  currentRoute?: Route<string>;
  navigationData?: any;
}

const initialState: State = {
  currentRoute: undefined,
};

const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    UPDATE_ROUTE: (state, action: PayloadAction<Route<any>>) => ({
      ...state,
      currentRoute: action.payload,
    }),
    SET_NAVIGATION_DATA: (state, action: PayloadAction<any>) => ({
      ...state,
      navigationData: action.payload,
    }),
    CLEAR_NAVIGATION_DATA: (state) => ({
      ...state,
      navigationData: undefined,
    }),
  },
});

export const { UPDATE_ROUTE, SET_NAVIGATION_DATA, CLEAR_NAVIGATION_DATA } = routeSlice.actions;
export default routeSlice.reducer;
