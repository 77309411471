import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useAppointmentBookingContext } from './AppointmentBookingContext';
import { DoctorListingContext } from './DoctorListingContext';

import { usePolling } from '~/api/hooks/Polling';
import { useOnDemandDoctorsQueue } from '~/api/hooks/consultations/OnDemandDoctorHook';
import { useFavouriteDoctorsWithEffect } from '~/api/hooks/favourites/FavouriteDoctorsHook';
import { AvailableDoctorModel } from '~/api/models/appointments/models/AvailableDoctorModel';
import { AvailableDoctorsHomeVisitRequest } from '~/api/models/appointments/requests/AvailableDoctorsHomeVisitRequest';
import {
  AvailableDoctorsForm,
  AvailableDoctorsRequest,
} from '~/api/models/appointments/requests/AvailableDoctorsRequest';
import { AvailableDoctorsListingModel } from '~/api/models/appointments/responses/AvailableDoctorsResponse';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import {
  apiGetAvailableDoctorsHomeVisit,
  apiGetAvailableDoctorsOnDemand,
  apiGetAvailableDoctorsScheduled,
} from '~/api/services/appointments/availabilities';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { SET_DOCTORS, doctorsListing } from '~/redux/reducers/patient/doctorListingReducer';
import { useAppDispatch } from '~/redux/store';
import { promiseAllSettledHelper } from '~/utils/promiseUtil';

interface Props {
  isListing?: boolean;
  consultationTypes?: ConsultationTypeEnum[];
  children: React.ReactNode;
  noAvailableDoctorAlert?: (consultationType: ConsultationTypeEnum) => void;
  changeConsultationTypeToOnDemand?: () => void;
  changeConsultationTime?: () => void;
  onSelectDoctorOrClinic?: (
    doctorOrClinic: AvailableDoctorModel,
    consultationType: ConsultationTypeEnum,
    channel?: number
  ) => void;
}

export const DoctorListingProvider: React.FC<Props> = ({
  noAvailableDoctorAlert,
  consultationTypes,
  children,
  isListing,
  onSelectDoctorOrClinic,
}) => {
  const appointmentProvider = useAppointmentBookingContext();
  const watchedValues = useWatch({ control: appointmentProvider?.formControl });
  const { updateFavouriteDoctors } = useFavouriteDoctorsWithEffect();
  const { onDemandQueues } = useOnDemandDoctorsQueue({
    isOnDemand: !consultationTypes || consultationTypes.includes(ConsultationTypeEnum.ON_DEMAND),
  });

  const queryPage = useRef(1);

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const { control, handleSubmit, getValues, reset } = useForm<AvailableDoctorsForm>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      sort: 'name',
      filter: {},
      search: '',
    },
  });

  const allDoctors = useSelector(doctorsListing);

  const allDoctorsWithQueue = useMemo<
    Record<Exclude<ConsultationTypeEnum, ConsultationTypeEnum.CLINIC>, AvailableDoctorsListingModel>
  >(() => {
    return {
      [ConsultationTypeEnum.HOME_VISIT]: allDoctors.home_visit,
      [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: allDoctors.scheduled_appointment,
      [ConsultationTypeEnum.ON_DEMAND]: allDoctors.on_demand
        ? {
            ...allDoctors.on_demand,
            data: allDoctors.on_demand?.data.map((doctor) => ({
              ...doctor,
              queue: onDemandQueues?.find((item) => item.doctor_account_id === doctor.id),
            })),
          }
        : undefined,
    };
  }, [
    allDoctors.home_visit,
    allDoctors.on_demand,
    allDoctors.scheduled_appointment,
    consultationTypes,
    onDemandQueues,
  ]);

  /**
   * Get Available Doctors for current consultations types
   * @param request Doctor list request
   * @returns boolean - true if there are any doctors for any of the consultation types
   */
  const getAvailableDoctors = async (request: AvailableDoctorsRequest, affectedConsultation?: ConsultationTypeEnum) => {
    const shouldGetHomeVisit = affectedConsultation
      ? (affectedConsultation = ConsultationTypeEnum.HOME_VISIT)
      : !consultationTypes || consultationTypes.includes(ConsultationTypeEnum.HOME_VISIT);
    const shouldGetScheduledApp = affectedConsultation
      ? (affectedConsultation = ConsultationTypeEnum.SCHEDULED_APPOINTMENT)
      : !consultationTypes || consultationTypes.includes(ConsultationTypeEnum.SCHEDULED_APPOINTMENT);
    const shouldGetOnDemand = affectedConsultation
      ? (affectedConsultation = ConsultationTypeEnum.ON_DEMAND)
      : !consultationTypes || consultationTypes.includes(ConsultationTypeEnum.ON_DEMAND);

    const homeVisitsRequest = shouldGetHomeVisit
      ? (): AvailableDoctorsHomeVisitRequest => {
          const ranges = appointmentProvider
            ? watchedValues.available_time_ranges
                .filter((range) => range.from && range.to)
                .map((range) => ({
                  from: `${watchedValues.available_time_date} ${range.from}`,
                  to: `${watchedValues.available_time_date} ${range.to}`,
                }))
            : undefined;

          const nearby =
            appointmentProvider && watchedValues?.location?.lat && watchedValues?.location?.lng
              ? [watchedValues.location.lat, watchedValues.location.lng]
              : undefined;

          return {
            ...request,
            filter: {
              ...request.filter,
              nearby,
            },
            ranges,
          };
        }
      : null;

    const promises = [
      !shouldGetHomeVisit
        ? null
        : apiGetAvailableDoctorsHomeVisit(homeVisitsRequest()).then((res) => {
            dispatch(
              SET_DOCTORS({
                type: ConsultationTypeEnum.HOME_VISIT,
                data: res.data,
              })
            );
            return res.data;
          }),
      !shouldGetScheduledApp
        ? null
        : apiGetAvailableDoctorsScheduled(request).then((res) => {
            dispatch(
              SET_DOCTORS({
                type: ConsultationTypeEnum.SCHEDULED_APPOINTMENT,
                data: res.data,
              })
            );
            return res.data;
          }),
      !shouldGetOnDemand
        ? null
        : apiGetAvailableDoctorsOnDemand(request).then((res) => {
            dispatch(
              SET_DOCTORS({
                type: ConsultationTypeEnum.ON_DEMAND,
                data: res.data,
              })
            );
            return res.data;
          }),
    ].filter((item) => item);

    const res = await promiseAllSettledHelper(promises);

    return res.some((promise) => promise.status === 'fulfilled' && !!promise.value?.meta?.total);
  };

  const submitQuery = async (options?: {
    data?: AvailableDoctorsForm;
    page?: number;
    loading?: boolean;
    affectedType?: ConsultationTypeEnum;
  }) => {
    try {
      setLoading(options?.loading ?? true);

      queryPage.current = options?.page || 1;
      const query = getAvailableDoctors(
        {
          filter: {
            name: options?.data?.search || undefined,
            languages: options?.data?.filter?.languages,
            is_gp: options?.data?.filter?.is_gp,
            consultation_channels: options?.data?.filter?.consultation_channels,
            favourited_by: options?.data?.filter?.favourited_by,
          },
          sort: options?.data?.sort,
          page: options?.page || 1,
        },
        options?.affectedType
      );

      const response = await query;

      reset(options?.data);

      if (
        !response &&
        consultationTypes?.length === 1 &&
        (consultationTypes[0] === ConsultationTypeEnum.HOME_VISIT ||
          consultationTypes[0] === ConsultationTypeEnum.SCHEDULED_APPOINTMENT) &&
        noAvailableDoctorAlert
      ) {
        noAvailableDoctorAlert(consultationTypes[0]);
      }
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setLoading(false);
    }
  };

  const { startPolling, stopPolling } = usePolling(() => {
    submitQuery({
      data: getValues(),
      page: queryPage.current,
      loading: false,
      affectedType: consultationTypes?.length ? consultationTypes[0] : undefined,
    });
  }, 60000);

  useEffect(() => {
    submitQuery({ data: getValues() });
  }, []);

  useEffect(() => {
    startPolling();

    return stopPolling;
  }, []);

  const setPage = (pageInfo: TablePaginationInfo, consultationType: ConsultationTypeEnum) => {
    if (!allDoctors?.[consultationType] || pageInfo.page === 1) return;
    submitQuery({ data: getValues(), page: pageInfo.page, affectedType: consultationType });
  };

  return (
    <DoctorListingContext.Provider
      value={{
        filterControl: control,
        consultationTypes,
        doctors: allDoctorsWithQueue,
        loading,
        isListing,
        updateFavourites: updateFavouriteDoctors,
        setPage,
        submitQuery: handleSubmit((data) => submitQuery({ data, page: 1 })),
        selectedDoctorOrClinic: (doctorOrClinic, consultationType, channel) =>
          onSelectDoctorOrClinic && onSelectDoctorOrClinic(doctorOrClinic, consultationType, channel),
      }}>
      {children}
    </DoctorListingContext.Provider>
  );
};
