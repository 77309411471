import React from 'react';

import { HealthRecordConditionEnum } from '~/api/models/consultations/constants/HealthRecordConditionEnum';
import { RadioButtons } from '~/components/inputs/RadioButtons';

interface Props {
  value: HealthRecordConditionEnum;
  onChange: (value: HealthRecordConditionEnum) => void;
  error?: boolean;
  errorMessage?: string;
}

const HealthRecordConditionList = [
  { id: HealthRecordConditionEnum.ACUTE, name: 'Acute' },
  { id: HealthRecordConditionEnum.STABLE, name: 'Chronic (stable)' },
  { id: HealthRecordConditionEnum.UNSTABLE, name: 'Chronic (unstable)' },
];

export const HealthRecordCondition: React.FC<Props> = ({ value, onChange, error, errorMessage }) => {
  return (
    <RadioButtons
      style={{ paddingTop: 16 }}
      items={HealthRecordConditionList}
      selected={value}
      onValueChange={onChange}
      label="Type of condition"
      error={error}
      errorMessage={errorMessage}
    />
  );
};
