import React, { useState } from 'react';
import { View } from 'react-native';

import { PatientDiagnosesList } from '../../../components/doctor/patients/PatientDiagnosesList';

import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { mb20, mt10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { AccountSelectionDropdown } from '~/components/accounts/AccountSelectionDropdown';
import { H2TtmSemiBoldBlack, SmallNsRegularBlack } from '~/components/commonText';
import { SearchBox } from '~/components/searchBox';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

export const PatientDiagnosesScreen: React.FC = () => {
  const { isMobile, isDesktop } = useBreakpoints();
  const [searchText, setSearchText] = useState('');
  const [currentAccount, setCurrentAccount] = useState<AccountModel>();

  return (
    <PageLayout
      header={
        <View style={{ flex: 1 }}>
          <View
            style={isMobile ? null : { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <H2TtmSemiBoldBlack>{labels.diagnoses}</H2TtmSemiBoldBlack>
            <SearchBox
              value={searchText}
              onSubmit={() => {
                setSearchText(searchText);
              }}
              onTextChange={setSearchText}
              placeholder="Search"
              style={isDesktop ? { marginVertical: 0 } : null}
            />
          </View>

          {isMobile ? null : (
            <SmallNsRegularBlack style={{ marginBottom: 10, marginTop: 20 }}>
              {labels.diagnosesParagraph}
            </SmallNsRegularBlack>
          )}

          <View style={isMobile ? mb20 : mt10}>
            <AccountSelectionDropdown currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} withAll />
          </View>
        </View>
      }
      scrollContentNode={
        <PatientDiagnosesList
          id={currentAccount?.id}
          searchValue={searchText}
          style={{ paddingHorizontal: isMobile ? MOBILE_HORIZONTAL_SPACE : 0 }}
        />
      }
    />
  );
};
