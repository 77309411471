import { useCalendarAppointmentSnackbar } from '../../channels/useCalendarAppointmentSnackbar';
import { useConsultationHelper } from '../helpers/HelperConsultation';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { isDoctorVersion, isPatientVersion, whenAppType } from '~/utils/buildConfig';

export const useHandleAppointmentScheduled = () => {
  const { getConsultation } = useConsultationHelper();
  const { showAppointmentSnackbar } = useCalendarAppointmentSnackbar();

  const consultationRequiredDoctorApproval = (consultation: ConsultationModel) => {
    return !consultation?.clinic && consultation?.type !== ConsultationTypeEnum.SCHEDULED_APPOINTMENT;
  };

  const validate = () => {
    return isPatientVersion() || isDoctorVersion();
  };

  const emitEvent = (consultationId?: number) => {
    AppEventHandler.emit(
      whenAppType({
        whenPatient: AppEvents.PATIENT_APPOINTMENTS_CHANGED,
        whenDoctor: AppEvents.DOCTOR_APPOINTMENTS_CHANGED,
      }),
      [consultationId]
    );
  };

  const execute = async (consultationId?: number) => {
    if (!isDoctorVersion()) return;

    const consultation = await getConsultation(consultationId);

    if (!consultation || consultationRequiredDoctorApproval(consultation)) return;

    showAppointmentSnackbar(consultation);
  };

  const handleNotification = async (consultationId: number) => {
    if (!validate()) return;

    await execute(consultationId);
    emitEvent(consultationId);
  };

  return { handleNotification };
};
