import { deleteReq, get, post, put } from '../../axios';

import { ClinicalTermArrayResponse } from '~/api/models/clinicalTerms/responses/ClinicalTermArrayResponse';
import { IdModel } from '~/api/models/common/models/IdModel';
import { GetMediaCollectionResponse } from '~/api/models/common/responses/GetMediaCollectionResponse';
import { ListItemResponse } from '~/api/models/common/responses/ListItemResponse';
import { BaseReferralModel } from '~/api/models/consultations/models/ReferralModel';
import { AddReferralPdfRequest } from '~/api/models/consultations/requests/AddReferralPdfRequest';
import { ConsultationFilterRequest } from '~/api/models/consultations/requests/ConsultationFilterRequest';
import { DeleteReferralsRequest } from '~/api/models/consultations/requests/DeleteReferralsRequest';
import { GetReferralRequest } from '~/api/models/consultations/requests/GetReferralRequest';
import { RemoveReferralMediaRequest } from '~/api/models/consultations/requests/RemoveReferralMediaRequest';
import { ReferralIndexResponse } from '~/api/models/consultations/responses/ReferralIndexResponse';
import { ReferralResponse } from '~/api/models/consultations/responses/ReferralResponse';
import { ClinicalTermArrayRequest } from '~/api/models/healthProfile/requests/ClinicalTermArrayRequest';
import { ReferralMediaIndexResponse } from '~/api/models/referrals/responses/ReferralMediaIndexResponse';
import { ReferralMediaTypeResponse } from '~/api/models/referrals/responses/ReferralMediaTypeResponse';
import fileSystem from '~/integrations/fileSystem';

export const apiAddReferral = (req: BaseReferralModel) => {
  return post('/referrals/', req);
};

export const apiShowReferral = (params: GetReferralRequest) => {
  return get<ReferralResponse>(`/referrals/${params.id}`);
};

export const apiGetReferralIndex = (params: ConsultationFilterRequest) => {
  return get<ReferralIndexResponse>('/referrals', params);
};

export const apiDeleteReferral = (req: DeleteReferralsRequest) => {
  return deleteReq(`/referrals/${req.id}`);
};

export const apiGetRequestFor = (refId: number) => {
  return get<ClinicalTermArrayResponse>(`/referrals/${refId}/request-for`);
};

export const apiUpdateRequestFor = (refId: number, params: ClinicalTermArrayRequest) => {
  return put(`/referrals/${refId}/request-for`, params);
};

export const apiGetExaminations = (refId: number) => {
  return get<ClinicalTermArrayResponse>(`/referrals/${refId}/examinations`);
};

export const apiUpdateExaminations = (refId: number, params: ClinicalTermArrayRequest) => {
  return put(`/referrals/${refId}/examinations`, params);
};

export const apiAddReferralPdf = (req: AddReferralPdfRequest) => {
  return fileSystem.postDocumentResult(`/referrals/${req.referral_id}/media`, 'media', req.document, {
    referral_media_type: req.referral_media_type,
  });
};

export const apiGetReferralMediaTypes = () => {
  return get<ReferralMediaTypeResponse>(`/referral-media-types`);
};

export const apiGetReferralMedia = (req: GetReferralRequest) => {
  return get<ReferralMediaIndexResponse>(`/referrals/${req.id}/media`);
};

export const apiRemoveReferralMedia = (params: RemoveReferralMediaRequest) => {
  return deleteReq(`/referrals/${params.referral_id}/media`, params);
};

export const apiGetReferralGeneratedPDFs = (req: IdModel) => {
  return get<GetMediaCollectionResponse>(`/referrals/${req.id}/media/download`);
};
export const apiGetExaminationTypes = () => {
  return get<ListItemResponse>('/examination-types');
};
