import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { HomeVisitAvailableSlot } from './model/homeAvailableSlot';
import { AvailableSlotsSelection } from '../../../../common/booking/AvailableSlotsSelection';

import { AvailabilitySlotsResponse } from '~/api/models/common/responses/AvailabilitySlotsResponse';
import { apiGetPatientAvailabilities } from '~/api/services/appointments/availabilities';
import { mb10, mb5, mt20, mv20 } from '~/common/commonStyles';
import { H4TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { DATE_FORMAT, SHORT_DATE_FORMAT, TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { bookingLabels } from '~/utils/labels/booking';

interface Props {
  consultationId: number;
  selected?: HomeVisitAvailableSlot;
  onChange: (value: HomeVisitAvailableSlot) => void;
  errorMessage?: string;
}

export const HomeVisitAvailableSlots: React.FC<Props> = ({ consultationId, onChange, errorMessage, selected }) => {
  const [loading, setLoading] = useState(false);

  const [slots, setSlots] = useState<AvailabilitySlotsResponse>();

  const getSlots = async () => {
    try {
      setLoading(true);
      const res = await apiGetPatientAvailabilities({ id: consultationId });

      const slots =
        res.data?.reduce<AvailabilitySlotsResponse>((prev, slot) => {
          const date = parseDateTime(slot.from, { outputFormat: DATE_FORMAT });

          if (!prev[date]) prev[date] = [];
          prev[date].push({
            date,
            from: parseDateTime(slot.from, { outputFormat: TIME_FORMAT }),
            to: parseDateTime(slot.to, { outputFormat: TIME_FORMAT }),
          });
          return prev;
        }, {}) ?? {};
      setSlots(slots);
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const date = useMemo(() => {
    if (!slots) return '';
    const slotKeys = Object.keys(slots);
    if (!slotKeys?.length) return '';

    return parseDateTime(slotKeys[0], { parseFormat: DATE_FORMAT, outputFormat: SHORT_DATE_FORMAT });
  }, [slots]);

  useEffect(() => {
    if (consultationId) getSlots();
  }, [consultationId]);

  return (
    <>
      <H4TtmSemiBoldBlack style={[mb5, mt20]}>{bookingLabels.homeVisit.selectAvailableSlot}</H4TtmSemiBoldBlack>
      <H6NsRegularBlack
        style={mb10}>{`${bookingLabels.homeVisit.selectAvailableSlotDescription} ${date}.`}</H6NsRegularBlack>

      <View style={mv20}>
        <AvailableSlotsSelection
          loading={loading}
          errorMessage={errorMessage}
          onChange={(value) => {
            onChange({ time_to: value.to, time_from: value.from, date: value.date });
          }}
          slots={slots}
          selected={{ from: selected?.time_from, to: selected?.time_to, date: selected?.date }}
        />
      </View>
    </>
  );
};
