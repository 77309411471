import React, { useMemo, useState } from 'react';

import { useUsedLanguageListing } from '~/api/hooks/referenceData/UsedLanguageListing';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { DateTimeType } from '~/api/models/common/types/DateType';
import { TextButton } from '~/components/commonButton';
import { CheckboxGroup } from '~/components/inputs/CheckboxGroup';
import { LatLng } from '~/integrations/mapView/interfaces/LatLng';
import { labels } from '~/utils/labels';

interface Props {
  value: number[];
  setValue: (value: number[]) => void;
  type: ConsultationTypeEnum;
  ranges?: { from: DateTimeType; to: DateTimeType }[];
  location?: LatLng;
  header?: React.ReactNode;
}

export const DoctorsListFilterLanguageComponent: React.FC<Props> = ({
  value,
  setValue,
  type,
  ranges,
  location,
  header,
}) => {
  const [showMore, setShowMore] = useState(false);
  const languages = useUsedLanguageListing({ type, ranges, location });

  const languageOptions = useMemo(() => {
    const shownLanguages = languages ? (showMore ? languages : languages.slice(0, 2)) : [];
    return shownLanguages.map((language) => ({ value: language.id, label: language.name })) || [];
  }, [languages, showMore]);

  if (!languageOptions?.length) return null;
  return (
    <>
      {header}
      <CheckboxGroup items={languageOptions} values={value ?? []} onChange={setValue} />

      {languages?.length > 2 ? (
        <TextButton
          style={{ display: 'flex', flexDirection: 'row', marginHorizontal: 10 }}
          label={showMore ? labels.showLess : labels.showMore}
          funCallback={() => setShowMore(!showMore)}
        />
      ) : null}
    </>
  );
};
