import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CountryModel } from '../../api/models/referenceData/models/CountryModel';
import { LanguageModel } from '../../api/models/referenceData/models/LanguageModel';

import { DependantRelationshipTypeModel } from '~/api/models/dependants/models/DependantRelationshipTypeModel';
import { InsuranceClaimDocumentTypeModel } from '~/api/models/referenceData/models/InsuranceClaimDocumentTypeModel';
import { LocalityModel } from '~/api/models/referenceData/models/LocalityModel';
import { setUnencryptedAsyncItem, storageKeys } from '~/common/asyncStorage';

interface IState {
  countryList: CountryModel[];
  languageList: LanguageModel[];
  usedLanguageList: LanguageModel[];
  localityList: LocalityModel[];
  dependantRelationships?: DependantRelationshipTypeModel[];
  insuranceClaimDocumentTypes?: InsuranceClaimDocumentTypeModel[];
}

const initialState: IState = {
  countryList: [],
  localityList: [],
  languageList: [],
  usedLanguageList: undefined,
  dependantRelationships: undefined,
  insuranceClaimDocumentTypes: undefined,
};

const referenceDataSlice = createSlice({
  name: 'referenceData',
  initialState,
  reducers: {
    UPDATE_COUNTRY_LIST: (state, action: PayloadAction<CountryModel[]>) => {
      setUnencryptedAsyncItem(storageKeys.countries, JSON.stringify(action.payload));
      return {
        ...state,
        countryList: action.payload,
      };
    },
    SET_COUNTRY_LIST: (state, action: PayloadAction<CountryModel[]>) => ({
      ...state,
      countryList: action.payload,
    }),
    UPDATE_LANGUAGE_LIST: (state, action: PayloadAction<LanguageModel[]>) => ({
      ...state,
      languageList: action.payload,
    }),
    UPDATE_USED_LANGUAGE_LIST: (state, action: PayloadAction<LanguageModel[]>) => ({
      ...state,
      usedLanguageList: action.payload,
    }),
    UPDATE_RELATIONSHIPS_LIST: (state, action: PayloadAction<DependantRelationshipTypeModel[]>) => ({
      ...state,
      dependantRelationships: action.payload,
    }),
    UPDATE_LOCALITY_LIST: (state, action: PayloadAction<LocalityModel[]>) => ({
      ...state,
      localityList: action.payload,
    }),
    UPDATE_INSURANCE_CLAIM_DOC_TYPES: (state, action: PayloadAction<InsuranceClaimDocumentTypeModel[]>) => ({
      ...state,
      insuranceClaimDocumentTypes: action.payload,
    }),
  },
});

export const {
  UPDATE_COUNTRY_LIST,
  SET_COUNTRY_LIST,
  UPDATE_LANGUAGE_LIST,
  UPDATE_RELATIONSHIPS_LIST,
  UPDATE_LOCALITY_LIST,
  UPDATE_USED_LANGUAGE_LIST,
  UPDATE_INSURANCE_CLAIM_DOC_TYPES,
} = referenceDataSlice.actions;
export default referenceDataSlice.reducer;
