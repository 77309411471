import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';

import { SettingsNotificationRow } from './SettingsNotificationRow';
import { alignItemsCenter, mt35, mv20 } from '../../../common/commonStyles';
import { H3TtmSemiBoldBlack, H6NsRegularBlack, SmallNsRegularSecondaryBlack } from '../../../components/commonText';
import { NestedPageInternalLayout } from '../../../layouts/NestedPageInternalLayout';
import { Column, Container, Row } from '../../../theme/components/grid';
import { labels } from '../../../utils/labels';

import { useSettings } from '~/api/hooks/NotificationSettingsHook';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { NotificationType } from '~/api/models/settings/constants/NotificationsEnum';
import { NotificationsFormObject } from '~/api/models/settings/models/NotificationsFormObjectModel';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { OutlineButton } from '~/components/commonButton';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { NotifyPatientsModal } from '~/components/modals/NotifyPatientsModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isDoctorVersion, isPatientVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { DigimedMilestones, digimedMilestoneEnabled } from '~/utils/milestoneUtil';
import { screenName } from '~/utils/screenName';

export const SettingsNotifications: React.FC = () => {
  const { isMobile } = useBreakpoints();

  const { notificationSettingsObject, updateNotificationSettings } = useSettings();
  const { registerModal, openModal } = useModalManager();
  const { hasFeatureAccess } = useFeatureAccess();

  const { control, reset, handleSubmit } = useForm<NotificationsFormObject>({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    registerModal(ModalName.NotifyPatients, NotifyPatientsModal);
  }, []);

  useEffect(() => {
    if (notificationSettingsObject) {
      reset(notificationSettingsObject);
    }
  }, [notificationSettingsObject]);

  const footer = (
    <CancelContinueFooter
      onCancel={reset}
      onConfirm={handleSubmit(updateNotificationSettings)}
      confirmTitle={labels.update}
    />
  );

  const notifyPatientsModal = () => {
    openModal(ModalName.NotifyPatients, {
      control,
    });
  };

  const disabledOption = (type: NotificationType) => {
    return (
      isPatientVersion() &&
      type === NotificationType.HEALTH_COMPANION &&
      !hasFeatureAccess(FeatureAccessEnum.HEALTH_COMPANION)
    );
  };

  return (
    <NestedPageInternalLayout
      backTitle="Settings"
      backLink={{ screen: screenName.Settings }}
      title={labels.notifications}
      footer={footer}>
      {notificationSettingsObject ? (
        <Container alignSelf="flex-start">
          {!isMobile ? (
            <Row>
              <Column>
                <H3TtmSemiBoldBlack>{labels.notifications}</H3TtmSemiBoldBlack>
              </Column>
              <Column>
                <Row>
                  <Column style={alignItemsCenter}>
                    <H3TtmSemiBoldBlack>{labels.email}</H3TtmSemiBoldBlack>
                  </Column>
                  <Column style={alignItemsCenter}>
                    <H3TtmSemiBoldBlack>{labels.notification}</H3TtmSemiBoldBlack>
                  </Column>
                </Row>
              </Column>
            </Row>
          ) : null}
          {notificationSettingsObject &&
            Object.keys(notificationSettingsObject).map((object: NotificationType, index: number, arr) => (
              <SettingsNotificationRow
                notificationSettings={notificationSettingsObject[object]}
                notificationType={object}
                form={{ control }}
                key={object}
                disabled={disabledOption(object)}
                style={
                  isMobile ? { borderBottomColor: colors.lightPurple2, borderBottomWidth: 1, paddingBottom: 20 } : null
                }
              />
            ))}
          {isDoctorVersion() && digimedMilestoneEnabled(DigimedMilestones.DoctorPatientApps) ? (
            <View style={mt35}>
              <H3TtmSemiBoldBlack>{labels.promotion}</H3TtmSemiBoldBlack>
              <Row style={{ flexWrap: 'wrap', marginTop: 20 }}>
                <Column span={{ xs: 12, md: 6 }}>
                  <H6NsRegularBlack>{labels.createAccount}</H6NsRegularBlack>
                  <SmallNsRegularSecondaryBlack>{labels.sendEmailToCreateAccount}</SmallNsRegularSecondaryBlack>
                </Column>
                <Column span={{ xs: 12, md: 6 }} style={isMobile ? mv20 : undefined}>
                  <OutlineButton
                    style={{ maxWidth: 150, alignSelf: 'center' }}
                    label={labels.viewPatients}
                    funCallback={notifyPatientsModal}
                  />
                </Column>
              </Row>
            </View>
          ) : null}
        </Container>
      ) : (
        <LoadingActivityIndicator />
      )}
    </NestedPageInternalLayout>
  );
};
