import React from 'react';

import { AppointmentShowPageLayout } from '../appointments/AppointmentShowPageLayout';

import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { Row, Column } from '~/theme/components/grid';

export const ConsultationLoading: React.FC = () => {
  return (
    <AppointmentShowPageLayout flexContent>
      <Row style={{ flex: 1 }} justifyContent="center">
        <Column alignSelf="center">
          <LoadingActivityIndicator />
        </Column>
      </Row>
    </AppointmentShowPageLayout>
  );
};
