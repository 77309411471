import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  H3TtmSemiBoldBlack,
  H6NsRegularBlack,
  H5TtmSemiBoldBlack,
  H6NsSemiBoldSecondaryBlack,
  H6NsSemiBoldBlack,
} from '../commonText';
import { StatusBadge } from '../misc/StatusBadge';
import { ProfileImageComponent } from '../profile/ProfileImageComponent';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { extractSymptoms } from '~/common/commonMethods';
import { mr10, flex1, mv5, ml10 } from '~/common/commonStyles';
import { colors } from '~/utils/colors';
import { parseDateTime, TIME_FORMAT } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';

interface Props {
  item: ConsultationModel;
}

export const DiagnosisConsultationPatientCard: React.FC<Props> = ({ item }) => {
  return (
    <View style={[styles.fullWidth, { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }]}>
      <View style={[styles.details]}>
        <View style={styles.mobileCardHeader}>
          <View style={mr10}>
            <ProfileImageComponent account={item.doctor} size={50} />
          </View>
          <View style={flex1}>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <H3TtmSemiBoldBlack style={flex1}>{`${item.doctor?.title || ''} ${item.doctor?.first_name || ''} ${
                item.doctor?.last_name || ''
              }`}</H3TtmSemiBoldBlack>
              <View style={ml10}>
                <StatusBadge
                  label={!item.submitted_at ? labels.draft : labels.done}
                  color={!item.submitted_at ? colors.warning : colors.success}
                  textStyle={{ textTransform: 'capitalize' }}
                />
              </View>
            </View>
            <View style={mv5}>
              {item?.doctor?.areas_of_specialisation?.map((element, index) => {
                return <H6NsRegularBlack key={index}>{element.name}</H6NsRegularBlack>;
              })}
            </View>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <H5TtmSemiBoldBlack style={{ color: colors.info }}>
                {parseDateTime(item.start_at, { outputFormat: 'DD MMM YY, HH:mm' })} {item.end_at ? ' - ' : null}
                {parseDateTime(item.end_at, { outputFormat: TIME_FORMAT })}
              </H5TtmSemiBoldBlack>
            </View>
          </View>
        </View>
        <View>
          <H6NsSemiBoldSecondaryBlack style={{ whiteSpace: 'pre-wrap' }}>{labels.symptoms}</H6NsSemiBoldSecondaryBlack>
          <H6NsRegularBlack>{extractSymptoms(item)}</H6NsRegularBlack>
        </View>
        {item.reason ? (
          <View style={styles.reason}>
            <H6NsSemiBoldSecondaryBlack>Reason</H6NsSemiBoldSecondaryBlack>
            <H6NsRegularBlack>{item.reason}</H6NsRegularBlack>
          </View>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  details: {
    flex: 1,
    minWidth: 60,
    marginRight: 10,
  },
  mobileCardHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
    overflow: 'hidden',
    width: '100%',
    alignItems: 'center',
  },
  reason: {
    marginTop: 5,
  },
});
