import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';

import { PrescriptionRequestApprovalComponent } from './PrescriptionRequestApprovalComponent';
import { PrescriptionRequestRejectionComponent } from './PrescriptionRequestRejectionComponent';
import { ErrorAlert } from '../ErrorAlert';
import { ModalContainer } from '../ModalContainer';
import { BaseModalProps } from '../interfaces/ModalProps';

import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import { apiAcceptPrescriptionRequest, apiRejectPrescriptionRequest } from '~/api/services/pharmacy';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { SuccessAlert } from '~/common/commonMethods';
import { flex1, ml10, mr10 } from '~/common/commonStyles';
import { Button, OutlineButton, TextButton } from '~/components/commonButton';
import FloatingInput from '~/components/inputs/FloatingInput';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { maxLengthValidation, validationSchema } from '~/services/validationConfig';
import { Grid } from '~/theme/components/grid';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Form {
  customerMessage: string;
}
interface Props extends BaseModalProps {
  request: PrescriptionRequestModel;
}

export const PrescriptionRequestApprovalModal: React.FC<Props> = ({ request, onHide }) => {
  const [loading, setLoading] = useState(false);
  const [declining, setDeclining] = useState(false);
  const { isMobile } = useBreakpoints();
  const startDecline = () => {
    setDeclining(true);
  };
  const approve = async (data: Form) => {
    try {
      setLoading(true);
      await apiAcceptPrescriptionRequest({
        requestId: request.id,
        params: {
          customer_instructions: data.customerMessage,
        },
      });
      SuccessAlert([], 'Prescription approved');
      hide(true);
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setLoading(false);
    }
  };
  const reject = async (data: Form) => {
    try {
      setLoading(true);
      await apiRejectPrescriptionRequest({
        requestId: request.id,
        params: {
          reason: data.customerMessage,
        },
      });
      SuccessAlert([], 'Prescription declined');
      hide(true);
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setLoading(false);
    }
  };

  const hide = (changes?: boolean) => {
    if (changes) AppEventHandler.emit(AppEvents.PHARMACY_REQUESTS_UPDATE);
    onHide();
  };

  const { handleSubmit, control } = useForm<Form>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      customerMessage: '',
    },
  });

  const buttons = declining
    ? [
        <Button label={labels.submit} funCallback={handleSubmit(reject)} disabled={loading} />,
        <OutlineButton label={labels.cancel} funCallback={hide} disabled={loading} />,
      ]
    : isMobile
    ? [
        <OutlineButton label="Decline" funCallback={startDecline} danger disabled={loading} />,
        <Button success label="Approve" funCallback={handleSubmit(approve)} disabled={loading} />,
        <TextButton label={labels.close} funCallback={hide} />,
      ]
    : [
        <View style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
          <View style={flex1}>
            <OutlineButton style={mr10} label="Decline" funCallback={startDecline} danger disabled={loading} />
          </View>
          <View style={flex1}>
            <Button style={ml10} success label="Approve" funCallback={handleSubmit(approve)} disabled={loading} />
          </View>
        </View>,
        <TextButton label={labels.close} funCallback={hide} />,
      ];

  const input = (
    <Controller
      name="customerMessage"
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
        <FloatingInput
          value={value}
          onChangeValue={onChange}
          onBlur={onBlur}
          error={isSubmitted && !!error?.message}
          errorMessage={isSubmitted && error?.message}
          label={declining ? 'Reason' : 'Customer instructions'}
          maxLength={validationSchema.string.maxLength}
        />
      )}
      rules={{
        maxLength: maxLengthValidation(validationSchema.string.maxLength),
      }}
    />
  );

  return (
    <ModalContainer onHide={hide} showInfoLine wide buttons={buttons}>
      <View>
        {loading ? (
          <LoadingActivityIndicator />
        ) : (
          <Grid>
            {!declining ? (
              <PrescriptionRequestApprovalComponent input={input} request={request} />
            ) : (
              <PrescriptionRequestRejectionComponent input={input} request={request} />
            )}
          </Grid>
        )}
      </View>
    </ModalContainer>
  );
};
