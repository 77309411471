import { calculateAge } from './dateAndTime';

import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { MedicalRegistrationModel } from '~/api/models/medicalRegistration/models/MedicalRegistrationModel';

export const getInitialsFromAccount = (account?: AccountModel) => {
  if (!account) return null;
  return getInitials(account);
};

export const getInitials = (data: Pick<AccountModel, 'first_name' | 'last_name' | 'clinic_name'>) => {
  if (data?.first_name || data?.last_name)
    return `${data?.first_name?.charAt(0) ?? ''}${data?.last_name?.charAt(0) ?? ''}`.toUpperCase();
  else if (data?.clinic_name) {
    return data.clinic_name
      .split(' ')
      .map((word) => (word?.length ? word.charAt(0) : ''))
      .join('')
      .slice(0, 3);
  }
};

export const getAccountName = (data: Pick<AccountModel, 'first_name' | 'last_name' | 'title' | 'clinic_name'>) => {
  if (!data) return '';
  if (data?.first_name || data?.last_name)
    return `${data?.title ? `${data.title}. ` : ''}${data?.first_name ? `${data.first_name} ` : ''}${
      data.last_name ?? ''
    }`;
  else if (data?.clinic_name) return data.clinic_name;

  return '';
};

export const getAccountPhone = (
  data: Pick<AccountModel, 'country_code' | 'mobile_number'>,
  mobileKey = 'mobile_number'
) => {
  if (!data) return '';
  return `${data?.country_code ? `+${data.country_code}` : ''}${(data && data[mobileKey]) ?? ''}`;
};

export const getDoctorExperience = (medicalCouncilRegistration: MedicalRegistrationModel) => {
  if (!medicalCouncilRegistration) return;

  const experience = calculateAge(medicalCouncilRegistration.warrant_year);
  return experience === 1 ? '1 Year' : `${experience} Years`;
};

export const getAccountID = (account: AccountModel) => {
  return account?.passport_number || account?.national_id_number || '';
};
