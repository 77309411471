import Constants from 'expo-constants';
import * as Location from 'expo-location';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { ExtraSmallNsRegularBlack } from '~/components/commonText';
import { LatLng } from '~/integrations/mapView/interfaces/LatLng';
import { MapViewRef } from '~/integrations/mapView/interfaces/MapViewRef';
import { Circle, GoogleMapView, Marker } from '~/integrations/mapView/mapView';
import { colors } from '~/utils/colors';

interface Props {
  latLng?: LatLng;
  radius?: number;
  hideRadius?: boolean;
  width?: number | string;
  height?: number | string;
  disableInitialLocation?: boolean;
  setLatLng?: (latLng: LatLng) => void;
  setFormattedAddress?: (address: string) => void;
}

const googleMapsKey = Constants.expoConfig.extra.googleMapsApiKey;
const DEFAULT_LOCATION = { lat: 35.90187844099918, lng: 14.475023746490479 };

export const HomeVisitMapView: React.FC<Props> = ({
  radius,
  latLng,
  setLatLng,
  setFormattedAddress,
  hideRadius,
  height = 300,
  width = '100%',
  disableInitialLocation,
}) => {
  const mapViewRef = useRef<MapViewRef>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const draggable = useMemo(() => !!setLatLng, [setLatLng]);
  const convertToAddress = async (coordinates: LatLng) => {
    if (!setFormattedAddress) return;
    await mapViewRef.current?.addressForCoordinate(coordinates).then((res) => {
      setFormattedAddress(res.formatted_address);
    });
  };

  const setInitialLocation = async () => {
    if (disableInitialLocation) return;
    setIsLoading(true);
    try {
      await Location.requestForegroundPermissionsAsync();
      const data = await Location.getCurrentPositionAsync();
      const initialLocation =
        data?.coords?.latitude && data?.coords?.longitude
          ? { lat: data.coords.latitude, lng: data.coords.longitude }
          : { ...DEFAULT_LOCATION };
      if (setLatLng) setLatLng(initialLocation);
      convertToAddress(initialLocation);
    } catch {
      if (setLatLng) setLatLng({ ...DEFAULT_LOCATION });
      convertToAddress({ ...DEFAULT_LOCATION });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!latLng?.lat || !latLng.lng) {
      setInitialLocation();
    }
  }, []);

  const setMarkerCoordinate = (latLng: LatLng) => {
    if (!draggable) return;
    setLatLng(latLng);
    convertToAddress(latLng);
  };
  return (
    <View>
      <GoogleMapView
        apiKey={googleMapsKey}
        ref={mapViewRef}
        style={styles.map}
        width={width}
        height={height}
        region={{
          latitude: latLng?.lat || DEFAULT_LOCATION.lat,
          longitude: latLng?.lng || DEFAULT_LOCATION.lng,
          latitudeDelta: 0.5,
          longitudeDelta: 1,
        }}
        onPress={setMarkerCoordinate}>
        {isLoading ? null : (
          <>
            {!hideRadius ? (
              <Circle
                fillColor={isDragging ? '#00000000' : '#B8E7E880'}
                strokeColor={isDragging ? '#00000000' : colors.info}
                center={latLng ?? DEFAULT_LOCATION}
                radius={radius * 1000}
                isDragging={isDragging}
              />
            ) : null}

            <Marker
              coordinate={latLng ?? DEFAULT_LOCATION}
              onDragEnd={(e) => {
                if (!draggable) return;
                setIsDragging(false);
                setMarkerCoordinate(e.latLng);
              }}
              onDrag={() => setIsDragging(true)}
              draggable={draggable}
            />
          </>
        )}
      </GoogleMapView>
      {isLoading ? (
        <View style={styles.loading}>
          <ExtraSmallNsRegularBlack>Retrieving current location</ExtraSmallNsRegularBlack>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  map: {
    flex: 1,
    height: 300,
    width: 'auto',
    borderRadius: 10,
    position: 'relative',
    overflow: 'hidden',
  },
  loading: {
    backgroundColor: colors.white,
    padding: 5,
    position: 'absolute',
    top: 0,
    left: 0,
    borderBottomRightRadius: 10,
  },
});
