import React, { useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  InputModeOptions,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextInputFocusEventData,
  TextStyle,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import { TextInput } from 'react-native-paper';
import { RenderProps } from 'react-native-paper/lib/typescript/components/TextInput/types';

import { MandatoryAsterisks } from './MandatoryAsterisks';
import { colors } from '../../utils/colors';
import { FormErrorMessage, H6NsRegularBlack } from '../commonText';

import { flex1 } from '~/common/commonStyles';
import { fontFamily } from '~/utils/fontFamily';

export interface InputProps {
  value: string;
  label?: string;
  placeholder?: string;
  componentKey?: string;
  showMandatory?: boolean;
  onChangeValue?: CallableFunction;
  onSubmitEditing?: () => void;
  onFocus?: CallableFunction;
  onBlur?: CallableFunction;
  height?: number;
  multilineHeight?: number;
  maxLength?: number;
  multiline?: boolean;
  numberOfLines?: number;
  error?: boolean;
  errorMessage?: string;
  keyboardType?: InputModeOptions;
  disabled?: boolean;
  editable?: boolean;
  autoFocus?: boolean;
  viewStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<TextStyle>;
  pointerEvents?: ViewProps['pointerEvents'];
  left?: React.ReactNode;
  right?: React.ReactNode;
  outlineColor?: string;
  noTrim?: boolean;
  render?: (props: RenderProps) => React.ReactNode;
  mode?: 'flat' | 'outlined';
  underlineColor?: string;
}

const FloatingInput = (
  {
    value,
    maxLength,
    errorMessage,
    keyboardType,
    error,
    label,
    placeholder,
    height,
    multilineHeight = 120,
    showMandatory,
    onChangeValue,
    multiline,
    disabled,
    style,
    viewStyle,
    editable = true,
    autoFocus,
    onFocus,
    onBlur,
    onSubmitEditing,
    numberOfLines,
    pointerEvents,
    left,
    right,
    outlineColor,
    noTrim,
    render,
    mode = 'outlined',
    underlineColor = colors.transparent,
  }: InputProps,
  ref
): JSX.Element => {
  const [isFocused, setFocused] = useState(false);
  // const _animatedIsFocused = new Animated.Value(0);
  const input = useRef();
  useImperativeHandle(ref, () => ({
    focus: () => {
      (input.current as any).focus();
    },
  }));

  const isFocusedOrFull = useMemo(() => !!value || isFocused, [value, isFocused]);

  const handleFocus = (ev: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setFocused(true);
    if (onFocus) onFocus(ev);
  };

  const handleBlur = () => {
    setFocused(false);

    if (!noTrim && /^\s+|\s+$/g.test(value)) {
      onChangeValue(value.trim());
    }
    if (onBlur) {
      onBlur();
    }
  };

  const showTextCount = useMemo(() => multiline && !!maxLength, [multiline, maxLength]);
  return (
    <View ref={ref} style={viewStyle}>
      <TextInput
        ref={input}
        mode={mode}
        underlineColor={underlineColor}
        autoFocus={autoFocus}
        disabled={disabled}
        editable={editable}
        multiline={multiline}
        numberOfLines={numberOfLines ?? (multiline ? 6 : undefined)}
        label={
          label ? (
            <Text
              style={[
                styles.label,
                isFocusedOrFull ? styles.labelFocused : styles.labelUnfocused,
                disabled ? styles.labelDisabled : null,
              ]}>
              {label}
              <MandatoryAsterisks isMandatory={showMandatory || false} />
            </Text>
          ) : null
        }
        value={value || ''}
        maxLength={maxLength}
        outlineColor={outlineColor || (error ? colors.danger : value ? colors.info : colors.lightPurple)}
        activeOutlineColor={outlineColor || (error ? colors.danger : colors.info)}
        autoCorrect={false}
        inputMode={keyboardType}
        placeholder={placeholder}
        placeholderTextColor={colors.purpleGrey}
        dense
        // multilineTopPosition={18}
        style={[
          styles.textInput,
          style,
          { height: multiline ? multilineHeight : height },
          isFocusedOrFull ? styles.textInputFocused : null,
        ]}
        onChangeText={(text) => {
          onChangeValue && onChangeValue(text);
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        blurOnSubmit
        pointerEvents={pointerEvents}
        left={left}
        right={right}
        onSubmitEditing={onSubmitEditing}
        render={render}
      />
      {errorMessage || showTextCount ? (
        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
          <View style={flex1}>{errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}</View>
          {showTextCount ? (
            <H6NsRegularBlack style={styles.textCounter}>
              {value?.length || 0}/{maxLength}
            </H6NsRegularBlack>
          ) : null}
        </View>
      ) : null}
    </View>
  );
};

export default React.forwardRef(FloatingInput);

const styles = StyleSheet.create({
  textInput: {
    width: '100%',
    alignSelf: 'center',
    minHeight: 45,
    marginTop: 8,
    color: colors.purpleGrey,
    backgroundColor: colors.lightPurple,
    fontFamily: fontFamily.fontRegular,
    fontSize: 14,
  },
  textInputFocused: {
    backgroundColor: colors.white,
  },
  label: {
    color: colors.purpleGrey,
    fontFamily: 'NotoSans',
    fontSize: 12,
  },
  labelFocused: {
    backgroundColor: colors.white,
    color: colors.info,
    lineHeight: 14,
  },
  labelDisabled: {
    color: colors.purpleGrey,
  },
  labelUnfocused: {
    // backgroundColor: 'transparent',
  },
  textCounter: {
    fontSize: 10,
    color: colors.purpleGrey,
  },
});
