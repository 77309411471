import React from 'react';

import { PageTypesEnum } from '~/api/models/pages/constants/PageTypesEnum';
import PolicyContent from '~/components/policies/PolicyContent';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { Column, Container, Row } from '~/theme/components/grid';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const HelpCookiePolicy: React.FC = () => {
  return (
    <NestedPageInternalLayout backTitle={labels.help} backLink={{ screen: screenName.Help }}>
      <Container fluid>
        <Row justifyContent="center">
          <Column width={800} alignSelf="center">
            <PolicyContent contentType={PageTypesEnum.COOKIE_POLICY} />
          </Column>
        </Row>
      </Container>
    </NestedPageInternalLayout>
  );
};
