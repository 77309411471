import { Link } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { Pressable, StyleSheet } from 'react-native';

import { useStoredRoute } from '../../redux/hooks/StoredRoute';
import { Column, Container, Grid, Row } from '../../theme/components/grid';
import { colors } from '../../utils/colors';
import { H6NsRegularBlack, nsSemiBold } from '../commonText';

import { mb10, mb15 } from '~/common/commonStyles';
import { useBreakpoints } from '~/utils/hooks/GridHook';

type PageTabsVariant = 'joined' | 'separated';

export interface PageTabItem {
  title: string;
  key?: string;
  link?: { screen: string; params?: Record<string, any> };
  onPress?: () => void;
  hidden?: boolean;
}
interface Props {
  pageTabsVariant?: PageTabsVariant;
  items: PageTabItem[];
  right?: React.ReactNode;
  activeKey?: string;
  fluid?: boolean;
}

export const PageTabsComponent: React.FC<Props> = ({
  items,
  pageTabsVariant = 'separated',
  right,
  activeKey,
  fluid,
}) => {
  const { route } = useStoredRoute();
  const { isMobile } = useBreakpoints();
  const styles = useMemo(() => pageTabStyle(pageTabsVariant), [pageTabsVariant]);

  const span = useMemo(() => (!isMobile ? 'auto' : 12), []);

  return (
    <Grid grid={{ noOuterGutter: true, gutters: 15 }}>
      <Container alignSelf="flex-start" style={styles.container} fluid={fluid}>
        <Row alignItems="center">
          {items
            .filter((item) => !item.hidden)
            .map((item) => {
              const active =
                (activeKey && item.key === activeKey) ||
                (item.link && route && route.name.indexOf(item.link.screen) === 0);
              return (
                <Column key={item.key || item.title} span={span} style={styles.column}>
                  {item.link ? (
                    <Link
                      to={item.link as never}
                      style={[isMobile ? null : styles.link, active ? [styles.linkActive, nsSemiBold] : null]}>
                      {item.title}
                    </Link>
                  ) : (
                    <Pressable onPress={item.onPress}>
                      <H6NsRegularBlack
                        style={[isMobile ? null : styles.link, active ? [styles.linkActive, nsSemiBold] : null]}>
                        {item.title}
                      </H6NsRegularBlack>
                    </Pressable>
                  )}
                </Column>
              );
            })}

          {right ? (
            <>
              <Column />
              <Column span="auto" alignSelf="flex-end">
                {right}
              </Column>
            </>
          ) : null}
        </Row>
      </Container>
    </Grid>
  );
};

const styles = (pageTabsVariant: PageTabsVariant) => {
  switch (pageTabsVariant) {
    case 'joined':
      return {
        link: {
          backgroundColor: colors.transparent,
        },
        linkActive: {
          color: colors.black,
          backgroundColor: colors.lightPurple2,
        },
        container: {
          backgroundColor: colors.lightPurple,
          borderRadius: 6,
        },
      };

    default: {
      return {
        link: {
          backgroundColor: colors.lightPurple,
        },
        linkActive: {
          backgroundColor: colors.purple,
          color: colors.white,
        },
        column: mb10,
      };
    }
  }
};
const pageTabStyle = (pageTabsVariant: PageTabsVariant) => {
  const { link, linkActive, container, column } = styles(pageTabsVariant);
  return StyleSheet.create({
    container: { ...mb15, ...container },
    link: {
      padding: 8,
      borderRadius: 6,
      minWidth: 110,
      textAlign: 'center',
      ...link,
    },
    linkActive: {
      ...linkActive,
    },
    column,
  });
};
