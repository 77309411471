import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';

import { ErrorAlert } from './ErrorAlert';
import { ModalContainer } from './ModalContainer';
import { ModalName } from './constants/ModalNames';
import { BaseModalProps } from './interfaces/ModalProps';
import { DataTable } from '../common/DataTable/DataTable';
import { DataTableColumns } from '../common/DataTable/DataTableTypes';
import { Button } from '../commonButton';
import {
  H3TtmSemiBoldBlack,
  SmallNsRegularBlack,
  H3TtmRegularBlack,
  SmallNsRegularSecondaryBlack,
} from '../commonText';
import { Checkbox } from '../inputs/Checkbox';
import { ProfileImageComponent } from '../profile/ProfileImageComponent';

import { useNonDigimedPatientsList } from '~/api/hooks/patients/NonDigimedPatientsListHook';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { apiSendNotificationsAll, apiSendNotifications } from '~/api/services/accounts';
import { SuccessAlert } from '~/common/commonMethods';
import { alignItemsCenter, flex1, mb5, mr10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { colors } from '~/utils/colors';
import { parseDateTime, DATE_FORMAT } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { errorMessages } from '~/utils/labels/errorMessages';
import { getAccountName } from '~/utils/personalDetailsUtils';

export const NotifyPatientsModal: React.FC<BaseModalProps> = ({ onHide }) => {
  const { pagination, setPagination, nonDigimedPatients, loading } = useNonDigimedPatientsList();
  const { isMobile } = useBreakpoints();

  const { closeModalByName } = useModalManager();
  const [selected, setSelected] = useState<number[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const columns: DataTableColumns = [
    {
      key: 'patient',
      title: 'Patient',
    },
    {
      key: 'gender',
      title: 'Gender',
    },
    {
      key: 'date_of_birth',
      title: 'Date of birth',
      selector: (item) => {
        return parseDateTime(item.date_of_birth, { outputFormat: DATE_FORMAT }) || '';
      },
    },
    {
      key: 'notify',
      title: '',
    },
  ];

  const buttons = [
    <View key="button" style={{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE, paddingBottom: MOBILE_HORIZONTAL_SPACE }}>
      <Button
        disabled={!selected.length && !isSelectAll}
        label={labels.sendEmail}
        funCallback={async () => {
          try {
            if (isSelectAll) {
              await apiSendNotificationsAll();
            } else if (selected.length) {
              await apiSendNotifications({ account_ids: selected });
            }
            SuccessAlert([labels.sendPatientNotification], '', '', () => {
              closeModalByName(ModalName.NotifyPatients);
            });
          } catch (e) {
            ErrorAlert(errorMessages.patientNotificationMessage);
          }
        }}
      />
    </View>,
  ];

  useEffect(() => {
    if (isSelectAll) {
      const allIds = nonDigimedPatients.map((patient) => patient.id);
      setSelected(allIds);
    } else if (!isSelectAll && selected.length === nonDigimedPatients.length) {
      setSelected([]);
    }
  }, [isSelectAll]);

  useEffect(() => {
    if (selected.length === nonDigimedPatients.length && nonDigimedPatients.length > 0) {
      setIsSelectAll(true);
    } else if (selected.length !== nonDigimedPatients.length) {
      setIsSelectAll(false);
    }
  }, [selected]);

  const handleIndividualCheckboxChange = (id: number, isChecked: boolean) => {
    if (isChecked) {
      setSelected((prevSelected) => [...prevSelected, id]);
    } else {
      setSelected((prevSelected) => prevSelected.filter((selectedId) => selectedId !== id));
    }
  };

  return (
    <ModalContainer hideOnBackground onHide={onHide} fullScreen edge showInfoLine buttons={buttons} noScroll>
      <View style={{ display: 'flex', flex: 1 }}>
        <View style={{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE }}>
          <View style={{ alignSelf: 'center', marginTop: 20 }}>
            <H3TtmSemiBoldBlack>{labels.patients}</H3TtmSemiBoldBlack>
          </View>

          <View style={alignItemsCenter}>
            <SmallNsRegularBlack>{labels.sendEmailToCreateAccount}</SmallNsRegularBlack>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <Checkbox label={labels.selectAll} checked={isSelectAll} onChange={() => setIsSelectAll((prev) => !prev)} />
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <DataTable
            style={isMobile ? undefined : { paddingHorizontal: MOBILE_HORIZONTAL_SPACE }}
            tableId="nonDigimedPatients"
            columns={columns}
            items={nonDigimedPatients}
            totalCount={pagination?.total || 0}
            page={pagination?.current_page}
            onPageChanged={setPagination}
            onItemsPerPageChange={setPagination}
            infiniteScroll={{ loading, estimatedItemSize: { desktop: 60, mobile: 110 } }}
            numberOfItemsPerPage={pagination?.per_page}
            forceMobileView
            emptyNote={isMobile ? labels.noPatients : <></>}
            mobileRenderItem={(account: AccountModel, item) => (
              <TouchableWithoutFeedback key={account.id}>
                <View style={styles.row}>
                  <View style={mr10}>
                    <ProfileImageComponent account={account} size={60} />
                  </View>
                  <View style={flex1}>
                    <H3TtmRegularBlack style={[mb5, { marginTop: 3 }]}>{getAccountName(account)}</H3TtmRegularBlack>
                    <View>
                      <View style={styles.row}>
                        <SmallNsRegularSecondaryBlack style={{ width: 75, color: colors.purpleGrey, marginRight: 10 }}>
                          Patient ID
                        </SmallNsRegularSecondaryBlack>
                        <SmallNsRegularBlack style={flex1}>
                          {account.passport_number || account.national_id_number}
                        </SmallNsRegularBlack>
                      </View>
                      <View style={styles.row}>
                        <SmallNsRegularSecondaryBlack style={{ width: 75, color: colors.purpleGrey, marginRight: 10 }}>
                          Gender
                        </SmallNsRegularSecondaryBlack>
                        <SmallNsRegularBlack style={{ textTransform: 'capitalize' }}>
                          {account.gender}
                        </SmallNsRegularBlack>
                      </View>
                      <View style={styles.row}>
                        <SmallNsRegularSecondaryBlack style={{ width: 75, color: colors.purpleGrey, marginRight: 10 }}>
                          DOB
                        </SmallNsRegularSecondaryBlack>
                        <SmallNsRegularBlack style={flex1}>{item.date_of_birth.content}</SmallNsRegularBlack>
                      </View>
                    </View>
                  </View>
                  <View style={{ alignSelf: 'center' }}>
                    <Checkbox
                      label=""
                      checked={selected.includes(account.id)}
                      onChange={(isChecked) => handleIndividualCheckboxChange(account.id, isChecked)}
                    />
                  </View>
                </View>
              </TouchableWithoutFeedback>
            )}
          />
        </View>
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
});
