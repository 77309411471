import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';

import { SearchBox } from '~/components/searchBox';
import { NavType } from '~/navigation/types';
import { useStoredRoute } from '~/redux/hooks/StoredRoute';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

interface RouteParams {
  q?: string;
}

export const PatientSearch: React.FC = () => {
  const { route } = useStoredRoute();
  const [searchValue, setSearchValue] = useState('');
  const { navigate } = useNavigation<NavType>();
  useEffect(() => {
    const routeParams: RouteParams = route?.params;
    if (routeParams?.q) {
      setSearchValue(routeParams.q);
    } else {
      setSearchValue(null);
    }
  }, [route]);

  const onSubmitSearch = () => {
    navigate(screenName.PatientManagement, searchValue ? { q: searchValue } : undefined);
  };

  const onBlur = () => {
    const routeParams: RouteParams = route?.params;
    if (searchValue !== routeParams?.q && !searchValue) {
      // submit search when clearing search
      onSubmitSearch();
    }
  };

  return (
    <SearchBox
      value={searchValue}
      onTextChange={setSearchValue}
      onSubmit={onSubmitSearch}
      onBlur={onBlur}
      placeholder={labels.searchPatientName}
      style={{ marginVertical: 0 }}
    />
  );
};
