import { useEffect, useState } from 'react';

import { useReduxInfiniteScroll } from '../common/useReduxInfiniteScroll';
import { useFeatureAccess } from '../subscriptions/FeatureAccessHook';

import { WithConsultationFilterModel } from '~/api/models/consultations/models/WithConsultationFilterModel';
import { PrescriptionsIndexRequest } from '~/api/models/consultations/requests/PrescriptionsIndexRequest';
import { PrescriptionIndexResponse } from '~/api/models/consultations/responses/PrescriptionsIndexResponse';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { apiGetPrescriptionIndex } from '~/api/services/consultations/prescriptions';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { RootState } from '~/redux/reducers';
import { prescriptionsSelector, SET_PRESCRIPTIONS_PAGE } from '~/redux/reducers/patient/prescriptionsReducer';
import { useAppDispatch } from '~/redux/store';

interface Props {
  sort?: PrescriptionsIndexRequest['sort'];
  limit?: number;
  query?: string;
}
export function usePrescriptionList(props?: Props) {
  const dispatch = useAppDispatch();
  const [isActive, setIsActive] = useState(true);
  const { hasFeatureAccess } = useFeatureAccess();
  const { openModal } = useModalManager();
  const { items, loading, pagination, setPagination } = useReduxInfiniteScroll<
    WithConsultationFilterModel,
    PrescriptionIndexResponse
  >({
    watch: [isActive, props?.sort],
    paginationSelector: (state: RootState) => state.prescriptionsReducer.pagination,
    itemsSelector: prescriptionsSelector,
    onResponse: (response) => dispatch(SET_PRESCRIPTIONS_PAGE(response.data)),
    getItems: (params) =>
      apiGetPrescriptionIndex({
        filter: { ...params.filter, is_active: isActive },
        sort: props?.sort,
        limit: params.limit,
        page: params.page,
      }),
  });

  useEffect(() => {
    if (!isActive && !hasFeatureAccess(FeatureAccessEnum.HEALTH_COMPANION)) {
      openModal(ModalName.PaywallModal, {
        title: 'Upgrade plan to view used prescriptions',
      });
    }
  }, [isActive]);

  return { setPagination, prescriptions: items, pagination, loading, isActive, setIsActive };
}
