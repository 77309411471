import { Link, ParamListBase, TabNavigationState, useNavigation, useRoute } from '@react-navigation/native';
import React, { ReactNode, useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { SmallNsRegularSecondaryBlack } from '../../commonText';
import { TabSelectionMobileComponent } from './TabSelectionMobileComponent';
import { TabItem } from './interface';

import { mb10 } from '~/common/commonStyles';
import { NavType } from '~/navigation/types';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  forceMobile?: boolean;
  items: TabItem[];
  state?: TabNavigationState<ParamListBase>;
  right?: ReactNode;
}

export const TabSelectionComponent: React.FC<Props> = ({ state, items, forceMobile, right }) => {
  const route = useRoute();

  const { isMobile } = useBreakpoints();
  const navigation = useNavigation<NavType>();

  const mobileTabs = useMemo(() => {
    return items.map((item, index) => ({
      title: item.title,
      key: index,
      focused: route.name === item.link.screen || (state && state.index === index),
    }));
  }, [state]);

  const mobileNavigate = (index: number) => {
    const item = items[index];
    navigation.navigate(item.link.screen, item.link.params);
  };

  if (isMobile || forceMobile) {
    return <TabSelectionMobileComponent items={mobileTabs} tabWidth={115} onPress={mobileNavigate} />;
  }

  return (
    <Grid grid={{ noOuterGutter: true, gutters: 12 }}>
      <Container alignSelf="flex-start" style={{ justifyContent: 'center' }}>
        <Row>
          {items.map((item) => {
            return (
              <Column span="auto" key={item.title} style={mb10}>
                <Link
                  to={item.link as never}
                  style={[route.name === item.link.screen ? styles.active : styles.inactive]}>
                  <SmallNsRegularSecondaryBlack>{item.title}</SmallNsRegularSecondaryBlack>
                </Link>
              </Column>
            );
          })}
          {right ? (
            <Column span={{ xs: 12, md: 'flex' }}>
              <Row justifyContent="flex-end">{right}</Row>
            </Column>
          ) : null}
        </Row>
      </Container>
    </Grid>
  );
};

const styles = StyleSheet.create({
  active: {
    borderRadius: 6,
    borderColor: colors.info,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10,
    paddingHorizontal: 17,
  },
  inactive: {
    padding: 10,
    borderRadius: 6,
    borderColor: colors.lightPurple,
    borderWidth: 1,
    paddingHorizontal: 17,
  },
});
