import React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';

import { AuditTableItem } from './AuditTableItem';
import { DataTableProps } from '../common/DataTable/DataTableTypes';
import { LoadingActivityIndicator } from '../loading/LoadingActivityIndicator';

import { ActivityDataModel } from '~/api/models/audit/models/ActivityDataModel';
import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { Pagination } from '~/components/common/DataTable/Pagination';
import { colors } from '~/utils/colors';

interface Props {
  data: ActivityDataModel[];
  pagination: PaginationMetaModel;
  loading?: boolean;
  onPageChanged: DataTableProps['onPageChanged'];
}

export const AuditTable: React.FC<Props> = ({ data, pagination, loading, onPageChanged }) => {
  return (
    <View>
      <View style={styles.flatList}>
        {loading ? (
          <LoadingActivityIndicator style={{ minHeight: 170 }} />
        ) : (
          <FlatList
            keyExtractor={(item) => `${item.id}`}
            data={data}
            renderItem={({ item }) => <AuditTableItem value={item} />}
          />
        )}
      </View>
      <Pagination
        page={pagination?.current_page || 1}
        totalCount={pagination?.total || 0}
        numberOfItemsPerPage={pagination?.per_page || 0}
        onPageChanged={onPageChanged}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  flatList: {
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.lightGrey,
  },
});
