import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { OngoingAppointments } from './OngoingAppointments';

import { flex1, mb10, mb20, mb30, mt20, p20, textCenter } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { Gradient } from '~/components/common/Gradient';
import { ButtonLink } from '~/components/commonButton';
import { H4TtmSemiBoldBlack } from '~/components/commonText';
import { DashboardCard } from '~/components/dashboard/common/DashboardCard';
import { AwaitingConfirmationAppointments } from '~/components/dashboard/patient/AwaitingConfirmationAppointments';
import { HeaderActionsMobile } from '~/components/dashboard/patient/DashboardHeader/HeaderActionsMobile';
import { PatientMobileHeader } from '~/components/dashboard/patient/DashboardHeader/PatientMobileHeader';
import { EducationCentreList } from '~/components/dashboard/patient/EducationCentreList';
import { UpcomingAppointments } from '~/components/dashboard/patient/UpcomingAppointments';
import { CompleteProfile } from '~/components/dashboard/patient/completion/CompleteProfile';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const PatientMobileDashboard: React.FC = () => {
  return (
    <View style={[styles.container]}>
      <PatientMobileHeader />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Grid grid={{ gutters: 10 }}>
          <Container alignSelf="flex-start" style={flex1}>
            <AwaitingConfirmationAppointments style={{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE, marginTop: 22 }} />
            <UpcomingAppointments style={{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE, marginTop: 22 }} />
            <OngoingAppointments style={{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE, marginTop: 20 }} />
            <Row style={[{ paddingHorizontal: 25 }]}>
              <Column>
                <CompleteProfile style={mt20} />
              </Column>
            </Row>
            <Row style={styles.rowstyle}>
              <Column>
                <H4TtmSemiBoldBlack style={mb10}>{labels.howCanWeHelp}</H4TtmSemiBoldBlack>
                <Row style={mb20}>
                  <Column>
                    <ButtonLink
                      label="Book an appointment"
                      screenName={screenName.AppointmentsBooking}
                      style={[textCenter, p20]}
                    />
                  </Column>
                </Row>
                <HeaderActionsMobile />
              </Column>
            </Row>
            <Row style={[styles.rowstyle, mb30]}>
              <Column>
                <H4TtmSemiBoldBlack style={mb10}>{labels.digimedEducationCentre}</H4TtmSemiBoldBlack>
                <DashboardCard style={{ height: '100%', display: 'flex' }}>
                  <EducationCentreList scrollable hideTitle />
                </DashboardCard>
              </Column>
            </Row>
          </Container>
        </Grid>
      </ScrollView>
      <Gradient />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
    display: 'flex',
    alignContent: 'space-between',
  },
  content: {
    marginTop: 10,
  },
  rowstyle: {
    paddingHorizontal: 25,
    marginTop: 20,
  },
  actionItemsContainer: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
});
