import { get } from '../../axios';
import { CountryIndexResponse } from '../../models/referenceData/responses/CountryIndexResponse';

import { MaltaId } from '~/api/hooks/referenceData/constants/CountryConstants';
import { UsedLanguagesRequest } from '~/api/models/referenceData/requests/UsedLanguagesRequest';
import { InsuranceClaimDocumentTypesResponse } from '~/api/models/referenceData/responses/InsuranceClaimDocumentTypesResponse';
import { LanguagesResponse } from '~/api/models/referenceData/responses/LanguagesResponse';
import { LocalityIndexResponse } from '~/api/models/referenceData/responses/LocalityIndexResponse';

export const apiCountryIndex = () => {
  return get<CountryIndexResponse>('/countries');
};

export const apiLocalityIndex = () => {
  return get<LocalityIndexResponse>('/localities', { filter: { country_id: MaltaId }, limit: -1 });
};

export const apiGetUsedLanguages = (req?: UsedLanguagesRequest) => {
  return get<LanguagesResponse>('used-languages', req);
};

export const apiGetInsuranceClaimDocumentTypes = () => {
  return get<InsuranceClaimDocumentTypesResponse>('/insurance-claim-document-types');
};
