import React from 'react';

import { OnBoardingContentPlaceholder } from '../onBoardingContentPlaceholder';

import { onboardingLabels } from '~/utils/labels/onboardingLabels';
interface Props {
  width: number;
}
export const OnBoardingPatientPage1: React.FC<Props> = ({ width }) => {
  return (
    <OnBoardingContentPlaceholder
      width={width}
      right
      maskUp
      title={onboardingLabels.patient[0].title}
      subtitle={onboardingLabels.patient[0].subTitle}
      image={require('~/../assets/img/onboarding/patient_1.png')}
    />
  );
};
