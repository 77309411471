import React from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { connect } from 'react-redux';

import { OngoingAppointments } from './OngoingAppointments';
import { DashboardWebHeader } from '../../components/dashboard/doctor/DashboardHeader/DashboardWebHeader';
import { PatientsList } from '../../components/dashboard/doctor/PatientsList';
import { colors } from '../../utils/colors';

import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { flex1, mb20 } from '~/common/commonStyles';
import { DashboardAppointmentsToday } from '~/components/dashboard/doctor/AppointmentsList/DashboardAppointmentsToday';
import { IncomeCard } from '~/components/dashboard/doctor/IncomeCard';
import { RequestsList } from '~/components/dashboard/doctor/RequestsList';
import { AppointmentListingProvider } from '~/providers/appointment/AppointmentListingProvider';
import { RootState } from '~/redux/reducers';
import { Grid, Row, Column, Container } from '~/theme/components/grid';

const mapStateToProps = (state: RootState) => ({
  userDetails: state.userDetailsReducer.userDetails,
  consultationDetails: state.consultationDetailsReducer.consultationDetails,
});

const DoctorDashboard: React.FC = () => {
  const { hasFeatureAccess } = useFeatureAccess();

  const incomeAccess = hasFeatureAccess(FeatureAccessEnum.INCOME);
  return (
    <View style={styles.container}>
      <ScrollView
        style={styles.content}
        contentContainerStyle={styles.scrollContent}
        showsVerticalScrollIndicator={false}>
        <Grid>
          <Container fluid alignSelf="flex-start" style={{ height: '100%' }}>
            <Row style={{ marginBottom: 20 }}>
              <Column>
                <DashboardWebHeader />
              </Column>
            </Row>
            <Row style={flex1}>
              <Column span={{ xs: 12, md: 12, lg: 8 }}>
                <Row>
                  <Column>
                    <OngoingAppointments includeEnded style={mb20} maxCount={2} />
                  </Column>
                </Row>

                <Row style={flex1}>
                  <Column style={mb20}>
                    <AppointmentListingProvider today ascending>
                      <DashboardAppointmentsToday />
                    </AppointmentListingProvider>
                  </Column>
                </Row>

                <Row style={flex1}>
                  <Column style={mb20}>
                    <RequestsList />
                  </Column>
                </Row>
              </Column>
              <Column span={{ xs: 12, md: 12, lg: 4 }}>
                <Row style={flex1}>
                  <Column style={mb20}>
                    <PatientsList />
                  </Column>
                </Row>
                {incomeAccess ? (
                  <Row>
                    <Column style={mb20}>
                      <IncomeCard />
                    </Column>
                  </Row>
                ) : null}
              </Column>
            </Row>
          </Container>
        </Grid>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
    display: 'flex',
  },
  content: {
    marginTop: 10,
  },
  scrollContent: {
    minHeight: '100%',
    paddingRight: 5,
  },
});

export default connect(mapStateToProps)(DoctorDashboard);
