import React, { useEffect, useMemo } from 'react';
import { View } from 'react-native';

import { ConsultationViewItem } from '../../ConsultationViewItem';

import { usePolling } from '~/api/hooks/Polling';
import { useSickLeave } from '~/api/hooks/consultations/SickLeaveHook';
import { MediaStatusEnum } from '~/api/models/common/constants/MediaStatusEnum';
import { SickLeaveCertificateEnum } from '~/api/models/consultations/constants/SickLeaveCertificateEnum';
import { ph10, pv20 } from '~/common/commonStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import { LoadingFetchingList } from '~/components/loading/LoadingFetchingList';
import { useSickLeaveDataContext } from '~/providers/consultation/SickLeaveDataContext';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const SickLeaveCertificateView: React.FC = () => {
  const { consultation, pollForMedia, medicalNoteMedia, loading: medicalNoteLoading } = useSickLeaveDataContext();

  const {
    loading: sickLeaveLoading,
    generatedFiles: generatedSickLeaveFiles,
    generatedFilesCommonStatus: generatedSickLeaveFilesCommonStatus,
    getSickLeaveCertificatesMedia,
  } = useSickLeave({ consultationId: consultation.id, editing: false });

  const {
    startPolling: medicalNoteStartPolling,
    stopPolling: medicalNoteStopPolling,
    isPolling: medicalNoteIsPolling,
  } = usePolling(pollForMedia, 2000);

  const {
    startPolling: sickLeaveCertificatesStartPolling,
    stopPolling: sickLeaveCertificatesStopPolling,
    isPolling: sickLeaveCertificatesIsPolling,
  } = usePolling(getSickLeaveCertificatesMedia, 2000);

  const { isMobile } = useBreakpoints();

  const hasSickLeaveCertificates = useMemo(
    () => !!generatedSickLeaveFiles?.length && generatedSickLeaveFilesCommonStatus !== null,
    [generatedSickLeaveFiles, generatedSickLeaveFilesCommonStatus]
  );

  const hasMedicalNote = useMemo(() => !!medicalNoteMedia?.media?.length, [medicalNoteMedia?.status]);
  const hasAnyData = useMemo(() => {
    return hasMedicalNote || hasSickLeaveCertificates;
  }, [hasMedicalNote, hasSickLeaveCertificates]);

  useEffect(() => {
    const status = medicalNoteMedia?.status;
    if (status === MediaStatusEnum.COMPLETED || status === MediaStatusEnum.FAILED || status === null) {
      medicalNoteStopPolling();
    } else if (!medicalNoteIsPolling()) {
      medicalNoteStartPolling();
    }
  }, [medicalNoteMedia?.status]);

  useEffect(() => {
    if (
      generatedSickLeaveFilesCommonStatus === MediaStatusEnum.COMPLETED ||
      generatedSickLeaveFilesCommonStatus === null
    ) {
      sickLeaveCertificatesStopPolling();
    } else if (!sickLeaveCertificatesIsPolling()) {
      sickLeaveCertificatesStartPolling();
    }
  }, [generatedSickLeaveFilesCommonStatus]);

  if ((sickLeaveLoading || medicalNoteLoading) && !hasAnyData) return <LoadingFetchingList fullWidth />;

  return (
    <View>
      {hasSickLeaveCertificates ? (
        <>
          {generatedSickLeaveFiles?.map((file, index) => {
            const isLast = !hasMedicalNote && index === generatedSickLeaveFiles.length - 1;
            return (
              <View key={index}>
                <ConsultationViewItem
                  title={file.type === SickLeaveCertificateEnum.EMPLOYER ? 'Employer Copy' : 'Employee Copy'}
                  value={file.media}
                  style={isLast ? { borderBottomWidth: 0 } : null}
                  status={file.status}
                />
              </View>
            );
          })}
        </>
      ) : null}

      {hasMedicalNote ? (
        <ConsultationViewItem
          title="Medical Note"
          value={medicalNoteMedia?.media?.length ? medicalNoteMedia.media[0] : undefined}
          status={medicalNoteMedia.status}
          style={{ borderBottomWidth: 0 }}
        />
      ) : null}

      {!hasAnyData ? (
        <View style={isMobile ? pv20 : [ph10, pv20]}>
          <H6NsRegularBlack>No certificates or medical notes were added</H6NsRegularBlack>
        </View>
      ) : null}
    </View>
  );
};
