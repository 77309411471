import { DocumentPickerAsset } from 'expo-document-picker';

import { deleteReq, get, post, put } from '~/api/axios';
import { PatientConsultationRequest } from '~/api/models/appointments/requests/PatientConsultationRequest';
import { PatientConsultationSubmitRequest } from '~/api/models/appointments/requests/PatientConsultationSubmitRequest';
import { PatientConsultationResponse } from '~/api/models/appointments/responses/PatientConsultationResponse';
import fileSystem from '~/integrations/fileSystem';

export const apiCreateConsultationRequest = (req: PatientConsultationRequest) => {
  return post<PatientConsultationResponse>('/patient/consultations', req);
};

export const apiUpdateConsultationRequest = (consultationId: number, req: PatientConsultationRequest) => {
  return put<PatientConsultationResponse>(`/patient/consultations/${consultationId}/request`, req);
};

export const apiGetConsultationRequest = (consultationId: number) => {
  return get<PatientConsultationResponse>(`/patient/consultations/${consultationId}/request`);
};

export const apiAppendConsultationReasonMedia = (consultationId: number, document: DocumentPickerAsset) => {
  return fileSystem.postDocumentResult(`/patient/consultations/${consultationId}/reason/media`, 'files[]', document);
};

export const apiRemoveConsultationReasonMedia = (consultationId: number, mediaIds: number[]) => {
  return deleteReq(`/patient/consultations/${consultationId}/reason/media`, {
    media_ids: mediaIds,
  });
};

export const apiSubmitConsultationRequest = (req: PatientConsultationSubmitRequest) => {
  return post<void>(`/patient/consultations/${req.consultationId}/request/submit`, req.params);
};
