import React from 'react';
import { Text, View } from 'react-native';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { extractSymptoms } from '~/common/commonMethods';
import { flex1 } from '~/common/commonStyles';
import { DataTableColumns } from '~/components/common/DataTable/DataTable';
import { H5TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { colors } from '~/utils/colors';
import { parseDateTime, DAY_MONTH_FORMAT, TIME_FORMAT } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';

export const doctorColumns: DataTableColumns = [
  {
    key: 'reference',
    title: 'Diagnosis Ref no.',
    cell: (cellData) => <H6NsRegularBlack>Ref No: {`${cellData || ''}`}</H6NsRegularBlack>,
    columnStyle: { maxWidth: 200 },
  },
  {
    key: 'dateTime',
    title: 'Date and time',
    cell: (_cellData, itemData) => (
      <View style={{ display: 'flex', flexDirection: 'column' }}>
        <H5TtmSemiBoldBlack>{parseDateTime(itemData.start_at, { outputFormat: DAY_MONTH_FORMAT })}</H5TtmSemiBoldBlack>
        <Text>
          {parseDateTime(itemData.start_at, { outputFormat: TIME_FORMAT })} {itemData.end_at ? ' - ' : null}
          {parseDateTime(itemData.end_at, { outputFormat: TIME_FORMAT })}
        </Text>
      </View>
    ),
    columnStyle: { maxWidth: 150 },
  },
  {
    key: 'doctor',
    title: 'Specialist',
    cell: (cellData) => <ProfileDetailsComponent account={cellData} detail=" " theme="table" style={flex1} />,
  },
  {
    key: 'patient',
    title: 'Patient',
    cell: (cellData) => <ProfileDetailsComponent account={cellData} theme="table" style={flex1} />,
  },
  {
    key: 'draftOrDone',
    title: 'Status',
    cell: (cellData, itemData) => (
      <StatusBadge
        label={!itemData.submitted_at ? labels.draft : labels.done}
        color={!itemData.submitted_at ? colors.warning : colors.success}
        textStyle={{ textTransform: 'capitalize' }}
      />
    ),
    columnStyle: { maxWidth: 100 },
  },
];

export const patientColumns: DataTableColumns = [
  {
    key: 'reference',
    title: 'Diagnosis Ref no.',
    cell: (cellData) => <H6NsRegularBlack>Ref No: {`${cellData || ''}`}</H6NsRegularBlack>,
    columnStyle: { maxWidth: 200 },
  },
  {
    key: 'dateTime',
    title: 'Date and time',
    cell: (_cellData, itemData) => (
      <View style={{ display: 'flex', flexDirection: 'column' }}>
        <H5TtmSemiBoldBlack>{parseDateTime(itemData.start_at, { outputFormat: DAY_MONTH_FORMAT })}</H5TtmSemiBoldBlack>
        <Text>
          {parseDateTime(itemData.start_at, { outputFormat: TIME_FORMAT })} {itemData.end_at ? ' - ' : null}
          {parseDateTime(itemData.end_at, { outputFormat: TIME_FORMAT })}
        </Text>
      </View>
    ),
    columnStyle: { maxWidth: 150 },
  },
  {
    key: 'doctor',
    title: 'Specialist',
    cell: (cellData, itemData) => (
      <ProfileDetailsComponent style={[{ alignItems: 'center' }, flex1]} account={cellData} isDoctor theme="table" />
    ),
  },
  {
    key: 'patient',
    title: 'Patient',
    cell: (cellData) => <ProfileDetailsComponent account={cellData} theme="table" style={flex1} />,
  },
  {
    key: 'symptoms',
    title: 'Symptoms',
    cell: (_cellData, itemData: ConsultationModel) => (
      <H6NsRegularBlack style={{ whiteSpace: 'pre-wrap' }}>{extractSymptoms(itemData)}</H6NsRegularBlack>
    ),
    columnStyle: { maxWidth: 400 },
  },
];
