import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { TextButton } from '../commonButton';
import { H3TtmSemiBoldTheme, H6NsSemiBoldTheme } from '../commonText';
import { FaqQuestions } from '../help/faq/FaqQuestions';

import { flex1, mb20, pt15 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { labels } from '~/utils/labels';
import { helpLabels } from '~/utils/labels/helpLabels';

interface Props extends BaseModalProps {
  selectedTaxon?: string;
  searchText?: string;
  title?: string;
}

export const FaqMobileModal: React.FC<Props> = ({ selectedTaxon, searchText, title, onHide }) => {
  const safeArea = useSafeAreaInsets();

  const buttons = [
    <TextButton style={{ marginBottom: safeArea.bottom }} label={labels.close.toUpperCase()} funCallback={onHide} />,
  ];
  return (
    <ModalContainer
      animationType="slide"
      hideOnBackground
      hideNotch
      fullScreen
      edge
      showInfoLine
      onHide={onHide}
      buttons={buttons}
      modalStyle={styles.modalStyle}>
      <View style={[flex1]}>
        <View style={[styles.modalHeader, styles.horizontalPadding, pt15]}>
          <View style={styles.modalTitle}>
            <H3TtmSemiBoldTheme>{helpLabels.faqTitle}</H3TtmSemiBoldTheme>
          </View>
        </View>
        <View style={[mb20, styles.horizontalPadding]}>
          <H6NsSemiBoldTheme>{title}</H6NsSemiBoldTheme>
        </View>
        <FaqQuestions taxonomySlug={selectedTaxon} search={searchText} withContentPadding />
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  modalContent: {
    display: 'flex',
    flex: 1,
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  horizontalPadding: {
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
  },
  transformHorizontalFlip: {
    transform: [{ rotateY: '180deg' }],
  },
  modalTitle: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  back: {
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalStyle: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
});
