import * as Location from 'expo-location';
import { TaskManagerTaskBody, defineTask } from 'expo-task-manager';

import { locationTrackingConstants } from './locationConstants';
import { isNative } from '../buildConfig';

import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';

export const locationTaskName = 'location-tracking';

export async function isTracking(): Promise<boolean> {
  if (isNative()) return await Location?.hasStartedLocationUpdatesAsync(locationTaskName);
}

export async function startTracking({ timeInterval = locationTrackingConstants.timeInterval }) {
  await Location.startLocationUpdatesAsync(locationTaskName, {
    accuracy: Location.Accuracy.BestForNavigation,
    timeInterval,
    pausesUpdatesAutomatically: false,
    // android behavior
    foregroundService: {
      notificationTitle: 'Location is active',
      notificationBody: 'Monitoring your location',
      notificationColor: '#333333',
    },
    // ios behavior
    activityType: Location.ActivityType.AutomotiveNavigation,
    showsBackgroundLocationIndicator: true,
  });
}

export async function stopTracking() {
  await Location.stopLocationUpdatesAsync(locationTaskName);
}

defineTask(locationTaskName, (event: TaskManagerTaskBody<{ locations: Location.LocationObject[] }>) => {
  if (event.error) {
    console.error(event.error);
    return;
  }

  const locations = event.data.locations;

  AppEventHandler.emit(AppEvents.DOCTOR_LOCATION_UPDATE, [locations]);
});
