import { Route } from '@react-navigation/native';
import React from 'react';

import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { MobileMenuPageComponent } from '~/components/navigation/MobileMenuPageComponent';
import { PATIENT_HEALTH_PROFILE_PAGES } from '~/constants/patients/HealthProfileMobilePages';
import { colors } from '~/utils/colors';
import { screenName } from '~/utils/screenName';
//MOBILE: PATIENT> HEALTH PROFILE
interface Props {
  route?: Route<string, { id: string }>;
}

export const PatientHealthProfileMobile: React.FC<Props> = ({ route }) => {
  const patientId = +route.params.id;
  return (
    <PatientsLayout
      title="Health Profile"
      hideHeaderRight
      style={{ backgroundColor: colors.lightPurple }}
      flexContent={false}
      back={{ to: { screen: screenName.PatientDetails, params: route.params } }}
      hideGradient
      scrollStyling={{ backgroundColor: colors.lightPurple }}>
      <MobileMenuPageComponent menu={PATIENT_HEALTH_PROFILE_PAGES} params={{ id: patientId } as never} />
    </PatientsLayout>
  );
};
