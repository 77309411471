import React, { ReactNode } from 'react';

import { PrescriptionRecordMobile } from './PrescriptionRecordMobile';
import { DataTable, DataTableColumns, TablePaginationInfo } from '../common/DataTable/DataTable';
import { OutlineButton } from '../commonButton';
import { ProfileDetailsComponent } from '../profile/ProfileDetailsComponent';

import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { flex1 } from '~/common/commonStyles';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { parseDateTime, DATE_FORMAT } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';

interface Props {
  prescriptions: PrescriptionModel[];
  pagination: PaginationMetaModel;
  onPageChanged: (page: TablePaginationInfo) => void;
  loading?: boolean;
  allowRowPress?: boolean;

  actionTitle?: string;
  emptyNote?: string;
  actionNode?: (prescription: PrescriptionModel) => ReactNode;
  onSelect?: (prescription: PrescriptionModel) => void;
  hideNewBadge?: boolean;
}
export const PharmacyPrescriptionsTable: React.FC<Props> = ({
  prescriptions,
  pagination,
  onPageChanged,
  onSelect,
  loading,
  actionTitle,
  actionNode,
  allowRowPress,
  emptyNote,
  hideNewBadge,
}) => {
  const { isMobile } = useBreakpoints();
  const { showNewBadge } = useNewBadge();

  const columns: DataTableColumns = [
    {
      key: 'doctor',
      title: 'Prescribed by',
      columnStyle: { maxWidth: 400, minWidth: 200 },
      cell: (_, itemData: PrescriptionModel) => (
        <ProfileDetailsComponent account={itemData.doctor} theme="table" style={flex1} />
      ),
    },
    {
      key: 'date',
      title: 'Created date',
      columnStyle: { minWidth: 120 },
      selector: (item: PrescriptionModel) => parseDateTime(item.created_at, { outputFormat: DATE_FORMAT }),
    },
    {
      key: 'patient',
      title: 'Patient',
      columnStyle: { maxWidth: 400, minWidth: 200 },
      cell: (_, itemData: PrescriptionModel) => (
        <ProfileDetailsComponent account={itemData.patient} theme="table" style={flex1} />
      ),
    },
    {
      key: 'reference',
      title: 'Prescription ref#',
      columnStyle: { maxWidth: 400 },
      selector: (item: PrescriptionModel) => item.reference_number,
    },
    actionTitle || actionNode
      ? {
          key: 'actions',
          title: '',
          columnStyle: { maxWidth: 400 },
          contentNumeric: true,
          showOnHover: true,
          cell: (_, itemData: PrescriptionModel) =>
            actionNode ? (
              actionNode(itemData)
            ) : (
              <OutlineButton label={actionTitle} funCallback={() => onSelect(itemData)} disabled={loading} />
            ),
        }
      : null,
  ];

  return (
    <DataTable
      tableId="prescriptionRequests"
      rowIsNewIdentifier={hideNewBadge ? undefined : (item) => showNewBadge({ value: item })}
      columns={columns}
      items={prescriptions}
      emptyNote={emptyNote}
      loading={loading}
      page={pagination?.current_page || 1}
      totalCount={pagination?.total || 0}
      numberOfItemsPerPage={pagination?.per_page || 0}
      onPageChanged={onPageChanged}
      onRowPress={isMobile || allowRowPress ? (_, item: PrescriptionModel) => onSelect(item) : null}
      mobileRenderItem={(content: PrescriptionModel) => (
        <PrescriptionRecordMobile prescriptionValue={content} dispenseNowButton />
      )}
      infiniteScroll={isMobile ? { loading, estimatedItemSize: { desktop: 60, mobile: 158 } } : null}
      mobileBreakpoint={DeviceSizeDefaults.tablet}
    />
  );
};
