import { differenceInDays, isSameDay } from 'date-fns';
import { Range } from 'react-date-range';

export const isToDate = (range: Range) => {
  return range?.startDate.getTime() === range?.endDate.getTime() && isSameDay(range?.endDate, new Date());
};

export const getTimeDifferenceAsDays = (range: Range) => {
  const difference = differenceInDays(range?.endDate, range?.startDate);

  if (difference === 0) {
    return 1;
  }
  return differenceInDays(range?.endDate, range?.startDate);
};
