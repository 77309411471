import React from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import { theme } from '../../utils/theme';

import { LabelValuePair } from '~/common/types/LabelValuePair';
import { FormErrorMessage, H6NsRegularBlack, H6NsRegularSecondaryBlack } from '~/components/commonText';
import { DaysOfTheWeek } from '~/constants/daysOfTheWeek';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  calendarDays?: LabelValuePair<string> & { short?: string };
  passedDates?: string[];
  onChange?: (...event: any[]) => void;
  errorMessage?: string;
}
export const DaySelectionCarousel: React.FC<Props> = ({ calendarDays, passedDates, onChange, errorMessage }) => {
  const daysList = calendarDays ? calendarDays : DaysOfTheWeek;
  const { isMobile } = useBreakpoints();
  const selectDays = (day) => {
    const newSelectedDays = [...passedDates];

    if (newSelectedDays.includes(day.value)) {
      const index = newSelectedDays.indexOf(day.value);
      if (index > -1) {
        newSelectedDays.splice(index, 1);
      }
    } else {
      newSelectedDays.push(day.value);
    }

    onChange(newSelectedDays);
  };

  return (
    <>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        horizontal
        style={{ paddingBottom: 10 }}
        bounces={false}
        bouncesZoom={false}>
        {daysList.map((day, index) => {
          return (
            <View key={index} style={{ alignItems: 'center', marginTop: 10 }}>
              <TouchableOpacity
                onPress={() => selectDays(day)}
                style={[
                  isMobile ? theme.repeatDaysButtonMobileStyle : theme.repeatDaysButtonStyle,
                  passedDates.includes(day.value) ? theme.selectedRepeatDaysButtonStyle : null,
                ]}>
                <H6NsRegularSecondaryBlack style={passedDates.includes(day.value) ? { fontWeight: 'bold' } : null}>
                  {day.short ?? day.label}
                </H6NsRegularSecondaryBlack>
              </TouchableOpacity>
            </View>
          );
        })}
      </ScrollView>
      {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}
    </>
  );
};
