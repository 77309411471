import { FlashList } from '@shopify/flash-list';
import React from 'react';
import { View } from 'react-native';
import { List } from 'react-native-paper';

import { FaqQuestion } from './FaqQuestion';

import { useFaq } from '~/api/hooks/help/FaqsHook';
import { flex1 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';

interface Props {
  taxonomySlug: string;
  search: string;
  withContentPadding?: boolean;
}

export const FaqQuestions: React.FC<Props> = ({ taxonomySlug, search, withContentPadding }) => {
  const { loading, faqs } = useFaq({ taxonomySlug, search });
  if (loading) return <LoadingActivityIndicator />;
  return (
    <List.AccordionGroup>
      <View style={flex1}>
        <FlashList
          data={faqs}
          keyExtractor={(item) => `${item.id}`}
          renderItem={({ item, index }) => (
            <View style={{ paddingHorizontal: withContentPadding ? MOBILE_HORIZONTAL_SPACE : 0 }}>
              <FaqQuestion faq={item} borderTop={index === 0} />
            </View>
          )}
          ListEmptyComponent={!loading && search ? <H6NsRegularBlack>No questions found</H6NsRegularBlack> : null}
          estimatedItemSize={73}
        />
      </View>
    </List.AccordionGroup>
  );
};
