import React from 'react';

import { flex1 } from '~/common/commonStyles';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { Row, Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';

interface Props {
  loading: boolean;
  children: React.ReactNode;
}

export const GridLoadingWrapper: React.FC<Props> = ({ loading, children }) => {
  return loading ? (
    <Row style={[flex1, { backgroundColor: colors.white }]}>
      <Column alignSelf="center">
        <LoadingActivityIndicator />
      </Column>
    </Row>
  ) : (
    <>{children}</>
  );
};
