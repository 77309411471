import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

import isNullOrUndefined from '~/utils/types/isNullOrUndefined';

export interface IState {
  healthProfile?: boolean;
  upgradedPlan?: boolean;
  familyMembers?: boolean;
  insurance?: boolean;
}

const initialState: IState = {
  healthProfile: undefined,
  upgradedPlan: undefined,
  familyMembers: undefined,
  insurance: undefined,
};

const profileCompletionReducer = createSlice({
  name: 'profileCompletion',
  initialState,
  reducers: {
    SET_HEALTH_PROFILE_COMPLETED: (state, action: PayloadAction<boolean>) => ({
      ...state,
      healthProfile: action.payload,
    }),
    SET_UPGRADED_PLAN_COMPLETED: (state, action: PayloadAction<boolean>) => ({
      ...state,
      upgradedPlan: action.payload,
    }),
    SET_FAMILY_MEMBERS_COMPLETED: (state, action: PayloadAction<boolean>) => ({
      ...state,
      familyMembers: action.payload,
    }),
    SET_INSURANCE_COMPLETED: (state, action: PayloadAction<boolean>) => ({
      ...state,
      insurance: action.payload,
    }),
    LOGOUT: () => ({ ...initialState }),
  },
});

export const {
  SET_HEALTH_PROFILE_COMPLETED,
  SET_UPGRADED_PLAN_COMPLETED,
  SET_FAMILY_MEMBERS_COMPLETED,
  SET_INSURANCE_COMPLETED,
  LOGOUT,
} = profileCompletionReducer.actions;

export default profileCompletionReducer.reducer;

export const hasCompletionState = (state: RootState) =>
  !isNullOrUndefined(state.profileCompletionReducer.healthProfile) &&
  !isNullOrUndefined(state.profileCompletionReducer.upgradedPlan) &&
  !isNullOrUndefined(state.profileCompletionReducer.familyMembers) &&
  !isNullOrUndefined(state.profileCompletionReducer.insurance);
