import { get, put } from '../../axios';
import { ProsthesisImplantsGraftsRequest } from '../../models/healthProfile/requests/ProsthesisImplantsGraftsRequest';
import { ProthesisImplantsGraftsResponse } from '../../models/healthProfile/responses/ProthesisImplantsGraftsResponse';

import { HealthProfileIdRequest } from '~/api/models/healthProfile/requests/HealthProfileIdRequest';
import { ProsthesisListResponse } from '~/api/models/healthProfile/responses/ProsthesisListResponse';

export const apiUpdateProsthesisImplantsGrafts = (params: { id: number; data: ProsthesisImplantsGraftsRequest }) => {
  return put<ProthesisImplantsGraftsResponse>(`/health-profile/${params.id}/prosthesis-implants-grafts`, params.data);
};

export const apiGetProsthesisImplantsGrafts = (params: HealthProfileIdRequest) => {
  return get<ProthesisImplantsGraftsResponse>(`/health-profile/${params.id}/prosthesis-implants-grafts`);
};

export const apiGetAllProsthesisImplantsGrafts = () => {
  return get<ProsthesisListResponse>(`/prosthesis-implants-grafts`);
};
