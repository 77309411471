import { get } from '~/api/axios';
import { BillingHistoryDataIndexRequest } from '~/api/models/billingHistory/requests/BillingHistoryDataIndexRequest';
import { BillingHistoryIndexResponse } from '~/api/models/billingHistory/responses/BillingHistoryIndexResponse';
import { InvoiceShowResponse } from '~/api/models/billingHistory/responses/InvoiceShowResponse';
import { StatementOfFeesShowResponse } from '~/api/models/billingHistory/responses/StatementOfFeesShowResponse';
import { IdModel } from '~/api/models/common/models/IdModel';

export const apiGetBillingHistoryDataIndex = (req: BillingHistoryDataIndexRequest) => {
  return get<BillingHistoryIndexResponse>('/me/transactions', req);
};

export const apiGetInvoiceDetails = (req: IdModel) => {
  return get<InvoiceShowResponse>(`/me/invoices/${req.id}`);
};

export const apiGetStatementOfFees = (req: IdModel) => {
  return get<StatementOfFeesShowResponse>(`/me/statement-of-fees/${req.id}`);
};
