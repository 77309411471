import { Link } from '@react-navigation/native';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { H5TtmRegularBlack, H5TtmSemiBoldTheme } from '../../commonText';

import { ml10, mr10, mr15 } from '~/common/commonStyles';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  title: string;
  linkTitle?: string;
  to?: To<ReactNavigation.RootParamList>;
  path?: string;
  style?: StyleProp<ViewStyle>;
  icon?: React.ReactNode;
  target?: string;
  onPress?: () => void;
}

export const TitledLinkButton: React.FC<Props> = ({
  title,
  linkTitle = 'View',
  to,
  target,
  path,
  style,
  icon,
  onPress,
}) => {
  const { isMobile } = useBreakpoints();
  return (
    <View style={[styles.container, style]}>
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        {icon ? <View style={[isMobile ? ml10 : null, mr15, mr10]}>{icon}</View> : null}
        <H5TtmRegularBlack>{title}</H5TtmRegularBlack>
      </View>
      {path ? (
        <a className="link-btn" href={path} target={target} onClick={onPress}>
          <H5TtmSemiBoldTheme>{linkTitle.toUpperCase()}</H5TtmSemiBoldTheme>
        </a>
      ) : (
        <Link to={to} target={target} onPress={onPress}>
          <H5TtmSemiBoldTheme>{linkTitle.toUpperCase()}</H5TtmSemiBoldTheme>
        </Link>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderRadius: 6,
    backgroundColor: colors.lightPurple,
  },
});
