import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  Person,
  HelpCircle,
  LogoutIcon,
  Settings,
  DownloadIcon,
  FolderDiagnoses,
  BillIcon,
  NewsIcon,
  InsuranceIcon,
  PatientGroup,
  SecondarySettings,
} from '~/components/svgImages';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { whenAppType } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

export const useMenusNativeSlide = () => {
  const currentPharmacy = useSelector(currentPharmacySelector);

  const doctor = [
    {
      image: (color: string) => <Person color={color} height={20} width={20} />,
      label: 'Profile',
      pageName: screenName.ProfileScreen,
    },
    {
      image: (color: string) => <Settings color={color} height={20} width={20} />,
      label: 'Settings',
      pageName: screenName.Settings,
    },
    {
      image: (color: string) => <HelpCircle color={color} height={20} width={20} />,
      label: 'Help',
      pageName: screenName.Help,
    },
    {
      image: (color: string) => <LogoutIcon color={color} height={20} width={20} />,
      label: 'Logout',
      pageName: screenName.OnboardingScreen,
    },
  ];

  const patient = [
    {
      image: (color: string) => <Person color={color} height={20} width={20} />,
      label: 'Profile',
      pageName: screenName.ProfileScreen,
    },
    {
      label: 'Diagnosis',
      image: (color: string) => <FolderDiagnoses height={20} width={20} color={color} />,
      pageName: screenName.Diagnoses,
    },
    {
      label: 'Insurance',
      image: (color: string) => <InsuranceIcon height={20} width={20} color={color} />,
      pageName: screenName.Insurance,
    },
    {
      label: 'Dependants',
      image: (color: string) => <PatientGroup height={20} width={20} color={color} />,
      pageName: screenName.Dependants,
    },
    {
      label: 'Billing',
      image: (color: string) => <BillIcon height={20} width={20} color={color} />,
      pageName: screenName.PatientBillingHistory,
    },
    {
      label: 'Education Centre',
      image: (color: string) => <NewsIcon height={20} width={20} color={color} />,
      pageName: screenName.EducationCentre,
    },
    {
      image: (color: string) => <Settings color={color} height={20} width={20} />,
      label: 'Settings',
      pageName: screenName.Settings,
    },
    {
      image: (color: string) => <HelpCircle color={color} height={20} width={20} />,
      label: 'Help',
      pageName: screenName.Help,
    },
    {
      image: (color: string) => <LogoutIcon color={color} height={20} width={20} />,
      label: 'Logout',
      pageName: screenName.OnboardingScreen,
    },
  ];

  const pharmacist = [
    {
      image: (color: string) => <Person color={color} height={20} width={20} />,
      label: 'Profile',
      pageName: screenName.ProfileScreen,
    },
    {
      image: (color: string) => <Settings color={color} height={20} width={20} />,
      label: 'Settings',
      pageName: screenName.Settings,
    },
    {
      image: (color: string) => <SecondarySettings color={color} height={20} width={20} />,
      label: 'Pharmacy settings',
      pageName: screenName.PharmacySettings,
      key: 'pharmacy_settings',
    },
    {
      image: (color: string) => <HelpCircle color={color} height={20} width={20} />,
      label: 'Help',
      pageName: screenName.Help,
    },
    {
      image: (color: string) => <DownloadIcon color={color} height={20} width={20} />,
      label: 'Document exports',
      pageName: screenName.Requests,
      pageParams: { screen: screenName.DocumentsExport },
    },
    {
      image: (color: string) => <LogoutIcon color={color} height={20} width={20} />,
      label: 'Logout',
      pageName: screenName.OnboardingScreen,
    },
  ];

  const clinic = [
    {
      image: (color: string) => <Person color={color} height={20} width={20} />,
      label: 'Profile',
      pageName: screenName.ProfileScreen,
    },
    {
      image: (color: string) => <Settings color={color} height={20} width={20} />,
      label: 'Settings',
      pageName: screenName.Settings,
    },
    {
      image: (color: string) => <SecondarySettings color={color} height={20} width={20} />,
      label: 'Clinic settings',
      pageName: screenName.SettingsClinic,
      key: 'clinic_settings',
    },
    {
      image: (color: string) => <HelpCircle color={color} height={20} width={20} />,
      label: 'Help',
      pageName: screenName.Help,
    },
  ];

  const menu = useMemo(() => {
    return whenAppType<
      {
        image: (color: string) => JSX.Element;
        label: string;
        pageName: string;
        pageParams?: Record<string, string>;
      }[]
    >({
      whenDoctor: doctor,
      whenPatient: patient,
      whenPharmacist: !currentPharmacy?.is_admin
        ? pharmacist.filter((menuItem) => menuItem.key !== 'pharmacy_settings')
        : pharmacist,
      whenClinic: clinic,
    });
  }, [currentPharmacy?.is_admin]);

  return { menu };
};
