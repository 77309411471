import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AppointmentListingContext } from './AppointmentListingContext';

import { useGeneralConsultationChannel } from '~/api/hooks/channels/useGeneralConsultationChannel';
import { useReduxTablePagination } from '~/api/hooks/common/useReduxTablePagination';
import { ConsultationEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { ConsultationsFilterModel } from '~/api/models/consultations/models/ConsultationsFilterModel';
import { ConsultationIndexForm } from '~/api/models/consultations/requests/ConsultationsIndexRequest';
import { ConsultationsPaginatedIndexResponse } from '~/api/models/consultations/responses/ConsultationsIndexResponse';
import { apiGetConsultationsIndex } from '~/api/services/consultations';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import {
  AppointmentFilter,
  AppointmentsFilterEnum,
  AppointmentsToday,
  processAppointmentStateFilters,
} from '~/constants/appointments/appointmentStateMapping';
import { CLEAR_TABLE } from '~/redux/reducers/tablePaginationReducer';
import { useAppDispatch } from '~/redux/store';
import { LARAVEL_DATE_TIME_SHORT } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { isDoctorVersion } from '~/utils/buildConfig';

interface Props {
  today?: boolean;
  ascending?: boolean;
  children: React.ReactNode;
}

const getDefaultStateFilters = (filter: AppointmentsFilterEnum) =>
  Object.entries(AppointmentFilter[filter].filters).reduce((prev, [key, value]) => {
    if (value.filter) {
      prev.push(key);
    }
    return prev;
  }, []);

const TABLE_NAME = 'appointmentListing';
export const AppointmentListingProvider: React.FC<Props> = ({ children, today, ascending }) => {
  const [loading, setLoading] = useState(true);
  const loadingRef = useRef(false);
  const { isMobile } = useBreakpoints();
  const dispatch = useAppDispatch();
  const { userDetails } = useUserDetails({ allowStale: true });

  const { control, handleSubmit, getValues, watch } = useForm<ConsultationIndexForm>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      filter: {
        state: {
          current: AppointmentsFilterEnum.UPCOMING,
        },
      },
      search: '',
      sort: ascending ? 'start_at' : '-start_at',
      appliedSort: '-start_at',
    },
  });

  const { items, pagination, setPagination, updateData, resetTable } = useReduxTablePagination<
    ConsultationsFilterModel,
    ConsultationsPaginatedIndexResponse
  >({
    isInfiniteScroll: isMobile,
    tableName: TABLE_NAME,
    getItems: async (params) => {
      setLoading(true);
      const data = getValues();
      const stateFilter = data.filter.state[data.filter.state.current];
      const state = today
        ? AppointmentsToday
        : stateFilter?.length
        ? stateFilter
        : getDefaultStateFilters(data.filter.state.current);
      const doctorId = isDoctorVersion() ? userDetails?.account?.id : undefined;
      const dates = today
        ? {
            after: moment().startOf('day').format(LARAVEL_DATE_TIME_SHORT),
            before: moment().endOf('day').format(LARAVEL_DATE_TIME_SHORT),
          }
        : {};

      const response = await apiGetConsultationsIndex({
        filter: {
          ...data?.filter,
          ...dates,
          state: processAppointmentStateFilters(state),
          doctor_id: doctorId,
        },
        page: params.page,
        sort: data?.sort,
      });
      setLoading(false);
      return response;
    },
  });

  const stateFilter = watch('filter.state.current');

  const submitQuery = async () => {
    try {
      if (loadingRef.current) return;
      setLoading(true);
      loadingRef.current = true;
      resetTable();
    } catch (e) {
      ErrorAlert(e);
    } finally {
      loadingRef.current = false;
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(CLEAR_TABLE({ tableName: TABLE_NAME }));
    resetTable();
  }, [stateFilter, today]);

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, resetTable);
    return removeListener;
  }, []);

  const setPage = (pageInfo: TablePaginationInfo) => {
    if (!items || pageInfo.page === 0) return;
    setPagination(pageInfo);
  };
  const consultations = useMemo(
    () => ({
      data: items,
      meta: pagination,
      links: [],
    }),
    [items, pagination]
  );

  const appointmentStateChangeEventHandler = (event) => {
    switch (event) {
      case ConsultationEventsEnum.CONSULTATION_UNRESPONSIVE_DOCTOR:
      case ConsultationEventsEnum.CONSULTATION_PATIENT_REQUEST_IGNORED:
      case ConsultationEventsEnum.CONSULTATION_REQUEST_APPROVED:
      case ConsultationEventsEnum.CONSULTATION_REQUEST_REJECTED:
      case ConsultationEventsEnum.CONSULTATION_APPOINTMENT_SCHEDULED:
      case ConsultationEventsEnum.CONSULTATION_RESCHEDULE_REQUEST_ACCEPTED:
      case ConsultationEventsEnum.CONSULTATION_RESCHEDULE_REQUEST_REJECTED:
      case ConsultationEventsEnum.CONSULTATION_STATE_ENDED:
      case ConsultationEventsEnum.CONSULTATION_STATE_STARTED:
      case ConsultationEventsEnum.CONSULTATION_CANCELLED:
      case ConsultationEventsEnum.CONSULTATION_PENDING_PAYMENT:
      case ConsultationEventsEnum.CONSULTATION_SUBMITTED:
      case ConsultationEventsEnum.CONSULTATION_ASSIGNED:
      case ConsultationEventsEnum.CONSULTATION_DOCTOR_CHANGED:
        updateData();
        break;
    }
  };

  useGeneralConsultationChannel({ eventHandler: appointmentStateChangeEventHandler });

  return (
    <AppointmentListingContext.Provider
      value={{
        filterControl: control,
        consultations,
        loading,
        setPage,
        submitQuery: handleSubmit(submitQuery),
      }}>
      {children}
    </AppointmentListingContext.Provider>
  );
};
