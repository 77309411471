import Feather from '@expo/vector-icons/Feather';
import React from 'react';
import { View, Pressable } from 'react-native';

import { PharmacyPrescriptionsTable } from './PharmacyPrescriptionsTable';
import { TablePaginationInfo } from '../common/DataTable/DataTableTypes';
import { H6NsRegularBlack } from '../commonText';
import { ModalName } from '../modals/constants/ModalNames';
import { PageTabsComponent } from '../tabs/PageTabsComponent';

import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import { mr15, mr10 } from '~/common/commonStyles';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  data: PrescriptionRequestModel[];
  loading?: boolean;
  pagination: PaginationMetaModel;
  sortDesc: boolean;
  onPageChanged: (info: TablePaginationInfo) => void;
  setSortingDesc: React.Dispatch<React.SetStateAction<boolean>>;
  updateData: () => void;
}
export const PharmacyAwaitingCollectionTable: React.FC<Props> = ({
  data,
  loading,
  pagination,
  sortDesc,
  onPageChanged,
  setSortingDesc,
  updateData,
}) => {
  const { isMobile } = useBreakpoints();
  const { openModal } = useModalManager();

  const tryDispense = (prescription: PrescriptionModel) => {
    openModal(ModalName.PrescriptionDispenseModal, { prescription, onHide: updateData });
  };

  const sorting = isMobile ? null : (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-end',
        ...mr15,
      }}>
      <Pressable
        style={{ paddingVertical: 12, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        onPress={() => {
          setSortingDesc(!sortDesc);
        }}>
        <H6NsRegularBlack style={mr10}>Sort by Date</H6NsRegularBlack>
        <Feather name={sortDesc ? 'chevron-down' : 'chevron-up'} size={14} />
      </Pressable>
    </View>
  );

  return (
    <View style={{ display: 'flex', flex: 1 }}>
      <PageTabsComponent items={[]} pageTabsVariant="joined" right={sorting} fluid />
      <View style={{ display: 'flex', flex: 1 }}>
        <PharmacyPrescriptionsTable
          actionTitle="Dispense now"
          onSelect={tryDispense}
          prescriptions={data?.map((p) => p.prescription) ?? []}
          pagination={pagination}
          onPageChanged={onPageChanged}
          loading={loading}
          emptyNote="No prescriptions awaiting collection"
        />
      </View>
    </View>
  );
};
