import React from 'react';
import { useSelector } from 'react-redux';

import { SuccessOrErrorPopup } from './SuccessOrErrorPopup';
import { BaseModalProps } from './interfaces/ModalProps';

import { RootState } from '~/redux/reducers';

export const MainModal: React.FC<BaseModalProps> = ({ onHide }) => {
  const modalProps = useSelector((state: RootState) => state.overLayReducer);
  return <SuccessOrErrorPopup {...modalProps} onHide={onHide} />;
};
