import React, { useEffect, useRef, useState } from 'react';

import { Button, TextButton } from '../../components/commonButton';
import { colors } from '../../utils/colors';
import { labels } from '../../utils/labels';
import { OTPTextInput, OTPTextInputRef } from '../inputs/OtpTextInput';

import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { useKeyboard } from '~/utils/hooks/KeyboardHook';
import { useSelector } from 'react-redux';
import { fullPageLoadingSelector } from '~/redux/reducers/overlayReducer';

interface Props {
  title?: string;
  subTitle?: React.ReactNode;
  footer?: React.ReactNode;
  errorFlag?: boolean;
  disableResendButton?: boolean;
  hideResendButton?: boolean;
  hideProgressBar?: boolean;
  onChange?: (otp: string) => void;
  onPress?: (value) => Promise<void>;
  onResend?: () => void;
  errorMessage?: string;
  waiting?: boolean;
  compressed?: boolean;
}

const OtpScreenComponent: React.FC<Props> = (props): JSX.Element => {
  const [otp, setOtp] = useState<string>('');
  const [otpClearFlag, setOtpClearFlag] = useState<boolean>(false);
  const { showing } = useKeyboard();
  const isPageLoading = useSelector(fullPageLoadingSelector);

  const [loading, setLoading] = useState(false);

  const otpBoxRef = useRef<OTPTextInputRef>();

  useEffect(() => {
    setTimeout(() => {
      setOtpClearFlag(false);
    }, 300);
  }, [otpClearFlag]);

  const click = () => {
    setOtp('');
    if (props.onPress) {
      setLoading(true);
      props.onPress(otp).finally(() => setLoading(false));
    }
  };

  const buttons = [
    <Button funCallback={click} label={labels.enter} disabled={props.waiting} />,

    props.hideResendButton || showing ? null : (
      <TextButton
        funCallback={props.onResend}
        disabled={props.disableResendButton || props.waiting}
        label={labels.resendCode}
      />
    ),
  ];
  return (
    <EnhancedRegistrationLayout
      title={props.title}
      subtitle={props.subTitle}
      hideProgress={props.hideProgressBar}
      compressed={props.compressed}
      buttons={buttons}>
      <OTPTextInput
        ref={otpBoxRef}
        handleTextChange={(e: string) => {
          if (props.onChange) props.onChange(e);
          setOtp(e);
        }}
        defaultValue={otp}
        inputCount={6}
        inputCellLength={1}
        keyboardType="numeric"
        tintColor={props.errorFlag ? colors.danger : colors.info}
        offTintColor={props.errorFlag ? colors.danger : colors.lightPurple2}
        autofocus={!isPageLoading}
        errorMessage={props.errorMessage}
        onEnter={click}
        editable={!props.waiting}
        style={{ paddingHorizontal: 20 }}
        loading={loading}
      />
    </EnhancedRegistrationLayout>
  );
};

export default OtpScreenComponent;
