import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import { useFeatureAccess } from '../subscriptions/FeatureAccessHook';

import { ConsultationTypeIdMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationTypeFormModel } from '~/api/models/consultations/models/ConsultationTypeFormModel';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { apiGetConsultationTypePriceIndex, apiUpdateConsultationTypePrice } from '~/api/services/consultations/prices';
import { apiStoreConsultationChannels, apiStorePreferredLanguages } from '~/api/services/registration';
import { SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import isNullOrUndefined from '~/utils/types/isNullOrUndefined';

interface Props {
  saveChannelsAndLanguages?: boolean;
}
export function useSettingsConsultationTypePrice(props?: Props) {
  const [loading, setLoading] = useState(false);
  const { isFeatureActive } = useFeatureAccess();

  const { handleSubmit, control, reset, watch, getValues } = useForm<ConsultationTypeFormModel>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      types: {
        home_visit: undefined,
        on_demand: undefined,
        scheduled_appointment: undefined,
      },
      languages: [],
      channels: [],
    },
  });
  const { dirtyFields } = useFormState({ control });
  const getConsultationTypePriceIndex = async () => {
    try {
      if (!(await isFeatureActive(FeatureAccessEnum.CONSULTATION_PRICES))) return;
      setLoading(true);
      const res = await apiGetConsultationTypePriceIndex();
      const priceObject = res.data.reduce((prev, item) => {
        prev[item.type] = item.price?.amount ?? 0;
        return prev;
      }, {});
      reset({
        ...getValues(),
        types: priceObject,
      });
    } catch {}
    setLoading(false);
  };

  useEffect(() => {
    getConsultationTypePriceIndex();
  }, []);

  const submit = async (details: ConsultationTypeFormModel) => {
    try {
      await apiUpdateConsultationTypePrice({
        consultation_type_price: Object.keys(details.types)
          .filter((type) => !isNullOrUndefined(details.types[type]))
          .map((type) => ({
            consultation_type_id: ConsultationTypeIdMapping[type],
            price: details.types[type],
            currency: 'EUR',
          })),
      });
      if (props?.saveChannelsAndLanguages) {
        if (dirtyFields.channels) {
          await apiStoreConsultationChannels({ consultation_channel_ids: details.channels });
        }
        if (dirtyFields.languages) {
          await apiStorePreferredLanguages({ languages: details.languages });
        }
      }

      reset({ ...details });
      SuccessAlert(['Consultation settings updated']);
    } catch (e) {
      console.error(e);
      ErrorAlert(e);
    }
  };

  return {
    loading,
    control,
    watch,
    reset,
    submitConsultationPrices: handleSubmit(submit),
  };
}
