import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import TimePicker from './common/TimePicker';
import { Button, OutlineButton } from './commonButton';
import { H6NsRegularBlack } from './commonText';
import { ModalContainer } from './modals/ModalContainer';
import { ModalName } from './modals/constants/ModalNames';
import { BaseModalProps } from './modals/interfaces/ModalProps';

import { useAppointmentReschedule } from '~/api/hooks/consultations/AppointmentRescheduleHook';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { requiredValidation, greaterThanValidation } from '~/services/validationConfig';
import { Column, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { TIME_FORMAT, parseDateTime, LARAVEL_ATOM } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';

interface Props extends BaseModalProps {
  consultation: ConsultationModel;
  onHide: () => void;
}

export const RescheduleAppointmentMobileModal: React.FC<Props> = ({ consultation, onHide }) => {
  const { closeModalByName } = useModalManager();
  const { control, submitRescheduleAppointment, handleSubmit } = useAppointmentReschedule(consultation);
  const [errorResponse, setErrorResponse] = useState<ErrorResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      setErrorResponse(null);
      await submitRescheduleAppointment(consultation);
      closeModalByName(ModalName.RescheduleAppointmentMobile);
      closeModalByName(ModalName.AppointmentNotification);
    } catch (e) {
      setErrorResponse(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ModalContainer
      animationType="slide"
      hideNotch={false}
      hideOnBackground
      onHide={onHide}
      buttons={[
        <Row style={{ paddingBottom: 30 }}>
          <Column style={{ paddingRight: 5 }}>
            <OutlineButton funCallback={onHide} label="Cancel" />
          </Column>
          <Column style={{ paddingLeft: 5 }}>
            <Button funCallback={handleSubmit(onSubmit)} label="Update" />
          </Column>
        </Row>,
      ]}
      modalStyle={{ backgroundColor: colors.lightPurple, justifyContent: 'center', alignContent: 'center' }}>
      <Grid>
        <Row style={{ paddingTop: 20 }}>
          <Column>
            <H6NsRegularBlack style={{ fontSize: 12 }}>
              {labels.scheduledAppointmentIn}5 minutes. {labels.reSchedule}
            </H6NsRegularBlack>
          </Column>
        </Row>

        <Row style={{ paddingTop: 25, paddingBottom: 40 }}>
          <Column>
            <Controller
              name="time"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <TimePicker
                  label="Choose time"
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message || errorResponse?.message}
                  error={!!error || !!errorResponse}
                />
              )}
              rules={{
                required: requiredValidation('Appointment time'),
                validate: {
                  greaterThanValidation: greaterThanValidation(
                    () =>
                      parseDateTime(consultation.start_at, {
                        parseFormat: LARAVEL_ATOM,
                        outputFormat: TIME_FORMAT,
                      }),
                    'Appointment Start Time',
                    'Selected Time'
                  ),
                },
              }}
            />
          </Column>
        </Row>
      </Grid>
    </ModalContainer>
  );
};
