import React from 'react';
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native';

import { BMIBar } from './BMIBar';
import { H2TtmSemiBoldBlack, H6NsRegularBlack } from './commonText';

import { textCenter } from '~/common/commonStyles';
import { colors } from '~/utils/colors';

interface Props {
  bmiValue: string;
  style?: StyleProp<ViewStyle>;
}

export const BmiUI: React.FC<Props> = (props) => {
  return (
    <View style={[props.style, styles.container]}>
      {props.bmiValue ? (
        <>
          <H6NsRegularBlack>Your BMI is</H6NsRegularBlack>
          <H2TtmSemiBoldBlack>{props.bmiValue}</H2TtmSemiBoldBlack>
        </>
      ) : (
        <H6NsRegularBlack style={textCenter}>{'Update your weight and height\nto calculate your BMI'}</H6NsRegularBlack>
      )}

      <View>
        <BMIBar bmiValue={Number(props.bmiValue)} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 22,
    backgroundColor: colors.lightPurple,
    alignItems: 'center',
  },
});
