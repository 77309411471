import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { DiagnosisContext } from './DiagnosisContext';

import { apiCreateDiagnosis } from '~/api/services/diagnoses';
import { CreateDiagnosisFormModel } from '~/components/doctor/patients/consultation/diagnosis/types/CreateDiagnosisFormModel';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { usePageFocus } from '~/utils/hooks/FocusHook';

interface Props {
  patientId: number;
  children: React.ReactNode;
  onSubmit?: (diagnosisId: number, reason?: string) => Promise<void>;
  onCancel?: () => Promise<void>;
  hideCancel?: boolean;
}

export const DiagnosisProvider: React.FC<Props> = ({ children, patientId, onCancel, hideCancel, onSubmit }) => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<CreateDiagnosisFormModel>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      exists: false,
      diagnosis: undefined,
      diagnosisId: undefined,
      consultationReason: '',
    },
  });

  const submit = async (data: CreateDiagnosisFormModel) => {
    let diagnosisId = undefined;
    try {
      setLoading(true);
      if (!data.exists) {
        const res = await apiCreateDiagnosis({
          account_id: patientId,
          diagnosis_id: data.diagnosis.value,
        });
        diagnosisId = res.data.id;
      } else {
        diagnosisId = data.diagnosisId;
      }

      if (onSubmit) await onSubmit(diagnosisId, data.consultationReason);
    } catch (e) {
      ErrorAlert(e);
    }

    setLoading(false);
  };

  usePageFocus(() => {
    reset();
  });

  return (
    <DiagnosisContext.Provider
      value={{
        patientId,
        formControl: control,
        submit: handleSubmit(submit),
        cancel: onCancel,
        hideCancel,
        loading,
      }}>
      {children}
    </DiagnosisContext.Provider>
  );
};
