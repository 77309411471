import React from 'react';

import { CalendarPageLayout } from './CalendarPageLayout';

import { CalendarNavigation } from '~/navigation/authorised/CalendarNavigation';

export const CalendarPageWeb: React.FC = () => {
  return (
    <CalendarPageLayout>
      <CalendarNavigation />
    </CalendarPageLayout>
  );
};
