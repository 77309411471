import { ExportConsultationsZipRequest } from '~/api/models/clinics/requests/ExportConsultationsZipRequest';
import { NotImplementedError } from '~/classes/errors/NotImplemented';
import fileSystem from '~/integrations/fileSystem';
import { isNative } from '~/utils/buildConfig';

export const apiExportConsultationsZip = (req: ExportConsultationsZipRequest) => {
  if (isNative()) throw new NotImplementedError();

  return fileSystem.downloadStream('/consultations/export-zip', {
    method: 'POST',
    fileName: req.fileName,
    data: req.params,
    mimeType: 'application/x-zip',
  });
};
