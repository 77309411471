import React, { useRef, useState } from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { SnackbarManagementContext, SnackbarOptions } from './SnackbarManagementContext';

import { SnackbarNotification } from '~/components/SnackbarNotification';
import { AlertCircle } from '~/components/svgImages';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

const SNACKBAR_DEFAULT_TIME = 8000;

interface SnackBarDataModel {
  id: number;
  message: string;
  options?: SnackbarOptions;
}

interface Props {
  children: React.ReactNode;
}
export const SnackbarManagementProvider: React.FC<Props> = ({ children }) => {
  const snackId = useRef(0);
  const lastSnackRef = useRef<string>();
  const { isMobile } = useBreakpoints();
  const snackBarsRef = useRef<SnackBarDataModel[]>([]);
  const [snackBars, setSnackBars] = useState<SnackBarDataModel[]>([]);
  const { top } = useSafeAreaInsets();
  const showSnackbar = async (message: string, options?: SnackbarOptions) => {
    if (options?.keyRef) {
      if (lastSnackRef.current === options.keyRef) {
        console.error('Multiple snacks with same ref', options.keyRef);
        return;
      } else {
        lastSnackRef.current = options.keyRef;
      }
    }

    snackId.current += 1;
    const newSnack: SnackBarDataModel = {
      id: snackId.current,
      message,
      options,
    };

    snackBarsRef.current = [...snackBarsRef.current, newSnack];
    setSnackBars(snackBarsRef.current);
  };

  const close = (snackId: number) => {
    const index = snackBarsRef.current.findIndex((snack) => snack.id === snackId);
    const tempSnacks = [...snackBarsRef.current];
    tempSnacks.splice(index, 1);
    snackBarsRef.current = tempSnacks;
    setSnackBars(tempSnacks);
  };

  return (
    <SnackbarManagementContext.Provider value={{ showSnackbar }}>
      {children}
      <View
        style={[
          {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            right: 0,
            top,
            maxHeight: '100%',
            maxWidth: '100%',
          },
          isMobile ? { width: '100%' } : { width: 400 },
        ]}>
        {snackBars?.map((snack) => {
          return (
            <SnackbarNotification
              key={snack.id}
              timer={snack.options?.noTimer ? null : SNACKBAR_DEFAULT_TIME}
              message={snack.message}
              actionTitle={snack.options?.actionTitle}
              onPress={snack.options?.onPress}
              onClose={() => close(snack.id)}
              icon={<AlertCircle width={20} height={20} color={snack.options?.isError ? colors.danger : colors.info} />}
              isError={snack.options?.isError}
            />
          );
        })}
      </View>
    </SnackbarManagementContext.Provider>
  );
};
