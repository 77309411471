import { get } from '~/api/axios';
import { FaqRequest } from '~/api/models/faq/requests/FaqRequest';
import { TaxonsRequest } from '~/api/models/faq/requests/TaxonsRequest';
import { FaqResponse } from '~/api/models/faq/responses/FaqResponse';
import { TaxonsResponse } from '~/api/models/faq/responses/TaxonsResponse';

export const apiGetTaxons = (req: TaxonsRequest) => {
  return get<TaxonsResponse>(`/taxonomies/${req.taxonomyId}/taxons`);
};

export const apiGetFaq = (req: FaqRequest) => {
  return get<FaqResponse>('/faqs', req);
};
