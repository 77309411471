export enum ConsultationStateEnum {
  Cancelled = 'cancelled',
  Draft = 'draft',
  Ended = 'ended',
  RequestIgnored = 'request_ignored',
  RequestRejected = 'request_rejected',
  RequestSubmitted = 'request_submitted',
  PendingPayment = 'pending_payment',
  Scheduled = 'scheduled',
  Started = 'started',
  Submitted = 'submitted',
}
