import React, { useState } from 'react';
import { View } from 'react-native';

import { AppointmentListFilterModal } from './modals/AppointmentListFilterModal';
import { AppointmentListSortingModal } from './modals/AppointmentListSortingModal';

import { IconButton } from '~/components/buttons/IconButton';
import { FilterIcon, SortIcon } from '~/components/svgImages';
import { colors } from '~/utils/colors';

export const AppointmentListMobileHeader: React.FC = () => {
  const [showSort, setShowSort] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 10 }}>
      <View style={{ flexDirection: 'row' }}>
        <IconButton transparent onPress={() => setShowSort(true)}>
          <SortIcon width={18} height={18} color={colors.purple} />
        </IconButton>
        <IconButton transparent onPress={() => setShowFilter(true)}>
          <FilterIcon width={18} height={18} color={colors.purple} />
        </IconButton>
      </View>

      <AppointmentListSortingModal show={showSort} onHide={() => setShowSort(false)} />
      <AppointmentListFilterModal show={showFilter} onHide={() => setShowFilter(false)} />
    </View>
  );
};
