import { ActivityDataModel } from '~/api/models/audit/models/ActivityDataModel';

export const extractAttributes = (value: ActivityDataModel) => {
  if (!value?.properties || !value.properties.length) return undefined;

  const attributes = value.properties.map((property) => property.attribute.replace(/_/g, ' '));
  if (attributes.length > 1) {
    return `${attributes.slice(0, -1).join(', ')} and ${attributes[attributes.length - 1]}`;
  } else {
    return attributes[0];
  }
};

export const extractSubject = (value: ActivityDataModel) => {
  return value.subject_type;
};

export const extractCauser = (value: ActivityDataModel) => {
  if (!value.causer) return undefined;
  return ` by ${value.causer.title ? value.causer.title + '.' : value.causer.first_name} ${value.causer.last_name}`;
};
