import React, { ReactNode } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Menu } from 'react-native-paper';

import { Checkbox } from './Checkbox';

import { LabelValuePair } from '~/common/types/LabelValuePair';

interface Props<T = string | number> {
  items: LabelValuePair<T, string | ReactNode>[];
  values: T[];
  onChange: (value: T[]) => void;
  idTitle?: string;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  children?: (value: string | number, isSelected?: boolean) => JSX.Element;
  singleValue?: boolean;
  isMenuItem?: boolean;
}

export const CheckboxGroup: React.FC<Props> = ({
  items,
  values,
  onChange,
  idTitle = 'value',
  style,
  disabled,
  children,
  singleValue = false,
  isMenuItem,
}) => {
  const change = (value) => {
    if (singleValue) {
      if (values?.includes(value)) {
        onChange([]);
      } else {
        const arr = [value];
        onChange(arr);
      }
    } else {
      if (values.includes(value)) {
        const arr = values.filter((e) => e !== value);
        onChange(arr);
      } else {
        onChange([...values, value]);
      }
    }
  };

  return (
    <View style={style}>
      {items.map((item) => (
        <View key={item.value}>
          {isMenuItem ? (
            <Menu.Item
              title={
                <View>
                  <Checkbox
                    key={item[idTitle]}
                    label={item.label}
                    checked={values.includes(item.value)}
                    onChange={(_) => {
                      change(item.value);
                    }}
                  />
                </View>
              }
              onPress={() => {
                change(item.value);
              }}
            />
          ) : (
            <Checkbox
              key={item[idTitle]}
              label={item.label}
              checked={values.includes(item.value)}
              onChange={(checked) => change(item.value)}
              disabled={disabled}
            />
          )}
          {children ? children(item.value, values.includes(item.value)) : null}
        </View>
      ))}
    </View>
  );
};
