import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import React from 'react';

import { TabsComponent } from '~/components/tabs/TabsComponent';

interface Props {
  tabProps: MaterialTopTabBarProps;
}
export const ProfileTabComponent: React.FC<Props> = ({ tabProps }) => {
  return <TabsComponent tabProps={tabProps} compact hasBottomMargin />;
};
