import React, { useRef } from 'react';
import { Animated } from 'react-native';

import { FadingDot } from '../FadingDot';

import { Row } from '~/theme/components/grid';

export const TypingDots: React.FC = () => {
  const animationFirstDot = useRef(new Animated.Value(0)).current;
  const animationSecondDot = useRef(new Animated.Value(0)).current;
  const animationThirdDot = useRef(new Animated.Value(0)).current;

  return (
    <Row>
      <FadingDot animatedValue={animationFirstDot} delay={0} />
      <FadingDot animatedValue={animationSecondDot} delay={100} style={{ marginHorizontal: 5 }} />
      <FadingDot animatedValue={animationThirdDot} delay={200} />
    </Row>
  );
};
