import { ClinicalTermLabelValue } from '~/api/hooks/clinicalTerms/ClinicalTermsSearchHook';
import { ClinicalTermModel } from '~/api/models/clinicalTerms/models/ClinicalTermModel';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { HealthProfileClinicalTermResponse } from '~/api/models/healthProfile/responses/HealthProfileClinicalTermResponse';
import { PatientHistoryDataItem } from '~/components/doctor/patients/healthProfile/models/PatientHistoryData';

interface Options {
  freeTextFallback?: boolean;
}

export function clinicalTermResponseToPatientHistoryDataItem(clinicalTermResponse: HealthProfileClinicalTermResponse) {
  return (
    clinicalTermResponse?.reduce<PatientHistoryDataItem<MediaModel>>((prev, item) => {
      prev[extractClinicalTermId(item)] = {
        media: item?.media || [],
        term: clinicalTermToLabelValue(item),
      };
      return prev;
    }, {}) || {}
  );
}

export function clinicalTermToLabelValue<T = number>(
  clinicalTerm?: ClinicalTermModel,
  options?: Options
): ClinicalTermLabelValue<T> | null {
  if (!clinicalTerm) return null;
  return {
    label: extractClinicalTermDescription(clinicalTerm),
    value: extractClinicalTermId(clinicalTerm, options) as T,
    fullySpecifiedName: clinicalTerm?.snomed_index?.fully_specified_name,
    snomedTerm: clinicalTerm?.snomed_index,
  };
}

export function extractClinicalTermDescription(clinicalTerm?: ClinicalTermModel) {
  return clinicalTerm?.snomed_index?.term || clinicalTerm?.clinical_term_description?.description || '';
}

export function extractClinicalTermId(clinicalTerm?: ClinicalTermModel, options?: Options) {
  return (
    clinicalTerm?.snomed_index?.id ||
    (options?.freeTextFallback
      ? clinicalTerm?.clinical_term_description?.description
      : clinicalTerm?.clinical_term_description?.id) ||
    undefined
  );
}
