import Feather from '@expo/vector-icons/Feather';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Menu } from 'react-native-paper';

import { mr10 } from '~/common/commonStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { H6NsRegularTheme } from '~/components/commonText';
import { useIncomeListingContext } from '~/providers/income/IncomeListingContext';
import { colors } from '~/utils/colors';

export const IncomeExportComponent: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const { generateIncomeExcelDownload, generateIncomePdfDownload } = useIncomeListingContext();
  return (
    <IconButton onPress={() => setVisible(true)}>
      <Menu
        visible={visible}
        onDismiss={() => setVisible(false)}
        anchor={<Feather name="download" size={20} color={colors.purple} style={{ marginHorizontal: 2 }} />}>
        <Menu.Item
          title={
            <View style={styles.item}>
              <MaterialCommunityIcons size={20} color={colors.purple} style={mr10} name="file-excel" />
              <H6NsRegularTheme>Download as Excel</H6NsRegularTheme>
            </View>
          }
          onPress={() => {
            generateIncomeExcelDownload();
            setVisible(false);
          }}
        />
        <Menu.Item
          title={
            <View style={styles.item}>
              <MaterialCommunityIcons size={20} color={colors.purple} style={mr10} name="file-pdf-box" />
              <H6NsRegularTheme>Download as PDF</H6NsRegularTheme>
            </View>
          }
          onPress={() => {
            generateIncomePdfDownload();
            setVisible(false);
          }}
        />
      </Menu>
    </IconButton>
  );
};

const styles = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'row',
  },
});
