import { get, put } from '../../axios';

import { HealthProfileClinicalTermsRequest } from '~/api/models/healthProfile/requests/HealthProfileClinicalTermRequest';
import { HealthProfileClinicalTermResponse } from '~/api/models/healthProfile/responses/HealthProfileClinicalTermResponse';

export const apiUpdateAllergies = (params: HealthProfileClinicalTermsRequest) => {
  return put(`/health-profile/${params.id}/allergies`, params.data);
};

export const apiGetAllergiesForProfile = (params: { id: number }) => {
  return get<HealthProfileClinicalTermResponse>(`/health-profile/${params.id}/allergies`);
};
