import React from 'react';
import { StyleSheet, View } from 'react-native';

import { flex1, mr10, textRight } from '../../../../common/commonStyles';
import { colors } from '../../../../utils/colors';
import { labels } from '../../../../utils/labels';
import { H1TtmSemiBoldBlack, H5TtmSemiBoldBlack } from '../../../commonText';

import { NotificationBell } from '~/components/dashboard/common/NotificationBell';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { useIncomeListingContext } from '~/providers/income/IncomeListingContext';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  children: React.ReactNode;
}
export const IncomeLayout: React.FC<Props> = ({ children }) => {
  const { isMobile } = useBreakpoints();
  const { accumulatedTotalIncome } = useIncomeListingContext();
  return (
    <PageLayout
      title={labels.income}
      hideBack
      headerRight={
        <View style={{ display: 'flex', alignItems: 'flex-end' }}>
          {isMobile ? (
            <View style={mr10}>
              <NotificationBell />
            </View>
          ) : (
            <View style={styles.headerRight}>
              <H5TtmSemiBoldBlack style={mr10}>Total:</H5TtmSemiBoldBlack>
              <H1TtmSemiBoldBlack style={[textRight, flex1]}>
                {accumulatedTotalIncome?.total_income.formatted_amount}
              </H1TtmSemiBoldBlack>
            </View>
          )}
        </View>
      }>
      {children}
    </PageLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
    display: 'flex',
    paddingHorizontal: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.lightPurple2,
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 6,
    minWidth: 160,
    minHeight: 46,
    margin: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
});
