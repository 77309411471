import { FlashList } from '@shopify/flash-list';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { ClinicDoctorAssignComponent } from './ClinicDoctorAssignComponent';

import { useClinicDoctorListing } from '~/api/hooks/clinics/ClinicDoctorListingHook';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import {
  AvailableDoctorModel,
  AvailableDoctorModelWithQueue,
} from '~/api/models/appointments/models/AvailableDoctorModel';
import { flex1, mh15, ph10, ph15, ph5, pv10, pv5 } from '~/common/commonStyles';
import { H3TtmSemiBoldBlack, SmallNsRegularBlack } from '~/components/commonText';
import { Splitter } from '~/components/misc/Splitter';
import { SearchBox } from '~/components/searchBox';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

interface Props {
  disabled?: boolean;
  assignedDoctor?: AccountModel;
  selectedDoctor?: Pick<AvailableDoctorModel, 'id'>;
  setSelectedDoctor: (doctor: AvailableDoctorModel) => void;
}

export const ClinicRequestDoctorListing: React.FC<Props> = ({
  disabled,
  selectedDoctor,
  assignedDoctor,
  setSelectedDoctor,
}) => {
  const { doctorsWithQueue, getDoctors, loading } = useClinicDoctorListing();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getDoctors();
  }, []);

  const listedDoctors = useMemo(() => {
    const doctorId = selectedDoctor?.id || assignedDoctor?.id;
    return doctorId || searchValue
      ? doctorsWithQueue.filter(
          (doctor) =>
            (!doctorId || doctor.id !== doctorId) &&
            (!searchValue || doctor.full_name.toLowerCase().includes(searchValue.toLowerCase()))
        )
      : doctorsWithQueue;
  }, [doctorsWithQueue, selectedDoctor, assignedDoctor, searchValue]);

  const selectedDoctorWithQueue = useMemo(() => {
    if (selectedDoctor) {
      return doctorsWithQueue.find((doctor) => doctor.id === selectedDoctor.id);
    }
    if (assignedDoctor) {
      return doctorsWithQueue.find((doctor) => doctor.id === assignedDoctor.id);
    }
  }, [selectedDoctor, assignedDoctor, doctorsWithQueue]);

  return (
    <View style={styles.container}>
      <H3TtmSemiBoldBlack style={[ph15, pv10]}>Assign a Doctor</H3TtmSemiBoldBlack>
      <SearchBox
        value={searchValue}
        onTextChange={setSearchValue}
        placeholder={labels.search}
        style={{ marginVertical: 0, marginHorizontal: 5 }}
      />
      <Splitter secondaryColor spacing={10} />
      {selectedDoctorWithQueue ? (
        <>
          <View style={ph5}>
            <ClinicDoctorAssignComponent
              doctor={selectedDoctorWithQueue}
              onPress={!disabled ? () => setSelectedDoctor(null) : null}
              isAssigned={assignedDoctor?.id === selectedDoctorWithQueue?.id}
              isSelected={selectedDoctor?.id === selectedDoctorWithQueue?.id}
              style={[ph10, pv5]}
            />
          </View>
          <Splitter secondaryColor spacing={5} />
        </>
      ) : null}
      <View style={[flex1]}>
        <FlashList<AvailableDoctorModelWithQueue>
          data={listedDoctors}
          keyExtractor={(item) => `${item.id}`}
          estimatedItemSize={100}
          ListEmptyComponent={
            !loading ? (
              <SmallNsRegularBlack style={{ paddingHorizontal: 5 }}>
                {!doctorsWithQueue?.length
                  ? 'There are no doctors assigned to your clinic'
                  : searchValue && !selectedDoctor
                  ? `Unable to find any doctors with search term "${searchValue}"`
                  : ''}
              </SmallNsRegularBlack>
            ) : null
          }
          ItemSeparatorComponent={() => <Splitter spacing={5} secondaryColor style={mh15} />}
          renderItem={({ item }) => (
            <ClinicDoctorAssignComponent
              doctor={item}
              onPress={!disabled ? setSelectedDoctor : undefined}
              style={[mh15, pv5, disabled ? { opacity: 0.5 } : null]}
            />
          )}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.lightPurple2,
    flex: 1,
  },
});
