import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useContext } from 'react';

import { BaseConsultationDataContext } from './BaseConsultationDataContext';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { BaseReferralModel, ReferralFormModel } from '~/api/models/consultations/models/ReferralModel';
import { UseFormWithRulesReturn } from '~/utils/hooks/FormWithRulesHook';

export interface IReferralDataContext extends BaseConsultationDataContext {
  form: UseFormWithRulesReturn<ReferralFormModel>;
  loading: boolean;
  referral: BaseReferralModel;
  consultation: ConsultationModel;
  referralMediaFiles: (DocumentPickerAsset | MediaModel)[];
  addFile: (document: DocumentPickerAsset) => Promise<void>;
  removeFile: (document: DocumentPickerAsset | MediaModel) => Promise<void>;
}

export const ReferralDataContext = React.createContext<IReferralDataContext>({} as IReferralDataContext);
export const useReferralDataContext = (): IReferralDataContext => useContext(ReferralDataContext);
