import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { ConsultationHomeVisitsScheduled } from './ConsultationHomeVisitsScheduled';
import { ConsultationOnDemandScheduled } from './ConsultationOnDemandScheduled';
import { ConsultationScheduleAppScheduled } from './ConsultationScheduleAppScheduled';
import { AppointmentDetails } from '../../appointments/AppointmentDetails';
import { AppointmentShowPageLayout } from '../../appointments/AppointmentShowPageLayout';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { mb20, mb10 } from '~/common/commonStyles';
import { H3TtmSemiBoldBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { Column, Row } from '~/theme/components/grid';

interface Props {
  consultation: ConsultationModel;
}

export const ConsultationWaitingRoom: React.FC<Props> = ({ consultation }) => {
  const isHomeVisit = useMemo(() => consultation.type === ConsultationTypeEnum.HOME_VISIT, [consultation]);
  const isScheduled = useMemo(() => consultation.type === ConsultationTypeEnum.SCHEDULED_APPOINTMENT, [consultation]);
  const isOnDemand = useMemo(() => consultation.type === ConsultationTypeEnum.ON_DEMAND, [consultation]);

  return (
    <AppointmentShowPageLayout title={isOnDemand ? 'Waiting room' : 'Appointment Details'}>
      <>
        {isOnDemand ? (
          <Row style={mb20}>
            <Column>
              <H6NsSemiBoldBlack>
                Please note that you may need to wait in our virtual queue until the doctor is available to see you
              </H6NsSemiBoldBlack>
            </Column>
          </Row>
        ) : null}
        <Row flex>
          <Column span={{ xs: 12, lg: 7 }} style={mb10}>
            <View style={[styles.headers]}>
              <H3TtmSemiBoldBlack>Consultation details</H3TtmSemiBoldBlack>
            </View>
            <AppointmentDetails consultation={consultation} />
          </Column>
          {consultation?.state === ConsultationStateEnum.Scheduled ? <></> : null}
          {isHomeVisit ? (
            <ConsultationHomeVisitsScheduled consultation={consultation} />
          ) : isOnDemand ? (
            <ConsultationOnDemandScheduled />
          ) : isScheduled ? (
            <ConsultationScheduleAppScheduled consultation={consultation} />
          ) : null}
        </Row>
      </>
    </AppointmentShowPageLayout>
  );
};
const styles = StyleSheet.create({
  headers: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
});
