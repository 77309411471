import { Slider } from '@sharcoux/slider';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { HomeVisitMapView } from './HomeVisitMapView';
import { H3TtmSemiBoldBlack } from '../../commonText';
import FloatingInput from '../../floatingInputBox';

import { LatLng } from '~/integrations/mapView/interfaces/LatLng';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

interface Props {
  latLng?: LatLng;
  radius?: number;
  initialFormattedAddress?: string;
  disableInitialLocation?: boolean;
  setLatLng: (latLng: LatLng) => void;
  setRadius: (radius: number) => void;
}

export const HomeVisitAvailabilitiesMap: React.FC<Props> = ({
  latLng,
  radius,
  setLatLng,
  setRadius,
  initialFormattedAddress,
  disableInitialLocation,
}) => {
  const [formattedAddress, setFormattedAddress] = useState<string>();

  useEffect(() => {
    if (initialFormattedAddress) setFormattedAddress(initialFormattedAddress);
  }, [initialFormattedAddress]);
  return (
    <View>
      <H3TtmSemiBoldBlack>{labels.chooseLocation}</H3TtmSemiBoldBlack>
      <HomeVisitMapView
        radius={radius}
        latLng={latLng}
        setFormattedAddress={setFormattedAddress}
        setLatLng={setLatLng}
        disableInitialLocation={disableInitialLocation}
      />
      <FloatingInput editable={false} placeholder="Address of pinned location shown here" value={formattedAddress} />

      <View style={{ marginTop: 20 }}>
        <H3TtmSemiBoldBlack>{labels.maxDistanceFromLocation}</H3TtmSemiBoldBlack>
        <Slider
          minimumValue={1}
          maximumValue={15}
          minimumTrackTintColor={colors.purple}
          maximumTrackTintColor={colors.lightPurple}
          thumbTintColor={colors.purple}
          trackStyle={{ zIndex: -1 }}
          step={1}
          value={radius}
          onValueChange={setRadius}
        />
        <View style={{ display: 'flex', alignItems: 'center' }}>
          <H3TtmSemiBoldBlack>{radius} km</H3TtmSemiBoldBlack>
        </View>
      </View>
    </View>
  );
};
