import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import semverDiff from 'semver-diff';

import { apiGetAppVersion } from '../services/authorisation';

import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import { infoModal } from '~/providers/modal/ModalManagementContext';
import { RootState } from '~/redux/reducers';
import { LATEST_APP_VERSIONS, MINOR_UPDATE_MODAL_SHOWN } from '~/redux/reducers/appDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { getAppType, getCurrentAppVersion, getCurrentPlatform, isNative } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';

export function useCheckAppVersion() {
  const updateModalShown = useSelector((state: RootState) => state.appDetailsReducer.minorUpdateModalShown);
  const latestAppVersions = useSelector((state: RootState) => state.appDetailsReducer.latestVersions);

  const appType = getAppType();
  const currentAppVersion = getCurrentAppVersion();
  const currentPlatform = getCurrentPlatform();
  const dispatch = useAppDispatch();

  const setUpdateModalShown = () => {
    dispatch(MINOR_UPDATE_MODAL_SHOWN(true));
  };

  const checkAppVersion = () => {
    return apiGetAppVersion()
      .then((response) => {
        dispatch(LATEST_APP_VERSIONS(response.data));
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (isNative()) checkAppVersion();
  }, []);

  useEffect(() => {
    if (latestAppVersions && currentPlatform !== 'web') {
      const latestAppVersion = latestAppVersions
        .filter((latestApp) => latestApp.platform === currentPlatform && latestApp.type === appType)
        .map((retrievedAppVersion) => retrievedAppVersion.latest_version);

      if (latestAppVersion.length) {
        const checkVersionsDiff = semverDiff(currentAppVersion, latestAppVersion[0]);
        switch (checkVersionsDiff) {
          case 'major':
            infoModal({
              message: [
                'A new version is available for download. To continue using the app, please update it by visiting the store.',
              ],
              type: ModalAlertTypeEnum.ERROR,
              title: 'New Version Available',
              okButtonTitle: '',
              blockButtons: true,
              hideOnBackground: false,
              centerModal: true,
            });
            break;
          case 'minor':
          case 'patch':
            if (!updateModalShown) {
              infoModal({
                message: [
                  'It is recommended that you update your app to the latest version. Please visit the store to update it.',
                ],
                type: ModalAlertTypeEnum.WARNING,
                title: 'Update Recommended',
                okButtonTitle: labels.ok,
                blockButtons: false,
                okFunction: setUpdateModalShown,
                centerModal: true,
              });
            }
            break;
          default:
            break;
        }
      }
    }
  }, [latestAppVersions]);
}
