import { Image, ImageErrorEventData } from 'expo-image';
import React, { useMemo, useState } from 'react';
import { StyleProp, View, StyleSheet, Text, ViewStyle } from 'react-native';

import { AccountModel } from '../../api/models/accounts/models/AccountModel';
import { colors } from '../../utils/colors';

import { GenderTypeEnum } from '~/api/models/common/constants/GenderTypeEnum';
import { getInitialsFromAccount } from '~/utils/personalDetailsUtils';

interface Props {
  account?: AccountModel;
  initials?: string;
  src?: string;
  size?: number;
  style?: StyleProp<ViewStyle>;
  fallback?: React.ReactNode;
  shadow?: boolean;
}

const backgroundByGender = (gender: GenderTypeEnum) => {
  switch (gender) {
    case GenderTypeEnum.MALE:
      return colors.lightPurple;
    case GenderTypeEnum.FEMALE:
      return colors.lightPurple3;
    case GenderTypeEnum.OTHER:
      return colors.lightPurple2;
    default:
      return colors.transparent;
  }
};
export const ProfileImageComponent: React.FC<Props> = ({
  account,
  initials,
  src,
  size = 50,
  style,
  fallback,
  shadow,
}) => {
  const [imageError, setImageError] = useState<ImageErrorEventData>();

  const imageSrc = useMemo(() => {
    return account?.profile_image?.conversions?.avatar || account?.profile_image?.url || src;
  }, [account?.profile_image?.conversions?.avatar, account?.profile_image?.url, src]);

  const nameInitials = useMemo(() => {
    return initials || getInitialsFromAccount(account);
  }, [initials, account]);
  return (
    <View
      style={[
        styles.container,
        { width: size, height: size },
        { backgroundColor: backgroundByGender(account?.gender) },
        shadow ? styles.shadow : null,
        style,
      ]}>
      {imageSrc && !imageError ? (
        <Image
          style={styles.profileImage}
          source={{ uri: imageSrc }}
          contentFit="cover"
          onError={(e) => setImageError(e)}
        />
      ) : nameInitials ? (
        fallback || <Text style={styles.profileInitials}>{nameInitials}</Text>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  profileImage: {
    height: '100%',
    width: '100%',
  },
  profileInitials: {
    fontWeight: 'bold',
    fontSize: 20,
    letterSpacing: 2,
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});
