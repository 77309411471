import React from 'react';

import { SelectableCard } from '../cards/SelectableCard';
import { EmailIcon, LockIcon, PhoneOrSMSIcon } from '../svgImages';

import { OtpChannelEnum } from '~/api/models/otp/constants/OtpChannelEnum';
import { mb15 } from '~/common/commonStyles';

interface Props {
  selectedOption: OtpChannelEnum;
  value: OtpChannelEnum;
  setTwoStepVerification: CallableFunction;
  title: string;
}

export const TwoStepVerificationButton: React.FC<Props> = ({
  selectedOption,
  value,
  setTwoStepVerification,
  title,
}) => {
  return (
    <SelectableCard
      selected={selectedOption === value}
      onPress={() => setTwoStepVerification(value)}
      icon={(color: string) =>
        value === OtpChannelEnum.EMAIL ? (
          <EmailIcon height={20} width={20} color={color} />
        ) : value === OtpChannelEnum.SMS ? (
          <PhoneOrSMSIcon height={20} width={20} color={color} />
        ) : (
          <LockIcon height={20} width={20} color={color} />
        )
      }
      label={title}
      style={mb15}
      centerVertical
    />
  );
};
