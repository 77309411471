import React, { useEffect, useState } from 'react';

import { useSpecialisationArea } from '~/api/hooks/SpecialisationListing';
import { IdNameModel } from '~/api/models/common/models/IdNameModel';
import { SearchableList } from '~/components/inputs/searchable/SearchableList';

interface Props {
  values: IdNameModel[];
  setValues: CallableFunction;
  error?: boolean;
  errorMessage?: string;
}

export const SpecialisationSearchableList: React.FC<Props> = ({ values, setValues, error, errorMessage }) => {
  const [searchValue, setSearchValue] = useState('');
  const { listOfAreaOfSpecialisation, searchSpecialisation, loading } = useSpecialisationArea();

  useEffect(() => {
    if (searchValue) searchSpecialisation({ name: searchValue });
  }, [searchValue]);

  const selectValue = (value: number, item: any) => {
    if (!values?.find((selectedValue) => selectedValue.id === value)) {
      if (!values) {
        setValues([item]);
      } else {
        setValues([...values, item]);
      }
    }
  };

  const removeValue = (value: number) => {
    const valueIndex = values?.findIndex((selectedValue) => selectedValue.id === value);
    if (valueIndex > -1) {
      const newValues = [...values];
      newValues.splice(valueIndex, 1);
      setValues(newValues);
    }
  };

  return (
    <SearchableList
      list={listOfAreaOfSpecialisation}
      labelTitle="name"
      valueTitle="id"
      inputOptions={{ placeholder: 'Search for Specialisation', error, errorMessage }}
      onSearchChange={setSearchValue}
      onSelect={selectValue}
      onRemove={removeValue}
      values={values}
      loading={loading}
      error={error}
      errorMessage={errorMessage}
      showMandatory
    />
  );
};
