import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View, StyleSheet } from 'react-native';

import { useProfileCompletionContext } from './provider/ProfileCompletionContext';
import { DashboardCard } from '../../common/DashboardCard';

import { flex1 } from '~/common/commonStyles';
import { OutlineButton } from '~/components/commonButton';
import { H4TtwRegularSemiBold, H6NsRegularSecondaryBlack } from '~/components/commonText';
import { PatientGroup } from '~/components/svgImages';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const FamilyMembers: React.FC = (): JSX.Element => {
  const { navigate } = useNavigation<NavType>();
  const { upgradedPlan } = useProfileCompletionContext();

  const upgradePress = () => {
    navigate(screenName.Dependants);
  };

  return (
    <DashboardCard>
      <View style={styles.container}>
        <View style={styles.header}>
          <PatientGroup width={25} height={25} color={colors.darkPurple} />
          <H4TtwRegularSemiBold style={styles.title}>Family members</H4TtwRegularSemiBold>
        </View>
        <View style={[flex1, { paddingVertical: 10 }]}>
          {upgradedPlan ? (
            <H6NsRegularSecondaryBlack style={flex1}>{labels.addDependants}</H6NsRegularSecondaryBlack>
          ) : (
            <H6NsRegularSecondaryBlack style={flex1}>{labels.upgradePlanDependants}</H6NsRegularSecondaryBlack>
          )}
        </View>
        <View style={flex1}>
          {upgradedPlan ? (
            <OutlineButton funCallback={upgradePress} label="Add" />
          ) : (
            <OutlineButton funCallback={upgradePress} label="Upgrade" />
          )}
        </View>
      </View>
    </DashboardCard>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    flex: 1,
    paddingBottom: 15,
  },
  header: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },
  title: {
    marginLeft: 10,
    color: colors.darkPurple,
  },
});
