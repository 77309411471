import React from 'react';

import { TitleLayout } from '../../layouts/TitleLayout';
import { labels } from '../../utils/labels';

import { MobileMenuPageComponent } from '~/components/navigation/MobileMenuPageComponent';
import { UserEditImageComponent } from '~/components/profile/UserEditImageComponent';
import { useProfileMenu } from '~/constants/profileMenu';
import { colors } from '~/utils/colors';

export const ProfileMobilePage: React.FC = () => {
  const profileMenu = useProfileMenu();
  return (
    <TitleLayout title={labels.profile} style={{ backgroundColor: colors.lightPurple }}>
      <UserEditImageComponent />
      <MobileMenuPageComponent menu={profileMenu.filter((item) => !item.hidden)} />
    </TitleLayout>
  );
};
