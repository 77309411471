import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/reducers';
import { UPDATE_TOBACCO_USES_LIST } from '../../redux/reducers/consultationDetailsReducer';
import { useAppDispatch } from '../../redux/store';
import { apiGetAllTobaccoUses } from '../services/healthProfile/tobaccoUses';

export function useTobaccoUsesListing() {
  const tobaccoUsesData = useSelector((state: RootState) => state.consultationDetailsReducer.tobaccoUsesList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!tobaccoUsesData?.length) {
      apiGetAllTobaccoUses().then((res) => {
        dispatch(UPDATE_TOBACCO_USES_LIST(res.data));
      });
    }
  }, [tobaccoUsesData]);

  return { tobaccoUsesData };
}
