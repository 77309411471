import { useNavigation } from '@react-navigation/native';

import { useClinicRequestsList } from '../../patientDataHandling/ClinicRequestsListHook';
import { useApprovalRequestsList } from '../../patientDataHandling/useApprovalRequestsList';

import { ConsultationEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { NavType } from '~/navigation/types';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { useConsultationQueue } from '~/redux/hooks/useConsultationsQueue';
import { isClinicVersion, isDoctorVersion, whenAppType } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

export const useHandleConsultationRequestSubmitted = () => {
  const { addToQueue } = useConsultationQueue();
  const { getRequests: getDoctorRequests } = useApprovalRequestsList();
  const { getRequests: getClinicRequests } = useClinicRequestsList();
  const { showSnackbar } = useSnackbarManager();
  const { navigate } = useNavigation<NavType>();

  const execute = async (consultationId: number) => {
    if (!consultationId) return;

    whenAppType({
      whenDoctor: () => {
        addToQueue(consultationId);
        getDoctorRequests();
      },
      whenClinic: () => {
        getClinicRequests().then(() =>
          showSnackbar(`You have new consultation request.`, {
            keyRef: `${ConsultationEventsEnum.CONSULTATION_HOME_VISIT_APPOINTMENT_STARTING}.${consultationId}`,
            actionTitle: 'Click here to assign doctor.',
            onPress: () => {
              navigate(screenName.ClinicConsultationRequest, { id: consultationId });
            },
          })
        );
      },
    })();

    AppEventHandler.emit(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, [consultationId]);
  };
  const validate = () => {
    return isDoctorVersion() || isClinicVersion();
  };

  const handleNotification = async (consultationId: number) => {
    if (!validate()) return;

    await execute(consultationId);
  };

  return { handleNotification };
};
