import { Link } from '@react-navigation/native';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import React from 'react';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';

import { H5TtmSemiBoldTheme } from '../commonText';

interface Props {
  title: string;
  to?: To<ReactNavigation.RootParamList>;
  style?: StyleProp<ViewStyle | TextStyle>;
  disabled?: boolean;
  onPress?: () => void;
}

export const LinkButton: React.FC<Props> = ({ title, to, style, disabled, onPress }) => {
  if (to) {
    return (
      <Link to={to} style={[styles.link, style]} disabled={disabled}>
        <H5TtmSemiBoldTheme>{title}</H5TtmSemiBoldTheme>
      </Link>
    );
  }

  return (
    <TouchableOpacity onPress={onPress} style={[styles.link, style]}>
      <H5TtmSemiBoldTheme>{title}</H5TtmSemiBoldTheme>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  link: {
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
});
