import { deleteReq, get, post } from '~/api/axios';
import { FavouriteDoctorsIndexRequest } from '~/api/models/favouriteDoctors/requests/FavouriteDoctorsIndexRequest';
import { FavouriteDoctorsModifyRequest } from '~/api/models/favouriteDoctors/requests/FavouriteDoctorsModifyRequest';
import {
  FavouriteDoctorsListedIndexResponse,
  FavouriteDoctorsPaginatedIndexResponse,
} from '~/api/models/favouriteDoctors/responses/FavouriteDoctorsIndexResponse';

export function apiGetFavouriteDoctors<
  T = FavouriteDoctorsPaginatedIndexResponse | FavouriteDoctorsListedIndexResponse
>(req?: FavouriteDoctorsIndexRequest) {
  return get<T>('/patient/favourite-doctors', req);
}

export const apiAddFavouriteDoctors = (params: FavouriteDoctorsModifyRequest) => {
  return post('/patient/favourite-doctors', params);
};

export const apiRemoveFavouriteDoctor = (params: FavouriteDoctorsModifyRequest) => {
  return deleteReq('/patient/favourite-doctors', params);
};
