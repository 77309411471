import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { PatientsLayout } from '../../../components/doctor/patients/PatientsLayout';

import { GetDiagnosisResponse } from '~/api/models/diagnoses/responses/GetDiagnosisResponse';
import { apiGetDiagnosis } from '~/api/services/diagnoses';
import { flex1, mb10, mb20 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { H2TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { PatientConsultationsList } from '~/components/doctor/patients/PatientConsultationsList';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { isPatientVersion } from '~/utils/buildConfig';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

interface Props {
  diagnosisId: number;
}

export const PatientDiagnosisList: React.FC<Props> = ({ diagnosisId }) => {
  const { isMobile } = useBreakpoints();
  const [diagnosisResponse, setDiagnosisResponse] = useState<GetDiagnosisResponse | null>(null);

  useEffect(() => {
    getDiagnosis();
  }, []);
  const getDiagnosis = () => {
    apiGetDiagnosis({ id: diagnosisId })
      .then((res) => {
        setDiagnosisResponse(res.data);
      })
      .catch(ErrorAlert);
  };

  const desktopContent = !isMobile ? (
    <Grid grid={{ noOuterGutter: true }}>
      <Container alignSelf="flex-start" style={flex1} fluid>
        <Row style={mb10}>
          <Column width={600}>
            <H2TtmSemiBoldBlack style={mb10}>
              {extractClinicalTermDescription(diagnosisResponse?.diagnosis) || ' '}
            </H2TtmSemiBoldBlack>
            <H6NsRegularBlack>Select from the below list to see consultation details.</H6NsRegularBlack>
          </Column>
        </Row>
        <Row style={flex1}>
          <Column>
            <View style={flex1}>
              <PatientConsultationsList patientId={diagnosisResponse?.account_data.id} diagnosisId={diagnosisId} />
            </View>
          </Column>
        </Row>
      </Container>
    </Grid>
  ) : null;

  const mobileContent = isMobile ? (
    <PatientConsultationsList
      patientId={diagnosisResponse?.account_data.id}
      diagnosisId={diagnosisId}
      listHeaders={
        <View style={[mb20, { paddingHorizontal: MOBILE_HORIZONTAL_SPACE }]}>
          <H2TtmSemiBoldBlack style={mb10}>
            {extractClinicalTermDescription(diagnosisResponse?.diagnosis) || ' '}
          </H2TtmSemiBoldBlack>
          <H6NsRegularBlack>Select from the below list to see consultation details.</H6NsRegularBlack>
        </View>
      }
    />
  ) : null;

  return (
    <PatientsLayout
      hideHeaderRight
      children={desktopContent}
      scrollContentNode={mobileContent}
      flexContent
      back={{
        to: isPatientVersion()
          ? { screen: screenName.Diagnoses }
          : { screen: screenName.PatientDetails, params: { id: diagnosisResponse?.account_data.id } },
      }}
    />
  );
};
