import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import {
  DurationIntervalTypeEnum,
  DurationIntervalTypeOptions,
} from '~/api/models/consultations/constants/DurationIntervalTypeEnum';
import {
  PrescriptionTypeEnum,
  PrescriptionTypeMapping,
} from '~/api/models/consultations/constants/PrescriptionTypeEnum';
import { mt10 } from '~/common/commonStyles';
import FloatingInput from '~/components/inputs/FloatingInput';
import { RadioButtons } from '~/components/inputs/RadioButtons';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { usePrescriptionDataContext } from '~/providers/consultation/PrescriptionDataContext';
import { maximumDurationValidationYear, validationSchema } from '~/services/validationConfig';
import { Row, Column } from '~/theme/components/grid';

interface Props {
  index: number;
}

export const PrescriptionsType: React.FC<Props> = ({ index }) => {
  const {
    form: { control, rulesArray, watch, setValue, trigger },
  } = usePrescriptionDataContext();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === `prescriptions.${index}.type` && value.prescriptions[index].type === PrescriptionTypeEnum.ONGOING) {
        setValue(`prescriptions.${index}.duration_interval_type`, DurationIntervalTypeEnum.MONTHS);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Row style={mt10}>
        <Column>
          <Controller
            name={`prescriptions.${index}.type`}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
              <RadioButtons
                horizontal
                items={PrescriptionTypeMapping}
                selected={value}
                onValueChange={onChange}
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
                showMandatory
              />
            )}
            rules={rulesArray?.prescriptions.type}
          />
        </Column>
      </Row>
      {watch(`prescriptions.${index}.type`) ? (
        <Row>
          <Column span={{ xs: 12, md: 6 }}>
            <Controller
              name={`prescriptions.${index}.duration_interval_amount`}
              control={control}
              render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                <FloatingInput
                  showMandatory
                  maxLength={validationSchema.string.maxLength}
                  error={!!error}
                  errorMessage={error?.message}
                  value={`${value ?? ''}`}
                  onChangeValue={(value) => onChange(Number(value))}
                  onBlur={onBlur}
                  keyboardType="numeric"
                  label="Duration"
                />
              )}
              rules={{
                ...rulesArray?.prescriptions.duration_interval_amount,
                validate: {
                  ...rulesArray?.prescriptions.duration_interval_amount?.validate,
                  maximumDurationValidationYear: maximumDurationValidationYear(
                    `prescriptions.${index}.duration_interval_type`,
                    'Prescription duration'
                  ),
                },
              }}
            />
          </Column>
          <Column span={{ xs: 12, md: 6 }}>
            <Controller
              name={`prescriptions.${index}.duration_interval_type`}
              control={control}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Dropdown
                  value={value}
                  error={!!error}
                  errorMessage={error?.message}
                  list={DurationIntervalTypeOptions}
                  label="Duration type"
                  setValue={(value) => {
                    onChange(value);
                    trigger(`prescriptions.${index}.duration_interval_amount`);
                  }}
                  disabled={watch(`prescriptions.${index}.type`) === PrescriptionTypeEnum.ONGOING}
                  showMandatory
                />
              )}
              rules={rulesArray?.prescriptions.duration_interval_type}
            />
          </Column>
        </Row>
      ) : null}
    </>
  );
};
