import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { AvailableDoctorModelWithQueue } from '~/api/models/appointments/models/AvailableDoctorModel';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ConfirmationAlert } from '~/common/commonMethods';
import { flex1, mb15 } from '~/common/commonStyles';
import { Button, OutlineButton } from '~/components/commonButton';
import { H3TtmSemiBoldBlack, H5TtmRegularTheme, H6NsRegularBlack } from '~/components/commonText';
import { Actions } from '~/components/doctor/requests/RequestActions';
import { BackNavigation } from '~/components/navigation/BackNavigation';
import { RatingViewComponent } from '~/components/patient/doctorListing/components/RatingViewComponent';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { screenName } from '~/utils/screenName';

interface Props {
  consultation: ConsultationModel;
  potentialDoctor?: AvailableDoctorModelWithQueue | AccountModel;
  onCancelAssigning?: () => Promise<void>;
  onCancelAppointment?: () => Promise<void>;
  onConfirmDoctor?: () => Promise<void>;
  onDeclineRequest?: () => Promise<void>;
}

export const ClinicRequestsAction: React.FC<Props> = ({
  consultation,
  potentialDoctor,
  onCancelAssigning,
  onCancelAppointment,
  onConfirmDoctor,
  onDeclineRequest,
}) => {
  const [waiting, setWaiting] = useState(false);
  const [assigningDoctor, setAssigningDoctor] = useState(false);

  useEffect(() => {
    if (potentialDoctor && !assigningDoctor) setAssigningDoctor(true);
  }, [potentialDoctor]);

  const currentDoctor = useMemo(() => {
    return potentialDoctor || consultation?.doctor;
  }, [consultation?.doctor, potentialDoctor]);

  const currentDoctorAlreadyAssigned = useMemo(
    () => consultation?.doctor && currentDoctor?.id === consultation?.doctor?.id,
    [consultation?.doctor, currentDoctor]
  );

  const canCancel = useMemo(
    () =>
      consultation?.doctor &&
      (consultation.state === ConsultationStateEnum.Scheduled ||
        consultation.state === ConsultationStateEnum.PendingPayment),
    [consultation?.doctor, consultation?.state]
  );

  const tryDecline = () => {
    const declineRequest = () => {
      if (onDeclineRequest) {
        setWaiting(true);
        onDeclineRequest().finally(() => setWaiting(false));
      }
    };
    ConfirmationAlert(
      [`Are you sure you want to decline a consultation with ${getAccountName(consultation.patient)}?`],
      { title: 'Decline consultation request', okTitle: 'Decline', okFunction: declineRequest }
    );
  };

  const tryCancelAppointment = () => {
    const cancelAppointment = () => {
      if (onCancelAppointment) {
        setWaiting(true);
        onCancelAppointment().finally(() => setWaiting(false));
      }
    };
    ConfirmationAlert(
      [`Are you sure you want to cancel the consultation with ${getAccountName(consultation.patient)}?`],
      { title: 'Cancel consultation', okFunction: cancelAppointment }
    );
  };

  const cancelAssigning = () => {
    setAssigningDoctor(false);
    if (onCancelAssigning) {
      setWaiting(true);
      onCancelAssigning().finally(() => setWaiting(false));
    }
  };

  const confirmAssigning = () => {
    if (onConfirmDoctor) {
      setWaiting(true);
      onConfirmDoctor().finally(() => setWaiting(false));
    }
  };

  if (!assigningDoctor && !currentDoctor)
    return (
      <View
        style={[
          { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
          styles.minHeight,
        ]}>
        <View>
          <Actions
            onAccept={() => setAssigningDoctor(true)}
            onDecline={tryDecline}
            approveTitle="Assign"
            buttonStyle={{ minWidth: 100 }}
          />
        </View>
        <View>
          <BackNavigation backLink={{ screen: screenName.Dashboard }} title="EXIT" hideArrow />
        </View>
      </View>
    );
  return (
    <Grid grid={{ noOuterGutter: true, gutters: 10 }}>
      <Container>
        <Row style={mb15}>
          <Column>
            <H3TtmSemiBoldBlack>Doctor Assignment</H3TtmSemiBoldBlack>
            <H6NsRegularBlack>
              {currentDoctorAlreadyAssigned
                ? 'Below doctor is scheduled to handle this consultation.'
                : potentialDoctor
                ? 'Confirm selected doctor to assign to this consultation'
                : 'Select a doctor to assign to this consultation'}
            </H6NsRegularBlack>
          </Column>
        </Row>
        <Row justifyContent="center" alignItems="center">
          <Column span={{ xs: 12, md: 'flex' }}>
            <View style={[flex1, styles.doctorDetails, styles.minHeight]}>
              {currentDoctor ? (
                <ProfileDetailsComponent
                  account={currentDoctor}
                  style={{ paddingRight: 20, minWidth: 350 }}
                  extraDetails={
                    'rating' in currentDoctor ? <RatingViewComponent rating={currentDoctor.rating} /> : null
                  }
                />
              ) : (
                <View style={styles.assignNoDoctor}>
                  <H5TtmRegularTheme>None</H5TtmRegularTheme>
                </View>
              )}
            </View>
          </Column>
          {currentDoctorAlreadyAssigned ? null : (
            <Column span={{ xs: 3, md: 'auto' }}>
              <Button
                label={labels.confirm}
                style={{ height: 80, minWidth: 90, justifyContent: 'center' }}
                funCallback={confirmAssigning}
                disabled={!potentialDoctor || waiting}
              />
            </Column>
          )}
          <Column span={{ xs: 3, md: 'auto' }}>
            {currentDoctorAlreadyAssigned ? (
              canCancel ? (
                <OutlineButton
                  danger
                  label={'Cancel\nAppointment'}
                  funCallback={tryCancelAppointment}
                  disabled={waiting}
                  style={{ height: 80, minWidth: 90, justifyContent: 'center' }}
                />
              ) : null
            ) : (
              <OutlineButton
                label={labels.back.toUpperCase()}
                funCallback={cancelAssigning}
                disabled={waiting}
                style={{ height: 80, minWidth: 90, justifyContent: 'center' }}
              />
            )}
          </Column>
        </Row>
      </Container>
    </Grid>
  );
};

const styles = StyleSheet.create({
  minHeight: {
    minHeight: 80,
  },
  assignContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  assignNoDoctor: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  doctorDetails: {
    borderRadius: 8,
    backgroundColor: colors.lightPurple,
    justifyContent: 'center',
    padding: 10,
  },
});
