import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { DoctorsListCard } from './DoctorsListCard';
import { DashboardCard } from '../../common/DashboardCard';

import { NavType } from '~/navigation/types';
import { AppointmentBookingProvider } from '~/providers/appointment/AppointmentBookingProvider';
import { DoctorListingProvider } from '~/providers/appointment/DoctorListingProvider';
import { SET_BOOKING_PHYSICIAN } from '~/redux/reducers/patient/appointmentReducer';
import { useAppDispatch } from '~/redux/store';
import { screenName } from '~/utils/screenName';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { ModalName } from '~/components/modals/constants/ModalNames';

export const DoctorsDashboardListing: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { navigate } = useNavigation<NavType>();
  const { hasFeatureAccess } = useFeatureAccess();
  const { openModal } = useModalManager();
  return (
    <AppointmentBookingProvider>
      <DoctorListingProvider
        isListing
        onSelectDoctorOrClinic={(doctorOrClinic, consultationType, channel) => {
          if (
            consultationType === ConsultationTypeEnum.HOME_VISIT &&
            !hasFeatureAccess(FeatureAccessEnum.HOME_VISITS)
          ) {
            openModal(ModalName.PaywallModal, {
              title: 'Upgrade plan to book Home Visits',
            });
          } else {
            dispatch(
              SET_BOOKING_PHYSICIAN({
                doctorOrClinic,
                channel,
                consultationType,
              })
            );
            navigate(screenName.AppointmentsBooking);
          }
        }}>
        <View style={{ flex: 1 }}>
          <DashboardCard title="Doctors" white viewAllLink={screenName.Doctors}>
            <DoctorsListCard />
          </DashboardCard>
        </View>
      </DoctorListingProvider>
    </AppointmentBookingProvider>
  );
};
