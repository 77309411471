import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalOverlayProperties, LayoutProperties } from './../../interfaces/overlayInterface';
import { ModalAlertTypeEnum } from '../../components/modals/ModalEnums';
import { RootState } from '.';

type IState = ModalOverlayProperties & LayoutProperties;
const initialState: IState = {
  hideMobileMenu: false,
  fullPageLoading: false,
  popupFlag: false,
  popupMessage: [],
  popupType: ModalAlertTypeEnum.NONE,
  popupTitle: '',
  popupButtonText: '',
  popupBlockButtons: false,
  popupOptionalFields: undefined,
};

const overlaySlice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    SET_FULL_PAGE_LOADING: (state, action: PayloadAction<boolean>) => ({
      ...state,
      fullPageLoading: action.payload,
    }),
    HIDE_SUCCESS_ERROR_POPUP: (state) => {
      return {
        ...state,
        popupFlag: false,
      };
    },
    SET_HIDE_MOBILE_MENU: (state, action: PayloadAction<boolean>) => ({ ...state, hideMobileMenu: action.payload }),
    SUCCESS_ERROR_POPUP_DETAILS: (state, action: PayloadAction<ModalOverlayProperties>) => {
      return {
        ...state,
        popupFlag: action.payload.popupFlag,
        popupMessage: action.payload.popupMessage,
        popupType: action.payload.popupType,
        popupTitle: action.payload.popupTitle,
        popupButtonText: action.payload.popupButtonText,
        popupBlockButtons: action.payload.popupBlockButtons,
        popupOptionalFields: action.payload.popupOptionalFields,
      };
    },
  },
});

export const { SUCCESS_ERROR_POPUP_DETAILS, HIDE_SUCCESS_ERROR_POPUP, SET_HIDE_MOBILE_MENU, SET_FULL_PAGE_LOADING } =
  overlaySlice.actions;
export default overlaySlice.reducer;

export const fullPageLoadingSelector = (state: RootState) => state.overLayReducer.fullPageLoading;
