import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '.';

import { ClinicTokenDetailsResponse } from '~/api/models/clinics/responses/ClinicTokenDetailsResponse';
import { PharmacyLinkingInfoModel } from '~/api/models/pharmacy/models/PharmacyLinkingInfoModel';
import { removeAsyncItem, setObjectAsyncItem, storageKeys } from '~/common/asyncStorage';

interface ClinicLinking {
  token: string;
  details: ClinicTokenDetailsResponse;
}

interface IState {
  pharmacyLinking?: PharmacyLinkingInfoModel;
  clinicLinking?: ClinicLinking;
}

const initialState: IState = {
  pharmacyLinking: undefined,
};

const tokenInviteSlice = createSlice({
  name: 'tokenInvites',
  initialState,
  reducers: {
    SET_PHARMACY_LINKING_DETAILS: (state, action: PayloadAction<PharmacyLinkingInfoModel>) => {
      setObjectAsyncItem(storageKeys.pharmacyLink, action.payload);
      return {
        ...state,
        pharmacyLinking: action.payload,
      };
    },
    CLEAR_PHARMACY_LINKING_DETAILS: (state) => {
      removeAsyncItem(storageKeys.pharmacyLink);
      return {
        ...state,
        pharmacyLinking: undefined,
      };
    },

    SET_CLINIC_LINKING_DETAILS: (state, action: PayloadAction<ClinicLinking>) => ({
      ...state,
      clinicLinking: action.payload,
    }),
    CLEAR_CLINIC_LINKING_DETAILS: (state) => ({
      ...state,
      clinicLinking: undefined,
    }),
  },
});

export const {
  SET_PHARMACY_LINKING_DETAILS,
  CLEAR_PHARMACY_LINKING_DETAILS,
  SET_CLINIC_LINKING_DETAILS,
  CLEAR_CLINIC_LINKING_DETAILS,
} = tokenInviteSlice.actions;

export default tokenInviteSlice.reducer;

export const pharmacyLinkingSelector = (state: RootState) => state.tokenInviteReducer.pharmacyLinking;
export const clinicLinkingSelector = (state: RootState) => state.tokenInviteReducer.clinicLinking;
