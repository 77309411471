import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { KeyboardAvoidingView, Platform, StyleSheet, View } from 'react-native';
import { Surface } from 'react-native-paper';

import { mt20, pb15, ph10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { Chat } from '~/components/common/consultations/chat/Chat';
import { ChatMessageInput } from '~/components/common/consultations/chat/ChatMessageInput';
import { H4TtmSemiBoldTheme } from '~/components/commonText';
import { BackArrow } from '~/components/svgImages';
import { NavType } from '~/navigation/types';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { SET_HIDE_MOBILE_MENU } from '~/redux/reducers/overlayReducer';
import { useAppDispatch } from '~/redux/store';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useAppointmentState } from '~/utils/hooks/appointments/AppointmentStateHook';
import { screenName } from '~/utils/screenName';
import { DoctorChatMenu } from './DoctorChatMenu';

export const DoctorChat: React.FC = () => {
  const { consultation, startConsultation, endConsultation } = useAppointmentContext();
  const { isDesktop } = useBreakpoints();
  const { isEndedOrPassed } = useAppointmentState(consultation);
  const navigation = useNavigation<NavType>();
  const { isMobile } = useBreakpoints();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(SET_HIDE_MOBILE_MENU(!isEndedOrPassed));

    return () => {
      dispatch(SET_HIDE_MOBILE_MENU(false));
    };
  }, [isEndedOrPassed]);

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate({ screen: screenName.CalendarPage });
    }
  };
  return (
    <Surface style={[isDesktop ? styles.container : { maxHeight: isMobile ? '100%' : 600 }, { height: '100%' }]}>
      <KeyboardAvoidingView
        style={[{ display: 'flex', flex: 1 }]}
        behavior={'padding'}
        keyboardVerticalOffset={Platform.select({
          ios: () => 50,
          android: () => 0,
          web: () => 0,
        })()}>
        {!isDesktop ? (
          <View style={styles.mobileHeader}>
            <IconButton onPress={goBack} style={{ padding: 11 }}>
              <BackArrow width={16} height={16} color={colors.purple} />
            </IconButton>
            <H4TtmSemiBoldTheme>Chat</H4TtmSemiBoldTheme>

            <DoctorChatMenu canEnd={!isEndedOrPassed} />
          </View>
        ) : null}
        <Chat consultation={consultation} small beforeJoin={startConsultation} />
        <View style={[mt20, ph10, Platform.OS === 'ios' ? styles.iosPadding : pb15]}>
          <ChatMessageInput consultation={consultation} />
        </View>
      </KeyboardAvoidingView>
    </Surface>
  );
};

const styles = StyleSheet.create({
  container: {
    borderColor: colors.lightPurple2,
    borderWidth: 1,
    borderRadius: 10,
    elevation: 3,
  },

  mobileHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
    paddingTop: 20,
  },

  iosPadding: {
    paddingBottom: 35,
  },
});
