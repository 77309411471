import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { DeclineConsultationRequest } from '~/api/models/consultations/requests/DeclineConsultationRequest';
import { MissingConsultationRequestError } from '~/classes/errors/MissingConsultationError';
import { mb10, mb20, textCenter } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { Button, TextButton } from '~/components/commonButton';
import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalContainer } from '~/components/modals/ModalContainer';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { validationSchema } from '~/services/validationConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props {
  consultation?: ConsultationModel;
  consultationId?: number;
  onReady: () => void;
  onHide: () => void;
  declineApi: (data: DeclineConsultationRequest) => Promise<void>;
  title?: string;
  description?: string;
  buttonTitle: string;
}

export const RejectConsultationReasonModal: React.FC<Props> = ({
  consultation,
  consultationId,
  onReady,
  onHide,
  declineApi,
  title,
  description,
  buttonTitle,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { closeModalByName } = useModalManager();
  const { isMobile } = useBreakpoints();
  const spacing = isMobile ? MOBILE_HORIZONTAL_SPACE : 0;
  const { control, handleSubmit } = useForm<DeclineConsultationRequest>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      cancellation_reason_note: '',
    },
  });

  const submit = async (data: DeclineConsultationRequest) => {
    try {
      setSubmitting(true);
      await declineApi({
        id: consultation?.id || consultationId,
        cancellation_reason_note: data.cancellation_reason_note,
      }).catch(() => new MissingConsultationRequestError(consultation?.id || consultationId));
      closeModalByName(ModalName.RejectConsultationReasonModal);
      closeModalByName(ModalName.ApprovalRequest);
      onReady();
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setSubmitting(false);
    }
  };

  const buttons = [
    <Button label={buttonTitle.toUpperCase()} funCallback={handleSubmit(submit)} disabled={submitting} />,
    <TextButton label={labels.goBack.toUpperCase()} funCallback={onHide} />,
  ];

  return (
    <ModalContainer showInfoLine buttons={buttons} onHide={onHide} padding="md">
      <View style={[{ paddingHorizontal: spacing, paddingTop: MOBILE_HORIZONTAL_SPACE }]}>
        <H3TtmSemiBoldBlack style={[textCenter, mb20]}>{title}</H3TtmSemiBoldBlack>
        <H6NsRegularBlack style={mb10}>{description}</H6NsRegularBlack>
        <Controller
          name="cancellation_reason_note"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingInput
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error?.message}
              errorMessage={isSubmitted && error?.message}
              multiline
              maxLength={validationSchema.string.maxLength}
              placeholder="Enter your reason (Optional)."
            />
          )}
        />
      </View>
    </ModalContainer>
  );
};
