import React, { useEffect } from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { TouchableOpacity } from 'react-native';

import { useProfileUserDetails } from '~/api/hooks/profile/ProfileUserDetailsHook';
import { mb10, mt10, mb5, alignSelfCenter } from '~/common/commonStyles';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { H3TtmSemiBoldBlack, H5TtmSemiBoldInfo } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { VerifyModal } from '~/components/modals/VerifyModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { validationSchema, alphabetCharactersValidation, requiredValidation } from '~/services/validationConfig';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { registrationLabels } from '~/utils/labels/registrationLabels';

export const PharmacistsProfileScreen: React.FC = () => {
  const { registerModal, openModal } = useModalManager();
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    registerModal(ModalName.VerifyModal, VerifyModal);
  }, []);

  const { onSubmit, resetFormValues, control, getUserDetails } = useProfileUserDetails();
  const { isDirty } = useFormState({ control });
  return (
    <PageLayout
      title={isMobile ? undefined : 'Profile'}
      hideBack={!isMobile}
      footer={<CancelContinueFooter onCancel={resetFormValues} onConfirm={onSubmit} disabled={!isDirty} />}>
      <Grid grid={{ gutters: 16 }}>
        <Container maxContainerWidths={650} alignSelf="flex-start">
          <Row style={mb10}>
            <Column>
              <H3TtmSemiBoldBlack>{isMobile ? 'Profile' : 'Profile information'}</H3TtmSemiBoldBlack>
            </Column>
          </Row>
          <Row>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="first_name"
                control={control}
                render={({ field: { value, onChange, onBlur }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    showMandatory
                    maxLength={validationSchema.name.maxLength}
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    label={labels.legalFirstName}
                  />
                )}
                rules={{
                  validate: { alphabetCharactersValidation },
                  required: requiredValidation(labels.legalFirstName),
                }}
              />
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="last_name"
                control={control}
                render={({ field: { value, onChange, onBlur }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    showMandatory
                    maxLength={validationSchema.lastName.maxLength}
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    label={labels.legalLastName}
                  />
                )}
                rules={{
                  validate: { alphabetCharactersValidation },
                  required: requiredValidation(labels.legalLastName),
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="email"
                control={control}
                render={({ field: { value } }) => <FloatingInput disabled label={labels.emailAddress} value={value} />}
              />

              <TouchableOpacity
                onPress={() => {
                  openModal(ModalName.VerifyModal, {
                    changeEmail: true,
                    onSuccess: getUserDetails,
                  });
                }}
                style={[mt10, mb5]}>
                <H5TtmSemiBoldInfo style={[alignSelfCenter]}>{labels.changeEmailAddress}</H5TtmSemiBoldInfo>
              </TouchableOpacity>
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Row>
                <Column span={4}>
                  <Controller
                    name="country_code"
                    control={control}
                    render={({ field: { value } }) => <FloatingInput disabled value={`+${value}`} label="Ext." />}
                  />
                </Column>
                <Column>
                  <Controller
                    name="mobile_number"
                    control={control}
                    render={({ field: { value } }) => (
                      <FloatingInput disabled value={value} keyboardType="numeric" label={labels.phoneNumber} />
                    )}
                  />
                </Column>
              </Row>
              <TouchableOpacity
                onPress={() => {
                  openModal(ModalName.VerifyModal, {
                    changeEmail: false,
                    onSuccess: getUserDetails,
                  });
                }}
                style={[mt10, mb5]}>
                <H5TtmSemiBoldInfo style={[alignSelfCenter]}>
                  {registrationLabels.changedPhoneNumberQuestion}
                </H5TtmSemiBoldInfo>
              </TouchableOpacity>
            </Column>
          </Row>
        </Container>
      </Grid>
    </PageLayout>
  );
};
