import { FlashList } from '@shopify/flash-list';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import CalendarStrip from 'react-native-calendar-strip';
import { Divider } from 'react-native-paper';

import { ICALUrlModal } from '../../../../components/calendar/modals/ICALUrlModal';

import { CalendarEventResponse } from '~/api/models/calendar/responses/CalendarEventResponse';
import { apiCalendarEvents, apiDeleteUnavailable, apiSetUnavailable } from '~/api/services/calendar';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { flex1, mt10, mv10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import ConsultationAgendaItem from '~/components/calendar/ConsultationAgendaItem';
import CalendarDay from '~/components/calendar/mobile/CalendarDay';
import { DoctorAvailabilityModal } from '~/components/calendar/mobile/DoctorAvailabilityModal';
import { OutlineButton } from '~/components/commonButton';
import { H3TtmSemiBoldBlack, H5TtmRegularTheme } from '~/components/commonText';
import DatePicker from '~/components/inputs/dateTime/DatePicker';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { CalendarIcon, DiagonalLines, DownArrow } from '~/components/svgImages';
import { PageLayout } from '~/layouts/PageLayout';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isNative } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { LARAVEL_DATE_TIME_SHORT, MONTH_YEAR_FORMAT, NUM_DATE_FORMAT, isPastDate } from '~/utils/dateAndTime';
import { transformLabel } from '~/utils/labelUtil';
import { calendarLabels } from '~/utils/labels/calendar';

export const DoctorAppointmentsMobile: React.FC = () => {
  const [refreshing, setRefreshing] = useState(false);
  const [events, setEvents] = useState<CalendarEventResponse>([]);
  const { registerModal, openModal, closeModalByName } = useModalManager();
  const CalendarStripRef = useRef(null);
  const [date, setDate] = useState(moment());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    registerModal(ModalName.DoctorAvailabilityModal, DoctorAvailabilityModal);
    registerModal(ModalName.ICALUrlModal, ICALUrlModal);
  }, []);

  useEffect(() => {
    getEvents(date);
  }, []);

  useEffect(() => {
    if (refreshing) {
      getEvents(date);
    }
    setRefreshing(false);
  }, [refreshing]);

  const handleRefresh = async () => {
    setRefreshing(true);
  };

  const getEvents = async (date: Moment) => {
    setLoading(true);
    const from = date.startOf('day').format(LARAVEL_DATE_TIME_SHORT);
    const to = date.endOf('day').format(LARAVEL_DATE_TIME_SHORT);

    const calendarEvents = await apiCalendarEvents({
      filter: { type: ['consultation', 'unavailability'].join(',') },
      to,
      from,
    });
    setEvents(calendarEvents.data);
    setLoading(false);
  };

  const setUnavailable = async () => {
    const from = date.startOf('day').format(LARAVEL_DATE_TIME_SHORT);
    const to = date.endOf('day').format(LARAVEL_DATE_TIME_SHORT);
    await apiSetUnavailable({
      ends_at: to,
      starts_at: from,
    });

    closeModalByName(ModalName.DoctorAvailabilityModal);
    handleRefresh();
  };

  const deleteUnavailable = async () => {
    await apiDeleteUnavailable(events[0].events[0].id);
    closeModalByName(ModalName.DoctorAvailabilityModal);
    handleRefresh();
  };

  const footer = (
    <OutlineButton
      funCallback={() => {
        openModal(ModalName.ICALUrlModal);
      }}
      label={calendarLabels.addToMyCalendar}
    />
  );

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, () => {
      setRefreshing(true);
    });
    return removeListener;
  }, []);

  const noBookingsMessage = useMemo(() => {
    return isPastDate(date.toDate())
      ? transformLabel(calendarLabels.noBookedAppointmentsOnDatePassed, {
          date: date.calendar({
            sameDay: '[today]',
            nextDay: '[tomorrow]',
            nextWeek: '[on] D MMMM',
            lastDay: '[yesterday]',
            lastWeek: '[on] D MMMM',
            sameElse: '[on] D MMMM',
          }),
        })
      : transformLabel(calendarLabels.noBookedAppointmentsOnDateFuture, {
          date: date.calendar({
            sameDay: '[today]',
            nextDay: '[tomorrow]',
            nextWeek: '[on] D MMMM',
            lastDay: '[yesterday]',
            lastWeek: '[on] D MMMM',
            sameElse: '[on] D MMMM',
          }),
        });
  }, [events, date]);

  return (
    <PageLayout
      contentStyling={flex1}
      hideBack
      noHeaderPadding
      footer={footer}
      scrollContentNode={
        <>
          <View style={{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE }}>
            <DatePicker
              withBorder={false}
              showMandatory={false}
              value={date.format(NUM_DATE_FORMAT)}
              formatDisplayValue={MONTH_YEAR_FORMAT}
              renderValue={(value) => {
                return <H5TtmRegularTheme>{value}</H5TtmRegularTheme>;
              }}
              iconPrefix={<CalendarIcon width={15} height={15} />}
              iconSuffix={<DownArrow height={10} width={10} />}
              onChangeValue={(value) => {
                setDate(moment(value, NUM_DATE_FORMAT));
                getEvents(moment(value, NUM_DATE_FORMAT));
              }}
            />
          </View>
          <CalendarStrip
            ref={CalendarStripRef}
            dayComponent={(props) => <CalendarDay {...props} />}
            calendarHeaderStyle={{ display: 'none' }}
            dayComponentHeight={80}
            selectedDate={date}
            style={{ backgroundColor: colors.white, width: '100%', height: 100, marginTop: 40 }}
            scrollable
            onDateSelected={(date) => {
              setDate(date);
              getEvents(date);
            }}
            leftSelector={isNative() ? <></> : null}
            rightSelector={isNative() ? <></> : null}
          />

          {events[0]?.events?.length === 0 ? (
            <TouchableOpacity
              style={{ flex: 0.9, paddingHorizontal: MOBILE_HORIZONTAL_SPACE, marginTop: 20 }}
              onPress={() => {
                openModal(ModalName.DoctorAvailabilityModal, {
                  events: events[0].events,
                  setUnavailable,
                  deleteUnavailable,
                });
              }}>
              <H3TtmSemiBoldBlack>{loading ? null : noBookingsMessage}</H3TtmSemiBoldBlack>
            </TouchableOpacity>
          ) : events[0]?.events[0]?.event_type === 'unavailability' ? (
            <TouchableOpacity
              style={{ flex: 0.9, paddingHorizontal: MOBILE_HORIZONTAL_SPACE, marginTop: 20 }}
              onPress={() => {
                openModal(ModalName.DoctorAvailabilityModal, {
                  events: events[0].events,
                  setUnavailable,
                  deleteUnavailable,
                });
              }}>
              <H3TtmSemiBoldBlack>{calendarLabels.unavailable}</H3TtmSemiBoldBlack>
              <View style={{ flexDirection: 'row', flex: 0.9, ...mt10, overflow: 'hidden' }}>
                <View style={{ borderWidth: 5, flex: 1, borderColor: colors.lightPurple2 }} />
                <DiagonalLines height="100%" width="100%" />
              </View>
            </TouchableOpacity>
          ) : (
            <FlashList
              data={events[0]?.events ?? []}
              keyExtractor={(item) => `${item.id}`}
              estimatedItemSize={75}
              ItemSeparatorComponent={() => (
                <View style={mv10}>
                  <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
                </View>
              )}
              renderItem={(item) => <ConsultationAgendaItem calendarEvent={item.item} />}
            />
          )}
        </>
      }
    />
  );
};
