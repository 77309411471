export function transformLabel(label: string, data?: Record<string, string> | string[]) {
  if (!label || !data || typeof label !== 'string') return label;

  const dataKeys = Object.keys(data);

  return dataKeys.reduce((prev, key) => prev.replace(new RegExp(`\\$${key}`, 'g'), data[key]), label);
}

export function transformLabels(label: string[], data?: Record<string, string> | string[]) {
  if (!label || !data) return label;
  return label.map((item) => transformLabel(item, data));
}
