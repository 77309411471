import React, { useMemo } from 'react';
import { View } from 'react-native';

import { PrescriptionDetailComponent } from './PrescriptionDetailComponent';
import { PrescriptionDoctorPatientDetails } from './PrescriptionDoctorPatientDetails';
import { PharmacyPrescriptionDispenseHistory } from './dispenseHistory/PharmacyPrescriptionDispenseHistoryComponent';
import { Button, OutlineButton } from '../../commonButton';
import { H3TtmSemiBoldBlack, H6NsSemiBoldLink } from '../../commonText';
import { LoadingActivityIndicator } from '../../loading/LoadingActivityIndicator';
import { ErrorAlert } from '../ErrorAlert';
import { ModalContainer } from '../ModalContainer';

import { usePrescriptionDispense } from '~/api/hooks/pharmacy/PharmacyDispenseHook';
import { usePrescriptionRequestsIndex } from '~/api/hooks/pharmacy/PharmacyPrescriptionsHook';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { mb20, textCenter } from '~/common/commonStyles';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { labels } from '~/utils/labels';
import { pharmacyLabels } from '~/utils/labels/pharmacy';

interface Props {
  prescription: PrescriptionModel;
  onHide?: () => void;
}

export const PrescriptionDispenseModal: React.FC<Props> = ({ prescription, onHide }) => {
  const {
    isAllCollected,
    isUsed,
    loading: requestLoading,
  } = usePrescriptionRequestsIndex({
    prescription,
  });
  const { dispense, loading: dispenseLoading } = usePrescriptionDispense();

  const isCollectable = useMemo(() => prescription.is_collectable !== false, [prescription]);

  if (!prescription) return null;

  const close = (
    <OutlineButton label={labels.close} funCallback={onHide} disabled={requestLoading || dispenseLoading} />
  );
  const buttons =
    isUsed || isAllCollected || requestLoading || !isCollectable
      ? [close]
      : [
          <Button
            label="Dispense now"
            funCallback={() => dispense(prescription).then(onHide).catch(ErrorAlert)}
            disabled={requestLoading || isAllCollected || dispenseLoading}
          />,
          close,
        ];

  const subtitle = !isCollectable ? (
    <H6NsSemiBoldLink style={textCenter}>{pharmacyLabels.modal.subtitleNotCollectable}</H6NsSemiBoldLink>
  ) : isUsed ? (
    <H6NsSemiBoldLink style={textCenter}>{pharmacyLabels.modal.subtitleUsed}</H6NsSemiBoldLink>
  ) : isAllCollected ? (
    <H6NsSemiBoldLink style={textCenter}>{pharmacyLabels.modal.subtitleCollected}</H6NsSemiBoldLink>
  ) : null;

  return (
    <ModalContainer hideOnBackground={!dispenseLoading} onHide={onHide} showInfoLine wide buttons={buttons}>
      <View>
        {requestLoading ? (
          <LoadingActivityIndicator />
        ) : (
          <Grid>
            <Container>
              <Row style={mb20} justifyContent="center">
                <Column span="auto">
                  <H3TtmSemiBoldBlack>Prescription {prescription.reference_number}</H3TtmSemiBoldBlack>
                </Column>
              </Row>
            </Container>

            {subtitle ? (
              <Container>
                <Row style={mb20}>
                  <Column>{subtitle}</Column>
                </Row>
              </Container>
            ) : null}

            <PrescriptionDoctorPatientDetails prescription={prescription} style={mb20} />

            <PharmacyPrescriptionDispenseHistory prescription={prescription} style={mb20} />

            <PrescriptionDetailComponent prescription={prescription} />
          </Grid>
        )}
      </View>
    </ModalContainer>
  );
};
