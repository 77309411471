import { useEffect } from 'react';

import { getAsyncItem, setAsyncItem } from '~/common/asyncStorage';

const isValidUrl = (url: string) => {
  try {
    const urlObject = new URL(url);
    return !!urlObject;
  } catch {
    return false;
  }
};

export const useStoreReferrer = () => {
  useEffect(() => {
    const referrer = document.referrer;

    if (referrer && isValidUrl(referrer)) {
      setAsyncItem('referrer', referrer);
    }
  }, []);
};

export const useLoadReferrer = () => {
  const getReferrer = async () => {
    const referrer = await getAsyncItem('referrer');
    return referrer && isValidUrl(referrer) ? referrer : undefined;
  };

  return {
    getReferrer,
  };
};
