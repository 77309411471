import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import { Snackbar } from 'react-native-paper';

import { IconButton } from './buttons/IconButton';
import { ExtraSmallNsRegularPlaceholder, H6NsRegularBlack, H6NsRegularWhite } from './commonText';
import { XOutline } from './svgImages';

import { isNative } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  message: string;
  icon?: JSX.Element;
  onPress?: () => void;
  onClose?: () => void;
  actionTitle?: string;
  timer?: number;
  isError?: boolean;
}
export const SnackbarNotification: React.FC<Props> = ({
  message,
  icon,
  onPress,
  onClose,
  actionTitle,
  timer,
  isError,
}) => {
  const { isMobile } = useBreakpoints();
  const windowWidth = Dimensions.get('window').width;
  const snackbarWidth = windowWidth - (10 / 100) * windowWidth;
  const closeTimeout = useRef<ReturnType<typeof setTimeout>>();

  const close = () => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
      closeTimeout.current = null;
    }
    if (onClose) onClose();
  };

  useEffect(() => {
    if (timer) {
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
      }
      closeTimeout.current = setTimeout(close, timer);
    }
  }, [timer]);

  const content = (
    <Snackbar
      visible
      wrapperStyle={{ position: 'relative' }}
      onDismiss={() => {}}
      style={[isMobile ? styles.snackBarMobile : styles.snackBarWeb, isError ? styles.snackbarError : null]}>
      <View style={{ flexDirection: 'row', alignItems: 'center', width: isNative() ? snackbarWidth : '100%' }}>
        {icon ? <View style={{ marginRight: 10 }}>{icon}</View> : null}

        <View style={{ flex: 1 }}>
          {isMobile ? <H6NsRegularWhite>{message}</H6NsRegularWhite> : <H6NsRegularBlack>{message}</H6NsRegularBlack>}
          {onPress ? (
            <ExtraSmallNsRegularPlaceholder>{actionTitle || 'Click here for more'}</ExtraSmallNsRegularPlaceholder>
          ) : null}
        </View>

        <IconButton onPress={close} transparent style={{ marginLeft: 'auto' }}>
          <XOutline width={10} height={10} color={isMobile ? colors.white : colors.black} />
        </IconButton>
      </View>
    </Snackbar>
  );
  if (!onPress) return <View>{content}</View>;
  return (
    <TouchableOpacity
      onPress={() => {
        onPress();
        onClose();
      }}>
      {content}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  snackBarWeb: { backgroundColor: colors.white, borderColor: colors.info, borderTopWidth: 10 },
  snackBarMobile: { backgroundColor: colors.purple },
  snackbarError: { borderColor: colors.danger },
});
