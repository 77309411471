import { useEffect } from 'react';

import { apiGetCheckHasUnreadNotifications } from '~/api/services/notifications';
import { HAS_UNREAD_NOTIFICATIONS } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';

export function useHasUnreadNotifications() {
  const dispatch = useAppDispatch();

  const checkIfUserHasNotifications = () => {
    return apiGetCheckHasUnreadNotifications()
      .then((response) => {
        dispatch(HAS_UNREAD_NOTIFICATIONS(response.data.has_unread_notifications));
      })
      .catch(() => {
        dispatch(HAS_UNREAD_NOTIFICATIONS(false));
      });
  };

  useEffect(() => {
    checkIfUserHasNotifications();
  }, []);
}
