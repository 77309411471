import { get, post, put } from '../../axios';
import { AddAccountRequest } from '../../models/accounts/requests/AddAccountRequest';
import { UpdateAccountRequest } from '../../models/accounts/requests/UpdateAccountRequest';
import { AccountResponse } from '../../models/accounts/responses/AccountResponse';
import { AccountsIndexResponse } from '../../models/accounts/responses/AccountsIndexResponse';
import { IdModel } from '../../models/common/models/IdModel';

import { AccountsIndexRequest } from '~/api/models/accounts/requests/AccountsIndexRequest';
import { AccountSendNotification } from '~/api/models/accounts/requests/AccountsSendNotification';
import { UpdateProfileImageRequest } from '~/api/models/accounts/requests/UpdateProfileImageRequest';
import fileSystem from '~/integrations/fileSystem';

export const apiGetAccountIndex = (params: AccountsIndexRequest) => {
  return get<AccountsIndexResponse>(`/accounts`, params);
};

export const apiAddAccount = (data: AddAccountRequest) => {
  return post<AccountResponse>('/accounts', data);
};

export const apiUpdateAccounts = (params: { id: number; data: UpdateAccountRequest }) => {
  return put<AccountResponse>(`/accounts/${params.id}`, params.data);
};

export const apiGetAccount = (params: IdModel) => {
  return get<AccountResponse>(`/accounts/${params.id}`);
};

export const apiUpdateProfileImage = (req: UpdateProfileImageRequest) => {
  return fileSystem.postDocumentResult(`/accounts/${req.id}/profile-image`, 'profile_image', req.document);
};

export const apiSendNotifications = (req: AccountSendNotification) => {
  return post('/accounts/send/patient-invitation', req);
};

export const apiSendNotificationsAll = () => {
  return post('/accounts/send/patient-invitation/all');
};
