export enum SumSubReviewStatus {
  READY = 'ready',
  INITIAL = 'initial',
  INCOMPLETE = 'incomplete',
  TEMPORARILY_DECLINED = 'temporarilydeclined',
  FINALLY_REJECTED = 'finallyrejected',
  PENDING = 'pending',
  COMPLETED = 'completed',
  APPROVED = 'approved',
}
export enum SumSubReviewAnswer {
  APPROVED = 'GREEN',
  REJECTED = 'RED',
}
export enum SumSubReviewRejectionType {
  FINAL = 'FINAL',
  RETRY = 'RETRY',
}
