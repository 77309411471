import React, { Dispatch, SetStateAction, useState } from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { Menu } from 'react-native-paper';

import { ConsultationTypeMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { IconButton } from '~/components/buttons/IconButton';
import { ConsultationTypeFilterComponent } from '~/components/common/appointments/appointmentListing/components/ConsultationTypeFilterComponent';
import { Button } from '~/components/commonButton';
import { H6NsRegularBlack } from '~/components/commonText';
import { DownArrow, FilterIcon } from '~/components/svgImages';
import { useIncomeListingContext } from '~/providers/income/IncomeListingContext';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  setYear?: Dispatch<SetStateAction<number>>;
  setMinDate?: Dispatch<SetStateAction<string>>;
  setType?: Dispatch<SetStateAction<string>>;
}

export const ConsultationTypeFilter: React.FC<Props> = ({ setYear, setMinDate, setType }) => {
  const {
    form: { control },
    submitQuery,
  } = useIncomeListingContext();

  const [visible, setVisible] = useState(false);

  const { isMobile } = useBreakpoints();

  return (
    <Controller
      name="ConsultationType"
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <IconButton onPress={() => setVisible(true)} style={[styles.buttonStyle]} hoverStyle={styles.buttonHover}>
            <Menu
              visible={visible}
              onDismiss={() => {
                setVisible(false);
              }}
              style={[styles.menuStyle]}
              anchor={
                isMobile ? (
                  <FilterIcon width="19" height="16" />
                ) : (
                  <View
                    style={{
                      width: 200,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <H6NsRegularBlack>
                      {value.length === 1 && value[0] == null
                        ? 'All'
                        : value
                            .filter((e) => e != null)
                            ?.map((type) => {
                              return ConsultationTypeMapping[type];
                            })
                            .join(', ')}
                    </H6NsRegularBlack>
                    <DownArrow height={10} width={10} color={colors.purple} />
                  </View>
                )
              }>
              <View style={[{ width: 250, alignItems: 'flex-start' }]}>
                <ConsultationTypeFilterComponent setValue={onChange} value={value} withAll />

                <View style={{ width: '100%', paddingHorizontal: 10, marginTop: 20 }}>
                  <Button
                    label="Apply"
                    funCallback={() => {
                      submitQuery();
                      setVisible(false);
                    }}
                  />
                </View>
              </View>
            </Menu>
          </IconButton>
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  menuStyle: {
    marginTop: 10,
    marginLeft: 10,
  },
  buttonStyle: {
    padding: 10,
    maxWidth: 250,
  },
  buttonHover: {
    borderColor: colors.lightPurple,
    backgroundColor: colors.lightPurple,
  },
});
