import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useUserDetails } from './accounts/UserDetails';
import { usePharmacyStoredLinkingDetails } from './pharmacy/PharmacyLinkingAction';
import { RootState } from '../../redux/reducers';
import {
  FLAG_REGISTRATION_STATUS_LOADING,
  registrationStatusSelector,
  UPDATE_REGISTRATION_STATUS,
  UPDATE_REGISTRATION_STATUS_COMPLETION,
} from '../../redux/reducers/userDetailsReducer';
import { hasToken } from '../axios/interceptors';
import { apiGetRegistrationStatus } from '../services/registration';

import { useAppDispatch } from '~/redux/store';
import { isPharmacyVersion } from '~/utils/buildConfig';
import { digimedMilestoneEnabled, DigimedMilestones } from '~/utils/milestoneUtil';
import {
  registrationPreKyiCompleted,
  registrationPreDigimedApprovalCompleted,
  registrationAllCompleted,
} from '~/utils/registration/registrationCompletion';
import { getRegistrationProgress } from '~/utils/registration/registrationProgress';
import { getCurrentRegistrationScreen, ScreenProperties } from '~/utils/registration/registrationScreenGuard';

export function useFetchRegistrationStatus() {
  const dispatch = useAppDispatch();
  const registrationSteps = useSelector((state: RootState) => state.userDetailsReducer.registrationStatus);
  const loading = useSelector((state: RootState) => !!state.userDetailsReducer.registrationStatusLoading);
  const { userDetails } = useUserDetails({ allowStale: true });
  const { hasLinkingDetails } = usePharmacyStoredLinkingDetails();

  const updateRegistrationStatus = async () => {
    if (!loading) {
      try {
        dispatch(FLAG_REGISTRATION_STATUS_LOADING());
        const hasAuthToken = await hasToken();
        if (!hasAuthToken) {
          dispatch(FLAG_REGISTRATION_STATUS_LOADING(false));
          return;
        }

        const response = await apiGetRegistrationStatus();
        // Filter out not required items for first milestone
        const data =
          digimedMilestoneEnabled(DigimedMilestones.DoctorPatientApps) && !hasLinkingDetails
            ? response.data.data
            : response.data.data.filter((item) => item.required);

        const completionStatus = {
          preKyi: registrationPreKyiCompleted(data, isPharmacyVersion() && !hasLinkingDetails),
          preDigimed: await registrationPreDigimedApprovalCompleted(data),
          all: await registrationAllCompleted(data, userDetails, response.data.is_completed),
        };

        dispatch(UPDATE_REGISTRATION_STATUS(data));
        dispatch(UPDATE_REGISTRATION_STATUS_COMPLETION(completionStatus));

        return { data, is_completed: response.data.is_completed };
      } catch {
        dispatch(FLAG_REGISTRATION_STATUS_LOADING(false));
      }
    }
  };

  return { steps: registrationSteps, loading, updateRegistrationStatus };
}

interface Props {
  immediate?: boolean;
}
export function useRegistrationSteps(options?: Props) {
  const fetchRegistrationStatus = useFetchRegistrationStatus();

  useEffect(() => {
    if (!options?.immediate || fetchRegistrationStatus.loading) {
      return;
    }
    fetchRegistrationStatus.updateRegistrationStatus();
  }, []);

  return fetchRegistrationStatus;
}

export function useRegistrationStepsScreenGuard() {
  const [loading, setLoading] = React.useState(true);
  const requiredCompleted = useSelector(
    (state: RootState) => !!state.userDetailsReducer.registrationStatusCompletion?.all
  );
  const registrationSteps = useSelector(registrationStatusSelector);
  const [currentScreen, setCurrentScreen] = React.useState<ScreenProperties>(null);
  const { userDetails } = useUserDetails({ allowStale: true });

  useEffect(() => {
    if (registrationSteps) {
      setLoading(false);
    }

    updateCurrentRegistrationScreen();
  }, [registrationSteps]);

  const updateCurrentRegistrationScreen = async () => {
    const hasAuthToken = await hasToken();
    if (!hasAuthToken) {
      return;
    }

    const screenProperties = getCurrentRegistrationScreen(registrationSteps, userDetails);
    setCurrentScreen(screenProperties);
  };
  return { completed: requiredCompleted, currentScreen, loading };
}

export function useRegistrationProgress() {
  const { steps } = useRegistrationSteps();
  const progress = useMemo(() => {
    return getRegistrationProgress(steps);
  }, [steps]);
  return { progress };
}
