import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { flex1, mb5, ml10 } from '~/common/commonStyles';
import { ConsultationTypeIcon } from '~/components/common/consultations/ConsultationTypeIcon';
import {
  H5TtmRegularBlack,
  SmallNsRegularInfo,
  SmallNsRegularSecondaryBlack,
  SmallNsSemiBoldBlack,
} from '~/components/commonText';
import { NewBadge } from '~/components/misc/NewBadge';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { ForwardArrow } from '~/components/svgImages';
import { ConsultationTypeName } from '~/services/config';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { parseDateTime, DAY_MONTH_FORMAT, displayTimeRange, TIME_FORMAT, DATE_FORMAT } from '~/utils/dateAndTime';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';

interface Props {
  request: ConsultationModel;
  onPress: (request: ConsultationModel) => void;
}

export const RequestListMobileItem: React.FC<Props> = ({ request, onPress }) => {
  const { showNewBadge } = useNewBadge({ timeKey: 'updated_at' });

  const date = useMemo(() => {
    if (request.type === ConsultationTypeEnum.ON_DEMAND) {
      return (
        <View>
          <SmallNsSemiBoldBlack style={mb5}>{`${
            parseDateTime(request.start_at, {
              outputFormat: DAY_MONTH_FORMAT,
            }) ?? ''
          }`}</SmallNsSemiBoldBlack>
          <SmallNsRegularInfo style={{ textWrap: 'wrap' }}>
            {displayTimeRange(
              parseDateTime(request.start_at, { outputFormat: TIME_FORMAT }),
              parseDateTime(request.end_at, { outputFormat: TIME_FORMAT })
            )}
          </SmallNsRegularInfo>
        </View>
      );
    } else {
      const firstAvailability = request?.patient_availabilities[0];
      return (
        <View>
          <SmallNsSemiBoldBlack style={mb5}>{`${
            parseDateTime(firstAvailability.from, {
              outputFormat: DAY_MONTH_FORMAT,
            }) ?? ''
          }`}</SmallNsSemiBoldBlack>
          <SmallNsRegularInfo style={{ textWrap: 'wrap' }}>
            from {parseDateTime(firstAvailability.from, { outputFormat: TIME_FORMAT })}
          </SmallNsRegularInfo>
        </View>
      );
    }
  }, [request]);

  return (
    <TouchableOpacity onPress={() => onPress(request)}>
      <Grid grid={{ gutters: 4 }}>
        <Container>
          <Row style={[styles.item, { paddingVertical: 8 }]}>
            <Column style={flex1} span="auto">
              {showNewBadge ? <NewBadge style={styles.newBadge} /> : null}
              <ProfileDetailsComponent account={request.patient} theme="table" style={[flex1, ml10]} />
            </Column>
            <Column style={flex1} alignSelf="center">
              {request.reason ? (
                <SmallNsRegularSecondaryBlack
                  numberOfLines={3}
                  ellipsizeMode="tail"
                  style={{ textOverflow: 'ellipsis' }}>
                  {request.reason}
                </SmallNsRegularSecondaryBlack>
              ) : (
                <View>
                  <H5TtmRegularBlack numberOfLines={3} ellipsizeMode="tail" style={{ textOverflow: 'ellipsis' }}>
                    {ConsultationTypeName[request.type]}
                  </H5TtmRegularBlack>
                  <ConsultationTypeIcon consultation={request} />
                </View>
              )}
            </Column>
            <Column span="auto" alignSelf="center">
              <ForwardArrow width={15} height={15} color={colors.purple} />
            </Column>
          </Row>
        </Container>
      </Grid>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  item: {
    minHeight: 75,
    flexGrow: 1,
    justifyContent: 'flex-start',
  },
  newBadge: {
    zIndex: 100,
    position: 'absolute',
    left: 3,
    top: 2,
  },
});
