import { Moment } from 'moment';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { CalendarEventModel } from '~/api/models/preferences/models/CalendarEventModel';
import { apiExportConsultationsZip } from '~/api/services/clinics/exports';
import { apiGetHealthRecord, apiHealthRecordMediaDownload } from '~/api/services/consultations/healthRecord';
import { apiGetPrescriptionIndex, apiGetPrescriptionMedia } from '~/api/services/consultations/prescriptions';
import {
  apiGetReferralGeneratedPDFs,
  apiGetReferralIndex,
  apiGetReferralMedia,
} from '~/api/services/consultations/referral';
import { ConfirmationAlert, InprogressAlert, SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import fileSystem from '~/integrations/fileSystem';
import { DAY_MONTH_YEAR_FORMAT, NUM_DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';

const UNSUBMITTED_STATES = [
  ConsultationStateEnum.Scheduled,
  ConsultationStateEnum.PendingPayment,
  ConsultationStateEnum.Started,
  ConsultationStateEnum.Ended,
];

export const useClinicReportExport = () => {
  const executeExport = async (date: Date | Moment) => {
    try {
      InprogressAlert(['We are in the process of generating your exported data and a file will be downloaded shortly']);
      const formattedDate = parseDateTime(date, { outputFormat: NUM_DATE_FORMAT });
      await apiExportConsultationsZip({
        fileName: `Digimed-Consultations-${formattedDate}.zip`,
        params: {
          start_date: formattedDate,
          end_date: formattedDate,
        },
      });

      SuccessAlert([
        `Consultation reports have been successfully downloaded for ${parseDateTime(date, {
          outputFormat: DAY_MONTH_YEAR_FORMAT,
        })}`,
      ]);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const tryExportConsultationEvents = (consultationEvents: CalendarEventModel[], date: Date | Moment) => {
    return tryExportConsultations(
      consultationEvents?.map((event) => event.eventable),
      date
    );
  };

  const tryExportConsultations = (consultations: ConsultationModel[], date: Date | Moment) => {
    if (!consultations?.length) return;

    const anyUnSubmitted = consultations.filter((consultation) => UNSUBMITTED_STATES.includes(consultation.state));
    const messages = [
      `Are you sure you want to export all the reports for consultations scheduled on ${parseDateTime(date, {
        outputFormat: DAY_MONTH_YEAR_FORMAT,
      })}?`,
    ];
    if (anyUnSubmitted?.length) {
      messages.push(
        'Some consultations are not yet finished for this day, you will then need to re-export them or export them one by one.'
      );
    }

    ConfirmationAlert(messages, {
      title: 'Export consultation reports',
      okTitle: 'Start export',
      okFunction: () => {
        executeExport(date);
      },
    });
  };

  const exportHealthRecord = async (healthRecordId: number) => {
    try {
      await apiHealthRecordMediaDownload({ id: healthRecordId });
    } catch {}
  };

  const exportPrescription = async (prescriptionId: number) => {
    try {
      const res = await apiGetPrescriptionMedia({ id: prescriptionId });
      if (res.data.media) {
        res.data.media.forEach(async (media) => await fileSystem.downloadMedia(media));
      }
    } catch {}
  };

  const exportReferral = async (referralId: number) => {
    try {
      const generatedPdf = (await apiGetReferralGeneratedPDFs({ id: referralId }))?.data;
      const mediaArray = await apiGetReferralMedia({ id: referralId }).then((res) =>
        res.data.data.reduce(
          (prev, item) => {
            prev.push(...item.media);
            return prev;
          },
          [...generatedPdf?.media]
        )
      );

      mediaArray.forEach(async (media) => await fileSystem.downloadMedia(media));
    } catch {}
  };

  const exportConsultation = async (consultation: ConsultationModel) => {
    const healthRecordId = (await apiGetHealthRecord({ consultationId: consultation.id })).data.id;
    const prescriptions = await apiGetPrescriptionIndex({ filter: { consultation_id: consultation.id } })
      .then((res) => res.data.data)
      .catch(() => []);
    const referrals = await apiGetReferralIndex({ filter: { consultation_id: consultation.id } })
      .then((res) => res.data.data)
      .catch(() => []);

    exportHealthRecord(healthRecordId);
    prescriptions.forEach((prescription) => exportPrescription(prescription.id));
    referrals.forEach((referral) => exportReferral(referral.id));
  };

  return { tryExportConsultationEvents, tryExportConsultations, exportConsultation };
};
