import { healthProfileLabels } from '~/utils/labels/healthProfileLabels';
import { screenName } from '~/utils/screenName';

export const PATIENT_HEALTH_PROFILE_PAGES = [
  { title: healthProfileLabels.healthMetrics, link: { screen: screenName.PatientHealthProfileHealthMetrics } },
  { title: healthProfileLabels.allergies, link: { screen: screenName.PatientHealthProfileAllergies } },
  { title: healthProfileLabels.medicalConditions, link: { screen: screenName.PatientHealthProfileMedical } },
  { title: healthProfileLabels.medications, link: { screen: screenName.PatientHealthProfileMedications } },
  { title: healthProfileLabels.procedures, link: { screen: screenName.PatientHealthProfileProcedures } },
  { title: healthProfileLabels.prosthesisImplantsGrafts, link: { screen: screenName.PatientHealthProfileProsthesis } },
  { title: healthProfileLabels.vaccinations, link: { screen: screenName.PatientHealthProfileVaccinations } },
  { title: healthProfileLabels.lifestyle, link: { screen: screenName.PatientHealthProfileLifestyle } },
];

export const HEALTH_PROFILE_PAGES = [
  { title: healthProfileLabels.healthMetrics, link: { screen: screenName.HealthProfileHealthMetrics } },
  { title: healthProfileLabels.allergies, link: { screen: screenName.HealthProfileAllergies } },
  { title: healthProfileLabels.medicalConditions, link: { screen: screenName.HealthProfileMedical } },
  { title: healthProfileLabels.medications, link: { screen: screenName.HealthProfileMedications } },
  { title: healthProfileLabels.procedures, link: { screen: screenName.HealthProfileProcedures } },
  { title: healthProfileLabels.prosthesisImplantsGrafts, link: { screen: screenName.HealthProfileProsthesis } },
  { title: healthProfileLabels.vaccinations, link: { screen: screenName.HealthProfileVaccinations } },
  { title: healthProfileLabels.lifestyle, link: { screen: screenName.HealthProfileLifestyle } },
];
