import { get, put } from '~/api/axios';
import { IdModel } from '~/api/models/common/models/IdModel';
import { NotificationsFilterRequest } from '~/api/models/notifications/requests/NotificationsFilterRequest';
import { HasUnreadNotificationResponse } from '~/api/models/notifications/responses/HasUnreadNotificationResposne';
import { NotificationsResponse } from '~/api/models/notifications/responses/NotificationsResponse';

export const apiGetNotifications = (params?: NotificationsFilterRequest) => {
  return get<NotificationsResponse>(`/notifications`, params);
};

export const apiGetCheckHasUnreadNotifications = () => {
  return get<HasUnreadNotificationResponse>(`/notifications/has-unread-notifications`);
};

export const apiMarkAllNotificationsAsRead = () => {
  return put(`/notifications/all/read`);
};

export const apiMarkNotificationAsRead = (req: IdModel<string>) => {
  return put(`/notifications/${req.id}/read`);
};
