import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { Button, OutlineButton } from '~/components/commonButton';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useJoinAppointment } from '~/utils/hooks/appointments/AppointmentDetailsHook';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { labels } from '~/utils/labels';

interface Props {
  consultation: ConsultationModel;
  // onPress: () => void;
  style?: StyleProp<ViewStyle>;
}
export const JoinConsultationButton: React.FC<Props> = ({ consultation, style }) => {
  const { isMobile } = useBreakpoints();

  const { showJoin } = useJoinAppointment(consultation);

  const { showDetails } = useShowAppointment(consultation);

  const onPress = () => {
    showDetails({ consultation });
  };

  if (showJoin) {
    return !isMobile ? (
      <Button style={style} label={labels.join} funCallback={onPress} />
    ) : (
      <OutlineButton style={style} label={labels.join} funCallback={onPress} />
    );
  }

  return <OutlineButton style={style} label={labels.viewDetails} funCallback={onPress} />;
};
