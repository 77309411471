import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ProfileCompletionContext } from './ProfileCompletionContext';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { useDependantsHook } from '~/api/hooks/dependants/DependantsHook';
import { useSubscriptions } from '~/api/hooks/subscriptions/SubscriptionHook';
import { SubscriptionTypeEnum } from '~/api/models/subscriptions/models/SubscriptionPlanModel';
import { apiGetHealthProfile } from '~/api/services/healthProfile';
import { useInsuranceDetails } from '~/components/patient/insurance/hooks/useInsuranceDetails';
import { RootState } from '~/redux/reducers';
import { allDependantsSelector } from '~/redux/reducers/dependantsReducer';
import {
  SET_FAMILY_MEMBERS_COMPLETED,
  SET_HEALTH_PROFILE_COMPLETED,
  SET_INSURANCE_COMPLETED,
  SET_UPGRADED_PLAN_COMPLETED,
  hasCompletionState,
} from '~/redux/reducers/patient/profileCompletionReducer';
import { useAppDispatch } from '~/redux/store';
import { usePageFocus } from '~/utils/hooks/FocusHook';

interface Props {
  children: React.ReactNode;
}

export const ProfileCompletionProvider: React.FC<Props> = ({ children }) => {
  const completionState = useSelector((state: RootState) => state.profileCompletionReducer);
  const hasAnyCompletionState = useSelector(hasCompletionState);

  const [loading, setLoading] = useState(true);
  const { userDetails } = useUserDetails({ allowStale: true });
  const { currentPlan } = useSubscriptions();
  const dependants = useSelector(allDependantsSelector);
  const { insuranceDetails } = useInsuranceDetails();
  const { fetchDependants } = useDependantsHook({ preventOnFocus: true });
  const dispatch = useAppDispatch();

  const fetchData = async () => {
    try {
      setLoading(!hasAnyCompletionState);
      if (!completionState.healthProfile) {
        const healthProfile = await apiGetHealthProfile({ id: userDetails.account.id })
          .then((res) => !!res.data)
          .catch(() => false);
        dispatch(SET_HEALTH_PROFILE_COMPLETED(healthProfile));
      }

      if (!dependants) await fetchDependants();
    } catch {}
    setLoading(false);
  };

  useEffect(() => {
    dispatch(SET_UPGRADED_PLAN_COMPLETED(currentPlan?.subscription_type === SubscriptionTypeEnum.PREMIUMPATIENT));
  }, [currentPlan]);

  useEffect(() => {
    dispatch(SET_FAMILY_MEMBERS_COMPLETED(!!dependants?.length));
  }, [dependants]);

  useEffect(() => {
    dispatch(SET_INSURANCE_COMPLETED(!!insuranceDetails?.bank_iban));
  }, [insuranceDetails]);

  usePageFocus(() => {
    if (userDetails?.account?.id) {
      fetchData();
    }
  }, [userDetails?.account?.id]);

  return (
    <ProfileCompletionContext.Provider value={{ ...completionState, loading }}>
      {children}
    </ProfileCompletionContext.Provider>
  );
};
