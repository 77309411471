import { useEffect, useState } from 'react';

import { apiGetSickLeaveCertificateMedia } from './../../services/consultations/sickLeave';

import { MediaStatusEnum } from '~/api/models/common/constants/MediaStatusEnum';
import { SickLeaveCertificatesMediaModel } from '~/api/models/common/models/SickLeaveCertificatesMediaModel';
import { SickLeaveCertificateModel } from '~/api/models/consultations/models/SickLeaveCertificateModel';
import { AddSickLeaveCertificateRequest } from '~/api/models/consultations/requests/AddSickLeaveCertificateRequest';
import { DeleteSickLeaveCertificateRequest } from '~/api/models/consultations/requests/DeleteSickLeaveCertificateRequest';
import {
  apiAddSickLeaveCertificate,
  apiDeleteSickLeaveCertificate,
  apiGetSickLeaveCertificates,
} from '~/api/services/consultations/sickLeave';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { isDoctorVersion } from '~/utils/buildConfig';

interface Props {
  consultationId: number;
  editing: boolean;
}
interface SickLeaveData {
  [type: string]: SickLeaveCertificateModel[];
}

export function useSickLeave({ consultationId, editing }: Props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<SickLeaveData>({});
  const [generatedFiles, setGeneratedFiles] = useState<SickLeaveCertificatesMediaModel[]>();
  const [generatedFilesCommonStatus, setGeneratedFilesCommonStatus] = useState<MediaStatusEnum>();

  const getCertificates = () => {
    if (isDoctorVersion())
      return apiGetSickLeaveCertificates({ filter: { consultation_id: consultationId } }).then((res) => {
        const tempData = res.data.reduce<SickLeaveData>((previous, current) => {
          if (!previous[current.type]) previous[current.type] = [];
          previous[current.type].push(current);
          return previous;
        }, {});
        setData(tempData);
      });
  };

  const getSickLeaveCertificatesMedia = async () => {
    await apiGetSickLeaveCertificateMedia({ id: consultationId })
      .then((res) => {
        setGeneratedFiles(res.data);
        if (
          res.data?.find(
            (item) => item?.status === MediaStatusEnum.PROCESSING || item?.status === MediaStatusEnum.PENDING
          )
        ) {
          setGeneratedFilesCommonStatus(MediaStatusEnum.PROCESSING);
        } else if (res.data?.find((item) => item?.status === null)) {
          setGeneratedFilesCommonStatus(null);
        } else {
          setGeneratedFilesCommonStatus(MediaStatusEnum.COMPLETED);
        }
      })
      .catch(ErrorAlert);
  };

  const getSickLeaveData = async () => {
    setLoading(true);
    const sickLeaveCertificates = getCertificates();
    const sickLeaveCertificatesMedia = !editing ? getSickLeaveCertificatesMedia() : Promise.resolve(true);
    await Promise.all([sickLeaveCertificates, sickLeaveCertificatesMedia]).then(() => setLoading(false));
  };

  useEffect(() => {
    if (consultationId) {
      getSickLeaveData();
    }
  }, [consultationId]);

  const addSickCertificate = (params: AddSickLeaveCertificateRequest) => {
    return apiAddSickLeaveCertificate(params).then(() => {
      getCertificates();
    });
  };

  const removeSickCertificate = (params: DeleteSickLeaveCertificateRequest) => {
    return apiDeleteSickLeaveCertificate(params).then(() => {
      getCertificates();
    });
  };

  return {
    data,
    addSickCertificate,
    removeSickCertificate,
    loading,
    getSickLeaveCertificatesMedia,
    generatedFiles,
    generatedFilesCommonStatus,
  };
}
