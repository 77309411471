import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { NotificationModel } from '~/api/models/notifications/models/NotificationModel';
import {
  apiGetNotifications,
  apiMarkAllNotificationsAsRead,
  apiMarkNotificationAsRead,
} from '~/api/services/notifications';
import { SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import PushNotifications from '~/integrations/pushNotifications/PushNotifications';
import { RootState } from '~/redux/reducers';
import { SET_NOTIFICATIONS } from '~/redux/reducers/requestsReducer';
import { HAS_UNREAD_NOTIFICATIONS } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { currentDateTime } from '~/utils/dateAndTime';
import { usePageFocus, usePageFocusState } from '~/utils/hooks/FocusHook';

export function useNotifications() {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const notifications = useSelector((state: RootState) => state.requestsReducer.notifications);

  const getNotifications = async (markAsRead?: boolean) => {
    setLoading(true);
    try {
      const response = await apiGetNotifications({ limit: 50 });
      const data = response.data.data;
      if (markAsRead) {
        const now = currentDateTime();
        data.forEach((item) => (item.read_at = item.read_at || now));
        dispatch(HAS_UNREAD_NOTIFICATIONS(false));
      } else {
        dispatch(HAS_UNREAD_NOTIFICATIONS(data.some((item) => !item.read_at)));
      }
      dispatch(SET_NOTIFICATIONS(data));
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const markAllNotificationsAsRead = async (options?: { hideAlert?: boolean }) => {
    try {
      await apiMarkAllNotificationsAsRead();
      await getNotifications(true);
      if (!options?.hideAlert) SuccessAlert(['All notifications marked as read']);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const markNotificationAsRead = async (notification: NotificationModel) => {
    try {
      await apiMarkNotificationAsRead({ id: notification.id });

      await getNotifications();
    } catch (e) {
      ErrorAlert(e);
    }
  };

  return { notifications, loading, getNotifications, markAllNotificationsAsRead, markNotificationAsRead };
}

export function useNotificationsWithLoad() {
  const { notifications, getNotifications, loading, markAllNotificationsAsRead, markNotificationAsRead } =
    useNotifications();

  useEffect(() => {
    const unsubscribe = PushNotifications.addNotificationListener(() => getNotifications());
    return unsubscribe;
  }, []);

  usePageFocus(() => {
    getNotifications();
  });
  return { notifications, loading, markAllNotificationsAsRead, markNotificationAsRead };
}

export function useNotificationsAutoMarkAsRead() {
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const { markAllNotificationsAsRead } = useNotifications();

  usePageFocusState((focused) => {
    if (focused) {
      timer.current = setTimeout(() => {
        markAllNotificationsAsRead({ hideAlert: true });
        timer.current = null;
      }, 30000);
    } else if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  });
}
