import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FaqFilter } from '~/api/models/faq/filters/FaqFilter';
import { apiGetFaq } from '~/api/services/faq';
import { RootState } from '~/redux/reducers';
import { SET_FAQS } from '~/redux/reducers/helpReducer';
import { useAppDispatch } from '~/redux/store';

interface Props {
  taxonomySlug?: string;
  search?: string;
}
export function useFaq({ taxonomySlug, search }: Props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const faqs = useSelector((state: RootState) => state.helpReducer.faqs?.data);

  useEffect(() => {
    if (!search && !taxonomySlug) return;

    const filter: FaqFilter = {};
    if (search) {
      filter.question = search;
    } else {
      filter.taxon_slug = taxonomySlug;
    }
    setLoading(true);
    apiGetFaq({ filter })
      .then((res) => {
        dispatch(SET_FAQS(res.data));
      })
      .finally(() => setLoading(false));
  }, [search, taxonomySlug]);

  return { loading, faqs };
}
