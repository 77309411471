import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useMemo } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, ScrollView, KeyboardAvoidingView, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { flex1, mt35, mv5 } from '../common/commonStyles';
import { ProgressBars } from '../components/commonComponents';
import {
  H1TtmSemiBoldBlack,
  H2TtmSemiBoldBlack,
  H3TtmSemiBoldBlack,
  H6NsRegularSecondaryBlack,
} from '../components/commonText';
import { colors } from '../utils/colors';

import { useRegistrationProgress } from '~/api/hooks/RegistrationSteps';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { INavigate } from '~/common/types/navigation/navigate';
import { Gradient } from '~/components/common/Gradient';
import { BackNavigation } from '~/components/navigation/BackNavigation';
import { AppLogoWithText } from '~/components/svgImages';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  hideRequired?: boolean;
  hideProgress?: boolean;
  title?: string | React.ReactNode;
  showAppLogo?: boolean;
  children?: React.ReactNode;
  hideSubtitle?: boolean;
  subtitle?: string | React.ReactNode;
  buttons?: React.ReactNode;
  backTitle?: string;
  backLink?: INavigate;
  childrenMarginTop?: number;
  notScrollable?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  smallTitle?: boolean;
  noPadding?: boolean;
  noHorizontalPadding?: boolean;
  noHorizontalContentPadding?: boolean;
  noVerticalPadding?: boolean;
  compressed?: boolean;
}
const RegistrationLayout: React.FC<Props> = (props) => {
  const { isMobile } = useBreakpoints();
  const { progress } = useRegistrationProgress();
  const navigation = useNavigation();
  const { bottom } = useSafeAreaInsets();

  const subtitle =
    typeof props.subtitle === 'string' ? (
      <H6NsRegularSecondaryBlack>{props.subtitle}</H6NsRegularSecondaryBlack>
    ) : (
      props.subtitle
    );

  const hideProgress = props.hideProgress || !isMobile;

  const allowedGoBack = useMemo(() => {
    return !!props.backLink?.screen || navigation.canGoBack();
  }, [props.backLink, navigation]);

  const contentRef = React.useRef<View>(null);
  // Calculate the height of the content inside the scrollable view

  const content = (
    <View ref={contentRef} style={{ flex: 1, width: '100%' }}>
      <View style={{ marginTop: props.childrenMarginTop ?? 40, alignItems: 'center', flex: 1 }}>{props.children}</View>
    </View>
  );

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 90 : props.compressed ? 0 : 75}
        style={[
          styles.subContainer,
          {
            justifyContent: props.compressed ? 'center' : 'space-between',
            marginBottom: props.noPadding || props.noVerticalPadding ? 0 : bottom || 10,
          },
        ]}>
        <View
          style={{
            paddingTop: hideProgress ? 20 : 0,
            paddingHorizontal: props.noPadding || props.noHorizontalPadding ? 0 : MOBILE_HORIZONTAL_SPACE,
          }}>
          {hideProgress ? null : <ProgressBars progress={progress} />}

          {props.backLink && allowedGoBack ? (
            <BackNavigation title={props.backTitle} backLink={props.backLink} />
          ) : null}

          {props.showAppLogo ? (
            <View style={[mv5, { alignItems: 'center' }]}>
              <AppLogoWithText height={80} width={200} />
            </View>
          ) : null}

          <View>
            {/* <View style={{ paddingHorizontal: isMobile ? MOBILE_HORIZONTAL_SPACE : 0 }}> */}
            {typeof props.title === 'string' ? (
              props.smallTitle ? (
                <H3TtmSemiBoldBlack>
                  {props.title}
                  {props.hideRequired ? null : (
                    <H3TtmSemiBoldBlack style={{ color: colors.danger }}>*</H3TtmSemiBoldBlack>
                  )}
                </H3TtmSemiBoldBlack>
              ) : (
                <H1TtmSemiBoldBlack>
                  {props.title}
                  {props.hideRequired ? null : (
                    <H2TtmSemiBoldBlack style={{ color: colors.danger }}>*</H2TtmSemiBoldBlack>
                  )}
                </H1TtmSemiBoldBlack>
              )
            ) : (
              <>{props.title}</>
            )}
          </View>

          {props.hideSubtitle ? null : <View style={[styles.subtitleContainer]}>{subtitle || null}</View>}
        </View>
        <View
          style={[
            props.compressed ? null : flex1,
            props.notScrollable && !(props.noHorizontalContentPadding || props.noPadding)
              ? { paddingHorizontal: MOBILE_HORIZONTAL_SPACE }
              : null,
          ]}>
          {!props.notScrollable ? (
            <ScrollView
              contentContainerStyle={[
                { width: '100%', paddingBottom: 20 },
                props.containerStyle,
                {
                  paddingHorizontal: props.noHorizontalContentPadding || props.noPadding ? 0 : MOBILE_HORIZONTAL_SPACE,
                },
              ]}>
              {content}
            </ScrollView>
          ) : (
            content
          )}
          {!props.notScrollable ? <Gradient /> : null}
        </View>
        {props.buttons ? <View style={[styles.buttons, props.compressed ? mt35 : null]}>{props.buttons}</View> : null}
      </KeyboardAvoidingView>
    </View>
  );
};

export default RegistrationLayout;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  subContainer: {
    maxHeight: 1200,
    backgroundColor: colors.white,
    flex: 1,
  },
  subtitleContainer: {
    marginTop: 5,
    minHeight: 40,
  },
  buttons: {
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
  },
});
