import { useState } from 'react';

import { PrivateNoteModel } from '~/api/models/consultations/models/PrivateNoteModel';
import {
  apiGetPrivateNote,
  apiUpdatePrivateNote,
  apiCreatePrivateNote,
  apiDeletePrivateNote,
} from '~/api/services/consultations/privateNotes';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { ErrorAlert } from '~/components/modals/ErrorAlert';

interface Props {
  consultationId: number;
}
export function usePrivateNote({ consultationId }: Props) {
  const [loading, setLoading] = useState(false);
  const [saving, setSavingLocally] = useState(false);
  const [error, setError] = useState<ErrorResponse>();
  const [data, setData] = useState<PrivateNoteModel>();

  const setSaving = (saving: boolean, error?: ErrorResponse) => {
    setSavingLocally(saving);
    if (!saving) setError(error);
  };

  const getPrivateNote = async () => {
    try {
      setLoading(true);
      const res = await apiGetPrivateNote({ consultation_Id: consultationId });
      setData(res.data);
      setLoading(false);
      return res;
    } catch {
      setData(null);
      setLoading(false);
    }
  };

  const deletePrivateNote = (id: number) => {
    if (id) {
      return apiDeletePrivateNote({ id })
        .catch(ErrorAlert)
        .then(() => setData(null));
    } else {
      return Promise.reject(new Error('No current private note to delete'));
    }
  };

  const setPrivateNote = async (formData: PrivateNoteModel) => {
    try {
      setSaving(true);
      if (formData.id) {
        if (!formData.note) {
          await deletePrivateNote(formData.id).then(() => {
            setData({} as PrivateNoteModel);
          });
          setSaving(false);
        } else {
          const res = await apiUpdatePrivateNote({
            id: formData.id,
            params: { note: formData.note, consultation_id: consultationId },
          });
          setSaving(false);
          return res;
        }
      } else if (formData.note.length > 0) {
        const res = await apiCreatePrivateNote({ note: formData.note, consultation_id: consultationId });
        setSaving(false);
        return res;
      }
    } catch (e) {
      setSaving(false, e);
      throw e;
    }
  };

  return { data, loading, saving, error, setPrivateNote, getPrivateNote };
}
