export function getDotObject<T>(obj: any, path: string): T {
  const splitPath = path.split('.');

  return splitPath.reduce((prev, key) => {
    if (key in prev) {
      prev = prev[key];
    }
    return prev;
  }, obj);
}
