import React from 'react';
import { TouchableOpacity, StyleSheet, StyleProp, ViewStyle, View } from 'react-native';

import { H6NsRegularSecondaryBlack } from '../commonText';
import { CardSurface } from '../commonViews';

import { alignItemsCenter, justifyCenter, justifyStart } from '~/common/commonStyles';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  selected?: boolean;
  children?: React.ReactNode;
  label?: string;
  icon?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  cardStyle?: StyleProp<ViewStyle>;
  elevated?: boolean;
  center?: boolean;
  centerVertical?: boolean;
  disabled?: boolean;
  onPress?: () => void;
}

export const ElevatedCard: React.FC<Props> = ({
  selected,
  children,
  label,
  icon,
  style,
  cardStyle,
  elevated,
  center,
  centerVertical,
  disabled,
  onPress,
}) => {
  const { isMobile } = useBreakpoints();
  const content = (
    <CardSurface
      elevation={elevated ? (disabled ? 3 : 4) : 0}
      style={[
        { display: 'flex', flexDirection: 'row', padding: 10, minHeight: isMobile ? 60 : null },
        center ? justifyCenter : justifyStart,
        center || centerVertical ? alignItemsCenter : undefined,
        selected
          ? [styles.selected, elevated ? styles.cardPurple : styles.cardGrey]
          : [styles.card, elevated ? null : styles.cardPurple],
        disabled ? { opacity: 0.5 } : null,
        cardStyle,
      ]}>
      {children ?? (
        <>
          {icon}
          <H6NsRegularSecondaryBlack style={{ marginLeft: icon ? 10 : 0, flex: 1 }}>{label}</H6NsRegularSecondaryBlack>
        </>
      )}
    </CardSurface>
  );
  if (!onPress) return <View style={style} children={content} />;
  return <TouchableOpacity onPress={onPress} disabled={disabled || !onPress} style={style} children={content} />;
};

const styles = StyleSheet.create({
  card: {
    borderColor: colors.transparent,
    borderWidth: 1,
  },
  cardPurple: {
    backgroundColor: colors.lightPurple,
  },
  cardGrey: {
    backgroundColor: colors.lightGrey,
  },
  selected: {
    shadowColor: colors.info,
    borderWidth: 1,
    borderColor: colors.info,
  },
});
