import React from 'react';

import { mb15, mt10 } from '~/common/commonStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import { Container } from '~/theme/components/grid';
import { appName } from '~/utils/buildConfig';

interface Props {
  exportText?: boolean;
  isGuest?: boolean;
}

export const PharmacyExportAccountDataComponent: React.FC<Props> = ({ isGuest }) => {
  return (
    <Container alignSelf="flex-start" style={mb15}>
      <H6NsRegularBlack>
        By permanently deleting your account you will no longer have access as a Pharmacist within {appName}. You will
        also no longer have access to any of the Pharmacies that you are linked to, along with their data.
      </H6NsRegularBlack>
      <H6NsRegularBlack style={mt10}>
        If no other member is actively linked to any of the pharmacies you are linked to, those pharmacies and their
        data will be permanently deleted within 30 days.
      </H6NsRegularBlack>
      {isGuest ? (
        <H6NsRegularBlack style={mt10}>
          Should you wish to proceed with the deletion, login to your account.
        </H6NsRegularBlack>
      ) : (
        <H6NsRegularBlack style={mt10}>
          Should you wish to proceed with the deletion, press the Continue button below.
        </H6NsRegularBlack>
      )}
    </Container>
  );
};
