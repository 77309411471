import { useEffect, useState } from 'react';

import { PageTypesEnum } from '../models/pages/constants/PageTypesEnum';
import { apiGetPage } from '../services/pages';

export function useGetHelpContent(type: PageTypesEnum) {
  const [content, setContent] = useState('');

  useEffect(() => {
    getContent();
  }, [type]);

  const getContent = async () => {
    if (!type) return;
    await apiGetPage({ slug: type })
      .then((response) => {
        setContent(response.data.content);
      })
      .catch(() => {});
  };

  return content;
}
