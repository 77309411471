import React, { useImperativeHandle, useRef, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { BiometryType } from 'react-native-biometrics';
import { ActivityIndicator } from 'react-native-paper';

import { DotComponent } from './DotComponent';
import { PasscodeInputMobile } from './PasscodeInputMobile';
import { PasscodeInputWeb, PasscodeInputWebRef } from './PasscodeInputWeb';
import { FormErrorMessage } from '../commonText';

import { isWeb } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { textCenter } from '~/common/commonStyles';

export interface PasscodeInputRef {
  clear: () => void;
}
interface Props {
  hasTouchFaceId?: boolean;
  biometricType?: BiometryType;
  autoTrigger?: boolean;
  creating?: boolean;
  loginWithTouchId?: () => void;
  onEnter: (code: string) => void;
  onChange?: (code: string) => void;
  style?: StyleProp<ViewStyle>;
  editable?: boolean;
  autoFocus?: boolean;
  forceWeb?: boolean;
  errorMessage?: string;
  loading?: boolean;
}

export const PassCodeInputComponent = React.forwardRef<PasscodeInputRef, Props>(
  (
    {
      hasTouchFaceId,
      biometricType,
      autoTrigger,
      creating,
      onEnter,
      onChange,
      loginWithTouchId,
      editable = true,
      style,
      autoFocus = true,
      forceWeb,
      errorMessage,
      loading,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      clear: () => {
        setPasscode('');
        webInput.current?.clearAll();
      },
    }));

    const webInput = useRef<PasscodeInputWebRef>();

    const [passcode, setPasscode] = useState('');

    const setValue = (code: string) => {
      setPasscode(code);
      if (onChange) onChange(code);
      if (code.length === 4 && autoTrigger) onEnter(code);
    };

    const onMobileButtonPressed = (value: string) => {
      const newPasscode = passcode + value;
      setValue(newPasscode);
    };

    const enterPressed = () => {
      onEnter(passcode);
    };

    return forceWeb || isWeb() ? (
      <View style={[style, { width: '100%' }]}>
        <PasscodeInputWeb
          ref={webInput}
          value={passcode}
          onChange={setValue}
          onEnter={enterPressed}
          autofocus={autoFocus}
          editable={editable && !loading}
          errorMessage={errorMessage}
          loading={loading}
        />
      </View>
    ) : (
      <View style={{ flexGrow: 1, justifyContent: 'space-between' }}>
        <View>
          <View style={{ alignItems: 'center' }}>
            <DotComponent passcode={passcode} />
          </View>
          <FormErrorMessage style={textCenter}>{errorMessage || ' '}</FormErrorMessage>
          {loading ? (
            <View style={[styles.loadingContainer, { bottom: 16 }]}>
              <ActivityIndicator />
            </View>
          ) : null}
        </View>

        <PasscodeInputMobile
          showCreatePassCode={creating}
          biometricType={biometricType}
          passcode={passcode}
          touchFaceID={hasTouchFaceId && !!loginWithTouchId}
          setPasscode={setValue}
          loginWithTouchID={loginWithTouchId}
          onButtonPressed={onMobileButtonPressed}
          onEnter={enterPressed}
          editable={editable && !loading}
        />
        <View />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `${colors.white}aa`,
  },
});
