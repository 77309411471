import React, { useEffect, useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useSelector } from 'react-redux';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { useDependantsHook } from '~/api/hooks/dependants/DependantsHook';
import { useFavouriteDoctors } from '~/api/hooks/favourites/FavouriteDoctorsHook';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { DependantStateEnum } from '~/api/models/dependants/constants/DependantStateEnum';
import { apiAddFavouriteDoctors, apiRemoveFavouriteDoctor } from '~/api/services/favouriteDoctors';
import { ConfirmationAlert } from '~/common/commonMethods';
import { IconButton } from '~/components/buttons/IconButton';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { HeartIcon } from '~/components/svgImages';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { RootState } from '~/redux/reducers';
import { favouriteDoctorsLoading } from '~/redux/reducers/patient/doctorListingReducer';
import { colors } from '~/utils/colors';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props {
  patientId?: number;
  doctor: AccountModel;
  style?: StyleProp<ViewStyle>;
}

export const DoctorFavouriteButton: React.FC<Props> = ({ patientId, doctor, style }) => {
  const { userDetails } = useUserDetails({ allowStale: true });
  const [loading, setLoading] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const { updateFavouriteDoctors } = useFavouriteDoctors();
  const { dependants } = useDependantsHook({ state: DependantStateEnum.Approved, allowStale: true });

  const favouriteLoading = useSelector(favouriteDoctorsLoading);
  const favouriteDoctor = useSelector((state: RootState) =>
    state.doctorListingReducer.favourites.data?.find(
      (item) => item.doctor_profile.id === doctor.id && (!patientId || item.favourite_by.includes(patientId))
    )
  );

  const { openModal } = useModalManager();

  useEffect(() => {
    setIsFavourite(!!favouriteDoctor);
  }, [favouriteDoctor]);

  const toggleFavourite = async (patientId: number) => {
    try {
      setLoading(true);
      if (isFavourite) {
        await apiRemoveFavouriteDoctor({
          patient_id: patientId,
          doctor_id: doctor.id,
        });

        setIsFavourite(false);
        await updateFavouriteDoctors();
      } else {
        const patientReference = patientId === userDetails?.account.id ? 'your' : "your dependant's";
        const doctorName = getAccountName(doctor);
        const add = await new Promise((resolve) => {
          ConfirmationAlert(
            [
              `You are about to add ${doctorName} as one of ${patientReference} favourite Digimed physicians.`,
              `This will give them full access to ${patientReference} health profile and records. Should you wish to revoke this access, you can unfavourite them at any time.`,
              ' ',
              `Are you sure you want to favourite ${doctorName}?`,
            ],
            {
              title: 'Add as favourite',
              okTitle: 'Add to favourites',
              okFunction: () => resolve(true),
              cancelFunction: () => resolve(false),
            }
          );
        });

        if (add) {
          await apiAddFavouriteDoctors({
            patient_id: patientId,
            doctor_id: doctor.id,
          });
          setIsFavourite(true);
          await updateFavouriteDoctors();
        }
      }
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const toggleFavouritesModal = () => {
    openModal(ModalName.DoctorFavouriteModal, {
      doctor: {
        doctor_profile: doctor,
        favourite_by: favouriteDoctor?.favourite_by ?? [],
      },
      onHide: updateFavouriteDoctors,
    });
  };

  const button = (onPress: () => void) => (
    <IconButton onPress={onPress} disabled={loading} style={style}>
      {loading || favouriteLoading ? (
        <ActivityIndicator color={colors.purple} size={18} />
      ) : (
        <HeartIcon size={18} fillColor={isFavourite ? colors.purple : colors.transparent} strokeColor={colors.purple} />
      )}
    </IconButton>
  );

  if (dependants?.length && !patientId) {
    return button(() => toggleFavouritesModal());
  } else {
    return button(() => toggleFavourite(patientId ?? userDetails?.account.id));
  }
};
