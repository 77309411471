import { Route } from '@react-navigation/native';
import React from 'react';

import { useDependant } from '~/api/hooks/dependants/DependantHook';
import { AccountAddressManagementComponent } from '~/components/profile/AccountAddressManagementComponent';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

interface Props {
  route: Route<string, { id: string }>;
}

export const DependantAddressScreen: React.FC<Props> = ({ route }) => {
  const { dependant } = useDependant({ id: route.params?.id, allowStale: true });

  return (
    <AccountAddressManagementComponent
      account={dependant?.account}
      backLink={{ screen: screenName.DependantsShow, params: route.params }}
      backTitle={labels.back}
    />
  );
};
