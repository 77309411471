import React from 'react';
import { View, StyleSheet } from 'react-native';

import OnboardingScreen from '../components/onBoarding/OnBoardingViewComponent';
import { RegistrationStepperComponent } from '../components/registration/RegistrationStepperComponent';
import { colors } from '../utils/colors';

import { useStoredRoute } from '~/redux/hooks/StoredRoute';
import { isClinicVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

const HIDE_REGISTRATION_STEPS = [
  screenName.LoginScreen,
  screenName.PassCodeLoginScreen,
  screenName.ForgotPasscodeScreen,
  screenName.OTPScreen_2FA,
  screenName.RegisterScreen,
  screenName.RegistrationMobileOtp,
  screenName.PassCodeRegisterScreen,
  screenName.RegisterTerms,
  screenName.EmailScreen,
  screenName.RegistrationEmailOtp,
  screenName.ForgotPasscodeOtp,
  screenName.TwoStepSelection,
  screenName.UploadIdentificationScreen,
  screenName.PermanentlyDeleteAccount,
];
interface Props {
  children?: React.ReactNode;
}

export const OnBoardingLayout: React.FC<Props> = ({ children }) => {
  const { route } = useStoredRoute();
  const { isMobile } = useBreakpoints();

  if (isMobile) return <>{children}</>;
  return (
    <View style={styles.container}>
      {route?.name === screenName.Action || isClinicVersion() ? null : (
        <View style={styles.sidePanel}>
          {!route?.name || HIDE_REGISTRATION_STEPS.includes(route.name) ? (
            <OnboardingScreen />
          ) : (
            <RegistrationStepperComponent />
          )}
        </View>
      )}
      <View style={[styles.mainPanel]}>
        <View
          style={[
            styles.mainPanelContent,
            route?.name === screenName.RegisterTerms || (isClinicVersion() && route?.name === screenName.RegisterScreen)
              ? null
              : styles.mainPanelWidth,
          ]}>
          {children}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  sidePanel: {
    flex: 1,
    display: 'flex',
    maxWidth: 442,
    flexDirection: 'column',
  },
  mainPanel: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
  mainPanelContent: {
    flex: 1,
    width: '100%',
    maxHeight: 900,
  },
  mainPanelWidth: {
    maxWidth: 430,
  },
});
