import React, { useEffect, useMemo, useRef } from 'react';
import { View } from 'react-native';

import { usePaymentCards } from '~/api/hooks/cards/PaymentCardsHook';
import { mt10 } from '~/common/commonStyles';
import { LinkButton } from '~/components/buttons/LinkButton';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { StripePaymentMethodRef } from '~/integrations/stripe/interface';
import { StripePaymentMethod } from '~/integrations/stripe/paymentMethod';
import { StripePaymentMethodTypeEnum } from '~/integrations/stripe/paymentMethod/constants/StripePaymentMethodTypeEnum';
import { formatPaymentCard } from '~/utils/paymentMethodUtil';

interface Props {
  selected: string;
  setSelected: (selected: string) => void;
  error?: string;
}
export const ConsultationBillingCardSelection: React.FC<Props> = ({ selected, setSelected, error }) => {
  const paymentMethod = useRef<StripePaymentMethodRef>();
  const { loading, cardInfo, addCard } = usePaymentCards({ paymentMethodRef: paymentMethod });

  const cardDropdownOptions = useMemo(
    () => cardInfo?.map((card) => ({ value: card.id, label: formatPaymentCard(card.card) })) ?? [],
    [cardInfo]
  );

  useEffect(() => {
    if (cardInfo?.length) setSelected(cardInfo[0].id);
  }, [cardInfo]);

  return (
    <View>
      <Dropdown
        list={cardDropdownOptions}
        value={selected}
        setValue={(v) => setSelected(v as string)}
        disabled={loading}
        label="Payment card"
        error={!!error}
        errorMessage={error}
      />
      <LinkButton style={mt10} onPress={addCard} title="Add a new card" disabled={loading} />
      <StripePaymentMethod ref={paymentMethod} type={StripePaymentMethodTypeEnum.SETUP} title="Add a new card" />
    </View>
  );
};
