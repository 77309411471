import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { pb20 } from '~/common/commonStyles';
import { OutlineButton } from '~/components/commonButton';
import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useNavigationHealthProfile } from '~/utils/hooks/AppNavigationHook';
import { useAppointmentState } from '~/utils/hooks/appointments/AppointmentStateHook';
import { labels } from '~/utils/labels';

interface Props {
  consultation: ConsultationModel;
}

export const ConsultationScheduleAppScheduled: React.FC<Props> = ({ consultation }) => {
  const { navigate: navigateHealthProfile } = useNavigationHealthProfile();
  const { loading, cancelAppointment } = useAppointmentContext();
  const { notStarted } = useAppointmentState(consultation);

  return (
    <Column>
      <View style={styles.headers}>
        <H3TtmSemiBoldBlack>Health Profile</H3TtmSemiBoldBlack>
      </View>
      <View style={{ display: 'flex', marginBottom: 30 }}>
        <OutlineButton funCallback={() => navigateHealthProfile(consultation)} label="View health profile" />
      </View>

      <View style={styles.timer}>
        <H6NsRegularBlack>
          It's still early for your appointment, try again about 15 minutes before your call so you can join.
        </H6NsRegularBlack>
      </View>
      {notStarted ? (
        <View style={pb20}>
          <OutlineButton danger funCallback={cancelAppointment} label={labels.cancelAppointment} disabled={loading} />
        </View>
      ) : null}
    </Column>
  );
};

const styles = StyleSheet.create({
  headers: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timer: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: colors.lightPurple2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 60,
    marginBottom: 40,
  },
});
