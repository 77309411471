import { StackActionType } from '@react-navigation/native';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { H6NsRegularBlack } from './commonText';
import {
  ButtonText,
  CommonTextButton,
  CommonButton,
  TextButtonText,
  CommonOutlineButton,
  OutlineButtonText,
  CommonSmallButton,
  CommonButtonLink,
  CommonShadowButton,
  CommonOutlineButtonLink,
} from './commonViews';
import { colors } from '../utils/colors';

type ButtonProps = {
  disabled?: boolean;
  funCallback: CallableFunction;
  label: string;
  style?: StyleProp<ViewStyle>;
  success?: boolean;
};

export const Button: React.FC<ButtonProps> = ({ style, disabled, funCallback, label, success }) => {
  return (
    <CommonButton
      disabled={disabled}
      style={[
        style,
        success ? { backgroundColor: colors.success } : null,
        disabled ? { backgroundColor: colors.purpleGrey } : null,
      ]}
      onPress={() => {
        funCallback();
      }}>
      <ButtonText>{label}</ButtonText>
    </CommonButton>
  );
};

export const SmallButton: React.FC<ButtonProps> = ({ style, disabled, funCallback, label }) => {
  return (
    <CommonSmallButton
      disabled={disabled}
      style={[style, disabled ? { backgroundColor: colors.purpleGrey } : null]}
      onPress={() => {
        funCallback();
      }}>
      <ButtonText>{label}</ButtonText>
    </CommonSmallButton>
  );
};

type TextButtonProps = {
  disabled?: boolean;
  funCallback: CallableFunction;
  label?: string;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
};

export const TextButton: React.FC<TextButtonProps> = (props) => {
  return (
    <CommonTextButton
      style={props.style}
      disabled={props.disabled}
      onPress={() => {
        props.funCallback();
      }}>
      {props.children ? (
        props.children
      ) : (
        <TextButtonText style={[props.labelStyle, props.disabled ? { color: colors.darkGrey } : null]}>
          {props.label}
        </TextButtonText>
      )}
    </CommonTextButton>
  );
};

type OutlineButtonProps = {
  disabled?: boolean;
  funCallback: CallableFunction;
  label?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  danger?: boolean;
  outline?: boolean;
};
// create a component
export const OutlineButton: React.FC<OutlineButtonProps> = (props) => {
  return (
    <CommonOutlineButton
      style={[
        props.danger ? { borderColor: colors.danger } : null,
        props.style,
        props.disabled ? { opacity: 0.5 } : null,
        props.outline ? { borderColor: 'transparent' } : null,
      ]}
      disabled={props.disabled}
      onPress={() => {
        props.funCallback();
      }}>
      {props.icon ? <View style={{ marginRight: 10 }}>{props.icon}</View> : null}
      <OutlineButtonText style={[props.danger ? { color: colors.danger } : null]}>
        {props.label || props.children}
      </OutlineButtonText>
    </CommonOutlineButton>
  );
};

interface LinkProps {
  screenName?: string;
  to?: To<ReactNavigation.RootParamList>;
  label: string;
  style?: StyleProp<TextStyle>;
  action?: StackActionType;
}

export const ButtonLink: React.FC<LinkProps> = ({ style, label, screenName, action, to }) => {
  return (
    <CommonButtonLink style={[style]} to={to ?? { screen: screenName }} action={action}>
      <ButtonText>{label}</ButtonText>
    </CommonButtonLink>
  );
};

export const OutlineButtonLink: React.FC<
  { to?: To<ReactNavigation.RootParamList> } & Omit<OutlineButtonProps, 'funCallback'>
> = (props) => {
  return (
    <CommonOutlineButtonLink
      style={[
        props.danger ? { borderColor: colors.danger } : null,
        props.style,
        props.disabled ? { opacity: 0.5 } : null,
        props.outline ? { borderColor: 'transparent' } : null,
      ]}
      disabled={props.disabled}
      to={props.to}>
      {props.icon ? <View style={{ marginRight: 10 }}>{props.icon}</View> : null}
      <OutlineButtonText style={[props.danger ? { color: colors.danger } : null]}>
        {props.label || props.children}
      </OutlineButtonText>
    </CommonOutlineButtonLink>
  );
};

type ShadowButtonProps = {
  disabled?: boolean;
  onPress: CallableFunction;
  label: string;
  style?: StyleProp<ViewStyle>;
};
export const ShadowButton: React.FC<ShadowButtonProps> = (props) => {
  return (
    <CommonShadowButton
      style={props.style}
      disabled={props.disabled}
      onPress={() => {
        props.onPress();
      }}>
      <H6NsRegularBlack>{props.label}</H6NsRegularBlack>
    </CommonShadowButton>
  );
};
