import { LabelValuePair } from '~/common/types/LabelValuePair';

export enum DurationIntervalTypeEnum {
  DAYS = 'Days',
  WEEKS = 'Weeks',
  MONTHS = 'Months',
}

export const DurationIntervalTypeOptions: LabelValuePair<DurationIntervalTypeEnum>[] = [
  { label: 'Days', value: DurationIntervalTypeEnum.DAYS },
  { label: 'Weeks', value: DurationIntervalTypeEnum.WEEKS },
  { label: 'Months', value: DurationIntervalTypeEnum.MONTHS },
];
