import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { IconButton } from './IconButton';
import { ModalAlertTypeEnum } from '../modals/ModalEnums';
import { InfoCircle } from '../svgImages';

import { infoModal } from '~/providers/modal/ModalManagementContext';
import { colors } from '~/utils/colors';

interface Props {
  message: string;
  tooltip?: string;
  title?: string;
  style?: StyleProp<ViewStyle>;
}

export const InfoButton: React.FC<Props> = ({ message, tooltip, title, style }) => {
  const onPress = () => {
    infoModal({ message: [message], type: ModalAlertTypeEnum.NONE, title });
  };
  return (
    <IconButton transparent title={tooltip} onPress={onPress} style={style}>
      <InfoCircle width={14} height={14} color={colors.purple} />
    </IconButton>
  );
};
