import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import { mb10 } from '~/common/commonStyles';
import { H3TtmRegularBlack, H6NsRegularDisableColor } from '~/components/commonText';
import { FaqCategories } from '~/components/help/faq/FaqCategories';
import { FaqMobileModal } from '~/components/modals/FaqMobileModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { SearchBox } from '~/components/searchBox';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { RootState } from '~/redux/reducers';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { helpLabels } from '~/utils/labels/helpLabels';
import { screenName } from '~/utils/screenName';

export const HelpFaqMobilePage: React.FC = () => {
  const [localSearchText, setLocalSearchText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedTaxon, setSelectedTaxon] = useState<string>(undefined);
  const taxons = useSelector((state: RootState) => state.helpReducer.taxons?.data);

  const { registerModal, openModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.FaqMobileModal, FaqMobileModal);
  }, []);

  useEffect(() => {
    if (localSearchText === '' && localSearchText !== searchText) {
      setSearchText('');
    }
  }, [localSearchText]);

  useEffect(() => {}, [selectedTaxon]);

  const title = useMemo(() => {
    if (selectedTaxon) {
      const taxon = taxons.find((taxon) => taxon.slug === selectedTaxon);
      return taxon?.name ?? '';
    } else if (searchText) {
      return `Searching for ${searchText}`;
    }
  }, [selectedTaxon, searchText]);

  const hideModal = () => {
    setSelectedTaxon(undefined);
    setSearchText('');
  };

  const showFaqModal = () => {
    if (!selectedTaxon && !searchText) return;

    openModal(ModalName.FaqMobileModal, {
      selectedTaxon,
      searchText,
      title,
      onHide: hideModal,
    });
  };

  useEffect(() => {
    if (!!selectedTaxon || !!searchText) {
      showFaqModal();
    }
  }, [searchText, selectedTaxon]);

  return (
    <NestedPageInternalLayout
      backTitle={labels.help}
      backLink={{ screen: screenName.Help }}
      title={labels.faq}
      style={styles.faqContainer}>
      <View>
        <Row>
          <Column>
            <H3TtmRegularBlack>{helpLabels.howCanWeHelp}</H3TtmRegularBlack>
          </Column>
        </Row>
        <Row>
          <Column>
            <SearchBox
              value={localSearchText}
              onSubmit={() => {
                setSearchText(localSearchText);
              }}
              onTextChange={setLocalSearchText}
              placeholder={labels.search}
              bgColor={colors.white}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <View style={mb10}>
              <H6NsRegularDisableColor>{helpLabels.selectAnIssue}</H6NsRegularDisableColor>
            </View>
            <FaqCategories searching={!!searchText} onSelect={setSelectedTaxon} mobileView />
          </Column>
        </Row>
      </View>
    </NestedPageInternalLayout>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: colors.white,
  },
  faqContainer: {
    flex: 1,
    backgroundColor: colors.lightPurple,
  },
});
