import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ml5 } from '~/common/commonStyles';
import { SmallNsRegularBlack } from '~/components/commonText';
import { Person, Stethoscope } from '~/components/svgImages';
import { isPatientVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { useChannelMeans } from './hooks/useChannelMeans';

interface Props {
  consultation: ConsultationModel;
  joinedCall: boolean;
}

export const ChannelMeansMobile: React.FC<Props> = ({ consultation, joinedCall }) => {
  const { showTimer, timeOver, time } = useChannelMeans({ consultation, joinedCall });

  return (
    <View>
      {isPatientVersion() ? (
        <View style={styles.accountName}>
          <Stethoscope width={16} height={16} color={colors.white} />
          <SmallNsRegularBlack style={[ml5, { color: colors.white }]}>
            {getAccountName(consultation.doctor) || 'N/A'}
          </SmallNsRegularBlack>
        </View>
      ) : null}
      <View style={styles.accountName}>
        <Person width={16} height={16} color={colors.white} />
        <SmallNsRegularBlack style={[ml5, { color: colors.white }]}>
          {getAccountName(consultation.patient) || 'N/A'}
        </SmallNsRegularBlack>
      </View>
      {showTimer ? (
        <View style={styles.countdown}>
          <SmallNsRegularBlack style={[styles.time, timeOver ? { color: colors.danger } : null]}>
            {time}
          </SmallNsRegularBlack>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  accountName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  countdown: {
    marginBottom: 5,
  },
  time: {
    textAlign: 'left',
    color: colors.white,
  },
});
