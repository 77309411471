import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FlatList, View, TouchableOpacity, StyleSheet } from 'react-native';

import { mt10 } from '../../../common/commonStyles';
import { Button, TextButton } from '../../../components/commonButton';
import { CardSurface } from '../../../components/commonViews';
import { colors } from '../../../utils/colors';
import { labels } from '../../../utils/labels';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { useConsultationTypeListing } from '~/api/hooks/consultations/ConstulationTypeListingHook';
import {
  PreferredConsultationTypesForm,
  usePreferredConsultationTypeForm,
} from '~/api/hooks/consultations/PreferredConsultationTypeHook';
import { ConsultationTypeToIcon } from '~/api/models/common/constants/ConsultationTypeToIcon';
import { FormErrorMessage, H6NsRegularSecondaryBlack } from '~/components/commonText';
import { ConsultationTypeLearnMoreModal } from '~/components/modals/ConsultationTypeLearnMoreModal';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import RegistrationLayout from '~/layouts/RegistrationLayout';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { requiredValidation } from '~/services/validationConfig';

export const TypeOfConsultationScreen: React.FC = () => {
  const { registerModal, openModal } = useModalManager();
  const { consultationTypes } = useConsultationTypeListing();
  const { control, handleSubmit, updatePreferredConsultationTypes } = usePreferredConsultationTypeForm();
  const { updateRegistrationStatus } = useRegistrationSteps();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    registerModal(ModalName.ConsultationTypeLearnMoreModal, ConsultationTypeLearnMoreModal);
  }, []);

  const submit = async (data: PreferredConsultationTypesForm) => {
    setLoading(true);
    try {
      await updatePreferredConsultationTypes(data);
      await updateRegistrationStatus();
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const buttons = (
    <View>
      <Button label={labels.continue} funCallback={handleSubmit(submit)} disabled={loading} />
      <TextButton
        style={mt10}
        funCallback={() => {
          openModal(ModalName.ConsultationTypeLearnMoreModal);
        }}
        label={labels.learnMore.toUpperCase()}
        disabled={loading}
      />
    </View>
  );

  return (
    <RegistrationLayout
      title={labels.typeOfConsultationTitle}
      subtitle={labels.typeOfConsultationSubMessage}
      buttons={buttons}>
      <Controller
        control={control}
        name="preferredConsultationTypes"
        render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
          <FlatList
            style={{ marginTop: 10, width: '100%' }}
            data={consultationTypes ?? []}
            ListFooterComponent={
              isSubmitted && error ? (
                <View style={mt10}>
                  <FormErrorMessage>{error.message}</FormErrorMessage>
                </View>
              ) : null
            }
            renderItem={({ item }) => {
              return (
                <CardSurface
                  style={[
                    { paddingHorizontal: 0 },
                    value?.find((v) => v.type === item.type) ? styles.selected : styles.card,
                  ]}>
                  <TouchableOpacity
                    style={{ paddingHorizontal: 15, display: 'flex', flexDirection: 'row', width: '100%' }}
                    onPress={() => {
                      if (value?.find((v) => v.type === item.type)) {
                        const index = value.findIndex((v) => v.type === item.type);
                        const newArray = [...value];
                        if (index > -1) newArray.splice(index, 1);
                        onChange(newArray);
                      } else {
                        onChange([...value, { id: item.id, type: item.type }]);
                      }
                    }}>
                    {ConsultationTypeToIcon[item.type] || null}
                    <H6NsRegularSecondaryBlack style={{ marginLeft: 10 }}>{item.name}</H6NsRegularSecondaryBlack>
                  </TouchableOpacity>
                </CardSurface>
              );
            }}
          />
        )}
        rules={{
          required: requiredValidation('Consultation type'),
        }}
      />
    </RegistrationLayout>
  );
};

const styles = StyleSheet.create({
  card: {
    borderColor: colors.transparent,
    borderWidth: 1,
  },
  selected: {
    shadowColor: colors.info,
    backgroundColor: colors.lightPurple,
    borderWidth: 1,
    borderColor: colors.info,
  },
});
