import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { Button, TextButton } from '../commonButton';
import { H3TtmSemiBoldBlack, H6NsRegularBlack, SmallNsRegularDisableColor } from '../commonText';
import { AmountInput } from '../inputs/AmountInput';

import { ConsultationFeeTypeEnum } from '~/api/models/appointments/enums/ConsultationFeeTypeEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { mb20, mb30, mr10, textCenter } from '~/common/commonStyles';
import { priceValidation } from '~/services/validationConfig';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  consultation: ConsultationModel;
  onSubmit: (finalAmount: number) => Promise<void>;
}

export const EndConsultationModal: React.FC<Props> = ({ consultation, onHide, onSubmit }) => {
  const [waiting, setWaiting] = useState(false);
  const { handleSubmit, control } = useForm<{ finalAmount: number }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      finalAmount: undefined,
    },
  });

  const consultationFee = useMemo(() => {
    return consultation?.latest_transaction_price?.fees?.find(
      (fee) => fee.type === ConsultationFeeTypeEnum.CONSULTATION_FEE
    )?.price;
  }, [consultation]);

  const submit = async (data: { finalAmount: number }) => {
    try {
      setWaiting(true);
      let amount = Number(data.finalAmount);
      if (isNaN(amount)) amount = consultationFee.amount;
      if (onSubmit) await onSubmit(amount);
    } catch {}
    setWaiting(false);
    onHide();
  };
  const buttons = [
    <Button label={labels.continue} funCallback={handleSubmit(submit)} disabled={waiting} />,
    <TextButton label={labels.cancel} funCallback={onHide} disabled={waiting} />,
  ];

  return (
    <ModalContainer onHide={onHide} showInfoLine buttons={buttons}>
      <View>
        <H3TtmSemiBoldBlack style={[textCenter, mb20]}>
          Please confirm final fee for this consultation.
        </H3TtmSemiBoldBlack>
        <H6NsRegularBlack style={[textCenter, mb30]}>Amount cannot exceed your maximum fee.</H6NsRegularBlack>
        <View style={styles.paymentContainer}>
          {consultation?.channel ? (
            <View style={[styles.row, mb20]}>
              <H6NsRegularBlack>{consultation?.channel?.name}</H6NsRegularBlack>
            </View>
          ) : null}
          <View style={styles.row}>
            <SmallNsRegularDisableColor style={mr10}>Consultation fee:</SmallNsRegularDisableColor>
            <H6NsRegularBlack>{consultationFee?.formatted_amount}</H6NsRegularBlack>
          </View>
          <View style={styles.row}>
            <Controller
              name="finalAmount"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <AmountInput
                  placeholder={`Override ${consultationFee?.formatted_amount} fee`}
                  cents={value}
                  onChange={onChange}
                  error={isSubmitted && !!error}
                  errorMessage={isSubmitted && error?.message}
                  style={{ width: '100%' }}
                />
              )}
              rules={{
                validate: {
                  maximum: (value: number) => {
                    if (!value) return;
                    return value <= consultationFee.amount
                      ? undefined
                      : `Amount must be less than ${consultationFee?.formatted_amount}`;
                  },
                  minimum: (value: number) => {
                    if (!value) return;
                    return value >= 0 ? undefined : `Amount cannot be less than 0`;
                  },
                  priceValidation,
                },
              }}
            />
          </View>
        </View>
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  paymentContainer: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.info,
    padding: 15,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
