import React, { useEffect, useRef } from 'react';
import { useFieldArray, useFormState } from 'react-hook-form';

import { PrescriptionDataContext } from './PrescriptionDataContext';

import { usePrescriptions } from '~/api/hooks/consultations/PrescriptionsHook';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { PrescriptionFormModel } from '~/api/models/consultations/models/PrescriptionModel';
import {
  validationSchema,
  requiredValidation,
  maxLengthValidation,
  numberValidation,
} from '~/services/validationConfig';
import { clinicalTermToLabelValue } from '~/utils/clinicalTerms';
import { useAutoSaveForm } from '~/utils/hooks/AutoSaveFormHook';
import { useFormWithRules } from '~/utils/hooks/FormWithRulesHook';
import { labels } from '~/utils/labels';

const DEFAULT_PRESCRIPTION: PrescriptionFormModel = {
  product: undefined,
  consultation_id: undefined,
  frequency: undefined,
  dose: '',
  duration_interval_amount: undefined,
  duration_interval_type: undefined,
  route: '',
  other_comments: '',
  type: undefined,
  is_collectable: true,
};

interface Props {
  consultation: ConsultationModel;
  children: React.ReactNode;
}

export const PrescriptionDataProvider: React.FC<Props> = (props) => {
  const { consultation, children } = props;
  const prescriptionIndex = useRef(1);
  const {
    prescriptions: prescriptionData,
    updatePrescription,
    getPrescriptions,
    loading,
  } = usePrescriptions({ consultationId: consultation.id, sort: 'created_at' });

  const form = useFormWithRules<{
    prescriptions: PrescriptionFormModel[];
  }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      prescriptions: [],
    },
    rulesArray: {
      prescriptions: {
        product: {
          required: requiredValidation(labels.productName),
        },
        frequency: {},
        dose: {
          maxLength: maxLengthValidation(validationSchema.string.maxLength),
          required: requiredValidation('Dose'),
        },
        duration_interval_amount: {
          validate: { numberValidation },
          required: requiredValidation('Duration'),
        },
        duration_interval_type: {
          required: requiredValidation('Duration type'),
        },
        other_comments: {
          maxLength: maxLengthValidation(validationSchema.string.maxLength),
        },
        route: {
          maxLength: maxLengthValidation(validationSchema.string.maxLength),
          required: requiredValidation('Route'),
        },
        type: {
          required: requiredValidation('Prescription type'),
        },
      },
    },
  });

  const { dirtyFields } = useFormState({ control: form.control });

  const { fields, append, remove, replace } = useFieldArray<
    { prescriptions: PrescriptionFormModel[] },
    'prescriptions',
    'fieldId'
  >({
    control: form.control,
    name: 'prescriptions',
    keyName: 'fieldId',
  });

  const submit = async () => {
    const templateData = form.getValues();
    if (dirtyFields.prescriptions) {
      let index = 0;
      for (const prescription of templateData.prescriptions) {
        if (dirtyFields?.prescriptions[index]) {
          const res = await updatePrescription(prescription, prescription.id);
          if (typeof res !== 'boolean') prescription.id = res;
        }
        index++;
      }
    }

    replace(templateData.prescriptions);
    form.reset(templateData, { keepValues: true });
    // await getPrescriptions();
  };

  const { saving } = useAutoSaveForm({
    control: form.control,
    autoSave: submit,
  });

  useEffect(() => {
    if (prescriptionData?.length) {
      form.reset({
        prescriptions: prescriptionData.map((prescription) => ({
          ...prescription,
          product: clinicalTermToLabelValue<string | number>(prescription.product, { freeTextFallback: true }),
        })),
      });
      // replace(
      //   prescriptionData.map((prescription) => ({
      //     ...prescription,
      //     product: clinicalTermToLabelValue<string | number>(prescription.product, { freeTextFallback: true }),
      //   }))
      // );
    }
  }, [prescriptionData]);

  const addNew = () => {
    prescriptionIndex.current = prescriptionIndex.current + 1;
    append({
      ...DEFAULT_PRESCRIPTION,
      formId: `local_${prescriptionIndex.current}`,
      consultation_id: consultation.id,
    });
  };

  const removePrescription = async (index: number) => {
    const prescription = fields[index];
    if (prescription) await updatePrescription(undefined, prescription?.id);
    remove(index);
  };

  const verifyForm = async () => {
    return form.triggerCustomValidation();
  };

  return (
    <PrescriptionDataContext.Provider
      value={{
        form,
        fields,
        prescriptions: prescriptionData,
        consultation,
        loading,
        saving,
        addNew,
        removePrescription,
        verifyForm,
        submit,
        loadData: () => getPrescriptions(),
      }}>
      {children}
    </PrescriptionDataContext.Provider>
  );
};
