import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import FloatingInput from './FloatingInput';

interface Props {
  cents: number;
  onChange: (value: number) => void;
  error?: boolean;
  errorMessage?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  style?: StyleProp<ViewStyle>;
  left?: React.ReactNode;
}

export interface AmountInputRef {
  updateValue: (cents: number) => void;
}

export const AmountInput = forwardRef<AmountInputRef, Props>(
  ({ cents, onChange, error, label, placeholder, style, left, disabled, errorMessage }, ref) => {
    const [localValue, setLocalValue] = useState<string>();
    const [localError, setLocalError] = useState<string>();

    const updateValue = (cents: number) => {
      if (cents === undefined || cents === null) {
        setLocalValue('');
      } else {
        setLocalValue((cents / 100).toFixed(2));
      }
    };

    useImperativeHandle(ref, () => ({
      updateValue,
    }));

    useEffect(() => {
      updateValue(cents);
    }, []);

    const onChangeStr = (valueStr: string) => {
      const valueNum = valueStr ? Number(valueStr) : undefined;
      setLocalValue(valueStr);

      if (valueNum !== undefined && isNaN(valueNum)) {
        setLocalError('Value must be a number');
        return;
      }

      setLocalError(null);

      if (!valueNum) {
        onChange(valueNum);
      } else {
        onChange(valueNum * 100);
      }
    };

    const applyChange = () => {
      const valueNum = localValue ? Number(localValue) : undefined;
      if (isNaN(valueNum)) return;
      updateValue(valueNum * 100);
    };

    return (
      <FloatingInput
        label={label}
        placeholder={placeholder}
        value={localValue}
        onChangeValue={onChangeStr}
        error={!!error || !!localError}
        errorMessage={errorMessage || localError}
        onSubmitEditing={applyChange}
        onBlur={applyChange}
        keyboardType="decimal"
        viewStyle={style}
        style={{ textAlign: 'right' }}
        left={left}
        disabled={disabled}
      />
    );
  }
);
