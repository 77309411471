import { useIsFocused, useNavigation } from '@react-navigation/native';
import { DependencyList, useEffect } from 'react';

export function usePageFocus(callback: () => void, dependencies: DependencyList = []) {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      callback();
    }
  }, [navigation, isFocused, ...dependencies]);
}

export function usePageFocusState(callback: (focused) => void, dependencies: DependencyList = []) {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  useEffect(() => {
    callback(isFocused);

    return () => callback(false);
  }, [navigation, isFocused, ...dependencies]);
}
