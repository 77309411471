import { DocumentPickerAsset } from 'expo-document-picker';

import { deleteReq } from '../../axios';

import { HealthProfileHistoryTypeEnum } from '~/api/models/healthProfile/constants/HealthProfileHistoryTypeEnum';
import fileSystem from '~/integrations/fileSystem';

export const apiUploadMedia = (params: {
  healthProfileId: number;
  data: DocumentPickerAsset;
  medical_history_type: HealthProfileHistoryTypeEnum;
  snomed_index_id: string;
}) => {
  return fileSystem.postDocumentResult(`/health-profile/${params.healthProfileId}/media`, 'media[]', params.data, {
    medical_history_type: params.medical_history_type,
    snomed_index_id: params.snomed_index_id,
  });
};

export const apiDeleteMedia = (params: { id: number; data: { media_ids: number[] } }) => {
  return deleteReq(`/health-profile/${params.id}/media`, params.data);
};
