import Ionicons from '@expo/vector-icons/Ionicons';
import React, { useMemo, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { IconButton } from './IconButton';
import { ErrorAlert } from '../modals/ErrorAlert';

import { InvoiceTypeModel } from '~/api/models/billingHistory/models/InvoiceTypeModel';
import { InvoiceTypeEnum } from '~/api/models/billingHistory/models/enums/InvoiceTypeEnum';
import { MediaStatusEnum } from '~/api/models/common/constants/MediaStatusEnum';
import { apiGetInvoiceDetails, apiGetStatementOfFees } from '~/api/services/billingHistory';
import { mh5 } from '~/common/commonStyles';
import { whenAppType } from '~/utils/buildConfig';
import { useOpenUrl } from '~/utils/hooks/OpenUrlHook';

const DoctorDocuments = [InvoiceTypeEnum.DOCTOR_SUBSCRIPTION, InvoiceTypeEnum.DOCTOR_CONSULTATION];
const ClinicDocuments = [InvoiceTypeEnum.DOCTOR_CONSULTATION];
const PatientDocuments = [InvoiceTypeEnum.PATIENT_SUBSCRIPTION, InvoiceTypeEnum.PATIENT_CONSULTATION];
const WithStatement = [InvoiceTypeEnum.DOCTOR_CONSULTATION, InvoiceTypeEnum.PATIENT_CONSULTATION];

interface Props {
  transactionId?: number;
  invoices?: InvoiceTypeModel[];
  statementOfFeesStatus?: MediaStatusEnum;
  style?: StyleProp<ViewStyle>;
}

export const InvoiceActionButtons: React.FC<Props> = ({ transactionId, invoices, statementOfFeesStatus, style }) => {
  const [loading, setLoading] = useState(false);
  const { openUrl } = useOpenUrl();

  const filteredInvoices = useMemo(() => {
    const types = whenAppType({
      whenDoctor: DoctorDocuments,
      whenPatient: PatientDocuments,
      whenClinic: ClinicDocuments,
      else: [],
    });
    return (
      invoices
        ?.filter((invoice) => types.includes(invoice.type))
        .map((invoice) => ({ invoice, withStatement: WithStatement.includes(invoice.type) })) ?? []
    );
  }, [invoices]);

  const getErrorMessage = (status: MediaStatusEnum, type: string) => {
    let reason = '';
    switch (status) {
      case MediaStatusEnum.FAILED:
        reason = 'There was an issue while generating your document. Please contact support for further assistance.';
        break;
      case MediaStatusEnum.PENDING:
      case MediaStatusEnum.PROCESSING:
        reason = 'It seems to be taking more time to process your document than expected. Please try again later.';
        break;
    }
    return `We were unable to get your ${type} at this time.${reason ? '\n' + reason : ''}`;
  };
  const getInvoice = async (invoiceId: number) => {
    try {
      setLoading(true);
      const res = await apiGetInvoiceDetails({ id: invoiceId });

      if (res.data.invoice.status !== MediaStatusEnum.COMPLETED) {
        throw new Error(getErrorMessage(res.data.invoice.status, 'invoice'));
      }
      await openUrl(res.data.invoice.media[0].url);
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const getStatement = async () => {
    try {
      setLoading(true);
      const res = await apiGetStatementOfFees({ id: transactionId });
      if (res.data.status !== MediaStatusEnum.COMPLETED) {
        throw new Error(getErrorMessage(res.data.status, 'statement of fees'));
      }
      await openUrl(res.data.media[0].url);
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  if (!filteredInvoices.length && statementOfFeesStatus !== MediaStatusEnum.COMPLETED) return null;
  return (
    <View style={[styles.container, style]}>
      {statementOfFeesStatus === MediaStatusEnum.COMPLETED ? (
        <IconButton
          title="Statement of fees"
          key={`statement_${transactionId}`}
          style={mh5}
          onPress={() => getStatement()}
          disabled={loading}>
          <Ionicons name="reader-outline" size={16} />
        </IconButton>
      ) : null}
      {filteredInvoices.map((item) => (
        <IconButton
          title="Invoice"
          key={`invoice_${item.invoice.id}`}
          style={mh5}
          onPress={() => getInvoice(item.invoice.id)}
          disabled={loading}>
          <Ionicons name="receipt-outline" size={16} />
        </IconButton>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: -5,
    display: 'flex',
    flexDirection: 'row',
  },
});
