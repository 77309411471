import React, { useMemo, useState } from 'react';
import { View } from 'react-native';

import { ErrorAlert } from './ErrorAlert';
import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { mv10, textCenter, mv20, mt15, mt10 } from '../../common/commonStyles';
import { labels } from '../../utils/labels';
import { OutlineButton, TextButton } from '../commonButton';
import { H2TtmSemiBoldBlack, H6NsRegularSecondaryBlack, H6NsSemiBoldSecondaryBlack } from '../commonText';
import { CheckboxGroup } from '../inputs/CheckboxGroup';
import { OTPTextInput } from '../inputs/OtpTextInput';
import { AlertCircle, Error } from '../svgImages';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { Submit2FAChallengeRequest } from '~/api/models/authorisation/requests/Submit2FAChallengeRequest';
import { OtpChannelEnum } from '~/api/models/otp/constants/OtpChannelEnum';
import { apiSendOtp } from '~/api/services/otp';
import { isPharmacyVersion, whenAppType } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { accountDeletionLabels } from '~/utils/labels/accountDeletionLabels';

interface PopupProps extends BaseModalProps {
  onDelete: (value: Submit2FAChallengeRequest) => void;
  verificationPending?: boolean;
}

const checkboxGroupItems = [
  { label: 'Downloaded List of Patients and Health Reports', value: 1 },
  { label: 'Downloaded List of Appointments', value: 2 },
  { label: 'Downloaded Financial Data', value: 3 },
];

export const DeleteAccountConfirmationModal: React.FC<PopupProps> = ({ onDelete, onHide, verificationPending }) => {
  const [checkBoxItems, setCheckBoxItems] = useState([]);
  const [showOTPMessage, setShowOTPMessage] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [otp, setOtp] = useState<string>('');
  const { userDetails } = useUserDetails({ allowStale: true });

  const hideCheckboxes = useMemo(
    () =>
      whenAppType({
        else: false,
        whenClinic: true,
        whenPharmacist: true,
      }) || verificationPending,
    [verificationPending]
  );
  const preferredOtpChannel = userDetails?.two_step_verification_type || OtpChannelEnum.SMS;

  const generateOTP = async () => {
    await apiSendOtp({ channel: preferredOtpChannel }).catch((error) => {
      ErrorAlert(error);
    });
  };

  const mobile = userDetails?.account
    ? `+${userDetails?.account?.country_code} ${userDetails?.account?.mobile_number}`
    : '';

  const modalButtons = [
    <OutlineButton
      danger
      disabled={hideCheckboxes ? null : checkBoxItems.length !== checkboxGroupItems.length}
      funCallback={() => {
        generateOTP();
        setShowOTPMessage(true);
      }}
      label={labels.deleteAccount.toUpperCase()}
    />,
    <TextButton style={mt10} funCallback={onHide} label={labels.goBack} />,
  ];

  const modalButtonsOtp = [
    <OutlineButton
      style={mt15}
      danger
      disabled={otp.length !== 6 || disableButtons}
      funCallback={() => {
        setDisableButtons(true);
        onDelete({ otp, channel: preferredOtpChannel });
      }}
      label={labels.deleteAccount.toUpperCase()}
    />,
    <TextButton style={mt10} disabled={disableButtons} funCallback={onHide} label={labels.cancel} />,
  ];
  return (
    <ModalContainer centerModal onHide={onHide} buttons={showOTPMessage ? modalButtonsOtp : modalButtons}>
      {showOTPMessage ? (
        <View>
          <View style={{ alignSelf: 'center' }}>
            <AlertCircle height={55} width={55} />
          </View>
          <H2TtmSemiBoldBlack style={[mv10, textCenter]}>{accountDeletionLabels.confirmDeletion}</H2TtmSemiBoldBlack>
          <H6NsRegularSecondaryBlack style={[mv20]}>
            {labels.weSentSixDigitCode}
            <H6NsSemiBoldSecondaryBlack>
              {preferredOtpChannel === OtpChannelEnum.SMS ? mobile : userDetails.account.email}
            </H6NsSemiBoldSecondaryBlack>
          </H6NsRegularSecondaryBlack>
          <View>
            <OTPTextInput
              handleTextChange={(e: string) => {
                setOtp(e);
              }}
              defaultValue={otp}
              inputCount={6}
              inputCellLength={1}
              keyboardType="numeric"
              tintColor={colors.info}
              offTintColor={colors.lightPurple2}
              autofocus
            />
          </View>
        </View>
      ) : (
        <>
          <View style={{ alignSelf: 'center' }}>
            <Error height={55} width={55} />
          </View>
          <H2TtmSemiBoldBlack style={[mv10, textCenter]}>{labels.sorryToSeeYouGo}</H2TtmSemiBoldBlack>
          <H6NsRegularSecondaryBlack style={[mv20, textCenter]}>
            {labels.accountDeleteMessage}
          </H6NsRegularSecondaryBlack>
          {hideCheckboxes ? null : (
            <>
              <H6NsRegularSecondaryBlack style={[mv20]}>{labels.kindlyConfirm}</H6NsRegularSecondaryBlack>
              <CheckboxGroup values={checkBoxItems} items={checkboxGroupItems} onChange={setCheckBoxItems} />
            </>
          )}
        </>
      )}
    </ModalContainer>
  );
};
