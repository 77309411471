import React from 'react';
import { View } from 'react-native';

import { VersionInfoComponent } from './VersionInfoComponent';
import { TitleLayout } from '../../layouts/TitleLayout';
import { labels } from '../../utils/labels';

import { flex1 } from '~/common/commonStyles';
import { HelpNavigation } from '~/navigation/authorised/HelpNavigation';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const HelpWebPage: React.FC = () => {
  const { isMobile } = useBreakpoints();
  return (
    <TitleLayout title={labels.help} noContentPadding noHeaderPadding={!isMobile}>
      <View style={flex1}>
        <HelpNavigation />
      </View>
      <VersionInfoComponent />
    </TitleLayout>
  );
};
