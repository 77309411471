import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { DataTable as RNDataTable } from 'react-native-paper';

import { DataTableColumns, RowStyle } from './DataTableTypes';
import { Hoverable } from '../hover/Hoverable';

import { H6NsRegularBlack } from '~/components/commonText';
import { NewBadge } from '~/components/misc/NewBadge';
import { isWeb } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';

interface Props {
  item: any;
  identifier: any;
  rowStyle?: RowStyle;
  rowHoverStyle?: RowStyle;
  onRowPress?: (key: any, item: any) => void;
  rowIsNewIdentifier?: (item: any) => boolean;
  filteredColumns: DataTableColumns;
  selected?: boolean;
  index: number;
}

export const DataTableRow: React.FC<Props> = ({
  item,
  rowStyle,
  rowHoverStyle,
  onRowPress,
  rowIsNewIdentifier,
  identifier,
  filteredColumns,
  selected,
  index,
}) => {
  const [hovering, setHovering] = useState(false);
  const rowView = (
    <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      {rowIsNewIdentifier && rowIsNewIdentifier(item) ? (
        <NewBadge withHover={isWeb()} hovering={hovering} style={styles.newBadge} />
      ) : null}

      {filteredColumns.map((column) => {
        const cellData = column.selector ? column.selector(item) : item[column.key];
        const content = column.cell ? column.cell(cellData, item, index) : cellData;
        const isString = typeof content === 'string';
        const showOnHover = column.showOnHover ?? false;
        return (
          <View
            key={`${identifier}_${column.key}`}
            pointerEvents="auto"
            style={[
              styles.cellStyle,
              styles.baseColumnStyle,
              column.columnStyle,
              column.cellStyle,
              column.contentNumeric ? styles.cellRight : null,
            ]}>
            {!showOnHover || (showOnHover && hovering) ? (
              isString ? (
                <H6NsRegularBlack>{content}</H6NsRegularBlack>
              ) : (
                content
              )
            ) : null}
          </View>
        );
      })}
    </View>
  );
  const rowContent = isWeb() ? (
    <Hoverable onHoverIn={() => setHovering(true)} onHoverOut={() => setHovering(false)}>
      {rowView}
    </Hoverable>
  ) : (
    rowView
  );

  return (
    <RNDataTable.Row
      disabled={!onRowPress}
      style={[selected ? styles.selectedRow : null, hovering ? rowHoverStyle : null, rowStyle]}
      onPress={onRowPress ? () => onRowPress(identifier, item) : null}>
      {rowContent}
    </RNDataTable.Row>
  );
};

const styles = StyleSheet.create({
  cellStyle: {
    padding: 5,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedRow: {
    backgroundColor: colors.lightPurple,
    borderWidth: 1,
    borderColor: colors.info,
    borderBottomColor: colors.info,
    borderRadius: 6,
  },
  newBadge: {
    zIndex: 5,
    position: 'absolute',
    left: -10,
    top: 2,
  },
  baseColumnStyle: {
    maxWidth: 400,
    minWidth: 120,
  },

  cellRight: {
    justifyContent: 'flex-end',
  },
});
