import React from 'react';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { INavigate } from '~/common/types/navigation/navigate';
import { HealthProfileHistoryItemMobile } from '~/components/doctor/patients/healthProfile/HealthProfileHistoryItemMobile';
import { HealthProfileAllergiesProvider } from '~/providers/healthProfile/history/HealthProfileAllergiesProvider';

interface Props {
  edit?: boolean;
  backLink: INavigate;
}

export const HealthProfileAllergiesMobile: React.FC<Props> = ({ edit, backLink }) => {
  return (
    <HealthProfileAllergiesProvider>
      <HealthProfileHistoryItemMobile
        edit={edit}
        type={{ label: 'Allergies', value: ClinicalTermTypeEnum.ALLERGIES }}
        backLink={backLink}
      />
    </HealthProfileAllergiesProvider>
  );
};
