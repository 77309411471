import { labels } from '../utils/labels';
import { screenName } from '../utils/screenName';

import { isClinicVersion, isPharmacyVersion } from '~/utils/buildConfig';
import { DigimedFeatures, digimedFeatureEnabled } from '~/utils/milestoneUtil';

const hideFaq = isPharmacyVersion() || isClinicVersion();

const allHelpMenu = [
  { title: labels.termsAndConditions, link: { screen: screenName.HelpTermsAndConditions, params: {} } },
  { title: labels.privacyPolicy, link: { screen: screenName.HelpPrivacyPolicy, params: {} } },
  { title: labels.cookiePolicy, link: { screen: screenName.HelpCookiePolicy, params: {} } },
  {
    title: labels.cancellationPolicy,
    link: { screen: screenName.HelpCancellationPolicy, params: {} },
    hidden: !digimedFeatureEnabled(DigimedFeatures.CalendarAndAppointments) || isPharmacyVersion() || isClinicVersion(),
  },
];

export const helpMenu = [
  { title: hideFaq ? labels.contactUs : labels.faq, link: { screen: screenName.HelpFaq, params: {} } },
  ...allHelpMenu,
];
export const helpMenuMobile = [
  { title: labels.contactUs, link: { screen: screenName.HelpContactUs, params: {} } },
  ...(hideFaq ? allHelpMenu : helpMenu),
];
