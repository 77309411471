import * as Application from 'expo-application';
import Constants from 'expo-constants';
import React, { useMemo } from 'react';

import { SmallNsRegularDisableColor } from '~/components/commonText';
import { isNative } from '~/utils/buildConfig';

export const VersionInfoComponent: React.FC = () => {
  const version = useMemo(() => {
    const version = Constants.expoConfig.version;
    const commitId = Constants.expoConfig.extra.version?.commitId;

    if (isNative() && Application.nativeBuildVersion) {
      return `${version}-${Application.nativeBuildVersion}`;
    } else if (commitId) {
      return `${version}-${String(commitId).slice(0, 8)}`;
    }
    return version;
  }, []);
  return <SmallNsRegularDisableColor>Version {version}</SmallNsRegularDisableColor>;
};
