import React, { useContext } from 'react';

import { useHealthProfileDataContext } from './HealthProfileDataContext';
import { IHealthProfileItemContext } from './HealthProfileItemContextTypes';

import { HealthProfileModel } from '~/api/models/healthProfile/Models/HealthProfileModel';

export const HealthProfileItemContext = React.createContext<IHealthProfileItemContext<HealthProfileModel>>(
  {} as IHealthProfileItemContext<HealthProfileModel>
);

export const useHealthProfileMetricsContext = (): IHealthProfileItemContext<HealthProfileModel> =>
  useContext(HealthProfileItemContext);

interface Props {
  children: React.ReactNode;
}
export const HealthProfileMetricsProvider: React.FC<Props> = ({ children }) => {
  const { healthProfile, accountId } = useHealthProfileDataContext();

  const getData = async () => {
    if (!healthProfile?.id) return;
    return healthProfile;
  };

  return (
    <HealthProfileItemContext.Provider
      value={{
        accountId,
        healthProfileId: healthProfile?.id,
        data: healthProfile,
        getData,
        loading: false,
        hasData: !!healthProfile,
      }}>
      {children}
    </HealthProfileItemContext.Provider>
  );
};
