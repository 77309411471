import { Link, useNavigation } from '@react-navigation/native';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { View } from 'react-native';

import { CountryListEnum, useCountryListing } from '../../api/hooks/referenceData/CountryListing';
import { OtpChannelEnum } from '../../api/models/otp/constants/OtpChannelEnum';
import { apiSendOtp } from '../../api/services/otp';
import { apiRegisterWithMobile } from '../../api/services/registration';
import { flex1, mr10, ph5, pl0, pr0, textCenter } from '../../common/commonStyles';
import { Button } from '../../components/commonButton';
import { H6NsRegularSecondaryBlack, H6TtmSemiBoldTheme } from '../../components/commonText';
import { RowView } from '../../components/commonViews';
import FloatingTextBox from '../../components/floatingInputBox';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import { UPDATE_LOGIN_DETAILS } from '../../redux/reducers/userDetailsReducer';
import { useAppDispatch } from '../../redux/store';
import {
  requiredValidation,
  validationSchema,
  minLengthValidation,
  numberValidation,
} from '../../services/validationConfig';
import { labels } from '../../utils/labels';
import { screenName } from '../../utils/screenName';

import { MaltaPhoneCode } from '~/api/hooks/referenceData/constants/CountryConstants';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { NavType } from '~/navigation/types';
import { registrationLabels } from '~/utils/labels/registrationLabels';

// create a component
const RegisterPhoneNumberScreen: React.FC = () => {
  const navigation = useNavigation<NavType>();
  const [loading, setLoading] = React.useState(false);

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ country_code: string; mobile_number: string }>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      country_code: MaltaPhoneCode,
      mobile_number: '',
    },
  });
  const countryItems = useCountryListing(CountryListEnum.PHONE_CODE);
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const submitMobileValue = getValues();
      const submitMobileRequest = {
        country_code: submitMobileValue.country_code,
        number: submitMobileValue.mobile_number,
      };
      await apiRegisterWithMobile(submitMobileRequest);
      dispatch(UPDATE_LOGIN_DETAILS(submitMobileRequest));
      sendOtp();
      navigation.navigate(screenName.RegistrationMobileOtp);
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setLoading(false);
    }
  };

  const sendOtp = () => {
    apiSendOtp({ channel: OtpChannelEnum.SMS }).catch(ErrorAlert);
  };

  const buttons = [
    <Button
      funCallback={handleSubmit(onSubmit)}
      disabled={!isValid || loading}
      label={labels.startRegistration.toUpperCase()}
    />,
    <View style={[{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}>
      <H6NsRegularSecondaryBlack style={[mr10, textCenter]}>
        {`${labels.alreadyIHaveAnAccount} `}

        <Link to={{ screen: screenName.LoginScreen } as never}>
          <H6TtmSemiBoldTheme>{labels.signIn.toUpperCase()}</H6TtmSemiBoldTheme>
        </Link>
      </H6NsRegularSecondaryBlack>
    </View>,
  ];

  return (
    <EnhancedRegistrationLayout
      compressed
      hideProgress
      title={registrationLabels.registerTitleMobile}
      subtitle={registrationLabels.registerSubtitle}
      buttons={buttons}>
      <RowView style={{ width: '100%', alignItems: 'flex-start' }}>
        <View style={[flex1, ph5, pl0, { maxWidth: 100, paddingTop: 2 }]}>
          <Controller
            name="country_code"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                list={countryItems.map((item) => ({ label: item.name, value: item.id }))}
                setValue={onChange}
                value={value}
                errorMessage={errors.country_code?.message}
                error={!!errors.country_code}
                searchable
              />
            )}
            rules={{
              required: requiredValidation(''),
            }}
          />
        </View>
        <View style={[flex1, ph5, pr0]}>
          <Controller
            name="mobile_number"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { isTouched } }) => (
              <FloatingTextBox
                showMandatory
                maxLength={validationSchema.phoneNumber.maxLength}
                error={isTouched && !!errors.mobile_number}
                errorMessage={isTouched && errors.mobile_number?.message}
                value={value}
                onChangeValue={onChange}
                onBlur={onBlur}
                keyboardType="numeric"
                label={labels.phoneNumber}
                onSubmitEditing={handleSubmit(onSubmit)}
              />
            )}
            rules={{
              validate: { numberValidation },
              required: requiredValidation(labels.phoneNumber),
              minLength: minLengthValidation(validationSchema.phoneNumber.minLength),
            }}
          />
        </View>
      </RowView>
    </EnhancedRegistrationLayout>
  );
};

export default RegisterPhoneNumberScreen;
