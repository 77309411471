import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { ScheduledAppointmentCard } from './ScheduledAppointmentCard';
import { ScheduledAvailabilityEditMode } from './ScheduledAvailabilityEditMode';

import { useScheduledAppointments } from '~/api/hooks/preferences/ScheduledAppointmentsHook';
import { AvailabilitiesPageStateEnum } from '~/api/models/preferences/enums/AvailabilitiesPageStateEnum';
import { ScheduledAppointmentsModelWithId } from '~/api/models/preferences/models/ScheduledAppointmentsWithIdModel';
import { mt20, mt25 } from '~/common/commonStyles';
import { OutlineButton } from '~/components/commonButton';
import { H6NsRegularBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { DaysOfTheWeek } from '~/constants/daysOfTheWeek';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { FULL_DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { doctorLabels } from '~/utils/labels/doctor';

interface Props {
  isRegistration?: boolean;
  pageState?: AvailabilitiesPageStateEnum;
  disabled?: boolean;
  setPageState: React.Dispatch<React.SetStateAction<AvailabilitiesPageStateEnum>>;
}
const shortDaysToFullDays = (days: string[]) => {
  let stringOfDays = '';

  days.map((day, index) => {
    let tempDay = '';

    tempDay = DaysOfTheWeek.find((arrayDay) => arrayDay.value === day).label;

    if (days.length - 1 === index) {
      stringOfDays += tempDay;
    } else {
      stringOfDays += `${tempDay}, `;
    }
  });

  return stringOfDays;
};

export const ScheduledAppointmentsAvailabilities: React.FC<Props> = ({
  isRegistration,
  pageState,
  disabled,
  setPageState,
}) => {
  const [mappedScheduledAvailabilities, setMappedScheduledAvailabilities] = useState([]);
  const [editing, setEdit] = useState<ScheduledAppointmentsModelWithId>(null);
  const editModeRef = useRef(null);

  const { scheduledAvailabilities, saveScheduledAvailability, removeScheduledAvailability, loading } =
    useScheduledAppointments({ immediate: true });

  const startEditing = (appointment: ScheduledAppointmentsModelWithId | null) => {
    setEdit(appointment);
    setPageState(AvailabilitiesPageStateEnum.CREATE);

    setTimeout(() => {
      if (editModeRef?.current?.scrollIntoView) {
        editModeRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    });
  };

  useEffect(() => {
    if (!loading && scheduledAvailabilities?.length === 0) {
      setPageState(AvailabilitiesPageStateEnum.CREATE);
    }
  }, [scheduledAvailabilities, loading]);

  useEffect(() => {
    const mapping = scheduledAvailabilities?.map((element: ScheduledAppointmentsModelWithId) => {
      return {
        id: element.group_id,
        labelValuePairs: [
          { label: 'Duration', value: `${element.slot_duration_in_minutes} minutes` },
          { label: 'Start Date', value: parseDateTime(element.start_date, { outputFormat: FULL_DATE_FORMAT }) },
          {
            label: 'Time',
            value: element.time_ranges.map((timeRange) => `${timeRange.start_time} to ${timeRange.end_time}\n`),
          },
          { label: 'Repeat Availability', value: shortDaysToFullDays(element.days_of_week) },
        ],
        data: element,
      };
    });
    setMappedScheduledAvailabilities(mapping);
  }, [scheduledAvailabilities]);

  return (
    <Grid grid={{ noOuterGutter: true, gutters: 12 }}>
      <Container alignSelf="flex-start" maxContainerWidths="100%">
        <Row>
          <Column>
            {mappedScheduledAvailabilities && !loading ? (
              <>
                {mappedScheduledAvailabilities.length > 0 ? (
                  <ScrollView horizontal bounces={false} bouncesZoom={false}>
                    {mappedScheduledAvailabilities.map((element) => {
                      return (
                        <ScheduledAppointmentCard
                          id={element.id}
                          items={element.labelValuePairs}
                          itemData={element.data}
                          key={element.id}
                          removeScheduledAppointment={removeScheduledAvailability}
                          updateScheduledAppointment={startEditing}
                          disabled={disabled}
                        />
                      );
                    })}
                  </ScrollView>
                ) : (
                  <H6NsRegularBlack>{doctorLabels.availabilities.setScheduledAppointments}</H6NsRegularBlack>
                )}
              </>
            ) : (
              <View style={styles.loading}>
                <LoadingActivityIndicator />
              </View>
            )}
          </Column>
        </Row>
        {loading || disabled ? null : pageState === AvailabilitiesPageStateEnum.VIEW ? (
          <Row style={mt25}>
            <Column span={isRegistration ? 12 : { xs: 12, md: 6 }}>
              <OutlineButton
                funCallback={() => startEditing(null)}
                label={labels.addNewSchedule}
                style={{ maxWidth: 250 }}
              />
            </Column>
          </Row>
        ) : (
          <View ref={editModeRef}>
            <ScheduledAvailabilityEditMode
              hideTitle
              isSmallScreen={isRegistration}
              scheduledAvailability={editing}
              onSave={(data: ScheduledAppointmentsModelWithId) => {
                saveScheduledAvailability(data)
                  .then(() => {
                    setEdit(null);
                    setPageState(AvailabilitiesPageStateEnum.VIEW);
                  })
                  .catch(ErrorAlert);
              }}
              onCancel={
                editing || scheduledAvailabilities?.length
                  ? () => {
                      setEdit(null);
                      setPageState(AvailabilitiesPageStateEnum.VIEW);
                    }
                  : null
              }
            />
          </View>
        )}
      </Container>
    </Grid>
  );
};

const styles = StyleSheet.create({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});
