import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';

import { useFinaliseRegistration } from '~/api/hooks/accounts/FinaliseRegistration';
import { useMyPharmacies } from '~/api/hooks/pharmacy/PharmacyHook';
import { useLocalityListing } from '~/api/hooks/referenceData/LocalityListing';
import { UpdatePharmacyRequest } from '~/api/models/pharmacy/requests/UpdatePharmacyRequest';
import { apiUpdatePharmacy } from '~/api/services/pharmacy';
import { Button } from '~/components/commonButton';
import FloatingInput from '~/components/floatingInputBox';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { alphaNumericValidationWithSpace, minLengthValidation, validationSchema } from '~/services/validationConfig';
import { labels } from '~/utils/labels';

export const PharmacyRegistrationLocationScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { loading: pharmaciesLoading, adminPharmacies } = useMyPharmacies();

  const { localities } = useLocalityListing();
  const { finaliseRegistration } = useFinaliseRegistration();

  const { handleSubmit, control, getValues, reset } = useForm<UpdatePharmacyRequest>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      id: undefined,
      params: {
        address: '',
        post_code: '',
        locality_id: undefined,
      },
    },
  });

  useEffect(() => {
    if (adminPharmacies?.length) {
      reset({
        ...getValues(),
        id: adminPharmacies[0].pharmacy.id,
      });
    }
  }, [adminPharmacies]);

  const onSubmit = async (data: UpdatePharmacyRequest) => {
    try {
      setLoading(true);
      await apiUpdatePharmacy(data);
      await finaliseRegistration();
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };
  const buttons = [
    <Button funCallback={handleSubmit(onSubmit)} label={labels.continue} disabled={loading || pharmaciesLoading} />,
  ];

  return (
    <EnhancedRegistrationLayout
      buttons={buttons}
      title="Pharmacy details"
      subtitle="Please provide pharmacy's information">
      <View style={{ width: '100%' }}>
        <Controller
          name="params.address"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingInput
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error?.message}
              errorMessage={isSubmitted && error?.message}
              label="Pharmacy address"
            />
          )}
        />
        <Controller
          name="params.locality_id"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <Dropdown
              list={localities.map((item) => ({ label: item.name, value: item.id }))}
              setValue={onChange}
              value={value}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
              searchable
              label="Locality"
            />
          )}
        />

        <Controller
          name="params.post_code"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingInput
              label={labels.postCode}
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
              maxLength={validationSchema.postCode.maxLength}
            />
          )}
          rules={{
            validate: { alphaNumericValidationWithSpace },
            minLength: minLengthValidation(validationSchema.postCode.minLength),
          }}
        />
      </View>
    </EnhancedRegistrationLayout>
  );
};
