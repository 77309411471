import { FlashListProps } from '@shopify/flash-list';
import React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export type RowStyle = StyleProp<ViewStyle> | StyleProp<ViewStyle>[];

export const DEFAULT_TABLE_ITEMS_PER_PAGE = 30;

export interface TablePaginationInfo {
  page: number;
  limit?: number;
}

interface HeaderProps {
  key: string;
  title?: string;
  headerNumeric?: boolean;
  sortDirection?: 'ascending' | 'descending';
  headerStyle?: StyleProp<ViewStyle>;
  headerTextStyle?: StyleProp<TextStyle>;
  headerChildren?: React.ReactNode;
  onHeaderPress?: (key: string, header: HeaderProps) => void;
}

interface CellProps<T = any> {
  contentNumeric?: boolean;
  cell?: (cellData: any, item: any, index: number) => React.ReactNode;
  selector?: (item: T) => string | number;
  onCellPress?: (cellData: any, key: string, item: T) => void;
  cellStyle?: StyleProp<ViewStyle>;
  cellTextStyle?: StyleProp<TextStyle>;
  hideMobile?: boolean;
  hideTablet?: boolean;
  hide?: (cellData: any, item: T) => boolean;
  showOnHover?: boolean;
}

interface ColumnProps {
  hide?: boolean | ((key: string) => boolean);
  columnStyle?: StyleProp<ViewStyle>;
}

export interface InfiniteScrollOptions {
  endReachedThreshold?: number;
  loading: boolean;
  estimatedItemSize?: number | { desktop: number; mobile: number };
}

export type DataTableColumns<T = any> = (HeaderProps & CellProps<T> & ColumnProps)[];

export interface DataTableProps<T = any> {
  columns: DataTableColumns<T>;
  items: T[];
  numberOfItemsPerPage?: number;
  rowStyle?: RowStyle;
  rowHoverStyle?: RowStyle;
  hidePagination?: boolean;
  totalCount?: number;
  identifier?: string;
  style?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  wrapperStyle?: StyleProp<ViewStyle>;
  page?: number;
  tableId?: string;
  infiniteScroll?: InfiniteScrollOptions;
  scrollEnabled?: boolean;
  loading?: boolean;
  hideHeaders?: boolean;
  headers?: () => React.ReactNode;
  listHeader?: FlashListProps<T>['ListHeaderComponent'];
  emptyNote?: string | FlashListProps<T>['ListEmptyComponent'];
  hideHeaderWhenEmpty?: boolean;
  selected?: (string | number)[];
  forceMobileView?: boolean;
  mobileBreakpoint?: number;
  mobileRenderItem?: (item: T, content: Record<string, { content: any; cellData: any }>) => React.ReactElement;
  rowIsNewIdentifier?: (item: T) => boolean;
  onRowPress?: (key: any, item: T) => void;
  onPageChanged?: (payload: TablePaginationInfo) => void;
  onItemsPerPageChange?: (payload: TablePaginationInfo) => void;
  dataTableCardStyle?: StyleProp<ViewStyle>;
  alwaysShowPagination?: boolean;
  nestedScrollEnabled?: boolean;
  notAbsolute?: boolean;
  noHorizontalScroll?: boolean;
}
