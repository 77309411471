import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Animated, View } from 'react-native';

import { isWeb } from '~/utils/buildConfig';
import isUndefined from '~/utils/types/isUndefined';

interface Props {
  size: number;
  diameter?: number;
  color: string;
  position: {
    left?: number;
    right?: number;
    bottom?: number;
    top?: number;
  };
  disabled?: boolean;
}

export const AnimatedCircle: React.FC<Props> = ({ size, color, position, diameter, disabled }) => {
  const [spinValue] = useState(new Animated.Value(0));
  const duration = useRef(Math.random() * 10000 + 20000).current;

  useEffect(() => {
    if (disabled) return;
    Animated.loop(
      Animated.timing(spinValue, {
        toValue: 1,
        duration,
        useNativeDriver: !isWeb(),
      })
    ).start();
  }, [disabled]);

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  const calculatedDiameter = useMemo(() => diameter ?? size * 2, [diameter, size]);
  const calculatedPosition = useMemo(() => {
    const radius = calculatedDiameter / 2;
    return {
      left: isUndefined(position?.left) ? undefined : position.left - radius,
      right: isUndefined(position?.right) ? undefined : position.right - radius,
      bottom: isUndefined(position?.bottom) ? undefined : position.bottom - radius,
      top: isUndefined(position?.top) ? undefined : position.top - radius,
    };
  }, [position, calculatedDiameter]);

  return (
    <Animated.View
      style={{
        position: 'absolute',
        ...calculatedPosition,
        width: calculatedDiameter,
        height: calculatedDiameter,
        transform: [{ rotate: spin }],
      }}>
      <View
        style={{
          backgroundColor: color,
          width: size,
          height: size,
          borderRadius: size * 2,
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      />
    </Animated.View>
  );
};
