import React from 'react';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';

import { cardStyle, flex1 } from '~/common/commonStyles';
import { LinkButton } from '~/components/buttons/LinkButton';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { colors } from '~/utils/colors';

type NestedScreenLink = {
  name: string;
  params?: {
    screen: string;
    params?: {
      screen: string;
    };
  };
};

interface Props {
  title?: string;
  headerRight?: React.ReactNode;
  children: React.ReactNode;
  shadow?: boolean;
  showViewAllPress?: boolean;
  viewAllLink?: string | NestedScreenLink;
  titleViewAll?: string;
  style?: StyleProp<ViewStyle>;
  childStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  white?: boolean;
  onPress?: () => void;
}
export const DashboardCard: React.FC<Props> = ({
  title,
  viewAllLink,
  showViewAllPress,
  children,
  style,
  childStyle,
  titleStyle,
  titleViewAll,
  white,
  shadow,
  headerRight,
  onPress,
}) => {
  const content = (
    <>
      {title ? (
        <View style={styles.titleHeader}>
          <H3TtmSemiBoldBlack style={[flex1, titleStyle]} maxFontSizeMultiplier={1.2}>
            {title}
          </H3TtmSemiBoldBlack>
          {headerRight ? (
            headerRight
          ) : viewAllLink || (showViewAllPress && onPress) ? (
            <LinkButton
              to={viewAllLink ? ({ screen: viewAllLink } as never) : null}
              onPress={showViewAllPress ? onPress : null}
              title={titleViewAll || 'VIEW ALL'}
            />
          ) : null}
        </View>
      ) : null}
      <View style={[styles.children, childStyle]}>{children}</View>
    </>
  );

  if (onPress) {
    return (
      <TouchableOpacity
        onPress={onPress}
        style={[
          cardStyle.cardContainer,
          styles.container,
          white ? styles.containerWhite : null,
          shadow ? styles.shadow : null,
          style,
        ]}>
        {content}
      </TouchableOpacity>
    );
  } else {
    return (
      <View
        style={[
          cardStyle.cardContainer,
          styles.container,
          white ? styles.containerWhite : null,
          shadow ? styles.shadow : null,
          style,
        ]}>
        {content}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  containerWhite: {
    borderColor: colors.lightPurple2,
    backgroundColor: colors.white,
  },
  titleHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  children: {
    display: 'flex',
    flex: 1,
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
});
