import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { flex1 } from '~/common/commonStyles';
import { H2TtmSemiBoldBlack, H4NsRegularBlack } from '~/components/commonText';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { colors } from '~/utils/colors';

interface Props {
  style?: StyleProp<ViewStyle>;
}
export const DoctorClinicInfo: React.FC<Props> = ({ style }) => {
  const { userDetails } = useUserDetails({ allowStale: true });

  const clinicName = useMemo(() => {
    return userDetails?.clinic_account?.clinic_name;
  }, [userDetails?.clinic_account]);

  if (!clinicName) return null;
  return (
    <View style={[styles.container, style]}>
      <ProfileImageComponent account={userDetails?.clinic_account} style={styles.profileImage} />
      <H2TtmSemiBoldBlack style={flex1}>{clinicName}</H2TtmSemiBoldBlack>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    backgroundColor: colors.lightPurple,
    padding: 10,
    borderRadius: 8,
  },
  profileImage: {
    backgroundColor: colors.white,
  },
});
