import * as React from 'react';
import { View } from 'react-native';

import { apiAcceptTermsAndConditions } from '../../api/services/registration';
import { Button } from '../../components/commonButton';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import { labels } from '../../utils/labels';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { PageTypesEnum } from '~/api/models/pages/constants/PageTypesEnum';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import PolicyContent from '~/components/policies/PolicyContent';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { isNative, whenAppType } from '~/utils/buildConfig';

export const RegistrationTermsAndConditionsScreen: React.FC = (): JSX.Element => {
  const [acceptingTerms, setAcceptingTerms] = React.useState(false);
  const { updateRegistrationStatus } = useRegistrationSteps();
  const acceptTerms = async () => {
    try {
      setAcceptingTerms(true);
      await apiAcceptTermsAndConditions();

      await updateRegistrationStatus();
    } catch (e) {
      ErrorAlert(e);
    }
    setAcceptingTerms(false);
  };

  const button = [
    <Button funCallback={acceptTerms} label={labels.agree} disabled={acceptingTerms} style={{ maxWidth: 430 }} />,
  ];
  return (
    <EnhancedRegistrationLayout title={labels.termsAndConditions} buttons={button} fixedContent>
      <View style={{ display: 'flex', height: isNative() ? undefined : '100%' }}>
        <PolicyContent
          style={{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE, width: '100%' }}
          contentType={whenAppType({
            whenClinic: PageTypesEnum.CLINIC_TERM_AND_CONDITIONS,
            whenDoctor: PageTypesEnum.DOCTOR_TERM_AND_CONDITIONS,
            whenPatient: PageTypesEnum.PATIENT_TERM_AND_CONDITIONS,
            whenPharmacist: PageTypesEnum.PHARMACY_TERM_AND_CONDITIONS,
          })}
        />
      </View>
    </EnhancedRegistrationLayout>
  );
};
