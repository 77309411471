import React, { useContext, useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import getBreakpoint from './utils/getBreakpoint';

import { GridDefaults } from '~/constants/grid/defaults';
import { IGridOptions } from '~/constants/grid/types';

export interface IGridContext extends IGridOptions {
  width?: number;
  breakpoint?: string;
  maxContainerWidth?: number;
  gutter?: number;
}

export const GridContext = React.createContext<IGridContext>({} as IGridContext);
export const useGridContext = (): IGridContext => useContext(GridContext);

interface Props {
  grid?: IGridOptions;
  children: React.ReactNode;
}

export const Grid: React.FC<Props> = ({ children, grid }) => {
  const width = useWindowDimensions().width;
  const [breakpoint, setBreakpoint] = useState('');
  const gridValues = { ...GridDefaults, ...grid };

  useEffect(() => {
    setBreakpoint(getBreakpoint(gridValues.breakpoints, width));
  }, [width]);

  const gutter = typeof gridValues.gutters === 'number' ? gridValues.gutters : gridValues.gutters[breakpoint];
  return (
    <GridContext.Provider
      value={{
        ...gridValues,
        width,
        breakpoint,
        gutter,
        maxContainerWidth: gridValues.maxContainerWidths[breakpoint] || GridDefaults.maxContainerWidths[breakpoint],
      }}>
      {children}
    </GridContext.Provider>
  );
};
