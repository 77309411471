import React, { useRef, useState } from 'react';
import { Text, View } from 'react-native';
import { Menu } from 'react-native-paper';

import { commonTextStyles, webMenuStyles } from './DotMenuCommonStyles';
import { NotificationOptionInterface } from './models/NotificationOptionInterface';
import * as commonStyles from '../../../common/commonStyles';
import { colors } from '../../../utils/colors';
import { MoreIcon } from '../../svgImages';

import { useNotificationActions } from '~/api/hooks/notifications/NotificationActionsHook';
import { NotificationModel } from '~/api/models/notifications/models/NotificationModel';
import { IconButton } from '~/components/buttons/IconButton';

interface Props {
  notification: NotificationModel;
}

export const NotificationDotMenuDesktop: React.FC<Props> = ({ notification }) => {
  const { actions } = useNotificationActions({ notificationData: notification?.data });
  const [visible, setVisible] = useState(false);
  const ref = useRef<View>();
  const [anchor, setAnchor] = useState<{ x: number; y: number }>();

  const button = ({
    title,
    subTitle,
    icon,
    disabled,
  }: {
    title: NotificationOptionInterface['title'];
    subTitle: NotificationOptionInterface['subTitle'];
    icon: NotificationOptionInterface['icon'];
    disabled: boolean;
  }) => {
    return (
      <View style={[commonStyles.flexRow]}>
        {icon ? (
          <View style={[commonStyles.justifyCenter, { marginRight: 15 }]}>
            {typeof icon === 'function' ? icon(disabled ? colors.lightGrey : colors.purple) : icon}
          </View>
        ) : null}
        <View>
          <Text style={[commonTextStyles.titleWeight, webMenuStyles.textSize]}>{title}</Text>
          <Text style={[commonTextStyles.subtitleWeight, webMenuStyles.textSize]}>{subTitle}</Text>
        </View>
      </View>
    );
  };

  return (
    <View>
      <View ref={ref}>
        <IconButton
          onPress={() => {
            if ('getBoundingClientRect' in ref.current) {
              const rect = (ref.current as any as HTMLElement).getBoundingClientRect();
              setAnchor({ x: rect.x, y: rect.y });
            } else {
              ref.current.measureInWindow((x, y) => {
                setAnchor({ x, y });
              });
            }
            setVisible(true);
          }}
          transparent>
          <MoreIcon height={20} width={20} color={colors.purple} />
        </IconButton>
      </View>
      <Menu
        visible={visible}
        onDismiss={() => setVisible(false)}
        anchor={anchor}
        contentStyle={{ borderRadius: 20 }}
        style={webMenuStyles.menuStyle}
        key={notification.id}>
        {actions?.map((item, i) => {
          const disabled = item.disabled && item.disabled(notification);
          return (
            <Menu.Item
              title={button({ title: item.title, subTitle: item.subTitle, icon: item.icon, disabled })}
              onPress={() => {
                item.action(notification);
                setVisible(false);
              }}
              style={i === actions.length - 1 ? webMenuStyles.lastItemStyle : webMenuStyles.itemsListStyle}
              key={i}
              disabled={disabled}
            />
          );
        })}
      </Menu>
    </View>
  );
};
