import React from 'react';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { Column, Container, Grid, Row } from '~/theme/components/grid';

export const ClinicDashboardHeader: React.FC = () => {
  const { userDetails } = useUserDetails({ allowStale: false });
  return (
    <Grid>
      <Container>
        <Row>
          <Column>
            <ProfileDetailsComponent account={userDetails?.clinic_account} theme="large" />
          </Column>
        </Row>
      </Container>
    </Grid>
  );
};
