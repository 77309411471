import React, { useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useConsultationIdChannel } from '~/api/hooks/channels/useConsultationIdChannel';
import { ConsultationNavigationEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { HomeVisitNavigationStateEnum } from '~/api/models/consultations/enums/HomeVisitNavigationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { pb20 } from '~/common/commonStyles';
import { OutlineButton } from '~/components/commonButton';
import { H1TtmSemiBoldBlack, H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useNavigationHealthProfile } from '~/utils/hooks/AppNavigationHook';
import { useAppointmentState } from '~/utils/hooks/appointments/AppointmentStateHook';
import { labels } from '~/utils/labels';

interface ConsultationChangeData {
  consultation_id: number;
}
interface EtaUpdatedEventData extends ConsultationChangeData {
  consultation_state: ConsultationStateEnum;
  navigation_state: HomeVisitNavigationStateEnum;
  eta: string;
}

interface Props {
  consultation: ConsultationModel;
}

export const ConsultationHomeVisitsScheduled: React.FC<Props> = ({ consultation }) => {
  const started = true;

  const { notStarted } = useAppointmentState(consultation);
  const { navigate: navigateHealthProfile } = useNavigationHealthProfile();
  const { loading, cancelAppointment, updateConsultation } = useAppointmentContext();
  const [lastEtaData, setLastEtaData] = useState<EtaUpdatedEventData>();

  const updatedHomeVisitArrival = (
    event: ConsultationNavigationEventsEnum,
    data: ConsultationChangeData | EtaUpdatedEventData
  ) => {
    if (event === ConsultationNavigationEventsEnum.CONSULTATION_DOCTOR_NAVIGATION_ETA_UPDATED && 'eta' in data) {
      setLastEtaData(data);
    } else if (
      [
        ConsultationNavigationEventsEnum.CONSULTATION_DOCTOR_NAVIGATION_ENDED,
        ConsultationNavigationEventsEnum.CONSULTATION_DOCTOR_NAVIGATION_PAUSED,
        ConsultationNavigationEventsEnum.CONSULTATION_DOCTOR_NAVIGATION_STARTED,
        ConsultationNavigationEventsEnum.CONSULTATION_DOCTOR_NAVIGATION_RESUMED,
      ].includes(event)
    ) {
      updateConsultation(false);
    }
  };

  useConsultationIdChannel({ consultationID: consultation?.id, eventHandler: updatedHomeVisitArrival });

  const estimatedStartTime = useMemo(() => {
    if (consultation.doctor_navigation_data?.state) {
      switch (consultation.doctor_navigation_data.state) {
        case HomeVisitNavigationStateEnum.STARTED: {
          let eta = lastEtaData?.eta ?? consultation.doctor_navigation_data.current_location?.eta;
          if (lastEtaData?.consultation_id !== consultation?.id) {
            eta = consultation.doctor_navigation_data.current_location?.eta;
          }
          return parseDateTime(eta, {
            outputFormat: TIME_FORMAT,
          });
        }
        case HomeVisitNavigationStateEnum.ENDED:
          return 'Arriving soon';
        case HomeVisitNavigationStateEnum.PAUSED:
          break;
      }
    }
    return parseDateTime(consultation.start_at, { outputFormat: TIME_FORMAT });
  }, [consultation, lastEtaData]);

  return (
    <Column>
      {started ? (
        <View style={styles.headers}>
          <H3TtmSemiBoldBlack>The doctor is on his way</H3TtmSemiBoldBlack>
        </View>
      ) : (
        <>
          <View style={styles.headers}>
            <H3TtmSemiBoldBlack>Health Profile</H3TtmSemiBoldBlack>
          </View>
          <View style={{ display: 'flex', marginBottom: 30 }}>
            <OutlineButton funCallback={() => navigateHealthProfile(consultation)} label="View health profile" />
          </View>
        </>
      )}

      <View style={styles.timer}>
        {started ? (
          <>
            <H6NsRegularBlack>Estimated time of arrival</H6NsRegularBlack>
            <H1TtmSemiBoldBlack>{estimatedStartTime}</H1TtmSemiBoldBlack>
          </>
        ) : (
          <H6NsRegularBlack>
            When the doctor is on his way, you will be able to see the estimated of arrival here.
          </H6NsRegularBlack>
        )}
      </View>

      {notStarted ? (
        <View style={pb20}>
          <OutlineButton danger funCallback={cancelAppointment} label={labels.cancelAppointment} disabled={loading} />
        </View>
      ) : null}
    </Column>
  );
};

const styles = StyleSheet.create({
  headers: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  timer: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: colors.lightPurple2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 60,
    marginBottom: 20,
  },
});
