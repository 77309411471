import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';

import { useLoginDetails } from '../../api/hooks/accounts/LoginDetails';
import { OtpChannelEnum } from '../../api/models/otp/constants/OtpChannelEnum';
import { apiSendOtp } from '../../api/services/otp';
import { apiVerifyMobile } from '../../api/services/registration';
import { SuccessAlert } from '../../common/commonMethods';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import OtpScreenComponent from '../../components/otp/OtpScreenComponent';
import { labels } from '../../utils/labels';
import { screenName } from '../../utils/screenName';
interface Props {}

const OtpMobileValidationScreen: React.FC<Props> = (): JSX.Element => {
  const [showOtpWrongFlag, setShowOtpWrongFlag] = useState<boolean>(false);
  const [waiting, setWaiting] = useState(false);
  useLoginDetails();
  const navigation = useNavigation();

  const resendOTP = () => {
    setWaiting(true);
    apiSendOtp({ channel: OtpChannelEnum.SMS })
      .then(() => {
        SuccessAlert(['An sms was sent to you with a one time passcode']);
      })
      .catch(ErrorAlert)
      .finally(() => {
        setWaiting(false);
      });
  };

  const mobileNumberOtpValidation = (otp: string) => {
    if (otp.length === 6) {
      setWaiting(true);
      return apiVerifyMobile({ verification_code: otp })
        .then(() => {
          navigation.navigate(screenName.PassCodeRegisterScreen as never);
        })
        .catch((error) => {
          setShowOtpWrongFlag(true);
          ErrorAlert(error);
        })
        .finally(() => {
          setWaiting(false);
        });
    }
  };

  return (
    <OtpScreenComponent
      title={labels.enter6DigitOtp}
      onPress={mobileNumberOtpValidation}
      onResend={resendOTP}
      errorFlag={showOtpWrongFlag}
      waiting={waiting}
      subTitle={labels.enter6DigitOtpPhoneSubtitle}
      compressed
    />
  );
};

//make this component available to the app
export default OtpMobileValidationScreen;
