import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { PrivateNoteDataContext } from './PrivateNoteDataContext';

import { usePrivateNote } from '~/api/hooks/consultations/PrivateNoteHook';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { PrivateNoteModel } from '~/api/models/consultations/models/PrivateNoteModel';
import { useAutoSaveForm } from '~/utils/hooks/AutoSaveFormHook';

interface Props {
  consultation: ConsultationModel;
  children: React.ReactNode;
}

export const PrivateNoteDataProvider: React.FC<Props> = ({ consultation, children }) => {
  const {
    data: privateNoteData,
    setPrivateNote,
    getPrivateNote,
    loading,
    saving,
    error,
  } = usePrivateNote({ consultationId: consultation?.id });

  const privateNoteId = useRef<number>();
  const {
    reset,
    getValues,
    control,
    formState: { isDirty },
  } = useForm<PrivateNoteModel>({
    mode: 'all',
    reValidateMode: 'onBlur',
    shouldUnregister: false,
    defaultValues: {
      note: '',
    },
  });

  useAutoSaveForm({
    control,
    autoSave: async () => {
      try {
        const formData = getValues();
        const res = await setPrivateNote({ ...formData, id: privateNoteId.current });
        reset({ note: formData.note }, { keepValues: true });
        privateNoteId.current = res?.data?.id;
      } catch {}
    },
  });

  useEffect(() => {
    if (privateNoteData) {
      privateNoteId.current = privateNoteData.id;
      reset({ note: privateNoteData.note || '' });
    }
  }, [privateNoteData]);

  const verifyForm = async () => {
    // No validation needed
    return true;
  };

  const submit = async () => {
    if (isDirty) {
      const formData = getValues();
      await setPrivateNote(formData);
    }
  };

  return (
    <PrivateNoteDataContext.Provider
      value={{
        control,
        consultation,
        verifyForm,
        submit,
        loadData: getPrivateNote,
        loading,
        saving,
        savingError: error,
      }}>
      {children}
    </PrivateNoteDataContext.Provider>
  );
};
