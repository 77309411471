import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';

import { DependantCard } from '../DependantCard';

import { CountryListEnum, useCountryListing } from '~/api/hooks/referenceData/CountryListing';
import { DependantModel } from '~/api/models/dependants/models/DependantModel';
import { EditDependentRequest } from '~/api/models/dependants/requests/EditDependentRequest';
import { apiEditDependant } from '~/api/services/dependants';
import { flex1, mb10, mt20, ph5, pl0, pr0 } from '~/common/commonStyles';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalContainer } from '~/components/modals/ModalContainer';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import {
  emailValidation,
  maxLengthValidation,
  minLengthValidation,
  numberValidation,
  requiredValidation,
  validationSchema,
} from '~/services/validationConfig';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { labels } from '~/utils/labels';

interface Props {
  dependent: DependantModel;
}

interface Over18DetailsForm extends EditDependentRequest {}

export const DependentTurnedNotMinor: React.FC<Props> = ({ dependent }) => {
  const countryPhoneCodes = useCountryListing(CountryListEnum.PHONE_CODE);
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Over18DetailsForm>();

  const { closeModalByName } = useModalManager();

  const submit = (data: Over18DetailsForm) => {
    try {
      apiEditDependant({
        id: dependent.id,
        data,
      });
    } catch (e) {
      ErrorAlert(e);
    } finally {
      closeModalByName(ModalName.ModalTurnedNotMinor);
    }
  };

  const closeModal = () => {
    closeModalByName(ModalName.ModalTurnedNotMinor);
  };

  return (
    <ModalContainer showInfoLine padding="md" hideOnBackground onHide={closeModal}>
      <View>
        <H3TtmSemiBoldBlack>Congratulations!</H3TtmSemiBoldBlack>
        <View style={mb10} />

        <H6NsRegularBlack>
          Please input your child’s phone number and email address allowing them to start managing their profile.
        </H6NsRegularBlack>
        <View style={mt20} />

        <View style={{ flex: 1 }}>
          <Grid
            grid={{
              gutters: 16,
              noOuterGutter: true,
            }}>
            <Container alignSelf="flex-start" maxContainerWidths={750}>
              <Row>
                <DependantCard dependant={dependent} showAge onPress={null} />
              </Row>
              <View style={mt20} />

              <Row flex>
                <Column span={{ xs: 12, md: 12 }}>
                  <Controller
                    name="email"
                    control={control}
                    render={({
                      field: { value, onChange, onBlur },
                      fieldState: { error },
                      formState: { isSubmitted },
                    }) => (
                      <FloatingInput
                        value={value}
                        label={labels.emailAddress}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        error={isSubmitted && !!errors.email}
                        errorMessage={isSubmitted && error?.message}
                        showMandatory
                      />
                    )}
                    defaultValue=""
                    rules={{
                      required: requiredValidation(labels.emailAddress),
                      maxLength: maxLengthValidation(validationSchema.email.maxLength),
                      validate: { emailValidation },
                    }}
                  />
                </Column>
              </Row>
              <Row flex>
                <Column span={{ xs: 12, md: 12 }} style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={[flex1, ph5, pl0, { maxWidth: '35%' }]}>
                    <Controller
                      name="country_code"
                      control={control}
                      render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                        <Dropdown
                          list={countryPhoneCodes.map((item) => ({ label: item.name, value: item.id }))}
                          setValue={onChange}
                          value={value}
                          error={isSubmitted && !!errors?.country_code}
                          errorMessage={isSubmitted && error?.message}
                          searchable
                          showMandatory
                        />
                      )}
                      rules={{
                        required: requiredValidation('Country code'),
                      }}
                    />
                  </View>
                  <View style={[flex1, ph5, pr0]}>
                    <Controller
                      name="mobile_number"
                      control={control}
                      render={({
                        field: { value, onChange, onBlur },
                        fieldState: { error },
                        formState: { isSubmitted },
                      }) => (
                        <FloatingInput
                          showMandatory
                          maxLength={validationSchema.phoneNumber.maxLength}
                          error={isSubmitted && !!errors?.mobile_number}
                          errorMessage={isSubmitted && error?.message}
                          value={value}
                          onChangeValue={onChange}
                          onBlur={onBlur}
                          keyboardType="numeric"
                          label={labels.phoneNumber}
                        />
                      )}
                      rules={{
                        validate: { numberValidation },
                        required: requiredValidation(labels.phoneNumber),
                        minLength: minLengthValidation(validationSchema.phoneNumber.minLength),
                      }}
                    />
                  </View>
                </Column>
              </Row>
            </Container>
          </Grid>

          <View style={{ flex: 1, ...mt20 }}>
            <CancelContinueFooter
              confirmTitle={labels.save}
              onConfirm={handleSubmit(submit)}
              hideCancel
              disabled={!isDirty}
            />
          </View>
        </View>
      </View>
    </ModalContainer>
  );
};
