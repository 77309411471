import React from 'react';
import { View } from 'react-native';

import { PrescriptionsEditMode } from './Edit/PrescriptionsEditMode';
import { PrescriptionsViewMode } from './View/PrescriptionsViewMode';

import { mb10, p10 } from '~/common/commonStyles';
import { Accordion } from '~/components/buttons/Accordions/Accordion';
import { Pill } from '~/components/svgImages';
import { usePrescriptionDataContext } from '~/providers/consultation/PrescriptionDataContext';

interface Props {
  editable?: boolean;
}

export const Prescription: React.FC<Props> = ({ editable }) => {
  const {
    form: {
      formState: { isSubmitted, isValid },
    },
    loadData,
  } = usePrescriptionDataContext();
  return (
    <Accordion
      left={<Pill width={16} height={16} />}
      title="Prescription"
      style={mb10}
      error={editable && isSubmitted && !isValid}
      onExpandedChanged={(expanded) => expanded && loadData()}>
      <View style={editable ? p10 : null}>{editable ? <PrescriptionsEditMode /> : <PrescriptionsViewMode />}</View>
    </Accordion>
  );
};
