import { StyleProp, Text, TextStyle } from 'react-native';
import styled from 'styled-components/native';

import { colors } from '../utils/colors';
import { fontFamily } from '../utils/fontFamily';

const rawStyles = {
  black: {
    color: colors.black,
  },
  secondaryBlack: {
    color: colors.grey,
  },
  theme: {
    color: colors.purple,
  },
  red: {
    color: colors.danger,
  },
  placeholder: {
    color: colors.purpleGrey,
  },
  purple: {
    color: colors.purple,
  },
  link: {
    color: colors.info,
  },
  disableColor: {
    color: colors.purpleGrey,
  },
  purpleGrey: {
    color: colors.purpleGrey,
  },
  yellow: {
    color: colors.warning,
  },
  darkWarning: {
    color: colors.darkWarning,
  },
  white: {
    color: colors.white,
  },
  green: {
    color: colors.success,
  },
  info: {
    color: colors.info,
  },

  h1: {
    fontSize: 28,
  },
  h2: {
    fontSize: 24,
  },
  h3: {
    fontSize: 18,
  },
  h4: {
    fontSize: 16,
  },
  h5: {
    fontSize: 14,
  },
  h6: {
    fontSize: 14,
  },
  small: {
    fontSize: 12,
  },

  extraSmall: {
    fontSize: 10,
  },

  ttmSemiBold: {
    fontFamily: fontFamily.fontSemiBold,
  },
  ttmRegular: {
    fontWeight: 500,
    fontFamily: fontFamily.fontTitilliumRegular,
  },
  nsSemiBold: {
    fontFamily: fontFamily.fontBold,
  },
  nsRegular: {
    fontFamily: fontFamily.fontRegular,
  },
};

// const styles = StyleSheet.create(rawStyles);

export const { h1, h2, h3, h4, h5, h6, small, extraSmall } = rawStyles;
export const {
  black,
  secondaryBlack,
  theme,
  placeholder,
  link,
  red,
  purpleGrey,
  purple,
  yellow,
  darkWarning,
  white,
  green,
  info,
} = rawStyles;
export const { ttmSemiBold, ttmRegular, nsRegular, nsSemiBold } = rawStyles;

export const H1TtmSemiBoldBlack = styled.Text`
  ${h1};
  ${ttmSemiBold};
  ${black}
`;

export const H2TtmSemiBoldBlack = styled(Text)`
  ${h2}
  ${ttmSemiBold}
  ${black}
`;

export const H2TtmSemiBoldRed = styled(Text)`
  ${h2};
  ${ttmSemiBold};
  ${red}
`;

export const H2TtmSemiBoldSecondaryBlack = styled(Text)`
  ${h2};
  ${ttmSemiBold};
  ${secondaryBlack}
`;

export const H2TtmRegularSecondaryBlack = styled(Text)`
  ${h2};
  ${ttmRegular};
  ${secondaryBlack}
`;

export const H3TtmSemiBoldBlack = styled(Text)`
  ${h3};
  ${ttmSemiBold};
  ${black}
`;
export const H3TtmSemiBoldTheme = styled(Text)`
  ${h3};
  ${ttmSemiBold};
  ${theme}
`;
export const H3TtmSemiBoldWhite = styled(Text)`
  ${h3};
  ${ttmSemiBold};
  ${white}
`;
export const H3TtmRegularBlack = styled(Text)`
  ${h3};
  ${ttmRegular};
  ${black}
`;
export const H3TtmRegularSecondaryBlack = styled(Text)`
  ${h3};
  ${ttmRegular};
  ${secondaryBlack}
`;

export const H4NsSemiBoldSecondaryBlack = styled(Text)`
  ${h4};
  ${nsSemiBold};
  ${secondaryBlack}
`;

export const H4NsRegularSecondaryBlack = styled(Text)`
  ${h4};
  ${nsRegular};
  ${secondaryBlack}
`;
export const H4TtwRegularBlack = styled(Text)`
  ${h4};
  ${ttmRegular};
  ${black}
`;
export const H4TtwSemiBoldBlack = styled(Text)`
  ${h4};
  ${ttmSemiBold};
  ${black}
`;
export const H4NsRegularBlack = styled(Text)`
  ${h4};
  ${nsRegular};
  ${black}
`;
export const H4TtwRegularSemiBold = styled(Text)`
  ${h4};
  ${ttmRegular};
  ${secondaryBlack}
`;
export const H4TtmRegularSecondaryBlack = styled(Text)`
  ${h4};
  ${ttmRegular};
  ${secondaryBlack}
`;
export const H4TtmSemiBoldGreen = styled(Text)`
  ${h4};
  ${ttmSemiBold};
  ${green}
`;
export const H4TtmSemiBoldBlack = styled(Text)`
  ${h4};
  ${ttmSemiBold};
  ${black}
`;
export const H4TtmSemiBoldTheme = styled(Text)`
  ${h4};
  ${ttmSemiBold};
  ${theme}
`;

export const H5TtmSemiBoldTheme = styled(Text)`
  ${h5};
  ${ttmSemiBold};
  ${theme}
`;
export const H5TtmSemiBoldBlack = styled(Text)`
  ${h5};
  ${ttmSemiBold};
  ${black}
`;
export const H5TtmRegularBlack = styled(Text)`
  ${h5};
  ${ttmRegular};
  ${black}
`;
export const H5TtmRegularPlaceholder = styled(Text)`
  ${h5};
  ${ttmRegular};
  ${placeholder}
`;
export const H5TtmRegularTheme = styled(Text)`
  ${h5};
  ${ttmRegular};
  ${theme}
`;
export const H5TtmRegularInfo = styled(Text)`
  ${h5};
  ${ttmRegular};
  ${link}
`;

export const H5TtmRegularSecondaryBlack = styled(Text)`
  ${h5};
  ${ttmRegular};
  ${secondaryBlack}
`;

export const H5TtmSemiBoldInfo = styled(Text)`
  ${h5};
  ${ttmSemiBold};
  ${link}
`;

export const H5TtmSemiBoldDanger = styled(Text)`
  ${h5};
  ${ttmSemiBold};
  ${red}
`;
export const H5TtmSemiBoldSuccess = styled(Text)`
  ${h5};
  ${ttmSemiBold};
  ${green}
`;
export const H5TtmSemiBoldDarkWarning = styled(Text)`
  ${h5};
  ${ttmSemiBold};
  ${darkWarning}
`;

export const H5TtmSemiBoldDisableColor = styled(Text)`
  ${h5};
  ${ttmSemiBold};
  ${purpleGrey}
`;
export const H5NsSemiBoldSecondaryBlack = styled(Text)`
  ${h5};
  ${nsSemiBold};
  ${black}
`;

export const H6NsSemiBoldSecondaryBlack = styled(Text)`
  ${h6};
  ${nsSemiBold};
  ${secondaryBlack}
`;

export const H6NsSemiBoldTheme = styled(Text)`
  ${h6};
  ${nsSemiBold};
  ${theme}
`;
export const H6NsRegularSecondaryBlack = styled(Text)`
  ${h6};
  ${nsRegular};
  ${secondaryBlack}
`;
export const H6NsRegularPlaceholder = styled(Text)`
  ${h6};
  ${nsRegular};
  ${placeholder}
`;
export const H6NsRegularRed = styled(Text)`
  ${h6};
  ${nsRegular};
  ${red}
`;
export const H6NsRegularTheme = styled(Text)`
  ${h6};
  ${nsRegular};
  ${theme}
`;
export const H6NsSemiBoldLink = styled(Text)`
  ${h6};
  ${nsSemiBold};
  ${link}
`;
export const H6NsRegularDisableColor = styled(Text)`
  ${h6};
  ${nsRegular};
  ${purpleGrey}
`;
export const H6NsRegularBlack = styled(Text)<{
  style?: StyleProp<TextStyle>;
}>`
  ${h6};
  ${nsRegular};
  ${black};
  ${(props) => props.style};
`;
export const H6NsRegularWhite = styled(Text)`
  ${h6};
  ${nsRegular};
  ${white}
`;
export const H6NsSemiBoldBlack = styled(Text)<{
  style?: StyleProp<TextStyle>;
}>`
  ${h6};
  ${nsSemiBold};
  ${black};
  ${(props) => props.style};
`;
export const H6NsSemiBoldInfo = styled(Text)<{
  style?: StyleProp<TextStyle>;
}>`
  ${h6};
  ${nsSemiBold};
  ${info};
  ${(props) => props.style};
`;
export const H6NsSemiBoldRed = styled(Text)<{
  style?: StyleProp<TextStyle>;
}>`
  ${h6};
  ${nsSemiBold};
  ${red};
  ${(props) => props.style};
`;
export const H6NsRegularLink = styled(Text)`
  ${h6};
  ${nsRegular};
  ${link}
`;

export const H6TtmSemiBoldTheme = styled(Text)`
  ${h6};
  ${ttmSemiBold};
  ${theme}
`;

export const SmallNsRegularSecondaryBlack = styled(Text)<{
  style?: StyleProp<TextStyle>;
}>`
  ${small};
  ${nsRegular};
  ${secondaryBlack};
  ${(props) => props.style};
`;

export const SmallNsBoldLink = styled(Text)`
  ${small};
  ${nsSemiBold};
  ${link}
`;
export const SmallNsSemiBoldSecondaryBlack = styled(Text)`
  ${small};
  ${nsSemiBold};
  ${secondaryBlack}
`;
export const SmallNsRegularDisableColor = styled(Text)`
  ${small};
  ${nsRegular};
  ${purpleGrey}
`;
export const SmallNsRegularInfo = styled(Text)`
  ${small};
  ${nsRegular};
  ${link}
`;
export const SmallNsRegularHint = styled(Text)`
  ${extraSmall};
  ${nsRegular};
  ${purpleGrey}
`;
export const SmallNsSemiBoldTheme = styled(Text)`
  ${extraSmall};
  ${nsSemiBold};
  ${theme}
`;
export const SmallNsRegularTheme = styled(Text)`
  ${small};
  ${nsRegular};
  ${theme}
`;
export const SmallNsRegularBlack = styled(Text)`
  ${small};
  ${nsRegular};
  ${black}
`;
export const SmallNsRegularRed = styled(Text)`
  ${small};
  ${nsRegular};
  ${red}
`;
export const SmallNsRegularPurpleGrey = styled(Text)`
  ${small};
  ${nsRegular};
  ${purpleGrey}
`;
// export const SmallNsSemiBoldTheme = styled(Text)`
//    ${small};${nsSemiBold};${theme}
// `
export const SmallNsSemiBoldInfo = styled(Text)`
  ${small};
  ${nsSemiBold};
  ${link}
`;
export const SmallNsSemiBoldBlack = styled(Text)`
  ${small};
  ${nsSemiBold};
  ${black}
`;
export const SmallNsRegularPlaceholder = styled(Text)`
  ${small};
  ${nsRegular};
  ${placeholder}
`;
export const SmallNsSemiBoldRed = styled(Text)`
  ${small};
  ${nsSemiBold};
  ${red}
`;
export const SmallNsSemiBoldYellow = styled(Text)`
  ${small};
  ${nsSemiBold};
  ${yellow}
`;

export const SmallTtmDisabled = styled(Text)<{
  style?: StyleProp<TextStyle>;
}>`
  ${small};
  ${ttmRegular};
  ${purpleGrey};
  ${(props) => props.style};
`;
export const SmallTtmSemiBoldDisabled = styled(Text)<{
  style?: StyleProp<TextStyle>;
}>`
  ${small};
  ${ttmSemiBold};
  ${purpleGrey};
  ${(props) => props.style};
`;
export const SmallTtmRegularTheme = styled(Text)<{
  style?: StyleProp<TextStyle>;
}>`
  ${small};
  ${ttmRegular};
  ${purple};
  ${(props) => props.style};
`;
export const SmallTtmSemiboldTheme = styled(Text)<{
  style?: StyleProp<TextStyle>;
}>`
  ${small};
  ${ttmSemiBold};
  ${purple};
  ${(props) => props.style};
`;

export const FormErrorMessage = styled(Text)`
  ${small};
  ${nsRegular};
  ${red}
`;

export const ExtraSmallTtmSemiBold = styled(Text)`
  ${extraSmall};
  ${ttmSemiBold};
  ${black}
`;
export const ExtraSmallNsRegularTheme = styled(Text)`
  ${extraSmall};
  ${nsRegular};
  ${theme}
`;
export const ExtraSmallNsRegularWarning = styled(Text)`
  ${extraSmall};
  ${nsRegular};
  ${yellow}
`;
export const ExtraSmallNsRegularDanger = styled(Text)`
  ${extraSmall};
  ${nsRegular};
  ${red}
`;
export const ExtraSmallNsRegularInfo = styled(Text)`
  ${extraSmall};
  ${nsRegular};
  ${info}
`;
export const ExtraSmallNsRegularPlaceholder = styled(Text)`
  ${extraSmall};
  ${nsRegular};
  ${placeholder}
`;

export const ExtraSmallNsRegularBlack = styled(Text)`
  ${extraSmall};
  ${nsRegular};
  ${black}
`;
