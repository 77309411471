import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';

import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  iconOn: (color: string) => React.ReactNode;
  iconOff?: (color: string) => React.ReactNode;
  color?: string;
  backgroundColor?: string;
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export const CallIconButton: React.FC<Props> = ({
  value,
  onChange,
  iconOn,
  iconOff,
  color,
  disabled,
  backgroundColor,
}) => {
  const { isMobile } = useBreakpoints();
  return (
    <TouchableOpacity
      onPress={() => onChange(!value)}
      disabled={disabled}
      style={[
        iconButtonStyles.touchable,
        isMobile ? iconButtonStyles.touchableMobile : null,
        {
          backgroundColor: backgroundColor || (value ? (isMobile ? colors.grey : colors.white) : colors.danger),
        },
        disabled ? iconButtonStyles.touchableDisabled : null,
      ]}>
      {value ? iconOn(color || (isMobile ? colors.white : colors.purple)) : iconOff(color || colors.white)}
    </TouchableOpacity>
  );
};

const iconButtonStyles = StyleSheet.create({
  touchable: {
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.lightPurple2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginHorizontal: 10,
  },
  touchableMobile: {
    padding: 8,
    borderColor: colors.transparent,
  },
  touchableDisabled: {
    opacity: 0.5,
  },
});
