import { useReduxInfiniteScroll } from '../common/useReduxInfiniteScroll';

import { AccountsIndexFilter } from '~/api/models/accounts/filters/AccountsIndexFilter';
import { AccountsIndexResponse } from '~/api/models/accounts/responses/AccountsIndexResponse';
import { apiGetAccountIndex } from '~/api/services/accounts';
import { useReduxTablePagination } from '../common/useReduxTablePagination';
import { useSelector } from 'react-redux';
import { userDetailsSelector } from '~/redux/reducers/userDetailsReducer';

export function useNonDigimedPatientsList() {
  const user = useSelector(userDetailsSelector);
  const { items, loading, pagination, setPagination } = useReduxTablePagination<
    AccountsIndexFilter,
    AccountsIndexResponse
  >({
    isInfiniteScroll: true,
    tableName: 'nonDigimedPatients',
    getItems: async (params) =>
      apiGetAccountIndex({
        filter: {
          type: 'patient',
          is_digimed_user: false,
          has_email: true,
          created_by: user?.id,
        },
        limit: params.limit,
        page: params.page,
      }),
  });

  return { setPagination, nonDigimedPatients: items, pagination, loading };
}
