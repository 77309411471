import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React from 'react';

import { TabSelectionComponent } from '~/components/navigation/TabSelection/TabSelectionComponent';
import { PharmacyAccountDetailsScreen } from '~/pages/pharmacy/pharmacyDetails/PharmacyAccountDetailsScreen';
import { PharmacyLocationScreen } from '~/pages/pharmacy/pharmacyDetails/PharmacyLocationScreen';
import { isNative } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

const Tab = createMaterialTopTabNavigator();

export const menu = [
  { title: 'Edit account', link: { screen: screenName.PharmacyDetailsAccount } },
  { title: 'Location', link: { screen: screenName.PharmacyDetailsLocation } },
];

export const PharmacyNavigation: React.FC = () => {
  return (
    <Tab.Navigator
      screenOptions={{ swipeEnabled: isNative() }}
      tabBar={({ state }) => <TabSelectionComponent items={menu} forceMobile state={state} />}
      initialRouteName={screenName.PharmacyDetailsAccount}>
      <Tab.Screen name={screenName.PharmacyDetailsAccount} component={PharmacyAccountDetailsScreen} />
      <Tab.Screen name={screenName.PharmacyDetailsLocation} component={PharmacyLocationScreen} />
    </Tab.Navigator>
  );
};
