import React from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { wizardStyle } from '~/common/commonStyles';
import { Button, TextButton } from '~/components/commonButton';
import { ClinicalTermSearchableList } from '~/components/inputs/searchable/ClinicalTermSearchableList';
import { WizardLayout } from '~/layouts/WizardLayout';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';

export const SymptomsStep: React.FC = () => {
  const {
    formControl,
    loading,
    wizard: {
      actions: { next },
    },
  } = useAppointmentBookingContext();

  const buttons = [
    <Button label={labels.next} funCallback={next} disabled={loading} />,
    <TextButton funCallback={next} label={labels.notNow.toUpperCase()} disabled={loading} />,
  ];

  return (
    <View style={wizardStyle.container}>
      <WizardLayout
        title={appointmentLabels.selectSymptomsTitle}
        subtitle={appointmentLabels.selectSymptomsSubtitle}
        buttons={buttons}
        hideRequired>
        <Controller
          control={formControl}
          name="symptoms"
          render={({ field: { value, onChange }, formState: { isSubmitted }, fieldState: { error } }) => (
            <ClinicalTermSearchableList
              type={ClinicalTermTypeEnum.PATIENT_SYMPTOMS}
              values={value}
              setValues={onChange}
              title="Symptoms"
              error={isSubmitted && !!error?.message}
              errorMessage={isSubmitted && error?.message}
              style={{ width: '100%' }}
            />
          )}
        />
      </WizardLayout>
    </View>
  );
};
