import { Route } from '@react-navigation/native';
import React, { useEffect } from 'react';

import { ConsultationReportMobileHeader } from '../ConsultationReportMobileHeader';

import { useConsultationDetails } from '~/api/hooks/consultations/ConsultationDetails';
import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { HealthRecordEdit } from '~/components/doctor/patients/consultation/tabs/HealthRecord/Edit/HealthRecordEdit';
import { HealthRecordView } from '~/components/doctor/patients/consultation/tabs/HealthRecord/View/HealthRecordView';
import { GridLoadingWrapper } from '~/components/loading/GridLoadingWrapper';
import { ConsultationReportProvider } from '~/contexts/ConsultationReportProvider';
import { useHealthRecordDataContext } from '~/providers/consultation/HealthRecordDataContext';
import { HealthRecordDataProvider } from '~/providers/consultation/HealthRecordDataProvider';
import { Grid, Container } from '~/theme/components/grid';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { id: string; diagnosisId: string; consultationId: string }>;
  isEditing?: boolean;
}

export const Content: React.FC<Props> = ({ route, isEditing }) => {
  const { loadData } = useHealthRecordDataContext();

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Grid>
      <Container style={{ height: '100%' }}>
        <GridLoadingWrapper loading={false}>
          <PatientsLayout
            hideHeaderRight
            back={{ to: { screen: screenName.PatientConsultation, params: route.params } }}
            flexContent={false}>
            <ConsultationReportMobileHeader title="Health Record" noAutoSave={isEditing} />
            {isEditing ? <HealthRecordEdit /> : <HealthRecordView />}
          </PatientsLayout>
        </GridLoadingWrapper>
      </Container>
    </Grid>
  );
};

export const ConsultationHealthRecordMobile: React.FC<Props> = ({ route }) => {
  const { consultation, isEditing } = useConsultationDetails({ consultationId: +route.params.consultationId });

  return (
    <HealthRecordDataProvider consultation={consultation}>
      <ConsultationReportProvider>
        <Content route={route} isEditing={isEditing} />
      </ConsultationReportProvider>
    </HealthRecordDataProvider>
  );
};
