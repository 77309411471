import { Link } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { mt20, mr10, textCenter } from '~/common/commonStyles';
import { H6NsRegularSecondaryBlack, H6TtmSemiBoldTheme } from '~/components/commonText';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const DefaultRegistrationScreen: React.FC = () => {
  const buttons = [
    <View style={[{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }, mt20]}>
      <H6NsRegularSecondaryBlack style={[mr10, textCenter]}>
        {`${labels.alreadyIHaveAnAccount} `}

        <Link to={{ screen: screenName.LoginScreen } as never}>
          <H6TtmSemiBoldTheme>{labels.signIn.toUpperCase()}</H6TtmSemiBoldTheme>
        </Link>
      </H6NsRegularSecondaryBlack>
    </View>,
  ];

  const subtitle = (
    <View>
      <H6NsRegularSecondaryBlack>
        To register as a Clinic administrator you need to be invited.
      </H6NsRegularSecondaryBlack>
      <H6NsRegularSecondaryBlack>
        If you were provided a registration link please follow that link to register
      </H6NsRegularSecondaryBlack>
    </View>
  );

  return (
    <EnhancedRegistrationLayout
      compressed
      hideProgress
      title="Invitation required"
      subtitle={subtitle}
      buttons={buttons}
    />
  );
};
