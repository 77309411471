export enum ModalName {
  Main = 'main',
  PageInfo = 'pageInfo',
  UploadPatientDetails = 'uploadPatientDetails',
  DeleteAccountConfirmation = 'deleteAccountConfirmation',
  TotpEnrolment = 'enrolment',
  VerifyModal = 'verify',
  ProfileImage = 'profileImage',
  FaqMobileModal = 'faq',
  StripeCheckout = 'stripeCheckout',
  NotificationDotMenu = 'NotificationDotMenu',
  Map = 'map',
  UploadDataLog = 'uploadDataLog',
  MobileMenu = 'mobileMenu',
  NotifyPatients = 'notifyPatients',
  ConsultationTypeLearnMoreModal = 'consultationTypeLearnMoreModal',
  DependantLearnMoreModal = 'dependantLearnMoreModal',
  DoctorInfoModal = 'doctorModal',
  DoctorFavouriteModal = 'doctorFavouriteModal',
  PrescriptionDetailsModal = 'prescriptionDetailsModal',
  PrescriptionDispenseModal = 'prescriptionDispenseModal',
  PreventBack = 'preventBack',
  AppointmentDetails = 'appointmentDetails',
  ApprovalRequest = 'ApprovalRequestModal',
  ImagePreview = 'imagePreview',
  AppointmentNotification = 'appointmentNotification',
  RescheduleAppointmentMobile = 'rescheduleAppointmentMobile',
  PatientAppointmentRescheduleModal = 'PatientAppointmentRescheduleModal',
  DoctorAvailabilityModal = 'doctorAvailabilityModal',
  AppointmentFeedback = 'appointmentFeedback',
  CallSettings = 'callSettings',
  EndConsultation = 'endConsultation',
  CancelAppointment = 'cancelAppointment',
  FilterMenuModal = 'FilterMenuModal',
  InvitePharmacist = 'invitePharmacist',
  IncomeFilterModal = 'IncomeFilterModal',
  PaywallModal = 'paywallModal',
  PrescriptionRequestApproval = 'prescriptionRequestApproval',
  ModalTurnedNotMinor = 'ModalTurnedNotMinor',
  ICALUrlModal = 'ICALUrlModal',
  PharmacySearch = 'pharmacySearch',
  PharmacyQrSearch = 'pharmacyQrSearch',
  SaveFavouriteDoctorModal = 'SaveFavouriteDoctorModal',
  WebViewModal = 'WebViewModal',
  OpenUrlModal = 'OpenUrlModal',
  RejectConsultationReasonModal = 'RejectConsultationReasonModal',
}
