export enum NotificationCodeEnum {
  FAILED_PATIENT_IMPORT = 'failedPatientImport',
  SUCCESS_PATIENT_IMPORT = 'successPatientImport',
  FAILED_PATIENT_EXPORT = 'failedPatientExport',
  SUCCESS_PATIENT_EXPORT = 'successPatientExport',
  FAILED_INFORMATION_EXPORT = 'failedInformationExport',
  SUCCESS_INFORMATION_EXPORT = 'successInformationExport',
  FAILED_BILLING_EXPORT = 'failedBillingExport',
  SUCCESS_BILLING_EXPORT = 'successBillingExport',
  FAILED_DISPENSE_HISTORY_EXPORT = 'failedDispenseHistoryExport',
  SUCCESS_DISPENSE_HISTORY_EXPORT = 'successDispenseHistoryExport',
  FAILED_REQUEST_HISTORY_EXPORT = 'failedRequestHistoryExport',
  SUCCESS_REQUEST_HISTORY_EXPORT = 'successRequestHistoryExport',
  FAILED_INCOME_EXPORT = 'failedIncomeExport',
  SUCCESS_INCOME_EXPORT = 'successIncomeExport',
  SUCCESS_DEPENDANT_EXPORT = 'successDependantExport',
  FAILED_DEPENDANT_EXPORT = 'failedDependantsExport',
  SUCCESS_CONSULTATION_EXPORT = 'successConsultationExport',
  FAILED_CONSULTATION_EXPORT = 'failedConsultationExport',
  SUCCESS_APPOINTMENT_EXPORT = 'successAppointmentExport',
  FAILED_APPOINTMENT_EXPORT = 'failedAppointmentExport',
  APPROVED_KYI = 'approvedKyi',
  REJECTED_KYI = 'rejectedKyi',
  APPROVED_ROLE = 'approvedRole',
  REJECTED_ROLE = 'rejectedRole',
  INVOICE_UPCOMING = 'invoiceUpcoming',
  INVOICE_PAYMENT_FAILED = 'invoicePaymentFailed',
  INVOICE_UPCOMING_EXPIRED_CARD = 'invoiceUpcomingExpiredCard',
  STRIPE_SUBSCRIPTION_CANCELED = 'stripeSubscriptionCanceled',
  STRIPE_SUBSCRIPTION_TRIAL_WILL_END = 'stripeSubscriptionTrialWillEnd',
  DISABLED_ROLE = 'disabledRole',
  ASSOCIATION_REQUEST = 'doctorRequestsDataAccess',
  CONSULTATION_DRAFT = 'consultationDraft',
  CONSULTATION_NOT_SUBMITTED = 'consultationNotSubmitted',
  CONSULTATION_REQUEST_SUBMITTED = 'newConsultationRequestSubmitted',
  CONSULTATION_REQUEST_APPROVED = 'newConsultationRequestApproved',
  CONSULTATION_REQUEST_REJECTED = 'newConsultationRequestRejected',
  CONSULTATION_MESSAGE_SENT = 'consultationMessageSent',
  CONSULTATION_ENDING = 'consultationEnding',
  CONSULTATION_ENDED = 'consultationEnded',
  CONSULTATION_STARTED = 'consultationStarted',
  CONSULTATION_STARTING = 'consultationStarting',
  CONSULTATION_SUBMITTED = 'consultationSubmitted',
  CONSULTATION_RESCHEDULE_REQUEST = 'consultationRescheduleRequest',
  CONSULTATION_RESCHEDULE_REQUEST_ACCEPTED = 'consultationRescheduleRequestAccepted',
  CONSULTATION_RESCHEDULE_REQUEST_REJECTED = 'consultationRescheduleRequestRejected',
  SIX_MONTHS_AFTER_CONSULTATION = 'sixMonthsAfterConsultation',
  APPROVED_DEPENDANT = 'approvedDependant',
  REJECTED_DEPENDANT = 'rejectedDependant',
  CONSULTATION_CANCELED = 'consultationCanceled',
  CONSULTATION_MISSED_BY_DOCTOR = 'consultationMissedByDoctor',
  DEPENDANT_NO_LONGER_MINOR = 'dependantNoLongerMinor',

  PRESCRIPTION_REQUEST_CREATED = 'prescriptionRequestCreated',
  PRESCRIPTION_REQUEST_APPROVED = 'prescriptionRequestApproved',
  PRESCRIPTION_REQUEST_REJECTED = 'prescriptionRequestRejected',
  UNUSED_PRESCRIPTION_DOCTOR = 'unusedPrescriptionDoctor',
  UNUSED_PRESCRIPTION_PATIENT = 'unusedPrescriptionPatient',
  PRESCRIPTION_REMINDER = 'prescriptionReminder',
  UPCOMING_HOME_VISIT_CONSULTATION = 'upcomingHomeVisitConsultation',
  PRESCRIPTION_END_OF_CONSUMPTION_NOTIFICATION = 'prescriptionEndOfConsumptionNotification',
  PRESCRIPTION_COLLECTED_FROM_DIFFERENT_PHARMACY = 'prescriptionCollectedFromDifferentPharmacy',

  UPDATE_HEALTH_PROFILE = 'updateHealthProfile',
}
