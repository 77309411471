import { get, post } from '../../axios';
import { AddRoleRequest } from '../../models/roles/requests/AddRoleRequest';
import { GetRolesResponse } from '../../models/roles/responses/GetRolesResponse';

export const apiAddRole = (data: AddRoleRequest) => {
  return post('/me/roles', data);
};

export const apiGetRoles = () => {
  return get<GetRolesResponse>('/me/roles');
};
