import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { hasToken } from '~/api/axios/interceptors';
import { SubscriptionTypeEnum } from '~/api/models/subscriptions/models/SubscriptionPlanModel';
import { SubscriptionPlanMeResponse } from '~/api/models/subscriptions/responses/SubscriptionPlanMeResponse';
import { apiGetMeSubscriptionPlan } from '~/api/services/subscriptions';
import { UPDATE_SUBSCRIPTION_PLAN, subscriptionPlan } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { isClinicVersion, isPharmacyVersion } from '~/utils/buildConfig';

export const checkIsPremiumPlan = (plan: SubscriptionPlanMeResponse) => {
  return (
    plan && [SubscriptionTypeEnum.PREMIUMPATIENT, SubscriptionTypeEnum.PREMIUMDOCTOR].includes(plan?.subscription_type)
  );
};

export function useSubscriptions() {
  const waiting = useRef<boolean>(false);
  const dispatch = useAppDispatch();

  const currentPlan = useSelector(subscriptionPlan);

  const getCurrentSubscriptionPlan = async () => {
    try {
      if (isPharmacyVersion() || isClinicVersion()) return;
      if (waiting.current || !(await hasToken())) return;
      waiting.current = true;
      const res = await apiGetMeSubscriptionPlan();

      dispatch(UPDATE_SUBSCRIPTION_PLAN(res.data));
      waiting.current = false;
      return res.data;
    } catch {
      waiting.current = false;
    }
  };

  useEffect(() => {
    if (!currentPlan) getCurrentSubscriptionPlan();
  }, []);

  const isFreePlan = useMemo(
    () =>
      currentPlan &&
      [SubscriptionTypeEnum.FREEPATIENT, SubscriptionTypeEnum.FREEDOCTOR].includes(currentPlan?.subscription_type),
    [currentPlan]
  );
  const isPremiumPlan = useMemo(() => checkIsPremiumPlan(currentPlan), [currentPlan]);

  return { currentPlan, getCurrentSubscriptionPlan, isPremiumPlan, isFreePlan };
}
