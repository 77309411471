import React from 'react';
import { TouchableOpacity, StyleSheet, StyleProp, ViewStyle, View } from 'react-native';

import { H6NsRegularSecondaryBlack, nsSemiBold } from '../commonText';

import { alignItemsCenter, justifyCenter, justifyStart } from '~/common/commonStyles';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  selected?: boolean;
  children?: React.ReactNode;
  label?: string;
  icon?: (color: string) => React.ReactNode;
  style?: StyleProp<ViewStyle>;
  cardStyle?: StyleProp<ViewStyle>;
  center?: boolean;
  centerVertical?: boolean;
  disabled?: boolean;
  onPress?: () => void;
}

export const SelectableCard: React.FC<Props> = ({
  selected,
  children,
  label,
  icon,
  style,
  cardStyle,
  center,
  centerVertical,
  disabled,
  onPress,
}) => {
  const { isMobile } = useBreakpoints();
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled || !onPress} style={style}>
      <View
        style={[
          { display: 'flex', flexDirection: 'row', padding: 10, minHeight: isMobile ? 60 : null },
          center ? justifyCenter : justifyStart,
          center || centerVertical ? alignItemsCenter : undefined,
          styles.card,
          selected ? [styles.cardSelected] : null,
          disabled ? { opacity: 0.5 } : null,
          cardStyle,
        ]}>
        {children ?? (
          <>
            {icon ? icon(selected ? colors.purple : colors.grey) : null}
            <H6NsRegularSecondaryBlack
              style={[
                { marginLeft: icon ? 10 : 0 },
                center ? null : { flex: 1 },
                selected ? [{ color: colors.purple }, nsSemiBold] : null,
              ]}>
              {label}
            </H6NsRegularSecondaryBlack>
          </>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  card: {
    borderColor: colors.borderGrey,
    borderWidth: 1,
    borderRadius: 6,
  },
  cardGrey: {
    backgroundColor: colors.lightGrey,
  },
  cardSelected: {
    shadowColor: colors.info,
    borderColor: colors.purple,
    backgroundColor: colors.lightPurple,
  },
});
