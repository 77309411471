import React from 'react';
import { View, StyleSheet } from 'react-native';

import { ToggleSwitch } from '../buttons/ToggleSwitch';
import { H3TtmSemiBoldBlack, H5TtmSemiBoldBlack, SmallNsRegularBlack } from '../commonText';

import { useBiometrics } from '~/api/hooks/BiometricsHook';
import { pb20 } from '~/common/commonStyles';
import { colors } from '~/utils/colors';

export const BiometricsToggle = () => {
  const { deviceIsEnrolled, setDeviceIsEnrolled, deviceSensorAvailable, enrollDevice, unenrollDevice } =
    useBiometrics();

  const enrollUnenrollDevice = async () => {
    if (deviceIsEnrolled) {
      unenrollDevice();
    } else {
      enrollDevice();
    }
  };

  return (
    <View style={styles.container}>
      <View style={pb20}>
        <H3TtmSemiBoldBlack>Allow Biometric Authentication</H3TtmSemiBoldBlack>
        <SmallNsRegularBlack>Log in using fingerprint or face ID</SmallNsRegularBlack>
      </View>
      {deviceSensorAvailable ? (
        <ToggleSwitch value={deviceIsEnrolled} setValue={setDeviceIsEnrolled} onToggleFunction={enrollUnenrollDevice} />
      ) : (
        <H5TtmSemiBoldBlack>
          Your device's biometric sensor is either unavailable or disabled. If your biometric sensor is available and
          wish to use biometrics, please enable this from your device's settings application.
        </H5TtmSemiBoldBlack>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: colors.lightPurple2,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingVertical: 20,
  },
});
