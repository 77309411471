import Feather from '@expo/vector-icons/Feather';
import React, { useState } from 'react';
import { Pressable, View } from 'react-native';

import { DataTable } from '../common/DataTable/DataTable';
import { DataTableColumns } from '../common/DataTable/DataTableTypes';
import { OutlineButton } from '../commonButton';
import { H6NsRegularBlack, H6NsSemiBoldBlack, SmallNsRegularBlack } from '../commonText';
import { ConsultationViewItem } from '../doctor/patients/consultation/ConsultationViewItem';
import { StatusBadge } from '../misc/StatusBadge';
import { ModalName } from '../modals/constants/ModalNames';
import { ProfileDetailsComponent } from '../profile/ProfileDetailsComponent';
import { PageTabItem } from '../tabs/PageTabsComponent';
import { TabsComponent } from '../tabs/TabsComponent';

import { usePrescriptionList } from '~/api/hooks/patients/PrescriptionsList';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { apiGetPrescriptionMedia } from '~/api/services/consultations/prescriptions';
import { flex1, mb5, ml15, mr10, mr15 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';
import { labels } from '~/utils/labels';
import { PrescriptionMobileItem } from './PrescriptionMobileItem';
import { PRESCRIPTION_COLLECTION_STATUS_COLOR_MAPPING } from '~/constants/prescriptions/statusColors';

export const PrescriptionsTable: React.FC = () => {
  const [sortDesc, setSortingDesc] = useState(true);
  const { showNewBadge } = useNewBadge();
  const { setPagination, loading, prescriptions, pagination, isActive, setIsActive } = usePrescriptionList({
    sort: sortDesc ? '-created_at' : 'created_at',
  });
  const { isMobile } = useBreakpoints();
  const [loadingReq, setLoadingReq] = useState(false);

  const { openModal } = useModalManager();

  const downloadPrescription = async (prescription: PrescriptionModel): Promise<MediaModel> => {
    try {
      setLoadingReq(true);
      const res = await apiGetPrescriptionMedia({ id: prescription.id });
      if (res.data?.media?.length) return res.data.media[0];
      else throw new Error('We were not able to retrieve the prescription pdf at this time');
    } finally {
      setLoadingReq(false);
    }
  };

  const openPrescription = (prescription: PrescriptionModel) => {
    if (loadingReq) return;

    openModal(ModalName.PrescriptionDetailsModal, {
      prescription,
      doctor: prescription.doctor,
      patient: prescription.patient,
    });
  };

  const columns: DataTableColumns = [
    {
      key: 'product',
      title: 'Prescription',
      cell: (cellData, itemData: PrescriptionModel) => (
        <View style={{ flex: 1 }}>
          <H6NsSemiBoldBlack style={{ textWrap: 'wrap' }}>{extractClinicalTermDescription(cellData)}</H6NsSemiBoldBlack>
          <SmallNsRegularBlack>Ref: {itemData.reference_number}</SmallNsRegularBlack>
        </View>
      ),
      columnStyle: { maxWidth: 500 },
    },
    {
      key: 'collection_status',
      title: 'Status',
      hide: () => !isActive,
      cell: (cellData) => (
        <StatusBadge
          label={cellData}
          color={PRESCRIPTION_COLLECTION_STATUS_COLOR_MAPPING[cellData]}
          textStyle={{ textTransform: 'capitalize' }}
        />
      ),
      columnStyle: { width: 80 },
    },
    {
      key: 'prescribed_by',
      title: 'Prescribed by',
      cell: (_, itemData: PrescriptionModel) => (
        <ProfileDetailsComponent account={itemData.doctor} theme="table" isDoctor style={flex1} />
      ),
      columnStyle: {
        minWidth: 200,
      },
    },
    {
      key: 'Patient Details',
      title: 'Patient details',
      cell: (_, itemData: PrescriptionModel) => (
        <ProfileDetailsComponent account={itemData.patient} theme="table" style={flex1} />
      ),
      columnStyle: {
        minWidth: 200,
      },
    },
    {
      key: 'created_at',
      title: 'Prescribed date',
      cell: (cellData) => (
        <View>
          <H6NsRegularBlack>{cellData}</H6NsRegularBlack>
        </View>
      ),
      selector: (itemData: PrescriptionModel) => parseDateTime(itemData.created_at, { outputFormat: DATE_FORMAT }),
      columnStyle: { maxWidth: 120 },
    },
    {
      key: 'actions',
      title: '',
      cell: (_, itemData: PrescriptionModel) => (
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {itemData.is_collectable ? (
            <ConsultationViewItem
              style={{ padding: 0 }}
              getMediaModel={async () => {
                return downloadPrescription(itemData);
              }}
              size={14}
              disableView
              withBottomBorder={false}
              status={null}
            />
          ) : null}
          <View style={ml15}>
            <OutlineButton
              label={labels.view}
              style={{ minWidth: 100 }}
              funCallback={() => openPrescription(itemData)}
            />
          </View>
        </View>
      ),
      columnStyle: { maxWidth: 250, minWidth: 180 },
      contentNumeric: true,
      showOnHover: true,
    },
  ];

  const tabItems: PageTabItem[] = [
    { title: 'Active', key: 'active', onPress: () => setIsActive(true) },
    { title: 'Used', key: 'used', onPress: () => setIsActive(false) },
  ];
  const sorting = isMobile ? null : (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-end',
        ...mr15,
      }}>
      <Pressable
        style={{ paddingVertical: 8, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        onPress={() => {
          setSortingDesc(!sortDesc);
        }}>
        <H6NsRegularBlack style={mr10}>Sort by Date</H6NsRegularBlack>
        <Feather name={sortDesc ? 'chevron-down' : 'chevron-up'} size={14} />
      </Pressable>
    </View>
  );

  return (
    <View style={{ flex: 1, display: 'flex' }}>
      <View style={[{ paddingHorizontal: isMobile ? MOBILE_HORIZONTAL_SPACE : 0 }, mb5]}>
        <TabsComponent
          items={tabItems}
          joinedBackground={!isMobile}
          right={sorting}
          active={isActive ? 'active' : 'used'}
          fluid
          compact
        />
      </View>
      <View style={{ flex: 1, display: 'flex' }}>
        <DataTable
          totalCount={pagination?.total || 0}
          page={pagination?.current_page}
          onPageChanged={setPagination}
          onItemsPerPageChange={setPagination}
          infiniteScroll={{ loading, estimatedItemSize: { desktop: 60, mobile: 110 } }}
          numberOfItemsPerPage={pagination?.per_page}
          tableId="prescriptionsList"
          columns={columns}
          items={!isMobile ? (loading ? [] : prescriptions) : prescriptions}
          loading={loading}
          mobileBreakpoint={DeviceSizeDefaults.tablet}
          emptyNote="No Prescriptions records available."
          onRowPress={(_, item) => openPrescription(item)}
          rowIsNewIdentifier={(item: PrescriptionModel) => showNewBadge({ value: item, timeKey: 'updated_at' })}
          mobileRenderItem={(item: PrescriptionModel) => {
            return (
              <PrescriptionMobileItem
                onGetMediaModel={downloadPrescription}
                onView={openPrescription}
                prescription={item}
              />
            );
          }}
        />
      </View>
    </View>
  );
};
