import React from 'react';

import { IncomeListItem } from './IncomeListItem';
import { DataTable, DataTableColumns } from '../../../common/DataTable/DataTable';

import { ConsultationTypeMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { IncomeIndex } from '~/api/models/income/responses/IncomeResponse';
import { InvoiceActionButtons } from '~/components/buttons/InvoiceActionButtons';
import { H6NsRegularBlack, H6NsSemiBoldBlack, SmallNsRegularPlaceholder } from '~/components/commonText';
import { useIncomeListingContext } from '~/providers/income/IncomeListingContext';
import { DATE_TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const IncomeTable: React.FC = () => {
  const { incomeListData, loading, setPage } = useIncomeListingContext();

  const columns: DataTableColumns = [
    {
      key: 'consultation_Id',
      title: 'Consultation ID',
      columnStyle: {
        maxWidth: 120,
      },
      cell: (_, itemData: IncomeIndex) => {
        return <H6NsSemiBoldBlack>#{itemData.model_id}</H6NsSemiBoldBlack>;
      },
    },
    {
      key: 'started_at',
      title: 'Consultation started at',
      cell: (_, itemData: IncomeIndex) => {
        return (
          <>
            {parseDateTime(itemData.model.started_at ?? itemData.model.start_at, { outputFormat: DATE_TIME_FORMAT })}
            {itemData.model.state === ConsultationStateEnum.Cancelled ? (
              <SmallNsRegularPlaceholder> Cancelled</SmallNsRegularPlaceholder>
            ) : null}
          </>
        );
      },
    },

    {
      key: 'consultation_type',
      title: 'Consultation type',

      cell: (_, itemData: IncomeIndex) => {
        return <H6NsRegularBlack>{ConsultationTypeMapping[itemData.model.type]}</H6NsRegularBlack>;
      },
    },
    {
      key: 'date',
      title: 'Billed at',

      cell: (_, itemData: IncomeIndex) => {
        return parseDateTime(itemData.processed_at, { outputFormat: DATE_TIME_FORMAT });
      },
    },
    {
      key: 'amount',
      title: 'Net amount',
      columnStyle: {
        maxWidth: 100,
      },
      cell: (_, itemData: IncomeIndex) => {
        return <H6NsRegularBlack>{itemData.amount.formatted_amount}</H6NsRegularBlack>;
      },
    },
    {
      key: 'action',
      columnStyle: {
        maxWidth: 100,
      },
      cell: (_, itemData: IncomeIndex) => (
        <InvoiceActionButtons
          transactionId={itemData.id}
          invoices={itemData.invoices}
          statementOfFeesStatus={itemData.statement_of_fees_status}
        />
      ),
    },
  ];
  const { isMobile } = useBreakpoints();
  return (
    <DataTable
      tableId="incomeTable"
      identifier="model_id"
      columns={columns}
      nestedScrollEnabled
      items={incomeListData?.data?.length > 0 ? incomeListData?.data : []}
      infiniteScroll={isMobile ? { loading, estimatedItemSize: { desktop: 60, mobile: 110 } } : null}
      page={incomeListData?.meta?.current_page}
      totalCount={incomeListData?.meta?.total}
      numberOfItemsPerPage={incomeListData?.meta?.per_page}
      emptyNote="No transactions found"
      loading={loading}
      hidePagination={!!isMobile}
      onPageChanged={setPage}
      mobileRenderItem={(item) => <IncomeListItem income={item} />}
      alwaysShowPagination
    />
  );
};
