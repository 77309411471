import React, { useEffect, useMemo, useRef } from 'react';
import { FlatList, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { DataTable as RNDataTable } from 'react-native-paper';

import { DataTableCard } from './DataTableCard';
import { DataTableRow } from './DataTableRow';
import { DataTableProps, DEFAULT_TABLE_ITEMS_PER_PAGE } from './DataTableTypes';
import { Pagination } from './Pagination';
import { getParsedAsyncItem, storageKeys } from '../../../common/asyncStorage';
import { colors } from '../../../utils/colors';

import { p15, textCenter } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { SmallNsRegularSecondaryBlack } from '~/components/commonText';
import { EndOfList } from '~/components/loading/EndOfList';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { roundedBorder } from '~/styles/commonStyling';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const DataTable: React.FC<DataTableProps> = ({
  numberOfItemsPerPage = DEFAULT_TABLE_ITEMS_PER_PAGE,
  items,
  totalCount,
  columns,
  rowStyle,
  rowHoverStyle,
  identifier = 'id',
  style,
  contentStyle,
  wrapperStyle,
  hidePagination,
  tableId,
  page = 1,
  infiniteScroll,
  scrollEnabled,
  loading,
  hideHeaders,
  headers,
  listHeader,
  emptyNote,
  hideHeaderWhenEmpty,
  selected,
  mobileBreakpoint = DeviceSizeDefaults.mobile,
  forceMobileView,
  mobileRenderItem,
  rowIsNewIdentifier,
  onRowPress,
  onPageChanged,
  onItemsPerPageChange,
  dataTableCardStyle,
  alwaysShowPagination,
  nestedScrollEnabled = false,
  notAbsolute,
  noHorizontalScroll = false,
}) => {
  const { windowWidth, getByDeviceSize, isMobile } = useBreakpoints();
  const parentRef = useRef(null);

  useEffect(() => {
    !infiniteScroll && onPageChanged && onPageChanged({ page: 1, limit: numberOfItemsPerPage });
  }, [numberOfItemsPerPage]);

  useEffect(() => {
    if (tableId && onItemsPerPageChange) {
      getParsedAsyncItem(storageKeys.tableDetails + tableId).then((tableDetails) => {
        if (tableDetails && tableDetails.perPage) {
          onItemsPerPageChange(tableDetails.perPage);
        }
      });
    }
  }, [tableId]);

  const renderForMobile = useMemo(
    () => forceMobileView || (mobileBreakpoint && windowWidth <= mobileBreakpoint),
    [windowWidth, mobileBreakpoint, forceMobileView]
  );

  const filteredColumns = useMemo(() => {
    return columns.filter(
      (col) =>
        col &&
        (!col.hide || (typeof col.hide === 'function' ? !col.hide(col.key) : !col.hide)) &&
        getByDeviceSize<boolean>({ mobile: !col.hideMobile, tablet: !col.hideTablet, desktop: true })
    );
  }, [columns, renderForMobile]);

  const endOfList = useMemo(() => {
    return totalCount <= page * numberOfItemsPerPage;
  }, [totalCount, page, numberOfItemsPerPage]);

  const estimatedItemSize = useMemo(() => {
    if (!infiniteScroll || !infiniteScroll.estimatedItemSize) return null;
    if (typeof infiniteScroll.estimatedItemSize === 'number') return infiniteScroll.estimatedItemSize;
    return renderForMobile ? infiniteScroll.estimatedItemSize.mobile : infiniteScroll.estimatedItemSize.desktop;
  }, [infiniteScroll?.estimatedItemSize, renderForMobile]);

  const numberOfItemsToGet = useMemo(() => {
    const estimateItemCount = Math.ceil((parentRef?.current?.offsetHeight || 0) / (estimatedItemSize || 50));
    return Math.ceil(Math.max(estimateItemCount, 5) * 1.5);
  }, [estimatedItemSize, parentRef?.current?.offsetHeight]);

  const fetchInfiniteData = () => {
    if (page === 0) {
      onPageChanged({ page: page + 1, limit: numberOfItemsToGet });
    } else if (!endOfList && !infiniteScroll.loading) {
      onPageChanged({ page: page + 1, limit: numberOfItemsPerPage });
    }
  };
  return (
    <View style={[notAbsolute ? null : styles.absolute, styles.containerFlex]} ref={parentRef}>
      <RNDataTable style={[styles.table, style]}>
        <View style={styles.dataContainer}>
          <View style={[styles.content, renderForMobile ? styles.contentMobile : null, wrapperStyle]}>
            <ScrollViewOrView useScrollView={!renderForMobile} noHorizontalScroll={noHorizontalScroll}>
              <>
                <>
                  {renderForMobile || hideHeaders || (!items?.length && emptyNote && hideHeaderWhenEmpty) ? null : (
                    <RNDataTable.Header>
                      <>
                        {headers
                          ? headers()
                          : filteredColumns.map((column) => {
                              return (
                                <RNDataTable.Title
                                  sortDirection={column.sortDirection}
                                  key={column.key}
                                  numeric={column.headerNumeric}
                                  style={[styles.baseColumnStyle, column.columnStyle, column.headerStyle]}
                                  textStyle={[styles.headerText, column.headerTextStyle, { paddingLeft: 5 }]}
                                  onPress={
                                    column.onHeaderPress
                                      ? () => {
                                          column.onHeaderPress(column.key, column);
                                        }
                                      : undefined
                                  }>
                                  {column.headerChildren || column.title}
                                </RNDataTable.Title>
                              );
                            })}
                      </>
                    </RNDataTable.Header>
                  )}
                </>
                <>
                  <FlatList
                    horizontal={false}
                    nestedScrollEnabled={nestedScrollEnabled} //for mobile nested scrollviews
                    keyExtractor={(item) => item[identifier]}
                    data={infiniteScroll || !loading ? items : []}
                    contentInsetAdjustmentBehavior="automatic"
                    // estimatedItemSize={estimatedItemSize}
                    onEndReachedThreshold={infiniteScroll ? infiniteScroll.endReachedThreshold || 0.2 : null}
                    onEndReached={infiniteScroll ? fetchInfiniteData : null}
                    extraData={[selected]}
                    scrollEnabled={scrollEnabled}
                    contentContainerStyle={[contentStyle, renderForMobile ? { paddingTop: 5 } : null]}
                    ListEmptyComponent={
                      emptyNote && !(infiniteScroll?.loading || loading) ? (
                        typeof emptyNote === 'string' ? (
                          <View
                            style={
                              renderForMobile
                                ? [roundedBorder, { marginHorizontal: isMobile ? MOBILE_HORIZONTAL_SPACE : 0 }]
                                : [p15, { paddingLeft: 20 }]
                            }>
                            <SmallNsRegularSecondaryBlack style={textCenter}>{emptyNote}</SmallNsRegularSecondaryBlack>
                          </View>
                        ) : (
                          emptyNote
                        )
                      ) : null
                    }
                    ListHeaderComponent={listHeader}
                    ListFooterComponent={
                      infiniteScroll?.loading || loading ? (
                        <LoadingActivityIndicator />
                      ) : endOfList ? (
                        items?.length ? (
                          <EndOfList />
                        ) : null
                      ) : null
                    }
                    renderItem={({ item, index }) => {
                      const isSelected = !!selected?.length && selected.includes(item[identifier]);
                      return renderForMobile ? (
                        <View
                          style={{
                            paddingHorizontal: nestedScrollEnabled ? 0 : isMobile ? MOBILE_HORIZONTAL_SPACE : 0,
                          }}>
                          <DataTableCard
                            item={item}
                            identifier={item[identifier]}
                            onRowPress={onRowPress}
                            filteredColumns={filteredColumns}
                            mobileRenderItem={mobileRenderItem}
                            selected={isSelected}
                            cardStyle={dataTableCardStyle}
                            index={index}
                            rowIsNewIdentifier={rowIsNewIdentifier}
                          />
                        </View>
                      ) : (
                        <DataTableRow
                          item={item}
                          identifier={item[identifier]}
                          rowHoverStyle={rowHoverStyle}
                          rowStyle={rowStyle}
                          onRowPress={onRowPress}
                          filteredColumns={filteredColumns}
                          selected={isSelected}
                          index={index}
                          rowIsNewIdentifier={rowIsNewIdentifier}
                        />
                      );
                    }}
                  />
                </>
              </>
            </ScrollViewOrView>
          </View>
        </View>
      </RNDataTable>
      {hidePagination || !!infiniteScroll ? null : (
        <Pagination
          page={page}
          totalCount={totalCount}
          numberOfItemsPerPage={numberOfItemsPerPage}
          onPageChanged={onPageChanged}
          alwaysShow={alwaysShowPagination}
        />
      )}
    </View>
  );
};

interface Props {
  children: React.ReactNode;
  useScrollView: boolean;
  noHorizontalScroll: boolean;
}

export const ScrollViewOrView: React.FC<Props> = ({ children, useScrollView, noHorizontalScroll }) => {
  if (!useScrollView) return <>{children}</>;
  return (
    <ScrollView
      horizontal
      contentContainerStyle={[
        { flexDirection: 'column', minWidth: '100%' },
        noHorizontalScroll ? { maxWidth: '100%' } : null,
      ]}
      children={children}
    />
  );
};
const styles = StyleSheet.create({
  table: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  absolute: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  containerFlex: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  dataContainer: {
    display: 'flex',
    flex: 1,
    alignContent: 'stretch',
    width: '100%',
  },
  content: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.lightPurple2,
    display: 'flex',
    flex: 1,
    alignContent: 'stretch',
    overflow: 'hidden',
  },
  contentMobile: {
    borderWidth: 0,
    borderRadius: 0,
  },
  headerText: {
    fontWeight: 'bold',
    color: colors.black,
    fontFamily: 'NotoSans',
  },
  baseColumnStyle: {
    maxWidth: 400,
    minWidth: 120,
  },
});

export * from './DataTableTypes';
