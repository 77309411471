import React from 'react';

import { ConsultationBillingBreakdownStep } from '../steps/ConsultationBillingBreakdownStep';
import { ConsultationConfirmationStep } from '../steps/ConsultationConfirmationStep';
import { ConsultationTypeSelectionStep } from '../steps/ConsultationTypeSelectionStep';
import { HomeVisitLocationStep } from '../steps/HomeVisitLocationStep';
import { HomeVisitSlotStep } from '../steps/HomeVisitSlotStep';
import { ListDoctorsStep } from '../steps/ListDoctorsStep';
import { ReasonForConsultationStep } from '../steps/ReasonForConsultationStep';
import { ScheduledSlotStep } from '../steps/ScheduledSlotStep';
import { SelectPatientStep } from '../steps/SelectPatientStep';
import { SickLeaveStep } from '../steps/SickLeaveStep';
import { SymptomsStep } from '../steps/SymptomsStep';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { WizardSteps } from '~/utils/hooks/WizardStepsHook';

export interface AppointmentStepsInitialValues {
  hasDependants?: boolean;
  doctorId?: number;
  clinicId?: number;
  channelId?: number;
  consultationId?: number;
  consultationType?: ConsultationTypeEnum;
}

const commonPreSteps = (values?: AppointmentStepsInitialValues): WizardSteps => [
  {
    key: 'patient_selection',
    skip: () => !values?.hasDependants,
    component: <SelectPatientStep />,
  },
  {
    key: 'consultation_type',
    component: <ConsultationTypeSelectionStep />,
    skip: () =>
      !!(
        values?.consultationId ||
        values?.consultationType ||
        (values?.channelId && (values?.doctorId || values?.clinicId))
      ),
  },
  { key: 'reason', component: <ReasonForConsultationStep /> },
  { key: 'symptoms', component: <SymptomsStep /> },
  { key: 'sick_leave', component: <SickLeaveStep /> },
];

const commonPostSteps = (): WizardSteps => [
  { key: 'confirmation', component: <ConsultationConfirmationStep /> },
  { key: 'payment', component: <ConsultationBillingBreakdownStep /> },
];

export const DoctorAvailableNowSteps = (values?: AppointmentStepsInitialValues): WizardSteps => [
  ...commonPreSteps(values),
  {
    key: 'list_doctors',
    component: <ListDoctorsStep />,
    skip: () => !!(values?.channelId && (values?.doctorId || values?.clinicId)),
  },
  ...commonPostSteps(),
];

export const HomeVisitSteps = (values?: AppointmentStepsInitialValues): WizardSteps => [
  ...commonPreSteps(values),
  { key: 'location', component: <HomeVisitLocationStep /> },
  { key: 'availability', component: <HomeVisitSlotStep /> },
  { key: 'list_doctors', component: <ListDoctorsStep /> },
  ...commonPostSteps(),
];

export const ScheduledAppointmentsSteps = (values?: AppointmentStepsInitialValues): WizardSteps => [
  ...commonPreSteps(values),
  {
    key: 'list_doctors',
    component: <ListDoctorsStep />,
    skip: () => !!(values?.consultationId || (values?.channelId && (values?.doctorId || values?.clinicId))),
  },
  { key: 'select_slot', component: <ScheduledSlotStep /> },
  ...commonPostSteps(),
];
