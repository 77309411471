import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { DoctorClinicName } from './DoctorClinicName';
import { DoctorDetailModal } from './DoctorDetailModal';
import { DoctorFavouriteButton } from './DoctorFavouriteButton';
import { DoctorOnDemandIndicator } from './DoctorOnDemandIndicator';
import { RatingViewComponent } from './RatingViewComponent';

import {
  AvailableDoctorModel,
  AvailableDoctorModelWithQueue,
} from '~/api/models/appointments/models/AvailableDoctorModel';
import { AvailableDoctorsListingModel } from '~/api/models/appointments/responses/AvailableDoctorsResponse';
import { ConsultationTypeEnum, ConsultationTypeMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { flex1, mb5, ml10, mr10, mt10, pb20, textCenter } from '~/common/commonStyles';
import { CustomAccordion } from '~/components/buttons/Accordions/CustomAccordion';
import { DataTable, DataTableColumns, TablePaginationInfo } from '~/components/common/DataTable/DataTable';
import { Button } from '~/components/commonButton';
import {
  ExtraSmallNsRegularWarning,
  H4TtmSemiBoldTheme,
  H6NsRegularSecondaryBlack,
  H6NsSemiBoldBlack,
  H6NsSemiBoldLink,
} from '~/components/commonText';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { useDoctorListingContext } from '~/providers/appointment/DoctorListingContext';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { formatAmount } from '~/utils/amountUtil';
import { idNameArrayToString } from '~/utils/arrayUtil';
import { colors } from '~/utils/colors';
import { getQueueInfo } from '~/utils/consultationUtils';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { getAccountName, getDoctorExperience, getInitials } from '~/utils/personalDetailsUtils';

interface Props {
  doctors: AvailableDoctorsListingModel;
  consultationType: ConsultationTypeEnum;
  initialExpanded?: boolean;
  setPage: (pageInfo: TablePaginationInfo) => void;
}
export const DoctorListingComponent: React.FC<Props> = ({ doctors, consultationType, initialExpanded, setPage }) => {
  const { loading, selectedDoctorOrClinic, isListing } = useDoctorListingContext();
  const { isMobile } = useBreakpoints();
  const { openModal, registerModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.DoctorInfoModal, DoctorDetailModal);
  }, []);

  const columns: DataTableColumns = [
    {
      key: 'doctor',
      title: 'Specialist',
      columnStyle: {
        minWidth: 200,
      },
      cell: (_, itemData: AvailableDoctorModelWithQueue) => {
        const queueInfoRes = !!itemData.queue && getQueueInfo(itemData.queue);

        const name = getAccountName(itemData);
        const specialisations = idNameArrayToString(itemData?.specialisations, 2);
        return (
          <View style={[{ display: 'flex', flexDirection: 'row', marginVertical: 5, maxWidth: '100%' }]}>
            <View style={[mr10, { alignSelf: 'flex-start' }]}>
              <ProfileImageComponent
                src={itemData.profile_image?.conversions?.avatar ?? itemData.profile_image?.url}
                initials={getInitials(itemData)}
              />
              {itemData.queue ? <DoctorOnDemandIndicator doctor={itemData} /> : null}
            </View>
            <View style={{ flex: 1, justifyContent: 'center' }}>
              {queueInfoRes?.noQueue ? null : (
                <ExtraSmallNsRegularWarning>{queueInfoRes.description}</ExtraSmallNsRegularWarning>
              )}
              <H6NsSemiBoldBlack style={{ whiteSpace: 'pre-wrap', marginBottom: specialisations ? 5 : 0 }}>
                {name}
              </H6NsSemiBoldBlack>
              {specialisations ? (
                <H6NsRegularSecondaryBlack style={{ whiteSpace: 'pre-wrap', marginBottom: 5 }}>
                  {specialisations}
                </H6NsRegularSecondaryBlack>
              ) : null}
              <DoctorClinicName doctor={itemData} />
            </View>
          </View>
        );
      },
    },
    {
      key: 'amount',
      title: 'Amount',
      columnStyle: {
        maxWidth: 140,
      },
      selector: (item: AvailableDoctorModel) =>
        item?.consultation_prices?.length
          ? `${formatAmount(item.consultation_prices[0].amount, item.consultation_prices[0].currency)}`
          : '',
      cell: (cellData) => (
        <View style={[flex1]}>
          <H6NsSemiBoldLink style={textCenter}>{cellData} per</H6NsSemiBoldLink>
          <H6NsSemiBoldLink style={textCenter}>consultation</H6NsSemiBoldLink>
        </View>
      ),
    },
    {
      key: 'rating',
      title: 'Rating',
      cell: (cellData) => <RatingViewComponent rating={cellData} />,
      columnStyle: {
        minWidth: 80,
        maxWidth: 100,
      },
    },
    {
      key: 'experience',
      title: 'Experience',
      selector: (item: AvailableDoctorModel) => getDoctorExperience(item.medical_council_registration),
      contentNumeric: true,
      headerNumeric: true,
      columnStyle: {
        minWidth: 80,
        maxWidth: 100,
      },
    },
    {
      key: 'view',
      cell: (_, itemData: AvailableDoctorModel) => (
        <View>
          <Button label="View Profile" funCallback={() => handleDoctorModal(itemData)} />
        </View>
      ),
      showOnHover: true,
      contentNumeric: true,
      hideTablet: true,
      hideMobile: true,
      columnStyle: {
        minWidth: 110,
        maxWidth: 200,
      },
    },
    {
      key: 'actions',
      cell: (_, itemData: AvailableDoctorModel) => <DoctorFavouriteButton doctor={itemData} />,
      showOnHover: true,
      contentNumeric: true,
      hideTablet: true,
      hideMobile: true,
      columnStyle: {
        minWidth: 60,
        maxWidth: 60,
      },
    },
  ];

  const handleDoctorModal = (doctor: AvailableDoctorModel) => {
    openModal(ModalName.DoctorInfoModal, {
      doctor,
      consultationType,
      okTitle: isListing ? 'Schedule appointment' : 'Continue',
      onContinue: selectedDoctorOrClinic,
    });
  };

  return (
    <CustomAccordion
      groupValue={consultationType}
      expanded={initialExpanded}
      expandedStyle={[flex1]}
      expandedBackgroundColor={colors.lightPurple}
      accordionStyle={[{ paddingHorizontal: 20 }, isMobile ? null : flex1]}
      titleNode={(expanded) => (
        <H4TtmSemiBoldTheme style={{ paddingVertical: 10 }}>
          {ConsultationTypeMapping[consultationType]}
        </H4TtmSemiBoldTheme>
      )}>
      <View style={[flex1]}>
        <DataTable
          notAbsolute={isMobile}
          scrollEnabled={!isMobile}
          tableId={'doctorListing_' + consultationType}
          columns={columns}
          items={doctors?.data ?? []}
          page={doctors?.meta.current_page}
          totalCount={doctors?.meta.total}
          numberOfItemsPerPage={doctors?.meta.per_page}
          emptyNote="No doctors are available"
          loading={loading && !doctors?.data?.length}
          onPageChanged={setPage}
          onRowPress={(_, item: AvailableDoctorModelWithQueue) => {
            handleDoctorModal(item);
          }}
          wrapperStyle={{ backgroundColor: isMobile ? colors.transparent : colors.white }}
          style={pb20}
          nestedScrollEnabled
          mobileRenderItem={(item: AvailableDoctorModelWithQueue) => {
            const queueInfoRes = !!item.queue && getQueueInfo(item.queue);
            return (
              <View style={[styles.profileContainer]}>
                <View>
                  <ProfileImageComponent
                    size={95}
                    src={item?.profile_image?.conversions?.avatar ?? item.profile_image?.url}
                    initials={getInitials(item)}
                  />
                  {item.queue ? <DoctorOnDemandIndicator doctor={item} /> : null}
                </View>
                <View style={{ flex: 1 }}>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      display: 'flex',
                      marginLeft: 10,
                    }}>
                    <View style={flex1}>
                      {queueInfoRes?.noQueue ? null : (
                        <ExtraSmallNsRegularWarning>{queueInfoRes.description}</ExtraSmallNsRegularWarning>
                      )}
                      <H6NsSemiBoldBlack style={{ whiteSpace: 'pre-wrap', marginBottom: 5 }}>
                        {getAccountName(item)}
                      </H6NsSemiBoldBlack>
                      <H6NsRegularSecondaryBlack>
                        {idNameArrayToString(item?.specialisations, 4)}
                      </H6NsRegularSecondaryBlack>
                      <DoctorClinicName doctor={item} />
                      <View style={[mb5, mt10]}>
                        <RatingViewComponent rating={item.rating} />
                      </View>
                    </View>
                  </View>
                  <View style={ml10}>
                    <H6NsSemiBoldLink>
                      {item?.consultation_prices?.length
                        ? `${formatAmount(
                            item.consultation_prices[0].amount,
                            item.consultation_prices[0].currency
                          )}/consultation`
                        : 'Price not available'}
                    </H6NsSemiBoldLink>
                  </View>
                </View>
              </View>
            );
          }}
        />
      </View>
    </CustomAccordion>
  );
};
const styles = StyleSheet.create({
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
