import Icon from '@expo/vector-icons/MaterialIcons';
import { Link } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ListRenderItem, View, FlatList, TouchableOpacity, StyleSheet } from 'react-native';

import { PharmacySelection } from './PharmacySelection';
import { ProfileItem } from './ProfileItem';
import { WebMenuListSection } from './WebMenuListSection';
import { flex1 } from '../../../common/commonStyles';
import { colors } from '../../../utils/colors';
import { screenName } from '../../../utils/screenName';
import { AppLogo, AppLogoWithText, BackArrow, ForwardArrow } from '../../svgImages';

import { userLogout } from '~/api/axios';
import { useVerificationStatus } from '~/api/hooks/accounts/VerificationStatus';
import { getAsyncItem, setAsyncItem, storageKeys } from '~/common/asyncStorage';
import { ConfirmationAlert } from '~/common/commonMethods';
import { TextButton } from '~/components/commonButton';
import { SmallNsRegularRed } from '~/components/commonText';
import { MenuItem } from '~/constants/menus/menuCommon';
import { useMenus } from '~/constants/menus/menuWeb';
import { isPharmacyVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';

const Sidebar = () => {
  const [expand, setExpand] = useState(true);
  const accountStatus = useVerificationStatus();
  const { menu } = useMenus();
  const { isDesktop } = useBreakpoints();

  const renderItem: ListRenderItem<MenuItem[]> = ({ item, index }) => {
    return (
      <WebMenuListSection menuItems={item} expanded={expand || isPharmacyVersion()} isLast={index + 1 >= menu.length} />
    );
  };

  const tryLogout = () => {
    ConfirmationAlert([''], { title: 'Are you sure you want to logout?', okTitle: 'Logout', okFunction: userLogout });
  };

  const toggleExpand = () => {
    const isExpanded = !expand;
    setExpand(isExpanded);
    setAsyncItem(storageKeys.sidebar, isExpanded.toString());
  };

  useEffect(() => {
    getAsyncItem(storageKeys.sidebar, isDesktop ? 'true' : 'false').then((isExpandedString) => {
      setExpand(isExpandedString === 'true');
    });
  }, []);

  return (
    <View style={[styles.sidebarContainer, expand ? styles.sidebarContainerExpanded : null]}>
      <View style={styles.background} />
      <View style={[styles.sidebar, flex1]}>
        <View style={[styles.container, flex1]}>
          <View style={styles.header}>
            <Link to={{ screen: screenName.Dashboard, params: {} } as never} style={styles.logo}>
              {expand || isPharmacyVersion() ? (
                <AppLogoWithText width={undefined} height={30} />
              ) : (
                <AppLogo width={40} height={30} />
              )}
            </Link>
            {!isPharmacyVersion() ? (
              <TouchableOpacity
                onPress={toggleExpand}
                style={[styles.arrow, expand ? null : styles.arrowCollapsed, { zIndex: 2 }]}>
                <View>{!expand ? <ForwardArrow width={15} height={15} /> : <BackArrow width={15} height={15} />}</View>
              </TouchableOpacity>
            ) : null}
          </View>

          <FlatList
            data={menu}
            renderItem={renderItem}
            keyExtractor={(_item, index) => index.toString()}
            style={flex1}
          />

          {isPharmacyVersion() ? (
            <PharmacySelection />
          ) : accountStatus.approved ? (
            <ProfileItem expanded={expand} />
          ) : null}
          <View>
            <TextButton funCallback={tryLogout} style={{ marginTop: 20 }}>
              <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Icon name="logout" size={16} color={colors.danger} style={{ paddingLeft: 20, paddingRight: 16 }} />
                {expand || isPharmacyVersion() ? <SmallNsRegularRed>Logout</SmallNsRegularRed> : null}
              </View>
            </TextButton>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Sidebar;

const styles = StyleSheet.create({
  sidebarContainer: {
    position: 'relative',
    zIndex: 10,
    maxWidth: 225,
  },
  sidebarContainerExpanded: {
    width: 225,
  },
  arrow: {
    position: 'absolute',
    right: 0,
    backgroundColor: colors.lightPurple,
    borderRadius: 15,
    padding: 3,
  },
  arrowCollapsed: {
    right: '-30px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.lightPurple2,
  },
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    overflow: 'hidden',
    backgroundColor: colors.lightPurple,
  },
  sidebar: {},
  container: {
    padding: 18,
    paddingTop: 22,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 40,
  },
  logo: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
});
