import { Route } from '@react-navigation/native';
import React from 'react';

import { HealthProfileMetrics } from '~/pages/common/healthProfile/mobile/HealthProfileMetrics';
import { HealthProfileDataProvider } from '~/providers/healthProfile/HealthProfileDataProviders';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { id: string; edit?: boolean }>;
}

export const PatientHealthProfileMetrics: React.FC<Props> = ({ route }) => {
  const patientId = +route.params.id;

  return (
    <HealthProfileDataProvider accountId={patientId}>
      <HealthProfileMetrics
        backLink={{ screen: screenName.PatientHealthProfile, params: route.params }}
        edit={!!route.params.edit}
      />
    </HealthProfileDataProvider>
  );
};
