import { useNavigation } from '@react-navigation/native';
import React, { ReactNode } from 'react';

import ErrorBoundary from './ErrorBoundary';

import { NavType } from '~/navigation/types';
import { screenName } from '~/utils/screenName';
import { systemLogout } from '~/api/axios';

interface Props {
  children: ReactNode;
}

export const RegistrationStackErrorBoundary: React.FC<Props> = ({ children }) => {
  const { navigate } = useNavigation<NavType>();

  const onErrorRecovery = () => {
    systemLogout();
    navigate(screenName.LoginScreen);
  };

  return (
    <ErrorBoundary label="Redirect" onErrorRecovery={onErrorRecovery}>
      {children}
    </ErrorBoundary>
  );
};
