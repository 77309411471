import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Control, Controller, useForm, UseFormWatch } from 'react-hook-form';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { Button } from '../commonButton';
import { H2TtmSemiBoldBlack, H4TtmSemiBoldTheme } from '../commonText';
import { Dropdown } from '../inputs/dropdown/Dropdown';
import { DropDownItem } from '../inputs/dropdown/interfaces';
import { AudioCallIcon, VideoCallIcon } from '../svgImages';

import { mb20, textCenter } from '~/common/commonStyles';
import { Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  setSelectedVideoDevice: Dispatch<SetStateAction<string | number | (string | number)[]>>;
  setSelectedAudioInputDevice: Dispatch<SetStateAction<string | number | (string | number)[]>>;
}

export const CallSettingsModal: React.FC<Props> = ({ onHide, setSelectedVideoDevice, setSelectedAudioInputDevice }) => {
  const [videoTab, setVideoTab] = useState<boolean>();
  const [videoDevicesList, setVideoDevicesList] = useState<DropDownItem<string | number>[]>([]);

  const { control, watch } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { microphones: '', speakers: '', video_devices: '' },
  });

  const tabs = [
    { icon: <AudioCallIcon width={13} height={20} color={colors.purple} />, title: 'Audio' },
    { icon: <VideoCallIcon width={18} height={10} />, title: 'Video' },
  ];

  const speakersList = useMemo(() => {
    const audioOutputDevices: DropDownItem<string | number>[] = [];
    OT.getAudioOutputDevices().then((devices) => {
      devices.forEach((element) => {
        audioOutputDevices.push({ value: element.deviceId, label: element.label });
        return audioOutputDevices?.map((device) => ({ value: device.value, label: device.label })) || [];
      });
    });
    return audioOutputDevices;
  }, []);

  const microphonesList = useMemo(() => {
    const audioInputDevices: DropDownItem<string | number>[] = [];
    OT.getDevices((error, devices) => {
      if (error) {
        return null;
      }

      devices.forEach((element) => {
        if (element.kind === 'audioInput') {
          audioInputDevices.push({ value: element.deviceId, label: element.label });
          return audioInputDevices?.map((device) => ({ value: device.value, label: device.label })) || [];
        }
      });
    });
    return audioInputDevices;
  }, []);

  useEffect(() => {
    OT.getDevices((error, devices) => {
      if (error) {
        return null;
      }

      const videoInputDevices: DropDownItem<string | number>[] = [];
      devices.map((element) => {
        if (element.kind === 'videoInput') {
          videoInputDevices.push({ value: element.deviceId, label: element.label });
          setVideoDevicesList(videoInputDevices);
        }
      });
    });
  }, []);

  const buttons = [<Button label={labels.close} funCallback={onHide} />];
  return (
    <ModalContainer showInfoLine hideOnBackground onHide={onHide} buttons={buttons}>
      <View>
        {speakersList.length > 0 && microphonesList.length > 0 ? (
          <View>
            <H2TtmSemiBoldBlack style={[textCenter, mb20]}>{labels.settings}</H2TtmSemiBoldBlack>
            <View
              style={{
                flexDirection: 'row',
                alignSelf: 'center',
                marginBottom: 20,
                borderRadius: 6,
                backgroundColor: colors.lightPurple,
              }}>
              {tabs.map((item, index) => {
                return (
                  <TouchableOpacity
                    key={index}
                    style={[
                      styles.link,
                      index === 1 && videoTab ? styles.linkActive : index === 0 && !videoTab ? styles.linkActive : null,
                    ]}
                    onPress={() => {
                      if (index === 1) {
                        setVideoTab(true);
                      } else {
                        setVideoTab(false);
                      }
                    }}>
                    <Row style={{ alignItems: 'center' }}>
                      {item.icon}
                      <H4TtmSemiBoldTheme style={{ paddingLeft: 10 }}>{item.title}</H4TtmSemiBoldTheme>
                    </Row>
                  </TouchableOpacity>
                );
              })}
            </View>
            {videoTab ? (
              <View>
                <Controller
                  name="video_devices"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Dropdown
                      list={videoDevicesList}
                      value={watch('video_devices') ? watch('video_devices') : videoDevicesList[0]?.value}
                      setValue={(val) => {
                        onChange(val);
                        setSelectedVideoDevice(val);
                      }}
                      label="Video Devices"
                    />
                  )}
                />
              </View>
            ) : (
              <View>
                <Controller
                  name="microphones"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Dropdown
                      list={microphonesList}
                      value={watch('microphones') ? watch('microphones') : 'default'}
                      setValue={(val) => {
                        onChange(val);
                        setSelectedAudioInputDevice(val);
                      }}
                      label="Microphone"
                    />
                  )}
                />

                <Controller
                  name="speakers"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Dropdown
                      list={speakersList}
                      setValue={(val) => {
                        onChange(val);
                        OT.setAudioOutputDevice(val.toString());
                      }}
                      value={value ? value : 'default'}
                      label="Speaker"
                    />
                  )}
                />
              </View>
            )}
          </View>
        ) : null}
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  link: {
    minWidth: 150,
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 6,
    backgroundColor: colors.lightPurple,
  },
  linkActive: {
    color: colors.black,
    borderColor: colors.info,
    borderWidth: 1,
    backgroundColor: colors.lightPurple2,
  },
});
