import { deleteReq, get, post, put } from '../../axios';

import { IdModel } from '~/api/models/common/models/IdModel';
import { GetMediaCollectionResponse } from '~/api/models/common/responses/GetMediaCollectionResponse';
import { CreateMedicalNoteRequest } from '~/api/models/consultations/requests/CreateMedicalNoteRequest';
import { DeleteMedicalNoteRequest } from '~/api/models/consultations/requests/DeleteMedicalNoteRequest';
import { GetMedicalNoteRequest } from '~/api/models/consultations/requests/GetMedicalNoteRequest';
import { UpdateMedicalNoteRequest } from '~/api/models/consultations/requests/UpdateMedicalNoteRequest';
import { MedicalNoteResponse } from '~/api/models/consultations/responses/MedicalNoteResponse';

export const apiCreateMedicalNote = (req: CreateMedicalNoteRequest) => {
  return post<MedicalNoteResponse>('/medical-notes', req);
};

export const apiDeleteMedicalNote = (req: DeleteMedicalNoteRequest) => {
  return deleteReq(`/medical-notes/${req.id}`);
};

export const apiUpdateMedicalNote = (req: UpdateMedicalNoteRequest) => {
  return put<MedicalNoteResponse>(`/medical-notes/${req.id}`, req.params);
};

export const apiGetMedicalNote = (req: GetMedicalNoteRequest) => {
  return get<MedicalNoteResponse>(`/consultations/${req.consultationId}/medical-note`);
};

export const apiGetMedicalNoteMedia = (req: IdModel) => {
  return get<GetMediaCollectionResponse>(`/medical-notes/${req.id}/media/download`);
};
