import React, { useState, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ml5 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { SmallNsRegularBlack, SmallNsRegularDisableColor } from '~/components/commonText';
import { Person, Stethoscope } from '~/components/svgImages';
import { isPatientVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { consultationsLabels } from '~/utils/labels/consultations';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { useChannelMeans } from './hooks/useChannelMeans';

interface Props {
  consultation: ConsultationModel;
  joinedCall: boolean;
  small?: boolean;
}

export const ChannelMeansHeaders: React.FC<Props> = ({ consultation, joinedCall, small }) => {
  const { isMobile } = useBreakpoints();

  const [hasSpace, setHasSpace] = useState(true);
  const [marginLeft, setMarginLeft] = useState(10);

  const { showTimer, timeOver, time } = useChannelMeans({ consultation, joinedCall });

  const onLayout = useCallback((event) => {
    const { width } = event.nativeEvent.layout;
    setHasSpace(width > 340);
    setMarginLeft(width > 340 ? 10 : 0);
  }, []);

  return (
    <View style={[styles.header, isMobile ? { paddingHorizontal: MOBILE_HORIZONTAL_SPACE } : null]} onLayout={onLayout}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        {isPatientVersion() && !isMobile ? (
          <View style={styles.accountName}>
            <Stethoscope width={16} height={16} />
            <SmallNsRegularBlack style={ml5}>{getAccountName(consultation.doctor) || 'N/A'}</SmallNsRegularBlack>
          </View>
        ) : null}
        <View style={styles.accountName}>
          <Person width={16} height={16} />
          <SmallNsRegularBlack style={ml5}>{getAccountName(consultation.patient) || 'N/A'}</SmallNsRegularBlack>
        </View>
      </View>
      {showTimer ? (
        <View style={styles.countdown}>
          {!isMobile && hasSpace && !small ? (
            <SmallNsRegularDisableColor>
              {timeOver ? consultationsLabels.timeOver : consultationsLabels.timeRemaining}
            </SmallNsRegularDisableColor>
          ) : null}
          <SmallNsRegularBlack style={[styles.time, timeOver ? { color: colors.danger } : null, { marginLeft }]}>
            {time}
          </SmallNsRegularBlack>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderBottomColor: colors.lightPurple2,
    borderBottomWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  accountName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15,
  },
  countdown: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  time: {
    minWidth: 50,
    textAlign: 'right',
  },
});
