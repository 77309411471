export enum NotificationTypeEnum {
  REMINDERS = 'reminders',
  PRODUCT_NEWS = 'productNews',
  DOCUMENTS = 'documents',
  ROLE = 'role',
  KYI = 'knowYourIdentity',
  ASSOCIATION_REQUEST = 'associationRequest',
  CONSULTATIONS = 'consultations',
  DEPENDANTS = 'dependants',
  CONSULTATION_MESSAGE = 'consultationMessage',
}
