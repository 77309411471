import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useLoginDetails } from '../accounts/LoginDetails';
import { useUserDetails } from '../accounts/UserDetails';

import { hasToken } from '~/api/axios/interceptors';
import {
  DoctorFeatures,
  FeatureAccessEnum,
  PatientFeatures,
} from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { FeatureAccessStateEnum } from '~/api/models/subscriptions/constants/FeatureAccessStateEnum';
import { apiGetFeatureAccess } from '~/api/services/subscriptions';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { RootState } from '~/redux/reducers';
import { UPDATE_FEATURE_ACCESS } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { whenAppType } from '~/utils/buildConfig';
import isUndefined from '~/utils/types/isUndefined';

export const useFeatureAccess = () => {
  const dispatch = useAppDispatch();

  const featureAccess = useSelector((state: RootState) => state.userDetailsReducer.featureAccess);

  const fetchFeatureAccess = async () => {
    try {
      const canCallFeatureApi = whenAppType({
        whenPatient: true,
        whenDoctor: true,
        else: false,
      });

      if (!canCallFeatureApi || !(await hasToken()) || false) return { features: [] };
      const res = await apiGetFeatureAccess().then((res) => res.data);
      dispatch(UPDATE_FEATURE_ACCESS(res.features));
      return res;
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const getFeature = (featureSlug: FeatureAccessEnum) => featureAccess?.find((item) => item.slug === featureSlug);
  const getFeatureAsync = async (featureSlug: FeatureAccessEnum) => {
    let localFeatureAccess = featureAccess;
    if (isUndefined(localFeatureAccess)) {
      localFeatureAccess = (await fetchFeatureAccess())?.features;
    }
    return localFeatureAccess?.find((item) => item.slug === featureSlug);
  };

  const hasFeatureAccess = (featureSlug: FeatureAccessEnum) => {
    const feature = getFeature(featureSlug);

    if (!feature) {
      return whenAppType({
        whenPatient: !PatientFeatures.includes(featureSlug),
        whenDoctor: !DoctorFeatures.includes(featureSlug),
        else: true,
      });
    }

    return feature?.state === FeatureAccessStateEnum.ACTIVE || feature?.state === FeatureAccessStateEnum.DISABLED;
  };

  const isFeatureDisabled = (featureSlug: FeatureAccessEnum) => {
    const feature = getFeature(featureSlug);
    return feature?.state === FeatureAccessStateEnum.DISABLED;
  };

  const isFeatureActive = async (featureSlug: FeatureAccessEnum) => {
    const feature = await getFeatureAsync(featureSlug);
    return !feature || feature?.state === FeatureAccessStateEnum.ACTIVE;
  };

  const getFeatureState = (featureSlug: FeatureAccessEnum) => {
    const feature = getFeature(featureSlug);
    return feature.state;
  };

  return { featureAccess, fetchFeatureAccess, hasFeatureAccess, isFeatureDisabled, isFeatureActive, getFeatureState };
};

export const useFeatureAccessPerUser = () => {
  const [loading, setLoading] = useState(true);
  const { details: loginDetails } = useLoginDetails();
  const { userDetails } = useUserDetails({ allowStale: true });
  const featureAccess = useFeatureAccess();

  useEffect(() => {
    if (userDetails && loginDetails.steppedUp) {
      setLoading(true);
      featureAccess.fetchFeatureAccess().finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [userDetails?.id, loginDetails?.steppedUp]);

  return { ...featureAccess, loading };
};
