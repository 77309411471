import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useFeatureAccess } from '../subscriptions/FeatureAccessHook';

import { ConsultationTypePriceModel } from '~/api/models/consultations/models/ConsultationTypePriceModel';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { apiGetConsultationTypePriceIndex, apiUpdateConsultationTypePrice } from '~/api/services/consultations/prices';

export interface PreferredConsultationTypesForm {
  preferredConsultationTypes: {
    id: number;
    type: string;
  }[];
}
export function usePreferredConsultationType() {
  const [currentConsultationTypes, setConsultationTypePrices] = useState<ConsultationTypePriceModel[]>(null);
  const { isFeatureActive } = useFeatureAccess();

  const getConsultationTypePriceIndex = async () => {
    try {
      if (!(await isFeatureActive(FeatureAccessEnum.CONSULTATION_PRICES))) return;

      const res = await apiGetConsultationTypePriceIndex();
      setConsultationTypePrices(res.data);
    } catch {}
  };

  useEffect(() => {
    getConsultationTypePriceIndex();
  }, []);

  return { currentConsultationTypes };
}

export function usePreferredConsultationTypeForm() {
  const { currentConsultationTypes } = usePreferredConsultationType();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<PreferredConsultationTypesForm>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      preferredConsultationTypes: [],
    },
  });

  const updatePreferredConsultationTypes = async ({ preferredConsultationTypes }: PreferredConsultationTypesForm) => {
    if (isDirty) {
      await apiUpdateConsultationTypePrice({
        consultation_type_price: preferredConsultationTypes.map((consultationType) => ({
          consultation_type_id: consultationType.id,
        })),
      });
    }
  };

  useEffect(() => {
    reset({
      preferredConsultationTypes: currentConsultationTypes?.map((item) => ({ id: item.id, type: item.type })) ?? [],
    });
  }, [currentConsultationTypes]);

  return { control, handleSubmit, updatePreferredConsultationTypes };
}
