import React, { useState } from 'react';
import { View } from 'react-native';

import { useEducationArticles } from '~/api/hooks/educationCentre/EducationArticlesHook';
import { ArticlePostsTable } from '~/components/common/educationCentre/ArticlePostsTable';
import { H2TtmSemiBoldBlack } from '~/components/commonText';
import { DashboardMobileHeader } from '~/components/dashboard/doctor/DashboardHeader/DashboardMobileHeader';
import { SearchBox } from '~/components/searchBox';
import { EducationCentreLayout } from '~/layouts/EducationCentreLayout';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props {}

export const DigimedEducationCentre: React.FC<Props> = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const { isMobile } = useBreakpoints();
  const { searchEducationArticle } = useEducationArticles();
  const search = (
    <SearchBox
      value={searchValue}
      onSubmit={() => {
        searchEducationArticle(searchValue);
      }}
      onTextChange={setSearchValue}
      placeholder="Search articles"
      style={isMobile ? { marginVertical: 0 } : { marginTop: 0 }}
    />
  );

  return (
    <View style={{ display: 'flex', flex: 1 }}>
      {isMobile ? (
        <DashboardMobileHeader
          title={labels.digimedEducationCentre}
          hideImage
          backgroundColor={colors.white}
          bottom={search}
        />
      ) : null}

      <EducationCentreLayout style={{ backgroundColor: colors.white, flex: 1, display: 'flex', paddingTop: 0 }}>
        <View style={{ marginVertical: 10 }}>
          <View
            style={isMobile ? null : { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            {isMobile ? null : (
              <>
                <H2TtmSemiBoldBlack>{labels.digimedEducationCentre}</H2TtmSemiBoldBlack>
                {search}
              </>
            )}
          </View>
        </View>
        <ArticlePostsTable />
      </EducationCentreLayout>
    </View>
  );
};
