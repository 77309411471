import Feather from '@expo/vector-icons/Feather';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import { BillingHistoryList } from './BillingHistoryList';
import { SubscriptionTypeCard } from './SubscriptionTypeCard';
import { SubscriptionTypeCardMobile } from './SubscriptionTypeCardMobile';
import { IconButton } from '../buttons/IconButton';
import { BillingHistoryFilterMenu } from '../common/billing/BillingHistoryFilterMenu';
import { BillingHistoryModal } from '../common/billing/BillingHistoryFilterModal';
import { useBillingHistoryContext } from '../common/billing/contexts/BillingHistoryContext';
import { H2TtmSemiBoldBlack, H3TtmSemiBoldBlack } from '../commonText';
import { DashboardMobileHeader } from '../dashboard/doctor/DashboardHeader/DashboardMobileHeader';
import { PatientsLayout } from '../doctor/patients/PatientsLayout';
import { ErrorAlert } from '../modals/ErrorAlert';
import { ModalName } from '../modals/constants/ModalNames';
import { FilterIcon } from '../svgImages';

import { useSubscriptions } from '~/api/hooks/subscriptions/SubscriptionHook';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ExportTypesEnum } from '~/api/models/common/constants/ExportTypesEnum';
import { apiExportData } from '~/api/services/exports';
import { InprogressAlert } from '~/common/commonMethods';
import { flex1, flexRow, mb5, ml10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

export enum SubscriptionTypeFilterEnum {
  STRIPESUBSCRIPTION = 'stripe-subscription',
}

export type BillingTypeFilterEnum = SubscriptionTypeFilterEnum | ConsultationTypeEnum;

export const PatientBillingHistory: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const { currentPlan } = useSubscriptions();

  const { registerModal, openModal, closeModalByName } = useModalManager();

  const { form } = useBillingHistoryContext();

  useEffect(() => {
    registerModal(ModalName.FilterMenuModal, BillingHistoryModal);
  }, []);

  const downloadPatientBilling = () => {
    apiExportData(ExportTypesEnum.BILLING_EXPORT)
      .then(() => {
        InprogressAlert(['Your billing history export is in progress, we will let you know once it is done'], {
          title: 'Exporting your billing history',
        });
      })
      .catch(ErrorAlert);
  };

  return (
    <View style={{ flex: 1 }}>
      {isMobile ? (
        <View style={{ backgroundColor: colors.white }}>
          <DashboardMobileHeader title={labels.billing} hideImage backgroundColor={colors.white} />
          <SubscriptionTypeCardMobile style={{ marginHorizontal: MOBILE_HORIZONTAL_SPACE }} currentPlan={currentPlan} />
        </View>
      ) : (
        <View style={styles.titleContainer}>
          <H2TtmSemiBoldBlack>{labels.billing}</H2TtmSemiBoldBlack>
          <SubscriptionTypeCard currentPlan={currentPlan} />
        </View>
      )}
      <PatientsLayout
        hideBack
        hideHeaderRight
        header={
          <View style={[styles.headerContainer, isMobile ? { marginBottom: 15 } : null]}>
            <H3TtmSemiBoldBlack>{labels.billingHistory}</H3TtmSemiBoldBlack>

            <View style={flexRow}>
              {isMobile ? (
                <IconButton
                  style={mb5}
                  onPress={() => {
                    openModal(ModalName.FilterMenuModal, {
                      onHide: () => {
                        closeModalByName(ModalName.FilterMenuModal);
                      },
                      form,
                    });
                  }}>
                  <FilterIcon width="19" height="16" />
                </IconButton>
              ) : (
                <BillingHistoryFilterMenu />
              )}

              <IconButton onPress={downloadPatientBilling} title="Download billing history" style={[ml10, mb5]}>
                <Feather name="download" size={18} color={colors.purple} style={{ marginHorizontal: 2 }} />
              </IconButton>
            </View>
          </View>
        }
        contentStyling={flex1}
        flexContent={false}>
        <BillingHistoryList />
      </PatientsLayout>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 34,
  },
});
