export const pharmacyLabels = {
  modal: {
    subtitleManualCollection:
      'We would recommend that you use our preferred network of Digimed pharmacies as we cannot guarantee the service offered at other pharmacies.',
    subtitleNotCollectable: 'This e-prescription is not collectable.',
    subtitleUsed: 'This e-prescription has been used.',
    subtitleCollected: 'This e-prescription has been collected.',
    pharmacyInstructions: 'Pharmacy instructions',
    pharmacyRejection: 'Latest prescription request rejection reason',
  },
  tables: {
    noDispenseHistory: 'You do not have any dispensed prescriptions.',
    noRequestHistory: 'You do not have any past requests.',
  },
};
