import { useMemo } from 'react';

import { labels } from '../utils/labels';
import { screenName } from '../utils/screenName';

import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { isClinicVersion, isDoctorVersion } from '~/utils/buildConfig';
import { profileLabels } from '~/utils/labels/profile';
import { digimedFeatureEnabled, DigimedFeatures } from '~/utils/milestoneUtil';

export const useProfileMenu = () => {
  const { featureAccess, hasFeatureAccess } = useFeatureAccess();

  const profileMenu = [
    { title: labels.personalInformation, link: { screen: screenName.ProfilePersonalInformation, params: {} } },
    {
      title: labels.homeAddress,
      link: { screen: screenName.ProfileHomeAddress, params: {} },
      hidden: isClinicVersion(),
    },
    {
      title: labels.healthProfile,
      link: { screen: screenName.HealthProfile, params: {} },
      hidden: isDoctorVersion() || isClinicVersion(),
    },
    {
      title: profileLabels.subscriptionPlan,
      link: { screen: screenName.ProfileSubscriptionPlan, params: {} },
      hidden:
        isClinicVersion() ||
        !digimedFeatureEnabled(DigimedFeatures.Payments) ||
        !hasFeatureAccess(FeatureAccessEnum.IBAN),
    },
    {
      title: labels.paymentDetails,
      link: { screen: screenName.ProfilePaymentDetails, params: {} },
      hidden: !digimedFeatureEnabled(DigimedFeatures.Payments) || !hasFeatureAccess(FeatureAccessEnum.IBAN),
    },
  ];
  const menu = useMemo(() => profileMenu.filter((item) => !item.hidden), [featureAccess]);

  return menu;
};
