import React, { CSSProperties, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Animated } from 'react-native';

import FloatingInput from './FloatingInput';

import { colors } from '~/utils/colors';

interface InputProps {
  value: string;
  label: string;
  componentKey?: string;
  onChangeValue: (value: string) => void;
  onFocus?: CallableFunction;
  onBlur?: CallableFunction;
  height?: number;
  max?: number | string;
  min?: number | string;
  maxLength?: number;
  minLength?: number;
  error?: boolean;
  disabled?: boolean;
  showMandatory?: boolean;

  inputType?: string;
  inputStyle?: CSSProperties;
  iconSuffix?: ReactNode;
  iconPrefix?: ReactNode;
  mode?: 'flat' | 'outlined';
}

const WebTextInput: React.FC<InputProps> = ({
  value,
  maxLength,
  minLength,
  max,
  min,
  error,
  label,
  height,
  onChangeValue,
  disabled,
  onFocus,
  onBlur,
  showMandatory,
  inputType,
  inputStyle,
  iconPrefix,
  iconSuffix,
  mode,
}): JSX.Element => {
  const [isFocused, setFocused] = useState(!!value);
  const _animatedIsFocused = new Animated.Value(0);
  const containerRef = useRef<HTMLDivElement>();

  const handleFocus = () => {
    setFocused(true);
    if (onFocus) onFocus();
  };
  const handleBlur = () => {
    setFocused(!!value);
    if (onBlur) onBlur();
  };

  useEffect(() => {
    value && setFocused(true);
    Animated.timing(_animatedIsFocused, {
      toValue: isFocused ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [isFocused, value]);

  const displayValue = useMemo(() => (value || isFocused ? ' ' : ''), [value, isFocused]);

  useEffect(() => {
    if (containerRef.current) {
      const inputs = containerRef.current.getElementsByTagName('input');
      if (inputs.length) {
        inputs.item(0).setAttribute('tabIndex', '-1');
      }
    }
  }, []);

  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }} ref={containerRef}>
      <FloatingInput
        value={displayValue}
        label={label}
        error={error}
        showMandatory={showMandatory}
        disabled={disabled}
        viewStyle={{ flex: 1 }}
        mode={mode}
        style={{ paddingTop: 0, borderWidth: 0 }}
        pointerEvents="none"
      />

      <div
        style={{
          position: 'absolute',
          border: 1,
          width: '100%',
          height: '100%',
          opacity: value || isFocused ? 1 : 0,
        }}>
        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', height: '100%' }}>
          {iconPrefix}
          <input
            style={{
              flexDirection: 'row',
              fontFamily: 'NotoSans',
              outline: 'none',
              fontSize: 14,
              border: 1,
              flex: 1,
              paddingLeft: 14,
              paddingRight: 14,
              paddingTop: 12,
              opacity: value || isFocused ? 1 : 0,
              alignContent: 'space-between',
              backgroundColor: colors.transparent,
              color: colors.black,
            }}
            value={value}
            maxLength={maxLength}
            minLength={minLength}
            max={max}
            min={min}
            type={inputType || 'text'}
            onChange={(ev) => {
              onChangeValue(ev.target.value);
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="web-input"
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default WebTextInput;
