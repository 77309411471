import { isDoctorVersion, isPatientVersion, whenAppType } from '../buildConfig';
import { digimedFeatureEnabled, DigimedFeatures } from '../milestoneUtil';

import { UserModel } from '~/api/models/authorisation/models/UserModel';
import { RegistrationMilestoneModel } from '~/api/models/registration/models/RegistrationMilestoneModel';

const isDoctorPartOfClinic = (userDetails?: UserModel) => !!userDetails?.clinic_account;

const stepCheck = async (milestones: RegistrationMilestoneModel[], userDetails?: UserModel) => {
  const check = whenAppType({
    whenDoctor: (item: RegistrationMilestoneModel) => {
      switch (item.label) {
        case 'Bank Account Details':
        case 'Preferences':
          return isDoctorPartOfClinic(userDetails) || item.completion.all;
        case 'Doctor Verification':
          return item.completion.required;
        default:
          return item.completion.all;
      }
    },
    else: (item: RegistrationMilestoneModel) => item.completion.all,
  });

  return milestones.every(check);
};

export function registrationPreKyiCompleted(registrationSteps: RegistrationMilestoneModel[], completeAll: boolean) {
  return !!registrationSteps
    ?.filter((step) => step.pre_sumsub_verification)
    ?.every((item) => !item || (item.completion.required && (!completeAll || item.completion.all)));
}

export async function registrationPreDigimedApprovalCompleted(registrationSteps: RegistrationMilestoneModel[]) {
  const steps = registrationSteps?.filter((step) => step.pre_digimed_approval);

  if (isDoctorVersion() && digimedFeatureEnabled(DigimedFeatures.Consultations)) {
    return await stepCheck(steps);
  }
  return !!steps?.every((item) => item.completion.required);
}

export async function registrationAllCompleted(
  registrationSteps: RegistrationMilestoneModel[],
  userDetails?: UserModel,
  isCompleted?: boolean
) {
  if (isCompleted) {
    if (isPatientVersion()) {
      const identificationStep = registrationSteps
        ?.find((step) => step.label === 'Verify Account')
        ?.steps.find((step) => step.key === 'identity_verification' && step.required && !step.done);
      if (identificationStep) return false;
    }
    return true;
  }
  if (
    whenAppType({
      whenDoctor: digimedFeatureEnabled(DigimedFeatures.Consultations),
      whenPharmacist: true,
      else: false,
    })
  ) {
    return await stepCheck(registrationSteps, userDetails);
  }
  return !!registrationSteps?.every((item) => item.completion.required);
}
