import moment from 'moment';
import { useEffect, useState } from 'react';

import { stateEndedOrPassed } from './AppointmentStateHook';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { isPastConsultation } from '~/utils/consultationUtils';

interface JoinProps {
  timeDifference?: {
    amount: number;
    unit?: moment.unitOfTime.DurationConstructor;
  };
}
export const useJoinAppointment = (consultation: ConsultationModel, options?: JoinProps) => {
  const [join, setJoin] = useState<boolean>(null);

  useEffect(() => {
    if (!consultation || stateEndedOrPassed(consultation.state)) {
      setJoin(false);
      return;
    }

    const currentTime = moment();
    const targetTime = moment(consultation.start_at).subtract(
      options?.timeDifference?.amount ?? 15,
      options?.timeDifference?.unit ?? 'minutes'
    );
    const timeDifference = moment.duration(targetTime.diff(currentTime)).asMilliseconds();
    const isPastAppointment = isPastConsultation(consultation);

    if (
      consultation.type === ConsultationTypeEnum.HOME_VISIT &&
      consultation.state === ConsultationStateEnum.Scheduled
    ) {
      setJoin(true);
    }

    if (timeDifference < 0 && !isPastAppointment) {
      setJoin(true);
    } else {
      setJoin(false);
      const timer = setTimeout(() => {
        setJoin(true);
      }, timeDifference);

      return () => clearTimeout(timer);
    }
  }, [consultation?.start_at, consultation?.end_at, consultation?.state]);

  return { showJoin: join };
};
