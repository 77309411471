import { apiSetInsurancePolicyNumber } from '~/api/services/insurance';
import { isPatientVersion } from '~/utils/buildConfig';

export const useTryUpgradeToPremium = () => {
  const tryUpgradeWithInsurance = async (details: {
    insuranceCompany?: number;
    insurancePolicy?: string;
    dob?: string;
    createPolicy?: boolean;
  }) => {
    if (!isPatientVersion() || !details.insuranceCompany || !details.insurancePolicy || !details.dob) return false;

    return await apiSetInsurancePolicyNumber({
      insurance_company_id: Number(details.insuranceCompany),
      policy_number: details.insurancePolicy,
      date_of_birth: details.dob,
      create_policy: !!details.createPolicy,
    })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  return { tryUpgradeWithInsurance };
};
