import React, { useEffect, useState } from 'react';
import { StyleProp, ViewStyle, View, Text, StyleSheet, TextStyle, Animated, Pressable } from 'react-native';

import { colors } from '~/utils/colors';
import { Hoverable } from '../common/hover/Hoverable';
import { isWeb } from '~/utils/buildConfig';

const DOT_SIZE = 12;

interface Props {
  style?: StyleProp<ViewStyle>;
  withHover?: boolean;
  hovering?: boolean;
}
export const NewBadge: React.FC<Props> = ({ style, withHover, hovering }) => {
  const [animValue] = useState(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(animValue, {
      toValue: hovering || !withHover ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [hovering, withHover]);

  const fontSize = animValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 10],
  });

  return (
    <View style={[style]}>
      <Animated.View style={[styles.container, { minWidth: DOT_SIZE, minHeight: DOT_SIZE }]}>
        <Animated.Text style={[styles.text, { fontSize }]} maxFontSizeMultiplier={1.5}>
          New
        </Animated.Text>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: DOT_SIZE,
    backgroundColor: colors.danger,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  text: {
    fontSize: 10,
    fontFamily: 'NotoSans',
    color: colors.white,
    textAlign: 'center',
    fontWeight: '600',
    paddingHorizontal: DOT_SIZE / 2,
    paddingVertical: DOT_SIZE / 4,
  },
  badge: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 4,
    height: 6,
    width: 6,
    borderRadius: 6,
    backgroundColor: colors.danger,
  },
});
