import Feather from '@expo/vector-icons/Feather';
import React, { useMemo, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Pressable, StyleSheet, TouchableOpacity, View } from 'react-native';

import { AppointmentListSortingModal } from '../mobile/modals/AppointmentListSortingModal';

import { mb10, mr10, mr15 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { H6NsRegularBlack, nsSemiBold } from '~/components/commonText';
import { AppointmentFilter } from '~/constants/appointments/appointmentStateMapping';
import { useAppointmentListingContext } from '~/providers/appointment/AppointmentListingContext';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const AppointmentsFilterStrip: React.FC = () => {
  const { filterControl } = useAppointmentListingContext();

  const { isMobile } = useBreakpoints();
  const [showSort, setShowSort] = useState(false);

  const { sort } = useWatch({ control: filterControl });

  const isDescending = useMemo(() => {
    if (sort.includes('-')) return true;
    return false;
  }, [sort]);

  return (
    <Controller
      control={filterControl}
      name="filter.state.current"
      render={({ field: { value, onChange } }) => (
        <View style={isMobile ? { paddingHorizontal: MOBILE_HORIZONTAL_SPACE } : null}>
          <Grid grid={{ noOuterGutter: true, gutters: 12 }}>
            <Container
              alignSelf="flex-start"
              fluid
              style={[
                styles.container,
                isMobile
                  ? {
                      backgroundColor: colors.transparent,
                    }
                  : null,
              ]}>
              <Row>
                {Object.keys(AppointmentFilter).map((key) => {
                  const active = key === value;
                  return (
                    <Column key={key} span="auto">
                      <TouchableOpacity
                        key={key}
                        style={[styles.link, active ? styles.linkActive : null, isMobile ? { ...mb10 } : null]}
                        onPress={() => {
                          onChange(key);
                        }}>
                        <H6NsRegularBlack style={active ? styles.textActive : null}>
                          {AppointmentFilter[key].label}
                        </H6NsRegularBlack>
                      </TouchableOpacity>
                    </Column>
                  );
                })}
                {!isMobile ? (
                  <Column>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        flex: 1,
                        justifyContent: 'flex-end',
                        ...mr15,
                      }}>
                      <Pressable
                        style={{ ...mr10 }}
                        onPress={() => {
                          setShowSort(true);
                        }}>
                        <H6NsRegularBlack>Sort by: Date</H6NsRegularBlack>
                      </Pressable>
                      <Feather name={isDescending ? 'chevron-down' : 'chevron-up'} size={14} />
                    </View>
                  </Column>
                ) : null}
              </Row>
            </Container>
            <AppointmentListSortingModal show={showSort} onHide={() => setShowSort(false)} />
          </Grid>
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: colors.lightPurple, borderRadius: 6 },
  link: {
    minWidth: 130,
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderRadius: 6,
    backgroundColor: colors.lightPurple,
  },

  linkActive: {
    borderWidth: 1,
    borderColor: colors.purple,
    backgroundColor: colors.purple,
  },
  textActive: {
    color: colors.white,
    ...nsSemiBold,
  },
});
