import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { StyleProp, View, ViewStyle } from 'react-native';

import { RowView } from '../commonViews';
import FloatingInput from '../floatingInputBox';
import { Dropdown } from '../inputs/dropdown/Dropdown';

import { CountryListEnum, useCountryListing } from '~/api/hooks/referenceData/CountryListing';
import { flex1, ph5, pl0, pr0 } from '~/common/commonStyles';
import {
  requiredValidation,
  validationSchema,
  numberValidation,
  minLengthValidation,
} from '~/services/validationConfig';
import { labels } from '~/utils/labels';

interface Props {
  control: Control<{ mobile_number?: string; country_code?: string; [x: string]: any }, any>;
  style?: StyleProp<ViewStyle>;
  onSubmit?: () => void;
}

export const PhoneNumberInputComponent: React.FC<Props> = ({ control, style, onSubmit }) => {
  const countryItems = useCountryListing(CountryListEnum.PHONE_CODE);

  return (
    <RowView style={style}>
      <View style={[flex1, ph5, pl0, { maxWidth: 100 }]}>
        <Controller
          name="country_code"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Dropdown
              list={countryItems.map((item) => ({ label: item.name, value: item.id }))}
              setValue={onChange}
              value={value}
              errorMessage={error?.message}
              error={!!error}
              searchable
            />
          )}
          rules={{
            required: requiredValidation('Country code'),
          }}
        />
      </View>
      <View style={[flex1, ph5, pr0]}>
        <Controller
          name="mobile_number"
          control={control}
          render={({ field: { onChange, onBlur, value }, formState: { isSubmitted }, fieldState: { error } }) => (
            <FloatingInput
              showMandatory
              maxLength={validationSchema.phoneNumber.maxLength}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              keyboardType="numeric"
              label={labels.phoneNumber}
              onSubmitEditing={onSubmit}
            />
          )}
          rules={{
            validate: { numberValidation },
            required: requiredValidation(labels.phoneNumber),
            minLength: minLengthValidation(validationSchema.phoneNumber.minLength),
          }}
        />
      </View>
    </RowView>
  );
};
