import React from 'react';

import { labels } from '../../utils/labels';
import { Button, TextButton } from '../commonButton';

interface Props {
  cancelTitle?: string;
  confirmTitle?: string;
  onCancel?: () => void;
  onConfirm: () => void;
  waiting?: boolean;
  disabled?: boolean;
  disabledConfirm?: boolean;
  disabledCancel?: boolean;
  hideContinue?: boolean;
  hideCancel?: boolean;
}

export const CancelContinueFooterArray = ({
  onCancel,
  onConfirm,
  cancelTitle = labels.cancel,
  confirmTitle = labels.continue,
  waiting,
  hideCancel,
  hideContinue,
  disabled,
  disabledConfirm,
  disabledCancel,
}: Props) => {
  return [
    hideContinue ? null : (
      <Button label={confirmTitle} funCallback={onConfirm} disabled={waiting || disabled || disabledConfirm} />
    ),
    hideCancel ? null : (
      <TextButton
        label={cancelTitle.toUpperCase()}
        funCallback={onCancel}
        disabled={waiting || disabled || disabledCancel}
      />
    ),
  ];
};
