import React, { useMemo, useRef, useState } from 'react';
import { View, ScrollView, FlatList, TouchableOpacity, StyleSheet } from 'react-native';
import { TextInput } from 'react-native-paper';

import { DropdownProps } from './interfaces';
import FloatingInput from '../FloatingInput';

import { mr10 } from '~/common/commonStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import { DownArrow, UpArrow, Verified } from '~/components/svgImages';
import { colors } from '~/utils/colors';

export const MultiSelectDropdown: React.FC<DropdownProps> = ({
  list,
  label,
  disabled,
  value,
  error,
  errorMessage,
  setValue,
}) => {
  const dropDownBottom = useRef(null);
  const [inFocus, setInFocus] = useState(false);
  const [inputInFocus, setInputInFocus] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const displayValue = useMemo(() => {
    if (inputInFocus) return searchValue;
    const values = value as (string | number)[];
    if (!values?.length) return '';

    const joinedValues = (values.length > 3 ? values.slice(0, 2) : values)
      .map((v) => list.find((item) => item.value === v)?.label)
      ?.join(', ');
    if (values.length > 3) {
      return `${joinedValues} and ${values.length - 3} more`;
    }
    return joinedValues;
  }, [value, searchValue, inFocus, inputInFocus]);

  const filteredList = useMemo(() => {
    if (searchValue) {
      const lowerCaseSearch = searchValue.toLowerCase();
      return list.filter((item) => item.label.toLowerCase().includes(lowerCaseSearch));
    }
    return list;
  }, [list, searchValue]);

  const scrollToBottom = () => {
    if (dropDownBottom?.current?.scrollIntoView) {
      dropDownBottom?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const open = () => {
    setInFocus(true);
    setTimeout(() => scrollToBottom());
  };

  const selectItem = (selectedValue: string | number) => {
    if ((value as (string | number)[])?.includes(selectedValue)) {
      const newValues = (value as any[]).filter((v) => v !== selectedValue);
      setValue(newValues);
    } else {
      setValue([...(value as (string | number)[]), selectedValue]);
    }
  };
  return (
    <div>
      <View ref={dropDownBottom}>
        {inFocus}
        <FloatingInput
          label={label}
          value={displayValue}
          onChangeValue={(val) => {
            if (inputInFocus) setSearchValue(val);
          }}
          disabled={disabled}
          error={error}
          errorMessage={errorMessage}
          onFocus={() => {
            setInputInFocus(true);
            setSearchValue('');
            open();
          }}
          onBlur={() => setInputInFocus(false)}
          right={
            <TextInput.Icon
              icon={() => (inFocus ? <UpArrow width={12} height={12} /> : <DownArrow width={12} height={12} />)}
              disabled={disabled}
              style={{ backgroundColor: colors.transparent }}
              forceTextInputFocus={!inFocus}
              onPress={() => {
                setInFocus(!inFocus);
              }}
            />
          }
        />

        {inFocus && !disabled ? (
          <View style={[styles.resultsContainer]}>
            <ScrollView bounces={false} style={styles.resultsScroll}>
              <FlatList
                keyExtractor={(item) => `${item.value}`}
                renderItem={(data) => {
                  const selected = (value as (string | number)[])?.includes(data.item.value);
                  return (
                    <TouchableOpacity
                      style={styles.resultItem}
                      onPress={() => {
                        selectItem(data.item.value);
                      }}>
                      <View style={[mr10, { minWidth: 12 }]}>
                        {selected ? <Verified width={12} height={12} /> : null}
                      </View>
                      <H6NsRegularBlack>{data.item.label}</H6NsRegularBlack>
                    </TouchableOpacity>
                  );
                }}
                data={filteredList}
              />
            </ScrollView>
          </View>
        ) : null}
      </View>
    </div>
  );
};

const styles = StyleSheet.create({
  selectedContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 8,
    paddingHorizontal: 12,
    flexWrap: 'wrap',
  },
  resultsContainer: {
    maxHeight: 240,
    borderColor: colors.lightGrey,
    borderWidth: 1,
    marginTop: 8,
    borderRadius: 6,
  },
  resultsScroll: {
    padding: 8,
  },
  resultItem: {
    paddingHorizontal: 4,
    paddingVertical: 6,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputLoading: {
    position: 'absolute',
    right: 10,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 8,
  },
  textInputStyling: {
    color: colors.purpleGrey,
    fontFamily: 'NotoSans',
    fontSize: 14,
  },
});
