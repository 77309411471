import { screenName } from './screenName';

export const windowTitle = {
  [screenName.ForgotPasscodeOtp]: 'Forgot Passcode',

  [screenName.EmailScreen]: 'Enter e-mail',
  [screenName.UploadIdentificationScreen]: 'Upload Identification Screen',
  [screenName.PersonalDetails]: 'Personal details',
  [screenName.PassCodeRegisterScreen]: 'Register',
  [screenName.PassCodeLoginScreen]: 'Login',
  [screenName.RegisterScreen]: 'Register',
  [screenName.ForgotPasscodeScreen]: 'Forgot Passcode',
  [screenName.RegisterTerms]: 'Register',

  // Doctor Dashboard pages
  [screenName.Dashboard]: 'Dashboard',

  [screenName.Requests]: 'Requests',
  [screenName.Notifications]: 'Requests',
  [screenName.DocumentsExport]: 'Requests',
  [screenName.DocumentsImport]: 'Requests',

  [screenName.CalendarPage]: 'Calendar',
  [screenName.AvailabilitiesScheduledAppointments]: 'Availabilities',
  [screenName.AvailabilitiesHomeVisit]: 'Availabilities',
  [screenName.AvailabilitiesDoctorsAvailableNow]: 'Availabilities',

  [screenName.PatientManagement]: 'Patient Management',
  [screenName.PatientDetails]: 'Patient Management',
  [screenName.PatientAddNew]: 'New | Patient Management',
  [screenName.PatientEditDetails]: 'Edit | Patient Management',
  [screenName.PatientDiagnosis]: 'Diagnosis',
  [screenName.PatientConsultation]: 'Consultation',
  [screenName.PatientConsultationPrivateNotes]: 'Consultation',
  [screenName.PatientConsultationHealthRecord]: 'Consultation',
  [screenName.PatientConsultationPrescription]: 'Consultation',
  [screenName.PatientConsultationSickLeave]: 'Consultation',
  [screenName.PatientConsultationReferral]: 'Consultation',
  [screenName.PatientNewConsultation]: 'Consultation',

  [screenName.PatientHealthProfile]: 'Health profile',
  [screenName.PatientHealthProfileEdit]: 'Edit | Health profile',
  [screenName.PatientHealthProfileHealthMetrics]: 'Health profile',
  [screenName.PatientHealthProfileAllergies]: 'Health profile',
  [screenName.PatientHealthProfileMedical]: 'Health profile',
  [screenName.PatientHealthProfileMedications]: 'Health profile',
  [screenName.PatientHealthProfileProcedures]: 'Health profile',
  [screenName.PatientHealthProfileProsthesis]: 'Health profile',
  [screenName.PatientHealthProfileVaccinations]: 'Health profile',
  [screenName.PatientHealthProfileLifestyle]: 'Health profile',
  [screenName.Diagnoses]: 'Diagnoses',

  [screenName.Income]: 'Income',

  [screenName.Settings]: 'Settings',
  [screenName.SettingsConsultations]: 'Settings',
  [screenName.SettingsNotifications]: 'Settings',
  [screenName.SettingsSecurity]: 'Settings',
  [screenName.SettingsAccount]: 'Settings',

  [screenName.Help]: 'Help',
  [screenName.HelpFaq]: 'Help',
  [screenName.HelpCookiePolicy]: 'Help',
  [screenName.HelpPrivacyPolicy]: 'Help',
  [screenName.HelpTermsAndConditions]: 'Help',
  [screenName.HelpCancellationPolicy]: 'Help',

  [screenName.ProfileScreen]: 'Profile',
  [screenName.ProfilePersonalInformation]: 'Profile',
  [screenName.ProfileHomeAddress]: 'Profile',
  [screenName.ProfilePaymentDetails]: 'Profile',
  [screenName.ProfileSubscriptionPlan]: 'Profile',

  [screenName.LoginScreen]: 'Login',
  [screenName.HomeAddress]: 'Registration',
  [screenName.DoctorVerification]: 'Registration',
  [screenName.RegistrationStepperScreen]: 'Registration',
  [screenName.UploadProfileScreen]: 'Registration',
  [screenName.TypeOfConsultationScreen]: 'Registration',
  [screenName.PreferencesScreen]: 'Registration',

  [screenName.CostPerConsultationType]: 'Cost Per Consultation',
  [screenName.ConsultationChannelPreferences]: 'Preferred Online Consultation Type',
  [screenName.AvailabilitiesScreen]: 'Availabilities Screen',
  [screenName.HomeVisitNavigationMapScreen]: 'Home Visit Navigation Map Screen',
  [screenName.VideoCallScreen]: 'Video Call Screen',
  [screenName.TwoStepSelection]: 'Two Step Verification',
  [screenName.ChatScreen]: 'Chat Screen',
  [screenName.AudioCallScreen]: 'Audio Call Screen',
  [screenName.AddBankDetails]: 'Add Bank Details',
  [screenName.PrescriptionScreen]: 'Prescriptions',
};
