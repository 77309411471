import { get, put } from '~/api/axios';
import { NotificationSettingsRequest } from '~/api/models/settings/requests/NotificationSettingsRequest';
import { NotificationSettingsResponse } from '~/api/models/settings/responses/NotificationSettingsResponse';

export const apiGetNotificationSettings = () => {
  return get<NotificationSettingsResponse>('/notifications/settings');
};

export const apiUpdateNotificationSettings = (req: NotificationSettingsRequest) => {
  return put('/notifications/settings', { notification_settings: req });
};
