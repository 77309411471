import React from 'react';
import { View } from 'react-native';

import { AppLogoWithText } from '../../components/svgImages';
import { colors } from '../../utils/colors';

export const LoadingScreen: React.FC = () => {
  return (
    <View
      style={{
        display: 'flex',
        flex: 1,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.lightPurple,
      }}>
      <AppLogoWithText width={225} height={60} />
    </View>
  );
};
