import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { View } from 'react-native';

import { SelectedFrequencyText } from './SelectedFrequencyText';

import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { PrescriptionFrequencyList } from '~/constants/PrescriptionFrequencyList';
import { usePrescriptionDataContext } from '~/providers/consultation/PrescriptionDataContext';

interface Props {
  index: number;
}

export const PrescriptionsFrequencyDropdown: React.FC<Props> = ({ index }) => {
  const {
    form: { control, rulesArray },
  } = usePrescriptionDataContext();

  const frequency = useWatch({ control, name: `prescriptions.${index}.frequency` });
  return (
    <>
      <Controller
        name={`prescriptions.${index}.frequency`}
        control={control}
        render={({ field: { value, onChange }, fieldState: { isTouched, error } }) => (
          <Dropdown
            value={value}
            error={!!error}
            errorMessage={error?.message}
            list={PrescriptionFrequencyList.map((item) => ({ label: item.name, value: item.id }))}
            label="Choose frequency"
            setValue={onChange}
            showMandatory
          />
        )}
        rules={rulesArray?.prescriptions.frequency}
      />
      <View>
        <SelectedFrequencyText frequencyValue={frequency} />
      </View>
    </>
  );
};
