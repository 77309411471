import { isSameDay } from 'date-fns';
import { createStaticRanges } from 'react-date-range';

import { isToDate } from '../util/DateFilterUtils';

import { DataRangeOptionsMapping, DateRangeOptionItems } from '~/components/doctor/income/constants/DateRangeOptions';
import { DateRangeOptions } from '~/components/patient/insurance/constants/DateRangeOptionsEnum';

export const staticRanges = createStaticRanges([
  {
    label: DateRangeOptionItems[DateRangeOptions.TODAY].label,
    range: () => DataRangeOptionsMapping[DateRangeOptions.TODAY].range,
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range?.startDate, definedRange?.startDate) &&
        isSameDay(range?.endDate, definedRange?.endDate) &&
        range?.startDate.getTime() !== range.endDate.getTime()
      );
    },
  },
  {
    label: DataRangeOptionsMapping[DateRangeOptions.LASTWEEK].label,
    range: () => DataRangeOptionsMapping[DateRangeOptions.LASTWEEK].range,
    isSelected(range) {
      const definedRange = this.range();
      return isSameDay(range?.startDate, definedRange?.startDate) && isSameDay(range?.endDate, definedRange?.endDate);
    },
  },

  {
    label: DataRangeOptionsMapping[DateRangeOptions.THISWEEK].label,
    range: () => DataRangeOptionsMapping[DateRangeOptions.THISWEEK].range,

    isSelected(range) {
      const definedRange = this.range();
      return isSameDay(range?.startDate, definedRange?.startDate) && isSameDay(range?.endDate, definedRange?.endDate);
    },
  },
  {
    label: DataRangeOptionsMapping[DateRangeOptions.LASTMONTH].label,
    range: () => DataRangeOptionsMapping[DateRangeOptions.LASTMONTH].range,

    isSelected(range) {
      const definedRange = this.range();
      return isSameDay(range?.startDate, definedRange?.startDate) && isSameDay(range?.endDate, definedRange?.endDate);
    },
  },
  {
    label: DataRangeOptionsMapping[DateRangeOptions.THISMONTH].label,
    range: () => DataRangeOptionsMapping[DateRangeOptions.THISMONTH].range,

    isSelected(range) {
      const definedRange = this.range();
      return isSameDay(range?.startDate, definedRange?.startDate) && isSameDay(range?.endDate, definedRange?.endDate);
    },
  },
  {
    label: DataRangeOptionsMapping[DateRangeOptions.LASTYEAR].label,
    range: () => DataRangeOptionsMapping[DateRangeOptions.LASTYEAR].range,

    isSelected(range) {
      const definedRange = this.range();
      return isSameDay(range?.startDate, definedRange?.startDate) && isSameDay(range?.endDate, definedRange?.endDate);
    },
  },
  {
    label: DataRangeOptionsMapping[DateRangeOptions.THISYEAR].label,
    range: () => DataRangeOptionsMapping[DateRangeOptions.THISYEAR].range,

    isSelected(range) {
      const definedRange = this.range();
      return isSameDay(range?.startDate, definedRange?.startDate) && isSameDay(range?.endDate, definedRange?.endDate);
    },
  },

  {
    label: DataRangeOptionsMapping[DateRangeOptions.TODATE].label,
    range: () => {
      const date = new Date();
      return {
        startDate: date,
        endDate: date,
      };
    },

    isSelected(range) {
      return isToDate(range);
    },
  },
]);
