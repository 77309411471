import React, { useState } from 'react';
import { KeyboardAvoidingView, Modal, Platform, StyleSheet, View } from 'react-native';

import { Button, OutlineButton } from './commonButton';
import { H6NsRegularBlack, H6NsSemiBoldBlack } from './commonText';
import { LoadingActivityIndicator } from './loading/LoadingActivityIndicator';
import { ErrorAlert } from './modals/ErrorAlert';
import { modalStyles } from './modals/ModalStyling';
import { BaseModalProps } from './modals/interfaces/ModalProps';
import { NotificationIcon } from './svgImages';

import { ConsultationRescheduleDetailsModel } from '~/api/models/appointments/models/ConsultationRescheduleDetailsModel';
import { apiApproveRescheduleRequest, apiDeclineRescheduleRequest } from '~/api/services/consultations/request';
import { flex1, mt10 } from '~/common/commonStyles';
import { Column, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { parseDateTime, TIME_FORMAT } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  consultationRescheduleRequest: ConsultationRescheduleDetailsModel;
}

export const PatientAppointmentRescheduleModal: React.FC<Props> = ({ onHide, consultationRescheduleRequest }) => {
  const { isMobile } = useBreakpoints();
  const [loading, setLoading] = useState(false);

  const approveRescheduleRequest = async () => {
    setLoading(true);
    try {
      await apiApproveRescheduleRequest({
        consultationId:
          consultationRescheduleRequest.consultation?.id || consultationRescheduleRequest?.consultation_id,
        consultationRequestId: consultationRescheduleRequest.id,
      });
      onHide();
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const declineRescheduleRequest = async () => {
    setLoading(true);
    try {
      await apiDeclineRescheduleRequest({
        consultationId:
          consultationRescheduleRequest.consultation?.id || consultationRescheduleRequest?.consultation_id,
        consultationRequestId: consultationRescheduleRequest.id,
      });
      onHide();
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  return (
    <Modal animationType="fade" transparent visible onRequestClose={null}>
      <KeyboardAvoidingView
        style={[isMobile ? null : { right: 45 }, { display: 'flex', flex: 1, top: 30 }]}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <View style={[modalStyles.modalWrapper]}>
          <View style={[flex1, { display: 'flex', height: '100%', paddingHorizontal: 20 }]}>
            <View
              pointerEvents="auto"
              style={[styles.contentContainer, isMobile ? {} : { alignSelf: 'flex-end', maxWidth: 370 }]}>
              <Grid>
                <Row style={[styles.row]}>
                  <View style={{ marginLeft: 10 }}>
                    <NotificationIcon width={30} height={30} />
                  </View>

                  <Column span={{ xs: 10, md: 10 }}>
                    <H6NsRegularBlack>{`The upcoming appointment has been rescheduled to ${parseDateTime(
                      consultationRescheduleRequest?.datetime_from,
                      {
                        outputFormat: TIME_FORMAT,
                      }
                    )}.`}</H6NsRegularBlack>
                    <H6NsSemiBoldBlack>Kindly approve or decline this change.</H6NsSemiBoldBlack>
                  </Column>
                </Row>

                {loading ? (
                  <LoadingActivityIndicator />
                ) : (
                  <Row style={styles.row}>
                    <Column>
                      <OutlineButton
                        danger
                        label={labels.decline}
                        funCallback={declineRescheduleRequest}
                        style={mt10}
                      />
                    </Column>
                    <Column>
                      <Button success label={labels.approve} funCallback={approveRescheduleRequest} style={mt10} />
                    </Column>
                  </Row>
                )}
              </Grid>
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    width: '100%',
    maxHeight: '100%',
    borderRadius: 5,
    backgroundColor: colors.lightPurple2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    display: 'flex',
  },
  row: { alignItems: 'center', justifyContent: 'center', marginHorizontal: 0, marginVertical: 10 },
});
