import React, { useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { AvailableDoctorModelWithQueue } from '~/api/models/appointments/models/AvailableDoctorModel';
import { flex1, ml10 } from '~/common/commonStyles';
import { H5TtmRegularBlack, H5TtmSemiBoldDarkWarning, H5TtmSemiBoldSuccess } from '~/components/commonText';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { ArrowRight } from '~/components/svgImages';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { getQueueInfo } from '~/utils/consultationUtils';
import { labels } from '~/utils/labels';

interface Props {
  doctor: AvailableDoctorModelWithQueue;
}

export const ClinicDoctorComponent: React.FC<Props> = ({ doctor }) => {
  const queueInfo = useMemo(() => getQueueInfo(doctor.queue), [doctor?.queue]);
  const { openModal } = useModalManager();

  const showDoctorDetails = () => {
    openModal(ModalName.DoctorInfoModal, {
      doctor,
      okTitle: labels.close,
    });
  };
  return (
    <TouchableOpacity onPress={showDoctorDetails}>
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <ProfileDetailsComponent account={doctor} style={flex1} theme="default" />
        <View>
          {queueInfo.noQueue ? (
            <H5TtmSemiBoldSuccess>Not in call</H5TtmSemiBoldSuccess>
          ) : (
            <View style={{ display: 'flex', alignItems: 'flex-end' }}>
              <H5TtmSemiBoldDarkWarning>In a call</H5TtmSemiBoldDarkWarning>
              <H5TtmRegularBlack>{queueInfo.description}</H5TtmRegularBlack>
            </View>
          )}
        </View>
        <View style={ml10}>
          <ArrowRight width={12} height={12} />
        </View>
      </View>
    </TouchableOpacity>
  );
};
