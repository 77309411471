import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { BillingRequestForm } from '../contexts/BillingHistoryProvider';
import { dateList, getYearsArray, typeList } from '../utils/FilterMenuUtils';

import { H6NsRegularDisableColor } from '~/components/commonText';
import { CheckboxGroup } from '~/components/inputs/CheckboxGroup';
import { isDoctorVersion } from '~/utils/buildConfig';

interface Props {
  form: UseFormReturn<BillingRequestForm, any>;
  isMenu?: boolean;
}

export const BillingHistoryFilters: React.FC<Props> = ({ form, isMenu }) => {
  return (
    <View style={styles.container}>
      <H6NsRegularDisableColor>Year</H6NsRegularDisableColor>

      <Controller
        name="filter.year"
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <CheckboxGroup
            isMenuItem={isMenu}
            values={value}
            items={getYearsArray()}
            singleValue
            onChange={(value) => {
              form.setValue('filter.min_date', []);
              onChange(value);
            }}
          />
        )}
      />

      <H6NsRegularDisableColor>Date</H6NsRegularDisableColor>
      <Controller
        name="filter.min_date"
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <CheckboxGroup
            isMenuItem={isMenu}
            values={value}
            items={dateList}
            singleValue
            onChange={(value) => {
              form.setValue('filter.year', []);
              onChange(value);
            }}
          />
        )}
      />

      {isDoctorVersion() ? null : (
        <>
          <H6NsRegularDisableColor>Type</H6NsRegularDisableColor>
          <Controller
            name="filter.type"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <CheckboxGroup
                isMenuItem={isMenu}
                values={value}
                items={typeList}
                onChange={(value) => {
                  onChange(value);
                }}
              />
            )}
          />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
  },
});
