export function timeout(ms?: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function promiseAllSettledHelper<T extends readonly unknown[] | []>(values: T) {
  return Promise.all(
    values.map(function (promise) {
      return promise
        .then(function (value) {
          return { status: 'fulfilled', value: value };
        })
        .catch(function (reason) {
          return { status: 'rejected', reason: reason };
        });
    })
  );
}
