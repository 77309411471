import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { AppointmentShowPageLayout } from '../../AppointmentShowPageLayout';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { mb10, pb15 } from '~/common/commonStyles';
import { ConsultationReportComponent } from '~/components/common/consultations/ConsultationReportComponent';
import { ButtonLink } from '~/components/commonButton';
import { H3TtmRegularBlack, H6NsRegularBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { ConsultationDetailsComponent } from '~/components/doctor/patients/consultation/ConsultationDetailsComponent';
import { Row, Column } from '~/theme/components/grid';
import { isPatientVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

interface Props {
  consultation: ConsultationModel;
}

export const AppointmentConsultationView: React.FC<Props> = ({ consultation }) => {
  const canViewReport = useMemo(() => consultation?.state === ConsultationStateEnum.Submitted, [consultation]);

  const started = useMemo(() => consultation?.state === ConsultationStateEnum.Started, [consultation]);

  return (
    <AppointmentShowPageLayout>
      <Row>
        <Column span={{ xs: 12, lg: 5 }} style={pb15}>
          <ConsultationDetailsComponent consultation={consultation} />
        </Column>
        <Column span={{ xs: 12, lg: 7 }} style={pb15}>
          <Row>
            <Column>
              {canViewReport ? (
                <>
                  <ConsultationReportComponent consultation={consultation} />
                  {isPatientVersion() && consultation?.diagnosis?.id ? (
                    <View style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                      <View>
                        <ButtonLink
                          label="Linked consultations"
                          to={
                            {
                              screen: screenName.PatientVersionDiagnosis,
                              params: { diagnosisId: consultation.diagnosis.id },
                            } as never
                          }
                        />
                      </View>
                    </View>
                  ) : null}
                </>
              ) : (
                <View>
                  <H3TtmRegularBlack style={mb10}>{labels.consultationReport}</H3TtmRegularBlack>
                  <View style={styles.noReport}>
                    {started ? (
                      <>
                        <H6NsSemiBoldBlack style={mb10}>Your consultation is currently underway.</H6NsSemiBoldBlack>
                        <H6NsRegularBlack>
                          You will be able to view the consultation report once the physician has ended your
                          consultation and submitted their report.
                        </H6NsRegularBlack>
                      </>
                    ) : (
                      <H6NsRegularBlack>
                        Your report is not ready yet, we will notify you once the physician has submitted the final
                        consultation details.
                      </H6NsRegularBlack>
                    )}
                  </View>
                </View>
              )}
            </Column>
          </Row>
        </Column>
      </Row>
    </AppointmentShowPageLayout>
  );
};

const styles = StyleSheet.create({
  noReport: {
    borderWidth: 1,
    borderRadius: 6,
    borderColor: colors.lightPurple2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 60,
  },
});
