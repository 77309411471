import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import { usePharmacyUsersList } from '~/api/hooks/pharmacy/PharmacyUsersList';
import {
  DeleteablePharmacyInvite,
  PharmacyInvitationStateEnum,
} from '~/api/models/pharmacy/constants/PharmacyInvitationStateEnum';
import { PharmacyUserModel } from '~/api/models/pharmacy/models/PharmacyUserModel';
import { apiDeletePharmacyInvitation, apiDeletePharmacyMember } from '~/api/services/pharmacy';
import { ConfirmationAlert, SuccessAlert } from '~/common/commonMethods';
import { flex1 } from '~/common/commonStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { DataTable } from '~/components/common/DataTable/DataTable';
import { DataTableColumns } from '~/components/common/DataTable/DataTableTypes';
import { Button } from '~/components/commonButton';
import { ExtraSmallNsRegularPlaceholder, SmallNsRegularBlack } from '~/components/commonText';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { InvitePharmacyUserModal } from '~/components/pharmacies/InvitePharmacyUserModal';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { DeleteIcon } from '~/components/svgImages';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { sameOrAfter } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { getAccountName, getAccountPhone } from '~/utils/personalDetailsUtils';
import { screenName } from '~/utils/screenName';

const STATUS_COLOR_MAPPING = {
  [PharmacyInvitationStateEnum.REJECTED]: colors.danger,
  [PharmacyInvitationStateEnum.EXPIRED]: colors.danger,
  [PharmacyInvitationStateEnum.PENDING]: colors.warning,
  [PharmacyInvitationStateEnum.APPROVED]: colors.success,
};

export const PharmacyUserManagementScreen: React.FC = () => {
  const { registerModal, openModal } = useModalManager();
  const { pharmacyUsers, getListOfUsers, loading } = usePharmacyUsersList();
  const currentPharmacy = useSelector(currentPharmacySelector);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    registerModal(ModalName.InvitePharmacist, InvitePharmacyUserModal);
  }, []);

  const showInviteUser = () => {
    openModal(ModalName.InvitePharmacist, {
      onComplete: ({ email }: { email: string }) => {
        getListOfUsers();
        SuccessAlert(['Successfully sent invitation to\n' + email]);
      },
    });
  };

  const tryDeleteInviteOrAccess = (item: PharmacyUserModel) => {
    const isApproved = item.status === PharmacyInvitationStateEnum.APPROVED;
    ConfirmationAlert(
      isApproved
        ? [
            `Are you sure you want to revoke access to\n${getAccountName(item) || item.email}?`,
            'The pharmacist will no longer be able to access this pharmacy and view anything related to prescriptions.',
          ]
        : [`Are you sure you want to delete the invitation to\n${item.email}?`],
      {
        title: isApproved ? 'Revoke access' : 'Delete invitation',
        okFunction: () => deleteInvite(item),
      }
    );
  };

  const deleteInvite = async (item: PharmacyUserModel) => {
    try {
      if (item.user_id) {
        await apiDeletePharmacyMember({ user_id: item.user_id, pharmacy_id: currentPharmacy.pharmacy.id });
        SuccessAlert(['Successfully revoked access']);
      } else {
        await apiDeletePharmacyInvitation({ id: item.id });
        SuccessAlert(['Successfully deleted invitation']);
      }
      getListOfUsers();
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const columns: DataTableColumns = [
    {
      key: 'first_name',
      title: 'First name',
    },
    {
      key: 'last_name',
      title: 'Last name',
    },
    {
      key: 'email',
      title: 'Email',
    },
    {
      key: 'mobile',
      title: 'Phone number',
      selector: (item: PharmacyUserModel) => getAccountPhone(item),
    },
    {
      key: 'status',
      title: 'Status',
      cell: (cellData, item: PharmacyUserModel) => {
        const expired =
          item.status !== PharmacyInvitationStateEnum.APPROVED ? sameOrAfter(new Date(), item.expires_at) : null;
        const status = expired ? PharmacyInvitationStateEnum.EXPIRED : cellData;
        return (
          <StatusBadge
            label={status}
            color={STATUS_COLOR_MAPPING[status]}
            textStyle={{ textTransform: 'capitalize' }}
          />
        );
      },
      columnStyle: { maxWidth: 100 },
    },
    {
      key: 'options',
      title: 'Options',
      showOnHover: true,
      contentNumeric: true,
      cell: (_, item: PharmacyUserModel) => {
        return DeleteablePharmacyInvite.includes(item.status) ? (
          <IconButton transparent onPress={() => tryDeleteInviteOrAccess(item)}>
            <DeleteIcon height={15} width={15} color={colors.danger} />
          </IconButton>
        ) : null;
      },

      columnStyle: { maxWidth: 60 },
    },
  ];

  const headerRight = (
    <Row justifyContent="flex-end">
      <Column span="auto" style={{ marginTop: isMobile ? 55 : undefined }}>
        <Button label="Add new user" funCallback={showInviteUser} />
      </Column>
    </Row>
  );

  return (
    <PageLayout
      headerRight={headerRight}
      hideBack={!isMobile}
      title="Users"
      back={{ to: { screen: screenName.PharmacySettings } }}
      scrollContentNode={
        <DataTable
          tableId="pharma_users"
          columns={columns}
          items={pharmacyUsers}
          loading={loading}
          mobileBreakpoint={DeviceSizeDefaults.tablet}
          mobileRenderItem={(item, content) => (
            <View style={{ width: '100%' }}>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={flex1}>
                  <ProfileDetailsComponent account={item} theme="table" />
                </View>
                <View style={{ display: 'flex', justifyContent: 'center', marginRight: 10 }}>
                  {content.status.content}
                </View>
                {content.options.content}
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={styles.bottomCell}>
                  <ExtraSmallNsRegularPlaceholder>Email</ExtraSmallNsRegularPlaceholder>
                  <SmallNsRegularBlack>{content.email.cellData}</SmallNsRegularBlack>
                </View>
                <View style={[styles.bottomCell, styles.bottomBordered]}>
                  <ExtraSmallNsRegularPlaceholder>Phone number</ExtraSmallNsRegularPlaceholder>
                  <SmallNsRegularBlack>{content.mobile.cellData}</SmallNsRegularBlack>
                </View>
              </View>
            </View>
          )}
        />
      }
    />
  );
};

const styles = StyleSheet.create({
  bottom: {
    borderTopWidth: 1,
    borderTopColor: colors.lightPurple2,
    display: 'flex',
    flexDirection: 'row',
  },
  bottomCell: {
    flex: 1,
    padding: 10,
    paddingRight: 10,
  },
  bottomBordered: {
    borderLeftColor: colors.lightPurple2,
    borderLeftWidth: 1,
    paddingRight: 0,
    paddingLeft: 10,
  },
});
