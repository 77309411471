import React, { useEffect, useMemo } from 'react';
import { Control, Controller, UseFormReset, useWatch } from 'react-hook-form';
import { View } from 'react-native';

import { useLanguageListing } from '../../../../api/hooks/referenceData/LanguageListing';
import { Column, Container, Row } from '../../../../theme/components/grid';
import { labels } from '../../../../utils/labels';
import { SmallNsRegularBlack } from '../../../commonText';

import { useConsultationPreferences } from '~/api/hooks/accounts/ConsultationPreferencesHook';
import { ConsultationTypeFormModel } from '~/api/models/consultations/models/ConsultationTypeFormModel';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';

interface Props {
  control: Control<ConsultationTypeFormModel>;
  reset: UseFormReset<ConsultationTypeFormModel>;
}

export const SettingsConsultationLanguage: React.FC<Props> = ({ control, reset }) => {
  const languages = useLanguageListing();
  const { userPreferences } = useConsultationPreferences();

  const watch = useWatch({ control });
  useEffect(() => {
    reset({
      types: watch.types,
      languages: userPreferences?.languages?.map((language) => language.id) ?? [],
      channels: userPreferences?.consultation_channels?.map((channel) => channel.id) ?? [],
    });
  }, [userPreferences]);

  const languageOptions = useMemo(
    () => languages?.map((language) => ({ value: language.id, label: language.name })) || [],
    [languages]
  );

  return (
    <Container alignSelf="flex-start">
      <Row>
        <Column span={{ xs: 12, md: 6 }}>
          <View style={{ marginTop: 15 }}>
            <SmallNsRegularBlack style={{ marginVertical: 15 }}>
              {labels.preferredOnlineConsultationLanguageSelect}
            </SmallNsRegularBlack>
            <Controller
              control={control}
              name="languages"
              render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                <Dropdown
                  list={languageOptions}
                  value={value}
                  setValue={onChange}
                  searchable
                  multiSelect
                  label="Choose your languages"
                  dropDownContainerStyle={{ marginTop: 5 }}
                  error={isSubmitted && !!error}
                  errorMessage={isSubmitted && error?.message}
                />
              )}
            />
          </View>
        </Column>
      </Row>
    </Container>
  );
};
