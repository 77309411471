import React, { useMemo } from 'react';

import { ConsultationViewItem } from '../../ConsultationViewItem';

import { ReferralMediaTypeModel } from '~/api/models/referrals/models/ReferralMediaTypeModel';
import { H4TtmRegularSecondaryBlack, H6NsRegularBlack, SmallNsRegularBlack } from '~/components/commonText';
import { flex1, mb10 } from '~/common/commonStyles';
import { InfoCircle } from '~/components/svgImages';
import { View } from 'react-native';

interface Props {
  mediaType: ReferralMediaTypeModel[];
}

export const OtherForms: React.FC<Props> = ({ mediaType }) => {
  const media = useMemo(() => mediaType?.filter((item) => item.media?.length), [mediaType]);
  return (
    <>
      <H4TtmRegularSecondaryBlack style={mb10}>Other Forms</H4TtmRegularSecondaryBlack>
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
        <InfoCircle width={12} height={12} />
        <SmallNsRegularBlack style={flex1}>
          Referral forms can be downloaded and filled out digitally if the device you're using has a PDF reader
          installed
        </SmallNsRegularBlack>
      </View>
      {media.map((element) => {
        const mediaModel = element.media[0];
        return <ConsultationViewItem key={element.id} title={element.title} value={mediaModel} />;
      })}
    </>
  );
};
