import { useNavigation } from '@react-navigation/native';

import { useConsultationHelper } from '../helpers/HelperConsultation';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { NavType } from '~/navigation/types';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { SET_RESCHEDULE_BOOKING } from '~/redux/reducers/patient/appointmentReducer';
import { useAppDispatch } from '~/redux/store';
import { isPatientVersion } from '~/utils/buildConfig';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { screenName } from '~/utils/screenName';

export const useHandleConsultationIgnoredOrRejected = () => {
  const { showSnackbar } = useSnackbarManager();
  const { getConsultation } = useConsultationHelper();
  const { navigate } = useNavigation<NavType>();
  const dispatch = useAppDispatch();

  const execute = async (consultationId: number) => {
    const consultation = await getConsultation(consultationId);
    if (!consultation) return;

    let message;
    if (consultation.state === ConsultationStateEnum.RequestIgnored) {
      message = `${
        getAccountName(consultation.doctor || consultation.clinic) || 'The physician'
      } did not respond to your appointment request.`;
    } else if (consultation.state === ConsultationStateEnum.RequestRejected) {
      message = `${
        getAccountName(consultation.doctor || consultation.clinic) || 'The physician'
      } is not able to handle your request.`;
    }

    if (!message) return;
    showSnackbar(message, {
      keyRef: `${consultation.state}.${consultationId}`,
      actionTitle: 'Click here to try another physician.',
      onPress: () => {
        dispatch(SET_RESCHEDULE_BOOKING(consultation));
        navigate(screenName.AppointmentsBooking);
      },
    });
  };

  const emitEvent = (consultationId?: number) => {
    AppEventHandler.emit(AppEvents.PATIENT_APPOINTMENTS_CHANGED, [consultationId]);
  };

  const validate = () => {
    return isPatientVersion();
  };

  const handleNotification = async (consultationId: number) => {
    if (!validate()) return;

    await execute(consultationId);
    emitEvent(consultationId);
  };

  return { handleNotification };
};
