import React, { useContext } from 'react';
import { Control } from 'react-hook-form';

import { BaseConsultationDataContext } from './BaseConsultationDataContext';

import { MediaWithStatusModel } from '~/api/models/common/models/MediaWithStatusModel';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { SickLeaveFormModel } from '~/api/models/consultations/models/SickLeaveFormModel';

export interface ISickLeaveDataContext extends BaseConsultationDataContext {
  control: Control<SickLeaveFormModel>;
  consultation: ConsultationModel;
  pollForMedia: () => Promise<void>;
  medicalNoteMedia?: MediaWithStatusModel;
}

export const SickLeaveDataContext = React.createContext<ISickLeaveDataContext>({} as ISickLeaveDataContext);
export const useSickLeaveDataContext = (): ISickLeaveDataContext => useContext(SickLeaveDataContext);
