import { useEffect, useState } from 'react';

import { PaymentMethodsModel } from '~/api/models/stripe/models/PaymentMethodsModel';
import { apiGetPaymentMethods, apiRemoveCard, apiSetupIntent } from '~/api/services/stripe';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { StripePaymentMethodRef } from '~/integrations/stripe/interface';

interface Props {
  paymentMethodRef?: React.MutableRefObject<StripePaymentMethodRef>;
}
export function usePaymentCards(props: Props) {
  const [cardInfo, setCardInfo] = useState<PaymentMethodsModel[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const getPaymentMethods = async () => {
    setLoading(true);
    await apiGetPaymentMethods()
      .then((res) => {
        setCardInfo(res.data);
      })
      .catch((e: ErrorResponse) => {
        if (e.status !== 404) ErrorAlert(e);
        else setCardInfo([]);
      })
      .finally(() => setLoading(false));
  };

  const addCard = async () => {
    if (!props.paymentMethodRef) return;
    try {
      const res = await apiSetupIntent();
      await props.paymentMethodRef?.current.initializePaymentSheet({ clientSecret: res.data.client_secret });
      await getPaymentMethods();
      SuccessAlert(['Successfully added a new payment method']);
    } catch (e) {
      if (e.message !== 'Canceled') ErrorAlert(e);
    }
  };

  const removeCard = async (id: string) => {
    await apiRemoveCard(id)
      .then(() => {
        const valueIndex = cardInfo?.findIndex((selectedValue) => selectedValue.id === id);
        if (valueIndex > -1) {
          const newCardInfo = [...cardInfo];
          newCardInfo.splice(valueIndex, 1);
          setCardInfo(newCardInfo);
        }
      })
      .catch(ErrorAlert);
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  return { loading, cardInfo, getPaymentMethods, addCard, removeCard };
}
