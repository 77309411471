import { useEffect, useState } from 'react';

import { useUserDetails } from '../accounts/UserDetails';
import { useFeatureAccess } from '../subscriptions/FeatureAccessHook';
import { useSubscriptions } from '../subscriptions/SubscriptionHook';

import { privateSubscriptionsChannel } from '~/api/models/channel/enum/channelNames';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { channelSubscribe } from '~/integrations/channels/PusherChannels';

export function usePrivateSubscriptionChannel() {
  const { userDetails } = useUserDetails({ allowStale: true });
  const { fetchFeatureAccess } = useFeatureAccess();

  const { getCurrentSubscriptionPlan } = useSubscriptions();

  const subscriptionEventHandler = (event, data: object) => {
    getCurrentSubscriptionPlan().then((plan) => {
      AppEventHandler.emit(AppEvents.SUBSCRIPTION_CHANGE, [event, plan]);
    });
    fetchFeatureAccess();
  };

  const subscribe = async () => {
    const res = await channelSubscribe(privateSubscriptionsChannel(userDetails.id), subscriptionEventHandler);
    return () => {
      res.unsubscribe();
    };
  };

  useEffect(() => {
    if (!userDetails) return;

    subscribe();
  }, [userDetails]);
}
