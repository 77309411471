import React from 'react';
import { StyleSheet, View } from 'react-native';

import { PatientHistoryItem } from './PatientHistoryItem';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { mb10 } from '~/common/commonStyles';
import { Accordion } from '~/components/buttons/Accordions/Accordion';
import { ClinicalTermSearchableList } from '~/components/inputs/searchable/ClinicalTermSearchableList';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { useHealthProfileHistoryItemContext } from '~/providers/healthProfile/history/HealthProfileHistoryItemContext';
import { colors } from '~/utils/colors';

interface Props {
  title?: string;
  type: ClinicalTermTypeEnum;
  locked?: boolean;
}

export const PatientHistoryAccordionItem: React.FC<Props> = ({ title, type, locked }) => {
  const { setPatientHistory } = useHealthProfileDataContext();
  const { getData, hasData, loading } = useHealthProfileHistoryItemContext();

  const expandedChanged = (expanded: boolean) => {
    if (expanded && (!hasData || locked)) {
      getData().catch(ErrorAlert);
    }
  };

  return (
    <View style={mb10}>
      <Accordion title={title} groupValue={type} contentStyle={{ padding: 0 }} onExpandedChanged={expandedChanged}>
        <View style={styles.accordion}>
          {loading ? (
            <LoadingActivityIndicator />
          ) : (
            <>
              {!locked ? (
                <View style={styles.search}>
                  <ClinicalTermSearchableList
                    singleSelect
                    type={type}
                    title={title}
                    inputOptions={{ style: styles.searchInput }}
                    setValues={(values) => {
                      setPatientHistory(type, values[0], []);
                    }}
                  />
                </View>
              ) : null}
              <View style={styles.items}>
                <PatientHistoryItem title={title} type={type} locked={locked} loading={loading} />
              </View>
            </>
          )}
        </View>
      </Accordion>
    </View>
  );
};

const styles = StyleSheet.create({
  search: {
    width: '100%',
    backgroundColor: colors.lightPurple,
    paddingHorizontal: 8,
    paddingBottom: 8,
  },
  searchInput: {
    backgroundColor: colors.white,
  },
  items: {
    padding: 8,
    width: '100%',
  },
  accordion: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
});
