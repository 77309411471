import Feather from '@expo/vector-icons/Feather';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';

import { HomeVisitAvailabilitiesMap } from './HomeVisitAvailabilitiesMap';
import { HomeVisitAvailabilityEditDetails } from './HomeVisitAvailabilityEditDetails';

import { HomeVisitFormModel } from '~/api/models/preferences/models/HomeVisitModel';
import { HomeVisitWithIdModel } from '~/api/models/preferences/models/HomeVisitWithIdModel';
import { mt25, pb20, mt20, mr25, mb20 } from '~/common/commonStyles';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { OutlineButton } from '~/components/commonButton';
import { FormErrorMessage, H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { MapModal } from '~/components/modals/MapModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { requiredValidation } from '~/services/validationConfig';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props {
  homeVisit?: HomeVisitWithIdModel;
  isSmallScreen?: boolean;
  hideTitle?: boolean;
  onSave: (homeVisit: HomeVisitFormModel | HomeVisitWithIdModel) => void;
  onCancel?: () => void;
}

const DefaultHomeVisit: HomeVisitFormModel = {
  starts_at: '00:00',
  ends_at: '23:59',
  slot_duration_in_minutes: undefined,
  recurring_pattern_data: {
    days_of_week: [],
    repeat_until: '',
    interval_type: 'weekly',
    interval: 1,
  },
  max_distance: 1,
  formatted_address: '',
  id: undefined,
  latLng: undefined,
};

export const HomeVisitAvailabilityEditMode: React.FC<Props> = ({
  homeVisit,
  hideTitle,
  isSmallScreen,
  onSave,
  onCancel,
}) => {
  const { isMobile } = useBreakpoints();
  const { registerModal, openModal } = useModalManager();

  const { control, watch, handleSubmit, reset, setValue } = useForm<HomeVisitFormModel>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      ...DefaultHomeVisit,
    },
  });

  const { max_distance } = watch();

  useEffect(() => {
    if (isMobile || isSmallScreen) registerModal(ModalName.Map, MapModal);
  }, [isMobile, isSmallScreen]);

  useEffect(() => {
    if (!homeVisit) reset({ ...DefaultHomeVisit });
    else {
      const lat = Number(homeVisit.lat);
      const lng = Number(homeVisit.lng);
      reset({
        ...homeVisit,
        latLng:
          lat && lng
            ? {
                lat,
                lng,
              }
            : undefined,
      });
    }
  }, [homeVisit]);

  const openMapModal = () => {
    openModal(ModalName.Map, {
      control,
      setValue,
    });
  };

  const isUpdating = useMemo(() => !!homeVisit?.id, [homeVisit]);
  return (
    <>
      <Row style={mt25}>
        <Column span={isSmallScreen ? 12 : { xs: 12, md: 6 }}>
          <H3TtmSemiBoldBlack>{!isUpdating ? labels.addAvailability : labels.updateAvailability}</H3TtmSemiBoldBlack>
        </Column>
      </Row>
      <Row style={{ flexDirection: 'row', flexWrap: 'wrap', paddingBottom: 20 }}>
        <Column
          span={isSmallScreen ? 12 : { xs: 12, md: 6 }}
          style={isMobile || isSmallScreen ? styles.mobileSeparator : styles.webSeparator}>
          <View style={[mt20, isMobile || isSmallScreen ? null : mr25]}>
            <HomeVisitAvailabilityEditDetails control={control} />
          </View>
        </Column>
        {isMobile ? null : <View style={{ flex: 0.05 }} />}
        <Column span={isSmallScreen ? 12 : { xs: 12, md: 6 }} style={styles.columns}>
          <H6NsRegularBlack style={mb20}>
            Choose the area that you want to serve for your home visits during the selected time.
          </H6NsRegularBlack>
          <Controller
            control={control}
            name="latLng"
            render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
              <>
                {isMobile || isSmallScreen ? (
                  <OutlineButton
                    funCallback={openMapModal}
                    icon={<Feather name="map" size={14} color={colors.purple} />}>
                    {labels.chooseLocation}
                  </OutlineButton>
                ) : (
                  <View style={{ flex: 1 }}>
                    <HomeVisitAvailabilitiesMap
                      latLng={value}
                      radius={max_distance}
                      setLatLng={onChange}
                      setRadius={(value) => setValue('max_distance', value)}
                      disableInitialLocation={isUpdating}
                      initialFormattedAddress={isUpdating ? homeVisit?.formatted_address : ''}
                    />
                  </View>
                )}
                {isSubmitted && !!error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
              </>
            )}
            rules={{
              required: requiredValidation('Location'),
            }}
          />
        </Column>
      </Row>
      <Row style={pb20}>
        <Column>
          <CancelContinueFooter
            onConfirm={handleSubmit(onSave)}
            confirmTitle={!isUpdating ? labels.addAvailability : labels.updateAvailability}
            hideCancel={!onCancel}
            onCancel={onCancel}
            cancelTitle={labels.cancel}
            stacked={isSmallScreen}
          />
        </Column>
      </Row>
    </>
  );
};

const styles = StyleSheet.create({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  mobileSeparator: {
    paddingBottom: 20,
    borderBottomColor: colors.lightPurple2,
    borderBottomWidth: 1,
    marginBottom: 20,
  },
  webSeparator: {
    borderRightColor: colors.lightPurple2,
    borderRightWidth: 1,
  },
  map: { paddingVertical: 50, flex: 1, height: 300, width: 400, borderRadius: 10 },
  columns: {
    flex: 1,
    minWidth: 200,
  },
});
