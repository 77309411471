import React, { useMemo } from 'react';
import { View } from 'react-native';

import { PassCodeRegisterScreen } from './PassCodeRegisterScreen';
import PersonalDetails from './PersonalDetails';
import { RegistrationTermsAndConditionsScreen } from './RegistrationTermsAndConditionsScreen';
import { TwoStepSelectionScreen } from './TwoStepSelectionScreen';

import { useFetchRegistrationStatus } from '~/api/hooks/RegistrationSteps';
import { useClinicAdminRegistrationStatus } from '~/api/hooks/clinics/ClinicAdminRegistrationAction';
import { apiApproveClinicAdminToken } from '~/api/services/clinics';
import { MissingClinicTokenError } from '~/classes/errors/MissingClinicTokenError';
import { DefaultRegistrationScreen } from '~/components/clinic/registration/DefaultRegistrationScreen';
import { colors } from '~/utils/colors';
import { getAccountName } from '~/utils/personalDetailsUtils';

enum RegistrationStages {
  INVALID,
  PASSCODE,
  TERMS_AND_CONDITIONS,
  TWO_FA_PREFERENCES,
  PERSONAL_DETAILS,
}
// create a component
export const RegisterClinicScreen: React.FC = () => {
  const { hasClinicLinking, token, details } = useClinicAdminRegistrationStatus();
  const { steps } = useFetchRegistrationStatus();

  const approveToken = async () => {
    if (!token) throw new MissingClinicTokenError();

    await apiApproveClinicAdminToken(token);
  };

  const stage = useMemo(() => {
    if (!steps?.length) {
      if (hasClinicLinking) return RegistrationStages.PASSCODE;
      return RegistrationStages.INVALID;
    }

    const allSteps = steps.reduce((previous, current) => {
      previous = [...previous, ...current.steps];
      return previous;
    }, []);

    for (let i = 0; i < allSteps.length; i++) {
      const step = allSteps[i];
      switch (step.key) {
        case 'passcode':
          if (!step.done) return RegistrationStages.PASSCODE;
          break;
        case 'accept_terms':
          if (!step.done) return RegistrationStages.TERMS_AND_CONDITIONS;
          break;
        case 'two_factor_preference':
          if (!step.done) return RegistrationStages.TWO_FA_PREFERENCES;
          break;
        case 'clinic_admin_personal_details':
          return RegistrationStages.PERSONAL_DETAILS;
      }
    }

    return RegistrationStages.INVALID;
  }, [steps, hasClinicLinking]);

  const content = useMemo(() => {
    switch (stage) {
      case RegistrationStages.PASSCODE:
        return (
          <PassCodeRegisterScreen
            onBeforeCreatePasscode={approveToken}
            title="Welcome to Digimed Clinic"
            subtitle={`Hi ${
              getAccountName(details) || details?.email || ''
            }, please enter a 4-digit passcode to accept your Digimed Clinic invitation`}
          />
        );
      case RegistrationStages.TERMS_AND_CONDITIONS:
        return <RegistrationTermsAndConditionsScreen />;
      case RegistrationStages.TWO_FA_PREFERENCES:
        return <TwoStepSelectionScreen />;
      case RegistrationStages.PERSONAL_DETAILS:
        return <PersonalDetails />;
      default:
        return <DefaultRegistrationScreen />;
    }
  }, [stage]);

  return (
    <View
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        backgroundColor: colors.white,
        alignItems: 'center',
      }}>
      <View style={{ maxWidth: stage !== RegistrationStages.TERMS_AND_CONDITIONS ? 430 : 1200, height: '100%' }}>
        {content}
      </View>
    </View>
  );
};

/**
 * Get token
 * Store token and approve
 * Set passcode
 * Accept terms
 * Setup 2fa
 * Personal details
 */
