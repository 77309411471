import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AvailableDoctorModel } from '~/api/models/appointments/models/AvailableDoctorModel';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';

interface IState {
  booking?: {
    doctorId?: number;
    clinicId?: number;
    channelId?: number;
    consultationId?: number;
    consultationType?: ConsultationTypeEnum;
  };
}

const initialState: IState = {};

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    SET_BOOKING_TYPE: (state, action: PayloadAction<ConsultationTypeEnum>) => ({
      ...state,
      booking: {
        consultationType: action.payload,
      },
    }),
    SET_RESCHEDULE_BOOKING: (state, action: PayloadAction<ConsultationModel>) => ({
      ...state,
      booking: {
        consultationId: action.payload.id,
      },
    }),
    SET_BOOKING_PHYSICIAN: (
      state,
      action: PayloadAction<{
        doctorOrClinic: AvailableDoctorModel;
        channel: number;
        consultationType: ConsultationTypeEnum;
      }>
    ) => ({
      ...state,
      booking: {
        channelId: action.payload.channel,
        doctorId:
          action.payload.consultationType === ConsultationTypeEnum.HOME_VISIT // Home visits need a time range before selecting the doctor
            ? undefined
            : action.payload.doctorOrClinic.clinic_name
            ? undefined
            : action.payload.doctorOrClinic.id,
        clinicId:
          action.payload.consultationType === ConsultationTypeEnum.HOME_VISIT
            ? undefined
            : !action.payload.doctorOrClinic.clinic_name
            ? undefined
            : action.payload.doctorOrClinic.id,
        consultationType: action.payload.consultationType,
      },
    }),
    CLEAR_INITIAL_BOOKING_DETAILS: (state) => ({
      ...state,
      booking: undefined,
    }),
  },
});

export const { SET_BOOKING_TYPE, SET_BOOKING_PHYSICIAN, SET_RESCHEDULE_BOOKING, CLEAR_INITIAL_BOOKING_DETAILS } =
  appointmentSlice.actions;
export default appointmentSlice.reducer;
