import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React from 'react';

import { screenName } from '../../utils/screenName';

import { HelpTabComponent } from '~/components/doctor/help/HelpTabComponent';
import { HelpCancellationPolicy } from '~/pages/help/HelpCancellationPolicy';
import { HelpCookiePolicy } from '~/pages/help/HelpCookiePolicy';
import { HelpFaqWebPage } from '~/pages/help/HelpFaqWebPage';
import { HelpPrivacyPolicyPage } from '~/pages/help/HelpPrivacyPolicyPage';
import { HelpTermsAndConditionsPage } from '~/pages/help/HelpTermsAndConditionsPage';
import { isNative, isPharmacyVersion } from '~/utils/buildConfig';
import { DigimedFeatures, digimedFeatureEnabled } from '~/utils/milestoneUtil';

const Tab = createMaterialTopTabNavigator();

export const HelpNavigation = () => {
  return (
    <Tab.Navigator
      initialRouteName={screenName.HelpFaq}
      screenOptions={{ swipeEnabled: isNative() }}
      tabBar={() => <HelpTabComponent />}>
      <Tab.Screen name={screenName.HelpFaq} component={HelpFaqWebPage} />
      <Tab.Screen name={screenName.HelpTermsAndConditions} component={HelpTermsAndConditionsPage} />
      <Tab.Screen name={screenName.HelpPrivacyPolicy} component={HelpPrivacyPolicyPage} />
      <Tab.Screen name={screenName.HelpCookiePolicy} component={HelpCookiePolicy} />
      {digimedFeatureEnabled(DigimedFeatures.CalendarAndAppointments) && !isPharmacyVersion() ? (
        <Tab.Screen name={screenName.HelpCancellationPolicy} component={HelpCancellationPolicy} />
      ) : null}
    </Tab.Navigator>
  );
};
