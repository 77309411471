import React from 'react';

import { H6NsRegularSecondaryBlack, H6NsSemiBoldBlack, H6NsSemiBoldLink } from '../commonText';
import { ErrorAlert } from '../modals/ErrorAlert';

import { openLink } from '~/common/commonMethods';
import { labels } from '~/utils/labels';

export const StripeBankTextComponent: React.FC = () => {
  return (
    <>
      <H6NsRegularSecondaryBlack>
        {labels.addBankDetailsText1}
        <H6NsSemiBoldBlack>{labels.addBankDetailsText2}</H6NsSemiBoldBlack>
        {labels.addBankDetailsText3}
        <H6NsSemiBoldBlack>{labels.addBankDetailsText4}</H6NsSemiBoldBlack>
        {labels.addBankDetailsText5}
        <H6NsSemiBoldLink onPress={() => openLink('https://stripe.com/').catch(ErrorAlert)}>
          {labels.addBankDetailsText6}
        </H6NsSemiBoldLink>
        {labels.addBankDetailsText7}
      </H6NsRegularSecondaryBlack>
    </>
  );
};
