import React, { useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, FlatList, Text, Pressable } from 'react-native';
import { DataTable } from 'react-native-paper';

import { ConsultationTypeBadge } from './ConsultationTypeBadge';

import { AvailableDoctorModelWithQueue } from '~/api/models/appointments/models/AvailableDoctorModel';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { flex1, mb5, mr10 } from '~/common/commonStyles';
import { H6NsRegularSecondaryBlack, H6NsSemiBoldBlack, SmallNsRegularBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { DoctorClinicName } from '~/components/patient/doctorListing/components/DoctorClinicName';
import { DoctorDetailModal } from '~/components/patient/doctorListing/components/DoctorDetailModal';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { ForwardArrow } from '~/components/svgImages';
import { useDoctorListingContext } from '~/providers/appointment/DoctorListingContext';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { idNameArrayToString } from '~/utils/arrayUtil';
import { colors } from '~/utils/colors';
import { getAccountName, getInitials } from '~/utils/personalDetailsUtils';

type UniqueDoctor = AvailableDoctorModelWithQueue & { consultationTypes: ConsultationTypeEnum[] };

const DOCTORS_LIST_MINIMUM = 3;
const RECORD_HEIGHT = 55;
const EXTRA_CONTENT_HEIGHT = 120;
export const DoctorsListCard: React.FC = () => {
  const { loading } = useDoctorListingContext();
  const [containerHeight, setContainerHeight] = useState<number>(undefined);
  const { doctors, selectedDoctorOrClinic, isListing } = useDoctorListingContext();

  const { openModal, registerModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.DoctorInfoModal, DoctorDetailModal);
  }, []);

  const recordCount = useMemo(
    () => Math.max(DOCTORS_LIST_MINIMUM, Math.floor((containerHeight - EXTRA_CONTENT_HEIGHT) / RECORD_HEIGHT)),
    [doctors, containerHeight]
  );

  const slicedDoctors = useMemo((): UniqueDoctor[] => {
    const uniqueDoctors: UniqueDoctor[] = [];

    const extractUnique = (
      toExtractArr: AvailableDoctorModelWithQueue[] | undefined,
      currentConsultationType: ConsultationTypeEnum
    ) => {
      toExtractArr?.forEach((doctor) => {
        const uniqueDoctor = uniqueDoctors.find((uniqueDoctor) => uniqueDoctor.id === doctor.id);
        if (!uniqueDoctor) {
          uniqueDoctors.push({ ...doctor, consultationTypes: [currentConsultationType] });
        } else {
          uniqueDoctor.consultationTypes.push(currentConsultationType);
        }
      });
    };

    extractUnique(doctors?.on_demand?.data, ConsultationTypeEnum.ON_DEMAND);
    extractUnique(doctors?.scheduled_appointment?.data, ConsultationTypeEnum.SCHEDULED_APPOINTMENT);
    extractUnique(doctors?.home_visit?.data, ConsultationTypeEnum.HOME_VISIT);

    return uniqueDoctors.slice(0, recordCount);
  }, [doctors?.on_demand, doctors?.scheduled_appointment, doctors?.home_visit, recordCount]);

  const height = useMemo(
    () => (slicedDoctors.length ? recordCount * RECORD_HEIGHT + EXTRA_CONTENT_HEIGHT : undefined),
    [recordCount, slicedDoctors]
  );

  const onLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    setContainerHeight(height);
  };

  const handleDoctorModal = (doctor: UniqueDoctor, consultationType: ConsultationTypeEnum) => {
    openModal(ModalName.DoctorInfoModal, {
      doctor,
      consultationType,
      okTitle: isListing
        ? consultationType === ConsultationTypeEnum.SCHEDULED_APPOINTMENT
          ? 'Schedule appointment'
          : consultationType === ConsultationTypeEnum.ON_DEMAND
          ? 'Book on demand'
          : 'Book home visit'
        : 'Continue',
      onContinue: selectedDoctorOrClinic,
    });
  };

  const renderItem = ({ item }: { item: UniqueDoctor; index: number }) => (
    <View style={{ maxHeight: height }}>
      <Pressable onPress={() => handleDoctorModal(item, item.consultationTypes[0])}>
        <View style={[flex1, { flexDirection: 'row' }]}>
          <View style={mr10}>
            <DataTable.Cell style={{ justifyContent: 'flex-start' }}>
              <ProfileImageComponent
                src={item.profile_image?.conversions?.avatar ?? item.profile_image?.url}
                initials={getInitials({ first_name: item.first_name, last_name: item.last_name })}
              />
            </DataTable.Cell>
          </View>
          <View style={[flex1, { flexDirection: 'column' }]}>
            <DataTable.Cell style={{ justifyContent: 'flex-start' }}>
              <H6NsSemiBoldBlack style={mb5}>{getAccountName(item)}</H6NsSemiBoldBlack>
            </DataTable.Cell>
            <DataTable.Cell style={{ justifyContent: 'flex-start', marginBottom: 4 }}>
              <SmallNsRegularBlack>{idNameArrayToString(item?.specialisations, 2)}</SmallNsRegularBlack>
            </DataTable.Cell>
            <DoctorClinicName doctor={item} />
            <View style={styles.consultationTypes}>
              {item.consultationTypes.map((type) => (
                <ConsultationTypeBadge type={type} key={type} onPress={() => handleDoctorModal(item, type)} />
              ))}
            </View>
          </View>
        </View>
      </Pressable>
    </View>
  );

  return (
    <View style={styles.container} onLayout={onLayout}>
      <FlatList<UniqueDoctor>
        data={slicedDoctors || []}
        ListEmptyComponent={
          loading ? (
            <View>
              <LoadingActivityIndicator />
            </View>
          ) : null
        }
        renderItem={renderItem}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingVertical: 10 }}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
  },
  separator: {
    width: '100%',
    borderBottomColor: colors.lightGrey,
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 10,
  },
  consultationTypes: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
  },
});
