import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { List } from 'react-native-paper';

import { useInsuranceClaimDocumentTypeListing } from '~/api/hooks/referenceData/InsuranceClaimDocumentTypeListing';
import { ml10, mt15 } from '~/common/commonStyles';
import { UploadDocumentTouchable } from '~/components/buttons/UploadDocumentTouchable';
import { ElevatedCard } from '~/components/cards/ElevatedCard';
import {
  FormErrorMessage,
  H5TtmRegularSecondaryBlack,
  H5TtmSemiBoldBlack,
  H5TtmSemiBoldDanger,
  H6NsRegularBlack,
  H6NsRegularTheme,
} from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { MandatoryAsterisks } from '~/components/inputs/MandatoryAsterisks';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { ConsultationTypeCostComponent } from '~/components/preferences/ConsultationTypeCostComponent';
import { RoundedRemoveIcon, Upload } from '~/components/svgImages';
import { ImageFormats } from '~/constants/documentUploadsConstants';
import { useInsuranceClaimFormContext } from '~/providers/insurance/InsuranceClaimFormContext';
import {
  minLengthValidation,
  priceValidation,
  requiredValidation,
  validationSchema,
} from '~/services/validationConfig';
import { AccordionPaperTheme } from '~/theme/paper/AccordionPaperTheme';
import { colors } from '~/utils/colors';
import { isDocumentResult } from '~/utils/files/isDocumentResult';
import { isMediaModel } from '~/utils/files/isMediaModel';

interface Props {
  index: number;
  fieldId: string;
  expanded?: boolean;
  notEditable?: boolean;
  onRemove: () => void;
}

export const ExpandableReceipt: React.FC<Props> = ({ index, fieldId, expanded, notEditable, onRemove }) => {
  const { control, watch } = useInsuranceClaimFormContext();
  const documentTypes = useInsuranceClaimDocumentTypeListing();
  const documentType = watch(`insurance_claim_documents.${index}.type`);
  const [descriptionRequired, setDescriptionRequired] = useState(false);

  const { type, description, amount, image } = useWatch({ name: `insurance_claim_documents.${index}`, control });

  const required = !!type || !!description || !!amount || !!image;

  useEffect(() => {
    if (!documentType || !documentTypes) {
      setDescriptionRequired(false);
    } else {
      const isRequired = !!documentTypes.find((item) => item.type === documentType)?.is_description_required;
      setDescriptionRequired(isRequired);
    }
  }, [documentType, documentTypes]);

  return (
    <List.Accordion
      key={fieldId}
      id={fieldId}
      style={[styles.accordion, expanded ? undefined : styles.accordionClosed]}
      theme={AccordionPaperTheme}
      title={
        <View style={{ flexDirection: 'row', width: '100%' }}>
          <H5TtmRegularSecondaryBlack>Document {index + 1}</H5TtmRegularSecondaryBlack>
          <View style={{ flex: 1 }} />
          {amount ? <H5TtmSemiBoldBlack>{`€` + (amount / 100).toFixed(2)}</H5TtmSemiBoldBlack> : null}
        </View>
      }>
      <View key={fieldId} style={styles.content}>
        <H6NsRegularBlack>Select the document type</H6NsRegularBlack>
        <Controller
          name={`insurance_claim_documents.${index}.type`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
            <Dropdown
              list={documentTypes?.map((item) => ({ label: item.label, value: item.type })) ?? []}
              value={value ?? undefined}
              setValue={onChange}
              label="Document type"
              showMandatory={required}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
            />
          )}
          rules={{
            required: required ? requiredValidation('Document type') : undefined,
          }}
        />
        <H6NsRegularBlack style={mt15}>Write a description for the attached document</H6NsRegularBlack>
        <Controller
          name={`insurance_claim_documents.${index}.description`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingInput
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
              label="Description"
              maxLength={validationSchema.string.maxLength}
              showMandatory={descriptionRequired}
              disabled={notEditable}
            />
          )}
          rules={{
            required: descriptionRequired ? requiredValidation('Description') : null,
          }}
        />
        <H6NsRegularBlack style={mt15}>Attach image of the document</H6NsRegularBlack>
        <Controller
          name={`insurance_claim_documents.${index}.image`}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
            <>
              <UploadDocumentTouchable
                formats={ImageFormats}
                onResult={async (results) => {
                  if (results?.length) onChange(results[0]);
                }}>
                <ElevatedCard elevated>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flex: 1,
                    }}>
                    <View>
                      <H6NsRegularTheme style={{ padding: 10, flex: 1 }}>
                        {isDocumentResult(value) ? (
                          value.name || 'Unknown file name'
                        ) : isMediaModel(value) ? (
                          value.name || value.file_name || 'Unknown file name'
                        ) : (
                          <>
                            Click here to attach document
                            <MandatoryAsterisks isMandatory />
                          </>
                        )}
                      </H6NsRegularTheme>
                    </View>
                    <View style={{ padding: 10 }}>
                      <Upload height={14} width={14} color={colors.purple} />
                    </View>
                  </View>
                </ElevatedCard>
              </UploadDocumentTouchable>
              {isSubmitted && !!error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
            </>
          )}
          rules={{
            required: required ? requiredValidation('Document attachment') : undefined,
          }}
        />
        <H6NsRegularBlack style={mt15}>
          If you are claiming an additional amount pertaining to this document, please specify the amount being claimed
        </H6NsRegularBlack>
        <View style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Controller
            name={`insurance_claim_documents.${index}.amount`}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <ConsultationTypeCostComponent
                error={isSubmitted && !!error}
                errorMessage={isSubmitted && error?.message}
                value={value}
                onChange={onChange}
                disabled={notEditable}
              />
            )}
            rules={{
              validate: { priceValidation },
              minLength: minLengthValidation(validationSchema.amount.minLength),
            }}
          />
        </View>

        {notEditable ? null : (
          <View style={{ display: 'flex', alignItems: 'flex-start' }}>
            <TouchableOpacity style={{ flexDirection: 'row', ...mt15, alignItems: 'center' }} onPress={onRemove}>
              <RoundedRemoveIcon height={20} width={20} color={colors.danger} />
              <H5TtmSemiBoldDanger style={ml10}>Remove</H5TtmSemiBoldDanger>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </List.Accordion>
  );
};

const styles = StyleSheet.create({
  accordion: {
    backgroundColor: colors.lightPurple,
    padding: 15,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    marginTop: 15,
    flex: 1,
  },
  accordionClosed: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  content: {
    padding: 15,
    borderWidth: 1,
    borderColor: colors.lightPurple,
  },
});
