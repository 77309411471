import { deleteReq, get, post, put } from '~/api/axios';
import { AddUpdateHomeVisitRequest } from '~/api/models/preferences/requests/AddUpdateHomeVisitRequest';
import { HomeVisitIndexResponse } from '~/api/models/preferences/responses/HomeVisitIndexResponse';
import { HomeVisitResponse } from '~/api/models/preferences/responses/HomeVisitResponse';
import { appTypeApiPrefix } from '~/utils/buildConfig';

export const apiGetHomeVisitsIndex = () => {
  return get<HomeVisitIndexResponse>(`/${appTypeApiPrefix()}/availabilities/home-visit`);
};

export const apiAddHomeVisit = (data: AddUpdateHomeVisitRequest) => {
  return post<HomeVisitResponse>(`/${appTypeApiPrefix()}/availabilities/home-visit`, data);
};

export const apiUpdateHomeVisit = (params: { calendarEventId: number; data: AddUpdateHomeVisitRequest }) => {
  return put<HomeVisitResponse>(
    `/${appTypeApiPrefix()}/availabilities/home-visit/${params.calendarEventId}`,
    params.data
  );
};

export const apiDeleteHomeVisit = (calendarEventId: number) => {
  return deleteReq(`/${appTypeApiPrefix()}/availabilities/home-visit/${calendarEventId}`);
};
