import React from 'react';
import { FlexStyle, LayoutChangeEvent, View, ViewProps } from 'react-native';

import { IGridContext, useGridContext } from './Grid';

import { IBreakpoints } from '~/constants/grid/types';

interface Props {
  children?: React.ReactNode;
  component?: React.FC<ViewProps>;
  fluid?: boolean;
  style?: ViewProps['style'];
  alignSelf?: FlexStyle['alignSelf'];
  noOuterGutter?: boolean;
  maxContainerWidths?: number | string | IBreakpoints;
  onLayout?: ((event: LayoutChangeEvent) => void) | undefined;
}

export const Container: React.FC<Props> = ({
  children,
  component = View,
  fluid,
  style,
  alignSelf,
  noOuterGutter,
  maxContainerWidths,
  onLayout,
}) => {
  const grid = useGridContext();

  const baseStyle = {
    width: '100%',
  };

  const getMaxWidthStyle = (grid: IGridContext, fluid) => {
    if (maxContainerWidths) {
      return {
        alignSelf: 'center',
        maxWidth:
          typeof maxContainerWidths === 'number' || typeof maxContainerWidths === 'string'
            ? maxContainerWidths
            : maxContainerWidths[grid.breakpoint],
      };
    }
    if (typeof grid.maxContainerWidth === 'undefined' || fluid) {
      return [];
    }

    return {
      alignSelf: 'center',
      maxWidth: grid.maxContainerWidth,
    };
  };

  const getItemAlignment = (alignSelf) => {
    if (typeof alignSelf === 'undefined') {
      return [];
    }

    if (typeof alignSelf === 'string') {
      return {
        alignSelf,
      };
    }

    return [];
  };

  const getGutterStyle = (grid: IGridContext) => {
    if (typeof grid.gutter === 'undefined' || grid.noOuterGutter || noOuterGutter) {
      return [];
    }

    return {
      paddingHorizontal: grid.gutter / 2,
    };
  };

  const styles = [].concat(
    baseStyle,
    getGutterStyle(grid),
    getMaxWidthStyle(grid, fluid),
    getItemAlignment(alignSelf),
    style
  );

  return React.createElement(
    component,
    {
      style: styles,
      onLayout,
    },
    children
  );
};
