import { labels } from '../utils/labels';
import { screenName } from '../utils/screenName';

import { isDoctorVersion, isPharmacyVersion } from '~/utils/buildConfig';
import { digimedFeatureEnabled, DigimedFeatures } from '~/utils/milestoneUtil';

export const settingsMenu = [
  {
    title: labels.consultations,
    link: { screen: screenName.SettingsConsultations, params: {} },
    hidden: !digimedFeatureEnabled(DigimedFeatures.Consultations) || !isDoctorVersion(),
  },

  {
    title: labels.notifications,
    link: { screen: screenName.SettingsNotifications, params: {} },
    hidden: isPharmacyVersion(),
    // hidden: !digimedFeatureEnabled(DigimedFeatures.Consultations),
  },
  { title: labels.security, link: { screen: screenName.SettingsSecurity, params: {} } },
  { title: labels.account, link: { screen: screenName.SettingsAccount, params: {} } },
];
