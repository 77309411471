import * as Location from 'expo-location';
import { useForegroundPermissions } from 'expo-location';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { HomeVisitNavigationStateEnum } from '~/api/models/consultations/enums/HomeVisitNavigationStateEnum';
import { ConfirmationAlert, openLink } from '~/common/commonMethods';
import { mt15 } from '~/common/commonStyles';
import { Button, OutlineButton } from '~/components/commonButton';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { useLocationData } from '~/utils/backgroundLocation/hook/useLocationData';
import { useLocationTracking } from '~/utils/backgroundLocation/hook/useLocationTracking';
import { isDoctorVersion, isNative } from '~/utils/buildConfig';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { labels } from '~/utils/labels';

interface Props {
  onPress?: () => void;
  allowDetailView?: boolean;
}

export const HomeVisitNavigationButton: React.FC<Props> = ({ onPress, allowDetailView }) => {
  const {
    consultation,
    startConsultation: contextStartConsultation,
    startNavigation: contextStartNavigation,
    pauseNavigation: contextPauseNavigation,
    endNavigation: contextEndNavigation,
  } = useAppointmentContext();

  const { goToAppointment } = useShowAppointment();
  const [waiting, setWaiting] = useState(false);
  const [permission, askPermission] = useForegroundPermissions();
  const { startLocationTracking, clearLocationTracking } = useLocationTracking({});
  const doctorVersion = useRef(isDoctorVersion()).current;
  useLocationData({ consultationId: consultation?.id });

  const timeDifference = useMemo(() => {
    if (!consultation) return;
    const currentTime = moment();
    const targetTime = moment(consultation.start_at);
    const timeDifference = moment.duration(targetTime.diff(currentTime));
    return timeDifference;
  }, [consultation?.start_at]);

  const allowStartNavigation = useMemo(() => {
    return consultation?.state === ConsultationStateEnum.Scheduled && timeDifference.asDays() < 1;
  }, [consultation, timeDifference]);

  const openGoogleMaps = async () => {
    const res = await Location.getCurrentPositionAsync({}).catch(() => undefined);
    const link = `https://www.google.com/maps/dir/?api=1${
      res?.coords ? `&origin=${res.coords.latitude},${res.coords.longitude}` : ''
    }&destination=${consultation.patient_location.lat},${consultation.patient_location.lng}&travelmode=driving${
      allowStartNavigation ? '&dir_action=navigate' : ''
    }`;
    openLink(link);
  };

  const startLocation = async () => {
    if (permission?.granted) {
      await startLocationTracking();
    } else {
      await askPermission();
      await startLocationTracking();
    }
  };

  const startConsultation = async () => {
    try {
      setWaiting(true);
      await contextStartConsultation();
    } catch {}
    setWaiting(false);
  };

  const startNavigation = async () => {
    try {
      setWaiting(true);
      if (isNative()) {
        await startLocation();
      }

      await contextStartNavigation();

      await openGoogleMaps();
    } catch {}
    setWaiting(false);
  };
  const pauseNavigation = async () => {
    try {
      setWaiting(true);
      if (isNative()) {
        await clearLocationTracking();
      }
      await contextPauseNavigation();
    } catch {}
    setWaiting(false);
  };

  const endNavigationAndStartConsultation = async () => {
    try {
      setWaiting(true);
      if (isNative()) {
        await clearLocationTracking();
      }

      await contextEndNavigation();
      await contextStartConsultation();
    } catch {}
    setWaiting(false);
  };

  const endNavigation = async () => {
    setWaiting(true);
    await new Promise<void>((resolve) => {
      ConfirmationAlert(
        [
          "If you've arrived at your destination, you can end navigation and start the consultation.",
          'Otherwise you can pause the navigation instead',
        ],
        {
          title: 'Destination reached',
          okTitle: 'Start consultation',
          okFunction: async () => {
            await endNavigationAndStartConsultation();
            resolve();
          },
          cancelTitle: 'PAUSE NAVIGATION',
          cancelFunction: async () => {
            await pauseNavigation();

            resolve();
          },
        }
      );
    });
    setWaiting(false);
  };

  const hasNavigationState = useMemo(
    () => !!consultation?.doctor_navigation_data,
    [consultation?.doctor_navigation_data]
  );

  const isNavigationStarted = useMemo(
    () => consultation?.doctor_navigation_data?.state === HomeVisitNavigationStateEnum.STARTED,
    [consultation?.doctor_navigation_data]
  );
  const isNavigationPaused = useMemo(
    () => consultation?.doctor_navigation_data?.state === HomeVisitNavigationStateEnum.PAUSED,
    [consultation?.doctor_navigation_data]
  );
  const isNavigationEnded = useMemo(
    () => consultation?.doctor_navigation_data?.state === HomeVisitNavigationStateEnum.ENDED,
    [consultation?.doctor_navigation_data]
  );

  useEffect(() => {
    if (!isNative() || !consultation?.doctor_navigation_data?.state) return;

    if (consultation.doctor_navigation_data.state === HomeVisitNavigationStateEnum.STARTED) {
      startLocation().catch(() => {});
    } else {
      clearLocationTracking().catch(() => {});
    }

    return () => {
      clearLocationTracking().catch(() => {});
    };
  }, [consultation?.doctor_navigation_data?.state]);

  if (consultation?.state !== ConsultationStateEnum.Scheduled || !doctorVersion) {
    if (allowDetailView) {
      return (
        <OutlineButton
          label={labels.viewDetails}
          funCallback={() => {
            goToAppointment(consultation);
            if (onPress) onPress();
          }}
        />
      );
    }
    return null;
  }
  if (!hasNavigationState || isNavigationPaused) {
    if (!allowStartNavigation) {
      return (
        <Button
          style={mt15}
          label="VIEW LOCATION"
          funCallback={async () => {
            await openGoogleMaps();
          }}
        />
      );
    } else {
      return (
        <>
          <Button
            disabled={waiting}
            style={mt15}
            label={isNavigationPaused ? 'CONTINUE NAVIGATION' : 'START NAVIGATION'}
            funCallback={startNavigation}
          />
          <OutlineButton disabled={waiting} style={mt15} label="START CONSULTATION" funCallback={startConsultation} />
        </>
      );
    }
  } else if (isNavigationStarted) {
    return (
      <>
        <Button disabled={waiting} style={mt15} label="Arrived at destination" funCallback={endNavigation} />
        <OutlineButton disabled={waiting} style={mt15} label="PAUSE NAVIGATION" funCallback={pauseNavigation} />
      </>
    );
  } else if (isNavigationEnded && !waiting) {
    return <OutlineButton disabled={waiting} style={mt15} label="START CONSULTATION" funCallback={startConsultation} />;
  }

  return null;
};
