import { useNavigation } from '@react-navigation/native';

import { useBreakpoints } from '../GridHook';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { NavType } from '~/navigation/types';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isClinicVersion, whenAppType } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

export const useShowAppointment = (consultation?: ConsultationModel) => {
  const { isMobile } = useBreakpoints();
  const { openModal } = useModalManager();
  const { navigate } = useNavigation<NavType>();

  const goToAppointment = (consultation: ConsultationModel) => goToConsultationId(consultation.id);

  const goToConsultationId = (consultationId: number) => {
    whenAppType({
      whenPatient: () => navigate(screenName.AppointmentsShow, { consultationId }),
      whenDoctor: () => navigate(screenName.CalendarAppointmentsShow, { consultationId }),
      whenClinic: () => navigate(screenName.ClinicConsultationRequest, { id: consultationId }),
      else: () => {},
    })();
  };

  const showDetails = (options?: {
    consultation?: ConsultationModel;
    consultationId?: number;
    buttons?: React.ReactNode[];
  }) => {
    if (isMobile && !isClinicVersion()) {
      openModal(ModalName.AppointmentDetails, {
        consultationId: options?.consultation?.id || options?.consultationId,
      });
    } else {
      goToConsultationId(options?.consultation?.id || options?.consultationId || consultation?.id);
    }
  };

  return { showDetails, goToAppointment };
};
