import { AxiosResponse } from 'axios';

import { get } from './verbs';
import { AuthorisationResponse } from '../models/authorisation/responses/AuthorisationResponse';

let refreshPromise: Promise<AxiosResponse<AuthorisationResponse, any>> = null;

export const refreshAccessToken = async () => {
  if (!refreshPromise) {
    refreshPromise = get<AuthorisationResponse>('/refresh-token');
  }
  const res = await refreshPromise
    .catch(() => {
      throw new Error('Your session has timed out, please login again to continue.');
    })
    .finally(() => (refreshPromise = null));
  refreshPromise = null;
  return res.data.data;
};

export const getRefreshPromise = () => refreshPromise;
