import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { StartsEndsTimesModel } from '~/api/models/common/models/StartsEndsTimeModel';
import { StartsEndsTimesModelWithId } from '~/api/models/common/models/StartsEndsTimeWithIdModel';
import { ScheduledAppointmentsModel } from '~/api/models/preferences/models/ScheduledAppointmentsModel';
import { ScheduledAppointmentsModelWithId } from '~/api/models/preferences/models/ScheduledAppointmentsWithIdModel';
import {
  apiAddScheduledAvailability,
  apiDeleteScheduledAvailability,
  apiGetScheduledAvailabilitiesIndex,
  apiUpdateScheduledAvailability,
} from '~/api/services/preferences/scheduledAppointments';
import { SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import {
  SET_SCHEDULED_AVAILABILITY,
  scheduledAvailabilitiesSelector,
} from '~/redux/reducers/doctor/availabilitiesReducer';
import { useAppDispatch } from '~/redux/store';
import { LARAVEL_DATE_TIME, NUM_DATE_FORMAT, TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';

const clearNullAndSetTimeRanges = (timeRangesArr, isUpdate, data) => {
  return timeRangesArr.reduce((previous, timeRange) => {
    if (timeRange.start_time === null && timeRange.end_time === null) {
      return [...previous];
    }
    return [
      ...previous,
      {
        id: isUpdate && typeof timeRange.id === 'number' ? timeRange.id : undefined,
        start_time: `${parseDateTime(data.start_date, { outputFormat: NUM_DATE_FORMAT })} ${timeRange.start_time}`,
        end_time: `${parseDateTime(data.start_date, { outputFormat: NUM_DATE_FORMAT })} ${timeRange.end_time}`,
      },
    ];
  }, []);
};

interface Props {
  immediate?: boolean;
}
export function useScheduledAppointments(options?: Props) {
  const [loading, setLoading] = useState<boolean>(!!options?.immediate);

  const dispatch = useAppDispatch();

  const scheduledAvailabilitiesRaw = useSelector(scheduledAvailabilitiesSelector);
  const scheduledAvailabilities = useMemo(() => scheduledAvailabilitiesRaw ?? [], [scheduledAvailabilitiesRaw]);

  // Helper function to format the time_ranges
  const formatTimeRanges = (
    timeRanges: StartsEndsTimesModel[] | StartsEndsTimesModelWithId[]
  ): StartsEndsTimesModelWithId[] => {
    return timeRanges.map((timeRange) => ({
      ...timeRange,
      start_time: parseDateTime(timeRange.start_time, { parseFormat: LARAVEL_DATE_TIME, outputFormat: TIME_FORMAT }),
      end_time: parseDateTime(timeRange.end_time, { parseFormat: LARAVEL_DATE_TIME, outputFormat: TIME_FORMAT }),
    }));
  };
  const setScheduledAppointments = (scheduledAppointments: ScheduledAppointmentsModelWithId[]) => {
    // Loop through the appointments and update the time format
    const formattedAppointments = scheduledAppointments.map((appointment) => ({
      ...appointment,
      time_ranges: formatTimeRanges(appointment.time_ranges),
    }));

    dispatch(SET_SCHEDULED_AVAILABILITY(formattedAppointments));
  };

  const getScheduledAvailabilities = async () => {
    setLoading(true);
    await apiGetScheduledAvailabilitiesIndex()
      .then((response) => {
        setScheduledAppointments(response.data.data);
      })
      .catch(ErrorAlert);
    setTimeout(() => setLoading(false), 500);
  };

  const updateScheduledAvailability = async (data: ScheduledAppointmentsModelWithId) => {
    if (data.group_id) {
      const newTimeRanges = clearNullAndSetTimeRanges(data.time_ranges, true, data);
      const newData = { ...data, time_ranges: newTimeRanges };

      await apiUpdateScheduledAvailability({ groupEventId: data.group_id, data: newData }).then((response) => {
        const scheduledAppointmentIndex = scheduledAvailabilities.findIndex(
          (scheduledAppointment) => scheduledAppointment.group_id === data.group_id
        );
        if (scheduledAppointmentIndex !== -1) {
          // Update only the specific card being edited
          const updatedScheduledAppointments = [...scheduledAvailabilities];
          updatedScheduledAppointments[scheduledAppointmentIndex] = {
            ...response.data,
            time_ranges: formatTimeRanges(response.data.time_ranges),
          };
          // Update the state with the modified array
          dispatch(SET_SCHEDULED_AVAILABILITY(updatedScheduledAppointments));
          SuccessAlert(['Scheduled appointment availability successfully updated']);
        } else {
          ErrorAlert('Scheduled appointment not found for update.');
        }
      });
    }
  };

  const addScheduledAvailability = async (data: ScheduledAppointmentsModel) => {
    const newTimeRanges = clearNullAndSetTimeRanges(data.time_ranges, false, data);
    const newData = { ...data, time_ranges: newTimeRanges };

    await apiAddScheduledAvailability(newData).then((result) => {
      // Format the time_ranges of the new card before adding to the state
      const formattedNewCard = {
        ...result.data,
        time_ranges: formatTimeRanges(result.data.time_ranges),
      };
      dispatch(SET_SCHEDULED_AVAILABILITY([...scheduledAvailabilities, formattedNewCard]));
      SuccessAlert(['Scheduled appointment availability added']);
    });
  };
  const removeScheduledAvailability = async (id: number) => {
    await apiDeleteScheduledAvailability(id)
      .then(() => {
        dispatch(
          SET_SCHEDULED_AVAILABILITY(
            scheduledAvailabilities.filter((scheduledAppointment) => scheduledAppointment.group_id !== id)
          )
        );
        SuccessAlert(['Scheduled availability successfully removed']);
      })
      .catch(ErrorAlert);
  };

  const saveScheduledAvailability = async (formData: ScheduledAppointmentsModelWithId) => {
    if ('group_id' in formData) {
      await updateScheduledAvailability(formData);
    } else {
      await addScheduledAvailability(formData);
    }
  };
  useEffect(() => {
    if (!scheduledAvailabilitiesRaw && options?.immediate) getScheduledAvailabilities();
    else setLoading(false);
  }, []);

  return {
    scheduledAvailabilities,
    removeScheduledAvailability,
    saveScheduledAvailability,
    loading,
  };
}
