import React from 'react';
import { TouchableOpacity } from 'react-native';

import { colors } from '../../../utils/colors';

import { useNotificationActions } from '~/api/hooks/notifications/NotificationActionsHook';
import { NotificationModel } from '~/api/models/notifications/models/NotificationModel';
import { NotificationDotMenu } from '~/components/modals/NotificationDotMenu';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { MoreIcon } from '~/components/svgImages';
import { useModalManager } from '~/providers/modal/ModalManagementContext';

interface Props {
  notification: NotificationModel;
}

export const NotificationDotMenuMobile: React.FC<Props> = ({ notification }) => {
  const { registerModal, openModal } = useModalManager();

  registerModal(ModalName.NotificationDotMenu, NotificationDotMenu);
  const { actions } = useNotificationActions({ notificationData: notification?.data });
  return (
    <>
      <TouchableOpacity
        onPress={() => openModal(ModalName.NotificationDotMenu, { notification, itemTitleList: actions })}
        key={notification.id}>
        <MoreIcon height={20} width={20} color={colors.purple} />
      </TouchableOpacity>
    </>
  );
};
