import { useState } from 'react';
import {
  apiClinicApproveConsultation,
  apiClinicDeclineConsultation,
  apiClinicUpdateConsultation,
} from '~/api/services/clinics';
import { SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { appointmentLabels } from '~/utils/labels/appointments';

export const useClinicRequestHandling = () => {
  const [waiting, setWaiting] = useState(false);
  const { openModal } = useModalManager();

  const declineRequest = async (consultationId: number) => {
    return new Promise<void>((resolve) => {
      openModal(ModalName.RejectConsultationReasonModal, {
        consultationId,
        title: appointmentLabels.declineConsultation,
        description: appointmentLabels.declineReason,
        declineApi: apiClinicDeclineConsultation,
        buttonTitle: appointmentLabels.decline,
        onReady: () => {
          resolve();
          SuccessAlert(['Consultation successfully declined']);
        },
      });
    });
  };

  const approveRequest = async (consultationId: number, doctorAccountId: number) => {
    try {
      setWaiting(true);
      await apiClinicApproveConsultation({ id: consultationId, params: { doctor_id: doctorAccountId } });
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const changeDoctor = async (consultationId: number, doctorAccountId: number) => {
    try {
      setWaiting(true);
      await apiClinicUpdateConsultation({ id: consultationId, params: { doctor_id: doctorAccountId } });
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  return { declineRequest, approveRequest, changeDoctor, waiting };
};
