import Feather from '@expo/vector-icons/Feather';
import React from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { wizardStyle } from '~/common/commonStyles';
import { MultipleSlotsTimePicker } from '~/components/common/MultipleSlotsTimePicker';
import { Button } from '~/components/commonButton';
import { H6NsRegularBlack } from '~/components/commonText';
import { CalendarStripInput } from '~/components/inputs/dateTime/CalendarStripInput';
import { Splitter } from '~/components/misc/Splitter';
import { WizardLayout } from '~/layouts/WizardLayout';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { NUM_DATE_FORMAT } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';

export const HomeVisitSlotStep: React.FC = () => {
  const { loading, validateRequest, formControl } = useAppointmentBookingContext();

  const buttons = [<Button label={labels.next} funCallback={validateRequest} disabled={loading} />];

  return (
    <View style={wizardStyle.container}>
      <WizardLayout title={appointmentLabels.chooseDateTime} buttons={buttons}>
        <View style={{ width: '100%' }}>
          <Controller
            control={formControl}
            name="available_time_date"
            render={({ field: { value, onChange } }) => (
              <CalendarStripInput onChange={onChange} value={value} minDate={new Date()} dateFormat={NUM_DATE_FORMAT} />
            )}
          />

          <Splitter />

          <H6NsRegularBlack>
            <Feather name="clock" size={14} />
            {' ' + appointmentLabels.chooseTimeRange}
          </H6NsRegularBlack>
          <Controller
            control={formControl}
            name="available_time_ranges"
            render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
              <MultipleSlotsTimePicker
                initialValue={value?.map((v) => ({ start_time: v.from, end_time: v.to }))}
                onChange={(value) => onChange(value?.map((v) => ({ from: v.start_time, to: v.end_time })))}
                errorMessage={isSubmitted && error?.message}
              />
            )}
            rules={{
              validate: (values) =>
                values.length && values.some((v) => v.from && v.to) ? undefined : 'Time ranges are required',
            }}
          />
        </View>
      </WizardLayout>
    </View>
  );
};
