import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

import { FaqCategories } from './FaqCategories';
import { FaqQuestions } from './FaqQuestions';

import { mb10 } from '~/common/commonStyles';
import { SearchBox } from '~/components/searchBox';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const FaqComponent: React.FC = () => {
  const [localSearchText, setLocalSearchText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedTaxon, setSelectedTaxon] = useState<string>(undefined);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    if (localSearchText === '' && localSearchText !== searchText) {
      setSearchText('');
    }
  }, [localSearchText]);
  return (
    <Grid grid={{ noOuterGutter: !isMobile }}>
      <Container alignSelf="flex-start">
        <Row>
          <Column span={{ xs: 12, md: 9 }} style={isMobile ? { paddingHorizontal: 0 } : null}>
            <SearchBox
              value={localSearchText}
              onSubmit={() => {
                setSearchText(localSearchText);
              }}
              onTextChange={setLocalSearchText}
              placeholder="Search"
              bgColor={isMobile ? colors.white : null}
            />
          </Column>
        </Row>
        <Row
          style={[
            styles.containerWithRadius,
            isMobile ? styles.containerMobile : styles.containerWeb,
            { paddingVertical: 20 },
          ]}>
          <Column span={{ xs: 12, md: 3 }} style={mb10}>
            <FaqCategories searching={!!searchText} selected={selectedTaxon} onSelect={setSelectedTaxon} />
          </Column>
          <Column span={{ xs: 12, md: 9 }} style={mb10}>
            <FaqQuestions taxonomySlug={selectedTaxon} search={searchText} />
          </Column>
        </Row>
      </Container>
    </Grid>
  );
};

const styles = StyleSheet.create({
  containerWithRadius: {
    borderRadius: 6,
    backgroundColor: colors.white,
  },
  containerMobile: {
    marginVertical: 20,
  },
  containerWeb: {
    minHeight: 400,
    marginVertical: 40,
  },
});
