export interface IErrorResponse {
  message: string;
  errors?: Record<string, string[]>;
}
export class ErrorResponse {
  errors: Record<string, string[]>;
  message: string;
  status: number;

  constructor(error: IErrorResponse, status: number) {
    this.message = error?.message;
    this.errors = error?.errors || {};
    this.status = status;
  }
}
