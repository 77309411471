import { Link } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { mv10, textCenter } from '~/common/commonStyles';
import { OutlineButton, TextButton } from '~/components/commonButton';
import { H2TtmSemiBoldBlack, H6NsRegularSecondaryBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { ModalContainer } from '~/components/modals/ModalContainer';
import { BaseModalProps } from '~/components/modals/interfaces/ModalProps';
import { Error } from '~/components/svgImages';
import { isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';
import { screenName } from '~/utils/screenName';

interface Props extends BaseModalProps {
  onSubmit: () => void;
}
export const AppointmentCancellationModal: React.FC<Props> = ({ onSubmit, onHide }) => {
  const buttons = [
    <OutlineButton
      danger
      disabled={false}
      funCallback={() => {
        onSubmit();
        onHide();
      }}
      label={labels.cancelAppointment}
    />,
    <TextButton disabled={false} funCallback={onHide} label={labels.goBack} />,
  ];
  return (
    <ModalContainer onHide={onHide} hideOnBackground buttons={buttons}>
      <View>
        <View style={{ padding: 10, alignSelf: 'center' }}>
          <Error height={55} width={55} />
          {/* } */}
        </View>
        <H2TtmSemiBoldBlack style={[mv10, textCenter]}>{appointmentLabels.cancelAppointmentTitle}</H2TtmSemiBoldBlack>
        {isDoctorVersion() ? (
          <H6NsRegularSecondaryBlack style={[textCenter, mv10]}>
            {appointmentLabels.cancelAppointmentDoctor}
          </H6NsRegularSecondaryBlack>
        ) : (
          <H6NsRegularSecondaryBlack style={[textCenter, mv10]}>
            {appointmentLabels.cancelAppointmentPatient.message1}
            <Link
              onPress={onHide}
              to={{ screen: screenName.Help as never, params: { screen: screenName.HelpTermsAndConditions } as never }}>
              <H6NsSemiBoldBlack style={{ color: colors.purple }}>
                {appointmentLabels.cancelAppointmentPatient.message2}
              </H6NsSemiBoldBlack>
            </Link>
            {appointmentLabels.cancelAppointmentPatient.message3}
            <Link
              onPress={onHide}
              to={{ screen: screenName.Help as never, params: { screen: screenName.HelpCancellationPolicy } as never }}>
              <H6NsSemiBoldBlack style={{ color: colors.purple }}>
                {appointmentLabels.cancelAppointmentPatient.message4}
              </H6NsSemiBoldBlack>
            </Link>
          </H6NsRegularSecondaryBlack>
        )}
      </View>
    </ModalContainer>
  );
};
