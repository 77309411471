import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import { Link } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

import { H6NsRegularBlack, nsSemiBold } from '../commonText';

import { mb10, mv5, textCenter } from '~/common/commonStyles';
import { Grid, Container, Row, Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  items?: {
    title: string;
    key?: string;
    link?: { screen: string; params?: Record<string, any> };
    onPress?: (key?: string) => void;
    hidden?: boolean;
  }[];
  active?: string;
  columnOnMobile?: boolean;
  compact?: boolean;
  fluid?: boolean;
  mobileStyling?: StyleProp<ViewStyle>;
  tabProps?: MaterialTopTabBarProps;
  right?: React.ReactNode;
  joinedBackground?: boolean;
  hasBottomMargin?: boolean;
}

export const TabsComponent: React.FC<Props> = ({
  items,
  active,
  columnOnMobile,
  compact,
  mobileStyling,
  tabProps,
  right,
  fluid,
  joinedBackground,
  hasBottomMargin,
}) => {
  const { isMobile } = useBreakpoints();
  const span = useMemo(() => (columnOnMobile && isMobile ? 12 : 'auto'), []);

  const tabItems = useMemo(() => {
    return (
      items?.filter((item) => !item.hidden && (item.link || item.onPress)) ||
      tabProps?.state.routes.map((route, index) => {
        const { options } = tabProps.descriptors[route.key];

        return {
          key: route.key,
          title: (typeof options.tabBarLabel === 'string' ? options.tabBarLabel : '') || options.title || route.name,
          onPress: (routeKey: string) => {
            const event = tabProps.navigation.emit({
              type: 'tabPress',
              target: routeKey,
              canPreventDefault: true,
            });

            const routeIndex = tabProps.state.routes.findIndex((route) => route.key === routeKey);
            if (tabProps.state.index !== routeIndex && !event.defaultPrevented) {
              tabProps.navigation.navigate(tabProps.state.routes[routeIndex].name);
            }
          },
        };
      }) ||
      []
    );
  }, [items, tabProps?.state?.routes]);

  const selectedIndex = useMemo(() => {
    if (tabProps) {
      return tabProps.state?.index;
    } else {
      return tabItems.findIndex((item) => item.key === active);
    }
  }, [tabProps, tabItems, active]);

  return (
    <Grid grid={{ noOuterGutter: true, gutters: 12 }}>
      <Container
        alignSelf="flex-start"
        style={[
          isMobile ? mobileStyling : null,
          joinedBackground ? styles.joinedBackground : null,
          hasBottomMargin ? mb10 : null,
        ]}
        fluid={fluid}>
        <Row>
          {tabItems.map((item, index) => {
            const active = index === selectedIndex;
            return (
              <Column key={item.title} span={span} style={[!joinedBackground ? mv5 : null, { minWidth: 90 }]}>
                {item.link ? (
                  <Link
                    to={item.link as never}
                    style={[
                      styles.link,
                      compact ? styles.linkCompact : styles.linkDefault,
                      active ? styles.buttonActive : null,
                      joinedBackground && active ? styles.linkActiveJoined : null,
                      textCenter,
                    ]}>
                    {item.title}
                  </Link>
                ) : (
                  <TouchableOpacity onPress={item.onPress ? () => item.onPress(item.key) : null}>
                    <View
                      style={[
                        styles.link,
                        compact ? styles.linkCompact : styles.linkDefault,
                        active ? styles.buttonActive : null,
                      ]}>
                      <H6NsRegularBlack style={[textCenter, active ? styles.textActive : null]}>
                        {item.title}
                      </H6NsRegularBlack>
                    </View>
                  </TouchableOpacity>
                )}
              </Column>
            );
          })}
          {right ? <Column style={{ flex: 1 }}>{right}</Column> : null}
        </Row>
      </Container>
    </Grid>
  );
};

const styles = StyleSheet.create({
  link: {
    padding: 8,
    borderWidth: 1,
    borderColor: colors.transparent,
    backgroundColor: colors.lightPurple,
    borderRadius: 6,
    overflow: 'hidden',
    minWidth: 112,
  },
  linkDefault: {
    padding: 16,
  },
  linkCompact: {
    padding: 8,
  },
  textActive: {
    ...nsSemiBold,
    color: colors.white,
  },
  linkActiveJoined: {
    borderColor: colors.info,
    borderWidth: 1,
    backgroundColor: colors.lightPurple2,
  },
  joinedBackground: {
    backgroundColor: colors.lightPurple,
    borderRadius: 6,
  },
  buttonActive: {
    backgroundColor: colors.purple,
    borderColor: colors.purple,
    color: colors.white,
    fontWeight: '600',
  },
});
