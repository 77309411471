import React, { useState } from 'react';
import { Platform, View } from 'react-native';

import { usePharmacyPrescriptionAwaitingCollection } from '~/api/hooks/pharmacy/PharmacyPrescriptionsHook';
import { mr15 } from '~/common/commonStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { H2TtmSemiBoldBlack } from '~/components/commonText';
import { PharmacyAwaitingCollectionTable } from '~/components/pharmacies/PharmacyAwaitingCollectionTable';
import { SearchBox } from '~/components/searchBox';
import { SortIcon } from '~/components/svgImages';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const PharmacyAwaitingCollectionScreen: React.FC = () => {
  const { data, loading, pagination, onPageChanged, setSearch, search, updatePrescriptions, setSortingDesc, sortDesc } =
    usePharmacyPrescriptionAwaitingCollection();
  const [localSearch, setLocalSearch] = useState(search);

  const { isMobile } = useBreakpoints();

  const searchComponent = (
    <SearchBox
      style={isMobile ? { flex: 1 } : { marginVertical: 0 }}
      onTextChange={setLocalSearch}
      value={localSearch}
      onSubmit={() => setSearch(localSearch)}
      placeholder="Search"
      keyboardType="search"
    />
  );
  return (
    <PageLayout
      hideBack
      title="Awaiting Collection"
      header={
        isMobile ? (
          <View
            style={[
              {
                flex: 1,
              },
            ]}>
            <H2TtmSemiBoldBlack>Awaiting Collection</H2TtmSemiBoldBlack>

            <View
              style={{
                flexDirection: 'row',
              }}>
              <IconButton
                transparent
                style={mr15}
                onPress={() => {
                  setSortingDesc(!sortDesc);
                }}>
                <SortIcon width={20} height={20} />
              </IconButton>
              {searchComponent}
            </View>
          </View>
        ) : (
          <View style={{ flex: 1, alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
            <H2TtmSemiBoldBlack style={{ minHeight: 45, marginBottom: 15 }}>Awaiting collection</H2TtmSemiBoldBlack>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>{searchComponent}</View>
          </View>
        )
      }
      scrollContentNode={
        <PharmacyAwaitingCollectionTable
          data={data}
          loading={loading}
          pagination={pagination}
          sortDesc={sortDesc}
          setSortingDesc={setSortingDesc}
          onPageChanged={onPageChanged}
          updateData={updatePrescriptions}
        />
      }
    />
  );
};
