import { differenceInMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { apiGetInsuranceDetails } from '~/api/services/insurance';
import { RootState } from '~/redux/reducers';
import { SET_INSURANCE_DETAILS } from '~/redux/reducers/patient/insuranceDetailsReducer';
import { useAppDispatch } from '~/redux/store';

export function useInsuranceDetails() {
  const insuranceDetails = useSelector((state: RootState) => state.insuranceDetailsReducer.insuranceDetails);
  const lastUpdated = useSelector((state: RootState) => state.insuranceDetailsReducer.lastUpdated);

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!insuranceDetails || differenceInMinutes(new Date(), lastUpdated) >= 5) {
      // If 5 minutes have elapsed, the image links need to be refreshed
      setLoading(!insuranceDetails);

      apiGetInsuranceDetails()
        .then((res) => {
          dispatch(SET_INSURANCE_DETAILS(res.data));
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [insuranceDetails]);

  return { insuranceDetails, loading };
}
