import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { View } from 'react-native';

import { ConsultationTypeIcons } from './ConsultationTypeIcons';

import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationTypeFormModel } from '~/api/models/consultations/models/ConsultationTypeFormModel';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { flex1, mb15, mh10 } from '~/common/commonStyles';
import { Accordion } from '~/components/buttons/Accordions/Accordion';
import { AccordionGroupProvider } from '~/components/buttons/Accordions/AccordionGroupProvider';
import {
  H5TtmRegularPlaceholder,
  H5TtmRegularSecondaryBlack,
  H5TtmSemiBoldDanger,
  H5TtmSemiBoldInfo,
} from '~/components/commonText';
import { Checkbox } from '~/components/inputs/Checkbox';
import { ConsultationTypeCostComponent } from '~/components/preferences/ConsultationTypeCostComponent';
import { minLengthValidation, validationSchema, priceValidation } from '~/services/validationConfig';
import { formatAmount } from '~/utils/amountUtil';
import { isClinicVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import isNullOrUndefined from '~/utils/types/isNullOrUndefined';

interface Props {
  control: Control<ConsultationTypeFormModel>;
  compact?: boolean;
}

const list: { title: string; type: ConsultationTypeEnum; unavailable?: boolean }[] = [
  { title: 'Home visit', type: ConsultationTypeEnum.HOME_VISIT, unavailable: isClinicVersion() },
  { title: 'Doctor on-demand', type: ConsultationTypeEnum.ON_DEMAND },
  { title: 'Scheduled appointment', type: ConsultationTypeEnum.SCHEDULED_APPOINTMENT, unavailable: isClinicVersion() },
];

export const ConsultationPriceDropdowns: React.FC<Props> = ({ control, compact }) => {
  const { isMobile } = useBreakpoints();
  const { hasFeatureAccess, isFeatureDisabled } = useFeatureAccess();

  const showPricing = hasFeatureAccess(FeatureAccessEnum.CONSULTATION_PRICES);
  return (
    <View style={{ alignSelf: 'stretch' }}>
      <AccordionGroupProvider>
        {list.map((element, index) => (
          <Controller
            key={index}
            name={`types.${element.type}` as any}
            control={control}
            render={({ field: { onChange, value }, fieldState: { isTouched, error } }) => (
              <Accordion
                disabled={!showPricing || element.unavailable}
                groupValue={element.type}
                style={[mb15]}
                titleNode={(expanded) => (
                  <View
                    style={
                      isMobile || compact
                        ? { height: expanded ? 20 : 50 }
                        : { display: 'flex', flexDirection: 'row', flex: 1 }
                    }>
                    <H5TtmRegularSecondaryBlack style={flex1}>{element.title}</H5TtmRegularSecondaryBlack>
                    {!expanded ? (
                      element.unavailable ? (
                        <H5TtmRegularPlaceholder>Type is not available</H5TtmRegularPlaceholder>
                      ) : !showPricing ? (
                        <H5TtmRegularPlaceholder>Availability is set by clinic</H5TtmRegularPlaceholder>
                      ) : isNullOrUndefined(value) ? (
                        <H5TtmRegularPlaceholder>Disabled</H5TtmRegularPlaceholder>
                      ) : value <= 0 ? (
                        <H5TtmSemiBoldDanger>Price is not set</H5TtmSemiBoldDanger>
                      ) : (
                        <H5TtmSemiBoldInfo>{`Maximum ${formatAmount(value, 'EUR')} per session`}</H5TtmSemiBoldInfo>
                      )
                    ) : null}
                  </View>
                )}
                left={<ConsultationTypeIcons type={element.type as ConsultationTypeEnum} style={mh10} />}>
                <>
                  <View style={mh10}>
                    <Checkbox
                      label="Enable consultation type"
                      checked={!isNullOrUndefined(value)}
                      onChange={(checked) => onChange(checked ? 0 : null)}
                    />
                  </View>
                  <ConsultationTypeCostComponent
                    containerStyle={{ marginVertical: 8, marginHorizontal: 15 }}
                    error={isTouched && !!error}
                    errorMessage={isTouched && error?.message}
                    value={value}
                    onChange={onChange}
                    title="Set up amount per visit"
                    disabled={isFeatureDisabled(FeatureAccessEnum.CONSULTATION_PRICES)}
                  />
                </>
              </Accordion>
            )}
            rules={{
              minLength: minLengthValidation(validationSchema.amount.minLength),
              validate: { priceValidation },
            }}
          />
        ))}
      </AccordionGroupProvider>
    </View>
  );
};
