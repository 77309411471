import React from 'react';
import { StyleSheet, View } from 'react-native';

import { AddDependantBackground } from './AddDependantBackground';

import { mb20, textCenter } from '~/common/commonStyles';
import { ButtonLink } from '~/components/commonButton';
import { H2TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import CustomSurface from '~/components/customSurface';
import { PatientGroup } from '~/components/svgImages';
import { colors } from '~/utils/colors';
import { dependantsLabels } from '~/utils/labels/dependantsLabels';
import { screenName } from '~/utils/screenName';

export const NoDependantsComponent: React.FC = () => {
  return (
    <View style={styles.container}>
      <View style={styles.background}>
        <AddDependantBackground />
      </View>
      <View style={styles.textContainer}>
        <View style={styles.content}>
          <CustomSurface style={[styles.centerSurface, mb20]}>
            <PatientGroup width={50} height={40} color={colors.info} />
          </CustomSurface>

          <View style={mb20}>
            <H2TtmSemiBoldBlack style={[textCenter, mb20]}>{dependantsLabels.addToFamily}</H2TtmSemiBoldBlack>

            {dependantsLabels.addToFamilySubtitle.map((text, index) => (
              <H6NsRegularBlack key={index} style={[textCenter, mb20]}>
                {text}
              </H6NsRegularBlack>
            ))}
          </View>
          <View style={styles.buttonView}>
            <ButtonLink
              style={{ width: '100%', textAlign: 'center' }}
              label={dependantsLabels.addDependant}
              screenName={screenName.DependantsAddNew}
            />
          </View>
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    position: 'relative',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  content: {
    maxWidth: 336,
    width: '100%',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
  },
  centerSurface: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    height: 100,
    width: 100,
    elevation: 2,
    backgroundColor: 'white',
    marginBottom: 20,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
  },
  buttonView: {
    width: '100%',
  },
  background: {
    position: 'absolute',
    right: -30,
    top: -15,
    left: 0,
    bottom: 0,
  },
});
