import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Checkbox as PaperCheckbox, TouchableRipple } from 'react-native-paper';

import { MandatoryAsterisks } from './MandatoryAsterisks';
import { colors } from '../../utils/colors';
import { FormErrorMessage, H6NsRegularBlack } from '../commonText';

import { isNative } from '~/utils/buildConfig';

interface Props {
  disabled?: boolean;
  label: string | React.ReactNode;
  postLabel?: React.ReactNode;
  preLabel?: boolean;
  checked: boolean;
  showMandatory?: boolean;
  error?: boolean;
  errorMessage?: string;
  onChange: (checked: boolean) => void;
}

export const Checkbox: React.FC<Props> = ({
  disabled,
  checked,
  label,
  postLabel,
  showMandatory,
  error,
  errorMessage,
  onChange,
}) => {
  const toggle = () => {
    if (onChange) onChange(!checked);
  };
  const labelComponent = label ? (
    <TouchableRipple style={{ flex: 1 }} onPress={toggle}>
      <View style={styles.label}>
        <H6NsRegularBlack>{label}</H6NsRegularBlack>
        <MandatoryAsterisks isMandatory={showMandatory} />
        {postLabel}
      </View>
    </TouchableRipple>
  ) : null;
  return (
    <View style={{ flex: 1 }}>
      <View style={styles.checkbox}>
        <PaperCheckbox.Android
          disabled={disabled}
          status={checked ? 'checked' : 'unchecked'}
          onPress={toggle}
          color={error ? colors.danger : colors.purple}
          uncheckedColor={error ? colors.danger : colors.purple}
        />
        {labelComponent}
      </View>
      {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    flex: isNative() ? undefined : 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
