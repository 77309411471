import React from 'react';

import { RequestsTable } from '../../components/doctor/requests/RequestsTable';
import { RequestsLayout } from '../../layouts/RequestsLayout';

interface Props {}

export const AppointmentRequests: React.FC<Props> = () => {
  return (
    <RequestsLayout rowStyle={{ marginHorizontal: 0 }}>
      <RequestsTable />
    </RequestsLayout>
  );
};
