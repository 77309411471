import React, { useEffect, useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { mb20, mt10, mt15, mt20 } from '~/common/commonStyles';
import { PatientListedDetails } from '~/components/common/patient/PatientListedDetails';
import {
  H4TtmRegularSecondaryBlack,
  SmallNsRegularSecondaryBlack,
  SmallNsSemiBoldTheme,
  SmallTtmSemiBoldDisabled,
} from '~/components/commonText';
import { Splitter } from '~/components/misc/Splitter';
import { ImagePreviewModal } from '~/components/modals/ImagePreviewModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { Column, Row } from '~/theme/components/grid';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { colors } from '~/utils/colors';
import { SHORT_DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';
import { consultationsLabels } from '~/utils/labels/consultations';

interface Props {
  consultation: ConsultationModel;
  theme?: 'lightPurple' | 'white';
  hideTitle?: boolean;
}

export const PatientConsultationInformation: React.FC<Props> = ({ consultation, hideTitle, theme = 'lightPurple' }) => {
  const symptoms = useMemo(
    () => consultation?.patient_symptoms?.map(extractClinicalTermDescription)?.join(', ') ?? '',
    [consultation]
  );

  const { openModal, registerModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.ImagePreview, ImagePreviewModal);
  }, []);

  if (!consultation) return null;
  return (
    <View
      style={{
        backgroundColor: colors[theme],
        paddingVertical: 10,
        borderRadius: 6,
        flex: 1,
      }}>
      {!hideTitle ? (
        <>
          <Row style={{ paddingHorizontal: 20 }}>
            <Column>
              <H4TtmRegularSecondaryBlack>{consultationsLabels.consultationDetails}</H4TtmRegularSecondaryBlack>
            </Column>
          </Row>
          <Splitter secondaryColor spacing={10} />
        </>
      ) : null}
      <View style={{ paddingHorizontal: 20 }}>
        <PatientListedDetails account={consultation?.patient} />
      </View>

      <Row style={[mt15, mb20, { paddingHorizontal: 20 }]}>
        <Column span={12}>
          <SmallTtmSemiBoldDisabled>{consultationsLabels.consultationReference}</SmallTtmSemiBoldDisabled>
          <SmallNsRegularSecondaryBlack>{consultation.reference || consultation.id}</SmallNsRegularSecondaryBlack>
        </Column>
        {consultation?.start_at ? (
          <Column span={12} style={mt10}>
            <SmallNsRegularSecondaryBlack>
              {parseDateTime(consultation.started_at ?? consultation.start_at, {
                outputFormat: `${SHORT_DATE_FORMAT} HH:mm`,
              })}{' '}
              - {parseDateTime(consultation?.ended_at ?? consultation.end_at, { outputFormat: 'HH:mm' })}
            </SmallNsRegularSecondaryBlack>
          </Column>
        ) : null}
      </Row>
      <Row style={{ paddingHorizontal: 20 }}>
        <Column>
          <View>
            <SmallTtmSemiBoldDisabled>Reason for consultation</SmallTtmSemiBoldDisabled>
            <SmallNsRegularSecondaryBlack>
              {consultation.reason || appointmentLabels.noReason}
            </SmallNsRegularSecondaryBlack>
          </View>

          {consultation?.reason_media?.length ? (
            <View style={mt15}>
              {consultation.reason_media.map((media, index) => (
                <TouchableOpacity
                  onPress={() => openModal(ModalName.ImagePreview, { image: media, account: consultation?.patient })}
                  key={index}>
                  <SmallNsSemiBoldTheme>{media.name}</SmallNsSemiBoldTheme>
                </TouchableOpacity>
              ))}
            </View>
          ) : null}

          {symptoms ? (
            <View style={mt20}>
              <SmallTtmSemiBoldDisabled>Symptoms</SmallTtmSemiBoldDisabled>
              <SmallNsRegularSecondaryBlack>{symptoms}</SmallNsRegularSecondaryBlack>
            </View>
          ) : null}
        </Column>
      </Row>
      <Row style={[mt15, mb20, { paddingHorizontal: 20 }]}>
        <Column>
          <SmallTtmSemiBoldDisabled>{labels.sickLeaveCertificate}</SmallTtmSemiBoldDisabled>
          <SmallNsRegularSecondaryBlack>
            {consultation?.is_sick_leave_certificate_required ? 'Yes' : 'No'}
          </SmallNsRegularSecondaryBlack>
        </Column>
      </Row>
    </View>
  );
};
