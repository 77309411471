import React from 'react';

import OnboardingScreen from '../../components/onBoarding/OnBoardingViewComponent';

import { setObjectAsyncItem, storageKeys } from '~/common/asyncStorage';
import { SET_ONBOARDING_COMPLETE } from '~/redux/reducers/appDetailsReducer';
import { useAppDispatch } from '~/redux/store';

export const OnBoardingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const complete = async () => {
    dispatch(SET_ONBOARDING_COMPLETE(true));
    await setObjectAsyncItem(storageKeys.onboarding, true);
  };
  return <OnboardingScreen onComplete={complete} />;
};
