import React from 'react';
import { Control } from 'react-hook-form';

import { ConsultationPriceDropdowns } from './ConsultationPriceDropdowns';
import { lineHeight22, mb10 } from '../../../../common/commonStyles';
import { Container, Row, Column } from '../../../../theme/components/grid';
import { labels } from '../../../../utils/labels';
import { H3TtmSemiBoldBlack, SmallNsRegularSecondaryBlack } from '../../../commonText';

import { ConsultationTypeFormModel } from '~/api/models/consultations/models/ConsultationTypeFormModel';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
interface Props {
  control: Control<ConsultationTypeFormModel>;
  loading: boolean;
}

export const SettingsConsultationCosts: React.FC<Props> = ({ control, loading }) => {
  return (
    <Container alignSelf="flex-start">
      {loading ? (
        <LoadingActivityIndicator style={{ minHeight: 170 }} />
      ) : (
        <>
          <Row style={[mb10]}>
            <Column>
              <H3TtmSemiBoldBlack>{labels.settingsConsultationTypeTitle}</H3TtmSemiBoldBlack>
            </Column>
          </Row>
          <Row>
            <Column span={{ xs: 12, md: 6 }}>
              {labels.settingsConsultationDesc.map((label, index) => (
                <SmallNsRegularSecondaryBlack key={index} style={[mb10, lineHeight22]}>
                  {label}
                </SmallNsRegularSecondaryBlack>
              ))}
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <ConsultationPriceDropdowns control={control} />
            </Column>
          </Row>
        </>
      )}
    </Container>
  );
};
