import React from 'react';
import { connect } from 'react-redux';

import { useLoginDetails } from '../../api/hooks/accounts/LoginDetails';
import { H6NsRegularSecondaryBlack, H6NsSemiBoldSecondaryBlack } from '../../components/commonText';
import OtpScreenComponent from '../../components/otp/OtpScreenComponent';
import { labels } from '../../utils/labels';

import { RootState } from '~/redux/reducers';
import log from '~/utils/logger';
interface Props {}

const mapStateToProps = (state: RootState) => ({
  userDetails: state.userDetailsReducer.userDetails,
});

const OtpForgotPasscodeValidationScreen: React.FC<Props> = (): JSX.Element => {
  const loginDetails = useLoginDetails();

  const forgotPasscodeValidation = (otp: string) => {
    log.warn('Not implemented', 'Verify forgot passcode');
    // navigation.navigate(screenName.PasscodeScreen,{showCreatePassCode:true,showForgotPassCode:true})
  };

  return (
    <>
      <OtpScreenComponent
        title={labels.enter6DigitOtp}
        onPress={forgotPasscodeValidation}
        hideResendButton
        hideProgressBar
        subTitle={
          <>
            <H6NsRegularSecondaryBlack>{labels.weSentTheOtpTo}</H6NsRegularSecondaryBlack>
            <H6NsSemiBoldSecondaryBlack>{`+${loginDetails.details?.country_code} ${loginDetails.details?.number}`}</H6NsSemiBoldSecondaryBlack>
          </>
        }
      />
    </>
  );
};

//make this component available to the app
export default connect(mapStateToProps)(OtpForgotPasscodeValidationScreen);
