/* eslint-disable import/order */
import { DateSelectArg, DatesSetArg, DayCellMountArg, EventApi } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import iCalendarPlugin from '@fullcalendar/icalendar';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FlashList } from '@shopify/flash-list';
/* eslint-enable import/order */
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Divider, Menu } from 'react-native-paper';
import { useSelector } from 'react-redux';

import ConsultationAgendaItem from '../../../../components/calendar/ConsultationAgendaItem';

import { useClinicReportExport } from '~/api/hooks/clinics/ClinicReportExport';
import { apiCalendarEvents, apiDeleteUnavailable, apiSetUnavailable } from '~/api/services/calendar';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { mb20, mr10, mt25, mv10 } from '~/common/commonStyles';
import { OutlineButton } from '~/components/commonButton';
import { H4TtmSemiBoldBlack, H5TtmRegularBlack, H6NsRegularBlack } from '~/components/commonText';
import { CalendarWithChecked, CalendarWithCross } from '~/components/svgImages';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { RootState } from '~/redux/reducers';
import {
  ADD_CALENDAR_EVENTS,
  ADD_CALENDAR_EVENTS_FOR_SELECTED_DATE,
  ADD_CURRENT_SELECTED_DATE,
  ADD_EVENTS_FOR_SELECTED_DATE,
  CLEAR_CALENDAR_EVENTS,
} from '~/redux/reducers/doctor/calendarAppointmentsReducer';
import { useAppDispatch } from '~/redux/store';
import { Column, Container, Row } from '~/theme/components/grid';
import { isClinicVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import {
  DAY_MONTH_LONG_FORMAT,
  LARAVEL_ATOM,
  LARAVEL_DATE_TIME_SHORT,
  NUM_DATE_FORMAT,
  momentObj,
  parseDateTime,
} from '~/utils/dateAndTime';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { labels } from '~/utils/labels';
import { calendarLabels } from '~/utils/labels/calendar';

export const DoctorAppointmentsWeb: React.FC = () => {
  const [delayedShow, setDelayedShow] = useState(false);
  const [datePopupLocation, setDatePopupLocation] = useState<{ x: number; y: number } | null>();
  const [refreshEvents, setRefreshEvents] = useState(false);

  const calendarRef = useRef<FullCalendar>(null);
  const dispatch = useAppDispatch();
  const { tryExportConsultationEvents } = useClinicReportExport();

  const calendarEvents = useSelector((state: RootState) => state.calendarEventsReducer.calendarEvents);
  const calendarEventsForSelectedDate = useSelector(
    (state: RootState) => state.calendarEventsReducer.calendarEventsForSelectedDate
  );
  const eventsForSelectedDate = useSelector((state: RootState) => state.calendarEventsReducer.eventsForSelectedDate);
  const currentSelectedDate = useSelector((state: RootState) => state.calendarEventsReducer.currentSelectedDate);

  const isCurrentDayUnavailable = useMemo(() => {
    return calendarEventsForSelectedDate?.filter((e) => e.allDay).length > 0;
  }, [calendarEventsForSelectedDate]);

  const formattedCurrentDate = useMemo(
    () => parseDateTime(currentSelectedDate, { outputFormat: DAY_MONTH_LONG_FORMAT }),
    [currentSelectedDate]
  );

  usePageFocus(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().updateSize();
    }
  });

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, () => {
      setRefreshEvents(true);
    });
    return removeListener;
  }, []);

  useEffect(() => {
    setDelayedShow(true);
  }, []);

  useEffect(() => {
    if (refreshEvents) {
      dispatch(CLEAR_CALENDAR_EVENTS());
      getEvents().then(() => {
        setSelectedDay(currentSelectedDate);
      });
    }
  }, [refreshEvents]);

  useEffect(() => {
    setSelectedDay(currentSelectedDate);
  }, [calendarRef.current, calendarEvents]);

  const setSelectedDay = (date: Date) => {
    if (calendarRef?.current != null) {
      const events = calendarRef?.current
        .getApi()
        .getEvents()
        .filter((e) => e.id === moment(date).format(NUM_DATE_FORMAT));
      addEventsForSelectedDate(events ?? []);
    }
  };

  const addEventsForSelectedDate = (events: EventApi[]) => {
    dispatch(ADD_CALENDAR_EVENTS_FOR_SELECTED_DATE(events));

    const consultations = [];
    events
      .filter((e) => e.extendedProps.event_type === 'consultation')
      .forEach((e) => {
        consultations.push(e.extendedProps);
      });

    dispatch(ADD_EVENTS_FOR_SELECTED_DATE(consultations));
  };

  const handleDayClick = (dateClick: DateClickArg) => {
    const date = dateClick.date;
    dispatch(ADD_CURRENT_SELECTED_DATE(dateClick.date));

    setSelectedDay(date);
  };

  const showDatePopup = (info: DateSelectArg) => {
    dispatch(ADD_CURRENT_SELECTED_DATE(info.start));
  };

  const handleContextMenu = (info: DayCellMountArg, jsEvent: MouseEvent) => {
    jsEvent.preventDefault();
    if (momentObj.isAfter(info.date)) return;

    const events = info.view.calendar
      .getEvents()
      .filter((e) => e.id === info.startStr)
      .filter((e) => e.extendedProps.event_type === 'consultation');
    if (events.length <= 0) {
      setDatePopupLocation({ x: jsEvent.pageX, y: jsEvent.pageY });
    }
  };

  const getEvents = async (dates?: DatesSetArg) => {
    const calendarApi = calendarRef.current?.getApi();
    const res = await apiCalendarEvents({
      filter: { type: ['consultation', 'unavailability'].join(',') },
      from: dates?.startStr ?? parseDateTime(calendarApi?.view.activeStart, { outputFormat: LARAVEL_ATOM }),
      to: dates?.endStr ?? parseDateTime(calendarApi?.view.activeEnd, { outputFormat: LARAVEL_ATOM }),
    });

    dispatch(CLEAR_CALENDAR_EVENTS());
    dispatch(ADD_CALENDAR_EVENTS(res.data));
    setRefreshEvents(false);
  };

  const setUnavailable = async () => {
    await apiSetUnavailable({
      ends_at: moment(currentSelectedDate).add(23.99, 'hours').format(LARAVEL_DATE_TIME_SHORT),
      starts_at: moment(currentSelectedDate).format(LARAVEL_DATE_TIME_SHORT),
    });
    setRefreshEvents(true);
    setDatePopupLocation(null);
  };
  const deleteUnavailable = async () => {
    await apiDeleteUnavailable(calendarEventsForSelectedDate[0].extendedProps.id);
    setRefreshEvents(true);
    setDatePopupLocation(null);
  };

  return (
    <NestedPageInternalLayout>
      <Container noOuterGutter alignSelf="flex-start">
        <Row>
          <Column span={{ xs: 12, lg: 8 }}>
            {delayedShow ? (
              <FullCalendar
                eventColor={colors.info}
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, iCalendarPlugin]}
                initialView="dayGridMonth"
                dateClick={handleDayClick}
                headerToolbar={{
                  left: 'prev next',
                  center: 'title',
                  right: 'today dayGridMonth timeGridWeek',
                }}
                events={calendarEvents}
                datesSet={getEvents}
                noEventsText="No Events"
                selectable
                select={showDatePopup}
                eventTimeFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                }}
                buttonText={{
                  today: labels.today,
                  month: labels.month,
                  week: labels.week,
                }}
                dayCellDidMount={(arg) => {
                  arg.el.addEventListener('contextmenu', (jsEvent) => {
                    handleContextMenu(arg, jsEvent);
                  });
                }}
              />
            ) : null}
          </Column>
          <Column span={{ xs: 12, lg: 4 }}>
            <View style={styles.dayViewHeader}>
              <H4TtmSemiBoldBlack>{`${formattedCurrentDate}`}</H4TtmSemiBoldBlack>
            </View>
            <View style={mt25} />
            <FlashList
              data={eventsForSelectedDate}
              keyExtractor={(item) => `${item.id}`}
              ItemSeparatorComponent={() => (
                <View style={mv10}>
                  <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
                </View>
              )}
              ListEmptyComponent={
                <H6NsRegularBlack style={mb20}>No appointments on the selected date</H6NsRegularBlack>
              }
              renderItem={({ item }) => <ConsultationAgendaItem calendarEvent={item} />}
              estimatedItemSize={75}
            />
            {isClinicVersion() ? (
              <View>
                <OutlineButton
                  disabled={!eventsForSelectedDate?.length}
                  label="Export Day's Reports"
                  funCallback={() => tryExportConsultationEvents(eventsForSelectedDate, currentSelectedDate)}
                />
              </View>
            ) : null}
          </Column>
        </Row>

        <Menu
          visible={!!datePopupLocation}
          style={{ borderRadius: 20 }}
          anchor={datePopupLocation}
          onDismiss={() => {
            setDatePopupLocation(null);
          }}>
          {!isCurrentDayUnavailable ? (
            <Menu.Item
              title={
                <View
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}>
                  <CalendarWithCross height={25} width={25} />
                  <View style={mr10} />
                  <H5TtmRegularBlack>{calendarLabels.unavailable}</H5TtmRegularBlack>
                </View>
              }
              onPress={setUnavailable}
            />
          ) : null}
          {isCurrentDayUnavailable ? (
            <Menu.Item
              title={
                <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                  <CalendarWithChecked height={25} width={25} />
                  <View style={mr10} />
                  <H5TtmRegularBlack>{calendarLabels.imAvailable}</H5TtmRegularBlack>
                </View>
              }
              onPress={deleteUnavailable}
            />
          ) : null}
        </Menu>
      </Container>
    </NestedPageInternalLayout>
  );
};

const styles = StyleSheet.create({
  dayViewHeader: {
    backgroundColor: colors.lightPurple,
    height: 50,
    paddingHorizontal: 12,
    display: 'flex',
    justifyContent: 'center',
  },
  noData: {
    display: 'flex',
    alignItems: 'center',
    padding: 12,
  },
});
