import React from 'react';

import { ConsultationTypeEnum } from './ConsultationTypeEnum';

import { Calendar, Home, Stethoscope, SvgImageProps } from '~/components/svgImages';
import { colors } from '~/utils/colors';

export const ConsultationTypeToIcon = {
  [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: <Calendar height={22} width={22} color={colors.grey} />,
  [ConsultationTypeEnum.ON_DEMAND]: <Stethoscope height={22} width={22} color={colors.grey} />,
  [ConsultationTypeEnum.HOME_VISIT]: <Home height={22} width={22} color={colors.grey} />,
};

export const DynamicConsultationTypeToIcon: Record<ConsultationTypeEnum, (props: SvgImageProps) => JSX.Element> = {
  [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: (props: SvgImageProps) => <Calendar {...props} />,
  [ConsultationTypeEnum.ON_DEMAND]: (props: SvgImageProps) => <Stethoscope {...props} />,
  [ConsultationTypeEnum.HOME_VISIT]: (props: SvgImageProps) => <Home {...props} />,
  [ConsultationTypeEnum.CLINIC]: (props: SvgImageProps) => null,
};
