import { Route } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useDependant } from '~/api/hooks/dependants/DependantHook';
import { FormAccountModel } from '~/api/models/accounts/models/AccountModel';
import { apiUpdateAccounts } from '~/api/services/accounts';
import { SuccessAlert } from '~/common/commonMethods';
import { mb10, pb15 } from '~/common/commonStyles';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import FloatingInput from '~/components/inputs/FloatingInput';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { IdentificationDocumentsEnum } from '~/constants/documents';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { genderList } from '~/services/config';
import { requiredValidation } from '~/services/validationConfig';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';

interface Props {
  route: Route<string, { id: string }>;
}

export const DependantPersonalInfoScreen: React.FC<Props> = ({ route }) => {
  const { dependant } = useDependant({ id: route.params?.id, allowStale: true });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<FormAccountModel>({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!dependant?.account) return;
    reset({
      ...dependant.account,
      document_type: dependant.account.passport_number
        ? IdentificationDocumentsEnum.PASSPORT
        : IdentificationDocumentsEnum.IDENTITY_CARD,
      document_number: dependant.account.passport_number || dependant.account.national_id_number,
    });
  }, [dependant]);

  const submit = async (details: FormAccountModel) => {
    try {
      await apiUpdateAccounts({
        id: dependant.account.id,
        data: {
          first_name: details.first_name,
          last_name: details.last_name,
          national_id_number: details.national_id_number,
          passport_number: details.passport_number,
          date_of_birth: details.date_of_birth,
          gender: details.gender,
        },
      });

      SuccessAlert(['Successfully update family member details']);
      reset(details);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  return (
    <NestedPageInternalLayout
      footer={
        <CancelContinueFooter
          onConfirm={handleSubmit(submit)}
          onCancel={reset}
          confirmTitle={labels.update}
          disabled={!isDirty}
        />
      }>
      <Grid>
        <Container alignSelf="flex-start" maxContainerWidths={760} style={pb15}>
          <Row style={mb10}>
            <Column>
              <H3TtmSemiBoldBlack>Profile information</H3TtmSemiBoldBlack>
            </Column>
          </Row>
          <Row>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="first_name"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    error={isSubmitted && !!error?.message}
                    errorMessage={isSubmitted && error?.message}
                    label={labels.legalFirstName}
                    showMandatory
                  />
                )}
                rules={{
                  required: requiredValidation(labels.legalFirstName),
                }}
              />
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="last_name"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    error={isSubmitted && !!error?.message}
                    errorMessage={isSubmitted && error?.message}
                    label={labels.legalLastName}
                    showMandatory
                  />
                )}
                rules={{
                  required: requiredValidation(labels.legalLastName),
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="document_number"
                control={control}
                render={({ field: { value }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    value={value}
                    label={
                      watch('document_type') === IdentificationDocumentsEnum.PASSPORT
                        ? labels.passport
                        : labels.identityCard
                    }
                    disabled
                  />
                )}
              />
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="date_of_birth"
                control={control}
                render={({ field: { value } }) => (
                  <FloatingInput
                    disabled
                    value={parseDateTime(value, { outputFormat: DATE_FORMAT }) || ''}
                    label={labels.dobLabel}
                    placeholder="DD/MM/YY"
                  />
                )}
              />
            </Column>
          </Row>
          <Row>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="gender"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <Dropdown
                    label={labels.gender}
                    value={value}
                    setValue={onChange}
                    list={genderList.map((item) => ({ label: item.name, value: item.id }))}
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                  />
                )}
              />
            </Column>
          </Row>
        </Container>
      </Grid>
    </NestedPageInternalLayout>
  );
};
