import { StyleSheet } from 'react-native';

import { colors } from '../../utils/colors';
import { fontFamily } from '../../utils/fontFamily';

export const modalStyles = StyleSheet.create({
  modalWrapper: {
    flex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalWrapperWeb: {
    justifyContent: 'center',
  },
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.7)',
    right: 0,
  },
  modalContentContainer: {
    width: '100%',
    maxWidth: 548,
    maxHeight: '100%',
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    display: 'flex',
  },
  typesOfConsultationsModalTopNotch: {
    marginBottom: 20,
    backgroundColor: '#35405233',
    borderRadius: 10,
    alignItems: 'center',
    width: 100,
    height: 6,
  },
  alertMessage: {
    fontFamily: fontFamily.fontRegular,
    fontSize: 14,
    color: colors.grey,
    marginVertical: 10,
    // textAlign: "center",
  },
  alertMessageTittle: {
    marginVertical: 10,
    textAlign: 'center',
    fontFamily: fontFamily.fontSemiBold,
    fontSize: 24,
    color: colors.black,
  },
});
