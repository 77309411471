import React, { useMemo } from 'react';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ml10 } from '~/common/commonStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import { CheckboxGroup } from '~/components/inputs/CheckboxGroup';

const ConsultationTypes = [
  {
    label: <H6NsRegularBlack style={ml10}>Doctor available now</H6NsRegularBlack>,
    value: ConsultationTypeEnum.ON_DEMAND,
  },
  {
    label: <H6NsRegularBlack style={ml10}>Scheduled appointment</H6NsRegularBlack>,
    value: ConsultationTypeEnum.SCHEDULED_APPOINTMENT,
  },
  {
    label: <H6NsRegularBlack style={ml10}>Home visit</H6NsRegularBlack>,
    value: ConsultationTypeEnum.HOME_VISIT,
  },
  {
    label: <H6NsRegularBlack style={ml10}>Clinic appointment</H6NsRegularBlack>,
    value: ConsultationTypeEnum.CLINIC,
  },
];

interface Props {
  value: ConsultationTypeEnum[];
  setValue: (value: number[]) => void;
  withAll?: boolean;
}
export const ConsultationTypeFilterComponent: React.FC<Props> = ({ value, setValue, withAll }) => {
  const items = useMemo(() => {
    if (withAll) {
      return [
        ...ConsultationTypes,
        {
          label: <H6NsRegularBlack style={ml10}>All</H6NsRegularBlack>,
          value: null,
        },
      ];
    } else {
      return ConsultationTypes;
    }
  }, [withAll]);

  const localSetValue = (value: number[]) => {
    if (!value.length || value[value.length - 1] === null) setValue([null]);
    else setValue(value.filter((v) => v));
  };
  return <CheckboxGroup items={items} values={value ?? []} onChange={localSetValue} />;
};
