import React from 'react';
import { useFormState } from 'react-hook-form';
import { View } from 'react-native';

import { SickLeaveCertificateEdit } from './SickLeaveCertificateEdit';
import { SickLeaveCertificateView } from './SickLeaveCertificateView';

import { mb10 } from '~/common/commonStyles';
import { Accordion } from '~/components/buttons/Accordions/Accordion';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { SickLeaveCert } from '~/components/svgImages';
import { useSickLeaveDataContext } from '~/providers/consultation/SickLeaveDataContext';

interface Props {
  editable?: boolean;
}

export const SickLeaveCertificate: React.FC<Props> = ({ editable }) => {
  const { control, loadData, loading } = useSickLeaveDataContext();

  const { isSubmitted, isValid } = useFormState({ control });
  return (
    <Accordion
      left={<SickLeaveCert width={16} height={16} />}
      title="Sick Leave Certificate"
      style={[mb10]}
      error={editable && isSubmitted && !isValid}
      onExpandedChanged={(expanded) => {
        if (expanded) loadData();
      }}>
      {loading && editable ? (
        <LoadingActivityIndicator />
      ) : (
        <View style={{ padding: editable ? 10 : 0 }}>
          {editable ? <SickLeaveCertificateEdit /> : <SickLeaveCertificateView />}
        </View>
      )}
    </Accordion>
  );
};
