import Feather from '@expo/vector-icons/Feather';
import React, { useMemo } from 'react';
import { View } from 'react-native';

import { mb10, mt10 } from '~/common/commonStyles';
import { OutlineButton } from '~/components/commonButton';
import { H3TtmSemiBoldBlack, H5TtmSemiBoldBlack } from '~/components/commonText';
import { RadioButtons } from '~/components/inputs/RadioButtons';
export const DoctorSortBy = [
  { id: 'name', name: 'Name' },
  { id: 'price', name: 'Price' },
  { id: 'rating', name: 'Rating' },
  { id: 'experience', name: 'Experience' },
];
interface Props {
  largeHeader?: boolean;
  value: string;
  onValueChanged: (value: string) => void;
}

export const DoctorListSortingComponent: React.FC<Props> = ({ value, onValueChanged, largeHeader }) => {
  const sortKey = useMemo(() => {
    return value?.replace(/^[+-]/, '') ?? '';
  }, [value]);

  const sortKeyChanged = (key: string) => {
    onValueChanged(`${isDescending ? '-' : ''}${key}`);
  };

  const sortDirectionChanged = (descending: boolean) => {
    onValueChanged(`${descending ? '-' : ''}${sortKey}`);
  };

  const isDescending = useMemo(() => {
    return value.charAt(0) === '-';
  }, [value]);

  return (
    <View>
      <View
        style={[
          mb10,
          { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
        ]}>
        {largeHeader ? (
          <H3TtmSemiBoldBlack>Sort by</H3TtmSemiBoldBlack>
        ) : (
          <H5TtmSemiBoldBlack>Sort by</H5TtmSemiBoldBlack>
        )}
      </View>
      <RadioButtons items={DoctorSortBy} selected={sortKey} onValueChange={sortKeyChanged} />
      <View style={mt10}>
        <OutlineButton
          label={isDescending ? 'Des' : 'Asc'}
          icon={<Feather name={isDescending ? 'chevron-down' : 'chevron-up'} size={14} />}
          funCallback={() => {
            sortDirectionChanged(!isDescending);
          }}
          style={{ borderWidth: 1, paddingVertical: 4 }}
          disabled={!sortKey}
        />
      </View>
    </View>
  );
};
