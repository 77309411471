import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { useHealthRecord } from '~/api/hooks/consultations/HealthRecordHook';
import { useInitialisedReferralMedia } from '~/api/hooks/consultations/ReferralMediaHook';
import { ReferralModel } from '~/api/models/consultations/models/ReferralModel';
import { apiGetReferralIndex } from '~/api/services/consultations/referral';
import { mb10 } from '~/common/commonStyles';
import { LabelWithTextColumn, LabelWithTextRow } from '~/components/commonComponents';
import { H5TtmSemiBoldDanger, H6NsRegularTheme, H6NsSemiBoldBlack } from '~/components/commonText';
import { ConsultationViewItem } from '~/components/doctor/patients/consultation/ConsultationViewItem';
import { useInsuranceClaimFormContext } from '~/providers/insurance/InsuranceClaimFormContext';
import { Column, Row } from '~/theme/components/grid';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { colors } from '~/utils/colors';
import { TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { getAccountName } from '~/utils/personalDetailsUtils';

export const DiagnosisAndTreatmentDetails: React.FC = () => {
  const { consultation } = useInsuranceClaimFormContext();
  const [referral, setReferral] = useState<ReferralModel>();

  const { referralMediaFiles, generatedFiles } = useInitialisedReferralMedia({ referralId: referral?.id });
  const { healthRecord, getHealthRecord } = useHealthRecord({ consultationId: consultation?.id });

  const hasNoReferralsUploadedFiles = useMemo(() => {
    return (!referralMediaFiles?.length && !generatedFiles?.media && !generatedFiles?.status) || !referral?.id;
  }, [referralMediaFiles, generatedFiles]);

  const getReferral = async (consultationId) => {
    const responseReferral = await apiGetReferralIndex({ filter: { consultation_id: consultationId }, limit: 1 });
    const referral = responseReferral.data.data?.length ? responseReferral.data.data[0] : undefined;

    setReferral(referral);
  };

  useEffect(() => {
    if (!consultation?.id) return;

    getHealthRecord();
    getReferral(consultation.id);
  }, [consultation?.id]);

  if (!consultation) return null;
  return (
    <Row>
      <Column>
        <H6NsSemiBoldBlack style={mb10}>Diagnosis and treatment</H6NsSemiBoldBlack>

        <LabelWithTextRow
          color={colors.black}
          label="Date of consultation"
          value={`${parseDateTime(consultation.started_at ?? consultation.start_at, {
            outputFormat: 'Do MMMM, ' + TIME_FORMAT,
          })}${parseDateTime(consultation.ended_at, { outputFormat: ` - ${TIME_FORMAT}` }) ?? ''}`}
          style={{ marginBottom: 10 }}
        />
        <LabelWithTextColumn
          label={labels.symptoms}
          value={
            healthRecord?.template_symptoms
              ? healthRecord?.template_symptoms
                  .map((e) => {
                    return extractClinicalTermDescription(e);
                  })
                  .join(',')
              : ''
          }
          style={{ marginBottom: 10 }}
        />
        <LabelWithTextColumn
          label={labels.diagnoses}
          value={extractClinicalTermDescription(healthRecord?.diagnosis)}
          style={{ marginBottom: 10 }}
        />
        <LabelWithTextColumn
          label={labels.DifferentialDiagnoses}
          value={extractClinicalTermDescription(healthRecord?.differential_diagnosis) || 'Not specified'}
          style={{ marginBottom: 10 }}
        />
        <LabelWithTextColumn
          label="Treatment given"
          value={healthRecord?.treatment_given}
          style={{ marginBottom: 10 }}
        />
        <LabelWithTextColumn
          label="Has the patient been referred to a specialist?"
          value={hasNoReferralsUploadedFiles ? 'No' : 'Yes'}
          style={{ marginBottom: 10 }}
        />

        {hasNoReferralsUploadedFiles ? null : (
          <View>
            <View style={{ flexDirection: 'column' }}>
              {referralMediaFiles.map((item, index, arr) => {
                return (
                  <ConsultationViewItem
                    title={item.file_name}
                    value={item}
                    style={{ borderBottomWidth: 0, padding: 0 }}
                    removeIconBorder
                  />
                );
              })}
              <ConsultationViewItem
                title="Referral"
                value={generatedFiles?.media?.length ? generatedFiles.media[0] : undefined}
                style={{ borderBottomWidth: 0, padding: 0 }}
                status={generatedFiles?.status}
                removeIconBorder
              />

              <H5TtmSemiBoldDanger style={{ fontSize: 12 }}>
                Please note that the referral form will be attached to the generated Insurance Claim Form PDF
              </H5TtmSemiBoldDanger>
            </View>
          </View>
        )}
        <View style={{ marginBottom: 10 }} />
        <LabelWithTextRow label="Doctor" value={getAccountName(consultation.doctor)} style={{ marginBottom: 10 }} />

        <LabelWithTextColumn
          label="Specialisations"
          value={consultation.doctor?.areas_of_specialisation?.map((specialisation) => specialisation.name).join(', ')}
          style={{ marginBottom: 10 }}
        />
      </Column>
    </Row>
  );
};
