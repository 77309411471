import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { mt10 } from '~/common/commonStyles';
import { H4TtmSemiBoldBlack } from '~/components/commonText';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { Container, Row, Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  prescription: PrescriptionModel;
  style?: StyleProp<ViewStyle>;
}

export const PrescriptionDoctorPatientDetails: React.FC<Props> = ({ prescription, style }) => {
  const { isMobile } = useBreakpoints();
  return (
    <Container style={[styles.profiles, style]}>
      <Row>
        <Column span={{ xs: 12, md: 6 }}>
          <View>
            <H4TtmSemiBoldBlack sty>Doctor details</H4TtmSemiBoldBlack>
            <ProfileDetailsComponent style={mt10} account={prescription.doctor} isDoctor />
          </View>
        </Column>
        <Column span={{ xs: 12, md: 6 }} style={isMobile ? { paddingTop: 20 } : null}>
          <View>
            <H4TtmSemiBoldBlack>Patient details</H4TtmSemiBoldBlack>
            <ProfileDetailsComponent style={mt10} account={prescription.patient} />
          </View>
        </Column>
      </Row>
    </Container>
  );
};

const styles = StyleSheet.create({
  profiles: {
    backgroundColor: colors.lightPurple,
    borderRadius: 6,
    padding: 8,
    marginBottom: 16,
  },
});
