import { Route } from '@react-navigation/native';
import React from 'react';

import { InsuranceClaimFormWizard } from '~/components/patient/insurance/InsuranceClaimFormWizard';
import { InsuranceClaimFormProvider } from '~/providers/insurance/InsuranceClaimFormProvider';
interface Props {
  route: Route<string, { consultationId: string }>;
}

export const InsuranceClaimFormScreen: React.FC<Props> = ({ route }) => {
  const consultationId = Number(route.params.consultationId);

  return (
    <InsuranceClaimFormProvider consultationId={consultationId}>
      <InsuranceClaimFormWizard />
    </InsuranceClaimFormProvider>
  );
};
