import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  consultationIds: number[];
}

const initialState: IState = {
  consultationIds: [],
};

const approvalConsultationRequestsQueueSlice = createSlice({
  name: 'approvalConsultationRequestsQueue',
  initialState,
  reducers: {
    ADD_TO_QUEUE: (state, action: PayloadAction<number>) => {
      if (state.consultationIds.includes(action.payload)) return state;

      return {
        consultationIds: [...state.consultationIds, action.payload],
      };
    },
    REMOVE_FROM_QUEUE: (state) => {
      return {
        consultationIds: [...state.consultationIds.slice(1)],
      };
    },
    LOGOUT: () => ({
      ...initialState,
    }),
  },
});

export const { ADD_TO_QUEUE, REMOVE_FROM_QUEUE, LOGOUT } = approvalConsultationRequestsQueueSlice.actions;

export default approvalConsultationRequestsQueueSlice.reducer;
