import * as WebBrowser from 'expo-web-browser';
import React, { useEffect } from 'react';
import { AppState, Linking, View } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { Button, TextButton } from '../commonButton';
import { H2TtmSemiBoldBlack, H6NsRegularBlack, H6NsSemiBoldBlack } from '../commonText';

import { mb20, mt10, txtCenter } from '~/common/commonStyles';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  url: string;
}

export const OpenUrlModal: React.FC<Props> = ({ onHide, url }) => {
  useEffect(() => {
    if (!url) onHide();
  }, [url]);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (nextAppState === 'background' || nextAppState === 'inactive') {
        onHide();
      }
    });

    return () => {
      subscription.remove();
    };
  }, []);

  const openDocument = async () => {
    await Linking.openURL(url).catch(() => {
      return WebBrowser.openBrowserAsync(url, {
        windowName: '_blank',
      });
    });
  };

  const buttons = [
    <Button label="Open document" funCallback={openDocument} />,
    <TextButton label={labels.close} funCallback={onHide} />,
  ];
  return (
    <ModalContainer hideOnBackground onHide={onHide} centerModal buttons={buttons}>
      <View>
        <H2TtmSemiBoldBlack style={[txtCenter, mt10, mb20]}>Opening document</H2TtmSemiBoldBlack>
        <H6NsRegularBlack>
          We are currently trying to open the document for you. Should the document not appear, please click the{' '}
          <H6NsSemiBoldBlack>Open document</H6NsSemiBoldBlack> button below
        </H6NsRegularBlack>
      </View>
    </ModalContainer>
  );
};
