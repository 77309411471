import Feather from '@expo/vector-icons/Feather';
import React, { useState } from 'react';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';

import { usePharmacyDispenseHistory } from '~/api/hooks/pharmacy/PharmacyDispenseHook';
import { apiExportDispenseHistory } from '~/api/services/pharmacy';
import { InprogressAlert } from '~/common/commonMethods';
import { ml10, mr15 } from '~/common/commonStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { Button } from '~/components/commonButton';
import { H2TtmSemiBoldBlack } from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { PharmacyDispenseHistoryTable } from '~/components/pharmacies/PharmacyDispenseHistoryTable';
import { SearchBox } from '~/components/searchBox';
import { SortIcon } from '~/components/svgImages';
import { PageLayout } from '~/layouts/PageLayout';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const PharmacyDispenseHistoryScreen: React.FC = () => {
  const currentPharmacy = useSelector(currentPharmacySelector);
  const { data, loading, pagination, onPageChanged, search, setSearch, setSortingDesc, sortDesc } =
    usePharmacyDispenseHistory();
  const [localSearch, setLocalSearch] = useState(search);

  const { isMobile } = useBreakpoints();

  const exportDispenseHistory = async () => {
    try {
      await apiExportDispenseHistory({ id: currentPharmacy.pharmacy.id });
      InprogressAlert(
        ['Exporting your dispense history details, this may take a while. We will notify you when it is done.'],
        {
          title: 'Exporting Dispense History',
        }
      );
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const searchComponent = (
    <SearchBox
      style={isMobile ? { flex: 1 } : { marginVertical: 0 }}
      onTextChange={setLocalSearch}
      value={localSearch}
      onSubmit={() => setSearch(localSearch)}
      placeholder="Search"
      keyboardType="search"
    />
  );

  return (
    <PageLayout
      footer={isMobile ? <Button funCallback={exportDispenseHistory} label="Export Data" /> : null}
      hideBack
      noHeaderPadding={!isMobile}
      header={
        isMobile ? (
          <View
            style={[
              {
                flex: 1,
              },
            ]}>
            <H2TtmSemiBoldBlack>Dispense History</H2TtmSemiBoldBlack>

            <View
              style={{
                flexDirection: 'row',
              }}>
              <IconButton
                transparent
                style={mr15}
                onPress={() => {
                  setSortingDesc(!sortDesc);
                }}>
                <SortIcon width={20} height={20} />
              </IconButton>
              {searchComponent}
            </View>
          </View>
        ) : (
          <View style={{ flex: 1, alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
            <H2TtmSemiBoldBlack style={{ minHeight: 45, marginBottom: 15 }}>Dispense History</H2TtmSemiBoldBlack>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <View>
                <IconButton onPress={exportDispenseHistory}>
                  <Feather name="download" size={20} color={colors.purple} />
                </IconButton>
              </View>
              <View style={ml10}>{searchComponent}</View>
            </View>
          </View>
        )
      }
      scrollContentNode={
        <PharmacyDispenseHistoryTable
          data={data}
          loading={loading}
          pagination={pagination}
          sortDesc={sortDesc}
          setSortingDesc={setSortingDesc}
          onPageChanged={onPageChanged}
        />
      }
    />
  );
};
