import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { DateType, TimeType } from '~/api/models/common/types/DateType';
import { FormErrorMessage, H6NsRegularBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { appointmentLabels } from '~/utils/labels/appointments';

interface TimeSlot {
  date?: DateType;
  from: TimeType;
  to: TimeType;
}

interface TimeSlots {
  [date: DateType]: TimeSlot[];
}

interface Props {
  slots: TimeSlots;
  selected: TimeSlot;
  loading: boolean;
  onChange: (value: TimeSlot) => void;
  errorMessage?: string;
}

export const AvailableSlotsSelection: React.FC<Props> = ({ slots, onChange, selected, errorMessage, loading }) => {
  const slotKeys = useMemo(() => (slots ? Object.keys(slots) : []), [slots]);

  return (
    <Grid grid={{ gutters: 20 }}>
      <Container>
        {!loading ? (
          slotKeys?.length ? (
            slotKeys?.map((key) => (
              <Row key={key} justifyContent="center">
                {slots[key]?.map((slot) => (
                  <Column span="auto" key={`${key}${slot.from}`}>
                    <TouchableOpacity onPress={() => onChange({ from: slot.from, to: slot.to, date: key })}>
                      <View
                        style={[styles.slotButton, slot.from === selected?.from ? styles.slotButtonSelected : null]}>
                        <H6NsRegularBlack>{slot.from}</H6NsRegularBlack>
                      </View>
                    </TouchableOpacity>
                  </Column>
                ))}
              </Row>
            ))
          ) : (
            <Row>
              <Column>
                <H6NsRegularBlack>{appointmentLabels.noAvailableScheduled}</H6NsRegularBlack>
              </Column>
            </Row>
          )
        ) : (
          <LoadingActivityIndicator />
        )}
        {errorMessage ? (
          <Row>
            <Column>
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </Column>
          </Row>
        ) : null}
      </Container>
    </Grid>
  );
};

const styles = StyleSheet.create({
  slotButton: {
    backgroundColor: colors.lightPurple,
    paddingHorizontal: 15,
    paddingVertical: 8,
    marginBottom: 15,
    borderRadius: 6,
    borderColor: colors.lightPurple,
    borderWidth: 2,
  },
  slotButtonSelected: {
    backgroundColor: colors.lightPurple2,
    borderColor: colors.info,
  },
});
