export const screenName = {
  Action: 'ActionHandler',
  OnboardingScreen: 'OnboardingScreen',
  RegistrationMobileOtp: 'OTPScreen_Mobile',
  RegistrationEmailOtp: 'OTPScreen_Email',
  ForgotPasscodeOtp: 'OTPScreen_ForgotPasscode',
  OTPScreen_2FA: 'OTPScreen_2FA',

  EmailScreen: 'EmailScreen',
  UploadIdentificationScreen: 'UploadIdentificationScreen',
  PersonalDetails: 'PersonalDetails',
  PassCodeRegisterScreen: 'PassCodeRegisterScreen',
  PassCodeLoginScreen: 'PassCodeLoginScreen',
  RegisterScreen: 'Register',
  ForgotPasscodeScreen: 'ForgotPasscodeScreen',
  RegisterTerms: 'TermsConditions',
  RegisterPharmacyDetails: 'RegisterPharmacyDetails',
  RegisterPharmacyLocations: 'RegisterPharmacyLocations',

  // Doctor Dashboard pages
  Dashboard: 'Dashboard',

  Requests: 'Requests',
  RequestsTab: 'Requests-Requests',
  Notifications: 'Requests-Notifications',
  DocumentsImport: 'Requests-DocumentsImport',
  DocumentsExport: 'Requests-DocumentsExport',
  ClinicPastRequests: 'Requests-PastRequests',

  CalendarPage: 'Calendar',
  CalendarAppointments: 'Calendar-Appointments',
  CalendarAppointmentsMain: 'Calendar-AppointmentsMain',
  CalendarAppointmentsShow: 'Calendar-AppointmentsShow',
  CalendarAppointmentsListView: 'Calendar-AppointmentsListView',
  CalendarAvailabilities: 'Calendar-Availabilities',
  AvailabilitiesScheduledAppointments: 'Calendar-AvailabilitiesScheduledAppointments',
  AvailabilitiesHomeVisit: 'Calendar-AvailabilitiesHomeVisit',
  AvailabilitiesDoctorsAvailableNow: 'Calendar-AvailabilitiesDoctorsAvailableNow',

  Appointments: 'Appointments',
  AppointmentsBooking: 'Appointments-Booking',
  AppointmentsShow: 'Appointments-Show',

  Doctors: 'Doctors',

  PatientManagement: 'PatientManagement',
  PatientDetails: 'PatientManagement-Details',
  PatientAddNew: 'PatientManagement-AddNew',
  PatientEditDetails: 'PatientManagement-Edit',
  PatientDiagnosis: 'PatientManagement-Diagnosis',
  Diagnoses: 'Diagnoses',
  PatientVersionDiagnosis: 'PatientVersionDiagnosis',
  PatientConsultation: 'PatientManagement-Consultation',
  PatientVersionConsultation: 'PatientVersionDiagnosis-Consultation',
  PatientConsultationPrivateNotes: 'PatientManagement-Consultation-PrivateNotes',
  PatientConsultationHealthRecord: 'PatientManagement-Consultation-HealthRecord',
  PatientConsultationPrescription: 'PatientManagement-Consultation-Prescription',
  PatientConsultationSickLeave: 'PatientManagement-Consultation-SickLeave',
  PatientConsultationReferral: 'PatientManagement-Consultation-Referral',
  PatientNewConsultation: 'PatientManagement-Consultation-Create',

  // for Doctor
  PatientHealthProfile: 'PatientManagement-HealthProfile',
  PatientHealthProfileEdit: 'PatientManagement-HealthProfileEdit',
  PatientHealthProfileHealthMetrics: 'PatientManagement-HP-HealthMetrics',
  PatientHealthProfileAllergies: 'PatientManagement-HP-Allergies',
  PatientHealthProfileMedical: 'PatientManagement-HP-Medical',
  PatientHealthProfileMedications: 'PatientManagement-HP-Medications',
  PatientHealthProfileProcedures: 'PatientManagement-HP-Procedures',
  PatientHealthProfileProsthesis: 'PatientManagement-HP-Prosthesis',
  PatientHealthProfileVaccinations: 'PatientManagement-HP-Vaccinations',
  PatientHealthProfileLifestyle: 'PatientManagement-HP-Lifestyle',

  //for Patient
  HealthProfile: 'HealthProfile',
  HealthProfileHealthMetrics: 'HealthProfile-HealthMetrics',
  HealthProfileAllergies: 'HealthProfile-Allergies',
  HealthProfileMedical: 'HealthProfile-Medical',
  HealthProfileMedications: 'HealthProfile-Medications',
  HealthProfileProcedures: 'HealthProfile-Procedures',
  HealthProfileProsthesis: 'HealthProfile-Prosthesis',
  HealthProfileVaccinations: 'HealthProfile-Vaccinations',
  HealthProfileLifestyle: 'HealthProfile-Lifestyle',

  Income: 'Income',

  Settings: 'Settings',
  SettingsConsultations: 'Settings-Consultations',
  SettingsConsultationCosts: 'Settings-ConsultationCosts',
  SettingsNotifications: 'Settings-Notifications',
  SettingsSecurity: 'Settings-Security',
  SettingsAccount: 'Settings-Account',
  SettingsExportData: 'SettingsExportData',
  PermanentlyDeleteAccount: 'SettingsPermanentlyDeleteAccount',

  SettingsClinic: 'ClinicSettings',
  SettingsClinicConsultations: 'ClinicSettings-Consultations',
  SettingsClinicPaymentDetails: 'ClinicSettings-PaymentDetails',
  SettingsClinicAddress: 'ClinicSettings-Address',

  ClinicConsultationRequest: 'Requests-ClinicConsultationRequest',

  Help: 'Help',
  HelpFaq: 'Help-Faq',
  HelpContactUs: 'Help-Contact-us',
  HelpCookiePolicy: 'Help-Cookie',
  HelpPrivacyPolicy: 'Help-Privacy',
  HelpTermsAndConditions: 'Help-Terms',
  HelpCancellationPolicy: 'Help-Cancellation',

  ProfileScreen: 'ProfileScreen',
  ProfilePersonalInformation: 'ProfilePersonalInformation',
  ProfileHomeAddress: 'ProfileHomeAddress',
  ProfilePaymentDetails: 'ProfilePaymentDetails',
  ProfileSubscriptionPlan: 'ProfileSubscriptionPlan',

  Dependants: 'Dependants',
  DependantsAddNew: 'Dependants-AddNew',
  DependantsShow: 'Dependants-Show',
  DependantPersonalInfo: 'Dependants-Show-Personal',
  DependantAddress: 'Dependants-Show-Address',
  DependantHealthProfile: 'Dependants-Show-HealthProfile',
  DependantLinkedAccounts: 'Dependants-Show-LinkedAccounts',

  LoginScreen: 'Login',
  HomeAddress: 'HomeAddress',
  DoctorVerification: 'DoctorVerification',
  RegistrationStepperScreen: 'RegistrationStepperScreen',
  UploadProfileScreen: 'UploadProfileScreen',
  TypeOfConsultationScreen: 'TypeOfConsultationScreen',
  PreferencesScreen: 'PreferencesScreen',
  CostPerConsultationType: 'CostPerConsultationType',
  ConsultationChannelPreferences: 'ConsultationChannelPreferences',
  AvailabilitiesScreen: 'AvailabilitiesScreen',
  HomeVisitPreferencesAvailabilitiesScreen: 'HomeVisitPreferencesAvailabilitiesScreen',
  DoctorOnDemandPreferencesAvailabilitiesScreen: 'DoctorOnDemandPreferencesAvailabilitiesScreen',
  ScheduledAppointmentsPreferencesAvailabilitiesScreen: 'ScheduledAppointmentsPreferencesAvailabilitiesScreen',
  HomeVisitNavigationMapScreen: 'HomeVisitNavigationMapScreen',
  VideoCallScreen: 'VideoCallScreen',
  TwoStepSelection: 'TwoStepSelection',
  ChatScreen: 'ChatScreen',
  AudioCallScreen: 'AudioCallScreen',
  AddBankDetails: 'AddBankDetails',

  PatientBillingHistory: 'PatientBillingHistory',

  PharmacySettings: 'PharmacySettings',
  PharmacyDetails: 'PharmacyDetails',
  PharmacyDetailsLocation: 'PharmacyDetails-Location',
  PharmacyDetailsAccount: 'PharmacyDetails-Account',
  PharmacyUserManagement: 'PharmacyManagement',

  PharmacyAwaitingCollection: 'AwaitingCollection',
  PharmacyDispenseHistory: 'DispenseHistory',
  PharmacyRequestHistory: 'RequestHistory',

  PrescriptionScreen: 'PrescriptionScreen',
  EducationCentre: 'EducationCentre',
  EducationArticle: 'EducationCentre-Article',

  Insurance: 'Insurance',
  InsuranceDetails: 'Insurance-Details',
  InsuranceClaims: 'Insurance-Claims',
  InsuranceClaimForm: 'Insurance-Claim-Form',
};
