import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PharmacyEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { privatePharmacyChannel } from '~/api/models/channel/enum/channelNames';
import { PrescriptionRequestStateEnum } from '~/api/models/consultations/enums/PrescriptionRequestStateEnum';
import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import { apiGetPrescriptionRequest } from '~/api/services/pharmacy';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { channelSubscribe, channelUnsubscribe } from '~/integrations/channels/PusherChannels';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { isPharmacyVersion } from '~/utils/buildConfig';

export function usePharmacyChannels(options?: { handler?: (event: string, data: any) => void }) {
  const [pharmacyId, setPharmacyId] = useState<number>();
  const { showSnackbar } = useSnackbarManager();
  const { openModal } = useModalManager();

  const currentPharmacy = useSelector(currentPharmacySelector);

  const showPrescriptionRequest = async (prescriptionRequestId: number) => {
    const response = await apiGetPrescriptionRequest({ prescriptionRequestId });
    openModal(ModalName.PrescriptionRequestApproval, {
      request: response.data,
    });
  };
  const eventHandler = (
    event: string,
    data: {
      prescription_request_id: number;
      prescription_id?: number;
      prescription_request_state?: PrescriptionRequestStateEnum;
      prescription_reference?: string;
      prescriptionRequest?: PrescriptionRequestModel;
    }
  ) => {
    switch (event) {
      case PharmacyEventsEnum.PHARMACY_PRESCRIPTION_PICKED_UP_FROM_DIFFERENT_PHARMACY:
        {
          const prescriptionReference = data.prescription_reference;
          const state =
            data?.prescription_request_state === PrescriptionRequestStateEnum.APPROVED ? 'Approved' : 'Pending';
          showSnackbar(`${state} prescription ${prescriptionReference} was picked up from a different pharmacy`, {
            keyRef: `${event}.${data.prescription_request_id}`,
          });
          AppEventHandler.emit(AppEvents.PHARMACY_REQUESTS_UPDATE);
        }
        break;
      case PharmacyEventsEnum.PHARMACY_PRESCRIPTION_REQUEST_CREATED:
        if (!data?.prescription_reference && !data.prescriptionRequest?.prescription?.reference_number) return;
        showSnackbar(
          `You have a new prescription request: ${
            data?.prescription_reference || data.prescriptionRequest?.prescription?.reference_number
          }`,
          {
            onPress: () => {
              showPrescriptionRequest(data.prescription_request_id ?? data.prescriptionRequest?.id);
            },
            keyRef: `${event}.${data.prescription_request_id}`,
          }
        );

        AppEventHandler.emit(AppEvents.PHARMACY_REQUESTS_UPDATE);
        break;
    }
  };

  const unsubscribe = (pharmacyId: number) => {
    channelUnsubscribe(privatePharmacyChannel(pharmacyId), options?.handler || eventHandler).catch(() => {});
  };

  const subscribe = (pharmacyId: number) => {
    setPharmacyId(pharmacyId);
    channelSubscribe(privatePharmacyChannel(pharmacyId), options?.handler || eventHandler);
  };

  useEffect(() => {
    if (!isPharmacyVersion()) return;
    if (pharmacyId === currentPharmacy?.pharmacy.id) return;
    if (pharmacyId) {
      unsubscribe(pharmacyId);
    }
    if (currentPharmacy?.pharmacy.id) {
      subscribe(currentPharmacy?.pharmacy.id);
    }
    return () => {
      unsubscribe(pharmacyId);
    };
  }, [currentPharmacy?.pharmacy?.id]);
}
