import React from 'react';
import { Animated, Dimensions, StyleSheet } from 'react-native';

import { AnimatedCircle } from './AnimatedCircle';

import { colors } from '~/utils/colors';
import useColorAnimation from '~/utils/hooks/useColorAnimation';

interface Props {
  width: number;
  backgroundColor?: string;
}

const screenDimensions = Dimensions.get('screen');

export const OnBoardingBalls: React.FC<Props> = ({ width, backgroundColor }) => {
  const height = screenDimensions.height;

  const { animColor } = useColorAnimation(backgroundColor ?? colors.lightPurple);

  return (
    <Animated.View style={[styles.container, { backgroundColor: animColor }]}>
      <>
        <AnimatedCircle
          size={20}
          diameter={width}
          color={colors.info + '99'}
          position={{
            right: 0,
            top: 0,
          }}
        />
        <AnimatedCircle
          size={width * 0.4}
          diameter={width * 0.4}
          color={colors.lightPurple3}
          position={{
            left: 0,
            top: 0,
          }}
          disabled
        />
        <AnimatedCircle
          size={width * 0.5}
          diameter={width * 0.54}
          color={colors.lightTurquoise}
          position={{
            left: 0,
            top: height * 0.3,
          }}
        />
        <AnimatedCircle
          size={width * 0.5}
          diameter={width * 0.55}
          color={colors.lightBlue}
          position={{ right: 0, top: height * 0.05 }}
        />
      </>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: colors.lightPurple,
    overflow: 'hidden',
  },
});
