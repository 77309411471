import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { RequestConsultationDetails } from '../RequestConsultationDetails';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { OnDemandDoctorQueueModel } from '~/api/models/consultations/models/OnDemandDoctorQueueModel';
import { apiDoctorWaitingRoomStatus } from '~/api/services/appointments';
import { alignSelfCenter, mb20 } from '~/common/commonStyles';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';

interface Props {
  consultation: ConsultationModel;
}

export const OnDemandApprovalRequestDetails: React.FC<Props> = ({ consultation }) => {
  const [queueDetails, setQueueDetails] = useState<OnDemandDoctorQueueModel[]>();

  useEffect(() => {
    apiDoctorWaitingRoomStatus()
      .then((res) => setQueueDetails(res.data))
      .catch(() => setQueueDetails(null));
  }, []);

  const extraDetails = useMemo(() => {
    if (!queueDetails?.length) return;
    return [
      { label: 'Queue length', value: `${queueDetails[0].queue}` },
      {
        label: 'Current estimated end time',
        value:
          queueDetails[0].queue > 0
            ? parseDateTime(queueDetails[0].estimated, { outputFormat: TIME_FORMAT })
            : undefined,
      },
    ];
  }, [queueDetails]);

  return (
    <View>
      <View style={[alignSelfCenter, mb20]}>
        <H3TtmSemiBoldBlack>Doctor On-Demand Request</H3TtmSemiBoldBlack>
      </View>
      <RequestConsultationDetails consultation={consultation} extraDetails={extraDetails} />
    </View>
  );
};
