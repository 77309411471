import { addEventListener, parse, getInitialURL } from 'expo-linking';
import { useEffect, useRef } from 'react';
import { EmitterSubscription, Platform } from 'react-native';
import { useSelector } from 'react-redux';

import { SET_DEEP_LINK, selectDeepLink } from '~/redux/reducers/appDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { isWeb } from '~/utils/buildConfig';

export function useAuthDeepLink() {
  const dispatch = useAppDispatch();
  const subscriptionRef = useRef<EmitterSubscription>();

  const deepLink = useSelector(selectDeepLink);

  const setDeepLink = (link: string) => {
    dispatch(SET_DEEP_LINK(link));
  };

  const allowDeepLinkOnLogin = (path: string) => {
    if (path.indexOf('action/clinic-admin') >= 0) {
      // checking 1 to allow for a / prefix
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!deepLink) {
      Platform.select({
        web: () => {
          subscriptionRef.current = addEventListener('url', handleDeepLink);
        },
        native: () => {
          getInitialURL().then((e) => setDeepLink(e));
        },
      })();
    }

    return () => {
      subscriptionRef.current?.remove();
    };
  }, []);

  const handleDeepLink = async (event) => {
    const { url } = event;

    if (isWeb()) {
      subscriptionRef.current?.remove();
      subscriptionRef.current = undefined;
    }

    if (!url) return;

    const { path: parsedPath } = parse(url);
    if (!parsedPath) return;

    const path = `/${parsedPath}`;

    if (!allowDeepLinkOnLogin(path)) return;

    setDeepLink(path);
  };

  return { deepLink };
}

export function useAuthDeepLinkClear() {
  const dispatch = useAppDispatch();
  const removeDeepLink = async () => {
    dispatch(SET_DEEP_LINK(undefined));
  };

  return { removeDeepLink };
}
