import { get, post, put } from '~/api/axios';
import { IdModel } from '~/api/models/common/models/IdModel';
import { AddDependantRequest } from '~/api/models/dependants/requests/AddDependantRequest';
import { DependantsIndexRequest } from '~/api/models/dependants/requests/DependantsIndexRequest';
import { DependantsMediaProofRequest } from '~/api/models/dependants/requests/DependantsMediaProofRequest';
import { DependantsRelationsRequest } from '~/api/models/dependants/requests/DependantsRelationsRequest';
import { EditDependentRequest } from '~/api/models/dependants/requests/EditDependentRequest';
import { AddDependantResponse } from '~/api/models/dependants/responses/AddDependantResponse';
import { DependantRelationsResponse } from '~/api/models/dependants/responses/DependantRelationsResponse';
import { DependantRelationshipTypesResponse } from '~/api/models/dependants/responses/DependantRelationshipTypesResponse';
import { DependantShowResponse } from '~/api/models/dependants/responses/DependantShowResponse';
import {
  DependantsIndexResponse,
  DependantsUnlimitedResponse,
} from '~/api/models/dependants/responses/DependantsIndexResponse';
import fileSystem from '~/integrations/fileSystem';

export const apiGetDependantRelationships = () => {
  return get<DependantRelationshipTypesResponse>('reference-data/dependant-relation-types');
};

export function apiGetDependants<T = DependantsIndexResponse | DependantsUnlimitedResponse>(
  req: DependantsIndexRequest
) {
  return get<T>('/patient/dependants', req);
}

export const apiShowDependant = (req: IdModel) => {
  return get<DependantShowResponse>(`/patient/dependants/${req.id}`);
};

export const apiShowDependantRelations = (req: DependantsRelationsRequest) => {
  return get<DependantRelationsResponse>(`/patient/dependants/${req.id}/relations`, req.params);
};

export const apiAddDependant = (params: AddDependantRequest) => {
  return post<AddDependantResponse>('/patient/dependants', params);
};

export const apiAddDependantMedia = (req: DependantsMediaProofRequest) => {
  return fileSystem.postDocumentResult(`/patient/dependants/${req.id}/proof-media`, 'images[]', req.document);
};

export const apiEditDependant = (params: { id: number; data: EditDependentRequest }) => {
  return put(`patient/dependants/${params.id}`, params.data);
};

export const apiDependantDeclarationDownload = () => {
  return fileSystem.downloadStream(`/downloads/dependants-declaration`, { fileName: `dependants_declaration.pdf` });
};
