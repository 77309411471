import React from 'react';

import { OnBoardingContentPlaceholder } from '../onBoardingContentPlaceholder';

import { onboardingLabels } from '~/utils/labels/onboardingLabels';

interface Props {
  width: number;
}
export const OnBoardingDoctorPage3: React.FC<Props> = ({ width }) => {
  return (
    <OnBoardingContentPlaceholder
      width={width}
      right
      noMask
      title={onboardingLabels.doctor[2].title}
      subtitle={onboardingLabels.doctor[2].subTitle}
      image={require('~/../assets/img/onboarding/doctor_3.png')}
    />
  );
};
