import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { isClinicVersion } from '~/utils/buildConfig';

export const useHandleAppointmentStarted = () => {
  const validate = () => {
    return isClinicVersion();
  };

  const emitEvent = (consultationId?: number) => {
    AppEventHandler.emit(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, [consultationId]);
  };

  const handleNotification = async (consultationId: number) => {
    if (!validate()) return;

    emitEvent(consultationId);
  };

  return { handleNotification };
};
