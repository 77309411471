import { Link } from '@react-navigation/native';
import React from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';

import { mr10, textCenter } from '../../common/commonStyles';
import { Button } from '../../components/commonButton';
import { H6NsRegularSecondaryBlack, H6TtmSemiBoldTheme } from '../../components/commonText';
import { GeneralProps } from '../../interfaces/generalProps';
import { UPDATE_LOGIN_DETAILS } from '../../redux/reducers/userDetailsReducer';
import { useAppDispatch } from '../../redux/store';
import { labels } from '../../utils/labels';
import { screenName } from '../../utils/screenName';

import { useLoginDetails } from '~/api/hooks/accounts/LoginDetails';
import { MaltaPhoneCode } from '~/api/hooks/referenceData/constants/CountryConstants';
import { PhoneNumberInputComponent } from '~/components/registration/PhoneNumberInputComponent';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { registrationLabels } from '~/utils/labels/registrationLabels';

const LoginPhoneNumberScreen: React.FC<GeneralProps> = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const { details } = useLoginDetails();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid },
  } = useForm<{
    country_code?: string;
    mobile_number?: string;
  }>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      country_code: MaltaPhoneCode,
      mobile_number: '',
    },
  });
  const onSubmit = () => {
    const loginMobileValues = getValues();
    const loginDetails = {
      country_code: loginMobileValues.country_code,
      number: loginMobileValues.mobile_number,
    };

    if (details?.country_code !== loginDetails.country_code || details.number !== loginDetails.number) {
      dispatch(UPDATE_LOGIN_DETAILS(loginDetails));
    }

    setTimeout(() => navigation.navigate(screenName.PassCodeLoginScreen));
  };

  const buttons = [
    <Button funCallback={handleSubmit(onSubmit)} disabled={!isValid} label={labels.signIn} />,
    <View style={[{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}>
      <H6NsRegularSecondaryBlack style={[mr10, textCenter]}>
        {`${labels.dontHaveAnAccount} `}
        <Link to={{ screen: screenName.RegisterScreen } as never}>
          <H6TtmSemiBoldTheme>{labels.register.toUpperCase()}</H6TtmSemiBoldTheme>
        </Link>
      </H6NsRegularSecondaryBlack>
    </View>,
  ];
  return (
    <EnhancedRegistrationLayout
      compressed
      hideProgress
      title={registrationLabels.loginTitle}
      subtitle={registrationLabels.loginSubtitleMobile}
      buttons={buttons}
      hideRequired>
      <PhoneNumberInputComponent style={{ width: '100%', alignItems: 'flex-start' }} control={control} />
    </EnhancedRegistrationLayout>
  );
};

export default LoginPhoneNumberScreen;
