import { deleteReq, get, post, put } from '../../axios';
import { PrescriptionRequest } from '../../models/consultations/requests/PrescriptionRequest';
import { PrescriptionResponse } from '../../models/consultations/responses/PrescriptionResponse';
import { PrescriptionIndexResponse } from '../../models/consultations/responses/PrescriptionsIndexResponse';

import { GetMediaCollectionResponse } from '~/api/models/common/responses/GetMediaCollectionResponse';
import { DeletePrescriptionsRequest } from '~/api/models/consultations/requests/DeletePrescriptionsRequest';
import { GetPrescriptionMediaRequest } from '~/api/models/consultations/requests/GetPrescriptionMediaRequest';
import { GetPrescriptionRequest } from '~/api/models/consultations/requests/GetPrescriptionRequest';
import { PrescriptionsIndexRequest } from '~/api/models/consultations/requests/PrescriptionsIndexRequest';
import { UpdatePrescriptionRequest } from '~/api/models/consultations/requests/UpdatePrescriptionRequest';
import {
  PharmacyPrescriptionCollectionsResponse,
  PatientPrescriptionCollectionsResponse,
} from '~/api/models/pharmacy/responses/PrescriptionCollectionsResponse';

export const apiGetPrescriptionIndex = (params?: PrescriptionsIndexRequest) => {
  return get<PrescriptionIndexResponse>('/prescriptions', params);
};

export const apiGetPrescription = (params: GetPrescriptionRequest) => {
  return get<PrescriptionResponse>(`/prescriptions/${params.prescriptionId}`);
};

export const apiAddPrescription = (params: PrescriptionRequest) => {
  return post<PrescriptionResponse>('/prescriptions', params);
};

export const apiDeletePrescription = (req: DeletePrescriptionsRequest) => {
  return deleteReq(`/prescriptions/${req.id}`);
};

export const apiUpdatePrescription = (req: UpdatePrescriptionRequest) => {
  return put<PrescriptionResponse>(`/prescriptions/${req.id}`, req.params);
};

export const apiGetPrescriptionMedia = (req: GetPrescriptionMediaRequest) => {
  return get<GetMediaCollectionResponse>(`/prescriptions/${req.id}/media/download`);
};

export const apiPatientGetPrescriptionCollections = (prescriptionId: number) => {
  return get<PatientPrescriptionCollectionsResponse>(`/prescription-collection/${prescriptionId}`);
};

export const apiPharmacyGetPrescriptionCollections = (prescriptionId: number, pharmacyId: number) => {
  return get<PharmacyPrescriptionCollectionsResponse>(`/prescription-collection/${prescriptionId}/${pharmacyId}`);
};
