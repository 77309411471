import { useEffect, useState } from 'react';

import { InsuranceCompany } from '~/api/models/insurance/responses/InsuranceCompaniesResponse';
import { apiGetInsuranceCompanies } from '~/api/services/insurance';
import { isPatientVersion } from '~/utils/buildConfig';

export function useInsuranceCompanyNames() {
  const [insuranceCompanies, setInsuranceCompanies] = useState<InsuranceCompany[]>([]);

  useEffect(() => {
    if (isPatientVersion() && insuranceCompanies?.length === 0) {
      apiGetInsuranceCompanies().then((res) => {
        setInsuranceCompanies(res.data.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)));
      });
    }
  }, [insuranceCompanies]);

  return { insuranceCompanies };
}
