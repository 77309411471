import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { connect } from 'react-redux';

import { MobileFooterMenuList } from './MobileFooterMenuList';
import { MobileMenuModal } from './MobileMenuModal';

import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { RootState } from '~/redux/reducers';
import { useKeyboard } from '~/utils/hooks/KeyboardHook';
import { colors } from '~/utils/colors';

const MENU_HEIGHT = 60;
const ANIMATION_TIME = 150;
const mapStateToProps = (state: RootState) => ({
  hideMobileMenu: state.overLayReducer.hideMobileMenu,
});

interface Props {
  hideMobileMenu?: boolean;
}
const Footer = ({ hideMobileMenu }: Props) => {
  const { openModal, registerModal, closeModalByName } = useModalManager();
  const { bottom } = useSafeAreaInsets();
  const { showing: showingKeyboard } = useKeyboard();
  const heightAnim = useRef(new Animated.Value(MENU_HEIGHT)).current;
  const totalMenuHeight = MENU_HEIGHT + bottom;

  useEffect(() => {
    registerModal(ModalName.MobileMenu, MobileMenuModal);
  }, []);

  useEffect(() => {
    if (hideMobileMenu || showingKeyboard) {
      Animated.timing(heightAnim, {
        toValue: 0,
        duration: ANIMATION_TIME,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(heightAnim, {
        toValue: totalMenuHeight,
        duration: ANIMATION_TIME,
        useNativeDriver: false,
      }).start();
    }
  }, [heightAnim, hideMobileMenu, showingKeyboard]);

  return (
    <Animated.View style={[styles.container, { height: heightAnim }]}>
      <View style={[styles.absolute, { height: totalMenuHeight }]}>
        <MobileFooterMenuList
          showSideMenu={false}
          setSideMenu={(show) => {
            if (show) openModal(ModalName.MobileMenu);
            else closeModalByName(ModalName.MobileMenu);
          }}
        />
      </View>
    </Animated.View>
  );
};

//make this component available to the app
export default connect(mapStateToProps)(Footer);

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: colors.white,
  },
  absolute: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },
});
