import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getParsedAsyncItem, storageKeys } from '../../../common/asyncStorage';
import { UserLoginDetailsModel } from '../../../redux/models/userDetails/UserLoginDetailsModel';
import { RootState } from '../../../redux/reducers';
import { UPDATE_CACHED_LOGIN_DETAILS } from '../../../redux/reducers/userDetailsReducer';
import { useAppDispatch } from '../../../redux/store';

export function useFetchLoginDetails() {
  const [loading, setLoading] = useState(false);

  const loginDetails = useSelector((state: RootState) => state.userDetailsReducer.loginDetails);
  const dispatch = useAppDispatch();

  const getLoginDetails = async (): Promise<UserLoginDetailsModel | undefined> => {
    setLoading(true);
    try {
      if (loginDetails) {
        return Promise.resolve(loginDetails);
      }
      const data = await getParsedAsyncItem<UserLoginDetailsModel>(storageKeys.loginDetails);
      dispatch(UPDATE_CACHED_LOGIN_DETAILS(data));
      setLoading(false);
      return Promise.resolve(data);
    } catch {
      setLoading(false);
      return Promise.resolve(undefined);
    }
  };

  return { details: loginDetails, loading, getLoginDetails };
}

export function useLoginDetails() {
  const fetchLoginDetails = useFetchLoginDetails();

  useEffect(() => {
    fetchLoginDetails.getLoginDetails();
  }, []);

  return { ...fetchLoginDetails };
}
