import { useEffect } from 'react';

import { privateConsultationIdChannel } from '~/api/models/channel/enum/channelNames';
import { channelSubscribe } from '~/integrations/channels/PusherChannels';

interface Props {
  eventHandler: (e: string, data: any) => void;
  consultationID?: number;
}

export function useConsultationIdChannel(options: Props) {
  const subscribe = async () => {
    const res = await channelSubscribe(privateConsultationIdChannel(options.consultationID), options.eventHandler);
    return () => {
      res.unsubscribe();
    };
  };

  useEffect(() => {
    if (!options.consultationID) return;

    subscribe();
  }, [options.consultationID]);
}
