import { deleteReq, get, put } from '~/api/axios';
import { IdModel } from '~/api/models/common/models/IdModel';
import { UpdateOnDemandRequest } from '~/api/models/preferences/requests/UpdateOnDemandRequest';
import { OnDemandAvailabilityResponse } from '~/api/models/preferences/responses/OnDemandAvailabilityResponse';
import { appTypeApiPrefix } from '~/utils/buildConfig';

export const apiUpdateOnDemand = (req: UpdateOnDemandRequest) => {
  return put<OnDemandAvailabilityResponse>(`/${appTypeApiPrefix()}/availabilities/on-demand`, req);
};

export const apiGetOnDemandIndex = () => {
  return get<OnDemandAvailabilityResponse>(`/${appTypeApiPrefix()}/availabilities/on-demand`);
};

export const apiDeleteOnDemand = (req: IdModel) => {
  return deleteReq(`/${appTypeApiPrefix()}/availabilities/on-demand/${req.id}`);
};
