import { useState } from 'react';

import { IdNameModel } from '../models/common/models/IdNameModel';
import { SpecialisationIndexFilter } from '../models/consultations/filters/SpecialisationIndexFilter';
import { apiGetSpecialisations } from '../services/accounts/specialisation';

export function useSpecialisationArea() {
  const [loading, setLoading] = useState(false);
  const [listOfAreaOfSpecialisation, setAreaOfSpecialisation] = useState<IdNameModel[]>([]);

  const searchAllSpecialisations = (filter?: SpecialisationIndexFilter) => {
    return apiGetSpecialisations({ filter })
      .then((response) => {
        setAreaOfSpecialisation(response.data.data);
        setLoading(false);
      })
      .catch(() => {
        setAreaOfSpecialisation([]);
        setLoading(false);
      });
  };

  const searchSpecialisation = (filter: SpecialisationIndexFilter) => {
    if (!filter.name?.length) {
      setAreaOfSpecialisation([]);
      return;
    }
    setLoading(true);
    return searchAllSpecialisations(filter);
  };

  return { listOfAreaOfSpecialisation, searchSpecialisation, loading };
}
