import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { PharmacyModel } from '~/api/models/pharmacy/models/PharmacyModel';
import { apiCreatePrescriptionRequest, apiPatientListPharmacies } from '~/api/services/pharmacy';
import { mb10 } from '~/common/commonStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { requiredValidation } from '~/services/validationConfig';
import { Column, Container, Row } from '~/theme/components/grid';
import { isPatientVersion } from '~/utils/buildConfig';
import { getPharmacyString } from '~/utils/prescriptionUtil';

export interface PrescriptionRequestCreationRef {
  submit: () => Promise<boolean>;
}
interface Props {
  prescription: PrescriptionModel;
}

export const PrescriptionRequestCreationComponent = forwardRef<any, Props>(({ prescription }, ref) => {
  const [pharmacies, setPharmacies] = useState<PharmacyModel[]>();

  useEffect(() => {
    if (!isPatientVersion()) return;
    apiPatientListPharmacies()
      .then((res) => setPharmacies(res.data))
      .catch(() => {});
  }, []);

  const mappedPharmacies = useMemo(() => {
    const mapped =
      pharmacies?.map((pharmacy) => ({
        value: pharmacy.id,
        label: getPharmacyString(pharmacy),
      })) ?? [];

    return [...mapped, { value: 0, label: 'Pharmacy not listed' }]; // TODO: set to 0
  }, [pharmacies]);

  const { handleSubmit, control } = useForm<{ pharmacy_id: number }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      pharmacy_id: undefined,
    },
  });

  const onSubmit = async (data: { pharmacy_id: number }) => {
    if (data.pharmacy_id === 0) return;
    await apiCreatePrescriptionRequest({
      pharmacy_id: data.pharmacy_id,
      prescription_id: prescription.id,
    });

    return true;
  };

  const submit = async () => {
    return new Promise<boolean>((resolve, reject) => {
      handleSubmit(
        (data) => {
          onSubmit(data)
            .then((res) => resolve(res))
            .catch(reject);
        },
        () => resolve(undefined)
      )();
    });
  };

  useImperativeHandle(ref, () => ({
    submit,
  }));

  return (
    <Container>
      <Row style={mb10}>
        <Column>
          <H6NsRegularBlack>Choose from where you would like to collect your prescription.</H6NsRegularBlack>
        </Column>
      </Row>
      <Row>
        <Column>
          <Controller
            name="pharmacy_id"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <Dropdown
                list={mappedPharmacies}
                value={value}
                setValue={onChange}
                label="Select pharmacy"
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
              />
            )}
            rules={{
              required: requiredValidation('Pharmacy'),
            }}
          />
        </Column>
      </Row>
    </Container>
  );
});
