import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { DiagnosisListCard } from './DiagnosisListCard';
import { DashboardCard } from '../../common/DashboardCard';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { AccountSelectionDropdown } from '~/components/accounts/AccountSelectionDropdown';
import { screenName } from '~/utils/screenName';

interface Props {}

export const DiagnosisList: React.FC<Props> = () => {
  const { userDetails } = useUserDetails({ allowStale: true });
  const [currentAccount, setCurrentAccount] = useState<AccountModel>();

  useEffect(() => {
    if (userDetails.account.id && !currentAccount) {
      setCurrentAccount(userDetails.account);
    }
  }, [userDetails?.account?.id]);

  return (
    <View style={{ flex: 1 }}>
      <DashboardCard title="Diagnosis" viewAllLink={screenName.Diagnoses} style={{ flex: 1 }}>
        <AccountSelectionDropdown currentAccount={currentAccount} setCurrentAccount={setCurrentAccount} />
        <View style={{ flex: 1, marginTop: 9 }}>
          <DiagnosisListCard patientId={currentAccount?.id} />
        </View>
      </DashboardCard>
    </View>
  );
};
