export enum FeatureAccessEnum {
  HEALTH_COMPANION = 'health-companion',
  FAMILY_GROUP = 'family-group',
  INSURANCE = 'insurance',
  HOME_VISITS = 'home-visits',
  INCOME = 'income',
  IBAN = 'iban',
  CONSULTATION_TYPES = 'consultation-types',
  CONSULTATION_PRICES = 'consultation-prices',
  CONSULTATION_CHANNELS = 'consultation-channels',
}

export const PatientFeatures = [
  FeatureAccessEnum.HEALTH_COMPANION,
  FeatureAccessEnum.FAMILY_GROUP,
  FeatureAccessEnum.INSURANCE,
  FeatureAccessEnum.HOME_VISITS,
];

export const DoctorFeatures = [
  FeatureAccessEnum.INCOME,
  FeatureAccessEnum.IBAN,
  FeatureAccessEnum.CONSULTATION_TYPES,
  FeatureAccessEnum.CONSULTATION_PRICES,
  FeatureAccessEnum.CONSULTATION_CHANNELS,
];
