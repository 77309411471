import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { DashboardCard } from '../../common/DashboardCard';

import { H4TtwRegularSemiBold } from '~/components/commonText';
import { Calendar } from '~/components/svgImages';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const AvailabilitiesButton: React.FC = (): JSX.Element => {
  const { navigate } = useNavigation<NavType>();
  const onPress = () => {
    navigate(screenName.CalendarPage, { screen: screenName.CalendarAvailabilities });
  };

  return (
    <DashboardCard white style={[styles.dashboardCard]} onPress={onPress}>
      <View style={styles.rowStyle}>
        <View style={styles.iconContainer}>
          <Calendar width={27} height={27} color={colors.info} />
        </View>
        <View style={styles.textContainer}>
          <H4TtwRegularSemiBold style={styles.title}>{labels.availabilities}</H4TtwRegularSemiBold>
        </View>
      </View>
    </DashboardCard>
  );
};

const styles = StyleSheet.create({
  card: {
    maxHeight: 60,
  },
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
  },
  dashboardCard: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
    maxHeight: 60,
  },
  title: {
    marginLeft: 10,
    color: colors.darkPurple,
    lineHeight: '105%',
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
  },
});
