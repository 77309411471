import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ExtraSmallNsRegularInfo, ExtraSmallNsRegularTheme } from '~/components/commonText';
import { useMenusNative } from '~/constants/menus/menuNative';
import { useStoredRoute } from '~/redux/hooks/StoredRoute';
import { colors } from '~/utils/colors';
import { DigimedMilestones, digimedMilestoneEnabled } from '~/utils/milestoneUtil';

interface Props {
  showSideMenu: boolean;
  setSideMenu: CallableFunction;
  inModal?: boolean;
}
export const MobileFooterMenuList = (props: Props) => {
  const navigation = useNavigation();
  const { route } = useStoredRoute();
  const { menu } = useMenusNative();

  const safeArea = useSafeAreaInsets();

  return (
    <View
      style={[
        {
          backgroundColor: 'white',
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        },
      ]}>
      <View style={[styles.container, styles.containerBorder]}>
        {menu?.map((item, index) => {
          const active = route && route.name.indexOf(item.screenName) === 0;

          return (
            <TouchableOpacity
              key={index}
              onPress={() => {
                if (item.key === 'menu') {
                  props.setSideMenu(!props.showSideMenu);
                } else {
                  props.setSideMenu(false);
                  if (item.screenName) {
                    navigation.navigate(item.screenName as never);
                  }
                }
              }}>
              {active ? (
                <View style={[styles.footerView, styles.selectedFooterView]}>
                  {item.image(colors.purple)}
                  <ExtraSmallNsRegularTheme style={{ marginLeft: 8 }}>{item.label}</ExtraSmallNsRegularTheme>
                </View>
              ) : (
                <View style={styles.footerView}>
                  {item.image(colors.info)}
                  {item.label && !digimedMilestoneEnabled(DigimedMilestones.DoctorPatientApps) ? (
                    <ExtraSmallNsRegularInfo style={{ marginLeft: 8 }}>{item.label}</ExtraSmallNsRegularInfo>
                  ) : null}
                </View>
              )}
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

// define your styles
const styles = StyleSheet.create({
  container: {
    zIndex: 999,
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    // backgroundColor: colors.lightPurple,
    paddingHorizontal: 30,

    borderTopWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: colors.lightPurple2,
    // borderRadius: 6,
    elevation: 3,
  },
  containerBorder: {
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
  },
  footerView: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedFooterView: {
    backgroundColor: colors.lightPurple,
    borderRadius: 8,
  },
});
