import { get, post } from '~/api/axios';
import { ExportTypesEnum } from '~/api/models/common/constants/ExportTypesEnum';
import { IdModel } from '~/api/models/common/models/IdModel';
import { ExportPatientDataIndexRequest } from '~/api/models/patientData/requests/ExportPatientDataIndexRequest';
import { ExportPatientDataIndexResponse } from '~/api/models/patientData/responses/ExportPatientDataIndexResponse';
import { ExportPatientGetResponse } from '~/api/models/patientData/responses/ExportPatientGetResponse';

export const apiExportData = (type: ExportTypesEnum) => {
  return post('/exports', { type });
};

export const apiGetExportDataIndex = (req: ExportPatientDataIndexRequest) => {
  return get<ExportPatientDataIndexResponse>('/exports', req);
};

export const apiGetSpecificExportPatientData = (params: IdModel) => {
  return get<ExportPatientGetResponse>(`/exports/${params.id}`);
};
