import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { HomeVisitAvailabilityEditMode } from './HomeVisitAvailabilityEditMode';
import { HomeVisitCard } from './HomeVisitCard';
import { mb5, mt25 } from '../../../common/commonStyles';
import { OutlineButton } from '../../commonButton';
import { H6NsRegularBlack } from '../../commonText';
import { LoadingActivityIndicator } from '../../loading/LoadingActivityIndicator';

import { useHomeVisitsAvailability } from '~/api/hooks/preferences/HomeVisitsAvailabilityHook';
import { AvailabilitiesPageStateEnum } from '~/api/models/preferences/enums/AvailabilitiesPageStateEnum';
import { HomeVisitFormModel } from '~/api/models/preferences/models/HomeVisitModel';
import { HomeVisitWithIdModel } from '~/api/models/preferences/models/HomeVisitWithIdModel';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { DaysOfTheWeek } from '~/constants/daysOfTheWeek';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, FULL_DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { doctorLabels } from '~/utils/labels/doctor';

interface Props {
  isRegistration?: boolean;
  pageState?: AvailabilitiesPageStateEnum;
  setPageState: React.Dispatch<React.SetStateAction<AvailabilitiesPageStateEnum>>;
  disabled?: boolean;
}

const shortDaysToFullDays = (days: string[]) => {
  return days?.map((day) => DaysOfTheWeek.find((arrayDay) => arrayDay.value === day).label).join(', ') || '';
};

export const HomeVisitAvailabilities: React.FC<Props> = ({ isRegistration, pageState, disabled, setPageState }) => {
  const [mappedHomeVisits, setMappedHomeVisits] = useState([]);
  const [editingHomeVisit, setEditHomeVisit] = useState<HomeVisitWithIdModel>(null);
  const editModeRef = useRef(null);
  const { homeVisits, removeHomeVisit, saveHomeVisit, loading } = useHomeVisitsAvailability({ immediate: true });

  useEffect(() => {
    if (!loading && homeVisits?.length === 0) {
      setPageState(AvailabilitiesPageStateEnum.CREATE);
    }
  }, [homeVisits, loading]);

  useEffect(() => {
    const mapping = homeVisits?.map((element: HomeVisitWithIdModel) => {
      console.log('El:', element);
      return {
        id: element.id,
        labelValuePairs: [
          { label: 'Duration', value: `${element.slot_duration_in_minutes} minutes` },
          {
            label: 'Start Date',
            value: parseDateTime(element.starts_on, { parseFormat: DATE_FORMAT, outputFormat: FULL_DATE_FORMAT }),
          },
          {
            label: 'Time',
            value: `${element.starts_at} to ${element.ends_at}`,
          },
          { label: 'Repeat Availability', value: shortDaysToFullDays(element.recurring_pattern_data.days_of_week) },
          { label: 'Address', value: element.formatted_address },
          { label: 'Distance', value: `${element.max_distance} km` },
        ],
        data: element,
      };
    });
    setMappedHomeVisits(mapping);
  }, [homeVisits]);

  const startEditing = (homeVisit: HomeVisitWithIdModel | null) => {
    setEditHomeVisit(homeVisit);
    setPageState(AvailabilitiesPageStateEnum.CREATE);

    setTimeout(() => {
      if (editModeRef?.current?.scrollIntoView) {
        editModeRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    });
  };
  return (
    <Grid grid={{ noOuterGutter: true, gutters: 12 }}>
      <Container alignSelf="flex-start" maxContainerWidths="100%">
        <Row style={styles.table}>
          <Column>
            {mappedHomeVisits && !loading ? (
              <>
                {mappedHomeVisits.length > 0 ? (
                  <ScrollView horizontal bounces={false} bouncesZoom={false} contentContainerStyle={mb5}>
                    {mappedHomeVisits?.map((element) => {
                      return (
                        <HomeVisitCard
                          id={element.id}
                          items={element.labelValuePairs}
                          itemData={element.data}
                          key={element.id}
                          removeHomeVisit={removeHomeVisit}
                          updateHomeVisit={startEditing}
                          disabled={disabled}
                        />
                      );
                    })}
                  </ScrollView>
                ) : (
                  <H6NsRegularBlack>{doctorLabels.availabilities.setHomeVisits}</H6NsRegularBlack>
                )}
              </>
            ) : (
              <View style={styles.loading}>
                <LoadingActivityIndicator />
              </View>
            )}
          </Column>
        </Row>
      </Container>
      <Container alignSelf="flex-start" maxContainerWidths="100%">
        {loading || disabled ? null : pageState === AvailabilitiesPageStateEnum.VIEW ? (
          <Row style={mt25}>
            <Column span={isRegistration ? 12 : { xs: 12, md: 6 }}>
              <OutlineButton
                funCallback={() => startEditing(null)}
                label={labels.addNewSchedule}
                style={{ maxWidth: 200 }}
              />
            </Column>
          </Row>
        ) : (
          <View ref={editModeRef}>
            <HomeVisitAvailabilityEditMode
              isSmallScreen={isRegistration}
              hideTitle
              homeVisit={editingHomeVisit}
              onSave={(data: HomeVisitFormModel) => {
                saveHomeVisit(data)
                  .then(() => {
                    setEditHomeVisit(null);
                    setPageState(AvailabilitiesPageStateEnum.VIEW);
                  })
                  .catch(ErrorAlert);
              }}
              onCancel={
                editingHomeVisit || homeVisits?.length
                  ? () => {
                      setEditHomeVisit(null);
                      setPageState(AvailabilitiesPageStateEnum.VIEW);
                    }
                  : null
              }
            />
          </View>
        )}
      </Container>
    </Grid>
  );
};

const styles = StyleSheet.create({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  mobileSeparator: {
    paddingBottom: 20,
    borderBottomColor: colors.lightPurple2,
    borderBottomWidth: 1,
  },
  webSeparator: {
    borderRightColor: colors.lightPurple2,
    borderRightWidth: 1,
  },
  map: { paddingVertical: 50, flex: 1, height: 300, width: 400, borderRadius: 10 },
  table: {
    alignSelf: 'flex-start',
    flex: 1,
  },
});
