import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import TimePicker from './TimePicker';
import { FormErrorMessage } from '../commonText';
import { RoundedAddIcon, RoundedRemoveIcon } from '../svgImages';

import { StartsEndsTimesModel } from '~/api/models/common/models/StartsEndsTimeModel';
import { mb20, mr10, mt10 } from '~/common/commonStyles';
import { checkOverlap, greaterThanValidation, requiredValidation } from '~/services/validationConfig';
import { labels } from '~/utils/labels';

interface Form {
  time_ranges: StartsEndsTimesModel[];
}

interface Props {
  initialValue: StartsEndsTimesModel[];
  onChange: (value: StartsEndsTimesModel[]) => void;
  errorMessage?: string;
}

export const MultipleSlotsTimePicker: React.FC<Props> = ({ initialValue, onChange, errorMessage }) => {
  const { control, watch } = useForm<Form>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      time_ranges: initialValue,
    },
  });

  const { time_ranges } = useWatch({ control });

  useEffect(() => {
    onChange(time_ranges);
  }, [time_ranges]);

  const { fields, append, remove } = useFieldArray({ control, name: 'time_ranges' });

  const addNew = () => {
    append({ start_time: '', end_time: '' });
  };
  return (
    <View>
      {fields?.map((field, index) => (
        <View key={field.id}>
          <View style={styles.timePickersRow}>
            <View style={styles.timePickerSubRow}>
              <View style={[styles.timePickerMargin, mr10]}>
                <Controller
                  name={`time_ranges.${index}.start_time`}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TimePicker
                      label="Start Time"
                      showMandatory
                      value={value}
                      onChangeValue={onChange}
                      error={!!error}
                      errorMessage={error?.message}
                    />
                  )}
                  rules={{
                    required: watch('time_ranges')[0].start_time === null ? requiredValidation('Start Time') : null,
                    validate: {
                      noOverlapStart: (startTime) => {
                        const currentSlot = time_ranges[index];
                        if (startTime && currentSlot) {
                          return (
                            checkOverlap({ ...currentSlot, start_time: startTime }, index, time_ranges) ||
                            labels.startTimeOverlap
                          );
                        }
                        return true;
                      },
                    },
                  }}
                />
              </View>
              <View style={[styles.timePickerMargin, mr10, mb20]}>
                <Controller
                  name={`time_ranges.${index}.end_time`}
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TimePicker
                      label="End Time"
                      showMandatory
                      value={value}
                      onChangeValue={onChange}
                      error={!!error}
                      errorMessage={error?.message}
                    />
                  )}
                  rules={{
                    required: watch('time_ranges')[0].end_time === null ? requiredValidation('End Time') : null,
                    validate: {
                      greaterThanValidation: greaterThanValidation(
                        () => watch(`time_ranges.${index}.start_time`),
                        'Start Time',
                        'End Time'
                      ),
                      noOverlapEnd: (endTime) => {
                        const currentSlot = time_ranges[index];
                        if (endTime && currentSlot) {
                          return (
                            checkOverlap({ ...currentSlot, end_time: endTime }, index, time_ranges) ||
                            labels.endTimeOverlap
                          );
                        }
                        return true;
                      },
                    },
                  }}
                />
              </View>
            </View>
            {index + 1 === fields.length ? (
              <>
                <TouchableOpacity onPress={addNew} style={{ maxHeight: 70, display: 'flex', justifyContent: 'center' }}>
                  <RoundedAddIcon width={15} height={15} />
                </TouchableOpacity>
              </>
            ) : (
              <TouchableOpacity
                onPress={() => remove(index)}
                style={{ maxHeight: 70, display: 'flex', justifyContent: 'center' }}>
                <RoundedRemoveIcon width={15} height={15} />
              </TouchableOpacity>
            )}
          </View>
        </View>
      ))}
      {errorMessage ? (
        <View style={mt10}>
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </View>
      ) : null}
    </View>
  );
};
const styles = StyleSheet.create({
  timePickersRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  timePickerSubRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flex: 1,
  },
  timePickerMargin: {
    flex: 1,
  },
});
