import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React from 'react';
import { View } from 'react-native';

import { bgPrimary, flex1 } from '~/common/commonStyles';
import { AvailabilitiesNavigationTab } from '~/components/availabilities/AvailabilitiesNavigationTab';
import { DoctorHomeVisitScreen } from '~/pages/doctor/calendar/availabilities/DoctorHomeVisitScreen';
import { DoctorOnDemandScreen } from '~/pages/doctor/calendar/availabilities/DoctorOnDemandScreen';
import { DoctorScheduledAppointmentsScreen } from '~/pages/doctor/calendar/availabilities/DoctorScheduledAppointments';
import { isDoctorVersion, isNative } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

const Tab = createMaterialTopTabNavigator();

export const AvailabilitiesNavigation = () => {
  return (
    <View style={[bgPrimary, flex1]}>
      <Tab.Navigator
        screenOptions={{ swipeEnabled: isNative() }}
        tabBar={(props) => <AvailabilitiesNavigationTab tabProps={props} />}>
        {isDoctorVersion() ? (
          <>
            <Tab.Screen
              options={{ title: 'Scheduled Appointments' }}
              name={screenName.AvailabilitiesScheduledAppointments}
              component={DoctorScheduledAppointmentsScreen}
            />
            <Tab.Screen
              options={{ title: 'Home Visit' }}
              name={screenName.AvailabilitiesHomeVisit}
              component={DoctorHomeVisitScreen}
            />
          </>
        ) : null}
        <Tab.Screen
          options={{ title: 'Doctors Available Now' }}
          name={screenName.AvailabilitiesDoctorsAvailableNow}
          component={DoctorOnDemandScreen}
        />
      </Tab.Navigator>
    </View>
  );
};
