import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import { IncomeBarChart } from './IncomeBarChart';
import { IncomeExportComponent } from './IncomeExportComponent';
import { IncomeTable } from './IncomeTable';
import { IncomeTotalsComponent } from './IncomeTotalsComponent';
import { MobileIncomeFilterModal } from './MobileIncomeFilterModal';
import { flex1, mb20, mb25, ml10, mr10, mr25, ph10 } from '../../../../common/commonStyles';
import { IncomeLayout } from '../layouts/IncomeLayout';

import { IconButton } from '~/components/buttons/IconButton';
import { H6NsSemiBoldBlack, H6NsSemiBoldTheme } from '~/components/commonText';
import { CardSurface } from '~/components/commonViews';
import { ConsultationTypeFilter } from '~/components/doctor/income/components/ConsultationTypeFilter';
import { DateRangeSelectorFilter } from '~/components/doctor/income/components/dateRangeSelectorFilter/DateRangeSelectorFilter';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { FilterIcon } from '~/components/svgImages';
import { useIncomeListingContext } from '~/providers/income/IncomeListingContext';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isNative } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const IncomePage: React.FC = () => {
  const { isMobile, isTablet } = useBreakpoints();
  const { registerModal, openModal } = useModalManager();
  const { form, submitQuery } = useIncomeListingContext();

  useEffect(() => {
    registerModal(ModalName.IncomeFilterModal, MobileIncomeFilterModal);
  }, []);

  return (
    <IncomeLayout>
      <CardSurface style={mb25} elevation={4} ph={0}>
        <View
          style={[
            {
              flexDirection: 'row',
              paddingHorizontal: 15,
              borderBottomColor: colors.lightPurple2,
              borderBottomWidth: 1,
              justifyContent: 'center',
              paddingBottom: isMobile ? 15 : 25,
            },
            !isMobile ? { justifyContent: 'flex-end', flex: 1, flexWrap: 'wrap', alignItems: 'center' } : null,
          ]}>
          <View style={{ alignSelf: 'center', flex: 1, flexWrap: 'wrap', marginRight: 15 }}>
            <H6NsSemiBoldBlack>Overview</H6NsSemiBoldBlack>
          </View>
          {isMobile || isTablet || isNative() ? (
            <>
              <IconButton
                onPress={() => {
                  openModal(ModalName.IncomeFilterModal, { form, submitQuery });
                }}
                style={[mr10, ph10]}>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <FilterIcon width={20} height={20} color={colors.purple} />
                  {isTablet ? <H6NsSemiBoldTheme style={ml10}>Filter</H6NsSemiBoldTheme> : null}
                </View>
              </IconButton>
              <IncomeExportComponent />
            </>
          ) : (
            <>
              <DateRangeSelectorFilter />
              <View style={mr25} />
              <ConsultationTypeFilter />
              <View style={mr25} />

              <IncomeExportComponent />
            </>
          )}
        </View>
        <View style={styles.graph}>
          <IncomeBarChart />
        </View>
      </CardSurface>
      <View style={mb20}>
        <IncomeTotalsComponent />
      </View>
      <H6NsSemiBoldBlack>Invoice and billing history</H6NsSemiBoldBlack>
      <View style={mb20} />
      <View style={styles.table}>
        <View style={flex1}>
          <IncomeTable />
        </View>
      </View>
    </IncomeLayout>
  );
};

const styles = StyleSheet.create({
  table: {
    minHeight: 850,
  },
  graph: {
    elevation: 1,
    paddingHorizontal: 15,
  },
});
