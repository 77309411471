import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { OtpChannelEnum } from '../../api/models/otp/constants/OtpChannelEnum';
import { apiSendOtp } from '../../api/services/otp';
import { apiVerifyEmail, apiVerifyEmailRegistration } from '../../api/services/registration';
import { SuccessAlert } from '../../common/commonMethods';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import OtpScreenComponent from '../../components/otp/OtpScreenComponent';
import { requiredValidation, passcodeLengthValidation } from '../../services/validationConfig';
import { labels } from '../../utils/labels';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { NavType } from '~/navigation/types';
import { isPharmacyVersion } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

const OtpEmailValidationScreen: React.FC = () => {
  const [waiting, setWaiting] = useState(false);
  const { updateRegistrationStatus } = useRegistrationSteps();
  const { navigate } = useNavigation<NavType>();

  const { control, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      otp: '',
    },
  });

  const emailOtpValidation = async (details: { otp: string }) => {
    try {
      setWaiting(true);
      const request = { email_verification_code: details.otp };
      if (isPharmacyVersion()) {
        await apiVerifyEmailRegistration(request);
        navigate(screenName.PassCodeRegisterScreen);
      } else {
        await apiVerifyEmail(request);
        await updateRegistrationStatus();
      }
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const resendOTP = () => {
    setWaiting(true);
    apiSendOtp({ channel: OtpChannelEnum.EMAIL })
      .then(() => {
        SuccessAlert(['An email was sent to you with a one time passcode']);
      })
      .catch(ErrorAlert)
      .finally(() => {
        setWaiting(false);
      });
  };

  return (
    <Controller
      name="otp"
      control={control}
      render={({ field: { onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
        <OtpScreenComponent
          title={labels.enter6DigitOtpToEmail}
          onChange={onChange}
          onPress={handleSubmit(emailOtpValidation)}
          onResend={resendOTP}
          errorMessage={isSubmitted && error?.message}
          waiting={waiting}
          subTitle={labels.enter6DigitOtpEmailSubtitle}
          compressed
        />
      )}
      rules={{
        minLength: passcodeLengthValidation(6),
        required: requiredValidation('One time passcode'),
      }}
    />
  );
};

//make this component available to the app
export default OtpEmailValidationScreen;
