import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import TimePicker from '../../common/TimePicker';
import { H5TtmSemiBoldBlack, H6NsRegularBlack } from '../../commonText';
import DatePicker from '../../inputs/dateTime/DatePicker';
import { Dropdown } from '../../inputs/dropdown/Dropdown';

import { HomeVisitFormModel } from '~/api/models/preferences/models/HomeVisitModel';
import { flex1, mb20, ml5, mr5, mt20 } from '~/common/commonStyles';
import { DaySelectionCarousel } from '~/components/common/DaySelectionCarousel';
import { AppointmentDuration } from '~/constants/appointments/appointmentDuration';
import { greaterThanValidation, requiredValidation } from '~/services/validationConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props {
  control: Control<HomeVisitFormModel, any>;
}

export const HomeVisitAvailabilityEditDetails: React.FC<Props> = ({ control }) => {
  const { isMobile } = useBreakpoints();
  const { starts_at } = useWatch({ control });
  let year = new Date().getFullYear();
  const minYear = new Date(year - 5, 0, 1);
  const maxYear = new Date(year + 5, 11, 31);
  return (
    <View>
      <H6NsRegularBlack>{labels.chooseAppointmentDuration}</H6NsRegularBlack>
      <Controller
        name="slot_duration_in_minutes"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Dropdown
            list={AppointmentDuration}
            value={value}
            setValue={onChange}
            errorMessage={error?.message}
            error={!!error}
            label="Appointment duration"
          />
        )}
        rules={{
          required: requiredValidation('Appointment duration'),
        }}
      />
      <View style={isMobile ? styles.messageMobile : styles.messageWeb}>
        <H6NsRegularBlack>
          {labels.addAvailabilityHomeVisitSubMessage}
          <H5TtmSemiBoldBlack>{labels.homeVisit}</H5TtmSemiBoldBlack>
        </H6NsRegularBlack>
      </View>
      <Controller
        name="recurring_pattern_data.days_of_week"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DaySelectionCarousel passedDates={value} onChange={onChange} errorMessage={error?.message} />
        )}
        rules={{
          required: requiredValidation('Availability days'),
        }}
      />
      <View style={mt20} />
      <H6NsRegularBlack>{labels.whatTimeWillBeAvailable}</H6NsRegularBlack>
      <View style={styles.timePickersRow}>
        <View style={[flex1, mr5]}>
          <Controller
            name="starts_at"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TimePicker
                label="Start Time"
                showMandatory
                value={value}
                onChangeValue={onChange}
                errorMessage={error?.message}
                error={!!error}
              />
            )}
            rules={{
              required: requiredValidation('Start Time'),
            }}
          />
        </View>
        <View style={[flex1, ml5]}>
          <Controller
            name="ends_at"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TimePicker
                label="End Time"
                showMandatory
                value={value}
                onChangeValue={onChange}
                errorMessage={error?.message}
                error={!!error}
              />
            )}
            rules={{
              required: requiredValidation('End Time'),
              validate: {
                greaterThanValidation: greaterThanValidation(() => starts_at, 'Start Time', 'End Time'),
              },
            }}
          />
        </View>
      </View>
      <View style={[mt20, mb20]}>
        <H6NsRegularBlack>{labels.repeatScheduleUntil}</H6NsRegularBlack>
        <Controller
          name="recurring_pattern_data.repeat_until"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <DatePicker
              value={value}
              onChangeValue={onChange}
              label="Date"
              showMandatory
              errorMessage={error?.message}
              error={!!error}
              minDate={minYear}
              maxDate={maxYear}
            />
          )}
          rules={{
            required: requiredValidation('Repeat schedule until date'),
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  messageWeb: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  },
  messageMobile: {
    display: 'flex',
    marginTop: 20,
  },
  timePickersRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
});
