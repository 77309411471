import { DurationIntervalTypeEnum } from '~/api/models/consultations/constants/DurationIntervalTypeEnum';
import { PharmacyModel } from '~/api/models/pharmacy/models/PharmacyModel';

export function generateDurationString(value: number, type: DurationIntervalTypeEnum) {
  return `${value ?? ''} ${!type ? '' : value === 1 ? type.toLowerCase().slice(0, -1) : type.toLowerCase()}`.trim();
}

export function getPharmacyString(pharmacy: PharmacyModel) {
  return pharmacy ? `${pharmacy.name}${pharmacy.locality?.name ? `, ${pharmacy.locality?.name}` : ''}` : '';
}
