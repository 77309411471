import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Menu } from 'react-native-paper';

import { H6NsRegularTheme } from './commonText';
import { ErrorAlert } from './modals/ErrorAlert';
import { UploadPatientDetailsModal } from './modals/UploadPatientDetailsModal';
import { ModalName } from './modals/constants/ModalNames';
import { DownloadIcon, FileExportIcon, MoreIcon, Upload } from './svgImages';

import { ExportTypesEnum } from '~/api/models/common/constants/ExportTypesEnum';
import { apiExportData } from '~/api/services/exports';
import { apiPatientDataDownload } from '~/api/services/patientDetails';
import { InprogressAlert } from '~/common/commonMethods';
import { flexRow, justifyCenter, mb5, mt35 } from '~/common/commonStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { patientManagementLabels } from '~/utils/labels/patientManagement';

export const PatientDetailsMenu: React.FC = () => {
  const [visible, setVisible] = React.useState(false);

  const { registerModal, openModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.UploadPatientDetails, UploadPatientDetailsModal);
  }, []);

  const itemTitleList = [
    {
      title: patientManagementLabels.exportPatientsAction,
      icon: <FileExportIcon width="20" height="20" color={colors.purple} />,
      action: () => {
        apiExportData(ExportTypesEnum.PATIENTS_EXPORT)
          .then(() => {
            InprogressAlert(patientManagementLabels.exportPatientsInProgress, {
              title: patientManagementLabels.exportPatientsTitle,
            });
          })
          .catch(ErrorAlert);
      },
    },
    {
      title: patientManagementLabels.downloadTemplateAction,
      icon: <DownloadIcon width="20" height="20" color={colors.purple} />,
      action: () => {
        apiPatientDataDownload().catch(ErrorAlert);
      },
      hidden: !isDoctorVersion(),
    },
    {
      title: patientManagementLabels.uploadPatientAction,
      icon: <Upload width="20" height="20" color={colors.purple} />,
      action: () => {
        openModal(ModalName.UploadPatientDetails);
      },
      hidden: !isDoctorVersion(),
    },
  ];

  const button = ({ title, icon }) => {
    return (
      <View style={flexRow} key={title}>
        <View style={[justifyCenter, { marginRight: 15 }]}>{icon}</View>
        <View>
          <H6NsRegularTheme key={title}>{title}</H6NsRegularTheme>
        </View>
      </View>
    );
  };

  return (
    <>
      <IconButton onPress={() => setVisible(true)} style={[styles.buttonStyle, mb5]} hoverStyle={styles.buttonHover}>
        <Menu
          visible={visible}
          onDismiss={() => {
            setVisible(false);
          }}
          style={styles.menuStyle}
          contentStyle={mt35}
          anchor={<MoreIcon height={20} width={20} color={colors.purple} />}>
          {itemTitleList
            .filter((item) => !item.hidden)
            .map((item, index) => (
              <Menu.Item
                key={index}
                title={button({ title: item.title, icon: item.icon })}
                onPress={() => {
                  if (item.action) item.action();
                  setVisible(false);
                }}
              />
            ))}
        </Menu>
      </IconButton>
    </>
  );
};

const styles = StyleSheet.create({
  menuStyle: {
    marginTop: 15,
    marginLeft: 10,
  },
  buttonStyle: {
    padding: 10,
    marginLeft: 10,
    maxHeight: 45,
  },
  buttonHover: {
    borderColor: colors.lightPurple,
    backgroundColor: colors.lightPurple,
  },
});
