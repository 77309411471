import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';

import { ErrorAlert } from './ErrorAlert';
import { ModalContainer } from './ModalContainer';
import { ModalName } from './constants/ModalNames';
import { BaseModalProps } from './interfaces/ModalProps';
import { InprogressAlert, SuccessAlert } from '../../common/commonMethods';
import { alignSelfCenter, mb10, mb20, mb5, mv15, textCenter } from '../../common/commonStyles';
import { SendEmailNotifCheckBox } from '../buttons/SendEmailNotifCheckBox';
import { UploadDocumentComponent } from '../buttons/UploadDocumentComponent';
import { Button, OutlineButton } from '../commonButton';
import { H2TtmSemiBoldBlack, H6NsRegularSecondaryBlack } from '../commonText';

import { apiPatientDataDownload, apiUploadPatientData } from '~/api/services/patientDetails';
import { DocumentTypes } from '~/constants/documentUploadsConstants';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { labels } from '~/utils/labels';
import { DigimedMilestones, digimedMilestoneEnabled } from '~/utils/milestoneUtil';
import { colors } from '~/utils/colors';
import { isNative } from '~/utils/buildConfig';

const hasPatientApp = digimedMilestoneEnabled(DigimedMilestones.DoctorPatientApps);
export const UploadPatientDetailsModal: React.FC<BaseModalProps> = ({ onHide }) => {
  const [selectedFiles, setSelectedFiles] = useState<DocumentPickerAsset[]>([]);
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [sendEmailNotification, setSendEmailNotification] = useState(false);
  const handleEmailCheckboxChange = () => {
    setSendEmailNotification(!sendEmailNotification);
  };
  const { closeModalByName } = useModalManager();

  const addFile = async (document: DocumentPickerAsset) => {
    setSelectedFiles([document]);
  };

  const uploadFiles = async () => {
    if (!selectedFiles.length) return;

    setLoading(true);
    return await apiUploadPatientData({
      data: selectedFiles[0],
      send_email_to_patient: hasPatientApp && sendEmailNotification,
    })
      .then(() => {
        InprogressAlert(
          [
            'Patient data was uploaded and is being processed.',
            'You will receive an e-mail notification once complete.',
          ],
          { title: 'File uploaded' }
        );
        closeModalByName(ModalName.UploadPatientDetails);
      })
      .catch((error) => {
        setErrorMessage(error?.message);
      })
      .finally(() => setLoading(false));
  };

  const removeFile = async () => {
    setSelectedFiles([]);
  };

  const downloadTemplate = async () => {
    try {
      setDownloading(true);
      await apiPatientDataDownload();

      if (isNative()) {
        SuccessAlert([
          'The template was successfully downloaded to your device',
          'Once you are finished inputting your patient details come back and upload your file.',
        ]);
      }
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setDownloading(false);
    }
  };

  const buttons = [
    <Button label={labels.completeUpload} funCallback={uploadFiles} disabled={loading} />,
    <OutlineButton funCallback={onHide}>Cancel</OutlineButton>,
  ];
  return (
    <ModalContainer hideOnBackground onHide={onHide} buttons={buttons}>
      <View>
        <H2TtmSemiBoldBlack style={[mb20, textCenter]}>{labels.uploadPatientDetails}</H2TtmSemiBoldBlack>
        <View style={mv15}>
          <H6NsRegularSecondaryBlack style={[textCenter]}>{labels.uploadExcelSheet1}</H6NsRegularSecondaryBlack>
          <View style={styles.templateContainer}>
            <H6NsRegularSecondaryBlack style={[mb10, textCenter]}>
              {labels.uploadExcelSheetDownloadTemplate}
            </H6NsRegularSecondaryBlack>
            <OutlineButton funCallback={downloadTemplate} disabled={downloading} outline>
              Download Excel template
            </OutlineButton>
          </View>
        </View>
        <View style={[alignSelfCenter, { width: '100%' }]}>
          <View style={mb20}>
            <UploadDocumentComponent
              hidePreview
              error={!!errorMessage}
              uploadedFiles={selectedFiles}
              add={addFile}
              remove={removeFile}
              allowedFormats={[DocumentTypes.xlsx]}
              uploadTitle="Upload Excel Import"
              hideState
            />
          </View>
          {hasPatientApp ? (
            <>
              <H6NsRegularSecondaryBlack style={mb5}>
                Would you like to notify your patients once we have finished importing their details?
              </H6NsRegularSecondaryBlack>
              <SendEmailNotifCheckBox checked={sendEmailNotification} onChange={handleEmailCheckboxChange} />
            </>
          ) : null}
        </View>
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  templateContainer: {
    borderRadius: 6,
    borderColor: colors.lightGrey,
    borderWidth: 1,
    padding: 10,
    marginTop: 20,
    marginBottom: 10,
  },
});
