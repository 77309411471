import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UploadDataLogModel } from '~/api/models/patientData/models/UploadDataLogModel';
import { apiGetExportDataIndex, apiGetSpecificExportPatientData } from '~/api/services/exports';
import { DEFAULT_TABLE_ITEMS_PER_PAGE, TablePaginationInfo } from '~/components/common/DataTable/DataTable';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import fileSystem from '~/integrations/fileSystem';
import { RootState } from '~/redux/reducers';
import { APPEND_EXPORTS_DATA } from '~/redux/reducers/requestsReducer';
import { useAppDispatch } from '~/redux/store';

export function useDocumentExportHook() {
  const exportsDocuments = useSelector((state: RootState) => state.requestsReducer.exports.data);
  const exportsPagination = useSelector((state: RootState) => state.requestsReducer.exports.meta);

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationInfo>({
    page: 0,
    limit: DEFAULT_TABLE_ITEMS_PER_PAGE,
  });

  const [downloadExports, setDownloadExports] = useState<UploadDataLogModel[]>(null);
  const dispatch = useAppDispatch();

  const getDocuments = (pagination: TablePaginationInfo) => {
    setTablePagination(pagination);

    setLoading(true);

    apiGetExportDataIndex({
      page: pagination.page,
      limit: DEFAULT_TABLE_ITEMS_PER_PAGE,
    })
      .then((res) => {
        dispatch(APPEND_EXPORTS_DATA(res.data));
      })
      .catch(ErrorAlert)
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getDocuments(tablePagination);
  }, [tablePagination.page]);

  const getDownloadExports = async (exportId: number) => {
    try {
      setDownloadExports(null);
      const res = await apiGetSpecificExportPatientData({ id: exportId });
      await fileSystem.downloadMedia(res.data.media);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  return {
    setPagination: setTablePagination,
    getDownloadExports,
    loading,
    exportsDocuments,
    exportsPagination,
    downloadExports,
  };
}
