import moment from 'moment';
import React, { useMemo } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { IDayComponentProps } from 'react-native-calendar-strip';

import { mb10 } from '~/common/commonStyles';
import { H5NsSemiBoldSecondaryBlack, SmallNsRegularBlack, SmallNsSemiBoldBlack } from '~/components/commonText';
import { colors } from '~/utils/colors';

const CalendarDay: React.FC<IDayComponentProps> = ({ date, onDateSelected, selected, enabled, marking }) => {
  const selectedDate = moment(date.toJSON());

  const markingColor = useMemo(() => (marking.dots?.length ? (marking.dots[0].color as string) : undefined), [marking]);

  return (
    <Pressable onPress={() => (enabled ? onDateSelected!(date) : null)} style={{ alignItems: 'center' }}>
      <H5NsSemiBoldSecondaryBlack
        style={{
          ...mb10,
          fontSize: 14,
        }}>
        {selectedDate.format('ddd')[0]}
      </H5NsSemiBoldSecondaryBlack>
      <View style={[styles.date, selected ? styles.dateSelected : null]}>
        {markingColor ? (
          <SmallNsSemiBoldBlack>{selectedDate.format('D')}</SmallNsSemiBoldBlack>
        ) : (
          <SmallNsRegularBlack>{selectedDate.format('D')}</SmallNsRegularBlack>
        )}
        {markingColor ? (
          <View style={styles.markerContainer}>
            <View style={[{ backgroundColor: markingColor }, styles.marking]} />
          </View>
        ) : null}
      </View>
    </Pressable>
  );
};

export default CalendarDay;

const styles = StyleSheet.create({
  marking: {
    width: 5,
    height: 5,
    borderRadius: 10,
  },
  markerContainer: {
    height: 0,
  },
  date: {
    borderWidth: 1,
    borderColor: colors.lightPurple,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 40,
    width: '100%',
    height: 40,
    borderRadius: 20,
  },
  dateSelected: {
    borderColor: colors.info,
    backgroundColor: colors.lightPurple2,
  },
});
