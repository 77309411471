import React from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { Accordion } from '~/components/buttons/Accordions/Accordion';
import { H6NsRegularBlack } from '~/components/commonText';
import { ClinicalTermSearchableList } from '~/components/inputs/searchable/ClinicalTermSearchableList';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { useHealthProfileSocialFactorsContext } from '~/providers/healthProfile/HealthProfileSocialFactorsProvider';
import { colors } from '~/utils/colors';

interface Props {
  locked?: boolean;
}

export const PatientLifestyleSocialFactors: React.FC<Props> = ({ locked }) => {
  const { controlSocialFactors } = useHealthProfileDataContext();
  const { getData, loading, hasData } = useHealthProfileSocialFactorsContext();

  const expandedChanged = (expanded: boolean) => {
    if (expanded && (!hasData || locked)) {
      getData().catch(() => {});
    }
  };

  return (
    <Accordion groupValue="social_factors" title="Social factors" onExpandedChanged={expandedChanged}>
      <Controller
        name="socialFactors"
        control={controlSocialFactors}
        render={({ field: { onChange, value } }) => (
          <View>
            {loading ? (
              <LoadingActivityIndicator />
            ) : !locked || !!value?.length ? (
              <ClinicalTermSearchableList
                type={ClinicalTermTypeEnum.SOCIAL_FACTORS}
                values={value}
                setValues={onChange}
                disabled={locked}
                inputOptions={{ style: locked ? { backgroundColor: colors.white } : null }}
              />
            ) : (
              <H6NsRegularBlack>No social factors were set</H6NsRegularBlack>
            )}
          </View>
        )}
      />
    </Accordion>
  );
};
