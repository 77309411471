import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import { ErrorAlert } from './ErrorAlert';
import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { Button, TextButton } from '../commonButton';
import {
  H3TtmSemiBoldBlack,
  H6NsRegularBlack,
  H6NsRegularSecondaryBlack,
  H6NsSemiBoldBlack,
  H6NsSemiBoldLink,
} from '../commonText';
import { MandatoryAsterisks } from '../inputs/MandatoryAsterisks';
import { RatingViewComponent } from '../patient/doctorListing/components/RatingViewComponent';
import { ProfileImageComponent } from '../profile/ProfileImageComponent';

import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { AvailableDoctorModel } from '~/api/models/appointments/models/AvailableDoctorModel';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiGetDoctor } from '~/api/services/accounts/doctor';
import { apiAddFavouriteDoctors } from '~/api/services/favouriteDoctors';
import { flex1, mb10, mb20, ml10, textCenter } from '~/common/commonStyles';
import { formatAmount } from '~/utils/amountUtil';
import { idNameArrayToString } from '~/utils/arrayUtil';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';
import { getAccountName, getInitials } from '~/utils/personalDetailsUtils';

interface Props extends BaseModalProps {
  doctorOrClinic: AccountModel;
  consultation: ConsultationModel;
  onSubmit: () => void;
}

export const SaveFavouriteDoctorModal: React.FC<Props> = ({ doctorOrClinic, consultation, onHide, onSubmit }) => {
  const [submitting, setSubmitting] = useState(false);
  const [physicianModel, setPhysicianModel] = useState<AvailableDoctorModel>(null);

  useEffect(() => {
    if (!doctorOrClinic?.id) return;
    apiGetDoctor({ id: doctorOrClinic.id })
      .then((res) => {
        setPhysicianModel(res.data);
      })
      .catch(() => {});
  }, [doctorOrClinic?.id]);

  const submitFavouriteDoctor = async () => {
    try {
      setSubmitting(true);
      await apiAddFavouriteDoctors({
        patient_id: consultation.patient.id,
        doctor_id: doctorOrClinic.id,
      });
      if (onSubmit) onSubmit();
    } catch (e) {
      ErrorAlert(e);
    }
    setSubmitting(false);
  };

  const buttons = [
    <Button label={appointmentLabels.saveFavouriteDoctor} funCallback={submitFavouriteDoctor} disabled={submitting} />,
    <TextButton label={labels.skip.toUpperCase()} funCallback={onHide} disabled={submitting} />,
  ];

  const getFormattedAmount = () => {
    const consultationType = physicianModel?.consultation_prices?.find((element) => {
      return element.type === consultation.type;
    });

    return consultationType ? `${formatAmount(consultationType.amount, consultationType.currency)}/consultation` : null;
  };

  return (
    <ModalContainer showInfoLine buttons={buttons}>
      <View>
        <H3TtmSemiBoldBlack style={[textCenter, mb20]}>{appointmentLabels.saveDoctorTitle}</H3TtmSemiBoldBlack>
        <H6NsRegularBlack style={mb10}>
          {appointmentLabels.saveDoctorDescription}
          <MandatoryAsterisks />
        </H6NsRegularBlack>
        {physicianModel ? (
          <View style={[styles.profileContainer, mb20]}>
            <View>
              <ProfileImageComponent
                size={90}
                src={physicianModel?.profile_image?.conversions?.avatar ?? physicianModel?.profile_image?.url ?? ''}
                initials={getInitials(physicianModel)}
              />
            </View>
            <View style={flex1}>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginLeft: 10,
                }}>
                <View style={{ flex: 1 }}>
                  <H6NsSemiBoldBlack style={{ whiteSpace: 'pre-wrap', marginBottom: 5 }}>
                    {getAccountName(physicianModel)}
                  </H6NsSemiBoldBlack>
                  <H6NsRegularSecondaryBlack>
                    {idNameArrayToString(physicianModel?.specialisations, 4)}
                  </H6NsRegularSecondaryBlack>
                </View>
                <View style={ml10}>
                  <RatingViewComponent rating={physicianModel?.rating ?? 'N/A'} />
                </View>
              </View>
              <View style={ml10}>
                <H6NsSemiBoldLink>{getFormattedAmount()}</H6NsSemiBoldLink>
              </View>
            </View>
          </View>
        ) : (
          <ActivityIndicator />
        )}
      </View>
    </ModalContainer>
  );
};
const styles = StyleSheet.create({
  profileContainer: {
    borderRadius: 6,
    borderWidth: 1,
    backgroundColor: colors.lightPurple,
    borderColor: colors.info,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
  },
});
