import { get, post } from '../../axios';
import { IdModel } from '../../models/common/models/IdModel';
import { AddDiagnosisRequest } from '../../models/diagnoses/requests/AddDiagnosisRequest';
import { GetDiagnosesRequest } from '../../models/diagnoses/requests/GetDiagnosesRequest';
import { AddDiagnosisResponse } from '../../models/diagnoses/responses/AddDiagnosisResponse';
import { GetDiagnosesResponse } from '../../models/diagnoses/responses/GetDiagnosesResponse';
import { GetDiagnosisResponse } from '../../models/diagnoses/responses/GetDiagnosisResponse';

export const apiCreateDiagnosis = (data: AddDiagnosisRequest) => {
  return post<AddDiagnosisResponse>('/diagnoses', data);
};

export const apiGetDiagnosesIndex = (data: GetDiagnosesRequest) => {
  return get<GetDiagnosesResponse>('/diagnoses', data);
};

export const apiGetDiagnosis = ({ id }: IdModel) => {
  return get<GetDiagnosisResponse>(`/diagnoses/${id}`);
};
