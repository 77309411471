import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useGeneralConsultationChannel } from '../channels/useGeneralConsultationChannel';

import { ConsultationEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { OnDemandAvailabilityForm } from '~/api/models/preferences/models/OnDemandAvailabilityFormModel';
import { apiDoctorCancelWaitingRoom } from '~/api/services/appointments';
import { apiGetConsultationsIndex } from '~/api/services/consultations';
import { apiDeleteOnDemand, apiGetOnDemandIndex, apiUpdateOnDemand } from '~/api/services/preferences/OnDemand';
import { ConfirmationAlert, SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import {
  SET_ON_DEMAND_AVAILABILITY,
  onDemandAvailabilitySelector,
} from '~/redux/reducers/doctor/availabilitiesReducer';
import { useAppDispatch } from '~/redux/store';
import { LARAVEL_DATE_TIME_SHORT, TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';

interface Props {
  immediate?: boolean;
}
export const useOnDemandAvailability = (options?: Props) => {
  const dispatch = useAppDispatch();
  const onDemandAvailability = useSelector(onDemandAvailabilitySelector);

  const getWaitingIndex = async () => {
    try {
      const response = await apiGetConsultationsIndex({
        filter: {
          state: [ConsultationStateEnum.Scheduled],
          type: [ConsultationTypeEnum.ON_DEMAND],
          after: moment().startOf('day').format(LARAVEL_DATE_TIME_SHORT),
        },
      });
      return response.data.meta.total;
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const onDemandChannelEventHandler = (event) => {
    if (event === ConsultationEventsEnum.CONSULTATION_ON_DEMAND_EXPIRED) {
      getOnDemandAvailability();
    }
  };
  useGeneralConsultationChannel({ eventHandler: onDemandChannelEventHandler });

  const getOnDemandAvailability = async () => {
    try {
      const res = await apiGetOnDemandIndex();
      dispatch(SET_ON_DEMAND_AVAILABILITY(res.data));
    } catch {
      dispatch(SET_ON_DEMAND_AVAILABILITY(undefined));
    }
  };

  const getOnlineUntil = (time: string) => {
    // TODO:[sc-9244] allow online until next day - backend limitation
    // const timeDate = stringToDate(time, { parseFormat: TIME_FORMAT });
    // const now = new Date();
    // now.setSeconds(0, 0);

    // if (timeDate <= now) {
    //   timeDate.setDate(now.getDate() + 1);
    // }

    // return parseDateTime(timeDate, { outputFormat: LARAVEL_DATE_TIME_SHORT });
    return parseDateTime(time, {
      parseFormat: TIME_FORMAT,
      outputFormat: LARAVEL_DATE_TIME_SHORT,
    });
  };

  const updateOnDemandAvailability = async (onDemandData: OnDemandAvailabilityForm) => {
    try {
      const data = {
        slot_duration_in_minutes: onDemandData.slot_duration_in_minutes,
        online_until: getOnlineUntil(onDemandData.online_until_time),
      };
      const res = await apiUpdateOnDemand(data);
      dispatch(SET_ON_DEMAND_AVAILABILITY(res.data));
      SuccessAlert(['Your on-demand availability has been switched on']);
    } catch (e) {
      ErrorAlert(e);
      throw e;
    }
  };

  const disableOnDemandAvailability = async (onDemandData: OnDemandAvailabilityForm) => {
    try {
      if (onDemandData.id) await apiDeleteOnDemand({ id: onDemandData.id });
      dispatch(SET_ON_DEMAND_AVAILABILITY(undefined));
      SuccessAlert(['Your on-demand availability has been switched off']);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const deleteWaitingRoom = async () => {
    try {
      await apiDoctorCancelWaitingRoom();
    } catch (e) {
      ErrorAlert(e);
      throw e;
    }
  };
  const saveOnDemandAvailability = async (onDemandData: OnDemandAvailabilityForm) => {
    if (onDemandData.is_online) {
      await updateOnDemandAvailability(onDemandData);
    } else {
      const waitingRoomCount = await getWaitingIndex();
      if (waitingRoomCount > 0) {
        ConfirmationAlert([], {
          type: ModalAlertTypeEnum.ERROR,
          title: labels.onDemandWaitingRoom,
          okTitle: labels.stayOnline,
          okFunction: () => {
            updateOnDemandAvailability(onDemandData);
          },
          cancelTitle: labels.offlineAndKeepAppointments,
          cancelFunction: () => {
            disableOnDemandAvailability(onDemandData);
          },
          additionalTitle: labels.offlineAndDeleteAppointments,
          additionalFunction: () => {
            deleteWaitingRoom();
            disableOnDemandAvailability(onDemandData);
          },
        });
      } else {
        disableOnDemandAvailability(onDemandData);
      }
    }
  };

  useEffect(() => {
    if (options?.immediate) getOnDemandAvailability();
  }, []);

  return {
    getOnDemandAvailability,
    saveOnDemandAvailability,
    onDemandAvailability,
    isAvailable: !!onDemandAvailability,
  };
};
