import 'expo/build/Expo.fx';
import { registerRootComponent } from 'expo';
// import { createRoot } from 'react-dom/client';
// import { Platform } from 'react-native';
import * as Sentry from '@sentry/react-native';

/**
 * ReactDOM.render is no longer supported in React 18. Use createRoot instead.
 * Until you switch to the new API, your app will behave as if it’s running React 17. Learn more: https://reactjs.org/link/switch-to-createroot
 *
 * Issues switching to React 18, as React Native Paper menu stopped working
 */
import App from './App';
// if (Platform.OS === 'web') {
//   const container = document.getElementById('root');
//   const root = createRoot(container);
//   root.render(<App />);
// } else {

registerRootComponent(Sentry.wrap(App));
// }
