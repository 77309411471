import React from 'react';

import { HomeOutline } from '~/components/svgImages';
import { colors } from '~/utils/colors';
import { screenName } from '~/utils/screenName';

export interface MenuItem {
  label?: string;
  key?: string;
  image?: (color?: string) => JSX.Element;
  screenName?: string;
  hidden?: boolean;
  disabled?: boolean;
  children?: Omit<MenuItem, 'children'>[];
}

export const menusVerificationPending: MenuItem[][] = [
  [
    {
      label: 'Home',
      image: (color = colors.purple) => <HomeOutline height={20} width={20} color={color} />,
      screenName: screenName.Dashboard,
    },
  ],
];
