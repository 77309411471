import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { flex1, ml10, mr10, mt20 } from '../../../../common/commonStyles';
import { screenName } from '../../../../utils/screenName';
import { H2TtmSemiBoldBlack } from '../../../commonText';
import { NotificationBell } from '../../common/NotificationBell';

import { useMedicalRegistration } from '~/api/hooks/accounts/MedicalRegistration';
import { useUserDetailsInfo } from '~/api/hooks/accounts/UserDetails';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { DoctorClinicName } from '~/components/patient/doctorListing/components/DoctorClinicName';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';

interface Props {
  title?: string;
  hideImage?: boolean;
  hideNotifications?: boolean;
  backgroundColor?: string;
  bottom?: React.ReactNode;
}
export const DashboardMobileHeader: React.FC<Props> = ({
  title,
  hideImage,
  hideNotifications,
  backgroundColor,
  bottom,
}) => {
  const { userDetails, fullName } = useUserDetailsInfo({ allowStale: true });
  const { medicalRegistration } = useMedicalRegistration();
  const navigation = useNavigation();
  return (
    <View style={[styles.container, backgroundColor ? { backgroundColor } : null]}>
      <View style={[styles.header, bottom ? { paddingBottom: 10 } : null]}>
        {hideImage ? null : (
          <TouchableOpacity
            onPress={() => {
              navigation.navigate(screenName.ProfileScreen as never);
            }}>
            <ProfileImageComponent account={userDetails?.account} shadow />
          </TouchableOpacity>
        )}
        <View
          style={[
            flex1,
            mr10,
            { justifyContent: 'space-between' },
            !hideImage ? ml10 : mt20,
            !hideNotifications ? mr10 : null,
          ]}>
          {title ? (
            <H2TtmSemiBoldBlack numberOfLines={2}>{title}</H2TtmSemiBoldBlack>
          ) : (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(screenName.ProfileScreen as never);
              }}>
              <H2TtmSemiBoldBlack numberOfLines={2}>{fullName || userDetails?.account?.email}</H2TtmSemiBoldBlack>
              <DoctorClinicName
                account={isDoctorVersion() ? userDetails?.account : null}
                fallback={medicalRegistration?.number}
                large
              />
            </TouchableOpacity>
          )}
        </View>
        {hideNotifications ? null : <NotificationBell />}
      </View>
      {bottom}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
    paddingTop: 25,
    backgroundColor: colors.lightPurple,
    paddingBottom: 15,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  redDot: {
    position: 'absolute',
    backgroundColor: colors.danger,
    width: 10,
    height: 10,
    borderRadius: 10,
    right: 0,
    top: -2,
  },
});
