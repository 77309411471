import { ErrorMoreInfoType } from './ErrorMoreInfoType';

export class ErrorWithMoreInfo extends Error {
  moreInfoType: ErrorMoreInfoType = undefined;

  constructor(message: string, moreInfoType: ErrorMoreInfoType) {
    super(message);

    this.moreInfoType = moreInfoType;
  }
}
