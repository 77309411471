import { SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { NotificationCodeEnum } from '~/integrations/pushNotifications/enums/NotificationCodeEnum';
import { DigimedNotificationEventPayload } from '~/integrations/pushNotifications/interfaces/DigimedNotificationEventPayload';
import { isPatientVersion } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';

export const useHandleDependantNotification = () => {
  const handleNotification = (payload: DigimedNotificationEventPayload) => {
    if (!isPatientVersion()) return;

    if (payload.userInfo.data.code === NotificationCodeEnum.APPROVED_DEPENDANT) {
      SuccessAlert(
        [payload.userInfo.notification.body || 'Your family member was successfully approved.'],
        'Family member approved',
        labels.continue
      );
    } else if (payload.userInfo.data.code === NotificationCodeEnum.REJECTED_DEPENDANT) {
      ErrorAlert(
        payload.userInfo.notification.body || 'Your family member request was declined.',
        'Family member declined',
        { buttonText: labels.continue }
      );
    }
  };

  return { handleNotification };
};
