import React from 'react';
import { View } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { TextButton } from '../commonButton';
import { H3TtmSemiBoldBlack } from '../commonText';
import { SubscriptionPlanListing } from '../subscriptions/SubscriptionPlanListing';

import { textCenter } from '~/common/commonStyles';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  title: string;
}

export const PaywallModal: React.FC<Props> = ({ onHide, title }) => {
  const buttons = [<TextButton label={labels.close.toUpperCase()} funCallback={onHide} />];
  return (
    <ModalContainer buttons={buttons} wide padding="sm">
      <View>
        <SubscriptionPlanListing title={<H3TtmSemiBoldBlack style={textCenter}>{title}</H3TtmSemiBoldBlack>} />
      </View>
    </ModalContainer>
  );
};
