import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Feather from '@expo/vector-icons/Feather';

import { flex1 } from '~/common/commonStyles';
import { SmallNsRegularBlack } from '~/components/commonText';
import { OTConnectionState } from '~/integrations/openTok/types/OTConnectionState';
import { colors } from '~/utils/colors';

interface Props {
  style?: StyleProp<ViewStyle>;
  connectionState: OTConnectionState;
}

export const ConnectionStateComponent: React.FC<Props> = ({ connectionState, style }) => {
  const [showState, setShowState] = useState(false);
  const [localState, setLocalState] = useState<OTConnectionState>(connectionState);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    switch (connectionState) {
      case OTConnectionState.Reconnecting:
        setShowState(true);
        break;
      case OTConnectionState.Connected:
        if (showState) timer.current = setTimeout(() => setShowState(false), 3000);
        break;
      case OTConnectionState.Disconnected:
        if (showState) timer.current = setTimeout(() => setShowState(false), 6000);
    }
    setLocalState(connectionState);
  }, [connectionState]);

  const componentDetails = useMemo(() => {
    switch (localState) {
      case OTConnectionState.Connected:
        return { viewStyle: styles.connectedView, textStyle: styles.connectedText, text: 'Connected', icon: 'wifi' };
      case OTConnectionState.Disconnected:
        return {
          viewStyle: styles.disconnectedView,
          textStyle: styles.disconnectedText,
          text: 'Disconnected',
          icon: 'wifi-off',
        };
      case OTConnectionState.Reconnecting:
        return {
          viewStyle: styles.reconnectingView,
          textStyle: styles.reconnectingText,
          text: 'Reconnecting',
          icon: 'refresh-cw',
        };
      case OTConnectionState.Initial:
        return {
          viewStyle: null,
          textStyle: null,
          text: 'Waiting...',
          icon: 'loader',
        };
    }
  }, [localState]);

  if (!showState) return;
  return (
    <View style={[styles.container, componentDetails.viewStyle, style]}>
      <SmallNsRegularBlack style={[flex1, componentDetails.textStyle]}>{componentDetails.text}</SmallNsRegularBlack>
      <View>
        <Feather size={14} name={componentDetails.icon} style={componentDetails.textStyle} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    borderWidth: 2,
    padding: 10,
    margin: 10,
    maxWidth: 150,
    display: 'flex',
    flexDirection: 'row',
    borderColor: colors.lightGrey,
  },
  reconnectingView: {
    borderColor: colors.warning,
  },
  reconnectingText: {
    color: colors.warning,
  },
  connectedView: {
    borderColor: colors.success,
  },
  connectedText: {
    color: colors.success,
  },
  disconnectedView: {
    borderColor: colors.danger,
  },
  disconnectedText: {
    color: colors.danger,
  },
});
