import React from 'react';
import { StyleSheet, Text } from 'react-native';

import { colors } from '~/utils/colors';

interface Props {
  isMandatory?: boolean;
}

export const MandatoryAsterisks: React.FC<Props> = ({ isMandatory }) => {
  return isMandatory ? <Text style={styles.asterisk}>*</Text> : null;
};

const styles = StyleSheet.create({
  asterisk: {
    color: colors.danger,
  },
});
