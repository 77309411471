import React from 'react';
import { View, StyleSheet } from 'react-native';

import { CardBrandLogos } from './CardBrandLogos';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { PaymentMethodsModel } from '~/api/models/stripe/models/PaymentMethodsModel';
import { ConfirmationAlert } from '~/common/commonMethods';
import { TextButton } from '~/components/commonButton';
import { SmallNsRegularDisableColor, SmallNsRegularSecondaryBlack } from '~/components/commonText';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { formatPaymentCard } from '~/utils/paymentMethodUtil';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props {
  cardInfo: PaymentMethodsModel[];
  removeCard: CallableFunction;
}

export const PaymentCards: React.FC<Props> = ({ cardInfo, removeCard }) => {
  const { userDetails } = useUserDetails();
  const { isMobile } = useBreakpoints();

  const tryRemoveCard = (cardId: string, cardLastFour: string) => {
    ConfirmationAlert([`Are you sure you want to remove the card ending ${cardLastFour}?`], {
      title: 'Remove card',
      okFunction: () => removeCard(cardId),
    });
  };
  return (
    <Column>
      <View style={[styles.container, isMobile ? { justifyContent: 'center' } : null]}>
        {cardInfo
          ? cardInfo?.map((item, index) => {
              return (
                <View style={[styles.cardContainer, isMobile ? null : { marginRight: 20 }]} key={index}>
                  <View style={styles.card} key={index}>
                    <View style={{ marginHorizontal: 15 }}>
                      <Row style={styles.topRow}>
                        <SmallNsRegularSecondaryBlack style={{ flex: 1, marginRight: 10 }}>
                          {getAccountName(userDetails?.account)}
                        </SmallNsRegularSecondaryBlack>
                        <SmallNsRegularSecondaryBlack>
                          {item.card.funding.charAt(0).toUpperCase() + item.card.funding.slice(1)} card
                        </SmallNsRegularSecondaryBlack>
                      </Row>
                      <Row style={{ bottom: -20 }}>
                        <SmallNsRegularSecondaryBlack>{formatPaymentCard(item.card)}</SmallNsRegularSecondaryBlack>
                      </Row>

                      <Row style={{ bottom: -30 }}>
                        <SmallNsRegularDisableColor>Expiry date</SmallNsRegularDisableColor>
                      </Row>
                      <Row style={{ bottom: -17, justifyContent: 'space-between', alignItems: 'center' }}>
                        <SmallNsRegularSecondaryBlack>
                          {item.card.exp_month}/{item.card.exp_year}
                        </SmallNsRegularSecondaryBlack>
                        <View>
                          <CardBrandLogos brand={item.card.brand} />
                        </View>
                      </Row>
                    </View>
                  </View>
                  <TextButton
                    style={{ alignItems: 'flex-end' }}
                    labelStyle={styles.dangerText}
                    label="Remove card"
                    funCallback={() => {
                      tryRemoveCard(item.id, item.card.last4);
                    }}
                  />
                </View>
              );
            })
          : null}
      </View>
    </Column>
  );
};

const styles = StyleSheet.create({
  container: { flexDirection: 'row', display: 'flex', flexWrap: 'wrap', flex: 1 },
  cardContainer: { maxWidth: 302, width: '100%', flexDirection: 'column', marginBottom: 10 },
  card: { maxWidth: 292, width: '100%', height: 147, borderRadius: 5, padding: 15, backgroundColor: '#E6E9F4' },
  topRow: { justifyContent: 'space-between', height: 35 },
  dangerText: { color: colors.danger },
});
