import { formatDistance } from 'date-fns';
import React, { useEffect } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { Actions } from './RequestActions';
import {
  H5TtmRegularBlack,
  H5TtmRegularSecondaryBlack,
  H5TtmSemiBoldBlack,
  SmallNsRegularSecondaryBlack,
} from '../../../components/commonText';
import { DataTable, DataTableColumns } from '../../common/DataTable/DataTable';

import { useApprovalRequestsList } from '~/api/hooks/patientDataHandling/useApprovalRequestsList';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiDeclineConsultation } from '~/api/services/consultations/request';
import { ConfirmationAlert, SuccessAlert } from '~/common/commonMethods';
import { flex1, mb5, mt10 } from '~/common/commonStyles';
import { ConsultationTypeIcon } from '~/components/common/consultations/ConsultationTypeIcon';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { Home, MoreIcon, Stethoscope } from '~/components/svgImages';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { ConsultationTypeName } from '~/services/config';
import { colors } from '~/utils/colors';
import {
  DAY_MONTH_FORMAT,
  LARAVEL_DATE_TIME_SHORT,
  TIME_FORMAT,
  displayTimeRange,
  parseDateTime,
  stringToDate,
} from '~/utils/dateAndTime';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';
import { labels } from '~/utils/labels';
import { bookingLabels } from '~/utils/labels/booking';
import { appointmentLabels } from '~/utils/labels/appointments';

export const RequestsTable: React.FC = () => {
  const { approvalRequests, loading, getRequests } = useApprovalRequestsList();
  const { openModal } = useModalManager();
  const { showNewBadge } = useNewBadge({ timeKey: 'updated_at' });

  useEffect(() => {
    getRequests();
  }, []);

  const declineRequest = async (consultationId: number) => {
    return new Promise<void>((resolve) =>
      ConfirmationAlert([bookingLabels.areYouSureYouWantDecline], {
        okTitle: labels.decline,
        okFunction: async () => {
          openModal(ModalName.RejectConsultationReasonModal, {
            consultationId,
            title: appointmentLabels.declineConsultation,
            description: appointmentLabels.declineReason,
            declineApi: apiDeclineConsultation,
            buttonTitle: appointmentLabels.decline,
            onReady: async () => {
              SuccessAlert(['Consultation successfully declined']);
              await getRequests();
              resolve();
            },
          });
        },
      })
    );
  };

  const openApprovalRequestModal = (consultation: ConsultationModel) => {
    openModal(ModalName.ApprovalRequest, {
      consultation,
      onReady: () => {
        getRequests();
      },
    });
  };

  const columns: DataTableColumns = [
    {
      key: 'patient profile',
      title: 'Patient',
      columnStyle: { maxWidth: 300 },
      cell: (_, itemData: ConsultationModel) => (
        <ProfileDetailsComponent account={itemData.patient} theme="table" style={flex1} />
      ),
    },
    {
      key: 'reason',
      title: 'Reason',
      columnStyle: { maxWidth: 300 },
      cell: (_, itemData: ConsultationModel) => (
        <SmallNsRegularSecondaryBlack>{itemData.reason}</SmallNsRegularSecondaryBlack>
      ),
    },
    {
      key: 'date',
      title: 'Requested on',
      columnStyle: { maxWidth: 300 },
      selector: (itemData: ConsultationModel) =>
        itemData?.start_at
          ? `${
              parseDateTime(itemData?.start_at || itemData?.updated_at, {
                outputFormat: DAY_MONTH_FORMAT,
              }) ?? ''
            } ${displayTimeRange(
              parseDateTime(itemData.start_at || itemData?.updated_at, { outputFormat: TIME_FORMAT }),
              parseDateTime(itemData.end_at, { outputFormat: TIME_FORMAT })
            )}`
          : itemData.updated_at
          ? formatDistance(stringToDate(itemData.updated_at, { parseFormat: LARAVEL_DATE_TIME_SHORT }), new Date()) +
            ' ago'
          : '',
      cell: (cell) => (
        <View style={styles.info}>
          <SmallNsRegularSecondaryBlack>{cell}</SmallNsRegularSecondaryBlack>
        </View>
      ),
    },
    {
      key: 'type',
      title: 'Consultation type',
      columnStyle: { maxWidth: 300 },
      cell: (_, itemData: ConsultationModel) => {
        return (
          <View style={flex1}>
            <H5TtmRegularSecondaryBlack style={mb5}>{`${
              ConsultationTypeName[itemData?.type]
            }`}</H5TtmRegularSecondaryBlack>
            <ConsultationTypeIcon consultation={itemData} />
          </View>
        );
      },
    },
    {
      key: 'actions',
      title: '',
      columnStyle: { maxWidth: 400 },
      cell: (_, itemData: ConsultationModel) => (
        <Actions
          onAccept={() => {
            openApprovalRequestModal(itemData);
          }}
          onDecline={async () => {
            await declineRequest(itemData.id);
            await getRequests();
          }}
        />
      ),
    },
  ];
  return (
    <>
      <DataTable
        tableId="approvalRequests"
        columns={columns}
        totalCount={approvalRequests.length || 0}
        items={approvalRequests}
        emptyNote={labels.noRequests}
        loading={loading}
        infiniteScroll={{ loading }}
        onPageChanged={() => null}
        rowIsNewIdentifier={(item) => showNewBadge({ value: item })}
        dataTableCardStyle={{
          backgroundColor: colors.lightPurple,
        }}
        mobileBreakpoint={DeviceSizeDefaults.tablet}
        mobileRenderItem={(item: ConsultationModel, content) => {
          return (
            <View
              style={[
                {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                },
              ]}>
              <View style={{ ...styles.iconBackground }}>
                {item.type === ConsultationTypeEnum.HOME_VISIT ? (
                  <Home color={colors.info} height={20} width={20} />
                ) : (
                  <Stethoscope color={colors.info} height={20} width={20} />
                )}
              </View>
              <View style={{ flex: 1 }}>
                <View>
                  <Text style={{ flexWrap: 'wrap' }}>
                    <H5TtmSemiBoldBlack>{`${item.patient.first_name}  ${item.patient.last_name}`}</H5TtmSemiBoldBlack>
                    <H5TtmRegularBlack>
                      {item.type === ConsultationTypeEnum.HOME_VISIT
                        ? ` has requested a `
                        : ` has requested an appointment for `}
                    </H5TtmRegularBlack>
                    <H5TtmSemiBoldBlack>{`${ConsultationTypeName[item?.type]}`}</H5TtmSemiBoldBlack>
                  </Text>
                </View>
                {content?.date?.cellData ? (
                  <SmallNsRegularSecondaryBlack style={mt10}>{content.date.cellData}</SmallNsRegularSecondaryBlack>
                ) : null}
              </View>
              <View>
                <TouchableOpacity
                  onPress={() => {
                    openApprovalRequestModal(item);
                  }}
                  key={item.id}>
                  <MoreIcon height={20} width={20} color={colors.purple} />
                </TouchableOpacity>
              </View>
            </View>
          );
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  iconBackground: {
    backgroundColor: colors.white,
    padding: 10,
    borderRadius: 30,
    marginRight: 10,
  },
  patientCell: {
    minWidth: 205,
  },
  patientContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    flexDirection: 'column',
    flex: 1,
  },
  reasonHeader: {
    minWidth: 110,
    padding: 5,
  },
  reasonCell: {
    minWidth: 110,
  },
  typeHeader: {
    padding: 5,

    minWidth: 150,
  },
  typeCell: {
    minWidth: 150,
  },
  consultationContainer: {
    flexDirection: 'column',
  },
  type: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  typeInfo: {
    flexDirection: 'row',
  },
  actionContainer: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  header2: {
    minWidth: 200,
    justifyContent: 'center',
  },
});
