import React, { useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { BillingRequestForm } from './BillingHistoryProvider';

import { BillingHistoryIndexResponse } from '~/api/models/billingHistory/responses/BillingHistoryIndexResponse';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';

export interface IBillingHistoryListingContext {
  form: UseFormReturn<BillingRequestForm, any>;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  submitQuery: () => void;
  billingHistory: BillingHistoryIndexResponse;
  setPage: (pageInfo: TablePaginationInfo) => void;
}

export const BillingHistoryContext = React.createContext<IBillingHistoryListingContext>(
  {} as IBillingHistoryListingContext
);

export const useBillingHistoryContext = (): IBillingHistoryListingContext => useContext(BillingHistoryContext);
