import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { View } from 'react-native';

import { DependantCard } from './DependantCard';

import { useDependantsHook } from '~/api/hooks/dependants/DependantsHook';
import { DependantModel } from '~/api/models/dependants/models/DependantModel';
import { textCenter } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { InfiniteList } from '~/components/common/InfiniteList/InfiniteList';
import { ButtonLink } from '~/components/commonButton';
import { H6NsRegularBlack } from '~/components/commonText';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { NavType } from '~/navigation/types';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { dependantsLabels } from '~/utils/labels/dependantsLabels';
import { screenName } from '~/utils/screenName';

export const DependantsListing: React.FC = () => {
  const { dependants } = useDependantsHook();
  const { navigate } = useNavigation<NavType>();
  const [columnCount, setColumnCount] = useState(1);
  const { isMobile } = useBreakpoints();
  const addDependantButton = (
    <Grid>
      <Container>
        <Row justifyContent="flex-end">
          <Column span={{ xs: 12, md: 'auto' }}>
            <ButtonLink
              label={dependantsLabels.addDependant}
              screenName={screenName.DependantsAddNew}
              style={textCenter}
            />
          </Column>
        </Row>
      </Container>
    </Grid>
  );
  return (
    <PageLayout
      hideBack
      title={dependantsLabels.dependantsTitle}
      footer={isMobile ? addDependantButton : null}
      hideHeaderRight={isMobile}
      headerRight={addDependantButton}
      scrollContentNode={
        <View
          style={{ flex: 1 }}
          onLayout={(event) => {
            const { width } = event.nativeEvent.layout;
            setColumnCount(Math.max(1, Math.floor(width / 300)));
          }}>
          <InfiniteList<DependantModel>
            key={'dependants' + columnCount}
            listProps={{
              contentContainerStyle: { paddingHorizontal: isMobile ? MOBILE_HORIZONTAL_SPACE : 0 },
              ListHeaderComponent: (
                <View style={{ maxWidth: 400, marginBottom: 20 }}>
                  <H6NsRegularBlack>{dependantsLabels.dependantsSubtitle}</H6NsRegularBlack>
                </View>
              ),
              data: dependants,
              numColumns: columnCount,
              keyExtractor: (item) => `${item.id}`,
              renderItem: ({ item }) => (
                <DependantCard
                  vertical={columnCount === 1}
                  dependant={item}
                  showAge
                  onPress={() => {
                    navigate(screenName.DependantsShow, { id: item.id });
                  }}
                />
              ),
              estimatedItemSize: 92,
            }}
          />
        </View>
      }
    />
  );
};
