import { useRef } from 'react';

export const usePolling = (fetchFn: () => any, interval: number) => {
  const timerRef = useRef(null);

  const fetchData = (interval: number, immediate?: boolean) => {
    timerRef.current = setTimeout(() => {
      fetchFn();
      if (isPolling()) {
        fetchData(interval);
      }
    }, interval);

    if (immediate) {
      fetchFn();
    }
  };

  const startPolling = (options?: { immediate?: boolean; interval?: number }) => {
    if (!isPolling() && typeof fetchFn === 'function') {
      fetchData(options?.interval ?? interval, options?.immediate);
    }
  };

  const stopPolling = () => {
    clearTimeout(timerRef.current);
    timerRef.current = null;
  };

  const isPolling = () => {
    return !!timerRef.current;
  };

  return { startPolling, stopPolling, isPolling };
};
