import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import React from 'react';

import { TabNavigationComponent } from '~/components/navigation/TabSelection/TabNavigationComponent';
import { TabsComponent } from '~/components/tabs/TabsComponent';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  tabProps: MaterialTopTabBarProps;
}

export const CustomMaterialTabNavigation: React.FC<Props> = ({ tabProps }) => {
  const { isMobile } = useBreakpoints();
  if (isMobile) {
    return <TabNavigationComponent {...tabProps} />;
  }
  return <TabsComponent tabProps={tabProps} compact hasBottomMargin />;
};
