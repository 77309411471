export enum ConsultationTypeEnum {
  HOME_VISIT = 'home_visit',
  ON_DEMAND = 'on_demand',
  SCHEDULED_APPOINTMENT = 'scheduled_appointment',
  CLINIC = 'clinic',
}

export const ConsultationTypeIdMapping = {
  [ConsultationTypeEnum.HOME_VISIT]: 1,
  [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: 2,
  [ConsultationTypeEnum.ON_DEMAND]: 3,
  [ConsultationTypeEnum.CLINIC]: 4,
};

export const ConsultationTypeMapping = {
  [ConsultationTypeEnum.CLINIC]: 'Clinic',
  [ConsultationTypeEnum.HOME_VISIT]: 'Home visit',
  [ConsultationTypeEnum.ON_DEMAND]: 'On demand',
  [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: 'Scheduled appointment',
};
