export enum NotificationType {
  PRODUCT_NEWS = 'productNews',
  REMINDERS = 'reminders',
  CONSULTATION_MESSAGE = 'consultationMessage',
  HEALTH_COMPANION = 'prescriptionReminder',
}

export enum SettingType {
  MAIL = 'mail',
  NOTIFICATION = 'notification',
}
