import React, { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { BillingHistoryContext } from './BillingHistoryContext';
import { TablePaginationInfo } from '../../DataTable/DataTableTypes';
import { getLastMonth, getLastWeek } from '../utils/FilterMenuUtils';

import { useReduxTablePagination } from '~/api/hooks/common/useReduxTablePagination';
import { BillingHistoryFilterModel } from '~/api/models/billingHistory/filters/BillingHistoryFilterModel';
import { BillingHistoryIndexResponse } from '~/api/models/billingHistory/responses/BillingHistoryIndexResponse';
import { apiGetBillingHistoryDataIndex } from '~/api/services/billingHistory';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { NUM_DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  children: React.ReactNode;
}

export interface BillingRequestForm {
  filter: {
    type: string[];
    max_date: string[];
    min_date: string[];
    year: string[];
  };
}

export const BillingHistoryProvider: React.FC<Props> = ({ children }) => {
  const form = useForm<BillingRequestForm>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      filter: { max_date: undefined, min_date: [], type: [], year: [] },
    },
  });

  const [loading, setLoading] = useState(true);
  const { filter } = useWatch({ control: form.control });
  const loadingRef = useRef(false);
  const { isMobile } = useBreakpoints();

  const { items, pagination, setPagination, resetTable } = useReduxTablePagination<
    BillingHistoryFilterModel,
    BillingHistoryIndexResponse
  >({
    isInfiniteScroll: isMobile,
    tableName: 'billingHistory',
    getItems: async (params) => {
      setLoading(true);
      const data = form.getValues();

      const year = data.filter.year.length > 0 ? Number(data.filter.year[0]) : undefined;

      let minDate = undefined;
      if (data.filter.min_date[0]?.includes('last-month')) {
        minDate = getLastMonth();
      } else if (data.filter.min_date[0]?.includes('last-week')) {
        minDate = getLastWeek();
      }

      const response = await apiGetBillingHistoryDataIndex({
        page: params.page,
        limit: 15,
        filter: {
          year,
          min_date: minDate,
          max_date: parseDateTime(new Date(), { outputFormat: NUM_DATE_FORMAT }),
          type: data.filter.type?.length > 0 ? data.filter.type?.join(',') : undefined,
        },
        sort: '-created_at',
      });
      setLoading(false);
      return response;
    },
  });

  useEffect(() => {
    resetTable();
  }, [filter.year, filter.min_date, filter.type]);

  const submitQuery = async () => {
    try {
      if (loadingRef.current) return;
      setLoading(true);
      loadingRef.current = true;
      await resetTable();
    } catch (e) {
      ErrorAlert(e);
    } finally {
      loadingRef.current = false;
      setLoading(false);
    }
  };

  const setPage = (pageInfo: TablePaginationInfo) => {
    if (!items || pageInfo.page === 0) return;
    setPagination(pageInfo);
  };

  return (
    <BillingHistoryContext.Provider
      value={{
        form,
        loading,
        setLoading,
        submitQuery: form.handleSubmit(submitQuery),
        billingHistory: { data: items, meta: pagination, links: [] },
        setPage,
      }}>
      {children}
    </BillingHistoryContext.Provider>
  );
};
