export function capitalize(str?: string) {
  return typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

export function convertToSentenceCase(str) {
  if (typeof str !== 'string' || str.length === 0) {
    return '';
  }

  // Split the string into words using whitespace as the delimiter
  const words = str.split(' ');

  // Capitalize the first letter of each word and convert the remaining letters to lowercase
  const sentenceCaseWords = words.map((word) => {
    const lowercasedWord = word.toLowerCase();
    const capitalizedWord = lowercasedWord.charAt(0).toUpperCase() + lowercasedWord.slice(1);
    return capitalizedWord;
  });

  // Join the words back into a string and return it
  return sentenceCaseWords.join(' ');
}
