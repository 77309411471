import React, { useMemo, useRef, useState } from 'react';
import { StyleSheet, View, TextInput as RNTextInput } from 'react-native';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { textCenter } from '~/common/commonStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { H6NsRegularBlack } from '~/components/commonText';
import { CommonButton } from '~/components/commonViews';
import { AddImage, PaperClip, Send } from '~/components/svgImages';
import { DocumentsAllowedFormats } from '~/constants/documentUploadsConstants';
import { useAppointmentChatContext } from '~/providers/appointment/AppointmentChatContext';
import { isDoctorVersion, isNative } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { fontFamily } from '~/utils/fontFamily';
import { useUploadDocumentHook } from '~/utils/hooks/UploadDocumentHook';

interface Props {
  consultation: ConsultationModel;
}

export const ChatMessageInput: React.FC<Props> = ({ consultation }) => {
  const [message, setMessage] = useState('');
  const typingRef = useRef<NodeJS.Timeout>();
  const textInputRef = useRef<RNTextInput>();
  const { sendMessage, sendMedia, setTyping, isTimeForConsultation } = useAppointmentChatContext();
  const [localTyping, setLocalTyping] = useState(false);
  const hideCamera = useRef(!isNative());
  const { launchImagePicker, launchFilePicker, launchCamera } = useUploadDocumentHook();

  const canJoinChat = useMemo(
    () =>
      consultation?.state === ConsultationStateEnum.Started ||
      (consultation?.state === ConsultationStateEnum.Scheduled && isDoctorVersion() && isTimeForConsultation),
    [consultation, isTimeForConsultation]
  );

  const cannotJoinMessage = useMemo(() => {
    switch (consultation?.state) {
      case ConsultationStateEnum.Scheduled: {
        if (!isTimeForConsultation) {
          return "It's still early for your appointment to start";
        }
        return isDoctorVersion()
          ? 'Join the chat to start the consultation.'
          : 'Waiting for the doctor to start the conversation';
      }
      case ConsultationStateEnum.Ended:
        return 'This appointment has ended and is pending the consultation report submission.';
      case ConsultationStateEnum.Submitted:
        return 'Consultation has been completed.';
      default:
        return 'Consultation is not active.';
    }
  }, [consultation?.state]);

  const trySendMessage = async () => {
    const trimmedMessage = message.trim();
    if (!trimmedMessage) return;

    updateMessage('');

    await sendMessage(trimmedMessage);
  };

  const tryUpdateMessage = (message: string) => {
    updateMessage(message);
  };

  const updateMessage = (message) => {
    const typing = !!message;
    if (localTyping !== typing) {
      setTyping(typing);
    }
    setLocalTyping(typing);

    if (typingRef.current) {
      clearTimeout(typingRef.current);
      typingRef.current = null;
    }

    if (typing) {
      typingRef.current = setTimeout(() => {
        setTyping(false);
        setLocalTyping(false);
      }, 1000);
    }
    setMessage(message);
  };

  const onBlur = () => {
    if (typingRef.current) {
      clearTimeout(typingRef.current);
      typingRef.current = null;
    }
  };

  const attachImage = async () => {
    const result = isNative() ? await launchCamera() : await launchImagePicker();
    if (result?.length) sendMedia(result[0]);
  };

  const attachFile = async () => {
    const result = await launchFilePicker({ allowedFormats: DocumentsAllowedFormats });
    if (result?.length) sendMedia(result[0]);
  };

  return (
    <View style={styles.container}>
      {!canJoinChat ? (
        <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <H6NsRegularBlack style={textCenter}>{cannotJoinMessage}</H6NsRegularBlack>
        </View>
      ) : (
        <>
          <View style={styles.textInputContainer}>
            <RNTextInput
              ref={textInputRef}
              style={styles.textInput}
              placeholder="Type your message"
              value={message}
              onChangeText={tryUpdateMessage}
              onSubmitEditing={trySendMessage}
              keyboardType="default"
              returnKeyType="send"
              onBlur={onBlur}
              maxLength={300}
              blurOnSubmit={false}
            />
            {hideCamera.current ? null : (
              <IconButton transparent onPress={attachImage}>
                <AddImage width={18} height={15} color={colors.purple} />
              </IconButton>
            )}
            <IconButton transparent onPress={attachFile}>
              <PaperClip width={18} height={15} color={colors.purple} />
            </IconButton>
          </View>
          <View>
            <CommonButton onPress={trySendMessage}>
              <Send width={22} height={22} color={colors.white} />
            </CommonButton>
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  textInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.lightPurple,
    marginRight: 10,
    borderRadius: 6,
    flex: 1,
    padding: 3,
  },
  textInput: {
    backgroundColor: colors.transparent,
    fontFamily: fontFamily.fontRegular,
    borderWidth: 0,
    fontSize: 12,
    height: 35,
    flex: 1,
    paddingLeft: 10,
  },
});
