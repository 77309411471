import React from 'react';

import { useClinicReportExport } from '~/api/hooks/clinics/ClinicReportExport';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { Button } from '~/components/commonButton';
import { Grid, Container, Column, Row } from '~/theme/components/grid';

interface Props {
  consultation: ConsultationModel;
}

export const ClinicCompletedConsultationAction: React.FC<Props> = ({ consultation }) => {
  const { exportConsultation } = useClinicReportExport();
  if (consultation?.state !== ConsultationStateEnum.Submitted) return;

  return (
    <Grid grid={{ gutters: 10 }}>
      <Container>
        <Row justifyContent="flex-end">
          <Column span="auto">
            <Button label="Export Consultation Report" funCallback={() => exportConsultation(consultation)} />
          </Column>
        </Row>
      </Container>
    </Grid>
  );
};
