import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ApproveConsultationRequest } from '~/api/models/consultations/requests/ApproveConsultationRequest';
import { apiApproveConsultation, apiDeclineConsultation } from '~/api/services/consultations/request';
import { MissingConsultationRequestError } from '~/classes/errors/MissingConsultationError';
import { ConfirmationAlert, SuccessAlert } from '~/common/commonMethods';
import { mt10 } from '~/common/commonStyles';
import { Button, OutlineButton, TextButton } from '~/components/commonButton';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { NavType } from '~/navigation/types';
import { DATE_TIME_FORMAT, LARAVEL_ATOM, parseDateTime } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { bookingLabels } from '~/utils/labels/booking';
import { screenName } from '~/utils/screenName';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useApprovalRequestsList } from '~/api/hooks/patientDataHandling/useApprovalRequestsList';
import { appointmentLabels } from '~/utils/labels/appointments';
import { RequestApprovalForm } from '~/api/models/consultations/models/RequestApprovalForm';

interface Props {
  consultation: ConsultationModel;
  handleSubmit: UseFormHandleSubmit<RequestApprovalForm>;
  onApprovedChanged: (approved: boolean) => void;
  onIgnore: () => void;
  onReady: () => void;
  availabilitySlotSelected?: boolean;
  departureSlotSelected?: boolean;
}

export const HomeVisitApprovalRequestButtons: React.FC<Props> = ({
  consultation,
  onApprovedChanged,
  onIgnore,
  onReady,
  handleSubmit,
  availabilitySlotSelected,
  departureSlotSelected,
}) => {
  const [approved, setApproved] = useState<boolean>(null);
  const { navigate } = useNavigation<NavType>();
  const [loading, setLoading] = useState(false);
  const { openModal } = useModalManager();
  const { getRequests } = useApprovalRequestsList();

  const approveConsultation = (approveConsultationRequest: ApproveConsultationRequest) => {
    return apiApproveConsultation(approveConsultationRequest);
  };

  const declineConsultation = async () => {
    openModal(ModalName.RejectConsultationReasonModal, {
      consultation,
      title: appointmentLabels.declineConsultation,
      description: appointmentLabels.declineReason,
      buttonTitle: appointmentLabels.decline,
      declineApi: apiDeclineConsultation,
      onReady: async () => {
        onReady();
        SuccessAlert(['Consultation successfully declined']);
        await getRequests();
      },
    });
  };

  const approveRequest = async (data: RequestApprovalForm) => {
    try {
      setLoading(true);
      await approveConsultation({
        id: consultation.id,
        params: {
          expected_departure: parseDateTime(`${data.availabilitySlot.date} ${data.expectedDeparture.time_from}`, {
            parseFormat: DATE_TIME_FORMAT,
            outputFormat: LARAVEL_ATOM,
          }),
          time_from: parseDateTime(`${data.availabilitySlot.date} ${data.availabilitySlot.time_from}`, {
            parseFormat: DATE_TIME_FORMAT,
            outputFormat: LARAVEL_ATOM,
          }),
          time_to: parseDateTime(`${data.availabilitySlot.date} ${data.availabilitySlot.time_to}`, {
            parseFormat: DATE_TIME_FORMAT,
            outputFormat: LARAVEL_ATOM,
          }),
        },
      });
      onReady();
      ConfirmationAlert([bookingLabels.homeVisit.successMessage], {
        title: bookingLabels.appointmentApproved,
        okTitle: bookingLabels.viewCalendar,
        cancelTitle: labels.continue,
        okFunction: () => {
          navigate(screenName.CalendarPage, { screen: screenName.CalendarAppointments });
        },
      });
      await getRequests();
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setLoading(false);
    }
  };

  if (approved) {
    return (
      <>
        <Button
          style={[{ alignSelf: 'flex-end' }, mt10]}
          label={bookingLabels.homeVisit.setDepartureTimes}
          funCallback={handleSubmit(approveRequest)}
          disabled={loading || !departureSlotSelected}
        />
      </>
    );
  } else {
    return (
      <>
        <Button
          success
          label={labels.approve}
          funCallback={handleSubmit(() => {
            setApproved(true);
            onApprovedChanged(true);
          })}
          style={mt10}
          disabled={loading || !availabilitySlotSelected}
        />
        <OutlineButton
          danger
          label={labels.decline}
          funCallback={declineConsultation}
          style={mt10}
          disabled={loading}
        />
        <TextButton style={mt10} label={labels.ignoreForNow.toUpperCase()} funCallback={onIgnore} />
      </>
    );
  }
};
