import React from 'react';
import { FlexStyle, View, ViewProps } from 'react-native';

import { IGridContext, useGridContext } from './Grid';

interface Props {
  children: React.ReactNode;
  justifyContent?: FlexStyle['justifyContent'];
  alignItems?: FlexStyle['alignItems'];
  style?: ViewProps['style'];
  reverse?: boolean;
  flex?: boolean;
}

export const Row: React.FC<Props> = ({ children, justifyContent, alignItems, style, reverse, flex }) => {
  const grid = useGridContext();

  const baseStyle = {
    flexDirection: 'row',
    flexWrap: 'wrap',
  };

  const getGutterStyle = ({ gutter }: IGridContext) => {
    if (typeof gutter === 'undefined') {
      return [];
    }

    return {
      marginHorizontal: gutter / -2,
    };
  };

  const getContentJustification = (justifyContent) => {
    if (typeof justifyContent === 'string') {
      return {
        justifyContent,
      };
    }

    return [];
  };

  const getItemAlignment = (alignItems) => {
    if (typeof alignItems === 'undefined') {
      return [];
    }

    if (typeof alignItems === 'string') {
      return {
        alignItems,
      };
    }

    return [];
  };

  const getDirection = (reverse?: boolean) => {
    return { flexDirection: reverse ? 'row-reverse' : 'row' };
  };

  const getFlex = () => {
    return flex ? { flex: 1 } : [];
  };

  const styles = [].concat(
    baseStyle,
    getGutterStyle(grid),
    getContentJustification(justifyContent),
    getItemAlignment(alignItems),
    getDirection(reverse),
    getFlex(),
    style
  );
  return React.createElement(View, { style: styles }, children);
};
