import React from 'react';
import { View } from 'react-native';

import { mb20, mb5 } from '~/common/commonStyles';
import { ProgressBars } from '~/components/commonComponents';
import { BackNavigation } from '~/components/navigation/BackNavigation';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { useInsuranceClaimFormContext } from '~/providers/insurance/InsuranceClaimFormContext';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { insuranceLabels } from '~/utils/labels/insurance';
import { screenName } from '~/utils/screenName';

export const InsuranceClaimFormWizard: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const {
    wizard: {
      progress,
      state,
      actions: { previous },
    },
  } = useInsuranceClaimFormContext();

  const header = (
    <View style={[isMobile ? mb5 : mb20, { flexBasis: 315, flexGrow: isMobile ? 1 : 0, flexShrink: 1 }]}>
      <BackNavigation
        black
        title={insuranceLabels.insuranceClaimForm}
        backLink={{ screen: screenName.InsuranceClaims }}
        onPress={state.hasPrevious ? previous : null}
        titleStyle={{ flex: 1, alignItems: isMobile ? 'center' : undefined, display: 'flex', marginRight: 14 }}
      />
      <ProgressBars progress={progress.percentile} noSpacing />
    </View>
  );
  return <PageLayout header={header} scrollContentNode={state.component} />;
};
