import React, { useEffect, useMemo } from 'react';

import { mb20, mt10, mt35 } from '../../../common/commonStyles';
import { H3TtmSemiBoldBlack, SmallNsRegularSecondaryBlack } from '../../../components/commonText';
import { Column, Container, Row } from '../../../theme/components/grid';
import { labels } from '../../../utils/labels';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { OtpChannelEnum } from '~/api/models/otp/constants/OtpChannelEnum';
import { apiEnrolmentTotp, apiSetPreferred2faChannel } from '~/api/services/registration';
import { AuthenticatorEnrolmentModal } from '~/components/modals/AuthenticatorEnrolmentModal';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { ChangePasscode } from '~/components/passCode/ChangePasscode';
import { TwoStepVerificationButton } from '~/components/registration/TwoStepVerificationButton';
import { BiometricsToggle } from '~/components/settings/BiometricsToggle';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isNative } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

export const SettingsSecurity: React.FC = () => {
  const { userDetails, getUserDetails } = useUserDetails();

  const { registerModal, openModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.TotpEnrolment, AuthenticatorEnrolmentModal);
  }, []);

  const isMobileVerified = useMemo(
    () => !!userDetails?.mobile_number_verified_at,
    [userDetails?.mobile_number_verified_at]
  );

  const isEmailVerified = useMemo(() => !!userDetails?.email_verified_at, [userDetails?.email_verified_at]);

  const setTwoStepVerification = (channel: OtpChannelEnum) => {
    apiSetPreferred2faChannel({ channel })
      .then(() => {
        getUserDetails();
      })
      .catch((error) => {
        ErrorAlert(error);
      });
  };

  const setGoogleAuthentication = async () => {
    if (userDetails.two_step_verification_type !== OtpChannelEnum.GOOGLE) {
      await apiEnrolmentTotp()
        .then((value) => {
          openModal(ModalName.TotpEnrolment, {
            totpEnrolment: value.data,
          });
        })
        .catch((error) => {
          ErrorAlert(error);
        });
    }
  };

  return (
    <NestedPageInternalLayout backTitle="Settings" backLink={{ screen: screenName.Settings }} title={labels.security}>
      <Container alignSelf="flex-start">
        <Row>
          <Column span={{ xs: 12, lg: 6 }} style={{ maxWidth: 500 }}>
            <H3TtmSemiBoldBlack>{labels.twoStepVerification}</H3TtmSemiBoldBlack>
            <SmallNsRegularSecondaryBlack style={[mt10, mb20]}>
              {labels.twoStepVerificationSubtitle}
            </SmallNsRegularSecondaryBlack>

            {isMobileVerified ? (
              <TwoStepVerificationButton
                selectedOption={userDetails?.two_step_verification_type}
                setTwoStepVerification={setTwoStepVerification}
                title={labels.securityCodeSentViaSMS}
                value={OtpChannelEnum.SMS}
              />
            ) : null}

            {isEmailVerified ? (
              <TwoStepVerificationButton
                selectedOption={userDetails?.two_step_verification_type}
                setTwoStepVerification={setTwoStepVerification}
                title={labels.securityCodeSentViaEmail}
                value={OtpChannelEnum.EMAIL}
              />
            ) : null}

            <TwoStepVerificationButton
              selectedOption={userDetails?.two_step_verification_type}
              value={OtpChannelEnum.GOOGLE}
              setTwoStepVerification={setGoogleAuthentication}
              title={labels.securityCodeViaAuthenticatorApp}
            />
          </Column>
        </Row>
        {isNative() ? (
          <Row style={mt35}>
            <Column>
              <BiometricsToggle />
            </Column>
          </Row>
        ) : null}
        <Row style={mt35}>
          <Column>
            <ChangePasscode />
          </Column>
        </Row>
      </Container>
    </NestedPageInternalLayout>
  );
};
