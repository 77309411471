import { useNavigation } from '@react-navigation/native';
import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AppointmentBookingContext } from './AppointmentBookingContext';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { useDependantsHook } from '~/api/hooks/dependants/DependantsHook';
import {
  PatientConsultationFormRequest,
  PatientConsultationRequest,
} from '~/api/models/appointments/requests/PatientConsultationRequest';
import { PatientConsultationResponse } from '~/api/models/appointments/responses/PatientConsultationResponse';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import {
  apiAppendConsultationReasonMedia,
  apiCreateConsultationRequest,
  apiGetConsultationRequest,
  apiRemoveConsultationReasonMedia,
  apiSubmitConsultationRequest,
  apiUpdateConsultationRequest,
} from '~/api/services/appointments/patient';
import { apiGetConsultation } from '~/api/services/consultations';
import { ConfirmationAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import { AppointmentStepsInitialValues } from '~/components/patient/booking/constants/appointmentWizardSteps';
import { NavType } from '~/navigation/types';
import { clinicalTermToLabelValue } from '~/utils/clinicalTerms';
import { DATE_FORMAT, NUM_DATE_FORMAT, TIME_FORMAT, currentDateTime, parseDateTime } from '~/utils/dateAndTime';
import { useNavigationHealthProfile } from '~/utils/hooks/AppNavigationHook';
import { usePageFocusState } from '~/utils/hooks/FocusHook';
import { useWizardSteps } from '~/utils/hooks/WizardStepsHook';
import { useAppointmentSteps } from '~/utils/hooks/appointments/AppointmentStepsHook';
import { transformLabel, transformLabels } from '~/utils/labelUtil';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';
import { healthProfileLabels } from '~/utils/labels/healthProfileLabels';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { screenName } from '~/utils/screenName';

interface Props {
  children: React.ReactNode;
  initialValues?: {
    consultationId?: number;
    doctorId?: number;
    clinicId?: number;
    channelId?: number;
    consultationType?: ConsultationTypeEnum;
  };
}

const DEFAULT_FORM: PatientConsultationFormRequest = {
  patient_id: undefined,
  doctor_id: undefined,
  clinic_account_id: undefined,
  reason: '',
  type: undefined,
  symptoms: [],
  is_sick_leave_certificate_required: false,
  channel_id: undefined,
  location: undefined,
  available_time_ranges: [{ from: undefined, to: undefined }],
  scheduled: undefined,
  reason_media: [],
  formattedAddress: '',
  available_time_date: currentDateTime(NUM_DATE_FORMAT),
};

const formDataToRequest = (data: PatientConsultationFormRequest, userAccountId: number): PatientConsultationRequest => {
  const available_time_ranges =
    data.type === ConsultationTypeEnum.HOME_VISIT
      ? data.available_time_ranges
          ?.filter((timeRange) => timeRange.from && timeRange.to)
          .map((timeRange) => ({
            from: `${data.available_time_date} ${timeRange.from}`,
            to: `${data.available_time_date} ${timeRange.to}`,
          }))
      : undefined;

  return {
    patient_id: data.patient_id || userAccountId,
    doctor_id: data.doctor_id,
    clinic_account_id: data.clinic_account_id,
    reason: data.reason,
    type: data.type,
    is_sick_leave_certificate_required: data.is_sick_leave_certificate_required,
    channel_id: data.channel_id,
    location: data.location,
    symptoms: data.symptoms?.map((item) => item.value),
    start_at:
      data.type === ConsultationTypeEnum.SCHEDULED_APPOINTMENT && data.scheduled?.start_at
        ? `${data.available_time_date} ${data.scheduled.start_at}`
        : undefined,
    end_at:
      data.type === ConsultationTypeEnum.SCHEDULED_APPOINTMENT && data.scheduled?.end_at
        ? `${data.available_time_date} ${data.scheduled.end_at}`
        : undefined,
    available_time_ranges: available_time_ranges?.length ? available_time_ranges : undefined,
  };
};

export const AppointmentBookingProvider: React.FC<Props> = ({ children, initialValues }) => {
  const { userDetails } = useUserDetails();
  const { navigate } = useNavigation<NavType>();
  const { fetchDependants } = useDependantsHook();
  const { navigate: navigateHealthProfile, generateUrl: generateNavigationUrl } = useNavigationHealthProfile();

  const { control, handleSubmit, reset, getValues, setValue } = useForm<PatientConsultationFormRequest>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      ...DEFAULT_FORM,
    },
  });

  const [loading, setLoading] = useState(true);
  const [consultationRequest, setConsultationRequest] = useState<PatientConsultationResponse>(null);
  const [saving, setSaving] = useState(false);

  const { wizardSteps, updateSteps, resetSteps } = useAppointmentSteps();

  const wizard = useWizardSteps({ steps: wizardSteps, loading });

  const getWizardInitialValues = async () => {
    const dependants = await fetchDependants();
    return {
      ...initialValues,
      hasDependants: !!dependants?.length,
    };
  };

  useEffect(() => {
    setValue('reason_media', consultationRequest?.consultation?.reason_media ?? []);
  }, [consultationRequest?.consultation?.reason_media]);

  const updateConsultationRequest = async (data: PatientConsultationFormRequest) => {
    const request = formDataToRequest(data, userDetails.account.id);

    const res = consultationRequest?.consultation?.id
      ? await apiUpdateConsultationRequest(consultationRequest.consultation.id, request)
      : await apiCreateConsultationRequest(request);

    setConsultationRequest(res.data);
    return res.data;
  };

  const getConsultationRequest = async (consultationId: number) => {
    const res = await apiGetConsultationRequest(consultationId);
    setConsultationRequest(res.data);
    return res.data;
  };

  const updateRequest = async (data: PatientConsultationFormRequest) => {
    setSaving(true);
    try {
      await updateConsultationRequest(data);
    } catch (e) {
      ErrorAlert(e);
    }
    setSaving(false);
  };

  const appendMedia = async (document: DocumentPickerAsset) => {
    try {
      let consultationId = consultationRequest?.consultation.id;
      if (!consultationId) {
        const res = await updateConsultationRequest(getValues());
        consultationId = res.consultation.id;
      }
      await apiAppendConsultationReasonMedia(consultationId, document);
      await getConsultationRequest(consultationId);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const removeMedia = async (mediaId: number) => {
    try {
      await apiRemoveConsultationReasonMedia(consultationRequest.consultation.id, [mediaId]);
      await getConsultationRequest(consultationRequest.consultation.id);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const viewHealthProfile = () => {
    navigateHealthProfile(consultationRequest.consultation);
  };

  const generateHealthProfileUrl = () => {
    return generateNavigationUrl(consultationRequest.consultation);
  };

  const submitRequest = async ({ paymentMethodId }) => {
    try {
      setSaving(true);
      await apiSubmitConsultationRequest({
        consultationId: consultationRequest.consultation.id,
        params: {
          payment_method_id: paymentMethodId,
        },
      });

      if (consultationRequest.consultation.type === ConsultationTypeEnum.SCHEDULED_APPOINTMENT) {
        ConfirmationAlert(
          transformLabels(appointmentLabels.appointmentBookedScheduledMessage, {
            channel: consultationRequest.consultation.channel.name.toLowerCase(),
            type: consultationRequest.consultation.channel?.name?.toLowerCase(),
            date: parseDateTime(consultationRequest?.consultation.start_at, { outputFormat: DATE_FORMAT }),
            time: parseDateTime(consultationRequest?.consultation.start_at, { outputFormat: TIME_FORMAT }),
          }),
          {
            type: ModalAlertTypeEnum.SUCCESS,
            title: appointmentLabels.appointmentBookedTitle,
            okTitle: labels.continue,
            okFunction: () => navigate(screenName.Appointments),
            cancelTitle: healthProfileLabels.view.toUpperCase(),
            cancelFunction: viewHealthProfile,
          }
        );
      } else {
        ConfirmationAlert(
          [
            transformLabel(appointmentLabels.appointmentPendingMessage, {
              doctor: getAccountName(
                consultationRequest.consultation.doctor || consultationRequest.consultation.clinic
              ),
            }),
            consultationRequest?.consultation?.clinic ? appointmentLabels.appointmentPendingMessageClinic : undefined,
          ],
          {
            type: ModalAlertTypeEnum.IN_PROGRESS,
            title: appointmentLabels.appointmentPendingTitle,
            okTitle: labels.continue,
            okFunction: () => navigate(screenName.Appointments),
            cancelTitle: healthProfileLabels.view.toUpperCase(),
            cancelFunction: viewHealthProfile,
          }
        );
      }
    } catch (e) {
      ErrorAlert(e);
    }
    setSaving(false);
  };

  const validateRequest = async (onSuccess: () => Promise<void>) => {
    return handleSubmit(async () => {
      if (typeof onSuccess === 'function') await onSuccess();
      wizard.actions.next();
    })();
  };

  const resetValues = (consultation?: ConsultationModel, initValues?: AppointmentStepsInitialValues) => {
    if (consultation) {
      const doctorId =
        consultation.type === ConsultationTypeEnum.ON_DEMAND ||
        consultation.state === ConsultationStateEnum.RequestIgnored ||
        consultation.state === ConsultationStateEnum.RequestRejected ||
        consultation.clinic
          ? undefined
          : consultation.doctor?.id;
      const clinicAccountId =
        consultation.type === ConsultationTypeEnum.ON_DEMAND ||
        consultation.state === ConsultationStateEnum.RequestIgnored ||
        consultation.state === ConsultationStateEnum.RequestRejected
          ? undefined
          : consultation.clinic?.id;

      reset(
        {
          ...DEFAULT_FORM,
          patient_id: consultation.patient?.id,
          type: consultation.type,
          doctor_id: doctorId,
          clinic_account_id: clinicAccountId,
          channel_id: consultation.channel?.id,
          symptoms: consultation.patient_symptoms?.map((symptom) => clinicalTermToLabelValue(symptom)),
          is_sick_leave_certificate_required: consultation.is_sick_leave_certificate_required,
          reason: consultation.reason,
        },
        { keepDefaultValues: true }
      );
      updateSteps(consultation.type, initValues);
    } else {
      reset(
        {
          ...DEFAULT_FORM,
          type: initialValues?.consultationType,
          doctor_id: initialValues?.doctorId,
          clinic_account_id: initValues?.clinicId,
          channel_id: initialValues?.channelId,
        },
        { keepDefaultValues: true }
      );
      resetSteps(initValues);
    }
  };

  const setInitialValues = async (resetAll?: boolean) => {
    setLoading(true);

    const consultationRes = initialValues?.consultationId
      ? await apiGetConsultation({ id: initialValues.consultationId }).catch(() => {
          return undefined;
        })
      : undefined;
    const initValues = await getWizardInitialValues();

    if (resetAll) {
      resetValues(consultationRes?.data, initValues);
      setConsultationRequest(null);
    }

    const { type } = getValues();
    updateSteps(type, initValues);

    setLoading(false);
  };

  usePageFocusState((focused) => {
    if (focused) {
      setInitialValues(true);
    } else {
      // reset data
      reset();
      resetSteps();
      wizard.reset();
    }
  });

  return (
    <AppointmentBookingContext.Provider
      value={{
        consultationRequest,
        formControl: control,
        wizard,
        updateRequest: handleSubmit(updateRequest),
        validateRequest,
        updateSteps: setInitialValues,
        submitRequest,
        generateHealthProfileUrl,
        media: {
          appendMedia,
          removeMedia,
        },
        loading,
        saving,
      }}>
      {children}
    </AppointmentBookingContext.Provider>
  );
};
