import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React from 'react';

import { SettingsConsultations } from '../../pages/doctor/settings/SettingsConsultations';
import { screenName } from '../../utils/screenName';

import { CustomMaterialTabNavigation } from '~/components/navigation/CustomMaterialTabNavigation';
import { ProfilePaymentDetails } from '~/pages/profile/common/ProfilePaymentDetails';
import { SettingsClinicAddress } from '~/pages/settings/SettingsClinicAddress';
import { isNative } from '~/utils/buildConfig';

const Tab = createMaterialTopTabNavigator();

export const SettingsClinicNavigation = () => {
  return (
    <Tab.Navigator
      tabBar={(props) => <CustomMaterialTabNavigation tabProps={props} />}
      screenOptions={{ swipeEnabled: isNative() }}>
      <Tab.Screen
        options={{ title: 'Consultations' }}
        name={screenName.SettingsClinicConsultations}
        component={SettingsConsultations}
      />

      <Tab.Screen
        options={{ title: 'Payment Details' }}
        name={screenName.SettingsClinicPaymentDetails}
        component={ProfilePaymentDetails}
      />

      <Tab.Screen
        options={{ title: 'Clinic Address' }}
        name={screenName.SettingsClinicAddress}
        component={SettingsClinicAddress}
      />
    </Tab.Navigator>
  );
};
