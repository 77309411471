import React, { useMemo } from 'react';
import { ScrollView, StyleProp, View, ViewStyle } from 'react-native';

import { FamilyMembers } from './FamilyMembers';
import { HealthProfileCompletion } from './HealthProfileCompletion';
import { Insurance } from './Insurance';
import { useProfileCompletionContext } from './provider/ProfileCompletionContext';
import { ProfileCompletionProvider } from './provider/ProfileCompletionProvider';

interface Props {
  style?: StyleProp<ViewStyle>;
  nodeWhenComplete?: React.ReactNode;
}
const CompleteProfileContent: React.FC<Props> = ({ nodeWhenComplete, style }) => {
  const completionStatus = useProfileCompletionContext();

  const stepsWithCompletion = useMemo(() => {
    return [
      { key: 'name_and_id', completed: true },
      { key: 'address', completed: true },
      { key: 'health_profile', completed: completionStatus.healthProfile },
      { key: 'upgrade_plan', completed: completionStatus.upgradedPlan },
      { key: 'insurance', completed: completionStatus.insurance },
      { key: 'family_members', completed: completionStatus.familyMembers },
    ];
  }, [completionStatus]);

  const complete = useMemo(() => stepsWithCompletion.every((step) => step.completed), [stepsWithCompletion]);

  if (completionStatus.loading || (complete && !nodeWhenComplete)) return null;
  return (
    <View style={[{ display: 'flex', flex: 1 }, style]}>
      {!complete ? (
        <>
          {completionStatus.loading ? null : (
            <ScrollView showsVerticalScrollIndicator={false}>
              {!completionStatus.healthProfile ? (
                <View style={{ marginBottom: 25 }}>
                  <HealthProfileCompletion />
                </View>
              ) : null}
              {!completionStatus.upgradedPlan || !completionStatus.familyMembers ? (
                <View style={{ marginBottom: 25 }}>
                  <FamilyMembers />
                </View>
              ) : null}
              {(!completionStatus.upgradedPlan || !completionStatus.insurance) &&
              (completionStatus.healthProfile || completionStatus.familyMembers) ? (
                <View>
                  <Insurance />
                </View>
              ) : null}
            </ScrollView>
          )}
        </>
      ) : (
        nodeWhenComplete
      )}
    </View>
  );
};

export const CompleteProfile: React.FC<Props> = (props) => {
  return (
    <ProfileCompletionProvider>
      <CompleteProfileContent {...props} />
    </ProfileCompletionProvider>
  );
};
