import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import Icon from '@expo/vector-icons/MaterialIcons';

import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { AvailableDoctorModel } from '~/api/models/appointments/models/AvailableDoctorModel';
import { H6NsRegularSecondaryBlack, SmallNsSemiBoldBlack } from '~/components/commonText';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';

interface Props {
  clinic?: AccountModel;
  account?: AccountModel;
  doctor?: AvailableDoctorModel;
  clinicName?: string;
  large?: boolean;
  fallback?: string;
}

export const DoctorClinicName: React.FC<Props> = ({ account, doctor, clinic, clinicName, large, fallback }) => {
  const extractedClinicName = useMemo(
    () => account?.clinic?.name || doctor?.clinic?.name || clinic?.clinic_name || clinicName,
    [account?.clinic, doctor?.clinic, clinic?.clinic_name, clinicName]
  );

  const clinicImage = useMemo(
    () => clinic?.profile_image?.conversions?.avatar || clinic?.profile_image?.url,
    [clinic?.profile_image]
  );

  if (!extractedClinicName && !fallback) return null;

  return (
    <View style={styles.row}>
      {extractedClinicName ? (
        clinicImage ? (
          <ProfileImageComponent src={clinicImage} style={{ marginRight: 4, borderRadius: 2 }} size={16} />
        ) : (
          <Icon name="local-hospital" size={16} style={{ marginRight: 2 }} />
        )
      ) : null}
      {large ? (
        <H6NsRegularSecondaryBlack children={extractedClinicName || fallback} />
      ) : (
        <SmallNsSemiBoldBlack children={extractedClinicName || fallback} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
