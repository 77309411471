import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useContext } from 'react';
import { Control } from 'react-hook-form';

import { HealthMetricsFormModel } from './forms/HealthMetricsFormModel';
import {
  DietaryRestrictionsFormModel,
  LifestyleRadioFormsModel,
  ProsthesisFormModel,
  RecreationalDrugsFormModel,
  SocialFactorsFormModel,
} from './forms/HealthProfileFormModels';

import { ClinicalTermLabelValue } from '~/api/hooks/clinicalTerms/ClinicalTermsSearchHook';
import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { HealthProfileModel } from '~/api/models/healthProfile/Models/HealthProfileModel';
import { PatientHistoryData } from '~/components/doctor/patients/healthProfile/models/PatientHistoryData';

export interface IHealthProfileDataContext {
  accountId?: number;
  healthProfile: HealthProfileModel;
  setHealthProfile: (value: HealthProfileModel) => void;

  patientHistory: PatientHistoryData;
  setPatientHistory: (
    type: ClinicalTermTypeEnum,
    termLabelValuePair: ClinicalTermLabelValue,
    documents: DocumentPickerAsset[]
  ) => void;
  removePatientHistoryDataType: (type: ClinicalTermTypeEnum, termId: string) => void;
  removePatientHistoryDataFile: (type: ClinicalTermTypeEnum, termId: string, document: DocumentPickerAsset) => void;
  getHealthProfile: () => Promise<void>;
  loading: boolean;
  waiting: boolean;
  submit: () => Promise<void>;
  reset: () => void;
  hasWriteAccess: boolean;

  controlMetrics: Control<HealthMetricsFormModel>;
  controlLifestyleRadioOptions: Control<LifestyleRadioFormsModel>;
  controlProsthesis: Control<ProsthesisFormModel>;
  controlDietaryRestrictions: Control<DietaryRestrictionsFormModel>;
  controlRecreationalDrugs: Control<RecreationalDrugsFormModel>;
  controlSocialFactors: Control<SocialFactorsFormModel>;
}

export const HealthProfileDataContext = React.createContext<IHealthProfileDataContext>({} as IHealthProfileDataContext);
export const useHealthProfileDataContext = (): IHealthProfileDataContext => useContext(HealthProfileDataContext);
