import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { View, Text } from 'react-native';

import { mt10, mt25 } from '../../common/commonStyles';
import { Button } from '../../components/commonButton';
import { FormErrorMessage, H6NsRegularSecondaryBlack } from '../../components/commonText';
import { RowView } from '../../components/commonViews';
import FloatingTextBox from '../../components/floatingInputBox';
import { AddImage } from '../../components/svgImages';
import { GeneralProps } from '../../interfaces/generalProps';
import { maxLengthValidation, requiredValidation, validationSchema } from '../../services/validationConfig';
import { isDoctorVersion } from '../../utils/buildConfig';
import { labels } from '../../utils/labels';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { apiUpdateProfileImage } from '~/api/services/accounts';
import { apiUpdateBiography } from '~/api/services/accounts/biography';
import { UploadDocumentComponent } from '~/components/buttons/UploadDocumentComponent';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { colors } from '~/utils/colors';

const UploadProfileScreen: React.FC<GeneralProps> = ({ navigation }) => {
  const [isDoctorApp] = useState(isDoctorVersion());
  const { userDetails } = useUserDetails();

  const [profileImageUri, setProfileImageUri] = useState('');
  const [waiting, setWaiting] = useState(false);
  const { updateRegistrationStatus } = useRegistrationSteps();
  const { handleSubmit, control, setValue, clearErrors } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: { biography: '', profileImage: undefined },
  });

  const updateProfileImage = async (documentResults: DocumentPickerAsset[]) => {
    const documentResult = documentResults.length ? documentResults[0] : undefined;
    setValue('profileImage', documentResult);
    if (documentResult) clearErrors('profileImage');
    setProfileImageUri(documentResult?.uri);
    return Promise.resolve();
  };

  const submit = async (formData: { biography: string; profileImage: DocumentPickerAsset }) => {
    setWaiting(true);
    try {
      if (formData.profileImage) {
        await apiUpdateProfileImage({ id: userDetails.account.id, document: formData.profileImage });
      }
      await apiUpdateBiography({
        id: userDetails.account.id,
        params: {
          biography: formData.biography,
        },
      });

      await updateRegistrationStatus();
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const button = [
    <Button
      funCallback={handleSubmit(submit)}
      // funCallback={this.uploadProfilePhoto.bind(this)}
      label={labels.continue}
      disabled={waiting}
    />,
  ];
  return (
    <EnhancedRegistrationLayout
      title={labels.uploadProfileScreenTitle}
      subtitle={labels.uploadProfileScreenSubMessage}
      buttons={button}>
      <Controller
        name="profileImage"
        control={control}
        render={({ fieldState: { error }, formState: { isSubmitted } }) => (
          <View style={{ width: '100%' }}>
            <UploadDocumentComponent
              uploadedFiles={[]}
              hideState
              imageUri={profileImageUri}
              square
              uploadTitle="Upload a photo"
              icon={<AddImage height={35} width={35} />}
              setUploadedFiles={updateProfileImage}
              error={isSubmitted && !!error}
              imagesOnly
            />
            {isSubmitted && error?.message ? (
              <View style={mt10}>
                <FormErrorMessage>{error.message}</FormErrorMessage>
              </View>
            ) : null}
          </View>
        )}
        rules={{
          required: requiredValidation('Profile image'),
        }}
      />

      {isDoctorApp ? (
        <View style={[mt25, { width: '100%' }]}>
          <Controller
            name="biography"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <View>
                <RowView>
                  <H6NsRegularSecondaryBlack>
                    {labels.addBiographyText}
                    <Text style={{ color: colors.danger }}>*</Text>
                  </H6NsRegularSecondaryBlack>
                </RowView>
                <FloatingTextBox
                  placeholder={labels.writeSomething}
                  height={120}
                  multiline
                  label={labels.biography}
                  value={value}
                  onChangeValue={onChange}
                  onBlur={onBlur}
                  error={isSubmitted && !!error}
                  errorMessage={isSubmitted && error?.message}
                  maxLength={validationSchema.string.maxLength}
                />
              </View>
            )}
            rules={{
              maxLength: maxLengthValidation(validationSchema.string.maxLength),
              required: requiredValidation(labels.biography),
            }}
          />
        </View>
      ) : null}
    </EnhancedRegistrationLayout>
  );
};

//make this component available to the app
export default UploadProfileScreen;
