import React, { useEffect } from 'react';

import { ModalContainer, ModalContainerProps } from './ModalContainer';

import { useModalManager } from '~/providers/modal/ModalManagementContext';

export const NonRegisteredModalContainer: React.FC<ModalContainerProps> = (props) => {
  const { setShowingNonRegisteredModal } = useModalManager();

  useEffect(() => {
    setShowingNonRegisteredModal('unregisteredModal', props.show);
  }, [props.show]);
  return <ModalContainer {...props} />;
};
