import { Route } from '@react-navigation/native';
import React from 'react';

import { CreateDiagnosisComponent } from '~/components/doctor/patients/consultation/diagnosis/CreateDiagnosisComponent';

interface Props {
  route?: Route<string, { id: string }>;
}
export const ConsultationCreatePage: React.FC<Props> = ({ route }) => {
  const patientId = +route.params.id;

  return <CreateDiagnosisComponent patientId={patientId} />;
};
