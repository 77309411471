import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PrescriptionRequestStateEnum } from '~/api/models/consultations/enums/PrescriptionRequestStateEnum';
import { apiGetPrescriptionRequests } from '~/api/services/pharmacy';
import { SET_PRESCRIPTION_REQUESTS } from '~/redux/reducers/pharmacy/pharmacyPrescriptionsReducer';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { useAppDispatch } from '~/redux/store';
import { isPharmacyVersion } from '~/utils/buildConfig';

export const usePrescriptionRequestsNotifications = () => {
  const currentPharmacy = useSelector(currentPharmacySelector);
  const dispatch = useAppDispatch();

  const updatePrescriptions = () => {
    if (!isPharmacyVersion() || !currentPharmacy) return;

    apiGetPrescriptionRequests({
      filter: { state: PrescriptionRequestStateEnum.PENDING, pharmacy_id: currentPharmacy?.pharmacy.id },
    })
      .then((res) => {
        dispatch(SET_PRESCRIPTION_REQUESTS({ data: res.data }));
      })
      .catch(() => {});
  };

  useEffect(() => {
    updatePrescriptions();
  }, []);
};
