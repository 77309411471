import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { mb10, ml5 } from '../../../../common/commonStyles';
import { colors } from '../../../../utils/colors';
import { H6NsRegularBlack, H6NsSemiBoldBlack } from '../../../commonText';
import { Upload, XOutline } from '../../../svgImages';

import { ClinicalTermLabelValue } from '~/api/hooks/clinicalTerms/ClinicalTermsSearchHook';
import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { DocumentNameWithPreview } from '~/components/buttons/DocumentNameWithPreview';
import { UploadDocumentTouchable } from '~/components/buttons/UploadDocumentTouchable';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { ImageFormats } from '~/constants/documentUploadsConstants';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  title?: string;
  type: ClinicalTermTypeEnum;
  locked?: boolean;
  loading?: boolean;
}

export const PatientHistoryItem: React.FC<Props> = ({ title, type, locked, loading }) => {
  const { isMobile } = useBreakpoints();
  const { patientHistory, removePatientHistoryDataFile, removePatientHistoryDataType, setPatientHistory } =
    useHealthProfileDataContext();

  const values = useMemo(() => (patientHistory ? patientHistory[type] : []), [patientHistory, type]);

  const valueKeys = useMemo(() => (values && Object.keys(values)) || [], [values]);

  const uploadFile = async (term: ClinicalTermLabelValue, results: DocumentPickerAsset[]) => {
    setPatientHistory(type, term, results);
  };
  return (
    <View
      style={[
        {
          width: '100%',
        },
      ]}>
      {valueKeys.length ? (
        valueKeys.map((termId) => {
          const term = values[termId]?.term;
          const name = term?.label;
          const files = values[termId]?.media || [];
          return (
            <View style={styles.historyItem} key={termId}>
              <View style={[styles.historyItemHeader, files.length ? styles.historyItemHeaderWithMedia : null]}>
                <View style={{ paddingVertical: 4, flex: 1 }}>
                  <H6NsSemiBoldBlack>{name}</H6NsSemiBoldBlack>
                </View>
                {locked ? null : (
                  <View style={styles.itemButtons}>
                    <UploadDocumentTouchable
                      formats={ImageFormats}
                      onResult={(results) => uploadFile(term, results)}
                      style={[ml5, { padding: 8 }]}>
                      <Upload height={12} width={12} color={colors.purple} />
                    </UploadDocumentTouchable>
                    <TouchableOpacity
                      style={[ml5, { padding: 8 }]}
                      onPress={() => {
                        removePatientHistoryDataType(type, termId);
                      }}>
                      <XOutline width={12} height={12} color={colors.purple} />
                    </TouchableOpacity>
                  </View>
                )}
              </View>
              <>
                {files.length ? (
                  <View style={styles.fileContainer}>
                    {files.map((file, index) => {
                      const key = 'name' in file ? `${file.name}-${index}` : `${index}`;
                      return (
                        <View key={key} style={files.length - 1 === index ? null : mb10}>
                          <DocumentNameWithPreview
                            file={file}
                            nameIfEmpty={'Image ' + (index + 1)}
                            remove={() => {
                              removePatientHistoryDataFile(type, termId, file);
                            }}
                            disabled={locked}
                          />
                        </View>
                      );
                    })}
                  </View>
                ) : null}
              </>
            </View>
          );
        })
      ) : loading ? (
        <LoadingActivityIndicator />
      ) : (
        <View style={[{ paddingVertical: 8 }, isMobile ? null : { paddingHorizontal: 14 }]}>
          <H6NsRegularBlack>{`No ${title.toLowerCase()} listed`}</H6NsRegularBlack>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  historyItem: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.lightPurple,
    padding: 10,
    marginBottom: 10,
  },
  historyItemHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  historyItemHeaderWithMedia: {
    borderBottomColor: colors.lightPurple,
    borderBottomWidth: 1,
    paddingBottom: 10,
  },
  fileContainer: {
    paddingTop: 10,
  },
  itemButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
