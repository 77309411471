import { get, postFormData } from '~/api/axios';
import { IdModel } from '~/api/models/common/models/IdModel';
import { GetChatMessagesRequest } from '~/api/models/consultations/requests/GetChatMessagesRequest';
import {
  PostChatMediaRequest,
  PostChatMessageRequest,
} from '~/api/models/consultations/requests/PostChatMessageRequest';
import { AudioVideoTokenResponse } from '~/api/models/consultations/responses/AudioVideoTokenResponse';
import { GetChatMessagesResponse } from '~/api/models/consultations/responses/GetChatMessagesResponse';
import fileSystem from '~/integrations/fileSystem';

export const apiGetChatMessages = (req: GetChatMessagesRequest) => {
  return get<GetChatMessagesResponse>(`/consultations/${req.consultationId}/chat`, req.params);
};

export const apiSendChatMessage = (req: PostChatMessageRequest) => {
  const formData = new FormData();
  formData.append('message', req.message);

  return postFormData(`/consultations/${req.consultationId}/chat`, formData);
};

export const apiSendChatMedia = (req: PostChatMediaRequest) => {
  return fileSystem.postDocumentResult(`/consultations/${req.consultationId}/chat`, 'files[]', req.media, {
    message: 'image',
  });
};

export const apiGetAudioVideoToken = (req: IdModel) => {
  return get<AudioVideoTokenResponse>(`consultations/${req.id}/call/token`);
};
