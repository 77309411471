import { get, post, setToken } from '~/api/axios';
import { AvailableDoctorsListResponse } from '~/api/models/appointments/responses/AvailableDoctorsResponse';
import { AuthorisationResponse } from '~/api/models/authorisation/responses/AuthorisationResponse';
import { ApproveConsultationRequestRequest } from '~/api/models/clinics/requests/ApproveConsultationRequestRequest';
import { ClinicTokenDetailsResponse } from '~/api/models/clinics/responses/ClinicTokenDetailsResponse';
import { DeclineConsultationRequest } from '~/api/models/consultations/requests/DeclineConsultationRequest';

export const apiGetClinicAdminTokenDetails = (token: string) => {
  return get<ClinicTokenDetailsResponse>(`clinic-admin/${token}/account-details`);
};

export const apiGetClinicDoctors = () => {
  return get<AvailableDoctorsListResponse>('clinic/doctors');
};

export const apiApproveClinicAdminToken = (token: string) => {
  const xhr = post<AuthorisationResponse>(`clinic-admin/${token}/approve`);
  xhr.then((res) => setToken(res.data.data));
  return xhr;
};

export const apiClinicDeclineConsultation = (req: DeclineConsultationRequest) => {
  return post(`clinic/consultation/${req.id}/reject`, {
    cancellation_reason_note: req.cancellation_reason_note,
  });
};

export const apiClinicApproveConsultation = (req: ApproveConsultationRequestRequest) => {
  return post(`clinic/consultation/${req.id}/approve`, req.params);
};

export const apiClinicUpdateConsultation = (req: ApproveConsultationRequestRequest) => {
  return post(`clinic/consultation/${req.id}/update`, req.params);
};
