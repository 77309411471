import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { PrescriptionIndexResponse } from '~/api/models/consultations/responses/PrescriptionsIndexResponse';

interface IState {
  prescriptions?: Record<number, PrescriptionModel[]>;
  pagination?: PaginationMetaModel;
}

const initialState: IState = {
  prescriptions: undefined,
  pagination: undefined,
};

const prescriptionsSlice = createSlice({
  name: 'prescriptionsList',
  initialState,
  reducers: {
    SET_PRESCRIPTIONS_PAGE: (state, action: PayloadAction<PrescriptionIndexResponse>) => ({
      ...state,
      prescriptions:
        action.payload.meta.current_page === 1
          ? { [action.payload.meta.current_page]: action.payload.data }
          : { ...state.prescriptions, [action.payload.meta.current_page]: action.payload.data },
      pagination: action.payload.meta,
    }),
    LOGOUT: () => ({
      ...initialState,
    }),
  },
});

export const { SET_PRESCRIPTIONS_PAGE, LOGOUT } = prescriptionsSlice.actions;
export default prescriptionsSlice.reducer;

const prescriptions = (state: RootState) => state.prescriptionsReducer.prescriptions;
export const prescriptionsSelector = createSelector([prescriptions], (prescriptions) => {
  return Object.keys(prescriptions || {})
    .sort((a, b) => +a - +b)
    .reduce((prev, page) => {
      const data = prescriptions[page];
      prev.push(...data);
      return prev;
    }, []);
});
