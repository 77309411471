import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { AlertCard } from '../cards/AlertCard';

import { useDoctorAvailabilityCheck } from '~/api/hooks/DoctorAvailabilityCheck';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { NavType } from '~/navigation/types';

interface Props {
  consultationType: ConsultationTypeEnum;
  style?: StyleProp<ViewStyle>;
}

export const DoctorAvailabilitiesWarning: React.FC<Props> = ({ consultationType, style }) => {
  const { navigate } = useNavigation<NavType>();
  const { availabilityIssue, loading } = useDoctorAvailabilityCheck({ type: consultationType });
  return availabilityIssue && !loading ? (
    <AlertCard
      title={availabilityIssue.title}
      message={availabilityIssue.message}
      onPress={() => navigate(availabilityIssue.link.screen, availabilityIssue.link.params)}
      style={style}
    />
  ) : null;
};
