import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useReduxTablePagination } from '../common/useReduxTablePagination';

import { PrescriptionRequestStateEnum } from '~/api/models/consultations/enums/PrescriptionRequestStateEnum';
import { PrescriptionRequestsPaginatedIndexResponse } from '~/api/models/pharmacy/responses/PrescriptionRequestsIndexResponse';
import { apiGetPrescriptionRequests } from '~/api/services/pharmacy';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const usePharmacyRequestHistory = () => {
  const [loading, setLoading] = useState(false);
  const [sortDesc, setSortingDesc] = useState(true);
  const [search, setSearch] = useState('');

  const currentPharmacy = useSelector(currentPharmacySelector);
  const { isMobile } = useBreakpoints();

  const { items, pagination, setPagination, resetTable } = useReduxTablePagination<
    any,
    PrescriptionRequestsPaginatedIndexResponse
  >({
    isInfiniteScroll: isMobile,
    tableName: 'requestHistory',
    watch: [sortDesc, currentPharmacy, search],
    getItems: async (params) => {
      setLoading(true);
      const res = await apiGetPrescriptionRequests({
        limit: params.limit,
        page: params.page,
        sort: sortDesc ? '-created_at' : 'created_at',
        filter: {
          state: [
            PrescriptionRequestStateEnum.APPROVED,
            PrescriptionRequestStateEnum.REJECTED,
            PrescriptionRequestStateEnum.COMPLETED,
          ],
          pharmacy_id: currentPharmacy?.pharmacy.id,
          search,
        },
      });
      setLoading(false);
      return res;
    },
  });

  return {
    data: items ?? [],
    pagination,
    loading,
    sortDesc,
    setSortingDesc,
    onPageChanged: setPagination,
    updateData: resetTable,
    search,
    setSearch,
  };
};
