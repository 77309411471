import { Route, useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';

// import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { Button } from '../../../components/commonButton';
import { H6NsRegularSecondaryBlack } from '../../../components/commonText';
import { CardSurface } from '../../../components/commonViews';
import { Stethoscope, Home, Calendar, ArrowRight } from '../../../components/svgImages';
import { GeneralProps } from '../../../interfaces/generalProps';
import {
  HomeVisitConsultationType,
  OnDemandConsultationType,
  ScheduleAppointmentConsultationType,
} from '../../../services/config';
import { colors } from '../../../utils/colors';
import { labels } from '../../../utils/labels';
import { screenName } from '../../../utils/screenName';
import { theme } from '../../../utils/theme';

import { useFinaliseRegistration } from '~/api/hooks/accounts/FinaliseRegistration';
import { mb10, mb5 } from '~/common/commonStyles';
import RegistrationLayout from '~/layouts/RegistrationLayout';

interface Props extends GeneralProps {
  route: Route<string, (object & { name: string }) | undefined>;
}

interface Options {
  title: string;
  iconImage: JSX.Element;
}

export const AvailabilitiesScreen: React.FC<Props> = () => {
  const navigation = useNavigation();
  const userDetails = {
    user_detail: {
      home_visit: HomeVisitConsultationType.Enable,
      on_demand: OnDemandConsultationType.Enable,
      schedule_appointment: ScheduleAppointmentConsultationType.Enable,
    },
  };

  const { finaliseRegistration } = useFinaliseRegistration();

  const [typeOfConsultations, setTypeOfConsultations] = useState<Options[]>([]);

  useEffect(() => {
    const list = [];
    // eslint-disable-next-line eqeqeq
    if (userDetails?.user_detail?.home_visit == HomeVisitConsultationType.Enable) {
      list.push({
        title: 'Home visit',
        iconImage: <Calendar height={22} width={22} color={colors.grey} />,
      });
    }
    // eslint-disable-next-line eqeqeq
    if (userDetails?.user_detail?.on_demand == OnDemandConsultationType.Enable) {
      list.push({
        title: 'Doctor on-demand',
        iconImage: <Stethoscope height={22} width={22} color={colors.grey} />,
      });
    }
    // eslint-disable-next-line eqeqeq
    if (userDetails?.user_detail?.schedule_appointment == ScheduleAppointmentConsultationType.Enable) {
      list.push({
        title: 'Scheduled appointments',
        iconImage: <Home height={22} width={22} color={colors.grey} />,
      });
    }

    // TODO:[sc-9245] Remove - should have at least one from previous screens
    if (!list.length) {
      list.push(
        {
          title: 'Home visit',
          iconImage: <Calendar height={22} width={22} color={colors.grey} />,
        },
        {
          title: 'Doctor on-demand',
          iconImage: <Stethoscope height={22} width={22} color={colors.grey} />,
        },
        {
          title: 'Scheduled appointments',
          iconImage: <Home height={22} width={22} color={colors.grey} />,
        }
      );
    }

    setTypeOfConsultations(list);
  }, []);

  return (
    <RegistrationLayout
      title={labels.availabilities}
      childrenMarginTop={20}
      subtitle={
        <>
          {labels.availabilitiesSubMessage.map((message, index) => (
            <H6NsRegularSecondaryBlack key={index} style={mb10}>
              {message}
            </H6NsRegularSecondaryBlack>
          ))}
        </>
      }
      buttons={<Button funCallback={finaliseRegistration} label={labels.finish} />}
      backTitle={labels.backToPreferences.toUpperCase()}
      backLink={{ screen: screenName.PreferencesScreen }}>
      <FlatList
        style={{ width: '100%' }}
        data={typeOfConsultations}
        keyExtractor={(item, index) => index + ''}
        renderItem={({ item, index }) => {
          return (
            <View key={index} style={{ paddingHorizontal: 2 }}>
              <CardSurface ph={0}>
                <TouchableOpacity
                  style={theme.uploadDocumentButtonStyle}
                  onPress={() => {
                    switch (item.title) {
                      case typeOfConsultations[0].title: {
                        navigation.navigate(screenName.HomeVisitPreferencesAvailabilitiesScreen as never);
                        break;
                      }
                      case typeOfConsultations[1].title: {
                        navigation.navigate(screenName.DoctorOnDemandPreferencesAvailabilitiesScreen as never);
                        break;
                      }
                      case typeOfConsultations[2].title:
                        navigation.navigate(screenName.ScheduledAppointmentsPreferencesAvailabilitiesScreen as never);
                        break;
                    }
                  }}>
                  <View style={theme.availabilitiesStartItems}>
                    {item.iconImage}
                    <H6NsRegularSecondaryBlack style={[theme.avaliabilitiesTypes, { marginLeft: 10 }]}>
                      {item.title}
                    </H6NsRegularSecondaryBlack>
                  </View>
                  <View style={theme.availabilitiesEndIcon}>
                    <ArrowRight width={14} height={14} />
                  </View>
                </TouchableOpacity>
              </CardSurface>
            </View>
          );
        }}
      />

      {/* <View style={{ marginTop: 20, width: '100%' }}>
        <H6NsRegularSecondaryBlack>{labels.availabilitiesTitle}</H6NsRegularSecondaryBlack>
        <View style={mt10}>
          {listOfScheduleAvailabilities.length > 0
            ? listOfScheduleAvailabilities.map((item: ScheduleSlotProps, index) => {
                return item.slot.length > 0
                  ? item.slot.map((slot: string, ind: number) => {
                      return (
                        <View
                          style={{
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            flexDirection: 'row',
                          }}>
                          <SmallNsRegularSecondaryBlack>{'* ' + item.days + ' - '}</SmallNsRegularSecondaryBlack>
                          <SmallNsSemiBoldSecondaryBlack>{slot}</SmallNsSemiBoldSecondaryBlack>
                        </View>
                      );
                    })
                  : null;
              })
            : null}
        </View>
      </View> */}
    </RegistrationLayout>
  );
};
