import React from 'react';

import { ProfilePersonalInformation } from './common/ProfilePersonalInformation';

import { UserEditImageComponent } from '~/components/profile/UserEditImageComponent';
import { TitleLayout } from '~/layouts/TitleLayout';
import { labels } from '~/utils/labels';

export const ProfileClinic: React.FC = () => {
  return (
    <TitleLayout title={labels.profile} alignHeader="flex-start" headerRight={<UserEditImageComponent />}>
      <ProfilePersonalInformation />
    </TitleLayout>
  );
};
