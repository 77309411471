import React from 'react';

import { Calendar, NotificationIcon, Pill } from '~/components/svgImages';
import { NotificationCodeEnum } from '~/integrations/pushNotifications/enums/NotificationCodeEnum';
import { colors } from '~/utils/colors';

interface Props {
  code?: NotificationCodeEnum;
}

export const NotificationIconComponent: React.FC<Props> = ({ code }) => {
  switch (code) {
    case NotificationCodeEnum.CONSULTATION_REQUEST_REJECTED:
    case NotificationCodeEnum.CONSULTATION_REQUEST_APPROVED:
      return <Calendar width={20} height={20} color={colors.purple} />;
    case NotificationCodeEnum.PRESCRIPTION_REMINDER:
      return <Pill width={20} height={20} color={colors.purple} />;
    default:
      return <NotificationIcon width={20} height={20} color={colors.purple} />;
  }
};
