import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FaqResponse } from '~/api/models/faq/responses/FaqResponse';
import { TaxonsResponse } from '~/api/models/faq/responses/TaxonsResponse';

interface IState {
  faqs: FaqResponse;
  taxons: TaxonsResponse;
}

const initialState: IState = {
  faqs: undefined,
  taxons: undefined,
};

const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    SET_FAQS: (state, action: PayloadAction<FaqResponse>) => ({
      ...state,
      faqs: action.payload,
    }),
    SET_TAXONS: (state, action: PayloadAction<TaxonsResponse>) => ({
      ...state,
      taxons: action.payload,
    }),
  },
});

export const { SET_FAQS, SET_TAXONS } = helpSlice.actions;
export default helpSlice.reducer;
