import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React from 'react';

import { SettingsAccount } from '../../pages/doctor/settings/SettingsAccount';
import { SettingsConsultations } from '../../pages/doctor/settings/SettingsConsultations';
import { SettingsNotifications } from '../../pages/doctor/settings/SettingsNotifications';
import { SettingsSecurity } from '../../pages/doctor/settings/SettingsSecurity';
import { screenName } from '../../utils/screenName';

import { SettingsTabComponent } from '~/components/doctor/settings/SettingsTabComponent';
import { isDoctorVersion, isNative, isPharmacyVersion } from '~/utils/buildConfig';
import { digimedFeatureEnabled, DigimedFeatures } from '~/utils/milestoneUtil';

const Tab = createMaterialTopTabNavigator();

export const SettingsNavigation = () => {
  const consultationsEnabled = digimedFeatureEnabled(DigimedFeatures.Consultations);
  return (
    <Tab.Navigator tabBar={() => <SettingsTabComponent />} screenOptions={{ swipeEnabled: isNative() }}>
      {consultationsEnabled && isDoctorVersion() ? (
        <>
          <Tab.Screen name={screenName.SettingsConsultations} component={SettingsConsultations} />
        </>
      ) : null}

      {!isPharmacyVersion() ? (
        <Tab.Screen name={screenName.SettingsNotifications} component={SettingsNotifications} />
      ) : null}
      <Tab.Screen name={screenName.SettingsSecurity} component={SettingsSecurity} />
      <Tab.Screen name={screenName.SettingsAccount} component={SettingsAccount} />
    </Tab.Navigator>
  );
};
