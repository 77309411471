import { labels } from './labels';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { successOrErrorPopup } from '~/common/commonMethods';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import { ImageFileExtensions } from '~/services/config';

export function checkIsDigimedFile(item: MediaModel) {
  return new Promise<void>((resolve) => {
    if (!item.is_digimed_file && !(item.extension in ImageFileExtensions)) {
      successOrErrorPopup(
        [labels.fileContentWarning],
        true,
        ModalAlertTypeEnum.WARNING,
        labels.warning,
        undefined,
        undefined,
        resolve
      );
    } else {
      resolve();
    }
  });
}
