import { useCallback, useEffect, useState } from 'react';

import { locationTrackingConstants } from '../locationConstants';
import * as Track from '../locationTracker';

interface Props {
  timeInterval?: number;
}

export function useLocationTracking({ timeInterval = locationTrackingConstants.timeInterval }: Props) {
  const [isTracking, setIsTracking] = useState<boolean>(false);

  const onStartTracking = useCallback(async () => {
    await Track.startTracking({ timeInterval });
    setIsTracking(true);
  }, []);

  const onStopTracking = useCallback(async () => {
    await Track.stopTracking();
    setIsTracking(false);
  }, []);

  const onClearTracking = useCallback(async () => {
    if (isTracking) {
      await onStopTracking();
    }
  }, [isTracking]);

  useEffect(() => {
    Track?.isTracking().then(() => setIsTracking(true));
  }, []);

  return {
    isLocationTracking: isTracking,
    startLocationTracking: onStartTracking,
    stopLocationTracking: onStopTracking,
    clearLocationTracking: onClearTracking,
  };
}
