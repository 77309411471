import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useReduxTablePagination } from '../common/useReduxTablePagination';

import { PrescriptionTypeEnum } from '~/api/models/consultations/constants/PrescriptionTypeEnum';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { PrescriptionDispenseHistoryModel } from '~/api/models/pharmacy/models/PrescriptionDispenseHistoryModel';
import { PharmacyDispenseHistoryIndexResponse } from '~/api/models/pharmacy/responses/PharmacyDispenseHistoryIndexResponse';
import { apiDispensePrescription, apiGetDispenseHistory } from '~/api/services/pharmacy';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { SuccessAlert } from '~/common/commonMethods';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const usePharmacyDispenseHistory = () => {
  const [loading, setLoading] = useState(false);
  const [sortDesc, setSortingDesc] = useState(true);
  const [search, setSearch] = useState('');
  const { isMobile } = useBreakpoints();
  const currentPharmacy = useSelector(currentPharmacySelector);

  const { items, pagination, setPagination, resetTable } = useReduxTablePagination<
    PrescriptionDispenseHistoryModel,
    PharmacyDispenseHistoryIndexResponse
  >({
    isInfiniteScroll: isMobile,
    tableName: 'dispenseHistory',
    watch: [sortDesc, currentPharmacy, search],
    getItems: async (params) => {
      setLoading(true);
      const res = await apiGetDispenseHistory({
        pharmacyId: currentPharmacy.pharmacy.id,
        filter: { search },
        sort: sortDesc ? '-created_at' : 'created_at',
        page: params.page,
        limit: params.limit,
      });
      setLoading(false);
      return res;
    },
  });

  return {
    data: items ?? [],
    pagination,
    loading,
    sortDesc,
    setSortingDesc,
    onPageChanged: setPagination,
    updateData: resetTable,
    search,
    setSearch,
  };
};

export const usePrescriptionDispense = () => {
  const [loading, setLoading] = useState(false);
  const currentPharmacy = useSelector(currentPharmacySelector);
  const dispense = async (item: PrescriptionModel, onSuccess?: () => Promise<void>) => {
    try {
      setLoading(true);
      await apiDispensePrescription({
        prescriptionId: item.id,
        params: {
          pharmacy_id: currentPharmacy.pharmacy.id,
        },
      });
      if (onSuccess) await onSuccess();

      AppEventHandler.emit(AppEvents.PHARMACY_DISPENSED_UPDATE);
      SuccessAlert(
        [
          item.type === PrescriptionTypeEnum.ONGOING
            ? 'This prescription is recurring'
            : 'This prescription can only be used once',
        ],
        'Prescription dispensed successfully'
      );
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return { dispense, loading };
};
