import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { AddDependantContext, AddDependantForm } from './AddDependantContext';

import { apiGetHasDigimedAccount } from '~/api/services/accounts/association';
import { apiAddDependant, apiAddDependantMedia } from '~/api/services/dependants';
import { InprogressAlert, SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { DependantWizardSteps } from '~/components/patient/dependants/constants/dependantWizardSteps';
import { IdentificationDocumentsEnum } from '~/constants/documents';
import { NavType } from '~/navigation/types';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useWizardSteps } from '~/utils/hooks/WizardStepsHook';
import { dependantsLabels } from '~/utils/labels/dependantsLabels';
import { screenName } from '~/utils/screenName';

const DEFAULT_FORM: AddDependantForm = {
  same_address: true,
  dependant_relationship_type_id: undefined,
  account: {
    first_name: '',
    last_name: '',
    gender: undefined,
    date_of_birth: '',
    passport_number: '',
    national_id_number: '',
  },
  document_number: '',
  document_type: undefined,
};

interface Props {
  children: React.ReactNode;
}

export const AddDependantProvider: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { navigate } = useNavigation<NavType>();
  const wizard = useWizardSteps({ steps: DependantWizardSteps });
  const { control, reset, handleSubmit } = useForm<AddDependantForm>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      ...DEFAULT_FORM,
    },
  });

  const submitDetails = async (data: AddDependantForm) => {
    try {
      setLoading(true);
      const passport_number =
        data.document_type === IdentificationDocumentsEnum.PASSPORT ? data.document_number : undefined;
      const national_id_number =
        data.document_type === IdentificationDocumentsEnum.IDENTITY_CARD ? data.document_number : undefined;

      const hasAccountRes = await apiGetHasDigimedAccount({
        passport_number,
        national_id_number,
        date_of_birth: data.account.date_of_birth,
      });

      if (hasAccountRes.data.is_user) {
        ErrorAlert(dependantsLabels.failedExistsMessage, dependantsLabels.failedExistsTitle);
        navigate(screenName.Dependants);
        return;
      } else if (hasAccountRes.data.account_id) {
        InprogressAlert([dependantsLabels.pendingExistsMessage], { title: dependantsLabels.pendingExistsTitle });
      }
      wizard.actions.next();
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const submitRequest = async (data: AddDependantForm) => {
    try {
      setLoading(true);
      const passport_number =
        data.document_type === IdentificationDocumentsEnum.PASSPORT ? data.document_number : undefined;
      const national_id_number =
        data.document_type === IdentificationDocumentsEnum.IDENTITY_CARD ? data.document_number : undefined;

      const res = await apiAddDependant({
        dependant_relationship_type_id: data.dependant_relationship_type_id,
        account: {
          ...data.account,
          passport_number,
          national_id_number,
        },
      });

      for (const document of data.relationship_media) {
        await apiAddDependantMedia({ id: res.data.id, document });
      }

      // if (!data.same_address) {
      //   await apiAddAddress({
      //     account_id: res.data.account.id,
      //     line_1: data.address.line_1,
      //     post_code: data.address.post_code,
      //     country_id: data.address.country_id,
      //   });
      // }

      SuccessAlert(
        [dependantsLabels.pendingAwaitingApprovalMessage],
        dependantsLabels.pendingAwaitingApprovalTitle,
        dependantsLabels.returnDependants,
        () => navigate(screenName.Dependants)
      );
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  usePageFocus(() => {
    reset();
    wizard.reset();
  });

  return (
    <AddDependantContext.Provider
      value={{
        control,
        wizard,
        submitDetails: handleSubmit(submitDetails),
        submitRequest: handleSubmit(submitRequest),
        loading,
      }}>
      {children}
    </AddDependantContext.Provider>
  );
};
