import { DocumentPickerAsset } from 'expo-document-picker';
import { useState } from 'react';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { MediaWithStatusModel } from '~/api/models/common/models/MediaWithStatusModel';
import {
  apiAddHealthRecordMedia,
  apiGetHealthRecordMedia,
  apiGetHealthRecordMediaGeneratedPDFs,
  apiRemoveHealthRecordMedia,
} from '~/api/services/consultations/healthRecord';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { isDoctorVersion } from '~/utils/buildConfig';

interface Props {
  healthRecordId?: number;
}
export function useHealthRecordMedia({ healthRecordId }: Props) {
  const [loading, setLoading] = useState(false);
  const [generatedFiles, setGeneratedFiles] = useState<MediaWithStatusModel>();

  const getGeneratedMediaFiles = async () => {
    await apiGetHealthRecordMediaGeneratedPDFs({ id: healthRecordId })
      .then((res) => {
        setGeneratedFiles(res.data);
      })
      .catch(ErrorAlert);
  };

  const getFiles = async (healthRecordId: number) => {
    setLoading(true);

    if (isDoctorVersion()) {
      const res = await apiGetHealthRecordMedia({ id: healthRecordId });
      return res;
    }

    // await getGeneratedMediaFiles();

    setLoading(false);
  };

  const addFile = async ({ document, healthRecordId }: { document: DocumentPickerAsset; healthRecordId: number }) => {
    if (document) return apiAddHealthRecordMedia({ healthRecordId, document });
  };

  const removeFile = async ({ document, healthRecordId }: { document: MediaModel; healthRecordId: number }) => {
    await apiRemoveHealthRecordMedia({ healthRecordId, mediaId: document.uuid });
  };

  return {
    loading,
    addFile,
    removeFile,
    getFiles,
    getGeneratedMediaFiles,
    generatedFiles,
  };
}
