import { AddUpdateScheduledAppointmentRequest } from './../../models/preferences/requests/AddUpdateScheduledAppointmentRequest';

import { deleteReq, get, post, put } from '~/api/axios';
import { ScheduledAppointmentsIndexResponse } from '~/api/models/preferences/responses/ScheduledAppointmentIndexResponse';
import { ScheduledAppointmentResponse } from '~/api/models/preferences/responses/ScheduledAppointmentResponse';
import { appTypeApiPrefix } from '~/utils/buildConfig';

export const apiGetScheduledAvailabilitiesIndex = () => {
  return get<ScheduledAppointmentsIndexResponse>('/doctor/availabilities/scheduled-availabilities');
};

export const apiAddScheduledAvailability = (data: AddUpdateScheduledAppointmentRequest) => {
  return post<ScheduledAppointmentResponse>(`/${appTypeApiPrefix()}/availabilities/scheduled-availabilities`, data);
};

export const apiUpdateScheduledAvailability = (params: {
  groupEventId: number;
  data: AddUpdateScheduledAppointmentRequest;
}) => {
  return put<ScheduledAppointmentResponse>(
    `/${appTypeApiPrefix()}/availabilities/scheduled-availabilities/${params.groupEventId}`,
    params.data
  );
};

export const apiDeleteScheduledAvailability = (groupEventId: number) => {
  return deleteReq(`/${appTypeApiPrefix()}/availabilities/scheduled-availabilities/${groupEventId}`);
};
