import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import { PaginationMetaModel } from '../../api/models/common/models/PaginationMetaModel';

interface IState {
  [tableName: string]: {
    pages?: Record<number, any[]>;
    pagination?: PaginationMetaModel;
  };
}

const initialState: IState = {};

const tablePaginationSlice = createSlice({
  name: 'tablePagination',
  initialState,
  reducers: {
    SET_PAGE: (state, action: PayloadAction<{ tableName: string; pagination: PaginationMetaModel; data: any[] }>) => ({
      ...state,
      [action.payload.tableName]: {
        pagination: action.payload.pagination,
        pages:
          action.payload.pagination.current_page === 1
            ? {
                [action.payload.pagination.current_page]: action.payload.data,
              }
            : {
                ...state[action.payload.tableName]?.pages,
                [action.payload.pagination.current_page]: action.payload.data,
              },
      },
    }),
    UPDATE_PAGE_DATA: (state, action: PayloadAction<{ tableName: string; page: number; data: any[] }>) => ({
      ...state,
      [action.payload.tableName]: {
        ...state[action.payload.tableName],
        pages: {
          ...state[action.payload.tableName]?.pages,
          [action.payload.page]: action.payload.data,
        },
      },
    }),
    UPDATE_PAGE_CURRENT: (state, action: PayloadAction<{ tableName: string; pagination: PaginationMetaModel }>) => ({
      ...state,
      [action.payload.tableName]: {
        ...state[action.payload.tableName],
        pagination: action.payload.pagination,
      },
    }),
    CLEAR_TABLE: (state, action: PayloadAction<{ tableName: string }>) => ({
      ...state,
      [action.payload.tableName]: undefined,
    }),
    LOGOUT: () => ({
      ...initialState,
    }),
  },
});

export const { SET_PAGE, CLEAR_TABLE, UPDATE_PAGE_DATA, UPDATE_PAGE_CURRENT, LOGOUT } = tablePaginationSlice.actions;
export default tablePaginationSlice.reducer;

export const infiniteScrollSelector = (tableName: string) =>
  createSelector([(state) => state.tablePaginationReducer[tableName]], (tablePagination) => {
    return Object.keys(tablePagination?.pages || {})
      .sort((a, b) => +a - +b)
      .reduce((prev, page) => {
        const data = tablePagination.pages[page];
        prev.push(...data);
        return prev;
      }, []);
  });

export const paginatedSelector = (tableName: string) =>
  createSelector([(state: RootState) => state.tablePaginationReducer[tableName]], (tablePagination) => {
    const currentPage = tablePagination?.pagination.current_page || 1;
    return tablePagination?.pages[currentPage] || [];
  });

export const paginationSelector = (tableName: string) => (state: RootState) => {
  return state.tablePaginationReducer[tableName]?.pagination;
};
