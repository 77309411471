import { FlashList } from '@shopify/flash-list';
import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { PharmacyModel } from '~/api/models/pharmacy/models/PharmacyModel';
import { PatientPrescriptionCollectionModel } from '~/api/models/pharmacy/models/PrescriptionCollectionModel';
import { PrescriptionDispenseHistoryRedactedModel } from '~/api/models/pharmacy/models/PrescriptionDispenseHistoryModel';
import { PatientPrescriptionCollectionsResponse } from '~/api/models/pharmacy/responses/PrescriptionCollectionsResponse';
import { PrescriptionDispenseHistoryIndexResponse } from '~/api/models/pharmacy/responses/PrescriptionDispenseHistoryIndexResponse';
import { mb20, ml10, mt10 } from '~/common/commonStyles';
import {
  H4TtmSemiBoldBlack,
  SmallNsRegularBlack,
  SmallNsRegularSecondaryBlack,
  SmallNsSemiBoldBlack,
} from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { colors } from '~/utils/colors';
import { DATE_TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props {
  loading?: boolean;
  collections: PrescriptionDispenseHistoryIndexResponse | PatientPrescriptionCollectionsResponse;
  style?: StyleProp<ViewStyle>;
  currentPharmacy?: PharmacyModel;
}

export const PrescriptionModalDispenseHistory: React.FC<Props> = ({ style, loading, collections, currentPharmacy }) => {
  const { isDesktop } = useBreakpoints();

  const extractDispensedBy = (item: PrescriptionDispenseHistoryRedactedModel | PatientPrescriptionCollectionModel) => {
    if ('dispensed_by' in item && item.dispensed_by) {
      return `Dispensed by ${getAccountName(item.dispensed_by)}\nfrom ${item.pharmacy?.name}, ${
        item.pharmacy?.locality?.name
      }`;
    } else if ('non_digimed_pharmacy' in item) {
      return `Dispensed by ${item.non_digimed_pharmacy.pharmacist_name}\nfrom ${item.non_digimed_pharmacy.name}`;
    } else if (currentPharmacy) {
      return item.pharmacy_id === currentPharmacy?.id
        ? `Dispensed by ${currentPharmacy.name}`
        : 'Dispensed by other pharmacy';
    }
  };
  return (
    <View style={[styles.container, style]}>
      <H4TtmSemiBoldBlack style={[mb20, { paddingHorizontal: 8 }]}>Prescription dispense history</H4TtmSemiBoldBlack>
      <FlashList<PrescriptionDispenseHistoryRedactedModel | PatientPrescriptionCollectionModel>
        keyExtractor={(item) => `${item.id}`}
        data={collections}
        numColumns={isDesktop ? 2 : 1}
        estimatedItemSize={100}
        ListEmptyComponent={
          loading ? (
            <LoadingActivityIndicator />
          ) : (
            <SmallNsRegularBlack style={{ paddingHorizontal: 8 }}>
              No previous collections were recorded
            </SmallNsRegularBlack>
          )
        }
        renderItem={({ item }) => (
          <View style={{ padding: 8, width: '100%' }}>
            <View style={styles.record}>
              <View style={{ display: 'flex' }}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <SmallNsRegularSecondaryBlack>Dispensed on:</SmallNsRegularSecondaryBlack>
                  <SmallNsSemiBoldBlack style={ml10}>
                    {parseDateTime(item.created_at, { outputFormat: DATE_TIME_FORMAT })}
                  </SmallNsSemiBoldBlack>
                </View>
                <View style={mt10}>
                  <SmallNsRegularBlack>{extractDispensedBy(item)}</SmallNsRegularBlack>
                </View>
              </View>
            </View>
          </View>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderColor: colors.lightPurple,
    borderRadius: 6,
    paddingVertical: 8,
    borderWidth: 1,
  },
  record: {
    backgroundColor: colors.lightPurple,
    borderRadius: 6,
    padding: 8,
    width: '100%',
  },
});
