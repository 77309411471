const CurrencyToSymbol = {
  EUR: '€',
};

export const formatAmount = (rate: number, unit: string) => {
  if (!rate || !unit) return '';
  if (unit === '%') {
    return `${(rate / 100).toFixed(2)}${unit}`;
  }
  return `${CurrencyToSymbol[unit] || unit}${(rate / 100).toFixed(2)}`;
};
