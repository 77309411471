import React, { useRef } from 'react';
import { View } from 'react-native';

import { useOnDemandAvailability } from '~/api/hooks/preferences/OnDemandAvailabilityHook';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { mb30, mt20, pb20 } from '~/common/commonStyles';
import { OnDemandAvailabilities, OnDemandAvailabilitiesRef } from '~/components/availabilities/OnDemandAvailabilities';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { H3TtmSemiBoldBlack, H4TtmRegularSecondaryBlack } from '~/components/commonText';
import { KeyboardAScrollView } from '~/components/commonViews';
import { CalendarLayout } from '~/components/doctor/calendar/CalendarLayout';
import { Grid, Container, Row, Column } from '~/theme/components/grid';
import { labels } from '~/utils/labels';

export const DoctorOnDemandScreen: React.FC = () => {
  const { saveOnDemandAvailability, onDemandAvailability, isAvailable } = useOnDemandAvailability({ immediate: true });
  const onDemandRef = useRef<OnDemandAvailabilitiesRef>(null);
  const { isFeatureDisabled } = useFeatureAccess();

  const disabled = isFeatureDisabled(FeatureAccessEnum.CONSULTATION_TYPES);

  return (
    <CalendarLayout>
      <KeyboardAScrollView>
        <Grid grid={{ noOuterGutter: true, gutters: 12 }}>
          <Container alignSelf="flex-start" style={pb20}>
            <Row>
              <Column span={{ xs: 12, md: 6 }}>
                <View style={[mb30]}>
                  {disabled ? null : (
                    <>
                      <H3TtmSemiBoldBlack>
                        {isAvailable ? labels.youAreAvailable : labels.youAreNotAvailable}
                      </H3TtmSemiBoldBlack>
                      <H4TtmRegularSecondaryBlack>
                        {isAvailable
                          ? labels.doctorOnDemandSubMessageAvailable
                          : labels.doctorOnDemandSubMessageNotAvailable}
                      </H4TtmRegularSecondaryBlack>
                    </>
                  )}
                </View>
                <OnDemandAvailabilities ref={onDemandRef} value={onDemandAvailability} disabled={disabled} />
                <CancelContinueFooter
                  disabled={disabled}
                  style={mt20}
                  hideCancel
                  confirmTitle={labels.save}
                  onConfirm={() => onDemandRef.current?.submit(saveOnDemandAvailability)()}
                />
              </Column>
            </Row>
          </Container>
        </Grid>
      </KeyboardAScrollView>
    </CalendarLayout>
  );
};
