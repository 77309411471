import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT, parseDateTime, stringToDate } from './dateAndTime';

interface Options {
  slotDuration?: number;
  minutesBefore?: number;
}

const DEFAULT_OPTIONS: Options = {
  slotDuration: 15,
  minutesBefore: 90,
};

export const splitSlots = (
  timeTill: Date | string,
  options?: Options
): { date: string; from: string; to: string }[] => {
  options = { ...DEFAULT_OPTIONS, ...options };

  let startTime = stringToDate(timeTill, { parseFormat: DATE_TIME_FORMAT });
  const endTime = new Date(startTime);

  startTime.setMinutes(startTime.getMinutes() - options.minutesBefore);

  const slots = [];
  for (; startTime < endTime; ) {
    const slotEndTime = new Date(startTime);
    slotEndTime.setMinutes(slotEndTime.getMinutes() + options.slotDuration);
    slots.push({
      date: parseDateTime(startTime, { outputFormat: DATE_FORMAT }),
      from: parseDateTime(startTime, { outputFormat: TIME_FORMAT }),
      to: parseDateTime(slotEndTime, { outputFormat: TIME_FORMAT }),
    });
    startTime = slotEndTime;
  }

  return slots;
};
