import React from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { PatientLifestyleDietaryRestrictions } from './PatientLifestyleDietaryRestrictions';
import { PatientLifestyleRecreationalDrugs } from './PatientLifestyleRecreationalDrugs';
import { PatientLifestyleSocialFactors } from './PatientLifestyleSocialFactors';
import { useAlcoholConsumptionsListing } from '../../../../../api/hooks/AlcoholConsumptionsListing';
import { useTobaccoUsesListing } from '../../../../../api/hooks/TobaccoUsesListing';
import { mb10 } from '../../../../../common/commonStyles';
import { ItemProps } from '../../../../../interfaces/generalProps';
import { Column, Row } from '../../../../../theme/components/grid';
import { Accordion } from '../../../../buttons/Accordions/Accordion';
import { RadioButtons } from '../../../../inputs/RadioButtons';
import { HealthProfileEditHeader } from '../HealthProfileEditHeader';

import { AccordionGroupProvider } from '~/components/buttons/Accordions/AccordionGroupProvider';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { HealthProfileDietaryRestrictionsProvider } from '~/providers/healthProfile/HealthProfileDietaryRestrictionsProvider';
import { HealthProfileRecreationalDrugsProvider } from '~/providers/healthProfile/HealthProfileRecreationalDrugsProvider';
import { HealthProfileSocialFactorsProvider } from '~/providers/healthProfile/HealthProfileSocialFactorsProvider';

interface Props {
  locked?: boolean;
  onStartEdit?: () => void;
}

export const PatientLifestyle: React.FC<Props> = ({ locked, onStartEdit }) => {
  const { alcoholConsumptionsData } = useAlcoholConsumptionsListing();
  const { tobaccoUsesData } = useTobaccoUsesListing();

  const { controlLifestyleRadioOptions } = useHealthProfileDataContext();

  const alcoholConsumptionOptions: ItemProps[] = alcoholConsumptionsData;
  const tobaccoUseOptions: ItemProps[] = tobaccoUsesData;

  return (
    <Row>
      <Column width={720}>
        <HealthProfileEditHeader title="Lifestyle" onEdit={onStartEdit} hideEdit={!locked} />
        <AccordionGroupProvider>
          <HealthProfileDietaryRestrictionsProvider>
            <PatientLifestyleDietaryRestrictions locked={locked} />
          </HealthProfileDietaryRestrictionsProvider>
          <HealthProfileRecreationalDrugsProvider>
            <PatientLifestyleRecreationalDrugs locked={locked} />
          </HealthProfileRecreationalDrugsProvider>

          <Controller
            name="alcoholConsumption"
            control={controlLifestyleRadioOptions}
            render={({ field: { onChange, value } }) => (
              <Accordion groupValue="alcohol_consumption" title="Alcohol Consumption" style={mb10}>
                <View>
                  <RadioButtons
                    items={alcoholConsumptionOptions}
                    selected={value}
                    onValueChange={(value) => onChange(+value)}
                    disabled={locked}
                  />
                </View>
              </Accordion>
            )}
          />

          <Controller
            name="tobaccoUse"
            control={controlLifestyleRadioOptions}
            render={({ field: { onChange, value } }) => (
              <Accordion groupValue="tobacco_use" title="Tobacco Use" style={mb10}>
                <View>
                  <RadioButtons
                    items={tobaccoUseOptions}
                    selected={value}
                    onValueChange={(value) => onChange(+value)}
                    disabled={locked}
                  />
                </View>
              </Accordion>
            )}
          />

          <HealthProfileSocialFactorsProvider>
            <PatientLifestyleSocialFactors locked={locked} />
          </HealthProfileSocialFactorsProvider>
        </AccordionGroupProvider>
      </Column>
    </Row>
  );
};
