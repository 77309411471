import { forwardRef, useImperativeHandle } from 'react';

import { StripePaymentMethodModal } from './modal/StripePaymentMethodModal';
import { StripeOptionsInterface, StripePaymentMethodProps, StripePaymentMethodRef } from '../interface';

import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';

export const StripePaymentMethod = forwardRef<StripePaymentMethodRef, StripePaymentMethodProps>((props, ref) => {
  const { registerModal, openModal } = useModalManager();

  const initializePaymentSheet = (options: StripeOptionsInterface) => {
    registerModal(ModalName.StripeCheckout, StripePaymentMethodModal);
    return new Promise<void>((resolve, reject) => {
      openModal(ModalName.StripeCheckout, {
        clientSecret: options.clientSecret,
        onComplete: resolve,
        onError: reject,
        type: props.type,
        title: props.title,
      });
    });
  };

  useImperativeHandle(ref, () => ({
    initializePaymentSheet,
  }));

  return null;
});
