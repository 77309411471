import React, { useState } from 'react';
import { View } from 'react-native';

import { useDependantsHook } from '~/api/hooks/dependants/DependantsHook';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { DependantsListing } from '~/components/patient/dependants/components/DependantsListing';
import { NoDependantsComponent } from '~/components/patient/dependants/components/NoDependantsComponent';
import { colors } from '~/utils/colors';
import { usePageFocus } from '~/utils/hooks/FocusHook';

export const DependantsScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { dependants, fetchDependants } = useDependantsHook();

  usePageFocus(() => {
    setLoading(true);
    fetchDependants(true)
      .catch(() => {})
      .finally(() => setLoading(false));
  });

  if (loading) {
    return (
      <View style={{ backgroundColor: colors.white, flex: 1 }}>
        <LoadingActivityIndicator />
      </View>
    );
  }

  if (dependants.length) {
    return <DependantsListing />;
  } else {
    return <NoDependantsComponent />;
  }
};
