import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

import { ml10, mt10 } from '../../../common/commonStyles';
import { Button, TextButton } from '../../../components/commonButton';
import { H6NsRegularSecondaryBlack } from '../../../components/commonText';
import { CardSurface } from '../../../components/commonViews';
import { AudioCallIcon, VideoCallIcon, ChatIcon } from '../../../components/svgImages';
import { colors } from '../../../utils/colors';
import { labels } from '../../../utils/labels';
import { screenName } from '../../../utils/screenName';
import { theme } from '../../../utils/theme';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { useConsultationPreferences } from '~/api/hooks/accounts/ConsultationPreferencesHook';
import { useLanguageListing } from '~/api/hooks/referenceData/LanguageListing';
import { apiStoreConsultationChannels, apiStorePreferredLanguages } from '~/api/services/registration';
import { SuccessAlert } from '~/common/commonMethods';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import RegistrationLayout from '~/layouts/RegistrationLayout';

interface Form {
  consultationTypes: number[];
  languages: number[];
}

export const ConsultationChannelPreferences: React.FC = () => {
  const { consultationTypes, getUserPreferences, userPreferences } = useConsultationPreferences();
  const languages = useLanguageListing();
  const { updateRegistrationStatus } = useRegistrationSteps();

  const navigation = useNavigation();

  const { control, handleSubmit, reset } = useForm<Form>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      consultationTypes: [],
      languages: [],
    },
  });

  const saveOnlineConsultationTypes = async (data: Form) => {
    try {
      if (data.consultationTypes?.length > 0) {
        await apiStoreConsultationChannels({ consultation_channel_ids: data.consultationTypes });
      }
      if (data.languages?.length) {
        await apiStorePreferredLanguages({ languages: data.languages });
      }
      await getUserPreferences();

      await updateRegistrationStatus();

      SuccessAlert(['Preferred means of consultations updated']);

      navigation.navigate(screenName.PreferencesScreen as never);
    } catch (error) {
      ErrorAlert(error);
    }
  };

  useEffect(() => {
    reset({
      languages: userPreferences?.languages.map((language) => language.id) ?? [],
      consultationTypes: userPreferences?.consultation_channels.map((channel) => channel.id) ?? [],
    });
  }, [userPreferences]);

  const languageOptions = useMemo(
    () => languages?.map((language) => ({ value: language.id, label: language.name })) || [],
    [languages]
  );
  const buttons = (
    <>
      <Button funCallback={handleSubmit(saveOnlineConsultationTypes)} label={labels.save} />
      <TextButton
        style={mt10}
        funCallback={() => navigation.navigate(screenName.PreferencesScreen as never)}
        label={labels.notNow}
      />
    </>
  );
  return (
    <RegistrationLayout
      title={labels.preferredOnlineConsultationHeading}
      subtitle={labels.preferredOnlineConsultationSubMessage}
      backTitle={labels.backToPreferences.toUpperCase()}
      backLink={{ screen: screenName.PreferencesScreen }}
      buttons={buttons}>
      <View style={{ width: '100%' }}>
        {userPreferences && consultationTypes && languages ? (
          <>
            <Controller
              control={control}
              name="consultationTypes"
              render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                <>
                  {consultationTypes?.map((item, index) => {
                    return (
                      <CardSurface
                        key={index}
                        style={[{ paddingHorizontal: 0 }, value.includes(item.id) ? styles.selected : styles.card]}>
                        <TouchableOpacity
                          style={theme.uploadDocumentButtonStyle}
                          onPress={() => {
                            if (value.includes(item.id)) {
                              const updatedConsultationMethods = value.filter(
                                (consultationTypeId) => consultationTypeId !== item.id
                              );
                              onChange(updatedConsultationMethods);
                            } else {
                              onChange([...value, item.id]);
                            }
                          }}>
                          {item.id === 1 ? (
                            <ChatIcon height={22} width={22} color={colors.grey} />
                          ) : item.id === 2 ? (
                            <AudioCallIcon height={22} width={22} color={colors.grey} />
                          ) : item.id === 3 ? (
                            <VideoCallIcon height={22} width={22} color={colors.grey} />
                          ) : null}
                          <H6NsRegularSecondaryBlack style={ml10}>{item.name}</H6NsRegularSecondaryBlack>
                        </TouchableOpacity>
                      </CardSurface>
                    );
                  })}
                </>
              )}
            />
            <H6NsRegularSecondaryBlack style={{ marginTop: 15, marginBottom: 5 }}>
              {labels.preferredOnlineConsultationLanguageSelect}
            </H6NsRegularSecondaryBlack>

            <Controller
              control={control}
              name="languages"
              render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                <Dropdown
                  list={languageOptions}
                  value={value}
                  setValue={onChange}
                  searchable
                  multiSelect
                  label="Choose your languages"
                  dropDownContainerStyle={{ marginTop: 5 }}
                  error={isSubmitted && !!error}
                  errorMessage={isSubmitted && error?.message}
                />
              )}
            />
          </>
        ) : (
          <LoadingActivityIndicator />
        )}
      </View>
    </RegistrationLayout>
  );
};

const styles = StyleSheet.create({
  card: {
    borderColor: colors.transparent,
    borderWidth: 1,
  },
  selected: {
    shadowColor: colors.info,
    backgroundColor: colors.lightPurple,
    borderWidth: 1,
    borderColor: colors.info,
  },
});
