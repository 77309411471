import React, { useEffect, useState } from 'react';

import { CheckboxGroup } from '~/components/inputs/CheckboxGroup';

enum GeneralSpecialisation {
  GP = 'gp',
  SPECIAL = 'specialisation',
}
const Specialisations = [
  { label: 'General practitioner', value: GeneralSpecialisation.GP },
  { label: 'Specialist', value: GeneralSpecialisation.SPECIAL },
];

interface Props {
  showGp?: boolean;
  setShowGp: (value?: boolean) => void;
}

export const DoctorListFilterSpecialisationComponent: React.FC<Props> = ({ showGp, setShowGp }) => {
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (values.length === 0 || values.length === 2) {
      setShowGp(undefined);
    } else {
      setShowGp(values[0] === GeneralSpecialisation.GP);
    }
  }, [values]);

  return <CheckboxGroup items={Specialisations} values={values} onChange={setValues} />;
};
