import React from 'react';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { INavigate } from '~/common/types/navigation/navigate';
import { HealthProfileHistoryItemMobile } from '~/components/doctor/patients/healthProfile/HealthProfileHistoryItemMobile';
import { HealthProfileProceduresProvider } from '~/providers/healthProfile/history/HealthProfileProceduresProvider';

interface Props {
  edit?: boolean;
  backLink: INavigate;
}

export const HealthProfileProceduresMobile: React.FC<Props> = ({ edit, backLink }) => {
  return (
    <HealthProfileProceduresProvider>
      <HealthProfileHistoryItemMobile
        edit={edit}
        type={{ label: 'Procedures', value: ClinicalTermTypeEnum.PROCEDURES }}
        backLink={backLink}
      />
    </HealthProfileProceduresProvider>
  );
};
