import { ModalAlertTypeEnum } from './ModalEnums';
import { ErrorResponse } from '../../classes/errors/ErrorResponse';
import { successOrErrorPopup } from '../../common/commonMethods';

import { PageTypesEnum } from '~/api/models/pages/constants/PageTypesEnum';
import { ErrorMoreInfoType } from '~/classes/errors/ErrorMoreInfoType';
import { ErrorWithMoreInfo } from '~/classes/errors/ErrorWithMoreInfo';
import { SHOW_PAGE_INFO_MODAL } from '~/redux/reducers/modalReducer';
import store from '~/redux/store';
import log from '~/utils/logger';

const getErrorMoreInfo = (error?: ErrorWithMoreInfo) => {
  if (!error || !error.moreInfoType) return {};
  if (error.moreInfoType === ErrorMoreInfoType.ReduceFileSize) {
    return {
      buttonText: 'Learn more',
      buttonAction: () => {
        store.dispatch(SHOW_PAGE_INFO_MODAL(PageTypesEnum.REDUCE_FILE_SIZE));
      },
    };
  }
};

export const ErrorAlert = (
  error?: string | ErrorResponse | Error,
  title?: string,
  options?: { buttonText?: string; errorKey?: string; errorCode?: string }
) => {
  // console.error('Error Alert', error, title, options);
  const errorIsString = typeof error === 'string';
  let message = errorIsString
    ? error
    : error?.message || 'Something went wrong and we were unable to complete your requests';
  log.info('ERROR', errorIsString, message);

  if (message === 'Unauthenticated.') return; // TODO: check which apis are being called with invalid token

  if (!errorIsString && 'errors' in error && error?.errors) {
    if (options?.errorKey && error.errors[options.errorKey]?.length) message = error.errors[options.errorKey][0];
    else {
      const values = Object.values(error.errors);
      const valueArr = values.find((val) => val.length);
      if (valueArr?.length && typeof valueArr[0] === 'string') message = valueArr[0];
    }
  }

  const moreInfoParams = !errorIsString && error instanceof ErrorWithMoreInfo ? getErrorMoreInfo(error) : undefined;

  successOrErrorPopup(
    [message],
    true,
    ModalAlertTypeEnum.ERROR,
    title,
    options?.buttonText,
    undefined,
    undefined,
    undefined,
    undefined,
    moreInfoParams?.buttonText,
    moreInfoParams?.buttonAction,
    {
      errorCode: options?.errorCode,
    }
  );
};
