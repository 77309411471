import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useMemo } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { H6NsRegularBlack } from '~/components/commonText';
import { PaperClip, XOutline } from '~/components/svgImages';
import { colors } from '~/utils/colors';
import { isMediaModel } from '~/utils/files/isMediaModel';

interface Props {
  image: MediaModel | DocumentPickerAsset;
  onRemove: () => void;
}

export const ReasonImageComponent: React.FC<Props> = ({ image, onRemove }) => {
  const imageUri = useMemo(() => {
    if (isMediaModel(image)) {
      return image.url;
    } else if (image) {
      return image.uri;
    } else {
    }
  }, [image]);

  const imageTitle = useMemo(() => {
    if (isMediaModel(image)) {
      return image.name;
    } else if (image) {
      return image.name;
    }
  }, [image]);
  return (
    <View style={styles.container}>
      <View style={styles.image}>
        {imageUri ? (
          <Image source={{ uri: imageUri }} style={{ width: '100%', height: '100%' }} />
        ) : (
          <PaperClip height={35} width={35} color={colors.lightPurple} />
        )}
      </View>
      <View style={styles.right}>
        <View style={styles.textContainer}>
          <H6NsRegularBlack>{imageTitle}</H6NsRegularBlack>
        </View>
        <TouchableOpacity style={styles.crossButton} onPress={onRemove}>
          <XOutline width={12} height={12} color={colors.purple} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightPurple,
    padding: 4,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    borderRadius: 6,
  },
  image: {
    backgroundColor: colors.white,
    borderRadius: 6,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    height: 120,
    flex: 1,
  },
  right: {
    marginLeft: 5,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  textContainer: { flex: 1, paddingTop: 20 },
  crossButton: {
    padding: 8,
    marginLeft: 5,
  },
});
