import Constants from 'expo-constants';

export enum DigimedMilestones {
  PatientManagementSystem = 1,
  DoctorPatientApps,
  PharmacyApp,
}

export enum DigimedFeatures {
  PatientManagementSystem = 1,
  CalendarAndAppointments,
  Consultations,
  IncomeAnalysis,
  Requests,
  Payments,
  HealthProfile,
}

const FeatureToMilestoneMapping = {
  [DigimedFeatures.PatientManagementSystem]: DigimedMilestones.PatientManagementSystem,
  [DigimedFeatures.CalendarAndAppointments]: DigimedMilestones.DoctorPatientApps,
  [DigimedFeatures.Consultations]: DigimedMilestones.DoctorPatientApps,
  [DigimedFeatures.IncomeAnalysis]: DigimedMilestones.DoctorPatientApps,
  [DigimedFeatures.Requests]: DigimedMilestones.DoctorPatientApps,
  [DigimedFeatures.Payments]: DigimedMilestones.DoctorPatientApps,
};

const CURRENT_MILESTONE =
  (Constants.expoConfig.extra.currentMilestone as DigimedMilestones) || DigimedMilestones.PatientManagementSystem;

export const currentMilestone = CURRENT_MILESTONE;

export const digimedFeatureEnabled = (feature: DigimedFeatures) => {
  return FeatureToMilestoneMapping[feature] <= CURRENT_MILESTONE;
};

export const digimedMilestoneEnabled = (milestone: DigimedMilestones) => {
  return milestone <= CURRENT_MILESTONE;
};
