import { isWeb } from '~/utils/buildConfig';

const isEnabled = isWeb();

// Implementation to cater for mobile devices with a mouse
//
// if (isWeb()) {
// const HOVER_THRESHOLD_MS = 1000;
// let lastTouchTimestamp = 0;

// const enableHover = () => {
//   if (isEnabled || Date.now() - lastTouchTimestamp < HOVER_THRESHOLD_MS) {
//     return;
//   }

//   document.removeEventListener('mousemove', enableHover)
//   isEnabled = true;
// };

// const disableHover = () => {
//   lastTouchTimestamp = Date.now();
//   if (isEnabled) {
//     isEnabled = false;
//   }
// };

// document.addEventListener('touchstart', disableHover, true);
// document.addEventListener('touchmove', disableHover, true);
// document.addEventListener('mousemove', enableHover, true);
// }

export function isHoverEnabled() {
  return isEnabled;
}
