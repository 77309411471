import React, { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { View } from 'react-native';

import { useInsuranceCompanyNames } from './hooks/useCompanyNames';
import { useInsuranceDetails } from './hooks/useInsuranceDetails';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { useTryUpgradeToPremium } from '~/api/hooks/insurance/TryUpgradePremiumHook';
import { CountryListEnum, useCountryListing } from '~/api/hooks/referenceData/CountryListing';
import { useSubscriptions } from '~/api/hooks/subscriptions/SubscriptionHook';
import { InsuranceDetails } from '~/api/models/insurance/models/InsuranceDetails';
import { apiPostInsuranceDetails } from '~/api/services/insurance';
import { SuccessAlert } from '~/common/commonMethods';
import { flex1, mb10, mb20, mt10, mt20, ph5, pl0, pr0 } from '~/common/commonStyles';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { H4TtmSemiBoldBlack, H5TtmSemiBoldBlack, H6NsRegularBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { RadioButtons } from '~/components/inputs/RadioButtons';
import DatePicker from '~/components/inputs/dateTime/DatePicker';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { Splitter } from '~/components/misc/Splitter';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { IdentificationDocumentTypes, IdentificationDocumentsEnum } from '~/constants/documents';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import {
  emailValidation,
  maxLengthValidation,
  minLengthValidation,
  numberValidation,
  requiredValidation,
  validationSchema,
} from '~/services/validationConfig';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { LARAVEL_ATOM, LARAVEL_DATE_TIME_SHORT, NUM_DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { insuranceLabels } from '~/utils/labels/insurance';

export const InsuranceDetailsForm: React.FC = () => {
  const { insuranceDetails } = useInsuranceDetails();
  const { tryUpgradeWithInsurance } = useTryUpgradeToPremium();
  const { isPremiumPlan } = useSubscriptions();

  const countryPhoneCodes = useCountryListing(CountryListEnum.PHONE_CODE);
  const countryListing = useCountryListing(CountryListEnum.COUNTRY_NAME);
  const { insuranceCompanies } = useInsuranceCompanyNames();
  const items = [
    { id: true, name: insuranceLabels.policyHolder },
    { id: false, name: insuranceLabels.dependent },
  ];
  const { userDetails } = useUserDetails({ allowStale: true });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    reset,
  } = useForm<InsuranceDetails>();

  const { is_policy_holder } = useWatch({ control });

  const resetForm = () => {
    reset({
      insurance_company_id: insuranceDetails?.insurance_company?.id,
      is_policy_holder: insuranceDetails?.is_policy_holder ?? true,
      insurance_policy_holder: {
        document_type: insuranceDetails?.insurance_policy_holder?.passport_number
          ? IdentificationDocumentsEnum.PASSPORT
          : insuranceDetails?.insurance_policy_holder?.national_id_number
          ? IdentificationDocumentsEnum.IDENTITY_CARD
          : undefined,
        mobile_number: insuranceDetails?.insurance_policy_holder?.contact_number,
        country_code: insuranceDetails?.insurance_policy_holder?.country_code,
        date_of_birth: insuranceDetails?.insurance_policy_holder?.date_of_birth
          ? parseDateTime(insuranceDetails?.insurance_policy_holder?.date_of_birth, {
              outputFormat: NUM_DATE_FORMAT,
            })
          : null,
        email_address: insuranceDetails?.insurance_policy_holder?.email_address,
        first_name: insuranceDetails?.insurance_policy_holder?.first_name,
        last_name: insuranceDetails?.insurance_policy_holder?.last_name,
        passport_number:
          insuranceDetails?.insurance_policy_holder?.passport_number ??
          insuranceDetails?.insurance_policy_holder?.national_id_number,
        address: {
          country_id: insuranceDetails?.insurance_policy_holder?.country.id,
          line1: insuranceDetails?.insurance_policy_holder?.line_1,
          post_code: insuranceDetails?.insurance_policy_holder?.post_code,
        },
      },
      policy_number: insuranceDetails?.policy_number,
      group_name: insuranceDetails?.group_name,
      valid_from: insuranceDetails?.valid_from
        ? parseDateTime(insuranceDetails?.valid_from, {
            parseFormat: LARAVEL_ATOM,
            outputFormat: LARAVEL_DATE_TIME_SHORT,
          })
        : null,
      valid_to: insuranceDetails?.valid_to
        ? parseDateTime(insuranceDetails?.valid_to, {
            parseFormat: LARAVEL_ATOM,
            outputFormat: LARAVEL_DATE_TIME_SHORT,
          })
        : null,
      bank_beneficiary_name: insuranceDetails?.bank_beneficiary_name,
      bank_iban: insuranceDetails?.bank_iban,
      bank_bic: insuranceDetails?.bank_bic,
    });
  };
  useEffect(() => {
    resetForm();
  }, [insuranceDetails]);

  const submit = async (data: InsuranceDetails) => {
    try {
      if (data.is_policy_holder) {
        delete data.insurance_policy_holder;
      } else if (data.insurance_policy_holder.document_type === IdentificationDocumentsEnum.IDENTITY_CARD) {
        data.insurance_policy_holder.national_id_number = data.insurance_policy_holder.passport_number;
        delete data.insurance_policy_holder.passport_number;
      }

      await apiPostInsuranceDetails(data);
      reset({
        ...data,
      });

      if (
        (await tryUpgradeWithInsurance({
          insuranceCompany: data.insurance_company_id,
          insurancePolicy: data.policy_number,
          dob: userDetails?.account?.date_of_birth,
        })) &&
        !isPremiumPlan
      ) {
        SuccessAlert(
          ['Your account has been upgraded to Digimed Premium as part of your insurance plan.'],
          'Your insurance plan includes Digimed Premium!'
        );
        return;
      }
      SuccessAlert(['Insurance details successfully saved']);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  return (
    <PageLayout
      hideBack
      noTopPadding
      footer={
        <View style={mt10}>
          <CancelContinueFooter
            confirmTitle={labels.save}
            onCancel={resetForm}
            onConfirm={handleSubmit(submit)}
            disabled={!isDirty}
          />
        </View>
      }>
      <Grid
        grid={{
          gutters: 16,
          noOuterGutter: true,
        }}>
        <Container alignSelf="flex-start" maxContainerWidths={760}>
          <H4TtmSemiBoldBlack>{insuranceLabels.insuranceDetails}</H4TtmSemiBoldBlack>
          <H6NsRegularBlack style={mb10}>{insuranceLabels.insuranceDetailsDescription}</H6NsRegularBlack>
          <Row>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="insurance_company_id"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <Dropdown
                    list={insuranceCompanies.map((item) => ({ label: item.name, value: item.id }))}
                    setValue={onChange}
                    value={value}
                    label={insuranceLabels.insuranceCompanyName}
                    searchable
                    showMandatory
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                  />
                )}
                rules={{
                  required: requiredValidation(insuranceLabels.insuranceCompanyName),
                }}
              />
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="policy_number"
                control={control}
                render={({ field: { value, onChange, onBlur }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    maxLength={validationSchema.lastName.maxLength}
                    label={insuranceLabels.insurancePolicyHolderNumber}
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    showMandatory
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                  />
                )}
                rules={{
                  required: requiredValidation(insuranceLabels.insurancePolicyHolderNumber),
                }}
              />
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="group_name"
                control={control}
                render={({ field: { value, onChange, onBlur }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    maxLength={validationSchema.laravelString.maxLength}
                    label={insuranceLabels.groupNameIfApplicable}
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Column>
          </Row>
          <View style={mt10} />
          <H6NsSemiBoldBlack>{insuranceLabels.coverPeriod}</H6NsSemiBoldBlack>

          <Row>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="valid_from"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <DatePicker
                    showMandatory
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    onChangeValue={(date) => {
                      onChange(
                        parseDateTime(date, { parseFormat: NUM_DATE_FORMAT, outputFormat: LARAVEL_DATE_TIME_SHORT })
                      );
                    }}
                    value={value}
                    label={insuranceLabels.coverPeriodFrom}
                  />
                )}
                rules={{
                  required: requiredValidation('Cover period from'),
                }}
              />
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="valid_to"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <DatePicker
                    showMandatory
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    onChangeValue={(date) => {
                      onChange(
                        parseDateTime(date, { parseFormat: NUM_DATE_FORMAT, outputFormat: LARAVEL_DATE_TIME_SHORT })
                      );
                    }}
                    value={value}
                    label={insuranceLabels.coverPeriodTo}
                  />
                )}
                rules={{
                  required: requiredValidation('Cover period to'),
                }}
              />
            </Column>
          </Row>
          <View style={mt20} />

          <Row>
            <Controller
              name="is_policy_holder"
              control={control}
              render={({ field: { value, onChange } }) => (
                <RadioButtons items={items} selected={value} onValueChange={onChange} />
              )}
            />
          </Row>

          <View style={mt20} />
          {is_policy_holder === false ? (
            <>
              <H4TtmSemiBoldBlack>{insuranceLabels.policyHolderDetails}</H4TtmSemiBoldBlack>
              <H6NsRegularBlack>{insuranceLabels.policyHolderDetailsDescription}</H6NsRegularBlack>
              <Row>
                <Column span={{ xs: 12, md: 6 }}>
                  <Controller
                    name="insurance_policy_holder.first_name"
                    control={control}
                    render={({
                      field: { value, onChange, onBlur },
                      fieldState: { error },
                      formState: { isSubmitted },
                    }) => (
                      <FloatingInput
                        maxLength={validationSchema.name.maxLength}
                        label={labels.legalFirstName}
                        showMandatory
                        value={value}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        error={isSubmitted && !!error}
                        errorMessage={isSubmitted && error?.message}
                      />
                    )}
                    rules={{
                      required: requiredValidation(labels.legalFirstName),
                    }}
                  />
                </Column>
                <Column span={{ xs: 12, md: 6 }}>
                  <Controller
                    name="insurance_policy_holder.last_name"
                    control={control}
                    render={({
                      field: { value, onChange, onBlur },
                      fieldState: { error },
                      formState: { isSubmitted },
                    }) => (
                      <FloatingInput
                        maxLength={validationSchema.lastName.maxLength}
                        label={labels.legalLastName}
                        showMandatory
                        value={value}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        error={isSubmitted && !!error}
                        errorMessage={isSubmitted && error?.message}
                      />
                    )}
                    rules={{
                      required: requiredValidation(labels.legalLastName),
                    }}
                  />
                </Column>
              </Row>
              <Row>
                <Column span={{ xs: 12, md: 6 }}>
                  <Controller
                    name="insurance_policy_holder.date_of_birth"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                      <DatePicker
                        showMandatory
                        error={isSubmitted && !!error}
                        errorMessage={isSubmitted && error?.message}
                        onChangeValue={(date) => {
                          onChange(parseDateTime(date, { outputFormat: NUM_DATE_FORMAT }));
                        }}
                        value={value}
                        label={labels.dateOfBirthPlaceholder}
                      />
                    )}
                    rules={{
                      required: requiredValidation('Date of birth'),
                    }}
                  />
                </Column>
              </Row>
              <Row>
                <Column span={{ xs: 12, md: 6 }}>
                  <Controller
                    name="insurance_policy_holder.email_address"
                    control={control}
                    render={({
                      field: { value, onChange, onBlur },
                      fieldState: { error },
                      formState: { isSubmitted },
                    }) => (
                      <FloatingInput
                        value={value}
                        label={labels.emailAddress}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        error={isSubmitted && !!errors?.insurance_policy_holder?.email_address}
                        errorMessage={isSubmitted && error?.message}
                        showMandatory
                      />
                    )}
                    defaultValue=""
                    rules={{
                      required: requiredValidation(labels.emailAddress),
                      maxLength: maxLengthValidation(validationSchema.email.maxLength),
                      validate: { emailValidation },
                    }}
                  />
                </Column>
                <Column span={{ xs: 12, md: 6 }} style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={[flex1, ph5, pl0, { maxWidth: '35%' }]}>
                    <Controller
                      name="insurance_policy_holder.country_code"
                      control={control}
                      render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                        <Dropdown
                          list={countryPhoneCodes.map((item) => ({ label: item.name, value: item.id }))}
                          setValue={onChange}
                          value={value}
                          error={isSubmitted && !!errors?.insurance_policy_holder?.country_code}
                          errorMessage={isSubmitted && error?.message}
                          searchable
                          showMandatory
                        />
                      )}
                      rules={{
                        required: requiredValidation('Country code'),
                      }}
                    />
                  </View>
                  <View style={[flex1, ph5, pr0]}>
                    <Controller
                      name="insurance_policy_holder.mobile_number"
                      control={control}
                      render={({
                        field: { value, onChange, onBlur },
                        fieldState: { error },
                        formState: { isSubmitted },
                      }) => (
                        <FloatingInput
                          showMandatory
                          maxLength={validationSchema.phoneNumber.maxLength}
                          error={isSubmitted && !!errors?.insurance_policy_holder?.country_code}
                          errorMessage={isSubmitted && error?.message}
                          value={value}
                          onChangeValue={onChange}
                          onBlur={onBlur}
                          keyboardType="numeric"
                          label={labels.phoneNumber}
                        />
                      )}
                      rules={{
                        validate: { numberValidation },
                        required: requiredValidation(labels.phoneNumber),
                        minLength: minLengthValidation(validationSchema.phoneNumber.minLength),
                      }}
                    />
                  </View>
                </Column>
              </Row>

              <Row>
                <Column span={{ xs: 12, md: 6 }}>
                  <Controller
                    name="insurance_policy_holder.document_type"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Dropdown
                        list={IdentificationDocumentTypes}
                        setValue={onChange}
                        value={value}
                        error={!!error}
                        errorMessage={error?.message as string}
                        label={labels.documentType}
                        showMandatory
                      />
                    )}
                    rules={{
                      required: requiredValidation(labels.documentType),
                    }}
                  />
                </Column>
                <Column span={{ xs: 12, md: 6 }}>
                  <Controller
                    name="insurance_policy_holder.passport_number"
                    control={control}
                    render={({
                      field: { value, onChange, onBlur },
                      fieldState: { error },
                      formState: { isSubmitted },
                    }) => (
                      <FloatingInput
                        showMandatory
                        maxLength={validationSchema.passportOrIdCard.maxLength}
                        error={isSubmitted && !!error}
                        errorMessage={isSubmitted && error?.message}
                        value={value}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        label={
                          watch('insurance_policy_holder.document_type') === IdentificationDocumentsEnum.PASSPORT
                            ? labels.passport
                            : labels.identityCard
                        }
                      />
                    )}
                    rules={{
                      minLength: minLengthValidation(validationSchema.passportOrIdCard.minLength),
                      required: requiredValidation(labels.idCardOrPassport),
                    }}
                  />
                </Column>
              </Row>
              <View style={mt10} />
              <H5TtmSemiBoldBlack>{labels.homeAddress}</H5TtmSemiBoldBlack>
              <Row>
                <Column span={{ xs: 12, md: 6 }}>
                  <Controller
                    name="insurance_policy_holder.address.country_id"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Dropdown
                        list={countryListing.map((item) => ({ label: item.name, value: item.id }))}
                        setValue={onChange}
                        value={value}
                        error={!!errors?.insurance_policy_holder?.address?.country_id}
                        errorMessage={errors?.insurance_policy_holder?.address?.country_id?.message}
                        label={labels.country}
                        searchable
                        showMandatory
                      />
                    )}
                    rules={{
                      required: requiredValidation(labels.country),
                    }}
                  />
                </Column>
                <Column span={{ xs: 12, md: 6 }}>
                  <Controller
                    name="insurance_policy_holder.address.post_code"
                    control={control}
                    render={({ field: { value, onChange, onBlur }, formState: { isSubmitted } }) => (
                      <FloatingInput
                        value={value}
                        label={labels.postCode}
                        maxLength={validationSchema.postCode.maxLength}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        error={isSubmitted && !!errors?.insurance_policy_holder?.address?.post_code}
                        errorMessage={isSubmitted && errors?.insurance_policy_holder?.address?.post_code?.message}
                        showMandatory
                      />
                    )}
                    rules={{
                      required: requiredValidation(labels.postCode),
                    }}
                  />
                </Column>
              </Row>

              <Row>
                <Column span={{ xs: 12, md: 6 }}>
                  <Controller
                    name="insurance_policy_holder.address.line1"
                    control={control}
                    render={({ field: { value, onChange, onBlur }, formState: { isSubmitted } }) => (
                      <FloatingInput
                        maxLength={validationSchema.address.maxLength}
                        error={isSubmitted && !!errors?.insurance_policy_holder?.address?.line1}
                        errorMessage={isSubmitted && errors?.insurance_policy_holder?.address?.line1?.message}
                        value={value}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        label={labels.address}
                        showMandatory
                      />
                    )}
                    rules={{
                      required: requiredValidation(labels.address),
                    }}
                  />
                </Column>
              </Row>
            </>
          ) : null}

          <Row>
            <Column>
              <Splitter secondaryColor />
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <H4TtmSemiBoldBlack>Bank details</H4TtmSemiBoldBlack>
              <H6NsRegularBlack style={mb20}>
                Please insert the bank details indicating where you wish to receive claim reimbursements from your
                insurance company.
              </H6NsRegularBlack>
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="bank_beneficiary_name"
                control={control}
                render={({ field: { value, onChange, onBlur }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    maxLength={validationSchema.name.maxLength}
                    label={insuranceLabels.beneficiary}
                    showMandatory
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    error={isSubmitted && !!errors.bank_beneficiary_name}
                    errorMessage={isSubmitted && error?.message}
                  />
                )}
                rules={{
                  required: requiredValidation(insuranceLabels.beneficiary),
                }}
              />
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="bank_iban"
                control={control}
                render={({ field: { value, onChange, onBlur }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    maxLength={validationSchema.iban.maxLength}
                    label={insuranceLabels.iban}
                    showMandatory
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    error={isSubmitted && !!errors.bank_beneficiary_name}
                    errorMessage={isSubmitted && error?.message}
                  />
                )}
                rules={{
                  required: requiredValidation(insuranceLabels.iban),
                  minLength: minLengthValidation(validationSchema.iban.minLength),
                  maxLength: maxLengthValidation(validationSchema.iban.maxLength),
                }}
              />
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="bank_bic"
                control={control}
                render={({ field: { value, onChange, onBlur }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    maxLength={validationSchema.bic.maxLength}
                    label={insuranceLabels.bic}
                    showMandatory
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    error={isSubmitted && !!errors.bank_beneficiary_name}
                    errorMessage={isSubmitted && error?.message}
                  />
                )}
                rules={{
                  required: requiredValidation(insuranceLabels.bic),
                  minLength: minLengthValidation(validationSchema.bic.minLength),
                  maxLength: maxLengthValidation(validationSchema.bic.maxLength),
                }}
              />
            </Column>
          </Row>
        </Container>
      </Grid>
    </PageLayout>
  );
};
