import { screenName } from '~/utils/screenName';
const initialScreen = screenName.RegisterScreen;

const commonScreens = {
  [screenName.LoginScreen]: '',
  [screenName.ForgotPasscodeScreen]: 'login/reset',
  [screenName.RegisterScreen]: 'register',
  [screenName.RegistrationMobileOtp]: 'register/otp-mobile',
  [screenName.PassCodeRegisterScreen]: 'register/passcode',
  [screenName.RegisterTerms]: 'register/terms',
  [screenName.EmailScreen]: 'register/email',
  [screenName.RegistrationEmailOtp]: 'register/otp-email',
  [screenName.TwoStepSelection]: 'register/sfa',
  [screenName.OTPScreen_2FA]: 'login/otp',
  [screenName.PassCodeLoginScreen]: 'login/passcode',
  [screenName.UploadIdentificationScreen]: 'register/identity',
  [screenName.PersonalDetails]: 'register/details',
  [screenName.HomeAddress]: 'register/address',
  [screenName.UploadProfileScreen]: 'register/profile',
  [screenName.ScheduledAppointmentsPreferencesAvailabilitiesScreen]:
    'register/preferences/availabilities/scheduled-appointments',

  [screenName.OnboardingScreen]: 'onboarding',

  [screenName.DoctorVerification]: 'register/doctor',
  [screenName.TypeOfConsultationScreen]: 'register/consultation',
  [screenName.AddBankDetails]: 'register/bank-details',
  [screenName.PreferencesScreen]: 'register/preferences',
  [screenName.CostPerConsultationType]: 'register/preferences/cost-per-consultation',
  [screenName.AvailabilitiesScreen]: 'register/preferences/availabilities',
  [screenName.HomeVisitPreferencesAvailabilitiesScreen]: 'register/preferences/availabilities/home-visit',
  [screenName.DoctorOnDemandPreferencesAvailabilitiesScreen]: 'register/preferences/availabilities/doctor-on-demand',

  [screenName.RegisterPharmacyDetails]: 'register/pharmacy-details',
  [screenName.RegisterPharmacyLocations]: 'register/pharmacy-locations',

  [screenName.Dashboard]: 'dashboard',
  [screenName.Requests]: {
    path: 'requests',
    screens: {
      [screenName.RequestsTab]: '',
      [screenName.Notifications]: 'notifications',
      [screenName.DocumentsImport]: 'documents-import',
      [screenName.DocumentsExport]: 'documents-export',
      [screenName.ClinicPastRequests]: 'past',
    },
  },

  [screenName.ClinicConsultationRequest]: 'requests/:id',
  [screenName.Income]: 'income',

  [screenName.PatientManagement]: 'patients',
  [screenName.PatientDetails]: 'patients/:id',
  [screenName.PatientAddNew]: 'patients/new',
  [screenName.PatientEditDetails]: 'patients/:id/edit',
  [screenName.PatientNewConsultation]: 'patients/:id/new',
  [screenName.PatientDiagnosis]: 'patients/:id/diagnosis/:diagnosisId',
  [screenName.PatientHealthProfileEdit]: 'patients/:id/health-profile/edit',
  [screenName.SettingsExportData]: 'settings/account/export',
  [screenName.PermanentlyDeleteAccount]: 'settings/account/permanently-delete-account',

  [screenName.CalendarPage]: {
    path: 'calendar',
    screens: {
      [screenName.CalendarAvailabilities]: {
        path: 'availabilities',
        screens: {
          [screenName.AvailabilitiesScheduledAppointments]: 'scheduled-appointments',
          [screenName.AvailabilitiesHomeVisit]: 'home-visit',
          [screenName.AvailabilitiesDoctorsAvailableNow]: 'doctors-available-now',
        },
      },
      [screenName.CalendarAppointments]: {
        path: 'appointments',
        screens: {
          [screenName.CalendarAppointmentsMain]: 'overview',
          [screenName.CalendarAppointmentsListView]: 'list',
        },
      },
    },
  },

  [screenName.CalendarAppointmentsShow]: 'calendar/appointments/:consultationId',

  [screenName.Appointments]: 'appointments',
  [screenName.AppointmentsBooking]: 'appointments/book',
  [screenName.AppointmentsShow]: 'appointments/:consultationId',

  [screenName.Dependants]: 'family',
  [screenName.DependantsAddNew]: 'family/new',

  [screenName.Doctors]: 'doctors',

  [screenName.ProfileScreen]: {
    path: 'profile',
    screens: {
      [screenName.ProfilePersonalInformation]: 'personal-information',
      [screenName.ProfileHomeAddress]: 'home-address',
      [screenName.ProfilePaymentDetails]: 'payment-details',
      [screenName.ProfileSubscriptionPlan]: 'subscription-plan',
      [screenName.HealthProfile]: {
        path: 'health-profile',
      },
    },
  },

  [screenName.Help]: {
    path: 'help',
    screens: {
      [screenName.HelpFaq]: 'faq',
      [screenName.HelpCookiePolicy]: 'cookie-policy',
      [screenName.HelpPrivacyPolicy]: 'privacy-policy',
      [screenName.HelpTermsAndConditions]: 'terms-and-conditions',
      [screenName.HelpCancellationPolicy]: 'cancellation-policy',
      [screenName.HelpContactUs]: 'contact-us',
    },
  },
  [screenName.Diagnoses]: 'diagnoses',
  [screenName.PatientVersionDiagnosis]: 'diagnoses/:diagnosisId',
  [screenName.PatientVersionConsultation]: 'diagnoses/:diagnosisId/:consultationId',
  [screenName.ConsultationChannelPreferences]: 'register/preferences/online-consultation',
  [screenName.PrescriptionScreen]: 'prescriptions',

  [screenName.Action]: 'action/:action/:param1?/:param2?',

  [screenName.EducationCentre]: 'education-centre',
  [screenName.EducationArticle]: 'education-centre/:articleId',
  [screenName.InsuranceClaimForm]: 'claim/:consultationId',
  [screenName.Insurance]: {
    path: 'insurance',
    screens: {
      [screenName.InsuranceDetails]: 'details',
      [screenName.InsuranceClaims]: 'claims',
    },
  },

  [screenName.PatientBillingHistory]: 'billing',

  [screenName.PharmacySettings]: 'pharmacy/settings',
  [screenName.PharmacyUserManagement]: 'pharmacy/user-management',
  [screenName.PharmacyDetails]: {
    path: 'pharmacy',
    screens: {
      [screenName.PharmacyDetailsAccount]: 'account',
      [screenName.PharmacyDetailsLocation]: 'location',
    },
  },
  [screenName.PharmacyAwaitingCollection]: 'awaiting-collection',
  [screenName.PharmacyDispenseHistory]: 'dispense-history',
  [screenName.PharmacyRequestHistory]: 'request-history',
};

export const webConfig = {
  initialRouteName: initialScreen,
  screens: {
    ...commonScreens,
    [screenName.PatientHealthProfile]: 'patients/:id/health-profile',
    [screenName.PatientConsultation]: 'patients/:id/diagnosis/:diagnosisId/:consultationId',

    [screenName.DependantsShow]: {
      path: 'family/:id',
      screens: {
        [screenName.DependantPersonalInfo]: 'personal-information',
        [screenName.DependantAddress]: 'home-address',
        [screenName.DependantLinkedAccounts]: 'linked',
        [screenName.DependantHealthProfile]: 'health-profile',
      },
    },

    [screenName.Settings]: {
      path: 'settings',
      screens: {
        [screenName.SettingsConsultations]: 'consultations',
        [screenName.SettingsNotifications]: 'notifications',
        [screenName.SettingsSecurity]: 'security',
        [screenName.SettingsAccount]: 'account',
      },
    },

    [screenName.SettingsClinic]: {
      path: 'clinic-settings',
      screens: {
        [screenName.SettingsClinicConsultations]: 'consultations',
        [screenName.SettingsClinicPaymentDetails]: 'payments',
        [screenName.SettingsClinicAddress]: 'address',
      },
    },
  },
};

export const mobileConfig = {
  initialRouteName: initialScreen,
  screens: {
    ...commonScreens,
    [screenName.RegistrationStepperScreen]: 'register/registration-stepper',
    [screenName.PatientConsultationPrivateNotes]: 'patients/:id/diagnosis/:diagnosisId/:consultationId/private-note',
    [screenName.PatientConsultationHealthRecord]: 'patients/:id/diagnosis/:diagnosisId/:consultationId/health-record',
    [screenName.PatientConsultationPrescription]: 'patients/:id/diagnosis/:diagnosisId/:consultationId/prescription',
    [screenName.PatientConsultationReferral]: 'patients/:id/diagnosis/:diagnosisId/:consultationId/referral',
    [screenName.PatientConsultationSickLeave]: 'patients/:id/diagnosis/:diagnosisId/:consultationId/sick-leave',

    [screenName.PatientHealthProfileHealthMetrics]: 'patients/:id/health-profile/health-metrics',
    [screenName.PatientHealthProfileAllergies]: 'patients/:id/health-profile/allergies',
    [screenName.PatientHealthProfileMedical]: 'patients/:id/health-profile/medical',
    [screenName.PatientHealthProfileMedications]: 'patients/:id/health-profile/medications',
    [screenName.PatientHealthProfileProcedures]: 'patients/:id/health-profile/procedures',
    [screenName.PatientHealthProfileProsthesis]: 'patients/:id/health-profile/prosthesis',
    [screenName.PatientHealthProfileVaccinations]: 'patients/:id/health-profile/vaccinations',
    [screenName.PatientHealthProfileLifestyle]: 'patients/:id/health-profile/lifestyle',

    [screenName.HealthProfileHealthMetrics]: 'health-metrics',
    [screenName.HealthProfileAllergies]: 'allergies',
    [screenName.HealthProfileMedical]: 'medical',
    [screenName.HealthProfileMedications]: 'medications',
    [screenName.HealthProfileProcedures]: 'procedures',
    [screenName.HealthProfileProsthesis]: 'prosthesis',
    [screenName.HealthProfileVaccinations]: 'vaccinations',
    [screenName.HealthProfileLifestyle]: 'lifestyle',

    [screenName.DependantsShow]: 'family/:id',
    [screenName.DependantPersonalInfo]: 'family/:id/personal-information',
    [screenName.DependantAddress]: 'family/:id/home-address',
    [screenName.DependantLinkedAccounts]: 'family/:id/linked',
    [screenName.DependantHealthProfile]: 'family/:id/health-profile',

    [screenName.Settings]: 'settings',
    [screenName.SettingsConsultations]: 'settings/consultations',
    [screenName.SettingsNotifications]: 'settings/notifications',
    [screenName.SettingsSecurity]: 'settings/security',
    [screenName.SettingsAccount]: 'settings/account',
  },
};
