import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';

import { PermanentlyDeleteAccountGuest } from '~/pages/doctor/settings/PermanentlyDeleteAccountGuest';
import { OnBoardingPage } from '~/pages/onBoarding/OnBoardingPage';
import { screenName } from '~/utils/screenName';

const Stack = createNativeStackNavigator();
export const OnboardingStack: React.FC = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name={screenName.OnboardingScreen} component={OnBoardingPage} />
      <Stack.Screen name={screenName.PermanentlyDeleteAccount} component={PermanentlyDeleteAccountGuest} />
    </Stack.Navigator>
  );
};
