import { useEffect, useState } from 'react';

import { NotificationType, SettingType } from '../models/settings/constants/NotificationsEnum';
import { NotificationModel } from '../models/settings/models/NotificationModel';
import { NotificationsFormObject } from '../models/settings/models/NotificationsFormObjectModel';
import { apiGetNotificationSettings, apiUpdateNotificationSettings } from '../services/settings';

import { SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { isClinicVersion, isPatientVersion, isPharmacyVersion } from '~/utils/buildConfig';
import { DigimedMilestones, digimedMilestoneEnabled } from '~/utils/milestoneUtil';

export function useSettings() {
  const [notificationSettingsArray, setNotificationSettingsArray] = useState<NotificationModel[]>([]);
  const [notificationSettingsObject, setNotificationSettingsObject] = useState(undefined);

  const getNotificationSettings = async () => {
    await apiGetNotificationSettings()
      .then((response) => {
        setNotificationSettingsArray(response.data);
      })
      .catch(ErrorAlert);
  };

  const initialiseSettings = async () => {
    getNotificationSettings();
  };

  const updateNotificationSettings = (notificationSettings: NotificationsFormObject) => {
    const newNotifications = Object.keys(notificationSettings).reduce<NotificationModel[]>(
      (previous, key: NotificationType) => {
        previous.push(
          { notification_type: key, setting_type: SettingType.MAIL, is_enabled: notificationSettings[key].mail },
          {
            notification_type: key,
            setting_type: SettingType.NOTIFICATION,
            is_enabled: notificationSettings[key].notification,
          }
        );
        return previous;
      },
      []
    );

    updateNotificationSettingsApi(newNotifications);
  };

  const updateNotificationSettingsApi = async (newNotifications) => {
    await apiUpdateNotificationSettings(newNotifications)
      .then(() => {
        setNotificationSettingsArray(newNotifications);
        SuccessAlert(['Notification Preferences Updated Successfully!']);
      })
      .catch(ErrorAlert);
  };

  useEffect(() => {
    initialiseSettings();
  }, []);

  useEffect(() => {
    const tempObject = {};
    notificationSettingsArray.forEach((element) => {
      if (
        (!digimedMilestoneEnabled(DigimedMilestones.DoctorPatientApps) || !isPatientVersion()) &&
        element.notification_type === NotificationType.HEALTH_COMPANION
      ) {
        return;
      }
      if (
        element.notification_type === NotificationType.CONSULTATION_MESSAGE &&
        (isPharmacyVersion() || isClinicVersion())
      ) {
        return;
      }
      if (!tempObject[element.notification_type]) {
        tempObject[element.notification_type] = {};
      }
      if (!tempObject[element.notification_type][element.setting_type]) {
        tempObject[element.notification_type][element.setting_type] = element.is_enabled;
      }
      setNotificationSettingsObject(tempObject);
    });
  }, [notificationSettingsArray]);

  return {
    notificationSettingsObject,
    getNotificationSettings,
    updateNotificationSettings,
    updateNotificationSettingsApi,
  };
}
