import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { useHealthProfileDataContext } from './HealthProfileDataContext';
import { IHealthProfileItemContext } from './HealthProfileItemContextTypes';

import { DietaryRestrictionsResponse } from '~/api/models/healthProfile/responses/DietaryRestrictionsResponse';
import { apiGetDietaryRestrictions } from '~/api/services/healthProfile/dietaryRestrictions';
import { RootState } from '~/redux/reducers';
import { SET_DIETARY_RESTRICTIONS } from '~/redux/reducers/healthProfileReducer';
import { useAppDispatch } from '~/redux/store';

export const HealthProfileItemContext = React.createContext<IHealthProfileItemContext<DietaryRestrictionsResponse>>(
  {} as IHealthProfileItemContext<DietaryRestrictionsResponse>
);

export const useHealthProfileDietaryRestrictionsContext = (): IHealthProfileItemContext<DietaryRestrictionsResponse> =>
  useContext(HealthProfileItemContext);

interface Props {
  children: React.ReactNode;
}
export const HealthProfileDietaryRestrictionsProvider: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { healthProfile, accountId } = useHealthProfileDataContext();
  const dispatch = useAppDispatch();

  const getData = async () => {
    if (!healthProfile?.id) return;
    try {
      setLoading(true);
      const res = await apiGetDietaryRestrictions({ id: healthProfile.id });
      dispatch(SET_DIETARY_RESTRICTIONS(res.data));
      setLoading(false);
      return res.data;
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  return (
    <HealthProfileItemContext.Provider
      value={{
        accountId,
        healthProfileId: healthProfile?.id,
        data: [],
        getData,
        loading,
        hasData: useSelector((state: RootState) => !!state.healthProfileReducer.dietaryRestrictions),
      }}>
      {children}
    </HealthProfileItemContext.Provider>
  );
};
