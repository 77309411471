import React from 'react';

import { useNotifications } from '~/api/hooks/notifications/Notifications';
import { TextButton } from '~/components/commonButton';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { RequestsNavigation } from '~/navigation/RequestsNavigation';
import { useStoredRoute } from '~/redux/hooks/StoredRoute';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const RequestsScreen: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const { route } = useStoredRoute();
  const { markAllNotificationsAsRead, loading } = useNotifications();

  const headerRight =
    isMobile && route?.name === screenName.Notifications ? (
      <Row justifyContent="flex-end">
        <Column span="auto">
          <TextButton
            labelStyle={{ paddingVertical: 5, color: colors.purple }}
            label="Mark all as read"
            funCallback={markAllNotificationsAsRead}
            disabled={loading}
          />
        </Column>
      </Row>
    ) : null;
  return (
    <PageLayout title={labels.requests} headerRight={headerRight} hideBack scrollContentNode={<RequestsNavigation />} />
  );
};
