import { LinkingOptions } from '@react-navigation/native';
import { createURL } from 'expo-linking';
import { useEffect, useState } from 'react';

import { mobileConfig, webConfig } from '../constants/linkingConfigs';

import { useBreakpoints } from '~/utils/hooks/GridHook';

export function useLinking() {
  const prefix = createURL('/');

  const [loading, setLoading] = useState(true);

  const [linking, setLinking] = useState<LinkingOptions<any>>({
    prefixes: [prefix],
  });

  const { isMobile } = useBreakpoints();

  useEffect(() => {
    if (isMobile) {
      setLinking({ ...linking, config: mobileConfig });
    } else {
      setLinking({ ...linking, config: webConfig });
    }
    setLoading(false);
  }, [isMobile]);

  return { linking, loading };
}
