import { DocumentPickerAsset } from 'expo-document-picker';
import { openBrowserAsync } from 'expo-web-browser';
import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { flexRow } from '../../common/commonStyles';
import { colors } from '../../utils/colors';
import { SmallNsBoldLink } from '../commonText';
import { ErrorAlert } from '../modals/ErrorAlert';
import { EyeIcon, XOutline } from '../svgImages';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { checkIsDigimedFile } from '~/utils/checkIsDigimedFile';
import { isMediaModel } from '~/utils/files/isMediaModel';

interface Props {
  file: DocumentPickerAsset | MediaModel;
  remove?: () => void;
  disabled?: boolean;
}

export const DocumentNameWithX: React.FC<Props> = ({ file, remove, disabled }) => {
  const fileName = useMemo(() => {
    return 'name' in file ? file.name : '';
  }, [file]);

  const canView = useMemo(() => !!file && isMediaModel(file), [file]);
  const view = async () => {
    if (isMediaModel(file)) {
      try {
        await checkIsDigimedFile(file);
        await openBrowserAsync(file.url);
      } catch (e) {
        ErrorAlert(e);
      }
    }
  };
  return (
    <View style={[flexRow, { width: '100%', alignItems: 'center', paddingVertical: 5 }]}>
      <View style={{ flexShrink: 1 }}>
        <SmallNsBoldLink>{fileName}</SmallNsBoldLink>
      </View>
      <View
        style={[
          flexRow,
          {
            height: 1,
            flexGrow: 1,
            marginHorizontal: 7,
            backgroundColor: colors.info,
          },
        ]}
      />
      {canView ? (
        <TouchableOpacity style={[styles.iconButtons, { padding: 6, marginRight: 5 }]} onPress={view}>
          <EyeIcon width={16} height={16} color={colors.info} />
        </TouchableOpacity>
      ) : null}
      {remove && !disabled ? (
        <TouchableOpacity style={[styles.iconButtons, { padding: 8 }]} onPress={remove}>
          <XOutline width={12} height={12} color={colors.info} />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  iconButtons: {
    display: 'flex',
    justifyContent: 'center',
  },
});
