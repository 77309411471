import React from 'react';
import { Controller } from 'react-hook-form';

import FloatingInput from '~/components/floatingInputBox';
import { usePrescriptionDataContext } from '~/providers/consultation/PrescriptionDataContext';
import { validationSchema } from '~/services/validationConfig';
import { labels } from '~/utils/labels';

interface Props {
  index: number;
}

export const PrescriptionsDoseInput: React.FC<Props> = ({ index }) => {
  const {
    form: { control, rulesArray },
  } = usePrescriptionDataContext();
  return (
    <>
      <Controller
        name={`prescriptions.${index}.dose`}
        control={control}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <FloatingInput
            showMandatory
            maxLength={validationSchema.string.maxLength}
            error={!!error}
            errorMessage={error?.message}
            value={value}
            onChangeValue={onChange}
            onBlur={onBlur}
            label={labels.dose}
          />
        )}
        rules={rulesArray?.prescriptions.dose}
      />
    </>
  );
};
