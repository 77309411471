import { differenceInMinutes } from 'date-fns';
import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { ConsultationStartedBlinker } from './ConsultationStartedBlinker';
import { ConsultationTypeIcon } from '../common/consultations/ConsultationTypeIcon';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { CalendarEventModel } from '~/api/models/preferences/models/CalendarEventModel';
import {
  ExtraSmallNsRegularBlack,
  H5TtmSemiBoldBlack,
  SmallNsRegularBlack,
  SmallNsSemiBoldBlack,
} from '~/components/commonText';
import { ForwardArrow } from '~/components/svgImages';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, TIME_FORMAT, isToday, parseDateTime, stringToDate } from '~/utils/dateAndTime';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { BADGE_COLOR_MAPPING } from '../patient/insurance/constants/StatusColorMapping';
import { isDoctorVersion } from '~/utils/buildConfig';
import { ColouredStatusBadge } from '../misc/ColouredStatusBadge';
import { alignSelfCenter, flex1, mv5 } from '~/common/commonStyles';

interface Props {
  calendarEvent?: CalendarEventModel;
  consultation?: ConsultationModel;
  noHorizontalPadding?: boolean;
  showStartDate?: boolean;
  hideArrow?: boolean;
  hideIndicator?: boolean;
}

const ConsultationAgendaItem: React.FC<Props> = ({
  consultation,
  calendarEvent,
  noHorizontalPadding,
  showStartDate,
  hideArrow,
  hideIndicator,
}) => {
  const currentConsultation = useMemo(
    () => consultation || calendarEvent?.eventable,
    [consultation, calendarEvent?.eventable]
  );
  const { showDetails } = useShowAppointment(currentConsultation);

  const slotDuration = useMemo(
    () =>
      calendarEvent?.slot_duration_in_minutes || (currentConsultation?.start_at && currentConsultation?.end_at)
        ? differenceInMinutes(stringToDate(currentConsultation.end_at), stringToDate(currentConsultation.start_at))
        : null,
    [calendarEvent?.slot_duration_in_minutes]
  );

  const isOngoing = useMemo(
    () => [ConsultationStateEnum.Started, ConsultationStateEnum.Ended].includes(currentConsultation.state),
    [currentConsultation?.state]
  );

  const isStarted = useMemo(
    () => currentConsultation?.state === ConsultationStateEnum.Started,
    [currentConsultation?.state]
  );

  const isConsultationToday = useMemo(
    () => isToday(currentConsultation?.start_at ?? currentConsultation?.request_submitted_at),
    [currentConsultation?.start_at, currentConsultation?.request_submitted_at]
  );

  const isWaitingForDoctor = useMemo(
    () =>
      consultation?.type === ConsultationTypeEnum.ON_DEMAND &&
      consultation?.state === ConsultationStateEnum.Scheduled &&
      !!consultation?.doctor,
    [consultation]
  );

  const barColor = useMemo(() => {
    if (calendarEvent?.eventable?.state) {
      return BADGE_COLOR_MAPPING[calendarEvent.eventable.state]?.color;
    }
  }, [calendarEvent?.eventable?.state]);

  const iconStatusColor = useMemo(() => {
    if (isOngoing) {
      return colors.darkWarning;
    }
    if (consultation) {
      return BADGE_COLOR_MAPPING[consultation?.state]?.color;
    }
    return BADGE_COLOR_MAPPING[calendarEvent?.eventable?.state]?.color;
  }, [calendarEvent?.eventable?.state, consultation, isOngoing]);

  const badgeLabel = useMemo(() => {
    if (consultation) {
      return BADGE_COLOR_MAPPING[consultation.state]?.label;
    }
    return BADGE_COLOR_MAPPING[calendarEvent?.eventable?.state]?.label;
  }, [consultation, calendarEvent?.eventable?.state]);

  const badgeColor = useMemo(() => {
    if (consultation) {
      return BADGE_COLOR_MAPPING[consultation.state]?.color;
    }
    return BADGE_COLOR_MAPPING[calendarEvent?.eventable?.state]?.color;
  }, [consultation, calendarEvent?.eventable?.state]);

  return (
    <TouchableOpacity
      onPress={() => {
        if (currentConsultation)
          showDetails({
            consultation: currentConsultation,
          });
      }}>
      <View style={[styles.container, isOngoing ? styles.itemOngoing : null]}>
        <Grid grid={{ gutters: 4 }}>
          <Container>
            <Row style={[styles.item, noHorizontalPadding ? null : styles.itemPadding, { paddingVertical: 8 }]}>
              {hideIndicator ? null : (
                <Column span="auto" style={{ paddingLeft: 0 }}>
                  <View style={[styles.verticalBarContainer]}>
                    <View
                      style={[
                        styles.verticalBar,
                        { backgroundColor: barColor },
                        isOngoing ? styles.verticalBarOngoing : null,
                      ]}
                    />
                  </View>
                </Column>
              )}
              <Column>
                <Row>
                  <Column span="auto" alignSelf="center">
                    {isDoctorVersion() && !isStarted ? (
                      <View>
                        <ColouredStatusBadge label={badgeLabel} color={badgeColor} />
                      </View>
                    ) : null}
                  </Column>
                </Row>
                <Row>
                  <Column span="auto" alignSelf="center" style={{ paddingRight: 20 }}>
                    <View style={mv5}>
                      {!isConsultationToday ? (
                        <SmallNsRegularBlack>
                          {parseDateTime(currentConsultation?.start_at || currentConsultation?.request_submitted_at, {
                            outputFormat: DATE_FORMAT,
                          })}
                        </SmallNsRegularBlack>
                      ) : null}
                      <SmallNsSemiBoldBlack>
                        {parseDateTime(currentConsultation?.start_at || currentConsultation?.request_submitted_at, {
                          outputFormat: TIME_FORMAT,
                        })}
                      </SmallNsSemiBoldBlack>
                      {slotDuration ? <SmallNsRegularBlack>{slotDuration} mins</SmallNsRegularBlack> : null}
                    </View>
                  </Column>
                  <Column style={[flex1, alignSelfCenter]}>
                    <H5TtmSemiBoldBlack>{getAccountName(currentConsultation?.patient)}</H5TtmSemiBoldBlack>
                    <View>
                      <ConsultationTypeIcon consultation={currentConsultation} color={iconStatusColor} />
                    </View>
                  </Column>
                </Row>
              </Column>
              {isStarted ? (
                <Column span="auto" alignSelf="flex-start">
                  <ConsultationStartedBlinker />
                </Column>
              ) : isWaitingForDoctor ? (
                <Column span="auto" alignSelf="center">
                  <ExtraSmallNsRegularBlack style={{ maxWidth: 70, textAlign: 'center' }}>
                    {isDoctorVersion() ? 'Waiting to Join' : 'Waiting for Doctor to Join'}
                  </ExtraSmallNsRegularBlack>
                </Column>
              ) : !hideArrow ? (
                <Column span="auto">
                  {!isStarted ? (
                    <View style={[flex1, { alignSelf: 'flex-end', justifyContent: 'center' }]}>
                      <ForwardArrow width={15} height={15} color={isOngoing ? colors.darkWarning : barColor} />
                    </View>
                  ) : null}
                </Column>
              ) : null}
            </Row>
          </Container>
        </Grid>
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(ConsultationAgendaItem);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.white,
    paddingHorizontal: 8,
    borderRadius: 8,
  },
  itemOngoing: {
    backgroundColor: colors.lightWarning,
  },
  item: {
    minHeight: 75,
    flexGrow: 1,
    justifyContent: 'flex-start',
  },
  itemPadding: {
    paddingHorizontal: 15,
  },
  verticalBarContainer: {
    width: 4,
    height: '100%',
    position: 'relative',
    marginRight: 10,
  },
  verticalBar: {
    width: '100%',
    height: '100%',
  },
  verticalBarOngoing: {
    backgroundColor: colors.darkWarning,
  },
});
