import { useNavigation } from '@react-navigation/native';
import { Image } from 'expo-image';
import React, { useMemo } from 'react';
import { View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';

import { useEducationArticles } from '~/api/hooks/educationCentre/EducationArticlesHook';
import { mb20, ml5, mt10, textCenter } from '~/common/commonStyles';
import {
  ExtraSmallNsRegularPlaceholder,
  H2TtmSemiBoldBlack,
  H6NsRegularBlack,
  SmallNsSemiBoldBlack,
} from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { ArrowRight } from '~/components/svgImages';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { parseDateTime, DAY_MONTH_YEAR_FORMAT } from '~/utils/dateAndTime';
import { screenName } from '~/utils/screenName';

interface Props {
  scrollable?: boolean;
  hideTitle?: boolean;
}
export const EducationCentreList: React.FC<Props> = ({ scrollable, hideTitle }): JSX.Element => {
  const { educationArticles, loading } = useEducationArticles();
  const navigation = useNavigation<NavType>();

  const slicedEducationArticles = useMemo(() => educationArticles?.slice(0, 4) ?? [], [educationArticles]);

  const content = (
    <>
      {slicedEducationArticles?.map((article) => (
        <TouchableOpacity
          key={article.id}
          style={styles.article}
          onPress={() => {
            navigation.navigate(screenName.EducationArticle, { articleId: article.id });
          }}>
          {article?.cover ? (
            <View style={[styles.imageContainer]}>
              <Image
                source={{
                  uri: article?.cover?.conversions?.cover_image_resized || article?.cover.url,
                }}
                style={[StyleSheet.absoluteFill]}
                contentFit="cover"
              />
            </View>
          ) : null}

          <View style={{ flex: 1 }}>
            <SmallNsSemiBoldBlack>{article.title}</SmallNsSemiBoldBlack>
            <ExtraSmallNsRegularPlaceholder style={mt10}>
              {parseDateTime(article?.published_at, { outputFormat: DAY_MONTH_YEAR_FORMAT })}
            </ExtraSmallNsRegularPlaceholder>
          </View>
          <View style={ml5}>
            <ArrowRight width={5} height={9} />
          </View>
        </TouchableOpacity>
      ))}
      {!loading && !slicedEducationArticles?.length ? (
        <H6NsRegularBlack style={textCenter}>
          We don't have any articles for you at the moment, will try and get something interesting for you soon!
        </H6NsRegularBlack>
      ) : null}
    </>
  );
  return (
    <View style={{ flex: 1 }}>
      {hideTitle ? null : <H2TtmSemiBoldBlack style={mb20}>Digimed Education Centre</H2TtmSemiBoldBlack>}

      {scrollable ? <ScrollView children={content} /> : <View children={content} />}
      {loading && !slicedEducationArticles?.length ? <LoadingActivityIndicator /> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  article: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.white,
    minHeight: 80,
    marginVertical: 8,
    borderRadius: 6,
    padding: 12,
    alignItems: 'center',
  },
  imageContainer: {
    marginRight: 10,
    width: 50,
    height: 50,
    borderRadius: 6,
    overflow: 'hidden',
  },
});
