import Icon from '@expo/vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import React, { useMemo, useState } from 'react';
import { Linking, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Card } from 'react-native-paper';

import { Button, OutlineButton } from '../commonButton';
import {
  H3TtmSemiBoldBlack,
  H5TtmSemiBoldSuccess,
  H6NsSemiBoldBlack,
  SmallNsRegularBlack,
  SmallNsRegularRed,
  SmallNsRegularTheme,
  SmallNsSemiBoldBlack,
} from '../commonText';

import { SubscriptionIntervalEnum } from '~/api/models/subscriptions/constants/SubscriptionIntervalEnum';
import { SubscriptionStoreTypeEnum } from '~/api/models/subscriptions/constants/SubscriptionStoreTypeEnum';
import { SubscriptionPlanModel, SubscriptionTypeEnum } from '~/api/models/subscriptions/models/SubscriptionPlanModel';
import {
  SubscriptionPriceModel,
  SubscriptionPriceWithMobilePricingModel,
} from '~/api/models/subscriptions/models/SubscriptionPriceModel';
import { SubscriptionPlanMeResponse } from '~/api/models/subscriptions/responses/SubscriptionPlanMeResponse';
import { mb10, mt10, mt15, mt20 } from '~/common/commonStyles';
import { NavType } from '~/navigation/types';
import { formatAmount } from '~/utils/amountUtil';
import { isNative } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { fontFamily } from '~/utils/fontFamily';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

const getPrice = (priceModel: SubscriptionPriceWithMobilePricingModel) => {
  if (priceModel?.price_android) return priceModel.price_android.formattedPrice;

  return formatAmount(Number(priceModel.amount), 'EUR');
};
interface Props {
  plan?: SubscriptionPlanModel;
  currentPlan?: SubscriptionPlanMeResponse;
  cancelOrManagePlan: () => Promise<boolean>;
  purchasePlan: (planPrice: SubscriptionPriceModel) => Promise<void>;
  upgradePlan: (planPrice: SubscriptionPriceModel) => Promise<void>;
  resumePlan: (currentPlan: SubscriptionPlanMeResponse) => Promise<void>;
}

export const SubscriptionPlanCard: React.FC<Props> = ({
  plan,
  currentPlan,
  purchasePlan,
  cancelOrManagePlan,
  upgradePlan,
  resumePlan,
}) => {
  const [waiting, setWaiting] = useState(false);
  const { navigate } = useNavigation<NavType>();
  const { isMobile } = useBreakpoints();
  const feeStar = useMemo(() => !!plan.subscription_features?.length, [plan]);
  const feeSentence = useMemo(() => {
    const fees = plan.subscription_platform_fee?.filter((fee) => !!fee.rate);

    if (!fees?.length && plan.subscription_platform_fee?.length) {
      return 'No transaction fees';
    }
    const feeCount = fees?.length;
    if (!feeCount) return '';

    if (feeCount === 1) {
      return `A ${formatAmount(fees[0].rate, fees[0].unit)} ${fees[0].type
        .toLowerCase()
        .replace(/_/g, ' ')} will be charged for all consultations`;
    } else {
      const allFees = fees.map(
        (fee) => `${formatAmount(fee.rate, fee.unit)} ${fee.type.toLowerCase().replace(/_/g, ' ')}`
      );
      const allFeesString = `${allFees.slice(0, fees.length - 1).join(', ')} and a ${allFees[fees.length - 1]}`;
      return `A ${allFeesString} will be charged for all consultations`;
    }
  }, [plan]);

  const isCurrentPlan = useMemo(() => plan.type === currentPlan?.subscription_type, [currentPlan, plan]);
  const isCurrentPlanCancelled = useMemo(() => isCurrentPlan && currentPlan.cancel_at, [currentPlan, isCurrentPlan]);
  const isCurrentPlanTrial = useMemo(() => isCurrentPlan && !!currentPlan.is_trial, [currentPlan, isCurrentPlan]);

  const monthlyPrice = useMemo(
    () => plan.price?.find((price) => price.interval === SubscriptionIntervalEnum.MONTH),
    [plan]
  );

  const isMonthlyCurrentPlan = useMemo(() => currentPlan?.interval === SubscriptionIntervalEnum.MONTH, [currentPlan]);

  const isFreePlan = useMemo(
    () =>
      currentPlan?.subscription_type === SubscriptionTypeEnum.FREEDOCTOR ||
      currentPlan?.subscription_type === SubscriptionTypeEnum.FREEPATIENT,
    [currentPlan]
  );

  const yearlyPrice = useMemo(
    () => plan.price?.find((price) => price.interval === SubscriptionIntervalEnum.YEAR),
    [plan]
  );

  const currentPlanOnCurrentDevice = useMemo(() => {
    return (
      isCurrentPlan &&
      currentPlan.type ===
        Platform.select({
          web: SubscriptionStoreTypeEnum.STRIPE,
          ios: SubscriptionStoreTypeEnum.APPLE,
          android: SubscriptionStoreTypeEnum.GOOGLE,
        })
    );
  }, [currentPlan, plan, isCurrentPlan]);

  const showEula = Platform.OS === 'ios' && !isFreePlan;

  const goToEula = () => {
    Linking.openURL('https://www.apple.com/legal/internet-services/itunes/dev/stdeula/');
  };
  const goToPrivacyPolicy = () => {
    if (isMobile) {
      navigate(screenName.HelpPrivacyPolicy);
    } else {
      navigate(screenName.Help, { screen: screenName.HelpPrivacyPolicy });
    }
  };

  return (
    <View>
      <Card elevation={2} style={isCurrentPlan ? styles.cardSelected : styles.card}>
        <Card.Title
          title={
            <View>
              <H3TtmSemiBoldBlack>{plan.plan_title || plan.type}</H3TtmSemiBoldBlack>
            </View>
          }
          titleStyle={{ fontFamily: fontFamily.fontTitilliumRegular, fontSize: 18, flexWrap: 'wrap' }}
          rightStyle={{ paddingRight: 16 }}
          right={() => (isCurrentPlan ? <H5TtmSemiBoldSuccess>{labels.currentPlan}</H5TtmSemiBoldSuccess> : null)}
        />
        <Card.Content>
          {plan?.subscription_features?.map((feature, index) => (
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }} key={index}>
              <View style={{ alignSelf: 'center', marginRight: 16 }}>
                <Icon
                  name={feature.is_available ? 'check' : 'x'}
                  size={18}
                  color={feature.is_available ? colors.success : colors.danger}
                />
              </View>
              <View style={{ paddingRight: 15, flex: 1 }}>
                <View>
                  <SmallNsSemiBoldBlack>
                    {feature.title}
                    {feature.has_additional_information ? <SmallNsRegularRed>*</SmallNsRegularRed> : null}
                  </SmallNsSemiBoldBlack>
                </View>
                <View>
                  <SmallNsRegularBlack style={{ fontStyle: 'italic' }}>{feature.subtitle}</SmallNsRegularBlack>
                </View>
              </View>
            </View>
          ))}

          <View style={mt15}>
            {feeSentence ? (
              <SmallNsRegularBlack>
                {feeStar ? <SmallNsRegularRed>*</SmallNsRegularRed> : null}
                {feeSentence}
              </SmallNsRegularBlack>
            ) : null}
          </View>

          {plan?.description ? (
            <View style={mt10}>
              <SmallNsRegularBlack>{plan.description}</SmallNsRegularBlack>
            </View>
          ) : null}

          {showEula ? (
            <View style={[mt10, { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }]}>
              <SmallNsRegularBlack>For more information please visit our </SmallNsRegularBlack>
              <TouchableOpacity onPress={goToEula}>
                <SmallNsRegularTheme>Terms of Service</SmallNsRegularTheme>
              </TouchableOpacity>
              <SmallNsRegularBlack> and </SmallNsRegularBlack>
              <TouchableOpacity onPress={goToPrivacyPolicy}>
                <SmallNsRegularTheme>Privacy Policy</SmallNsRegularTheme>
              </TouchableOpacity>
            </View>
          ) : null}
        </Card.Content>
      </Card>
      {!isCurrentPlanTrial ? (
        <>
          {monthlyPrice || yearlyPrice ? (
            <View style={mt10}>
              <>
                {monthlyPrice && isFreePlan ? (
                  <Button
                    disabled={waiting}
                    label={`${getPrice(monthlyPrice)}/Month`}
                    funCallback={async () => {
                      try {
                        setWaiting(true);
                        await purchasePlan(monthlyPrice);
                      } finally {
                        setWaiting(false);
                      }
                    }}
                    style={{ ...mb10, ...mt10 }}
                  />
                ) : null}
              </>

              <>
                {yearlyPrice && (isFreePlan || (isMonthlyCurrentPlan && !isCurrentPlanCancelled)) ? (
                  <Button
                    disabled={waiting}
                    label={`${getPrice(yearlyPrice)}/Year`}
                    funCallback={async () => {
                      try {
                        setWaiting(true);
                        if (isFreePlan) {
                          await purchasePlan(yearlyPrice);
                        } else {
                          await upgradePlan(yearlyPrice);
                        }
                      } finally {
                        setWaiting(false);
                      }
                    }}
                    style={mb10}
                  />
                ) : null}
              </>

              {currentPlanOnCurrentDevice && (!isCurrentPlanCancelled || isNative()) ? (
                <OutlineButton
                  disabled={waiting}
                  label={Platform.select({ native: labels.manageSubscription, web: labels.turnOffAutoRenewal })}
                  danger
                  funCallback={async () => {
                    try {
                      setWaiting(true);
                      await cancelOrManagePlan();
                    } finally {
                      setWaiting(false);
                    }
                  }}
                />
              ) : null}
            </View>
          ) : null}
        </>
      ) : (
        <H6NsSemiBoldBlack style={mt20}>You are currently on a Premium trial subscription</H6NsSemiBoldBlack>
      )}
      <>
        {isCurrentPlanCancelled && !isNative() ? (
          <Button
            disabled={waiting}
            funCallback={async () => {
              try {
                setWaiting(true);
                await resumePlan(currentPlan);
              } finally {
                setWaiting(false);
              }
            }}
            label={labels.turnOnAutoRenewal}
          />
        ) : null}
      </>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    borderWidth: 2,
    borderColor: colors.transparent,
  },
  cardSelected: {
    borderWidth: 2,
    borderColor: colors.info,
  },
});
