import React from 'react';
import { Text } from 'react-native';

import { OngoingAppointmentCard } from './OngoingAppointmentCard';
import { UpcomingAppointmentCard } from './UpcomingAppointmentCard';
import { DashboardCard } from '../common/DashboardCard';

import { usePatientDashboardStats } from '~/api/hooks/patients/PatientDashboardStatsHook';
import { flex1 } from '~/common/commonStyles';
import { ttmSemiBold } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { Column, Row } from '~/theme/components/grid';

export const DetailsList: React.FC = (): JSX.Element => {
  const { stats, loading } = usePatientDashboardStats();

  return (
    <Row justifyContent="flex-end">
      <OngoingAppointmentCard style={{ marginTop: 22 }} />
      <UpcomingAppointmentCard style={{ marginTop: 22 }} />

      {stats.map((stat, index) => (
        <Column
          span={{ xs: 12, md: 12 / stats.length, lg: 6 / stats.length }}
          style={{ marginTop: 22 }}
          key={stat.key ?? index}>
          <DashboardCard title={stat.label}>
            {loading ? (
              <LoadingActivityIndicator style={flex1} />
            ) : (
              <Text
                adjustsFontSizeToFit
                numberOfLines={1}
                style={[ttmSemiBold, { fontSize: 36, flex: 1, display: 'flex', alignItems: 'flex-end' }]}>
                {stat.value}
              </Text>
            )}
          </DashboardCard>
        </Column>
      ))}
    </Row>
  );
};
