import React from 'react';

import { DoctorExportAccountDataComponent } from '../../../components/doctor/settings/deletePermanentlyAccount/DoctorExportAccountDataComponent';
import { PatientExportAccountDataComponent } from '../../../components/settings/deletePermanentlyAccount/PatientExportAccountDataComponent';

import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { appName, whenAppType } from '~/utils/buildConfig';
import { transformLabel } from '~/utils/labelUtil';
import { accountDeletionLabels } from '~/utils/labels/accountDeletionLabels';
import { screenName } from '~/utils/screenName';

export const SettingsExportData: React.FC = () => {
  return (
    <PageLayout
      title={transformLabel(accountDeletionLabels.exportAccountInfo, { appName })}
      back={{ to: { screen: screenName.Settings, params: { screen: screenName.SettingsAccount } } }}>
      {whenAppType({
        whenDoctor: <DoctorExportAccountDataComponent exportText />,
        whenPatient: <PatientExportAccountDataComponent exportText />,
        whenPharmacist: null,
      })}
    </PageLayout>
  );
};
