import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { ConsultationTypeEnum, ConsultationTypeIdMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { DateTimeType } from '~/api/models/common/types/DateType';
import { apiGetUsedLanguages } from '~/api/services/referenceData';
import { LatLng } from '~/integrations/mapView/interfaces/LatLng';
import { RootState } from '~/redux/reducers';
import { UPDATE_USED_LANGUAGE_LIST } from '~/redux/reducers/referenceDataReducer';
import { useAppDispatch } from '~/redux/store';

interface Props {
  type: ConsultationTypeEnum;
  ranges?: {
    from: DateTimeType;
    to: DateTimeType;
  }[];
  location?: LatLng;
}
export const useUsedLanguageListing = ({ type, ranges, location }: Props) => {
  const languageData = useSelector((state: RootState) => state.referenceDataReducer.usedLanguageList);

  const dispatch = useAppDispatch();
  const loading = useRef(false);

  const execute = async () => {
    try {
      loading.current = true;
      const topLanguageCodes = ['en', 'mt'];
      await apiGetUsedLanguages({
        filter: {
          consultation_types: type ? [ConsultationTypeIdMapping[type]] : undefined,
        },
        ranges: type === ConsultationTypeEnum.HOME_VISIT ? ranges : undefined,
        nearby: location && location.lat && location.lng ? [location.lat, location.lng] : undefined,
      }).then((res) => {
        const languages = res.data;
        languages.sort((a, b) => {
          const aInTop = topLanguageCodes.includes(a.code);
          const bInTop = topLanguageCodes.includes(b.code);
          if (aInTop || bInTop) {
            if (aInTop && !bInTop) {
              return -1;
            } else if (bInTop && !aInTop) {
              return 1;
            }
          }
          return a.name.localeCompare(b.name);
        });

        dispatch(UPDATE_USED_LANGUAGE_LIST(languages));
      });
    } catch {}
    loading.current = false;
  };

  useEffect(() => {
    if (!loading.current) {
      execute();
    }
  }, []);

  return languageData;
};
