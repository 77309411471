import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, Pressable, Animated } from 'react-native';

import { DataTableColumns } from './DataTableTypes';

import { H6NsRegularBlack } from '~/components/commonText';
import { NewBadge } from '~/components/misc/NewBadge';
import { colors } from '~/utils/colors';

interface Props {
  item: any;
  identifier: any;
  filteredColumns: DataTableColumns;
  selected?: boolean;
  rowIsNewIdentifier?: (item: any) => boolean;
  onRowPress?: (key: any, item: any) => void;
  mobileRenderItem?: (item: any, content: Record<string, { content: any; cellData: any }>) => React.ReactElement;
  cardStyle?: StyleProp<ViewStyle>;
  index: number;
}

export const DataTableCard: React.FC<Props> = ({
  item,
  mobileRenderItem,
  onRowPress,
  rowIsNewIdentifier,
  identifier,
  filteredColumns,
  selected,
  cardStyle,
  index,
}) => {
  const card = (
    <View style={{ padding: 3 }}>
      <Animated.View style={[styles.card, cardStyle, selected ? styles.selected : null]}>
        {rowIsNewIdentifier && rowIsNewIdentifier(item) ? <NewBadge style={styles.newBadge} /> : null}
        {mobileRenderItem
          ? mobileRenderItem(
              item,
              filteredColumns.reduce((previous, column) => {
                const cellData = column.selector ? column.selector(item) : item[column.key];
                const content = column.cell ? column.cell(cellData, item, index) : cellData;
                previous[column.key] = { content, cellData };
                return previous;
              }, {})
            )
          : filteredColumns.map((column, index) => {
              const cellData = column.selector ? column.selector(item) : item[column.key];
              const content = column.cell ? column.cell(cellData, item, index) : cellData;
              const isText = !column.cell;
              if (!content) return null;
              return (
                <View key={`${identifier}_${column.key}`} style={[styles.cellPadding, column.cellStyle]}>
                  {isText ? <H6NsRegularBlack style={column.cellTextStyle}>{content}</H6NsRegularBlack> : content}
                </View>
              );
            })}
      </Animated.View>
    </View>
  );

  if (onRowPress) {
    return <Pressable onPress={() => onRowPress(identifier, item)}>{card}</Pressable>;
  }
  return card;
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 2.62,
    shadowOpacity: 0.2,
    elevation: 4,
    borderRadius: 6,
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: colors.white,
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  cellPadding: {
    paddingVertical: 2,
  },
  selected: {
    backgroundColor: colors.lightPurple,
    borderWidth: 1,
    borderColor: colors.info,
    borderBottomColor: colors.info,
    borderRadius: 6,
  },
  newBadge: {
    zIndex: 5,
    position: 'absolute',
    left: 6,
    top: -8,
  },
});
