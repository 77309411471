import { Route } from '@react-navigation/native';
import React from 'react';

import { PatientDiagnosisList } from './PatientDiagnosisList';

interface Props {
  route?: Route<string, { patientId?: string; diagnosisId: string }>;
}

export const PatientDiagnosisDetails: React.FC<Props> = ({ route }) => {
  return <PatientDiagnosisList diagnosisId={Number(route.params.diagnosisId)} />;
};
