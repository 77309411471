import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useContext } from 'react';
import { Control } from 'react-hook-form';

import { FormAddressModel } from '~/api/models/addresses/models/AddressModel';
import { AddDependantRequest } from '~/api/models/dependants/requests/AddDependantRequest';
import { IdentificationDocumentsEnum } from '~/constants/documents';
import { UseWizard } from '~/utils/hooks/WizardStepsHook';

export interface AddDependantForm extends AddDependantRequest {
  same_address: boolean;
  document_type?: IdentificationDocumentsEnum;
  document_number: string;
  address?: FormAddressModel;
  relationship_media?: DocumentPickerAsset[];
}
export interface IAddDependantContext {
  control: Control<AddDependantForm>;
  wizard: UseWizard;
  submitDetails: () => Promise<void>;
  submitRequest: () => Promise<void>;
  loading?: boolean;
}

export const AddDependantContext = React.createContext<IAddDependantContext>({} as IAddDependantContext);
export const useAddDependantContext = (): IAddDependantContext => useContext(AddDependantContext);
