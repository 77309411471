import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

import { PharmacyMemberModel } from '~/api/models/pharmacy/models/PharmacyMemberModel';
import { PharmacyUserModel } from '~/api/models/pharmacy/models/PharmacyUserModel';
import { removeAsyncItem, setAsyncItem, storageKeys } from '~/common/asyncStorage';

interface IState {
  pharmacies: PharmacyMemberModel[];
  currentPharmacy: PharmacyMemberModel;
  pharmacyUsers: PharmacyUserModel[];
}

const initialState: IState = {
  pharmacies: [],
  currentPharmacy: undefined,
  pharmacyUsers: undefined,
};

const pharmacySlice = createSlice({
  name: 'pharmacy',
  initialState,
  reducers: {
    SET_CURRENT_PHARMACY: (state, action: PayloadAction<PharmacyMemberModel>) => {
      if (action.payload) {
        setAsyncItem(storageKeys.lastPharmacy, `${action.payload.pharmacy.id}`);
      } else {
        removeAsyncItem(storageKeys.lastPharmacy);
      }
      return {
        ...state,
        currentPharmacy: action.payload,
        pharmacyUsers: undefined,
      };
    },
    SET_PHARMACIES: (state, action: PayloadAction<PharmacyMemberModel[]>) => ({
      ...state,
      pharmacies: action.payload,
    }),
    SET_PHARMACY_USERS: (state, action: PayloadAction<PharmacyUserModel[]>) => ({
      ...state,
      pharmacyUsers: action.payload,
    }),
    LOGOUT: () => {
      removeAsyncItem(storageKeys.lastPharmacy);
      return {
        ...initialState,
      };
    },
  },
});

export const { SET_CURRENT_PHARMACY, SET_PHARMACIES, SET_PHARMACY_USERS, LOGOUT } = pharmacySlice.actions;
export default pharmacySlice.reducer;

export const currentPharmacySelector = (state: RootState) => state.pharmacyReducer.currentPharmacy;
export const pharmaciesSelector = (state: RootState) => state.pharmacyReducer.pharmacies;

export const adminPharmaciesSelector = createSelector(
  [(state: RootState) => state.pharmacyReducer.pharmacies],
  (pharmacies) => pharmacies?.filter((item) => item.is_admin)
);
