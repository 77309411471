import { DocumentPickerAsset } from 'expo-document-picker';
import React from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { useSickLeave } from '~/api/hooks/consultations/SickLeaveHook';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { SickLeaveCertificateEnum } from '~/api/models/consultations/constants/SickLeaveCertificateEnum';
import { UploadDocumentComponent } from '~/components/buttons/UploadDocumentComponent';
import { SmallNsRegularBlack, H4TtmSemiBoldBlack } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { ImageFormats } from '~/constants/documentUploadsConstants';
import { useSickLeaveDataContext } from '~/providers/consultation/SickLeaveDataContext';
import { validationSchema } from '~/services/validationConfig';
import { colors } from '~/utils/colors';

export const SickLeaveCertificateEdit: React.FC = () => {
  const { control, consultation } = useSickLeaveDataContext();
  const {
    data: sickLeaveData,
    addSickCertificate,
    removeSickCertificate,
  } = useSickLeave({ consultationId: consultation.id, editing: true });

  return (
    <View>
      <View style={{ paddingBottom: 20 }}>
        <SmallNsRegularBlack>
          Kindly upload a copy of the sick leave certificate or add comments for the medical not below.
        </SmallNsRegularBlack>
      </View>

      <View>
        <H4TtmSemiBoldBlack>Sick Leave Certificate - Patient's Copy</H4TtmSemiBoldBlack>
        <SmallNsRegularBlack>Upload a scan or a photo of the blue sick leave certificate</SmallNsRegularBlack>
        <View style={{ paddingVertical: 12 }}>
          <UploadDocumentComponent
            hidePreview
            uploadedFiles={sickLeaveData[SickLeaveCertificateEnum.PATIENT]?.map((item) => item.media) || []}
            add={(document: DocumentPickerAsset) => {
              return addSickCertificate({
                consultationId: consultation.id,
                type: SickLeaveCertificateEnum.PATIENT,
                document,
              });
            }}
            remove={(file: MediaModel) => {
              const item = sickLeaveData[SickLeaveCertificateEnum.PATIENT].find((item) => item.media.id === file.id);
              return removeSickCertificate({ id: item.id });
            }}
            allowedFormats={ImageFormats}
            noFilesText="No Sick Leave Certificate (Patient copy) has been uploaded yet"
          />
        </View>
      </View>
      <View>
        <H4TtmSemiBoldBlack>Sick Leave Certificate - Employer's Copy</H4TtmSemiBoldBlack>
        <SmallNsRegularBlack>Upload a scan or a photo of the blue sick leave certificate</SmallNsRegularBlack>
        <View style={{ paddingVertical: 12 }}>
          <UploadDocumentComponent
            hidePreview
            uploadedFiles={sickLeaveData[SickLeaveCertificateEnum.EMPLOYER]?.map((item) => item.media) || []}
            add={(document: DocumentPickerAsset) => {
              return addSickCertificate({
                consultationId: consultation.id,
                type: SickLeaveCertificateEnum.EMPLOYER,
                document,
              });
            }}
            remove={(file: MediaModel) => {
              const item = sickLeaveData[SickLeaveCertificateEnum.EMPLOYER].find((item) => item.media.id === file.id);
              return removeSickCertificate({ id: item.id });
            }}
            allowedFormats={ImageFormats}
            noFilesText="No Sick Leave Certificate (Employer copy) has been uploaded yet"
          />
        </View>
      </View>

      <View style={styles.splitter} />

      <View>
        <H4TtmSemiBoldBlack>Medical note</H4TtmSemiBoldBlack>

        <View style={{ borderRadius: 6, marginTop: 2 }}>
          <Controller
            name="note"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <FloatingInput
                value={value}
                onChangeValue={onChange}
                onBlur={onBlur}
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
                placeholder="Enter a medical note about this consultation"
                multiline
                maxLength={validationSchema.medicalNote.maxLength}
                label={null}
                numberOfLines={6}
                style={{ padding: 0 }}
              />
            )}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  splitter: {
    height: 1,
    width: '100%',
    backgroundColor: colors.lightPurple,
    marginVertical: 15,
  },
});
