import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';

import { screenName } from '../../utils/screenName';

import { ActionHandlerScreen } from '~/pages/actions/ActionHandlerScreen';
import { VerificationPendingDashboard } from '~/pages/dashboard/VerificationPendingDashboard';

const Stack = createNativeStackNavigator();
export const VerificationPendingStack: React.FC = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={screenName.Dashboard}>
      <Stack.Screen name={screenName.Dashboard} component={VerificationPendingDashboard} />
      <Stack.Screen name={screenName.Action} component={ActionHandlerScreen} />
    </Stack.Navigator>
  );
};
