import React from 'react';
import { View } from 'react-native';

import { ReferralsEditMode } from './ReferralsEditMode';
import { ReferralsViewMode } from './ReferralsViewMode';
import { mb10 } from '../../../../../../common/commonStyles';
import { Accordion } from '../../../../../buttons/Accordions/Accordion';
import { Stethoscope } from '../../../../../svgImages';

import { useReferralDataContext } from '~/providers/consultation/ReferralDataContext';

interface Props {
  editable?: boolean;
}

export const Referrals: React.FC<Props> = ({ editable }) => {
  const {
    form: {
      formState: { isSubmitted, isValid },
    },
    loadData,
  } = useReferralDataContext();
  return (
    <Accordion
      left={<Stethoscope width={16} height={16} />}
      title="Referrals"
      style={mb10}
      error={isSubmitted && !isValid}
      onExpandedChanged={(expanded) => expanded && loadData()}>
      <View style={{ padding: editable ? 10 : 0 }}>{editable ? <ReferralsEditMode /> : <ReferralsViewMode />}</View>
    </Accordion>
  );
};
