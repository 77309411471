import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { apiGetBiography, apiUpdateBiography } from '~/api/services/accounts/biography';
import { RootState } from '~/redux/reducers';
import { UPDATE_BIOGRAPHY } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { isDoctorVersion } from '~/utils/buildConfig';

interface Props {
  accountId: number;
}
export function useDoctorBiography({ accountId }: Props) {
  const biography = useSelector((state: RootState) => state.userDetailsReducer.biography);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isDoctorVersion() || biography || !accountId) return;

    apiGetBiography({ id: accountId })
      .then((res) => dispatch(UPDATE_BIOGRAPHY(res.data.biography)))
      .catch(() => {});
  }, [accountId]);

  const updateBiography = (biography: string) => {
    return apiUpdateBiography({ id: accountId, params: { biography } }).then((res) => {
      dispatch(UPDATE_BIOGRAPHY(biography));
      return res;
    });
  };

  return { biography, updateBiography };
}
