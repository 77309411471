import { AxiosError, AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { addMinutes, differenceInMinutes, fromUnixTime, getUnixTime } from 'date-fns';

import { getRefreshPromise, refreshAccessToken } from './refresh';
import { post } from './verbs';
import { ErrorResponse, IErrorResponse } from '../../classes/errors/ErrorResponse';
import { getParsedAsyncItem, removeAsyncItem, setObjectAsyncItem, storageKeys } from '../../common/asyncStorage';
import { AuthorisationModel, AuthorisationStoredModel } from '../models/authorisation/models/AuthorisationModel';

import { clearAllState } from '~/../modules/expo-pusher-beams';
import { LOGOUT as AccountsLogout } from '~/redux/reducers/accountsReducer';
import { LOGOUT as DependantsLogout } from '~/redux/reducers/dependantsReducer';
import { LOGOUT as ApprovalQueueLogout } from '~/redux/reducers/doctor/approvalRequestsQueueReducer';
import { LOGOUT as AvailabilitiesLogout } from '~/redux/reducers/doctor/availabilitiesReducer';
import { LOGOUT as CalendarAppointmentsLogout } from '~/redux/reducers/doctor/calendarAppointmentsReducer';
import { CLEAR_HEALTH_PROFILE as HealthProfileLogout } from '~/redux/reducers/healthProfileReducer';
import { LOGOUT as HealthRecordLogout } from '~/redux/reducers/healthRecordReducer';
import { LOGOUT as DoctorListingLogout } from '~/redux/reducers/patient/doctorListingReducer';
import { LOGOUT as InsuranceDetailsLogout } from '~/redux/reducers/patient/insuranceDetailsReducer';
import { LOGOUT as PrescriptionsLogout } from '~/redux/reducers/patient/prescriptionsReducer';
import { LOGOUT as ProfileCompletionLogout } from '~/redux/reducers/patient/profileCompletionReducer';
import { LOGOUT as PharmacyLogout } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { LOGOUT as NotificationLogout } from '~/redux/reducers/requestsReducer';
import { LOGOUT as TablePaginationLogout } from '~/redux/reducers/tablePaginationReducer';
import { LOGOUT as UserDetailsLogout } from '~/redux/reducers/userDetailsReducer';
import ReduxStore from '~/redux/store';
import { isNative } from '~/utils/buildConfig';
import { axiosLogger } from '~/utils/logger';
import { timeout } from '~/utils/promiseUtil';

const FALLBACK_EXPIRES_IN = 6;
const REFRESH_BEFORE_MINS = 5;
const REFRESH_ON_VISIBLE = 45;
const FORCE_REFRESH_ON_VISIBLE = false;
const EXPIRE_SESSION_MINUTES = isNative() ? 1 * 60 : 23 * 60;

let token: string = '';
let expiresBy: Date = null;

const isRefreshUrl = (url: string) => /^\/?(refresh-token)/.test(url);
const canRefreshUrl = (url: string) =>
  !/^\/?(login|register\/|auth\/logout)/.test(url) || /^\/?(register\/status)/.test(url);

const loadToken = async (forceLoad?): Promise<string> => {
  if (token && !forceLoad) return token;
  const loadedToken = await getParsedAsyncItem<AuthorisationStoredModel | string>(storageKeys.authToken);
  token =
    typeof loadedToken === 'string' ? loadedToken : loadedToken && 'token' in loadedToken ? loadedToken.token : '';
  if (token) {
    try {
      expiresBy =
        typeof loadedToken !== 'string' && loadedToken && 'expires_by' in loadedToken
          ? fromUnixTime(loadedToken.expires_by)
          : addMinutes(new Date(), FALLBACK_EXPIRES_IN);
    } catch {
      expiresBy = addMinutes(new Date(), FALLBACK_EXPIRES_IN);
    }
  }

  return token || '';
};

export const clearToken = () => {
  return storeToken(null);
};

export const storeToken = (tokenModel?: AuthorisationModel) => {
  if (!tokenModel) {
    token = '';
    return removeAsyncItem(storageKeys.authToken);
  } else {
    token = tokenModel.token;
    expiresBy = addMinutes(new Date(), tokenModel.expires_in);
    const expiresByTimestamp = getUnixTime(expiresBy);
    return setObjectAsyncItem<AuthorisationStoredModel>(storageKeys.authToken, {
      token: tokenModel.token,
      expires_by: expiresByTimestamp,
    });
  }
};

export const getToken = () => {
  return token;
};

export const hasToken = async (forceLoad?: boolean) => {
  return loadToken(forceLoad).then((res) => !!res);
};

/**
 * Check if the locally stored token is valid by attempting to refresh it - should be called when tab is visible
 * @returns boolean - Whether token is valid or not (Valid token will be a refreshed token)
 */
export const verifyStoredToken = async () => {
  const isExpired = await isTokenMissingOrExpired();
  if (!isExpired) {
    const minutesTillExpiry = differenceInMinutes(expiresBy, new Date());
    if (!FORCE_REFRESH_ON_VISIBLE && expiresBy && minutesTillExpiry >= REFRESH_ON_VISIBLE) {
      return true;
    }

    try {
      const refreshResult = await refreshAccessToken();
      await storeToken(refreshResult);
      return true;
    } catch {}
  }
  logout();
  return false;
};

const isTokenMissingOrExpired = async () => {
  const hasStoredToken = await hasToken(true);
  if (hasStoredToken && expiresBy) {
    const minutesAfterExpiry = differenceInMinutes(new Date(), expiresBy);
    return minutesAfterExpiry > EXPIRE_SESSION_MINUTES;
  }
  return true;
};

const refreshAndStoreToken = async () => {
  const hasStoredToken = await hasToken();
  if (hasStoredToken) {
    const tokenModel = await refreshAccessToken().catch((e) => {
      logout();
      // console.log('Request info error', error?.request?.responseURL);
      throw e;
    });
    // console.log('Refreshed token');
    storeToken(tokenModel);

    return tokenModel.token;
  }

  throw new Error('No authorization token is present');
};

/**
 * If a token is about to expire in less than REFRESH_BEFORE_MINS,
 * try and refresh the token, if it fails logout the user
 * @returns string - refreshed token
 */
const tryRefreshTokenBeforeRequest: () => Promise<string> = async () => {
  if (expiresBy && differenceInMinutes(expiresBy, new Date()) <= REFRESH_BEFORE_MINS) {
    return await refreshAndStoreToken().catch(() => token || '');
  } else {
    const isRefreshing = getRefreshPromise();
    if (isRefreshing) {
      return await isRefreshing.then((res) => res.data.data.token);
    } else {
      return token;
    }
  }
};

const onRequestFulfilled = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  // console.log('Request Fulfilled', config);
  const getTokenAsync = isRefreshUrl(config.url) ? loadToken : tryRefreshTokenBeforeRequest;
  const token = await getTokenAsync();

  if (token) config.headers['Authorization'] = `Bearer ${token}`;

  return config;
};

const onRequestError = (error) => Promise.reject(error);

const onResponseFulfilled = (response) => response;

const onResponseError = (axiosInstance: AxiosInstance) => async (error: AxiosError) => {
  // console.log('Response Error', error);
  const responseCode = +(error.response?.status || 0);

  const originalRequest: AxiosRequestConfig<any> & { _retry?: boolean } = error.config;

  if (error.code === 'ERR_NETWORK') {
    if (!originalRequest._retry) {
      await timeout(2000); // Timing it might not be the best solution
      originalRequest._retry = true;
      return axiosInstance(originalRequest);
    }

    return Promise.reject(
      new ErrorResponse({ message: 'Seems there might be an issue with your internet connection' }, 0)
    );
  } else {
    switch (responseCode) {
      case 401:
        if (canRefreshUrl(error.config.url)) {
          if (!isRefreshUrl(error.config.url) && !originalRequest._retry) {
            originalRequest._retry = true;
            const token = await refreshAndStoreToken().catch(() => {});
            if (token) return axiosInstance(originalRequest);
          }
          logout();
          // console.log('Request info', error?.request?.responseURL);
        }
        break;
      case 429:
        axiosLogger.warn('Too many attempts', error);
        logout();
        break;
    }
  }
  return Promise.reject(new ErrorResponse(error.response?.data as IErrorResponse, responseCode));
};

export const logout = async (options?: { clearDetails?: boolean; clearPushNotifications?: boolean }) => {
  // console.log('logout', token);
  if (await hasToken()) {
    await post('/auth/logout').catch(() => {});
    clearToken();
  }

  console.log('LOGOUT', options);

  ReduxStore.dispatch(UserDetailsLogout(options?.clearDetails));
  ReduxStore.dispatch(AccountsLogout());
  ReduxStore.dispatch(AvailabilitiesLogout());
  ReduxStore.dispatch(DependantsLogout());
  ReduxStore.dispatch(NotificationLogout());
  ReduxStore.dispatch(PharmacyLogout());
  ReduxStore.dispatch(InsuranceDetailsLogout());
  ReduxStore.dispatch(ProfileCompletionLogout());
  ReduxStore.dispatch(CalendarAppointmentsLogout());
  ReduxStore.dispatch(ApprovalQueueLogout());
  ReduxStore.dispatch(HealthProfileLogout());
  ReduxStore.dispatch(HealthRecordLogout());
  ReduxStore.dispatch(DoctorListingLogout());
  ReduxStore.dispatch(PrescriptionsLogout());
  ReduxStore.dispatch(TablePaginationLogout());

  if (options?.clearPushNotifications) clearAllState().catch(() => {});
};

/**
 * User initiated logout
 * Some user details are kept, push notifications are cleared
 * Redux stores with user specific data are reset
 */
export const userLogout = () => {
  console.log('User logout');
  logout({ clearDetails: false, clearPushNotifications: true });
};

/**
 * System initiated logout
 * Some user details are kept and push notifications as well
 * Redux stores with user specific data are reset
 */
export const systemLogout = () => {
  console.log('System logout');
  logout({ clearDetails: false, clearPushNotifications: false });
};

/**
 * Full logout
 * User details and Push notifications are cleared
 * Redux stores with user specific data are reset
 */
export const fullLogout = () => {
  console.log('Full logout');
  logout({ clearDetails: true, clearPushNotifications: true });
};

export { onRequestError, onRequestFulfilled, onResponseError, onResponseFulfilled };

// NOTE: https://lightrains.com/blogs/axios-intercepetors-react/
