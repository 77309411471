/**
 * README:
 * Managed to get it to work, however the final result would not then re-evaluate on change
 * Will keep this branch for future issues which may arise and this may help
 */

import { useEffect } from 'react';
import {
  ArrayPath,
  FieldArrayPath,
  FieldValues,
  Path,
  RegisterOptions,
  UseControllerProps,
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';

export type FieldRules<TFieldValues extends FieldValues = FieldValues> = {
  [K in keyof TFieldValues]?: UseControllerProps<TFieldValues>['rules'];
};

export type UseFormWithRulesProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>
> = UseFormProps<TFieldValues> &
  Partial<{
    rules: FieldRules<TFieldValues>;
    rulesArray?: Record<TFieldArrayName, Record<string, FieldArrayRules>>;
  }>;

export type FieldArrayRules = Omit<
  RegisterOptions<any, any>,
  | 'valueAsNumber'
  | 'valueAsDate'
  | 'value'
  | 'setValueAs'
  | 'shouldUnregister'
  | 'onChange'
  | 'onBlur'
  | 'disabled'
  | 'deps'
>;

export type UseFormWithRulesReturn<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>
> = UseFormReturn<TFieldValues> & {
  rules: FieldRules<TFieldValues>;
  rulesArray?: Record<TFieldArrayName, Record<string, FieldArrayRules>>;
  triggerCustomValidation: () => Promise<boolean>;
};

export function useFormWithRules<TFieldValues extends FieldValues = FieldValues>(
  props?: UseFormWithRulesProps<TFieldValues>
): UseFormWithRulesReturn<TFieldValues> {
  const form = useForm(props);

  useEffect(() => {
    if (form.register && props?.rules) {
      const keys = Object.keys(props.rules);
      for (const key of keys) {
        form.register(key as Path<TFieldValues>, props.rules[key]);
      }
    }
  }, []);

  useEffect(() => {
    if (form.register && props?.rulesArray) {
      const arrayKeys = Object.keys(props.rulesArray);
      if (!arrayKeys.length) return;
      form.watch((data, { name, type }) => {
        if (!type) {
          if (props.rulesArray[name as ArrayPath<TFieldValues>]) {
            const array = data[name];
            const keys = Object.keys(props.rulesArray[name as ArrayPath<TFieldValues>]);
            for (let i = 0; i < array.length; i++) {
              for (const key of keys) {
                form.register(
                  `${name}.${i}.${key}` as Path<TFieldValues>,
                  props.rulesArray[name as ArrayPath<TFieldValues>][key]
                );
              }
            }
          }
        }
      });
    }
  }, []);

  const triggerCustomValidation = () => {
    return new Promise<boolean>((resolve) => {
      form.handleSubmit(
        () => resolve(true),
        () => resolve(false)
      )();
    });
  };

  return {
    ...form,
    rules: props.rules || {},
    rulesArray: props.rulesArray,
    triggerCustomValidation,
  };
}
