import * as React from 'react';
import { Fragment, useMemo } from 'react';
import { View, StyleSheet, ScrollView, KeyboardAvoidingView, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { alignItemsCenter, flex1, mb10, mt10 } from '../common/commonStyles';
import { H3TtmSemiBoldBlack, H6NsRegularSecondaryBlack } from '../components/commonText';
import { colors } from '../utils/colors';

import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useKeyboard } from '~/utils/hooks/KeyboardHook';

interface Props {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  children?: React.ReactNode;
  flexContent?: boolean;

  hideRequired?: boolean;
  buttons?: React.ReactNode[];

  fixedHeader?: boolean;
  fixedContent?: boolean;
  keyboardButtonIndex?: number;
  wide?: boolean;
}
export const WizardLayout: React.FC<Props> = (props) => {
  const { isMobile } = useBreakpoints();
  const { bottom } = useSafeAreaInsets();
  const { showing: showingKeyboard } = useKeyboard();

  const subtitle =
    typeof props.subtitle === 'string' ? (
      <H6NsRegularSecondaryBlack>{props.subtitle}</H6NsRegularSecondaryBlack>
    ) : (
      props.subtitle
    );

  const titleIsString = useMemo(() => typeof props.title === 'string', [props.title]);

  const horizontalButton = !isMobile && props.wide;

  const headers = (
    <View>
      <View style={styles.titleContainer}>
        {titleIsString ? (
          <H3TtmSemiBoldBlack style={{ lineHeight: 26 }}>
            {props.title}
            {props.hideRequired ? null : <H3TtmSemiBoldBlack style={{ color: colors.danger }}>*</H3TtmSemiBoldBlack>}
          </H3TtmSemiBoldBlack>
        ) : (
          <>{props.title}</>
        )}
      </View>

      {!subtitle ? null : <View style={[styles.subtitleContainer]}>{subtitle}</View>}
    </View>
  );

  const buttons = useMemo(() => {
    const filteredButtons = props.buttons?.filter((btn) => !!btn);
    if (!filteredButtons?.length) return null;
    if (showingKeyboard) return filteredButtons[props.keyboardButtonIndex || 0];
    return (
      <View
        style={{
          display: 'flex',
          gap: 10,
        }}>
        {filteredButtons.map((button, index, arr) => (
          <View style={{ minWidth: 120 }} key={index}>
            {button}
          </View>
        ))}
      </View>
    );
  }, [props.buttons, showingKeyboard]);

  const content = <View style={[alignItemsCenter, flex1]}>{props.children}</View>;

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 90 : 75}
        style={[
          styles.subContainer,
          {
            justifyContent: 'space-between',
            marginBottom: bottom || 10,
          },
        ]}>
        {props.fixedContent || props.fixedHeader ? (
          <View style={isMobile ? styles.mobileHorizontalPadding : null}>{headers}</View>
        ) : null}
        <View style={[flex1]}>
          {!props.fixedContent ? (
            <ScrollView
              contentContainerStyle={[
                { width: '100%' },
                isMobile ? styles.mobileHorizontalPadding : null,
                { paddingVertical: 10 },
                props.flexContent && !showingKeyboard ? { flex: 1 } : null,
              ]}>
              <>
                {!props.fixedHeader ? headers : null}
                {content}
              </>
            </ScrollView>
          ) : (
            content
          )}
        </View>
        {props.buttons ? (
          <View
            style={[
              isMobile ? styles.mobileHorizontalPadding : null,
              isMobile ? mb10 : null,
              mt10,
              horizontalButton ? styles.buttonsHorizontal : null,
            ]}
            children={buttons}
          />
        ) : null}
      </KeyboardAvoidingView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  subContainer: {
    height: '100%',
    maxHeight: 1200,
    backgroundColor: colors.white,
  },
  titleContainer: {
    marginBottom: 10,
  },
  subtitleContainer: {
    minHeight: 40,
    marginBottom: 10,
  },
  mobileHorizontalPadding: {
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
  },
  buttonsHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
});
