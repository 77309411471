import React from 'react';
import { View } from 'react-native';

import { Button } from '../../components/commonButton';
import { H6NsRegularSecondaryBlack } from '../../components/commonText';
import RegistrationStepsComponent from '../../components/registration/RegistrationStepsComponent';
import { GeneralProps } from '../../interfaces/generalProps';
import RegistrationLayout from '../../layouts/RegistrationLayout';
import { labels } from '../../utils/labels';
import { screenName } from '../../utils/screenName';

const RegistrationStepperScreen: React.FC<GeneralProps> = ({ navigation }) => {
  return (
    <RegistrationLayout
      title={labels.registrationStepperTitle}
      subtitle={
        <H6NsRegularSecondaryBlack>
          {labels.registrationStepperSubMessage1}
          <H6NsRegularSecondaryBlack style={{ color: 'red' }}>*</H6NsRegularSecondaryBlack>
          {labels.registrationStepperSubMessage2}
        </H6NsRegularSecondaryBlack>
      }
      buttons={
        <Button
          funCallback={() => navigation.navigate(screenName.UploadIdentificationScreen)}
          label={labels.continue}
        />
      }>
      <View style={{ width: '100%' }}>
        <RegistrationStepsComponent />
      </View>
    </RegistrationLayout>
  );
};

//make this component available to the app
export default RegistrationStepperScreen;
