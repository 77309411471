import * as React from 'react';
import { View } from 'react-native';

import { apiSumSubAccessToken } from '../../api/services/kyi';
import { successOrErrorPopup } from '../../common/commonMethods';
import { flex1 } from '../../common/commonStyles';
import { Button } from '../../components/commonButton';
import { H6NsRegularSecondaryBlack, H6NsSemiBoldSecondaryBlack } from '../../components/commonText';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import { launchSumSub } from '../../integrations/sumsub';
import { SumSubReviewRejectionType, SumSubReviewStatus } from '../../integrations/sumsub/constants';
import { GeneralProps } from '../../interfaces/generalProps';
import { labels } from '../../utils/labels';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { isNative, isWeb } from '~/utils/buildConfig';
import { registrationLabels } from '~/utils/labels/registrationLabels';
import { SumSubApplicantStatus } from '~/integrations/sumsub/interfaces';
import { fullLogout } from '~/api/axios';

interface Props extends GeneralProps {}

const UploadIdentificationScreen: React.FC<Props> = ({ navigation }) => {
  const [complete, setComplete] = React.useState(false);
  const [sumSubStatus, setSumSubStatus] = React.useState<SumSubReviewStatus>(SumSubReviewStatus.INITIAL);
  const [infoMessage, setInfoMessage] = React.useState<string[]>([]);
  const { updateRegistrationStatus, loading } = useRegistrationSteps();

  const getKycAccessToken = () => {
    apiSumSubAccessToken()
      .then((res) => {
        const token = res.data.token;
        launchKyc(token);
      })
      .catch(ErrorAlert);
  };

  const logoutAndClear = () => {
    fullLogout();
  };

  const onSumsubCompleted = (success: boolean, payload: SumSubApplicantStatus) => {
    setComplete(true);
    let status = payload.reviewStatus;

    if (success) {
      if (isWeb() && payload.reviewStatus === SumSubReviewStatus.COMPLETED) {
        if (payload.reviewResult?.reviewRejectType === SumSubReviewRejectionType.RETRY) {
          status = SumSubReviewStatus.TEMPORARILY_DECLINED;
        } else if (payload.reviewResult?.reviewRejectType === SumSubReviewRejectionType.FINAL) {
          status = SumSubReviewStatus.FINALLY_REJECTED;
        }
      }
      setSumSubStatus(status);

      const completed = status === SumSubReviewStatus.COMPLETED || status === SumSubReviewStatus.APPROVED;
      const completedOrPending = completed || status === SumSubReviewStatus.PENDING;
      const rejected = status === SumSubReviewStatus.FINALLY_REJECTED;

      const getMessage = () => {
        switch (status) {
          case SumSubReviewStatus.COMPLETED:
          case SumSubReviewStatus.APPROVED:
            return registrationLabels.verificationCompletedMessage;
          case SumSubReviewStatus.PENDING:
            return registrationLabels.verificationPendingMessage;
          case SumSubReviewStatus.FINALLY_REJECTED:
            return registrationLabels.verificationFailedMessage;
          case SumSubReviewStatus.TEMPORARILY_DECLINED:
            return registrationLabels.verificationTemporarilyFailedMessage;
          default:
            return registrationLabels.verificationIncompleteMessage;
        }
      };
      const message = getMessage();

      if (status === SumSubReviewStatus.INITIAL || status === SumSubReviewStatus.READY) {
        setInfoMessage(message);
        return;
      }
      successOrErrorPopup(
        message,
        true,
        completed ? ModalAlertTypeEnum.SUCCESS : rejected ? ModalAlertTypeEnum.ERROR : ModalAlertTypeEnum.IN_PROGRESS,
        undefined,
        completedOrPending || rejected ? labels.continue : registrationLabels.verificationRetry,
        false,
        completedOrPending ? updateRegistrationStatus : rejected ? logoutAndClear : null
      );
    } else {
      setInfoMessage(registrationLabels.verificationErrorMessage);
    }
  };

  const launchKyc = (token: string) => {
    launchSumSub({
      accessToken: token,
      element: '#sumsub-container',
      onCompleted: onSumsubCompleted,
    });
  };

  React.useEffect(() => {
    getKycAccessToken();
  }, []);

  const buttons = [
    sumSubStatus === SumSubReviewStatus.COMPLETED || sumSubStatus === SumSubReviewStatus.PENDING ? (
      <Button funCallback={() => updateRegistrationStatus()} label={labels.continue} disabled={loading} />
    ) : sumSubStatus === SumSubReviewStatus.FINALLY_REJECTED ? (
      <Button funCallback={logoutAndClear} label={labels.continue} disabled={loading} />
    ) : complete && isNative() ? (
      <Button funCallback={getKycAccessToken} label={registrationLabels.verificationRetry} disabled={loading} />
    ) : null,
  ];
  return (
    <EnhancedRegistrationLayout fixedHeader title={registrationLabels.verificationTitle} buttons={buttons}>
      <>
        <H6NsRegularSecondaryBlack style={{ marginVertical: 15, width: '100%' }}>
          {registrationLabels.verificationDocumentsMessage1}
        </H6NsRegularSecondaryBlack>

        <View style={{ marginBottom: 20, width: '100%' }}>
          {infoMessage.length > 0 ? (
            <>
              {infoMessage.map((element, index) => (
                <H6NsSemiBoldSecondaryBlack key={index} style={{ width: '100%' }}>
                  {element}
                </H6NsSemiBoldSecondaryBlack>
              ))}
            </>
          ) : (
            <H6NsRegularSecondaryBlack style={{ marginTop: 15 }}>
              {registrationLabels.verificationDocumentsMessage2}
            </H6NsRegularSecondaryBlack>
          )}
        </View>
        <View nativeID="sumsub-container" style={[flex1, { marginBottom: 20, width: '100%' }]} />
      </>
    </EnhancedRegistrationLayout>
  );
};

//make this component available to the app
export default UploadIdentificationScreen;
