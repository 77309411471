export enum ClinicalTermTypeEnum {
  ALLERGIES = 'allergies',
  DIAGNOSES = 'diagnoses',
  DIFFERENTIAL_DIAGNOSIS = 'differential-diagnosis',
  MEDICAL_CONDITIONS = 'medical-conditions',
  MEDICATIONS = 'medications',
  PRESCRIPTION_PRODUCT = 'prescription-product',
  PROCEDURES = 'procedures',
  REFERRAL_EXAMINATIONS = 'examination',
  REFERRAL_REQUEST = 'referral-request',
  SOCIAL_FACTORS = 'social-factors',
  PATIENT_SYMPTOMS = 'patient-symptoms',
  TEMPLATE_SYMPTOMS = 'template-symptoms',
  VACCINATIONS = 'vaccinations',
}
