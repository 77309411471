import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';

import { AvailabilitiesNavigation } from './AvailabilitiesNavigation';
import { DoctorAppointmentsNavigation } from './DoctorAppointmentsNavigation';

import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { screenName } from '~/utils/screenName';

const Stack = createNativeStackNavigator();

export const CalendarNavigation: React.FC = () => {
  const { hasFeatureAccess } = useFeatureAccess();

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name={screenName.CalendarAppointments} component={DoctorAppointmentsNavigation} />
      {hasFeatureAccess(FeatureAccessEnum.CONSULTATION_TYPES) ? (
        <Stack.Screen name={screenName.CalendarAvailabilities} component={AvailabilitiesNavigation} />
      ) : null}
    </Stack.Navigator>
  );
};
