import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '../commonText';
import { Error } from '../svgImages';

import { AuthVerificationEnum } from '~/api/models/authorisation/constants/AuthVerificationEnum';
import { mb10 } from '~/common/commonStyles';
import { whenAppType } from '~/utils/buildConfig';
import verificationPendingLabels from '~/utils/labels/verificationPendingLabels';
import { useSelector } from 'react-redux';
import { RootState } from '~/redux/reducers';
import { RoleStateEnum } from '~/api/models/roles/constants';

interface Props {
  status: AuthVerificationEnum.DISABLED | AuthVerificationEnum.REJECTED;
}
export const VerificationStatusRejectedComponent: React.FC<Props> = ({ status }) => {
  const userVerificationStatus = useSelector(
    (state: RootState) => state.userDetailsReducer.verificationStatus?.digimed
  );

  const title = useMemo(() => {
    if (status === AuthVerificationEnum.DISABLED) {
      return whenAppType({
        whenPatient: verificationPendingLabels.disabledPatientTitle,
        whenDoctor: verificationPendingLabels.disabledDoctorTitle,
        whenClinic: verificationPendingLabels.disabledClinicAdminTitle,
        whenPharmacist:
          userVerificationStatus === RoleStateEnum.APPROVED
            ? verificationPendingLabels.disabledPharmacyTitle
            : verificationPendingLabels.disabledPharmacistTitle,
      });
    } else {
      return whenAppType({
        whenPatient: verificationPendingLabels.rejectedPatientTitle,
        whenDoctor: verificationPendingLabels.rejectedDoctorTitle,
        whenClinic: verificationPendingLabels.rejectedClinicAdminTitle,
        whenPharmacist:
          userVerificationStatus === RoleStateEnum.APPROVED
            ? verificationPendingLabels.rejectedPharmacyTitle
            : verificationPendingLabels.rejectedPharmacistTitle,
      });
    }
  }, [status, userVerificationStatus]);

  const message = useMemo(() => {
    if (status === AuthVerificationEnum.DISABLED) {
      return whenAppType({
        whenPatient: verificationPendingLabels.disabledPatientMessage,
        whenDoctor: verificationPendingLabels.disabledDoctorMessage,
        whenClinic: verificationPendingLabels.disabledClinicAdminMessage,
        whenPharmacist:
          userVerificationStatus === RoleStateEnum.APPROVED
            ? verificationPendingLabels.disabledPharmacyMessage
            : verificationPendingLabels.disabledPharmacistMessage,
      });
    } else {
      return whenAppType({
        whenPatient: verificationPendingLabels.rejectedPatientMessage,
        whenDoctor: verificationPendingLabels.rejectedDoctorMessage,
        whenClinic: verificationPendingLabels.rejectedClinicAdminMessage,
        whenPharmacist:
          userVerificationStatus === RoleStateEnum.APPROVED
            ? verificationPendingLabels.rejectedPharmacyMessage
            : verificationPendingLabels.rejectedPharmacistMessage,
      });
    }
  }, [status, userVerificationStatus]);

  return (
    <View style={styles.center}>
      <View style={styles.center}>
        <Error width={65} height={65} />
      </View>
      <View style={styles.center}>
        <H3TtmSemiBoldBlack>{title}</H3TtmSemiBoldBlack>
      </View>
      <View style={[styles.center, styles.textContainer]}>
        <View style={mb10}>
          <H6NsRegularBlack>{message}</H6NsRegularBlack>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  center: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  textContainer: {
    maxWidth: 380,
  },
});
