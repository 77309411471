import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { apiGetIsAllowedAccess } from '../services/authorisation';

import { getAsyncItem, setAsyncItem, storageKeys } from '~/common/asyncStorage';
import { RootState } from '~/redux/reducers';
import { UPDATE_ACCESS_DATA } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';

export function useGeoLocation() {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const access = useSelector((state: RootState) => state.userDetailsReducer.access);

  const getGeoLocationData = async () => {
    try {
      const res = await apiGetIsAllowedAccess();
      const acceptedPermission = await getAsyncItem(storageKeys.geoPermission + res.data.location?.iso_code);
      dispatch(
        UPDATE_ACCESS_DATA({ ...res.data, permission_required: res.data.permission_required && !acceptedPermission })
      );
    } catch {}
    setLoading(true);
  };

  useEffect(() => {
    getGeoLocationData();
  }, []);

  const acceptPermissionRequired = () => {
    setAsyncItem(storageKeys.geoPermission + access.location?.iso_code, 'true');
    dispatch(UPDATE_ACCESS_DATA({ ...access, permission_required: false }));
  };

  return {
    readAccess: !!access?.read_access,
    writeAccess: !!access?.write_access,
    permissionRequired: !!access?.permission_required,
    loading,
    acceptPermissionRequired,
  };
}
