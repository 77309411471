import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import { ChatMessageBubble } from './ChatMessageBubble';
import { InfiniteList } from '../../InfiniteList/InfiniteList';
import { ChannelMeansHeaders } from '../ChannelMeansHeaders';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import {
  ChatMessageModel,
  PostChatSendingRequest,
  isPostChatSendingRequest,
} from '~/api/models/consultations/models/ChatMessageModel';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { pv20, textCenter } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ImagePreviewModal } from '~/components/modals/ImagePreviewModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useAppointmentChatContext } from '~/providers/appointment/AppointmentChatContext';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { H3TtmSemiBoldBlack, SmallNsRegularBlack } from '~/components/commonText';
import { useKeyboard } from '~/utils/hooks/KeyboardHook';

interface Props {
  consultation: ConsultationModel;
  small?: boolean;
  beforeJoin?: () => Promise<void>;
}

export const Chat: React.FC<Props> = ({ consultation, small, beforeJoin }) => {
  const { allMessages, recipientTyping, pagination, loading, getMessages, isTimeForConsultation } =
    useAppointmentChatContext();
  const { registerModal } = useModalManager();
  const { isMobile } = useBreakpoints();
  const [joining, setJoining] = useState(false);
  const { showing: showingKeyboard } = useKeyboard();

  const { userDetails } = useUserDetails({ allowStale: true });

  useEffect(() => {
    registerModal(ModalName.ImagePreview, ImagePreviewModal);
  }, []);

  const typingSender = useMemo(() => {
    if (!consultation) return undefined;

    return recipientTyping && (isDoctorVersion() ? consultation.patient : consultation.doctor);
  }, [consultation, recipientTyping]);

  const joinChat = async () => {
    try {
      setJoining(true);
      if (beforeJoin) await beforeJoin();
    } catch (e) {
      ErrorAlert(e);
    }
    setJoining(false);
  };

  const consultationStarted = useMemo(
    () => consultation?.state === ConsultationStateEnum.Started,
    [consultation?.state]
  );
  const consultationScheduled = useMemo(
    () => consultation?.state === ConsultationStateEnum.Scheduled,
    [consultation?.state]
  );

  const canJoinChat = useMemo(
    () =>
      consultation?.state === ConsultationStateEnum.Started ||
      (consultation?.state === ConsultationStateEnum.Scheduled && isDoctorVersion() && isTimeForConsultation),
    [consultation, isTimeForConsultation]
  );

  const swipeLeftForDetails = useMemo(() => {
    return isMobile && isDoctorVersion() && !showingKeyboard ? (
      <View style={styles.swipeLeftDetailView}>
        <SmallNsRegularBlack style={{ color: colors.black, textAlign: 'center' }}>
          Swipe left for more details
        </SmallNsRegularBlack>
      </View>
    ) : null;
  }, [isMobile, isDoctorVersion, showingKeyboard]);

  return (
    <View
      style={[
        styles.container,
        isMobile || small ? styles.containerMobile : null,
        isMobile ? styles.containerTopBorder : null,
      ]}>
      <ChannelMeansHeaders consultation={consultation} joinedCall={consultationStarted} />

      {!consultationScheduled ? (
        <InfiniteList<ChatMessageModel | PostChatSendingRequest>
          pagination={{
            perPage: pagination?.per_page ?? 30,
            page: pagination?.current_page ?? 1,
            totalCount: pagination?.total ?? 0,
            loading,
          }}
          listProps={{
            inverted: true,
            data: allMessages,
            keyExtractor: (item) => ('id' in item ? `${item.id}` : `sending-${item.localId}`),
            contentContainerStyle: { paddingBottom: 20 },
            estimatedItemSize: 55,
            renderItem: ({ item }) => {
              return isPostChatSendingRequest(item) ? (
                <ChatMessageBubble
                  message={item.message}
                  documentResult={item.media}
                  isSender
                  sending
                  error={item.error}
                  localId={item.localId}
                />
              ) : (
                <ChatMessageBubble
                  message={item.message}
                  media={item.media}
                  time={item.created_at}
                  sender={item.sender}
                  isSender={userDetails?.account.id === item.sender.id}
                  model={item}
                />
              );
            },
            ListFooterComponent: loading ? (
              <View style={pv20}>
                <ActivityIndicator color={colors.purple} />
              </View>
            ) : null,
            ListHeaderComponent: typingSender ? <ChatMessageBubble typing sender={typingSender} /> : null,
          }}
          fetchData={getMessages}
        />
      ) : isDoctorVersion() ? (
        <View style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <View>
            {loading ? (
              <LoadingActivityIndicator />
            ) : canJoinChat ? (
              <Button label="Join chat" funCallback={joinChat} style={{ minWidth: 150 }} disabled={joining} />
            ) : !isTimeForConsultation ? (
              <View style={[styles.pendingJoinChat, { backgroundColor: colors.transparent }]}>
                <H3TtmSemiBoldBlack style={[textCenter]}>Still early to start</H3TtmSemiBoldBlack>
              </View>
            ) : null}
          </View>
        </View>
      ) : null}

      {swipeLeftForDetails}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.lightPurple2,
    overflow: 'hidden',
  },
  containerMobile: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderRadius: 0,
  },
  containerTopBorder: {
    borderTopWidth: 1,
  },
  scrollContent: {
    paddingHorizontal: 30,
    paddingTop: 50,
  },
  elevated: {},
  pendingJoinChat: {
    borderRadius: 10,
    backgroundColor: colors.white,
    padding: 40,
    maxWidth: 480,
    margin: 10,
  },
  swipeLeftDetailView: {
    paddingVertical: 10,
    alignSelf: 'center',
    width: '100%',
  },
});
