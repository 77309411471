import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React from 'react';

import { CustomMaterialTabNavigation } from '~/components/navigation/CustomMaterialTabNavigation';
import { DocumentExports } from '~/pages/doctor/requests/DocumentExports';
import { DocumentImports } from '~/pages/doctor/requests/DocumentImports';
import { NotificationsListing } from '~/pages/doctor/requests/NotificationsListing';
import { AppointmentRequests } from '~/pages/requests/AppointmentRequests';
import { ClinicAppointmentRequests } from '~/pages/requests/ClinicAppointmentRequests';
import { ClinicAppointmentsPastRequests } from '~/pages/requests/ClinicAppointmentsPastRequests';
import { PrescriptionRequests } from '~/pages/requests/PrescriptionRequests';
import { AppType, isAppType, isClinicVersion, isDoctorVersion, isNative, whenAppType } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

const Tab = createMaterialTopTabNavigator();

export const RequestsNavigation = () => {
  return (
    <Tab.Navigator
      tabBar={(props) => <CustomMaterialTabNavigation tabProps={props} />}
      screenOptions={{ swipeEnabled: isNative() }}>
      {whenAppType({
        whenClinic: (
          <Tab.Screen
            options={{ title: 'Requests' }}
            name={screenName.RequestsTab}
            component={ClinicAppointmentRequests}
          />
        ),
        whenDoctor: (
          <Tab.Screen options={{ title: 'Requests' }} name={screenName.RequestsTab} component={AppointmentRequests} />
        ),
        whenPharmacist: (
          <Tab.Screen options={{ title: 'Requests' }} name={screenName.RequestsTab} component={PrescriptionRequests} />
        ),
      })}
      {isAppType(AppType.DOCTOR, AppType.PATIENT) ? (
        <Tab.Screen
          options={{ title: 'Notifications' }}
          name={screenName.Notifications}
          component={NotificationsListing}
        />
      ) : null}
      {isDoctorVersion() ? (
        <Tab.Screen
          options={{ title: 'Document Imports' }}
          name={screenName.DocumentsImport}
          component={DocumentImports}
        />
      ) : null}
      {!isClinicVersion() ? (
        <Tab.Screen
          options={{ title: 'Document Exports' }}
          name={screenName.DocumentsExport}
          component={DocumentExports}
        />
      ) : null}
      {isClinicVersion() ? (
        <Tab.Screen
          options={{ title: 'Past Requests' }}
          name={screenName.ClinicPastRequests}
          component={ClinicAppointmentsPastRequests}
        />
      ) : null}
    </Tab.Navigator>
  );
};
