import React from 'react';

import { IncomePage } from '~/components/doctor/income/components/IncomePage';
import { IncomeListingProvider } from '~/providers/income/IncomeListingProvider';

export const IncomeScreen: React.FC = () => {
  return (
    <IncomeListingProvider>
      <IncomePage />
    </IncomeListingProvider>
  );
};
