import { Link, useNavigation } from '@react-navigation/native';
import React from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';

import { usePharmacyLinkingState, usePharmacyStoredLinkingDetails } from '~/api/hooks/pharmacy/PharmacyLinkingAction';
import { OtpChannelEnum } from '~/api/models/otp/constants/OtpChannelEnum';
import { apiSendOtp } from '~/api/services/otp';
import { apiRegisterWithEmail } from '~/api/services/registration';
import { mr10, textCenter } from '~/common/commonStyles';
import { LinkButton } from '~/components/buttons/LinkButton';
import { Button } from '~/components/commonButton';
import { H6NsRegularSecondaryBlack, H6TtmSemiBoldTheme } from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { EmailInputComponent } from '~/components/registration/EmailInputComponent';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { NavType } from '~/navigation/types';
import { UPDATE_LOGIN_DETAILS } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { labels } from '~/utils/labels';
import { registrationLabels } from '~/utils/labels/registrationLabels';
import { screenName } from '~/utils/screenName';

interface Form {
  email?: string;
}

export const RegisterPharmacyScreen: React.FC = () => {
  const { linkingDetails: pharmacyLinkingDetails } = usePharmacyStoredLinkingDetails();
  const dispatch = useAppDispatch();
  const { navigate } = useNavigation<NavType>();
  const { clearLinkingToken } = usePharmacyLinkingState();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Form>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: Form) => {
    try {
      await apiRegisterWithEmail({ email: data.email });
      dispatch(UPDATE_LOGIN_DETAILS(data));

      await apiSendOtp({ channel: OtpChannelEnum.EMAIL });
      navigate(screenName.RegistrationEmailOtp);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const subtitle = pharmacyLinkingDetails?.token ? (
    <>
      <H6NsRegularSecondaryBlack>
        To access the pharmacy, complete registration. Already registered?{' '}
        <LinkButton to={{ screen: screenName.LoginScreen } as never} title="Sign in for access" />
      </H6NsRegularSecondaryBlack>
      <H6NsRegularSecondaryBlack style={{ marginTop: 10 }}>
        If you wish to register as a new pharmacy,
        <LinkButton onPress={clearLinkingToken} title="click here" />
      </H6NsRegularSecondaryBlack>
    </>
  ) : (
    registrationLabels.registerPharmacy
  );

  const buttons = [
    <Button
      funCallback={handleSubmit(onSubmit)}
      disabled={isSubmitting}
      label={labels.startRegistration.toUpperCase()}
    />,
    <View style={[{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}>
      <H6NsRegularSecondaryBlack style={[mr10, textCenter]}>
        {`${labels.alreadyIHaveAnAccount} `}

        <Link to={{ screen: screenName.LoginScreen } as never}>
          <H6TtmSemiBoldTheme>{labels.signIn.toUpperCase()}</H6TtmSemiBoldTheme>
        </Link>
      </H6NsRegularSecondaryBlack>
    </View>,
  ];

  return (
    <EnhancedRegistrationLayout
      compressed
      hideProgress
      title={registrationLabels.registerTitleEmail}
      subtitle={subtitle}
      buttons={buttons}>
      <EmailInputComponent style={{ width: '100%', alignItems: 'flex-start' }} control={control} />
    </EnhancedRegistrationLayout>
  );
};
