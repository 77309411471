import React from 'react';
import { View } from 'react-native';

import { AppointmentListingComponent } from './AppointmentListing';

import { flex1, mt10 } from '~/common/commonStyles';
import { colors } from '~/utils/colors';

interface Props {
  hideTitle?: boolean;
}
export const AppointmentListingAndFiltering: React.FC<Props> = () => {
  return (
    <View style={{ display: 'flex', flex: 1, backgroundColor: colors.white }}>
      <View style={[{ display: 'flex', flex: 1 }, mt10]}>
        <View style={flex1}>
          <AppointmentListingComponent />
        </View>
      </View>
    </View>
  );
};
