import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UploadDataLogModel } from '~/api/models/patientData/models/UploadDataLogModel';
import { apiGetUploadedPatientDataIndex, apiGetUploadPatientDataErrors } from '~/api/services/patientDetails';
import { TablePaginationInfo, DEFAULT_TABLE_ITEMS_PER_PAGE } from '~/components/common/DataTable/DataTable';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { RootState } from '~/redux/reducers';
import { APPEND_IMPORTS_DATA } from '~/redux/reducers/requestsReducer';
import { useAppDispatch } from '~/redux/store';

export function useDocumentImportHook() {
  const importsDocuments = useSelector((state: RootState) => state.requestsReducer.imports.data);
  const importsPagination = useSelector((state: RootState) => state.requestsReducer.imports.meta);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationInfo>({
    page: 0,
    limit: DEFAULT_TABLE_ITEMS_PER_PAGE,
  });

  const [currentUploadErrors, setUploadErrors] = useState<{
    fileName: string;
    id: number;
    details: UploadDataLogModel[];
  }>(null);
  const dispatch = useAppDispatch();

  const getDocuments = (pagination: TablePaginationInfo) => {
    setTablePagination(pagination);

    setLoading(true);

    apiGetUploadedPatientDataIndex({
      page: pagination.page,
      limit: pagination?.limit || DEFAULT_TABLE_ITEMS_PER_PAGE,
      sort: '-created_at',
    })
      .then((res) => {
        dispatch(APPEND_IMPORTS_DATA(res.data));
      })
      .catch(ErrorAlert)
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (tablePagination.page === 0) return;
    getDocuments(tablePagination);
  }, [tablePagination.page]);

  const getUploadErrors = (uploadId: number) => {
    setUploadErrors(null);
    return apiGetUploadPatientDataErrors({ id: uploadId })
      .then((res) =>
        setUploadErrors({
          id: uploadId,
          fileName: importsDocuments.find((document) => document.id === uploadId)?.file_name,
          details: res.data,
        })
      )
      .catch(ErrorAlert);
  };

  return {
    setPagination: setTablePagination,
    getUploadErrors,
    currentUploadErrors,
    loading,
    importsDocuments,
    importsPagination,
  };
}
