import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { DoctorBadge } from './DoctorBadge';
import { BadgeTypeEnum } from './constants/BadgeTypeEnum';

import { AvailableDoctorModel } from '~/api/models/appointments/models/AvailableDoctorModel';

interface Props {
  doctor: AvailableDoctorModel;
  style?: StyleProp<ViewStyle>;
}

export const DoctorBadgesComponent: React.FC<Props> = ({ doctor, style }) => {
  return (
    <View style={[styles.container, style]}>
      <DoctorBadge key={3} type={BadgeTypeEnum.CLINIC} doctor={doctor} style={{ marginRight: 80 }} />
      <DoctorBadge key={1} type={BadgeTypeEnum.PATIENT_COUNT} doctor={doctor} style={{ marginRight: 80 }} />
      <DoctorBadge key={2} type={BadgeTypeEnum.EXPERIENCE} doctor={doctor} style={{ marginRight: 80 }} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    minHeight: 50,
  },
});
