import Feather from '@expo/vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import React, { ReactNode, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { ICALUrlModal } from '../../../components/calendar/modals/ICALUrlModal';
import { colors } from '../../../utils/colors';

import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { ExportTypesEnum } from '~/api/models/common/constants/ExportTypesEnum';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { apiExportData } from '~/api/services/exports';
import { InprogressAlert } from '~/common/commonMethods';
import { mb10, mr10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { OutlineButton } from '~/components/commonButton';
import { H2TtmSemiBoldBlack } from '~/components/commonText';
import { NotificationBell } from '~/components/dashboard/common/NotificationBell';
import { CalendarTypeMenu } from '~/components/doctor/calendar/CalendarTypeMenu';
import { CalendarPageTypes } from '~/components/doctor/calendar/constants/CalendarConstants';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { NavType } from '~/navigation/types';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { useStoredRoute } from '~/redux/hooks/StoredRoute';
import { Grid } from '~/theme/components/grid';
import { isClinicVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { calendarLabels } from '~/utils/labels/calendar';
import { screenName } from '~/utils/screenName';

interface Props {
  children: ReactNode;
}

export const CalendarPageLayout: React.FC<Props> = ({ children }) => {
  const { route } = useStoredRoute();
  const { navigate } = useNavigation<NavType>();
  const [pageType, setPageType] = useState<string>(CalendarPageTypes[0].value);
  const { isMobile } = useBreakpoints();
  const { openModal, registerModal } = useModalManager();
  const { hasFeatureAccess } = useFeatureAccess();

  useEffect(() => {
    if (route?.name.includes('Calendar-Appointments')) {
      setPageType('Calendar-Appointments');
    } else if (route?.name.includes('Calendar-Availabilities')) {
      setPageType('Calendar-Availabilities');
    }
  }, [route]);

  useEffect(() => {
    const selectedPageType = CalendarPageTypes.find((item) => item.value === pageType);
    if (selectedPageType) {
      const headerTitle = selectedPageType.label;
      // Set the header title dynamically using setOptions
      navigate(selectedPageType.value as never, { headerTitle });
    }
  }, [pageType, navigate]);

  useEffect(() => {
    registerModal(ModalName.ICALUrlModal, ICALUrlModal);
  }, []);

  const generateAppointmentsDownload = () => {
    apiExportData(ExportTypesEnum.APPOINTMENT_EXPORT)
      .then(() => {
        InprogressAlert(['Your appointments export are in progress, we will let you know once they are done'], {
          title: 'Exporting your appointments',
        });
      })
      .catch(ErrorAlert);
  };

  const downloadComponent = isClinicVersion() ? null : (
    <IconButton onPress={generateAppointmentsDownload} style={[mr10, mb10]}>
      <Feather name="download" size={20} color={colors.purple} />
    </IconButton>
  );

  return (
    <View style={styles.container}>
      <View
        style={[
          { flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'flex-end' },
          isMobile ? styles.headerPadding : null,
        ]}>
        <View style={styles.header}>
          <H2TtmSemiBoldBlack>{labels.calendar}</H2TtmSemiBoldBlack>
        </View>
        <View style={{ flex: 1 }} />
        {isMobile ? (
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {downloadComponent}
            <View style={{ marginBottom: 5, marginLeft: 10 }}>
              <NotificationBell />
            </View>
          </View>
        ) : (
          <Grid>
            <OutlineButton
              style={[{ maxWidth: 160 }, mr10, mb10]}
              label={calendarLabels.addToMyCalendar}
              funCallback={() => {
                openModal(ModalName.ICALUrlModal);
              }}
            />
            {downloadComponent}
            <IconButton
              onPress={() =>
                navigate(screenName.CalendarAppointments, {
                  screen: screenName.CalendarAppointmentsMain,
                })
              }
              style={[route?.name === screenName.CalendarAppointmentsMain ? styles.activeView : null, mr10, mb10]}>
              <Feather name="grid" size={20} color={colors.purple} />
            </IconButton>
            <IconButton
              onPress={() =>
                navigate(screenName.CalendarAppointments, {
                  screen: screenName.CalendarAppointmentsListView,
                })
              }
              style={[route?.name === screenName.CalendarAppointmentsListView ? styles.activeView : null, mr10, mb10]}>
              <Feather name="menu" size={20} color={colors.purple} />
            </IconButton>

            {/* SEARCH TO BE IMLPEMENTED IN BE */}
            {/* <View style={[mb10]}>
              <AppointmentListSearchComponent />
            </View> */}
          </Grid>
        )}
      </View>
      {hasFeatureAccess(FeatureAccessEnum.CONSULTATION_TYPES) ? (
        <View style={isMobile ? styles.selectorPadding : mb10}>
          <CalendarTypeMenu
            value={pageType}
            itemsList={CalendarPageTypes}
            onChange={(item) => setPageType(item.value)}
          />
        </View>
      ) : null}
      <View style={[styles.content]}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
    display: 'flex',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  noData: {
    display: 'flex',
    alignItems: 'center',
    padding: 12,
  },
  content: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.white,
  },
  row: {
    flex: 1,
  },
  column: {
    height: '100%',
  },
  activeView: {
    borderWidth: 1,
    backgroundColor: colors.lightPurple,
    borderColor: colors.info,
  },

  headerPadding: {
    paddingTop: 20,
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
  },
  selectorPadding: {
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
  },
});
