import { get, put } from '../../axios';
import { ListItemResponse } from '../../models/common/responses/ListItemResponse';
import { DietaryRestrictionsRequest } from '../../models/healthProfile/requests/DietaryRestrictionsRequest';
import { DietaryRestrictionsResponse } from '../../models/healthProfile/responses/DietaryRestrictionsResponse';

import { HealthProfileIdRequest } from '~/api/models/healthProfile/requests/HealthProfileIdRequest';

export const apiUpdateDietaryRestrictions = (params: { id: number; data: DietaryRestrictionsRequest }) => {
  return put<DietaryRestrictionsResponse>(`/health-profile/${params.id}/dietary-restrictions`, params.data);
};

export const apiGetDietaryRestrictions = (params: HealthProfileIdRequest) => {
  return get<DietaryRestrictionsResponse>(`/health-profile/${params.id}/dietary-restrictions`);
};

export const apiGetAllDietaryRestrictions = () => {
  return get<ListItemResponse>(`/dietary-restrictions`);
};
