import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { isDoctorVersion, isPatientVersion, whenAppType } from '~/utils/buildConfig';

export const useHandleConsultationCancelled = () => {
  const emitEvent = (consultationId?: number) => {
    if (isDoctorVersion()) {
      AppEventHandler.emit(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, [consultationId]);
    } else if (isPatientVersion()) {
      AppEventHandler.emit(AppEvents.PATIENT_APPOINTMENTS_CHANGED, [consultationId]);
    }
  };

  const validate = () => {
    return whenAppType({
      whenDoctor: true,
      whenPatient: true,
      else: false,
    });
  };

  const handleNotification = async (consultationId: number) => {
    if (!validate()) return;

    emitEvent(consultationId);
  };

  return { handleNotification };
};
