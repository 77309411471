import React from 'react';
import { View } from 'react-native';

import { ml10 } from '~/common/commonStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import { CheckboxGroup } from '~/components/inputs/CheckboxGroup';
import { AudioCallIcon, ChatIcon, VideoCallIcon } from '~/components/svgImages';
import { ChannelTypeEnum } from '~/constants/channelTypeEnum';
import { colors } from '~/utils/colors';

const ChannelTypes = [
  {
    label: (
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <VideoCallIcon height={18} width={18} color={colors.grey} />
        <H6NsRegularBlack style={ml10}>Video call</H6NsRegularBlack>
      </View>
    ),
    value: ChannelTypeEnum.Video,
  },
  {
    label: (
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <AudioCallIcon height={18} width={18} color={colors.grey} />
        <H6NsRegularBlack style={ml10}>Audio call</H6NsRegularBlack>
      </View>
    ),
    value: ChannelTypeEnum.Audio,
  },
  {
    label: (
      <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <ChatIcon height={18} width={18} />
        <H6NsRegularBlack style={ml10}>Chat</H6NsRegularBlack>
      </View>
    ),
    value: ChannelTypeEnum.Chat,
  },
];

interface Props {
  value: number[];
  setValue: (value: number[]) => void;
}
export const DoctorListFilterChannelTypeComponent: React.FC<Props> = ({ value, setValue }) => {
  return <CheckboxGroup items={ChannelTypes} values={value ?? []} onChange={setValue} />;
};
