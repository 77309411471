import { useNavigation } from '@react-navigation/native';
import { formatDistance } from 'date-fns';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { NotificationActions } from './NotificationActions';
import { NotificationDotMenuDesktop } from './NotificationDotMenuDesktop';
import { NotificationDotMenuMobile } from './NotificationDotMenuMobile';
import { NotificationIconComponent } from './NotificationIconComponent';
import { NotificationMessage } from './NotificationMessage';
import { colors } from '../../../utils/colors';
import { labels } from '../../../utils/labels';
import { DataTable, DataTableColumns } from '../../common/DataTable/DataTable';
import { SmallNsRegularSecondaryBlack } from '../../commonText';

import { useNotificationsWithLoad } from '~/api/hooks/notifications/Notifications';
import { ActionableNotificationData, NotificationModel } from '~/api/models/notifications/models/NotificationModel';
import { mt10, mt20 } from '~/common/commonStyles';
import { TextButton } from '~/components/commonButton';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { stringToDate, LARAVEL_DATE_TIME_SHORT } from '~/utils/dateAndTime';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';

export const NotificationsTable: React.FC = () => {
  const { notifications, loading, markAllNotificationsAsRead } = useNotificationsWithLoad();
  const { showNewBadge } = useNewBadge({
    extraPredicate: (item: NotificationModel) => !item.read_at,
  });

  const columns: DataTableColumns = [
    {
      key: 'icon',
      cell: (_, itemData: NotificationModel) => (
        <View style={styles.iconBackground}>
          <NotificationIconComponent />
        </View>
      ),
      cellStyle: styles.notification,
      columnStyle: {
        minWidth: 50,
        maxWidth: 60,
      },
    },
    {
      key: 'data',
      cell: (cellData: NotificationModel['data'], itemData: NotificationModel) => (
        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
          <NotificationMessage message={cellData.message} read={!!itemData.read_at} />
          <NotificationActions notificationData={itemData.data} createdAt={itemData.created_at} />
        </View>
      ),
      cellStyle: styles.textContainer,
      columnStyle: {
        maxWidth: null,
        flex: 1,
      },
    },
    {
      key: 'created_at',
      cell: (cellData) => <SmallNsRegularSecondaryBlack>{cellData}</SmallNsRegularSecondaryBlack>,
      selector: (item: NotificationModel) =>
        item?.created_at
          ? formatDistance(stringToDate(item.created_at, { parseFormat: LARAVEL_DATE_TIME_SHORT }), new Date()) + ' ago'
          : '',
      columnStyle: {
        maxWidth: 190,
      },
      cellStyle: [styles.placeEnd, { paddingRight: 50 }],
    },
    {
      key: 'dropdown',
      cell: (cellData, itemData) => (
        <View style={{ display: 'flex', alignItems: 'center' }}>
          <NotificationDotMenuDesktop notification={itemData} />
        </View>
      ),
      cellStyle: [styles.placeEnd, { minWidth: 30, maxWidth: 30 }],
      headerStyle: [styles.placeEnd],
    },
  ];

  return (
    <DataTable
      tableId="notifications"
      columns={columns}
      items={notifications}
      emptyNote="No current notifications."
      loading={loading}
      rowIsNewIdentifier={(item) => showNewBadge({ value: item })}
      headers={() => (
        <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flex: 1 }}>
          <TextButton label={labels.markAsAllRead} funCallback={markAllNotificationsAsRead} />
        </View>
      )}
      hidePagination
      noHorizontalScroll
      mobileBreakpoint={DeviceSizeDefaults.tablet}
      mobileRenderItem={(item: NotificationModel, content) => (
        <View style={{ display: 'flex', width: '100%' }}>
          <View
            style={[
              {
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: 1,
              },
            ]}
            key={item.id}>
            <View style={styles.iconBackground}>
              <NotificationIconComponent code={(item.data as ActionableNotificationData)?.notification_code} />
            </View>
            <View style={{ flex: 1, paddingHorizontal: 15 }}>
              <NotificationMessage message={item.data.message} read={!!item.read_at} />
              <SmallNsRegularSecondaryBlack style={mt10}>{content?.created_at?.cellData}</SmallNsRegularSecondaryBlack>
            </View>
            <View>
              <NotificationDotMenuMobile notification={item} />
            </View>
          </View>

          <View style={{ display: 'flex', alignItems: 'center' }}>
            <NotificationActions notificationData={item.data} style={mt20} createdAt={item.created_at} />
          </View>
        </View>
      )}
    />
  );
};

const styles = StyleSheet.create({
  notification: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 50,
  },
  iconBackground: {
    backgroundColor: colors.lightPurple,
    padding: 10,
    borderRadius: 30,
  },
  textContainer: {
    marginLeft: 15,
  },
  placeEnd: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  arrow: {
    flexDirection: 'column',
    marginLeft: -5,
  },
});
