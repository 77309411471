import * as Clipboard from 'expo-clipboard';
import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { apiPostGenerateIcalToken } from '~/api/services/calendar';
import { mr15, mr5, mt10 } from '~/common/commonStyles';
import { Accordion } from '~/components/buttons/Accordions/Accordion';
import { AccordionGroupProvider } from '~/components/buttons/Accordions/AccordionGroupProvider';
import { Button, TextButton } from '~/components/commonButton';
import {
  H3TtmSemiBoldBlack,
  H6NsRegularBlack,
  SmallNsRegularBlack,
  SmallNsRegularTheme,
} from '~/components/commonText';
import { ModalContainer } from '~/components/modals/ModalContainer';
import { BaseModalProps } from '~/components/modals/interfaces/ModalProps';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { helpLabels } from '~/utils/labels/helpLabels';

interface Props extends BaseModalProps {}

export const ICALUrlModal: React.FC<Props> = ({ onHide }) => {
  const [copied, setCopied] = useState(false);
  const [icalUrl, setIcalUrl] = useState('');
  const [loading, setLoading] = useState(true);

  const getIcalUrl = async () => {
    const res = await apiPostGenerateIcalToken();
    setIcalUrl(res.data.url);
  };

  useEffect(() => {
    setLoading(true);
    getIcalUrl().then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  const copyToClipboard = async () => {
    try {
      await Clipboard.setStringAsync(icalUrl);
      setCopied(true);
    } catch {}
  };

  return (
    <ModalContainer
      hideOnBackground
      showInfoLine
      wide
      buttons={[<Button label={labels.close} funCallback={onHide} />]}
      onHide={onHide}>
      <View>
        <View style={styles.modalHeader}>
          <View style={styles.modalTitle}>
            <H3TtmSemiBoldBlack>Save your Digimed appointments to your Calendar</H3TtmSemiBoldBlack>
          </View>

          <H6NsRegularBlack>
            To ensure you never miss an appointment, link your Digimed appointments to your personal calendar by
            following the steps below.
          </H6NsRegularBlack>
          <H6NsRegularBlack style={mt10}>
            Note that the process might vary slightly based on your calendar software:
          </H6NsRegularBlack>

          <AccordionGroupProvider>
            {Object.keys(helpLabels.ical).map((key) => {
              return (
                <Accordion groupValue={key} key={key} title={helpLabels.ical[key].title} style={mt10}>
                  <View>
                    {helpLabels.ical[key].content.map((instruction, index) => (
                      <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 4 }} key={key + index}>
                        <SmallNsRegularBlack style={mr5}>{index + 1}.</SmallNsRegularBlack>
                        <SmallNsRegularBlack>{instruction}</SmallNsRegularBlack>
                      </View>
                    ))}
                  </View>
                </Accordion>
              );
            })}
          </AccordionGroupProvider>
          <View style={{ width: 30 }} />
        </View>
        {loading ? null : (
          <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', flex: 1 }}>
            <Pressable
              style={styles.urlBox}
              onPress={() => {
                copyToClipboard();
              }}>
              <SmallNsRegularTheme>{icalUrl}</SmallNsRegularTheme>
            </Pressable>
            <TextButton
              label={copied ? 'COPIED' : 'COPY LINK'}
              style={{ minWidth: 65 }}
              funCallback={() => {
                copyToClipboard();
              }}
            />
          </View>
        )}
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  modalContent: {
    flex: 1,
  },
  modalHeader: {
    marginBottom: 20,
  },

  modalTitle: {
    flex: 1,
    display: 'flex',
    marginBottom: 10,
    alignItems: 'center',
  },

  urlBox: {
    backgroundColor: colors.lightPurple,
    padding: 10,
    flex: 1,
    ...mr15,
    flexBasis: 200,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.purple,
  },
});
