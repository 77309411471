import { post } from '~/api/axios';
import { IdModel } from '~/api/models/common/models/IdModel';
import { UpdateDoctorLocationRequest } from '~/api/models/consultations/requests/UpdateDoctorLocationRequest';

export const apiStartHomeVisitNavigation = (params: IdModel) => {
  return post(`consultations/${params.id}/navigation/start`);
};
export const apiPauseHomeVisitNavigation = (params: IdModel) => {
  return post(`consultations/${params.id}/navigation/pause`);
};
export const apiEndHomeVisitNavigation = (params: IdModel) => {
  return post(`consultations/${params.id}/navigation/end`);
};

export const apiUpdateDoctorLocation = (params: UpdateDoctorLocationRequest) => {
  return post(`consultations/${params.consultationId}/navigation`, params.data);
};
