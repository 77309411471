import { RegistrationMilestoneModel } from '~/api/models/registration/models/RegistrationMilestoneModel';

const RegistrationKeyToProgress = {
  submit_mobile: 0,
  accept_terms: 1,
  mobile_verify: 2,
  passcode: 3,
  submit_email: 4,
  verify_email: 5,
  two_factor_preference: 5,
  identity_verification: 6,
  personal_details: 7,
  submit_address: 8,
  submit_profile_image: 9,
  submit_medical_registration_number: 10,
  doctor_verification_documents: 10,
};
const REGISTRATION_COMPLETED_VALUE = 11;

const getProgress = (progressKey: string) => {
  return (RegistrationKeyToProgress[progressKey] ?? 0) / REGISTRATION_COMPLETED_VALUE;
};

const nextNotDone = (registrationSteps: RegistrationMilestoneModel[]) => {
  for (let i = 0; i < registrationSteps.length; i++) {
    const registrationStep = registrationSteps[i];

    for (let innerIndex = 0; innerIndex < registrationStep.steps.length; innerIndex++) {
      const step = registrationStep.steps[innerIndex];
      if (!step.done) {
        return step.key;
      }
    }
  }
};

export const getRegistrationProgress = (registrationSteps: RegistrationMilestoneModel[]) => {
  if (!registrationSteps) return 0;
  const nextKey = nextNotDone(registrationSteps);
  return getProgress(nextKey);
};
