// import DocumentPicker from 'react-native-document-picker';
import { colors } from '../utils/colors';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { GenderTypeEnum } from '~/api/models/common/constants/GenderTypeEnum';

export const UserRole = {
  SuperAdmin: 1,
  Admin: 2,
  Doctor: 'doctor',
  Patient: 'patient',
};

export enum DerivedImageFormats {
  '.png',
  '.PNG',
  '.jpg',
  '.JPG',
  '.jpeg',
  '.JPEG',
}

export enum ImageFileExtensions {
  'png',
  'PNG',
  'jpg',
  'JPG',
  'jpeg',
  'JPEG',
}

export enum ConsultationTypes {
  'Scheduled appointment' = 1,
  'Home vist',
  'Doctor on-demand',
}

export enum AppointmentStatus {
  Pending = 'NEW', //1
  Confirmed = 'ACCEPTED', //2
  Done = 'COMPLETED', //3
  Cancelled = 'CANCELLED', //4
  Expired = 'EXPIRED', //5
  Rejected = 'REJECTED', //6
}
export enum NotificationStatus {
  New = 'NEW', //1
}

export const communicationChannelName = {
  '1': 'Video call',
  '2': 'Audio call',
  '3': 'Chat',
  '4': 'Home',
};
export enum CommunicationChannels {
  Chat = 1,
  AudioCall,
  VideoCall,
}

export enum OnDemandConsultationType {
  NotEnable = 0,
  Enable,
}
export enum HomeVisitConsultationType {
  NotEnable = 0,
  Enable,
}
export enum ScheduleAppointmentConsultationType {
  NotEnable = 0,
  Enable,
}

export const onlineConsultationChannelTypes = [
  {
    label: 'Video Call',
    id: CommunicationChannels.VideoCall,
  },
  {
    label: 'Audio Call',
    id: CommunicationChannels.AudioCall,
  },
  {
    label: 'Chat',
    id: CommunicationChannels.Chat,
  },
];

export const consultationTypes = {
  '1': 'Scheduled Appointment',
  '2': 'Home vist',
  '3': 'Doctor on-demand',
};

export const onlineConsultationTypes = [
  {
    id: '1',
    name: 'Scheduled For Later',
    learn_name: 'Scheduled For Later',
  },
  {
    id: '2',
    name: 'Schedule A Home visit',
    learn_name: 'Home visit',
  },
  {
    id: '3',
    name: 'Search For Available Doctors Online',
  },
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const days = [
  { label: 'M', value: 'M', name: 'Mon', isSelected: false },
  { label: 'T', value: 'T', name: 'Tue', isSelected: false },
  { label: 'W', value: 'W', name: 'Wed', isSelected: false },
  { label: 'T', value: 'T', name: 'Thu', isSelected: false },
  { label: 'F', value: 'F', name: 'Fri', isSelected: false },
  { label: 'S', value: 'S', name: 'Sat', isSelected: false },
  { label: 'S', value: 'S', name: 'Sun', isSelected: false },
];

export const googleMapApiKey = 'AIzaSyAT5tpb333HWJ8RIkaNXqkAPGim6XTODDg'; // client
// export const googleMapApiKey = 'AIzaSyCHfk1ykNi_lmM9yLAjUuLUJ5Ckfpupouo'; //

export const defaultLocation = {
  Latitude: 11.304175807061,
  Longitude: 77.014057226479,
};
export const defaultImage = 'https://i.ibb.co/26Ryd0d/user.png';
export const genderList = [
  { name: 'Male', id: GenderTypeEnum.MALE },
  { name: 'Female', id: GenderTypeEnum.FEMALE },
  { name: 'Other', id: GenderTypeEnum.OTHER },
];

export const triggerStyles = {
  triggerTouchable: {
    underlayColor: colors.white,
    borderRadius: 10,
    activeOpacity: 0.5,
  },
};

export const optionsStyles = {
  optionsContainer: {
    borderRadius: 6,
    marginTop: 45,
    width: 150,
    alignItems: 'center' as 'center',
  },
  optionWrapper: {
    padding: 15,
  },
  optionTouchable: {
    underlayColor: colors.white,
    activeOpacity: 0.5,
  },
};

export const incomeOptionsStyles = {
  optionsContainer: {
    borderRadius: 6,
    // marginTop: 25,
    width: 150,
    alignItems: 'center' as 'center',
  },
  optionWrapper: {
    padding: 5,
  },
  optionTouchable: {
    underlayColor: colors.white,
    activeOpacity: 0.5,
  },
};

// export const pusherChannelName = 'digmed-chat-channel';
// export const pusherId = 'dee32252d340b8b2925d';
export const defaultCountryCode = { id: '356', name: '+356' };

export const qrImage =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png';

export enum UserCompletion {
  InitialValue = 0,
  RegistrationCompleted = 1,
  SumsubCompleted,
  PersonalDetailsCompleted,
  DoctorVerificationCompleted,

  StripeIntegrationCompleted,
  PreferenceCompleted,
  OnboardingCompleted,
}

export enum AdminStatus {
  OnBoarding = 0,
  Inprogress,
  Approved,
  Declined,
  InActive = -1,
}
export enum SumsubStatus {
  Inprogress = 0,
  Approved,
  Declined,
}

export enum OtpTypes {
  message = 1,
  email,
}

export const consultationFilters = [
  { label: 'Scheduled Appointment', type: '1', isSelect: false },
  { label: 'Doctors Available Now', type: '3', isSelect: false },
  { label: 'Home Visits', type: '2', isSelect: false },
  // {label: 'All', type: 'all', isSelect: false},
];

export const incomeDashBoardFilters = [
  // {name: 'This week', id: 'this_week'},
  // {name: 'Last week', id: 'last_week'},
  // {name: 'This month', id: 'this_month'},
  // {name: 'Last month', id: 'last_month'},
  // {name: 'This year', id: 'this_year'},
  // {name: 'Last year', id: 'last_year'},

  { name: 'Week', id: 'week' },
  { name: 'Month', id: 'month' },
];

export const incomeFilters = [
  { name: 'To date', id: 'to_date' },
  { name: 'This week', id: 'this_week' },
  { name: 'Last week', id: 'last_week' },
  { name: 'This month', id: 'this_month' },
  { name: 'Last month', id: 'last_month' },
  { name: 'This year', id: 'this_year' },
  { name: 'Last year', id: 'last_year' },
  { name: 'Custom', id: 'custom' },
];

export const mocKChartData = [
  { month: 'Jan', earnings: 12 },
  { month: 'Feb', earnings: 20 },
  { month: 'Mar', earnings: 123 },
  { month: 'Apr', earnings: 12 },
  // {month:5,earnings:56},
  // {month:6,earnings:10},
  // {month:7,earnings:12},
  // {month:8,earnings:5},
  // {month:9,earnings:50},
  // {month:10,earnings:12},
  // {month:11,earnings:5},
  // {month:12,earnings:100},

  // {month:13,earnings:10},
  // {month:14,earnings:12},
  // {month:15,earnings:5},
  // {month:16,earnings:50},
  // {month:17,earnings:12},
  // {month:18,earnings:5},
  // {month:19,earnings:100},
  // {month:20,earnings:56},
];

export const auditLogOptionsStyles = {
  optionsContainer: {
    borderRadius: 6,
    // marginTop: 25,
    width: '70%',
    alignItems: 'center' as 'center',
  },
  optionWrapper: {
    padding: 5,
  },
  optionTouchable: {
    underlayColor: colors.white,
    activeOpacity: 0.5,
  },
};

export enum SumsubIframeStatus {
  Ready = 'Ready',
  Failed = 'Failed',
  Initial = 'Initial', // Once application is created or initial (without uploading any docs)
  Incomplete = 'Incomplete', // Incomplete for when i have uploaded the only document and not uploaded the selfi
  Pending = 'Pending', // after uploaded the r verificattion status (selfi and id doc) waiting for sumsub admin responce
  TemporarilyDeclined = 'TemporarilyDeclined', // something wrong in first name and last name
  FinallyRejected = 'FinallyRejected',
  Approved = 'Approved',
  ActionCompleted = 'ActionCompleted',
}

export const ConsultationTypeName = {
  [ConsultationTypeEnum.CLINIC]: 'Clinic',
  [ConsultationTypeEnum.HOME_VISIT]: 'Home visit',
  [ConsultationTypeEnum.ON_DEMAND]: 'Doctors available now',
  [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: 'Scheduled appointment',
};
