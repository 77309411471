import React, { useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Menu } from 'react-native-paper';

import { DashboardCard } from '../common/DashboardCard';

import { apiGetDoctorDashboardIncomeTotals } from '~/api/services/dashboard';
import { flex1, mb25 } from '~/common/commonStyles';
import { H2TtmSemiBoldBlack, H3TtmSemiBoldBlack } from '~/components/commonText';
import { DataRangeOptionsMapping } from '~/components/doctor/income/constants/DateRangeOptions';
import { DateRangeOptions } from '~/components/patient/insurance/constants/DateRangeOptionsEnum';
import { DownArrow } from '~/components/svgImages';
import { IncomeListingProvider } from '~/providers/income/IncomeListingProvider';
import { colors } from '~/utils/colors';
import { DATE_FORMAT_2DIGIT_YEAR, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

export const IncomeCard: React.FC = () => {
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeOptions | undefined>(DateRangeOptions.THISWEEK);
  const [totalIncome, setTotalIncome] = useState('');
  const { isMobile } = useBreakpoints();
  const showMenu = () => setMenuVisible(true);
  const hideMenu = () => setMenuVisible(false);

  const dateRangeFilter = (value) => {
    const allowedValues = [DateRangeOptions.THISWEEK, DateRangeOptions.THISMONTH, DateRangeOptions.THISYEAR];
    return allowedValues.includes(value);
  };
  const filteredDateRanges = Object.values(DateRangeOptions).filter(dateRangeFilter);

  const fetchIncomeTotals = async (dateRange) => {
    const params = {
      filter: {
        date_from: parseDateTime(DataRangeOptionsMapping[dateRange]?.range.startDate, {
          outputFormat: DATE_FORMAT_2DIGIT_YEAR,
        }),
        date_to: parseDateTime(DataRangeOptionsMapping[dateRange]?.range.endDate, {
          outputFormat: DATE_FORMAT_2DIGIT_YEAR,
        }),
      },
    };
    const result = await apiGetDoctorDashboardIncomeTotals(params);
    return result.data.total_income.formatted_amount;
  };

  const handleDateRangeChange = async (dateRange: DateRangeOptions) => {
    setSelectedDateRange(dateRange);
    const newTotalIncome = await fetchIncomeTotals(dateRange);
    setTotalIncome(newTotalIncome);
    hideMenu();
  };

  useEffect(() => {
    const fetchInitialTotalIncome = async () => {
      const initialTotalIncome = await fetchIncomeTotals(selectedDateRange);
      setTotalIncome(initialTotalIncome);
    };
    fetchInitialTotalIncome();
  }, [selectedDateRange]);

  return (
    <IncomeListingProvider>
      <View style={isMobile ? mb25 : styles.container}>
        <DashboardCard
          title={isMobile ? 'Overview' : 'Income'}
          viewAllLink={isMobile ? null : screenName.Income}
          titleViewAll="VIEW MORE"
          style={flex1}>
          <View style={styles.select}>
            <H3TtmSemiBoldBlack style={{ color: colors.purple, marginRight: 10 }}>
              {DataRangeOptionsMapping[selectedDateRange]?.label}
            </H3TtmSemiBoldBlack>
            <TouchableOpacity onPress={showMenu}>
              <View style={styles.arrow}>
                <Menu
                  visible={menuVisible}
                  onDismiss={hideMenu}
                  anchor={<DownArrow height={15} width={15} color={colors.purple} />}>
                  {filteredDateRanges.map((dateRangeOption: DateRangeOptions) => (
                    <Menu.Item
                      key={dateRangeOption}
                      title={DataRangeOptionsMapping[dateRangeOption]?.label}
                      onPress={() => handleDateRangeChange(dateRangeOption)}
                    />
                  ))}
                </Menu>
              </View>
            </TouchableOpacity>
          </View>
          <View style={styles.row}>
            <H2TtmSemiBoldBlack>{totalIncome}</H2TtmSemiBoldBlack>
          </View>
        </DashboardCard>
      </View>
    </IncomeListingProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    paddingVertical: 30,
  },
  arrow: {
    height: 15,
    width: 15,
  },
});
