import React from 'react';
import { StyleSheet, View } from 'react-native';

import { AvailableDoctorModelWithQueue } from '~/api/models/appointments/models/AvailableDoctorModel';
import { colors } from '~/utils/colors';
import { getQueueInfo } from '~/utils/consultationUtils';

interface Props {
  doctor: AvailableDoctorModelWithQueue;
  backgroundColor?: string;
}

export const DoctorOnDemandIndicator: React.FC<Props> = ({ doctor, backgroundColor }) => {
  const queueInfoRes = !!doctor?.queue && getQueueInfo(doctor.queue);
  return queueInfoRes ? (
    <View style={[styles.queueIndicatorContainer, { backgroundColor: backgroundColor ?? colors.white }]}>
      <View
        style={[styles.queueIndicator, queueInfoRes.noQueue ? styles.queueIndicatorGreen : styles.queueIndicatorYellow]}
      />
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  queueIndicatorContainer: {
    position: 'absolute',
    bottom: -5,
    right: -5,
    width: 20,
    height: 20,
    borderRadius: 10,
  },
  queueIndicator: {
    position: 'absolute',
    borderRadius: 14,
    width: 6,
    height: 6,
    right: 8,
    bottom: 8,
  },
  queueIndicatorYellow: {
    backgroundColor: colors.warning,
  },
  queueIndicatorGreen: {
    backgroundColor: colors.success,
  },
});
