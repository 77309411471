import { Route } from '@react-navigation/native';
import React, { useRef, useState, useEffect } from 'react';
import { View } from 'react-native';

import { AutoSaveState } from './AutoSaveState';
import { useConsultationDetails } from '~/api/hooks/consultations/ConsultationDetails';
import { apiSubmitConsultation } from '~/api/services/consultations';
import { InprogressAlert, SuccessAlert } from '~/common/commonMethods';
import { flex1, mh10, pb15 } from '~/common/commonStyles';
import {
  ConsultationReportComponent,
  ConsultationReportComponentRef,
} from '~/components/common/consultations/ConsultationReportComponent';
import { Button } from '~/components/commonButton';
import { SmallNsRegularBlack } from '~/components/commonText';
import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { ConsultationDetailsComponent } from '~/components/doctor/patients/consultation/ConsultationDetailsComponent';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { Grid, Container, Row, Column } from '~/theme/components/grid';
import { isDoctorVersion, isPatientVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { errorMessages } from '~/utils/labels/errorMessages';
import { patientManagementLabels } from '~/utils/labels/patientManagement';
import { screenName } from '~/utils/screenName';
import { useSelector } from 'react-redux';
import { consultationSavingSelector } from '~/redux/reducers/consultationDetailsReducer';

interface Props {
  route?: Route<string, { id: string; diagnosisId: string; consultationId: string }>;
}

export const ConsultationDetailPage: React.FC<Props> = ({ route }) => {
  const consultationId = +route.params.consultationId;
  const consultationReportRef = useRef<ConsultationReportComponentRef>();
  const { isMobile } = useBreakpoints();
  const { consultation, isEditing, getConsultation } = useConsultationDetails({ consultationId });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const consultationSaving = useSelector(consultationSavingSelector);

  const validate = async () => {
    const valid = await consultationReportRef.current.verify();
    if (!valid) throw new Error('Some fields are not valid, can you please check them out to be able to save');
  };

  useEffect(() => {
    if (!consultationSaving && saving) {
      handleSubmit();
    }
  }, [consultationSaving]);

  const handleSubmit = async () => {
    if (consultationSaving) {
      setSaving(true);
      return;
    }
    try {
      setSaving(true);
      setIsSubmitted(true);
      await validate();

      const includeSignature = consultationReportRef.current.getIncludeSignature();
      if (!includeSignature) {
        throw new Error(errorMessages.signatureRequiredModalMessage);
      }

      InprogressAlert(['Saving your latest changes'], { title: 'Submitting consultation', block: true });
      // await consultationReportRef.current.submit();

      await apiSubmitConsultation({
        id: consultationId,
        req: { include_signature: consultationReportRef.current.getIncludeSignature() },
      });

      await getConsultation();

      SuccessAlert(['Consultation successfully submitted']);
    } catch (e) {
      ErrorAlert(e);
    }
    setSaving(false);
  };

  const footer = isEditing ? (
    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
      {!isMobile ? (
        <View style={{ display: 'flex', alignItems: 'center', paddingHorizontal: 10, maxWidth: 350, flex: 1 }}>
          <SmallNsRegularBlack>{patientManagementLabels.consultationEnsureDetailsCorrect}</SmallNsRegularBlack>
        </View>
      ) : null}
      <AutoSaveState style={mh10} />
      <View>
        <Button label="Submit" funCallback={handleSubmit} style={{ minWidth: 180 }} />
      </View>
    </View>
  ) : null;

  return (
    <PatientsLayout
      title={isDoctorVersion() ? labels.patients : undefined}
      footer={footer}
      back={{
        to: !isPatientVersion()
          ? {
              screen: screenName.PatientDiagnosis,
              params: { id: route.params.id, diagnosisId: route.params.diagnosisId },
            }
          : {
              screen: screenName.Diagnoses,
              params: { diagnosisId: route.params.diagnosisId },
            },
        title: labels.back.toUpperCase(),
      }}
      flexContent={false}>
      <Grid grid={{ noOuterGutter: true }}>
        {consultation ? (
          <Container alignSelf="flex-start" style={flex1} fluid>
            <Row>
              <Column span={{ xs: 12, lg: 5 }} style={pb15}>
                <ConsultationDetailsComponent consultation={consultation} />
              </Column>
              <Column span={{ xs: 12, lg: 7 }} style={pb15}>
                <Row>
                  <Column>
                    <ConsultationReportComponent
                      ref={consultationReportRef}
                      consultation={consultation}
                      isSubmitted={isSubmitted}
                    />
                  </Column>
                </Row>
              </Column>
            </Row>
          </Container>
        ) : null}
      </Grid>
    </PatientsLayout>
  );
};
