import { useEffect, useState } from 'react';

import { apiWriteAccess } from '~/api/services/authorisation';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { isDoctorVersion, isPatientVersion } from '~/utils/buildConfig';

export const useHasWriteAccess = (accountId: number) => {
  const [hasWriteAccess, setHasWriteAccess] = useState(isPatientVersion());

  const checkWriteAccess = async () => {
    if (isDoctorVersion()) {
      await apiWriteAccess({ account_id: accountId })
        .then((response) => {
          setHasWriteAccess(response.data.has_write_access);
        })
        .catch(ErrorAlert);
    } else if (isPatientVersion()) {
      setHasWriteAccess(true);
    }
  };

  useEffect(() => {
    if (!accountId) return;

    checkWriteAccess();
  }, [accountId]);

  return { hasWriteAccess };
};
