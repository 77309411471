import { DocumentPickerAsset } from 'expo-document-picker';
import React from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { ReferralMediaTypeModel } from '~/api/models/referrals/models/ReferralMediaTypeModel';
import { mt15 } from '~/common/commonStyles';
import { UploadDocumentComponent } from '~/components/buttons/UploadDocumentComponent';
import { H4TtmRegularSecondaryBlack } from '~/components/commonText';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { DocumentTypes } from '~/constants/documentUploadsConstants';
import { useReferralDataContext } from '~/providers/consultation/ReferralDataContext';

interface Props {
  mediaTypeList: ReferralMediaTypeModel[];
  referralMediaFiles: (DocumentPickerAsset | MediaModel)[];
  addFile: (file: DocumentPickerAsset) => Promise<void>;
  removeFile: (file: DocumentPickerAsset | MediaModel) => Promise<void>;
  horizontalUpload?: boolean;
}

export const SelectUploadForm: React.FC<Props> = ({
  mediaTypeList,
  referralMediaFiles,
  addFile,
  removeFile,
  horizontalUpload,
}) => {
  const {
    form: { control, rules, watch },
  } = useReferralDataContext();
  return (
    <View nativeID="formWithDropdown">
      <H4TtmRegularSecondaryBlack>
        Select a form from the dropdown menu and upload the completed form.
      </H4TtmRegularSecondaryBlack>

      <Controller
        name="referral_media_type"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Dropdown
            error={!!error}
            errorMessage={error?.message}
            label="Select form"
            list={mediaTypeList.map((item) => ({ label: item.title, value: item.id }))}
            value={value}
            setValue={onChange}
          />
        )}
        rules={rules.referral_media_type}
      />

      <View style={mt15}>
        <UploadDocumentComponent
          uploadedFiles={referralMediaFiles}
          add={addFile}
          remove={removeFile}
          allowedFormats={[DocumentTypes.pdf]}
          horizontal={horizontalUpload}
          disabled={!watch('referral_media_type')}
        />
      </View>
    </View>
  );
};
