import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { commonTextStyles, mobileMenuStyles } from '../doctor/notifications/DotMenuCommonStyles';
import { NotificationOptionInterface } from '../doctor/notifications/models/NotificationOptionInterface';

import { NotificationModel } from '~/api/models/notifications/models/NotificationModel';
import { alignItemsCenter, flexRow } from '~/common/commonStyles';
import { colors } from '~/utils/colors';

interface Props extends BaseModalProps {
  itemTitleList: NotificationOptionInterface[];
  notification: NotificationModel;
}

export const NotificationDotMenu: React.FC<Props> = ({ itemTitleList, notification, onHide }) => {
  return (
    <ModalContainer onHide={onHide} hideOnBackground animationType="slide">
      <View>
        {itemTitleList.map((item, i) => {
          const disabled = item.disabled && item.disabled(notification);
          return (
            <View key={i}>
              <TouchableOpacity
                disabled={disabled}
                style={
                  i === itemTitleList.length - 1 ? mobileMenuStyles.lastItemStyle : mobileMenuStyles.itemsListStyle
                }
                onPress={() => {
                  item.action(notification);
                  onHide();
                }}>
                <View style={[flexRow, alignItemsCenter]}>
                  {item.icon && (
                    <View style={{ marginRight: 15 }}>
                      {typeof item.icon === 'function'
                        ? item.icon(disabled ? colors.lightGrey : colors.purple)
                        : item.icon}
                    </View>
                  )}
                  <View>
                    <Text
                      style={[
                        mobileMenuStyles.titleStyle,
                        commonTextStyles.titleWeight,
                        disabled ? { color: colors.lightGrey } : null,
                      ]}>
                      {item.title}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          );
        })}
      </View>
    </ModalContainer>
  );
};
