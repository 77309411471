import React, { useEffect, useMemo, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { mb5 } from '~/common/commonStyles';
import { GroupedBarChart } from '~/components/chart/GroupedBarChart';
import { ExtraSmallNsRegularBlack, SmallNsSemiBoldBlack } from '~/components/commonText';
import { useIncomeListingContext } from '~/providers/income/IncomeListingContext';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';

const LABEL_WIDTH = 90;
export const IncomeBarChart: React.FC = () => {
  const { graphData } = useIncomeListingContext();
  const labelsWidth = useMemo(() => graphData?.labels.length * LABEL_WIDTH, [graphData]);

  const [containerWidth, setContainerWidth] = useState(labelsWidth);

  const chartWidth = useMemo(() => Math.max(labelsWidth, containerWidth), [containerWidth, labelsWidth]);

  const [displayChart, setDisplayChart] = useState(false);

  const [info, setInfo] = useState<{ index: number; values: number[] }>();

  useEffect(() => {
    setInfo(null);
  }, [graphData]);
  return (
    <View
      onLayout={(e) => {
        setContainerWidth(e.nativeEvent.layout.width);
        setDisplayChart(true);
      }}>
      {displayChart ? (
        <View style={{ position: 'relative' }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'flex-end',
              paddingVertical: 20,
            }}>
            <Grid>
              <Container>
                <Row>
                  {graphData.legend.map((legendItem: string, index: number) => (
                    <Column span={{ xs: 6, md: 'auto' }} style={mb5} key={legendItem}>
                      <View style={{ display: 'flex', flexDirection: 'row', marginLeft: 16 }}>
                        <View
                          style={{
                            width: 16,
                            height: 16,
                            marginRight: 8,
                            borderRadius: 8,
                            backgroundColor: graphData.barColors[index],
                          }}
                        />
                        <ExtraSmallNsRegularBlack>{legendItem}</ExtraSmallNsRegularBlack>
                      </View>
                    </Column>
                  ))}
                </Row>
              </Container>
            </Grid>
          </View>
          <ScrollView horizontal>
            <GroupedBarChart
              nativeID="incomeChart"
              width={chartWidth}
              height={520}
              data={graphData}
              onGroupClick={(index: number, values: number[]) => {
                console.log('Click', graphData.labels[index], values);
                setInfo({ index, values });
              }}
              yAxisLabel="€"
              chartConfig={{
                stackedBar: true,
                backgroundColor: colors.white,
                backgroundGradientFrom: colors.white,
                backgroundGradientTo: colors.white,
                color: (_) => colors.black,
                propsForLabels: {
                  strokeWidth: 0.3,
                  stroke: 'white',
                  strokeOpacity: 0.5,
                },
              }}
            />
          </ScrollView>
          {info ? (
            <View style={[styles.infoPopup, styles.shadow]} pointerEvents="none">
              <View style={{ backgroundColor: colors.lightPurple, padding: 10, display: 'flex', alignItems: 'center' }}>
                <SmallNsSemiBoldBlack>{graphData.labels[info.index]}</SmallNsSemiBoldBlack>
              </View>
              <View style={{ padding: 10 }}>
                {graphData.barColors.map((color: string, index: number) => (
                  <View
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginVertical: 2 }}
                    key={index}>
                    <View
                      style={{
                        width: 8,
                        height: 8,
                        marginRight: 8,
                        borderRadius: 8,
                        backgroundColor: color,
                      }}
                    />
                    <ExtraSmallNsRegularBlack>€ {(info.values[index] ?? 0).toFixed(2)}</ExtraSmallNsRegularBlack>
                  </View>
                ))}
              </View>
            </View>
          ) : null}
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  infoPopup: {
    position: 'absolute',
    minWidth: 90,
    left: 70,
    top: 40,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: colors.white,
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
  },
});
