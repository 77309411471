import React from 'react';

import { SubscriptionPlanListing } from '~/components/subscriptions/SubscriptionPlanListing';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const ProfileSubscriptionPlan: React.FC = () => {
  return (
    <NestedPageInternalLayout backTitle={labels.profile} backLink={{ screen: screenName.ProfileScreen }}>
      <SubscriptionPlanListing />
    </NestedPageInternalLayout>
  );
};
