import { IBreakpoints } from '~/constants/grid/types';
import isNullOrUndefined from '~/utils/types/isNullOrUndefined';

export default function getSpan(breakpoints: IBreakpoints, spans: IBreakpoints, width: number) {
  let breakpointName;
  let breakpointWidth = 0;

  const keys = Object.keys(spans);
  keys.forEach((name) => {
    const value = breakpoints?.[name];

    if (!isNullOrUndefined(value) && width >= value && value >= breakpointWidth) {
      breakpointName = name;
      breakpointWidth = value;
    }
  });

  return spans[breakpointName] || undefined;
}
