import React from 'react';
import { StyleSheet, View } from 'react-native';

import { H5TtmSemiBoldBlack, SmallNsRegularPurpleGrey, H4TtmSemiBoldBlack } from '../commonText';
import { StatusBadge } from '../misc/StatusBadge';
import { ProfileImageComponent } from '../profile/ProfileImageComponent';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { mr10, mr5 } from '~/common/commonStyles';
import { colors } from '~/utils/colors';
import { parseDateTime, TIME_FORMAT } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';

interface Props {
  item: ConsultationModel;
}

export const DiagnosisConsultationDoctorCard: React.FC<Props> = ({ item }) => {
  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <View style={mr10}>
          <ProfileImageComponent account={item.doctor} size={50} />
        </View>
        <View style={styles.content}>
          <View>
            <View style={styles.doctorDetails}>
              <H4TtmSemiBoldBlack>{`${item.doctor?.first_name || ''} ${
                item.doctor?.last_name || ''
              }`}</H4TtmSemiBoldBlack>
            </View>
            <View style={styles.date}>
              <H5TtmSemiBoldBlack style={{ color: colors.info }}>
                {parseDateTime(item.start_at, { outputFormat: 'DD MMM YY, HH:mm' })} {item.end_at ? ' - ' : null}
                {parseDateTime(item.end_at, { outputFormat: TIME_FORMAT })}
              </H5TtmSemiBoldBlack>
            </View>
            {item.reason ? (
              <View style={styles.reason}>
                <H5TtmSemiBoldBlack style={mr5}>Reason</H5TtmSemiBoldBlack>
                <H5TtmSemiBoldBlack>{item.reason}</H5TtmSemiBoldBlack>
              </View>
            ) : null}
          </View>
        </View>
        <View>
          <StatusBadge
            label={!item.submitted_at ? labels.draft : labels.done}
            color={!item.submitted_at ? colors.warning : colors.success}
            textStyle={{ textTransform: 'capitalize' }}
          />
        </View>
      </View>
      <View style={styles.bottomContainer}>
        <SmallNsRegularPurpleGrey>Ref No: </SmallNsRegularPurpleGrey>
        <SmallNsRegularPurpleGrey>{`${item.reference || ''}`}</SmallNsRegularPurpleGrey>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  topContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  bottomContainer: {
    flex: 1,
    marginTop: 5,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  fullWidth: {
    width: '100%',
  },
  content: {
    flexDirection: 'column',
    flex: 1,
  },
  doctorDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  date: {
    display: 'flex',
    flexDirection: 'row',
  },
  reason: {
    marginTop: 5,
    flexDirection: 'row',
    flex: 1,
  },
});
