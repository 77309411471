import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';

import { parseMoment } from '../dateAndTime';

export const useWaitingRoomCountdown = (timeTo: string) => {
  const timerRef = useRef(null);

  const [time, setTime] = useState('--:--:--');
  const [isOver, setIsOver] = useState(false);

  const momentTimeTo = useMemo(() => {
    if (!timeTo) return;
    return parseMoment(timeTo);
  }, [timeTo]);

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };
  const calculateTime = () => {
    const diff = momentTimeTo.diff(moment(), 'milliseconds');
    setIsOver(diff < 0);
    const formatted = moment.utc(Math.abs(diff)).format('HH:mm:ss');
    setTime(formatted);
  };

  useEffect(() => {
    if (!momentTimeTo) return;
    stopTimer();

    timerRef.current = setInterval(calculateTime, 1000);
    calculateTime();
  }, [momentTimeTo]);

  return { time, isOver, stopTimer };
};
