export enum DateRangeOptions {
  TODAY,
  TODATE,
  THISWEEK,
  LASTWEEK,
  THISMONTH,
  LASTMONTH,
  THISYEAR,
  LASTYEAR,
  CUSTOM,
}
