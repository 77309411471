import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { DiagnosisAndTreatmentDetails } from './DiagnosisAndTreatmentDetails';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { InsuranceDetailsResponse } from '~/api/models/insurance/responses/InsuranceDetailsResponse';
import { flex1, mb10 } from '~/common/commonStyles';
import { PatientListedDetails } from '~/components/common/patient/PatientListedDetails';
import { LabelWithTextRow } from '~/components/commonComponents';
import { H6NsSemiBoldBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { Splitter } from '~/components/misc/Splitter';
import { useInsuranceClaimFormContext } from '~/providers/insurance/InsuranceClaimFormContext';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { insuranceLabels } from '~/utils/labels/insurance';
import { getAccountName, getAccountPhone } from '~/utils/personalDetailsUtils';

interface Props {
  insuranceDetails: InsuranceDetailsResponse;
}

export const InsurancePolicyDetails: React.FC<Props> = ({ insuranceDetails }) => {
  const { isMobile } = useBreakpoints();
  const { userDetails } = useUserDetails({ allowStale: true });
  const { consultation } = useInsuranceClaimFormContext();

  const policyHolder = useMemo(() => {
    if (!insuranceDetails) return {} as AccountModel;
    return insuranceDetails?.is_policy_holder ? userDetails?.account : insuranceDetails.insurance_policy_holder;
  }, [userDetails, insuranceDetails]);

  const policyHolderEmail = useMemo(() => {
    return 'email_address' in policyHolder ? policyHolder?.email_address : policyHolder?.email;
  }, [policyHolder]);

  const policyHolderMobile = useMemo(() => {
    if (!policyHolder) return '';
    return getAccountPhone(policyHolder, 'contact_number' in policyHolder ? 'contact_number' : 'mobile_number');
  }, [policyHolder]);

  return (
    <View style={[styles.detailsContainer, isMobile ? { backgroundColor: colors.white, padding: 0 } : null]}>
      {!insuranceDetails || !consultation ? (
        <Row style={flex1}>
          <Column alignSelf="center">
            <LoadingActivityIndicator />
          </Column>
        </Row>
      ) : (
        <View>
          <DiagnosisAndTreatmentDetails />
          <Splitter secondaryColor={!isMobile} />
          <Row>
            <Column span={{ xs: 12, lg: undefined }} style={mb10}>
              <H6NsSemiBoldBlack style={mb10}>Patient details</H6NsSemiBoldBlack>
              <PatientListedDetails account={consultation.patient} row />
            </Column>
          </Row>
          <Splitter secondaryColor={!isMobile} />
          <Row>
            <Column>
              <H6NsSemiBoldBlack style={mb10}>Insurance policy details</H6NsSemiBoldBlack>

              <LabelWithTextRow
                fontWeight="bold"
                color={colors.black}
                label={insuranceLabels.insuranceCompanyName}
                value={insuranceDetails.insurance_company.name}
              />
              <LabelWithTextRow label={insuranceLabels.policyNumber} value={insuranceDetails.policy_number} />
              <LabelWithTextRow label={insuranceLabels.groupName} value={insuranceDetails.group_name} />
              <LabelWithTextRow
                label={insuranceLabels.periodFromLabel}
                value={parseDateTime(insuranceDetails.valid_from, { outputFormat: DATE_FORMAT })}
              />

              <LabelWithTextRow
                label={insuranceLabels.periodToLabel}
                value={parseDateTime(insuranceDetails.valid_to, { outputFormat: DATE_FORMAT })}
              />
              <Splitter secondaryColor={!isMobile} />

              <H6NsSemiBoldBlack style={mb10}>Policy holder personal details</H6NsSemiBoldBlack>

              <LabelWithTextRow
                fontWeight="bold"
                color={colors.black}
                label={labels.fullName}
                value={getAccountName(policyHolder)}
              />
              <LabelWithTextRow
                label={labels.patientID}
                value={policyHolder?.national_id_number ?? policyHolder?.passport_number}
              />
              <LabelWithTextRow
                label={labels.dob}
                value={
                  policyHolder?.date_of_birth
                    ? parseDateTime(policyHolder?.date_of_birth, {
                        outputFormat: DATE_FORMAT,
                      })
                    : ''
                }
              />
              <LabelWithTextRow label={labels.phone} value={policyHolderMobile} color={colors.info} />

              <LabelWithTextRow label={labels.email} value={policyHolderEmail} />
            </Column>
          </Row>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  detailsContainer: {
    backgroundColor: colors.lightPurple,
    padding: 20,
    borderRadius: 6,
    minHeight: 400,
  },
});
