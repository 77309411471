import React, { useState } from 'react';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { PatientEditableHealthProfile } from '~/components/doctor/patients/healthProfile/PatientEditableHealthProfile';
import { HealthProfileDataProvider } from '~/providers/healthProfile/HealthProfileDataProviders';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const HealthProfile: React.FC = () => {
  const { userDetails } = useUserDetails();
  const { isDesktop } = useBreakpoints();
  const [editing, setEditing] = useState(false);

  return (
    <HealthProfileDataProvider accountId={userDetails?.account.id} onSaved={() => setEditing(false)}>
      <PatientEditableHealthProfile
        hideBack={isDesktop}
        editing={editing}
        onStartEditing={(accountId) => {
          setEditing(true);
        }}
        onCancel={() => {
          setEditing(false);
        }}
      />
    </HealthProfileDataProvider>
  );
};
