import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { DashboardCard } from '../common/DashboardCard';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiGetConsultationsIndex } from '~/api/services/consultations';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { flex1, mb5, mr15, textCenter } from '~/common/commonStyles';
import { ButtonLink } from '~/components/commonButton';
import { H5TtmSemiBoldInfo, H6NsSemiBoldBlack } from '~/components/commonText';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { LARAVEL_DATE_TIME_SHORT, parseDateTime } from '~/utils/dateAndTime';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { screenName } from '~/utils/screenName';

interface Props {
  style?: StyleProp<ViewStyle>;
}
export const OngoingAppointmentCard: React.FC<Props> = ({ style }): JSX.Element => {
  const [appointment, setAppointment] = useState<ConsultationModel>(undefined);
  const { showDetails } = useShowAppointment();

  const getStarted = async () => {
    try {
      const appointmentRes = await apiGetConsultationsIndex({
        filter: {
          state: [ConsultationStateEnum.Started],
          after: moment().startOf('D').format(LARAVEL_DATE_TIME_SHORT),
        },
        sort: 'start_at',
      });

      if (appointmentRes.data.data.length > 0) {
        setAppointment(appointmentRes.data.data[0]);
      } else {
        setAppointment(undefined);
      }
    } catch {}
  };

  usePageFocus(() => {
    getStarted();
  }, []);

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.PATIENT_APPOINTMENTS_CHANGED, getStarted);
    return removeListener;
  }, []);

  return (
    <Column span={{ xs: 12, md: 6, lg: 'flex' }} style={{ marginTop: 22 }}>
      {appointment ? (
        <DashboardCard
          shadow
          style={[styles.dashboardCard, { backgroundColor: colors.lightWarning, borderColor: colors.lightWarning }]}
          title="Ongoing"
          titleViewAll="JOIN"
          showViewAllPress
          onPress={() => {
            showDetails({ consultation: appointment });
          }}>
          <View style={styles.items}>
            <View style={mr15}>
              <View style={{ justifyContent: 'flex-start' }}>
                <ProfileImageComponent account={appointment.doctor} />
              </View>
            </View>
            <View style={[flex1, { flexDirection: 'column' }]}>
              <View style={{ justifyContent: 'flex-start' }}>
                <H6NsSemiBoldBlack style={mb5}>{getAccountName(appointment?.doctor)}</H6NsSemiBoldBlack>
              </View>
              <View style={{ justifyContent: 'flex-start' }}>
                <H5TtmSemiBoldInfo>
                  {parseDateTime(appointment.start_at, { outputFormat: `Do MMMM [at] HH:mm` })}
                </H5TtmSemiBoldInfo>
              </View>
            </View>
          </View>
        </DashboardCard>
      ) : (
        <DashboardCard shadow title="How can we help?">
          <View style={[flex1, { display: 'flex', justifyContent: 'flex-end', paddingTop: 15 }]}>
            <ButtonLink
              label="Book an appointment"
              screenName={screenName.AppointmentsBooking}
              style={[textCenter, { maxWidth: 260, paddingVertical: 10 }]}
            />
          </View>
        </DashboardCard>
      )}
    </Column>
  );
};

const styles = StyleSheet.create({
  dashboardCard: {
    width: '100%',
    flex: 1,
  },
  items: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
    marginTop: 15,
  },
});
