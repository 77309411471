import Constants from 'expo-constants';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import PushNotifications from './PushNotifications';
import { DigimedNotificationEventPayload } from './interfaces/DigimedNotificationEventPayload';

import * as ExpoPusherBeams from '~/../modules/expo-pusher-beams';
import { apiGetBeamsAuth } from '~/api/services/pusher';
import { userDetailsSelector } from '~/redux/reducers/userDetailsReducer';
import { isWeb } from '~/utils/buildConfig';

const pusherInstanceId: string = Constants.expoConfig.extra.pusher.instanceId;

export function usePushNotifications() {
  const userDetails = useSelector(userDetailsSelector);
  const initialised = useRef<boolean>(false);
  const currentUser = useRef<number>(null);
  const [userId, setUserId] = useState<number>();

  const init = async () => {
    if (!initialised.current) {
      try {
        initialised.current = true;
        await ExpoPusherBeams.setInstanceId(pusherInstanceId, {
          serviceWorkerFileName: 'sw',
          webBroadcastChannelName: 'expo-pusher-beams',
        });
      } catch (e) {
        initialised.current = false;
        console.error('Error initialising the push notifications', e);
        return;
      }

      try {
        await ExpoPusherBeams.addNotificationListener((payload: DigimedNotificationEventPayload) => {
          PushNotifications.emit(payload);
        });
      } catch {}
    }

    setUser();
  };

  const setUser = async () => {
    if (!initialised.current || !userDetails?.id) return;
    try {
      // console.log('subscribe debug-threls');
      // await ExpoPusherBeams.subscribe('debug-threls')
      //   .then(() => {
      //     console.log('Subscribed');
      //   })
      //   .catch((reason) => {
      //     console.error('Error subscribing', reason);
      //   });

      currentUser.current = userDetails.id;
      const response = await apiGetBeamsAuth({ userId: userDetails.id });
      if (userId !== userDetails.id) {
        await ExpoPusherBeams.clearAllState();
      }
      await ExpoPusherBeams.setUserId(`App.User.${userDetails.id}`, response.data.token);
      setUserId(userDetails.id);
      console.log(`Beams App.User.${userDetails.id}`);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isWeb()) return; // TODO: Fix for web
    try {
      init();
    } catch (e) {
      console.error(e);
    }
  }, [userDetails?.id]);

  return { init };
}
