import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';

import { PatientEditableDetailsFormInterface } from './PatientEditableDetailsFormInterface';
import { useCountryListing, CountryListEnum } from '../../../../api/hooks/referenceData/CountryListing';
import { flex1, mb10, mb20, mv15, ph5, pl0, pr0 } from '../../../../common/commonStyles';
import { IdentificationDocumentTypes, IdentificationDocumentsEnum } from '../../../../constants/documents';
import { genderList } from '../../../../services/config';
import {
  alphabetCharactersValidation,
  emailValidation,
  maxAgeValidation,
  maxDateValidation,
  maxLengthValidation,
  minLengthValidation,
  numberValidation,
  requireIfValidation,
  requiredValidation,
  validationSchema,
} from '../../../../services/validationConfig';
import { separator } from '../../../../styles/commonStyling';
import { Column, Container, Grid, Row } from '../../../../theme/components/grid';
import { labels } from '../../../../utils/labels';
import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '../../../commonText';
import FloatingInput from '../../../inputs/FloatingInput';
import { PatientsLayout } from '../PatientsLayout';

import { useAccountDetails } from '~/api/hooks/accounts/AccountDetails';
import { MaltaPhoneCode, MaltaId } from '~/api/hooks/referenceData/constants/CountryConstants';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { SendEmailNotifCheckBox } from '~/components/buttons/SendEmailNotifCheckBox';
import DatePicker from '~/components/inputs/dateTime/DatePicker';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { DigimedMilestones, digimedMilestoneEnabled } from '~/utils/milestoneUtil';
import { screenName } from '~/utils/screenName';

interface Props {
  header?: React.ReactNode;
  waiting?: boolean;
  patientId?: number;
  onCancel: () => void;
  onSubmit: (formData: PatientEditableDetailsFormInterface) => void;
}

//Check if patient has an account
const hasPatientApp = digimedMilestoneEnabled(DigimedMilestones.DoctorPatientApps);

export const PatientEditableDetails: React.FC<Props> = ({ header, onSubmit, onCancel, waiting, patientId }) => {
  const countryPhoneCodes = useCountryListing(CountryListEnum.PHONE_CODE);
  const countryListing = useCountryListing(CountryListEnum.COUNTRY_NAME);

  const { account, address, getAccountDetails } = useAccountDetails({ id: patientId });
  const { isMobile } = useBreakpoints();

  const showSendEmail = useMemo(() => hasPatientApp && !patientId, [patientId, hasPatientApp]);

  useEffect(() => {
    getAccountDetails();
  }, [patientId]);

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, defaultValues },
  } = useForm<PatientEditableDetailsFormInterface>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      country_code: MaltaPhoneCode,
      mobile_number: '',
      firstName: '',
      lastName: '',
      document_type: undefined,
      document_number: '',
      dob: '',
      gender: undefined,
      country: MaltaId,
      postcode: '',
      address: '',
    },
  });

  useEffect(() => {
    if (account) {
      const documentType = account?.national_id_number
        ? IdentificationDocumentsEnum.IDENTITY_CARD
        : account?.passport_number
        ? IdentificationDocumentsEnum.PASSPORT
        : undefined;
      reset({
        ...defaultValues,
        email: account?.email,
        country_code: account?.country_code,
        mobile_number: account?.mobile_number,
        firstName: account?.first_name,
        lastName: account?.last_name,
        document_type: documentType,
        document_number: account?.national_id_number || account?.passport_number,
        dob: account?.date_of_birth,
        gender: account?.gender,
      });
    }
  }, [account]);

  useEffect(() => {
    if (address) {
      reset({
        ...defaultValues,
        country: address?.country.id,
        postcode: address?.post_code,
        address: address?.line_1,
        addressId: address?.id,
      });
    }
  }, [address]);

  usePageFocus(() => {
    reset();
  });

  return (
    <PatientsLayout
      hideHeaderRight
      header={header}
      flexContent={false}
      back={{ to: { screen: screenName.PatientDetails } }}
      footer={
        <View style={isMobile ? null : styles.webFooter}>
          <>
            {showSendEmail && !isMobile ? (
              <Controller
                name="send_email_to_patient"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <View>
                    <SendEmailNotifCheckBox checked={value} onChange={onChange} />
                  </View>
                )}
              />
            ) : null}
            <CancelContinueFooter
              waiting={waiting}
              onCancel={onCancel}
              onConfirm={handleSubmit(onSubmit)}
              confirmTitle={patientId ? labels.update : labels.create}
            />
          </>
        </View>
      }>
      <View nativeID="formWithDropdown">
        <H3TtmSemiBoldBlack style={mb10}>What are their personal details?</H3TtmSemiBoldBlack>
        <H6NsRegularBlack style={mb20}>Please provide the details below</H6NsRegularBlack>

        <Grid
          grid={{
            gutters: 16,
            noOuterGutter: true,
          }}>
          <Container alignSelf="flex-start" maxContainerWidths={760}>
            <Row>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { value, onChange, onBlur }, formState: { isSubmitted } }) => (
                    <FloatingInput
                      value={value}
                      label={labels.emailAddress}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      error={isSubmitted && !!errors?.email}
                      errorMessage={isSubmitted && errors?.email?.message}
                    />
                  )}
                  defaultValue=""
                  rules={{
                    maxLength: maxLengthValidation(validationSchema.email.maxLength),
                    validate: { emailValidation },
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 6 }} style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={[flex1, ph5, pl0, { maxWidth: '35%' }]}>
                  <Controller
                    name="country_code"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Dropdown
                        list={countryPhoneCodes.map((item) => ({ label: item.name, value: item.id }))}
                        setValue={onChange}
                        value={value}
                        errorMessage={errors.country_code?.message}
                        error={!!errors.country_code}
                        searchable
                      />
                    )}
                    rules={{
                      required: requiredValidation('Country code'),
                    }}
                  />
                </View>
                <View style={[flex1, ph5, pr0]}>
                  <Controller
                    name="mobile_number"
                    control={control}
                    render={({ field: { value, onChange, onBlur }, formState: { isSubmitted } }) => (
                      <FloatingInput
                        showMandatory
                        maxLength={validationSchema.phoneNumber.maxLength}
                        error={isSubmitted && !!errors.mobile_number}
                        errorMessage={isSubmitted && errors.mobile_number?.message}
                        value={value}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        keyboardType="numeric"
                        label={labels.phoneNumber}
                      />
                    )}
                    rules={{
                      validate: { numberValidation },
                      required: requiredValidation(labels.phoneNumber),
                      minLength: minLengthValidation(validationSchema.phoneNumber.minLength),
                    }}
                  />
                </View>
              </Column>
            </Row>
            <Row>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field: { value, onChange, onBlur }, formState: { isSubmitted } }) => (
                    <FloatingInput
                      maxLength={validationSchema.name.maxLength}
                      label={labels.legalFirstName}
                      showMandatory
                      value={value}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      error={isSubmitted && !!errors.firstName}
                      errorMessage={isSubmitted && errors.firstName?.message}
                    />
                  )}
                  rules={{
                    validate: { alphabetCharactersValidation },
                    required: requiredValidation(labels.legalFirstName),
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field: { value, onChange, onBlur }, formState: { isSubmitted } }) => (
                    <FloatingInput
                      maxLength={validationSchema.lastName.maxLength}
                      label={labels.legalLastName}
                      showMandatory
                      value={value}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      error={isSubmitted && !!errors.lastName}
                      errorMessage={isSubmitted && errors.lastName?.message}
                    />
                  )}
                  rules={{
                    validate: { alphabetCharactersValidation },
                    required: requiredValidation(labels.legalLastName),
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="document_type"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Dropdown
                      list={IdentificationDocumentTypes}
                      setValue={onChange}
                      value={value}
                      error={!!errors.document_type}
                      errorMessage={errors.document_type?.message as string}
                      label={labels.documentType}
                      showMandatory
                    />
                  )}
                  rules={{
                    required: requiredValidation(labels.documentType),
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="document_number"
                  control={control}
                  render={({ field: { value, onChange, onBlur }, formState: { isSubmitted } }) => (
                    <FloatingInput
                      showMandatory
                      maxLength={validationSchema.passportOrIdCard.maxLength}
                      error={isSubmitted && !!errors['document_number']}
                      errorMessage={isSubmitted && errors['document_number']?.message}
                      value={value}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      label={labels.idCardOrPassport}
                    />
                  )}
                  rules={{
                    minLength: minLengthValidation(validationSchema.passportOrIdCard.minLength),
                    required: requiredValidation(labels.idCardOrPassport),
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="dob"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                    <DatePicker
                      showMandatory
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      onChangeValue={onChange}
                      value={value}
                      label={labels.dateOfBirthPlaceholder}
                      minDate={moment().subtract(120, 'years').toDate()}
                      maxDate={new Date()}
                    />
                  )}
                  rules={{
                    required: requiredValidation('Date of birth'),
                    validate: {
                      maxAge: maxAgeValidation(validationSchema.age.max, 'Patient'),
                      maxDateValidation: maxDateValidation(new Date(), 'Date of birth must be before today'),
                    },
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Dropdown
                      list={genderList.map((item) => ({ label: item.name, value: item.id }))}
                      setValue={onChange}
                      value={value}
                      errorMessage={error?.message as string}
                      error={!!error}
                      label={labels.gender}
                    />
                  )}
                />
              </Column>
            </Row>
          </Container>
          <Container alignSelf="flex-start">
            <View style={[separator, mv15]} />
            <Row>
              <Column>
                <H3TtmSemiBoldBlack style={mb10}>What is their home address?</H3TtmSemiBoldBlack>
              </Column>
            </Row>
            <Row>
              <Column span={{ xs: 12, md: 4 }}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Dropdown
                      list={countryListing.map((item) => ({ label: item.name, value: item.id }))}
                      setValue={onChange}
                      value={value}
                      error={!!errors.country}
                      errorMessage={errors.country?.message}
                      label={labels.country}
                      searchable
                    />
                  )}
                  rules={{
                    validate: {
                      requiredIf: requireIfValidation(labels.country, ['postcode', 'address'], getValues),
                    },
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 4 }}>
                <Controller
                  name="postcode"
                  control={control}
                  render={({ field: { value, onChange, onBlur }, formState: { isSubmitted } }) => (
                    <FloatingInput
                      value={value}
                      label="Postcode"
                      maxLength={validationSchema.postCode.maxLength}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      error={isSubmitted && !!errors?.postcode}
                      errorMessage={isSubmitted && errors?.postcode?.message}
                    />
                  )}
                  rules={{
                    validate: {
                      requiredIf: requireIfValidation(labels.postCode, ['address'], getValues),
                    },
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 4 }}>
                <Controller
                  name="address"
                  control={control}
                  render={({ field: { value, onChange, onBlur }, formState: { isSubmitted } }) => (
                    <FloatingInput
                      maxLength={validationSchema.address.maxLength}
                      error={isSubmitted && !!errors.address}
                      errorMessage={isSubmitted && errors.address?.message}
                      value={value}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      label={labels.address}
                    />
                  )}
                  rules={{
                    validate: {
                      requiredIf: requireIfValidation(labels.address, ['postcode'], getValues),
                    },
                  }}
                />
              </Column>
            </Row>
          </Container>
        </Grid>
      </View>
      {showSendEmail && isMobile ? (
        <Controller
          name="send_email_to_patient"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Container style={styles.mobileFooter}>
              <SendEmailNotifCheckBox checked={value} onChange={onChange} />
            </Container>
          )}
        />
      ) : null}
    </PatientsLayout>
  );
};
const styles = StyleSheet.create({
  webFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileFooter: {
    paddingVertical: 15,
  },
});
