import React from 'react';

import { PatientsLayout } from '../PatientsLayout';

import { SuccessAlert } from '~/common/commonMethods';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { MobileMenuPageComponent } from '~/components/navigation/MobileMenuPageComponent';
import { PATIENT_HEALTH_PROFILE_PAGES } from '~/constants/patients/HealthProfileMobilePages';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
interface Props {
  patientId: number;
  header: React.ReactNode;
  onComplete: () => void;
}

export const PatientAddNewHealthProfile: React.FC<Props> = ({ patientId, header, onComplete }) => {
  const handleComplete = () => {
    if (onComplete) onComplete();
    SuccessAlert([labels.updateProfile]);
  };

  const footer = <CancelContinueFooter hideCancel onConfirm={handleComplete} confirmTitle="Finish" />;

  return (
    <PatientsLayout
      title="Health Profile"
      header={header}
      footer={footer}
      footerStyling={{ backgroundColor: colors.lightPurple }}
      flexContent={false}
      style={{ backgroundColor: colors.lightPurple }}
      hideGradient
      scrollStyling={{ backgroundColor: colors.lightPurple }}>
      <MobileMenuPageComponent menu={PATIENT_HEALTH_PROFILE_PAGES} params={{ id: patientId, edit: true } as never} />
    </PatientsLayout>
  );
};
