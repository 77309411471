import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React from 'react';

import { ProfileHomeAddress } from '../../pages/profile/common/ProfileHomeAddress';
import { ProfilePaymentDetails } from '../../pages/profile/common/ProfilePaymentDetails';
import { ProfilePersonalInformation } from '../../pages/profile/common/ProfilePersonalInformation';
import { screenName } from '../../utils/screenName';

import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { ProfileTabComponent } from '~/components/doctor/profile/ProfileTabComponent';
import { HealthProfile } from '~/pages/patient/healthProfile/HealthProfile';
import { ProfileSubscriptionPlan } from '~/pages/profile/common/ProfileSubscriptionPlan';
import { isClinicVersion, isNative, isPatientVersion } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';
import { digimedFeatureEnabled, DigimedFeatures } from '~/utils/milestoneUtil';

const Tab = createMaterialTopTabNavigator();

export const ProfileNavigation = () => {
  const { hasFeatureAccess } = useFeatureAccess();
  return (
    <Tab.Navigator
      tabBar={(props) => <ProfileTabComponent tabProps={props} />}
      screenOptions={{ swipeEnabled: isNative() }}
      initialRouteName={screenName.ProfilePersonalInformation}>
      <Tab.Screen
        options={{ title: labels.personalInformation }}
        name={screenName.ProfilePersonalInformation}
        component={ProfilePersonalInformation}
      />
      {!isClinicVersion() ? (
        <Tab.Screen
          options={{ title: labels.homeAddress }}
          name={screenName.ProfileHomeAddress}
          component={ProfileHomeAddress}
        />
      ) : null}

      {isPatientVersion() ? (
        <>
          <Tab.Screen
            options={{ title: labels.healthProfile }}
            name={screenName.HealthProfile}
            component={HealthProfile}
          />
        </>
      ) : null}

      {digimedFeatureEnabled(DigimedFeatures.Payments) && hasFeatureAccess(FeatureAccessEnum.IBAN) ? (
        <>
          {!isClinicVersion() ? (
            <Tab.Screen
              options={{ title: labels.subscriptionPlan }}
              name={screenName.ProfileSubscriptionPlan}
              component={ProfileSubscriptionPlan}
            />
          ) : null}
          <Tab.Screen
            options={{ title: labels.paymentDetails }}
            name={screenName.ProfilePaymentDetails}
            component={ProfilePaymentDetails}
          />
        </>
      ) : null}
    </Tab.Navigator>
  );
};
