import React, { useMemo } from 'react';
import { View } from 'react-native';

import { SmallNsRegularBlack, SmallNsSemiBoldBlack } from '../commonText';

import { SubscriptionIntervalEnum } from '~/api/models/subscriptions/constants/SubscriptionIntervalEnum';
import { SubscriptionPlanMeResponse } from '~/api/models/subscriptions/responses/SubscriptionPlanMeResponse';
import { colors } from '~/utils/colors';
import { parseDateTime, DATE_FORMAT } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';

interface Props {
  plan: SubscriptionPlanMeResponse;
}
export const SubscriptionPaymentCard: React.FC<Props> = ({ plan }) => {
  const nextPaymentDate = useMemo(() => {
    if (!plan?.subscription_type) return '';
    return parseDateTime(plan.next_payment_date, { outputFormat: DATE_FORMAT });
  }, [plan]);

  const cancelAtDate = useMemo(() => {
    if (!plan?.subscription_type) return '';
    return parseDateTime(plan.cancel_at, { outputFormat: DATE_FORMAT });
  }, [plan]);

  const validUntilDate = useMemo(() => {
    if (!plan?.valid_until) return '';
    return parseDateTime(plan.valid_until, { outputFormat: DATE_FORMAT });
  }, [plan]);

  const subscriptionSubTitle = () => {
    if (plan.interval === SubscriptionIntervalEnum.MONTH)
      return <SmallNsRegularBlack>{labels.monthly}</SmallNsRegularBlack>;
    else if (plan.interval === SubscriptionIntervalEnum.YEAR)
      return <SmallNsRegularBlack>{labels.yearly}</SmallNsRegularBlack>;
    return null;
  };

  return (
    <View style={{ backgroundColor: colors.lightPurple2, borderRadius: 6, padding: 16 }}>
      {plan?.is_trial ? (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}>
          <View style={{ marginRight: 16 }}>
            <SmallNsRegularBlack>{labels.trialSubscription}</SmallNsRegularBlack>
          </View>
          <View style={{ paddingLeft: 16, borderLeftColor: colors.lightPurple3, borderLeftWidth: 1 }}>
            <SmallNsRegularBlack style={{ color: colors.purpleGrey }}>{labels.validUntil}</SmallNsRegularBlack>
            <SmallNsRegularBlack>{validUntilDate}</SmallNsRegularBlack>
          </View>
        </View>
      ) : plan.cancel_at ? (
        <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <SmallNsRegularBlack>{labels.planWillExpireOnLabel}</SmallNsRegularBlack>
          <SmallNsSemiBoldBlack>{cancelAtDate}</SmallNsSemiBoldBlack>
        </View>
      ) : (
        <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <SmallNsRegularBlack>{labels.nextPaymentDateLabel}</SmallNsRegularBlack>
          <SmallNsSemiBoldBlack>{nextPaymentDate}</SmallNsSemiBoldBlack>
        </View>
      )}

      {!plan?.is_trial ? (
        <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <SmallNsRegularBlack>{labels.subscriptionTypeLabel}</SmallNsRegularBlack>
          {subscriptionSubTitle()}
        </View>
      ) : null}
    </View>
  );
};
