import React, { useMemo, useState } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { View } from 'react-native';

import { mt10 } from '~/common/commonStyles';
import { ConsultationTypeFilterComponent } from '~/components/common/appointments/appointmentListing/components/ConsultationTypeFilterComponent';
import { Button } from '~/components/commonButton';
import { H5TtmSemiBoldBlack } from '~/components/commonText';
import { DateRangeOptionItems } from '~/components/doctor/income/constants/DateRangeOptions';
import DatePicker from '~/components/inputs/dateTime/DatePicker';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { Splitter } from '~/components/misc/Splitter';
import { ModalContainer } from '~/components/modals/ModalContainer';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { BaseModalProps } from '~/components/modals/interfaces/ModalProps';
import { DateRangeOptions } from '~/components/patient/insurance/constants/DateRangeOptionsEnum';
import { IncomeRequestForm } from '~/providers/income/IncomeListingProvider';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { Column, Grid, Row } from '~/theme/components/grid';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  form: UseFormReturn<IncomeRequestForm, any>;
  submitQuery: () => Promise<void>;
}

export const MobileIncomeFilterModal: React.FC<Props> = ({ form, submitQuery, onHide }) => {
  const { DateRangeOption } = useWatch({ control: form.control });
  const [loading, setLoading] = useState(false);

  const isToDate = useMemo(() => {
    return DateRangeOption === DateRangeOptions.TODATE;
  }, [DateRangeOption]);

  const isToday = useMemo(() => {
    return DateRangeOption === DateRangeOptions.TODAY;
  }, [DateRangeOption]);

  const { closeModalByName } = useModalManager();

  const submit = async () => {
    try {
      setLoading(true);
      await submitQuery();
      onHide();
    } catch {}
    setLoading(false);
  };
  return (
    <ModalContainer
      animationType="slide"
      hideOnBackground
      buttons={[<Button label={labels.apply} disabled={loading} funCallback={submit} />]}
      onHide={() => {
        closeModalByName(ModalName.IncomeFilterModal);
      }}>
      <View>
        <H5TtmSemiBoldBlack>Show Reports for</H5TtmSemiBoldBlack>

        <Grid>
          <Row flex>
            <Column span={{ md: 12 }}>
              <Controller
                name="DateRangeOption"
                control={form.control}
                render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => {
                  return (
                    <Dropdown
                      list={DateRangeOptionItems}
                      value={value}
                      setValue={(value) => {
                        if (onChange) onChange(value);
                      }}
                    />
                  );
                }}
              />
            </Column>
          </Row>
          <Row>
            {isToDate || isToday ? null : (
              <Column span={{ sm: 6, md: 12 }}>
                <Controller
                  name="dateFrom"
                  control={form.control}
                  render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => {
                    return (
                      <DatePicker
                        value={value}
                        placeholder="Start date"
                        showMandatory
                        onChangeValue={(date) => {
                          if (onChange) onChange(date);
                        }}
                      />
                    );
                  }}
                />
              </Column>
            )}
            <Column span={{ sm: 6, md: 12 }}>
              <Controller
                name="dateTo"
                control={form.control}
                render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => {
                  return (
                    <DatePicker
                      placeholder={isToDate || isToday ? 'Date' : 'End date'}
                      showMandatory
                      value={value}
                      onChangeValue={(date) => {
                        if (onChange) onChange(date);
                      }}
                    />
                  );
                }}
              />
            </Column>
          </Row>
        </Grid>
        <Splitter />
        <H5TtmSemiBoldBlack>Filter By Consultation</H5TtmSemiBoldBlack>
        <Controller
          name="ConsultationType"
          control={form.control}
          render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => {
            return <ConsultationTypeFilterComponent setValue={onChange} value={value} withAll />;
          }}
        />
      </View>
    </ModalContainer>
  );
};
