import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { PasscodeInputWeb, PasscodeInputWebRef } from './PasscodeInputWeb';
import { requiredValidation } from '../../services/validationConfig';
import { CancelContinueFooter } from '../buttons/CancelContinueFooter';
import { OutlineButton } from '../commonButton';
import { ErrorAlert } from '../modals/ErrorAlert';

import { ChangePasscodeRequest } from '~/api/models/authorisation/requests/ChangePasscodeRequest';
import { apiChangePasscode } from '~/api/services/authorisation';
import { SuccessAlert } from '~/common/commonMethods';
import { mb20, mt15, mt20 } from '~/common/commonStyles';
import { H2TtmSemiBoldRed, H3TtmSemiBoldBlack } from '~/components/commonText';
import { labels } from '~/utils/labels';

interface Props {}

export const ChangePasscode: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [displayFields, setDisplayFields] = useState<boolean>(false);
  const fieldsRef = useRef(null);
  const currentPasscodeRef = useRef<PasscodeInputWebRef>();
  const newPasscodeRef = useRef<PasscodeInputWebRef>();
  const confirmPasscodeRef = useRef<PasscodeInputWebRef>();
  const changePasscode = async (data) => {
    try {
      setLoading(true);
      await apiChangePasscode(data);
      setDisplayFields(false);
      SuccessAlert(['Passcode changed successfully']);
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const { control, handleSubmit, reset } = useForm<ChangePasscodeRequest>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      passcode: '',
      new_passcode: '',
      new_passcode_confirmation: '',
    },
  });

  const clearAll = () => {
    currentPasscodeRef.current.clearAll();
    newPasscodeRef.current.clearAll();
    confirmPasscodeRef.current.clearAll();

    reset();
    setDisplayFields(false);
  };

  const toggleDisplayFields = () => {
    if (!displayFields) {
      setDisplayFields(true);
      setTimeout(() => {
        if (fieldsRef?.current?.scrollIntoView) {
          fieldsRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      });
    } else {
      setDisplayFields(false);
    }
  };
  return (
    <View style={mb20}>
      <View>
        <OutlineButton
          style={{ width: 180 }}
          funCallback={toggleDisplayFields}
          disabled={displayFields}
          label={labels.changePasscode}
        />
      </View>

      {displayFields && (
        <View ref={fieldsRef}>
          <View>
            <H3TtmSemiBoldBlack style={mt20}>
              {labels.enterYourCurrentPasscode}
              <H2TtmSemiBoldRed>*</H2TtmSemiBoldRed>
            </H3TtmSemiBoldBlack>
            <Controller
              name="passcode"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                <View style={styles.passcodeContainer}>
                  <PasscodeInputWeb
                    ref={currentPasscodeRef}
                    onChange={onChange}
                    onEnter={onChange}
                    value={value}
                    errorMessage={isSubmitted && error?.message}
                    autofocus
                  />
                </View>
              )}
              defaultValue=""
              rules={{
                maxLength: 4,
                required: requiredValidation('Passcode'),
              }}
            />

            <H3TtmSemiBoldBlack style={mt20}>
              {labels.enterNewPasscode}
              <H2TtmSemiBoldRed>*</H2TtmSemiBoldRed>
            </H3TtmSemiBoldBlack>
            <Controller
              name="new_passcode"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                <View style={styles.passcodeContainer}>
                  <PasscodeInputWeb
                    ref={newPasscodeRef}
                    onChange={onChange}
                    onEnter={onChange}
                    value={value}
                    errorMessage={isSubmitted && error?.message}
                  />
                </View>
              )}
              defaultValue=""
              rules={{
                maxLength: 4,
                required: requiredValidation('New Passcode'),
              }}
            />

            <H3TtmSemiBoldBlack style={mt20}>
              {labels.confirmNewPasscode}
              <H2TtmSemiBoldRed>*</H2TtmSemiBoldRed>
            </H3TtmSemiBoldBlack>
            <Controller
              name="new_passcode_confirmation"
              control={control}
              render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                <View style={styles.passcodeContainer}>
                  <PasscodeInputWeb
                    ref={confirmPasscodeRef}
                    onChange={onChange}
                    onEnter={onChange}
                    value={value}
                    errorMessage={isSubmitted && error?.message}
                  />
                </View>
              )}
              defaultValue=""
              rules={{
                maxLength: 4,
                required: requiredValidation('Passcode Confirmation'),
              }}
            />
          </View>
          <CancelContinueFooter
            style={mt15}
            onCancel={clearAll}
            onConfirm={handleSubmit(changePasscode)}
            confirmTitle="Update"
            waiting={loading}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  passcodeContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
});
