import React from 'react';
import { Controller, useController } from 'react-hook-form';
import { View } from 'react-native';

import { mt10, wizardStyle } from '~/common/commonStyles';
import { HomeVisitMapView } from '~/components/availabilities/homeVisit/HomeVisitMapView';
import { Button } from '~/components/commonButton';
import FloatingInput from '~/components/inputs/FloatingInput';
import { WizardLayout } from '~/layouts/WizardLayout';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { requiredValidation } from '~/services/validationConfig';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';

export const HomeVisitLocationStep: React.FC = () => {
  const {
    loading,
    formControl,
    wizard: {
      actions: { next },
    },
  } = useAppointmentBookingContext();

  const buttons = [<Button style={mt10} label={labels.next} funCallback={next} disabled={loading} />];

  const {
    field: { value: formattedAddress, onChange: setFormattedAddress },
  } = useController({ control: formControl, name: 'formattedAddress' });

  return (
    <View style={wizardStyle.container}>
      <WizardLayout title={appointmentLabels.chooseLocationMap} buttons={buttons}>
        <View style={{ width: '100%' }}>
          <Controller
            control={formControl}
            name="location"
            render={({ field: { value, onChange }, formState: { isSubmitted }, fieldState: { error } }) => (
              <>
                <HomeVisitMapView
                  hideRadius
                  latLng={value}
                  setFormattedAddress={setFormattedAddress}
                  setLatLng={onChange}
                />
                <FloatingInput
                  editable={false}
                  label="Pinned address"
                  placeholder="Address of pinned location shown here"
                  value={formattedAddress}
                  error={isSubmitted && !!error}
                  errorMessage={isSubmitted && error?.message}
                />
              </>
            )}
            rules={{
              required: requiredValidation('Address'),
            }}
          />
        </View>
      </WizardLayout>
    </View>
  );
};
