import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle, Text, FlexStyle, TextStyle, TouchableOpacity } from 'react-native';
import { RadioButton } from 'react-native-paper';

import { colors } from '../../utils/colors';
import { FormErrorMessage, H6NsRegularBlack } from '../commonText';

import { flex1, mb5, mr10 } from '~/common/commonStyles';

interface Props {
  items: any[];
  selected: any;
  onValueChange: (selected: string) => void;
  idTitle?: string;
  valueTitle?: string;
  horizontal?: boolean;
  reverse?: boolean;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  itemStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  selectedTextStyle?: StyleProp<TextStyle>;
  showMandatory?: boolean;
  disabled?: boolean;
  itemNode?: (item: any) => React.ReactNode;
}

export const RadioButtons: React.FC<Props> = ({
  items,
  selected,
  onValueChange,
  idTitle = 'id',
  valueTitle = 'name',
  horizontal,
  reverse,
  label,
  error,
  errorMessage,
  style,
  containerStyle,
  itemStyle,
  textStyle,
  showMandatory,
  disabled,
  itemNode,
}) => {
  const flexDirection = useMemo<FlexStyle['flexDirection']>(() => {
    if (horizontal) {
      return reverse ? 'row-reverse' : 'row';
    } else {
      return reverse ? 'column-reverse' : 'column';
    }
  }, [horizontal, reverse]);

  return (
    <View style={style}>
      {label ? (
        <H6NsRegularBlack style={mb5}>
          {label} {showMandatory ? <Text style={styles.asterisk}>*</Text> : null}
        </H6NsRegularBlack>
      ) : null}
      <RadioButton.Group onValueChange={onValueChange} value={selected}>
        <View style={[{ display: 'flex', flexDirection }, containerStyle]}>
          {items.map((item) => (
            <View style={[styles.radioButton, itemStyle, horizontal ? mr10 : null]} key={item[idTitle]}>
              <RadioButton.Android
                value={item[idTitle]}
                color={colors.purple}
                uncheckedColor={error ? colors.danger : colors.purple}
                disabled={disabled}
                style={{ alignSelf: 'center' }}
              />
              <TouchableOpacity disabled={disabled} onPress={() => onValueChange(item[idTitle])}>
                {itemNode ? (
                  itemNode(item)
                ) : (
                  <H6NsRegularBlack
                    style={[selected === item[idTitle] && !disabled ? { color: colors.purple } : null, textStyle]}>
                    {item[valueTitle]}
                  </H6NsRegularBlack>
                )}
              </TouchableOpacity>
            </View>
          ))}
        </View>
      </RadioButton.Group>
      {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  radioButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontSize: 14,
    fontFamily: 'NotoSans',
  },
  asterisk: {
    color: colors.danger,
  },
});
