import React from 'react';

import { OnBoardingContentPlaceholder } from '../onBoardingContentPlaceholder';

import { onboardingLabels } from '~/utils/labels/onboardingLabels';
interface Props {
  width: number;
}
export const OnBoardingPatientPage4: React.FC<Props> = ({ width }) => {
  return (
    <OnBoardingContentPlaceholder
      width={width}
      noMask
      title={onboardingLabels.patient[3].title}
      subtitle={onboardingLabels.patient[3].subTitle}
      image={require('~/../assets/img/onboarding/patient_4.png')}
    />
  );
};
