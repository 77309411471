export function toggleFullScreen(id: string | HTMLElement) {
  if (!document.fullscreenElement) {
    const element = typeof id !== 'string' ? id : document.getElementById(id);
    if (!element) return;
    element.requestFullscreen();
    return { isFullScreen: true };
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
    return { isFullScreen: false };
  }
}

export function addFullScreenChangeListener(method: () => any) {
  if (document.addEventListener) {
    document.addEventListener('fullscreenchange', method, false);
    document.addEventListener('mozfullscreenchange', method, false);
    document.addEventListener('MSFullscreenChange', method, false);
    document.addEventListener('webkitfullscreenchange', method, false);
  }

  return () => {
    document.removeEventListener('fullscreenchange', method);
    document.removeEventListener('mozfullscreenchange', method);
    document.removeEventListener('MSFullscreenChange', method);
    document.removeEventListener('webkitfullscreenchange', method);
  };
}

export const checkIsFullScreen = () => {
  return !!(
    document.fullscreenElement ||
    // @ts-ignore
    document?.webkitIsFullScreen ||
    // @ts-ignore
    document?.mozFullScreen ||
    // @ts-ignore
    document?.msFullscreenElement
  );
};
