export enum AppointmentPageEnum {
  Loading = 1,
  WaitingRoom,
  InConsultation_Chat,
  InConsultation_Call,
  Ended,
  Details,
  HomeVisitDetails,
  NotFound,
}
