import React, { ReactNode } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { FormErrorMessage, H6NsRegularPlaceholder, SmallNsRegularInfo } from './commonText';
import { border1, borderDangerColor, ml10, mr10 } from '../common/commonStyles';
import { colors } from '../utils/colors';

interface InputProps {
  value: string;
  label: string;
  placeholder?: string;
  showMandatory: boolean;
  onClick: CallableFunction;
  onChange?: CallableFunction;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  changeComponentFlex?: boolean;
  renderValue?: (value: string) => ReactNode;
  iconPrefix?: ReactNode;
  iconSuffix?: ReactNode;

  withBorder?: boolean;
}

// create a component
const FloatingDateTimeBox: React.FC<InputProps> = ({
  errorMessage,
  label,
  error,
  onChange,
  disabled,
  placeholder,
  value,
  onClick,
  changeComponentFlex,
  iconPrefix,
  iconSuffix,
  renderValue,
  showMandatory,
  withBorder = true,
}): JSX.Element => {
  React.useEffect(() => {
    value && onChange && onChange(value);
  }, []);

  return (
    <View style={[changeComponentFlex ? null : { flex: 0.45, marginTop: 10 }]}>
      <TouchableOpacity
        disabled={disabled}
        style={[
          withBorder ? border1 : null,
          value ? styles.timeSelectedTheme : styles.selectTimeTextBox,
          error && [border1, borderDangerColor],
        ]}
        onPress={() => onClick()}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {iconPrefix ? <View style={mr10}>{iconPrefix}</View> : null}
          {renderValue ? (
            renderValue(value)
          ) : (
            <H6NsRegularPlaceholder>
              {value ? value : label + (placeholder || '')}
              {!value && showMandatory ? (
                <H6NsRegularPlaceholder style={{ color: colors.danger }}>*</H6NsRegularPlaceholder>
              ) : null}
            </H6NsRegularPlaceholder>
          )}
          {iconSuffix ? <View style={ml10}>{iconSuffix}</View> : null}
        </View>
      </TouchableOpacity>

      {value ? (
        <SmallNsRegularInfo style={styles.pickerText}>
          {label}
          {showMandatory ? <SmallNsRegularInfo style={{ color: colors.danger }}>*</SmallNsRegularInfo> : null}
        </SmallNsRegularInfo>
      ) : null}

      {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  pickerText: {
    marginLeft: 10,
    paddingHorizontal: 5,
    position: 'absolute',
    top: -7,
    backgroundColor: 'white',
  },

  timeSelectedTheme: {
    borderColor: colors.info,
    width: '100%',
    borderRadius: 6,
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingHorizontal: 10,
    height: 56,
    justifyContent: 'center',
  },
  selectTimeTextBox: {
    borderColor: colors.lightPurple,
    width: '100%',
    borderRadius: 6,
    backgroundColor: colors.lightPurple,
    alignSelf: 'center',
    paddingHorizontal: 10,
    height: 56,
    justifyContent: 'center',
  },
});
export default FloatingDateTimeBox;
