import React, { useEffect } from 'react';
import { View } from 'react-native';

import { ConsultationViewItem } from '../../ConsultationViewItem';

import { useMediaPolling } from '~/api/hooks/consultations/MediaPolling';
import { useReferralMedia } from '~/api/hooks/consultations/ReferralMediaHook';
import { pv20, ph10 } from '~/common/commonStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import { LoadingFetchingList } from '~/components/loading/LoadingFetchingList';
import { useReferralDataContext } from '~/providers/consultation/ReferralDataContext';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const ReferralsViewMode: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const { loading, referral } = useReferralDataContext();
  const {
    referralMediaFiles,
    generatedFiles,
    getReferralsMedia,
    loading: referralLoading,
    getFiles,
  } = useReferralMedia();

  useMediaPolling(() => getReferralsMedia(referral?.id), 2000, generatedFiles?.status);
  useEffect(() => {
    if (referral?.id) {
      getFiles(referral.id);
    }
  }, [referral?.id]);

  if (loading || referralLoading) return <LoadingFetchingList fullWidth />;
  return (
    <View>
      {generatedFiles ? (
        <ConsultationViewItem
          title="Referral"
          value={generatedFiles?.media?.length ? generatedFiles.media[0] : undefined}
          style={{ borderBottomWidth: 0 }}
          status={generatedFiles.status}
        />
      ) : null}
      {referralMediaFiles?.map((item, index, arr) => {
        const isLast = index === arr.length - 1;
        return <ConsultationViewItem key={item.id} value={item} style={isLast ? { borderBottomWidth: 0 } : null} />;
      })}

      {(!referralMediaFiles?.length && !generatedFiles?.media && !generatedFiles?.status) || !referral?.id ? (
        <View style={isMobile ? pv20 : [ph10, pv20]}>
          <H6NsRegularBlack>No referral files submitted</H6NsRegularBlack>
        </View>
      ) : null}
    </View>
  );
};
