import FontAwesome from '@expo/vector-icons/FontAwesome';
import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useMemo, useState } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import { Menu } from 'react-native-paper';

import { ErrorAlert } from '../modals/ErrorAlert';

import { DocumentFormats, ImageFormats } from '~/constants/documentUploadsConstants';
import { isNative } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useUploadDocumentHook } from '~/utils/hooks/UploadDocumentHook';

interface Props {
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  formats: string[];
  children: React.ReactNode;
  onResult: (result: DocumentPickerAsset[]) => Promise<void>;
}

export const UploadDocumentTouchable: React.FC<Props> = ({ disabled, children, style, formats, onResult }) => {
  const [visible, setVisible] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const imagesAllowed = useMemo(() => ImageFormats.some((imgFormat) => formats.includes(imgFormat)), [formats]);
  const documentsAllowed = useMemo(() => DocumentFormats.some((docFormat) => formats.includes(docFormat)), [formats]);

  const showMenuSelect = useMemo(() => isNative() && imagesAllowed, [imagesAllowed]);

  const uploadDocumentHook = useUploadDocumentHook();

  const toggleMenu = () => {
    setVisible(!visible);
  };

  const startUpload = async () => {
    try {
      setWaiting(true);
      if (!documentsAllowed) {
        await launchImagePicker();
      } else {
        await launchFilePicker();
      }
    } finally {
      setWaiting(false);
    }
  };

  const launchImagePicker = async () => {
    try {
      setWaiting(true);
      setVisible(false);
      const result = await uploadDocumentHook.launchImagePicker();
      onResult(result);
    } catch (error) {
      ErrorAlert(error);
    } finally {
      setWaiting(false);
    }
  };

  const launchFilePicker = async () => {
    try {
      setWaiting(true);
      setVisible(false);
      const result = await uploadDocumentHook.launchFilePicker({
        allowedFormats: formats,
      });

      onResult(result);
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setWaiting(false);
    }
  };

  const launchCamera = async () => {
    try {
      setWaiting(true);
      setVisible(false);
      const result = await uploadDocumentHook.launchCamera();

      onResult(result);
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setWaiting(false);
    }
  };

  if (showMenuSelect)
    return (
      <Menu
        visible={visible}
        onDismiss={toggleMenu}
        anchor={
          <TouchableOpacity disabled={disabled || waiting} onPress={toggleMenu} style={style}>
            {children}
          </TouchableOpacity>
        }>
        <Menu.Item
          style={styles.container}
          title="Photo Library"
          titleStyle={styles.title}
          onPress={launchImagePicker}
          leadingIcon={({ color }) => <FontAwesome color={color} name="photo" size={20} style={styles.icon} />}
        />
        <Menu.Item
          style={[styles.container, styles.middleOption]}
          title="Take Photo"
          titleStyle={styles.title}
          leadingIcon={({ color }) => <FontAwesome color={color} name="camera" size={20} style={styles.icon} />}
          onPress={launchCamera}
        />
        <Menu.Item
          style={styles.container}
          title="Choose File"
          titleStyle={styles.title}
          onPress={launchFilePicker}
          leadingIcon={({ color }) => <FontAwesome color={color} name="folder-open-o" size={20} style={styles.icon} />}
        />
      </Menu>
    );

  return (
    <TouchableOpacity disabled={disabled || waiting} onPress={startUpload} style={style}>
      {children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 12,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlignVertical: 'center',
  },
  middleOption: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: colors.lightGrey,
    paddingVertical: 4,
  },
});
