import React, { useMemo, useState } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { Menu } from 'react-native-paper';

import { webMenuStyles } from '../notifications/DotMenuCommonStyles';

import { LabelValuePair } from '~/common/types/LabelValuePair';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { DownArrow, UpArrow } from '~/components/svgImages';
import { colors } from '~/utils/colors';

interface Props {
  value: string;
  onChange: (item: LabelValuePair<string>) => void;
  itemsList: LabelValuePair<string>[];
}

export const CalendarTypeMenu: React.FC<Props> = ({ value, onChange, itemsList }) => {
  const [visible, setVisible] = useState(false);

  const changePageType = (item) => {
    onChange(item);
    changeVisibility();
  };

  const changeVisibility = () => {
    setVisible(!visible);
  };

  const item = useMemo(() => itemsList.find((item) => item.value === value), [value, itemsList]);
  return (
    <TouchableOpacity
      onPress={() => {
        setVisible(true);
      }}>
      <View style={styles.select}>
        <H3TtmSemiBoldBlack style={{ color: colors.purple, marginRight: 10 }}>{item?.label}</H3TtmSemiBoldBlack>
        <Menu
          visible={visible}
          onDismiss={() => changeVisibility()}
          anchor={
            visible ? (
              <UpArrow height={10} width={10} color={colors.purple} />
            ) : (
              <DownArrow height={10} width={10} color={colors.purple} />
            )
          }
          contentStyle={{ borderRadius: 20 }}
          style={webMenuStyles.menuStyle}>
          {itemsList?.map((item, i) => (
            <Menu.Item
              title={item?.label}
              onPress={() => changePageType(item)}
              style={i === itemsList?.length - 1 ? webMenuStyles.lastItemStyle : webMenuStyles.itemsListStyle}
              key={item.value}
            />
          ))}
        </Menu>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  select: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
});
