import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { userLogout } from '~/api/axios';

import { ConfirmationAlert } from '~/common/commonMethods';
import { mh20, mr0 } from '~/common/commonStyles';
import { H4NsRegularBlack } from '~/components/commonText';
import { ForwardArrow } from '~/components/svgImages';
import { useMenusNativeSlide } from '~/constants/menus/menuNativeSlide';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';

interface Props {
  canClose: CallableFunction;
}

export const SlideMenu: React.FC<Props> = ({ canClose }) => {
  const navigation = useNavigation<NavType>();

  const tryLogout = () => {
    ConfirmationAlert([''], { title: 'Are you sure you want to logout?', okTitle: 'Logout', okFunction: userLogout });
  };

  const { menu } = useMenusNativeSlide();
  return (
    <View style={{ paddingTop: 15, paddingBottom: 10 }}>
      {menu.map((item, index) => {
        const isLogout = item.label === 'Logout';
        return (
          <TouchableOpacity
            key={index}
            style={styles.sideMenuRow}
            onPress={() => {
              if (item.label === 'Logout') {
                tryLogout();
              } else if (item.pageName) {
                navigation.navigate(item.pageName, item.pageParams);
              }
              canClose();
            }}>
            <View style={styles.sideMenuColumn}>
              {item.image(isLogout ? colors.danger : colors.purple)}
              <H4NsRegularBlack style={[mh20, mr0, { color: isLogout ? colors.danger : colors.purple }]}>
                {item.label}
              </H4NsRegularBlack>
            </View>
            {isLogout ? null : (
              <View style={styles.sideMenuColumn}>
                <ForwardArrow color={colors.purple} height={12} width={12} />
              </View>
            )}
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

// define your styles
const styles = StyleSheet.create({
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.7)',
    right: 0,
  },
  typesOfConsultationsModalTopNotch: {
    marginBottom: 20,
    backgroundColor: '#35405233',
    borderRadius: 10,
    alignItems: 'center',
    // justifyContent:"center",
    alignSelf: 'center',
    width: 100,
    height: 6,
  },
  sideMenuRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 15,
  },
  sideMenuColumn: { flexDirection: 'row', alignItems: 'center' },
});

//make this component available to the app
