import React from 'react';
import { View, StyleSheet, Text } from 'react-native';

import { colors } from '~/utils/colors';

interface Props {
  backgroundColor?: string;
  color: string;
  label: string;
}

export const ColouredStatusBadge: React.FC<Props> = ({ color, backgroundColor, label }) => {
  return (
    <View style={[styles.container, { backgroundColor: backgroundColor || `${color}22` }]}>
      <Text style={[styles.text, { color }]}>{label}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    backgroundColor: colors.lightPurple,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    paddingVertical: 4,
    paddingHorizontal: 10,
  },
  text: {
    fontSize: 10,
    fontFamily: 'NotoSans',
    fontWeight: '600',
  },
  badge: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 4,
    height: 6,
    width: 6,
    borderRadius: 6,
  },
});
