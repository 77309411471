import React, { useRef } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import { SickLeaveDataContext } from './SickLeaveDataContext';

import { useMedicalNote } from '~/api/hooks/consultations/MedicalNoteHook';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { MedicalNoteModel } from '~/api/models/consultations/models/MedicalNoteModel';
import { SickLeaveFormModel } from '~/api/models/consultations/models/SickLeaveFormModel';
import { useAutoSaveForm } from '~/utils/hooks/AutoSaveFormHook';

interface Props {
  consultation: ConsultationModel;
  children: React.ReactNode;
}

export const SickLeaveDataProvider: React.FC<Props> = ({ consultation, children }) => {
  const { loading, setMedicalNote, getMedicalNote, getMedicalNoteMedia, generatedFiles } = useMedicalNote({
    consultationId: consultation.id,
  });

  const medicalNoteId = useRef<number>();
  const { control, getValues, reset } = useForm<SickLeaveFormModel>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      note: '',
    },
  });

  const { isDirty } = useFormState({ control });

  const setFormData = (data: MedicalNoteModel, keepValues?: boolean) => {
    medicalNoteId.current = data?.id;
    reset(
      {
        note: data?.note || '',
      },
      { keepValues }
    );
  };

  const { saving } = useAutoSaveForm({
    control,
    autoSave: async () => {
      const formData = getValues();
      const res = await setMedicalNote({ ...formData, medicalNoteId: medicalNoteId.current });
      setFormData(res?.data, true);
    },
  });

  const verifyForm = async () => {
    // no validation needed
    return true;
  };

  const submit = async () => {
    if (isDirty) {
      const formData = getValues();
      const res = await setMedicalNote(formData);
      setFormData(res?.data, true);
    }
  };

  const loadData = async () => {
    const res = await getMedicalNote();
    const data = res?.data;
    setFormData(data);

    if (data?.id && consultation?.submitted_at) {
      pollForMedia();
    }
  };

  const pollForMedia = async () => {
    if (!medicalNoteId.current) return;
    return getMedicalNoteMedia(medicalNoteId.current);
  };

  return (
    <SickLeaveDataContext.Provider
      value={{
        saving,
        loading,
        control,
        consultation,
        verifyForm,
        submit,
        loadData,
        pollForMedia,
        medicalNoteMedia: generatedFiles,
      }}>
      {children}
    </SickLeaveDataContext.Provider>
  );
};
