import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import React, { useMemo } from 'react';
import { View } from 'react-native';

import { DoctorAvailabilitiesWarning } from './DoctorAvailabilitiesWarning';
import { H3TtmSemiBoldBlack, H4TtmRegularSecondaryBlack } from '../commonText';
import { CustomMaterialTabNavigation } from '../navigation/CustomMaterialTabNavigation';

import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { mb10, mb20 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

interface Props {
  tabProps: MaterialTopTabBarProps;
}

export const AvailabilitiesNavigationTab: React.FC<Props> = ({ tabProps }) => {
  const { isMobile } = useBreakpoints();
  const { isFeatureDisabled } = useFeatureAccess();
  const disabledConsultationTypes = isFeatureDisabled(FeatureAccessEnum.CONSULTATION_TYPES);

  const consultationType = useMemo(() => {
    const screen = tabProps?.state.routeNames[tabProps.state.index];
    switch (screen) {
      case screenName.AvailabilitiesScheduledAppointments:
        return ConsultationTypeEnum.SCHEDULED_APPOINTMENT;
      case screenName.AvailabilitiesHomeVisit:
        return ConsultationTypeEnum.HOME_VISIT;
      case screenName.AvailabilitiesDoctorsAvailableNow:
        return ConsultationTypeEnum.ON_DEMAND;
    }
  }, [tabProps?.state.routeNames, tabProps?.state.index]);

  return (
    <>
      <CustomMaterialTabNavigation tabProps={tabProps} />
      <DoctorAvailabilitiesWarning
        consultationType={consultationType}
        style={[mb10, isMobile ? { marginHorizontal: MOBILE_HORIZONTAL_SPACE } : null]}
      />
      {disabledConsultationTypes ? (
        <View style={[mb20, isMobile ? { marginHorizontal: MOBILE_HORIZONTAL_SPACE } : null]}>
          <H3TtmSemiBoldBlack>Your availability is managed by a clinic</H3TtmSemiBoldBlack>
          <H4TtmRegularSecondaryBlack>
            You will be assigned consultations by the clinic, so you do not need to set your own availabilities.
          </H4TtmRegularSecondaryBlack>
        </View>
      ) : null}
    </>
  );
};
