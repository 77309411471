import React from 'react';

import { PageTypesEnum } from '~/api/models/pages/constants/PageTypesEnum';
import PolicyContent from '~/components/policies/PolicyContent';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { Column, Container, Row } from '~/theme/components/grid';
import { isPatientVersion } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const HelpCancellationPolicy: React.FC = () => {
  return (
    <NestedPageInternalLayout backTitle={labels.help} backLink={{ screen: screenName.Help }}>
      <Container fluid>
        <Row justifyContent="center">
          <Column width={800} alignSelf="center">
            <PolicyContent
              contentType={
                isPatientVersion()
                  ? PageTypesEnum.PATIENT_CANCELLATION_POLICY
                  : PageTypesEnum.DOCTOR_CANCELLATION_POLICY
              }
            />
          </Column>
        </Row>
      </Container>
    </NestedPageInternalLayout>
  );
};
