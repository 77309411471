import * as WebBrowser from 'expo-web-browser';
import { useEffect, useRef } from 'react';
import { AppState, Linking } from 'react-native';

import { timeout } from '../promiseUtil';

import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
export const useOpenUrl = () => {
  const { openModal } = useModalManager();
  const stateChanged = useRef(false);

  const openUrl = async (url: string) => {
    stateChanged.current = false;
    await Linking.openURL(url).catch(() => {
      return WebBrowser.openBrowserAsync(url, {
        windowName: '_blank',
      });
    });

    await timeout(200);

    if (!stateChanged.current && AppState.currentState === 'active') {
      openModal(ModalName.OpenUrlModal, {
        url,
      });
    }
  };

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      stateChanged.current = stateChanged.current || nextAppState !== 'active';
    });

    return () => {
      subscription?.remove();
    };
  }, []);

  return { openUrl };
};
