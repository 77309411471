import { useNavigation } from '@react-navigation/native';
import React from 'react';

import { AmountPaidForm } from '../components/AmountPaidForm';
import { InsurancePolicyDetails } from '../components/InsurancePolicyDetails';
import { useInsuranceDetails } from '../hooks/useInsuranceDetails';

import { CancelContinueFooterArray } from '~/components/buttons/CancelContinueFooterArray';
import { WizardLayout } from '~/layouts/WizardLayout';
import { NavType } from '~/navigation/types';
import { useInsuranceClaimFormContext } from '~/providers/insurance/InsuranceClaimFormContext';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { screenName } from '~/utils/screenName';

export const AmountPaidStep: React.FC = () => {
  const { nextPage, loading, updateInsuranceDocuments } = useInsuranceClaimFormContext();
  const { navigate } = useNavigation<NavType>();
  const { insuranceDetails } = useInsuranceDetails();

  const buttons = CancelContinueFooterArray({
    waiting: loading,
    onCancel: () => navigate(screenName.Insurance, { screen: screenName.InsuranceClaims }),
    onConfirm: () => {
      updateInsuranceDocuments().then(nextPage);
    },
  });

  return (
    <WizardLayout buttons={buttons} wide>
      <Grid grid={{ gutters: 16 }}>
        <Container alignSelf="flex-start">
          <Row flex>
            <Column span={{ xs: 12, lg: 4 }} style={{ minWidth: 370, marginBottom: 20 }}>
              <InsurancePolicyDetails insuranceDetails={insuranceDetails} />
            </Column>
            <AmountPaidForm padded />
          </Row>
        </Container>
      </Grid>
    </WizardLayout>
  );
};
