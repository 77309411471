import { Dimensions, StyleSheet } from 'react-native';

import { colors } from './colors';
import { fontFamily } from './fontFamily';

export const deviceWidth = Dimensions.get('screen').width;
export const deviceHeight = Dimensions.get('screen').height;

export const theme = StyleSheet.create({
  // onboarding
  onboardingContainer: {
    flex: 1,
    backgroundColor: colors.lightPurple,
  },
  // onboardingImageContainer: {
  //     flex: 0.6,
  //     // backgroundColor:"#2c3e50"
  // },
  onboardingBodyContainer: {
    flex: 0.4,
    paddingHorizontal: 36,
    justifyContent: 'space-around',
  },
  // onboardingWelcomeTitleStyle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 24,
  //     alignSelf: "center",
  //     color: colors.black,
  //     marginVertical: 10,
  // },
  // onboardingMessageStyle: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14,
  //     marginHorizontal: 20,
  //     textAlign: "center",
  //     color: colors.grey,
  // },
  // onboardingMessageHintStyle: {
  //     marginTop: 15
  // },
  // onboardingNextButtonStyle: {
  //     backgroundColor: colors.purple,
  //     paddingHorizontal: 57,
  //     paddingVertical: 9,
  //     borderRadius: 5,
  //     alignSelf: "center",
  //     // marginTop: 70
  // },
  // onboardingNextButtonFontStyle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 14,
  //     textAlign: "center",
  //     color: colors.white,
  // },
  onboardingHorizontalDot: {
    flexDirection: 'row',
    flex: 0.05,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dotUnSelected: {
    marginHorizontal: 5,
    height: 10,
    width: 10,
    borderRadius: 30,
    backgroundColor: colors.lightPurple3,
  },
  dotSelected: {
    marginHorizontal: 5,
    height: 10,
    width: 20,
    borderRadius: 30,
    backgroundColor: colors.purple,
  },
  dotSelectedColor: {
    backgroundColor: colors.purple,
  },
  // signInButton: {
  //     color: colors.purple,
  //     fontSize: 15,
  //     fontFamily: fontFamily.fontSemiBold,
  // },

  // otp Screen
  // optTextBoxStyle: {
  //     height: 45, width: 45, borderRadius: 6, color: colors.black
  // },

  onboardingScreenTitle: {
    fontFamily: fontFamily.fontSemiBold,
    fontSize: 24,
    // alignSelf:"center",
    color: colors.black,
    // marginVertical:10,
  },
  // onboardingScreenSubTitle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 18,
  //     // alignSelf:"center",
  //     color: colors.black,
  //     // marginVertical:10,
  // },

  // otpScreenTitle:{
  //     fontFamily:fontFamily.fontSemiBold,
  //     fontSize:24,
  //     // alignSelf:"center",
  //     color:colors.black,
  //     // marginVertical:10,
  // },
  // otpScreenSubText: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14,
  //     // marginHorizontal:20,
  //     // textAlign:"center",
  //     color: colors.grey,
  // },
  // onboardingScreenSubmessage: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14,
  //     // marginHorizontal:20,
  //     // textAlign:"center",
  //     color: colors.grey,
  // },
  // otpScreenMObileNumberFont: {
  //     fontFamily: fontFamily.fontBold,
  // },
  // otpScreenEnterButtonStyle: {
  //     backgroundColor: colors.purple,
  //     // paddingHorizontal:57,
  //     width: "80%",
  //     paddingVertical: 9,
  //     borderRadius: 5,
  //     alignSelf: "center",
  //     // marginTop:70
  // },
  // resendCodeButtonTextStyle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 14,
  //     color: colors.purple,
  //     paddingVertical: 25,
  //     alignSelf: "center"
  // },

  // Enter email
  emailScrenContainer: {
    flex: 1,
    justifyContent: 'space-between',

    backgroundColor: colors.white,
    // padding: 10,
    paddingVertical: 30,
    paddingHorizontal: 30,
  },
  // emailTextBoxStyle: {
  //     width: "100%",
  //     borderRadius: 6,
  //     backgroundColor: colors.lightPurple,
  //     alignSelf: "center",
  //     height: 45,
  //     paddingHorizontal: 10,
  //     // marginTop: 50
  // },
  floatingTextBoxStyle: {
    width: '100%',
    borderRadius: 6,
    backgroundColor: colors.lightPurple,
    alignSelf: 'center',
    height: 45,
    paddingHorizontal: 10,
    // marginTop: 50
  },
  // inValidEmailTextBoxStyle: {
  //     width: "100%",
  //     borderRadius: 6,
  //     backgroundColor: colors.lightPurple,
  //     alignSelf: "center",
  //     height: 45,
  //     paddingHorizontal: 10,
  //     // marginTop: 50,
  //     borderWidth: 1, borderColor: colors.danger,

  //     shadowColor: colors.black,
  //     shadowOffset: { width: -2, height: 4 },
  //     shadowOpacity: 0.2,
  //     shadowRadius: 3,
  // },

  uploadDocumentButtonStyle: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
    // shadowColor: 'black',

    // shadowOpacity: 0.1,
    // elevation: 2,
    // // backgroundColor : "#0000",
    // shadowRadius: 5 ,
    // shadowOffset : { width: 0, height: 0},
    // // borderWidth:0,
    // borderRadius:5,
  },
  // uploadDocumentButtonTextStyle: {
  //     textAlign: "center",
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14,
  //     color: colors.grey,
  // },
  // typeOfConsultationsScreenSurfaceText: {
  //     // textAlign: "center",
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14,
  //     color: colors.purple,
  // },
  // consultationTypeButtonTextStyle: {
  //     textAlign: "center",
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14,
  //     color: colors.grey,
  // },
  // uploadDocumentIconsStyle: {
  //     height: 25, width: 25, marginRight: 10, tintColor: colors.grey
  // },
  // uploadDocumentSurface: {
  //     justifyContent: "center",
  //     height: 45,
  //     borderRadius: 6,
  //     marginVertical: 25,
  //     elevation: 4,
  // },
  selectedIDButtonStyle: {
    shadowColor: colors.info,
    backgroundColor: colors.lightPurple,
    borderWidth: 1,
    borderColor: colors.info,
  },

  personalDetailsTextBox: {
    borderColor: colors.lightPurple,
    width: '100%',
    borderRadius: 6,
    backgroundColor: colors.lightPurple,
    alignSelf: 'center',
    paddingHorizontal: 10,
    marginTop: 20,
    height: 45,
    justifyContent: 'center',
  },
  // personalDetailsDropDown: {
  //     borderColor: colors.lightPurple,
  //     width: "100%",
  //     borderRadius: 6,
  //     backgroundColor: colors.lightPurple,
  //     alignSelf: "center",
  //     paddingHorizontal: 10,
  //     // marginTop:20
  // },
  onboardingScreenDOBTextStyle: {
    // fontFamily:fontFamily.fontRegular,
    fontSize: 14,
    // textAlign:"center",
    color: colors.purpleGrey,
  },
  // homeAddressScrenContainer: {
  //     flex: 1,
  //     justifyContent: "space-between",

  //     backgroundColor: colors.white,
  //     // padding: 10,
  //     paddingVertical: 30,
  //     paddingHorizontal: 30
  // },
  // drVerificationUploadedImageStyle: {
  //     // width:"80%",
  //     marginTop: 20,
  //     borderRadius: 6,
  //     height: 180,
  // },

  drVerificationNewStyles: {
    backgroundColor: colors.lightPurple,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    borderRadius: 6,
    height: 180,
  },

  areaofSpecialisationStyle: {
    borderTopColor: colors.lightPurple2,
    borderTopWidth: 2,
    // paddingHorizontal: 30
  },
  // listOfClinicContainerStyle: {
  //     // borderTopColor: colors.lightPurple2,
  //     borderBottomColor: colors.lightPurple2,
  //     // borderTopWidth: 2,
  //     borderBottomWidth: 2,
  //     // paddingBottom: 20,
  //     paddingVertical: 20,
  //     paddingHorizontal: 30
  // },
  // doctorVerificationCOntainerStyle: {
  //     flex: 1,
  //     justifyContent: "space-between",

  //     backgroundColor: colors.white,
  //     // padding: 10,
  //     paddingVertical: 30,
  // },
  // addAnotherAreaOfSpecialisationStyle: {
  //     color: colors.info,
  //     fontSize: 15,
  //     fontFamily: fontFamily.fontSemiBold,
  //     marginTop: 8,
  //     alignItems: "center", alignSelf: "center"
  // },

  // passcodeScreenContainer: {
  //     flex: 1,
  //     padding: 20,
  //     paddingTop: 50,
  //     alignItems: "center",
  //     backgroundColor: colors.white
  // },
  // passcodeLogoStyle: {
  //     width: 223,
  //     height: 97,
  //     marginVertical: 10

  // },
  // passcodefaceIdImage: {
  //     width: 25,
  //     height: 25,
  // },
  passcodedot: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  passcodedotActive: {
    backgroundColor: colors.purple,
  },
  passcodedotInactive: {
    backgroundColor: colors.lightPurple3,
  },
  passcodedotContainer: {
    width: 80,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
    marginTop: 30,
  },
  // passcodenumberContainer: {
  //     flexDirection: 'row',
  //     justifyContent: "center",
  //     marginBottom: 10,
  //     marginTop: 10,
  // },
  // passcodeuserWelcome: {
  //     fontSize: 16,
  //     color: colors.black,
  //     marginBottom: 10,
  //     marginTop: 10,
  // },
  // passcodeforgotPasscode: {
  //     textAlign: "center",
  //     fontSize: 14,
  //     color: colors.purple,
  //     marginBottom: 5,
  //     marginTop: 20,
  //     fontFamily: fontFamily.fontSemiBold
  // },

  // tcTextStyle: {
  //     textAlign: "center",
  //     fontSize: 14,
  //     color: colors.info,
  //     marginBottom: 10,
  //     // marginTop: 20,
  //     fontFamily: fontFamily.fontSemiBold
  // },
  // passcodenumbersKey: {
  //     fontFamily: fontFamily.fontTitilliumRegular,
  //     color: colors.grey,
  //     fontSize: 14
  // },
  // passcodeScreenEnterAndClearTextStyle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     color: colors.themeCopurplelor,
  //     fontSize: 14
  // },
  phoneNumberScreenContainer: {
    flex: 1,
    justifyContent: 'space-between',

    backgroundColor: colors.white,
    // padding: 10,
    paddingVertical: 30,
    paddingHorizontal: 30,
  },
  // phoneNumberScreenChangePhoneNumberButtonTextStyle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 14,
  //     color: colors.info,
  //     paddingVertical: 25,
  //     alignSelf: "center"
  // },
  phoneNumberScreenCountryPickerButtonStyle: {
    backgroundColor: colors.lightPurple,
    height: 55,
    borderRadius: 6,
    marginRight: 10,
  },
  phoneNumberScreenContainerStyle: {
    color: 'black',
    height: 55,
    width: '100%',
    borderRadius: 6,
  },
  phoneNumberScreenTextContainerStyle: {
    color: 'black',
    height: 55,
    backgroundColor: colors.lightPurple,
    borderRadius: 6,
  },
  phoneInputText: {
    fontSize: 14,
    fontFamily: fontFamily.fontRegular,
    color: colors.purpleGrey,
  },
  // registrationStepperScreenContainer: {
  //     flex: 1,
  //     justifyContent: "space-between",
  //     backgroundColor: colors.white,
  //     // padding: 10,
  //     paddingVertical: 30,
  //     paddingHorizontal: 30
  // },

  registrationStepperScreenverticalLine: {
    backgroundColor: colors.lightPurple2,
    width: 2,
    height: '95%',
    position: 'absolute',
    marginLeft: 40,
    marginTop: 10,
  },
  registrationStepperScreenverticalWrap: {
    justifyContent: 'space-between',
    height: '100%',
  },
  registrationStepperScreenitemWrap: {
    width: '100%',
    height: 40,
    marginLeft: 20,
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  registrationStepperScreenpointWrap: {
    height: 30,
    width: 30,
    marginLeft: 5,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  registrationStepperScreencompletedPoint: {
    backgroundColor: colors.purple,
  },
  registrationStepperScreenpoint: {
    backgroundColor: colors.lightPurple2,
  },
  registrationStepperScreenmarkerText: {
    color: 'white',
  },

  uploadProfileButtonStyle: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  // uploadProfileButtonTextStyle: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14,
  //     color: colors.grey,
  // },
  // uploadProfileIconsStyle: {
  //     height: 25, width: 25, marginRight: 10, tintColor: colors.grey
  // },
  // uploadProfileCameraIconStyle: {
  //     height: 25, width: 25, tintColor:colors.lightPurple3
  // },
  uploadProfileSurface: {
    justifyContent: 'center',
    height: 45,
    borderRadius: 6,
    marginVertical: 10,
    elevation: 4,
  },
  uploadProfileScreenImageBox: {
    backgroundColor: colors.lightPurple,
    marginVertical: 20,
    alignSelf: 'center',
    borderRadius: 5,
    height: 146,
    width: 146,
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadProfileScreenProfileImage: {
    height: 146,
    width: 146,
    borderColor: 'black',
    borderWidth: 1,
    marginHorizontal: 3,
    borderRadius: 6,
  },
  uploadProfileScreenNotNowButtonTextStyle: {
    fontFamily: fontFamily.fontSemiBold,
    fontSize: 14,
    color: colors.purple,
    paddingVertical: 25,
    alignSelf: 'center',
  },
  // typeOfConsultationsScreenSurface: {
  //     justifyContent: "center",
  //     // height: 45,
  //     borderRadius: 6,
  //     marginVertical: 10,
  //     elevation: 4,
  //     paddingVertical: 10
  // },
  // typeOfConsultationsIconsStyle: {
  //     height: 20, width: 20, marginRight: 10, tintColor: colors.grey
  // },
  typesOfConsultationsModalTopNotch: {
    marginBottom: 20,
    backgroundColor: '#35405233',
    borderRadius: 10,
    alignItems: 'center',
    width: 100,
    height: 6,
  },
  typeOfConsultationsModalTittle: {
    alignSelf: 'flex-start',
    textTransform: 'capitalize',
  },
  typesOfConsultationsModalList: {
    paddingVertical: 15,
    borderBottomColor: '#35405233',
    borderBottomWidth: 0.5,
  },
  // typesOfConsultationsModalListTittleText: {
  //     fontSize: 14,
  //     color: colors.grey,
  //     fontFamily: fontFamily.fontRegular
  // },

  // typesOfConsultationsModalListMessage: {
  //     color: colors.grey,
  //     fontFamily: fontFamily.fontRegular
  // },
  awaitingApprovalScrenContainer: {
    flex: 1,
    justifyContent: 'space-between',

    backgroundColor: colors.white,
    // padding: 10,
    paddingVertical: 30,
    paddingHorizontal: 30,
  },

  // preferenceButtonTextStyle: {
  //     textAlign: "left",
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14,
  //     color: colors.grey,
  // },
  // preferencesScreenSurface: {
  //     justifyContent: "center",
  //     height: 68,
  //     borderRadius: 6,
  //     marginVertical: 10,
  //     paddingVertical: 10,
  //     elevation: 4,
  // },
  amountTextBoxStyle: {
    width: 120,

    borderRadius: 6,
    backgroundColor: colors.lightPurple,
    alignSelf: 'center',
    height: 45,
    paddingHorizontal: 10,
    marginHorizontal: 15,
    textAlign: 'right',
    borderWidth: 1,
    borderColor: colors.lightPurple,
  },
  // costPerconsultationScrenContainer: {
  //     flex: 1,
  //     backgroundColor: colors.white,
  //     // padding: 10,
  //     height: "100%",
  //     paddingVertical: 30,
  //     paddingHorizontal: 30
  // },
  preferedOnlineconsultationScrenContainer: {
    flex: 1,
    backgroundColor: colors.white,
    // padding: 10,
    justifyContent: 'space-between',
    paddingVertical: 30,
    paddingHorizontal: 30,
  },
  // availabilityTittle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 14,
  //     color: colors.grey,
  // },
  // availabilitiesScheduleList: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 12,
  //     color: colors.grey,
  // },
  // availabilitiesScheduleTiming: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 12,
  //     color: colors.grey,
  // },
  avaliabilitiesTypes: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  availabilitiesStartItems: {
    flex: 0.9,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  availabilitiesEndIcon: {
    flex: 0.1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  // modalNavigationBar: {
  //     flexDirection: "row",
  //     alignSelf: "flex-start"
  // },
  // modalNavigationBarIconFlex: {
  //     flex: 0.1,
  //     justifyContent: "center"
  // },
  // modalNavigationBarTittleFlex: {
  //     color: colors.purple,
  //     textAlign: "center",
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 18, flex: 0.8
  // },
  doctorOnDemandOptionItem: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: colors.lightPurple2,
    justifyContent: 'space-around',
    paddingVertical: 15,
  },
  // doctorOnDemandOptionItemText: {
  //     fontSize: 14,
  //     fontFamily: fontFamily.fontRegular,
  //     color: colors.grey
  // },
  doctorOnDemandOptionItemTimeBox: {
    backgroundColor: colors.lightPurple,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    height: 42,
    width: 146,
  },
  doctorOnDemandOptionItemTime: {
    fontSize: 24,
    color: colors.grey,
    fontFamily: fontFamily.fontSemiBold,
  },

  dateSelectedTheme: {
    borderWidth: 1,
    borderColor: colors.info,
    width: '100%',
    borderRadius: 6,
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingHorizontal: 10,
    marginTop: 5,
    height: 45,
    justifyContent: 'center',
  },
  timeSelectedTheme: {
    borderWidth: 1,
    borderColor: colors.info,
    width: '100%',
    borderRadius: 6,
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingHorizontal: 10,
    marginTop: 8,
    height: 45,
    justifyContent: 'center',
  },
  selectTimeTextBox: {
    borderColor: colors.lightPurple,
    width: '100%',
    borderRadius: 6,
    backgroundColor: colors.lightPurple,
    alignSelf: 'center',
    paddingHorizontal: 10,
    marginTop: 0,
    height: 45,
    justifyContent: 'center',
  },
  startAndEndTimeRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingVertical: 10,
  },

  // appointmentSlotStyle: {
  //     // flexDirection:"row",
  //     borderColor: colors.lightPurple,
  //     // width: "100%",
  //     borderRadius: 6,
  //     backgroundColor: colors.lightPurple,
  //     alignSelf: "center",
  //     paddingHorizontal: 10,
  //     marginTop: 10,
  //     // height: 45,
  //     // justifyContent: "center"
  // },

  appointmentTimeDurationText: {
    fontFamily: fontFamily.fontRegular,
    fontSize: 14,
    color: colors.grey,
  },
  // appointmentTimeDurationButton: {
  //     paddingHorizontal: 20,
  //     paddingVertical: 10
  // },

  // selectedAppointmentTimeDurationButton: {
  //     borderWidth: 2, borderRadius: 6, borderColor: colors.info, backgroundColor: colors.lightPurple2
  // },
  // repeatModeAvailabilityText: {
  //     fontFamily: fontFamily.fontBold,
  //     fontSize: 14,
  //     color: colors.grey,
  // },

  repeatDaysButtonMobileStyle: {
    backgroundColor: colors.lightPurple,
    height: 42,
    width: 42,
    borderRadius: 42,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 3,
    marginTop: 8,
    // borderWidth:2,borderColor:colors.info
  },
  repeatDaysButtonStyle: {
    backgroundColor: colors.lightPurple,
    height: 52,
    width: 52,
    borderRadius: 52,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 3,
    marginTop: 8,
    // borderWidth:2,borderColor:colors.info
  },

  selectedRepeatDaysButtonStyle: {
    borderWidth: 2,
    borderColor: colors.info,
    backgroundColor: colors.lightPurple2,
  },
  addBiographyRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
  },
  // hintTextStyle: {
  //     color: colors.purpleGrey,
  //     fontSize: 10,
  //     fontFamily: fontFamily.fontRegular
  // },
  // drVerificationContentText: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 12,
  //     color: colors.grey,
  // },

  // drVerificationScrenContainer: {
  //     flex: 1,
  //     justifyContent: "space-between",

  //     backgroundColor: colors.white,
  //     // padding: 10,
  //     paddingVertical: 30,
  //     // paddingHorizontal: 30
  // },

  commonButtonStyle: {
    backgroundColor: colors.purple,
    // paddingHorizontal:57,
    width: '80%',
    paddingVertical: 9,
    borderRadius: 5,
    alignSelf: 'center',
    // marginTop:70
  },

  borderLine: {
    backgroundColor: colors.lightPurple2,
    height: 2,
    // borderTopColor: colors.lightPurple2,
    // borderBottomColor: colors.lightPurple2,
    // borderTopWidth: 2,
    // borderBottomWidth: 2,
    // paddingBottom: 20,
    // paddingHorizontal: 30
  },
  // showMoreAndShowLessFontStyle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 14,
  //     color: colors.info,
  //     // paddingVertical: 25,
  //     // alignSelf: "center"
  // },

  uploadedDocumentNameStyle: {
    fontSize: 12,
    color: colors.info,
    fontFamily: fontFamily.fontRegular,
    // marginBottom: 10
    paddingRight: 10,
    backgroundColor: colors.white,
  },
  uploadedDocumentCloseButtonStyle: {
    paddingLeft: 10,
    alignItems: 'center',
    // padding: 5, top: 0, right: 0
  },
  uploadedDocumentNameView: {
    //    justifyContent:"center",
    alignItems: 'center',
    marginVertical: 3,
    flexDirection: 'row',
  },
  uploadDocumentNameLine: {
    width: '100%',
    position: 'absolute',
    height: 2,
    zIndex: -1,
    marginTop: 8,
    backgroundColor: colors.info,
  },
  // chooseLocationTextStyle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 14,
  //     // marginHorizontal:20,
  //     // textAlign:"center",
  //     color: colors.grey,
  // },
  // distanceTextStyle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 14,
  //     color: colors.purple,
  //     textAlign: "center"
  // },

  // calender page styles
  // calenderPageContainer: {
  //     flex: 1,
  //     paddingHorizontal: 18,
  //     paddingVertical: 18,
  //     backgroundColor: colors.white
  // },
  // calenderScreenHeaderTextStyle: {
  //     fontSize: 24,
  //     fontFamily: fontFamily.fontSemiBold
  // },
  calenderHeaderRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  //
  // repeatDaysButtonStyle: {
  //     backgroundColor: colors.lightPurple,
  //     height: 50, width: 50,
  //     borderRadius: 50,
  //     justifyContent: "center", alignItems: "center",
  //     marginRight: 8,
  //     marginTop: 12
  //     // borderWidth:2,borderColor:colors.info
  // },
  // monthTextStyle: {
  //     color: colors.purple, fontSize: 14, fontFamily: fontFamily.fontRegular, marginHorizontal: 10
  // },

  // AppointmantListPageContainer: {
  //     flex: 1,
  //     backgroundColor: colors.white
  // },
  calendarPageDropDownStyle: {
    flexDirection: 'row',
    // borderColor: colors.lightPurple,
    // width: "100%",
    // borderRadius: 6,
    // backgroundColor: colors.lightPurple,
    alignItems: 'center',
    // paddingHorizontal: 10,
    // marginBottom:20
  },
  // selectedCalendarTypeTextStyle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 18,
  //     color: colors.purple,
  //     marginRight: 10
  // },
  calendarDropDownOptionsStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 3,
  },
  // calendarDropDownOptionsTextStyle: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14,
  //     color: colors.purple
  // },
  calenderPageDropDownOptions: {
    borderRadius: 5,

    backgroundColor: colors.lightPurple,
  },
  // dayNameStyle: {
  //     fontSize: 14, fontFamily: fontFamily.fontSemiBold
  // },
  // navigationBarTittle: {
  //     fontSize: 18,
  //     fontFamily: fontFamily.fontSemiBold,
  //     color: colors.purple,
  // },
  singleLineTextWithLabelRow: {
    flexDirection: 'row',
    marginVertical: 4,
  },
  singleLineTextWithLabelColumn: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginVertical: 4,
  },
  // popupLabelStyle: {
  //     flex: 0.4,
  //     fontSize: 12,
  //     fontFamily: fontFamily.fontRegular,
  //     color: colors.purpleGrey
  // }, ////////////////////////////////////////////////////////////////////////////// done
  // popupValueStyle: {
  //     flex: 0.6,
  //     fontSize: 12,
  //     fontFamily: fontFamily.fontRegular,
  //     color: colors.grey
  // },
  // startNavigationMessageTextStyle: {
  //     fontSize: 14,
  //     fontFamily: fontFamily.fontRegular,
  //     color: colors.grey
  // },

  //DASH BOARD STYLES START

  doctorImageContainer: {
    flex: 0.2,
  },
  doctorNameAndRegNoContainer: {
    flex: 0.7,
  },
  doctorDashBoardNotifictaionContainer: {
    flex: 0.1,
  },
  doctorDashboardImageStyle: {
    height: 50,
    width: 50,
    borderRadius: 6,
    borderWidth: 0.5,
    borderColor: colors.lightPurple2,
  },
  // dashBoardDoctorName: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 24,
  //     color: colors.black
  // },
  // dashBoardMedicalRedNoTextStyle: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14,
  //     color: colors.grey
  // },
  dashboardTodaysAppointmentHeader: {
    // marginVertical: 10,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // dashboardTodaysAppointmentTextStyle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 18,
  //     color: colors.black
  // },
  // dashboardViewAlltTextStyle: {
  //     fontFamily: fontFamily.fontSemiBold,
  //     fontSize: 14,
  //     color: colors.purple
  // },
  //DASH BOARD STYLES START

  noAppointmensStyle: {
    marginTop: 20,
  },

  //VIDEO CALL
  doctorViewProfileImageContainer: {
    flex: 0.35,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  doctorViewProfileImage: {
    width: '90%',
    height: '100%',
    borderRadius: 6,
    borderWidth: 0.5,
    borderColor: colors.lightPurple2,
  },
  twoSepVerificationContainer: {
    flex: 1,
    justifyContent: 'space-between',

    backgroundColor: colors.white,
    // padding: 10,
    paddingVertical: 30,
    paddingHorizontal: 30,
  },
  selectedSymptomsItem: {
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.lightTurquoise,
    paddingVertical: 8,
    paddingHorizontal: 10,
    marginBottom: 10,
    marginRight: 7,
  },
  // selectedSymptomsItemText: {
  //     color: colors.grey,
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14, marginRight: 8
  // },

  //CHAT SCREEN STYLES START
  // chatScreenContainer: {
  //     paddingHorizontal: 20,
  //     justifyContent: "space-between",
  //     backgroundColor: colors.white, flex: 1
  // },
  // chatScreenNavBarTittle: {
  //     textAlign: "center",
  //     color: colors.purple,
  //     fontSize: 18,
  //     fontFamily: fontFamily.fontSemiBold
  // },
  chatScreenNavBarButtons: {
    width: 35,
    height: 35,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: colors.white,
    borderColor: '#ddd',
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 3,
  },
  chatScreenChatHeader: {
    marginVertical: 20,
    padding: 10,
    borderTopWidth: 1,
    borderColor: colors.lightPurple2,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // chatScreenChatHeaderDrName: {
  //     marginLeft: 10, textAlign: "center",
  //     color: colors.black, fontSize: 12, fontFamily: fontFamily.fontRegular
  // },
  // chatScreenChatHeaderDurationText: {
  //     textAlign: "center",
  //     color: colors.black, fontSize: 12, fontFamily: fontFamily.fontSemiBold
  // },
  chatScreenInputBox: {
    backgroundColor: colors.lightPurple,
    borderRadius: 6,
    overflow: 'hidden',
    height: 45,
    flexDirection: 'row',
    marginRight: 5,
    paddingRight: 10,
    flex: 1,
  },
  chatScreenSendButton: {
    width: 45,
    height: 45,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: colors.purple,
  },
  chatScreenTextBox: {
    padding: 10,
    borderRadius: 10,
    marginBottom: 5,
    marginTop: 10,
  },
  chatScreenSendTextBox: {
    backgroundColor: colors.purple,
    borderBottomRightRadius: 0,
  },
  chatScreenRecievedTextBox: {
    backgroundColor: colors.lightPurple,
    borderBottomLeftRadius: 0,
  },
  // chatScreenTextBoxText: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 12,
  //     color: colors.grey
  // },
  chatScreenAttachmentBox: {
    marginBottom: 5,
    height: deviceWidth / 4,
    width: deviceWidth / 2.5,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
  },
  chatScreenUserImgContainer: {
    width: 40,
    alignSelf: 'flex-end',
    marginBottom: 20,
    margin: 10,
  },
  chatScreenUserImg: {
    width: 40,
    height: 40,
    borderRadius: 6,
    borderWidth: 0.5,
    borderColor: colors.lightPurple2,
  },
  // chatScreenChatDateTime: {
  //     fontSize: 10,
  //     fontFamily: fontFamily.fontRegular, color: colors.purpleGrey
  // },
  //CHAT SCREEN STYLES END

  // Booking details confirmation screen design styles

  // bookingDetailsViewContainer: {
  //     flex: 1,
  //     justifyContent: "space-between",
  //     backgroundColor: colors.white,
  //     paddingVertical: 30,
  //     paddingHorizontal: 30
  // },
  bookingDetailsDoctorPatientCard: {
    height: 100,
    elevation: 0,
    marginVertical: 10,
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  // bookingDetailsDoctorPatientName: {
  //     fontFamily: fontFamily.fontBold,
  //     fontSize: 14,
  //     color: colors.black,
  //     marginBottom: 5
  // },
  // bookingDetailsDoctorPatientSubText: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 12,
  //     color: colors.grey
  // },
  bookingDetailsProfileImageContainer: {
    flex: 0.3,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  bookingDetailsProfileImage: {
    // width: "60%",
    // height: "70%",
    width: 70,
    height: 70,
    borderRadius: 6,
    borderWidth: 0.4,
    borderColor: colors.lightPurple2,
  },
  bookingDetailsDoctorPatientDetailsCol: {
    flex: 0.7,
    justifyContent: 'center',
  },

  bookingDetailsRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 10,
    flex: 1,
    paddingVertical: 10,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: colors.lightPurple2,
  },
  bookingDetailsRowCol: {
    marginVertical: 10,
    // width: "50%"
  },

  // bookingDetailsValueText: {
  //     fontFamily: fontFamily.fontBold,
  //     color: colors.grey,
  //     fontSize: 14
  // },
  // bookingDetailsLabelText: {
  //     fontFamily: fontFamily.fontRegular,
  //     color: colors.grey,
  //     fontSize: 14
  // },
  // bookingDetailsSubTittleText: {
  //     fontSize: 14,
  //     color: colors.grey,
  //     fontFamily: fontFamily.fontBold,
  //     marginBottom: 10,
  // },
  // bookingDetailsParagraghText: {
  //     fontSize: 12,
  //     color: colors.grey,
  //     fontFamily: fontFamily.fontRegular,
  //     marginBottom: 10,
  // },
  renderDoctorPatientCardStyle: {
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: colors.lightPurple2,
  },
  spinnerStyle: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: deviceWidth,
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)',
    zIndex: 9999,
  },
  // HEALTH PROFILE START
  // selectSymptomsTypeAhead: {
  //     borderRadius: 6,
  //     borderColor: colors.info,
  //     borderWidth: 1, position: "relative",
  //     width: "100%", backgroundColor: colors.lightPurple
  // },
  // selectSymptomsTypeAheadList: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14, color: colors.black,
  //     paddingHorizontal: 10, paddingVertical: 8
  // },
  selectedSymptomsView: {
    flexDirection: 'row',
    zIndex: -1,
    flexWrap: 'wrap',
  },
  selectedSymptomsItemHP: {
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.lightTurquoise,
    paddingVertical: 8,
    paddingHorizontal: 10,
    marginVertical: 5,
    marginRight: 7,
  },
  // selectedSymptomsItemTextHP: {
  //     color: colors.grey,
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14, marginRight: 8
  // },
  // selectSymptomsTypeAhead: {
  //     borderRadius: 6,
  //     borderColor: colors.info,
  //     borderWidth: 1, position: "relative",
  //     width: "100%", backgroundColor: colors.lightPurple
  // },
  // selectSymptomsTypeAheadList: {
  //     fontFamily: fontFamily.fontRegular,
  //     fontSize: 14, color: colors.black,
  //     paddingHorizontal: 10, paddingVertical: 8
  // },
  radiobuttonButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  radiobuttonStyle: {
    height: 10,
    width: 10,
    borderRadius: 40,
    backgroundColor: colors.purple,
  },
  radiobuttonBorderStyle: {
    height: 20,
    width: 20,
    borderRadius: 40,
    borderColor: colors.purple,
    padding: 5,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
  },
  // HEALTH PROFILE END
  dashBordNotificationIconCol: {
    maxWidth: 30,
    // alignSelf: "flex-start"
  },
  dashBordBadge: {
    borderRadius: 10,
    height: 10,
    width: 10,
    position: 'absolute',
    top: 0,
    right: 1,
    backgroundColor: colors.danger,
  },
});
