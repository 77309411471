import React, { useImperativeHandle } from 'react';
import { InputModeOptions, StyleProp, StyleSheet, TextInput, TouchableOpacity, ViewStyle } from 'react-native';

import { RowView } from './commonViews';
import { Search } from './svgImages';
import { colors } from '../utils/colors';

interface SearchProps {
  noIcon?: boolean;
  autoFocus?: boolean;
  value?: string;
  placeholder?: string;
  onTextChange: CallableFunction;
  bgColor?: string;
  onSubmit?: CallableFunction;
  onBlur?: CallableFunction;
  style?: StyleProp<ViewStyle>;
  keyboardType?: InputModeOptions;
}
export type SearchBoxRef = { focus: CallableFunction };

export const SearchBox = React.forwardRef(
  (
    {
      onSubmit,
      value,
      placeholder,
      noIcon,
      autoFocus,
      bgColor,
      onTextChange,
      onBlur,
      style,
      keyboardType = 'search',
    }: SearchProps,
    ref?
  ) => {
    let input: TextInput;
    useImperativeHandle(ref, () => ({
      focus: () => {
        input.focus();
      },
    }));

    return (
      <RowView style={[styles.searchBoxView, style]}>
        <TextInput
          value={value ?? ''}
          onChangeText={(txt) => {
            onTextChange(txt);
          }}
          style={[
            styles.searchTextBoxStyle,
            noIcon ? { borderRadius: 6 } : null,
            bgColor ? { backgroundColor: bgColor } : null,
            noIcon ? { paddingRight: 10 } : null,
          ]}
          placeholder={placeholder}
          autoComplete="off"
          autoCorrect={false}
          ref={(e) => {
            if (e) input = e;
          }}
          onSubmitEditing={() => {
            if (onSubmit) onSubmit();
          }}
          placeholderTextColor={colors.purpleGrey}
          inputMode={keyboardType}
          onBlur={() => {
            if (onBlur) onBlur();
          }}
          autoFocus={autoFocus}
        />
        {!noIcon ? (
          <TouchableOpacity
            onPress={() => {
              onSubmit && onSubmit();
            }}
            style={[styles.searchIconViewStyle, bgColor ? { backgroundColor: bgColor } : null]}>
            <Search height={18} width={18} color={colors.purple} />
          </TouchableOpacity>
        ) : null}
      </RowView>
    );
  }
);

const styles = StyleSheet.create({
  searchBoxView: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
  },
  searchTextBoxStyle: {
    flex: 1,
    height: 45,
    paddingLeft: 10,
    backgroundColor: colors.lightPurple,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    width: '100%',
    color: colors.purpleGrey,
    marginLeft: 2,
    zIndex: 1,
  },
  searchIconViewStyle: {
    backgroundColor: colors.lightPurple,
    height: 45,
    paddingHorizontal: 20,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
