import { useEffect } from 'react';

import { ConsultationCallEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { channelSubscribe, channelUnsubscribe } from '~/integrations/channels/PusherChannels';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';

export const useConsultationCall = (consultationId: number) => {
  const { showSnackbar } = useSnackbarManager();

  const handleNotification = (
    eventName: string,
    data: { consultationEndingData: { minutes_remaining: number; consultation_id: number } }
  ) => {
    switch (eventName) {
      case ConsultationCallEventsEnum.CONSULTATION_CALL_CONSULTATION_ENDING:
        showSnackbar(`Your consultation is ending in ${data?.consultationEndingData.minutes_remaining} minutes`, {
          keyRef: `${eventName}.${data.consultationEndingData.consultation_id}.${data.consultationEndingData.minutes_remaining}`,
        });
        break;
    }
  };

  useEffect(() => {
    if (!consultationId) return;

    channelSubscribe(`private-consultation-call.${consultationId}`, handleNotification);
    return () => {
      channelUnsubscribe(`private-consultation-call.${consultationId}`, handleNotification);
    };
  }, [consultationId]);
};
