import { useNavigation } from '@react-navigation/native';

import { apiUseRole } from '~/api/services/authorisation';
import { apiAddRole } from '~/api/services/roles';
import { ConfirmationAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { NavType } from '~/navigation/types';
import { appUserRole, isDoctorVersion, whenAppType } from '~/utils/buildConfig';
import { useLoadReferrer } from '~/utils/hooks/StoreReferrerHook';
import { screenName } from '~/utils/screenName';

export function useSiteRole() {
  const { getReferrer } = useLoadReferrer();

  const { navigate } = useNavigation<NavType>();
  const applySecondRole = async () => {
    await new Promise((resolve, reject) => {
      ConfirmationAlert(
        [
          `Your account is not able to use the Digimed ${
            !isDoctorVersion() ? 'Patient' : 'Doctor'
          } portal. You will first need to apply for your new role.`,
          'Do you want to continue with the registration for this portal?',
        ],
        {
          title: 'Missing role',
          okTitle: 'Register',
          cancelTitle: 'Back',
          okFunction: async () => {
            const referral_link = await getReferrer();
            apiAddRole({ role: appUserRole(), referral_link }).then(resolve).catch(reject);
          },
          cancelFunction: () => {
            navigate(screenName.LoginScreen);
          },
        }
      );
    });

    await apiUseRole({ role: appUserRole() });
  };

  const execute = async () => {
    try {
      await apiUseRole({ role: appUserRole() });
    } catch (e) {
      if (e.status === 403) {
        if (whenAppType({ whenDoctor: true, whenPatient: true, else: false })) {
          await applySecondRole();
        } else {
          ErrorAlert(
            whenAppType({
              whenClinic: 'The account you are using does not have access to the Digimed Clinics Application',
              whenPharmacist: 'The account you are using does not have access to the Digimed Pharmacies Application'
            }),
            'You do not have the necessary access'
          );

          navigate(screenName.LoginScreen);
        }
      } else {
        throw e;
      }
    }
  };

  return { execute };
}
