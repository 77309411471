import { colors } from '~/utils/colors';

export enum DependantStateEnum {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  Ended = 'ended',
}

export const DependantStateColorMapping = {
  [DependantStateEnum.Pending]: colors.warning,
  [DependantStateEnum.Approved]: colors.success,
  [DependantStateEnum.Rejected]: colors.danger,
  [DependantStateEnum.Ended]: colors.lightGrey,
};
