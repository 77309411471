import Feather from '@expo/vector-icons/Feather';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { CustomDateStyle, DateChangedCallback } from 'react-native-calendar-picker';
import CalendarStrip from 'react-native-calendar-strip';
import { Menu } from 'react-native-paper';

import { CalendarPickerInput } from './CalendarPickerInput';

import { mh5, mt10 } from '~/common/commonStyles';
import CalendarDay from '~/components/calendar/mobile/CalendarDay';
import { SmallNsRegularTheme } from '~/components/commonText';
import { CalendarIcon, DownArrow } from '~/components/svgImages';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, NUM_DATE_FORMAT } from '~/utils/dateAndTime';

export interface MarkedDate {
  date: string | Date | Moment;
  dots?: { color: string; selectedColor?: string }[];
  lines?: { color: string; selectedColor?: string }[];
}

export interface CalendarStripInputProps {
  onChange: (date: string) => void;
  onMonthChanged?: DateChangedCallback;
  onWeekChanged?: (start: Moment, end: Moment) => void;
  value: string;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  markedDates?: string[];
}

export const CalendarStripInput: React.FC<CalendarStripInputProps> = ({
  onChange,
  onWeekChanged,
  onMonthChanged,
  value,
  minDate,
  maxDate,
  dateFormat,
  markedDates,
}) => {
  const [localValue, setLocalValue] = useState<Moment>();
  const [monthSelectionVisible, setMonthSelectionVisible] = useState(false);
  const stripRef = useRef<CalendarStrip>();

  const onChangeCalendarDate = (momentDate: Moment) => {
    onChangeDate(momentDate);
    stripRef.current.setSelectedDate(momentDate);
    stripRef.current.updateWeekView(momentDate);
    setMonthSelectionVisible(false);
  };

  const onChangeDate = (momentDate: Moment) => {
    setLocalValue(momentDate);

    onChange(momentDate.format(dateFormat || DATE_FORMAT));
  };

  useEffect(() => {
    const date = value && moment(value, dateFormat);
    if (date) {
      setLocalValue(date);
      stripRef.current.setSelectedDate(date);
      stripRef.current.updateWeekView(date);
    }
  }, [value]);

  const calendarDateStyles = useMemo(
    () =>
      markedDates?.map<CustomDateStyle>((date) => ({
        date,
        textStyle: {
          fontWeight: '600',
        },
      })) ?? undefined,
    [markedDates]
  );

  const disabledDates = markedDates
    ? (date: Moment) => {
        return !markedDates.includes(date.format(NUM_DATE_FORMAT));
      }
    : undefined;

  const handleWeekChanged = (start: Moment, end: Moment) => {
    if (!monthSelectionVisible) onWeekChanged(start, end);
  };

  const formattedMarkedDates = useMemo<MarkedDate[]>(
    () =>
      markedDates?.map((date) => ({
        date,
        dots: [
          {
            color: colors.info,
          },
        ],
      })) ?? [],
    [markedDates]
  );
  return (
    <View>
      <TouchableOpacity onPress={() => setMonthSelectionVisible(!monthSelectionVisible)}>
        <View>
          <Menu
            visible={monthSelectionVisible}
            onDismiss={() => setMonthSelectionVisible(false)}
            anchor={
              <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <CalendarIcon height={15} width={15} />
                <SmallNsRegularTheme style={[mh5]}>{localValue?.format('MMMM yyyy')}</SmallNsRegularTheme>
                <DownArrow height={10} width={10} color={colors.purple} />
              </View>
            }>
            <CalendarPickerInput
              minDate={new Date()}
              onChangeMoment={onChangeCalendarDate}
              onChangeMonth={onMonthChanged}
              value={localValue?.toISOString()}
              customDatesStyles={calendarDateStyles}
              disabledDates={disabledDates}
            />
          </Menu>
        </View>
      </TouchableOpacity>

      <CalendarStrip
        ref={stripRef}
        dayComponent={(props) => <CalendarDay {...props} />}
        calendarHeaderStyle={{ display: 'none' }}
        dayComponentHeight={80}
        selectedDate={localValue}
        style={[mt10, { backgroundColor: colors.white, flexBasis: 100 }]}
        onDateSelected={(date) => {
          onChangeDate(date);
        }}
        leftSelector={<Feather name="chevron-left" size={20} color={colors.purple} />}
        rightSelector={<Feather name="chevron-right" size={20} color={colors.purple} />}
        minDate={minDate}
        maxDate={maxDate}
        scrollable={false}
        scrollToOnSetSelectedDate={false}
        onWeekChanged={onWeekChanged ? handleWeekChanged : undefined}
        markedDates={formattedMarkedDates}
        datesWhitelist={(date: Moment) => {
          return (!minDate || date.isSameOrAfter(minDate, 'day')) && (!maxDate || date.isSameOrBefore(maxDate, 'day'));
        }}
      />
    </View>
  );
};
