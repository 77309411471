export const calendarLabels = {
  title: 'Calendar',
  imAvailable: "I'm available now",
  unavailable: 'Unavailable',
  addToMyCalendar: 'ADD TO MY CALENDAR',
  noBookedAppointments: 'No booked appointments',
  noBookedAppointmentsOnDatePassed: 'You had no appointments $date',
  noBookedAppointmentsOnDateFuture: 'You have no appointments $date',
  loadingAppointments: 'Getting your appointments',
};
