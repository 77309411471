import * as React from 'react';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { AuthorisedStack } from './authorised';
import { useAuthDeepLink } from './hooks/useAuthDeepLink';
import { OnboardingStack } from './onboarding';
import { RegistrationStack } from './registration';
import { VerificationPendingStack } from './verificationPending';

import { useNavigationGuardHook } from '~/api/hooks/navigation/NavigationGuardHook';
import { useVerificationStatusCheck } from '~/api/hooks/navigation/VerificationStatusCheckHook';
import { useGlobalNotificationHandling } from '~/api/hooks/notifications/GlobalNotificationHandling';
import { safeAreaStyle } from '~/common/commonStyles';
import { NavStacks } from '~/constants/NavStacksEnum';
import { LayoutProvider } from '~/contexts/LayoutProvider';
import { LayoutEnums } from '~/layouts/LayoutEnums';
import { AuthorisedStackErrorBoundary } from '~/pages/ErrorBoundary/AuthorisedStackErrorBoundary';
import { RegistrationStackErrorBoundary } from '~/pages/ErrorBoundary/RegistrationStackErrorBoundary';
import { SET_FULL_PAGE_LOADING } from '~/redux/reducers/overlayReducer';
import { useAppDispatch } from '~/redux/store';
import { isWeb } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';

const NavigationComponent: React.FC = () => {
  const navigationGuard = useNavigationGuardHook();
  useVerificationStatusCheck();
  useGlobalNotificationHandling();
  const { deepLink } = useAuthDeepLink();
  const dispatch = useAppDispatch();

  const { isMobile } = useBreakpoints();
  const currentStack = React.useMemo(() => {
    if (navigationGuard.loading && navigationGuard.stack !== NavStacks.Registration) return null;
    switch (navigationGuard.stack) {
      case NavStacks.Registration:
        return (
          <SafeAreaView style={safeAreaStyle} edges={isMobile ? ['top', 'left', 'right'] : undefined}>
            <RegistrationStackErrorBoundary>
              <RegistrationStack />
            </RegistrationStackErrorBoundary>
          </SafeAreaView>
        );
      case NavStacks.Authorised:
        return (
          <SafeAreaView style={safeAreaStyle} edges={isMobile ? ['top', 'left', 'right'] : undefined}>
            <AuthorisedStackErrorBoundary>
              <AuthorisedStack initialRouteName={deepLink} />
            </AuthorisedStackErrorBoundary>
          </SafeAreaView>
        );
      case NavStacks.Pending:
        return <VerificationPendingStack />;
      case NavStacks.Onboarding:
        return <OnboardingStack />;

      default:
        return null;
    }
  }, [navigationGuard.loading, navigationGuard.stack]);

  const layout = React.useMemo(() => {
    switch (navigationGuard.stack) {
      case NavStacks.Registration:
        return isWeb() ? LayoutEnums.ON_BOARDING : LayoutEnums.BLANK;
      case NavStacks.Authorised:
      case NavStacks.Pending:
      default:
        return LayoutEnums.DEFAULT;
    }
  }, [navigationGuard.loading, navigationGuard.stack]);

  React.useEffect(() => {
    dispatch(SET_FULL_PAGE_LOADING(navigationGuard.loading || !currentStack));
  }, [navigationGuard.loading, currentStack]);

  if ((navigationGuard.loading && navigationGuard.stack !== NavStacks.Registration) || !currentStack) {
    return null;
  } else {
    return (
      <View style={{ position: 'relative', display: 'flex', height: '100%' }}>
        <LayoutProvider layout={layout}>{currentStack}</LayoutProvider>
      </View>
    );
  }
};

export const MainNavigation = () => {
  const nav = React.useMemo(() => <NavigationComponent />, []);

  return nav;
};
