import moment from 'moment';
import { useForm } from 'react-hook-form';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiRescheduleAppointment } from '~/api/services/appointments';
import { TIME_FORMAT, LARAVEL_ATOM } from '~/utils/dateAndTime';

export function useAppointmentReschedule() {
  const { control, handleSubmit, getValues } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      time: '',
    },
  });

  const rescheduleAppointment = async (consultation: ConsultationModel) => {
    const startTime = moment(consultation.start_at);
    const time = getValues().time;
    const chosenTime = moment(time, TIME_FORMAT);
    const difference = chosenTime.diff(startTime, 'minutes');
    const endTime = moment(consultation.end_at).add(Math.abs(difference), 'minutes');

    await apiRescheduleAppointment({
      id: consultation.id,
      params: {
        datetime_from: chosenTime.format(LARAVEL_ATOM),
        datetime_to: moment(endTime).format(LARAVEL_ATOM),
      },
    });
  };

  return {
    control,
    handleSubmit,
    submitRescheduleAppointment: rescheduleAppointment,
  };
}
