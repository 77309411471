import React from 'react';
import { View } from 'react-native';

import MobileFooterMenu from '../components/navigation/MobileFooterMenu';

import { useVerificationStatus } from '~/api/hooks/accounts/VerificationStatus';
import { flex1 } from '~/common/commonStyles';

interface Props {
  children: React.ReactNode;
}
export const DashboardMobileLayout: React.FC<Props> = ({ children }) => {
  const { approved } = useVerificationStatus();
  return (
    <View style={flex1}>
      <View style={flex1}>{children}</View>
      {approved ? <MobileFooterMenu /> : null}
    </View>
  );
};
