import React from 'react';
import { View } from 'react-native';

import { RequestConsultationDetails } from '../RequestConsultationDetails';

import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { alignSelfCenter, mb10, mb20, mb5, mt20 } from '~/common/commonStyles';
import { H3TtmSemiBoldBlack, H4TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { requiredValidation } from '~/services/validationConfig';
import { bookingLabels } from '~/utils/labels/booking';
import { HomeVisitAvailableSlots } from './HomeVisitAvailableSlots';
import { HomeVisitDepartureTimeSlots } from './HomeVisitDepartureTimeSlots';
import { HomeVisitAvailableSlot } from './model/homeAvailableSlot';
import { RequestApprovalForm } from '~/api/models/consultations/models/RequestApprovalForm';

interface Props {
  consultation: ConsultationModel;
  approved?: boolean;
  control: Control<RequestApprovalForm>;
  watch: UseFormWatch<RequestApprovalForm>;
}

export const HomeVisitApprovalRequestDetails: React.FC<Props> = ({ consultation, approved, control, watch }) => {
  return (
    <>
      <View style={[alignSelfCenter, mb20]}>
        <H3TtmSemiBoldBlack>Home Visit Request</H3TtmSemiBoldBlack>
      </View>
      <RequestConsultationDetails consultation={consultation} />
      {approved ? (
        <>
          <H4TtmSemiBoldBlack style={[mb5, mt20]}>
            {bookingLabels.homeVisit.expectedDepartureTimeTitle}
          </H4TtmSemiBoldBlack>
          <H6NsRegularBlack style={mb10}>
            {`${bookingLabels.homeVisit.expectedDepartureTimeDesc} ${watch('availabilitySlot.time_from')}.`}
          </H6NsRegularBlack>
          <Controller
            name="expectedDeparture"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
              <HomeVisitDepartureTimeSlots
                errorMessage={isSubmitted && error?.message}
                selected={value}
                onChange={onChange}
                appointment={watch('availabilitySlot')}
              />
            )}
            rules={{
              required: requiredValidation('Choose departure time'),
            }}
          />
        </>
      ) : (
        <Controller
          control={control}
          name="availabilitySlot"
          render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
            <HomeVisitAvailableSlots
              errorMessage={isSubmitted && error?.message}
              selected={value}
              onChange={onChange}
              consultationId={consultation.id}
            />
          )}
          rules={{
            required: requiredValidation('Time slot'),
          }}
        />
      )}
    </>
  );
};
