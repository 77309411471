import React, { useEffect, useState } from 'react';

import { CircleOptions } from '../interfaces/Props';
import { useMapViewContext } from '../mapView';

export const Circle: React.FC<CircleOptions> = (options) => {
  const { map, maps } = useMapViewContext();

  const [circle, setCircle] = useState<google.maps.Circle>();

  const getOptions = (): google.maps.CircleOptions => ({
    ...options,
    map,
  });

  useEffect(() => {
    if (!maps || !map) return;

    const circle = new maps.Circle(getOptions());
    setCircle(circle);
  }, [maps, map]);

  useEffect(() => {
    if (circle) {
      circle.setOptions(getOptions());
    }

    if (options.isDragging) {
      circle.setMap(null);
    }
  }, [options]);

  return null;
};
