import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';

import { ConsultationTypeEnum, ConsultationTypeMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { colors } from '~/utils/colors';

interface Props {
  type: ConsultationTypeEnum;
  onPress?: () => void;
}

const ConsultationTypeColors = {
  [ConsultationTypeEnum.ON_DEMAND]: { text: colors.onDemandGreen, background: colors.onDemandGreenBackground },
  [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: { text: colors.purple, background: colors.lightPurple },
  [ConsultationTypeEnum.HOME_VISIT]: { text: colors.homeVisitGreen, background: colors.homeVisitGreenBackground },
};

export const ConsultationTypeBadge: React.FC<Props> = ({ type, onPress }) => {
  return (
    <Pressable onPress={onPress}>
      <View style={[styles.container, { backgroundColor: ConsultationTypeColors[type].background }]}>
        <Text style={[styles.text, { color: ConsultationTypeColors[type].text }]}>{ConsultationTypeMapping[type]}</Text>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 6,
  },
  text: {
    fontSize: 10,
    fontFamily: 'TitilliumWeb-SemiBold',
  },
});
