import { DocumentPickerAsset } from 'expo-document-picker';
import { Image, ImageContentFit } from 'expo-image';
import React, { useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, ViewStyle, StyleProp } from 'react-native';

import { DocumentNameWithX } from './DocumentNameWithX';
import { UploadDocumentTouchable } from './UploadDocumentTouchable';
import { flex1, flexRow, mb10, mt10, pv10 } from '../../common/commonStyles';
import { colors } from '../../utils/colors';
import { labels } from '../../utils/labels';
import { theme } from '../../utils/theme';
import { Button } from '../commonButton';
import { H6NsRegularSecondaryBlack, SmallNsRegularDisableColor } from '../commonText';
import { CardSurface } from '../commonViews';
import { MediaUploadStateComponent } from '../media/MediaUploadStateComponent';
import { UploadingStateEnum } from '../media/UploadStateEnum';
import { Upload } from '../svgImages';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { DefaultAllowedFormats, ImageFormats } from '~/constants/documentUploadsConstants';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface BaseProps {
  submit?: () => void;
  hidePreview?: boolean;
  hideState?: boolean;
  hideFileList?: boolean;
  disabled?: boolean;
  disabledUpload?: boolean;
  disabledComplete?: boolean;
  horizontal?: boolean;
  square?: boolean;
  imageUri?: string;
  icon?: React.ReactNode;
  uploadTitle?: string;
  resizeMode?: ImageContentFit | undefined;
  style?: StyleProp<ViewStyle>;
  error?: boolean;
  noFilesText?: string;
  uploadedFiles: (DocumentPickerAsset | MediaModel)[];
  allowedFormats?: string[];
  setUploadedFiles?: (documents: (DocumentPickerAsset | MediaModel)[]) => void;
  remove?: (document: DocumentPickerAsset | MediaModel) => Promise<void>;
}

interface FilePickerProps extends BaseProps {
  add?: (documentResult: DocumentPickerAsset) => Promise<void>;
  imagesOnly?: false;
}

interface ImagePickerProps extends BaseProps {
  imagesOnly: true;
}

type Props = FilePickerProps | ImagePickerProps;

export const UploadDocumentComponent: React.FC<Props> = (props) => {
  const {
    imageUri,
    error,
    hidePreview,
    hideState,
    hideFileList,
    disabled,
    disabledUpload,
    disabledComplete,
    horizontal,
    uploadTitle,
    icon,
    square,
    resizeMode = 'contain',
    submit,
    noFilesText,
    style,
  } = props;
  const [uploadState, setUploadState] = useState<UploadingStateEnum>(UploadingStateEnum.NONE);
  const [uploadBorderStyle, setUploadBorderStyle] = useState<ViewStyle | null>(null);
  const [stateTimeout, setStateTimeout] = useState<ReturnType<typeof setTimeout>>(null);
  const { isMobile } = useBreakpoints();

  const generateResultName = (result: DocumentPickerAsset) => {
    if (!result.name) {
      const pathParts = result.uri.split('/');
      const last = pathParts?.length ? pathParts[pathParts.length - 1] : 'File';
      return {
        ...result,
        name: last,
      };
    }

    return result;
  };

  const allowedFormats = useMemo(
    () => ('allowedFormats' in props ? props.allowedFormats : props.imagesOnly ? ImageFormats : DefaultAllowedFormats),
    [props.allowedFormats]
  );

  const onResult = async (results: DocumentPickerAsset[]) => {
    if (results?.length) {
      setUploadState(UploadingStateEnum.UPLOADING);

      if ('add' in props) {
        await props
          .add(results[0])
          .then(() => {
            setUploadState(UploadingStateEnum.COMPLETED);
          })
          .catch(() => {
            setUploadState(UploadingStateEnum.FAILED);
          });
      } else if (props.setUploadedFiles) {
        props.setUploadedFiles([...results.map(generateResultName), ...(props.uploadedFiles ?? [])]);

        setUploadState(UploadingStateEnum.COMPLETED);
      }
    }
  };

  const removeFile = (documentResult: DocumentPickerAsset | MediaModel) => {
    if (props.remove) {
      props.remove(documentResult);
    } else if (props.setUploadedFiles) {
      const fileIndex = props.uploadedFiles.findIndex((uploadedFile) => uploadedFile === documentResult);
      if (fileIndex > -1) {
        const files = [...props.uploadedFiles];
        files.splice(fileIndex, 1);
        props.setUploadedFiles(files);
      }
    }
  };

  const resetState = () => {
    if (stateTimeout) clearTimeout(stateTimeout);
    const timeout = setTimeout(() => {
      setUploadState(UploadingStateEnum.NONE);
    }, 3000);

    setStateTimeout(timeout);
  };

  useEffect(() => {
    switch (uploadState) {
      case UploadingStateEnum.COMPLETED:
        setUploadBorderStyle(styles.uploadContainerSuccess);
        resetState();
        break;
      case UploadingStateEnum.FAILED:
        setUploadBorderStyle(styles.uploadContainerFailed);
        resetState();
        break;
      default:
        setUploadBorderStyle(null);
        break;
    }
  }, [uploadState]);

  return (
    <View style={[{ width: '100%' }, horizontal && !isMobile ? { maxWidth: 650 } : null]}>
      <View style={[{ display: 'flex' }, horizontal && !isMobile ? flexRow : null]}>
        <View
          style={[
            {
              alignItems: square ? 'center' : null,
              paddingBottom: 10,
            },
            isMobile ? null : flex1,
          ]}>
          {hidePreview ? null : (
            <UploadDocumentTouchable disabled={disabled || disabledUpload} formats={allowedFormats} onResult={onResult}>
              <View
                style={[
                  styles.uploadContainer,
                  { marginBottom: 8 },
                  error ? { borderColor: colors.danger } : null,
                  uploadBorderStyle,
                  square ? styles.square : null,
                  style,
                  disabled ? styles.disabledOpacity : null,
                ]}>
                {imageUri ? (
                  <Image source={{ uri: imageUri }} style={{ width: '100%', height: '100%' }} contentFit={resizeMode} />
                ) : (
                  icon || <Upload height={35} width={35} color={colors.purple} />
                )}
              </View>
            </UploadDocumentTouchable>
          )}
          {hideState ? null : <MediaUploadStateComponent state={uploadState} />}
          {!hideFileList ? (
            props.uploadedFiles?.length ? (
              <View style={pv10}>
                {props.uploadedFiles.map((file, index) => {
                  const key = 'name' in file ? `${file.name}-${index}` : `${index}`;
                  return file ? (
                    <DocumentNameWithX
                      file={file}
                      key={key}
                      remove={() => {
                        removeFile(file);
                      }}
                    />
                  ) : null;
                })}
              </View>
            ) : noFilesText ? (
              <View>
                <SmallNsRegularDisableColor>{noFilesText}</SmallNsRegularDisableColor>
              </View>
            ) : null
          ) : null}
        </View>
        <View
          style={[
            horizontal && !isMobile ? { marginLeft: 10 } : null,
            { marginTop: 8 },
            disabled ? styles.disabledOpacity : null,
          ]}>
          <View style={mb10}>
            <CardSurface ph={0} mv={0}>
              <UploadDocumentTouchable
                disabled={disabled || disabledUpload}
                style={theme.uploadDocumentButtonStyle}
                formats={allowedFormats}
                onResult={onResult}>
                <Upload height={20} width={20} color={colors.purple} />
                <H6NsRegularSecondaryBlack style={{ marginLeft: 10 }}>
                  {uploadTitle || labels.uploadDocument}
                </H6NsRegularSecondaryBlack>
              </UploadDocumentTouchable>
            </CardSurface>
          </View>
        </View>
      </View>
      {submit ? (
        <Button
          label={labels.completeUpload}
          funCallback={() => submit()}
          disabled={disabled || disabledComplete}
          style={mt10}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  uploadContainer: {
    backgroundColor: colors.lightPurple,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    borderColor: colors.lightPurple,
    borderWidth: 1,
    height: 120,
    minWidth: 200,
    overflow: 'hidden',
    display: 'flex',
  },
  square: {
    height: 140,
    minWidth: 140,
    width: 140,
  },
  uploadContainerSuccess: {
    borderColor: colors.success,
  },
  uploadContainerFailed: {
    borderColor: colors.danger,
  },
  disabledOpacity: {
    opacity: 0.6,
  },
});
