import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { View } from 'react-native';

import { STATUS_COLOR_MAPPING } from './constants/StatusColorMapping';
import { DoctorsAppointmentItem } from './mobile/DoctorsAppointmentItem';
import { PatientsAppointmentItem } from './mobile/PatientsAppointmentItem';
import { Gradient } from '../../Gradient';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ConsultationIndexForm } from '~/api/models/consultations/requests/ConsultationsIndexRequest';
import { flex1, pv10 } from '~/common/commonStyles';
import { DataTable, DataTableColumns } from '~/components/common/DataTable/DataTable';
import { JoinConsultationButton } from '~/components/common/appointments/appointmentListing/components/JoinConsultationButton';
import { OutlineButton } from '~/components/commonButton';
import { H5TtmSemiBoldDisableColor, H6NsRegularBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { NavType } from '~/navigation/types';
import { useAppointmentListingContext } from '~/providers/appointment/AppointmentListingContext';
import { SET_RESCHEDULE_BOOKING } from '~/redux/reducers/patient/appointmentReducer';
import { useAppDispatch } from '~/redux/store';
import { isDoctorVersion, isPatientVersion } from '~/utils/buildConfig';
import { isPastConsultation, isRejectedConsultation } from '~/utils/consultationUtils';
import { TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';
import { screenName } from '~/utils/screenName';

export const AppointmentListingComponent: React.FC = () => {
  const { consultations, setPage, loading, filterControl } = useAppointmentListingContext();
  const { navigate } = useNavigation<NavType>();
  const dispatch = useAppDispatch();
  const { showDetails } = useShowAppointment();

  const { appliedSort } = useWatch<ConsultationIndexForm>({ control: filterControl });
  const { breakpoint, isMobile } = useBreakpoints();
  const tabletButton = breakpoint === 'md' || breakpoint === 'sm';
  const viewDetailsButton = (consultation: ConsultationModel, buttons?: React.ReactNode[]) => {
    const viewDetails = () => {
      showDetails({
        consultation,
        buttons,
      });
    };
    return (
      <OutlineButton
        style={{ minWidth: 150 }}
        funCallback={viewDetails}
        label={labels.viewDetails}
        outline={tabletButton}
      />
    );
  };

  const rescheduleButton = (consultation: ConsultationModel) => {
    return (
      <OutlineButton
        style={{ minWidth: 130 }}
        label={appointmentLabels.reschedule}
        funCallback={() => {
          dispatch(SET_RESCHEDULE_BOOKING(consultation));
          navigate(screenName.AppointmentsBooking);
        }}
      />
    );
  };

  const getActionByConsultationType = (consultation: ConsultationModel) => {
    if (consultation.state === ConsultationStateEnum.RequestSubmitted)
      return <H5TtmSemiBoldDisableColor>{labels.awaitingConfirmation.toUpperCase()}</H5TtmSemiBoldDisableColor>;

    if (isPastConsultation(consultation)) {
      if (isDoctorVersion() || consultation.state === ConsultationStateEnum.Submitted) {
        if (isRejectedConsultation(consultation)) return null;
        return viewDetailsButton(consultation);
      } else if (isPatientVersion()) {
        return rescheduleButton(consultation);
      }
    } else if (consultation.state === ConsultationStateEnum.Ended) {
      return viewDetailsButton(consultation);
    }

    switch (consultation.type) {
      case ConsultationTypeEnum.HOME_VISIT:
      case ConsultationTypeEnum.CLINIC:
        return viewDetailsButton(consultation);
      case ConsultationTypeEnum.SCHEDULED_APPOINTMENT:
      case ConsultationTypeEnum.ON_DEMAND:
        return <JoinConsultationButton style={{ minWidth: 150 }} consultation={consultation} />;
      default:
        return null;
    }
  };

  const statusLabel = (consultation: ConsultationModel) => {
    if (
      consultation.type === ConsultationTypeEnum.ON_DEMAND &&
      consultation.state === ConsultationStateEnum.Scheduled
    ) {
      return 'Waiting to join';
    }
    return STATUS_COLOR_MAPPING[consultation.state]?.label;
  };

  const columns: DataTableColumns = [
    {
      key: 'start_date',
      title: 'Date and time',
      columnStyle: {
        minWidth: 60,
      },
      cell: (_, itemData: ConsultationModel) => {
        if (appliedSort.includes('start_at')) {
          if (!itemData.start_at) return null;
          return (
            <View style={[pv10]}>
              <H6NsSemiBoldBlack style={{ textWrap: 'wrap', textOverflow: 'ellipsis' }}>
                {parseDateTime(itemData.start_at, { outputFormat: 'Do MMMM' })}
              </H6NsSemiBoldBlack>
              {itemData.state === ConsultationStateEnum.RequestSubmitted ? (
                <H6NsRegularBlack>Time to be confirmed</H6NsRegularBlack>
              ) : (
                <H6NsRegularBlack>{parseDateTime(itemData.start_at, { outputFormat: TIME_FORMAT })}</H6NsRegularBlack>
              )}
            </View>
          );
        } else {
          if (!itemData.created_at) return null;
          return (
            <View style={[pv10]}>
              <H6NsSemiBoldBlack style={{ textWrap: 'wrap', textOverflow: 'ellipsis' }}>
                {parseDateTime(itemData.created_at, { outputFormat: 'Do MMMM' })}
              </H6NsSemiBoldBlack>
              <H6NsRegularBlack>{parseDateTime(itemData.created_at, { outputFormat: TIME_FORMAT })}</H6NsRegularBlack>
            </View>
          );
        }
      },
    },

    {
      key: 'doctor',
      title: 'Specialist',
      columnStyle: {
        minWidth: 180,
      },
      cell: (_, itemData: ConsultationModel) => (
        <ProfileDetailsComponent account={itemData.doctor} theme="table" isDoctor style={flex1} />
      ),
      hide: () => isDoctorVersion(),
    },

    {
      key: 'patient',
      title: 'Patient',
      columnStyle: {
        minWidth: 180,
      },
      cell: (_, itemData: ConsultationModel) => (
        <ProfileDetailsComponent theme="table" account={itemData.patient} style={flex1} />
      ),
      hideMobile: isPatientVersion(),
      hideTablet: isPatientVersion(),
    },
    {
      key: 'status',
      title: 'Status',
      selector: (item: ConsultationModel) => statusLabel(item),
      cell: (status, itemData: ConsultationModel) => (
        <StatusBadge
          label={status}
          color={STATUS_COLOR_MAPPING[itemData.state]?.color}
          textStyle={{ textTransform: 'capitalize' }}
        />
      ),
      columnStyle: {
        minWidth: 110,
      },
    },

    {
      key: 'actions',
      columnStyle: {
        minWidth: 160,
      },
      cell: (_, itemData: ConsultationModel) => getActionByConsultationType(itemData),
      contentNumeric: true,
    },
  ];

  return (
    <View style={flex1}>
      <DataTable
        style={flex1}
        tableId="appointmentListing"
        columns={columns}
        items={consultations?.data ?? []}
        page={consultations?.meta?.current_page || 1}
        totalCount={consultations.meta?.total || 0}
        numberOfItemsPerPage={consultations?.meta?.per_page || 0}
        infiniteScroll={isMobile ? { loading, estimatedItemSize: { desktop: 60, mobile: 110 } } : null}
        emptyNote="No appointments are available"
        loading={loading}
        onPageChanged={setPage}
        mobileBreakpoint={DeviceSizeDefaults.tablet}
        mobileRenderItem={(itemData: ConsultationModel, content) =>
          isDoctorVersion() ? (
            <DoctorsAppointmentItem itemData={itemData} status={content?.status?.cellData}>
              {getActionByConsultationType(itemData)}
            </DoctorsAppointmentItem>
          ) : (
            <PatientsAppointmentItem consultation={itemData} status={content?.status?.cellData}>
              {getActionByConsultationType(itemData)}
            </PatientsAppointmentItem>
          )
        }
      />
      {isMobile ? <Gradient /> : null}
    </View>
  );
};
