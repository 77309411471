import Feather from '@expo/vector-icons/Feather';
import React, { useEffect } from 'react';
import { Pressable, View } from 'react-native';

import { PrescriptionRecordMobile } from '../PrescriptionRecordMobile';

import { usePharmacyPrescriptionRequests } from '~/api/hooks/pharmacy/PharmacyPrescriptionsHook';
import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { flex1, mr10, mr15 } from '~/common/commonStyles';
import { DataTable } from '~/components/common/DataTable/DataTable';
import { DataTableColumns } from '~/components/common/DataTable/DataTableTypes';
import { OutlineButton } from '~/components/commonButton';
import { H6NsRegularBlack } from '~/components/commonText';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { PageTabsComponent } from '~/components/tabs/PageTabsComponent';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';
import { labels } from '~/utils/labels';

interface Props {
  hideSorting?: boolean;
  hidePagination?: boolean;
}
export const PrescriptionRequestsTable: React.FC<Props> = ({ hideSorting, hidePagination }) => {
  const { prescriptions, loading, pagination, sortDesc, updatePrescriptions, onPageChanged, setSortingDesc } =
    usePharmacyPrescriptionRequests();
  const { openModal } = useModalManager();
  const { showNewBadge } = useNewBadge();

  const { isMobile } = useBreakpoints();

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.PHARMACY_REQUESTS_UPDATE, updatePrescriptions);
    return removeListener;
  }, []);

  const showModal = (prescriptionRequest: PrescriptionRequestModel) => {
    openModal(ModalName.PrescriptionRequestApproval, {
      request: prescriptionRequest,
    });
  };

  const columns: DataTableColumns = [
    {
      key: 'doctor',
      title: 'Prescribed by',
      columnStyle: { minWidth: 200, maxWidth: 400 },
      cell: (_, itemData: PrescriptionRequestModel) => (
        <ProfileDetailsComponent account={itemData.prescription.doctor} theme="table" style={flex1} />
      ),
    },
    {
      key: 'patient',
      title: 'Patient',
      columnStyle: { minWidth: 200, maxWidth: 400 },
      cell: (_, itemData: PrescriptionRequestModel) => (
        <ProfileDetailsComponent account={itemData.prescription.patient} theme="table" style={flex1} />
      ),
    },
    {
      key: 'product',
      title: 'Prescription name',
      columnStyle: { maxWidth: 400 },
      selector: (item: PrescriptionRequestModel) => extractClinicalTermDescription(item.prescription.product),
    },
    {
      key: 'date',
      title: 'Date',
      columnStyle: { minWidth: 70, maxWidth: 90 },
      selector: (item: PrescriptionRequestModel) =>
        parseDateTime(item.prescription.created_at, { outputFormat: DATE_FORMAT }),
    },
    {
      key: 'actions',
      title: '',
      columnStyle: { maxWidth: 120 },
      contentNumeric: true,
      cell: (_, itemData: PrescriptionRequestModel) => (
        <OutlineButton funCallback={() => showModal(itemData)} style={{ minWidth: 100 }} label={labels.view} />
      ),
    },
  ];

  const sorting = isMobile ? null : (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-end',
        ...mr15,
      }}>
      <Pressable
        style={{ paddingVertical: 12, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        onPress={() => {
          setSortingDesc(!sortDesc);
        }}>
        <H6NsRegularBlack style={mr10}>Sort by Date</H6NsRegularBlack>
        <Feather name={sortDesc ? 'chevron-down' : 'chevron-up'} size={14} />
      </Pressable>
    </View>
  );

  return (
    <View style={{ display: 'flex', flex: 1 }}>
      {hideSorting ? null : <PageTabsComponent items={[]} pageTabsVariant="joined" right={sorting} />}
      <View style={{ display: 'flex', flex: 1 }}>
        <DataTable
          tableId="prescriptionRequests"
          columns={columns}
          items={prescriptions ?? []}
          emptyNote={labels.noRequests}
          loading={loading}
          page={pagination?.current_page || 1}
          totalCount={pagination?.total || 0}
          numberOfItemsPerPage={pagination?.per_page || 0}
          rowIsNewIdentifier={(item) => showNewBadge({ value: item })}
          onPageChanged={onPageChanged}
          hidePagination={hidePagination}
          onRowPress={isMobile ? (_, item: PrescriptionRequestModel) => showModal(item) : null}
          mobileBreakpoint={DeviceSizeDefaults.tablet}
          mobileRenderItem={(content: PrescriptionRequestModel) => (
            <PrescriptionRecordMobile prescriptionValue={content.prescription} dispenseNowButton={false} />
          )}
        />
      </View>
    </View>
  );
};
