import { addDays, subDays } from 'date-fns';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Controller } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';
import { Menu } from 'react-native-paper';

import { IconButton } from '../../../../buttons/IconButton';
import { H6NsRegularBlack } from '../../../../commonText';
import { ArrowRight, BackArrow, CalendarIcon } from '../../../../svgImages';
import { staticRanges } from '../../constants/StaticRanges';
import { getTimeDifferenceAsDays, isToDate } from '../../util/DateFilterUtils';

import { mr10, mr15, ph10 } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { useIncomeListingContext } from '~/providers/income/IncomeListingContext';
import { Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, displayTimeRange, parseDateTime } from '~/utils/dateAndTime';

export const DateRangeSelectorFilter: React.FC = () => {
  const {
    form: { control, setValue },
    submitQuery,
  } = useIncomeListingContext();

  const [open, setOpen] = useState(false);

  return (
    <Controller
      name="dateRangeSelector"
      control={control}
      render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => {
        return (
          <Menu
            visible={open}
            style={{ flexDirection: 'row', flex: 1, width: 800 }}
            anchor={
              <TouchableOpacity
                onPress={() => {
                  setOpen(true);
                }}>
                <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <CalendarIcon height={20} width={20} />
                  <View style={mr15} />
                  <H6NsRegularBlack>{`${displayTimeRange(
                    parseDateTime(value[0]?.startDate, {
                      outputFormat: DATE_FORMAT,
                    }),
                    parseDateTime(value[0]?.endDate, {
                      outputFormat: DATE_FORMAT,
                    })
                  )}`}</H6NsRegularBlack>
                  <View style={mr10} />

                  <>
                    <IconButton
                      disabled={isToDate(value[0])}
                      onPress={() => {
                        setValue('dateRangeSelector', [
                          {
                            key: 'selection',
                            endDate: subDays(value[0]?.endDate, getTimeDifferenceAsDays(value[0])),
                            startDate: subDays(value[0]?.startDate, getTimeDifferenceAsDays(value[0])),
                          },
                        ]);
                        submitQuery();
                      }}>
                      <BackArrow height={10} width={10} strokeWidth={1} />
                    </IconButton>
                    <IconButton
                      disabled={isToDate(value[0])}
                      onPress={() => {
                        setValue('dateRangeSelector', [
                          {
                            key: 'selection',
                            endDate: addDays(value[0]?.endDate, getTimeDifferenceAsDays(value[0])),
                            startDate: addDays(value[0]?.startDate, getTimeDifferenceAsDays(value[0])),
                          },
                        ]);
                        submitQuery();
                      }}>
                      <ArrowRight height={10} width={10} />
                    </IconButton>
                  </>
                </Row>
              </TouchableOpacity>
            }
            onDismiss={() => {
              setOpen(false);
            }}
            contentStyle={{ flexDirection: 'row', flex: 1 }}>
            <DateRangePicker
              onChange={(item) => {
                onChange([item.selection]);
              }}
              dragSelectionEnabled
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={value}
              direction="horizontal"
              staticRanges={staticRanges}
              rangeColors={[colors.info]}
              inputRanges={[]}
              footerContent={
                <View style={ph10}>
                  <Button
                    label="Apply"
                    funCallback={() => {
                      submitQuery();
                      setOpen(false);
                    }}
                  />
                </View>
              }
            />
          </Menu>
        );
      }}
    />
  );
};
