import { useNavigation } from '@react-navigation/native';
import { Image } from 'expo-image';
import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';

import { EducationArticleThumbnailModel } from '~/api/models/educationCentre/models/EducationArticleThumbnailModel';
import { H3TtmSemiBoldBlack, H6NsRegularDisableColor } from '~/components/commonText';
import { AppLogo } from '~/components/svgImages';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { DAY_MONTH_YEAR_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { screenName } from '~/utils/screenName';

interface Props {
  article: EducationArticleThumbnailModel;
  imageSize: number;
}

export const ArticlePostThumbnail: React.FC<Props> = ({ article, imageSize }) => {
  const navigation = useNavigation<NavType>();

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate(screenName.EducationArticle, { articleId: article.id });
      }}>
      <View style={styles.thumbnailContainer}>
        {article?.cover ? (
          <View style={styles.appLogoContainer}>
            <Image
              source={{
                uri: article?.cover?.conversions?.cover_image_resized || article?.cover?.url,
              }}
              style={styles.imageStyle}
              contentFit="cover"
            />
          </View>
        ) : (
          <View style={[styles.appLogoContainer, { paddingVertical: '20%' }]}>
            <AppLogo width={imageSize} height={imageSize} />
          </View>
        )}
        <View style={styles.cardContent}>
          <H3TtmSemiBoldBlack numberOfLines={2} ellipsizeMode="tail" style={styles.title}>
            {article?.title}
          </H3TtmSemiBoldBlack>
          <H6NsRegularDisableColor>
            {parseDateTime(article?.published_at, { outputFormat: DAY_MONTH_YEAR_FORMAT })}
          </H6NsRegularDisableColor>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  thumbnailContainer: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.lightPurple,
    marginVertical: 13,
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'auto',
  },
  imageStyle: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    aspectRatio: 16 / 9,
    width: '100%',
    height: 'auto',
  },
  appLogoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.lightPurple,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 'auto',
    width: '100%',
  },
  cardContent: {
    margin: 10,
  },
  title: {
    overflow: 'hidden',
  },
});
