import { useEffect, useState } from 'react';
import { Keyboard, KeyboardEvent } from 'react-native';

import { isNative } from '../buildConfig';

export const useKeyboard = () => {
  const [keyboardShowing, setKeyboardShowing] = useState(false);
  const handleKeyboardShow = (ev: KeyboardEvent) => {
    setKeyboardShowing(true);
  };
  const handleKeyboardHide = (ev: KeyboardEvent) => {
    setKeyboardShowing(false);
  };

  useEffect(() => {
    if (!isNative()) return;
    const keyboardWillShow = Keyboard.addListener('keyboardWillShow', handleKeyboardShow);
    const keyboardDidShow = Keyboard.addListener('keyboardDidShow', handleKeyboardShow);
    const keyboardWillHide = Keyboard.addListener('keyboardWillHide', handleKeyboardHide);
    const keyboardDidHide = Keyboard.addListener('keyboardDidHide', handleKeyboardHide);

    return () => {
      keyboardWillShow.remove();
      keyboardDidShow.remove();
      keyboardWillHide.remove();
      keyboardDidHide.remove();
    };
  }, []);

  return { showing: keyboardShowing };
};
