import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';

import { DashboardCard } from '../common/DashboardCard';

import { usePatientsListOnDemand } from '~/api/hooks/patients/PatientsListHook';
import { flex1 } from '~/common/commonStyles';
import { DataTable } from '~/components/common/DataTable/DataTable';
import { DataTableColumns } from '~/components/common/DataTable/DataTableTypes';
import { OutlineButton } from '~/components/commonButton';
import { H6NsSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { ArrowRight } from '~/components/svgImages';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { parseDateTime, DATE_FORMAT } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { DigimedMilestones, digimedMilestoneEnabled } from '~/utils/milestoneUtil';
import { screenName } from '~/utils/screenName';

const PATIENT_LIST_MINIMUM = 3;
const RECORD_HEIGHT = 85;
const EXTRA_CONTENT_HEIGHT = 60;
export const PatientsList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [containerHeight, setContainerHeight] = useState<number>(undefined);
  const { navigate } = useNavigation<NavType>();
  const { getListOfMyPatients, patients } = usePatientsListOnDemand();
  const windowHeight = Dimensions.get('window').height;

  const rowPress = (key: number) => {
    if (!key) return;
    navigate(screenName.PatientDetails, { id: key });
  };

  const recordCount = useMemo(
    () => Math.max(PATIENT_LIST_MINIMUM, Math.floor((containerHeight - EXTRA_CONTENT_HEIGHT) / RECORD_HEIGHT)),
    [patients, containerHeight]
  );

  const slicedPatients = useMemo(() => {
    return patients?.slice(0, recordCount) || [];
  }, [patients, recordCount]);

  const columns: DataTableColumns = digimedMilestoneEnabled(DigimedMilestones.DoctorPatientApps)
    ? [
        {
          key: 'patient',
          title: 'Patient',
          cell: (_, itemData) => <ProfileDetailsComponent account={itemData} theme="default" style={flex1} />,
          cellStyle: styles.cellPatient,
        },
        {
          key: 'actions',
          cell: () => <ArrowRight height={10} width={5} />,
          columnStyle: { minWidth: 50, maxWidth: 50, display: 'flex', flexDirection: 'row-reverse' },
        },
      ]
    : [
        {
          key: 'patient',
          title: 'Patient',
          cell: (_, itemData) => <ProfileDetailsComponent account={itemData} theme="default" style={flex1} />,
          cellStyle: styles.cellPatient,
        },
        {
          key: 'gender',
          title: 'Gender',
          cell: (cellData) => (
            <View style={{ maxWidth: '100%' }}>
              <H6NsSemiBoldBlack>Gender</H6NsSemiBoldBlack>
              <H6NsRegularBlack style={{ textTransform: 'capitalize' }}>{cellData}</H6NsRegularBlack>
            </View>
          ),
          hideMobile: true,
        },
        {
          key: 'date_of_birth',
          title: 'Date of birth',
          hideMobile: true,
          selector: (item) => {
            return parseDateTime(item.date_of_birth, { outputFormat: DATE_FORMAT }) || '';
          },
          cell: (cellData) => (
            <View style={{ maxWidth: '100%' }}>
              <H6NsSemiBoldBlack>Date of Birth</H6NsSemiBoldBlack>
              <H6NsRegularBlack>{cellData}</H6NsRegularBlack>
            </View>
          ),
          cellStyle: { minWidth: 100 },
        },
        {
          key: 'actions',
          cell: (_, item) => (
            <OutlineButton
              label="View Details"
              funCallback={() => {
                rowPress(item.id);
              }}
            />
          ),
          contentNumeric: true,
          showOnHover: true,
          hideMobile: true,
          hideTablet: true,
        },
      ];

  useEffect(() => {
    setLoading(true);
    const itemsLimit = Math.max(30, Math.floor(windowHeight / RECORD_HEIGHT));
    getListOfMyPatients({ page: 1, limit: itemsLimit }).finally(() => {
      setLoading(false);
    });
  }, []);

  const onLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    setContainerHeight(height);
  };

  return (
    <View style={flex1} onLayout={onLayout}>
      <DashboardCard
        title="Patients"
        viewAllLink={screenName.PatientManagement}
        style={{ flex: 1, backgroundColor: colors.white, borderColor: colors.lightPurple2 }}>
        <View style={styles.container}>
          <DataTable
            columns={columns}
            items={slicedPatients || []}
            onRowPress={rowPress}
            page={1}
            hideHeaders
            hidePagination
            style={flex1}
            wrapperStyle={styles.table}
            rowStyle={styles.rowStyle}
            rowHoverStyle={styles.rowHoverStyle}
            loading={loading}
            emptyNote={labels.noPatients}
            scrollEnabled={slicedPatients?.length ? false : undefined}
          />
        </View>
      </DashboardCard>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    marginTop: 10,
  },
  table: {
    borderColor: colors.transparent,
    paddingRight: 5,
  },
  cellPatient: {
    minWidth: 100,
  },
  rowStyle: {
    backgroundColor: colors.white,
    marginVertical: 5,
    borderBottomColor: colors.transparent,
    borderRadius: 6,
    paddingVertical: 5,
    marginHorizontal: 2,
    paddingHorizontal: 0,
  },
  rowHoverStyle: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});
