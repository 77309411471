import React from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';

import { flex1 } from '../../common/commonStyles';
import { DashboardWebHeader } from '../../components/dashboard/doctor/DashboardHeader/DashboardWebHeader';
import { PatientsList } from '../../components/dashboard/doctor/PatientsList';
// import { ActionTypes } from '../redux/action/actionList';
import { colors } from '../../utils/colors';

import { Column, Container, Grid, Row } from '~/theme/components/grid';

const DoctorDashboardPms: React.FC = (): JSX.Element => {
  return (
    <>
      <View style={styles.container}>
        <DashboardWebHeader />
        <ScrollView style={styles.content} contentContainerStyle={flex1}>
          <Grid>
            <Container alignSelf="flex-start" style={{ flex: 1 }}>
              <Row style={{ flex: 1, minHeight: 280 }}>
                <Column span={{ xs: 12, xl: 8 }}>
                  <PatientsList />
                </Column>
                {/* <Column span={{ xs: 12, md: 4 }}>
                  <DashboardCard>
                    <RequestsList />
                  </DashboardCard>
                </Column> */}
              </Row>
            </Container>
          </Grid>
        </ScrollView>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
    display: 'flex',
  },
  content: {
    marginTop: 10,
  },
  row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: -15,
    paddingVertical: 15,
  },
  column1: {
    flex: 0.7,
    height: '100%',
    paddingHorizontal: 15,
  },
  column2: {
    flex: 0.3,
    paddingHorizontal: 15,
  },
});

//make this component available to the app
export default DoctorDashboardPms;
