import { DocumentPickerAsset } from 'expo-document-picker';
import React from 'react';
import { Controller } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';

import { ReasonImageComponent } from '../components/ReasonImageComponent';

import { mt10, wizardStyle } from '~/common/commonStyles';
import { UploadDocumentTouchable } from '~/components/buttons/UploadDocumentTouchable';
import { Button, TextButton } from '~/components/commonButton';
import { OutlineButtonText } from '~/components/commonViews';
import FloatingInput from '~/components/floatingInputBox';
import { PaperClip } from '~/components/svgImages';
import { ImageFormats } from '~/constants/documentUploadsConstants';
import { WizardLayout } from '~/layouts/WizardLayout';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { validationSchema } from '~/services/validationConfig';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';

export const ReasonForConsultationStep: React.FC = () => {
  const {
    formControl,
    loading,
    wizard: {
      actions: { next },
    },
    media,
  } = useAppointmentBookingContext();

  const uploadFile = async (results: DocumentPickerAsset[]) => {
    if (!results?.length) return;
    await media.appendMedia(results[0]);
  };

  const buttons = [
    <UploadDocumentTouchable formats={ImageFormats} onResult={uploadFile}>
      <View style={[styles.outlineButton]}>
        <PaperClip width={30} height={20} color={colors.purple} />
        <OutlineButtonText>Add attachment</OutlineButtonText>
      </View>
    </UploadDocumentTouchable>,
    <Button label={labels.next} funCallback={next} disabled={loading} />,
    <TextButton funCallback={next} label={labels.notNow.toUpperCase()} disabled={loading} />,
  ];

  return (
    <View style={wizardStyle.container}>
      <WizardLayout title={appointmentLabels.reasonTitle} buttons={buttons} keyboardButtonIndex={1} hideRequired>
        <View style={{ width: '100%' }}>
          <Controller
            control={formControl}
            name="reason"
            render={({ field: { value, onChange }, formState: { isSubmitted }, fieldState: { error } }) => (
              <FloatingInput
                value={value}
                onChangeValue={onChange}
                label={labels.description}
                error={isSubmitted && !!error}
                errorMessage={isSubmitted && error?.message}
                maxLength={validationSchema.string.maxLength}
                multiline
              />
            )}
          />

          <Controller
            control={formControl}
            name="reason_media"
            render={({ field: { value } }) => (
              <View style={mt10}>
                {value?.map((field, index) => (
                  <ReasonImageComponent image={field} key={index} onRemove={() => media.removeMedia(field.id)} />
                ))}
              </View>
            )}
          />
        </View>
      </WizardLayout>
    </View>
  );
};

const styles = StyleSheet.create({
  outlineButton: {
    flexDirection: 'row',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.purple,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    width: '100%',
  },
});
