import { Feather } from '@expo/vector-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { useAccordionGroupContext } from './AccordionGroupProvider';
import { ml10, mr10 } from '../../../common/commonStyles';
import { colors } from '../../../utils/colors';
import { fontFamily } from '../../../utils/fontFamily';
import { H5TtmRegularSecondaryBlack } from '../../commonText';

interface Props {
  title?: string;
  titleNode?: (expanded: boolean) => React.ReactNode;
  groupValue?: string;
  left?: React.ReactNode;
  children: React.ReactNode;
  expanded?: boolean;
  accessibilityLabel?: string;
  style?: StyleProp<ViewStyle>;
  expandedStyle?: StyleProp<ViewStyle>;
  accordionStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  error?: boolean;
  expandedBackgroundColor?: string;
  right?: (props: { isExpanded: boolean }) => React.ReactNode;
  onExpandedChanged?: (expanded: boolean, expandedValue?: string) => void;
}

export const CustomAccordion: React.FC<Props> = ({
  title,
  titleNode,
  groupValue,
  children,
  expanded = false,
  accessibilityLabel,
  style,
  expandedStyle,
  left,
  accordionStyle,
  error,
  disabled,
  expandedBackgroundColor = colors.white,
  right,
  onExpandedChanged,
}) => {
  const groupContext = useAccordionGroupContext();

  const [isLocalExpanded, setLocalExpanded] = useState(false);

  const isExpanded = useMemo(
    () => (groupContext && groupValue ? groupContext.isExpanded(groupValue) : isLocalExpanded),
    [groupContext?.expanded, isLocalExpanded]
  );

  const setExpanded = (expanded?: boolean) => {
    if (isExpanded === expanded) return;
    if (groupContext && groupValue) {
      if (groupContext.isExpanded(groupValue) !== expanded) groupContext.setExpanded(expanded ? groupValue : null);
    } else {
      setLocalExpanded(expanded);
    }
  };

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);

  useEffect(() => {
    if (onExpandedChanged) {
      const isExpanded = isLocalExpanded || groupContext?.isExpanded(groupValue);
      onExpandedChanged(isExpanded, isExpanded ? groupValue : groupContext?.expanded);
    }
  }, [isLocalExpanded, groupContext?.expanded]);

  const handlePress = () => setExpanded(!isExpanded);

  return (
    <View
      style={[
        styles.container,
        error && !isExpanded ? styles.containerError : null,
        style,
        isExpanded ? expandedStyle : null,
      ]}>
      <View
        style={[
          styles.accordion,
          accordionStyle,
          isExpanded ? [styles.accordionExpanded, { backgroundColor: expandedBackgroundColor }] : null,
        ]}
        accessibilityLabel={accessibilityLabel || title}>
        <View>
          <Pressable onPress={handlePress} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
              }}>
              {left ? <View style={[ml10, mr10, { display: 'flex', justifyContent: 'center' }]}>{left}</View> : null}
              {title ? (
                <H5TtmRegularSecondaryBlack>{title}</H5TtmRegularSecondaryBlack>
              ) : titleNode ? (
                titleNode(isExpanded)
              ) : null}
            </View>
            {disabled ? null : (
              <View>
                {right ? (
                  right({ isExpanded })
                ) : (
                  <Feather name={isExpanded ? 'chevron-up' : 'chevron-down'} size={20} />
                )}
              </View>
            )}
          </Pressable>
        </View>
        {isExpanded ? children : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.lightPurple,
    borderStyle: 'solid',
  },
  containerError: {
    borderColor: colors.danger,
  },
  accordion: {
    padding: 0,
    borderRadius: 6,
    borderBottomWidth: 1,
    borderColor: colors.lightPurple,
    borderStyle: 'solid',
    backgroundColor: colors.lightPurple,
  },
  accordionExpanded: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  title: {
    color: colors.black,
    fontFamily: fontFamily.fontTitilliumRegular,
    fontSize: 14,
  },
});
