import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationChannelEnum } from '~/api/models/consultations/enums/ConsultationChannelEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { SmallNsRegularInfo } from '~/components/commonText';
import { AudioCallIcon, ChatIcon, LocationIcon, VideoCallIcon } from '~/components/svgImages';
import { colors } from '~/utils/colors';

interface Props {
  consultation: ConsultationModel;
  color?: string;
}

export const ConsultationTypeIcon: React.FC<Props> = ({ consultation, color }) => {
  const localColor = color || colors.info;
  switch (consultation?.channel?.name ?? null) {
    case ConsultationChannelEnum.audioCall:
      return (
        <View style={styles.row}>
          <View style={styles.icon}>
            <AudioCallIcon height={20} width={20} color={localColor} />
          </View>
          <SmallNsRegularInfo
            style={[styles.description, { color: localColor }]}>{`${consultation.channel.name}`}</SmallNsRegularInfo>
        </View>
      );

    case ConsultationChannelEnum.chat:
      return (
        <View style={styles.row}>
          <View style={styles.icon}>
            <ChatIcon height={20} width={20} color={localColor} />
          </View>
          <SmallNsRegularInfo
            style={[styles.description, { color: localColor }]}>{`${consultation.channel.name}`}</SmallNsRegularInfo>
        </View>
      );

    case ConsultationChannelEnum.videoCall:
      return (
        <View style={styles.row}>
          <View style={styles.icon}>
            <VideoCallIcon height={20} width={20} color={localColor} />
          </View>
          <SmallNsRegularInfo
            style={[styles.description, { color: localColor }]}>{`${consultation.channel.name}`}</SmallNsRegularInfo>
        </View>
      );

    case null: {
      if (consultation?.type === ConsultationTypeEnum.HOME_VISIT) {
        return (
          <View style={styles.row}>
            <View style={styles.icon}>
              <LocationIcon height={20} width={20} color={localColor} />
            </View>
            {consultation.patient_location?.formatted_address ? (
              <SmallNsRegularInfo style={[styles.description, { color: localColor }]} ellipsisMode="tail">
                {consultation.patient_location.formatted_address}
              </SmallNsRegularInfo>
            ) : null}
          </View>
        );
      }
    }
  }
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  description: {
    marginLeft: 7,
    flex: 1,
  },
  icon: {
    minWidth: 20,
    flex: 0,
  },
});
