import React from 'react';
import { View } from 'react-native';

import { ConsultationTypeMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { IncomeIndex } from '~/api/models/income/responses/IncomeResponse';
import { mb10, mt20 } from '~/common/commonStyles';
import { InvoiceActionButtons } from '~/components/buttons/InvoiceActionButtons';
import { H6NsRegularBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';

export interface Props {
  income: IncomeIndex;
}

export const IncomeListItem: React.FC<Props> = ({ income }) => {
  return (
    <View style={{ width: '100%' }}>
      <H6NsRegularBlack style={mb10}>
        {parseDateTime(income.model.started_at, { outputFormat: DATE_FORMAT })}
      </H6NsRegularBlack>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <View style={{ flex: 1 }}>
          <H6NsSemiBoldBlack>
            #{income.model_id} <H6NsRegularBlack>{ConsultationTypeMapping[income.model.type]}</H6NsRegularBlack>
          </H6NsSemiBoldBlack>
        </View>
        <H6NsSemiBoldBlack>{income.amount.formatted_amount}</H6NsSemiBoldBlack>
      </View>
      <View style={{ display: 'flex', alignItems: 'flex-end' }}>
        <InvoiceActionButtons
          style={mt20}
          transactionId={income.id}
          invoices={income.invoices}
          statementOfFeesStatus={income.statement_of_fees_status}
        />
      </View>
    </View>
  );
};
