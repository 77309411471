import Feather from '@expo/vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import { PatientConsultationInformation } from '../../../patient/consultations/PatientConsultationInformation';
import { AppointmentShowPageLayout } from '../../appointments/AppointmentShowPageLayout';

import { mb20, mt20 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { Chat } from '~/components/common/consultations/chat/Chat';
import { ChatMessageInput } from '~/components/common/consultations/chat/ChatMessageInput';
import { H4TtmSemiBoldTheme } from '~/components/commonText';
import { BackArrow } from '~/components/svgImages';
import { NavType } from '~/navigation/types';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { SET_HIDE_MOBILE_MENU } from '~/redux/reducers/overlayReducer';
import { useAppDispatch } from '~/redux/store';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { stateEndedOrPassed } from '~/utils/hooks/appointments/AppointmentStateHook';
import { screenName } from '~/utils/screenName';

export const PatientChat: React.FC = () => {
  const { consultation, endConsultation } = useAppointmentContext();
  const { isMobile, isDesktop } = useBreakpoints();
  const navigation = useNavigation<NavType>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!consultation) return;

    dispatch(SET_HIDE_MOBILE_MENU(!stateEndedOrPassed(consultation.state)));

    return () => {
      dispatch(SET_HIDE_MOBILE_MENU(false));
    };
  }, [consultation.state]);

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate({ screen: screenName.Appointments });
    }
  };

  return (
    <AppointmentShowPageLayout noPadding flexContent noScrollWhenMobile>
      <Row style={{ flex: 1 }}>
        {isDesktop ? (
          <Column style={{ maxWidth: 350 }}>
            <PatientConsultationInformation consultation={consultation} />
          </Column>
        ) : null}
        <Column style={{ maxHeight: '100%' }}>
          {isMobile ? (
            <View style={styles.mobileHeader}>
              <IconButton onPress={goBack}>
                <BackArrow width={15} height={15} color={colors.purple} />
              </IconButton>
              <H4TtmSemiBoldTheme>Chat</H4TtmSemiBoldTheme>
              <View style={{ width: 40, height: 40 }} />
            </View>
          ) : null}
          <Chat consultation={consultation} />
          <View style={[mt20, isMobile ? [{ paddingHorizontal: MOBILE_HORIZONTAL_SPACE }] : null]}>
            <ChatMessageInput consultation={consultation} />
          </View>
        </Column>
      </Row>
    </AppointmentShowPageLayout>
  );
};

const styles = StyleSheet.create({
  mobileHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
  },
});
