import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { EducationArticleThumbnailModel } from '~/api/models/educationCentre/models/EducationArticleThumbnailModel';
import { GetEducationArticlesIndexResponse } from '~/api/models/educationCentre/responses/GetEducationArticlesIndexResponse';

interface IState {
  articles: EducationArticleThumbnailModel[];
  pagination: PaginationMetaModel;
  loading: boolean;
}

const initialState: IState = {
  articles: undefined,
  pagination: undefined,
  loading: true,
};

const educationCentreSlice = createSlice({
  name: 'educationCentre',
  initialState,
  reducers: {
    SET_ARTICLE_LOADING: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    SET_ARTICLES: (state, action: PayloadAction<GetEducationArticlesIndexResponse>) => ({
      ...state,
      articles: action.payload.data,
      pagination: action.payload.meta,
      loading: false,
    }),
  },
});

export const { SET_ARTICLES, SET_ARTICLE_LOADING } = educationCentreSlice.actions;
export default educationCentreSlice.reducer;
