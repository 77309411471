import { StyleSheet } from 'react-native';

export const MOBILE_HORIZONTAL_SPACE = 26;

const styles = StyleSheet.create({
  mobileContentPadding: {
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
    paddingVertical: 15,
  },
  mobileHorizontalPadding: {
    paddingHorizontal: 15,
  },
});

export const { mobileContentPadding, mobileHorizontalPadding } = styles;
