import React, { useState } from 'react';

import { AvailabilitiesPageStateEnum } from '~/api/models/preferences/enums/AvailabilitiesPageStateEnum';
import { ScheduledAppointmentsAvailabilities } from '~/components/availabilities/scheduledAppointment/ScheduledAppointmentsAvailabilities';
import RegistrationLayout from '~/layouts/RegistrationLayout';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const ScheduledAppointmentsRegistration: React.FC = () => {
  const [pageState, setPageState] = useState<AvailabilitiesPageStateEnum>(AvailabilitiesPageStateEnum.VIEW);
  const { isMobile } = useBreakpoints();
  return (
    <RegistrationLayout
      title={labels.addAvailability}
      backLink={{ screen: screenName.AvailabilitiesScreen }}
      backTitle={labels.availabilities.toUpperCase()}
      childrenMarginTop={20}
      hideSubtitle
      noHorizontalContentPadding={!isMobile}>
      <ScheduledAppointmentsAvailabilities pageState={pageState} setPageState={setPageState} isRegistration />
    </RegistrationLayout>
  );
};
