import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TextInput, StyleProp, ViewStyle } from 'react-native';

import { colors } from '../../utils/colors';

interface Props {
  value: string | number;
  label: string;
  placeholder?: string;
  unit?: string;
  locked?: boolean;
  style?: StyleProp<ViewStyle>;
  onChange?: (text: string) => void;
}

export const MetricInput: React.FC<Props> = ({ value, label, placeholder, style, unit, locked = false, onChange }) => {
  const [textValue, setTextValue] = useState<string>('');

  useEffect(() => {
    setTextValue(value?.toString() || '');
  }, [value]);

  const onChangeText = (text?: string) => {
    setTextValue(text);
    if (onChange) {
      onChange(text);
    }
  };
  return (
    <View style={[styles.container, locked ? styles.containerLocked : null, style]}>
      <Text style={[locked ? styles.textLocked : styles.textUnlocked]}>{label + (locked ? ': ' : '')}</Text>
      <View style={styles.valueContainer}>
        {locked && !value ? (
          <Text style={styles.textLocked}>N/A</Text>
        ) : (
          <>
            {locked ? (
              <Text style={styles.textLocked}>{value}</Text>
            ) : (
              <TextInput
                style={styles.input}
                value={textValue}
                placeholder={placeholder}
                onChangeText={onChangeText}
                placeholderTextColor={colors.placeholderGrey}
              />
            )}
            {unit ? (
              <Text style={[{ paddingLeft: 2, minWidth: 'auto' }, locked ? styles.textLocked : null]}>{unit}</Text>
            ) : null}
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 12,
    borderRadius: 6,
    backgroundColor: colors.lightPurple2,
  },
  containerLocked: {
    justifyContent: 'center',
    backgroundColor: colors.info,
  },
  textLocked: {
    color: colors.white,
    fontWeight: '600',
    fontFamily: 'NotoSans',
  },
  textUnlocked: {
    width: '50%',
  },
  input: {
    textAlign: 'right',
    minWidth: 50,
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});
