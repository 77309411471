import { AsyncEncryptStorage, EncryptStorage } from 'encrypt-storage';
import Constants from 'expo-constants';

const encryptionKey = Constants.expoConfig.extra.webStorageKey;

const encryptStorage = new AsyncEncryptStorage(encryptionKey, { doNotEncryptValues: false });
const hashing = new EncryptStorage(encryptionKey);

function hashKey(key: string) {
  return hashing.hash(key);
}

async function getAsyncItem(key: string, defaultData?: string) {
  const encKey = await hashKey(key);
  const value = await encryptStorage.getItem<string>(encKey);
  return value ?? defaultData;
}

async function setAsyncItem(key: string, value: string) {
  const encKey = await hashKey(key);
  await encryptStorage.setItem(encKey, value);
}

async function removeAsyncItem(key: string) {
  const encKey = await hashKey(key);
  await encryptStorage.removeItem(encKey);
}

export default {
  getAsyncItem,
  setAsyncItem,
  removeAsyncItem,
};
