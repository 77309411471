import { get, post } from '~/api/axios';
import { UpdateAccountBiographyRequest } from '~/api/models/accounts/requests/UpdateAccountBiographyRequest';
import { BiographyResponse } from '~/api/models/accounts/responses/BiographyResponse';
import { IdModel } from '~/api/models/common/models/IdModel';

export const apiUpdateBiography = (req: UpdateAccountBiographyRequest) => {
  return post(`accounts/${req.id}/biography`, req.params);
};

export const apiGetBiography = (req: IdModel) => {
  return get<BiographyResponse>(`accounts/${req.id}/biography`);
};
