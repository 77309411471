import { FlashList, FlashListProps } from '@shopify/flash-list';
import React, { useMemo } from 'react';

import { TablePaginationInfo } from '../DataTable/DataTableTypes';

interface Props<TItem extends unknown> {
  listProps: FlashListProps<TItem>;
  pagination?: {
    perPage: number;
    page: number;
    totalCount: number;
    loading?: boolean;
  };
  fetchData?: (pagination: TablePaginationInfo) => void;
}

export function InfiniteList<TItem extends unknown>({ listProps, pagination, fetchData }: Props<TItem>) {
  const endOfList = useMemo(() => {
    if (!pagination) return true;
    return pagination.totalCount <= pagination.page * pagination.perPage;
  }, [pagination]);

  const fetchInfiniteData = () => {
    if (!pagination || !fetchData) return;
    const page = pagination.page;
    if (!page) {
      fetchData({ page: 1, limit: pagination.perPage });
    } else if (!endOfList && !pagination.loading) {
      fetchData({ page: page + 1, limit: pagination.perPage });
    }
  };
  return <FlashList {...listProps} onEndReached={fetchInfiniteData} onEndReachedThreshold={0.2} />;
}
