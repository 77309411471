import * as React from 'react';
import { View } from 'react-native';

import { theme } from '../../utils/theme';

interface Props {
  passcode: string;
}

const Dot: React.FC<{ active: boolean }> = ({ active }) => {
  const currentStyle = active ? theme.passcodedotActive : theme.passcodedotInactive;
  return <View style={[theme.passcodedot, currentStyle]} />;
};

export const DotComponent: React.FC<Props> = ({ passcode }): JSX.Element => {
  return (
    <View style={theme.passcodedotContainer}>
      <Dot active={passcode.length > 0} />
      <Dot active={passcode.length > 1} />
      <Dot active={passcode.length > 2} />
      <Dot active={passcode.length > 3} />
    </View>
  );
};
