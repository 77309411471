import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ConsultationPriceModel } from '~/api/models/appointments/models/ConsultationPriceModel';
import { SmallNsRegularBlack, SmallNsRegularSecondaryBlack, SmallNsSemiBoldBlack } from '~/components/commonText';
import { colors } from '~/utils/colors';

interface Props {
  price: ConsultationPriceModel;
}

export const ConsultationFeesComponent: React.FC<Props> = ({ price }) => {
  return (
    <View>
      <View style={styles.fees}>
        {price?.fees?.map((fee) => (
          <View style={styles.row} key={fee.type}>
            <View style={styles.col1}>
              <SmallNsRegularBlack>{fee.name || fee.type}</SmallNsRegularBlack>
            </View>
            <View style={styles.col2}>
              <SmallNsRegularBlack>{fee.price.formatted_amount}</SmallNsRegularBlack>
            </View>
          </View>
        ))}
      </View>
      <View style={[styles.fees, styles.row]}>
        <View style={styles.col1}>
          <SmallNsRegularSecondaryBlack>Subtotal</SmallNsRegularSecondaryBlack>
        </View>
        <View style={styles.col2}>
          <SmallNsRegularSecondaryBlack>{price?.sub_total?.formatted_amount}</SmallNsRegularSecondaryBlack>
        </View>
      </View>
      <View style={[styles.fees, styles.row]}>
        <View style={styles.col1}>
          <SmallNsRegularSecondaryBlack>Total VAT on fees</SmallNsRegularSecondaryBlack>
        </View>
        <View style={styles.col2}>
          <SmallNsRegularSecondaryBlack>{price?.total_vat?.formatted_amount}</SmallNsRegularSecondaryBlack>
        </View>
      </View>
      <View style={[styles.total, styles.row]}>
        <View style={styles.col1}>
          <SmallNsSemiBoldBlack>Total</SmallNsSemiBoldBlack>
        </View>
        <View style={styles.col2}>
          <SmallNsSemiBoldBlack>{price?.total?.formatted_amount}</SmallNsSemiBoldBlack>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  col1: {
    flex: 2,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  col2: {
    flex: 1,
    borderLeftWidth: 1,
    borderLeftColor: colors.lightPurple2,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  fees: {
    borderBottomColor: colors.lightPurple2,
    borderBottomWidth: 1,
  },
  total: {
    borderTopColor: colors.lightPurple2,
    borderTopWidth: 2,
  },
});
