import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { View } from 'react-native';

import { AmountPaidForm } from '../../components/AmountPaidForm';

import { flex1, mb10, pb15 } from '~/common/commonStyles';
import { CancelContinueFooterArray } from '~/components/buttons/CancelContinueFooterArray';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { WizardLayout } from '~/layouts/WizardLayout';
import { NavType } from '~/navigation/types';
import { useInsuranceClaimFormContext } from '~/providers/insurance/InsuranceClaimFormContext';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { screenName } from '~/utils/screenName';

export const InsuranceClaimFormMobile: React.FC = () => {
  const { nextPage, loading, updateInsuranceDocuments } = useInsuranceClaimFormContext();
  const { navigate } = useNavigation<NavType>();
  const [saving, setSaving] = useState(false);

  const confirm = async () => {
    try {
      setSaving(true);
      await updateInsuranceDocuments();
      nextPage();
    } catch (e) {
      ErrorAlert(e);
    }
    setSaving(false);
  };

  const buttons = CancelContinueFooterArray({
    waiting: loading || saving,
    onCancel: () => navigate(screenName.Insurance, { screen: screenName.InsuranceClaims }),
    onConfirm: confirm,
  });

  return (
    <View style={flex1}>
      <WizardLayout title="Expenses and Supporting documents" buttons={buttons} wide>
        <Grid grid={{ gutters: 16, noOuterGutter: true }}>
          <Container alignSelf="center" style={pb15}>
            <Row style={mb10}>
              <Column>
                <AmountPaidForm />
              </Column>
            </Row>
          </Container>
        </Grid>
      </WizardLayout>
    </View>
  );
};
