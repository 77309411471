import React from 'react';

import { PrescriptionRequestsTable } from '~/components/pharmacies/requests/PrescriptionRequestsTable';
import { RequestsLayout } from '~/layouts/RequestsLayout';

export const PrescriptionRequests: React.FC = () => {
  return (
    <RequestsLayout>
      <PrescriptionRequestsTable />
    </RequestsLayout>
  );
};
