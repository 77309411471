import React, { useMemo } from 'react';
import { Controller, useController, useWatch } from 'react-hook-form';
import { View } from 'react-native';

import { DoctorListFilterChannelTypeComponent } from './DoctorListFilterChannelTypeComponent';
import { DoctorListFilterFavouritedByComponent } from './DoctorListFilterFavouritedByComponent';
import { DoctorListFilterSpecialisationComponent } from './DoctorListFilterSpecialisationComponent';
import { DoctorsListFilterLanguageComponent } from './DoctorsListFilterLanguageComponent';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { mb10 } from '~/common/commonStyles';
import { H3TtmSemiBoldBlack, H5TtmSemiBoldBlack, SmallNsRegularDisableColor } from '~/components/commonText';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { useDoctorListingContext } from '~/providers/appointment/DoctorListingContext';

interface Props {
  largeHeader?: boolean;
}
export const DoctorListFilterComponent: React.FC<Props> = ({ largeHeader }) => {
  const { filterControl, isListing } = useDoctorListingContext();
  const { formControl } = useAppointmentBookingContext();

  const location = useWatch({ control: formControl, name: 'location' });
  const availableTimeRanges = useWatch({ control: formControl, name: 'available_time_ranges' });
  const availableTimeDate = useWatch({ control: formControl, name: 'available_time_date' });
  const consultationTypeValue = useWatch({ control: formControl, name: 'type' });
  const patientIdValue = useWatch({ control: formControl, name: 'patient_id' });
  const filterRanges = useMemo(
    () =>
      availableTimeRanges
        ?.filter((range) => range.from && range.to)
        ?.map((range) => ({
          from: `${availableTimeDate} ${range.from}`,
          to: `${availableTimeDate} ${range.to}`,
        })) || undefined,
    [availableTimeDate, availableTimeRanges]
  );
  return (
    <View>
      <View style={mb10}>
        {largeHeader ? (
          <H3TtmSemiBoldBlack>Filter</H3TtmSemiBoldBlack>
        ) : (
          <H5TtmSemiBoldBlack>Filter</H5TtmSemiBoldBlack>
        )}
      </View>
      <SmallNsRegularDisableColor>Specialisation</SmallNsRegularDisableColor>
      <View style={mb10}>
        <Controller
          control={filterControl}
          name="filter.is_gp"
          render={({ field: { value, onChange } }) => (
            <DoctorListFilterSpecialisationComponent showGp={value} setShowGp={onChange} />
          )}
        />
      </View>
      <View style={mb10}>
        <Controller
          control={filterControl}
          name="filter.favourited_by"
          render={({ field: { value, onChange } }) => (
            <DoctorListFilterFavouritedByComponent
              value={value}
              onChange={onChange}
              singlePatient={isListing ? undefined : patientIdValue}
            />
          )}
        />
      </View>
      {consultationTypeValue === ConsultationTypeEnum.HOME_VISIT ? null : (
        <>
          <SmallNsRegularDisableColor>Channels used for consultation</SmallNsRegularDisableColor>
          <View style={mb10}>
            <Controller
              control={filterControl}
              name="filter.consultation_channels"
              render={({ field: { value, onChange } }) => (
                <DoctorListFilterChannelTypeComponent value={value} setValue={onChange} />
              )}
            />
          </View>
        </>
      )}
      <View style={mb10}>
        <Controller
          control={filterControl}
          name="filter.languages"
          render={({ field: { value, onChange } }) => (
            <DoctorsListFilterLanguageComponent
              type={consultationTypeValue}
              ranges={filterRanges}
              location={location}
              value={value}
              setValue={onChange}
              header={<SmallNsRegularDisableColor>Languages spoken</SmallNsRegularDisableColor>}
            />
          )}
        />
      </View>
    </View>
  );
};
