import React, { ReactNode } from 'react';
import { View } from 'react-native';

import { ConsultationTypeMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { mb10, mb5, mr10 } from '~/common/commonStyles';
import { ConsultationTypeIcon } from '~/components/common/consultations/ConsultationTypeIcon';
import { H5TtmRegularBlack, H5TtmRegularInfo, H6NsSemiBoldBlack } from '~/components/commonText';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { getAccountName, getInitials } from '~/utils/personalDetailsUtils';

interface Props {
  status?: string;
  itemData?: ConsultationModel;
  children: ReactNode;
}
export const DoctorsAppointmentItem: React.FC<Props> = ({ status, itemData, children }) => {
  return (
    <View style={{ flex: 1, width: '100%' }}>
      <View
        style={[
          { ...mb10, display: 'flex', flexDirection: 'row', flex: 1, flexGrow: 1, justifyContent: 'space-between' },
        ]}>
        <View style={mr10}>
          <ProfileImageComponent
            src={itemData.patient.profile_image?.conversions?.avatar ?? itemData.patient.profile_image?.url}
            initials={getInitials({ first_name: itemData.patient.first_name, last_name: itemData.patient.last_name })}
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <H6NsSemiBoldBlack style={{ whiteSpace: 'pre-wrap', marginBottom: 5 }}>
            {getAccountName(itemData.patient)}
          </H6NsSemiBoldBlack>
          <View style={{ flexDirection: 'row' }}>
            <H5TtmRegularInfo>
              {parseDateTime(itemData.start_at || itemData.request_submitted_at, { outputFormat: 'D MMMM,' })}
            </H5TtmRegularInfo>
            <H5TtmRegularInfo>
              {parseDateTime(itemData.start_at || itemData.request_submitted_at, { outputFormat: TIME_FORMAT })}
            </H5TtmRegularInfo>
          </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <H5TtmRegularBlack style={mb5}> {ConsultationTypeMapping[itemData.type]}</H5TtmRegularBlack>
          <ConsultationTypeIcon consultation={itemData} />
        </View>
      </View>
      {children}
    </View>
  );
};
