import React, { forwardRef } from 'react';
import { TouchableOpacity } from 'react-native';

import { CustomDropdown } from './CustomDropdown';
import { DropdownProps } from './interfaces';

export const Dropdown = forwardRef<TouchableOpacity, DropdownProps>((props, ref) => {
  const {
    list,
    error,
    searchNoItems,
    setValue,
    value,
    placeholder,
    label,
    multiSelect,
    searchable = false,
    searchPlaceholder,
    errorMessage,
    dropDownStyle,
    dropDownContainerStyle,
    showMandatory,
    disabled,
  } = props;

  return (
    <CustomDropdown
      value={value}
      setValue={setValue}
      list={list}
      error={error}
      errorMessage={errorMessage}
      searchNoItems={searchNoItems}
      label={label}
      placeholder={placeholder}
      multiSelect={multiSelect}
      searchable={searchable}
      searchPlaceholder={searchPlaceholder}
      dropDownStyle={dropDownStyle}
      dropDownContainerStyle={dropDownContainerStyle}
      showMandatory={showMandatory}
      disabled={disabled}
    />
  );
});
