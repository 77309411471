import React, { useContext } from 'react';
import { Control } from 'react-hook-form';

import { AvailableDoctorModel } from '~/api/models/appointments/models/AvailableDoctorModel';
import { AvailableDoctorsForm } from '~/api/models/appointments/requests/AvailableDoctorsRequest';
import { AvailableDoctorsListingModel } from '~/api/models/appointments/responses/AvailableDoctorsResponse';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';

export interface IDoctorListingContext {
  doctors: Record<Exclude<ConsultationTypeEnum, ConsultationTypeEnum.CLINIC>, AvailableDoctorsListingModel>;
  loading: boolean;
  consultationTypes?: ConsultationTypeEnum[];
  filterControl: Control<AvailableDoctorsForm>;
  isListing?: boolean;
  updateFavourites: () => Promise<void>;
  setPage: (pageInfo: TablePaginationInfo, consultationType: ConsultationTypeEnum) => void;
  submitQuery: () => Promise<void>;
  selectedDoctorOrClinic: (
    doctorOrClinic: AvailableDoctorModel,
    consultationType: ConsultationTypeEnum,
    channel?: number
  ) => void;
}

export const DoctorListingContext = React.createContext<IDoctorListingContext>({} as IDoctorListingContext);

export const useDoctorListingContext = (): IDoctorListingContext => useContext(DoctorListingContext);
