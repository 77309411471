import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';

import { ConsultationModel } from '../../../api/models/consultations/models/ConsultationModel';
import { mb10, mt20 } from '../../../common/commonStyles';
import { H3TtmRegularBlack } from '../../commonText';
import { HealthRecord } from '../../doctor/patients/consultation/tabs/HealthRecord/HealthRecord';
import { Prescription } from '../../doctor/patients/consultation/tabs/Prescriptions/Prescription';
import { PrivateNote } from '../../doctor/patients/consultation/tabs/PrivateNote/PrivateNote';
import { Referrals } from '../../doctor/patients/consultation/tabs/Referrals/Referrals';
import { SickLeaveCertificate } from '../../doctor/patients/consultation/tabs/SickLeaveCertificate/SickLeaveCertificate';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { Checkbox } from '~/components/inputs/Checkbox';
import { ConsultationReportProvider, ConsultationReportProviderRef } from '~/contexts/ConsultationReportProvider';
import { HealthRecordDataProvider } from '~/providers/consultation/HealthRecordDataProvider';
import { PrescriptionDataProvider } from '~/providers/consultation/PrescriptionDataProvider';
import { PrivateNoteDataProvider } from '~/providers/consultation/PrivateNoteDataProvider';
import { ReferralDataProvider } from '~/providers/consultation/ReferralDataProvider';
import { SickLeaveDataProvider } from '~/providers/consultation/SickLeaveDataProvider';
import { isDoctorVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { errorMessages } from '~/utils/labels/errorMessages';
import { patientManagementLabels } from '~/utils/labels/patientManagement';

export interface ConsultationReportComponentRef {
  verify: () => Promise<boolean>;
  submit: () => Promise<void>;
  getIncludeSignature: () => boolean;
}
interface Props {
  consultation: ConsultationModel;
  isSubmitted?: boolean;
  hideTitle?: boolean;
  appointmentView?: boolean;
}
export const ConsultationReportComponent = forwardRef(
  ({ consultation, isSubmitted, hideTitle, appointmentView }: Props, ref) => {
    const { isMobile } = useBreakpoints();
    const [includeSignature, setIncludeSignature] = useState(false);

    const editable = useMemo(() => {
      return (consultation?.state !== ConsultationStateEnum.Submitted && isDoctorVersion()) ?? false;
    }, [consultation]);

    const canSubmit = useMemo(
      () =>
        (consultation?.state === ConsultationStateEnum.Ended || consultation?.state === ConsultationStateEnum.Draft) &&
        isDoctorVersion(),
      [consultation]
    );

    const consultationReportProviderRef = useRef<ConsultationReportProviderRef>();
    useImperativeHandle(ref, () => ({
      verify: () => consultationReportProviderRef.current?.triggerVerification(),
      submit: () => consultationReportProviderRef.current?.triggerAsyncSave(),
      getIncludeSignature: () => includeSignature,
    }));

    return (
      <View>
        {isMobile || hideTitle ? null : <H3TtmRegularBlack style={mb10}>{labels.consultationReport}</H3TtmRegularBlack>}

        <PrivateNoteDataProvider consultation={consultation}>
          <HealthRecordDataProvider consultation={consultation}>
            <PrescriptionDataProvider consultation={consultation}>
              <SickLeaveDataProvider consultation={consultation}>
                <ReferralDataProvider consultation={consultation}>
                  <ConsultationReportProvider ref={consultationReportProviderRef} appointmentView={appointmentView}>
                    {!isDoctorVersion() ? null : <PrivateNote editable={editable} />}
                    <HealthRecord editable={editable} />
                    <Prescription editable={editable} />
                    <SickLeaveCertificate editable={editable} />
                    <Referrals editable={editable} />
                  </ConsultationReportProvider>
                </ReferralDataProvider>
              </SickLeaveDataProvider>
            </PrescriptionDataProvider>
          </HealthRecordDataProvider>
        </PrivateNoteDataProvider>

        {editable && canSubmit ? (
          <View style={mt20}>
            <Checkbox
              checked={includeSignature}
              onChange={setIncludeSignature}
              label={patientManagementLabels.includeSignature}
              errorMessage={isSubmitted && !includeSignature ? errorMessages.signatureRequired : null}
              showMandatory
            />
          </View>
        ) : null}
      </View>
    );
  }
);
