import { DigimedFeatures, digimedFeatureEnabled } from '../milestoneUtil';
import { screenName } from '../screenName';

import { UserModel } from '~/api/models/authorisation/models/UserModel';
import { RegistrationMilestoneModel } from '~/api/models/registration/models/RegistrationMilestoneModel';
import { RegistrationStepModel } from '~/api/models/registration/models/RegistrationStepModel';

type SetNextSignature = (nextValue: string, step: RegistrationStepModel, params?: Record<string, any>) => boolean;
interface NeededUserDetails {
  isPartOfClinic?: boolean;
}
export interface ScreenProperties {
  name: string;
  params?: Record<string, any>;
}

export const getCurrentRegistrationScreen = (registrationSteps: RegistrationMilestoneModel[], user?: UserModel) => {
  if (!registrationSteps?.length) {
    return null;
  }

  const nextRoutes = getNextRegistrationScreen(registrationSteps, {
    isPartOfClinic: !!user?.clinic_account,
  });
  if (nextRoutes.next) return nextRoutes.next;

  if (nextRoutes.nextRequired) return nextRoutes.nextRequired;
  return null;
};

const getNextRegistrationScreen = (
  registrationSteps: RegistrationMilestoneModel[],
  neededUserDetails: NeededUserDetails
) => {
  if (!registrationSteps?.length) {
    return {};
  }

  let next: ScreenProperties = undefined;
  let nextRequired: ScreenProperties = undefined;

  const setNext = (nextValue: string, step: RegistrationStepModel, params?: Record<string, any>) => {
    if (!step.done) {
      if (!next) {
        next = { name: nextValue, params };
      }
      if (step.required && !nextRequired) {
        nextRequired = { name: nextValue, params };
        return true;
      }
    }
    return false;
  };

  for (let i = 0; i < registrationSteps.length; i++) {
    const registrationStep = registrationSteps[i];

    if (!registrationStep.completion.all) {
      switch (registrationStep.label) {
        case 'Registration':
        case 'Pharmacy Registration':
          getNextRegistrationScreen_Registration(registrationStep, setNext);
          break;
        case 'Verify Account':
          getNextRegistrationScreen_VerifyAccount(registrationStep, setNext);
          break;
        case 'Personal Details':
          getNextRegistrationScreen_PersonalDetails(registrationStep, setNext);
          break;
        case 'Doctor Verification':
          getNextRegistrationScreen_DoctorVerification(registrationStep, setNext);
          break;
        case 'Bank Account Details':
          if (!neededUserDetails?.isPartOfClinic)
            getNextRegistrationScreen_BankAccountDetails(registrationStep, setNext);
          break;
        case 'Preferences':
          if (!neededUserDetails?.isPartOfClinic) getNextRegistrationScreen_Preferences(registrationStep, setNext);
          break;
        case 'Pharmacy Details':
          getNextRegistrationScreen_PharmacyDetails(registrationStep, setNext);
          break;
      }
    }
    if (nextRequired) return { next, nextRequired };
  }
  return { next, nextRequired };
};

const getNextRegistrationScreen_Registration = (
  registrationStep: RegistrationMilestoneModel,
  setNext: SetNextSignature
) => {
  for (let i = 0; i < registrationStep.steps.length; i++) {
    const step = registrationStep.steps[i];
    if (!step.done) {
      switch (step.key) {
        case 'submit_mobile':
          if (setNext(screenName.RegisterScreen, step)) return;
          break;
        case 'accept_terms':
          if (setNext(screenName.RegisterTerms, step)) return;
          break;
      }
    }
  }
};

const getNextRegistrationScreen_VerifyAccount = (
  registrationStep: RegistrationMilestoneModel,
  setNext: SetNextSignature
) => {
  for (let i = 0; i < registrationStep.steps.length; i++) {
    const step = registrationStep.steps[i];
    if (!step.done) {
      switch (step.key) {
        case 'mobile_verify':
          if (setNext(screenName.RegistrationMobileOtp, step)) return;
          break;
        case 'passcode':
          if (setNext(screenName.PassCodeRegisterScreen, step)) return;
          break;
        case 'submit_email':
        case 'verify_email':
          if (setNext(screenName.EmailScreen, step)) return;
          break;
        case 'two_factor_preference':
          if (setNext(screenName.TwoStepSelection, step)) return;
          break;
        case 'identity_verification':
          if (setNext(screenName.UploadIdentificationScreen, step)) return;
          break;
      }
    }
  }
};

const getNextRegistrationScreen_PersonalDetails = (
  registrationStep: RegistrationMilestoneModel,
  setNext: SetNextSignature
) => {
  for (let i = 0; i < registrationStep.steps.length; i++) {
    const step = registrationStep.steps[i];
    if (!step.done) {
      switch (step.key) {
        case 'pharmacy_personal_details':
        case 'personal_details':
          if (setNext(screenName.PersonalDetails, step)) return;
          break;
        case 'submit_address':
          if (setNext(screenName.HomeAddress, step)) return;
          break;
        case 'submit_profile_image':
          if (setNext(screenName.UploadProfileScreen, step)) return;
          break;
      }
    }
  }
};

const getNextRegistrationScreen_DoctorVerification = (
  registrationStep: RegistrationMilestoneModel,
  setNext: SetNextSignature
) => {
  for (let i = 0; i < registrationStep.steps.length; i++) {
    const step = registrationStep.steps[i];
    if (!step.done) {
      switch (step.key) {
        case 'submit_medical_registration_number':
        case 'doctor_verification_documents':
          if (setNext(screenName.DoctorVerification, step)) return;
          break;
        case 'preferred_consultation_type':
          if (
            digimedFeatureEnabled(DigimedFeatures.Consultations) &&
            setNext(screenName.TypeOfConsultationScreen, step)
          )
            return;
          break;
      }
    }
  }
};

const getNextRegistrationScreen_BankAccountDetails = (
  registrationStep: RegistrationMilestoneModel,
  setNext: SetNextSignature
) => {
  for (let i = 0; i < registrationStep.steps.length; i++) {
    const step = registrationStep.steps[i];
    if (!step.done) {
      switch (step.key) {
        case 'bank_account_details':
          if (setNext(screenName.AddBankDetails, step)) return;
          break;
      }
    }
  }
};

const getNextRegistrationScreen_Preferences = (
  registrationStep: RegistrationMilestoneModel,
  setNext: SetNextSignature
) => {
  for (let i = 0; i < registrationStep.steps.length; i++) {
    const step = registrationStep.steps[i];
    if (!step.done) {
      switch (step.key) {
        case 'consultation_prices':
        case 'preferred_consultation_channel':
        case 'preferred_language':
        case 'consultation_availabilities':
          if (setNext(screenName.PreferencesScreen, step)) return;
          break;
      }
    }
  }
};

const getNextRegistrationScreen_PharmacyDetails = (
  registrationStep: RegistrationMilestoneModel,
  setNext: SetNextSignature
) => {
  for (let i = 0; i < registrationStep.steps.length; i++) {
    const step = registrationStep.steps[i];
    if (!step.done) {
      switch (step.key) {
        case 'pharmacy_details':
          if (setNext(screenName.RegisterPharmacyDetails, step)) return;
          break;
        case 'pharmacy_location':
          if (setNext(screenName.RegisterPharmacyLocations, step)) return;
          break;
      }
    }
  }
};
