import { useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

import { H4TtmSemiBoldBlack, H5TtmSemiBoldInfo, H5TtmSemiBoldTheme, H6NsRegularBlack } from '../commonText';

import { checkIsPremiumPlan } from '~/api/hooks/subscriptions/SubscriptionHook';
import { SubscriptionPlanMeResponse } from '~/api/models/subscriptions/responses/SubscriptionPlanMeResponse';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

interface Props {
  style?: StyleProp<ViewStyle>;
  currentPlan: SubscriptionPlanMeResponse;
}
export const SubscriptionTypeCardMobile: React.FC<Props> = ({ style, currentPlan }) => {
  const { isMobile } = useBreakpoints();
  const { navigate } = useNavigation<NavType>();
  const isPremium = useMemo(() => checkIsPremiumPlan(currentPlan), [currentPlan]);
  return (
    <TouchableOpacity onPress={() => navigate(screenName.ProfileSubscriptionPlan)}>
      <View style={style}>
        <View style={{ marginBottom: 10 }}>
          <H4TtmSemiBoldBlack>{labels.subscriptionPlan}</H4TtmSemiBoldBlack>
        </View>
        <View style={[styles.container, isMobile ? null : { width: 380 }]}>
          <View style={{ paddingVertical: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <H5TtmSemiBoldInfo>{isPremium ? labels.premiumPlan : labels.freePlan}</H5TtmSemiBoldInfo>

            {!isPremium ? <H5TtmSemiBoldTheme>{labels.upgrade.toUpperCase()}</H5TtmSemiBoldTheme> : null}
          </View>
          {isPremium ? (
            <View style={{ paddingVertical: 10 }}>
              <H6NsRegularBlack>
                {labels.nextDue}: {parseDateTime(currentPlan?.valid_until, { outputFormat: DATE_FORMAT })}
              </H6NsRegularBlack>
            </View>
          ) : null}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightPurple,
    borderRadius: 10,
    borderColor: colors.lightPurple,
    borderWidth: 1,
    paddingHorizontal: 10,
  },
});
