export const registrationLabels = {
  loginTitle: 'Welcome back!',
  loginSubtitleMobile: 'Welcome back to Digimed. Please enter your phone number to sign back in.',
  loginSubtitleEmail: 'Welcome back to Digimed. Please enter your email address to sign back in.',
  loginPasscodeSubtitle: 'Please enter the passcode you created during registration to sign in.',
  registerPasscodeTitle: 'Create a passcode on your Digimed account',
  registerPasscodeSubtitle: 'Please enter your passcode',

  forgotYourPasscode: 'Forgot your passcode',

  registerTitleMobile: 'Enter your phone number',
  registerTitleEmail: 'Enter your e-mail',
  registerSubtitle: 'Welcome to Digimed. Please enter your phone number below to create your account:',
  registerPharmacy:
    'Welcome to Digimed. Please enter your details below to create your account and register a new pharmacy.',
  changedPhoneNumberQuestion: 'Changed your phone number?',
  addPhoneNumberQuestion: 'Add your phone number?',
  changedPhoneNumber: 'changed your phone number?',
  changeCredential: 'change user?',

  verificationTitle: 'Verify your Identity',
  verificationDocumentsMessage1: 'We need to determine if an identity document is authentic and belongs to you. ',
  verificationDocumentsMessage2:
    'You just need to go through some steps which will help us to build a secure system together.',

  verificationErrorMessage: ['Something went wrong during your verification', 'Please try again'],
  verificationIncompleteMessage: [
    'Your verification process is incomplete.',
    'Please review your information and continue your verification.',
  ],
  verificationTemporarilyFailedMessage: ['Something is wrong with you verification'],
  verificationFailedMessage: ['Your verification process failed'],
  verificationPendingMessage: ['Your verification is pending.', 'Please continue your registration.'],
  verificationCompletedMessage: ['Your verification is complete.', 'Please continue your registration.'],
  verificationRetry: 'Retry verification',
  alliedProfessionalCheckbox: 'I am an Allied Professional',
  specialisationLabel: 'Search for and select your area(s) of specialisation',
};
