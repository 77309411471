import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import { AccountModel } from '../../api/models/accounts/models/AccountModel';
import { AccountsIndexResponse } from '../../api/models/accounts/responses/AccountsIndexResponse';
import { PaginationMetaModel } from '../../api/models/common/models/PaginationMetaModel';

import { AuthVerificationEnum } from '~/api/models/authorisation/constants/AuthVerificationEnum';

interface IState {
  accountStatus?: AuthVerificationEnum;
  accounts?: Record<number, AccountModel[]>;
  pagination?: PaginationMetaModel;
}

const initialState: IState = {
  accountStatus: null,
  accounts: undefined,
  pagination: undefined,
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    SET_APPROVAL_STATUS: (state, action: PayloadAction<AuthVerificationEnum>) => ({
      ...state,
      accountStatus: action.payload,
    }),
    SET_ACCOUNTS_PAGE: (state, action: PayloadAction<AccountsIndexResponse>) => ({
      ...state,
      accounts:
        action.payload.meta.current_page === 1
          ? { [action.payload.meta.current_page]: action.payload.data }
          : { ...state.accounts, [action.payload.meta.current_page]: action.payload.data },
      pagination: action.payload.meta,
    }),
    LOGOUT: (state) => ({
      ...initialState,
    }),
  },
});

export const { SET_ACCOUNTS_PAGE, SET_APPROVAL_STATUS, LOGOUT } = accountsSlice.actions;
export default accountsSlice.reducer;

const accounts = (state: RootState) => state.accountsReducer.accounts;
export const allAccountsSelector = createSelector([accounts], (accounts) => {
  return Object.keys(accounts || {})
    .sort((a, b) => +a - +b)
    .reduce((prev, page) => {
      const data = accounts[page];
      prev.push(...data);
      return prev;
    }, []);
});

export const accountApprovedSelector = (state: RootState) =>
  state.accountsReducer.accountStatus === AuthVerificationEnum.APPROVED;
