import React from 'react';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { MobileMenuPageComponent } from '~/components/navigation/MobileMenuPageComponent';
import { HEALTH_PROFILE_PAGES } from '~/constants/patients/HealthProfileMobilePages';
import { colors } from '~/utils/colors';
import { screenName } from '~/utils/screenName';

export const HealthProfileMobile: React.FC = () => {
  const { userDetails } = useUserDetails();
  return (
    <PatientsLayout
      title="Health Profile"
      hideHeaderRight
      style={{ backgroundColor: colors.lightPurple }}
      flexContent={false}
      back={{ to: { screen: screenName.ProfileScreen } }}>
      <MobileMenuPageComponent menu={HEALTH_PROFILE_PAGES} params={{ id: userDetails?.account.id } as never} />
    </PatientsLayout>
  );
};
