import React, { useEffect, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { usePolling } from '~/api/hooks/Polling';
import { mr5, mt20, pb20, textCenter } from '~/common/commonStyles';
import { Button, OutlineButton } from '~/components/commonButton';
import { H1TtmSemiBoldBlack, H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { PatientGroup } from '~/components/svgImages';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { usePageFocusState } from '~/utils/hooks/FocusHook';
import { useWaitingRoomCountdown } from '~/utils/hooks/TimerHook';
import { useAppointmentState } from '~/utils/hooks/appointments/AppointmentStateHook';
import { labels } from '~/utils/labels';

export const ConsultationOnDemandScheduled: React.FC = () => {
  const { updateConsultation, estimatedWait, updateEstimate, loading, cancelAppointment, consultation } =
    useAppointmentContext();

  const { notStarted } = useAppointmentState(consultation);
  const { time, stopTimer, isOver } = useWaitingRoomCountdown(estimatedWait?.estimated);

  const { startPolling, stopPolling, isPolling } = usePolling(updateEstimate, 30000);

  useEffect(() => {
    if (!isPolling()) {
      startPolling({ immediate: true });
    }

    return () => stopPolling();
  }, []);

  usePageFocusState((focused) => {
    if (!focused) {
      stopPolling();
    }
  });

  useEffect(() => {
    if (isOver && estimatedWait?.queue === 1) {
      stopPolling();
      stopTimer();
    }
  }, [isOver, estimatedWait]);

  const queueIndex = useMemo(() => {
    if (!estimatedWait?.queue) return '-';
    return estimatedWait.queue;
  }, [estimatedWait]);

  return (
    <Column>
      <View style={styles.headers}>
        <H3TtmSemiBoldBlack>You will soon be connected</H3TtmSemiBoldBlack>
        {estimatedWait?.queue > 1 ? (
          <H6NsRegularBlack style={{ display: 'flex', alignItems: 'center' }}>
            <View style={mr5}>
              <PatientGroup width={25} height={25} color={colors.black} />
            </View>
            Live queue: {queueIndex}
          </H6NsRegularBlack>
        ) : null}
      </View>

      <View style={styles.timer}>
        {isOver ? (
          <View>
            <H1TtmSemiBoldBlack style={textCenter}>Your doctor will be with you shortly</H1TtmSemiBoldBlack>

            <View style={mt20}>
              <Button label={labels.join} funCallback={updateConsultation} disabled={loading} />
            </View>
          </View>
        ) : estimatedWait ? (
          <View>
            <H6NsRegularBlack style={textCenter}>Estimated wait time</H6NsRegularBlack>
            <H1TtmSemiBoldBlack>{time}</H1TtmSemiBoldBlack>
          </View>
        ) : (
          <></>
        )}
      </View>
      {notStarted ? (
        <View style={pb20}>
          <OutlineButton danger funCallback={cancelAppointment} label={labels.cancelAppointment} disabled={loading} />
        </View>
      ) : null}
    </Column>
  );
};

const styles = StyleSheet.create({
  headers: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timer: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: colors.lightPurple2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 60,
    marginBottom: 20,
  },
});
