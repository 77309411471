import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { DynamicConsultationTypeToIcon } from '~/api/models/common/constants/ConsultationTypeToIcon';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { mb10, mb20, mt20, wizardStyle } from '~/common/commonStyles';
import { SelectableCard } from '~/components/cards/SelectableCard';
import { Button, TextButton } from '~/components/commonButton';
import { FormErrorMessage, H5TtmSemiBoldBlack } from '~/components/commonText';
import { ConsultationTypeLearnMoreModal } from '~/components/modals/ConsultationTypeLearnMoreModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { WizardLayout } from '~/layouts/WizardLayout';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { requiredValidation } from '~/services/validationConfig';
import { labels } from '~/utils/labels';
import { patientLabels } from '~/utils/labels/patient';
import { timeout } from '~/utils/promiseUtil';

const ConsultationTypesDescription = {
  [ConsultationTypeEnum.HOME_VISIT]: 'Schedule a home visit',
  [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: 'Schedule for later',
  [ConsultationTypeEnum.ON_DEMAND]: 'Search for available doctors online',
};

export const ConsultationTypeSelectionStep: React.FC = () => {
  const { formControl, loading, validateRequest, updateSteps } = useAppointmentBookingContext();
  const { registerModal, openModal } = useModalManager();
  const { hasFeatureAccess } = useFeatureAccess();

  useEffect(() => {
    registerModal(ModalName.ConsultationTypeLearnMoreModal, ConsultationTypeLearnMoreModal);
  }, []);

  const card = (
    consultationType: ConsultationTypeEnum,
    value: ConsultationTypeEnum,
    onPress: (type: ConsultationTypeEnum) => void,
    disabled?: boolean
  ) => (
    <>
      <SelectableCard
        onPress={() => onPress(consultationType)}
        icon={(color) => DynamicConsultationTypeToIcon[consultationType]({ color, width: 22, height: 22 })}
        label={ConsultationTypesDescription[consultationType]}
        selected={consultationType === value}
        style={mb20}
        disabled={!!disabled}
        centerVertical
      />
    </>
  );

  const submit = async () => {
    await validateRequest(async () => {
      updateSteps();

      await timeout(500);
    });
  };

  const buttons = [
    <Button label={labels.next} funCallback={submit} disabled={loading} />,
    <TextButton
      funCallback={() => {
        openModal(ModalName.ConsultationTypeLearnMoreModal);
      }}
      label={labels.learnMore.toUpperCase()}
      disabled={loading}
    />,
  ];

  return (
    <View style={wizardStyle.container}>
      <WizardLayout
        title={patientLabels.chooseConsultation}
        subtitle={patientLabels.chooseConsultationSubtitle}
        buttons={buttons}>
        <Controller
          control={formControl}
          name="type"
          render={({ field: { value, onChange }, formState: { isSubmitted }, fieldState: { error } }) => {
            const onPress = (type: ConsultationTypeEnum) => {
              if (value === type) {
                onChange(null);
              } else {
                onChange(type);
              }
            };
            return (
              <View style={{ width: '100%' }}>
                <H5TtmSemiBoldBlack style={mb10}>{patientLabels.onlineConsultations}</H5TtmSemiBoldBlack>
                {card(ConsultationTypeEnum.ON_DEMAND, value, onPress)}
                {card(ConsultationTypeEnum.SCHEDULED_APPOINTMENT, value, onPress)}
                <H5TtmSemiBoldBlack style={[mt20, mb10]}>{labels.homeVisit}</H5TtmSemiBoldBlack>
                {card(
                  ConsultationTypeEnum.HOME_VISIT,
                  value,
                  onPress,
                  !hasFeatureAccess(FeatureAccessEnum.HOME_VISITS)
                )}

                {isSubmitted && error?.message ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
              </View>
            );
          }}
          rules={{
            required: requiredValidation(labels.consultationType),
          }}
        />
      </WizardLayout>
    </View>
  );
};
