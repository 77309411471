import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { usePolling } from '../Polling';
import { useStoredDoctorIds } from '../doctors/StoredDoctorIdsHook';

import { apiGetOnDemandDoctorsQueue } from '~/api/services/consultations/waitingRoom';
import { RootState } from '~/redux/reducers';
import { SET_ON_DEMAND_QUEUE, SET_ON_DEMAND_QUEUE_LOADING } from '~/redux/reducers/patient/doctorListingReducer';
import { useAppDispatch } from '~/redux/store';
import { usePageFocusState } from '~/utils/hooks/FocusHook';

interface Props {
  isOnDemand: boolean;
}
export function useOnDemandDoctorsQueue({ isOnDemand }: Props) {
  const dispatch = useAppDispatch();
  const loadingInternal = useRef(false);

  const { allDoctorIds } = useStoredDoctorIds();

  const onDemandQueues = useSelector((state: RootState) => state.doctorListingReducer.onDemandQueue.data);
  const loading = useSelector((state: RootState) => state.doctorListingReducer.onDemandQueue.loading);

  const getOnDemandQueues = async (doctorIds: number[]) => {
    if (!doctorIds?.length || loading || loadingInternal.current) return;
    try {
      loadingInternal.current = true;
      dispatch(SET_ON_DEMAND_QUEUE_LOADING(true));
      const res = await apiGetOnDemandDoctorsQueue({
        doctors: doctorIds,
      });
      dispatch(SET_ON_DEMAND_QUEUE(res.data));
    } catch {
      dispatch(SET_ON_DEMAND_QUEUE_LOADING(false));
    }
    loadingInternal.current = false;
  };

  const updateOnDemandQueues = () => {
    getOnDemandQueues(allDoctorIds);
  };

  const { startPolling, stopPolling } = usePolling(updateOnDemandQueues, 30000);

  usePageFocusState(
    (focused) => {
      if (!allDoctorIds?.length) return;
      if (!focused || !isOnDemand) {
        stopPolling();
      } else {
        startPolling({ immediate: true });
      }
    },
    [isOnDemand, allDoctorIds]
  );

  return { updateOnDemandQueues, onDemandQueues, loading };
}
