import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useController } from 'react-hook-form';
import { View } from 'react-native';

import { DoctorListingAndFiltering } from '../../doctorListing/pages/DoctorListingAndFiltering';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConfirmationAlert } from '~/common/commonMethods';
import { flex1 } from '~/common/commonStyles';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import { NavType } from '~/navigation/types';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { DoctorListingProvider } from '~/providers/appointment/DoctorListingProvider';
import { SET_BOOKING_TYPE } from '~/redux/reducers/patient/appointmentReducer';
import { useAppDispatch } from '~/redux/store';
import { transformLabels } from '~/utils/labelUtil';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';
import { screenName } from '~/utils/screenName';

export const ListDoctorsStep: React.FC = () => {
  const {
    formControl,
    updateSteps,
    wizard: {
      actions: { next, previous },
    },
  } = useAppointmentBookingContext();
  const dispatch = useAppDispatch();

  const {
    field: { value: type, onChange: onChangeType },
  } = useController({ control: formControl, name: 'type' });

  const {
    field: { onChange: onChangeDoctor },
  } = useController({ control: formControl, name: 'doctor_id' });

  const {
    field: { onChange: onChangeClinic },
  } = useController({ control: formControl, name: 'clinic_account_id' });

  const {
    field: { onChange: onChangeChannelId },
  } = useController({ control: formControl, name: 'channel_id' });

  const { navigate } = useNavigation<NavType>();

  const noAvailableDoctorAlert = (consultationType: ConsultationTypeEnum) => {
    ConfirmationAlert(transformLabels(appointmentLabels.appointmentNoAvailableDoctorsMessage[consultationType]), {
      type: ModalAlertTypeEnum.ERROR,
      title: appointmentLabels.noAvailableDoctorsTitle,
      okTitle:
        consultationType === ConsultationTypeEnum.HOME_VISIT
          ? labels.goBack
          : appointmentLabels.appointmentNoAvailableDoctorsButton,
      okFunction:
        consultationType === ConsultationTypeEnum.HOME_VISIT
          ? changeConsultationTime
          : changeConsultationTypeToOnDemand,
      cancelTitle:
        consultationType === ConsultationTypeEnum.HOME_VISIT
          ? appointmentLabels.appointmentNoAvailableDoctorsButton
          : labels.cancel,
      cancelFunction:
        consultationType === ConsultationTypeEnum.HOME_VISIT ? changeConsultationTypeToOnDemand : undefined,
    });
  };

  const changeConsultationTypeToOnDemand = () => {
    dispatch(SET_BOOKING_TYPE(ConsultationTypeEnum.ON_DEMAND));
    onChangeType(ConsultationTypeEnum.ON_DEMAND);
    navigate(screenName.AppointmentsBooking);
    updateSteps();
  };

  const changeConsultationTime = () => {
    previous();
  };

  return (
    <View style={flex1}>
      <DoctorListingProvider
        consultationTypes={[type]}
        onSelectDoctorOrClinic={(doctorOrClinic, consultationType, channel) => {
          if (doctorOrClinic.clinic_name) {
            onChangeClinic(doctorOrClinic.id);
          } else {
            onChangeDoctor(doctorOrClinic.id);
          }
          onChangeChannelId(channel);
          next();
        }}
        changeConsultationTypeToOnDemand={changeConsultationTypeToOnDemand}
        changeConsultationTime={changeConsultationTime}
        noAvailableDoctorAlert={noAvailableDoctorAlert}>
        <DoctorListingAndFiltering />
      </DoctorListingProvider>
    </View>
  );
};
