import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Route } from '@react-navigation/native';
import React from 'react';

import { screenName } from '../../utils/screenName';

import { PageTabsComponent } from '~/components/tabs/PageTabsComponent';
import { dependantsProfileMenu } from '~/constants/dependantsProfileMenu';
import { DependantAddressScreen } from '~/pages/patient/dependants/DependantAddressScreen';
import { DependantHealthProfile } from '~/pages/patient/dependants/DependantHealthProfile';
import { DependantLinkedAccounts } from '~/pages/patient/dependants/DependantLinkedAccounts';
import { DependantPersonalInfoScreen } from '~/pages/patient/dependants/DependantPersonalInfoScreen';
import { isNative } from '~/utils/buildConfig';

const Tab = createMaterialTopTabNavigator();

interface Props {
  route: Route<string, { id: string }>;
}
export const DependantProfileNavigation: React.FC<Props> = ({ route }) => {
  return (
    <Tab.Navigator
      tabBar={() => <PageTabsComponent items={dependantsProfileMenu} />}
      screenOptions={{ swipeEnabled: isNative() }}
      initialRouteName={screenName.DependantPersonalInfo}>
      <Tab.Screen
        name={screenName.DependantPersonalInfo}
        initialParams={route?.params}
        component={DependantPersonalInfoScreen}
      />
      <Tab.Screen name={screenName.DependantAddress} initialParams={route?.params} component={DependantAddressScreen} />
      <Tab.Screen
        name={screenName.DependantHealthProfile}
        initialParams={route?.params}
        component={DependantHealthProfile}
      />
      <Tab.Screen
        name={screenName.DependantLinkedAccounts}
        initialParams={route?.params}
        component={DependantLinkedAccounts}
      />
    </Tab.Navigator>
  );
};
