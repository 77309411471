import { createNavigationContainerRef } from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef();

let navigateWhenReady = null;
export function rootNavigate(name: string, params?: any) {
  if (navigationRef.isReady()) {
    // Perform navigation if the react navigation is ready to handle actions
    try {
      // @ts-ignore
      navigationRef.navigate(name as never, params as never);
    } catch {}
  } else {
    navigateWhenReady = { name, params };
    setTimeout(() => {
      if (navigateWhenReady) {
        const localNav = navigateWhenReady;
        navigateWhenReady = null;
        rootNavigate(localNav.name, localNav.params);
      }
    }, 100);
  }
}
