import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';

import { isNative } from '../buildConfig';
import { fileSizeCheck, imagePickersAssetsToDocumentResults } from '../files/documentResultUtil';

import { checkMediaLibraryPermission, mediaLibraryPermission, checkCameraPermission } from '~/common/permissions';
import { DefaultAllowedFormats, ImageFormats } from '~/constants/documentUploadsConstants';

export function useUploadDocumentHook() {
  const launchImagePicker = async () => {
    if (isNative()) {
      const { status } = await checkMediaLibraryPermission();
      if (status !== 'granted') {
        await mediaLibraryPermission();
      }
      const mediaOptions: ImagePicker.ImagePickerOptions = {
        quality: 0.15,
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
      };
      const media = await ImagePicker.launchImageLibraryAsync(mediaOptions);
      if (!media.canceled) {
        fileSizeCheck(media.assets, true);
        return imagePickersAssetsToDocumentResults(media.assets);
      }
    } else {
      const result = await launchFilePicker({ allowedFormats: ImageFormats });
      if (result?.length) return result;
    }
    return null;
  };

  const launchFilePicker = async (options?: { allowedFormats?: string[] }) => {
    const result = await DocumentPicker.getDocumentAsync({
      type: options?.allowedFormats ? options.allowedFormats : DefaultAllowedFormats,
      copyToCacheDirectory: true,
    });

    if (!result.canceled) {
      fileSizeCheck(result.assets, false);

      return result.assets;
    } else {
      return null;
    }
  };

  const launchCamera = async () => {
    const { status } = await checkCameraPermission();
    if (status !== 'granted') {
      throw new Error('Camera permission was denied');
    } else {
      const mediaOptions = { quality: 0.15, base64: false };
      const media = await ImagePicker.launchCameraAsync(mediaOptions);
      if (!media.canceled) {
        fileSizeCheck(media.assets, true);

        return imagePickersAssetsToDocumentResults(media.assets);
      }
    }
  };

  return { launchCamera, launchFilePicker, launchImagePicker };
}
