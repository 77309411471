import { deleteReq, get, post, put } from '../../axios';

import { CreatePrivateNoteRequest } from '~/api/models/consultations/requests/CreatePrivateNoteRequest';
import { DeletePrivateNoteRequest } from '~/api/models/consultations/requests/DeletePrivateNoteRequest';
import { GetPrivateNoteRequest } from '~/api/models/consultations/requests/GetPrivateNoteRequest';
import { UpdatePrivateNoteRequest } from '~/api/models/consultations/requests/UpdatePrivateNoteRequest';
import { PrivateNoteResponse } from '~/api/models/consultations/responses/PrivateNoteResponse';

export const apiCreatePrivateNote = (req: CreatePrivateNoteRequest) => {
  return post<PrivateNoteResponse>('/private-notes', req);
};

export const apiDeletePrivateNote = (req: DeletePrivateNoteRequest) => {
  return deleteReq(`/private-notes/${req.id}`);
};

export const apiUpdatePrivateNote = (req: UpdatePrivateNoteRequest) => {
  return put<PrivateNoteResponse>(`/private-notes/${req.id}`, req.params);
};

export const apiGetPrivateNote = (req: GetPrivateNoteRequest) => {
  return get<PrivateNoteResponse>(`/consultations/${req.consultation_Id}/private-note`);
};
