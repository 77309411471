import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { useDependantsHook } from '~/api/hooks/dependants/DependantsHook';
import { DependantStateEnum } from '~/api/models/dependants/constants/DependantStateEnum';
import { wizardStyle } from '~/common/commonStyles';
import { LinkButton } from '~/components/buttons/LinkButton';
import { Button } from '~/components/commonButton';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { WizardLayout } from '~/layouts/WizardLayout';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { requiredValidation } from '~/services/validationConfig';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';
import { dependantsLabels } from '~/utils/labels/dependantsLabels';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { screenName } from '~/utils/screenName';

export const SelectPatientStep: React.FC = () => {
  const { formControl, loading, validateRequest } = useAppointmentBookingContext();

  const buttons = [<Button label={labels.next} funCallback={validateRequest} disabled={loading} />];

  const { userDetails } = useUserDetails({ allowStale: true });
  const { dependants } = useDependantsHook({ state: DependantStateEnum.Approved });

  const dropdownValues = useMemo(() => {
    return [
      { label: getAccountName(userDetails?.account), value: userDetails?.account.id },
      ...(dependants?.map((d) => ({ label: getAccountName(d.account), value: d.account.id })) ?? []),
    ];
  }, [dependants, userDetails]);
  return (
    <View style={wizardStyle.container}>
      <WizardLayout title={appointmentLabels.selectPatientTitle} buttons={buttons}>
        <Controller
          control={formControl}
          name="patient_id"
          render={({ field: { value, onChange }, formState: { isSubmitted }, fieldState: { error } }) => (
            <View style={{ width: '100%' }}>
              <Dropdown
                list={dropdownValues}
                value={value}
                setValue={onChange}
                error={isSubmitted && !!error}
                errorMessage={isSubmitted && error?.message}
                label={appointmentLabels.selectPatientLabel}
              />
            </View>
          )}
          rules={{
            required: requiredValidation(appointmentLabels.member),
          }}
        />

        <View style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', marginTop: 20 }}>
          <LinkButton
            to={{ screen: screenName.DependantsAddNew as never, params: undefined as never }}
            title={dependantsLabels.addNewFamilyMember}
          />
        </View>
      </WizardLayout>
    </View>
  );
};
