import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { H6NsRegularBlack } from '../../commonText';
import FloatingInput from '../../inputs/FloatingInput';
import { MandatoryAsterisks } from '../../inputs/MandatoryAsterisks';

import { PrescriptionCollectRequest } from '~/api/models/pharmacy/requests/PrescriptionCollectRequest';
import { apiManualCollectPrescription } from '~/api/services/pharmacy';
import { mt20 } from '~/common/commonStyles';
import { requiredValidation } from '~/services/validationConfig';
import { Container, Row, Column } from '~/theme/components/grid';

export interface PrescriptionManualCollectionFormRef {
  submit: () => Promise<boolean>;
}
interface Props {
  prescriptionId: number;
}
export const PrescriptionManualCollectionForm = forwardRef<PrescriptionManualCollectionFormRef, Props>(
  ({ prescriptionId }, ref) => {
    const { handleSubmit, control } = useForm<PrescriptionCollectRequest>({
      mode: 'all',
      reValidateMode: 'onBlur',
      defaultValues: {
        prescriptionId,
        params: {
          pharmacy_name: '',
          pharmacist_name: '',
          pharmacist_warrant_number: '',
        },
      },
    });

    const onSubmit = async (data: PrescriptionCollectRequest) => {
      await apiManualCollectPrescription(data);

      return true;
    };

    const submit = async () => {
      return new Promise<boolean>((resolve, reject) => {
        handleSubmit(
          (data) => {
            onSubmit(data)
              .then((res) => resolve(res))
              .catch(reject);
          },
          () => resolve(undefined)
        )();
      });
    };

    useImperativeHandle(ref, () => ({
      submit,
    }));
    return (
      <Container maxContainerWidths={380}>
        <Row style={mt20}>
          <Column>
            <H6NsRegularBlack>
              Pharmacy name
              <MandatoryAsterisks isMandatory />
            </H6NsRegularBlack>
            <Controller
              name="params.pharmacy_name"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <FloatingInput
                  value={value}
                  onChangeValue={onChange}
                  onBlur={onBlur}
                  error={isSubmitted && !!error?.message}
                  errorMessage={isSubmitted && error?.message}
                  placeholder="Pharmacy name"
                  showMandatory
                />
              )}
              rules={{
                required: requiredValidation('Pharmacy name'),
              }}
            />
            <H6NsRegularBlack style={mt20}>
              Pharmacist name
              <MandatoryAsterisks isMandatory />
            </H6NsRegularBlack>
            <Controller
              name="params.pharmacist_name"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <FloatingInput
                  value={value}
                  onChangeValue={onChange}
                  onBlur={onBlur}
                  error={isSubmitted && !!error?.message}
                  errorMessage={isSubmitted && error?.message}
                  placeholder="Name surname"
                  showMandatory
                />
              )}
              rules={{
                required: requiredValidation('Pharmacist name'),
              }}
            />
            <H6NsRegularBlack style={mt20}>
              Pharmacist warrant number
              <MandatoryAsterisks isMandatory />
            </H6NsRegularBlack>
            <Controller
              name="params.pharmacist_warrant_number"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <FloatingInput
                  value={value}
                  onChangeValue={onChange}
                  onBlur={onBlur}
                  error={isSubmitted && !!error?.message}
                  errorMessage={isSubmitted && error?.message}
                  placeholder="Warrant number"
                  showMandatory
                />
              )}
              rules={{
                required: requiredValidation('Pharmacist warrant number'),
              }}
            />
          </Column>
        </Row>
      </Container>
    );
  }
);
