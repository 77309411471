import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { DaySelectionCarousel } from '../../common/DaySelectionCarousel';
import { H3TtmSemiBoldBlack, H5NsSemiBoldSecondaryBlack, H6NsRegularBlack } from '../../commonText';
import DatePicker from '../../inputs/dateTime/DatePicker';
import { Dropdown } from '../../inputs/dropdown/Dropdown';
import { RepeatIcon } from '../../svgImages';

import { ScheduledAppointmentsModel } from '~/api/models/preferences/models/ScheduledAppointmentsModel';
import { ScheduledAppointmentsModelWithId } from '~/api/models/preferences/models/ScheduledAppointmentsWithIdModel';
import { mb10, mb20, ml25, mr10, mr25, mt10, mt20, mt25, pb20 } from '~/common/commonStyles';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { ScheduledAvailabilityMultipleSlotsTimePicker } from '~/components/common/ScheduledAvailabilityMultipleSlotsTimePicker';
import { AppointmentDuration } from '~/constants/appointments/appointmentDuration';
import { dateGreaterThanValidation, dateInRangeValidation, requiredValidation } from '~/services/validationConfig';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { currentDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props {
  isSmallScreen?: boolean;
  scheduledAvailability?: ScheduledAppointmentsModelWithId;
  hideTitle?: boolean;
  onSave: (scheduledAvailability: ScheduledAppointmentsModel | ScheduledAppointmentsModelWithId) => void;
  onCancel?: () => void;
}

const DefaultScheduledAvailability = {
  start_date: '',
  repeat_until_date: '',
  slot_duration_in_minutes: undefined,
  days_of_week: [],
  time_ranges: [{ start_time: '00:00', end_time: '23:59' }],
};
export const ScheduledAvailabilityEditMode: React.FC<Props> = ({
  isSmallScreen,
  hideTitle,
  scheduledAvailability,
  onSave,
  onCancel,
}) => {
  const { isMobile } = useBreakpoints();
  const { control, handleSubmit, reset, watch } = useForm<ScheduledAppointmentsModel>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      ...DefaultScheduledAvailability,
      ...scheduledAvailability,
    },
  });

  useEffect(() => {
    if (!scheduledAvailability) reset({ ...DefaultScheduledAvailability });
    else {
      reset({
        ...scheduledAvailability,
      });
    }
  }, [scheduledAvailability]);

  const isUpdating = useMemo(() => !!scheduledAvailability?.group_id, [scheduledAvailability]);
  const year = new Date().getFullYear();
  const minYear = new Date(year - 5, 0, 1);
  const maxYear = new Date(year + 5, 11, 31);

  return (
    <>
      <Row style={mt25}>
        <Column span={isSmallScreen ? 12 : { xs: 12, md: 6 }}>
          <H3TtmSemiBoldBlack>{!isUpdating ? labels.addAvailability : labels.updateAvailability}</H3TtmSemiBoldBlack>
        </Column>
      </Row>
      <Row style={{ flexDirection: 'row', flexWrap: 'wrap', paddingBottom: 20 }}>
        <Column
          span={isSmallScreen ? 12 : { xs: 12, md: 6 }}
          style={[isMobile || isSmallScreen ? styles.mobileSeparator : styles.webSeparator, mb10]}>
          <View style={[mt20, isMobile || isSmallScreen ? null : mr25]}>
            <H6NsRegularBlack>{labels.chooseAppointmentDuration}</H6NsRegularBlack>
            <Controller
              name="slot_duration_in_minutes"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Dropdown
                  list={AppointmentDuration}
                  value={value}
                  setValue={onChange}
                  errorMessage={error?.message}
                  error={!!error}
                  label="Appointment duration"
                  showMandatory
                />
              )}
              rules={{
                required: requiredValidation('Appointment duration'),
              }}
            />
            <View style={isMobile || isSmallScreen ? styles.messageMobile : styles.messageWeb}>
              <H6NsRegularBlack>{labels.visitorsScheduleAbility}</H6NsRegularBlack>
            </View>
            <Controller
              name="start_date"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DatePicker
                  value={value}
                  onChangeValue={onChange}
                  label="Start date"
                  errorMessage={error?.message}
                  error={!!error}
                  showMandatory
                  minDate={minYear}
                  maxDate={maxYear}
                />
              )}
              rules={{
                required: requiredValidation('Start date'),
                validate: {
                  dateInRange: dateInRangeValidation('Start date', minYear, maxYear),
                  dateGreaterThanValidation: dateGreaterThanValidation(
                    () => currentDateTime(),
                    "Today's date",
                    'Start date'
                  ),
                },
              }}
            />
            <View style={isMobile ? styles.messageMobile : styles.messageWeb}>
              <H6NsRegularBlack>{labels.addTimeSlotScheduledAppointment}</H6NsRegularBlack>
            </View>
            <Controller
              control={control}
              name="time_ranges"
              render={({ field: { value } }) =>
                value ? <ScheduledAvailabilityMultipleSlotsTimePicker control={control} /> : null
              }
              rules={{
                required: requiredValidation('Availability slots'),
              }}
            />
          </View>
        </Column>
        {isMobile ? null : <View style={{ flex: 0.05 }} />}
        <Column span={isSmallScreen ? 12 : { xs: 12, md: 6 }} style={styles.columns}>
          <View>
            <View style={styles.messageRow}>
              <View style={mr10}>
                <RepeatIcon width={15} height={15} />
              </View>
              <H5NsSemiBoldSecondaryBlack>{labels.repeatAvailability}</H5NsSemiBoldSecondaryBlack>
            </View>
            <H6NsRegularBlack style={mt10}>{labels.repeatAvailabilityMessage}</H6NsRegularBlack>
            <Controller
              name="days_of_week"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DaySelectionCarousel passedDates={value} onChange={onChange} errorMessage={error?.message} />
              )}
              rules={{
                required: requiredValidation('Availability days'),
              }}
            />
            <View style={[mt20, mb20]}>
              <H6NsRegularBlack>{labels.repeatScheduleUntil}</H6NsRegularBlack>
              <Controller
                name="repeat_until_date"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <DatePicker
                    value={value}
                    onChangeValue={onChange}
                    label="Date"
                    errorMessage={isSubmitted && error?.message}
                    error={isSubmitted && !!error}
                    showMandatory
                    minDate={minYear}
                    maxDate={maxYear}
                  />
                )}
                rules={{
                  required: requiredValidation('Repeat until date'),
                  validate: {
                    dateGreaterThanValidation: dateGreaterThanValidation(
                      () => watch(`start_date`),
                      'Start Date',
                      'Repeat Until Date'
                    ),
                    dateInRange: dateInRangeValidation('Repeat until date', minYear, maxYear),
                  },
                }}
              />
            </View>
          </View>
        </Column>
      </Row>
      <Row style={pb20}>
        <Column>
          <CancelContinueFooter
            onConfirm={handleSubmit(onSave)}
            confirmTitle={!isUpdating ? labels.addAvailability : labels.updateAvailability}
            hideCancel={!onCancel}
            onCancel={onCancel}
            cancelTitle={labels.cancel}
            stacked={isSmallScreen}
          />
        </Column>
      </Row>
    </>
  );
};

const styles = StyleSheet.create({
  mobileSeparator: {
    paddingBottom: 20,
    borderBottomColor: colors.lightPurple2,
    borderBottomWidth: 1,
  },
  webSeparator: {
    borderRightColor: colors.lightPurple2,
    borderRightWidth: 1,
  },
  map: { paddingVertical: 50, flex: 1, height: 300, width: 400, borderRadius: 10 },
  columns: {
    flex: 1,
    minWidth: 200,
  },
  mobileSpacing: {
    marginTop: 20,
  },
  webSpacing: {
    marginLeft: 25,
  },
  messageWeb: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  },
  messageMobile: {
    display: 'flex',
    marginTop: 10,
  },
  messageRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
});
