import { useEffect, useState } from 'react';

import { SumsubApplicantDataResponse } from '../models/kyi/responses/SumsubApplicantDataResponse';
import { apiSumsubApplicantData } from '../services/kyi';

import { isPatientVersion } from '~/utils/buildConfig';

export function useSumSubApplicantData(): SumsubApplicantDataResponse | null {
  const [sumSubApplicantDetails, setSumSubApplicantDetails] = useState<SumsubApplicantDataResponse | null>(null);

  useEffect(() => {
    if (!isPatientVersion()) return;

    apiSumsubApplicantData()
      .then((response) => {
        setSumSubApplicantDetails(response.data);
      })
      .catch(() => {});
  }, []);
  return sumSubApplicantDetails;
}
