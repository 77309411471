import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import { UserModel } from '../../api/models/authorisation/models/UserModel';
import { RegistrationMilestoneModel } from '../../api/models/registration/models/RegistrationMilestoneModel';
import { removeAsyncItem, setAsyncItem, storageKeys } from '../../common/asyncStorage';
import { UserLoginDetailsModel } from '../models/userDetails/UserLoginDetailsModel';

import { IsAllowedAccessResponse } from '~/api/models/access/responses/IsAllowedAccessResponse';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { AuthVerificationStatusResponse } from '~/api/models/authorisation/responses/AuthVerificationStatusResponse';
import { MedicalRegistrationModel } from '~/api/models/medicalRegistration/models/MedicalRegistrationModel';
import { FeatureAccessModel } from '~/api/models/subscriptions/models/FeatureAccessModel';
import { FeatureAccessResponse } from '~/api/models/subscriptions/responses/FeatureAccessResponse';
import { SubscriptionPlanMeResponse } from '~/api/models/subscriptions/responses/SubscriptionPlanMeResponse';

interface IState {
  userDetails?: UserModel;
  userLastUpdated?: Date;
  subscriptionPlan?: SubscriptionPlanMeResponse;
  loginDetails?: UserLoginDetailsModel;
  registrationStatusLoading?: boolean;
  registrationStatusCompletion?: {
    preKyi?: boolean;
    preDigimed?: boolean;
    all?: boolean;
  };
  registrationStatus?: RegistrationMilestoneModel[];
  verificationStatus?: AuthVerificationStatusResponse;
  medicalRegistration?: MedicalRegistrationModel;
  biography?: string;
  access?: IsAllowedAccessResponse;
  featureAccess?: FeatureAccessResponse['features'];
  hasUnreadNotifications: boolean;
}

const initialState: IState = {
  userDetails: undefined,
  userLastUpdated: undefined,
  subscriptionPlan: undefined,
  loginDetails: undefined,
  registrationStatusLoading: undefined,
  registrationStatusCompletion: undefined,
  registrationStatus: undefined,
  verificationStatus: undefined,
  medicalRegistration: undefined,
  biography: undefined,
  access: undefined,
  featureAccess: undefined,
  hasUnreadNotifications: undefined,
};

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    USER_DETAILS: (state, action: PayloadAction<UserModel>) => ({
      ...state,
      userDetails: action.payload,
    }),
    UPDATE_USER_ACCOUNT: (state, action: PayloadAction<AccountModel>) => {
      if (!state.userDetails) return { ...state };
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          account: action.payload,
        },
        userLastUpdated: new Date(),
      };
    },
    UPDATE_FEATURE_ACCESS: (state, action: PayloadAction<FeatureAccessModel[]>) => ({
      ...state,
      featureAccess: action.payload,
    }),
    UPDATE_SUBSCRIPTION_PLAN: (state, action: PayloadAction<SubscriptionPlanMeResponse>) => ({
      ...state,
      subscriptionPlan: action.payload,
    }),
    UPDATE_LOGIN_DETAILS: (state, action: PayloadAction<UserLoginDetailsModel>) => {
      const loginDetails: UserLoginDetailsModel = {
        country_code: action.payload.country_code,
        number: action.payload.number,
        email: action.payload.email,
        fullName: action.payload.fullName,
        steppedUp: !!action.payload?.steppedUp,
        useEmail: !!action.payload.email,
      };
      setAsyncItem(storageKeys.loginDetails, JSON.stringify(loginDetails));
      return { ...state, loginDetails };
    },
    UPDATE_CACHED_LOGIN_DETAILS: (state, action: PayloadAction<UserLoginDetailsModel>) => ({
      ...state,
      loginDetails: action.payload,
    }),
    UPDATE_REGISTRATION_STATUS: (state, action: PayloadAction<RegistrationMilestoneModel[]>) => ({
      ...state,
      registrationStatusLoading: false,
      registrationStatus: action.payload,
    }),
    UPDATE_REGISTRATION_STATUS_COMPLETION: (
      state,
      action: PayloadAction<{
        preKyi?: boolean;
        preDigimed?: boolean;
        all?: boolean;
      }>
    ) => ({
      ...state,
      registrationStatusCompletion: action.payload,
    }),
    FLAG_REGISTRATION_STATUS_LOADING: (state, action: PayloadAction<boolean | undefined>) => ({
      ...state,
      registrationStatusLoading: action.payload ?? true,
    }),
    UPDATE_VERIFICATION_STATUS: (state, action: PayloadAction<AuthVerificationStatusResponse>) => ({
      ...state,
      verificationStatus: action.payload,
    }),
    UPDATE_MEDICAL_REGISTRATION: (state, action: PayloadAction<MedicalRegistrationModel>) => ({
      ...state,
      medicalRegistration: action.payload,
    }),
    UPDATE_BIOGRAPHY: (state, action: PayloadAction<string>) => ({
      ...state,
      biography: action.payload,
    }),
    UPDATE_ACCESS_DATA: (state, action: PayloadAction<IsAllowedAccessResponse>) => ({
      ...state,
      access: action.payload,
    }),
    HAS_UNREAD_NOTIFICATIONS: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasUnreadNotifications: action.payload,
    }),
    LOGOUT: (state, action: PayloadAction<boolean>) => {
      // Logout from exported axios method, to clear token and avoid circular dependencies
      let loginDetails: UserLoginDetailsModel = undefined;
      if (action.payload) {
        loginDetails = undefined;
        removeAsyncItem(storageKeys.loginDetails);
      } else {
        loginDetails = { ...state.loginDetails, steppedUp: false };
        setAsyncItem(storageKeys.loginDetails, JSON.stringify(loginDetails));
      }
      return {
        ...initialState,
        access: state.access,
        loginDetails,
      };
    },
  },
});

export const {
  USER_DETAILS,
  UPDATE_USER_ACCOUNT,
  UPDATE_SUBSCRIPTION_PLAN,
  UPDATE_LOGIN_DETAILS,
  UPDATE_CACHED_LOGIN_DETAILS,
  UPDATE_REGISTRATION_STATUS,
  UPDATE_REGISTRATION_STATUS_COMPLETION,
  FLAG_REGISTRATION_STATUS_LOADING,
  UPDATE_MEDICAL_REGISTRATION,
  UPDATE_VERIFICATION_STATUS,
  UPDATE_BIOGRAPHY,
  UPDATE_ACCESS_DATA,
  UPDATE_FEATURE_ACCESS,
  HAS_UNREAD_NOTIFICATIONS,
  LOGOUT,
} = userDetailsSlice.actions;
export default userDetailsSlice.reducer;

const registrationStatusCompletion = (state: RootState) => state.userDetailsReducer.registrationStatusCompletion;

export const registrationCompletedSelector = createSelector(
  [registrationStatusCompletion],
  (registrationStatusCompletion) => ({
    all: !!registrationStatusCompletion?.all,
    preKyi: !!registrationStatusCompletion?.preKyi,
    preDigimed: !!registrationStatusCompletion?.preDigimed,
  })
);

export const registrationStatusSelector = (state: RootState) => state.userDetailsReducer.registrationStatus;

export const userDetailsSelector = (state: RootState) => state.userDetailsReducer.userDetails;
export const userLastUpdatedSelector = (state: RootState) => state.userDetailsReducer.userLastUpdated;

export const subscriptionPlan = (state: RootState) => state.userDetailsReducer.subscriptionPlan;

export const allowConsultations = (state: RootState) => !!state.userDetailsReducer.access?.write_access;
