import React from 'react';
import { FlatList, View } from 'react-native';
import { useSelector } from 'react-redux';

import { WebMenuItem } from './WebMenuItem';

import { MenuItem } from '~/constants/menus/menuCommon';
import { RootState } from '~/redux/reducers';
import { separator } from '~/styles/commonStyling';
import { whenAppType } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

interface Props {
  expanded: boolean;
  menuItems: MenuItem[];
  isLast?: boolean;
}

export const WebMenuListSection: React.FC<Props> = ({ menuItems, expanded, isLast }) => {
  const hasUnreadNotifications = useSelector((state: RootState) =>
    whenAppType({
      whenDoctor: state.userDetailsReducer.hasUnreadNotifications || !!state.requestsReducer.requests?.length,
      whenPatient: state.userDetailsReducer.hasUnreadNotifications,
      whenPharmacist: !!state.pharmacyPrescriptionsReducer.prescriptionRequests?.data.length,
    })
  );

  const renderItem = ({ item }: { item: MenuItem }) => {
    return (
      <WebMenuItem
        item={item}
        expanded={expanded}
        withDot={hasUnreadNotifications && (item.key === screenName.Notifications || item.key === screenName.Requests)}
      />
    );
  };
  return (
    <View>
      <FlatList
        data={menuItems.filter((menuItem) => !menuItem.hidden)}
        renderItem={renderItem}
        keyExtractor={(_item, index) => index.toString()}
      />
      {isLast ? null : <View style={separator} />}
    </View>
  );
};
