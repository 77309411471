import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useRef } from 'react';
import { useFormState, useWatch } from 'react-hook-form';

import { HealthRecordDataContext } from './HealthRecordDataContext';

import { useHealthRecord } from '~/api/hooks/consultations/HealthRecordHook';
import { useHealthRecordMedia } from '~/api/hooks/consultations/HealthRecordMediaHook';
import {
  useHealthRecordAnswers,
  useHealthRecordTemplateSymptoms,
} from '~/api/hooks/consultations/HealthRecordTemplateHook';
import { TEMPLATE_ID_DEFAULT } from '~/api/hooks/consultations/HealthRecordTemplatesHook';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { BaseHealthRecordAnswerModel } from '~/api/models/consultations/models/HealthRecordAnswerModel';
import {
  HealthRecordFormModel,
  UpdateHealthRecordModel,
} from '~/api/models/consultations/models/UpdateHealthRecordModel';
import { HealthRecordAnswerResponse } from '~/api/models/consultations/responses/HealthRecordAnswerResponse';
import { HealthRecordSymptomsResponse } from '~/api/models/consultations/responses/HealthRecordSymptomsResponse';
import { HealthRecordTemplateQuestionsResponse } from '~/api/models/consultations/responses/HealthRecordTemplateQuestionsResponse';
import { maxLengthValidation, requiredValidation, validationSchema } from '~/services/validationConfig';
import { isDoctorVersion } from '~/utils/buildConfig';
import { clinicalTermToLabelValue } from '~/utils/clinicalTerms';
import { useAutoSaveForm } from '~/utils/hooks/AutoSaveFormHook';
import { useFormWithRules } from '~/utils/hooks/FormWithRulesHook';
import removeEmpty from '~/utils/processing/removeEmpty';

interface Props {
  consultation: ConsultationModel;
  children: React.ReactNode;
}

export const HealthRecordDataProvider: React.FC<Props> = ({ consultation, children }) => {
  const { healthRecord, setHealthRecord, loading, getHealthRecord } = useHealthRecord({
    consultationId: consultation?.id,
  });
  const { updateTemplateAnswers, getTemplateAnswers } = useHealthRecordAnswers();
  const { addFile, removeFile, getFiles } = useHealthRecordMedia({
    healthRecordId: healthRecord?.id,
  });
  const { updateTemplateSymptoms, getTemplateSymptoms } = useHealthRecordTemplateSymptoms();

  const healthRecordId = useRef<number>();

  const form = useFormWithRules<HealthRecordFormModel>({
    mode: 'all',
    reValidateMode: 'onBlur',
    shouldUnregister: false,
    defaultValues: {
      treatment_given: '',
      diagnosis: undefined,
      differential_diagnosis: undefined,
      recommended_follow_up: undefined,
      other_comments: '',
      condition: undefined,
      template_id: undefined,
      symptoms: [],
      template: {},
      dirtyTemplate: false,
      media: [],
    },
    rules: {
      treatment_given: {
        maxLength: maxLengthValidation(validationSchema.string.maxLength),
        required: requiredValidation('Treatment given'),
      },
      diagnosis: {
        required: requiredValidation('Diagnosis'),
      },
      recommended_follow_up: {
        maxLength: maxLengthValidation(validationSchema.string.maxLength),
      },
      other_comments: {
        maxLength: maxLengthValidation(validationSchema.string.maxLength),
      },
      symptoms: {
        required: requiredValidation('Symptoms'),
      },
    },
  });

  const { dirtyFields } = useFormState({ control: form.control });
  const dirtyTemplate = useWatch({ control: form.control, name: 'dirtyTemplate' });

  const { saving } = useAutoSaveForm({
    control: form.control,
    autoSave: async () => {
      await submit();
    },
    watch: [dirtyTemplate],
  });

  const loadHealthRecord = async (keepValues?: boolean) => {
    const res = await getHealthRecord();
    if (res) {
      healthRecordId.current = res.id;
      form.reset(
        {
          ...form.getValues(),
          treatment_given: res.treatment_given || '',
          diagnosis: clinicalTermToLabelValue(res.diagnosis),
          differential_diagnosis: clinicalTermToLabelValue(res.differential_diagnosis),
          recommended_follow_up: res.recommended_follow_up || '',
          other_comments: res.other_comments || '',
          condition: res.condition || undefined,
          template_id: res.template_id || TEMPLATE_ID_DEFAULT, // HANDLE loading template
        },
        { keepValues }
      );
    } else {
      const values = form.getValues();
      form.reset({
        ...values,
        template_id: values?.template_id || TEMPLATE_ID_DEFAULT,
      });
    }
    return res?.id;
  };

  const setTemplateAnswers = (data: HealthRecordAnswerResponse, keepValues?: boolean) => {
    const templateAnswers = data.reduce((previous, answer) => {
      previous[`q${answer.question_id}`] = answer.ans;
      return previous;
    }, {});

    form.reset(
      {
        ...form.getValues(),
        template: templateAnswers,
        dirtyTemplate: false,
      },
      { keepValues }
    );
  };

  const setTemplateQuestions = (questions: HealthRecordTemplateQuestionsResponse) => {
    const currentValues = form.getValues();
    const templateAnswers = questions.reduce((previous, question) => {
      previous[`q${question.id}`] = '';
      return previous;
    }, {});
    form.reset({
      ...currentValues,
      template: templateAnswers,
      dirtyTemplate: false,
    });
  };

  const loadTemplateAnswers = async (healthRecordId: number) => {
    if (!isDoctorVersion()) return;
    const res = await getTemplateAnswers(healthRecordId);
    if (res?.data) setTemplateAnswers(res.data);
  };

  const setTemplateSymptoms = (data: HealthRecordSymptomsResponse, keepValues?: boolean) => {
    form.reset(
      {
        ...form.getValues(),
        symptoms: data.map((symptom) => clinicalTermToLabelValue(symptom)),
      },
      { keepValues }
    );
  };

  const loadTemplateSymptoms = async (healthRecordId: number) => {
    if (!isDoctorVersion()) return;
    const res = await getTemplateSymptoms(healthRecordId);
    if (res) {
      setTemplateSymptoms(res.data);
    }
  };

  const loadMedia = async (healthRecordId: number) => {
    if (!isDoctorVersion()) return;

    const res = await getFiles(healthRecordId);
    form.reset({ ...form.getValues(), media: res?.data ?? [] });
  };

  const loadData = async () => {
    const healthRecordId = await loadHealthRecord();
    if (healthRecordId) {
      await loadTemplateAnswers(healthRecordId);
      await loadTemplateSymptoms(healthRecordId);
      await loadMedia(healthRecordId);
    }
  };

  const verifyForm = async () => {
    // If health record is not loaded, consider valid
    if (!healthRecordId.current) return true;

    return form.triggerCustomValidation();
  };

  const processFormData = (healthRecordData: HealthRecordFormModel) => {
    return removeEmpty<UpdateHealthRecordModel>({
      condition: healthRecordData.condition,
      diagnosis_id: healthRecordData.diagnosis?.value,
      differential_diagnosis_id: healthRecordData.differential_diagnosis?.value,
      treatment_given: healthRecordData.treatment_given,
      recommended_follow_up: healthRecordData.recommended_follow_up,
      other_comments: healthRecordData.other_comments,
      template_id: healthRecordData.template_id || TEMPLATE_ID_DEFAULT,
      patient_id: consultation.patient.id,
    });
  };

  const getOrCreateHealthRecordId = async () => {
    if (healthRecordId.current) return healthRecordId.current;
    const formData = form.getValues();

    const res = await setHealthRecord(processFormData(formData));
    return res.id;
  };

  const isHealthRecordDirty = () => {
    return (
      dirtyFields.diagnosis ||
      dirtyFields.differential_diagnosis ||
      dirtyFields.condition ||
      dirtyFields.treatment_given ||
      dirtyFields.recommended_follow_up ||
      dirtyFields.other_comments ||
      dirtyFields.template_id
    );
  };

  const submit = async () => {
    const healthRecordData = form.getValues();
    if (!healthRecordId.current) {
      await loadHealthRecord();
    }
    const result =
      !healthRecordId.current || isHealthRecordDirty()
        ? await setHealthRecord(processFormData(healthRecordData), healthRecordId.current)
        : null;
    if (!healthRecordId.current) healthRecordId.current = result.id;

    if (healthRecordId) {
      if (dirtyFields?.symptoms) {
        const res = await updateTemplateSymptoms({
          healthRecordId: healthRecordId.current,
          terms: healthRecordData.symptoms,
        });
        if (res?.data) setTemplateSymptoms(res.data);
      }

      if (
        Object.values(dirtyFields?.template ?? []).some((dirtyQuestion) => dirtyQuestion) ||
        healthRecordData.dirtyTemplate
      ) {
        const templateAnswerData = Object.keys(healthRecordData.template)
          .filter((key) => !!healthRecordData.template[key])
          .map<BaseHealthRecordAnswerModel>((key) => ({
            question_id: +key.slice(1),
            ans: healthRecordData.template[key],
          }));
        if (templateAnswerData.length) {
          const res = await updateTemplateAnswers(healthRecordId.current, templateAnswerData);
          if (res?.data) setTemplateAnswers(res.data);
        }
      }

      if (result) await loadHealthRecord(true);
      else form.reset({ ...healthRecordData }, { keepValues: true });
    }
  };

  const addMediaFile = async (document: DocumentPickerAsset) => {
    const healthRecordId = await getOrCreateHealthRecordId();
    await addFile({ healthRecordId, document });
    await loadMedia(healthRecordId);
  };

  const removeMediaFile = async (document: MediaModel) => {
    const healthRecordId = await getOrCreateHealthRecordId();
    await removeFile({ document, healthRecordId });
    await loadMedia(healthRecordId);
  };

  return (
    <HealthRecordDataContext.Provider
      value={{
        form,
        loadData,
        saving: false,
        savingError: undefined,
        healthRecord,
        loading: loading && !saving,
        addFile: addMediaFile,
        removeFile: removeMediaFile,
        verifyForm,
        submit,
        setTemplateQuestions,
        setDirtyTemplateAnswers: () => form.setValue('dirtyTemplate', true),
      }}>
      {children}
    </HealthRecordDataContext.Provider>
  );
};
