import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import { View } from 'react-native';
import DateTimePicker from 'react-native-date-picker';

import { colors } from '../../../utils/colors';
import { DATE_FORMAT, NUM_DATE_FORMAT, parseDateTime, stringToDate } from '../../../utils/dateAndTime';
import { FormErrorMessage } from '../../commonText';
import FloatingDateTimeBox from '../../floatingDateTimeBox';
import WebTextInput from '../WebTextInput';

import { isWeb } from '~/utils/buildConfig';

interface Props {
  value: string;
  label?: string;
  placeholder?: string;
  showMandatory?: boolean;
  onChangeValue?: (date: string, event?: React.SyntheticEvent<EventTarget> | undefined) => void;
  error?: boolean;
  errorMessage?: string;
  readonly?: boolean;
  maxDate?: Date;
  minDate?: Date;
  formatDisplayValue?: string;
  renderValue?: (value: string) => ReactNode;
  iconPrefix?: ReactNode;
  iconSuffix?: ReactNode;
  withBorder?: boolean;
}

// create a component
const DatePicker: React.FC<Props> = ({
  value,
  maxDate,
  minDate,
  errorMessage,
  error,
  label,
  placeholder,
  showMandatory,
  readonly,
  onChangeValue,
  formatDisplayValue,
  renderValue,
  iconPrefix,
  iconSuffix,
  withBorder = true,
}): JSX.Element => {
  const [showModalInput, setShowModalInput] = React.useState(false);
  const [localValue, setLocalValue] = React.useState('');
  const webPlatform = useRef(isWeb());

  useEffect(() => {
    const date = parseDateTime(value, { parseFormat: NUM_DATE_FORMAT, outputFormat: NUM_DATE_FORMAT });
    setLocalValue(date || '');
  }, [value]);

  const dateValue = useMemo(() => {
    return stringToDate(localValue, { parseFormat: NUM_DATE_FORMAT });
  }, [localValue]);

  const displayValue = useMemo(() => {
    return parseDateTime(localValue, {
      outputFormat: formatDisplayValue ? formatDisplayValue : DATE_FORMAT,
      parseFormat: NUM_DATE_FORMAT,
    });
  }, [localValue]);
  return (
    <View>
      {webPlatform.current ? (
        <WebTextInput
          label={label || placeholder}
          inputStyle={{ color: colors.purpleGrey, paddingLeft: 14, paddingRight: 14 }}
          inputType="date"
          min={minDate && parseDateTime(minDate, { parseFormat: NUM_DATE_FORMAT, outputFormat: NUM_DATE_FORMAT })}
          max={maxDate && parseDateTime(maxDate, { parseFormat: NUM_DATE_FORMAT, outputFormat: NUM_DATE_FORMAT })}
          onChangeValue={setLocalValue}
          onBlur={() => {
            onChangeValue(localValue);
          }}
          value={localValue}
          disabled={readonly}
          error={error}
          showMandatory={showMandatory}
          mode={withBorder ? 'outlined' : 'flat'}
          iconPrefix={iconPrefix}
          iconSuffix={iconSuffix}
        />
      ) : (
        <FloatingDateTimeBox
          label={label}
          placeholder={placeholder}
          onClick={() => setShowModalInput(true)}
          value={displayValue}
          showMandatory={showMandatory}
          error={error}
          renderValue={renderValue}
          iconPrefix={iconPrefix}
          iconSuffix={iconSuffix}
          withBorder={withBorder}
          disabled={readonly}
        />
      )}
      <>{error && errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}</>
      <>
        {webPlatform.current ? null : !showModalInput ? null : (
          <DateTimePicker
            testID="dateTimePicker"
            modal
            minimumDate={minDate || undefined}
            maximumDate={maxDate || undefined}
            date={dateValue || new Date()}
            open={showModalInput}
            mode="date"
            onCancel={() => {
              onChangeValue(localValue);
              setShowModalInput(false);
            }}
            onConfirm={(newDate) => {
              const stringDate = parseDateTime(newDate, { outputFormat: NUM_DATE_FORMAT });
              setLocalValue(stringDate);
              onChangeValue(stringDate);
              setShowModalInput(false);
            }}
          />
        )}
      </>
    </View>
  );
};

export default DatePicker;
