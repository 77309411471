import React from 'react';

export interface StripeProviderProps {
  children: React.ReactElement | React.ReactElement[];
  publishableKey: string;
  urlScheme?: string;
}

export const StripeProvider: React.FC<StripeProviderProps> = (props) => {
  return <>{props.children}</>;
};
