import { labels } from '../utils/labels';

import { DropDownItem } from '~/components/inputs/dropdown/interfaces';

export enum IdentificationDocumentsEnum {
  IDENTITY_CARD,
  PASSPORT,
}

export const IdentificationDocumentTypes: DropDownItem[] = [
  { label: labels.identityCard, value: IdentificationDocumentsEnum.IDENTITY_CARD },
  { label: labels.passport, value: IdentificationDocumentsEnum.PASSPORT },
];
