import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';

import { ErrorAlert } from './ErrorAlert';
import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { flex1, mt10, ph5, pl0, pr0, textCenter } from '../../common/commonStyles';
import FloatingTextBox from '../../components/floatingInputBox';
import {
  minLengthValidation,
  requiredValidation,
  validationSchema,
  emailValidation,
  numberValidation,
} from '../../services/validationConfig';
import { Button, TextButton } from '../commonButton';
import { H2TtmSemiBoldBlack, H5TtmRegularSecondaryBlack, H5TtmSemiBoldBlack } from '../commonText';
import { RowView } from '../commonViews';
import { OTPTextInput } from '../inputs/OtpTextInput';
import { Dropdown } from '../inputs/dropdown/Dropdown';

import { useCountryListing, CountryListEnum } from '~/api/hooks/referenceData/CountryListing';
import { MaltaPhoneCode } from '~/api/hooks/referenceData/constants/CountryConstants';
import { OtpChannelEnum } from '~/api/models/otp/constants/OtpChannelEnum';
import { apiChangeEmail, apiChangeMobile } from '~/api/services/authorisation';
import { apiSendOtpEmailUpdate, apiSendOtpMobileUpdate } from '~/api/services/otp';
import { SuccessAlert } from '~/common/commonMethods';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  changeEmail: boolean;
  onSuccess: () => void;
}

export const VerifyModal: React.FC<Props> = ({ onHide, changeEmail, onSuccess }) => {
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');
  const countryItems = useCountryListing(CountryListEnum.PHONE_CODE);
  const [countryCode, setCountryCode] = useState<string>();
  const [verifiedEmail, setVerifiedEmail] = useState<string>();
  const [verifiedMobile, setVerifiedMobile] = useState<string>();
  const [waiting, setWaiting] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      mobile: '',
      country_code: MaltaPhoneCode,
    },
  });

  const sendOtp = async (details: { email: string; country_code: string; mobile: string }) => {
    try {
      setWaiting(true);
      if (changeEmail) {
        setVerifiedEmail(details.email);
        await apiSendOtpEmailUpdate({
          channel: OtpChannelEnum.EMAIL,
          email: details.email,
        });
      } else {
        setVerifiedMobile(details.mobile);
        setCountryCode(details.country_code);
        await apiSendOtpMobileUpdate({
          channel: OtpChannelEnum.SMS,
          country_code: details.country_code,
          mobile: details.mobile,
        });
      }
      setShowOtp(true);
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const resendOTP = async () => {
    try {
      setWaiting(true);
      await sendOtp({ email: verifiedEmail, country_code: countryCode, mobile: verifiedMobile });
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const submitNew = async () => {
    try {
      setWaiting(true);
      if (changeEmail) {
        await apiChangeEmail({ email: verifiedEmail, otp });
      } else {
        await apiChangeMobile({ country_code: countryCode, mobile: verifiedMobile, otp });
      }
      SuccessAlert([changeEmail ? 'Email successfully changed' : 'Phone number successfully changed']);
      onHide();
      if (onSuccess) onSuccess();
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const buttons = [
    <Button label={labels.continue} funCallback={handleSubmit(sendOtp)} disabled={waiting} />,
    <TextButton label={labels.cancel} funCallback={onHide} disabled={waiting} />,
  ];

  const buttonsOtp = [
    showOtp ? <TextButton funCallback={resendOTP} label={labels.resendCode} /> : null,
    <Button
      funCallback={() => {
        setOtp('');
        submitNew();
      }}
      disabled={otp.length !== 6 || waiting}
      label={labels.enter}
    />,
    <TextButton label={labels.cancel} funCallback={onHide} />,
  ];

  return (
    <ModalContainer hideOnBackground onHide={onHide} buttons={showOtp ? buttonsOtp : buttons}>
      <View>
        <View style={{ zIndex: 1 }}>
          <H2TtmSemiBoldBlack style={textCenter}>
            {changeEmail ? labels.verifyEmailAddress : labels.verifyPhoneNumber}
          </H2TtmSemiBoldBlack>
          {changeEmail ? (
            <View style={[{ width: '100%', justifyContent: 'space-between' }]}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { isTouched } }) => (
                  <FloatingTextBox
                    label={labels.emailAddress}
                    showMandatory
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    error={isTouched && !!errors.email}
                    errorMessage={isTouched && errors.email?.message}
                    maxLength={validationSchema.email.maxLength}
                    keyboardType="email"
                    disabled={showOtp}
                  />
                )}
                rules={{
                  required: requiredValidation(labels.emailAddress),
                  validate: { emailValidation },
                }}
              />
            </View>
          ) : (
            <RowView style={[{ width: '100%', alignItems: 'flex-start' }]}>
              <View style={[flex1, ph5, pl0, { maxWidth: 120 }]}>
                <Controller
                  name="country_code"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <View>
                      <Dropdown
                        list={countryItems.map((item) => ({ label: item.name, value: item.id }))}
                        setValue={onChange}
                        value={value}
                        error={!!errors['country_code']}
                        errorMessage={errors['country_code']?.message}
                        searchable
                        disabled={showOtp}
                      />
                    </View>
                  )}
                  rules={{
                    required: requiredValidation('Country code'),
                  }}
                />
              </View>
              <View style={[flex1, ph5, pr0]}>
                <Controller
                  name="mobile"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { isTouched } }) => (
                    <FloatingTextBox
                      label={labels.phoneNumber}
                      showMandatory
                      value={value}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      error={isTouched && !!errors.mobile}
                      errorMessage={isTouched && errors.mobile?.message}
                      maxLength={validationSchema.phoneNumber.maxLength}
                      keyboardType="tel"
                      disabled={showOtp}
                    />
                  )}
                  rules={{
                    required: requiredValidation(labels.phoneNumber),
                    validate: { numberValidation },
                    minLength: minLengthValidation(validationSchema.phoneNumber.minLength),
                  }}
                />
              </View>
            </RowView>
          )}
        </View>
        {!showOtp ? null : (
          <View style={mt10}>
            <H5TtmRegularSecondaryBlack>
              {labels.weSentSixDigitCode}
              <H5TtmSemiBoldBlack>
                {changeEmail ? verifiedEmail : `+${countryCode} ${verifiedMobile}`}
              </H5TtmSemiBoldBlack>
            </H5TtmRegularSecondaryBlack>
            <OTPTextInput
              tintColor={colors.info}
              offTintColor={colors.lightPurple2}
              handleTextChange={(e: string) => {
                setOtp(e);
              }}
              defaultValue={otp}
              inputCount={6}
              inputCellLength={1}
              keyboardType="numeric"
              containerStyle={{ justifyContent: 'center' }}
              loading={waiting}
            />
          </View>
        )}
      </View>
    </ModalContainer>
  );
};
