import React, { useImperativeHandle, useRef } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { OTPTextInputRef, OTPTextInput } from '../inputs/OtpTextInput';

import { colors } from '~/utils/colors';

export interface PasscodeInputWebRef {
  clearAll: CallableFunction;
}

interface Props {
  value: string;
  onChange: (string) => void;
  onEnter: () => void;
  style?: StyleProp<ViewStyle>;
  errorMessage?: string;
  autofocus?: boolean;
  editable?: boolean;
  loading?: boolean;
}
export const PasscodeInputWeb = React.forwardRef(
  ({ value, onChange, onEnter, style, errorMessage, autofocus, editable = true, loading }: Props, ref) => {
    useImperativeHandle(ref, () => ({
      clearAll: () => {
        otpRef.current.clearAll();
      },
    }));

    const otpRef = useRef<OTPTextInputRef>();
    return (
      <View style={style}>
        <OTPTextInput
          ref={otpRef}
          handleTextChange={onChange}
          defaultValue={value}
          inputCount={4}
          inputCellLength={1}
          keyboardType="numeric"
          tintColor={colors.info}
          offTintColor={colors.lightPurple2}
          secureTextEntry
          onEnter={onEnter}
          autofocus={autofocus}
          editable={editable}
          errorMessage={errorMessage}
          loading={loading}
        />
      </View>
    );
  }
);
