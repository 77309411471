import React, { useEffect, useState } from 'react';

import { MarkerOptions } from '../interfaces/Props';
import { useMapViewContext } from '../mapView';

export const Marker: React.FC<MarkerOptions> = (options) => {
  const { map, maps } = useMapViewContext();

  const [marker, setMarker] = useState<google.maps.Marker>();

  const getOptions = (): google.maps.MarkerOptions => ({
    draggable: options.draggable,
    position: options.coordinate,
    map,
  });

  const handleOnDrag = (e: google.maps.MapMouseEvent) => {
    if (!options.onDrag) return;

    options.onDrag({
      latLng: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    });
  };

  const handleDragEnd = (e: google.maps.MapMouseEvent) => {
    if (!options.onDragEnd) return;

    options.onDragEnd({
      latLng: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    });
  };

  useEffect(() => {
    if (!maps || !map) return;

    const marker = new google.maps.Marker(getOptions());

    if (options.onDragEnd) {
      marker.addListener('dragend', handleDragEnd);
    }

    if (options.onDrag) {
      marker.addListener('drag', handleOnDrag);
    }
    setMarker(marker);
  }, [maps, map]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(getOptions());
    }
  }, [options]);

  return null;
};
