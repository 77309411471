import Constants from 'expo-constants';
import { useEffect, useState } from 'react';
import { Subscription } from 'react-native-iap';

import { SubscriptionIntervalEnum } from '~/api/models/subscriptions/constants/SubscriptionIntervalEnum';
import { SubscriptionPriceWithMobilePricingModel } from '~/api/models/subscriptions/models/SubscriptionPriceModel';
import { SubscriptionPlansResponse } from '~/api/models/subscriptions/responses/SubscriptionFeaturesResponse';
import { apiGetSubscriptionPlans } from '~/api/services/subscriptions';
import { isAndroid, isDoctorVersion } from '~/utils/buildConfig';

interface Props {
  subscriptions?: Subscription[];
}

export const getProductId = (interval: SubscriptionIntervalEnum) => {
  const appType = isDoctorVersion() ? 'doctor' : 'patient';

  const env = `_${Constants.expoConfig.extra.iapEnv || 'preview'}`;
  if (interval === SubscriptionIntervalEnum.MONTH) {
    return `${appType}_premium_monthly${env}`;
  } else {
    return `${appType}_premium_yearly${env}`;
  }
};

const getAndroidSubscriptionOffer = (productId: string, subscriptions: Subscription[]) => {
  if (!isAndroid() || !productId || !subscriptions?.length) return undefined;

  const subscription = subscriptions.find((sub) => sub.productId === productId);

  if (subscription.platform === 'android') {
    const pricingPhases = subscription.subscriptionOfferDetails.find((offer) => offer.basePlanId && !offer.offerId)
      .pricingPhases?.pricingPhaseList;

    console.log('SubscriptionOfferDetails', subscription.subscriptionOfferDetails);

    return pricingPhases?.length ? pricingPhases[0] : undefined;
  }
};

export function useSubscriptionPlanListing(props: Props) {
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlansResponse>();

  const execute = async () => {
    try {
      const res = await apiGetSubscriptionPlans(isDoctorVersion() ? 'doctor' : 'patient');

      const plans = res.data.map((plan) => ({
        ...plan,
        price: plan.price?.map<SubscriptionPriceWithMobilePricingModel>((price) => {
          const product_id = price.product_id || getProductId(price.interval);
          const price_android = getAndroidSubscriptionOffer(product_id, props.subscriptions);
          return { ...price, product_id, price_android };
        }),
      }));

      setSubscriptionPlans(plans);
    } catch {}
  };

  useEffect(() => {
    execute();
  }, [props.subscriptions]);

  return { subscriptionPlans };
}
