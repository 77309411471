import React from 'react';

import { SearchBox } from '~/components/searchBox';
import { labels } from '~/utils/labels';

interface Props {
  value: string;
  setValue: (value: string) => void;
  onSubmitSearch: (value: string) => void;
}

export const DoctorListSearchComponent: React.FC<Props> = ({ value, setValue, onSubmitSearch }) => {
  const submit = () => {
    onSubmitSearch(value);
  };

  return (
    <SearchBox
      value={value}
      onTextChange={setValue}
      onSubmit={submit}
      onBlur={submit}
      placeholder={labels.searchPractitioner}
      style={{ marginVertical: 0 }}
    />
  );
};
