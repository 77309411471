import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '.';

import { DependantModel } from '~/api/models/dependants/models/DependantModel';
import { DependantsUnlimitedResponse } from '~/api/models/dependants/responses/DependantsIndexResponse';

interface IState {
  current?: DependantModel;
  dependants?: DependantModel[];
}

const initialState: IState = {
  current: undefined,
  dependants: undefined,
};

const dependantsSlice = createSlice({
  name: 'dependants',
  initialState,
  reducers: {
    SET_DEPENDANT: (state, action: PayloadAction<DependantModel>) => ({
      ...state,
      current: action.payload,
    }),
    SET_DEPENDANTS: (state, action: PayloadAction<DependantsUnlimitedResponse>) => ({
      ...state,
      dependants: action.payload,
    }),
    LOGOUT: () => ({ ...initialState }),
  },
});

export const { SET_DEPENDANTS, SET_DEPENDANT, LOGOUT } = dependantsSlice.actions;
export default dependantsSlice.reducer;

export const allDependantsSelector = (state: RootState) => state.dependantsReducer.dependants;
