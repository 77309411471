import React, { useMemo, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { DashboardCard } from '../../common/DashboardCard';

import { ConsultationTypeEnum, ConsultationTypeMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { HomeVisitNavigationStateEnum } from '~/api/models/consultations/enums/HomeVisitNavigationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { flex1, mb5 } from '~/common/commonStyles';
import { DataTable, DataTableColumns } from '~/components/common/DataTable/DataTable';
import { ConsultationTypeIcon } from '~/components/common/consultations/ConsultationTypeIcon';
import { OutlineButton } from '~/components/commonButton';
import { H5TtmRegularBlack, H5TtmSemiBoldDisableColor } from '~/components/commonText';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { ArrowRight } from '~/components/svgImages';
import { useAppointmentListingContext } from '~/providers/appointment/AppointmentListingContext';
import { isDoctorVersion, isPatientVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';
import { DoctorJoinConsultationButton } from '~/components/common/appointments/appointmentListing/components/DoctorJoinConsultationButton';
import { useJoinAppointment } from '~/utils/hooks/appointments/AppointmentDetailsHook';
import { isPastConsultation, isRejectedConsultation } from '~/utils/consultationUtils';

const APPOINTMENT_LIST_MINIMUM = 1;
const RECORD_HEIGHT = 85;
const EXTRA_CONTENT_HEIGHT = 60;

export const DashboardAppointmentsToday: React.FC = () => {
  const [containerHeight, setContainerHeight] = useState<number>(undefined);
  const { consultations, loading } = useAppointmentListingContext();
  const { showDetails } = useShowAppointment();

  const recordCount = useMemo(
    () => Math.max(APPOINTMENT_LIST_MINIMUM, Math.floor((containerHeight - EXTRA_CONTENT_HEIGHT) / RECORD_HEIGHT)),
    [consultations, containerHeight]
  );

  const slicedAppointments = useMemo(() => {
    return consultations?.data?.slice(0, recordCount) || [];
  }, [consultations, recordCount]);

  const startNavigationButton = (consultation: ConsultationModel) => {
    if (
      isDoctorVersion() &&
      consultation.state === ConsultationStateEnum.Scheduled &&
      (!consultation?.doctor_navigation_data ||
        consultation.doctor_navigation_data.state === HomeVisitNavigationStateEnum.PAUSED)
    ) {
      const viewDetails = () => {
        showDetails({
          consultation,
        });
      };

      return <OutlineButton style={{ minWidth: 130 }} label={labels.startNavigation} funCallback={viewDetails} />;
    }
    return null;
  };

  const getActionByConsultationType = (consultation: ConsultationModel) => {
    const { showJoin } = useJoinAppointment(consultation);

    if (consultation.state === ConsultationStateEnum.RequestSubmitted)
      return <H5TtmSemiBoldDisableColor>{labels.awaitingConfirmation.toUpperCase()}</H5TtmSemiBoldDisableColor>;

    if (isPastConsultation(consultation)) {
      if (isDoctorVersion() || consultation.state === ConsultationStateEnum.Submitted) {
        if (isRejectedConsultation(consultation)) return null;
        return null;
      }
    } else if (consultation.state === ConsultationStateEnum.Ended) {
      return null;
    }

    switch (consultation.type) {
      case ConsultationTypeEnum.HOME_VISIT:
        return startNavigationButton(consultation);
      case ConsultationTypeEnum.SCHEDULED_APPOINTMENT:
      case ConsultationTypeEnum.ON_DEMAND:
        return showJoin ? <DoctorJoinConsultationButton style={{ minWidth: 130 }} consultation={consultation} /> : null;
      case ConsultationTypeEnum.CLINIC:
        return null;
      default:
        return null;
    }
  };

  const actionCell = (consultation: ConsultationModel) => {
    const actionComponent = getActionByConsultationType(consultation);
    const hideArrow = actionComponent !== null;

    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {actionComponent}
        {!hideArrow ? (
          <TouchableOpacity onPress={() => showDetails({ consultation })}>
            <ArrowRight height={10} width={5} />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  };

  const columns: DataTableColumns = [
    {
      key: 'doctor',
      title: 'Specialist',
      columnStyle: {
        minWidth: 200,
      },
      cell: (_, itemData: ConsultationModel) => (
        <ProfileDetailsComponent account={itemData.doctor} isDoctor theme="table" style={flex1} />
      ),
      hide: () => isDoctorVersion(),
    },
    {
      key: 'profile',
      title: 'Profile',
      columnStyle: {
        minWidth: 200,
      },
      cell: (_, itemData: ConsultationModel) => (
        <ProfileDetailsComponent account={itemData.patient} theme="table" style={flex1} />
      ),
      hideMobile: isPatientVersion(),
      hideTablet: isPatientVersion(),
    },
    {
      key: 'type',
      title: 'Consultation type',
      columnStyle: {
        maxWidth: 180,
      },
      cell: (_, itemData: ConsultationModel) => (
        <View style={flex1}>
          <H5TtmRegularBlack style={mb5}> {ConsultationTypeMapping[itemData.type]}</H5TtmRegularBlack>
          <ConsultationTypeIcon consultation={itemData} />
        </View>
      ),
    },
    {
      key: 'actions',
      columnStyle: {
        minWidth: 160,
      },
      cell: (_, itemData: ConsultationModel) => actionCell(itemData),
      contentNumeric: true,
    },
  ];

  const onLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    setContainerHeight(height);
  };

  const rowPress = (_, itemData: ConsultationModel) => {
    if (!itemData) return;
    showDetails({ consultation: itemData });
  };

  return (
    <View style={flex1} onLayout={onLayout}>
      <DashboardCard
        title={labels.todaysAppointments}
        viewAllLink={{
          name: screenName.CalendarPage,
          params: {
            screen: screenName.CalendarAppointments,
            params: {
              screen: screenName.CalendarAppointmentsListView,
            },
          },
        }}
        style={{ flex: 1 }}>
        <View style={styles.container}>
          <DataTable
            columns={columns}
            items={slicedAppointments || []}
            page={1}
            hideHeaders
            hidePagination
            onRowPress={rowPress}
            style={flex1}
            emptyNote={labels.noAppointments}
            wrapperStyle={styles.table}
            rowStyle={styles.rowStyle}
            rowHoverStyle={styles.rowHoverStyle}
            loading={loading}
            scrollEnabled={slicedAppointments?.length ? false : undefined}
          />
        </View>
      </DashboardCard>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    marginTop: 10,
  },
  table: {
    borderColor: colors.transparent,
    paddingRight: 5,
  },
  rowStyle: {
    backgroundColor: colors.white,
    marginVertical: 5,
    borderBottomColor: colors.transparent,
    borderRadius: 6,
    paddingVertical: 5,
    marginHorizontal: 2,
  },
  rowHoverStyle: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});
