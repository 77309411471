import React from 'react';
import { Dimensions, StyleSheet, View, Image } from 'react-native';

import { colors } from '~/utils/colors';
const { height } = Dimensions.get('screen');

export const AddDependantBackground: React.FC = () => {
  return (
    <View style={styles.absolute}>
      <View style={styles.circle1} />
      <View style={styles.circle3} />
      <View style={styles.circle2} />
      <View style={[styles.image1Circle, styles.dropShadow]}>
        <Image style={styles.innerImage} source={require('~/../assets/img/onboarding_img/one.jpg')} />
      </View>
      <View style={[styles.image2Circle, styles.dropShadow]}>
        <Image style={styles.innerImage} source={require('~/../assets/img/onboarding_img/2.jpeg')} />
      </View>
    </View>
  );
};

const roundedHeight = Math.round(height);

const styles = StyleSheet.create({
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
  },
  circle1: {
    position: 'absolute',
    right: roundedHeight * 0.4,
    top: -roundedHeight * 0.2 * 0.2,
    backgroundColor: colors.info,
    width: roundedHeight * 0.2,
    height: roundedHeight * 0.2,
    borderRadius: roundedHeight * 0.2,
    zIndex: 1,
  },
  circle2: {
    position: 'absolute',
    right: -roundedHeight * 0.48,
    top: -roundedHeight * 0.48,
    backgroundColor: colors.lightPurple,
    width: roundedHeight,
    height: roundedHeight,
    borderRadius: roundedHeight,
    zIndex: 0,
  },
  circle3: {
    position: 'absolute',
    right: -roundedHeight * 0.15 * 0.2,
    top: roundedHeight * 0.2,
    backgroundColor: colors.info,
    width: roundedHeight * 0.15,
    height: roundedHeight * 0.15,
    borderRadius: roundedHeight * 0.15,
    zIndex: 2,
  },
  image1Circle: {
    height: roundedHeight * 0.15,
    width: roundedHeight * 0.15 * 1.8,
    borderRadius: 80,
    backgroundColor: colors.info,
    position: 'absolute',
    top: roundedHeight * 0.12,
    right: roundedHeight * 0.05,
    zIndex: 1,
    overflow: 'hidden',
  },
  innerImage: {
    width: '100%',
    height: '100%',
  },
  image2Circle: {
    height: roundedHeight * 0.06,
    width: roundedHeight * 0.06,
    borderRadius: roundedHeight * 0.06,
    backgroundColor: colors.info,
    position: 'absolute',
    top: roundedHeight * 0.25,
    right: roundedHeight * 0.4,
    zIndex: 4,
    overflow: 'hidden',
  },
  dropShadow: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 2,
  },
});
