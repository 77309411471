import React from 'react';

import { ClinicPastRequestsTable } from '~/components/clinic/requests/ClinicPastRequestsTable';
import { RequestsLayout } from '~/layouts/RequestsLayout';

export const ClinicAppointmentsPastRequests: React.FC = () => {
  return (
    <RequestsLayout rowStyle={{ marginHorizontal: 0 }}>
      <ClinicPastRequestsTable />
    </RequestsLayout>
  );
};
