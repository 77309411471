import { get, post, setToken } from '../../axios';
import { AuthorisationResponse } from '../../models/authorisation/responses/AuthorisationResponse';
import { Preferred2FAChannelRequest } from '../../models/registration/requests/Preferred2FAChannelRequest';
import { ReceiveMarketingMaterialRequest } from '../../models/registration/requests/ReceiveMarketingMaterialRequest';
import { SubmitEmailRequest } from '../../models/registration/requests/SubmitEmailRequest';
import { RegisterEmailRequest, RegisterMobileRequest } from '../../models/registration/requests/SubmitMobileRequest';
import { SubmitPasscodeRequest } from '../../models/registration/requests/SubmitPasscodeRequest';
import { VerifyEmailRequest } from '../../models/registration/requests/VerifyEmailRequest';
import { VerifyMobileRequest } from '../../models/registration/requests/VerifyMobileRequest';
import { RegistrationStatusResponse } from '../../models/registration/responses/RegistrationStatusResponse';

import { ConsultationChannelsModel } from '~/api/models/registration/models/ConsultationChannelsModel';
import { PreferredLanguagesModel } from '~/api/models/registration/models/PreferredLanguagesModel';
import { TotpEnrolmentConfirmModel } from '~/api/models/registration/models/TotpEnrolmentConfirmModel';
import { TotpEnrolmentResponse } from '~/api/models/registration/responses/TotpEnrolmentResponse';

export const apiRegisterWithMobile = (data: RegisterMobileRequest) => {
  const xhr = post<AuthorisationResponse>('/register/mobile', data);
  xhr.then((res) => {
    setToken(res.data.data);
  });
  return xhr;
};

export const apiRegisterWithEmail = (data: RegisterEmailRequest) => {
  const xhr = post<AuthorisationResponse>('/register/email-registration', data);
  xhr.then((res) => {
    setToken(res.data.data);
  });
  return xhr;
};

export const apiVerifyMobile = (data: VerifyMobileRequest) => {
  const xhr = post<AuthorisationResponse>('/register/mobile/verify', data);
  xhr.then((res) => {
    setToken(res.data.data);
  });
  return xhr;
};

export const apiVerifyEmailRegistration = (data: VerifyEmailRequest) => {
  const xhr = post<AuthorisationResponse>('/register/email-registration/verify', data);
  xhr.then((res) => {
    setToken(res.data.data);
  });
  return xhr;
};

export const apiSubmitPasscode = (data: SubmitPasscodeRequest) => {
  const xhr = post<AuthorisationResponse>('register/passcode', data);
  xhr.then((res) => {
    setToken(res.data.data);
  });
  return xhr;
};

export const apiSubmitEmail = (data: SubmitEmailRequest) => {
  return post('register/email', data);
};

export const apiVerifyEmail = (data: VerifyEmailRequest) => {
  return post('register/email/verify', data);
};

export const apiSetPreferred2faChannel = (data: Preferred2FAChannelRequest) => {
  return post('register/2fa/channel/preferred', data);
};

export const apiGetRegistrationStatus = () => {
  return get<RegistrationStatusResponse>('/register/status');
};

export const apiAcceptTermsAndConditions = () => {
  return post('terms-and-condition');
};

export const apiSetMarketingMaterial = (req: ReceiveMarketingMaterialRequest) => {
  return post('/me/marketing-material', req);
};

export const apiEnrolmentTotp = () => {
  return post<TotpEnrolmentResponse>('/user/totp/enrolment');
};

export const apiEnrolmentTotpConfirm = (data: TotpEnrolmentConfirmModel) => {
  return post('/user/totp/enrolment/confirm', data);
};

export const apiStoreConsultationChannels = (data: ConsultationChannelsModel) => {
  return post('/me/consultation-channels', data);
};
export const apiStorePreferredLanguages = (data: PreferredLanguagesModel) => {
  return post('/me/preferred-language', data);
};
