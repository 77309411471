import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';

import { DoctorAppointmentsMobile } from '~/pages/doctor/calendar/appointments/DoctorAppointmentsMobile';
import { DoctorAppointmentsWeb } from '~/pages/doctor/calendar/appointments/DoctorAppointmentsWeb';
import { AppointmentsScreen } from '~/pages/patient/AppointmentsScreen';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

export const DoctorAppointmentsNavigation: React.FC = () => {
  const Stack = createNativeStackNavigator();
  const { isMobile } = useBreakpoints();

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={screenName.CalendarAppointmentsMain}
        component={isMobile ? DoctorAppointmentsMobile : DoctorAppointmentsWeb}
      />
      <Stack.Screen name={screenName.CalendarAppointmentsListView} component={AppointmentsScreen} />
    </Stack.Navigator>
  );
};
