import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { colors } from '~/utils/colors';

interface Props {
  bmiValue: number;
}

export const BMIBar: React.FC<Props> = ({ bmiValue }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [position, setPosition] = useState<number>();
  const [marker, setMarker] = useState<string>();

  const rangesList = [
    { backgroundColor: colors.info, startRange: 0, endRange: 18.4 },
    { backgroundColor: colors.success, startRange: 18.5, endRange: 24.9 },
    { backgroundColor: colors.warning, startRange: 25, endRange: 39.9 },
    { backgroundColor: colors.danger, startRange: 40, endRange: 100 },
  ];

  const placeBMIMarker = () => {
    if (bmiValue) {
      let startRange;
      let endRange;

      rangesList.forEach((item, index) => {
        if (bmiValue >= item.startRange && bmiValue <= item.endRange) {
          startRange = item.startRange;
          endRange = item.endRange;
          setPosition(index);
        }
      });

      const bmiStartRangeDifference = bmiValue - startRange;
      const rangeDifference = endRange - startRange;

      if (bmiValue >= 99) {
        setMarker('97%');
      } else {
        setMarker((bmiStartRangeDifference * 100) / rangeDifference + '%');
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    placeBMIMarker();
    setLoading(false);
  }, [bmiValue]);

  return (
    <View style={[styles.container]}>
      {loading
        ? null
        : rangesList.map((item, index) => {
            return (
              <View key={index} style={[styles.rowSection, { backgroundColor: item.backgroundColor }]}>
                {index === position ? <View style={[styles.markerContainer, { left: marker }]} /> : null}
              </View>
            );
          })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    paddingTop: 22,
  },
  rowSection: {
    flex: 1,
    height: 7,
  },
  markerContainer: { height: 19, width: 3, backgroundColor: colors.darkGrey, top: -5 },
});
