import React from 'react';

import { RequestsLayout } from '../../layouts/RequestsLayout';

import { ClinicRequestsTable } from '~/components/clinic/requests/ClinicRequestsTable';

export const ClinicAppointmentRequests: React.FC = () => {
  return (
    <RequestsLayout rowStyle={{ marginHorizontal: 0 }}>
      <ClinicRequestsTable />
    </RequestsLayout>
  );
};
