import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MenuItem, menusVerificationPending } from './menuCommon';
import {
  AppointmentsIcon,
  BillIcon,
  Calendar,
  DispenseHistoryIcon,
  DoctorIcon,
  FolderDiagnoses,
  HelpCircle,
  HomeOutline,
  InsuranceIcon,
  NewsIcon,
  NotificationIcon,
  PackageIcon,
  PatientGroup,
  Person,
  Pill,
  RequestHistoryIcon,
  SecondarySettings,
  Settings,
  Wallet,
} from '../../components/svgImages';
import { isNative, isPharmacyVersion, whenAppType } from '../../utils/buildConfig';
import { colors } from '../../utils/colors';
import { screenName } from '../../utils/screenName';

import { useVerificationStatus } from '~/api/hooks/accounts/VerificationStatus';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { digimedFeatureEnabled, DigimedFeatures } from '~/utils/milestoneUtil';
import { PharmacyStateEnum } from '~/api/models/pharmacy/constants/PharmacyStateEnum';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';

export const useMenus = () => {
  const { featureAccess, hasFeatureAccess } = useFeatureAccess();
  const accountStatus = useVerificationStatus();
  const currentPharmacy = useSelector(currentPharmacySelector);

  const doctorMenu: MenuItem[][] = [
    [
      {
        label: 'Home',
        image: (color = colors.purple) => <HomeOutline height={20} width={20} color={color} />,
        screenName: screenName.Dashboard,
      },
    ],
    [
      {
        label: 'Requests',
        image: (color = colors.purple) => <NotificationIcon height={20} width={20} color={color} />,
        screenName: screenName.Requests,
        key: screenName.Requests,
      },
    ],
    [
      {
        label: 'Calendar',
        image: (color = colors.purple) => <Calendar height={20} width={20} color={color} />,
        screenName: screenName.CalendarPage,
        hidden: !digimedFeatureEnabled(DigimedFeatures.CalendarAndAppointments),
      },
      {
        label: 'Patients',
        image: (color = colors.purple) => <PatientGroup height={20} width={20} color={color} />,
        screenName: screenName.PatientManagement,
        hidden: !digimedFeatureEnabled(DigimedFeatures.PatientManagementSystem),
      },
      {
        label: 'Income',
        image: (color = colors.purple) => <Wallet height={20} width={20} color={color} />,
        screenName: screenName.Income,
        hidden: !digimedFeatureEnabled(DigimedFeatures.IncomeAnalysis) || !hasFeatureAccess(FeatureAccessEnum.INCOME),
      },
    ],
    [
      {
        label: 'Settings',
        image: (color = colors.purple) => <Settings height={20} width={20} color={color} />,
        screenName: screenName.Settings,
      },
      {
        label: 'Help',
        image: (color = colors.purple) => <HelpCircle height={20} width={20} color={color} />,
        screenName: screenName.Help,
      },
    ],
  ];

  const patientMenu: MenuItem[][] = [
    [
      {
        label: 'Home',
        image: (color: string = colors.purple) => <HomeOutline height={20} width={20} color={color} />,
        screenName: screenName.Dashboard,
      },
      {
        label: 'Notifications',
        image: (color: string = colors.purple) => <NotificationIcon height={20} width={20} color={color} />,
        screenName: screenName.Requests,
        key: screenName.Requests,
      },
    ],
    [
      {
        label: 'Appointments',
        image: (color: string = colors.purple) => <AppointmentsIcon height={20} width={20} color={color} />,
        screenName: screenName.Appointments,
      },
      {
        label: 'Doctors',
        image: (color: string = colors.purple) => <DoctorIcon height={20} width={20} color={color} />,
        screenName: screenName.Doctors,
      },
      {
        label: 'Diagnosis',
        image: (color: string = colors.purple) => <FolderDiagnoses height={20} width={20} color={color} />,
        screenName: screenName.Diagnoses,
      },
      {
        label: 'Prescriptions',
        image: (color: string = colors.purple) => <Pill height={20} width={20} color={color} />,
        screenName: screenName.PrescriptionScreen,
      },
      {
        label: 'Insurance',
        image: (color: string = colors.purple) => <InsuranceIcon height={20} width={20} color={color} />,
        screenName: screenName.Insurance,
      },
      {
        label: 'Billing',
        image: (color: string = colors.purple) => <BillIcon height={20} width={20} color={color} />,
        screenName: screenName.PatientBillingHistory,
      },
      {
        label: 'Dependants',
        image: (color: string = colors.purple) => <PatientGroup height={20} width={20} color={color} />,
        screenName: screenName.Dependants,
      },
      {
        label: 'Digimed Education Centre',
        image: (color: string = colors.purple) => <NewsIcon height={20} width={20} color={color} />,
        screenName: screenName.EducationCentre,
      },
    ],
    [
      {
        label: 'Settings',
        image: (color: string = colors.purple) => <Settings height={20} width={20} color={color} />,
        screenName: screenName.Settings,
      },
      {
        label: 'Help',
        image: (color: string = colors.purple) => <HelpCircle height={20} width={20} color={color} />,
        screenName: screenName.Help,
      },
    ],
  ];

  const pharmacistMenu: MenuItem[][] = [
    [
      {
        label: 'Home',
        image: (color = colors.purple) => <HomeOutline height={20} width={20} color={color} />,
        screenName: screenName.Dashboard,
      },
      {
        label: 'Requests',
        image: (color = colors.purple) => <NotificationIcon height={20} width={20} color={color} />,
        screenName: screenName.Requests,
        key: screenName.Requests,
      },
    ],
    [
      {
        label: 'Awaiting collection',
        image: (color = colors.purple) => <PackageIcon height={20} width={20} color={color} />,
        screenName: screenName.PharmacyAwaitingCollection,
      },
      {
        label: 'Dispense History',
        image: (color = colors.purple) => <DispenseHistoryIcon height={20} width={20} color={color} />,
        screenName: screenName.PharmacyDispenseHistory,
      },
      {
        label: 'Request History',
        image: (color = colors.purple) => <RequestHistoryIcon height={20} width={20} color={color} />,
        screenName: screenName.PharmacyRequestHistory,
      },
    ],
    [
      {
        label: 'Profile',
        image: (color = colors.purple) => <Person height={20} width={20} color={color} />,
        screenName: screenName.ProfileScreen,
      },

      {
        label: 'Settings',
        image: (color = colors.purple) => <Settings height={20} width={20} color={color} />,
        screenName: screenName.Settings,
      },
      {
        label: 'Help',
        image: (color = colors.purple) => <HelpCircle height={20} width={20} color={color} />,
        screenName: screenName.Help,
      },
    ],
    [
      {
        label: 'Pharmacy Settings',
        key: 'pharmacy_settings',
        image: (color = colors.purple) => <SecondarySettings height={20} width={20} color={color} />,
        hidden: isNative(),
        children: [
          {
            label: 'Pharmacy details',
            screenName: screenName.PharmacyDetails,
          },
          {
            label: 'Add user',
            screenName: screenName.PharmacyUserManagement,
          },
        ],
      },
    ],
  ];

  const clinicMenu: MenuItem[][] = [
    [
      {
        label: 'Home',
        image: (color = colors.purple) => <HomeOutline height={20} width={20} color={color} />,
        screenName: screenName.Dashboard,
      },
    ],
    [
      {
        label: 'Requests',
        image: (color = colors.purple) => <NotificationIcon height={20} width={20} color={color} />,
        screenName: screenName.Requests,
        key: screenName.Requests,
      },
    ],
    [
      {
        label: 'Calendar',
        image: (color = colors.purple) => <Calendar height={20} width={20} color={color} />,
        screenName: screenName.CalendarPage,
        hidden: !digimedFeatureEnabled(DigimedFeatures.CalendarAndAppointments),
      },
      {
        label: 'Patients',
        image: (color = colors.purple) => <PatientGroup height={20} width={20} color={color} />,
        screenName: screenName.PatientManagement,
        hidden: !digimedFeatureEnabled(DigimedFeatures.PatientManagementSystem),
      },
      {
        label: 'Income',
        image: (color = colors.purple) => <Wallet height={20} width={20} color={color} />,
        screenName: screenName.Income,
        hidden: !digimedFeatureEnabled(DigimedFeatures.IncomeAnalysis),
      },
    ],
    [
      {
        label: 'Account Settings',
        image: (color = colors.purple) => <Settings height={20} width={20} color={color} />,
        screenName: screenName.Settings,
      },
      {
        label: 'Clinic Settings',
        image: (color = colors.purple) => <SecondarySettings height={20} width={20} color={color} />,
        screenName: screenName.SettingsClinic,
      },
      {
        label: 'Help',
        image: (color = colors.purple) => <HelpCircle height={20} width={20} color={color} />,
        screenName: screenName.Help,
      },
    ],
  ];

  const menu = useMemo(() => {
    if (!accountStatus?.approved) return menusVerificationPending;

    const menu = whenAppType({
      whenDoctor: doctorMenu,
      whenPatient: patientMenu,
      whenPharmacist: pharmacistMenu,
      whenClinic: clinicMenu,
    });

    if (isPharmacyVersion()) {
      if (!currentPharmacy || currentPharmacy?.pharmacy?.state !== PharmacyStateEnum.APPROVED) {
        return menusVerificationPending;
      }
      if (!currentPharmacy?.is_admin) {
        return menu.filter((menuItem) => !menuItem.find((item) => item.key === 'pharmacy_settings'));
      }
    }
    return menu;
  }, [featureAccess, accountStatus?.approved, currentPharmacy?.is_admin, currentPharmacy]);

  return { menu };
};
