import { DependencyList, useEffect, useRef, useState } from 'react';
import { Control, useFormState, useWatch } from 'react-hook-form';

const AUTO_SAVE_TIME = 300;
interface Props<T> {
  control: Control<T>;
  autoSave: () => Promise<any>;
  watch?: DependencyList;
}
export function useAutoSaveForm<T extends Record<string, any> = Record<string, any>>({
  control,
  autoSave,
  watch = [],
}: Props<T>) {
  const formWatch = useWatch({ control });
  const [saving, setSaving] = useState(false);
  const { isDirty } = useFormState({ control });
  const autoSaveTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    // Triggers every time content is modified and set to dirty
    if (!saving && isDirty) {
      if (autoSaveTimeout.current) {
        clearTimeout(autoSaveTimeout.current);
      }

      autoSaveTimeout.current = setTimeout(() => {
        setSaving(true);
        autoSave()
          .then(() => setSaving(false))
          .catch(() => setSaving(false))
          .finally(() => (autoSaveTimeout.current = null));
      }, AUTO_SAVE_TIME);
    }
  }, [isDirty, formWatch, saving, ...watch]);

  return { saving, pendingChanges: isDirty || saving };
}
