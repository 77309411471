import moment from 'moment';

import { parseMoment } from './dateAndTime';
import { capitalize } from './stringUtil';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { OnDemandDoctorQueueModel } from '~/api/models/consultations/models/OnDemandDoctorQueueModel';
import {
  AppointmentRejectedState,
  AppointmentsPastState,
  AppointmentsUpcomingState,
} from '~/constants/appointments/appointmentStateMapping';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';

export const isPastConsultation = (consultation?: ConsultationModel) => {
  if (!consultation) return false;
  return !!AppointmentsPastState[consultation.state];
};

export const isUpcomingConsultation = (consultation?: ConsultationModel) => {
  if (!consultation) return false;
  return !!AppointmentsUpcomingState[consultation.state];
};

export const isRejectedConsultation = (consultation?: ConsultationModel) => {
  if (!consultation) return false;
  return AppointmentRejectedState.includes(consultation.state);
};

const calculateTimeDifference = (timeTo: string) => {
  const momentTimeTo = parseMoment(timeTo);
  const diff = momentTimeTo.diff(moment());
  return `${capitalize(moment.duration(diff).humanize())} left`;
};

const queueCount = (count: number) => {
  if (count <= 0) {
    return '';
  } else {
    return `(${count} waiting)`;
  }
};
export const getQueueInfo = (data: OnDemandDoctorQueueModel) => {
  if (!data?.queue || data.queue <= 0) return { noQueue: true };
  const count = queueCount(data.queue);
  const timeDiff = data.estimated ? calculateTimeDifference(data.estimated) : '';

  return { noQueue: false, description: `${timeDiff} ${count}` };
};

/**
 * Checks if consultation is an On Demand or Scheduled consultation,
 * which would mean they user one of the online consultation channels
 * @param consultation ConsultationModel
 * @returns If consultation uses the audio or video call or a chat channels
 */
export const isCallOrChatConsultation = (consultation?: ConsultationModel) => {
  if (!consultation) return false;

  if ([ConsultationTypeEnum.CLINIC, ConsultationTypeEnum.HOME_VISIT].includes(consultation.type)) {
    return false;
  }

  return true;
};

/**
 * Checks if the consultation is in state Scheduled (including Pending Payment) or Started
 * @param consultation ConsultationModel
 * @returns Boolean If consultation is in state Scheduled, PendingPayment or Started
 */
export const isConsultationScheduledOrStarted = (consultation: ConsultationModel) => {
  // State in pending payment is considered as scheduled, just pending payment
  return (
    !!consultation &&
    (consultation?.state === ConsultationStateEnum.PendingPayment ||
      consultation?.state === ConsultationStateEnum.Scheduled ||
      consultation?.state === ConsultationStateEnum.Started)
  );
};
