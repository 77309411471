// private-consultation.<account id>
export enum ConsultationEventsEnum {
  CONSULTATION_UNRESPONSIVE_DOCTOR = 'consultation.unresponsive-doctor',
  CONSULTATION_PATIENT_REQUEST_IGNORED = 'consultation.patient-request-ignored',
  CONSULTATION_ON_DEMAND_EXPIRED = 'consultation.on-demand-expired',
  CONSULTATION_REQUEST_SUBMITTED = 'consultation.request-submitted',
  CONSULTATION_REQUEST_APPROVED = 'consultation.request-approved',
  CONSULTATION_REQUEST_REJECTED = 'consultation.request-rejected',
  CONSULTATION_SCHEDULED_APPOINTMENT_STARTING = 'consultation.scheduled-appointment-starting',
  CONSULTATION_HOME_VISIT_APPOINTMENT_STARTING = 'consultation.home-visit-appointment-starting',
  CONSULTATION_APPOINTMENT_SCHEDULED = 'consultation.appointment-scheduled',
  CONSULTATION_RESCHEDULE_REQUEST = 'consultation.reschedule-request',
  CONSULTATION_RESCHEDULE_REQUEST_ACCEPTED = 'consultation.reschedule-request-accepted',
  CONSULTATION_RESCHEDULE_REQUEST_REJECTED = 'consultation.reschedule-request-rejected',
  CONSULTATION_STATE_ENDED = 'consultation-state.ended', // also in private-consultation-id.<consultation id>
  CONSULTATION_STATE_STARTED = 'consultation-state.started', // also in private-consultation-id.<consultation id>
  CONSULTATION_CANCELLED = 'consultation.cancelled',
  // CONSULTATION_DRAFT = 'consultation.draft',
  CONSULTATION_PENDING_PAYMENT = 'consultation.pending-payment',
  CONSULTATION_SUBMITTED = 'consultation.submitted',
  CONSULTATION_ASSIGNED = 'consultation.assigned',
  CONSULTATION_DOCTOR_CHANGED = 'consultation.doctor-changed',
  DEBUG = 'debug',
}

// private-consultation-chat.<consultation id>
export enum ConsultationChatEventsEnum {
  CONSULTATION_CHAT_MESSAGE_SENT = 'consultation-chat.message-sent',
  CONSULTATION_CHAT_CONSULTATION_ENDING = 'consultation-chat.consultation-ending',
  CONSULTATION_CHAT_CLIENT_TYPING = 'client-consultation-chat.typing',
}

// private-consultation-call.<consultation id>
export enum ConsultationCallEventsEnum {
  CONSULTATION_CALL_CONSULTATION_ENDING = 'consultation-call.consultation-ending',
}

// private-consultation-id.<consultation id>
export enum ConsultationNavigationEventsEnum {
  CONSULTATION_DOCTOR_NAVIGATION_STARTED = 'consultation.navigation.started',
  CONSULTATION_DOCTOR_NAVIGATION_RESUMED = 'consultation.navigation.resumed',
  CONSULTATION_DOCTOR_NAVIGATION_PAUSED = 'consultation.navigation.paused',
  CONSULTATION_DOCTOR_NAVIGATION_ENDED = 'consultation.navigation.ended',
  CONSULTATION_DOCTOR_NAVIGATION_ETA_UPDATED = 'consultation.navigation.eta-updated',
}

// private-subscription.<user id>
export enum SubscriptionsEventsEnum {
  SUBSCRIPTION_UPGRADED_TO_PREMIUM = 'subscription.upgraded-to-premium',
  SUBSCRIPTION_DOWNGRADED_TO_FREE = 'subscription.downgraded-to-free',
  SUBSCRIPTION_CHANGED_STATE = 'subscription.changed_state',
}

// private-prescription.<account id>
export enum PrescriptionEventsEnum {
  PRESCRIPTION_REQUEST_APPROVED = 'prescription-request.approved',
  PRESCRIPTION_REQUEST_REJECTED = 'prescription-request.rejected',
}

// private-pharmacy.<pharmacy id>
export enum PharmacyEventsEnum {
  PHARMACY_PRESCRIPTION_PICKED_UP_FROM_DIFFERENT_PHARMACY = 'pharmacy.prescription-picked-up-from-different-pharmacy',
  PHARMACY_PRESCRIPTION_REQUEST_CREATED = 'pharmacy.prescription-request-created',
}

// private-association-request.<association request id>
export enum AssociationEventsEnum {
  ASSOCIATION_REQUEST_SENT = 'association-request.sent',
  ASSOCIATION_REQUEST_APPROVED = 'association-request.approved',
  ASSOCIATION_REQUEST_DECLINED = 'association-request.declined',
}

// private-general.<account id>
export enum GeneralEventsEnum {
  INSURANCE_CLAIM_PDF_GENERATED = 'insurance-claim-pdf.generated',
  SUMSUB_ACCOUNT_UPDATED = 'sumsub.account-updated',
  USER_ROLE_STATUS_UPDATED = 'user.role-status-updated',
  PHARMACY_STATE_CHANGED = 'pharmacy.state-changed',
  DEBUG = 'debug',
}

export type ChannelEventEnum =
  | AssociationEventsEnum
  | PharmacyEventsEnum
  | PrescriptionEventsEnum
  | SubscriptionsEventsEnum
  | ConsultationNavigationEventsEnum
  | ConsultationCallEventsEnum
  | ConsultationChatEventsEnum
  | ConsultationEventsEnum
  | GeneralEventsEnum;
