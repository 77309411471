import React from 'react';
import { StyleSheet, View } from 'react-native';

import { InvoiceActionButtons } from '../buttons/InvoiceActionButtons';
import { Gradient } from '../common/Gradient';
import { useBillingHistoryContext } from '../common/billing/contexts/BillingHistoryContext';

import { BillingHistoryModel } from '~/api/models/billingHistory/models/BillingHistoryModel';
import { BillingHistoryStatusEnum } from '~/api/models/billingHistory/models/enums/BillingHistoryStatusEnum';
import { TransactionTypeEnum } from '~/api/models/billingHistory/models/enums/TransactionTypeEnum';
import { flex1, mb15, mb5, mt15, mt20 } from '~/common/commonStyles';
import { DataTable, DataTableColumns } from '~/components/common/DataTable/DataTable';
import {
  ExtraSmallNsRegularPlaceholder,
  H6NsRegularBlack,
  H6NsRegularPlaceholder,
  H6NsSemiBoldBlack,
} from '~/components/commonText';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';

const STATUS_COLOR_MAPPING = {
  [BillingHistoryStatusEnum.ON_HOLD]: { color: colors.warning, label: () => 'On hold' },
  [BillingHistoryStatusEnum.PAID]: {
    color: colors.success,
    label: (item: BillingHistoryModel) => (item.type === TransactionTypeEnum.CREDIT_NOTE ? 'Refunded' : 'Paid'),
  },
  [BillingHistoryStatusEnum.CANCELLED]: { color: colors.danger, label: () => 'Cancelled' },
  [BillingHistoryStatusEnum.SCHEDULED]: { color: colors.warning, label: () => 'Scheduled' },
};

const TYPE_LABEL_MAPPING = {
  [TransactionTypeEnum.CONSULTATION]: { label: 'Consultation' },
  [TransactionTypeEnum.STRIPE_SUBSCRIPTION]: { label: 'Subscription - Web' },
  [TransactionTypeEnum.CREDIT_NOTE]: { label: 'Credit Note' },
  [TransactionTypeEnum.APPLE_SUBSCRIPTION]: { label: 'Subscription - App Store' },
  [TransactionTypeEnum.GOOGLE_SUBSCRIPTION]: { label: 'Subscription - Play Store' },
};

const getBillingTimes = (item: BillingHistoryModel) => {
  switch (item.status) {
    case BillingHistoryStatusEnum.PAID:
      return `Processed at ${item.processed_at}`;
    case BillingHistoryStatusEnum.ON_HOLD:
    case BillingHistoryStatusEnum.SCHEDULED:
      return `Last updated at ${item.updated_at}`;
    case BillingHistoryStatusEnum.CANCELLED:
      return `Cancelled at ${item.cancelled_at}`;
    default:
      return null;
  }
};

export const BillingHistoryList: React.FC = () => {
  const { setPage, loading, billingHistory } = useBillingHistoryContext();
  const { isMobile } = useBreakpoints();

  const formatBillingTime = (item: BillingHistoryModel) => {
    const billingTime = getBillingTimes(item);

    const [prefix, timeString] = billingTime.split(' at ');
    const formattedTime = parseDateTime(timeString, { outputFormat: TIME_FORMAT });
    return (
      <View>
        <ExtraSmallNsRegularPlaceholder>{`${prefix} at\n`}</ExtraSmallNsRegularPlaceholder>
        <H6NsRegularBlack style={isMobile ? styles.customFontSize : null}>{formattedTime}</H6NsRegularBlack>
      </View>
    );
  };

  const columns: DataTableColumns = [
    {
      key: 'date',
      title: 'Date',
      cell: (cellData, itemData: BillingHistoryModel) => (
        <H6NsRegularBlack>{parseDateTime(itemData.created_at, { outputFormat: DATE_FORMAT })}</H6NsRegularBlack>
      ),
      columnStyle: { maxWidth: 120 },
    },
    {
      key: 'time',
      cell: (_, itemData: BillingHistoryModel) => {
        return formatBillingTime(itemData);
      },
      columnStyle: { maxWidth: 150 },
    },
    {
      key: 'description',
      title: 'Description',
      cell: (_, itemData: BillingHistoryModel) => <H6NsSemiBoldBlack>{itemData?.description}</H6NsSemiBoldBlack>,
    },
    {
      key: 'subscription_type',
      title: 'Type',
      cell: (_, itemData: BillingHistoryModel) => (
        <H6NsRegularBlack>{TYPE_LABEL_MAPPING[itemData.type]?.label ?? itemData.type}</H6NsRegularBlack>
      ),
      columnStyle: { maxWidth: 120 },
    },
    {
      key: 'status',
      title: 'Status',
      cell: (cellData, itemData: BillingHistoryModel) => (
        <StatusBadge
          label={STATUS_COLOR_MAPPING[cellData].label(itemData)}
          color={STATUS_COLOR_MAPPING[cellData].color}
          textStyle={{ textTransform: 'capitalize' }}
        />
      ),
      columnStyle: { maxWidth: 120 },
    },
    {
      key: 'amount',
      title: 'Amount',
      cell: (_, itemData: BillingHistoryModel) => (
        <H6NsSemiBoldBlack>{itemData.amount.formatted_amount}</H6NsSemiBoldBlack>
      ),
      columnStyle: { maxWidth: 120 },
    },
    {
      key: 'action',
      title: 'Action',
      cell: (_, itemData: BillingHistoryModel) => (
        <InvoiceActionButtons
          transactionId={itemData.id}
          statementOfFeesStatus={itemData.statement_of_fees_status}
          invoices={itemData.invoices}
        />
      ),
      columnStyle: { maxWidth: 100 },
    },
  ];
  return (
    <View style={flex1}>
      <DataTable
        tableId="billingHistory"
        columns={columns}
        items={!isMobile ? (loading ? [] : billingHistory?.data ?? []) : billingHistory?.data ?? []}
        identifier="created_at"
        infiniteScroll={isMobile ? { loading, estimatedItemSize: { desktop: 60, mobile: 110 } } : null}
        page={billingHistory?.meta?.current_page || 1}
        totalCount={billingHistory?.meta?.total || 0}
        numberOfItemsPerPage={billingHistory?.meta?.per_page || 0}
        onPageChanged={setPage}
        loading={loading}
        emptyNote="No Billing records available."
        hidePagination={!!isMobile}
        alwaysShowPagination
        nestedScrollEnabled
        mobileRenderItem={(item: BillingHistoryModel) => (
          <View style={[{ display: 'flex', flexDirection: 'column', width: '100%' }]}>
            <View style={styles.status}>
              <H6NsRegularBlack style={styles.customFontSize}>
                {parseDateTime(item.created_at, { outputFormat: DATE_FORMAT })}
              </H6NsRegularBlack>
              <StatusBadge
                label={STATUS_COLOR_MAPPING[item.status].label(item)}
                color={STATUS_COLOR_MAPPING[item.status].color}
                textStyle={{ textTransform: 'capitalize' }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                flexWrap: 'wrap',
              }}>
              <View style={{ flex: 1, flexWrap: 'wrap' }}>
                {item?.description != null ? (
                  <View style={mb15}>
                    <H6NsSemiBoldBlack style={styles.customFontSize}>{`${item.description}`}</H6NsSemiBoldBlack>
                  </View>
                ) : null}
              </View>
            </View>
            <View
              style={[
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                },
                mb5,
              ]}>
              {formatBillingTime(item)}
              <H6NsSemiBoldBlack style={styles.customFontSize}>{item.amount.formatted_amount}</H6NsSemiBoldBlack>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              {item.type != null ? (
                <H6NsRegularPlaceholder style={styles.customFontSize}>
                  {`${TYPE_LABEL_MAPPING[item.type]?.label || item.type}`}
                </H6NsRegularPlaceholder>
              ) : null}
              <InvoiceActionButtons
                style={mt20}
                transactionId={item.id}
                invoices={item.invoices}
                statementOfFeesStatus={item.statement_of_fees_status}
              />
            </View>
          </View>
        )}
      />
      {isMobile ? <Gradient /> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  status: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 5,
  },
  customFontSize: { fontSize: 12 },
});
