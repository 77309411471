import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';

import { DoctorExportAccountDataComponent } from '../../../components/doctor/settings/deletePermanentlyAccount/DoctorExportAccountDataComponent';
import { PatientExportAccountDataComponent } from '../../../components/settings/deletePermanentlyAccount/PatientExportAccountDataComponent';

import { fullLogout } from '~/api/axios';
import { Submit2FAChallengeRequest } from '~/api/models/authorisation/requests/Submit2FAChallengeRequest';
import { apiAccountDeletion } from '~/api/services/authorisation';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { DeleteAccountConfirmationModal } from '~/components/modals/DeleteAccountConfirmationModal';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { ClinicExportAccountDataComponent } from '~/components/settings/deletePermanentlyAccount/ClinicExportAccountDataComponent';
import { PharmacyExportAccountDataComponent } from '~/components/settings/deletePermanentlyAccount/PharmacyExportAccountDataComponent';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { NavType } from '~/navigation/types';
import { infoModal, useModalManager } from '~/providers/modal/ModalManagementContext';
import { whenAppType } from '~/utils/buildConfig';
import { accountDeletionLabels } from '~/utils/labels/accountDeletionLabels';
import { screenName } from '~/utils/screenName';

export const PermanentlyDeleteAccountAuthorised: React.FC = () => {
  const { registerModal, closeModalByName } = useModalManager();
  const { openModal } = useModalManager();
  const { navigate } = useNavigation<NavType>();

  useEffect(() => {
    registerModal(ModalName.DeleteAccountConfirmation, DeleteAccountConfirmationModal);
  }, []);

  const cancel = () => {
    navigate(screenName.Settings, { screen: screenName.SettingsAccount });
  };

  const deleteAccount = async (otpData: Submit2FAChallengeRequest) => {
    try {
      await apiAccountDeletion(otpData);
      fullLogout();
      infoModal({
        message: ['Sorry to see you go, your account was successfully deleted.'],
        type: ModalAlertTypeEnum.SUCCESS,
      });
      closeModalByName(ModalName.DeleteAccountConfirmation);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const showPopup = () => {
    openModal(ModalName.DeleteAccountConfirmation, {
      onDelete: deleteAccount,
    });
  };

  const buttons = <CancelContinueFooter onConfirm={showPopup} onCancel={cancel} />;

  return (
    <PageLayout
      title={accountDeletionLabels.permanentlyDeleteAccount}
      back={{ to: { screen: screenName.Settings, params: { screen: screenName.SettingsAccount } } }}
      footer={buttons}>
      {whenAppType({
        whenDoctor: <DoctorExportAccountDataComponent />,
        whenPatient: <PatientExportAccountDataComponent />,
        whenPharmacist: <PharmacyExportAccountDataComponent />,
        whenClinic: <ClinicExportAccountDataComponent />,
      })}
    </PageLayout>
  );
};
