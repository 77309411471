import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import { apiDependantDeclarationDownload } from '~/api/services/dependants';
import { SuccessAlert } from '~/common/commonMethods';

import { flex1, pb15, mb10, mb20 } from '~/common/commonStyles';
import { CancelContinueFooterArray } from '~/components/buttons/CancelContinueFooterArray';
import { UploadDocumentComponent } from '~/components/buttons/UploadDocumentComponent';
import { Button, OutlineButton } from '~/components/commonButton';
import { FormErrorMessage, H6NsRegularBlack } from '~/components/commonText';
import { DependantLearnMoreModal } from '~/components/modals/DependantLearnMoreModal';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { DocumentsAllowedFormats } from '~/constants/documentUploadsConstants';
import { WizardLayout } from '~/layouts/WizardLayout';
import { NavType } from '~/navigation/types';
import { useAddDependantContext } from '~/providers/dependants/AddDependantContext';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { requiredValidation } from '~/services/validationConfig';
import { Grid, Container, Row, Column } from '~/theme/components/grid';
import { isNative } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';
import { dependantsLabels } from '~/utils/labels/dependantsLabels';
import { screenName } from '~/utils/screenName';

export const RelationshipMediaStep: React.FC = () => {
  const { control, loading, submitRequest } = useAddDependantContext();
  const { navigate } = useNavigation<NavType>();
  const [downloading, setDownloading] = useState(false);

  const { registerModal, openModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.DependantLearnMoreModal, DependantLearnMoreModal);
  }, []);

  const buttons = CancelContinueFooterArray({
    waiting: loading,
    onCancel: () => navigate(screenName.Dependants),
    onConfirm: submitRequest,
    confirmTitle: labels.submit,
  });

  const downloadDeclaration = async () => {
    try {
      setDownloading(true);
      await apiDependantDeclarationDownload();

      if (isNative()) {
        SuccessAlert(['The form was successfully downloaded to your device']);
      }
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <View style={flex1}>
      <WizardLayout title={dependantsLabels.authenticateRelationship} buttons={buttons}>
        <Grid grid={{ gutters: 16, noOuterGutter: true }}>
          <Container alignSelf="flex-start" style={pb15}>
            <Row style={mb10}>
              <Column>
                <H6NsRegularBlack>{dependantsLabels.authenticateRelationshipSubtitle}</H6NsRegularBlack>
              </Column>
            </Row>
            <Row style={mb20}>
              <Column span="auto">
                <View>
                  <OutlineButton
                    label={labels.learnMore.toUpperCase()}
                    funCallback={() => openModal(ModalName.DependantLearnMoreModal)}
                    style={{ minWidth: 150, marginBottom: 10 }}
                  />
                </View>
              </Column>
              <Column span="auto">
                <View>
                  <Button
                    label="Download Declaration Form"
                    funCallback={downloadDeclaration}
                    style={{ minWidth: 150, marginBottom: 10 }}
                    disabled={downloading}
                  />
                </View>
              </Column>
            </Row>
            <Row>
              <Column>
                <Controller
                  name="relationship_media"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                    <>
                      <UploadDocumentComponent
                        uploadedFiles={value}
                        setUploadedFiles={onChange}
                        allowedFormats={DocumentsAllowedFormats}
                        horizontal
                        error={isSubmitted && !!error}
                      />
                      {error?.message ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                    </>
                  )}
                  rules={{
                    required: requiredValidation(dependantsLabels.relationshipDocument),
                  }}
                />
              </Column>
            </Row>
          </Container>
        </Grid>
      </WizardLayout>
    </View>
  );
};
