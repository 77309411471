import React, { useMemo } from 'react';
import { View } from 'react-native';
import { flex1 } from '~/common/commonStyles';

import { SmallNsRegularSecondaryBlack, SmallNsSemiBoldSecondaryBlack } from '~/components/commonText';

interface Props {
  message: string;
  read?: boolean;
}

export const NotificationMessage: React.FC<Props> = ({ message, read }) => {
  const formattedMessage = useMemo(() => {
    return message.split('\n').map((line) => line.trim());
  }, [message]);

  return (
    <View style={flex1}>
      {formattedMessage.map((line, index) =>
        read ? (
          <SmallNsRegularSecondaryBlack key={index} style={{ width: '100%' }}>
            {line}
          </SmallNsRegularSecondaryBlack>
        ) : (
          <SmallNsSemiBoldSecondaryBlack key={index} style={{ width: '100%' }}>
            {line}
          </SmallNsSemiBoldSecondaryBlack>
        )
      )}
    </View>
  );
};
