import moment from 'moment';
import { UseFormGetValues, ValidationValueMessage } from 'react-hook-form';

import { StartsEndsTimesModel } from '~/api/models/common/models/StartsEndsTimeModel';
import { DurationIntervalTypeEnum } from '~/api/models/consultations/constants/DurationIntervalTypeEnum';
import {
  NUM_DATE_FORMAT,
  STRING_DATE_FORMAT,
  TIME_FORMAT,
  calculateAge,
  getYearWithChange,
  sameOrAfter,
  stringToDate,
} from '~/utils/dateAndTime';
import { getDotObject } from '~/utils/getObjectUtil';
import isEmptyString from '~/utils/types/isEmptyString';
import isNullOrUndefined from '~/utils/types/isNullOrUndefined';

export const validationSchema = {
  amount: {
    minLength: 1,
    maxLength: 4,
  },
  bic: {
    minLength: 8,
    maxLength: 15,
  },
  iban: {
    minLength: 15,
    maxLength: 80,
  },
  postCode: {
    minLength: 3,
    maxLength: 15,
  },
  passportOrIdCard: {
    minLength: 3,
    maxLength: 26,
  },
  title: {
    minLength: 1,
    maxLength: 26,
  },
  name: {
    minLength: 2,
    maxLength: 26,
  },

  lastName: {
    minLength: 1,
    maxLength: 26,
  },

  email: {
    minLength: 3,
    maxLength: 64,
  },
  address: {
    minLength: 3,
    maxLength: 255,
  },
  phoneNumber: {
    minLength: 7,
    maxLength: 15,
  },
  string: {
    maxLength: 255,
  },
  laravelString: {
    maxLength: 191,
  },
  medicalNote: {
    maxLength: 300,
  },
  age: {
    max: 120,
  },
};

export const numberValidation = (v: string): boolean | string => {
  const numberRegex = /^[0-9]*$/g;
  return numberRegex.test(v) || 'Only numbers are allowed.';
};

export const priceValidation = (v: number): boolean | string => {
  const valueString = `${v ?? ''}`;
  if (!valueString) return true;
  const numberRegex = /^\d*(\.\d+)?$/gm;
  return numberRegex.test(valueString) || 'Only numeric values are allowed.';
};

export const alphabetsValidation = (v: string): boolean | string => {
  const alphabetsRegex = /^[a-zA-Z\s]*$/;
  return alphabetsRegex.test(v) || 'Only letters from A-Z are allowed';
};

export const alphabetCharactersValidation = (v: string): boolean | string => {
  const alphabetsRegex = /^[^0-9|\\~¡¢∞§¶•ªº–≠‘“«…≥÷≤∑œ´®™†¥¨^π¬˚∆˙©<>{}!#€$£₺₴₹%&()*+,./:;=?@"[\]^`]*$/;
  return alphabetsRegex.test(v) || 'Only alphabetical characters are allowed';
};

export const emailValidation = (v: string): boolean | string => {
  const emailRegex = /^[a-z-0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,7}$/i;
  return isNullOrUndefined(v) || isEmptyString(v) || emailRegex.test(v) || 'Invalid email address.';
};

export const valuePresentValidation = (label: string) => (value: any) => {
  return isNullOrUndefined(value) ? `${label} is required` : undefined;
};

export const alphaNumericValidation = (v: string): boolean | string => {
  const alphabetsRegex = /^[A-Za-z0-9]*$/;
  return alphabetsRegex.test(v) || 'Invalid format.';
};

export const alphaNumericValidationWithSpace = (v: string): boolean | string => {
  const alphaNumericRegex = /^[A-Za-z0-9\s]*$/;
  return alphaNumericRegex.test(v) || 'Invalid format.';
};

export const alphaNumericValidationToAvoidScripting = (v: string): boolean | string => {
  const alphaRegex = /<("[^"]*"|'[^']*'|[^'">])*>/;
  return !alphaRegex.test(v) || 'Some invalid characters were found. Avoid using script and HTML tags.';
};

export const requireIfValidation = (
  label: string,
  dependencies: string | string[],
  getValue: UseFormGetValues<any>
) => {
  return (value: any) => {
    const dependentKeys: string[] = typeof dependencies === 'string' ? [dependencies] : dependencies;
    const dependentValues = dependentKeys.map((key) => getValue(key));
    if (
      (isNullOrUndefined(value) || value === '') &&
      dependentValues.some((dependentValue) => !isNullOrUndefined(dependentValue) && dependentValue !== '')
    ) {
      return getRequiredMessage(label);
    }
    return undefined;
  };
};

export const yearValidation = (options?: { minChange?: number; maxChange?: number }) => {
  return (v: string) => {
    if (!v) return undefined;
    const yearRegex = /^[12][0-9]{3}$/;
    if (!yearRegex.test(v)) {
      return 'Invalid year format';
    }

    if (options?.minChange !== undefined) {
      const yearChanged = getYearWithChange(options.minChange, { outputFormat: 'YYYY' });
      if (!sameOrAfter(v, yearChanged)) return `Year must be on or after ${yearChanged}`;
    }
    if (options?.maxChange !== undefined) {
      const yearChanged = getYearWithChange(options.maxChange, { outputFormat: 'YYYY' });
      if (!sameOrAfter(yearChanged, v)) return `Year must be on or before ${yearChanged}`;
    }
    return undefined;
  };
};

export const dateValidation = () => {
  return (v: string) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!isNullOrUndefined(v) && !isEmptyString(v) && !dateRegex.test(v)) {
      return `Date must be entered in ${STRING_DATE_FORMAT} format`;
    }
    return undefined;
  };
};

export const getMinLengthMessage = (length: number): string => {
  return length === 1 ? `A minimum of 1 character is allowed.` : `A minimum of ${length} characters is allowed.`;
};
export const getMaxLengthMessage = (length: number): string => {
  return length === 1 ? `A maximum of 1 character is allowed.` : `A maximum of ${length} characters is allowed.`;
};
export const getPasscodeLengthMessage = (length: number): string => {
  return `Passcode requires ${length} digits`;
};

export const getRequiredMessage = (field: string): string => {
  return `${field} is required.`;
};

export const minLengthValidation = (length: number): ValidationValueMessage<number> => {
  return { value: length, message: getMinLengthMessage(length) };
};
export const maxLengthValidation = (length: number): ValidationValueMessage<number> => {
  return { value: length, message: getMaxLengthMessage(length) };
};
export const passcodeLengthValidation = (length: number): ValidationValueMessage<number> => {
  return { value: length, message: getPasscodeLengthMessage(length) };
};

export const requiredValidation = (label: string): ValidationValueMessage<boolean> => {
  return { value: true, message: getRequiredMessage(label) };
};

export const greaterThanValidation = (v1: () => string, label1: string, label2: string) => (v2: string) => {
  const startDate = stringToDate(v1(), { parseFormat: TIME_FORMAT });
  const endDate = stringToDate(v2, { parseFormat: TIME_FORMAT });

  return startDate > endDate ? `${label1} cannot be greater than ${label2}` : true;
};

export const dateGreaterThanValidation = (v1: () => string, label1: string, label2: string) => (v2: string) => {
  const startingDate = stringToDate(v1(), { parseFormat: NUM_DATE_FORMAT });
  const repeatUntilDate = stringToDate(v2, { parseFormat: NUM_DATE_FORMAT });

  return startingDate > repeatUntilDate ? `${label1} cannot be greater than ${label2}` : true;
};

export const lessThanValidation = (v1: () => string, label1: string, label2: string) => (v2: string) => {
  const startDate = stringToDate(v1(), { parseFormat: TIME_FORMAT });
  const endDate = stringToDate(v2, { parseFormat: TIME_FORMAT });

  return startDate > endDate ? `${label1} cannot be less than ${label2}` : true;
};

export const checkOverlap = (currentSlot: StartsEndsTimesModel, index: number, slots: StartsEndsTimesModel[]) => {
  const newStartTimeObj = currentSlot.start_time
    ? stringToDate(currentSlot.start_time, { parseFormat: TIME_FORMAT }).getTime()
    : null;
  const newEndTimeObj = currentSlot.end_time
    ? stringToDate(currentSlot.end_time, { parseFormat: TIME_FORMAT }).getTime()
    : null;

  for (let i = 0; i < index; i++) {
    const previousSlot = slots[i];
    if (previousSlot) {
      const prevStartTimeObj = previousSlot.start_time
        ? stringToDate(previousSlot.start_time, { parseFormat: TIME_FORMAT }).getTime()
        : null;
      const prevEndTimeObj = previousSlot.end_time
        ? stringToDate(previousSlot.end_time, { parseFormat: TIME_FORMAT }).getTime()
        : null;

      if (newStartTimeObj !== null && newEndTimeObj !== null && prevStartTimeObj !== null && prevEndTimeObj !== null) {
        const maxStart = Math.max(prevStartTimeObj, newStartTimeObj);
        const minEnd = Math.min(prevEndTimeObj, newEndTimeObj);

        if (maxStart < minEnd) {
          return false;
        }
      }
    }
  }
  return true;
};

export const maxAgeValidation = (maxAge: number, label) => (date: string) => {
  return date && calculateAge(date) > maxAge ? `${label} cannot be older than ${maxAge} years` : undefined;
};

export const maxDateValidation = (maxDate: Date, label) => (date: string) => {
  if (maxDate && date && !moment(date).isSameOrBefore(maxDate)) {
    return label;
  }
  return undefined;
};

export const maximumDurationValidationYear =
  (comparisonPath: string, label: string) => (duration: number, formValues) => {
    const durationTypeEnum = getDotObject<DurationIntervalTypeEnum>(formValues, comparisonPath);
    if (
      (durationTypeEnum === DurationIntervalTypeEnum.DAYS && duration > 366) ||
      (durationTypeEnum === DurationIntervalTypeEnum.WEEKS && duration > 52) ||
      (durationTypeEnum === DurationIntervalTypeEnum.MONTHS && duration > 12)
    ) {
      return `${label} cannot be longer than a year.`;
    }
    return undefined;
  };

export const dateInRangeValidation =
  (label1: string, minYear: Date, maxYear: Date) =>
  (value: string): boolean | string => {
    const selectedDate = new Date(value);
    if (selectedDate < minYear) {
      return `${label1} must be within the next 5 years.`;
    }
    if (selectedDate > maxYear) {
      return `${label1} must be within the next 5 years.`;
    }
    return true;
  };
