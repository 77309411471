import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { View } from 'react-native';

import { apiPatientDataDownload } from '~/api/services/patientDetails';
import { mb10, mh10, mt20, p15, textCenter } from '~/common/commonStyles';
import { Button, OutlineButton } from '~/components/commonButton';
import { H6NsRegularSecondaryBlack } from '~/components/commonText';
import { WebsiteSvg } from '~/components/images/WebsiteSvg';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { UploadPatientDetailsModal } from '~/components/modals/UploadPatientDetailsModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { DownloadIcon, Upload } from '~/components/svgImages';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { transformLabel } from '~/utils/labelUtil';
import { labels } from '~/utils/labels';
import { patientManagementLabels } from '~/utils/labels/patientManagement';
import { screenName } from '~/utils/screenName';

interface Props {
  showAddNew?: boolean;
  query?: string;
}
export const NoPatientsComponent: React.FC<Props> = ({ showAddNew, query }) => {
  const { navigate } = useNavigation();

  const { registerModal, openModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.UploadPatientDetails, UploadPatientDetailsModal);
  }, []);

  const downloadTemplate = () => {
    apiPatientDataDownload().catch(ErrorAlert);
  };

  const uploadPatientDetails = () => {
    openModal(ModalName.UploadPatientDetails);
  };
  return (
    <View style={[p15, { paddingLeft: 20, display: 'flex', alignItems: 'center' }]}>
      <View style={[mt20, { marginBottom: 40, marginLeft: 25 }]}>
        <WebsiteSvg height={82} />
      </View>
      <H6NsRegularSecondaryBlack>
        {query ? transformLabel(labels.noPatientsWithQuery, { query }) : labels.noPatients}
      </H6NsRegularSecondaryBlack>
      {isDoctorVersion() ? (
        <>
          {showAddNew ? (
            <>
              <View style={mt20}>
                <Button funCallback={() => navigate(screenName.PatientAddNew as never)} label={labels.addNewPatient} />
              </View>
              <View style={[mt20, { maxWidth: 500 }]}>
                <H6NsRegularSecondaryBlack style={textCenter}>
                  {patientManagementLabels.useBulkImport}
                </H6NsRegularSecondaryBlack>
              </View>
            </>
          ) : (
            <>
              <View style={[mt20, { maxWidth: 500 }]}>
                <H6NsRegularSecondaryBlack style={textCenter}>
                  {patientManagementLabels.addNewOrBulkImport}
                </H6NsRegularSecondaryBlack>
              </View>
            </>
          )}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginTop: 40,
            }}>
            <View style={[mb10, mh10]}>
              <OutlineButton
                icon={<DownloadIcon width="16" height="16" color={colors.purple} />}
                funCallback={downloadTemplate}>
                {patientManagementLabels.downloadTemplateAction}
              </OutlineButton>
            </View>
            <View style={[mb10, mh10]}>
              <OutlineButton
                icon={<Upload width="16" height="16" color={colors.purple} />}
                funCallback={uploadPatientDetails}>
                {patientManagementLabels.uploadPatientAction}
              </OutlineButton>
            </View>
          </View>
        </>
      ) : null}
    </View>
  );
};
