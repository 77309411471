import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFetchLoginDetails } from '../accounts/LoginDetails';

import { apiGetClinicAdminTokenDetails } from '~/api/services/clinics';
import { SET_CLINIC_LINKING_DETAILS, clinicLinkingSelector } from '~/redux/reducers/tokenInviteReducer';
import { UPDATE_LOGIN_DETAILS } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { isClinicVersion } from '~/utils/buildConfig';
import { fullLogout } from '~/api/axios';

export const useClinicAdminRegistrationAction = () => {
  const dispatch = useAppDispatch();
  const { getLoginDetails } = useFetchLoginDetails();

  const handleClinicAdminRegistration = async (token: string) => {
    if (!isClinicVersion() || !token) return;

    const loginDetails = await getLoginDetails();
    if (loginDetails?.steppedUp) {
      await fullLogout();
    }

    const tokenDetails = await apiGetClinicAdminTokenDetails(token);
    const linkingDetails = { details: tokenDetails.data, token };

    dispatch(
      UPDATE_LOGIN_DETAILS({
        fullName: `${linkingDetails.details.first_name} ${linkingDetails.details.last_name}`,
        email: linkingDetails.details.email,
      })
    );
    dispatch(SET_CLINIC_LINKING_DETAILS(linkingDetails));
  };

  return { handleClinicAdminRegistration };
};

export const useClinicAdminRegistrationStatus = () => {
  const clinicLinking = useSelector(clinicLinkingSelector);

  const hasClinicLinking = useMemo(() => {
    return clinicLinking?.token && clinicLinking.details;
  }, [clinicLinking]);

  return {
    hasClinicLinking,
    hasClinicLinkingToken: !!clinicLinking?.token,
    token: clinicLinking?.token,
    details: clinicLinking?.details,
  };
};
