import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';

import { HealthProfileModel } from '~/api/models/healthProfile/Models/HealthProfileModel';
import { DietaryRestrictionsResponse } from '~/api/models/healthProfile/responses/DietaryRestrictionsResponse';
import { HealthProfileClinicalTermResponse } from '~/api/models/healthProfile/responses/HealthProfileClinicalTermResponse';
import { ProthesisImplantsGraftsResponse } from '~/api/models/healthProfile/responses/ProthesisImplantsGraftsResponse';
import { RecreationalDrugsResponse } from '~/api/models/healthProfile/responses/RecreationalDrugsResponse';

interface IState {
  accountId: number;
  healthProfile: HealthProfileModel;
  prosthesisImplantsGrafts: ProthesisImplantsGraftsResponse;
  dietaryRestrictions: DietaryRestrictionsResponse;
  recreationalDrugs: RecreationalDrugsResponse;
  allergiesProfileList: HealthProfileClinicalTermResponse;
  medicalConditionsProfileList: HealthProfileClinicalTermResponse;
  medicationsProfileList: HealthProfileClinicalTermResponse;
  proceduresProfileList: HealthProfileClinicalTermResponse;
  vaccinationsProfileList: HealthProfileClinicalTermResponse;
  socialFactorsProfileList: HealthProfileClinicalTermResponse;
}

const initialState: IState = {
  accountId: undefined,
  healthProfile: undefined,
  prosthesisImplantsGrafts: undefined,
  dietaryRestrictions: undefined,
  recreationalDrugs: undefined,
  allergiesProfileList: undefined,
  medicalConditionsProfileList: undefined,
  medicationsProfileList: undefined,
  proceduresProfileList: undefined,
  vaccinationsProfileList: undefined,
  socialFactorsProfileList: undefined,
};

const healthProfileSlice = createSlice({
  name: 'healthProfile',
  initialState,
  reducers: {
    SET_HEALTH_PROFILE_ACCOUNT: (state, actions: PayloadAction<number>) => ({
      ...state,
      accountId: actions.payload,
    }),
    SET_HEALTH_PROFILE: (state, actions: PayloadAction<HealthProfileModel>) => ({
      ...state,
      healthProfile: actions.payload,
    }),
    SET_PROSTHESIS_IMPLANTS_GRAFTS: (state, actions: PayloadAction<ProthesisImplantsGraftsResponse>) => ({
      ...state,
      prosthesisImplantsGrafts: actions.payload,
    }),
    SET_DIETARY_RESTRICTIONS: (state, actions: PayloadAction<DietaryRestrictionsResponse>) => ({
      ...state,
      dietaryRestrictions: actions.payload,
    }),
    SET_RECREATIONAL_DRUGS: (state, actions: PayloadAction<RecreationalDrugsResponse>) => ({
      ...state,
      recreationalDrugs: actions.payload,
    }),
    SET_ALLERGIES_PROFILE_LIST: (state, actions: PayloadAction<HealthProfileClinicalTermResponse>) => ({
      ...state,
      allergiesProfileList: actions.payload,
    }),
    SET_MEDICAL_CONDITIONS_PROFILE_LIST: (state, actions: PayloadAction<HealthProfileClinicalTermResponse>) => ({
      ...state,
      medicalConditionsProfileList: actions.payload,
    }),
    SET_MEDICATIONS_PROFILE_LIST: (state, actions: PayloadAction<HealthProfileClinicalTermResponse>) => ({
      ...state,
      medicationsProfileList: actions.payload,
    }),
    SET_PROCEDURES_PROFILE_LIST: (state, actions: PayloadAction<HealthProfileClinicalTermResponse>) => ({
      ...state,
      proceduresProfileList: actions.payload,
    }),
    SET_VACCINATIONS_PROFILE_LIST: (state, actions: PayloadAction<HealthProfileClinicalTermResponse>) => ({
      ...state,
      vaccinationsProfileList: actions.payload,
    }),
    SET_SOCIAL_FACTORS_PROFILE_LIST: (state, actions: PayloadAction<HealthProfileClinicalTermResponse>) => ({
      ...state,
      socialFactorsProfileList: actions.payload,
    }),
    CLEAR_HEALTH_PROFILE: () => ({
      ...initialState,
    }),
  },
});

export const {
  SET_HEALTH_PROFILE_ACCOUNT,
  SET_HEALTH_PROFILE,
  SET_PROSTHESIS_IMPLANTS_GRAFTS,
  SET_DIETARY_RESTRICTIONS,
  SET_RECREATIONAL_DRUGS,
  SET_ALLERGIES_PROFILE_LIST,
  SET_MEDICAL_CONDITIONS_PROFILE_LIST,
  SET_MEDICATIONS_PROFILE_LIST,
  SET_PROCEDURES_PROFILE_LIST,
  SET_VACCINATIONS_PROFILE_LIST,
  SET_SOCIAL_FACTORS_PROFILE_LIST,
  CLEAR_HEALTH_PROFILE,
} = healthProfileSlice.actions;
export default healthProfileSlice.reducer;

export const selectHealthProfileAccountId = (state: RootState) => state.healthProfileReducer.accountId;
export const selectHealthProfileId = (state: RootState) => state.healthProfileReducer.healthProfile?.id;
export const selectHealthProfile = (state: RootState) => state.healthProfileReducer.healthProfile;
export const selectProsthesisImplantsGrafts = (state: RootState) => state.healthProfileReducer.prosthesisImplantsGrafts;
export const selectDietaryRestrictions = (state: RootState) => state.healthProfileReducer.dietaryRestrictions;
export const selectRecreationalDrugs = (state: RootState) => state.healthProfileReducer.recreationalDrugs;
export const selectAllergiesProfileList = (state: RootState) => state.healthProfileReducer.allergiesProfileList;
export const selectMedicalConditionsProfileList = (state: RootState) =>
  state.healthProfileReducer.medicalConditionsProfileList;
export const selectMedicationsProfileList = (state: RootState) => state.healthProfileReducer.medicationsProfileList;
export const selectProceduresProfileList = (state: RootState) => state.healthProfileReducer.proceduresProfileList;
export const selectVaccinationsProfileList = (state: RootState) => state.healthProfileReducer.vaccinationsProfileList;
export const selectSocialFactorsProfileList = (state: RootState) => state.healthProfileReducer.socialFactorsProfileList;
