import React from 'react';
import { StyleProp, ViewStyle, View, Text, StyleSheet, TextStyle } from 'react-native';

import { colors } from '~/utils/colors';

interface Props {
  label: string;
  color?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  left?: React.ReactNode;
}

export const StatusBadge: React.FC<Props> = ({ label, color, style, left, textStyle }) => {
  return (
    <View style={[styles.container, style]}>
      {left ? (
        left
      ) : (
        <View style={{ display: 'flex', justifyContent: 'center' }}>
          <View style={[styles.badge, color ? { backgroundColor: color } : null]} />
        </View>
      )}
      <Text style={[styles.text, color ? { color } : null, textStyle]}>{label}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    backgroundColor: colors.lightPurple,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    paddingVertical: 4,
    paddingHorizontal: 10,
  },
  text: {
    fontSize: 10,
    fontFamily: 'NotoSans',
  },
  badge: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 4,
    height: 6,
    width: 6,
    borderRadius: 6,
  },
});
