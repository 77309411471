export type ChangeIdType = string | number;

export type ChangeTypePrimitive = string | number;
export type ChangeTypeObject = Record<ChangeIdType, any>;
export type ChangeTypeArray<TChangeTypeObject extends ChangeTypeObject = ChangeTypeObject> = TChangeTypeObject[];

export enum ChangeResultType {
  DELETED = 'deleted',
  UPDATED = 'updated',
  CREATED = 'created',
  MULTIPLE = 'multiple',
}

export type ChangeType<TChangeTypeObject extends ChangeTypeObject = ChangeTypeObject> =
  | TChangeTypeObject
  | TChangeTypeObject[];

type ChangeIdKeyType<TChangeTypeObject extends ChangeTypeObject = ChangeTypeObject> = keyof TChangeTypeObject;

export interface ChangeOptions<TChangeTypeObject extends ChangeTypeObject = ChangeTypeObject> {
  key?: ChangeIdKeyType<TChangeTypeObject>;
  ignoreKeys?: string[];
}

export interface ChangeReturn<TChangeTypeObject extends ChangeTypeObject = ChangeTypeObject> {
  created?: (TChangeTypeObject | ChangeTypePrimitive)[];
  deleted?: (ChangeIdType | ChangeTypePrimitive)[];
  updated?: Record<
    ChangeIdType,
    { full: TChangeTypeObject; changes: Partial<TChangeTypeObject>; removed: Partial<TChangeTypeObject> }
  >;
  change?: ChangeResultType;
}
