import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useContext } from 'react';

import { BaseConsultationDataContext } from './BaseConsultationDataContext';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { HealthRecordModel } from '~/api/models/consultations/models/HealthRecordModel';
import { HealthRecordFormModel } from '~/api/models/consultations/models/UpdateHealthRecordModel';
import { HealthRecordTemplateQuestionsResponse } from '~/api/models/consultations/responses/HealthRecordTemplateQuestionsResponse';
import { UseFormWithRulesReturn } from '~/utils/hooks/FormWithRulesHook';

export interface IHealthRecordDataContext extends BaseConsultationDataContext {
  form: UseFormWithRulesReturn<HealthRecordFormModel>;
  healthRecord: HealthRecordModel;
  loading: boolean;
  addFile: (file: DocumentPickerAsset) => Promise<void>;
  removeFile: (file: MediaModel) => Promise<void>;
  setTemplateQuestions: (questions: HealthRecordTemplateQuestionsResponse) => void;
  setDirtyTemplateAnswers: (dirty: boolean) => void;
}

export const HealthRecordDataContext = React.createContext<IHealthRecordDataContext>({} as IHealthRecordDataContext);
export const useHealthRecordDataContext = (): IHealthRecordDataContext => useContext(HealthRecordDataContext);
