import React from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';
import { TextInput } from 'react-native-paper';

import { FormErrorMessage, SmallNsRegularDisableColor } from '../../components/commonText';
import { AmountInput } from '../inputs/AmountInput';

import { colors } from '~/utils/colors';

interface Props {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  title?: string;
  containerStyle?: StyleProp<ViewStyle>;
}

export const ConsultationTypeCostComponent: React.FC<Props> = ({
  value,
  onChange,
  disabled,
  error,
  errorMessage,
  title,
  containerStyle,
}): JSX.Element => {
  return (
    <View style={containerStyle}>
      {title ? <SmallNsRegularDisableColor>{title}</SmallNsRegularDisableColor> : null}
      <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 10 }}>
        <AmountInput
          left={
            <TextInput.Icon
              icon={() => <Text style={{ fontSize: 24 }}>€</Text>}
              style={{ backgroundColor: colors.transparent }}
            />
          }
          cents={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
        />
      </View>
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </View>
  );
};
