import { useState } from 'react';

import { WizardSteps } from '../WizardStepsHook';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import {
  AppointmentStepsInitialValues,
  DoctorAvailableNowSteps,
  HomeVisitSteps,
  ScheduledAppointmentsSteps,
} from '~/components/patient/booking/constants/appointmentWizardSteps';

export function useAppointmentSteps() {
  const [wizardSteps, setWizardSteps] = useState<WizardSteps>(null);

  const updateSteps = (type: ConsultationTypeEnum, initialValues: AppointmentStepsInitialValues) => {
    switch (type) {
      case ConsultationTypeEnum.HOME_VISIT:
        setWizardSteps(HomeVisitSteps(initialValues));
        break;
      case ConsultationTypeEnum.ON_DEMAND:
        setWizardSteps(DoctorAvailableNowSteps(initialValues));
        break;
      default:
      case ConsultationTypeEnum.SCHEDULED_APPOINTMENT:
        setWizardSteps(ScheduledAppointmentsSteps(initialValues));
        break;
    }
  };

  return {
    wizardSteps,
    updateSteps,
    resetSteps: (initialValues?: AppointmentStepsInitialValues) =>
      setWizardSteps(ScheduledAppointmentsSteps(initialValues)),
  };
}
