import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { View } from 'react-native';

import { NyhaQuestionType, processNyhaQuestion } from './customQuestions/cardiovascularTemplate';

import { HealthRecordQuestionTypeEnum } from '~/api/models/consultations/constants/HealthRecordQuestionTypeEnum';
import { HealthRecordFormModel } from '~/api/models/consultations/models/UpdateHealthRecordModel';
import { HealthRecordTemplateQuestionModel } from '~/api/models/consultations/requests/HealthRecordTemplateQuestionModel';
import { flex1, mb10 } from '~/common/commonStyles';
import { H6NsRegularBlack, nsSemiBold, purple } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { RadioButtons } from '~/components/inputs/RadioButtons';
import { AlertCircle } from '~/components/svgImages';
import { useHealthRecordDataContext } from '~/providers/consultation/HealthRecordDataContext';
import { requiredValidation } from '~/services/validationConfig';
import { colors } from '~/utils/colors';

interface Props {
  question: HealthRecordTemplateQuestionModel;
  control: Control<HealthRecordFormModel>;
}

export const HealthRecordQuestionEditor: React.FC<Props> = ({ question, control }) => {
  const isCardioNyhaGrading = useMemo(
    () => question?.type === HealthRecordQuestionTypeEnum.RADIO && question.title.toLowerCase().startsWith('nyha'),
    [question]
  );
  const { setDirtyTemplateAnswers } = useHealthRecordDataContext();
  if (!question) return null;

  if (isCardioNyhaGrading) {
    return (
      <View>
        <Controller
          name={`template.q${question.id}`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <RadioButtons
              selected={value}
              onValueChange={(v) => {
                onChange(v);
                setDirtyTemplateAnswers(true);
              }}
              label={question.title}
              items={processNyhaQuestion(question.options)}
              itemStyle={[mb10]}
              error={isSubmitted && !!error?.message}
              errorMessage={isSubmitted && error?.message}
              showMandatory
              style={{ maxWidth: 500 }}
              itemNode={(item: NyhaQuestionType) => (
                <View style={[flex1]}>
                  <H6NsRegularBlack style={[nsSemiBold, purple]}>{item.title}</H6NsRegularBlack>
                  <H6NsRegularBlack style={[purple, { fontSize: 12 }]}>{item.subtitle}</H6NsRegularBlack>
                  <H6NsRegularBlack style={{ fontSize: 12 }}>{item.met}</H6NsRegularBlack>
                </View>
              )}
            />
          )}
          rules={{
            required: requiredValidation(question.title),
          }}
        />
        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
          <View style={{ marginTop: 3 }}>
            <AlertCircle color={colors.purpleGrey} width={12} height={12} />
          </View>
          <H6NsRegularBlack style={{ fontSize: 12, color: colors.purpleGrey, marginLeft: 4 }}>
            MET (metabolic equivalent) is defined as the resting VO2 for a 40-year-old 70kg man.{'\n'}1 MET = 3.5mL O2
            /min/kg body weight, CHF = Congestive Heart Failure, LV = Left ventricle.
          </H6NsRegularBlack>
        </View>
      </View>
    );
  } else if (question.type === HealthRecordQuestionTypeEnum.TEXT) {
    return (
      <Controller
        name={`template.q${question.id}`}
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
          <FloatingInput
            value={value}
            onChangeValue={(v) => {
              onChange(v);
              setDirtyTemplateAnswers(true);
            }}
            onBlur={onBlur}
            error={isSubmitted && !!error?.message}
            errorMessage={isSubmitted && error?.message}
            label={question.title}
            showMandatory
          />
        )}
        rules={{
          required: requiredValidation(question.title),
        }}
      />
    );
  } else if (question.type === HealthRecordQuestionTypeEnum.RADIO) {
    return (
      <Controller
        name={`template.q${question.id}`}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
          <RadioButtons
            horizontal
            selected={value}
            onValueChange={(v) => {
              onChange(v);
              setDirtyTemplateAnswers(true);
            }}
            label={question.title}
            items={question.options.map((option) => ({ id: option, name: option }))}
            containerStyle={{ justifyContent: 'space-between' }}
            itemStyle={{ flexDirection: 'column' }}
            error={isSubmitted && !!error?.message}
            errorMessage={isSubmitted && error?.message}
            showMandatory
            style={{ maxWidth: 500 }}
            textStyle={[nsSemiBold, purple]}
          />
        )}
        rules={{
          required: requiredValidation(question.title),
        }}
      />
    );
  }

  return null;
};
