import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useDoctorBiography } from '../accounts/Biography';
import { useMedicalRegistration } from '../accounts/MedicalRegistration';
import { useUserDetails } from '../accounts/UserDetails';

import { apiUpdateMyAccount } from '~/api/services/authorisation';
import { apiGetDoctorSpecialisationArea, apiUpdateSpecialisationArea } from '~/api/services/medicalRegistration';
import { SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { IdentificationDocumentsEnum } from '~/constants/documents';
import { UPDATE_USER_ACCOUNT } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { isDoctorVersion } from '~/utils/buildConfig';

export const useProfileUserDetails = () => {
  const [saving, setSaving] = useState(false);
  const { userDetails, getUserDetails } = useUserDetails();
  const { medicalRegistration } = useMedicalRegistration();
  const { biography, updateBiography } = useDoctorBiography({ accountId: userDetails?.account.id });
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    reset,
    resetField,
    formState: { defaultValues },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      country_code: '',
      mobile_number: '',
      first_name: '',
      last_name: '',
      title: '',
      dob: '',
      document_type: IdentificationDocumentsEnum.IDENTITY_CARD,
      document_number: '',
      gender: undefined,
      biography: '',
      registrationNo: '',
      yearOfWarrant: '',
      areaOfSpecialisation: undefined,
    },
  });

  const setFormValues = () => {
    reset({
      ...defaultValues,
      email: userDetails?.account.email || '',
      country_code: userDetails?.account.country_code || '',
      mobile_number: userDetails?.account.mobile_number || '',
      first_name: userDetails?.account.first_name || '',
      last_name: userDetails?.account.last_name || '',
      title: userDetails?.account.title || '',
      document_type: userDetails?.account.passport_number
        ? IdentificationDocumentsEnum.PASSPORT
        : IdentificationDocumentsEnum.IDENTITY_CARD,
      document_number: userDetails?.account.passport_number || userDetails?.account.national_id_number || '',
      dob: userDetails?.account.date_of_birth || '',
      gender: userDetails?.account.gender || undefined,
    });
  };

  const resetFormValues = () => {
    reset({
      ...defaultValues,
      biography,
    });
  };

  useEffect(() => {
    setFormValues();
  }, [userDetails]);

  useEffect(() => {
    if (isDoctorVersion() && medicalRegistration) {
      reset({
        ...defaultValues,
        registrationNo: medicalRegistration.number || undefined,
        yearOfWarrant: medicalRegistration.warrant_year || undefined,
      });
    }
  }, [medicalRegistration]);

  useEffect(() => {
    if (isDoctorVersion()) {
      reset({
        ...defaultValues,
        biography,
      });
    }
  }, [biography]);

  useEffect(() => {
    if (isDoctorVersion()) {
      apiGetDoctorSpecialisationArea()
        .then((res) => {
          resetField('areaOfSpecialisation', { defaultValue: res.data.data });
        })
        .catch((e) => {
          ErrorAlert(e);
        });
    }
  }, []);

  const onSubmit = async (details) => {
    try {
      setSaving(true);
      const newDetails = await apiUpdateMyAccount({
        email: userDetails.account.email,
        country_code: userDetails.account.country_code,
        mobile_number: userDetails.account.mobile_number,
        date_of_birth: userDetails.account.date_of_birth,

        first_name: details.first_name,
        last_name: details.last_name,
        title: details.title,
        passport_number: userDetails.account.passport_number,
        national_id_number: userDetails.account.national_id_number,
        gender: details.gender,
      });

      if (newDetails.data) {
        dispatch(UPDATE_USER_ACCOUNT(newDetails.data));
      }

      if (details.biography) {
        await updateBiography(details.biography);
      }

      const valueIds = details.areaOfSpecialisation?.map((value) => value.id);

      if (valueIds) {
        await apiUpdateSpecialisationArea({ specialisation_area_ids: valueIds });
      }

      SuccessAlert(['Your details have been updated successfully']);
    } catch (e) {
      ErrorAlert(e);
    }
    setSaving(false);
  };

  return { onSubmit: handleSubmit(onSubmit), saving, resetFormValues, control, getUserDetails };
};
