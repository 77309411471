import React from 'react';
import { Controller } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';

import { alignItemsRight, flexRow, alignItemsCenter, mt10, ml10 } from '~/common/commonStyles';
import { InfoButton } from '~/components/buttons/InfoButton';
import { H5TtmSemiBoldDanger, H6NsRegularBlack } from '~/components/commonText';
import { Checkbox } from '~/components/inputs/Checkbox';
import { RoundedRemoveIcon } from '~/components/svgImages';
import { usePrescriptionDataContext } from '~/providers/consultation/PrescriptionDataContext';
import { Row, Column } from '~/theme/components/grid';
import { labels } from '~/utils/labels';
import { helpLabels } from '~/utils/labels/helpLabels';

interface Props {
  index: number;
}

export const PrescriptionsRemoveItem: React.FC<Props> = ({ index }) => {
  const {
    removePrescription,
    form: { control },
  } = usePrescriptionDataContext();

  return (
    <Row style={mt10}>
      <Column span={{ xs: 12, md: null }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
          }}>
          <Controller
            control={control}
            name={`prescriptions.${index}.is_collectable`}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                checked={value === false}
                onChange={(val) => onChange(!val)}
                label="Mark as not collectable"
                postLabel={
                  <InfoButton
                    style={ml10}
                    message={helpLabels.markNotCollectableInfo}
                    tooltip={helpLabels.markNotCollectableInfo}
                    title="About Mark as not collectable"
                  />
                }
              />
            )}
          />
        </View>
      </Column>
      <Column span={{ xs: 12, md: 'auto' }} style={alignItemsRight}>
        <TouchableOpacity
          onPress={() => removePrescription(index)}
          style={[flexRow, alignItemsCenter, { marginVertical: 8 }]}>
          <RoundedRemoveIcon width="15" height="15" />
          <H5TtmSemiBoldDanger style={{ marginLeft: 10 }}>{labels.removeItem}</H5TtmSemiBoldDanger>
        </TouchableOpacity>
      </Column>
    </Row>
  );
};
