import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import { useDocumentExportHook } from '~/api/hooks/patientDataHandling/DocumentExportHook';
import { ExportTypeMapping } from '~/api/models/common/constants/ExportTypesEnum';
import { UploadPatientDataStatusEnum } from '~/api/models/patientData/constants/UploadPatientDataStatusEnum';
import { ExportPatientDataModel } from '~/api/models/patientData/models/ExportPatientDataModel';
import { flex1 } from '~/common/commonStyles';
import { DataTable, DataTableColumns } from '~/components/common/DataTable/DataTable';
import { OutlineButton } from '~/components/commonButton';
import { H6NsRegularBlack, H6NsSemiBoldBlack, H6TtmSemiBoldTheme } from '~/components/commonText';
import { NewBadge } from '~/components/misc/NewBadge';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { CLEAR_EXPORTS_DATA } from '~/redux/reducers/requestsReducer';
import { useAppDispatch } from '~/redux/store';
import { colors } from '~/utils/colors';
import { TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';

const STATUS_COLOR_MAPPING = {
  [UploadPatientDataStatusEnum.FAILED]: colors.danger,
  [UploadPatientDataStatusEnum.PENDING]: colors.warning,
  [UploadPatientDataStatusEnum.PROCESSING]: colors.purple,
  [UploadPatientDataStatusEnum.SUCCESS]: colors.success,
  [UploadPatientDataStatusEnum.COMPLETED]: colors.success,
  [UploadPatientDataStatusEnum.EXPIRED]: colors.placeholderGrey,
};

export const DocumentExportList: React.FC = () => {
  const { setPagination, loading, getDownloadExports, exportsDocuments, exportsPagination } = useDocumentExportHook();
  const dispatch = useAppDispatch();
  const { showNewBadge } = useNewBadge();
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    dispatch(CLEAR_EXPORTS_DATA());
  }, []);

  const columns: DataTableColumns = [
    {
      key: 'created_at',
      title: 'Date and Time',
      cell: (cellData) => (
        <View style={flex1}>
          <H6NsSemiBoldBlack>{parseDateTime(cellData, { outputFormat: 'D MMMM' })}</H6NsSemiBoldBlack>
          <H6NsRegularBlack>{parseDateTime(cellData, { outputFormat: TIME_FORMAT })}</H6NsRegularBlack>
        </View>
      ),
      columnStyle: { maxWidth: 110 },
    },
    {
      key: 'file_name',
      title: 'Export type',
      selector: (item: ExportPatientDataModel) => ExportTypeMapping[item.type],
    },
    {
      key: 'new',
      cell: (_, item) => (showNewBadge({ value: item }) ? <NewBadge /> : null),
      columnStyle: {
        maxWidth: 60,
      },
    },
    {
      key: 'status',
      title: 'Status',
      selector: (item: ExportPatientDataModel) => item.status.toLowerCase(),
      cell: (cellData) => (
        <StatusBadge
          label={cellData}
          color={STATUS_COLOR_MAPPING[cellData]}
          textStyle={{ textTransform: 'capitalize' }}
        />
      ),
      columnStyle: { maxWidth: 100 },
    },
    {
      key: 'download',
      cell: (cellData, itemData: ExportPatientDataModel) =>
        itemData.media && itemData.status?.toLowerCase() === UploadPatientDataStatusEnum.COMPLETED ? (
          <OutlineButton
            funCallback={() => {
              getDownloadExports(itemData.id);
            }}
            label="Download"
          />
        ) : null,
      columnStyle: { maxWidth: 120 },
    },
  ];

  return (
    <View style={[flex1]}>
      <DataTable
        tableId="documentExport"
        columns={columns}
        items={exportsDocuments || []}
        totalCount={exportsPagination?.total || 0}
        page={exportsPagination?.current_page || 1}
        onPageChanged={setPagination}
        numberOfItemsPerPage={exportsPagination?.per_page}
        infiniteScroll={{ loading, estimatedItemSize: { desktop: 50, mobile: 130 } }}
        emptyNote="No document export records available."
        rowIsNewIdentifier={isMobile ? (item) => showNewBadge({ value: item }) : null}
        mobileBreakpoint={DeviceSizeDefaults.tablet}
        mobileRenderItem={(item: ExportPatientDataModel, content) => (
          <View style={[{ display: 'flex', flexDirection: 'column', width: '100%' }]}>
            <View style={styles.status}>
              <View style={{ flex: 1 }}>
                <H6TtmSemiBoldTheme>{content.file_name.cellData}</H6TtmSemiBoldTheme>
              </View>
              <View>
                <StatusBadge
                  label={item.status}
                  color={STATUS_COLOR_MAPPING[item.status.toLowerCase()]}
                  textStyle={{ textTransform: 'capitalize' }}
                />
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
              <H6NsSemiBoldBlack>{parseDateTime(item.created_at, { outputFormat: 'D MMMM, ' })}</H6NsSemiBoldBlack>
              <H6NsRegularBlack>{parseDateTime(item.created_at, { outputFormat: TIME_FORMAT })}</H6NsRegularBlack>
            </View>
            {item.media && content.status.cellData === UploadPatientDataStatusEnum.COMPLETED ? (
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10 }}>
                <OutlineButton
                  funCallback={() => {
                    getDownloadExports(item.id);
                  }}
                  label="Download"
                  style={{ width: 'auto' }}
                />
              </View>
            ) : null}
          </View>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  status: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
});
