import React, { useState } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { TitledTouchableOpacity } from './TitledTouchableOpacity';
import { Hoverable } from '../common/hover/Hoverable';

import { colors } from '~/utils/colors';

interface Props {
  disabled?: boolean;
  transparent?: boolean;
  onPress?: () => void;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  hoverStyle?: StyleProp<ViewStyle>;
  title?: string;
}
export const IconButton: React.FC<Props> = ({ transparent, style, disabled, hoverStyle, onPress, children, title }) => {
  const [hovering, setHovering] = useState(false);
  return (
    <Hoverable onHoverIn={() => setHovering(true)} onHoverOut={() => setHovering(false)}>
      <TitledTouchableOpacity
        style={[
          iconButtonStyles.touchable,
          transparent ? { borderColor: colors.transparent } : null,
          style,
          hovering && !disabled ? hoverStyle || iconButtonStyles.touchableHovering : null,
          disabled ? iconButtonStyles.touchableDisabled : null,
        ]}
        title={title ?? ''}
        onPress={onPress}
        disabled={disabled}>
        {children}
      </TitledTouchableOpacity>
    </Hoverable>
  );
};

const iconButtonStyles = StyleSheet.create({
  touchable: {
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.lightPurple2,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
  touchableHovering: {
    backgroundColor: colors.lightPurple2,
  },
  touchableDisabled: {
    opacity: 0.5,
  },
});
