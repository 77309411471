import React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { ProgressBar } from 'react-native-paper';

import { H2TtmSemiBoldBlack, SmallNsRegularPlaceholder, SmallNsRegularSecondaryBlack } from './commonText';
import { RowView } from './commonViews';
import { NotificationIcon } from './svgImages';
import { colors } from '../utils/colors';
import { theme } from '../utils/theme';

import HtmlRendering from '~/integrations/renderHtml';
import { HtmlRenderingProps } from '~/integrations/renderHtml/interfaces';

// create a component
interface ProgressBarProps {
  progress: number;
  noSpacing?: boolean;
  spacing?: number;
  indeterminate?: boolean;
}
export const ProgressBars = (props: ProgressBarProps) => {
  return (
    <ProgressBar
      indeterminate={props.indeterminate}
      progress={props.progress}
      color={colors.purple}
      style={{ height: 6, borderRadius: 5, marginVertical: props.noSpacing ? 0 : props.spacing ?? 20 }}
    />
  );
};

export const HTMLViewerComponent = (props: HtmlRenderingProps) => {
  return <HtmlRendering {...props} />;
};

interface LabelWithTextProps {
  label: string;
  labelWidth?: number;
  color?: string;
  fontFamily?: string;
  fontWeight?: string;
  value?: string;
  style?: StyleProp<ViewStyle>;
  gap?: number;
}
export const LabelWithTextRow = (props: LabelWithTextProps) => {
  return (
    <View style={[theme.singleLineTextWithLabelRow, props.style]}>
      <SmallNsRegularPlaceholder style={{ flex: 1, minWidth: props.labelWidth ?? 90 }}>
        {props.label}
      </SmallNsRegularPlaceholder>
      <SmallNsRegularSecondaryBlack
        style={[
          { flex: 2 },
          props.color ? { color: props.color } : {},
          props.fontFamily ? { fontFamily: props.fontFamily } : {},
          props.fontWeight ? { fontWeight: props.fontWeight } : {},
        ]}>
        {props.value}
      </SmallNsRegularSecondaryBlack>
    </View>
  );
};

export const LabelWithTextColumn = (props: LabelWithTextProps) => {
  return (
    <View style={[theme.singleLineTextWithLabelColumn, props.style]}>
      <SmallNsRegularPlaceholder style={{ flex: 1, marginBottom: props.gap }}>{props.label}</SmallNsRegularPlaceholder>
      <SmallNsRegularSecondaryBlack
        style={[
          { flex: 1 },
          props.color ? { color: props.color } : {},
          props.fontFamily ? { fontFamily: props.fontFamily } : {},
          props.fontWeight ? { fontWeight: props.fontWeight } : {},
        ]}>
        {props.value}
      </SmallNsRegularSecondaryBlack>
    </View>
  );
};

export const HeaderWithNotification: React.FC<{ label: string }> = (props) => {
  return (
    <RowView>
      <H2TtmSemiBoldBlack>{props.label}</H2TtmSemiBoldBlack>
      <TouchableOpacity onPress={() => {}}>
        <NotificationIcon height={25} width={25} />
      </TouchableOpacity>
    </RowView>
  );
};
