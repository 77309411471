import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { AccountModel } from '../../api/models/accounts/models/AccountModel';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { ProfileImageComponent } from './ProfileImageComponent';
import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { isDoctorVersion, whenAppType } from '~/utils/buildConfig';

interface Props {
  account: AccountModel;
  dependantInitials?: string;
  dependant?: MediaModel;
}

export const DependantProfileImageComponent: React.FC<Props> = ({ account, dependantInitials, dependant }) => {
  const { userDetails } = useUserDetails({ allowStale: true });
  const dependantImageSrc = useMemo(() => dependant?.conversions?.avatar ?? dependant?.url, [dependant]);

  const hasDependant = useMemo(
    () =>
      (dependantImageSrc || dependantInitials) &&
      whenAppType({
        whenPatient: account.id === userDetails?.account?.id,
        whenDoctor: account.id !== userDetails?.account?.id,
        else: false,
      }),
    [dependantImageSrc, dependantInitials, userDetails, account]
  );

  const renderProfileImages = () => (
    <>
      <ProfileImageComponent
        size={24}
        account={account}
        style={[styles.userContainer, { [isDoctorVersion() ? 'left' : 'right']: 0 }]}
      />
      <ProfileImageComponent
        size={28}
        src={dependantImageSrc}
        initials={dependantInitials}
        style={[
          styles.dependantConatiner,
          {
            position: 'absolute',
            bottom: 0,
            [isDoctorVersion() ? 'right' : 'left']: 0,
          },
        ]}
      />
    </>
  );

  return (
    <View>
      {!hasDependant ? (
        <ProfileImageComponent size={36} account={account} />
      ) : (
        <View style={styles.container}>{renderProfileImages()}</View>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: 36,
    height: 36,
  },

  userContainer: {
    opacity: 0.6,
    position: 'absolute',
    top: 0,
  },

  dependantConatiner: {
    position: 'absolute',
    bottom: 0,
  },
});
