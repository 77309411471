import { useBeforeRemove, useBeforeUnload } from './UnloadHook';

export const usePreventUnload = (enabled: boolean) => {
  useBeforeUnload((e) => {
    if (enabled) {
      e.preventDefault();
      e.returnValue = '';
    }
  });

  useBeforeRemove((e) => {
    if (enabled) {
      e.preventDefault();
    }
  });
};
