import { useEffect, useState } from 'react';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { AppointmentsPastState } from '~/constants/appointments/appointmentStateMapping';

const stateNotStarted = (state?: ConsultationStateEnum) => {
  return (
    state &&
    [
      ConsultationStateEnum.Scheduled,
      ConsultationStateEnum.PendingPayment,
      ConsultationStateEnum.RequestSubmitted,
    ].includes(state)
  );
};

export const stateCanCancel = (state?: ConsultationStateEnum) => {
  return state && [ConsultationStateEnum.Scheduled, ConsultationStateEnum.PendingPayment].includes(state);
};

const stateStarted = (state?: ConsultationStateEnum) => state === ConsultationStateEnum.Started;

const stateIsPast = (state?: ConsultationStateEnum) => !!AppointmentsPastState[state];

export const stateEndedOrPassed = (state?: ConsultationStateEnum) =>
  state === ConsultationStateEnum.Ended || stateIsPast(state);

export const useAppointmentState = (consultation?: ConsultationModel) => {
  const [notStarted, setNotStarted] = useState(false);
  const [started, setStarted] = useState(false);
  const [isEndedOrPassed, setIsEndedOrPassed] = useState(false);
  const [isCancellable, setIsCancellable] = useState(false);

  useEffect(() => {
    setNotStarted(stateNotStarted(consultation?.state));
    setStarted(stateStarted(consultation?.state));
    setIsEndedOrPassed(stateEndedOrPassed(consultation?.state));
    setIsCancellable(stateCanCancel(consultation?.state));
  }, [consultation]);

  return {
    notStarted,
    started,
    isEndedOrPassed,
    isCancellable,
  };
};
