import { Route } from '@react-navigation/native';
import React from 'react';

import { SubscriptionPlanListing } from '~/components/subscriptions/SubscriptionPlanListing';
import { PageLayout } from '~/layouts/EnhancedPageLayout';

interface Props {
  route?: Route<string, { title: string }>;
  title?: string;
  hideBack?: boolean;
}
export const PaywallScreen: React.FC<Props> = ({ route, title, hideBack }) => {
  return (
    <PageLayout title={title || route?.params?.title} hideBack={hideBack} hideGradient>
      <SubscriptionPlanListing />
    </PageLayout>
  );
};
