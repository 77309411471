import React from 'react';
import { View } from 'react-native';

import { Accordion } from './Accordion';

import { pt15, p10, alignSelfRight } from '~/common/commonStyles';
import { OutlineButton } from '~/components/commonButton';
import { H6NsRegularBlack } from '~/components/commonText';
import { isWeb } from '~/utils/buildConfig';

interface Props {
  title: string;
  groupValue?: string;
  message?: string;
  expanded?: boolean;
  buttonTitle?: string;
  buttonAction?: () => void;
}

export const AccordionWithAction: React.FC<Props> = ({
  title,
  message,
  buttonTitle,
  expanded,
  buttonAction,
  groupValue,
}) => {
  return (
    <View style={pt15}>
      <Accordion title={title} expanded={expanded} groupValue={groupValue}>
        <View style={p10}>
          <H6NsRegularBlack>{message}</H6NsRegularBlack>
          {buttonTitle && buttonAction && (
            <View style={[pt15, isWeb() ? [alignSelfRight, { minWidth: 150 }] : null]}>
              <OutlineButton label={buttonTitle} funCallback={buttonAction} />
            </View>
          )}
        </View>
      </Accordion>
    </View>
  );
};
