import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View, ScrollView, StyleSheet, Animated, Dimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { OnBoardingBalls } from './OnBoardingBalls';
import { OnBoardingWelcome } from './OnBoardingWelcome';
import { OnBoardingDoctorPage1 } from './doctor/OnBoardingDoctorPage1';
import { OnBoardingDoctorPage2 } from './doctor/OnBoardingDoctorPage2';
import { OnBoardingDoctorPage3 } from './doctor/OnBoardingDoctorPage3';
import { OnBoardingDoctorPage4 } from './doctor/OnBoardingDoctorPage4';
import { OnBoardingPatientPage1 } from './patient/OnBoardingPatientPage1';
import { OnBoardingPatientPage2 } from './patient/OnBoardingPatientPage2';
import { OnBoardingPatientPage3 } from './patient/OnBoardingPatientPage3';
import { OnBoardingPatientPage4 } from './patient/OnBoardingPatientPage4';
import { OnBoardingPharmacyPage1 } from './pharmacy/OnBoardingPharmacyPage1';
import { OnBoardingPharmacyPage2 } from './pharmacy/OnBoardingPharmacyPage2';
import { OnBoardingPharmacyPage3 } from './pharmacy/OnBoardingPharmacyPage3';
import { OnBoardingPharmacyPage4 } from './pharmacy/OnBoardingPharmacyPage4';
import { isDoctorVersion, isNative, isWeb, whenAppType } from '../../utils/buildConfig';
import { colors } from '../../utils/colors';
import { labels } from '../../utils/labels';
import ExpandingDotAnimation from '../animatedPagination/ExpandingDotAnimation';
import { SmallButton } from '../commonButton';
import CustomSurface from '../customSurface';
import { AppLogo } from '../svgImages';

import { useBreakpoints } from '~/utils/hooks/GridHook';

const screenDimensions = Dimensions.get('screen');

interface Props {
  onComplete?: () => Promise<void>;
}

const getOnBoardingComponents = whenAppType({
  else: () => [],
  whenDoctor: (width: number) => [
    <OnBoardingDoctorPage1 width={width} key={1} />,
    <OnBoardingDoctorPage2 width={width} key={2} />,
    <OnBoardingDoctorPage3 width={width} key={3} />,
    <OnBoardingDoctorPage4 width={width} key={4} />,
    <OnBoardingWelcome width={width} key={5} />,
  ],
  whenPatient: (width: number) => [
    <OnBoardingPatientPage1 width={width} key={1} />,
    <OnBoardingPatientPage2 width={width} key={2} />,
    <OnBoardingPatientPage3 width={width} key={3} />,
    <OnBoardingPatientPage4 width={width} key={4} />,
    <OnBoardingWelcome width={width} key={5} />,
  ],
  whenPharmacist: (width: number) => [
    <OnBoardingPharmacyPage1 width={width} key={1} />,
    <OnBoardingPharmacyPage2 width={width} key={2} />,
    <OnBoardingPharmacyPage3 width={width} key={3} />,
    <OnBoardingPharmacyPage4 width={width} key={4} />,
    <OnBoardingWelcome width={width} key={5} />,
  ],
});

const getLogoPosition = whenAppType({
  else: (_) => 0,
  whenPatient: (index: number) => [0, -1, -1, -1, -1][index],
  whenDoctor: (index: number) => [0, 1, 1, 0.5, -1][index],
  whenPharmacist: (index: number) => [0, 1, 0, 0, -1][index],
});

const OnboardingScreen: React.FC<Props> = ({ onComplete }) => {
  //Current item index of ScrollView
  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const logoAnim = useRef(new Animated.Value(getLogoPosition(0))).current;
  const { bottom } = useSafeAreaInsets();
  const { isMobile } = useBreakpoints();
  const currentScreenRef = useRef(0);
  const [currentScreen, setCurrentScreen] = useState(0);
  const refs = useRef<ScrollView>(null);
  const scrollX = useRef(new Animated.Value(0)).current;
  const refWidth = useRef<number>(isNative() || isMobile ? screenDimensions.width : null);
  const [width, setWidth] = useState<number>(isNative() || isMobile ? screenDimensions.width : null);

  const changeIndex = (index: number) => {
    let newIndex = index;
    if (newIndex >= getOnBoardingComponents(width).length) newIndex = 0;
    setCurrentScreen(newIndex);
    currentScreenRef.current = newIndex;
    refs.current?.scrollTo({ x: refWidth.current * newIndex, animated: true });
  };

  useEffect(() => {
    if (!isMobile) {
      setInterval(() => {
        changeIndex(currentScreenRef.current + 1);
      }, 5000);
    }
  }, []);

  const onBoardingComponents = useMemo(() => getOnBoardingComponents(width), [width]);

  useEffect(() => {
    if (isMobile) {
      fadeAnim.setValue(1);
      return;
    }
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
      useNativeDriver: !isWeb(),
    }).start();
  }, [fadeAnim]);

  useEffect(() => {
    Animated.timing(logoAnim, {
      toValue: (width * 0.8 - 90) * getLogoPosition(currentScreen),
      duration: currentScreen === 0 && (isMobile || isNative()) ? 0 : 500,
      useNativeDriver: false,
    }).start();
  }, [logoAnim, currentScreen]);

  const isLastIndex = useMemo(
    () => currentScreen >= onBoardingComponents.length - 1,
    [currentScreen, onBoardingComponents?.length]
  );

  return (
    <>
      <Animated.View style={{ opacity: fadeAnim, flex: 1 }}>
        <View
          style={[styles.onboardingContainer, { position: 'relative' }]}
          onLayout={(event) => {
            setWidth(event.nativeEvent.layout.width);
            refWidth.current = event.nativeEvent.layout.width;
          }}>
          {width ? (
            <>
              <OnBoardingBalls
                width={width}
                backgroundColor={isDoctorVersion() && currentScreen === 3 ? colors.lightPurple2 : undefined}
              />
              <Animated.ScrollView
                style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }}
                onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }], {
                  useNativeDriver: false,
                })}
                horizontal
                pagingEnabled
                showsHorizontalScrollIndicator={false}
                ref={refs}
                decelerationRate="normal"
                scrollEventThrottle={16}
                scrollEnabled={false}>
                {onBoardingComponents.map((item) => item)}
              </Animated.ScrollView>
              <View style={styles.staticContainer}>
                <View
                  style={{
                    position: 'absolute',
                    left: width * 0.1,
                    right: width * 0.1,
                    height: 90,
                    bottom: 50,
                  }}>
                  <Animated.View style={{ position: 'absolute', left: logoAnim, bottom: 0 }}>
                    <CustomSurface style={styles.centerSurface}>
                      <AppLogo height={60} width={60} />
                    </CustomSurface>
                  </Animated.View>
                </View>
                <View style={{ paddingTop: 20 }}>
                  <ExpandingDotAnimation
                    data={onBoardingComponents}
                    expandingDotWidth={27}
                    scrollX={scrollX}
                    inActiveDotOpacity={0.6}
                    activeDotColor={colors.purple}
                    width={width}
                    dotStyle={{
                      width: 10,
                      height: 10,
                      backgroundColor: colors.purple,
                      borderRadius: 5,
                      marginHorizontal: 10,
                    }}
                    containerStyle={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      flexDirection: 'row',
                    }}
                  />
                </View>
              </View>
            </>
          ) : null}
        </View>
      </Animated.View>
      {isMobile && !!width ? (
        <View style={[styles.mobileContainer, { bottom }]}>
          <>
            {!isLastIndex ? (
              <SmallButton
                label={labels.next}
                funCallback={() => {
                  setCurrentScreen((index) => {
                    changeIndex(index + 1);
                    return index + 1;
                  });
                }}
              />
            ) : (
              <SmallButton
                label="Let's get started"
                funCallback={async () => {
                  if (onComplete) await onComplete();
                }}
              />
            )}
          </>
        </View>
      ) : null}
    </>
  );
};

//make this component available to the app
export default OnboardingScreen;

const styles = StyleSheet.create({
  onboardingContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: colors.lightPurple,
  },
  staticContainer: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: '37.5%',
    paddingBottom: 20,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  centerSurface: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    height: 90,
    width: 90,
    elevation: 2,
    backgroundColor: 'white',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
  },
  mobileContainer: {
    position: 'absolute',
    top: 0,
    right: 45,
    left: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
});
