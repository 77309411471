import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import React from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { useDependantsRelationshipListing } from '~/api/hooks/referenceData/DependantsRelationshipListing';
import { flex1, mb10, pb15 } from '~/common/commonStyles';
import { CancelContinueFooterArray } from '~/components/buttons/CancelContinueFooterArray';
import { H6NsRegularBlack } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import DatePicker from '~/components/inputs/dateTime/DatePicker';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { IdentificationDocumentTypes } from '~/constants/documents';
import { ItemProps } from '~/interfaces/generalProps';
import { WizardLayout } from '~/layouts/WizardLayout';
import { NavType } from '~/navigation/types';
import { useAddDependantContext } from '~/providers/dependants/AddDependantContext';
import { genderList } from '~/services/config';
import {
  alphabetCharactersValidation,
  maxAgeValidation,
  maxDateValidation,
  minLengthValidation,
  requiredValidation,
  validationSchema,
} from '~/services/validationConfig';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { labels } from '~/utils/labels';
import { dependantsLabels } from '~/utils/labels/dependantsLabels';
import { screenName } from '~/utils/screenName';

const genderItems: ItemProps[] = genderList;

export const DependantDetailsStep: React.FC = () => {
  const { control, loading, submitDetails } = useAddDependantContext();
  // const countryListing = useCountryListing(CountryListEnum.COUNTRY_NAME);
  const { navigate } = useNavigation<NavType>();
  const { relationshipItems } = useDependantsRelationshipListing();

  // const { same_address } = useWatch({ control });
  const buttons = CancelContinueFooterArray({
    waiting: loading,
    onCancel: () => navigate(screenName.Dependants),
    onConfirm: submitDetails,
  });

  return (
    <View style={[flex1]}>
      <WizardLayout title={dependantsLabels.addDependantTitle} buttons={buttons}>
        <Grid grid={{ gutters: 16, noOuterGutter: true }}>
          <Container alignSelf="flex-start" maxContainerWidths={760} style={[pb15]}>
            <Row style={mb10}>
              <Column>
                <H6NsRegularBlack>{dependantsLabels.addDependantSubtitle} </H6NsRegularBlack>
              </Column>
            </Row>
            <Row>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="dependant_relationship_type_id"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                    <Dropdown
                      label={dependantsLabels.relationshipToHolder}
                      value={value}
                      setValue={onChange}
                      list={relationshipItems.map((item) => ({ label: item.name, value: item.id }))}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      showMandatory
                    />
                  )}
                  rules={{
                    required: requiredValidation(dependantsLabels.relationshipToHolder),
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="account.first_name"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                    formState: { isSubmitted },
                  }) => (
                    <FloatingInput
                      showMandatory
                      maxLength={validationSchema.name.maxLength}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      value={value ?? ''}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      label={labels.legalFirstName}
                    />
                  )}
                  rules={{
                    validate: { alphabetCharactersValidation },
                    required: requiredValidation(labels.legalFirstName),
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="account.last_name"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                    formState: { isSubmitted },
                  }) => (
                    <FloatingInput
                      showMandatory
                      maxLength={validationSchema.lastName.maxLength}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      value={value ?? ''}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      label={labels.legalLastName}
                    />
                  )}
                  rules={{
                    validate: { alphabetCharactersValidation },
                    required: requiredValidation(labels.legalLastName),
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="document_type"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Dropdown
                      list={IdentificationDocumentTypes}
                      setValue={onChange}
                      value={value}
                      error={!!error}
                      errorMessage={error?.message as string}
                      label={labels.documentType}
                      showMandatory
                    />
                  )}
                  rules={{
                    required: requiredValidation(labels.documentType),
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="document_number"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    formState: { isSubmitted },
                    fieldState: { error },
                  }) => (
                    <FloatingInput
                      showMandatory
                      maxLength={validationSchema.passportOrIdCard.maxLength}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      value={value}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      label={labels.idCardOrPassport}
                    />
                  )}
                  rules={{
                    minLength: minLengthValidation(validationSchema.passportOrIdCard.minLength),
                    required: requiredValidation(labels.idCardOrPassport),
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="account.date_of_birth"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                    <DatePicker
                      showMandatory
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      onChangeValue={onChange}
                      value={value}
                      label={labels.dateOfBirthPlaceholder}
                      minDate={moment().subtract(120, 'years').toDate()}
                      maxDate={new Date()}
                    />
                  )}
                  rules={{
                    required: requiredValidation('Date of birth'),
                    validate: {
                      maxAge: maxAgeValidation(validationSchema.age.max, 'Dependant'),
                      maxDateValidation: maxDateValidation(new Date(), 'Date of birth must be before today'),
                    },
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="account.gender"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                    <Dropdown
                      label={labels.gender}
                      value={value}
                      setValue={onChange}
                      list={genderItems.map((item) => ({ label: item.name, value: item.id }))}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                    />
                  )}
                />
              </Column>
            </Row>
          </Container>
          {/* <Container alignSelf="flex-start">
            <View style={[separator, mv15]} />
            <Row>
              <Column>
                <H3TtmSemiBoldBlack style={mb10}>What is their home address?</H3TtmSemiBoldBlack>
              </Column>
            </Row>
            <Row>
              <Column span="auto">
                <Controller
                  name="same_address"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                    <Checkbox
                      label={dependantsLabels.sameAddress}
                      checked={value}
                      onChange={onChange}
                      error={isSubmitted && !!error?.message}
                      errorMessage={isSubmitted && error?.message}
                    />
                  )}
                />
              </Column>
            </Row>
            <Row>
              <Column span={{ xs: 12, md: 4 }}>
                <Controller
                  name="address.country_id"
                  control={control}
                  render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <Dropdown
                      list={countryListing.map((item) => ({ label: item.name, value: item.id }))}
                      setValue={onChange}
                      value={value}
                      error={!!error}
                      errorMessage={error?.message}
                      label={labels.country}
                      searchable
                      disabled={same_address}
                    />
                  )}
                  rules={{
                    required: !same_address ? requiredValidation(labels.country) : undefined,
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 4 }}>
                <Controller
                  name="address.post_code"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    formState: { isSubmitted },
                    fieldState: { error },
                  }) => (
                    <FloatingInput
                      value={value}
                      label="Postcode"
                      maxLength={validationSchema.postCode.maxLength}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      disabled={same_address}
                    />
                  )}
                  rules={{
                    required: !same_address ? requiredValidation(labels.postCode) : undefined,
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: 4 }}>
                <Controller
                  name="address.line_1"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    formState: { isSubmitted },
                    fieldState: { error },
                  }) => (
                    <FloatingInput
                      maxLength={validationSchema.address.maxLength}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      value={value}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      label={labels.address}
                      disabled={same_address}
                    />
                  )}
                  rules={{
                    required: !same_address ? requiredValidation(labels.address) : undefined,
                  }}
                />
              </Column>
            </Row>
          </Container> */}
        </Grid>
      </WizardLayout>
    </View>
  );
};
