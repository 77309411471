import { Route } from '@react-navigation/native';
import { Image } from 'expo-image';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';

import { EducationArticleModel } from '~/api/models/educationCentre/models/EducationArticleModel';
import { apiGetEducationArticle } from '~/api/services/educationCentre';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { HTMLViewerComponent } from '~/components/commonComponents';
import { H3TtmSemiBoldBlack, H6NsRegularDisableColor } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { BackNavigation } from '~/components/navigation/BackNavigation';
import { colors } from '~/utils/colors';
import { DAY_MONTH_YEAR_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { articleId: string }>;
}

export const DigimedEducationArticle: React.FC<Props> = ({ route }) => {
  const [article, setArticle] = useState<EducationArticleModel>();
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    apiGetEducationArticle({ id: Number(route.params.articleId) }).then((response) => {
      setLoading(true);
      setArticle(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <View style={{ display: 'flex', flex: 1 }}>
      {loading ? (
        <LoadingActivityIndicator />
      ) : (
        <View style={{ backgroundColor: colors.white, flex: 1, display: 'flex' }}>
          <View style={isMobile ? { paddingHorizontal: MOBILE_HORIZONTAL_SPACE, paddingTop: 15 } : null}>
            <BackNavigation title={labels.digimedEducationCentre} backLink={{ screen: screenName.EducationCentre }} />
          </View>
          <ScrollView>
            <View style={styles.blogContainer}>
              <View style={styles.header}>
                <H3TtmSemiBoldBlack style={{ marginBottom: 10 }}>{article?.title}</H3TtmSemiBoldBlack>
                <H6NsRegularDisableColor>
                  {parseDateTime(article?.published_at, { outputFormat: DAY_MONTH_YEAR_FORMAT })}
                </H6NsRegularDisableColor>
              </View>

              {article?.cover ? (
                <Image source={{ uri: article?.cover?.url }} style={styles.imageStyle} contentFit="cover" />
              ) : null}

              <HTMLViewerComponent content={article?.content} baseFont={12} />
            </View>
          </ScrollView>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  blogContainer: { width: '100%', maxWidth: 600, paddingHorizontal: MOBILE_HORIZONTAL_SPACE, alignSelf: 'center' },
  header: { backgroundColor: colors.lightPurple, paddingTop: 20, paddingBottom: 35, paddingHorizontal: 22 },
  imageStyle: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    width: '100%',
    aspectRatio: 16 / 9,
  },
});
