import React from 'react';
import { View, StyleSheet } from 'react-native';
import { LinearGradient, Rect, Stop, Svg } from 'react-native-svg';

export const Gradient = () => {
  return (
    <View style={styles.gradientContainer}>
      <View style={styles.gradientContent}>
        <Svg height="100%" width="100%" style={styles.gradient}>
          <LinearGradient id="grad" x1="0%" y1="100%" x2="0%" y2="0%">
            <Stop offset="0%" stopColor="white" stopOpacity="1" />
            <Stop offset="100%" stopColor="white" stopOpacity="0" />
          </LinearGradient>
          <Rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
        </Svg>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  gradientContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 20,
    zIndex: 1,
  },
  gradientContent: {
    flex: 1,
  },
  gradient: {
    flex: 1,
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
});
