import { get } from '../../axios';
import { IdModel } from '../../models/common/models/IdModel';

import { EducationArticlesIndexRequest } from '~/api/models/educationCentre/requests/EducationArticlesIndexRequest';
import { GetEducationArticleResponse } from '~/api/models/educationCentre/responses/GetEducationArticleResponse';
import { GetEducationArticlesIndexResponse } from '~/api/models/educationCentre/responses/GetEducationArticlesIndexResponse';

export const apiGetEducationArticlesIndex = (data: EducationArticlesIndexRequest) => {
  return get<GetEducationArticlesIndexResponse>('/blogs', data);
};

export const apiGetEducationArticle = ({ id }: IdModel) => {
  return get<GetEducationArticleResponse>(`/blogs/${id}`);
};
