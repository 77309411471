import { PaymentMethodsResponse } from '../../models/stripe/responses/PaymentMethodsResponse';
import { StripeSetupIntentResponse } from '../../models/stripe/responses/StripeSetupIntentResponse';

import { deleteReq, get, post } from '~/api/axios';
import { StripeAccountLinkResponse } from '~/api/models/stripe/responses/StripeAccountLinkResponse';
import { StripeAccountResponse } from '~/api/models/stripe/responses/StripeAccountResponse';
import { StipeCheckOnboardingResponse } from '~/api/models/stripe/responses/StripeCheckOnboardingResponse';
import { StripeConnectLinkResponse } from '~/api/models/stripe/responses/StripeConnectLinkResponse';

export const apiGetPaymentMethods = () => {
  return get<PaymentMethodsResponse>(`/stripe/payment-methods`);
};

export const apiSetupIntent = () => {
  return post<StripeSetupIntentResponse>(`/stripe/setup-intent`);
};

export const apiRemoveCard = (id: string) => {
  return deleteReq<StripeSetupIntentResponse>(`/stripe/payment-methods/${id}`);
};

export const apiGetStripeAccount = () => {
  return get<StripeAccountResponse>('/stripe/account');
};

export const apiConnectStripeAccount = () => {
  return post('/stripe/account');
};

export const apiLinkStripeAccount = () => {
  return post<StripeAccountLinkResponse>('/stripe/account/account-link');
};

export const apiStripeConnectLink = () => {
  return post<StripeConnectLinkResponse>('/stripe/account/login-link');
};

export const apiStripeCheckOnboarding = () => {
  return post<StipeCheckOnboardingResponse>('/stripe/account/check-onboarding');
};
