import { Link } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { View, StyleSheet, Text } from 'react-native';

import { mb5 } from '~/common/commonStyles';
import { nsRegular, nsSemiBold } from '~/components/commonText';
import { MenuItem } from '~/constants/menus/menuCommon';
import { useStoredRoute } from '~/redux/hooks/StoredRoute';
import { colors } from '~/utils/colors';

interface Props {
  item: MenuItem;
  expanded: boolean;
  withDot?: boolean;
}

export const WebMenuItem: React.FC<Props> = ({ item, expanded, withDot }) => {
  const { route } = useStoredRoute();
  const active = route && route.name.indexOf(item.screenName) === 0;

  const children = useMemo(() => item.children?.filter((child) => !child.hidden), [item]);
  const hasChildren = useMemo(() => !!children?.length, [children]);

  const childItem = (child: MenuItem, index: number) => {
    const childActive = route && route.name.indexOf(child.screenName) === 0;
    return (
      <View key={child.key ?? index} style={mb5}>
        <Link to={{ screen: child.screenName, params: {} } as never}>
          <Text style={[styles.linkText, styles.linkTextChild, childActive ? styles.linkActive : null]}>
            {child.label}
          </Text>
        </Link>
      </View>
    );
  };
  return (
    <>
      {item.screenName && !item.disabled ? (
        <Link
          to={{ screen: item.screenName, params: {} } as never}
          style={[styles.link, expanded ? null : styles.linkCollapsed]}>
          <View style={styles.icon}>
            <View>{item.image(active ? colors.info : colors.purple)}</View>
            {withDot ? <View style={styles.redDot} /> : null}
          </View>
          {expanded ? <Text style={[styles.linkText, active ? styles.linkActive : null]}>{item.label}</Text> : null}
        </Link>
      ) : (
        <Text style={[styles.link, expanded ? null : styles.linkCollapsed]}>
          <View style={styles.icon}>
            <View>{item.image(active ? colors.info : item.disabled ? colors.placeholderGrey : colors.purple)}</View>
            {withDot ? <View style={styles.redDot} /> : null}
          </View>
          {expanded ? (
            <Text
              style={[styles.linkText, active ? styles.linkActive : item.disabled ? styles.linkDisabled : undefined]}>
              {item.label}
            </Text>
          ) : null}
        </Text>
      )}
      {hasChildren ? <View style={styles.childrenContainer}>{children.map(childItem)}</View> : null}
    </>
  );
};

const styles = StyleSheet.create({
  link: {
    color: colors.purple,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 18,
  },
  linkCollapsed: {
    justifyContent: 'center',
  },
  linkActive: {
    color: colors.info,
    ...nsSemiBold,
  },
  linkDisabled: {
    color: colors.placeholderGrey,
  },
  linkText: {
    marginLeft: 16,
    fontSize: 12,
    ...nsRegular,
  },
  linkTextChild: {
    color: colors.purple,
    fontSize: 10,
  },
  icon: {
    position: 'relative',
  },
  redDot: {
    position: 'absolute',
    backgroundColor: colors.danger,
    width: 10,
    height: 10,
    borderRadius: 10,
    right: 0,
    top: -2,
  },
  childrenContainer: {
    marginLeft: 38,
  },
});
