import Feather from '@expo/vector-icons/Feather';
import { setStringAsync } from 'expo-clipboard';
import React from 'react';
import { Controller } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';
import QRCode from 'react-native-qrcode-svg';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { requiredValidation, passcodeLengthValidation } from '../../services/validationConfig';
import { IconButton } from '../buttons/IconButton';
import { Button, TextButton } from '../commonButton';
import {
  H3TtmRegularBlack,
  H3TtmSemiBoldBlack,
  H5TtmRegularBlack,
  H5TtmSemiBoldBlack,
  H6NsRegularBlack,
} from '../commonText';
import FloatingInput from '../inputs/FloatingInput';

import { useTotpEnrolmentConfirm } from '~/api/hooks/TotpEnrolmentConfirmHook';
import { TotpEnrolmentModel } from '~/api/models/registration/models/TotpEnrolmentModel';
import { mb10, ml10, mv15, mv20 } from '~/common/commonStyles';
import { separator } from '~/styles/commonStyling';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  totpEnrolment: TotpEnrolmentModel;
  onSuccess: () => void;
}

export const AuthenticatorEnrolmentModal: React.FC<Props> = ({ onHide, onSuccess, totpEnrolment }) => {
  const { control, totpConfirmEnrolment } = useTotpEnrolmentConfirm({
    onEnrolmentConfirmed: () => {
      if (onSuccess) onSuccess();
      onHide();
    },
  });

  const copyCode = () => {
    setStringAsync(setupKey);
  };

  if (!totpEnrolment) return null;

  const qrCodeText = `${totpEnrolment.data?.qr_code_text}`;
  const setupKey = `${totpEnrolment.data?.setup_key}`;

  const buttons = [
    <Button label={labels.verify} funCallback={totpConfirmEnrolment} />,
    <TextButton label={labels.cancel} funCallback={onHide} />,
  ];
  return (
    <ModalContainer hideOnBackground onHide={onHide} buttons={buttons}>
      <View>
        <H3TtmSemiBoldBlack style={[mb10, { alignSelf: 'center' }]}>
          {labels.googleAuthenticatorEnrolmentTitle}
        </H3TtmSemiBoldBlack>
        <H3TtmRegularBlack>{labels.setupInstructions}</H3TtmRegularBlack>

        {labels.googleAuthenticatorInstructions.map((message, index) => (
          <H6NsRegularBlack key={index} style={styles.instructionsStyling}>
            {message}
          </H6NsRegularBlack>
        ))}

        <View style={[separator, mv15]} />

        <View style={{ alignItems: 'center' }}>
          <H5TtmRegularBlack style={{ fontStyle: 'italic' }}>{labels.scanImage}</H5TtmRegularBlack>
          <View style={mv20}>
            <QRCode value={qrCodeText} size={120} />
          </View>

          <H5TtmRegularBlack>Or enter the following code:</H5TtmRegularBlack>
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <H5TtmSemiBoldBlack>{setupKey}</H5TtmSemiBoldBlack>
            <IconButton title="Copy" onPress={copyCode} style={ml10}>
              <Feather name="copy" size={16} />
            </IconButton>
          </View>
        </View>
        <Controller
          name="totp"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <View>
              <FloatingInput
                value={value}
                onChangeValue={onChange}
                onBlur={onBlur}
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
                label="Verification code"
              />
            </View>
          )}
          rules={{
            minLength: passcodeLengthValidation(6),
            required: requiredValidation('Verification code'),
          }}
        />
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  instructionsStyling: {
    fontSize: 13,
    marginBottom: 5,
    paddingLeft: 15,
  },
  borderRight: {
    borderRightColor: colors.lightPurple2,
    borderRightWidth: 1,
  },
});
