import * as React from 'react';
import { Fragment, useMemo } from 'react';
import { View, StyleSheet, ScrollView, KeyboardAvoidingView, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { alignItemsCenter, flex1, mb10, mb5, mt10, mt35 } from '../common/commonStyles';
import { ProgressBars } from '../components/commonComponents';
import { H1TtmSemiBoldBlack, H2TtmSemiBoldBlack, H6NsRegularSecondaryBlack } from '../components/commonText';
import { colors } from '../utils/colors';

import { useRegistrationProgress } from '~/api/hooks/RegistrationSteps';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { AppLogoWithText } from '~/components/svgImages';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useKeyboard } from '~/utils/hooks/KeyboardHook';

interface Props {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  children?: React.ReactNode;
  compressed?: boolean;
  flexContent?: boolean;

  hideRequired?: boolean;
  hideProgress?: boolean;
  showAppLogo?: boolean;
  buttons?: React.ReactNode[];

  fixedHeader?: boolean;
  fixedContent?: boolean;
  keyboardButtonIndex?: number;
}
export const EnhancedRegistrationLayout: React.FC<Props> = (props) => {
  const { isMobile } = useBreakpoints();
  const { progress } = useRegistrationProgress();
  const { bottom } = useSafeAreaInsets();
  const { showing: showingKeyboard } = useKeyboard();

  const subtitle =
    typeof props.subtitle === 'string' ? (
      <H6NsRegularSecondaryBlack>{props.subtitle}</H6NsRegularSecondaryBlack>
    ) : (
      props.subtitle
    );

  const hideProgress = props.hideProgress || !isMobile;
  const compressed = props.compressed && hideProgress;

  const titleIsString = useMemo(() => typeof props.title === 'string', [props.title]);

  const headers = (
    <View
      style={
        hideProgress
          ? {
              paddingTop: 20,
            }
          : null
      }>
      {hideProgress ? null : <ProgressBars progress={progress} />}

      {props.showAppLogo ? (
        <View style={[mb5, { alignItems: 'center' }]}>
          <AppLogoWithText height={80} width={200} />
        </View>
      ) : null}

      <View style={styles.titleContainer}>
        {titleIsString ? (
          <H1TtmSemiBoldBlack style={{ lineHeight: 32 }}>
            {props.title}
            {props.hideRequired ? null : <H2TtmSemiBoldBlack style={{ color: colors.danger }}>*</H2TtmSemiBoldBlack>}
          </H1TtmSemiBoldBlack>
        ) : (
          <>{props.title}</>
        )}
      </View>

      {!subtitle ? null : <View style={[styles.subtitleContainer]}>{subtitle}</View>}
    </View>
  );

  const buttons = useMemo(() => {
    const filteredButtons = props.buttons?.filter((btn) => !!btn);
    if (!filteredButtons?.length) return null;
    if (showingKeyboard && !compressed) return filteredButtons[props.keyboardButtonIndex || 0];
    return (
      <>
        {filteredButtons.map((button, index, arr) => (
          <Fragment key={index}>
            {button}
            {index < arr.length - 1 ? <View style={{ height: 10, width: 20 }} /> : null}
          </Fragment>
        ))}
      </>
    );
  }, [props.buttons, showingKeyboard]);

  const content = <View style={[alignItemsCenter, compressed ? { minHeight: 50 } : flex1]}>{props.children}</View>;

  return (
    <View style={styles.container}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 90 : compressed ? 0 : 75}
        style={[
          styles.subContainer,
          {
            justifyContent: compressed ? 'center' : 'space-between',
            marginBottom: bottom || 10,
          },
        ]}>
        {props.fixedContent || props.fixedHeader ? <View style={styles.mobileHorizontalPadding}>{headers}</View> : null}
        <View style={[flex1]}>
          {!props.fixedContent ? (
            <ScrollView
              keyboardShouldPersistTaps="handled"
              contentContainerStyle={[
                { width: '100%' },
                styles.mobileHorizontalPadding,
                compressed ? { flex: 1, justifyContent: 'center' } : { paddingBottom: 10 },
                props.flexContent && !showingKeyboard ? { flex: 1 } : null,
              ]}>
              <>
                {!props.fixedHeader ? headers : null}
                {content}
                {compressed && props.buttons ? <View style={[mt35]} children={buttons} /> : null}
              </>
            </ScrollView>
          ) : (
            content
          )}
        </View>
        {!compressed && props.buttons ? (
          <View style={[styles.mobileHorizontalPadding, mt10]} children={buttons} />
        ) : null}
      </KeyboardAvoidingView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  subContainer: {
    height: '100%',
    flex: 1,
    maxHeight: 1200,
    backgroundColor: colors.white,
  },
  titleContainer: {
    marginBottom: 10,
  },
  subtitleContainer: {
    minHeight: 40,
    marginBottom: 10,
  },
  mobileHorizontalPadding: {
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
  },
});
