import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { apiGetAccountIndex } from '~/api/services/accounts';
import { DEFAULT_TABLE_ITEMS_PER_PAGE } from '~/components/common/DataTable/DataTable';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { RootState } from '~/redux/reducers';
import { allAccountsSelector, SET_ACCOUNTS_PAGE } from '~/redux/reducers/accountsReducer';
import { useAppDispatch } from '~/redux/store';
import { usePageFocus } from '~/utils/hooks/FocusHook';

interface Props {
  limit?: number;
  query?: string;
}
export function usePatientsList(props?: Props) {
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<TablePaginationInfo>({
    page: 0,
    limit: props?.limit || DEFAULT_TABLE_ITEMS_PER_PAGE,
  });
  const dispatch = useAppDispatch();

  const patients = useSelector(allAccountsSelector);
  const paginatedPatients = useSelector((state: RootState) => state.accountsReducer.pagination);

  useEffect(() => {
    if (pagination.page !== 0) getListOfMyPatients(pagination);
  }, [pagination.limit, pagination.page]);

  useEffect(() => {
    if (pagination.page !== 1) {
      setPagination({
        page: 1,
        limit: pagination.limit,
      });
    } else {
      getListOfMyPatients(pagination);
    }
  }, [props?.query]);

  const getListOfMyPatients = (pagination: TablePaginationInfo) => {
    setLoading(true);

    setPagination(pagination);
    apiGetAccountIndex({
      page: pagination.page,
      limit: pagination.limit,
      filter: {
        type: 'patient',
        full_name: props?.query || undefined,
      },
    })
      .then((response) => {
        dispatch(SET_ACCOUNTS_PAGE(response.data));
      })
      .catch(ErrorAlert)
      .finally(() => setLoading(false));
  };

  usePageFocus(() => {
    const currentPagination = { page: 1, limit: pagination.limit };
    setPagination(currentPagination);
    getListOfMyPatients(currentPagination);
  });

  return { setPagination, patients, pagination: paginatedPatients, loading };
}

export function usePatientsListOnDemand() {
  const dispatch = useAppDispatch();
  const patients = useSelector(allAccountsSelector);

  const getListOfMyPatients = (pagination: TablePaginationInfo, fullNameFilter?: string) => {
    return apiGetAccountIndex({
      page: pagination.page,
      limit: pagination.limit,
      filter: {
        type: 'patient',
        full_name: fullNameFilter || undefined,
      },
    })
      .then((response) => {
        dispatch(SET_ACCOUNTS_PAGE(response.data));
      })
      .catch(ErrorAlert);
  };

  return { getListOfMyPatients, patients };
}
