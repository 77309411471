import React from 'react';
import { StyleSheet, View } from 'react-native';

import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '../commonText';
import { InProgress } from '../svgImages';

import { AuthVerificationEnum } from '~/api/models/authorisation/constants/AuthVerificationEnum';
import { mb10, textLeft } from '~/common/commonStyles';
import { whenAppType } from '~/utils/buildConfig';
import verificationPendingLabels from '~/utils/labels/verificationPendingLabels';

interface Props {
  status: AuthVerificationEnum.PENDING_ADMIN | AuthVerificationEnum.PENDING_KYI;
}
export const VerificationStatusPendingComponent: React.FC<Props> = () => {
  return (
    <View style={styles.center}>
      <View style={styles.center}>
        <InProgress width={65} height={65} />
      </View>
      <View style={styles.center}>
        <H3TtmSemiBoldBlack>
          {whenAppType({
            whenDoctor: verificationPendingLabels.awaitingApproval,
            whenPharmacist: verificationPendingLabels.awaitingApproval,
            whenPatient: verificationPendingLabels.awaitingVerification,
          })}
        </H3TtmSemiBoldBlack>
      </View>
      <View style={[styles.center, styles.textContainer]}>
        {whenAppType({
          whenDoctor: verificationPendingLabels.awaitingApprovalMessages,
          whenPharmacist: verificationPendingLabels.awaitingApprovalMessages,
          whenPatient: verificationPendingLabels.awaitingVerificationMessages,
        }).map((message, index) => (
          <View style={mb10} key={index}>
            <H6NsRegularBlack style={textLeft}>{message}</H6NsRegularBlack>
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  center: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  textContainer: {
    maxWidth: 380,
  },
});
