import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../redux/reducers';
import { SET_COUNTRY_LIST, UPDATE_COUNTRY_LIST } from '../../../redux/reducers/referenceDataReducer';
import { useAppDispatch } from '../../../redux/store';
import { apiCountryIndex } from '../../services/referenceData';

import { CountryIndexResponse } from '~/api/models/referenceData/responses/CountryIndexResponse';
import { getUnencryptedParsedAsyncItem, storageKeys } from '~/common/asyncStorage';

export enum CountryListEnum {
  PHONE_CODE,
  COUNTRY_NAME,
  PRE_FETCH,
}
export function useCountryListing(type = CountryListEnum.PHONE_CODE) {
  const countryData = useSelector((state: RootState) => state.referenceDataReducer.countryList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!countryData?.length) {
      getUnencryptedParsedAsyncItem<CountryIndexResponse>(storageKeys.countries).then(
        (res) => res?.length && dispatch(SET_COUNTRY_LIST(res))
      );

      apiCountryIndex()
        .then((res) => {
          dispatch(UPDATE_COUNTRY_LIST(res.data));
        })
        .catch(() => {});
    }
  }, [countryData]);

  switch (type) {
    case CountryListEnum.COUNTRY_NAME:
      return countryData.map((country) => ({
        id: country.id,
        name: country.name,
      }));
    case CountryListEnum.PRE_FETCH:
      return {};
    case CountryListEnum.PHONE_CODE:
    default: {
      const countriesObj = countryData.reduce((prev, country) => {
        prev[country.phone_code] = `+${country.phone_code}`;
        return prev;
      }, {});
      return Object.keys(countriesObj).map((key) => ({ id: key, name: countriesObj[key] }));
    }
  }
}
