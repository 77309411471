import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import { VerificationStatusPendingComponent } from './VerificationStatusPendingComponent';
import { VerificationStatusRejectedComponent } from './VerificationStatusRejectedComponent';

import { AuthVerificationEnum } from '~/api/models/authorisation/constants/AuthVerificationEnum';
import { RootState } from '~/redux/reducers';
import { colors } from '~/utils/colors';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { isPharmacyVersion } from '~/utils/buildConfig';
import { mapPharmacyStateToAuthVerification } from '~/api/models/pharmacy/constants/PharmacyStateEnum';

export const VerificationStatusComponent: React.FC = () => {
  const status = useSelector((state: RootState) => state.accountsReducer.accountStatus);
  const currentPharmacy = useSelector(currentPharmacySelector);

  const localStatus = useMemo(() => {
    if (!isPharmacyVersion()) {
      return status;
    } else if (currentPharmacy && status === AuthVerificationEnum.APPROVED) {
      return mapPharmacyStateToAuthVerification(currentPharmacy?.pharmacy?.state);
    }
    return undefined;
  }, [status, currentPharmacy]);

  return (
    <View style={styles.container}>
      {localStatus === AuthVerificationEnum.PENDING_ADMIN || localStatus === AuthVerificationEnum.PENDING_KYI ? (
        <VerificationStatusPendingComponent status={localStatus} />
      ) : localStatus === AuthVerificationEnum.REJECTED || localStatus === AuthVerificationEnum.DISABLED ? (
        <VerificationStatusRejectedComponent status={localStatus} />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    borderColor: colors.lightPurple2,
    borderWidth: 1,
    padding: 28,
  },
});
