import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import {
  requiredValidation,
  validationSchema,
  alphabetCharactersValidation,
  maxLengthValidation,
  alphaNumericValidationToAvoidScripting,
  emailValidation,
} from '../../../services/validationConfig';

import { ContactUsModel } from '~/api/models/help/models/ContactUsModel';
import { apiContactUs } from '~/api/services/help';
import { SuccessAlert } from '~/common/commonMethods';
import { pt15 } from '~/common/commonStyles';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { H3TtmSemiBoldBlack, H5TtmRegularSecondaryBlack } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { Row, Column, Container, Grid } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

interface Props {
  whiteInputs?: boolean;
}
export const ContactUsComponent: React.FC<Props> = ({ whiteInputs }) => {
  const [waiting, setWaiting] = useState(false);

  const { handleSubmit, control, reset } = useForm<ContactUsModel>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      first_name: '',
      last_name: '',
      subject: '',
      message: '',
      email: '',
    },
  });

  const resetFields = () => {
    reset();
    setWaiting(false);
  };

  const submit = (details: ContactUsModel) => {
    setWaiting(true);
    apiContactUs(details)
      .then(() => {
        SuccessAlert(
          ['Your form has been submitted successfully.', 'You should hear from us soon!'],
          'Contact Form Submitted!',
          labels.ok,
          resetFields
        );
      })
      .catch((error) => {
        setWaiting(false);
        ErrorAlert(error);
      });
  };

  return (
    <View style={pt15}>
      <H3TtmSemiBoldBlack>{labels.cantFindIssue}</H3TtmSemiBoldBlack>
      <H5TtmRegularSecondaryBlack>{labels.contactUsFormSubtitle}</H5TtmRegularSecondaryBlack>
      <Grid grid={{ gutters: 4 }}>
        <Container alignSelf="flex-start">
          <Row justifyContent="center">
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="first_name"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    maxLength={validationSchema.name.maxLength}
                    label={labels.legalFirstName}
                    showMandatory
                    value={value}
                    onChangeValue={onChange}
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    style={whiteInputs ? { backgroundColor: colors.white } : null}
                  />
                )}
                rules={{
                  validate: { alphabetCharactersValidation },
                  required: requiredValidation(labels.legalFirstName),
                }}
              />
            </Column>

            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="last_name"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    maxLength={validationSchema.name.maxLength}
                    label={labels.legalLastName}
                    showMandatory
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    style={whiteInputs ? { backgroundColor: colors.white } : null}
                  />
                )}
                rules={{
                  validate: { alphabetCharactersValidation },
                  required: requiredValidation(labels.legalLastName),
                }}
              />
            </Column>
          </Row>
          <Row justifyContent="center">
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    maxLength={validationSchema.email.maxLength}
                    label={labels.emailAddress}
                    showMandatory
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    style={whiteInputs ? { backgroundColor: colors.white } : null}
                  />
                )}
                rules={{
                  maxLength: maxLengthValidation(validationSchema.email.maxLength),
                  required: requiredValidation(labels.emailAddress),
                  validate: { emailValidation },
                }}
              />
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                name="subject"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    maxLength={validationSchema.string.maxLength}
                    label={labels.subject}
                    showMandatory
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    style={whiteInputs ? { backgroundColor: colors.white } : null}
                  />
                )}
                rules={{
                  validate: { alphabetCharactersValidation },
                  required: requiredValidation(labels.subject),
                }}
              />
            </Column>
          </Row>
          <Row justifyContent="center">
            <Column span={{ xs: 12, md: 'flex' }}>
              <Controller
                name="message"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                  <FloatingInput
                    maxLength={validationSchema.string.maxLength}
                    label={labels.message}
                    showMandatory
                    value={value}
                    onChangeValue={onChange}
                    onBlur={onBlur}
                    error={isSubmitted && !!error}
                    errorMessage={isSubmitted && error?.message}
                    multiline
                    numberOfLines={6}
                    style={whiteInputs ? { backgroundColor: colors.white } : null}
                  />
                )}
                rules={{
                  validate: { alphaNumericValidationToAvoidScripting },
                  required: requiredValidation(labels.message),
                }}
              />
            </Column>
          </Row>

          <CancelContinueFooter
            hideCancel
            confirmTitle={labels.submit}
            onConfirm={handleSubmit(submit)}
            waiting={waiting}
            style={styles.submitButton}
          />
        </Container>
      </Grid>
    </View>
  );
};

const styles = StyleSheet.create({
  submitButton: {
    marginTop: 25,
  },
});
