import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { useDietaryRestrictionsListing } from '~/api/hooks/DietaryRestrictionsListing';
import { mb10 } from '~/common/commonStyles';
import { Accordion } from '~/components/buttons/Accordions/Accordion';
import { CheckboxGroup } from '~/components/inputs/CheckboxGroup';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { useHealthProfileDietaryRestrictionsContext } from '~/providers/healthProfile/HealthProfileDietaryRestrictionsProvider';

interface Props {
  locked?: boolean;
}

export const PatientLifestyleDietaryRestrictions: React.FC<Props> = ({ locked }) => {
  const { dietaryData } = useDietaryRestrictionsListing();
  const { getData, loading, hasData } = useHealthProfileDietaryRestrictionsContext();

  const sortedDietaryList = useMemo(() => {
    return dietaryData
      .map((item) => ({ label: item.name, value: item.id }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [dietaryData]);

  const { controlDietaryRestrictions } = useHealthProfileDataContext();

  const expandedChanged = (expanded: boolean) => {
    if (expanded && (!hasData || locked)) {
      getData().catch(() => {});
    }
  };

  return (
    <Accordion
      groupValue="dietary_restrictions"
      title="Dietary restrictions"
      style={mb10}
      onExpandedChanged={expandedChanged}>
      <View>
        {loading ? (
          <LoadingActivityIndicator />
        ) : (
          <Controller
            name="dietaryRestrictions"
            control={controlDietaryRestrictions}
            render={({ field: { onChange, value } }) => (
              <CheckboxGroup
                values={value}
                items={sortedDietaryList}
                onChange={(selected: (string | number)[]) => {
                  onChange(selected.map((item) => +item));
                }}
                disabled={locked}
              />
            )}
          />
        )}
      </View>
    </Accordion>
  );
};
