import { useState } from 'react';

export const useCallSettingsHook = () => {
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [cameraPosition, setCameraPosition] = useState<'front' | 'back'>('front');

  const disconnect = () => {
    setVideoEnabled(true);
    setAudioEnabled(true);
  };

  const toggleCameraPosition = (front: boolean) => {
    setCameraPosition(front ? 'front' : 'back');
  };

  return {
    disconnect,
    audioEnabled,
    videoEnabled,
    cameraPosition,
    toggleCameraPosition,
    setAudioEnabled,
    setVideoEnabled,
  };
};
