import { DocumentPickerAsset } from 'expo-document-picker';
import { Image } from 'expo-image';
import React, { useEffect, useMemo, useState } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';

import { colors } from '../../utils/colors';
import { labels } from '../../utils/labels';
import { H5TtmSemiBoldTheme, SmallNsRegularBlack } from '../commonText';
import { ErrorAlert } from '../modals/ErrorAlert';
import { ProfileImageUpload } from '../modals/ProfileImageUpload';
import { ModalName } from '../modals/constants/ModalNames';
import { AddImage } from '../svgImages';

import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { apiUpdateProfileImage } from '~/api/services/accounts';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isWeb } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  account: AccountModel;
  title?: string;
  isDependant?: boolean;
  onUpdate?: () => Promise<any>;
}

export const EditImageComponent: React.FC<Props> = ({ account, isDependant, title, onUpdate }) => {
  const { registerModal, openModal, closeModalByName } = useModalManager();

  const { isMobile } = useBreakpoints();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    registerModal(ModalName.ProfileImage, ProfileImageUpload);
  }, []);

  const imageSrc = useMemo(() => {
    return (isWeb() && account?.profile_image?.conversions?.avatar) || account?.profile_image?.url;
  }, [account]);

  const submit = async (document: DocumentPickerAsset) => {
    try {
      if (document) {
        setLoading(true);
        await apiUpdateProfileImage({ id: account.id, document });
        if (onUpdate) await onUpdate();
      }
    } catch (e) {
      ErrorAlert(e);
    }
    closeModalByName(ModalName.ProfileImage);
    setLoading(false);
  };

  const startEdit = () => {
    openModal(ModalName.ProfileImage, {
      savePhoto: submit,
      waiting: loading,
    });
  };

  if (!account) return null;
  return (
    <View style={[styles.component, isMobile ? styles.componentMobile : null]}>
      <TouchableOpacity
        onPress={startEdit}
        style={[styles.imageContainer, isMobile ? styles.imageContainerMobile : null]}>
        {imageSrc ? (
          <Image
            source={{ uri: imageSrc }}
            style={{ width: '100%', height: '100%', borderRadius: 6 }}
            contentFit="cover"
          />
        ) : (
          <AddImage height={30} width={30} />
        )}
      </TouchableOpacity>
      <View style={styles.buttonsContainer}>
        {!isMobile ? (
          <SmallNsRegularBlack>
            {title || `Change or edit ${!isDependant ? 'your' : `${account.first_name}'s`} profile picture`}
          </SmallNsRegularBlack>
        ) : null}
        <TouchableOpacity onPress={startEdit}>
          <H5TtmSemiBoldTheme>{labels.edit}</H5TtmSemiBoldTheme>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },
  componentMobile: {
    flexDirection: 'column',
  },
  imageContainer: {
    display: 'flex',
    height: 68,
    width: 68,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.lightPurple3,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    marginBottom: 5,
  },
  imageContainerMobile: {
    height: 146,
    width: 146,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 180,
    marginBottom: 10,
  },
});
