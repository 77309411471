import React, { useMemo } from 'react';
import { View } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { Button } from '../commonButton';
import {
  H2TtmSemiBoldBlack,
  H6NsRegularSecondaryBlack,
  SmallNsRegularDisableColor,
  SmallNsRegularSecondaryBlack,
  SmallNsSemiBoldSecondaryBlack,
} from '../commonText';
import { Error } from '../svgImages';

import { UploadLogTypeEnum } from '~/api/models/patientData/constants/UploadLogTypeEnum';
import { UploadDataLogModel } from '~/api/models/patientData/models/UploadDataLogModel';
import { mb10, mb20 } from '~/common/commonStyles';
import { patientManagementLabels } from '~/utils/labels/patientManagement';

interface Props extends BaseModalProps {
  fileName?: string;
  details: UploadDataLogModel[];
}

export const DocumentImportDetailsModal: React.FC<Props> = ({ details, fileName, onHide }) => {
  const getDetailNode = (detail: UploadDataLogModel, index) => (
    <View key={index} style={mb10}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={{ display: 'flex', alignItems: 'flex-end', minWidth: 30 }}>
          <SmallNsRegularDisableColor>{index + 1}: </SmallNsRegularDisableColor>
        </View>
        {extractLocation(detail, index)}
      </View>
      <View style={{ marginLeft: 30 }}>
        <SmallNsRegularSecondaryBlack>{detail.message}</SmallNsRegularSecondaryBlack>
      </View>
    </View>
  );

  const detailNodes = useMemo(() => {
    const warnings = [];
    const errors = details.reduce((prev, detail) => {
      if (detail.type === UploadLogTypeEnum.WARNING) {
        warnings.push(getDetailNode(detail, warnings.length));
      } else {
        prev.push(getDetailNode(detail, prev.length));
      }
      return prev;
    }, []);

    return { warnings, errors };
  }, [details]);

  return (
    <ModalContainer hideOnBackground onHide={onHide} buttons={[<Button funCallback={onHide} label="Close" />]}>
      <View>
        <View style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', marginBottom: 20 }}>
          <View style={mb10}>
            <Error height={55} width={55} />
          </View>
          <H2TtmSemiBoldBlack>{patientManagementLabels.documentImportDetails}</H2TtmSemiBoldBlack>
        </View>

        {fileName ? (
          <View style={mb20}>
            <H6NsRegularSecondaryBlack>
              {patientManagementLabels.documentImportSubtitle} {fileName}
            </H6NsRegularSecondaryBlack>
          </View>
        ) : null}
        <View>{detailNodes.errors.map((msg) => msg)}</View>
      </View>
    </ModalContainer>
  );
};

const extractLocation = (detail: UploadDataLogModel, index) => {
  const row = detail.row && Number(detail.row);
  const column = detail.column;
  const sheet = detail.sheet_name;

  if (!row && !column && !sheet) return null;

  if (!row && !column)
    return (
      <SmallNsRegularDisableColor>
        Sheet <SmallNsSemiBoldSecondaryBlack>{sheet}</SmallNsSemiBoldSecondaryBlack>{' '}
      </SmallNsRegularDisableColor>
    );

  if (!row)
    return (
      <SmallNsRegularDisableColor>
        Column <SmallNsSemiBoldSecondaryBlack>{column}</SmallNsSemiBoldSecondaryBlack>, Sheet{' '}
        <SmallNsSemiBoldSecondaryBlack>{sheet}</SmallNsSemiBoldSecondaryBlack>
      </SmallNsRegularDisableColor>
    );
  if (!column)
    return (
      <SmallNsRegularDisableColor>
        Row <SmallNsSemiBoldSecondaryBlack>{row}</SmallNsSemiBoldSecondaryBlack>, Sheet{' '}
        <SmallNsSemiBoldSecondaryBlack>{sheet}</SmallNsSemiBoldSecondaryBlack>
      </SmallNsRegularDisableColor>
    );
  return (
    <SmallNsRegularDisableColor>
      Row <SmallNsSemiBoldSecondaryBlack>{row}</SmallNsSemiBoldSecondaryBlack>, Column{' '}
      <SmallNsSemiBoldSecondaryBlack>{column}</SmallNsSemiBoldSecondaryBlack>, Sheet{' '}
      <SmallNsSemiBoldSecondaryBlack>{sheet}</SmallNsSemiBoldSecondaryBlack>
    </SmallNsRegularDisableColor>
  );
};
