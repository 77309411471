import React, { useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { StackedBarChartData } from 'react-native-chart-kit/dist/StackedBarChart';

import { IncomeRequestForm } from './IncomeListingProvider';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { DoctorDashboardIncomeTotalResponse } from '~/api/models/dashboard/responses/DoctorDashboardIncomeTotalResponse';
import { IncomeResponse } from '~/api/models/income/responses/IncomeResponse';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';

export interface IIncomeListingProvider {
  form: UseFormReturn<IncomeRequestForm, any>;
  loading: boolean;
  consultationType?: ConsultationTypeEnum;
  setPage: (pageInfo: TablePaginationInfo) => void;
  submitQuery: () => Promise<void>;
  graphData: StackedBarChartData;
  incomeListData: IncomeResponse;
  filteredTotalIncome: DoctorDashboardIncomeTotalResponse;
  accumulatedTotalIncome: DoctorDashboardIncomeTotalResponse;
  generateIncomeExcelDownload: () => Promise<void>;
  generateIncomePdfDownload: () => Promise<void>;
}

export const IncomeListingContext = React.createContext<IIncomeListingProvider>({} as IIncomeListingProvider);

export const useIncomeListingContext = (): IIncomeListingProvider => useContext(IncomeListingContext);
