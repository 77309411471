import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { ActionButton } from './ActionButton';

import { useSubscriptions } from '~/api/hooks/subscriptions/SubscriptionHook';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { SubscriptionTypeEnum } from '~/api/models/subscriptions/models/SubscriptionPlanModel';
import { mt10 } from '~/common/commonStyles';
import { Calendar, Home, Stethoscope } from '~/components/svgImages';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { consultationsLabels } from '~/utils/labels/consultations';

export const HeaderActionsMobile: React.FC = (): JSX.Element => {
  const { isMobile } = useBreakpoints();
  const { currentPlan } = useSubscriptions();
  const isFreePlan = useMemo(() => currentPlan?.subscription_type === SubscriptionTypeEnum.FREEPATIENT, [currentPlan]);

  return (
    <>
      <Row>
        <Column span={6} style={[styles.mobileCard]}>
          <ActionButton
            consultationType={ConsultationTypeEnum.SCHEDULED_APPOINTMENT}
            icon={
              isMobile ? (
                <Calendar width={39} height={39} color={colors.info} />
              ) : (
                <Calendar width={27} height={27} color={colors.info} />
              )
            }
            title={consultationsLabels.scheduleForLater}
          />
        </Column>
        <Column span={6} style={[styles.mobileCard]}>
          <ActionButton
            consultationType={ConsultationTypeEnum.ON_DEMAND}
            icon={
              isMobile ? (
                <Stethoscope width={39} height={39} color={colors.info} />
              ) : (
                <Stethoscope width={27} height={27} color={colors.info} />
              )
            }
            title={consultationsLabels.doctorAvailableNow}
          />
        </Column>
      </Row>

      {isFreePlan ? null : (
        <Row style={mt10}>
          <Column span={12} style={[styles.mobileCard]}>
            <ActionButton
              consultationType={ConsultationTypeEnum.HOME_VISIT}
              icon={
                isMobile ? (
                  <Home width={37} height={37} color={colors.info} />
                ) : (
                  <Home width={27} height={27} color={colors.info} />
                )
              }
              title={consultationsLabels.homeVisit}
            />
          </Column>
        </Row>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  mobileCard: {
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
