import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { labels } from '../../../utils/labels';
import { Button, OutlineButton } from '../../commonButton';

interface Props {
  buttonStyle?: StyleProp<ViewStyle>;
  approveTitle?: string;
  acceptDisabled?: boolean;
  onDecline: () => void;
  onAccept: () => void;
}
export const Actions: React.FC<Props> = ({ approveTitle, acceptDisabled, buttonStyle, onDecline, onAccept }) => {
  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <OutlineButton
          style={[{ paddingHorizontal: 8 }, buttonStyle]}
          disabled={false}
          danger
          label={labels.decline}
          funCallback={onDecline}
        />
      </View>
      <View style={styles.buttonContainer}>
        <Button
          style={[{ paddingHorizontal: 8 }, buttonStyle]}
          success
          disabled={acceptDisabled}
          label={approveTitle || labels.approve}
          funCallback={onAccept}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginHorizontal: 8,
  },
});
