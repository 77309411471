import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { apiGetEducationArticlesIndex } from '~/api/services/educationCentre';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTable';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { RootState } from '~/redux/reducers';
import { SET_ARTICLES, SET_ARTICLE_LOADING } from '~/redux/reducers/educationCentre';
import { useAppDispatch } from '~/redux/store';

export function useEducationArticles() {
  const [currentPage, setCurrentPage] = useState(1);
  const educationArticles = useSelector((state: RootState) => state.educationCentreReducer.articles);
  const pagination = useSelector((state: RootState) => state.educationCentreReducer.pagination);
  const loading = useSelector((state: RootState) => state.educationCentreReducer.loading);

  const dispatch = useAppDispatch();

  const pageChanged = (pageInfo: TablePaginationInfo) => {
    setCurrentPage(pageInfo.page);
  };

  const getEducationArticles = (articleTitle?: string) => {
    dispatch(SET_ARTICLE_LOADING(true));
    apiGetEducationArticlesIndex({ page: currentPage, limit: 14, sort: '-created_at', filter: { title: articleTitle } })
      .then((res) => {
        dispatch(SET_ARTICLES(res.data));
      })
      .catch(ErrorAlert)
      .finally(() => {
        SET_ARTICLE_LOADING(false);
      });
  };

  useEffect(() => {
    if (currentPage) getEducationArticles();
  }, [currentPage]);

  return {
    educationArticles,
    pagination,
    pageChanged,
    loading,
    searchEducationArticle: getEducationArticles,
  };
}
