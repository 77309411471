import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useContext } from 'react';

import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { ChatMessageModel, PostChatSendingRequest } from '~/api/models/consultations/models/ChatMessageModel';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';

export interface IAppointmentChatContext {
  loading: boolean;
  recipientTyping: boolean;
  sending: PostChatSendingRequest[];
  messages: ChatMessageModel[];
  allMessages: (ChatMessageModel | PostChatSendingRequest)[];
  pagination: PaginationMetaModel;
  sendMessage: (message?: string) => Promise<void>;
  sendMedia: (media?: DocumentPickerAsset) => Promise<void>;
  setTyping: (typing: boolean) => void;
  getMessages: (pagination: TablePaginationInfo) => Promise<void>;
  retrySend: (localId: number) => Promise<void>;
  isTimeForConsultation: boolean;
}

export const AppointmentChatContext = React.createContext<IAppointmentChatContext>({} as IAppointmentChatContext);
export const useAppointmentChatContext = (): IAppointmentChatContext => useContext(AppointmentChatContext);
