import { useEffect } from 'react';

import { useHandleAssociation } from './handlers/HandleAssociationHook';
import { useHandleDependantNotification } from './handlers/HandleDependantNotificationHook';

import PushNotifications from '~/integrations/pushNotifications/PushNotifications';
import { NotificationCodeEnum } from '~/integrations/pushNotifications/enums/NotificationCodeEnum';
import { DigimedNotificationEventPayload } from '~/integrations/pushNotifications/interfaces/DigimedNotificationEventPayload';

export const useGlobalNotificationHandling = () => {
  const { handleNotification: handelAssociationRequest } = useHandleAssociation();
  const { handleNotification: handleDependantNotification } = useHandleDependantNotification();

  useEffect(() => {
    const removeListener = PushNotifications.addNotificationListener(handleNotification);
    return () => {
      if (removeListener) removeListener();
    };
  }, []);

  const handleNotification = (payload: DigimedNotificationEventPayload) => {
    switch (payload.userInfo.data.code) {
      case NotificationCodeEnum.ASSOCIATION_REQUEST:
        handelAssociationRequest(payload);
        break;
      case NotificationCodeEnum.APPROVED_DEPENDANT:
      case NotificationCodeEnum.REJECTED_DEPENDANT:
        handleDependantNotification(payload);
        break;
    }
  };
};
