import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';

export const appointmentLabels = {
  selectPatientTitle: 'Is this for you or someone else?',
  selectPatientLabel: 'Choose member',
  member: 'Member',
  selectSymptomsTitle: 'Please search and select your symptoms below.',
  selectSymptomsSubtitle:
    'Please be as specific and detailed as possible. This information will be used by your doctor to provide the highest possible level of care.',
  reasonTitle: 'What is the reason for your visit?',
  sickLeaveTitle: 'Do you need a sick leave certificate?',
  listDoctorTitle: 'Choose a practitioner',
  availablePractitionersTitle: 'Available practitioners',
  chooseDateTime: 'Choose date and time',
  chooseLocationMap: 'Choose location on map',
  billingDetailsTitle: 'Billing details',
  billingDetailsSubtitle: 'Fill in the details below.',
  transactionFeeTitle: 'Transaction fee',
  paymentDetailsTitle: 'Payment details',
  chooseTimeRange: 'Choose a time range',
  availableSlots: 'Available slots',
  noReason: 'No reason was specified',
  requestDataAccess:
    'On booking this consultation, the doctor will have access to $patientName health records in order to provide you with the best medical services possible.',
  accessNeeded: 'Access needed',
  acceptAndContinue: 'Accept and Continue',
  noAvailableScheduled: 'There are no slots available on this day',
  noAvailableDoctorsTitle: 'There are no doctors available',
  appointmentBookedTitle: 'Booking confirmed!',
  appointmentBookedHomeVisitMessage: [
    'All set! Your practitioner will come to your address on $date at $time. You can monitor the estimated time of arrival by clicking on the appointment for more details.',
  ],
  appointmentBookedScheduledMessage: [
    'All set! Your practitioner will be available for the $channel on $date at around $time',
  ],
  appointmentBookedOnDemandMessage: [
    'All set! Your practitioner will be with you shortly, please wait in the waiting room for your turn.',
  ],
  appointmentPendingTitle: 'Request sent',
  appointmentPendingMessage:
    'Your request has been sent to $doctor. We will notify you when the practitioner approves or declines your request.',
  appointmentPendingMessageClinic:
    'You will be assigned to a doctor by the clinic administrator. Please note that you may need to wait in our virtual queue until the doctor is available to see you.',
  appointmentDeclinedTitle: 'Request declined',
  appointmentDeclinedMessage: [
    'Your request has been declined. You can try and find another doctor that may be available.',
  ],
  appointmentNoAvailableDoctorsMessage: {
    [ConsultationTypeEnum.HOME_VISIT]: [
      'There are no doctors available at the time chosen. Please choose a different time range.',
    ],
    [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: [
      'Currently there are no doctors available for scheduled appointments. Go ahead and check for an on demand doctor.',
    ],
  },
  appointmentNoAvailableDoctorsButton: 'CHECK DOCTORS AVAILABLE NOW',
  onDemandRequest:
    'You have a new Doctor on Demand request by $patient. Would you like to accept and have them wait in the waiting room?',
  past: 'Past',
  upcoming: 'Upcoming',
  decline: 'Decline',
  declineConsultation: 'Decline Consultation',
  declineReason: 'Can you provide a reason why you are rejecting this consultation?',
  feedbackTitle: 'Consultation ended',
  feedbackQuestion1: 'Would you recommend $doctor?',
  feedbackQuestion2: 'Rate your experience while using Digimed',
  feedbackQuestion3: 'Write a comment',
  reschedule: 'Reschedule',
  disconnectOnly: 'Disconnect only',
  cancelAppointmentTitle: 'Are you sure you want to cancel the appointment?',

  cancelAppointmentPatient: {
    message1: 'Some charges may still apply. For more information view our ',
    message2: 'terms of business',
    message3: ' and ',
    message4: 'cancellation policy.',
  },
  cancelAppointmentDoctor:
    'When cancelling your appointment the patient will be fully refunded, and directed to created a new appointment.',
  saveDoctorDescription:
    'By saving, the doctor will have full access to your health records. You can edit the favourite doctors at any time from the Doctors tab.',
  saveDoctorTitle: 'Save doctor',
  saveFavouriteDoctor: 'SAVE TO FAVOURITE DOCTORS',
  endConsultationDesc: 'You will receive a notification once the Health Records and Prescriptions are complete.',
  returnToAppointments: 'RETURN TO APPOINTMENTS',
  preAuthoriseTitle:
    'Pre-authorise your card to book your appointment. You will only be charged after your consultation ends.',
  bookingPriceText:
    'Your physician will confirm the consultation fee at the end of the appointment. The consultation fee may be lower than the price shown here, but not higher. If you need to cancel your appointment, please do so at your earliest to avoid being charged the total amount due.',
  bookingCancellationLearnMore: 'Click here to learn more',
};
