import React, { useMemo, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { HeaderActions } from './HeaderActions';
import { useUserDetailsInfo } from '../../../../api/hooks/accounts/UserDetails';
import { H2TtmSemiBoldBlack, H4TtmSemiBoldBlack, H6NsRegularSecondaryBlack } from '../../../commonText';

import { useSubscriptions } from '~/api/hooks/subscriptions/SubscriptionHook';
import { SubscriptionTypeEnum } from '~/api/models/subscriptions/models/SubscriptionPlanModel';
import { mb10 } from '~/common/commonStyles';
import { ButtonText, CommonButtonLink } from '~/components/commonViews';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const PatientWebHeader: React.FC = () => {
  const { currentPlan } = useSubscriptions();
  const isFreePlan = useMemo(() => currentPlan?.subscription_type === SubscriptionTypeEnum.FREEPATIENT, [currentPlan]);
  const [cardWidth, setCardWidth] = useState<number>(0);
  const minCardWidth = 183;

  const onCardLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setCardWidth(width);
  };

  const shouldShowUpgrade = cardWidth > minCardWidth;

  const { fullName, userDetails } = useUserDetailsInfo();

  return (
    <Row style={{ marginTop: 3 }}>
      <Column span={{ xs: 12, md: 3 }} style={mb10}>
        <H2TtmSemiBoldBlack numberOfLines={2}>{fullName}</H2TtmSemiBoldBlack>
        <H6NsRegularSecondaryBlack>
          {userDetails?.account?.national_id_number || userDetails?.account?.passport_number}
        </H6NsRegularSecondaryBlack>
      </Column>
      {/* Once user upgrades, this will be removed - */}
      {isFreePlan ? (
        <Column span={{ xs: 12, md: 3 }}>
          {shouldShowUpgrade ? (
            <CommonButtonLink
              style={{ backgroundColor: colors.fadedTurquoise, padding: '18px', maxheight: 62 }}
              to={{ screen: screenName.ProfileScreen, params: { screen: screenName.ProfileSubscriptionPlan } }}
              onLayout={onCardLayout}>
              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  minWidth: '25%',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}>
                <H4TtmSemiBoldBlack>{labels.freeAccount}</H4TtmSemiBoldBlack>
                <ButtonText style={{ color: colors.darkPurple }}>{labels.upgrade}</ButtonText>
              </View>
            </CommonButtonLink>
          ) : (
            <CommonButtonLink
              style={{ backgroundColor: colors.fadedTurquoise, padding: '18px', maxheight: 62 }}
              to={{ screen: screenName.ProfileScreen, params: { screen: screenName.ProfileSubscriptionPlan } }}
              onLayout={onCardLayout}>
              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  minWidth: '25%',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}>
                <View style={styles.upgradeRowStyle} onLayout={onCardLayout}>
                  <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <ButtonText style={{ color: colors.darkPurple }}>Upgrade</ButtonText>
                  </View>
                </View>
              </View>
            </CommonButtonLink>
          )}
        </Column>
      ) : null}
      {/* - Once user upgrades, this will be removed */}
      <HeaderActions />
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    flex: 1,
  },
  upgradeRowStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
});
