import { Route, useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { View } from 'react-native';

import { useFetchLoginDetails } from '~/api/hooks/accounts/LoginDetails';
import { useClinicAdminRegistrationAction } from '~/api/hooks/clinics/ClinicAdminRegistrationAction';
import { useHandleAssociation } from '~/api/hooks/notifications/handlers/HandleAssociationHook';
import { usePharmacyLinkingAction } from '~/api/hooks/pharmacy/PharmacyLinkingAction';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { useAuthDeepLinkClear } from '~/navigation/hooks/useAuthDeepLink';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { screenName } from '~/utils/screenName';

enum ActionEnum {
  ASSOCIATIONS = 'associations',
  PHARMACIST = 'pharmacist',
  CLINIC_ADMIN = 'clinic-admin',
}

type AssociationType = 'approve' | 'decline';
type Token = string;
interface AssociationRequest {
  param1: AssociationType;
  param2: Token;
}

interface PharmacistLinkRequest {
  param1: Token;
}

interface BaseRouteParams {
  action: ActionEnum;
}

type RouteParams = BaseRouteParams & (AssociationRequest | PharmacistLinkRequest);
interface Props {
  route?: Route<string, RouteParams>;
}

export const ActionHandlerScreen: React.FC<Props> = ({ route }) => {
  const { getLoginDetails } = useFetchLoginDetails();

  const { acceptAssociationRequest, declineAssociationRequest } = useHandleAssociation();
  const { handlePharmacistLinkAction } = usePharmacyLinkingAction();
  const { handleClinicAdminRegistration } = useClinicAdminRegistrationAction();
  const { navigate } = useNavigation<NavType>();
  const { removeDeepLink } = useAuthDeepLinkClear();

  const handleAssociation = async (type: string) => {
    /**
     * Handle association requests
     * URL structure:
     * * <domainUrl>/action/associations/approve/<Token>
     * * <domainUrl>/action/associations/decline/<Token>
     */
    const params = route.params as AssociationRequest;
    if (type === 'approve') {
      await acceptAssociationRequest(params.param2);
    } else if (type === 'decline') {
      await declineAssociationRequest(params.param2);
    }
  };

  const handle = async () => {
    let isLoggedIn = false;
    try {
      const { action, param1 } = route.params ?? {};

      const loginDetails = await getLoginDetails();
      isLoggedIn = !!loginDetails?.steppedUp;

      switch (action) {
        case ActionEnum.ASSOCIATIONS:
          if ((route.params as AssociationRequest).param2 && isLoggedIn) {
            await handleAssociation(param1);
          }
          break;
        case ActionEnum.PHARMACIST:
          if (param1) {
            await handlePharmacistLinkAction(param1).catch((e) => {
              ErrorAlert(e);
            });
            navigate(isLoggedIn ? screenName.Dashboard : screenName.RegisterScreen);
            return;
          }
          break;
        case ActionEnum.CLINIC_ADMIN:
          if (param1) {
            await handleClinicAdminRegistration(param1).catch(() => {});
            await removeDeepLink();
            navigate(screenName.RegisterScreen);
            return;
          }
      }
      navigate(isLoggedIn ? screenName.Dashboard : screenName.LoginScreen);
    } catch (e) {
      ErrorAlert(e);
      navigate(screenName.LoginScreen);
    }
  };

  useEffect(() => {
    handle();
  }, []);
  return <View style={{ flex: 1, backgroundColor: colors.white }} />;
};
