import { PrescriptionRequestStateEnum } from '~/api/models/consultations/enums/PrescriptionRequestStateEnum';
import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import { colors } from '~/utils/colors';

export const PrescriptionRequestStatusMapping = {
  [PrescriptionRequestStateEnum.APPROVED]: {
    label: () => 'Approved',
    color: () => colors.warning,
  },
  [PrescriptionRequestStateEnum.COMPLETED]: {
    label: (request: PrescriptionRequestModel) =>
      !request.is_collected
        ? 'Expired'
        : request.is_collected_from_other_pharmacy
        ? 'From other Pharmacy'
        : 'Dispensed',
    color: (request: PrescriptionRequestModel) =>
      !request.is_collected
        ? colors.placeholderGrey
        : request.is_collected_from_other_pharmacy
        ? colors.purple
        : colors.success,
  },
  [PrescriptionRequestStateEnum.PENDING]: {
    label: () => 'Pending',
    color: () => colors.purple,
  },
  [PrescriptionRequestStateEnum.REJECTED]: {
    label: () => 'Declined',
    color: () => colors.danger,
  },
};
