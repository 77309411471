import React, { useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { ProfileImageComponent } from './ProfileImageComponent';
import { AccountModel } from '../../api/models/accounts/models/AccountModel';
import { justifyCenter, mr10 } from '../../common/commonStyles';
import {
  H2TtmSemiBoldBlack,
  H3TtmSemiBoldBlack,
  H4TtmSemiBoldBlack,
  H6NsRegularSecondaryBlack,
  SmallNsRegularBlack,
  SmallNsSemiBoldBlack,
} from '../commonText';
import { DoctorClinicName } from '../patient/doctorListing/components/DoctorClinicName';

import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props {
  account: AccountModel;
  clinic?: AccountModel;
  isDoctor?: boolean;
  detail?: string;
  style?: StyleProp<ViewStyle>;
  theme?: 'default' | 'table' | 'large';
  extraDetails?: React.ReactNode;
}
export const ProfileDetailsComponent: React.FC<Props> = ({
  detail,
  account,
  style,
  isDoctor,
  clinic,
  theme,
  extraDetails,
}) => {
  const profile = useMemo(() => {
    const details =
      detail ??
      (isDoctor
        ? account?.areas_of_specialisation?.map((element) => element.name).join(', ')
        : account?.national_id_number
        ? `ID:  ${account?.national_id_number}`
        : account?.passport_number
        ? `Passport:  ${account?.passport_number}`
        : '');
    return {
      profileName: getAccountName(account),
      details,
    };
  }, [account, detail]);
  const details = useMemo(() => {
    switch (theme) {
      case 'table':
        return (
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <SmallNsSemiBoldBlack style={{ whiteSpace: 'pre-wrap', marginBottom: 4, textOverflow: 'ellipsis' }}>
              {profile.profileName}
            </SmallNsSemiBoldBlack>
            <SmallNsRegularBlack style={{ textOverflow: 'ellipsis', marginBottom: 4 }}>
              {profile.details}
            </SmallNsRegularBlack>
            <DoctorClinicName account={isDoctor ? account : null} clinic={clinic} />
            {extraDetails}
          </View>
        );
      case 'large':
        return (
          <View style={{ flex: 1, justifyContent: 'center', marginLeft: 10 }}>
            <H2TtmSemiBoldBlack
              style={[
                { whiteSpace: 'pre-wrap', marginBottom: profile.details ? 5 : undefined, textOverflow: 'ellipsis' },
              ]}>
              {profile.profileName}
            </H2TtmSemiBoldBlack>
            <H3TtmSemiBoldBlack style={{ textOverflow: 'ellipsis' }}>{profile.details}</H3TtmSemiBoldBlack>
            <DoctorClinicName account={isDoctor ? account : null} clinic={clinic} />
            {extraDetails}
          </View>
        );
      default:
        return (
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <H4TtmSemiBoldBlack style={[{ whiteSpace: 'pre-wrap', textOverflow: 'ellipsis' }]}>
              {profile.profileName}
            </H4TtmSemiBoldBlack>
            <H6NsRegularSecondaryBlack style={[{ whiteSpace: 'pre-wrap', textOverflow: 'ellipsis' }]}>
              {profile.details}
            </H6NsRegularSecondaryBlack>
            <DoctorClinicName account={isDoctor ? account : null} clinic={clinic} />
            {extraDetails}
          </View>
        );
    }
  }, [theme, profile]);

  return (
    <View style={[{ display: 'flex', flexDirection: 'row', marginVertical: theme === 'table' ? 5 : undefined }, style]}>
      <View style={[mr10, justifyCenter]}>
        <ProfileImageComponent account={account} size={theme === 'large' ? 80 : undefined} shadow={theme === 'large'} />
      </View>
      {details}
    </View>
  );
};
