import { Image } from 'expo-image';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ErrorAlert } from './ErrorAlert';
import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { IconButton } from '../buttons/IconButton';
import { SmallNsRegularBlack } from '../commonText';
import { DownloadIcon, Person, XOutline } from '../svgImages';

import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { ml10, ml5, mr5 } from '~/common/commonStyles';
import fileSystem from '~/integrations/fileSystem';
import { colors } from '~/utils/colors';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props extends BaseModalProps {
  image: MediaModel;
  account?: AccountModel;
}

export const ImagePreviewModal: React.FC<Props> = ({ onHide, image, account }) => {
  const download = () => {
    fileSystem.downloadMedia(image).catch(ErrorAlert);
  };

  return (
    <ModalContainer onHide={onHide} hideOnBackground edge fullScreen wide noScroll>
      <View style={styles.container}>
        <View style={styles.header}>
          <View style={styles.name}>
            <Person width={20} height={20} />
            <SmallNsRegularBlack style={ml10}>{getAccountName(account)}</SmallNsRegularBlack>
          </View>

          <View style={styles.actions}>
            <IconButton transparent style={mr5} onPress={download}>
              <DownloadIcon width={15} height={15} />
            </IconButton>

            <IconButton transparent style={ml5} onPress={onHide}>
              <XOutline width={15} height={15} />
            </IconButton>
          </View>
        </View>
        <View style={styles.imageContainer}>
          <Image style={styles.image} source={{ uri: image?.url }} contentFit="contain" />
        </View>
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.black,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.lightPurple2,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  name: {
    display: 'flex',
    flexDirection: 'row',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    flex: 1,
    position: 'relative',
  },
  image: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
});
