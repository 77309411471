import { AssociationVerificationStatusEnum } from '../constants/AssociationVerificationStatusEnum';

import {
  apiAcceptAssociationRequest,
  apiDeclineAssociationRequest,
  apiValidateAssociationRequest,
} from '~/api/services/accounts/association';
import { ConfirmationAlert, SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import { DigimedNotificationEventPayload } from '~/integrations/pushNotifications/interfaces/DigimedNotificationEventPayload';
import { isPatientVersion } from '~/utils/buildConfig';

export const useHandleAssociation = () => {
  const validateAssociation = async (token: string) => {
    try {
      await apiValidateAssociationRequest(token);
      return AssociationVerificationStatusEnum.VALID;
    } catch (e) {
      if (e?.status === 404) {
        return AssociationVerificationStatusEnum.USED;
      }
      return AssociationVerificationStatusEnum.EXPIRED;
    }
  };
  const acceptAssociationRequest = async (token: string) => {
    try {
      await apiAcceptAssociationRequest(token);
      SuccessAlert(['Your doctor now has access to your details']);
    } catch (e) {
      ErrorAlert(e);
    }
  };
  const declineAssociationRequest = async (token: string) => {
    try {
      await apiDeclineAssociationRequest(token);
      SuccessAlert(['You have declined the patient information association request.']);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const handleNotification = (payload: DigimedNotificationEventPayload) => {
    if (!isPatientVersion()) return;
    const token = payload.userInfo.data.extra;
    if (token) {
      // Todo: api request ot get info on the doctor
      ConfirmationAlert(
        [
          payload.userInfo.notification.body,
          'By accepting this request the doctor will be able to view and modify your health profile',
        ],
        {
          title: 'Patient association request',
          okTitle: 'Confirm',
          cancelTitle: 'Decline',
          type: ModalAlertTypeEnum.WARNING,
          okFunction: () => {
            acceptAssociationRequest(token);
          },
          cancelFunction: () => {
            declineAssociationRequest(token);
          },
        }
      );
    }
  };

  return { handleNotification, acceptAssociationRequest, declineAssociationRequest, validateAssociation };
};
