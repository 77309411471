import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DependantModel } from '~/api/models/dependants/models/DependantModel';
import { apiShowDependant, apiShowDependantRelations } from '~/api/services/dependants';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { RootState } from '~/redux/reducers';
import { SET_DEPENDANT } from '~/redux/reducers/dependantsReducer';
import { useAppDispatch } from '~/redux/store';

interface Props {
  id: number | string;
  allowStale?: boolean;
}
export function useDependant({ id, allowStale }: Props) {
  const [loading, setLoading] = useState(false);
  const dependant = useSelector((state: RootState) => state.dependantsReducer.current);
  const [showOver18Button, setShowOver18Button] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loading) {
      setShowOver18Button(false);
    } else if (dependant !== undefined) {
      setShowOver18Button(
        dependant?.is_minor === false &&
          (dependant?.account.email == null ||
            dependant?.account.mobile_number == null ||
            dependant?.account.country_code == null)
      );
    }
  }, [dependant]);

  const getCurrentDependant = async () => {
    const idNum = Number(id);
    if (!idNum) return;
    try {
      setLoading(true);

      const res = await apiShowDependant({ id: idNum });

      dispatch(SET_DEPENDANT(res.data));
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!dependant || !allowStale || dependant.id !== +id) getCurrentDependant();
  }, [id]);

  return { loading, dependant, getCurrentDependant, showOver18Button };
}

interface LinkedAccountsProps {
  id: number | string;
}
export function useDependantLinkedAccounts({ id }: LinkedAccountsProps) {
  const [loading, setLoading] = useState(false);
  const [linkedAccounts, setLinkedAccounts] = useState<DependantModel[]>();

  const getLinkedAccounts = async () => {
    try {
      const idNum = Number(id);
      if (!idNum) return;

      setLoading(true);

      const res = await apiShowDependantRelations({
        id: idNum,
        params: { limit: -1 },
      });

      setLinkedAccounts(res.data);
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!id) return;
    getLinkedAccounts();
  }, [id]);

  return { loading, linkedAccounts };
}
