import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';

import { ErrorAlert } from './ErrorAlert';
import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { Button, TextButton } from '../commonButton';
import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '../commonText';
import FloatingInput from '../floatingInputBox';
import { MandatoryAsterisks } from '../inputs/MandatoryAsterisks';
import { RatingInput } from '../inputs/RatingInput';

import { FeedbackModel } from '~/api/models/appointments/models/FeedbackModel';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiStoreFeedback } from '~/api/services/appointments/feedback';
import { mb10, mb20, mt20, textCenter } from '~/common/commonStyles';
import { requiredValidation, validationSchema } from '~/services/validationConfig';
import { transformLabel } from '~/utils/labelUtil';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props extends BaseModalProps {
  consultation: ConsultationModel;
  onSubmit: () => void;
  onHide: () => void;
}

export const FeedbackModal: React.FC<Props> = ({ consultation, onHide, onSubmit }) => {
  const [submitting, setSubmitting] = useState(false);
  const { control, handleSubmit } = useForm<FeedbackModel>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      doctor_rating: 0,
      app_rating: 0,
      comment: '',
    },
  });

  const submitFeedback = async (data: FeedbackModel) => {
    try {
      setSubmitting(true);
      await apiStoreFeedback({
        id: consultation.id,
        params: data,
      });

      if (onSubmit) onSubmit();
    } catch (e) {
      ErrorAlert(e);
    }
    setSubmitting(false);
  };

  const buttons = [
    <Button label={labels.submit} funCallback={handleSubmit(submitFeedback)} disabled={submitting} />,
    <TextButton label={labels.skipFeedback.toUpperCase()} funCallback={onHide} disabled={submitting} />,
  ];

  const feedbackRequired = (value: number) => {
    return value === 0 ? 'Rating is required' : true;
  };

  return (
    <ModalContainer showInfoLine buttons={buttons}>
      <View>
        <H3TtmSemiBoldBlack style={[textCenter, mb20]}>{appointmentLabels.feedbackTitle}</H3TtmSemiBoldBlack>
        <H6NsRegularBlack style={mb10}>
          {transformLabel(appointmentLabels.feedbackQuestion1, { doctor: getAccountName(consultation.doctor) })}
          <MandatoryAsterisks isMandatory />
        </H6NsRegularBlack>
        <Controller
          name="doctor_rating"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <RatingInput value={value} onChange={onChange} error={isSubmitted && error?.message} />
          )}
          rules={{
            required: requiredValidation('Doctor rating'),
            validate: {
              feedbackRequired,
            },
          }}
        />
        <H6NsRegularBlack style={[mt20, mb10]}>
          {appointmentLabels.feedbackQuestion2}
          <MandatoryAsterisks isMandatory />
        </H6NsRegularBlack>
        <Controller
          name="app_rating"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <RatingInput value={value} onChange={onChange} error={isSubmitted && error?.message} />
          )}
          rules={{
            required: requiredValidation('App rating'),
            validate: {
              feedbackRequired,
            },
          }}
        />
        <H6NsRegularBlack style={[mt20]}>{appointmentLabels.feedbackQuestion3}</H6NsRegularBlack>
        <Controller
          name="comment"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingInput
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error?.message}
              errorMessage={isSubmitted && error?.message}
              multiline
              maxLength={validationSchema.string.maxLength}
            />
          )}
        />
      </View>
    </ModalContainer>
  );
};
