import React from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

import { colors } from '../../utils/colors';
import { H6NsRegularBlack } from '../commonText';
import { XOutline } from '../svgImages';

interface Props {
  value: any;
  labelTitle?: string;
  remove?: (value) => void;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const SelectedItemButton: React.FC<Props> = ({ value, labelTitle = 'label', children, remove, style }) => {
  return (
    <View style={[styles.container, style]}>
      <View style={{ flexShrink: 1 }}>{children || <H6NsRegularBlack>{value[labelTitle]}</H6NsRegularBlack>}</View>
      {remove ? (
        <TouchableOpacity style={{ display: 'flex', justifyContent: 'center', marginLeft: 8 }} onPress={remove}>
          <XOutline width={8} height={8} color={colors.black} />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderRadius: 6,
    backgroundColor: colors.lightTurquoise,
  },
});
