export enum OTConnectionState {
  Initial,
  Disconnected,
  Reconnecting,
  Connected,
}

export enum OTVideoState {
  Disabled,
  Warning,
  NotSupported,
  PoorQuality,
  Enabled,
}
