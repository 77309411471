import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

import {
  AvailableDoctorsListResponse,
  AvailableDoctorsResponse,
} from '~/api/models/appointments/responses/AvailableDoctorsResponse';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { OnDemandDoctorQueueModel } from '~/api/models/consultations/models/OnDemandDoctorQueueModel';
import { FavouriteDoctorModel } from '~/api/models/favouriteDoctors/models/FavouriteDoctorModel';

interface IState {
  favourites: {
    data: FavouriteDoctorModel[];
    loading?: boolean;
  };
  onDemandQueue: {
    data: OnDemandDoctorQueueModel[];
    loading?: boolean;
  };
  doctors: {
    [ConsultationTypeEnum.ON_DEMAND]?: AvailableDoctorsResponse;
    [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]?: AvailableDoctorsResponse;
    [ConsultationTypeEnum.HOME_VISIT]?: AvailableDoctorsResponse;
  };
  clinicDoctors?: AvailableDoctorsListResponse;
}

const initialState: IState = {
  favourites: {
    data: undefined,
    loading: false,
  },
  onDemandQueue: {
    data: undefined,
    loading: false,
  },
  doctors: {},
  clinicDoctors: undefined,
};

const doctorListingSlice = createSlice({
  name: 'doctorListing',
  initialState,
  reducers: {
    SET_FAVOURITES: (state, action: PayloadAction<FavouriteDoctorModel[]>) => ({
      ...state,
      favourites: {
        data: action.payload,
        loading: false,
      },
    }),
    SET_FAVOURITES_LOADING: (state, action: PayloadAction<boolean>) => ({
      ...state,
      favourites: {
        ...state.favourites,
        loading: action.payload,
      },
    }),

    SET_ON_DEMAND_QUEUE: (state, action: PayloadAction<OnDemandDoctorQueueModel[]>) => ({
      ...state,
      onDemandQueue: {
        data: action.payload,
        loading: false,
      },
    }),
    SET_ON_DEMAND_QUEUE_LOADING: (state, action: PayloadAction<boolean>) => ({
      ...state,
      onDemandQueue: {
        ...state.onDemandQueue,
        loading: action.payload,
      },
    }),

    SET_DOCTORS: (
      state,
      action: PayloadAction<{
        type: ConsultationTypeEnum;
        data: AvailableDoctorsResponse | AvailableDoctorsListResponse;
      }>
    ) => ({
      ...state,
      doctors: {
        ...state.doctors,
        [action.payload.type]: action.payload.data,
      },
    }),

    SET_CLINIC_DOCTORS: (state, action: PayloadAction<AvailableDoctorsListResponse>) => ({
      ...state,
      clinicDoctors: action.payload,
    }),

    LOGOUT: () => ({
      ...initialState,
    }),
  },
});

export const {
  SET_FAVOURITES,
  SET_FAVOURITES_LOADING,
  SET_DOCTORS,
  SET_CLINIC_DOCTORS,
  SET_ON_DEMAND_QUEUE,
  SET_ON_DEMAND_QUEUE_LOADING,
  LOGOUT,
} = doctorListingSlice.actions;
export default doctorListingSlice.reducer;

export const favouriteDoctors = (state: RootState) => state.doctorListingReducer.favourites.data;
export const favouriteDoctorsLoading = (state: RootState) => state.doctorListingReducer.favourites.loading;

export const isFavouriteSelector = createSelector(
  favouriteDoctors,
  (_, doctorId: number) => doctorId,
  (doctors: FavouriteDoctorModel[], doctorId: number) =>
    !!doctors?.find((favourite) => favourite.doctor_profile.id === doctorId)
);

export const doctorsListing = (state: RootState) => state.doctorListingReducer.doctors;
export const onDemandDoctorsListing = (state: RootState) =>
  state.doctorListingReducer.doctors[ConsultationTypeEnum.ON_DEMAND];
export const clinicDoctorsListing = (state: RootState) => state.doctorListingReducer.clinicDoctors;
