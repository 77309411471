import { AxiosResponse } from 'axios';
import { DocumentPickerAsset } from 'expo-document-picker';

import { deleteReq, put, get, post } from '../../axios';
import { IdModel } from '../../models/common/models/IdModel';
import { UpdateMedicalRegistrationRequest } from '../../models/medicalRegistration/requests/UpdateMedicalRegistrationRequest';

import { SpecialisationAreasModel } from '~/api/models/medicalRegistration/models/SpecialisationAreasModel';
import { AddDoctorVerificationDocumentResponse } from '~/api/models/medicalRegistration/responses/AddDoctorVerificationDocumentResponse';
import { GetDoctorMedicalRegistrationResponse } from '~/api/models/medicalRegistration/responses/GetDoctorMedicalRegistrationResponse';
import { GetDoctorSpecialisationAreasResponse } from '~/api/models/medicalRegistration/responses/GetDoctorSpecialisationAreasResponse';
import fileSystem from '~/integrations/fileSystem';

export const apiUpdateDoctorMedicalRegistration = (data: UpdateMedicalRegistrationRequest) => {
  return put<GetDoctorMedicalRegistrationResponse>('/me/medical-registration', data);
};

export const apiGetDoctorMedicalRegistration = () => {
  return get<GetDoctorMedicalRegistrationResponse>('/me/medical-registration');
};

export const apiAddDoctorVerificationDocuments = (
  data: DocumentPickerAsset
): Promise<AxiosResponse<AddDoctorVerificationDocumentResponse>> => {
  return fileSystem.postDocumentResult('/me/doctor-verification-documents', 'documents[]', data);
};

export const apiDeleteDoctorVerificationDocument = (params: IdModel) => {
  return deleteReq(`/me/doctor-verification-documents/${params.id}`);
};

export const apiUpdateDoctorSignature = (data: DocumentPickerAsset) => {
  return fileSystem.postDocumentResult('/me/signature', 'file', data);
};

export const apiGetDoctorSpecialisationArea = () => {
  return get<GetDoctorSpecialisationAreasResponse>('/doctor/specialisation-areas');
};

export const apiUpdateSpecialisationArea = (params: SpecialisationAreasModel) => {
  return put('/doctor/specialisation-areas', params);
};

export const apiMarkAsAllied = (isAllied: boolean) => {
  return post('accounts/mark-as-allied', { is_allied: isAllied });
};
