import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { FilteredRequest } from '~/api/models/common/requests/FilteredRequest';
import { PaginatedRequest } from '~/api/models/common/requests/PaginatedRequest';
import { DEFAULT_TABLE_ITEMS_PER_PAGE } from '~/components/common/DataTable/DataTable';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { RootState } from '~/redux/reducers';
import { usePageFocus } from '~/utils/hooks/FocusHook';

/**
 * deprecated: Use useReduxTablePagination
 */

interface Props<TFilterRequest, TDataResponse> {
  limit?: number;
  query?: string;
  itemsSelector?: (state: RootState) => any[];
  paginationSelector?: (state: RootState) => PaginationMetaModel;
  getItems?: (
    params?: FilteredRequest<TFilterRequest> & PaginatedRequest
  ) => Promise<AxiosResponse<TDataResponse, any>>;
  onResponse?: (result: AxiosResponse<TDataResponse, any>) => void;
  watch?: any[];
}
export function useReduxInfiniteScroll<TFilterRequest, TDataResponse>({
  paginationSelector,
  limit,
  query,
  itemsSelector,
  getItems,
  onResponse,
  watch,
}: Props<TFilterRequest, TDataResponse>) {
  const [loading, setLoading] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationInfo>({
    page: 0,
    limit: limit || DEFAULT_TABLE_ITEMS_PER_PAGE,
  });

  const items = useSelector(itemsSelector);
  const paginatedItems = useSelector(paginationSelector);

  useEffect(() => {
    if (pagination.page !== 0) getListOfItems(pagination);
  }, [pagination.limit, pagination.page]);

  useEffect(() => {
    if (pagination.page !== 1) {
      setPagination({
        page: 1,
        limit: pagination.limit,
      });
    } else {
      getListOfItems(pagination);
    }
  }, [query]);

  const getListOfItems = (pagination: TablePaginationInfo) => {
    setLoading(true);

    setPagination(pagination);

    setDirty(true);
  };

  usePageFocus(() => {
    const currentPagination = { page: 1, limit: pagination.limit };
    setPagination(currentPagination);
    getListOfItems(currentPagination);
  }, watch);

  useEffect(() => {
    if (dirty) {
      getItems({
        page: pagination.page,
        limit: pagination.limit,
      })
        .then((response) => {
          onResponse(response);
        })
        .catch(ErrorAlert)
        .finally(() => {
          setLoading(false);
          setDirty(false);
        });
    }
  }, [dirty]);

  return { setPagination, items, pagination: paginatedItems, loading, updateData: () => getListOfItems(pagination) };
}
