import { useEffect, useRef, useState } from 'react';

import { useUserDetails } from '../accounts/UserDetails';

import { privateConsultationGeneralChannel } from '~/api/models/channel/enum/channelNames';
import { channelSubscribe } from '~/integrations/channels/PusherChannels';
import { isPharmacyVersion, whenAppType } from '~/utils/buildConfig';

interface Props {
  eventHandler: (e: string, data: any) => void;
}

export function useGeneralConsultationChannel(options: Props) {
  const { userDetails } = useUserDetails({ allowStale: true });
  const unsubscribe = useRef<() => void>();

  const subscribe = async () => {
    const accountId = whenAppType({
      whenClinic: userDetails.clinic_account?.id,
      else: userDetails.account.id,
    });

    if (!accountId) return;
    const res = await channelSubscribe(privateConsultationGeneralChannel(accountId), options.eventHandler);
    return res.unsubscribe;
  };

  useEffect(() => {
    if (isPharmacyVersion()) return;

    if (!userDetails) {
      if (unsubscribe.current) {
        unsubscribe.current();
      }
      return;
    }

    subscribe().then((res) => (unsubscribe.current = res));

    return unsubscribe.current;
  }, [userDetails]);
}
