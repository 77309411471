import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { apiLocalityIndex } from '~/api/services/referenceData';
import { RootState } from '~/redux/reducers';
import { UPDATE_LOCALITY_LIST } from '~/redux/reducers/referenceDataReducer';
import { useAppDispatch } from '~/redux/store';

export function useLocalityListing() {
  const localityData = useSelector((state: RootState) => state.referenceDataReducer.localityList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!localityData?.length) {
      apiLocalityIndex()
        .then((res) => {
          dispatch(UPDATE_LOCALITY_LIST(res.data.sort((a, b) => (a.name < b.name ? -1 : 1))));
        })
        .catch(() => {});
    }
  }, []);

  return { localities: localityData };
}
