export enum CancellationReasonEnum {
  UNRESPONSIVE = 'unresponsive',
  CANCELLED_BY_DOCTOR = 'cancelled_by_doctor',
  CANCELLED_BY_CLINIC = 'cancelled_by_clinic',
  CANCELLED_BY_PATIENT = 'cancelled_by_patient',
  CANCELLED_BY_ADMIN = 'canceled_by_admin',
  RESCHEDULED = 'rescheduled',
  EXPIRED_PAYMENT_METHOD = 'expired_payment_method',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  CONSULTATION_RESCHEDULING_REJECTED = 'consultation_rescheduling_rejected',
}
