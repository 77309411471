import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { H6NsRegularBlack } from '../commonText';

import { mr10 } from '~/common/commonStyles';
import { colors } from '~/utils/colors';

interface Props {
  label?: string;
  fullWidth?: boolean;
}

export const LoadingFetchingList: React.FC<Props> = ({ label, fullWidth }) => {
  return (
    <View style={[styles.container, fullWidth ? { width: '100%' } : null]}>
      <View style={mr10}>
        <ActivityIndicator color={colors.info} />
      </View>

      <H6NsRegularBlack>{label || 'Loading'}</H6NsRegularBlack>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: 8,
    flexDirection: 'row',
  },
});
