import Feather from '@expo/vector-icons/Feather';
import React from 'react';
import { Pressable, View } from 'react-native';

import { PrescriptionRecordMobile } from './PrescriptionRecordMobile';
import { IconButton } from '../buttons/IconButton';
import { DataTable, DataTableColumns, TablePaginationInfo } from '../common/DataTable/DataTable';
import { H6NsRegularBlack, SmallNsRegularDisableColor, SmallNsSemiBoldBlack } from '../commonText';
import { ConsultationViewItem } from '../doctor/patients/consultation/ConsultationViewItem';
import { ModalName } from '../modals/constants/ModalNames';
import { ProfileDetailsComponent } from '../profile/ProfileDetailsComponent';
import { EyeIcon } from '../svgImages';
import { PageTabsComponent } from '../tabs/PageTabsComponent';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { PrescriptionDispenseHistoryModel } from '~/api/models/pharmacy/models/PrescriptionDispenseHistoryModel';
import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import { apiGetPrescriptionMedia } from '~/api/services/consultations/prescriptions';
import { flex1, mr10, mr15 } from '~/common/commonStyles';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { pharmacyLabels } from '~/utils/labels/pharmacy';

interface Props {
  data: PrescriptionDispenseHistoryModel[];
  loading?: boolean;
  pagination: PaginationMetaModel;
  sortDesc: boolean;
  onPageChanged: (info: TablePaginationInfo) => void;
  setSortingDesc: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PharmacyDispenseHistoryTable: React.FC<Props> = ({
  data,
  loading,
  pagination,
  sortDesc,
  onPageChanged,
  setSortingDesc,
}) => {
  const { openModal } = useModalManager();

  const { isMobile } = useBreakpoints();

  const downloadPrescription = async (prescription: PrescriptionModel): Promise<MediaModel> => {
    const res = await apiGetPrescriptionMedia({ id: prescription.id });
    if (res.data?.media?.length) return res.data.media[0];
    else throw new Error('We were not able to retrieve the prescription pdf at this time');
  };

  const options = (item: PrescriptionDispenseHistoryModel) => (
    <ConsultationViewItem
      style={{ paddingVertical: 0 }}
      getMediaModel={async () => {
        return downloadPrescription(item.prescription);
      }}
      size={14}
      withBottomBorder={false}
      onView={() => {
        openModal(ModalName.PrescriptionDetailsModal, {
          prescription: item.prescription,
        });
      }}
      status={null}
    />
  );
  const columns: DataTableColumns = [
    {
      key: 'patient',
      title: 'Patient',
      columnStyle: { maxWidth: 400, minWidth: 200 },
      cell: (_, itemData: PrescriptionDispenseHistoryModel) => (
        <ProfileDetailsComponent account={itemData.prescription.patient} theme="table" style={flex1} />
      ),
    },
    {
      key: 'product',
      title: 'Prescription name',
      columnStyle: { width: 200, minWidth: 200 },
      selector: (item: PrescriptionDispenseHistoryModel) => extractClinicalTermDescription(item.prescription.product),
      cell: (cellData: string, item: PrescriptionDispenseHistoryModel) => (
        <View style={flex1}>
          <SmallNsSemiBoldBlack style={{ textWrap: 'wrap' }}>{cellData}</SmallNsSemiBoldBlack>
          <SmallNsRegularDisableColor>{item.prescription.reference_number}</SmallNsRegularDisableColor>
        </View>
      ),
    },
    {
      key: 'date',
      title: 'Prescribed date',
      columnStyle: { minWidth: 120, width: 120 },
      selector: (item: PrescriptionDispenseHistoryModel) =>
        parseDateTime(item.prescription.created_at, { outputFormat: DATE_FORMAT }),
    },
    {
      key: 'dispense_date',
      title: 'Dispense date',
      columnStyle: { minWidth: 120, width: 120 },
      selector: (item: PrescriptionDispenseHistoryModel) =>
        parseDateTime(item.created_at, { outputFormat: DATE_FORMAT }),
    },
    {
      key: 'options',
      title: 'Options',
      cell: (_, item: PrescriptionDispenseHistoryModel) => options(item),
    },
  ];

  const sorting = isMobile ? null : (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'flex-end',
        ...mr15,
      }}>
      <Pressable
        style={{ paddingVertical: 12, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        onPress={() => {
          setSortingDesc(!sortDesc);
        }}>
        <H6NsRegularBlack style={mr10}>Sort by Date</H6NsRegularBlack>
        <Feather name={sortDesc ? 'chevron-down' : 'chevron-up'} size={14} />
      </Pressable>
    </View>
  );

  return (
    <View style={{ display: 'flex', flex: 1 }}>
      <PageTabsComponent items={[]} pageTabsVariant="joined" right={sorting} fluid />
      <View style={{ display: 'flex', flex: 1 }}>
        <DataTable
          tableId="prescriptionRequests"
          columns={columns}
          items={data}
          emptyNote={pharmacyLabels.tables.noDispenseHistory}
          loading={loading}
          page={pagination?.current_page || 1}
          totalCount={pagination?.total || 0}
          numberOfItemsPerPage={pagination?.per_page || 0}
          onPageChanged={onPageChanged}
          infiniteScroll={isMobile ? { loading, estimatedItemSize: { desktop: 60, mobile: 158 } } : null}
          mobileBreakpoint={DeviceSizeDefaults.tablet}
          mobileRenderItem={(content: PrescriptionDispenseHistoryModel) => (
            <PrescriptionRecordMobile dispenseHistory={content} actionIcons={options(content)} />
          )}
        />
      </View>
    </View>
  );
};
