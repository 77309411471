import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { useRecreationalDrugsListing } from '~/api/hooks/RecreationalDrugsListing';
import { mb10 } from '~/common/commonStyles';
import { Accordion } from '~/components/buttons/Accordions/Accordion';
import FloatingInput from '~/components/floatingInputBox';
import { CheckboxGroup } from '~/components/inputs/CheckboxGroup';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { useHealthProfileRecreationalDrugsContext } from '~/providers/healthProfile/HealthProfileRecreationalDrugsProvider';

interface Props {
  locked?: boolean;
}

export const PatientLifestyleRecreationalDrugs: React.FC<Props> = ({ locked }) => {
  const { recreationalDrugsData } = useRecreationalDrugsListing();
  const { controlRecreationalDrugs } = useHealthProfileDataContext();

  const { getData, loading, hasData } = useHealthProfileRecreationalDrugsContext();

  const sortedRecreationalDrugs = useMemo(() => {
    const mappedRecreationalDrugsList = recreationalDrugsData.map((item) => ({ label: item.name, value: item.id }));
    const indexOfOtherRecreationalDrug = mappedRecreationalDrugsList.findIndex((drug) => drug.label === 'Other');
    const otherDrugObjectArr = mappedRecreationalDrugsList.splice(indexOfOtherRecreationalDrug, 1);
    const sortRecreationalDrugs = mappedRecreationalDrugsList.sort((a, b) => a.label.localeCompare(b.label));
    const newRecreationalDrugs = sortRecreationalDrugs.concat(otherDrugObjectArr);
    return newRecreationalDrugs;
  }, [recreationalDrugsData]);

  const checkboxWithTextbox = (value: string | number, isSelected: boolean) => {
    if (sortedRecreationalDrugs.find((drug) => drug.value === value).label === 'Other') {
      return (
        <Controller
          name="otherText"
          control={controlRecreationalDrugs}
          render={({ field: { onChange, value } }) => (
            <FloatingInput
              value={value}
              onChangeValue={onChange}
              disabled={locked || !isSelected}
              placeholder="Please specify..."
            />
          )}
        />
      );
    }
  };

  const expandedChanged = (expanded: boolean) => {
    if (expanded && (!hasData || locked)) {
      getData().catch(() => {});
    }
  };
  return (
    <Accordion
      groupValue="recreational_drugs"
      title="Recreational Drugs"
      style={mb10}
      onExpandedChanged={expandedChanged}>
      <Controller
        control={controlRecreationalDrugs}
        name="recreationalDrugs"
        render={({ field: { value, onChange } }) => (
          <View>
            {loading ? (
              <LoadingActivityIndicator />
            ) : (
              <CheckboxGroup
                values={value}
                items={sortedRecreationalDrugs}
                onChange={(selected: (string | number)[]) => onChange(selected.map((item) => +item))}
                disabled={locked}
                children={checkboxWithTextbox}
              />
            )}
          </View>
        )}
      />
    </Accordion>
  );
};
