import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';

export const useHandleDebug = () => {
  const { showSnackbar } = useSnackbarManager();

  const execute = async (data: any) => {
    let message = 'Just wanted to say hi!';
    try {
      message = data && typeof data !== 'string' ? JSON.stringify(data) : data ?? 'Just wanted to say Hi!';
    } catch {}

    showSnackbar(message);
  };

  const handleNotification = async (data: any) => {
    await execute(data);
  };

  return { handleNotification };
};
