import React, { useMemo } from 'react';
import { Pressable, View } from 'react-native';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { p5, mb10, mr5, flex1 } from '~/common/commonStyles';
import { Row, Column } from '~/theme/components/grid';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { H6NsSemiBoldBlack, H6NsRegularBlack, SmallNsRegularSecondaryBlack, SmallNsRegularBlack } from '../commonText';
import { ConsultationViewItem } from '../doctor/patients/consultation/ConsultationViewItem';
import { StatusBadge } from '../misc/StatusBadge';
import { ModalName } from '../modals/constants/ModalNames';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { RowView } from '../commonViews';
import { PRESCRIPTION_COLLECTION_STATUS_COLOR_MAPPING } from '~/constants/prescriptions/statusColors';

interface Props {
  prescription: PrescriptionModel;
  onGetMediaModel: (prescription: PrescriptionModel) => Promise<MediaModel>;
  onView: (prescription: PrescriptionModel) => void;
}

export const PrescriptionMobileItem: React.FC<Props> = ({ prescription, onView, onGetMediaModel }) => {
  const createdAt = useMemo(() => {
    return parseDateTime(prescription.created_at, { outputFormat: DATE_FORMAT });
  }, [prescription.created_at]);
  return (
    <Pressable onPress={() => onView(prescription)} style={{ width: '100%' }}>
      <View
        style={{
          marginBottom: 10,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          justifyContent: 'space-between',
        }}>
        <View style={flex1}>
          <H6NsSemiBoldBlack>{extractClinicalTermDescription(prescription.product)}</H6NsSemiBoldBlack>
          <H6NsRegularBlack>Ref: {prescription.reference_number}</H6NsRegularBlack>
        </View>
        <View>
          <StatusBadge
            label={prescription.collection_status}
            color={PRESCRIPTION_COLLECTION_STATUS_COLOR_MAPPING[prescription.collection_status]}
            textStyle={{ textTransform: 'capitalize' }}
          />
        </View>
      </View>

      <View>
        <View style={{ display: 'flex', gap: 5 }}>
          <RowView>
            <SmallNsRegularSecondaryBlack style={[mr5, { width: 100 }]}>Prescribed by:</SmallNsRegularSecondaryBlack>
            <SmallNsRegularBlack style={[flex1]}>{getAccountName(prescription.doctor)}</SmallNsRegularBlack>
          </RowView>
          <RowView>
            <SmallNsRegularSecondaryBlack style={[mr5, { width: 100 }]}>Patient:</SmallNsRegularSecondaryBlack>
            <SmallNsRegularBlack style={[flex1]}>{getAccountName(prescription.patient)}</SmallNsRegularBlack>
          </RowView>
          <RowView>
            <SmallNsRegularSecondaryBlack style={[mr5, { width: 100 }]}>Date:</SmallNsRegularSecondaryBlack>
            <SmallNsRegularBlack style={[flex1]}>{createdAt}</SmallNsRegularBlack>
          </RowView>
        </View>
        {prescription.is_collectable ? (
          <ConsultationViewItem
            key={40}
            onView={() => onView(prescription)}
            getMediaModel={() => onGetMediaModel(prescription)}
            withBottomBorder={false}
            status={null}
            style={{ padding: 0 }}
          />
        ) : null}
      </View>
    </Pressable>
  );
};
