import React, { useEffect, useMemo, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { List } from 'react-native-paper';

import { useAccordionGroupContext } from './AccordionGroupProvider';
import { ml10, mr10 } from '../../../common/commonStyles';
import { AccordionPaperTheme } from '../../../theme/paper/AccordionPaperTheme';
import { colors } from '../../../utils/colors';
import { fontFamily } from '../../../utils/fontFamily';
import { H5TtmRegularSecondaryBlack } from '../../commonText';
import { isNative } from '~/utils/buildConfig';

interface Props {
  title?: string;
  titleNode?: (expanded: boolean) => React.ReactNode;
  groupValue?: string;
  left?: React.ReactNode;
  children: React.ReactNode;
  expanded?: boolean;
  accessibilityLabel?: string;
  style?: StyleProp<ViewStyle>;
  accordionStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  error?: boolean;
  expandedBackgroundColor?: string;
  right?: (props: { isExpanded: boolean }) => React.ReactNode;
  onExpandedChanged?: (expanded: boolean, expandedValue?: string) => void;
}

export const Accordion: React.FC<Props> = ({
  title,
  titleNode,
  groupValue,
  children,
  expanded = false,
  accessibilityLabel,
  style,
  left,
  contentStyle,
  accordionStyle,
  titleStyle,
  error,
  disabled,
  expandedBackgroundColor = colors.white,
  right,
  onExpandedChanged,
}) => {
  const groupContext = useAccordionGroupContext();

  const [isLocalExpanded, setLocalExpanded] = useState(false);

  const isExpanded = useMemo(
    () => (groupValue && 'isExpanded' in groupContext ? groupContext.isExpanded(groupValue) : isLocalExpanded),
    [groupContext?.expanded, isLocalExpanded]
  );

  const setExpanded = (expanded?: boolean) => {
    if (isExpanded === expanded) return;
    if (groupValue && 'isExpanded' in groupContext) {
      if (groupContext.isExpanded(groupValue) !== expanded) groupContext.setExpanded(expanded ? groupValue : null);
    } else {
      setLocalExpanded(expanded);
    }
  };

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);

  useEffect(() => {
    if (onExpandedChanged) {
      const isExpanded =
        isLocalExpanded ||
        (groupValue && 'isExpanded' in groupContext ? groupContext.isExpanded(groupValue) : undefined);
      onExpandedChanged(isExpanded, isExpanded ? groupValue : groupContext?.expanded);
    }
  }, [isLocalExpanded, groupContext?.expanded]);

  const handlePress = () => setExpanded(!isExpanded);

  return (
    <View style={[styles.container, error && !isExpanded ? styles.containerError : null, style]}>
      <List.Accordion
        style={[
          styles.accordion,
          accordionStyle,
          isExpanded ? [styles.accordionExpanded, { backgroundColor: expandedBackgroundColor }] : null,
        ]}
        titleStyle={styles.title}
        theme={AccordionPaperTheme}
        title={
          <View
            style={[
              {
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              },
              isNative() ? { paddingTop: 5 } : null,
              titleStyle,
            ]}>
            {left ? <View style={[ml10, mr10, { display: 'flex', justifyContent: 'center' }]}>{left}</View> : null}
            {title ? (
              <H5TtmRegularSecondaryBlack>{title}</H5TtmRegularSecondaryBlack>
            ) : titleNode ? (
              titleNode(isExpanded)
            ) : null}
          </View>
        }
        right={disabled ? () => <></> : right}
        expanded={isExpanded}
        onPress={disabled ? null : handlePress}
        accessibilityLabel={accessibilityLabel || title}>
        <View style={[styles.content, contentStyle]}>{children}</View>
      </List.Accordion>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.lightPurple,
    borderStyle: 'solid',
  },
  containerError: {
    borderColor: colors.danger,
  },
  accordion: {
    padding: 0,
    borderRadius: 6,
    borderBottomWidth: 1,
    borderColor: colors.lightPurple,
    borderStyle: 'solid',
    backgroundColor: colors.lightPurple,
  },
  accordionExpanded: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  content: {
    padding: 8,
  },
  title: {
    color: colors.black,
    fontFamily: fontFamily.fontTitilliumRegular,
    fontSize: 14,
  },
});
