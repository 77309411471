import { endOfDay } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { Range } from 'react-date-range';
import { useForm } from 'react-hook-form';
import { StackedBarChartData } from 'react-native-chart-kit/dist/StackedBarChart';

import { IncomeListingContext } from './IncomeListingContext';

import { useReduxTablePagination } from '~/api/hooks/common/useReduxTablePagination';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { DoctorDashboardIncomeTotalResponse } from '~/api/models/dashboard/responses/DoctorDashboardIncomeTotalResponse';
import { IncomeGraphFilter } from '~/api/models/income/models/IncomeGraphFilter';
import { IncomeGraphResponse } from '~/api/models/income/responses/IncomeGraphResponse';
import { IncomeResponse } from '~/api/models/income/responses/IncomeResponse';
import { apiGetDoctorDashboardIncomeTotals } from '~/api/services/dashboard';
import {
  apiGenerateIncomeDownloadExcel,
  apiGenerateIncomeDownloadPdf,
  apiGetIncome,
  apiGetIncomeGraph,
} from '~/api/services/income';
import { InprogressAlert } from '~/common/commonMethods';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';
import { DataRangeOptionsMapping } from '~/components/doctor/income/constants/DateRangeOptions';
import { isToDate } from '~/components/doctor/income/util/DateFilterUtils';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { DateRangeOptions } from '~/components/patient/insurance/constants/DateRangeOptionsEnum';
import { DATE_FORMAT_2DIGIT_YEAR, NUM_DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { incomeLabels } from '~/utils/labels/income';

interface Props {
  isListing?: boolean;
  children: React.ReactNode;
}

export type IncomeRequestForm = {
  dateRangeSelector?: Range[];
  ConsultationType?: ConsultationTypeEnum[];
  dateTo: string;
  dateFrom: string;
  DateRangeOption: DateRangeOptions;
};

export const IncomeListingProvider: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const loadingRef = useRef(false);

  const [graphData, setGraphData] = useState<StackedBarChartData>({ barColors: [], data: [], labels: [], legend: [] });

  const [filteredTotalIncome, setFilteredTotalIncome] = useState<DoctorDashboardIncomeTotalResponse>(null);
  const [accumulatedTotalIncome, setAccumulatedTotalIncome] = useState<DoctorDashboardIncomeTotalResponse>(null);

  const form = useForm<IncomeRequestForm>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      dateRangeSelector: [{ startDate: endOfDay(new Date()), endDate: endOfDay(new Date()), key: 'selection' }],
      ConsultationType: [null],
      dateFrom: null,
      dateTo: parseDateTime(DataRangeOptionsMapping[DateRangeOptions.TODATE].range?.endDate, {
        parseFormat: NUM_DATE_FORMAT,
        outputFormat: NUM_DATE_FORMAT,
      }),
      DateRangeOption: DateRangeOptions.TODATE,
    },
  });

  const { isMobile } = useBreakpoints();

  const { items, pagination, setPagination, resetTable } = useReduxTablePagination<IncomeRequestForm, IncomeResponse>({
    isInfiniteScroll: isMobile,
    tableName: 'income',
    getItems: async (params) => {
      setLoading(true);
      const data = form.getValues();

      const query = await apiGetIncome({
        filter: getFilters(data).filter,
        page: params.page,
        sort: '-created_at',
      });
      const response = query;

      setLoading(false);
      return response;
    },
  });
  const selectedDateRangeOption = form.watch('DateRangeOption');

  const getInitialTotalIncome = async () => {
    const result = await getIncomeTotal();
    setAccumulatedTotalIncome(result);
  };

  useEffect(() => {
    getInitialTotalIncome();
  }, []);

  const getDateFilters = (data: IncomeRequestForm) => {
    return isMobile
      ? {
          dateFrom: data.dateFrom,
          dateTo: data.dateTo,
        }
      : {
          dateFrom: isToDate(data.dateRangeSelector[0]) ? undefined : data.dateRangeSelector[0]?.startDate,
          dateTo: data.dateRangeSelector[0]?.endDate,
        };
  };

  const getFilters = (data: IncomeRequestForm) => {
    const dates = getDateFilters(data);
    const filters: { filter: IncomeGraphFilter } = {
      filter: {
        date_from: dates.dateFrom
          ? parseDateTime(dates.dateFrom, {
              outputFormat: DATE_FORMAT_2DIGIT_YEAR,
            })
          : undefined,
        date_to: parseDateTime(dates.dateTo, { outputFormat: DATE_FORMAT_2DIGIT_YEAR }),
      },
    };

    const consultationTypes =
      data?.ConsultationType?.length === 1 && data?.ConsultationType?.[0] === null
        ? undefined
        : data?.ConsultationType?.filter((item) => item != null).join(',');
    if (consultationTypes) filters.filter.consultation_types = consultationTypes;
    return filters;
  };

  const getIncomeTotal = async (data?: IncomeRequestForm) => {
    const params = data
      ? {
          filter: getFilters(data).filter,
        }
      : null;
    return (await apiGetDoctorDashboardIncomeTotals(params)).data;
  };

  useEffect(() => {
    if (selectedDateRangeOption != null) {
      form.resetField('dateFrom', {
        defaultValue: DataRangeOptionsMapping[selectedDateRangeOption].range?.startDate
          ? parseDateTime(DataRangeOptionsMapping[selectedDateRangeOption].range?.startDate, {
              outputFormat: NUM_DATE_FORMAT,
            })
          : undefined,
      });

      form.resetField('dateTo', {
        defaultValue: parseDateTime(DataRangeOptionsMapping[selectedDateRangeOption].range?.endDate, {
          outputFormat: NUM_DATE_FORMAT,
        }),
      });
    }
  }, [selectedDateRangeOption]);

  useEffect(() => {
    form.handleSubmit((data) => submitQuery(data))();
  }, []);

  const submitQuery = async (data: IncomeRequestForm) => {
    try {
      if (loadingRef.current) return;
      setLoading(true);
      loadingRef.current = true;
      await getGraphData(data);
      await resetTable();
      const totalIncome = await getIncomeTotal(data);
      setFilteredTotalIncome(totalIncome);
    } catch (e) {
      ErrorAlert(e);
    } finally {
      loadingRef.current = false;
      setLoading(false);
    }
  };

  const getGraphData = async (data: IncomeRequestForm) => {
    const query = apiGetIncomeGraph({
      filter: getFilters(data).filter,
    });
    const response = await query;

    setGraphData(convertResponseToGraphData(response.data));
  };

  const refreshQuery = () => {
    submitQuery(form.getValues());
  };

  useEffect(() => {
    refreshQuery();
  }, []);

  const setPage = (pageInfo: TablePaginationInfo) => {
    if (!items || pageInfo.page === 0) return;
    setPagination(pageInfo);
  };

  const convertResponseToGraphData = (incomeGraphResponse: IncomeGraphResponse) => {
    const barColors = incomeGraphResponse.datasets.map((dataset) => dataset.backgroundColor);
    const legend = incomeGraphResponse.datasets.map((dataset) => dataset.label);
    const data = incomeGraphResponse.datasets[0].data.map((_, index) => {
      return incomeGraphResponse.datasets.map((_, dataSetIndex) => {
        const value = incomeGraphResponse.datasets[dataSetIndex].data[index];
        return value === 0 ? null : value / 100;
      });
    });

    return { barColors, data, labels: incomeGraphResponse.labels, legend };
  };

  const generateIncomeExcelDownload = async () => {
    const data = form.getValues();
    const req = data ? getFilters(data) : undefined;
    return apiGenerateIncomeDownloadExcel(req)
      .then(() => {
        InprogressAlert(incomeLabels.exportMessageExcel, {
          title: incomeLabels.exportTitle,
        });
      })
      .catch(ErrorAlert);
  };

  const generateIncomePdfDownload = async () => {
    const data = form.getValues();
    const req = data ? getFilters(data) : undefined;
    return apiGenerateIncomeDownloadPdf(req)
      .then(() => {
        InprogressAlert(incomeLabels.exportMessagePDF, {
          title: incomeLabels.exportTitle,
        });
      })
      .catch(ErrorAlert);
  };

  return (
    <IncomeListingContext.Provider
      value={{
        form,
        loading,
        setPage,
        submitQuery: form.handleSubmit((data) => submitQuery(data)),
        graphData,
        incomeListData: { data: items, meta: pagination, links: [] },
        accumulatedTotalIncome,
        filteredTotalIncome,
        generateIncomeExcelDownload,
        generateIncomePdfDownload,
      }}>
      {children}
    </IncomeListingContext.Provider>
  );
};
