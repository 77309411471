import { isPharmacyVersion } from '~/utils/buildConfig';
import { useCurrentPharmacy } from '../pharmacy/PharmacyHook';
import { PharmacyModel } from '~/api/models/pharmacy/models/PharmacyModel';
import { PharmacyStateEnum } from '~/api/models/pharmacy/constants/PharmacyStateEnum';
import { useSelector } from 'react-redux';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { successOrErrorPopup } from '~/common/commonMethods';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';

export function usePharmacyStateChange() {
  const { updatePharmacies } = useCurrentPharmacy();
  const currentPharmacy = useSelector(currentPharmacySelector);
  const { showSnackbar } = useSnackbarManager();

  const updatePharmacyState = async () => {
    if (isPharmacyVersion()) {
      await updatePharmacies();
    }
  };

  const issueSnackbar = (pharmacy: PharmacyModel) => {
    if (currentPharmacy?.pharmacy?.id === pharmacy.id) {
      const success = pharmacy.state === PharmacyStateEnum.APPROVED;
      successOrErrorPopup(
        [`${pharmacy.name} has been ${pharmacy.state} by the Digimed administration`],
        true,
        success ? ModalAlertTypeEnum.SUCCESS : ModalAlertTypeEnum.ERROR,
        success ? 'Pharmacy enabled' : 'Pharmacy disabled'
      );
    } else {
      showSnackbar(`${pharmacy.name} has been ${pharmacy.state} by the Digimed administration`);
    }
  };

  return { updatePharmacyState, issueSnackbar };
}
