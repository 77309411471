export const incomeLabels = {
  exportMessageExcel: [
    'Exporting your income data as an excel file,\nthis may take a while.',
    'We will notify you when it is done.',
  ],
  exportMessagePDF: [
    'Exporting your income data as a PDF,\nthis may take a while.',
    'We will notify you when it is done.',
  ],
  exportTitle: 'Exporting income details',
};
