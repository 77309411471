import { useNavigation } from '@react-navigation/native';
import React from 'react';

import { Button, TextButton } from '../../../components/commonButton';
import { ConsultationTypeSubtitle } from '../../../components/preferences/ConsultationTypeSubtitle';
import RegistrationLayout from '../../../layouts/RegistrationLayout';
import { labels } from '../../../utils/labels';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { useSettingsConsultationTypePrice } from '~/api/hooks/consultations/SettingsConsultationTypePriceHook';
import { mt10 } from '~/common/commonStyles';
import { ConsultationPriceDropdowns } from '~/components/doctor/settings/consultations/ConsultationPriceDropdowns';
import { screenName } from '~/utils/screenName';

export const CostPerConsultationTypeScreen: React.FC = () => {
  const { control, submitConsultationPrices } = useSettingsConsultationTypePrice({ saveChannelsAndLanguages: false });
  const { updateRegistrationStatus } = useRegistrationSteps();
  const navigation = useNavigation();

  const submit = async () => {
    await submitConsultationPrices();
    await updateRegistrationStatus();
    navigation.navigate(screenName.PreferencesScreen as never);
  };

  const button = (
    <>
      <Button funCallback={submit} label={labels.save} />
      <TextButton
        style={mt10}
        funCallback={() => navigation.navigate(screenName.PreferencesScreen as never)}
        label={labels.notNow}
      />
    </>
  );
  return (
    <RegistrationLayout
      title={labels.costPerConsultationText}
      subtitle={<ConsultationTypeSubtitle />}
      buttons={button}
      backTitle={labels.backToPreferences.toUpperCase()}
      backLink={{ screen: screenName.PreferencesScreen }}>
      <ConsultationPriceDropdowns control={control} compact />
    </RegistrationLayout>
  );
};
