import { useMemo } from 'react';

import { useBreakpoints } from '~/utils/hooks/GridHook';

export const LEFTDOTS = 'left';
export const RIGHTDOTS = 'right';

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({ totalPageCount, siblingCount = 2, currentPage }) => {
  const { isMobile } = useBreakpoints();

  const paginationRange = useMemo<(string | number)[]>(() => {
    if (isMobile) return [];
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > siblingCount - 1;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - (siblingCount - 1);

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 1 + siblingCount;
      const leftRange = range(1, leftItemCount);
      return [...leftRange, RIGHTDOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = siblingCount + 1;
      const rightRange = range(totalPageCount - rightItemCount, totalPageCount);
      return [firstPageIndex, LEFTDOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, LEFTDOTS, ...middleRange, RIGHTDOTS, lastPageIndex];
    }
  }, [totalPageCount, siblingCount, currentPage, isMobile]);
  return paginationRange;
};
