import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { apiGetInsuranceClaimDocumentTypes } from '~/api/services/referenceData';
import { RootState } from '~/redux/reducers';
import { UPDATE_INSURANCE_CLAIM_DOC_TYPES } from '~/redux/reducers/referenceDataReducer';
import { useAppDispatch } from '~/redux/store';

export function useInsuranceClaimDocumentTypeListing() {
  const insuranceClaimDocumentTypes = useSelector(
    (state: RootState) => state.referenceDataReducer.insuranceClaimDocumentTypes
  );

  const dispatch = useAppDispatch();
  const loading = useRef(false);

  const execute = async () => {
    try {
      loading.current = true;
      const res = await apiGetInsuranceClaimDocumentTypes();

      dispatch(UPDATE_INSURANCE_CLAIM_DOC_TYPES(res.data));
    } catch {}

    loading.current = false;
  };

  useEffect(() => {
    if (!insuranceClaimDocumentTypes && !loading.current) {
      execute();
    }
  }, []);

  return insuranceClaimDocumentTypes;
}
