import { DocumentPickerAsset } from 'expo-document-picker';

import { deleteReq, get, post, put } from '../../axios';

import { IdModel } from '~/api/models/common/models/IdModel';
import { GetMediaCollectionResponse } from '~/api/models/common/responses/GetMediaCollectionResponse';
import { AddInsuranceDetailsFormRequest } from '~/api/models/insurance/requests/AddInsuranceDetailsRequest';
import { InsuranceClaimFormRequest } from '~/api/models/insurance/requests/InsuranceClaimFormRequest';
import { SetInsurancePolicyNumberRequest } from '~/api/models/insurance/requests/SetInsurancePolicyNumberRequest';
import {
  InsuranceClaimFormResponse,
  InsuranceClaimsReceiptResponse,
} from '~/api/models/insurance/responses/InsuranceClaimFormResponse';
import { InsuranceCompaniesResponse } from '~/api/models/insurance/responses/InsuranceCompaniesResponse';
import { InsuranceDetailsResponse } from '~/api/models/insurance/responses/InsuranceDetailsResponse';
import fileSystem from '~/integrations/fileSystem';
import { trimNullOrUndefined } from '~/utils/objectUtil';

export const apiGetInsuranceCompanies = () => {
  return get<InsuranceCompaniesResponse>('/insurance-companies');
};

export const apiGetInsuranceDetails = () => {
  return get<InsuranceDetailsResponse>('/me/insurance-details');
};

export const apiPostInsuranceDetails = (data: AddInsuranceDetailsFormRequest) => {
  return post('/me/insurance-details', data);
};
export const apiGetInsuranceClaim = (insuranceClaimId: number) => {
  return get<InsuranceClaimFormResponse>(`/patient/insurance-claims/${insuranceClaimId}`);
};

export const apiPostInsuranceClaimForm = (data: InsuranceClaimFormRequest) => {
  return post<InsuranceClaimFormResponse>('/patient/insurance-claims', data);
};

export const apiPutInsuranceClaimForm = (insuranceClaimId: number, data: InsuranceClaimFormRequest) => {
  return put<InsuranceClaimFormResponse>(`/patient/insurance-claims/${insuranceClaimId}`, data);
};

export const apiPostSubmitInsuranceClaimForm = (insuranceClaimId: number) => {
  return post(`/patient/insurance-claims/${insuranceClaimId}/submit`);
};

export const getInsuranceClaimMedia = (insuranceClaimId: number) => {
  return get<GetMediaCollectionResponse>(`/patient/insurance-claims/${insuranceClaimId}/media`);
};

export const apiSetInsurancePolicyNumber = (params: SetInsurancePolicyNumberRequest) => {
  return post('/auth/me/insurance', params);
};

export const apiGetInsuranceClaimDocuments = (req: IdModel) => {
  return get<InsuranceClaimsReceiptResponse[]>(`patient/insurance-claims/${req.id}/documents`);
};

export const apiAddInsuranceClaimDocument = (req: {
  claimId: number;
  image: DocumentPickerAsset;
  params: { type: string; amount?: string; currency?: string; description?: string };
}) => {
  const params = trimNullOrUndefined(req.params);
  return fileSystem.postDocumentResult(
    `/patient/insurance-claims/${req.claimId}/documents`,
    'image',
    req.image,
    params
  );
};

export const apiUpdateInsuranceClaimDocument = (req: {
  documentId: number;
  image: DocumentPickerAsset;
  params: { type: string; amount?: string; currency?: string; description?: string };
}) => {
  const params = trimNullOrUndefined(req.params);
  if (!req.image) {
    return put(`patient/insurance-claim-documents/${req.documentId}`, params);
  } else {
    return fileSystem.putDocumentResult(
      `patient/insurance-claim-documents/${req.documentId}`,
      'image',
      req.image,
      params
    );
  }
};

export const apiDeleteInsuranceClaimDocument = (req: IdModel) => {
  return deleteReq(`patient/insurance-claim-documents/${req.id}`);
};
