import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';

import { labels } from '../../utils/labels';
import { ToggleSwitch } from '../buttons/ToggleSwitch';
import { H6NsRegularBlack, FormErrorMessage } from '../commonText';
import { Dropdown } from '../inputs/dropdown/Dropdown';
import { LoadingActivityIndicator } from '../loading/LoadingActivityIndicator';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { OnDemandAvailabilityEventModel } from '~/api/models/preferences/models/OnDemandAvailabilityEventModel';
import { OnDemandAvailabilityForm } from '~/api/models/preferences/models/OnDemandAvailabilityFormModel';
import { mt10 } from '~/common/commonStyles';
import { DoctorAvailabilitiesWarning } from '~/components/availabilities/DoctorAvailabilitiesWarning';
import TimePicker from '~/components/common/TimePicker';
import { AppointmentDuration } from '~/constants/appointments/appointmentDuration';
import { requiredValidation } from '~/services/validationConfig';
import { colors } from '~/utils/colors';
import { parseDateTime, TIME_FORMAT, LARAVEL_DATE_TIME, stringToDate } from '~/utils/dateAndTime';

const isTimeAfterNow = (dateTime: string) => {
  const dateTimeObj = stringToDate(dateTime);
  return dateTimeObj > new Date();
};
export interface OnDemandAvailabilitiesRef {
  submit: (onValid: (value: OnDemandAvailabilityForm) => Promise<void>, onInvalid?: () => void) => () => Promise<void>;
}

const DEFAULT_AVAILABILITY = {
  slot_duration_in_minutes: undefined,
  online_until: '',
  online_until_time: '',
  is_online: false,
};
interface Props {
  loading?: boolean;
  value?: OnDemandAvailabilityEventModel;
  saveOnDemandAvailability?: (onDemandData: OnDemandAvailabilityForm) => Promise<void>;
  autoSave?: boolean;
  disabled?: boolean;
}

export const OnDemandAvailabilities = forwardRef<OnDemandAvailabilitiesRef, Props>(
  ({ loading, value, saveOnDemandAvailability, autoSave, disabled }: Props, ref) => {
    useImperativeHandle(ref, () => ({
      submit: (onValid, onInvalid) =>
        handleSubmit((data) => {
          return onValid(data)
            .then(() => {
              reset({ ...getValues() });
            })
            .catch(() => resetField('is_online'));
        }, onInvalid),
    }));

    const { control, handleSubmit, reset, getValues, watch, resetField } = useForm<OnDemandAvailabilityForm>({
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues: {
        ...DEFAULT_AVAILABILITY,
      },
    });

    const values = watch();

    const save = handleSubmit(async (data) => {
      try {
        await saveOnDemandAvailability(data);
        reset({ ...getValues() });
      } catch {
        resetField('is_online');
      }
    });

    useEffect(() => {
      const currentValues = getValues();

      reset({
        id: value?.id ?? undefined,
        slot_duration_in_minutes:
          value?.slot_duration_in_minutes ||
          currentValues?.slot_duration_in_minutes ||
          DEFAULT_AVAILABILITY.slot_duration_in_minutes,
        online_until: value?.ends_at ?? DEFAULT_AVAILABILITY.online_until,
        online_until_time: value?.ends_at
          ? parseDateTime(value.ends_at, {
              parseFormat: LARAVEL_DATE_TIME,
              outputFormat: TIME_FORMAT,
            })
          : DEFAULT_AVAILABILITY.online_until_time,
        is_online: (value?.ends_at && isTimeAfterNow(value.ends_at)) || false,
      });
    }, [value]);

    return (
      <View style={{ display: 'flex', width: '100%' }}>
        <Controller
          name="is_online"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
            <View>
              <View style={styles.toggleOnline}>
                <H6NsRegularBlack>{labels.markAsOnline}</H6NsRegularBlack>
                {loading ? (
                  <LoadingActivityIndicator />
                ) : (
                  <ToggleSwitch
                    value={value}
                    disabled={disabled}
                    setValue={(val) => {
                      onChange(val);
                      if (autoSave) {
                        save();
                      }
                    }}
                    error={isSubmitted && !!error}
                  />
                )}
              </View>
              {error?.message ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
            </View>
          )}
        />
        <Controller
          name="slot_duration_in_minutes"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
            <View style={styles.border}>
              <Dropdown
                label="Appointment duration"
                value={value}
                setValue={(val) => {
                  onChange(val);
                  if (autoSave && values.is_online && values.online_until_time) {
                    save();
                  }
                }}
                list={AppointmentDuration}
                error={isSubmitted && !!error}
                errorMessage={isSubmitted && error?.message}
                disabled={disabled}
              />
            </View>
          )}
          rules={{
            required: values.is_online ? requiredValidation('Appointment Duration') : null,
          }}
        />
        <Controller
          name="online_until_time"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <TimePicker
              label="Online until"
              showMandatory
              value={value}
              onChangeValue={(val) => {
                onChange(val);
                if (autoSave && values.is_online && values.slot_duration_in_minutes) {
                  save();
                }
              }}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
              readonly={disabled}
            />
          )}
          rules={{
            required: values.is_online ? requiredValidation('Online until') : null,
          }}
        />

        {autoSave && values.is_online ? (
          <DoctorAvailabilitiesWarning consultationType={ConsultationTypeEnum.ON_DEMAND} style={[mt10]} />
        ) : null}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  toggleOnline: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  border: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: colors.lightGrey,
    borderBottomColor: colors.lightGrey,
    marginTop: 16,
    marginBottom: 8,
    paddingTop: 6,
    paddingBottom: 16,
  },
});
