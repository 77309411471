import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { usePharmacyChannels } from '../channels/usePharmacyChannels';
import { useReduxTablePagination } from '../common/useReduxTablePagination';

import { hasToken } from '~/api/axios/interceptors';
import { PrescriptionTypeEnum } from '~/api/models/consultations/constants/PrescriptionTypeEnum';
import { PrescriptionRequestStateEnum } from '~/api/models/consultations/enums/PrescriptionRequestStateEnum';
import { PrescriptionsPharmacyIndexFilter } from '~/api/models/consultations/filters/PrescriptionsIndexFilter';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { PrescriptionIndexResponse } from '~/api/models/consultations/responses/PrescriptionsIndexResponse';
import {
  PatientPrescriptionCollectionModel,
  PharmacyPrescriptionCollectionModel,
} from '~/api/models/pharmacy/models/PrescriptionCollectionModel';
import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import {
  PrescriptionRequestsIndexResponse,
  PrescriptionRequestsPaginatedIndexResponse,
} from '~/api/models/pharmacy/responses/PrescriptionRequestsIndexResponse';
import {
  apiPatientGetPrescriptionCollections,
  apiPharmacyGetPrescriptionCollections,
} from '~/api/services/consultations/prescriptions';
import { apiGetPrescriptionRequests, apiSearchPrescription, apiShowPrescription } from '~/api/services/pharmacy';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { RootState } from '~/redux/reducers';
import { SET_PRESCRIPTION_REQUESTS } from '~/redux/reducers/pharmacy/pharmacyPrescriptionsReducer';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import ReduxStore, { useAppDispatch } from '~/redux/store';
import { isNative, isPatientVersion, isPharmacyVersion } from '~/utils/buildConfig';
import { sameOrAfter } from '~/utils/dateAndTime';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const usePharmacyPrescriptionRequests = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sortDesc, setSortingDesc] = useState(true);
  const [search, setSearch] = useState('');

  const currentPharmacy = useSelector(currentPharmacySelector);
  const prescriptionsResponse = useSelector(
    (state: RootState) => state.pharmacyPrescriptionsReducer.prescriptionRequests
  );

  const dispatch = useAppDispatch();

  const channelEvent = () => {
    updatePrescriptions();
  };
  usePharmacyChannels({ handler: channelEvent });

  const updatePrescriptions = async () => {
    try {
      const loggedIn = await hasToken();
      if (!loggedIn) return;
      setLoading(true);
      const res = await apiGetPrescriptionRequests({
        limit: 20,
        page,
        sort: sortDesc ? '-created_at' : 'created_at',
        filter: { state: PrescriptionRequestStateEnum.PENDING, pharmacy_id: currentPharmacy?.pharmacy.id, search },
      });

      dispatch(SET_PRESCRIPTION_REQUESTS({ data: res.data }));
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  usePageFocus(() => {
    updatePrescriptions();
  }, [sortDesc, currentPharmacy?.pharmacy?.id, page]);

  return {
    prescriptions: prescriptionsResponse?.data ?? [],
    pagination: prescriptionsResponse?.meta,
    loading,
    sortDesc,
    setSortingDesc,
    onPageChanged: (pageInfo: TablePaginationInfo) => setPage(pageInfo.page),
    updatePrescriptions,
    search,
    setSearch,
  };
};

export const usePharmacyPrescriptionAwaitingCollection = () => {
  const [loading, setLoading] = useState(false);
  const [sortDesc, setSortingDesc] = useState(true);
  const { isMobile } = useBreakpoints();

  const [search, setSearch] = useState('');

  const currentPharmacy = useSelector(currentPharmacySelector);

  const { items, pagination, setPagination, resetTable } = useReduxTablePagination<
    any,
    PrescriptionRequestsPaginatedIndexResponse
  >({
    isInfiniteScroll: isMobile,
    tableName: 'awaitingCollection',
    watch: [sortDesc, currentPharmacy, search],
    getItems: async (params) => {
      setLoading(true);
      const res = await apiGetPrescriptionRequests({
        limit: params.limit,
        page: params.page,
        sort: sortDesc ? '-created_at' : 'created_at',
        filter: {
          search,
          state: PrescriptionRequestStateEnum.APPROVED,
          is_collected: false,
          pharmacy_id: currentPharmacy?.pharmacy.id,
        },
      });
      setLoading(false);
      return res;
    },
  });

  return {
    data: items ?? [],
    pagination,
    loading,
    sortDesc,
    setSortingDesc,
    onPageChanged: setPagination,
    updatePrescriptions: resetTable,
    setSearch,
    search,
  };
};

interface PrescriptionRequestsIndexProps {
  prescription: PrescriptionModel;
}
export const usePrescriptionRequestsIndex = ({ prescription }: PrescriptionRequestsIndexProps) => {
  // Used by Prescription Modal to determine state of modal

  const [loading, setLoading] = useState(false);
  const [prescriptionRequests, setPrescriptionRequests] = useState<PrescriptionRequestModel[]>();
  const [prescriptionCollections, setPrescriptionCollections] =
    useState<(PharmacyPrescriptionCollectionModel | PatientPrescriptionCollectionModel)[]>();

  const getPrescriptionRequests = async () => {
    try {
      setLoading(true);
      const res = await apiGetPrescriptionRequests<PrescriptionRequestsIndexResponse>({
        limit: -1,
        sort: '-created_at',
        filter: { prescription_no: prescription.reference_number },
      });

      setPrescriptionRequests(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const getPrescriptionRequestsAndCollections = async () => {
    try {
      setLoading(true);
      const prescriptionRequests = await apiGetPrescriptionRequests<PrescriptionRequestsIndexResponse>({
        limit: -1,
        sort: '-created_at',
        filter: { prescription_no: prescription.reference_number },
      });
      setPrescriptionRequests(prescriptionRequests.data);

      if (isPatientVersion()) {
        const res = await apiPatientGetPrescriptionCollections(prescription.id);
        setPrescriptionCollections(res.data);
      } else if (isPharmacyVersion()) {
        const pharmacy = ReduxStore.getState().pharmacyReducer.currentPharmacy;
        if (pharmacy?.pharmacy.id) {
          const res = await apiPharmacyGetPrescriptionCollections(prescription.id, pharmacy.pharmacy.id);
          setPrescriptionCollections(res.data);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  useEffect(() => {
    if ((isPatientVersion() || isPharmacyVersion()) && prescription.reference_number) {
      getPrescriptionRequestsAndCollections().catch(() => {});
    }
  }, [prescription?.reference_number]);

  const latestCollectedRequest = useMemo(
    () => prescriptionRequests?.find((request) => request.is_collected),
    [prescriptionRequests]
  );

  const latestNonRejectedRequest = useMemo(
    () =>
      prescriptionRequests?.length
        ? prescriptionRequests.find((item) => item.state !== PrescriptionRequestStateEnum.REJECTED)
        : undefined,
    [prescriptionRequests]
  );
  const latestRequest = useMemo(
    () => (prescriptionRequests?.length ? prescriptionRequests[0] : undefined),
    [prescriptionRequests]
  );

  const isUsed = useMemo(
    () => !!prescription.consumption_ends_at && sameOrAfter(new Date(), prescription.consumption_ends_at),
    [prescription]
  );

  const isAllCollected = useMemo(() => {
    if (prescription.type === PrescriptionTypeEnum.ONETIME) {
      return isUsed || !!latestNonRejectedRequest?.is_collected || !!prescriptionCollections?.length;
    } else {
      return isUsed;
    }
  }, [prescription, isUsed, latestNonRejectedRequest, prescriptionCollections]);

  return {
    loading,
    prescriptionRequests,
    latestRequest,
    latestUncollectedRequest: latestNonRejectedRequest?.is_collected ? undefined : latestNonRejectedRequest,
    latestCollectedRequest,
    isAllCollected,
    isUsed,
    prescriptionCollections,
    getPrescriptionRequests,
  };
};

export const usePrescriptionSearch = () => {
  const [loading, setLoading] = useState(false);
  const { isMobile } = useBreakpoints();
  const query = useRef('');

  const { items, pagination, setPagination, resetTable } = useReduxTablePagination<
    PrescriptionsPharmacyIndexFilter,
    PrescriptionIndexResponse
  >({
    limit: isNative() ? undefined : 3,
    isInfiniteScroll: isMobile,
    tableName: 'dispenseSearch',
    getItems: async (params) => {
      setLoading(true);
      const res = await apiSearchPrescription({
        limit: params?.limit || 30,
        page: params?.page || 1,
        filter: { search_by_reference_number: query.current },
      });
      setLoading(false);
      return res;
    },
  });

  const searchByPartialReference = (props: { query: string; page?: number; limit?: number }) => {
    query.current = props.query;
    resetTable();
  };

  const searchBySpecificReference = async ({ query }: { query: string }) => {
    try {
      setLoading(true);

      const res = await apiShowPrescription({ reference: query });
      setLoading(false);
      return res.data;
    } catch (e) {
      setLoading(false);
      if (e.status === 404) {
        return undefined;
      }
      throw e;
    }
  };

  return {
    searchByPartialReference,
    searchBySpecificReference,
    data: items,
    pagination,
    setPagination,
    loading,
  };
};
