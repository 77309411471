import React, { useMemo } from 'react';
import { View, StyleSheet, Image } from 'react-native';

import { H5NsSemiBoldSecondaryBlack, H6NsRegularBlack } from '../commonText';

import { PharmacyModel } from '~/api/models/pharmacy/models/PharmacyModel';
import { colors } from '~/utils/colors';

interface Props {
  pharmacy: PharmacyModel;
}

export const PharmacyPreviewComponent: React.FC<Props> = ({ pharmacy }) => {
  const imageSrc = useMemo(() => {
    return pharmacy?.logo?.url;
  }, [pharmacy]);
  return (
    <View style={styles.container}>
      <View style={[styles.imageContainer]}>
        {imageSrc ? <Image style={styles.image} source={{ uri: imageSrc }} /> : null}
      </View>
      <View style={{ marginLeft: 30 }}>
        <H5NsSemiBoldSecondaryBlack>{pharmacy.name}</H5NsSemiBoldSecondaryBlack>
        <H6NsRegularBlack>{}</H6NsRegularBlack>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    padding: 14,
    backgroundColor: colors.lightPurple,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageContainer: {
    width: 80,
    height: 80,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: colors.lightPurple2,
  },
  image: {
    height: '100%',
    width: '100%',
  },
});
