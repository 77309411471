import React, { useState } from 'react';
import { View } from 'react-native';
import DateTimePicker from 'react-native-date-picker';

import { parseDateTime, stringToDate, TIME_FORMAT } from '../../utils/dateAndTime';
import { FormErrorMessage } from '../commonText';
import FloatingDateTimeBox from '../floatingDateTimeBox';
import WebTextInput from '../inputs/WebTextInput';

import { isNative } from '~/utils/buildConfig';

interface Props {
  value: string | null;
  label: string;
  showMandatory?: boolean;
  onChangeValue: (time: string | null, event?: React.SyntheticEvent<EventTarget> | undefined) => void;
  error?: boolean;
  errorMessage?: string;
  readonly?: boolean;
  max?: string;
  min?: string | null;
}

// create a component
const TimePicker: React.FC<Props> = ({
  value,
  max,
  min,
  errorMessage,
  error,
  label,
  showMandatory,
  onChangeValue,
  readonly,
}): JSX.Element => {
  const [showModalInput, setShowModalInput] = useState(false);

  return (
    <View>
      {isNative() ? (
        <FloatingDateTimeBox
          label={label}
          onClick={() => setShowModalInput(true)}
          value={value || ''}
          showMandatory={showMandatory}
          disabled={readonly}
        />
      ) : (
        <WebTextInput
          label={label}
          inputType="time"
          min={min || ''}
          max={max || ''}
          value={value || '00:00'}
          onChangeValue={(value) => {
            onChangeValue(value || null);
          }}
          showMandatory={showMandatory}
          error={error}
          disabled={readonly}
        />
      )}

      {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}

      {isNative() && showModalInput ? (
        <DateTimePicker
          testID="timePicker"
          modal
          minimumDate={stringToDate(min, { parseFormat: TIME_FORMAT })}
          maximumDate={stringToDate(max, { parseFormat: TIME_FORMAT })}
          date={value ? stringToDate(value, { parseFormat: TIME_FORMAT }) : new Date()}
          open={showModalInput}
          mode="time"
          onCancel={() => {
            onChangeValue(null);
            setShowModalInput(false);
          }}
          onConfirm={(newDate) => {
            onChangeValue(newDate && parseDateTime(newDate, { outputFormat: TIME_FORMAT }));
            setShowModalInput(false);
          }}
        />
      ) : null}
    </View>
  );
};

export default TimePicker;
