import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { DependantStateColorMapping, DependantStateEnum } from '~/api/models/dependants/constants/DependantStateEnum';
import { DependantModel } from '~/api/models/dependants/models/DependantModel';
import { flex1, mh10 } from '~/common/commonStyles';
import { ElevatedCard } from '~/components/cards/ElevatedCard';
import { H6NsRegularBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { ForwardArrow } from '~/components/svgImages';
import { colors } from '~/utils/colors';
import { calculateAge } from '~/utils/dateAndTime';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { capitalize } from '~/utils/stringUtil';

interface Props {
  dependant: DependantModel;
  vertical?: boolean;
  onPress?: () => void;
  showAge?: boolean;
}

export const DependantCard: React.FC<Props> = ({ dependant, vertical, onPress, showAge }) => {
  const description = useMemo(() => {
    if (showAge && dependant?.account?.date_of_birth) {
      return `Age: ${calculateAge(dependant.account.date_of_birth)}`;
    } else {
      return dependant?.relation_type.name;
    }
  }, [showAge, dependant]);
  return (
    <ElevatedCard
      onPress={onPress}
      disabled={dependant.state !== DependantStateEnum.Approved}
      elevated
      style={vertical ? null : styles.selectableCard}>
      <View style={styles.card}>
        <ProfileImageComponent account={dependant.account} />
        <View style={[flex1, mh10]}>
          <H6NsSemiBoldBlack>{getAccountName(dependant.account)}</H6NsSemiBoldBlack>
          <H6NsRegularBlack>{description}</H6NsRegularBlack>
        </View>
        {dependant.state === DependantStateEnum.Approved ? (
          onPress ? (
            <View style={styles.arrowContainer}>
              <ForwardArrow width={5} height={10} color={colors.purple} />
            </View>
          ) : null
        ) : (
          <View style={styles.statusContainer}>
            <StatusBadge label={capitalize(dependant.state)} color={DependantStateColorMapping[dependant.state]} />
          </View>
        )}
      </View>
    </ElevatedCard>
  );
};

const styles = StyleSheet.create({
  column: {
    marginBottom: 15,
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  arrowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectableCard: {
    flexBasis: 350,
    flexGrow: 0,
    flexShrink: 1,
    marginRight: 15,
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: 10,
    height: '100%',
  },
});
