import React from 'react';

import { HealthProfileEditHeader } from './HealthProfileEditHeader';
import { PatientHistoryAccordionItem } from './PatientHistoryAccordionItem';
import { Column, Row } from '../../../../theme/components/grid';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { AccordionGroupProvider } from '~/components/buttons/Accordions/AccordionGroupProvider';
import { HealthProfileAllergiesProvider } from '~/providers/healthProfile/history/HealthProfileAllergiesProvider';
import { HealthProfileMedicalConditionsProvider } from '~/providers/healthProfile/history/HealthProfileMedicalConditionsProvider';
import { HealthProfileMedicationsProvider } from '~/providers/healthProfile/history/HealthProfileMedicationsProvider';
import { HealthProfileProceduresProvider } from '~/providers/healthProfile/history/HealthProfileProceduresProvider';
import { HealthProfileVaccinationsProvider } from '~/providers/healthProfile/history/HealthProfileVaccinationsProvider';

interface Props {
  locked?: boolean;
  onStartEdit?: () => void;
}

export const PatientHistory: React.FC<Props> = ({ locked, onStartEdit }) => {
  return (
    <>
      <Row>
        <Column width={720}>
          <HealthProfileEditHeader title="History" hideEdit={!locked} onEdit={onStartEdit} />
          <Row>
            <Column>
              <AccordionGroupProvider>
                <HealthProfileAllergiesProvider>
                  <PatientHistoryAccordionItem
                    title="Allergies"
                    type={ClinicalTermTypeEnum.ALLERGIES}
                    locked={locked}
                  />
                </HealthProfileAllergiesProvider>
                <HealthProfileMedicalConditionsProvider>
                  <PatientHistoryAccordionItem
                    title="Medical Conditions"
                    type={ClinicalTermTypeEnum.MEDICAL_CONDITIONS}
                    locked={locked}
                  />
                </HealthProfileMedicalConditionsProvider>
                <HealthProfileMedicationsProvider>
                  <PatientHistoryAccordionItem
                    title="Medications"
                    type={ClinicalTermTypeEnum.MEDICATIONS}
                    locked={locked}
                  />
                </HealthProfileMedicationsProvider>
                <HealthProfileProceduresProvider>
                  <PatientHistoryAccordionItem
                    title="Procedures"
                    type={ClinicalTermTypeEnum.PROCEDURES}
                    locked={locked}
                  />
                </HealthProfileProceduresProvider>
                <HealthProfileVaccinationsProvider>
                  <PatientHistoryAccordionItem
                    title="Vaccinations"
                    type={ClinicalTermTypeEnum.VACCINATIONS}
                    locked={locked}
                  />
                </HealthProfileVaccinationsProvider>
              </AccordionGroupProvider>
            </Column>
          </Row>
        </Column>
      </Row>
    </>
  );
};
