import React from 'react';
import { ScrollView, StyleProp, ViewStyle } from 'react-native';

import { PageTypesEnum } from '../../api/models/pages/constants/PageTypesEnum';
import { HTMLViewerComponent } from '../commonComponents';
import { LoadingActivityIndicator } from '../loading/LoadingActivityIndicator';

import { useGetHelpContent } from '~/api/hooks/HelpContentHook';

interface Props {
  contentType: PageTypesEnum;
  style?: StyleProp<ViewStyle>;
}

const PolicyContent: React.FC<Props> = ({ contentType, style }) => {
  const content = useGetHelpContent(contentType);

  return (
    <>
      {content ? (
        <ScrollView contentContainerStyle={style}>
          <HTMLViewerComponent content={content} />
        </ScrollView>
      ) : (
        <LoadingActivityIndicator />
      )}
    </>
  );
};

export default PolicyContent;
