import { TransactionTypeEnum } from '~/api/models/billingHistory/models/enums/TransactionTypeEnum';
import { LabelValuePair } from '~/common/types/LabelValuePair';
import { NUM_DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';

export const dateList = [
  { label: 'Last week', value: 'last-week' },
  { label: 'Last month', value: 'last-month' },
];

export const typeList = [
  { label: 'Consultations', value: TransactionTypeEnum.CONSULTATION },
  { label: 'Subscription', value: TransactionTypeEnum.STRIPE_SUBSCRIPTION },
  { label: 'Credit notes', value: TransactionTypeEnum.CREDIT_NOTE },
];

const MINIMUM_YEAR = 2023;

export const getYearsArray = () => {
  const checkboxList: LabelValuePair[] = [];
  const currentYear = new Date().getFullYear();
  const min = Math.max(MINIMUM_YEAR, new Date().getFullYear() - 4);

  for (let i = currentYear; i >= min; i--) {
    checkboxList.push({ label: `${i}`, value: i });
  }

  return checkboxList;
};

export const getLastWeek = () => {
  const now = new Date();
  const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
  return parseDateTime(lastWeek, { outputFormat: NUM_DATE_FORMAT });
};

export const getLastMonth = () => {
  const now = new Date();
  const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
  return parseDateTime(lastMonth, { outputFormat: NUM_DATE_FORMAT });
};
