import React from 'react';
import { StyleSheet, View } from 'react-native';

import { mt10 } from '~/common/commonStyles';
import { ContactUsComponent } from '~/components/help/contactUs/ContactUsComponent';
import { FaqComponent } from '~/components/help/faq/FaqComponent';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { whenAppType } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

export const HelpFaqWebPage: React.FC = () => {
  return (
    <NestedPageInternalLayout backTitle={labels.help} title={labels.faq}>
      <View style={styles.faqContainer}>
        {whenAppType({
          whenClinic: true,
          whenPharmacist: true,
          else: false,
        }) ? null : (
          <FaqComponent />
        )}
        <View style={mt10}>
          <ContactUsComponent />
        </View>
      </View>
    </NestedPageInternalLayout>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: colors.white,
  },
  faqContainer: {
    minHeight: 400,
    marginBottom: 20,
  },
});
