import React, { useEffect } from 'react';
import { Controller, useFormState, useWatch } from 'react-hook-form';
import { TouchableOpacity, View } from 'react-native';

import { alignSelfCenter, mb10, mt20, mt10, mb5, pb15 } from '../../../common/commonStyles';
import { H3TtmSemiBoldBlack, H5TtmSemiBoldInfo } from '../../../components/commonText';
import { IdentificationDocumentsEnum } from '../../../constants/documents';
import { ItemProps } from '../../../interfaces/generalProps';
import { NestedPageInternalLayout } from '../../../layouts/NestedPageInternalLayout';
import { genderList } from '../../../services/config';
import {
  validationSchema,
  requiredValidation,
  alphabetsValidation,
  alphabetCharactersValidation,
  maxLengthValidation,
} from '../../../services/validationConfig';
import { Column, Container, Grid, Row } from '../../../theme/components/grid';
import { DATE_FORMAT, parseDateTime } from '../../../utils/dateAndTime';
import { labels } from '../../../utils/labels';

import { useProfileUserDetails } from '~/api/hooks/profile/ProfileUserDetailsHook';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import FloatingInput from '~/components/floatingInputBox';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { SpecialisationSearchableList } from '~/components/inputs/searchable/SpecialisationSearchableList';
import { VerifyModal } from '~/components/modals/VerifyModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isDoctorVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { registrationLabels } from '~/utils/labels/registrationLabels';
import { screenName } from '~/utils/screenName';

export const ProfilePersonalInformation: React.FC = () => {
  const genderItems: ItemProps[] = genderList;

  const { isMobile } = useBreakpoints();
  const { onSubmit, resetFormValues, control, getUserDetails, saving } = useProfileUserDetails();

  const { registerModal, openModal } = useModalManager();

  const { isDirty } = useFormState({ control });
  const { document_type, mobile_number } = useWatch({ control });

  useEffect(() => {
    registerModal(ModalName.VerifyModal, VerifyModal);
  }, []);

  return (
    <NestedPageInternalLayout
      backTitle="Profile"
      title={labels.personalInformation}
      backLink={{ screen: screenName.ProfileScreen }}
      footer={
        <View style={mt10}>
          <CancelContinueFooter
            onCancel={resetFormValues}
            onConfirm={onSubmit}
            confirmTitle={labels.update}
            disabled={!isDirty || saving}
          />
        </View>
      }>
      <View nativeID="formWithDropdown">
        <Grid
          grid={{
            gutters: 16,
            noOuterGutter: true,
          }}>
          <Container alignSelf="flex-start" maxContainerWidths={760} style={pb15}>
            {isMobile ? null : (
              <Row style={mb10}>
                <Column>
                  <H3TtmSemiBoldBlack>Profile information</H3TtmSemiBoldBlack>
                </Column>
              </Row>
            )}
            <Row>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { value } }) => (
                    <FloatingInput disabled label={labels.emailAddress} value={value} />
                  )}
                />

                <TouchableOpacity
                  onPress={() => {
                    openModal(ModalName.VerifyModal, {
                      changeEmail: true,
                      onSuccess: getUserDetails,
                    });
                  }}
                  style={[mt10, mb5]}>
                  <H5TtmSemiBoldInfo style={[alignSelfCenter]}>{labels.changeEmailAddress}</H5TtmSemiBoldInfo>
                </TouchableOpacity>
              </Column>
              <Column span={{ xs: 12, md: 6 }}>
                <Row>
                  <Column span={4}>
                    <Controller
                      name="country_code"
                      control={control}
                      render={({ field: { value } }) => (
                        <FloatingInput disabled value={value ? `+${value}` : ''} label="Ext." />
                      )}
                    />
                  </Column>
                  <Column>
                    <Controller
                      name="mobile_number"
                      control={control}
                      render={({ field: { value } }) => (
                        <FloatingInput disabled value={value} keyboardType="numeric" label={labels.phoneNumber} />
                      )}
                    />
                  </Column>
                </Row>
                <TouchableOpacity
                  onPress={() => {
                    openModal(ModalName.VerifyModal, {
                      changeEmail: false,
                      onSuccess: getUserDetails,
                    });
                  }}
                  style={[mt10, mb5]}>
                  <H5TtmSemiBoldInfo style={[alignSelfCenter]}>
                    {mobile_number
                      ? registrationLabels.changedPhoneNumberQuestion
                      : registrationLabels.addPhoneNumberQuestion}
                  </H5TtmSemiBoldInfo>
                </TouchableOpacity>
              </Column>
            </Row>
            <Row>
              {isDoctorVersion() ? (
                <Column span={{ xs: 12, md: 4 }}>
                  <Controller
                    name="title"
                    control={control}
                    render={({
                      field: { value, onChange, onBlur },
                      fieldState: { error },
                      formState: { isSubmitted },
                    }) => (
                      <FloatingInput
                        maxLength={validationSchema.title.maxLength}
                        error={isSubmitted && !!error}
                        errorMessage={isSubmitted && error?.message}
                        value={value}
                        onChangeValue={onChange}
                        onBlur={onBlur}
                        label={labels.legalTitle}
                      />
                    )}
                    rules={{
                      validate: { alphabetsValidation },
                      maxLength: maxLengthValidation(validationSchema.title.maxLength),
                    }}
                  />
                </Column>
              ) : null}

              <Column span={{ xs: 12, md: isDoctorVersion() ? 4 : 6 }}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                    formState: { isSubmitted },
                  }) => (
                    <FloatingInput
                      showMandatory
                      maxLength={validationSchema.name.maxLength}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      value={value}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      label={labels.legalFirstName}
                    />
                  )}
                  rules={{
                    validate: { alphabetCharactersValidation },
                    required: requiredValidation(labels.legalFirstName),
                  }}
                />
              </Column>
              <Column span={{ xs: 12, md: isDoctorVersion() ? 4 : 6 }}>
                <Controller
                  name="last_name"
                  control={control}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                    formState: { isSubmitted },
                  }) => (
                    <FloatingInput
                      showMandatory
                      maxLength={validationSchema.lastName.maxLength}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      value={value}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      label={labels.legalLastName}
                    />
                  )}
                  rules={{
                    validate: { alphabetCharactersValidation },
                    required: requiredValidation(labels.legalLastName),
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="document_number"
                  control={control}
                  render={({ field: { value }, fieldState: { error }, formState: { isSubmitted } }) => (
                    <FloatingInput
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      value={value}
                      label={
                        document_type === IdentificationDocumentsEnum.PASSPORT ? labels.passport : labels.identityCard
                      }
                      disabled
                    />
                  )}
                />
              </Column>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="dob"
                  control={control}
                  render={({ field: { value } }) => (
                    <FloatingInput
                      disabled
                      value={parseDateTime(value, { outputFormat: DATE_FORMAT }) || ''}
                      label={labels.dobLabel}
                      placeholder="DD/MM/YY"
                    />
                  )}
                />
              </Column>
            </Row>
            <Row>
              <Column span={{ xs: 12, md: 6 }}>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                    <Dropdown
                      label={labels.gender}
                      value={value}
                      setValue={onChange}
                      list={genderItems.map((item) => ({ label: item.name, value: item.id }))}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                    />
                  )}
                />
              </Column>
            </Row>
            {isDoctorVersion() ? (
              <>
                <Row>
                  <Column span={{ xs: 12, md: 6 }}>
                    <Controller
                      name="biography"
                      control={control}
                      render={({
                        field: { value, onChange, onBlur },
                        fieldState: { error },
                        formState: { isSubmitted },
                      }) => (
                        <FloatingInput
                          label={labels.biography}
                          height={120}
                          multiline
                          value={value}
                          onChangeValue={onChange}
                          onBlur={onBlur}
                          error={isSubmitted && !!error}
                          errorMessage={isSubmitted && error?.message}
                          maxLength={validationSchema.string.maxLength}
                        />
                      )}
                      rules={{
                        maxLength: maxLengthValidation(validationSchema.string.maxLength),
                        required: requiredValidation(labels.biography),
                      }}
                    />
                  </Column>
                </Row>
                <Row style={[mb10, mt20]}>
                  <Column>
                    <H3TtmSemiBoldBlack>Medical profession details</H3TtmSemiBoldBlack>
                  </Column>
                </Row>
                <Row>
                  <Column span={{ xs: 12, md: 6 }}>
                    <Controller
                      name="registrationNo"
                      control={control}
                      render={({ field: { value } }) => (
                        <FloatingInput
                          label={labels.medicalCouncilRegistrationNumber}
                          editable={false}
                          value={value}
                          disabled
                        />
                      )}
                    />
                  </Column>
                  <Column span={{ xs: 12, md: 6 }}>
                    <Controller
                      name="yearOfWarrant"
                      control={control}
                      render={({ field: { value } }) => (
                        <FloatingInput
                          label={labels.yearOfWarrant}
                          editable={false}
                          value={value}
                          disabled
                          keyboardType="numeric"
                        />
                      )}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column span={{ xs: 12, md: 6 }}>
                    <Controller
                      name="areaOfSpecialisation"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                        <SpecialisationSearchableList
                          values={value}
                          setValues={onChange}
                          error={isSubmitted && !!error?.message}
                          errorMessage={isSubmitted && error?.message}
                        />
                      )}
                      rules={{
                        required: requiredValidation(labels.areaOfSpecialisation),
                      }}
                    />
                  </Column>
                </Row>
              </>
            ) : null}
          </Container>
        </Grid>
      </View>
    </NestedPageInternalLayout>
  );
};
