import { useNavigation } from '@react-navigation/native';
import moment from 'moment';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { NavType } from '~/navigation/types';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { currentDateTime, DATE_FORMAT, parseDateTime, TIME_FORMAT } from '~/utils/dateAndTime';
import { screenName } from '~/utils/screenName';

export function useCalendarAppointmentSnackbar() {
  const { showSnackbar } = useSnackbarManager();
  const { navigate } = useNavigation<NavType>();

  const showAppointmentSnackbar = async (consultation: ConsultationModel) => {
    try {
      const tomorrow = moment().add(1, 'days').format(DATE_FORMAT);
      const fetchedDate = parseDateTime(consultation.start_at, {
        outputFormat: DATE_FORMAT,
      });
      const currentDate = parseDateTime(currentDateTime(), {
        outputFormat: DATE_FORMAT,
      });

      const keyRef = 'new_appointment.' + consultation.id;
      if (fetchedDate === currentDate) {
        showSnackbar(
          `You have a new appointment Today at ${parseDateTime(consultation.start_at, {
            outputFormat: TIME_FORMAT,
          })}`,
          { onPress: () => navigate(screenName.CalendarAppointmentsShow, { consultationId: consultation.id }), keyRef }
        );
      } else if (fetchedDate === tomorrow) {
        showSnackbar(
          `You have a new appointment Tomorrow at ${parseDateTime(consultation.start_at, {
            outputFormat: TIME_FORMAT,
          })}`,
          { onPress: () => navigate(screenName.CalendarAppointmentsShow, { consultationId: consultation.id }), keyRef }
        );
      } else {
        showSnackbar(
          `You have a new appointment on ${parseDateTime(consultation.start_at, {
            outputFormat: DATE_FORMAT,
          })} at ${parseDateTime(consultation.start_at, { outputFormat: TIME_FORMAT })}`,
          { onPress: () => navigate(screenName.CalendarAppointmentsShow, { consultationId: consultation.id }), keyRef }
        );
      }
    } catch (e) {
      ErrorAlert(e);
    }
  };

  return { showAppointmentSnackbar };
}
