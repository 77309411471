import React from 'react';

import { Call } from './Call';
import { AppointmentShowPageLayout } from '../../appointments/AppointmentShowPageLayout';

import { flex1 } from '~/common/commonStyles';
import { Column, Row } from '~/theme/components/grid';

export const PatientCall: React.FC = () => {
  return (
    <AppointmentShowPageLayout fluid noPadding flexContent>
      <Row style={flex1}>
        <Column style={{ maxHeight: '100%', height: '100%' }}>
          <Call />
        </Column>
      </Row>
    </AppointmentShowPageLayout>
  );
};
