import { FlashList } from '@shopify/flash-list';
import React, { useEffect, useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Divider } from 'react-native-paper';

import { RequestListMobileItem } from './RequestListMobileItem';
import { H3TtmSemiBoldBlack } from '../../commonText';

import { useApprovalRequestsList } from '~/api/hooks/patientDataHandling/useApprovalRequestsList';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { mv10 } from '~/common/commonStyles';
import { LinkButton } from '~/components/buttons/LinkButton';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';
import { usePolling } from '~/api/hooks/Polling';

interface Props {
  style?: StyleProp<ViewStyle>;
}
export const RequestsListMobile: React.FC<Props> = ({ style }) => {
  const { approvalRequests, getRequests } = useApprovalRequestsList();
  const { openModal } = useModalManager();
  const { startPolling, stopPolling } = usePolling(getRequests, 60000);

  useEffect(() => {
    startPolling({ immediate: true });
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, getRequests);
    return () => {
      removeListener();
      stopPolling();
    };
  }, []);

  const slicedRequests = useMemo(() => {
    return approvalRequests?.slice(0, 4) || [];
  }, [approvalRequests]);

  const rowPress = (consultation: ConsultationModel) => {
    openModal(ModalName.ApprovalRequest, {
      consultation,
      onReady: () => {
        getRequests();
      },
    });
  };

  if (!approvalRequests?.length) return null;
  return (
    <View style={[style]}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <H3TtmSemiBoldBlack style={{ marginBottom: 5 }}>{labels.requests}</H3TtmSemiBoldBlack>
        <LinkButton to={{ screen: screenName.Requests } as never} title="VIEW ALL" />
      </View>
      <View style={mv10}>
        <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
      </View>
      <FlashList
        data={slicedRequests}
        keyExtractor={(item) => `${item.id}`}
        estimatedItemSize={75}
        ItemSeparatorComponent={() => (
          <View style={mv10}>
            <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
          </View>
        )}
        renderItem={({ item }) => <RequestListMobileItem request={item} onPress={rowPress} />}
      />
      <View style={mv10}>
        <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
      </View>
    </View>
  );
};
