import { FlashList } from '@shopify/flash-list';
import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput as RNTextInput,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { TextInput } from 'react-native-paper';

import { SelectedItemButton } from '../../buttons/SelectedItemButton';
import { InputProps } from '../FloatingInput';

import { p10 } from '~/common/commonStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { colors } from '~/utils/colors';

interface Props {
  values?: any[];
  list: any[];
  labelTitle?: string;
  valueTitle?: string;
  disabled?: boolean;
  hideInput?: boolean;
  singleSelect?: boolean;
  inputOptions?: Partial<InputProps>;
  loading?: boolean;
  style?: StyleProp<ViewStyle>;
  noResultsText?: string;
  error?: boolean;
  showMandatory?: boolean;
  errorMessage?: string;
  listHeader?: React.ReactNode;
  renderItem?: (props: any) => React.ReactNode;
  allowFreeText?: boolean;
  onSelect: (value: any, item: any) => void;
  onRemove?: (value: any, item: any) => void;
  onSearchChange?: (value: string) => void | Promise<void>;
  onBlur?: () => void;
}

export const SearchableList: React.FC<Props> = ({
  labelTitle = 'label',
  valueTitle = 'value',
  values,
  list,
  disabled,
  hideInput,
  inputOptions,
  loading,
  style,
  noResultsText = 'No results for your search',
  error,
  errorMessage,
  showMandatory,
  renderItem,
  allowFreeText,
  onSelect,
  onSearchChange,
  onRemove,
  onBlur,
  listHeader,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [inFocus, setInFocus] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [searchWasEmpty, setSearchWasEmpty] = useState(true);
  const [searchTimeout, setSearchTimeout] = useState<ReturnType<typeof setTimeout>>();
  const dropDownBottom = useRef(null);

  const emitSearchChange = (value: string) => {
    if (!value) {
      setSearchWasEmpty(true);
    } else {
      setSearchWasEmpty(false);
    }
    const response = onSearchChange(value);
    if (response) {
      setLocalLoading(true);
      response.finally(() => {
        setLocalLoading(false);
      });
    }
  };

  const changeSearch = (value: string) => {
    setSearchValue(value);
    if (onSearchChange) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      const timeout = setTimeout(() => {
        emitSearchChange(value);
      }, 500);

      setSearchTimeout(timeout);
    }
  };

  const selectItem = (value: any, item: any) => {
    onSelect(value, item);
    setSearchValue('');
  };

  const selectFreeText = () => {
    const value = searchValue;
    onSelect(value, { [labelTitle]: value, [valueTitle]: value });
    setSearchValue('');
  };

  const scrollToBottom = () => {
    if (dropDownBottom?.current?.scrollIntoView) {
      dropDownBottom?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (inFocus && !searchWasEmpty && list?.length && scrollToBottom) scrollToBottom();
  }, [list, scrollToBottom]);

  const noResults = !searchWasEmpty && !list?.length && !(loading || localLoading);
  const searching = searchWasEmpty || loading || localLoading;
  const showInlineLoading = searchValue && !!list?.length && (loading || localLoading);
  return (
    <View style={style}>
      <View ref={dropDownBottom}>
        <View style={{ position: 'relative' }}>
          <FloatingInput
            {...inputOptions}
            style={{ lineHeight: 22 }}
            value={searchValue}
            onChangeValue={changeSearch}
            disabled={disabled}
            error={error}
            errorMessage={errorMessage}
            outlineColor={hideInput ? colors.transparent : null}
            onFocus={() => setInFocus(true)}
            onBlur={() => {
              setInFocus(false);
              if (onBlur) onBlur();
            }}
            onSubmitEditing={() => {
              if (searchValue) {
                if (allowFreeText) selectFreeText();
                else if (list.length >= 1) selectItem(list[0][valueTitle], list[0]);
              }
            }}
            right={
              searchValue && allowFreeText ? (
                <TextInput.Icon
                  size={18}
                  style={{
                    borderRadius: 4,
                    borderWidth: 1,
                    borderColor: colors.purple,
                    opacity: showInlineLoading ? 0 : undefined,
                  }}
                  icon="arrow-left-bottom"
                  color={colors.purple}
                  onPress={() => selectFreeText()}
                />
              ) : searchValue && !loading && !localLoading ? (
                <TextInput.Icon size={18} icon="close" color={colors.lightGrey} onPress={() => setSearchValue('')} />
              ) : null
            }
            render={(props) => {
              return (
                <>
                  {values?.length ? (
                    <View style={[styles.selectedContainer, hideInput ? { padding: 0 } : null]}>
                      {values.map((value) => (
                        <SelectedItemButton
                          key={value[valueTitle]}
                          value={value}
                          labelTitle={labelTitle}
                          style={{ marginRight: 5, maxWidth: '100%', marginBottom: 5 }}
                          remove={
                            !disabled && onRemove
                              ? () => {
                                  onRemove(value[valueTitle], value);
                                }
                              : null
                          }
                        />
                      ))}
                    </View>
                  ) : null}
                  {hideInput ? null : (
                    <RNTextInput
                      {...props}
                      placeholder={props.placeholder + (showMandatory ? '*' : '')}
                      style={[props.style, styles.textInputStyling]}
                    />
                  )}
                </>
              );
            }}
          />
          {showInlineLoading ? (
            <View style={styles.inputLoading}>
              <ActivityIndicator size={20} />
            </View>
          ) : null}
        </View>

        {searchValue && !disabled ? (
          <View
            style={[
              styles.resultsContainer,
              noResults || searching ? styles.resultsContainerEmptyOrSearching : null,
              list?.length ? styles.resultsContainerWithOptions : null,
            ]}>
            {listHeader}
            <FlashList
              nestedScrollEnabled
              keyExtractor={(item) => item[valueTitle]}
              estimatedItemSize={100}
              scrollEnabled={!(noResults || searching) || !!list?.length}
              ListEmptyComponent={
                noResults ? (
                  <Text style={p10}>{noResultsText}</Text>
                ) : searching ? (
                  <View style={p10}>
                    <ActivityIndicator size={20} />
                  </View>
                ) : null
              }
              renderItem={(data) => {
                return (
                  <TouchableOpacity
                    style={styles.resultItem}
                    onPress={() => {
                      selectItem(data.item[valueTitle], data.item);
                    }}>
                    {renderItem ? renderItem(data.item) : <H6NsRegularBlack>{data.item[labelTitle]}</H6NsRegularBlack>}
                  </TouchableOpacity>
                );
              }}
              data={list}
            />
          </View>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  selectedContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 8,
    paddingHorizontal: 12,
    flexWrap: 'wrap',
  },
  resultsContainer: {
    maxHeight: 240,
    borderColor: colors.lightGrey,
    borderWidth: 1,
    marginTop: 14,
    borderRadius: 6,
  },
  resultsContainerEmptyOrSearching: {
    height: 40,
  },
  resultsContainerWithOptions: {
    height: 240,
  },
  resultItem: {
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
  inputLoading: {
    position: 'absolute',
    right: 10,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 8,
  },
  textInputStyling: {
    color: colors.purpleGrey,
    fontFamily: 'NotoSans',
    fontSize: 14,
  },
});
