import React, { useState } from 'react';

import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { AvailabilitiesPageStateEnum } from '~/api/models/preferences/enums/AvailabilitiesPageStateEnum';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { ScheduledAppointmentsAvailabilities } from '~/components/availabilities/scheduledAppointment/ScheduledAppointmentsAvailabilities';
import { KeyboardAScrollView } from '~/components/commonViews';
import { CalendarLayout } from '~/components/doctor/calendar/CalendarLayout';

export const DoctorScheduledAppointmentsScreen: React.FC = () => {
  const [pageState, setPageState] = useState<AvailabilitiesPageStateEnum>(AvailabilitiesPageStateEnum.VIEW);
  const { isFeatureDisabled } = useFeatureAccess();

  return (
    <CalendarLayout>
      <KeyboardAScrollView>
        <ScheduledAppointmentsAvailabilities
          pageState={pageState}
          setPageState={setPageState}
          disabled={isFeatureDisabled(FeatureAccessEnum.CONSULTATION_TYPES)}
        />
      </KeyboardAScrollView>
    </CalendarLayout>
  );
};
