import { StyleSheet } from 'react-native';

import { colors } from '../utils/colors';

const styles = StyleSheet.create({
  roundedBorder: {
    borderWidth: 1,
    borderRadius: 6,
    borderColor: colors.lightPurple2,
    padding: 10,
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: colors.lightPurple2,
    marginVertical: 8,
  },
});

export const { roundedBorder, separator } = styles;
