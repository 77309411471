export const patientLabels = {
  insuranceCompany: 'Insurance Company',
  insurancePolicy: 'Insurance Policy',
  appointments: 'Appointments',
  bookAppointment: 'Book an appointment',
  chooseConsultation: 'Choose type of consultation',
  chooseConsultationSubtitle: 'Select one of the following types of consultations.',
  onlineConsultations: 'Online consultations',
  doctors: 'Doctors',

  deleteAccount: {
    exportDataInProgress: 'Exporting Data',
    exportDataInProgressMessage: 'Exporting your details, this may take a while. We will notify you when it is done.',
  },
};
