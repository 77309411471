import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Switch, SwitchProps } from 'react-native-paper';

import { FormErrorMessage } from '../commonText';

import { colors } from '~/utils/colors';

interface Props {
  value: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  onToggleFunction?: () => void;
  switchProps?: Omit<SwitchProps, 'theme'>;
}

export const ToggleSwitch: React.FC<Props> = ({
  value,
  setValue,
  disabled,
  error,
  errorMessage,
  onToggleFunction,
  switchProps,
}) => {
  const onToggleSwitch = () => {
    if (onToggleFunction) {
      onToggleFunction();
    } else {
      setValue(!value);
    }
  };

  return (
    <View style={styles.container}>
      <Switch value={value} color={colors.info} onValueChange={onToggleSwitch} disabled={disabled} {...switchProps} />
      {error && errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingHorizontal: 4,
  },
});
