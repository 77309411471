import React, { useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ModalAlertTypeEnum } from './ModalEnums';
import { modalStyles } from './ModalStyling';
import { successOrErrorPopup } from '../../common/commonMethods';
import { isWeb } from '../../utils/buildConfig';

interface Props {
  show: boolean;
}
export const ModalBackground: React.FC<Props> = ({ show }) => {
  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const [visible, setVisible] = useState(false);
  const [animating, setAnimating] = useState(false);
  const safeArea = useSafeAreaInsets();

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: visible ? 1 : 0,
      duration: 300,
      useNativeDriver: !isWeb(),
    }).start(() => {
      setAnimating(false);
    });
  }, [fadeAnim, visible]);

  useEffect(() => {
    if (!!show !== visible) {
      setVisible(!visible);
      setAnimating(true);
    }
  }, [show]);

  if (!visible && !animating) return null;
  return (
    <Animated.View
      style={[
        modalStyles.modalOverlay,
        {
          opacity: fadeAnim,
          marginBottom: -safeArea.bottom,
          marginTop: -safeArea.top,
          marginLeft: -safeArea.left,
          marginRight: -safeArea.right,
        },
      ]}
    />
  );
};
