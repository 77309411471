import React from 'react';
import { StyleSheet } from 'react-native';

import { ExportTypesEnum } from '~/api/models/common/constants/ExportTypesEnum';
import { apiExportData } from '~/api/services/exports';
import { InprogressAlert } from '~/common/commonMethods';
import { mb15, mb5, mt15 } from '~/common/commonStyles';
import { OutlineButton } from '~/components/commonButton';
import { H4TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { Column, Container, Row } from '~/theme/components/grid';
import { appName } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { transformLabel } from '~/utils/labelUtil';
import { labels } from '~/utils/labels';
import { accountDeletionLabels } from '~/utils/labels/accountDeletionLabels';
import { patientLabels } from '~/utils/labels/patient';

interface Props {
  exportText?: boolean;
  isGuest?: boolean;
}

export const PatientExportAccountDataComponent: React.FC<Props> = ({ exportText, isGuest }) => {
  const exportInformation = () => {
    apiExportData(ExportTypesEnum.INFORMATION_EXPORT)
      .then(() => {
        InprogressAlert([patientLabels.deleteAccount.exportDataInProgressMessage], {
          title: patientLabels.deleteAccount.exportDataInProgress,
        });
      })
      .catch(ErrorAlert);
  };

  const patientExportOptions = [
    {
      title: accountDeletionLabels.exportConsultationReportsTitle,
      message: accountDeletionLabels[exportText ? 'export' : 'delete'].exportConsultationReportsMessage,
    },
    {
      title: accountDeletionLabels.exportHealthProfileTitle,
      message: accountDeletionLabels[exportText ? 'export' : 'delete'].exportHealthProfileMessage,
    },
    {
      title: accountDeletionLabels.exportPersonalInformationTitle,
      message: accountDeletionLabels[exportText ? 'export' : 'delete'].exportPersonalInformationMessage,
    },
  ];

  return (
    <Container alignSelf="flex-start" style={mb15}>
      <H6NsRegularBlack>
        {transformLabel(accountDeletionLabels[exportText ? 'export' : 'delete'].subtitle, {
          appName,
        })}
      </H6NsRegularBlack>
      <Column style={[mt15, styles.bordered]}>
        {patientExportOptions.map((item, index) => (
          <>
            <Row style={mb5} key={`${index}_title`}>
              <Column>
                <H4TtmSemiBoldBlack>{item.title}</H4TtmSemiBoldBlack>
              </Column>
            </Row>
            <Row style={mb15} key={`${index}_message`}>
              <Column>
                <H6NsRegularBlack>{item.message}</H6NsRegularBlack>
              </Column>
            </Row>
          </>
        ))}
        {isGuest ? null : (
          <OutlineButton
            style={{ maxWidth: 120, alignSelf: 'flex-end' }}
            label={labels.export}
            funCallback={exportInformation}
          />
        )}
      </Column>
    </Container>
  );
};

const styles = StyleSheet.create({
  bordered: {
    borderWidth: 1,
    borderColor: colors.lightPurple,
    borderRadius: 8,
    padding: 12,
  },
});
