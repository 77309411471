import React from 'react';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { HealthProfileMedicationsMobile } from '~/pages/common/healthProfile/mobile/history/HealthProfileMedicationsMobile';
import { HealthProfileDataProvider } from '~/providers/healthProfile/HealthProfileDataProviders';
import { screenName } from '~/utils/screenName';

export const MyHealthProfileMedicationsMobile: React.FC = () => {
  const { userDetails } = useUserDetails();

  return (
    <HealthProfileDataProvider accountId={userDetails?.account.id}>
      <HealthProfileMedicationsMobile backLink={{ screen: screenName.HealthProfile }} />
    </HealthProfileDataProvider>
  );
};
