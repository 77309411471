import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { AvailabilitiesButton } from './AvailabilitiesButton';
import { useUserDetailsInfo } from '../../../../api/hooks/accounts/UserDetails';
import { flex1 } from '../../../../common/commonStyles';
import { H2TtmSemiBoldBlack } from '../../../commonText';

import { useMedicalRegistration } from '~/api/hooks/accounts/MedicalRegistration';
import { useVerificationStatus } from '~/api/hooks/accounts/VerificationStatus';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { DoctorClinicName } from '~/components/patient/doctorListing/components/DoctorClinicName';
import { Column, Row } from '~/theme/components/grid';
import { DigimedMilestones, digimedMilestoneEnabled } from '~/utils/milestoneUtil';
import { isPharmacyVersion } from '~/utils/buildConfig';

export const DashboardWebHeader: React.FC = (): JSX.Element => {
  const [showAvailabilities, setShowAvailabilities] = useState(false);
  const { medicalRegistration } = useMedicalRegistration();
  const { fullName, userDetails } = useUserDetailsInfo();
  const { approved } = useVerificationStatus();
  const { isFeatureActive } = useFeatureAccess();

  useEffect(() => {
    if (isPharmacyVersion()) {
      setShowAvailabilities(false);
    } else {
      isFeatureActive(FeatureAccessEnum.CONSULTATION_TYPES).then((active) => {
        setShowAvailabilities(active);
      });
    }
  }, []);

  return (
    <Row>
      <Column>
        <View style={styles.container}>
          <View style={[flex1]}>
            <H2TtmSemiBoldBlack style={{ lineHeight: '120%' }} numberOfLines={2}>
              {fullName}
            </H2TtmSemiBoldBlack>
            <DoctorClinicName account={userDetails?.account} fallback={medicalRegistration?.number} large />
          </View>
        </View>
      </Column>
      {showAvailabilities && approved && digimedMilestoneEnabled(DigimedMilestones.DoctorPatientApps) ? (
        <Column style={styles.buttonContainer}>
          <View>
            <AvailabilitiesButton />
          </View>
        </Column>
      ) : null}
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
