import { doctorLabels } from './doctor';
import { patientLabels } from './patient';
import { pharmacyLabels } from './pharmacy';

export const labels = {
  welcomeToDigimed: 'Welcome to Digimed',
  watermark: '© Digimed',
  onboardingMessage1: 'A guided introduction to the app before setting up some initial preferences.',
  onboardingMessageHint: 'Up to 3 screens with description of the app.',
  accouontCreateMessage: 'Get started by creating an account.',
  accountDisabled: 'Your account is temporarily disabled.',
  accountDisabledMessage: 'Kindly wait a few seconds to log in again.',
  next: 'NEXT',
  register: 'REGISTER',
  startRegistration: 'Start Registration',
  alreadyIHaveAnAccount: 'Already have an account?',
  dontHaveAnAccount: "Don't have an account?",
  signIn: 'SIGN IN',
  enter6DigitOtp: 'Enter 6-digit code sent to your phone.',
  enter6DigitOtpPhoneSubtitle: 'Please enter the 6-digit code sent to your phone in order to verify your phone number.',
  enter6DigitOtpToEmail: 'Enter 6-digit code sent to your email.',
  enter6DigitOtpEmailSubtitle:
    'Please enter the 6-digit code sent to your e-mail in order to verify your e-mail address.',
  weSentTheOtpTo: "We've sent it to the number ",
  weSentTheOtpToEmail: "We've sent it to ",
  getOtpFromAuthenticator: 'Enter the 6-digit code from your Authenticator app.',
  weSentSixDigitCode: "We've sent a 6-digit code to ",
  weSentSixDigitOneTimePassword: "We've sent a 6-digit One Time Password!",
  enter: 'ENTER',
  resendCode: 'RESEND CODE',
  emailAddress: 'Email Address',
  resetPasscode: 'Reset Passcode',

  enterYourPrimaryEmail: "What's your primary email?",
  emailScreenSubMessage: 'Please enter the e-mail address that you will associate with your Digimed account.',
  verifyEmailAddress: 'Verify your new email address',
  verifyPhoneNumber: 'Verify your new phone number',
  uploadIdentification: 'Upload Identification',
  uploadPatientDetails: 'Upload Patient Details',
  uploadExcelSheet1: 'Easily import your patient details into Digimed using our Excel Import functionality.',
  uploadExcelSheetDownloadTemplate:
    'Download our Excel template and start filling it in to upload multiple patient information',

  selectDoucumentToScan: 'Select the type of document you wish to scan',
  passport: 'Passport',
  identityCard: 'Identity card',
  passportAndIdcard: 'ID card / Passport number',
  verify: 'VERIFY',

  goBack: 'GO BACK',
  stayOnline: 'STAY ONLINE',
  offlineAndKeepAppointments: 'GO OFFLINE & KEEP APPOINTMENTS',
  offlineAndDeleteAppointments: 'GO OFFLINE & DELETE APPOINTMENTS',
  onDemandWaitingRoom: 'There are people in the waiting room for On Demand consultations',
  idVerified: 'Your Id  has been verified.',
  somethingwentWrong: 'Sorry something went wrong.',
  somethingwentWrongMessage:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',

  personalDetailsScreenTitle: 'What are your personal details?',
  personalDetailsScreenMessage: 'Please provide your personal information',
  continue: 'CONTINUE',
  confirm: 'Confirm',
  changesLost: 'Any changes will be lost',
  sureGoBack: 'Are you sure you want to go back?',
  changesWillBeLost: 'Any changes will be lost and should be saved before leaving',

  legalTitle: 'Title (e.g. Dr)',
  legalFirstName: 'Legal first name',
  legalLastName: 'Legal last name',
  dobLabel: 'Date of Birth ',
  gender: 'Gender ',
  homeAddressScreenTitle: "What's your address?",
  homeAddressScreenMessage: 'Please provide your residential information.',

  postCode: 'Postcode',
  address: 'Address',
  sickLeaveCertificate: 'Sick Leave Certificate',
  sickLeaveCertificateRequired: 'Sick Leave Certificate Required',
  country: 'Country',

  doctorVerification: 'Doctor verification',
  doctorVerificationMessage: 'Please provide the details below.',
  warrantNumber: 'Warrant number',
  uploadDocument: 'Upload document',
  uploadDocuments: 'Upload documents',
  uploadSignature: ' Upload signature',
  areaOfSpecialisation: 'Area of specialisation',
  addAnotherAreaOfSpecialisation: 'Add another area of specialisation',

  changeEmailAddress: 'Changed your email address?',
  registrationStepperSubMessage1: 'The sections marked with [',
  registrationStepperSubMessage2: '] are required to complete the registration process.',
  registrationStepperTitle: 'You’re almost done',
  uploadProfileScreenTitle: 'Personalise your profile',
  uploadProfileScreenSubMessage: 'Add a profile photo',
  addBiographyText: 'Add biography',
  writeSomething: 'Write something about you',
  uploadPhoto: 'Upload a photo',
  takePhoto: 'Take a photo',
  notNow: 'NOT NOW',
  skip: 'Skip',
  skipFeedback: 'Skip feedback',
  agreeTC: 'By creating a passcode you agree with our',
  TandCText: 'Terms & Conditions',
  updateProfile: 'Health profile was successfully updated',
  addHealthProfile: 'Add more details to your profile to create your health profile.',
  typeOfConsultationTitle: 'What type of consultations would you like to give?',
  typeOfConsultationSubMessage:
    'You can select more than one from the below. This can be edited from your profile at anytime.',
  learnMore: 'Learn more',
  save: 'SAVE',
  costPerConsultationText: 'Cost per consultation type',
  standardCostPerConsultation:
    'What is the maximum cost per consultation? This can be edited later on from the preferences section.',
  standardCostPerConsultationSubMsg:
    'At the end of each consultation, you will be asked to confirm your fee for that consultation. The ‘maximum fee’ you enter below will be blocked on the patient’s card. If you choose to charge less than the maximum (example in case of a simple follow-up), the difference will be refunded to the patient.',
  amount: 'AMOUNT',
  addAmount: 'Add amount',
  consultationReport: 'Consultation report',

  gotIt: 'GOT IT',
  awaitingApproval: 'Awaiting approval',
  awaitingConfirmation: 'Awaiting confirmation',
  awaitingApprovalSubMessage1:
    'Our team is currently reviewing your profile account. Please allow 2-3 working days for us to review your account.',
  awaitingApprovalSubMessage2: 'You will received a notification once this has been approved.',
  preferences: 'Preferences',
  preferencesSubMessage: 'This can be edited later on from the account settings.',

  preferredOnlineConsultationHeading: 'Which are your preferred means of online consultation?',
  preferredOnlineConsultationSubMessage:
    'You can select more than one from the below. These will show up as options for the patient when booking an appointment.',
  preferredOnlineConsultationLanguageSelect:
    'Choose your preferred languages for Audio, Video calls and chat. You can select more than one option.',
  preferredLanguage: 'Preferred language',
  availabilities: 'Availabilities',
  availabilitiesSubMessage: [
    'Set availabilities for each type of consultation.',
    `Don't worry, you can edit or complete this information later.\nYour choices are not final, and you can always update them whenever you like.`,
  ],
  availabilitiesTitle: 'Availability for scheduled appointments',
  finish: 'FINISH',
  connect: 'CONNECT',
  connectWithStripe: 'Connect with Stripe',
  connectWithStripeSubMessage1: 'Click the “Connect” button to link to your Stripe Account to your Digimed Account.',
  connectWithStripeSubMessage2: 'Stripe is a Payment Service for accepting credit card payments. Click ',
  connectWithStripeSubMessage3: 'here',
  connectWithStripeSubMessage4: ' to learn more about Stripe.',
  youAreNotAvailable: 'You are not available at the moment.',
  youAreAvailable: 'You are currently available.',
  doctorOnDemandSubMessageNotAvailable:
    'You are not visible for Doctors available now. Mark yourself as available to receive new requests. Your status will change to ‘offline’ if you do not reply to a request.',
  doctorOnDemandSubMessageAvailable:
    'You are visible for Doctors available now and available to receive new requests. Your status will change to ‘offline’ if you do not reply to a request.',
  addPaymentCard: 'Add Payment Card',

  scheduledAppointments: 'Scheduled appointments',
  scheduledAppointmentIn: 'Scheduled appointment in ',
  reSchedule: 'Reschedule by choosing the exact time to notify the patient.',
  visitorsScheduleAbility: 'Visitors will only be able to schedule appointments during available hours.',
  addTimeSlotScheduledAppointment: `Use the '+' button to add more availability slots`,
  addAvailability: 'Add availability slot',
  updateAvailability: 'Update availability slot',
  whatTimeWillBeAvailable: 'At what time will you be available?',
  chooseLocation: 'Choose location on the map',
  openMap: 'Open map to choose location',
  maxDistanceFromLocation: 'Maximum distance from location',
  addAvailabilityHomeVisitSubMessage: 'Choose which days of the week you will be available to receive requests for ',
  addAvailabilitySubMessage: 'Visitors will only be able to schedule appointments during available hours.',
  selectAppointmentDate: 'Select Appointment Date ',
  chooseAppointmentDuration: 'Choose appointment duration',
  appointmentDuration: 'Appointment duration',
  timeBetweenAppointment: 'Time between appointments',
  repeatAvailability: 'Repeat availability',
  repeatAvailabilityMessage: 'Choose any day of the week to repeat this availability.',
  biography: 'Biography',
  listOfClinic: 'List Of Clinic',
  modalPopupMessage1:
    'Our team is currently reviewing your account. Please allow 2-3 working days for us to review your account. You will received a notification once this has been approved.',
  modalPopupMessage2:
    '1. All approved applications are subject to review, from time to time or as may be necessary, by Digimed Medical Committee;',
  modalPopupMessage3:
    '2. Please notify Digimed Medical Committee at your earliest of any changes to the above that may occur.',
  showMore: 'Show More',
  showLess: 'Show Less',
  chooseYourLanguage: 'Choose your language',
  repeatScheduleUntil: 'Repeat this schedule until:',
  markAsOnline: 'Mark yourself as online',
  doctorsAvailableNow: 'Doctors available now',
  setTimeOutPeriod: 'Online until:',
  calendar: 'Calendar',
  cancel: 'CANCEL',
  update: 'UPDATE',
  agree: 'AGREE',
  termsAndConditions: 'Terms and Conditions',
  privacyPolicy: 'Privacy Policy',
  patientName: 'Patient name',
  patientID: 'Patient ID',
  phone: 'Phone',
  date: 'Date',
  bookingTime: 'Booking time',
  startNavigation: 'START NAVIGATION',
  endNavigation: 'END NAVIGATION',
  startNavigationMessage: 'The location is enabled for patient to see the estimated time of arrival.',
  ongoingConsultations: 'Ongoing consultations',
  todaysAppointments: "Today's appointments",
  viewAll: 'View All',
  view: 'View',
  viewConsultation: 'View consultation',
  homeVisit: 'Home Visit',
  ondemand: 'On Demand',
  doctorOnDemand: 'Doctor On-Demand',
  request: 'Request',
  requests: 'Requests',
  documentImports: 'Document Imports',
  documentExports: 'Document Exports',
  chooseATimeSlotBetween: 'Choose a time slot between',
  youHave1NewRequestForaHomeVisit: 'You have 1 new request for a home visit',
  youHave1NewRequestForOnDemand: 'You have 1 new request for Doctor available now',
  and: 'and',
  approve: 'APPROVE',
  decline: 'DECLINE',
  patientApprovedReschedule: 'The patient accepted the appointment reschedule',
  patientRejectedReschedule: 'The patient rejected the appointment reschedule',
  addExpectedDepatureTime: 'Add expected departure time',
  addExpectedDepatureTimeMSG:
    'Consider traffic and time to find parking in order to arrive at the destination on time.',
  addDepatureTime: 'ADD DEPARTURE TIME',
  createAppointmentAvailability: 'Create Appointment Availability',
  noAppointments: 'You do not have any appointments today.',
  noPatients: 'Currently you do not have any patients.',
  noPatientsWithQuery: 'You do not have any patients matching your query "$query".',
  noRequests: 'You do not have any requests at the moment.',
  swipeLeftForDetails: 'Swipe left for details',
  backToCamera: 'BACK TO CAMERA',
  fullName: 'Full name',
  age: 'Age',
  dob: 'DOB',
  consultationId: 'Consultation ID',
  reasonForConsultation: 'Reason for consultation',
  twoStepVerification: '2-step verification',
  twoStepVerificationSubtitle:
    'Ensure top-level security for your account with 2-step verification. Choose a method from the below:',
  securityCodeSentViaSMS: 'Security code sent via SMS',
  securityCodeSentViaEmail: 'Security code sent via email',
  securityCodeViaAuthenticatorApp: 'Security code via Authenticator App',
  EnterTheCodeBelow: 'Enter the code below:',
  pleaseConfirmFinalFee: 'Please confirm final fee for this consultation.',
  changesCannotExceedYourMaximumFee: 'Charges cannot exceed your maximum fee.',
  amountForThisVisit: 'Amount for this visit',
  apply: 'APPLY',
  addDiscountAmount: 'Add discount %',
  consultationEnd: 'Consultation ended',
  completeReport: 'COMPLETE REPORT',
  kindlyFillTheDetailsToCompleteTheConsultation: 'Kindly fill in the details to complete the consultation report.',
  notifications: 'Notifications',
  markAsAllRead: 'MARK ALL AS READ',
  patients: 'Patients',
  myPatients: 'My Patients',
  searchPatientName: 'Search patients’ name',
  searchPractitioner: 'Search practitioner',
  allPatients: 'All patients',
  addConsultation: 'ADD CONSULTATION',
  patientInformation: 'Patient Information',
  healthProfile: 'Health Profile',
  history: 'History',
  updateDetails: 'Update details',
  completeUpload: 'Complete Upload',
  uploadRelevantDocument: 'Upload any relevant documents',
  chooseSectionTitle:
    'Choose section title from dropdown menu, write the type of health condition and upload the document/s related to it. Once the document is uploaded, you will be able to see it in the respective tab.',
  diagnoses: 'Diagnoses',
  DifferentialDiagnoses: 'Differential diagnoses',
  diagnosesParagraph: 'Below is a list of diagnoses with multiple consultations grouped under the same diagnosis.',
  lastUpdated: 'Last updated :',
  id: 'ID : ',
  back: 'Back',
  backToPreferences: 'Back to Preferences',
  descriptionAboutDiagnosis: 'This will be the description about the diagnosis added by the doctor.',
  profile: 'Profile',
  logout: 'Log out',
  edit: 'EDIT',
  personalInformation: 'Personal information',
  idCardOrPassportNo: 'ID card or Passport no',
  medicalProfessionDetails: 'Medical profession details',
  medicalCouncilRegistrationNumber: 'Medical Council Registration Number',
  yearOfWarrant: 'Year of warrant',
  documentsUpload: 'Documents Upload',
  ok: 'OK',
  okay: 'Okay',
  close: 'Close',
  warning: 'Warning',
  uploadPNGFormatImage: 'Upload a PNG format of your digital signature',
  awaitingVerification: 'Awaiting verification',
  sumsubWaitingMessage:
    'Your ID card or passport are currently being reviewed. You will be notified once this is verified.',
  yourIdVerified: 'Your ID has been verified',
  chat: 'Chat',
  yourCameraIsOff: 'Your camera is off',
  symptoms: 'Symptoms',
  doctorProfileDeclined: 'Doctor’s profile declined',
  settings: 'Settings',
  doctorProfileCreater: 'Doctor’s profile Created',
  writeAnyNotesHere: 'Write any notes here.',
  appointmentDetails: 'Appointment details',
  join: 'JOIN',
  cancelAppointment: 'Cancel Appointment',
  venue: 'Venue',
  time: 'Time',
  online: 'Online',
  consultationWillTakeVia: 'Consultation will take place via',
  consultationWillTakeAt: 'Consultation will take place at',
  typeOfConsultation: 'Type of consultation',
  fileContentWarning: 'May we remind you to never click on any suspicious links within a file',
  saveAndContinue: 'SAVE AND CONTINUE',
  addYourBankDetails: 'Add your bank details',

  addBankDetailsText1: 'We have integrated our platform with global leader ',
  addBankDetailsText2: 'Stripe,',
  addBankDetailsText3:
    ' to use their advanced software in order to accept payments, send payouts and allow you to manage your online financial activity while using ',
  addBankDetailsText4: 'Digimed.',
  addBankDetailsText5: ' Click ',
  addBankDetailsText6: 'here',
  addBankDetailsText7: ' to learn more about Stripe.',
  addBankDetailsText8: 'To manage your details access ',
  stripeDashboard: 'Stripe Dashboard',
  beneficiary: 'Beneficiary',
  iban: 'IBAN',
  bic: 'BIC',
  bankDetails: 'Bank details',
  stripeConnectedSuccessfully: 'Stripe connection is created...',
  minRemaining: ' minutes remaining',
  addAnotherSchedule: 'ADD ANOTHER SCHEDULE',

  otpSendMessage: 'We have sent a password recover instruction to your email',
  checkYourEmail: 'Check your email',
  accountCretedPlsLogin: 'Your Account has been created please login',
  phoneNumber: 'Phone number',
  addNewPatient: 'ADD A NEW PATIENT',
  editPatient: 'Edit patient details',
  viewAuditLog: 'View audit log',
  viewConsultationReport: 'VIEW CONSULTATION REPORT',
  patientProfileCreated: 'Patient profile created',
  createProfile: 'CREATE PROFILE',
  prescriptionCode: 'Prescription code',
  name: 'Name',
  prescribedBy: 'Prescribed by',
  prescribedOn: 'Prescribed on',
  checkAvalability: 'CHECK AVALABILITY',
  prescriptionDetails: 'Prescription Details',
  prescribedData: 'Date prescribed',
  type: 'Type',
  productName: 'Product name',
  addAnotherItem: 'Add another item',
  removeItem: 'Remove item',
  dose: 'Dose',
  frequency: 'Frequency',
  duration: 'Duration',
  delete: 'Delete',
  deleteNotification: 'Delete this notification',
  turnOff: 'Turn off',
  turnOffMessage: 'Stop reciving notifications like this',
  whatareTheirPersonalDetails: 'What are their personal details?',

  homeAddress: 'Home address',
  AddNewAddress: 'Add a new address',
  primaryAddress: 'Primary address',
  secondaryAddress: 'Secondary address',
  updateEmail: 'Update Email',
  help: 'Help',
  selectAnIssue: 'Select an issue',
  cantFindIssue: `Can't find the answer to your question? Let us know how we can help you`,
  contactUsFormSubtitle:
    'Due to the high amount of requests it can take up to 5 working days for someone to get in touch.',
  faq: 'FAQs',
  contactUs: 'Contact us',
  message: 'Message',
  subject: 'Subject',
  submit: 'Submit',
  saveAsDraft: 'Save as draft',
  draft: 'draft',
  done: 'done',
  setUpNow: 'Set up now',

  notification: 'Notification',
  sendEmailNotification: 'Send an email notification to the patient',
  security: 'Security',
  account: 'Account',
  allowCameraAccess: 'Allow camera access',
  allowLocationAccess: 'Allow location access',
  needForAllConsultation: 'This is needed for all consultations',
  productNews: 'Product news',
  reviewNewsAndLatesProductUpdates: 'Receive news and the latest product updates on Digimed.',
  reminders: 'Reminders',
  notificationRemindYouAppointments: 'This are notifications to remind you about appointments etc.',
  inApp: 'In-app',
  push: 'Push',
  ensureTopLevelSecurityDesc: 'Ensure top-level security for your account with 2-step verification.',
  changePasscode: 'Change Passcode',
  yourCurrentPasscode: 'What is your current passcode?',
  enterYourCurrentPasscode: 'Enter your current passcode',
  enterNewPasscode: 'Enter a new passcode',
  confirmNewPasscode: 'Confirm new passcode',
  createNewPasscode: 'Create a new passcode',
  requestAccountInfo: 'Export account info',
  requestAccountInfoDesc: 'Generate exports for all your data stored in your Digimed account.',
  requestReport: 'Generate Export',
  deleteAccount: 'Delete account',
  deleteAccountInfo:
    'Delete your account and all data associated with it.\nYour data will be completely removed within 30 days.',
  turnOnAutoRenewal: 'Turn on auto-renewal',
  turnOffAutoRenewal: 'Turn off auto-renewal',
  manageSubscription: 'Manage subscription',
  email: 'Email',
  consultations: 'Consultations',
  settingsConsultationTypeTitle: 'Set your consultation type',
  settingsConsultationDesc: [
    'By enabling and setting the price of your consultation types, you will be able to set your availability from the calendar and be available to the patients.',
    'At the end of each consultation, you will be asked to confirm your fee for that consultation. The ‘maximum fee’ you enter here will be blocked on the patient’s card. If you choose to charge less than the maximum (example in case of a simple follow-up), the difference will be refunded to the patient.',
    'For every change which is edited, the administrators will be notified accordingly.',
  ],
  changeYourOnlinePreferedConsultation: 'Change your preferred means of online consultation',
  income: 'Income',
  totalIncome: 'Total income',
  totalAppointments: 'Total appointments',
  timeSpent: 'Time spent',
  invoiceAndBillingHistory: 'Invoice and billing history',
  billing: 'Billing',
  billingHistory: 'Billing history',
  showReportsFor: 'Show Reports for',
  filterByConsultation: 'Filter By Consultation',
  auditLogs: 'Audit Logs',
  overView: 'Overview',
  viewMore: 'VIEW MORE',
  accountDeleteMessage:
    'Are you sure you want to delete your account?\nBy clicking on ‘Delete account’, you are permanently deleting your information. You won’t be able to retrieve any of the content or information after this action. \n',
  kindlyConfirm: 'Kindly confirm that you:',
  sorryToSeeYouGo: 'Sorry to see you go!',
  paymentDetails: 'Payment Details',
  saveCard: 'Save Card',
  addNewCard: 'ADD NEW CARD',
  addNewAccount: 'ADD NEW ACCOUNT',
  filter: 'Filter',
  export: 'EXPORT',
  exports: 'Exports',
  imports: 'Imports',
  uploads: 'Uploads',
  startDate: 'Start Date',
  endDate: 'End Date',
  startTime: 'Start Time',
  startTimeOverlap: 'Start time overlaps with a previous slot',
  endTime: 'End Time',
  endTimeOverlap: 'End time overlaps with a previous slot',
  sumsubIdentificationRejected: 'Your sumsub identification was rejected.',
  documentType: 'Document type',
  dateOfBirthPlaceholder: 'Date of Birth DD/MM/YYYY',
  idCardOrPassport: 'ID card / Passport number',
  upcomingAppointments: 'Upcoming appointments',
  noUpcomingAppointmentsPatients: 'There are no upcoming appointments with this patient.',
  noUpcomingAppointments: 'You have no upcoming appointments.',
  noCurrentDiagnosisDoctor: 'This patient does not currently have any diagnoses or consultations.',
  noCurrentDiagnosisPatient: 'You do not have any diagnoses or consultations at the moment.',
  noCurrentConsultations: 'This patient does not currently have any consultations for this diagnosis.',
  failedImportation:
    'Something went wrong during the importation.\nPlease try again and if the problem persists please contact us.',

  receiveMarketingEmails: 'Please check this box if you wish to receive promotions and offers from Digimed',
  cookiePolicy: 'Cookie Policy',
  cancellationPolicy: 'Cancellation Policy',
  today: 'Today',
  month: 'Month',
  week: 'Week',
  doctor: doctorLabels,
  patient: patientLabels,
  pharmacy: pharmacyLabels,
  search: 'Search',

  setupInstructions: 'Setup Instructions: ',
  scanImage: 'Scan the image below with the Google Authenticator App to configure your device.',
  googleAuthenticatorEnrolmentTitle: 'Google Authenticator - Enrolment',
  googleAuthenticatorSubtitle:
    'Provides Two-Factor authentication using codes generated by the Google Authenticator App',
  googleAuthenticatorInstructions: [
    '1. Download and install the Google Authenticator app from your mobile device’s app store',
    '2. Set up a new account using the QR code',
    '3. Verify the configuration by entering the code that Google Authenticator provides',
  ],
  consultationTypes: 'Consultation types',
  consultationType: 'Consultation type',
  addNewSlot: 'Add new slot',
  addNewSchedule: 'Add a new schedule',
  invalidTimeRange: 'Invalid time range.',
  excludingVATMonth: 'Month EXCL. VAT',
  excludingVATYear: 'Year EXCL. VAT',
  nextPaymentDateLabel: 'Next payment date',
  subscriptionTypeLabel: 'Subscription type',
  subscriptionPlan: 'Subscription Plan',
  premiumPlan: 'Premium Plan',
  freePlan: 'Free Plan',
  nextDue: 'Next Due',
  planWillExpireOnLabel: 'Plan will expire on',
  validUntil: 'Valid until',
  trialSubscription: 'Trial Subscription',
  currentPlan: 'CURRENT PLAN',
  yearly: 'Yearly',
  monthly: 'Monthly',
  upgrade: 'Upgrade',
  freeAccount: 'Free Account',
  upgradePlanDependants: 'Upgrade your plan to be able to add your family members.',
  addDependants: 'Add your family members.',
  upgradePlanInsurance: 'Upgrade your plan to be able to add an insurance plan.',
  addInsurance: 'Add an insurance plan.',
  upgradeSuccessful: 'Your subscription has been upgraded.',
  favouriteDoctor: 'Select favourite access',
  favouriteDoctorDescription:
    "By selecting this doctor as a 'favourite', for either yourself or your dependants, you're granting the doctor access to that patient's information, health profile & health records.\n\nSelect the person below.",
  promotion: 'Promotion',
  createAccount: 'Create an Account',
  sendEmailToCreateAccount:
    'Send an email notification to patients from your management system to create a Digimed account.',
  viewPatients: 'VIEW PATIENTS',
  sendEmail: 'Send Email',

  sendPatientNotification: 'Notification has been successfully dispatched.',
  description: 'Description',
  selectAll: 'Select all',
  prescriptions: 'Prescriptions',
  linkedAccounts: 'Linked accounts',
  completeProfile: 'Complete your Profile',
  howCanWeHelp: 'How can we help you?',
  digimedEducationCentre: 'Digimed Education Centre',

  willYouBeOnTime: 'Will you be on time?',
  viewDetails: 'View Details',
  create: 'Create',
  consultationMessageNotification: 'Consultation chat messages',
  consultationMessageNotificationDescription:
    'These are notifications received for new chat messages in a consultation.',
  healthCompanionNotification: 'Health Companion',
  healthCompanionNotificationMessage: 'These are notifications to remind you about prescriptions',
  markAsCollected: 'Mark as collected',
  submitPrescriptionRequest: 'Check availability',
  ignoreForNow: 'Ignore for now',
};
