import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ErrorAlert } from '../../../components/modals/ErrorAlert';
import { RootState } from '../../../redux/reducers';
import { CONSULTATION_DETAILS } from '../../../redux/reducers/consultationDetailsReducer';
import { useAppDispatch } from '../../../redux/store';
import { apiGetConsultation } from '../../services/consultations';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { isDoctorVersion } from '~/utils/buildConfig';

interface Props {
  consultationId: number;
  onError?: (e: ErrorResponse) => void;
}
export function useConsultationDetails({ consultationId, onError }: Props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const consultation = useSelector((state: RootState) => {
    if (state.consultationDetailsReducer.consultationDetails?.id === consultationId)
      return state.consultationDetailsReducer.consultationDetails;
  });

  useEffect(() => {
    if (consultationId) getConsultation();
  }, [consultationId]);

  const getConsultation = () => {
    setLoading(true);

    return apiGetConsultation({ id: consultationId })
      .then((response) => {
        dispatch(CONSULTATION_DETAILS(response.data));
      })
      .catch(onError || ErrorAlert)
      .finally(() => setLoading(false));
  };

  const isEditing = useMemo(
    () => isDoctorVersion() && consultation?.state !== ConsultationStateEnum.Submitted,
    [consultation?.state]
  );

  return { consultation, isEditing, getConsultation, loading };
}
