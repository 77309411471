import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { usePharmacyDashboardStats } from '~/api/hooks/pharmacy/PharmacyDashboardStatsHook';
import { useCurrentPharmacy } from '~/api/hooks/pharmacy/PharmacyHook';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { flex1, justifyEnd, mb10, pb10, pb20 } from '~/common/commonStyles';
import { LinkButton } from '~/components/buttons/LinkButton';
import { H3TtmSemiBoldBlack, H3TtmSemiBoldTheme, nsRegular, ttmSemiBold } from '~/components/commonText';
import { DashboardCard } from '~/components/dashboard/common/DashboardCard';
import { NotificationBell } from '~/components/dashboard/common/NotificationBell';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { PharmacySelection } from '~/components/navigation/WebSidebarMenu/PharmacySelection';
import { PharmacySearchModal } from '~/components/pharmacies/PharmacySearchModal';
import { PrescriptionRequestsTable } from '~/components/pharmacies/requests/PrescriptionRequestsTable';
import { PharmacyQrScannerIcon, PharmacySearchIcon } from '~/components/svgImages';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { NavType } from '~/navigation/types';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { Grid, Container, Column, Row } from '~/theme/components/grid';
import { isNative } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { usePharmacyScannerPermission } from '~/utils/hooks/PharmacyScannerPermissionsHook';
import { screenName } from '~/utils/screenName';

export const PharmacistDashboard: React.FC = () => {
  const { currentPharmacy } = useCurrentPharmacy();
  const { stats, loading } = usePharmacyDashboardStats();
  const { navigate } = useNavigation<NavType>();
  const { isDesktop, isMobile } = useBreakpoints();
  const onPress = (key: string) => {
    switch (key) {
      case 'total_pending_prescription_requests':
        return () => navigate(screenName.Requests);
      case 'total_dispensed_prescriptions':
        return () => navigate(screenName.PharmacyDispenseHistory);
      case 'total_prescriptions_awaiting_collection':
        return () => navigate(screenName.PharmacyAwaitingCollection);
      default:
        return null;
    }
  };

  const showDispense = (prescription: PrescriptionModel) => {
    openModal(ModalName.PrescriptionDispenseModal, { prescription });
  };

  const { registerModal, openModal } = useModalManager();
  useEffect(() => {
    registerModal(ModalName.PharmacySearch, PharmacySearchModal);
  }, []);

  const { showPrescriptionScanner } = usePharmacyScannerPermission();

  const scanQrCodeAction = isNative() ? (
    <DashboardCard
      white
      shadow
      style={[mb10, styles.cardMinHeight, { justifyContent: 'center' }]}
      onPress={() => showPrescriptionScanner(showDispense)}>
      <View style={[styles.iconButton]}>
        <View style={pb10}>
          <PharmacyQrScannerIcon width={29} height={29} />
        </View>
        <Text style={[nsRegular, { fontSize: 12, color: colors.purple, textAlign: 'center' }]}>
          Scan a new prescription
        </Text>
      </View>
    </DashboardCard>
  ) : null;

  const searchCodeAction = (
    <DashboardCard
      white
      shadow
      style={[{ marginRight: 2 }, styles.cardMinHeight, { justifyContent: 'center' }]}
      onPress={() => {
        openModal(ModalName.PharmacySearch, { onSelect: showDispense });
      }}>
      <View style={[styles.iconButton]}>
        <View style={pb10}>
          <PharmacySearchIcon width={29} height={29} />
        </View>
        <Text style={[nsRegular, { fontSize: 12, color: colors.purple, textAlign: 'center' }]}>
          Search Patients Prescription
        </Text>
      </View>
    </DashboardCard>
  );

  const header = isMobile ? (
    <View
      style={{
        paddingVertical: 40,
        paddingHorizontal: 15,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
      }}>
      <PharmacySelection large style={{ paddingRight: 20, flex: 1 }} />
      <NotificationBell />
    </View>
  ) : null;

  return (
    <PageLayout
      hideGradient
      hideBack
      title={currentPharmacy?.pharmacy?.name}
      contentStyling={[isDesktop ? flex1 : { paddingHorizontal: 10 }]}
      header={header}>
      <Grid grid={{ noOuterGutter: isDesktop, gutters: isDesktop ? 30 : 10 }}>
        <Container style={[flex1]} alignSelf="flex-start">
          <Row>
            <Column>
              <H3TtmSemiBoldTheme>Overview</H3TtmSemiBoldTheme>
            </Column>
          </Row>
          <Row>
            {stats.map((stat, index) => (
              <Column span={{ xs: 6, lg: 3 }} style={{ marginTop: 22 }} key={stat.key ?? index}>
                <DashboardCard
                  title={stat.label}
                  onPress={onPress(stat.key)}
                  style={styles.cardMinHeight}
                  childStyle={justifyEnd}>
                  <Text
                    style={[
                      ttmSemiBold,
                      { fontSize: 36, display: 'flex', flexDirection: 'row', alignItems: 'flex-end' },
                    ]}
                    maxFontSizeMultiplier={1}>
                    {loading ? <LoadingActivityIndicator /> : stat.value}
                  </Text>
                </DashboardCard>
              </Column>
            ))}
            <Column span={{ xs: 6, lg: 3 }} style={{ marginTop: 22, justifyContent: 'space-between' }}>
              {isDesktop ? (
                searchCodeAction
              ) : (
                <DashboardCard
                  title="Prescription requests"
                  onPress={() => navigate(screenName.Requests)}
                  titleStyle={{ color: colors.white }}
                  style={[{ backgroundColor: colors.purple }, styles.cardMinHeight]}
                  childStyle={justifyEnd}>
                  <Text
                    style={[
                      ttmSemiBold,
                      {
                        fontSize: 12,
                        display: 'flex',
                        alignItems: 'flex-end',
                        color: colors.white,
                        paddingTop: 10,
                        paddingBottom: 10,
                      },
                    ]}>
                    View all
                  </Text>
                </DashboardCard>
              )}
            </Column>
          </Row>
          {isDesktop ? (
            <Row style={flex1}>
              <Column style={{ marginTop: 30, paddingBottom: 10 }}>
                <View style={{ marginBottom: 15, flexDirection: 'row', justifyContent: 'space-between' }}>
                  <H3TtmSemiBoldBlack>Prescription Requests</H3TtmSemiBoldBlack>
                  <LinkButton to={{ screen: screenName.Requests } as never} title="VIEW ALL" />
                </View>
                <PrescriptionRequestsTable hideSorting hidePagination />
              </Column>
            </Row>
          ) : (
            <>
              <Row>
                <Column style={{ marginTop: 30, marginBottom: 15 }}>
                  <H3TtmSemiBoldBlack>Prescriptions</H3TtmSemiBoldBlack>
                </Column>
              </Row>
              <Row style={pb20}>
                {scanQrCodeAction ? (
                  <Column>
                    <View style={{ padding: 2 }}>{scanQrCodeAction}</View>
                  </Column>
                ) : null}
                <Column>
                  <View style={{ padding: 2 }}>{searchCodeAction}</View>
                </Column>
              </Row>
            </>
          )}
        </Container>
      </Grid>
    </PageLayout>
  );
};

const styles = StyleSheet.create({
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  cardMinHeight: {
    height: 136,
    minHeight: 136,
    justifyContent: 'space-between',
  },
});
