import { useState, useEffect } from 'react';
import { useUserDetails } from '../accounts/UserDetails';
import { GeneralEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { privateGeneralChannel } from '~/api/models/channel/enum/channelNames';
import { MediaStatusEnum } from '~/api/models/common/constants/MediaStatusEnum';
import { getInsuranceClaimMedia } from '~/api/services/insurance';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { channelSubscribe } from '~/integrations/channels/PusherChannels';
import fileSystem from '~/integrations/fileSystem';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { PharmacyModel } from '~/api/models/pharmacy/models/PharmacyModel';
import { usePharmacyStateChange } from '../notifications/PharmacyStateChangeHook';
import { useHandleDebug } from '../notifications/handlers/HandleDebug';

interface InsuranceEvent {
  claim_id: number;
  consultation_id: number;
  pharmacy: PharmacyModel;
}
export function useGeneralChannel(props?: { onEvent: (event: GeneralEventsEnum) => void }) {
  const { userDetails } = useUserDetails({ allowStale: true });
  const { issueSnackbar, updatePharmacyState } = usePharmacyStateChange();

  const { showSnackbar } = useSnackbarManager();
  const { handleNotification: handleDebug } = useHandleDebug();

  const downloadInsuranceClaim = async (claimId: number) => {
    try {
      //TODO: Remove duplicate code
      const res = await getInsuranceClaimMedia(claimId);
      const media = res.data?.media?.length ? res.data.media[0] : null;
      if (!media)
        throw new Error('We were not able to retrieve the insurance claim pdf at this time. Please try again later.');

      if (res.data.status !== MediaStatusEnum.COMPLETED) {
        let reason = '';
        switch (res.data.status) {
          case MediaStatusEnum.FAILED:
            reason =
              'There was an issue while generating your document. Please contact support for further assistance.';
            break;
          case MediaStatusEnum.PENDING:
          case MediaStatusEnum.PROCESSING:
            reason = 'It seems to be taking more time to process your document than expected. Please try again later.';
            break;
        }
        return `We were unable to get your insurance claim at this time.${reason ? '\n' + reason : ''}`;
      } else {
        await fileSystem.downloadMedia(media);
      }
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const eventHandler = (event: GeneralEventsEnum, data: InsuranceEvent) => {
    if (props?.onEvent) {
      props.onEvent(event);
      return;
    }

    switch (event) {
      case GeneralEventsEnum.INSURANCE_CLAIM_PDF_GENERATED:
        showSnackbar('Your insurance claim PDF was generated', {
          onPress: () => {
            downloadInsuranceClaim(data.claim_id);
          },
          actionTitle: 'Click here to download',
        });
        break;
      case GeneralEventsEnum.PHARMACY_STATE_CHANGED:
        issueSnackbar(data.pharmacy);
        updatePharmacyState();
        break;
      case GeneralEventsEnum.DEBUG:
        handleDebug(data);
        break;
    }
  };

  const subscribe = async () => {
    const res = await channelSubscribe(privateGeneralChannel(userDetails.account.id), eventHandler);
    return () => {
      res.unsubscribe();
    };
  };

  useEffect(() => {
    if (!userDetails) return;

    let unsubscribe: () => void;
    subscribe().then((res) => (unsubscribe = res));

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [userDetails?.account?.id]);
}
