import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { SmallNsRegularBlack } from '../commonText';

import { colors } from '~/utils/colors';

interface Props {
  label?: string;
  vertical?: boolean;
  secondaryColor?: boolean;
  size?: number;
  spacing?: number;
  style?: StyleProp<ViewStyle>;
}

export const Splitter: React.FC<Props> = ({ label, vertical, secondaryColor, style, size = 1, spacing = 20 }) => {
  return (
    <View
      style={[
        {
          display: 'flex',
          marginVertical: vertical ? 0 : spacing,
          marginHorizontal: vertical ? spacing : 0,
          flexDirection: vertical ? 'column' : 'row',
          justifyContent: 'center',
        },
        style,
      ]}>
      <View
        style={{
          backgroundColor: secondaryColor ? colors.lightPurple2 : colors.lightPurple,
          position: 'absolute',
          left: vertical ? '50%' : 0,
          right: vertical ? undefined : 0,
          top: vertical ? 0 : '50%',
          bottom: vertical ? 0 : undefined,
          height: vertical ? undefined : size,
          width: vertical ? size : undefined,
        }}
      />
      {label ? (
        <View style={{ zIndex: 1, backgroundColor: colors.white, paddingHorizontal: 5 }}>
          <SmallNsRegularBlack>{label}</SmallNsRegularBlack>
        </View>
      ) : null}
    </View>
  );
};
