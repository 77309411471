import { deleteReq, get, post, put } from '~/api/axios';
import { SubscriptionSetupRequest } from '~/api/models/subscriptions/requests/SubscriptionSetupRequest';
import { SubscriptionUpgradePlanRequest } from '~/api/models/subscriptions/requests/SubscriptionUpgradePlan';
import { FeatureAccessResponse } from '~/api/models/subscriptions/responses/FeatureAccessResponse';
import { SubscriptionPlansResponse } from '~/api/models/subscriptions/responses/SubscriptionFeaturesResponse';
import { SubscriptionPlanMeResponse } from '~/api/models/subscriptions/responses/SubscriptionPlanMeResponse';
import { SubscriptionSetupResponse } from '~/api/models/subscriptions/responses/SubscriptionSetupResponse';

export const apiGetSubscriptionPlans = (type: 'doctor' | 'patient') => {
  return get<SubscriptionPlansResponse>('/subscription-plans', { type });
};

export const apiGetMeSubscriptionPlan = () => {
  return get<SubscriptionPlanMeResponse>('/me/subscription');
};

export const apiCancelSubscription = (subscriptionId: string) => {
  return deleteReq(`/subscriptions/${subscriptionId}`);
};

export const apiSubscriptionSetup = (params: SubscriptionSetupRequest) => {
  return post<SubscriptionSetupResponse>('/subscription/setup', params);
};

export const apiSubscriptionUpgrade = (subscriptionId: string, params: SubscriptionUpgradePlanRequest) => {
  return put<SubscriptionSetupResponse>(`/subscriptions/${subscriptionId}`, params);
};

export const apiResumeSubscription = (subscriptionId: string) => {
  return put<SubscriptionSetupResponse>(`/subscriptions/${subscriptionId}/resume`);
};

export const apiGetFeatureAccess = () => {
  return get<FeatureAccessResponse>('/me/feature-access');
};
