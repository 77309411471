import React, { useContext } from 'react';

import { ActiveModal } from './ModalManagementProvider';

import { successOrErrorPopup } from '~/common/commonMethods';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';

export interface ShowInfoModal {
  message: string[];
  type: ModalAlertTypeEnum;
  title?: string;
  blockButtons?: boolean;
  okButtonTitle?: string;
  okFunction?: () => void;
  centerModal?: boolean;
  hideOnBackground?: boolean;
}

export interface IModalManagementContext {
  registerModal: (name: string, component: React.FC<any>) => void;
  openModal: (name: string, params?: any) => void;
  closeModal: () => void;
  closeModalByName: (name: string) => void;
  setShowingNonRegisteredModal: (modalName: string, show: boolean) => void;
  activeModals: ActiveModal[];
}

export const ModalManagementContext = React.createContext<IModalManagementContext>({} as IModalManagementContext);
export const useModalManager = (): IModalManagementContext => useContext(ModalManagementContext);

export const infoModal = (params: ShowInfoModal) => {
  successOrErrorPopup(
    params.message,
    true,
    params.type,
    params.title,
    params.okButtonTitle,
    params.blockButtons,
    params.okFunction,
    params.hideOnBackground,
    params.centerModal
  );
};
