import { useEffect } from 'react';

import { isNative } from '../buildConfig';

import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { PharmacySearchQrCodeModal } from '~/components/pharmacies/PharmacySearchQrCodeModal';
import { useBarcodeScannerPermission } from '~/integrations/barcodeScanner';
import { useModalManager } from '~/providers/modal/ModalManagementContext';

export const usePharmacyScannerPermission = () => {
  const { registerModal, openModal } = useModalManager();
  const { getBarCodeScannerPermission } = useBarcodeScannerPermission();
  useEffect(() => {
    if (isNative()) registerModal(ModalName.PharmacyQrSearch, PharmacySearchQrCodeModal);
  });

  const showPrescriptionScanner = async (onSelect: (prescription: PrescriptionModel) => void) => {
    const granted = await getBarCodeScannerPermission();

    if (granted) {
      openModal(ModalName.PharmacyQrSearch, { onSelect });
    } else {
      ErrorAlert(
        'We were unable to get camera permissions to allow you to scan a new prescription.',
        'Camera permission required'
      );
    }
  };

  return { showPrescriptionScanner };
};
