import React from 'react';
import { StyleSheet, View } from 'react-native';

import { colors } from '../../../../utils/colors';
import { labels } from '../../../../utils/labels';
import { H2TtmSemiBoldBlack, H6NsRegularBlack } from '../../../commonText';

import { useIncomeListingContext } from '~/providers/income/IncomeListingContext';

export const IncomeTotalsComponent: React.FC = () => {
  const { filteredTotalIncome } = useIncomeListingContext();

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <H6NsRegularBlack style={styles.col}>{labels.totalIncome}</H6NsRegularBlack>
        <H2TtmSemiBoldBlack style={styles.col}>
          {filteredTotalIncome?.total_income?.formatted_amount}
        </H2TtmSemiBoldBlack>
      </View>
      <View style={styles.row}>
        <H6NsRegularBlack style={styles.col}>{labels.totalAppointments}</H6NsRegularBlack>
        <H2TtmSemiBoldBlack style={styles.col}>{filteredTotalIncome?.total_consultations}</H2TtmSemiBoldBlack>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.lightPurple2,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 6,
    maxWidth: 420,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 8,
    alignItems: 'center',
  },
  col: {
    flex: 1,
  },
});
