import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import { useDocumentImportHook } from '~/api/hooks/patientDataHandling/DocumentImportHook';
import { UploadPatientDataStatusEnum } from '~/api/models/patientData/constants/UploadPatientDataStatusEnum';
import { UploadPatientDataModel } from '~/api/models/patientData/models/UploadPatientDataModel';
import { flex1 } from '~/common/commonStyles';
import { DataTable, DataTableColumns } from '~/components/common/DataTable/DataTable';
import { OutlineButton } from '~/components/commonButton';
import { H6NsRegularBlack, H6NsSemiBoldBlack, H6TtmSemiBoldTheme } from '~/components/commonText';
import { NewBadge } from '~/components/misc/NewBadge';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { DocumentImportDetailsModal } from '~/components/modals/DocumentImportDetailsModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { CLEAR_IMPORTS_DATA } from '~/redux/reducers/requestsReducer';
import { useAppDispatch } from '~/redux/store';
import { colors } from '~/utils/colors';
import { parseDateTime, TIME_FORMAT } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';

const STATUS_COLOR_MAPPING = {
  [UploadPatientDataStatusEnum.FAILED]: colors.danger,
  [UploadPatientDataStatusEnum.PENDING]: colors.warning,
  [UploadPatientDataStatusEnum.PROCESSING]: colors.purple,
  [UploadPatientDataStatusEnum.SUCCESS]: colors.success,
};
export const DocumentImportList: React.FC = () => {
  const { setPagination, getUploadErrors, currentUploadErrors, loading, importsDocuments, importsPagination } =
    useDocumentImportHook();
  const dispatch = useAppDispatch();
  const { showNewBadge } = useNewBadge();
  const { isMobile } = useBreakpoints();

  const { registerModal, openModal } = useModalManager();

  useEffect(() => {
    dispatch(CLEAR_IMPORTS_DATA());
    registerModal(ModalName.UploadDataLog, DocumentImportDetailsModal);
  }, []);

  const viewErrors = (uploadId: number) => {
    getUploadErrors(uploadId);
  };

  useEffect(() => {
    if (currentUploadErrors) {
      openModal(ModalName.UploadDataLog, {
        fileName: currentUploadErrors?.fileName,
        details: currentUploadErrors?.details,
      });
    }
  }, [currentUploadErrors]);

  const columns: DataTableColumns = [
    {
      key: 'created_at',
      title: 'Date and Time',
      cell: (cellData, itemData) => (
        <View style={flex1}>
          <H6NsSemiBoldBlack>{parseDateTime(cellData, { outputFormat: 'D MMMM,' })}</H6NsSemiBoldBlack>
          <H6NsRegularBlack>{parseDateTime(cellData, { outputFormat: TIME_FORMAT })}</H6NsRegularBlack>
        </View>
      ),
      columnStyle: { maxWidth: 100 },
    },
    {
      key: 'file_name',
      title: 'File Name',
    },
    {
      key: 'new',
      cell: (_, item) => (showNewBadge({ value: item }) ? <NewBadge /> : null),
      columnStyle: {
        maxWidth: 60,
      },
    },
    {
      key: 'status',
      title: 'Status',
      cell: (cellData) => (
        <StatusBadge
          label={cellData}
          color={STATUS_COLOR_MAPPING[cellData]}
          textStyle={{ textTransform: 'capitalize' }}
        />
      ),
      columnStyle: { maxWidth: 100 },
    },
    {
      key: 'errors',
      contentNumeric: true,
      cell: (_, itemData: UploadPatientDataModel) =>
        itemData.status === UploadPatientDataStatusEnum.FAILED ? (
          <OutlineButton
            funCallback={() => {
              viewErrors(itemData.id);
            }}
            label="View Errors"
          />
        ) : null,
      columnStyle: { maxWidth: 120 },
    },
  ];

  return (
    <View style={[flex1]}>
      <DataTable
        tableId="documentImport"
        columns={columns}
        items={importsDocuments || []}
        totalCount={importsPagination?.total || 0}
        page={importsPagination?.current_page || 0}
        onPageChanged={setPagination}
        numberOfItemsPerPage={importsPagination?.per_page}
        infiniteScroll={{ loading, estimatedItemSize: { desktop: 50, mobile: 130 } }}
        emptyNote="No document import records available."
        rowIsNewIdentifier={isMobile ? (item) => showNewBadge({ value: item }) : null}
        mobileBreakpoint={DeviceSizeDefaults.tablet}
        mobileRenderItem={(upload: UploadPatientDataModel) => (
          <View style={[{ display: 'flex', flexDirection: 'column', width: '100%' }]}>
            <View style={styles.status}>
              <View style={{ flex: 1 }}>
                <H6TtmSemiBoldTheme>{upload.file_name}</H6TtmSemiBoldTheme>
              </View>
              <View>
                <StatusBadge
                  label={upload.status}
                  color={STATUS_COLOR_MAPPING[upload.status]}
                  textStyle={{ textTransform: 'capitalize' }}
                />
              </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
              <H6NsSemiBoldBlack>{parseDateTime(upload.created_at, { outputFormat: 'D MMMM, ' })}</H6NsSemiBoldBlack>
              <H6NsRegularBlack>{parseDateTime(upload.created_at, { outputFormat: TIME_FORMAT })}</H6NsRegularBlack>
            </View>
            {upload.status === UploadPatientDataStatusEnum.FAILED ? (
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10 }}>
                <OutlineButton
                  funCallback={() => {
                    viewErrors(upload.id);
                  }}
                  label="View Errors"
                  style={{ width: 'auto' }}
                />
              </View>
            ) : null}
          </View>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  status: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
});
