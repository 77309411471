import { ClinicalTermTypeEnum } from '../../clinicalTerms/constants/ClinicalTermTypeEnum';

export enum HealthProfileHistoryTypeEnum {
  ALLERGY = 'allergy',
  MEDICAL_CONDITION = 'medical_condition',
  MEDICATION = 'medication',
  PROCEDURE = 'procedure',
  VACCINATION = 'vaccination',
}

export const ClinicalTermTypeToHealthProfileHistoryTypeMapping = {
  [ClinicalTermTypeEnum.ALLERGIES]: HealthProfileHistoryTypeEnum.ALLERGY,
  [ClinicalTermTypeEnum.MEDICAL_CONDITIONS]: HealthProfileHistoryTypeEnum.MEDICAL_CONDITION,
  [ClinicalTermTypeEnum.MEDICATIONS]: HealthProfileHistoryTypeEnum.MEDICATION,
  [ClinicalTermTypeEnum.PROCEDURES]: HealthProfileHistoryTypeEnum.PROCEDURE,
  [ClinicalTermTypeEnum.VACCINATIONS]: HealthProfileHistoryTypeEnum.VACCINATION,
};
