import React, { useMemo, useState } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { Menu } from 'react-native-paper';

import { H3TtmSemiBoldBlack } from '../commonText';
import { webMenuStyles } from '../doctor/notifications/DotMenuCommonStyles';
import { DownArrow } from '../svgImages';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { useDependantsHook } from '~/api/hooks/dependants/DependantsHook';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { DependantStateEnum } from '~/api/models/dependants/constants/DependantStateEnum';
import { colors } from '~/utils/colors';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props {
  withAll?: boolean;
  currentAccount?: AccountModel;
  setCurrentAccount: (account?: AccountModel) => void;
}

export const AccountSelectionDropdown: React.FC<Props> = ({ currentAccount, setCurrentAccount, withAll }) => {
  const { userDetails } = useUserDetails({ allowStale: true });
  const [visible, setVisible] = useState(false);
  const { dependants } = useDependantsHook({ state: DependantStateEnum.Approved });
  const dropdownValues = useMemo(() => {
    if (!userDetails) return [];
    return [
      withAll ? { label: 'All Diagnoses', value: null } : null,
      { label: getAccountName(userDetails?.account), value: userDetails?.account },
      ...(dependants?.map((d) => ({ label: getAccountName(d.account), value: d.account })) ?? []),
    ].filter((item) => item);
  }, [dependants, userDetails]);

  const selectedName = useMemo(() => {
    return currentAccount ? getAccountName(currentAccount) : withAll ? 'All Diagnoses' : '';
  }, [currentAccount]);

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  return (
    <View style={styles.select}>
      <H3TtmSemiBoldBlack style={{ color: colors.purple, marginRight: 10 }}>{selectedName}</H3TtmSemiBoldBlack>
      {dependants && dependants.length > 0 && (
        <TouchableOpacity onPress={openMenu}>
          <View style={styles.arrow}>
            <Menu
              visible={visible}
              onDismiss={closeMenu}
              anchor={<DownArrow height={15} width={15} color={colors.purple} />}>
              {dropdownValues?.map((item, i, arr) => (
                <Menu.Item
                  title={item?.label}
                  onPress={() => {
                    setCurrentAccount(item.value);
                    closeMenu();
                  }}
                  style={i === arr.length - 1 ? webMenuStyles.lastItemStyle : webMenuStyles.itemsListStyle}
                  key={item.value?.id || 'all'}
                />
              ))}
            </Menu>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3,
  },
  arrow: {
    height: 15,
    width: 15,
  },
});
