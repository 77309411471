import { useState } from 'react';

import { useReduxTablePagination } from '../common/useReduxTablePagination';

import { DiagnosesFilterModel } from '~/api/models/diagnoses/models/DiagnosesFilterModel';
import { GetDiagnosesResponse } from '~/api/models/diagnoses/responses/GetDiagnosesResponse';
import { apiGetDiagnosesIndex } from '~/api/services/diagnoses';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { SET_PAGE } from '~/redux/reducers/tablePaginationReducer';
import { useAppDispatch } from '~/redux/store';

interface Props {
  accountId: number;
  clinicalTerm?: string;
  isInfiniteScroll?: boolean;
}

const TABLE_NAME = 'diagnoses';
export function usePatientDiagnoses({ accountId, clinicalTerm, isInfiniteScroll }: Props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { items, pagination, setPagination } = useReduxTablePagination<DiagnosesFilterModel, GetDiagnosesResponse>({
    isInfiniteScroll,
    tableName: TABLE_NAME,
    getItems: async (params) => {
      setLoading(true);
      const response = await apiGetDiagnosesIndex({
        filter: {
          clinical_term: clinicalTerm,
          account_id: accountId,
        },
        page: params.page,
      });
      setLoading(false);
      return response;
    },
    watch: [accountId],
  });

  const getDiagnoses = async (clinicalTerm?: string) => {
    setLoading(true);
    await apiGetDiagnosesIndex({
      filter: {
        clinical_term: clinicalTerm,
        account_id: accountId,
      },
    })
      .then((response) => {
        dispatch(
          SET_PAGE({
            tableName: TABLE_NAME,
            pagination: response.data.meta,
            data: response.data.data,
          })
        );
      })
      .catch(ErrorAlert)
      .finally(() => setLoading(false));
  };

  const searchDiagnoses = (clinicalTerm: string) => {
    getDiagnoses(clinicalTerm);
  };

  return {
    setPagination,
    pagination,
    loading,
    diagnoses: items,
    searchDiagnoses,
  };
}
