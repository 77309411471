import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { useHealthProfileDataContext } from './HealthProfileDataContext';
import { IHealthProfileItemContext } from './HealthProfileItemContextTypes';

import { ProthesisImplantsGraftsResponse } from '~/api/models/healthProfile/responses/ProthesisImplantsGraftsResponse';
import { apiGetProsthesisImplantsGrafts } from '~/api/services/healthProfile/prosthesis';
import { RootState } from '~/redux/reducers';
import { SET_PROSTHESIS_IMPLANTS_GRAFTS } from '~/redux/reducers/healthProfileReducer';
import { useAppDispatch } from '~/redux/store';

export const HealthProfileItemContext = React.createContext<IHealthProfileItemContext<ProthesisImplantsGraftsResponse>>(
  {} as IHealthProfileItemContext<ProthesisImplantsGraftsResponse>
);

export const useHealthProfileProsthesisContext = (): IHealthProfileItemContext<ProthesisImplantsGraftsResponse> =>
  useContext(HealthProfileItemContext);

interface Props {
  children: React.ReactNode;
}

export const HealthProfileProsthesisProvider: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { healthProfile, accountId } = useHealthProfileDataContext();
  const dispatch = useAppDispatch();

  const getData = async () => {
    if (!healthProfile?.id) return;
    try {
      setLoading(true);
      const res = await apiGetProsthesisImplantsGrafts({ id: healthProfile.id });
      dispatch(SET_PROSTHESIS_IMPLANTS_GRAFTS(res.data));
      setLoading(false);
      return res.data;
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  return (
    <HealthProfileItemContext.Provider
      value={{
        accountId,
        healthProfileId: healthProfile?.id,
        data: [],
        getData,
        loading,
        hasData: useSelector((state: RootState) => !!state.healthProfileReducer.prosthesisImplantsGrafts),
      }}>
      {children}
    </HealthProfileItemContext.Provider>
  );
};
