import React from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { flex1, mb5, mr5 } from '~/common/commonStyles';
import { SmallNsRegularHint } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { AlertCircle } from '~/components/svgImages';
import { usePrivateNoteDataContext } from '~/providers/consultation/PrivateNoteDataContext';
import { validationSchema } from '~/services/validationConfig';
import { colors } from '~/utils/colors';

interface Props {
  editable?: boolean;
  noPadding?: boolean;
  saveOnBlur?: boolean;
}

export const PrivateNoteEdit: React.FC<Props> = ({ editable, noPadding, saveOnBlur }) => {
  const { control, submit, loading } = usePrivateNoteDataContext();

  const saveNote = async () => {
    try {
      await submit();
    } catch (e) {
      ErrorAlert(e);
    }
  };
  return (
    <View style={{ backgroundColor: colors.lightPurple, borderRadius: 6, padding: noPadding ? 0 : 8 }}>
      <View style={[{ display: 'flex', flexDirection: 'row', alignItems: 'center' }, mb5]}>
        <View style={mr5}>
          <AlertCircle color={colors.info} width={20} height={20} />
        </View>
        <SmallNsRegularHint style={flex1}>
          The notes written in this tab will only be visible to the doctor.
        </SmallNsRegularHint>
      </View>

      <Controller
        name="note"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
          <FloatingInput
            placeholder={loading ? 'Loading...' : editable ? 'Enter a private note about this consultation' : ''}
            value={value}
            onChangeValue={onChange}
            style={{ padding: 0 }}
            multiline
            numberOfLines={6}
            editable={editable && !loading}
            height={300}
            error={isSubmitted && !!error?.message}
            errorMessage={isSubmitted && error?.message}
            onBlur={saveOnBlur ? saveNote : null}
            maxLength={validationSchema.string.maxLength}
          />
        )}
      />
    </View>
  );
};
