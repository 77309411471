import { combineReducers } from '@reduxjs/toolkit';

import accountsReducer from './accountsReducer';
import appDetailsReducer from './appDetailsReducer';
import consultationDetailsReducer from './consultationDetailsReducer';
import dependantsReducer from './dependantsReducer';
import approvalConsultationRequestsQueueReducer from './doctor/approvalRequestsQueueReducer';
import availabilitiesReducer from './doctor/availabilitiesReducer';
import calendarEventsReducer from './doctor/calendarAppointmentsReducer';
import educationCentreReducer from './educationCentre';
import healthProfileReducer from './healthProfileReducer';
import healthRecordReducer from './healthRecordReducer';
import helpReducer from './helpReducer';
import modalReducer from './modalReducer';
import overlayReducer from './overlayReducer';
import appointmentReducer from './patient/appointmentReducer';
import doctorListingReducer from './patient/doctorListingReducer';
import insuranceDetailsReducer from './patient/insuranceDetailsReducer';
import prescriptionsReducer from './patient/prescriptionsReducer';
import profileCompletionReducer from './patient/profileCompletionReducer';
import pharmacyPrescriptionsReducer from './pharmacy/pharmacyPrescriptionsReducer';
import pharmacyReducer from './pharmacy/pharmacyReducer';
import referenceDataReducer from './referenceDataReducer';
import requestsReducer from './requestsReducer';
import routeReducer from './routeReducer';
import tablePaginationReducer from './tablePaginationReducer';
import tokenInviteReducer from './tokenInviteReducer';
import userDetailsReducer from './userDetailsReducer';

const rootReducer = combineReducers({
  accountsReducer,
  appDetailsReducer,
  appointmentReducer,
  approvalConsultationRequestsQueueReducer,
  availabilitiesReducer,
  calendarEventsReducer,
  consultationDetailsReducer,
  dependantsReducer,
  doctorListingReducer,
  educationCentreReducer,
  healthProfileReducer,
  healthRecordReducer,
  helpReducer,
  insuranceDetailsReducer,
  modalReducer,
  overLayReducer: overlayReducer,
  pharmacyPrescriptionsReducer,
  pharmacyReducer,
  prescriptionsReducer,
  referenceDataReducer,
  requestsReducer,
  routeReducer,
  userDetailsReducer,
  tablePaginationReducer,
  profileCompletionReducer,
  tokenInviteReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
