import { Route, useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { View } from 'react-native';

import { PatientsLayout } from '../../../components/doctor/patients/PatientsLayout';
import { PatientsTable } from '../../../components/doctor/patients/PatientsTable';

import { flex1, mb10 } from '~/common/commonStyles';
import { PatientDetailsMenu } from '~/components/PatientDetailsMenu';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { H2TtmSemiBoldBlack } from '~/components/commonText';
import { DashboardMobileHeader } from '~/components/dashboard/doctor/DashboardHeader/DashboardMobileHeader';
import { PatientSearch } from '~/components/doctor/patients/PatientSearch';
import { UploadPatientDetailsModal } from '~/components/modals/UploadPatientDetailsModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isClinicVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { patientManagementLabels } from '~/utils/labels/patientManagement';
import { DigimedMilestones, digimedMilestoneEnabled } from '~/utils/milestoneUtil';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { q?: string }>;
}

export const PatientsListing: React.FC<Props> = ({ route }) => {
  const { isMobile } = useBreakpoints();
  const { navigate } = useNavigation();

  const { registerModal, openModal } = useModalManager();

  const headerIcon = isMobile ? null : !isClinicVersion() ? <PatientDetailsMenu /> : null;

  useEffect(() => {
    registerModal(ModalName.UploadPatientDetails, UploadPatientDetailsModal);
  }, []);

  const footer = !isMobile ? null : (
    <CancelContinueFooter
      confirmTitle={labels.addNewPatient}
      cancelTitle={patientManagementLabels.uploadPatientAction.toUpperCase()}
      onCancel={() => {
        openModal(ModalName.UploadPatientDetails);
      }}
      onConfirm={() => {
        navigate(screenName.PatientAddNew as never);
      }}
    />
  );
  return (
    <View style={{ display: 'flex', flex: 1 }}>
      {isMobile && !digimedMilestoneEnabled(DigimedMilestones.DoctorPatientApps) ? <DashboardMobileHeader /> : null}
      <PatientsLayout
        hideBack
        footer={footer}
        contentStyling={flex1}
        headerIcon={headerIcon}
        flexContent={false}
        title="Patients"
        hideGradient={!isMobile}
        header={
          isMobile ? (
            <View style={[flex1, mb10]}>
              <H2TtmSemiBoldBlack style={{ marginBottom: 10 }}>Patients</H2TtmSemiBoldBlack>
              <PatientSearch />
            </View>
          ) : null
        }
        scrollContentNode={<PatientsTable query={route.params?.q} />}
      />
    </View>
  );
};
