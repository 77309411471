import { useMemo, useState } from 'react';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { SnomedTermModel } from '~/api/models/clinicalTerms/models/SnomedTermModel';
import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { apiSearchClinicalTerm } from '~/api/services/clinicalTerms';
import { LabelValuePair } from '~/common/types/LabelValuePair';
import { ErrorAlert } from '~/components/modals/ErrorAlert';

export interface ClinicalTermLabelValue<T = number> extends LabelValuePair<T> {
  fullySpecifiedName?: SnomedTermModel;
  snomedTerm?: SnomedTermModel;
}
interface Props {
  type: ClinicalTermTypeEnum;
}
export function useClinicalTermsSearchHook({ type }: Props) {
  const [clinicalTerms, setClinicalTerms] = useState<SnomedTermModel[]>([]);
  const [apiPagination, setApiPagination] = useState<PaginationMetaModel>(null);

  const search = async (searchValue: string) => {
    if (!searchValue) {
      setClinicalTerms([]);
      return;
    }
    try {
      const response = await apiSearchClinicalTerm({
        type,
        q: searchValue,
      });

      setApiPagination(response.data.meta);
      setClinicalTerms(response.data.data);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const clinicalTermsLabelValue = useMemo<ClinicalTermLabelValue[]>(
    () =>
      clinicalTerms.map((term) => ({
        label: term.term,
        value: term.id,
        fullySpecifiedName: term.id !== term.fully_specified_name?.id ? term.fully_specified_name : null,
        snomedTerm: term,
      })),
    [clinicalTerms]
  );

  return {
    clinicalTermSearch: search,
    clinicalTermsLabelValue,
    clinicalTerms,
    apiPagination,
  };
}
