import { deleteReq, get, post, put } from '~/api/axios';
import { IdModel } from '~/api/models/common/models/IdModel';
import { PaginatedRequest } from '~/api/models/common/requests/PaginatedRequest';
import { GetMediaCollectionResponse } from '~/api/models/common/responses/GetMediaCollectionResponse';
import { AddHealthRecordMediaRequest } from '~/api/models/consultations/requests/AddHealthRecordMediaRequest';
import { ConsultationRequest } from '~/api/models/consultations/requests/ConsultationRequest';
import { CreateHealthRecordRequest } from '~/api/models/consultations/requests/CreateHealthRecordRequest';
import { ModifyHealthRecordSymptomRequest } from '~/api/models/consultations/requests/ModifyHealthRecordSymptomRequest';
import { RemoveHealthRecordMediaRequest } from '~/api/models/consultations/requests/RemoveHealthRecordMediaRequest';
import { UpdateHealthRecordAnswerRequest } from '~/api/models/consultations/requests/UpdateHealthRecordAnswerRequest';
import { UpdateHealthRecordRequest } from '~/api/models/consultations/requests/UpdateHealthRecordRequest';
import { CreateHealthRecordResponse } from '~/api/models/consultations/responses/CreateHealthRecordResponse';
import { HealthRecordAnswerResponse } from '~/api/models/consultations/responses/HealthRecordAnswerResponse';
import { HealthRecordMediaIndexResponse } from '~/api/models/consultations/responses/HealthRecordMediaIndexResponse';
import { HealthRecordResponse } from '~/api/models/consultations/responses/HealthRecordResponse';
import { HealthRecordSymptomsResponse } from '~/api/models/consultations/responses/HealthRecordSymptomsResponse';
import { HealthRecordTemplateIndexResponse } from '~/api/models/consultations/responses/HealthRecordTemplateIndexResponse';
import { HealthRecordTemplateQuestionsResponse } from '~/api/models/consultations/responses/HealthRecordTemplateQuestionsResponse';
import fileSystem from '~/integrations/fileSystem';

// Health Record Templates
export const apiGetHealthRecordTemplates = (req?: PaginatedRequest) => {
  return get<HealthRecordTemplateIndexResponse>('/health-record-templates', { params: req });
};

export const apiGetHealthRecordTemplateQuestions = (req: IdModel) => {
  return get<HealthRecordTemplateQuestionsResponse>(`/health-record-templates/${req.id}/questions`);
};

// Health Record Answers
export const apiGetHealthRecordAnswers = (req: IdModel) => {
  return get<HealthRecordAnswerResponse>(`/health-records/${req.id}/answers`);
};

export const apiUpdateHealthRecordAnswers = (req: UpdateHealthRecordAnswerRequest) => {
  return put<HealthRecordAnswerResponse>(`/health-records/${req.healthRecordId}/answers`, req.params);
};

// Health Record
export const apiGetHealthRecord = (req: ConsultationRequest) => {
  return get<HealthRecordResponse>(`/consultations/${req.consultationId}/health-record`);
};

export const apiCreateHealthRecord = (req: CreateHealthRecordRequest) => {
  return post<CreateHealthRecordResponse>(`/consultations/${req.consultationId}/health-record`, req.params);
};

export const apiUpdateHealthRecord = (req: UpdateHealthRecordRequest) => {
  return put(`/health-record/${req.healthRecordId}`, req.params);
};

// Health Record Media
export const apiGetHealthRecordMedia = (req: IdModel) => {
  return get<HealthRecordMediaIndexResponse>(`/health-records/${req.id}/media`);
};

export const apiGetHealthRecordMediaGeneratedPDFs = (req: IdModel) => {
  return get<GetMediaCollectionResponse>(`/health-records/${req.id}/media/generated-pdf`);
};

export const apiAddHealthRecordMedia = (req: AddHealthRecordMediaRequest) => {
  return fileSystem.postDocumentResult(`/health-records/${req.healthRecordId}/media`, 'file', req.document);
};

export const apiRemoveHealthRecordMedia = (req: RemoveHealthRecordMediaRequest) => {
  return deleteReq(`/health-records/${req.healthRecordId}/media/${req.mediaId}`);
};

export const apiHealthRecordMediaDownload = (req: IdModel) => {
  return fileSystem.downloadStream(`/health-records/${req.id}/media/download`, {
    fileName: `HealthRecords-${req.id}.zip`,
  });
};

// Health Record Template Symptoms
export const apiGetHealthRecordTemplateSymptoms = (req: IdModel) => {
  return get<HealthRecordSymptomsResponse>(`/health-records/${req.id}/template-symptoms`);
};

export const apiUpdateHealthRecordTemplateSymptom = (req: ModifyHealthRecordSymptomRequest) => {
  return put(`health-records/${req.id}/template-symptoms`, req.params);
};
