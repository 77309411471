import React, { useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { PatientDiagnosesList } from '../../PatientDiagnosesList';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { m5, mb5, ml10, mt10, mt20 } from '~/common/commonStyles';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { FormErrorMessage, H6NsRegularBlack } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { RadioButtons } from '~/components/inputs/RadioButtons';
import { ClinicalTermSearchableList } from '~/components/inputs/searchable/ClinicalTermSearchableList';
import { RadioButtonsYesNo } from '~/constants/radioButtons/radioButtonOptions';
import { useDiagnosisContext } from '~/providers/diagnosis/DiagnosisContext';
import { requiredValidation, validationSchema } from '~/services/validationConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props {
  hideReason?: boolean;
  hideButtons?: boolean;
}
export const CreateDiagnosisEditingComponent: React.FC<Props> = ({ hideButtons, hideReason }) => {
  const { isDesktop } = useBreakpoints();

  const mobileFlex = useMemo<ViewStyle>(() => (!isDesktop ? { display: 'flex', flex: 1 } : null), [isDesktop]);

  const { formControl, patientId, submit, loading, hideCancel, cancel } = useDiagnosisContext();

  const { exists } = useWatch({ control: formControl });
  return (
    <View style={[!isDesktop ? null : styles.container, mobileFlex]}>
      <H6NsRegularBlack>Is this part of an existing diagnosis?</H6NsRegularBlack>

      <Controller
        name="exists"
        control={formControl}
        render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
          <RadioButtons
            items={RadioButtonsYesNo}
            selected={value}
            onValueChange={onChange}
            error={isSubmitted && !!error}
            errorMessage={isSubmitted && error?.message}
            horizontal
            style={mt10}
          />
        )}
      />
      {exists ? (
        <View style={[mt10, mobileFlex]}>
          <H6NsRegularBlack>Choose an existing diagnosis</H6NsRegularBlack>

          <Controller
            name="diagnosisId"
            control={formControl}
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <View style={mobileFlex}>
                <View style={[styles.diagnosesContainer, mobileFlex]}>
                  <PatientDiagnosesList
                    id={patientId}
                    onRowClick={onChange}
                    selected={value ? [value] : []}
                    wrapperStyle={!isDesktop ? styles.tableBorder : styles.tableShadow}
                    rowStyle={[m5, { marginRight: 20 }]}
                    hideHeaders
                    existingDiagnoses
                    forceInfiniteScroll
                  />
                </View>
                {isSubmitted && error?.message ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
              </View>
            )}
            rules={{
              required: requiredValidation('Existing diagnosis'),
            }}
          />
        </View>
      ) : (
        <Controller
          name="diagnosis"
          control={formControl}
          render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <ClinicalTermSearchableList
              type={ClinicalTermTypeEnum.DIAGNOSES}
              values={value ? [value] : null}
              setValues={(values) => {
                onChange(values?.length ? values[0] : undefined);
              }}
              singleSelect
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
              title="Diagnosis"
            />
          )}
          rules={{
            required: requiredValidation('Diagnosis name'),
          }}
        />
      )}

      {!hideReason ? (
        <>
          <H6NsRegularBlack style={[mt20, mb5, ml10]}>Was there a reason for this consultation?</H6NsRegularBlack>
          <View style={ml10}>
            <Controller
              name="consultationReason"
              control={formControl}
              render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <FloatingInput
                  value={value}
                  onChangeValue={onChange}
                  onBlur={onBlur}
                  error={isSubmitted && !!error?.message}
                  errorMessage={isSubmitted && error?.message}
                  maxLength={validationSchema.string.maxLength}
                  multiline
                  label="Consultation reason"
                  placeholder="Write a short description (Optional)"
                />
              )}
            />
          </View>
        </>
      ) : null}
      {!hideButtons ? (
        <View style={styles.buttonContainer}>
          <View style={styles.buttonWidth}>
            <CancelContinueFooter
              onConfirm={submit}
              confirmTitle={exists ? labels.continue : labels.create}
              onCancel={cancel}
              waiting={loading}
              hideCancel={hideCancel}
            />
          </View>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.lightPurple2,
    padding: 10,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: 10,
  },
  buttonWidth: {
    minWidth: 120,
  },
  diagnosesContainer: {
    minHeight: 200,
    marginTop: 10,
  },
  tableShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
  },
  tableBorder: {
    borderWidth: 1,
    borderRadius: 6,
  },
});
