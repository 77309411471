import { Route } from '@react-navigation/native';
import React, { useState } from 'react';

import { useDependant } from '~/api/hooks/dependants/DependantHook';
import { PatientEditableHealthProfile } from '~/components/doctor/patients/healthProfile/PatientEditableHealthProfile';
import { HealthProfileDataProvider } from '~/providers/healthProfile/HealthProfileDataProviders';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

interface Props {
  route: Route<string, { id: string }>;
}
export const DependantHealthProfile: React.FC<Props> = ({ route }) => {
  const id = Number(route.params.id);
  const [editing, setEditing] = useState(false);
  const { dependant } = useDependant({ id });
  const { isMobile } = useBreakpoints();

  return (
    <HealthProfileDataProvider accountId={dependant?.account.id} onSaved={() => setEditing(false)}>
      <PatientEditableHealthProfile
        hideBack={!isMobile}
        backLink={{ screen: screenName.DependantsShow, params: route?.params }}
        editing={editing}
        onStartEditing={() => setEditing(true)}
        onCancel={() => setEditing(false)}
      />
    </HealthProfileDataProvider>
  );
};
