import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { StyleProp, ViewStyle } from 'react-native';

import { NotificationType } from '~/api/models/settings/constants/NotificationsEnum';
import { alignItemsCenter, mt20, mt25 } from '~/common/commonStyles';
import { H6NsRegularBlack, SmallNsRegularSecondaryBlack } from '~/components/commonText';
import { Checkbox } from '~/components/inputs/Checkbox';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props {
  notificationSettings: { mail: boolean; notification: boolean };
  notificationType: NotificationType;
  form: { control: any };
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
}

const labelSubtitleMappings = {
  [NotificationType.PRODUCT_NEWS]: { label: labels.productNews, subtitle: labels.reviewNewsAndLatesProductUpdates },
  [NotificationType.REMINDERS]: { label: labels.reminders, subtitle: labels.notificationRemindYouAppointments },
  [NotificationType.CONSULTATION_MESSAGE]: {
    label: labels.consultationMessageNotification,
    subtitle: labels.consultationMessageNotificationDescription,
  },
  [NotificationType.HEALTH_COMPANION]: {
    label: labels.healthCompanionNotification,
    subtitle: labels.healthCompanionNotificationMessage,
  },
};

export const SettingsNotificationRow: React.FC<Props> = ({
  notificationSettings,
  notificationType,
  form,
  disabled,
  style,
}) => {
  const { isMobile } = useBreakpoints();

  const objectKeys = useMemo(() => {
    return Object.keys(notificationSettings);
  }, [notificationSettings]);

  const label = useMemo(() => {
    const labelSubtitleObject = labelSubtitleMappings[notificationType];
    if (labelSubtitleObject) {
      return labelSubtitleObject.label;
    } else
      notificationType.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
      });
  }, [notificationType]);

  const subtitle = useMemo(() => labelSubtitleMappings[notificationType]?.subtitle ?? '', [notificationType]);

  return (
    <Row style={[mt25, style]}>
      <Column span={{ xs: 12, md: 6 }}>
        <H6NsRegularBlack style={disabled ? { color: colors.placeholderGrey } : null}>{label}</H6NsRegularBlack>
        <SmallNsRegularSecondaryBlack numberOfLines={2} style={disabled ? { color: colors.placeholderGrey } : null}>
          {subtitle}
        </SmallNsRegularSecondaryBlack>
      </Column>
      <Column>
        <Row style={isMobile ? mt20 : null}>
          {objectKeys.map((item) => {
            return (
              <Column span={{ xs: 12, md: 6 }} key={item} style={!isMobile ? alignItemsCenter : null}>
                <Controller
                  name={`${notificationType}.${item}`}
                  control={form.control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      checked={value}
                      label={isMobile ? (item === 'mail' ? labels.email : labels.notification) : ''}
                      onChange={onChange}
                      disabled={disabled}
                    />
                  )}
                />
              </Column>
            );
          })}
        </Row>
      </Column>
    </Row>
  );
};
