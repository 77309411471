export enum PageTypesEnum {
  PATIENT_TERM_AND_CONDITIONS = 'patient-terms-and-conditions',
  PHARMACY_TERM_AND_CONDITIONS = 'pharmacy-terms-and-conditions',
  DOCTOR_TERM_AND_CONDITIONS = 'physician-terms-and-conditions',
  CLINIC_TERM_AND_CONDITIONS = 'clinic-terms-and-conditions',
  PRIVACY_POLICY = 'privacy-policy',
  COOKIE_POLICY = 'cookie-policy',
  REDUCE_FILE_SIZE = 'reduce-file-size',
  PATIENT_CANCELLATION_POLICY = 'patient-cancellation-policy',
  DOCTOR_CANCELLATION_POLICY = 'healthcare-professional-cancellation-policy',
}
