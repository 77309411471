import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { colors } from '../utils/colors';

import { Column, Container, Grid, Row } from '~/theme/components/grid';

interface Props {
  children: React.ReactNode;
  rowStyle?: StyleProp<ViewStyle>;
  hideTabs?: boolean;
  search?: {
    onSubmitSearch: (searchText: string) => void;
    placeholder: string;
  };
}

export const RequestsLayout: React.FC<Props> = ({ children, rowStyle, search, hideTabs }) => {
  return (
    <View style={styles.container}>
      <Grid>
        <Container style={styles.content} alignSelf="flex-start">
          <Row style={[styles.row, rowStyle]}>
            <Column style={styles.column}>{children}</Column>
          </Row>
        </Container>
      </Grid>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
    display: 'flex',
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
    alignItems: 'center',
  },
  row: {
    flex: 1,
  },
  column: {
    height: '100%',
  },
  button: {
    width: '6%',
  },
  arrowWithText: {
    marginLeft: 5,
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: 'red',
  },
});
