import OT, { Session } from '@opentok/client';
import React, { useContext, useEffect, useState } from 'react';
import { StyleProp, ViewProps, ViewStyle } from 'react-native';

import { OTSessionEvents } from '../types/OTEvents';

interface IOpenTokContext {
  sessionId: string;
  token: string;
  session: Session;
}
const OpenTokContext = React.createContext<IOpenTokContext>({} as IOpenTokContext);

interface Props extends Partial<ViewProps> {
  apiKey: string;
  sessionId: string;
  token: string;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  options?: any;
  eventHandlers?: OTSessionEvents;
}

export const useOpenTokSession = (): IOpenTokContext => useContext(OpenTokContext);

export const OTSession: React.FC<Props> = ({ apiKey, sessionId, token, children, style, eventHandlers }) => {
  const [session, setSession] = useState<Session>();

  useEffect(() => {
    if (!apiKey || !sessionId) return;

    const session = OT.initSession(apiKey, sessionId);
    setSession(session);

    return () => {
      session.disconnect();
    };
  }, [apiKey, sessionId]);

  useEffect(() => {
    if (!session) return;

    session.on(eventHandlers);

    return () => session.off(eventHandlers);
  }, [eventHandlers, session]);

  return (
    <OpenTokContext.Provider
      value={{
        sessionId,
        session,
        token,
      }}
      // @ts-ignore: Not sure if it gets applied on web
      style={style}>
      {children}
    </OpenTokContext.Provider>
  );
};
