import React, { useMemo } from 'react';
import { Platform, View, Text } from 'react-native';

import { DashboardMobileLayout } from '../layouts/DashboardMobileLayout';
import { DashboardWebLayout } from '../layouts/DashboardWebLayout';
import { LayoutEnums } from '../layouts/LayoutEnums';
import { OnBoardingLayout } from '../layouts/OnBoardingLayout';

import { useStoredRoute } from '~/redux/hooks/StoredRoute';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface ILayoutProvider {
  layout: LayoutEnums;
  children?: React.ReactNode;
}

export const LayoutProvider: React.FC<ILayoutProvider> = ({ layout, children }) => {
  const { route } = useStoredRoute();
  const { isMobile } = useBreakpoints();

  const getLayout = (layout: string, children?: React.ReactNode) => {
    switch (layout) {
      case LayoutEnums.DEFAULT:
        return Platform.select({
          web: isMobile ? (
            <DashboardMobileLayout>{children}</DashboardMobileLayout>
          ) : (
            <DashboardWebLayout>{children}</DashboardWebLayout>
          ),
          native: <DashboardMobileLayout>{children}</DashboardMobileLayout>,
        });

      case LayoutEnums.ON_BOARDING:
        return <OnBoardingLayout>{children}</OnBoardingLayout>;

      case LayoutEnums.BLANK:
        return <>{children}</>;

      default:
        // TODO:[sc-7983] Layout error page
        return (
          <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            <Text style={{ fontWeight: 'bold', fontSize: 24, marginBottom: 10 }}>Oops, how did you end up here?</Text>
            <Text>Something went wrong with the navigation</Text>
          </View>
        );
    }
  };

  const screen = useMemo(() => {
    return getLayout(layout, children);
  }, [layout, isMobile, route]);

  return <>{screen}</>;
};
