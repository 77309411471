import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/reducers';
import { UPDATE_RECREATIONAL_DRUGS_LIST } from '../../redux/reducers/consultationDetailsReducer';
import { useAppDispatch } from '../../redux/store';
import { apiGetAllRecreationalDrugs } from '../services/healthProfile/recreationalDrugs';

export function useRecreationalDrugsListing() {
  const recreationalDrugsData = useSelector(
    (state: RootState) => state.consultationDetailsReducer.recreationalDrugsList
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!recreationalDrugsData?.length) {
      apiGetAllRecreationalDrugs().then((res) => {
        dispatch(UPDATE_RECREATIONAL_DRUGS_LIST(res.data));
      });
    }
  }, [recreationalDrugsData]);

  const filteredRecreationalDrugsData = useMemo(() => {
    return recreationalDrugsData?.filter((item) => item.name.toLowerCase() !== 'none');
  }, [recreationalDrugsData]);

  return { recreationalDrugsData: filteredRecreationalDrugsData };
}
