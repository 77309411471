import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';

import { useHealthRecordDataContext } from '~/providers/consultation/HealthRecordDataContext';
import { usePrescriptionDataContext } from '~/providers/consultation/PrescriptionDataContext';
import { usePrivateNoteDataContext } from '~/providers/consultation/PrivateNoteDataContext';
import { useReferralDataContext } from '~/providers/consultation/ReferralDataContext';
import { useSickLeaveDataContext } from '~/providers/consultation/SickLeaveDataContext';
import { SET_CONSULTATION_SAVING, consultationSavingSelector } from '~/redux/reducers/consultationDetailsReducer';
import { useAppDispatch } from '~/redux/store';

type SaveMethodType = () => Promise<any>;
type VerifyMethodType = () => Promise<boolean>;

export interface IConsultationReportContext {
  includeSignature: boolean;
  setIncludeSignature: (value: boolean) => void;

  triggerAsyncSave: SaveMethodType;
  triggerVerification: VerifyMethodType;

  saving?: boolean;
  appointmentView?: boolean;
}

const ConsultationReportContext = React.createContext<IConsultationReportContext>({} as IConsultationReportContext);

export const useConsultationReportContext = (): IConsultationReportContext => useContext(ConsultationReportContext);

export interface ConsultationReportProviderRef {
  triggerAsyncSave: SaveMethodType;
  triggerVerification: VerifyMethodType;
  getIncludeSignature: () => boolean;
}

interface Props {
  appointmentView?: boolean;
  children: React.ReactNode;
}
export const ConsultationReportProvider = forwardRef(({ children, appointmentView }: Props, ref) => {
  const [includeSignature, setIncludeSignature] = useState(false);
  const dispatch = useAppDispatch();
  const saving = useSelector(consultationSavingSelector);
  const privateNoteContext = usePrivateNoteDataContext();
  const healthRecordContext = useHealthRecordDataContext();
  const prescriptionContext = usePrescriptionDataContext();
  const sickLeaveContext = useSickLeaveDataContext();
  const referralContext = useReferralDataContext();

  useImperativeHandle(ref, () => ({
    triggerAsyncSave,
    triggerVerification,
    getIncludeSignature: () => {
      return includeSignature;
    },
  }));

  const triggerAsyncSave = async () => {
    if (privateNoteContext?.submit) await privateNoteContext.submit();
    if (healthRecordContext?.submit) await healthRecordContext.submit();
    if (prescriptionContext?.submit) await prescriptionContext.submit();
    if (sickLeaveContext?.submit) await sickLeaveContext.submit();
    if (referralContext?.submit) await referralContext.submit();
  };

  const triggerVerification = async () => {
    const privateNoteVerified = !privateNoteContext?.verifyForm || (await privateNoteContext.verifyForm());
    const healthRecordVerified = !healthRecordContext?.verifyForm || (await healthRecordContext.verifyForm());
    const prescriptionVerified = !prescriptionContext?.verifyForm || (await prescriptionContext.verifyForm());
    const sickLeaveVerified = !sickLeaveContext?.verifyForm || (await sickLeaveContext.verifyForm());
    const referralVerified = !referralContext?.verifyForm || (await referralContext.verifyForm());

    return privateNoteVerified && healthRecordVerified && prescriptionVerified && sickLeaveVerified && referralVerified;
  };

  useEffect(() => {
    const saving =
      privateNoteContext?.saving ||
      healthRecordContext?.saving ||
      prescriptionContext?.saving ||
      sickLeaveContext?.saving ||
      referralContext?.saving;

    dispatch(SET_CONSULTATION_SAVING(saving));
  }, [
    privateNoteContext?.saving,
    healthRecordContext?.saving,
    prescriptionContext?.saving,
    sickLeaveContext?.saving,
    referralContext?.saving,
  ]);

  return (
    <ConsultationReportContext.Provider
      value={{
        includeSignature,
        setIncludeSignature,
        triggerAsyncSave,
        triggerVerification,
        saving,
        appointmentView,
      }}>
      {children}
    </ConsultationReportContext.Provider>
  );
});
