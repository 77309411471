import { FlashList } from '@shopify/flash-list';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { Divider } from 'react-native-paper';

import { CalendarEventResponse } from '~/api/models/calendar/responses/CalendarEventResponse';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { CalendarEventModel } from '~/api/models/preferences/models/CalendarEventModel';
import { apiCalendarEvents } from '~/api/services/calendar';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { LinkButton } from '~/components/buttons/LinkButton';
import ConsultationAgendaItem from '~/components/calendar/ConsultationAgendaItem';
import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { colors } from '~/utils/colors';
import { LARAVEL_DATE_TIME_SHORT } from '~/utils/dateAndTime';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const DoctorTodayAppointmentsMobile: React.FC = (props) => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<CalendarEventResponse>([]);

  const getEvents = async (date: Moment) => {
    setLoading(true);
    try {
      const from = date.startOf('day').format(LARAVEL_DATE_TIME_SHORT);
      const to = date.endOf('day').format(LARAVEL_DATE_TIME_SHORT);

      const calendarEvents = await apiCalendarEvents({
        filter: { type: ['consultation', 'unavailability'].join(',') },
        to,
        from,
      });
      setEvents(calendarEvents.data);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  usePageFocus(() => {
    getEvents(moment());
  });

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, () => {
      getEvents(moment());
    });
    return removeListener;
  }, []);

  const todaysEvents = useMemo(() => {
    if (loading && !events?.length) return [];
    return (
      (events?.length
        ? events[0]?.events?.filter(
            (event) =>
              event.eventable.state !== ConsultationStateEnum.Started &&
              event.eventable.state !== ConsultationStateEnum.Ended
          )
        : null) || []
    );
  }, [events, loading]);

  return (
    <View style={{ flex: 1, backgroundColor: colors.white, marginBottom: 40 }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <H3TtmSemiBoldBlack style={{ marginBottom: 15 }}>{labels.todaysAppointments}</H3TtmSemiBoldBlack>
        <LinkButton to={{ screen: screenName.CalendarPage } as never} title="VIEW ALL" />
      </View>
      <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
      <FlashList<CalendarEventModel | { id: number }>
        data={todaysEvents}
        keyExtractor={(item) => `${item.id}`}
        ItemSeparatorComponent={() => (
          <View style={{ marginTop: 10, marginBottom: 5 }}>
            <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple }} />
          </View>
        )}
        estimatedItemSize={75}
        ListEmptyComponent={
          <H6NsRegularBlack style={{ paddingTop: 16, paddingBottom: 14 }}>
            {loading ? "We're getting your appointments for today" : 'You do not have any appointments today'}
          </H6NsRegularBlack>
        }
        renderItem={(item) => (
          <ConsultationAgendaItem calendarEvent={item.item as CalendarEventModel} noHorizontalPadding />
        )}
      />
      <Divider style={{ borderWidth: 0.2, borderColor: colors.lightPurple, marginTop: 7 }} />
    </View>
  );
};
