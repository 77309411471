import { Route } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useConsultationDetails } from '~/api/hooks/consultations/ConsultationDetails';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { flex1, mb10 } from '~/common/commonStyles';
import { ClinicDashboardHeader } from '~/components/clinic/headers/ClinicDashboardHeader';
import { ClinicRequestsAssignComponent } from '~/components/clinic/requests/ClinicRequestsAssignComponent';
import { H2TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  route: Route<string, { id: string }>;
}

export const ClinicRequestAssignScreen: React.FC<Props> = ({ route }) => {
  const consultationId = Number(route.params.id);
  const [latestError, setLatestError] = useState<ErrorResponse>();

  const { isMobile } = useBreakpoints();

  const { consultation, loading, getConsultation } = useConsultationDetails({
    consultationId,
    onError: (e) => setLatestError(e),
  });

  useEffect(() => {
    if (consultation && latestError) {
      setLatestError(null);
    }
  }, [consultation]);

  return (
    <PageLayout hideBack hideGradient header={<ClinicDashboardHeader />} contentStyling={!isMobile ? flex1 : null}>
      {loading ? (
        <View style={styles.container}>
          <LoadingActivityIndicator />
        </View>
      ) : latestError ? (
        <View style={styles.container}>
          <H2TtmSemiBoldBlack style={mb10}>
            We were not able to get consultation with ID {consultationId}
          </H2TtmSemiBoldBlack>
          <H6NsRegularBlack>
            It may be the request has times out or was cancelled, and you no longer have access to it. If you believe
            this may be an error please contact support.
          </H6NsRegularBlack>
        </View>
      ) : (
        <ClinicRequestsAssignComponent consultation={consultation} onConsultationChanged={getConsultation} />
      )}
    </PageLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
