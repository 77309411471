import React from 'react';
import { Linking, StyleProp, TextStyle, View } from 'react-native';

import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { GenderTypeEnum } from '~/api/models/common/constants/GenderTypeEnum';
import { mb10, mb5, mr25, mt10 } from '~/common/commonStyles';
import { SmallNsRegularSecondaryBlack, SmallTtmSemiBoldDisabled } from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, calculateAge, parseDateTime } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { getAccountPhone } from '~/utils/personalDetailsUtils';
import { capitalize } from '~/utils/stringUtil';

export interface DetailKeys {
  label?: string;
  key: string;
  format?: (account?: AccountModel, gender?: GenderTypeEnum) => string;
  style?: StyleProp<TextStyle>;
}

const detailKeys: DetailKeys[] = [
  {
    label: labels.fullName,
    key: 'full_name',
    format: (account?: AccountModel) => {
      return `${account?.first_name || ''} ${account?.last_name || ''}`;
    },
    style: { fontWeight: '900' },
  },
  {
    label: labels.patientID,
    key: 'patientID',
    format: (account?: AccountModel) => {
      return account?.passport_number || account?.national_id_number;
    },
  },
  {
    label: labels.phone,
    key: 'phone',
    format: (account: AccountModel) => {
      if (!account) return '';
      return getAccountPhone(account);
    },
    style: { color: colors.info },
  },
  {
    label: labels.email,
    key: 'email',
    style: { color: colors.info },
  },
  {
    label: labels.gender,
    key: 'gender',
    format: (_account: AccountModel, gender: string) => capitalize(gender),
  },
  {
    label: labels.age,
    key: 'age',
    format: (account: AccountModel) => {
      return `${calculateAge(account?.date_of_birth) || ''}`;
    },
  },
  {
    label: labels.dob,
    key: 'date_of_birth',
    format: (account: AccountModel) => {
      return parseDateTime(account?.date_of_birth, { outputFormat: DATE_FORMAT }) || '';
    },
  },
];

interface Props {
  account: AccountModel;
  row?: boolean;
  details?: DetailKeys[];
  hideProfileImage?: boolean;
  shadow?: boolean;
}

export const PatientListedDetails: React.FC<Props> = ({
  account,
  row = false,
  details = detailKeys,
  hideProfileImage = false,
  shadow,
}) => {
  const linkPhoneNumber = (account: AccountModel) => {
    if (!account?.mobile_number) return;
    const phoneNumberUrl = `tel:${getAccountPhone(account)}`;

    Linking.canOpenURL(phoneNumberUrl)
      .then(() => {
        return Linking.openURL(phoneNumberUrl);
      })
      .catch(ErrorAlert);
  };

  const linkEmailAddress = (account: AccountModel) => {
    if (!account?.email) return;

    const emailUrl = `mailto:${account.email}`;
    Linking.canOpenURL(emailUrl)
      .then(() => {
        return Linking.openURL(emailUrl);
      })
      .catch(ErrorAlert);
  };

  return (
    <View style={{ flexDirection: row ? 'row' : 'column' }}>
      {hideProfileImage ? null : (
        <View style={row ? [mr25] : [mb10, mt10]}>
          <ProfileImageComponent size={68} account={account} shadow={shadow} />
        </View>
      )}
      <View style={{ flex: 1 }}>
        {details.map((detailItem) => (
          <Row key={detailItem.key}>
            <Column width={100}>
              <SmallTtmSemiBoldDisabled>{detailItem.label}</SmallTtmSemiBoldDisabled>
            </Column>
            <Column>
              <SmallNsRegularSecondaryBlack
                style={[detailItem?.style, mb5]}
                onPress={
                  detailItem.key === 'phone'
                    ? () => {
                        linkPhoneNumber(account);
                      }
                    : detailItem.key === 'email'
                    ? () => linkEmailAddress(account)
                    : null
                }>
                {detailItem.format
                  ? detailItem.format(account, account && account[detailItem.key])
                  : (account && account[detailItem.key]) || ''}
              </SmallNsRegularSecondaryBlack>
            </Column>
          </Row>
        ))}
      </View>
    </View>
  );
};
