import React from 'react';

import {
  AmexLogo,
  DinersLogo,
  DiscoverLogo,
  JcbLogo,
  MasterCardLogo,
  UnionPayLogo,
  VisaLogo,
} from '~/components/StripeCardLogos';

enum BrandNamesEnum {
  AMEX = 'amex',
  CARTES_BANCAIRES = 'cartes_bancaires',
  DINERS = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MASTERCARD = 'mastercard',
  VISA = 'visa',
  UNIONPAY = 'unionpay',
}

const imageWith: number = 45;
const imageHeight: number = 45;

const Logos = {
  [BrandNamesEnum.AMEX]: <AmexLogo width={imageWith} height={imageHeight} />,
  [BrandNamesEnum.CARTES_BANCAIRES]: <VisaLogo width={imageWith} height={imageHeight} />,
  [BrandNamesEnum.DINERS]: <DinersLogo width={imageWith} height={imageHeight} />,
  [BrandNamesEnum.DISCOVER]: <DiscoverLogo width={imageWith} height={imageHeight} />,
  [BrandNamesEnum.JCB]: <JcbLogo width={imageWith} height={imageHeight} />,
  [BrandNamesEnum.MASTERCARD]: <MasterCardLogo width={imageWith} height={imageHeight} />,
  [BrandNamesEnum.VISA]: <VisaLogo width={imageWith} height={35} />,
  [BrandNamesEnum.UNIONPAY]: <UnionPayLogo width={imageWith} height={imageHeight} />,
};

interface Props {
  brand: string;
}

export const CardBrandLogos: React.FC<Props> = ({ brand }): JSX.Element => {
  return Logos[brand];
};
