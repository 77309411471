import { useEffect } from 'react';

import { usePolling } from '../Polling';

import { MediaStatusEnum } from '~/api/models/common/constants/MediaStatusEnum';

export const useMediaPolling = (getMediaFunction: () => Promise<any>, interval: number, status: MediaStatusEnum) => {
  const { startPolling, stopPolling } = usePolling(getMediaFunction, interval);

  useEffect(() => {
    if (status === MediaStatusEnum.COMPLETED || status === MediaStatusEnum.FAILED || status === null) {
      stopPolling();
    } else {
      startPolling({ immediate: true });
    }

    return () => stopPolling();
  }, [status, getMediaFunction]);
};
