import { apiGetConsultation } from '~/api/services/consultations';

export const useConsultationHelper = () => {
  const getConsultation = async (consultationId: number) => {
    try {
      if (!consultationId) return undefined;
      return (await apiGetConsultation({ id: consultationId })).data;
    } catch {
      return undefined;
    }
  };
  return {
    getConsultation,
  };
};
