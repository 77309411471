import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/reducers';
import { UPDATE_ALCOHOL_CONSUMPTIONS_LIST } from '../../redux/reducers/consultationDetailsReducer';
import { useAppDispatch } from '../../redux/store';
import { apiGetAllAlcoholConsumptions } from '../services/healthProfile/alcoholConsumptions';

export function useAlcoholConsumptionsListing() {
  const alcoholConsumptionsData = useSelector(
    (state: RootState) => state.consultationDetailsReducer.alcoholConsumptionsList
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!alcoholConsumptionsData?.length) {
      apiGetAllAlcoholConsumptions().then((res) => {
        dispatch(UPDATE_ALCOHOL_CONSUMPTIONS_LIST(res.data));
      });
    }
  }, [alcoholConsumptionsData]);

  return { alcoholConsumptionsData };
}
