import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useContext } from 'react';
import { Control } from 'react-hook-form';

import { PatientConsultationFormRequest } from '~/api/models/appointments/requests/PatientConsultationRequest';
import { PatientConsultationResponse } from '~/api/models/appointments/responses/PatientConsultationResponse';
import { UseWizard } from '~/utils/hooks/WizardStepsHook';

export interface IAppointmentBookingContext {
  consultationRequest?: PatientConsultationResponse;
  formControl?: Control<PatientConsultationFormRequest>;
  wizard: UseWizard;
  updateSteps: () => Promise<void>;
  updateRequest: () => Promise<void>;
  validateRequest: (onSuccess?: () => Promise<void>) => Promise<void>;
  submitRequest: (params: { paymentMethodId: string }) => Promise<void>;
  generateHealthProfileUrl: () => string;
  media: {
    appendMedia: (document: DocumentPickerAsset) => Promise<void>;
    removeMedia: (id: number) => Promise<void>;
  };
  loading?: boolean;
  saving?: boolean;
}

export const AppointmentBookingContext = React.createContext<IAppointmentBookingContext>(
  {} as IAppointmentBookingContext
);
export const useAppointmentBookingContext = (): IAppointmentBookingContext => useContext(AppointmentBookingContext);
