import { useMemo, useState } from 'react';

import { useFeatureAccess } from './subscriptions/FeatureAccessHook';
import { ConsultationTypeEnum, ConsultationTypeMapping } from '../models/common/constants/ConsultationTypeEnum';
import { StripeAccountModel } from '../models/stripe/models/StripeAccountModel';
import { FeatureAccessEnum } from '../models/subscriptions/constants/FeatureAccessEnum';
import { apiGetMePreferredChannels } from '../services/accounts/preferences';
import { apiGetConsultationTypePriceIndex } from '../services/consultations/prices';
import { apiGetStripeAccount } from '../services/stripe';

import { whenAppType } from '~/utils/buildConfig';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { screenName } from '~/utils/screenName';

export enum DoctorAvailabilityIssues {
  LOADING,
  STRIPE_CONFIGURATION,
  CHANNEL_TYPE,
  CONSULTATION_DISABLED,
  CONSULTATION_PRICE,
}
type ConsultationTypeCheck = Record<ConsultationTypeEnum, { enabled?: boolean; priceSet?: boolean }>;
type ConsultationChannelCheck = Record<ConsultationTypeEnum, { atLeastOne?: boolean }>;
interface Props {
  type: ConsultationTypeEnum;
}
export const useDoctorAvailabilityCheck = ({ type }: Props) => {
  const [loadingStripe, setLoadingStripe] = useState(true);
  const [loadingConsultationTypes, setLoadingConsultationTypes] = useState(true);
  const [loadingConsultationChannels, setLoadingConsultationChannels] = useState(true);
  const [hasNecessaryAccess, setHasNecessaryAccess] = useState<boolean>();
  const [stripeAccount, setStripeAccount] = useState<StripeAccountModel>();
  const [consultationTypeChecks, setConsultationTypeChecks] = useState<ConsultationTypeCheck>();
  const [consultationChannelsCheck, setConsultationChannelCheck] = useState<ConsultationChannelCheck>();

  const { hasFeatureAccess } = useFeatureAccess();

  const currentProblem = useMemo(() => {
    if (!hasNecessaryAccess) return undefined;

    if (loadingStripe) {
      return DoctorAvailabilityIssues.LOADING;
    } else if (!stripeAccount?.is_enabled) {
      return DoctorAvailabilityIssues.STRIPE_CONFIGURATION;
    }

    if (loadingConsultationChannels) {
      return DoctorAvailabilityIssues.LOADING;
    } else if (!consultationChannelsCheck || !consultationChannelsCheck[type]?.atLeastOne) {
      return DoctorAvailabilityIssues.CHANNEL_TYPE;
    }

    if (loadingConsultationTypes) {
      return DoctorAvailabilityIssues.LOADING;
    } else {
      const typeCheck = consultationTypeChecks && consultationTypeChecks[type];
      if (!typeCheck || (typeCheck.enabled && typeCheck.priceSet)) return undefined;

      if (!typeCheck.enabled) return DoctorAvailabilityIssues.CONSULTATION_DISABLED;
      if (!typeCheck.priceSet) return DoctorAvailabilityIssues.CONSULTATION_PRICE;
    }
  }, [
    stripeAccount,
    consultationTypeChecks,
    consultationChannelsCheck,
    loadingStripe,
    loadingConsultationTypes,
    loadingConsultationChannels,
    type,
  ]);

  const currentProblemInfo = useMemo(() => {
    switch (currentProblem) {
      case DoctorAvailabilityIssues.STRIPE_CONFIGURATION:
        return {
          title: 'Your stripe account is not setup properly',
          message:
            'Please follow the link to fix the issue, otherwise you will not be seen as available for consultations.',
          link: whenAppType({
            whenDoctor: { screen: screenName.ProfileScreen, params: { screen: screenName.ProfilePaymentDetails } },
            whenClinic: {
              screen: screenName.SettingsClinic,
              params: { screen: screenName.SettingsClinicPaymentDetails },
            },
          }),
        };
      case DoctorAvailabilityIssues.CONSULTATION_DISABLED:
        return {
          title: `Your ${ConsultationTypeMapping[type].toLowerCase()} consultation type is disabled`,
          message: `Please follow the link to enable your ${ConsultationTypeMapping[
            type
          ].toLowerCase()} consultation type and set a maximum price, otherwise you will not be seen as available for consultations.`,
          link: whenAppType({
            whenDoctor: { screen: screenName.Settings, params: { screen: screenName.SettingsConsultations } },
            whenClinic: {
              screen: screenName.SettingsClinic,
              params: { screen: screenName.SettingsClinicConsultations },
            },
          }),
        };
      case DoctorAvailabilityIssues.CONSULTATION_PRICE:
        return {
          title: `Your ${ConsultationTypeMapping[type].toLowerCase()} consultation price is not set`,
          message: `Please follow the link to set your ${ConsultationTypeMapping[
            type
          ].toLowerCase()} consultation maximum price, otherwise you will not be seen as available for consultations.`,
          link: whenAppType({
            whenDoctor: { screen: screenName.Settings, params: { screen: screenName.SettingsConsultations } },
            whenClinic: {
              screen: screenName.SettingsClinic,
              params: { screen: screenName.SettingsClinicConsultations },
            },
          }),
        };
      case DoctorAvailabilityIssues.CHANNEL_TYPE:
        return {
          title: `Your preferred consultation channels are not set`,
          message: `Please follow the link to set your preferred means of online consultation, otherwise you will not be seen as available for consultations.`,
          link: whenAppType({
            whenDoctor: { screen: screenName.Settings, params: { screen: screenName.SettingsConsultations } },
            whenClinic: {
              screen: screenName.SettingsClinic,
              params: { screen: screenName.SettingsClinicConsultations },
            },
          }),
        };
      default:
        return undefined;
    }
  }, [currentProblem]);

  const executeStripeCheck = async () => {
    try {
      setLoadingStripe(true);
      const res = await apiGetStripeAccount();
      setStripeAccount(res.data);
    } catch {}
    setLoadingStripe(false);
  };

  const executeConsultationCheck = async () => {
    try {
      setLoadingConsultationTypes(true);
      const res = await apiGetConsultationTypePriceIndex();

      const homeVisit = res.data?.find((item) => item.type === ConsultationTypeEnum.HOME_VISIT);
      const onDemand = res.data?.find((item) => item.type === ConsultationTypeEnum.ON_DEMAND);
      const scheduled = res.data?.find((item) => item.type === ConsultationTypeEnum.SCHEDULED_APPOINTMENT);
      setConsultationTypeChecks({
        [ConsultationTypeEnum.CLINIC]: {},
        [ConsultationTypeEnum.HOME_VISIT]: { enabled: !!homeVisit, priceSet: !!homeVisit?.price.amount },
        [ConsultationTypeEnum.ON_DEMAND]: { enabled: !!onDemand, priceSet: !!onDemand?.price.amount },
        [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: { enabled: !!scheduled, priceSet: !!scheduled?.price.amount },
      });
    } catch {}
    setLoadingConsultationTypes(false);
  };

  const executeConsultationChannelCheck = async () => {
    try {
      setLoadingConsultationChannels(true);
      const res = await apiGetMePreferredChannels();
      const atLeastOneChannel = !!res.data.length;
      setConsultationChannelCheck({
        [ConsultationTypeEnum.CLINIC]: { atLeastOne: true },
        [ConsultationTypeEnum.HOME_VISIT]: { atLeastOne: true },
        [ConsultationTypeEnum.ON_DEMAND]: { atLeastOne: atLeastOneChannel },
        [ConsultationTypeEnum.SCHEDULED_APPOINTMENT]: { atLeastOne: atLeastOneChannel },
      });
    } catch {}
    setLoadingConsultationChannels(false);
  };

  usePageFocus(() => {
    if (
      !hasFeatureAccess(FeatureAccessEnum.IBAN) ||
      !hasFeatureAccess(FeatureAccessEnum.CONSULTATION_TYPES) ||
      !hasFeatureAccess(FeatureAccessEnum.CONSULTATION_PRICES) ||
      !hasFeatureAccess(FeatureAccessEnum.CONSULTATION_CHANNELS)
    ) {
      setLoadingStripe(false);
      setLoadingConsultationTypes(false);
      setLoadingConsultationChannels(false);
      setHasNecessaryAccess(false);
      return;
    } else {
      setHasNecessaryAccess(true);
    }
    executeStripeCheck();
    executeConsultationCheck();
    executeConsultationChannelCheck();
  });

  return { availabilityIssue: currentProblemInfo, loading: currentProblem === DoctorAvailabilityIssues.LOADING };
};
