export const bookingLabels = {
  homeVisit: {
    selectAvailableSlot: 'Select a slot for the patient',
    selectAvailableSlotDescription: 'Select what time you are available for a home visit on',
    expectedDepartureTimeTitle: 'Add expected departure time',
    expectedDepartureTimeDesc:
      'Consider traffic and time to find parking in order to arrive at the destination on time at',
    successMessage:
      'You can view the appointment details in your calendar. You will receive a notification to leave on time for the Home visit.',
    setDepartureTimes: 'Set Departure Times',
  },
  onDemand: {
    successMessage: 'You can join your appointment from your calendar',
  },
  viewCalendar: 'View Calendar',
  appointmentApproved: 'Appointment approved',
  approveRequest: 'Approve request',
  areYouSureYouWantDecline: 'Are sure you want to decline this request?',
};
