import { useNavigation } from '@react-navigation/native';
import React, { ReactNode } from 'react';

import ErrorBoundary from './ErrorBoundary';

import { screenName } from '~/utils/screenName';

interface Props {
  children: ReactNode;
}

export const AuthorisedStackErrorBoundary: React.FC<Props> = ({ children }) => {
  const { navigate } = useNavigation();

  const onErrorRecovery = () => {
    navigate(screenName.Dashboard as never);
  };

  return (
    <ErrorBoundary label="BACK TO HOME" onErrorRecovery={onErrorRecovery}>
      {children}
    </ErrorBoundary>
  );
};
