import { deleteReq, get, post } from '~/api/axios';
import { CalendarEventRequest } from '~/api/models/calendar/requests/CalendarEventRequest';
import { UnavailableRequest } from '~/api/models/calendar/requests/UnavailableRequest';
import { CalendarEventResponse } from '~/api/models/calendar/responses/CalendarEventResponse';
import { GenerateIcalTokenResponse } from '~/api/models/calendar/responses/GenerateIcalTokenResponse';

export const apiCalendarEvents = (params: CalendarEventRequest) => {
  return get<CalendarEventResponse>(`/calendar/events`, params);
};
export const apiSetUnavailable = (body: UnavailableRequest) => {
  return post(`/doctor/unavailabilities`, body);
};
export const apiDeleteUnavailable = (calendaerEvent: number) => {
  return deleteReq<CalendarEventResponse>(`/doctor/unavailabilities/${calendaerEvent}`);
};

export const apiPostGenerateIcalToken = () => {
  return post<GenerateIcalTokenResponse>(`/calendar/ical`);
};
