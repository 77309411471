import { useForm } from 'react-hook-form';

import { useUserDetails } from './accounts/UserDetails';
import { TotpEnrolmentConfirmModel } from '../models/registration/models/TotpEnrolmentConfirmModel';
import { apiEnrolmentTotpConfirm } from '../services/registration';

import { ErrorAlert } from '~/components/modals/ErrorAlert';
interface Props {
  onEnrolmentConfirmed?: () => void;
}

export function useTotpEnrolmentConfirm({ onEnrolmentConfirmed }: Props) {
  const { getUserDetails } = useUserDetails();

  const { control, handleSubmit, reset } = useForm<TotpEnrolmentConfirmModel>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      totp: '',
    },
  });

  const confirmEnrolment = async (details: TotpEnrolmentConfirmModel) => {
    await apiEnrolmentTotpConfirm(details)
      .then(() => {
        getUserDetails();
        if (onEnrolmentConfirmed) onEnrolmentConfirmed();
        reset();
      })
      .catch((error) => {
        ErrorAlert(error);
      });
  };

  return { control, totpConfirmEnrolment: handleSubmit(confirmEnrolment) };
}
