import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { WebViewNavigationEvent } from 'react-native-webview/lib/WebViewTypes';
import { useSelector } from 'react-redux';

import { StripeLink } from './StripeLink';
import { Button } from '../commonButton';
import { ErrorAlert } from '../modals/ErrorAlert';
import { ModalName } from '../modals/constants/ModalNames';

import { useVerificationStatus } from '~/api/hooks/accounts/VerificationStatus';
import { StripeAccountModel } from '~/api/models/stripe/models/StripeAccountModel';
import {
  apiConnectStripeAccount,
  apiLinkStripeAccount,
  apiGetStripeAccount,
  apiStripeCheckOnboarding,
} from '~/api/services/stripe';
import { openLink } from '~/common/commonMethods';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { whenAppType, isNative } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { H6NsRegularBlack } from '../commonText';

interface Props {
  limitWidth?: boolean;
  alreadyOnBoarded?: React.ReactNode;
}
export const StripeConnect: React.FC<Props> = ({ limitWidth, alreadyOnBoarded }) => {
  const [stripeAccount, setStripeAccount] = useState<StripeAccountModel>();
  const [showOnboardButton, setShowOnboardButton] = useState(true);
  const [loading, setLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const { approved } = useVerificationStatus();

  const { openModal, closeModalByName } = useModalManager();

  const createStripeDoctorAccount = async () => {
    try {
      setWaiting(true);
      if (!stripeAccount?.stripe_account_id) await apiConnectStripeAccount();
      const res = await apiLinkStripeAccount();
      if (isNative()) {
        openModal(ModalName.WebViewModal, {
          uri: res.data.url,
          onLoadStart: (e: WebViewNavigationEvent) => {
            if (e?.nativeEvent?.url?.includes('digimed.health')) {
              closeModalByName(ModalName.WebViewModal);
            }
            getStripeConnect();
          },
        });
      } else {
        openLink(res.data.url);
      }
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const getStripeConnect = async () => {
    try {
      setLoading(true);
      const res = await apiGetStripeAccount();
      setStripeAccount(res.data);

      if (!res.data.is_enabled) {
        const isOnboardingComplete = (await apiStripeCheckOnboarding()).data.is_onboarding_completed;
        setShowOnboardButton(!isOnboardingComplete);
      } else {
        setShowOnboardButton(false);
      }
    } catch {}
    setLoading(false);
  };

  const hasStripeConnect = whenAppType({
    whenClinic: true,
    whenDoctor: true,
    else: false,
  });

  usePageFocus(() => {
    if (hasStripeConnect && approved) getStripeConnect();
  }, [approved]);

  if (!hasStripeConnect || !approved) return null;

  if (loading) return <H6NsRegularBlack>We are checking your stripe details...</H6NsRegularBlack>;

  if (!showOnboardButton) return alreadyOnBoarded || <StripeLink stripeAccount={stripeAccount} />;

  return (
    <View style={limitWidth ? { maxWidth: 200 } : null}>
      <Button funCallback={createStripeDoctorAccount} label={labels.setUpNow} disabled={waiting} />
    </View>
  );
};
