import React from 'react';
import { StyleSheet, View } from 'react-native';

import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import { mb20, p10, p20 } from '~/common/commonStyles';
import { LabelWithTextRow } from '~/components/commonComponents';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { Splitter } from '~/components/misc/Splitter';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { Error } from '~/components/svgImages';
import { Column, Container, Row } from '~/theme/components/grid';
import { extractClinicalTermDescription } from '~/utils/clinicalTerms';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  input: React.ReactNode;
  request: PrescriptionRequestModel;
}

export const PrescriptionRequestRejectionComponent: React.FC<Props> = ({ input, request }) => {
  const { isMobile } = useBreakpoints();

  return (
    <>
      <Container>
        <Row style={mb20} justifyContent="center">
          <Column span="auto">
            <Error height={55} width={55} />
          </Column>
        </Row>
        <Row style={mb20} justifyContent="center">
          <Column span="auto">
            <H3TtmSemiBoldBlack>Decline prescription</H3TtmSemiBoldBlack>
          </Column>
        </Row>
        <Row>
          <Column>
            <View style={styles.detailsContainer}>
              <Row>
                <Column span={{ xs: 12, sm: 'flex' }}>
                  <ProfileDetailsComponent style={[p20]} account={request.prescription.patient} />
                </Column>
                {!isMobile ? <Splitter vertical spacing={0} /> : null}
                <Column span={{ xs: 12, sm: 'flex' }}>
                  <View style={[p10]}>
                    <LabelWithTextRow
                      labelWidth={120}
                      fontWeight="600"
                      label="Prescription code"
                      value={request.prescription.reference_number}
                    />
                    <LabelWithTextRow
                      labelWidth={120}
                      fontWeight="600"
                      label="Product name"
                      value={extractClinicalTermDescription(request.prescription.product)}
                    />
                  </View>
                </Column>
              </Row>
              {/* TODO: current pharmacy details */}
              <View style={p20}>{input}</View>
            </View>
          </Column>
        </Row>
      </Container>
    </>
  );
};

const styles = StyleSheet.create({
  detailsContainer: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.lightPurple2,
  },
});
