import React, { useMemo } from 'react';

import { ArticlePostChunk } from './ArticlePostChunk';
import { Pagination } from '../DataTable/Pagination';

import { useEducationArticles } from '~/api/hooks/educationCentre/EducationArticlesHook';
import { KeyboardAScrollView } from '~/components/commonViews';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { Grid } from '~/theme/components/grid';

export const ArticlePostsTable: React.FC = () => {
  const { educationArticles, pagination, pageChanged, loading } = useEducationArticles();

  const articleChunkData = useMemo(() => {
    const chunkSize = 7;
    const arr = [];
    for (let i = 0; i < educationArticles?.length ?? 0; i += chunkSize) {
      const chunk = educationArticles.slice(i, i + chunkSize);
      arr.push({ chunk, key: chunk.map((item) => item.id).join('-') });
    }
    return arr;
  }, [educationArticles]);

  return (
    <KeyboardAScrollView>
      <Grid grid={{ noOuterGutter: true }}>
        {loading ? (
          <LoadingActivityIndicator style={{ minHeight: 170 }} />
        ) : (
          <>
            {articleChunkData.map((item) => (
              <ArticlePostChunk key={item.key} items={item.chunk} />
            ))}
          </>
        )}
      </Grid>
      <Pagination
        page={pagination?.current_page || 1}
        totalCount={pagination?.total || 0}
        numberOfItemsPerPage={pagination?.per_page || 0}
        onPageChanged={pageChanged}
      />
    </KeyboardAScrollView>
  );
};
