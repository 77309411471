import { FlashList } from '@shopify/flash-list';
import React from 'react';
import { ScrollView, View } from 'react-native';

import { ClinicDoctorComponent } from './ClinicDoctorComponent';

import { useClinicDoctorListing } from '~/api/hooks/clinics/ClinicDoctorListingHook';
import { AvailableDoctorModelWithQueue } from '~/api/models/appointments/models/AvailableDoctorModel';
import { flex1 } from '~/common/commonStyles';
import { Splitter } from '~/components/misc/Splitter';
import { usePageFocus } from '~/utils/hooks/FocusHook';

export const ClinicDoctorListing: React.FC = () => {
  const { doctorsWithQueue, getDoctors } = useClinicDoctorListing();

  usePageFocus(() => {
    getDoctors();
  });

  return (
    <View style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
      <Splitter spacing={10} secondaryColor style={{ marginHorizontal: -15 }} />
      <ScrollView
        horizontal={false}
        style={[flex1, { marginHorizontal: -15 }]}
        contentContainerStyle={{ paddingHorizontal: 15 }}>
        <FlashList<AvailableDoctorModelWithQueue>
          data={doctorsWithQueue}
          estimatedItemSize={100}
          keyExtractor={(item) => `${item.id}`}
          ItemSeparatorComponent={() => <Splitter spacing={10} secondaryColor />}
          renderItem={({ item }) => <ClinicDoctorComponent doctor={item} />}
        />
      </ScrollView>
    </View>
  );
};
