import React, { useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import QRCode from 'react-native-qrcode-svg';

import { PrescriptionDetailComponent } from './PrescriptionDetailComponent';
import { PrescriptionDoctorPatientDetails } from './PrescriptionDoctorPatientDetails';
import {
  PrescriptionManualCollectionForm,
  PrescriptionManualCollectionFormRef,
} from './PrescriptionManualCollectionForm';
import {
  PrescriptionRequestCreationComponent,
  PrescriptionRequestCreationRef,
} from './PrescriptionRequestCreationComponent';
import { PatientPrescriptionCollectionsComponent } from './dispenseHistory/PatientPrescriptionCollectionsComponent';
import { Button, OutlineButton } from '../../commonButton';
import {
  H3TtmSemiBoldBlack,
  H4TtmSemiBoldBlack,
  H6NsRegularBlack,
  H6NsSemiBoldBlack,
  H6NsSemiBoldLink,
  SmallNsRegularBlack,
} from '../../commonText';
import { LoadingActivityIndicator } from '../../loading/LoadingActivityIndicator';
import { ErrorAlert } from '../ErrorAlert';
import { ModalContainer } from '../ModalContainer';

import { usePrescriptionRequestsIndex } from '~/api/hooks/pharmacy/PharmacyPrescriptionsHook';
import { PrescriptionRequestStateEnum } from '~/api/models/consultations/enums/PrescriptionRequestStateEnum';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import { InprogressAlert, SuccessAlert } from '~/common/commonMethods';
import { mb10, mb20, mt10, textCenter } from '~/common/commonStyles';
import { DashboardCard } from '~/components/dashboard/common/DashboardCard';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { isPatientVersion } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';
import { pharmacyLabels } from '~/utils/labels/pharmacy';
import { getPharmacyString } from '~/utils/prescriptionUtil';
import isNullOrUndefined from '~/utils/types/isNullOrUndefined';

interface Props {
  prescription: PrescriptionModel;
  prescriptionRequest: PrescriptionRequestModel;
  onHide?: () => void;
}

export const PrescriptionModal: React.FC<Props> = ({ prescription, prescriptionRequest, onHide }) => {
  const {
    latestUncollectedRequest,
    latestRequest,
    isAllCollected,
    isUsed,
    loading: requestLoading,
    prescriptionCollections,
    getPrescriptionRequests,
  } = usePrescriptionRequestsIndex({
    prescription,
  });
  const [loading, setLoading] = useState(false);
  const [manualCollection, setManualCollection] = useState(false);
  const patientVersion = useRef(isPatientVersion());

  const manualCollectionRef = useRef<PrescriptionManualCollectionFormRef>();
  const collectionRequestRef = useRef<PrescriptionRequestCreationRef>();
  const isCollectable = useMemo(() => prescription.is_collectable !== false, [prescription]);
  const showManualCollection = useMemo(
    () =>
      isCollectable && (manualCollection || !!latestUncollectedRequest) && !isAllCollected && patientVersion.current,
    [isAllCollected, manualCollection, latestUncollectedRequest, patientVersion]
  );

  const markAsCollected = async () => {
    try {
      setLoading(true);
      const submitted = await manualCollectionRef.current.submit();
      if (isNullOrUndefined(submitted)) {
        return;
      }
      SuccessAlert(['Your prescription was successfully marked as collected']);
      onHide();
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setLoading(false);
    }
  };

  const createPickupRequest = async () => {
    try {
      setLoading(true);
      const submitted = await collectionRequestRef.current.submit();
      if (isNullOrUndefined(submitted)) {
        return;
      }
      await getPrescriptionRequests();
      InprogressAlert(['Your request was submitted to the pharmacy.', 'We will let you know once they reply.']);
      onHide();
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setLoading(false);
    }
  };

  if (!prescription) return null;

  const prescriptionLink = `${prescription?.reference_number}`;

  const buttons = showManualCollection
    ? [
        <Button label={labels.markAsCollected} funCallback={markAsCollected} disabled={loading || requestLoading} />,
        <OutlineButton label={labels.close} funCallback={onHide} disabled={loading || requestLoading} />,
      ]
    : !latestUncollectedRequest && !isAllCollected && isCollectable && patientVersion.current
    ? [
        <Button
          label={labels.submitPrescriptionRequest}
          funCallback={createPickupRequest}
          disabled={loading || requestLoading}
        />,
        <OutlineButton
          label={labels.markAsCollected}
          funCallback={() => setManualCollection(true)}
          disabled={loading || requestLoading}
        />,
      ]
    : [<Button label={labels.close} funCallback={onHide} disabled={loading || requestLoading} />];

  const subtitle = !patientVersion.current ? null : showManualCollection ? (
    <>
      {latestUncollectedRequest?.state === PrescriptionRequestStateEnum.PENDING ? (
        <H6NsRegularBlack style={[mb10, textCenter]}>
          You already submitted a request to{' '}
          <H6NsSemiBoldBlack>{getPharmacyString(latestUncollectedRequest.requested_pharmacy)}</H6NsSemiBoldBlack> and we
          are waiting for a response. We will let you know as soon as possible.
        </H6NsRegularBlack>
      ) : null}
      {latestUncollectedRequest?.state === PrescriptionRequestStateEnum.APPROVED ? (
        <H6NsRegularBlack style={[mb10, textCenter]}>
          Your request to pick up your prescription from{' '}
          <H6NsSemiBoldBlack>{getPharmacyString(latestUncollectedRequest.requested_pharmacy)}</H6NsSemiBoldBlack> has
          been approved and is pending collection.
        </H6NsRegularBlack>
      ) : null}
      <SmallNsRegularBlack style={textCenter}>{pharmacyLabels.modal.subtitleManualCollection}</SmallNsRegularBlack>
    </>
  ) : !latestUncollectedRequest && !isAllCollected && isCollectable ? (
    <Row>
      <Column>
        <PrescriptionRequestCreationComponent ref={collectionRequestRef} prescription={prescription} />
      </Column>
      <Column span="auto">
        <QRCode value={prescriptionLink} />
      </Column>
    </Row>
  ) : !isCollectable ? (
    <H6NsSemiBoldLink style={textCenter}>{pharmacyLabels.modal.subtitleNotCollectable}</H6NsSemiBoldLink>
  ) : isUsed ? (
    <H6NsSemiBoldLink style={textCenter}>{pharmacyLabels.modal.subtitleUsed}</H6NsSemiBoldLink>
  ) : isAllCollected ? (
    <H6NsSemiBoldLink style={textCenter}>{pharmacyLabels.modal.subtitleCollected}</H6NsSemiBoldLink>
  ) : null;

  return (
    <ModalContainer hideOnBackground={!loading} onHide={onHide} showInfoLine wide buttons={buttons}>
      <View>
        {requestLoading ? (
          <LoadingActivityIndicator />
        ) : (
          <Grid>
            <Container>
              <Row style={mb20} justifyContent="center">
                <Column span="auto">
                  <H3TtmSemiBoldBlack>Prescription {prescription.reference_number}</H3TtmSemiBoldBlack>
                </Column>
              </Row>
            </Container>

            {subtitle ? (
              <Container>
                <Row style={mb20}>
                  <Column>{subtitle}</Column>
                </Row>
              </Container>
            ) : null}

            <PrescriptionDoctorPatientDetails prescription={prescription} style={mb20} />

            <PrescriptionDetailComponent prescription={prescription} style={mb20} />

            <PatientPrescriptionCollectionsComponent collections={prescriptionCollections} />

            {(latestUncollectedRequest || prescriptionRequest)?.customer_instructions ? (
              <>
                <View style={mb20} />
                <DashboardCard>
                  <H4TtmSemiBoldBlack>{pharmacyLabels.modal.pharmacyInstructions}</H4TtmSemiBoldBlack>
                  <View style={mt10}>
                    <SmallNsRegularBlack>
                      {(latestUncollectedRequest || prescriptionRequest).customer_instructions}
                    </SmallNsRegularBlack>
                  </View>
                </DashboardCard>
              </>
            ) : null}

            {(latestRequest || prescriptionRequest)?.state === PrescriptionRequestStateEnum.REJECTED ? (
              <>
                <View style={mb20} />
                <DashboardCard>
                  <H4TtmSemiBoldBlack>{pharmacyLabels.modal.pharmacyRejection}</H4TtmSemiBoldBlack>
                  <View style={mt10}>
                    <SmallNsRegularBlack>
                      {(latestRequest || prescriptionRequest).rejection_reason ?? 'No reason given'}
                    </SmallNsRegularBlack>
                  </View>
                </DashboardCard>
              </>
            ) : null}

            {showManualCollection ? (
              <PrescriptionManualCollectionForm prescriptionId={prescription?.id} ref={manualCollectionRef} />
            ) : null}
          </Grid>
        )}
      </View>
    </ModalContainer>
  );
};
