import React from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { View } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { HomeVisitAvailabilitiesMap } from '../availabilities/homeVisit/HomeVisitAvailabilitiesMap';
import { Button } from '../commonButton';

import { HomeVisitFormModel } from '~/api/models/preferences/models/HomeVisitModel';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  control: Control<HomeVisitFormModel, any>;
  setValue: UseFormSetValue<HomeVisitFormModel>;
}

export const MapModal: React.FC<Props> = ({ onHide, control, setValue }) => {
  const buttons = [<Button funCallback={onHide} label={labels.save} />];

  const { max_distance, latLng } = useWatch({ control });

  return (
    <ModalContainer hideOnBackground onHide={onHide} buttons={buttons}>
      <View>
        <HomeVisitAvailabilitiesMap
          radius={max_distance}
          latLng={{
            lat: latLng?.lat,
            lng: latLng?.lng,
          }}
          setLatLng={(latLng) => {
            setValue('latLng', latLng);
          }}
          setRadius={(value) => setValue('max_distance', value)}
        />
      </View>
    </ModalContainer>
  );
};
