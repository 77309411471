import React, { useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { AlertCard } from '../cards/AlertCard';
import { H6NsRegularSecondaryBlack, H6NsSemiBoldLink } from '../commonText';
import { ErrorAlert } from '../modals/ErrorAlert';

import { StripeAccountModel } from '~/api/models/stripe/models/StripeAccountModel';
import { apiStripeConnectLink } from '~/api/services/stripe';
import { InprogressAlert, openLink } from '~/common/commonMethods';
import { mb10 } from '~/common/commonStyles';
import { DATE_FORMAT, isPastDateTime, parseDateTime } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';

interface Props {
  style?: StyleProp<ViewStyle>;
  stripeAccount?: StripeAccountModel;
}

export const StripeLink: React.FC<Props> = ({ style, stripeAccount }) => {
  const openStripeConnect = async () => {
    try {
      const close = InprogressAlert(['Please wait while we redirect you to your Stripe Dashboard']);
      const res = await apiStripeConnectLink();
      openLink(res.data.url);
      close();
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const requirementsDeadline = useMemo(
    () => parseDateTime(stripeAccount?.requirements_deadline, { outputFormat: DATE_FORMAT }),
    [stripeAccount?.requirements_deadline]
  );

  const hasRequirementsDatePassed = useMemo(
    () => !!stripeAccount?.requirements_deadline && isPastDateTime(stripeAccount?.requirements_deadline),
    [stripeAccount?.requirements_deadline]
  );

  return (
    <View style={style}>
      {requirementsDeadline ? (
        <AlertCard
          style={mb10}
          onPress={openStripeConnect}
          title="Action needed on your Stripe Connect account"
          message={
            hasRequirementsDatePassed
              ? 'Please click here to see what requirements are needed from Stripe in order to be able to process payments'
              : `Stripe requires more information by ${requirementsDeadline}. Please click here to see what is needed and avoid any interruptions of your service`
          }
        />
      ) : null}
      <H6NsRegularSecondaryBlack>
        {labels.addBankDetailsText8}
        <H6NsSemiBoldLink onPress={openStripeConnect}>{labels.stripeDashboard}</H6NsSemiBoldLink>
      </H6NsRegularSecondaryBlack>
    </View>
  );
};
