import { get, put } from '../../axios';
import { ListItemResponse } from '../../models/common/responses/ListItemResponse';
import { RecreationalDrugsRequest } from '../../models/healthProfile/requests/RecreationalDrugsRequest';
import { RecreationalDrugsResponse } from '../../models/healthProfile/responses/RecreationalDrugsResponse';

import { HealthProfileIdRequest } from '~/api/models/healthProfile/requests/HealthProfileIdRequest';

export const apiUpdateRecreationalDrugs = (params: { id: number; data: RecreationalDrugsRequest }) => {
  return put<RecreationalDrugsResponse>(`/health-profile/${params.id}/recreational-drugs`, params.data);
};

export const apiGetRecreationalDrugs = (params: HealthProfileIdRequest) => {
  return get<RecreationalDrugsResponse>(`/health-profile/${params.id}/recreational-drugs`);
};

export const apiGetAllRecreationalDrugs = () => {
  return get<ListItemResponse>(`/recreational-drugs`);
};
