export enum ActivityActionEnum {
  VIEWED = 'viewed',
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  ATTACHED = 'attached',
  DETACHED = 'detached',
}

export const ActivityActionIconMapping = {
  [ActivityActionEnum.VIEWED]: 'eye',
  [ActivityActionEnum.CREATED]: 'file-plus',
  [ActivityActionEnum.UPDATED]: 'arrow-up-circle',
  [ActivityActionEnum.DELETED]: 'x-octagon',
  [ActivityActionEnum.ATTACHED]: 'plus-circle',
  [ActivityActionEnum.DETACHED]: 'minus-circle',
};
