import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { DoctorListFilterModal } from './DoctorListFilterModal';
import { DoctorListSortingModal } from './DoctorListSortingModal';
import { DoctorListSearchComponent } from '../components/DoctorListSearchComponent';

import { mb10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { FilterIcon, SortIcon } from '~/components/svgImages';
import { useDoctorListingContext } from '~/providers/appointment/DoctorListingContext';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { appointmentLabels } from '~/utils/labels/appointments';

export const DoctorListMobileHeader: React.FC = () => {
  const { filterControl, submitQuery } = useDoctorListingContext();
  const { isMobile } = useBreakpoints();

  const [showSort, setShowSort] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  return (
    <Grid grid={{ gutters: 10 }}>
      <Container style={{ paddingHorizontal: isMobile ? MOBILE_HORIZONTAL_SPACE : 0, marginBottom: 10 }}>
        <Row alignItems="center">
          <Column style={mb10}>
            <H3TtmSemiBoldBlack>{appointmentLabels.availablePractitionersTitle}</H3TtmSemiBoldBlack>
          </Column>
          <Column style={mb10} span="auto">
            <IconButton transparent onPress={() => setShowSort(true)}>
              <SortIcon width={18} height={18} color={colors.purple} />
            </IconButton>
          </Column>
          <Column style={mb10} span="auto">
            <IconButton transparent onPress={() => setShowFilter(true)}>
              <FilterIcon width={18} height={18} color={colors.purple} />
            </IconButton>
          </Column>
        </Row>
        <Row>
          <Column>
            <Controller
              name="search"
              control={filterControl}
              render={({ field: { value, onChange } }) => (
                <DoctorListSearchComponent value={value} setValue={onChange} onSubmitSearch={submitQuery} />
              )}
            />
          </Column>
        </Row>
      </Container>

      <DoctorListSortingModal show={showSort} onHide={() => setShowSort(false)} />
      <DoctorListFilterModal show={showFilter} onHide={() => setShowFilter(false)} />
    </Grid>
  );
};
