import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ContactUsComponent } from '~/components/help/contactUs/ContactUsComponent';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const HelpContactUsMobile: React.FC = () => {
  return (
    <NestedPageInternalLayout
      backTitle={labels.help}
      backLink={{ screen: screenName.Help }}
      title={labels.contactUs}
      style={styles.faqContainer}>
      <View>
        <Row>
          <Column>
            <ContactUsComponent />
          </Column>
        </Row>
      </View>
    </NestedPageInternalLayout>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: colors.white,
  },
  faqContainer: {
    flex: 1,
    backgroundColor: colors.white,
  },
});
