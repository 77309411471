import { get, post } from '../../axios';

import { ConsultationRescheduleDetailsModel } from '~/api/models/appointments/models/ConsultationRescheduleDetailsModel';
import { ApproveConsultationRequest } from '~/api/models/consultations/requests/ApproveConsultationRequest';
import { DeclineConsultationRequest } from '~/api/models/consultations/requests/DeclineConsultationRequest';
import { RescheduleRequest } from '~/api/models/consultations/requests/RescheduleRequest';

export const apiApproveConsultation = (req?: ApproveConsultationRequest) => {
  return post(`/consultations/${req.id}/request/approve`, req.params);
};

export const apiDeclineConsultation = (req: DeclineConsultationRequest) => {
  return post(`consultations/${req.id}/request/reject`, {
    cancellation_reason_note: req.cancellation_reason_note,
  });
};

export const apiGetRescheduleRequest = (req: Omit<RescheduleRequest, 'params'>) => {
  return get<ConsultationRescheduleDetailsModel>(
    `/patient/consultations/${req.consultationId}/reschedule-requests/${req.consultationRequestId}`
  );
};

export const apiApproveRescheduleRequest = (req: RescheduleRequest) => {
  return post(
    `/patient/consultations/${req.consultationId}/reschedule-requests/${req.consultationRequestId}/approve`,
    req.params
  );
};

export const apiDeclineRescheduleRequest = (req: RescheduleRequest) => {
  return post(
    `/patient/consultations/${req.consultationId}/reschedule-requests/${req.consultationRequestId}/reject`,
    req.params
  );
};
