import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { DateType } from '~/api/models/common/types/DateType';
import { apiGetDoctorSlotDays } from '~/api/services/appointments/availabilities';
import { CalendarStripInput, CalendarStripInputProps } from '~/components/inputs/dateTime/CalendarStripInput';
import { NUM_DATE_FORMAT } from '~/utils/dateAndTime';

interface Props extends CalendarStripInputProps {
  doctorId: number;
}

export const ScheduledSlotDateSelectionComponent: React.FC<Props> = (props) => {
  const [currentDate, setCurrentDates] = useState<{ from: string; to: string }>();
  const [availableDates, setAvailableDates] = useState<DateType[]>();

  const rangeChanged = (date: Moment) => {
    const today = moment().startOf('day');
    let start = moment(date).startOf('month').add(-7, 'days');
    if (start.isBefore(today)) start = today;

    const end = moment(date).endOf('month').add(7, 'days');
    if (today.isAfter(end)) return;

    const params = {
      from: start.format(NUM_DATE_FORMAT),
      to: end.format(NUM_DATE_FORMAT),
    };

    if (currentDate && currentDate.from === params.from && currentDate.to === params.from) return;
    setCurrentDates(params);
  };

  const getMarkedDates = async () => {
    if (!currentDate || !props.doctorId) return;
    try {
      const res = await apiGetDoctorSlotDays({
        doctorId: props.doctorId,
        params: currentDate,
      });

      setAvailableDates(res.data);
    } catch {
      setAvailableDates([]);
    }
  };

  useEffect(() => {
    getMarkedDates();
  }, [currentDate?.to, currentDate?.from]);

  return useMemo(
    () => (
      <CalendarStripInput
        {...props}
        onWeekChanged={rangeChanged}
        onMonthChanged={rangeChanged}
        markedDates={availableDates}
      />
    ),
    [props, availableDates]
  );
};
