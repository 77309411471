import React from 'react';
import { useFormState } from 'react-hook-form';
import { View } from 'react-native';

import { HealthRecordEdit } from './Edit/HealthRecordEdit';
import { HealthRecordView } from './View/HealthRecordView';

import { mb10, p10 } from '~/common/commonStyles';
import { Accordion } from '~/components/buttons/Accordions/Accordion';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { HealthRecord as HealthRecordIcon } from '~/components/svgImages';
import { useHealthRecordDataContext } from '~/providers/consultation/HealthRecordDataContext';

interface Props {
  editable?: boolean;
}

export const HealthRecord: React.FC<Props> = ({ editable }) => {
  const {
    form: { control },
    loadData,
    loading,
  } = useHealthRecordDataContext();
  const { isSubmitted, isValid } = useFormState({ control });

  return (
    <Accordion
      left={<HealthRecordIcon width={16} height={16} />}
      title="Health Record"
      style={mb10}
      error={editable && isSubmitted && !isValid}
      onExpandedChanged={(expanded) => {
        if (expanded) loadData();
      }}>
      {loading ? (
        <LoadingActivityIndicator />
      ) : (
        <View style={editable ? p10 : null}>{editable ? <HealthRecordEdit /> : <HealthRecordView />}</View>
      )}
    </Accordion>
  );
};
