import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { ModalManagementContext } from './ModalManagementContext';

import { PatientAppointmentRescheduleModal } from '~/components/PatientAppointmentRescheduleModal';
import { ApprovalRequestModal } from '~/components/doctor/booking/requestApprovals/ApprovalRequestModal';
import { RejectConsultationReasonModal } from '~/components/doctor/booking/RejectConsultationReasonModal';
import { AppointmentDetailsModal } from '~/components/modals/AppointmentDetailsModal';
import { FeedbackModal } from '~/components/modals/FeedbackModal';
import { MainModal } from '~/components/modals/MainModal';
import { ModalBackground } from '~/components/modals/ModalBackground';
import { OpenUrlModal } from '~/components/modals/OpenUrlModal';
import { PageModal } from '~/components/modals/PageModal';
import { PaywallModal } from '~/components/modals/PaywallModal';
import { SaveFavouriteDoctorModal } from '~/components/modals/SaveFavouriteDoctorModal';
import { WebViewModal } from '~/components/modals/WebViewModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { PrescriptionDispenseModal } from '~/components/modals/prescriptions/PrescriptionDispenseModal';
import { PrescriptionModal } from '~/components/modals/prescriptions/PrescriptionModal';
import { PrescriptionRequestApprovalModal } from '~/components/modals/prescriptions/PrescriptionRequestApprovalModal';
import { DoctorDetailModal } from '~/components/patient/doctorListing/components/DoctorDetailModal';
import { DoctorToggleFavouriteModal } from '~/components/patient/doctorListing/components/DoctorToggleFavouriteModal';
import { RootState } from '~/redux/reducers';
import { whenAppType } from '~/utils/buildConfig';
import log from '~/utils/logger';

const initialModals = {
  main: MainModal,
  pageInfo: PageModal,
  [ModalName.OpenUrlModal]: OpenUrlModal,
  [ModalName.PrescriptionDetailsModal]: PrescriptionModal,
  ...whenAppType({
    whenDoctor: {
      [ModalName.AppointmentDetails]: AppointmentDetailsModal,
      [ModalName.ApprovalRequest]: ApprovalRequestModal,
      [ModalName.WebViewModal]: WebViewModal,
      [ModalName.RejectConsultationReasonModal]: RejectConsultationReasonModal,
    },
    whenPatient: {
      [ModalName.PaywallModal]: PaywallModal,
      [ModalName.AppointmentDetails]: AppointmentDetailsModal,
      [ModalName.AppointmentFeedback]: FeedbackModal,
      [ModalName.SaveFavouriteDoctorModal]: SaveFavouriteDoctorModal,
      [ModalName.DoctorFavouriteModal]: DoctorToggleFavouriteModal,
      [ModalName.PatientAppointmentRescheduleModal]: PatientAppointmentRescheduleModal,
    },
    whenPharmacist: {
      [ModalName.PrescriptionRequestApproval]: PrescriptionRequestApprovalModal,
      [ModalName.PrescriptionDispenseModal]: PrescriptionDispenseModal,
    },
    whenClinic: {
      [ModalName.DoctorInfoModal]: DoctorDetailModal,
      [ModalName.RejectConsultationReasonModal]: RejectConsultationReasonModal,
    },
  }),
};
export interface ActiveModal {
  name: string;
  params?: any;
}
interface Props {
  children: React.ReactNode;
}
export const ModalManagementProvider: React.FC<Props> = ({ children }) => {
  const activeModalsRef = useRef<ActiveModal[]>([]);
  const [activeModals, setActiveModalsStack] = useState<ActiveModal[]>([]);

  const registeredModals = useRef(initialModals);

  const showMainModal = useSelector((state: RootState) => state.overLayReducer.popupFlag);
  const showPageModal = useSelector((state: RootState) => state.modalReducer.pageInfoModal);

  const getCurrentActiveModals = () => {
    return [...activeModalsRef.current];
  };
  const updateActiveModalsState = (activeModals: ActiveModal[]) => {
    activeModalsRef.current = activeModals;
    setActiveModalsStack(() => {
      return activeModalsRef.current;
    });
  };

  const registerModal = (modalName: string, Component: React.FC<any>) => {
    if (!registeredModals.current[modalName]) {
      registeredModals.current = {
        ...registeredModals.current,
        [modalName]: Component,
      };
    }
  };

  const openModal = (name: string, params?: any) => {
    if (!registeredModals.current[name]) {
      log.warn('No registered modal found with the name:', name);
      return;
    }
    const tempActiveModals = [{ name, params }, ...activeModalsRef.current];
    updateActiveModalsState(tempActiveModals);
  };

  const closeModal = () => {
    if (currentModal) {
      const tempActiveModals = getCurrentActiveModals();
      tempActiveModals.slice(1);

      updateActiveModalsState(tempActiveModals);
    }
  };

  const closeModalByName = (modalName?: string) => {
    if (!modalName) return;
    let tempActiveModals = getCurrentActiveModals();
    let i = 0;
    for (; i < tempActiveModals.length; i++) {
      if (tempActiveModals[i].name === modalName) break;
    }

    if (i >= 0) {
      tempActiveModals.splice(i, 1);
      updateActiveModalsState(tempActiveModals);
    }
  };

  const setShowingNonRegisteredModal = (modalName: string, show: boolean) => {
    if (show) {
      const tempActiveModals = getCurrentActiveModals();
      updateActiveModalsState([{ name: modalName }, ...tempActiveModals]);
    } else {
      closeModalByName(modalName);
    }
  };

  useEffect(() => {
    if (showMainModal) {
      openModal(ModalName.Main);
    } else {
      closeModalByName(ModalName.Main);
    }
  }, [showMainModal]);

  useEffect(() => {
    if (showPageModal) {
      openModal(ModalName.PageInfo, {
        pageType: showPageModal,
      });
    } else {
      closeModalByName(ModalName.PageInfo);
    }
  }, [showPageModal]);

  const currentModal = useMemo(() => {
    if (activeModals.length) {
      return activeModals[0];
    }
    return null;
  }, [activeModals]);

  const Component = useMemo((): React.FC => {
    return currentModal && registeredModals.current[currentModal.name]
      ? registeredModals.current[currentModal.name]
      : null;
  }, [currentModal]);

  const componentParams = useMemo(() => {
    return currentModal?.params || {};
  }, [currentModal]);

  return (
    <ModalManagementContext.Provider
      value={{ registerModal, openModal, closeModal, closeModalByName, setShowingNonRegisteredModal, activeModals }}>
      {currentModal && Component ? (
        <Component
          {...componentParams}
          onHide={() => {
            if (typeof componentParams?.onHide === 'function') componentParams.onHide();
            closeModalByName(currentModal.name);
          }}
        />
      ) : null}

      {children}
      <ModalBackground show={!!currentModal} />
    </ModalManagementContext.Provider>
  );
};
