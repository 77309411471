import { DocumentPickerAsset } from 'expo-document-picker';

import { ConsultationModel } from './ConsultationModel';
import { AccountModel } from '../../accounts/models/AccountModel';
import { MediaModel } from '../../common/models/MediaModel';
import { TimestampModel } from '../../common/models/TimestampModel';

import { ErrorResponse } from '~/classes/errors/ErrorResponse';

export interface ChatMessageModel extends TimestampModel {
  message?: string;
  media?: MediaModel[];
  recipient: AccountModel;
  dependant_profile_image?: MediaModel;
  dependant_first_name?: string;
  dependant_last_name?: string;
  sender: AccountModel;
  consultation?: ConsultationModel;
  id: number;
}

export type PostChatSendingRequest = {
  localId: number;
  media?: DocumentPickerAsset;
  message?: string;
  error?: ErrorResponse;
};

export const isPostChatSendingRequest = (x: ChatMessageModel | PostChatSendingRequest): x is PostChatSendingRequest =>
  'localId' in x;
