import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { UpdateMedicalRegistrationRequest } from '~/api/models/medicalRegistration/requests/UpdateMedicalRegistrationRequest';
import {
  apiGetDoctorMedicalRegistration,
  apiUpdateDoctorMedicalRegistration,
} from '~/api/services/medicalRegistration';
import { RootState } from '~/redux/reducers';
import { UPDATE_MEDICAL_REGISTRATION } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { isDoctorVersion } from '~/utils/buildConfig';

export function useMedicalRegistration() {
  const medicalRegistration = useSelector((state: RootState) => state.userDetailsReducer.medicalRegistration);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isDoctorVersion() || medicalRegistration) return;

    apiGetDoctorMedicalRegistration()
      .then((res) => dispatch(UPDATE_MEDICAL_REGISTRATION(res.data)))
      .catch(() => {});
  }, []);

  const updateMedicalRegistration = (req: UpdateMedicalRegistrationRequest) => {
    return apiUpdateDoctorMedicalRegistration(req).then((res) => {
      dispatch(UPDATE_MEDICAL_REGISTRATION(res.data));
      return res;
    });
  };

  return { medicalRegistration, updateMedicalRegistration };
}
