import React from 'react';
import { Platform, View } from 'react-native';
import { Surface } from 'react-native-paper';

const CustomSurface = ({ children, style }) => {
  if (Platform.OS === 'web') {
    return <View style={style}>{children}</View>;
  } else {
    return <Surface style={style}>{children}</Surface>;
  }
};

export default CustomSurface;
