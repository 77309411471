import React, { useEffect, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { AssociationVerificationStatusEnum } from '~/api/hooks/notifications/constants/AssociationVerificationStatusEnum';
import { useHandleAssociation } from '~/api/hooks/notifications/handlers/HandleAssociationHook';
import { DateTimeType } from '~/api/models/common/types/DateType';
import { NotificationModel } from '~/api/models/notifications/models/NotificationModel';
import { mh10 } from '~/common/commonStyles';
import { Button, OutlineButton } from '~/components/commonButton';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ASSOCIATION_TOKEN_VALIDITY_MINS } from '~/constants/tokens';
import { NotificationCodeEnum } from '~/integrations/pushNotifications/enums/NotificationCodeEnum';
import { LARAVEL_DATE_TIME_SHORT, getDifferenceFromTime } from '~/utils/dateAndTime';

interface Props {
  notificationData: NotificationModel['data'];
  createdAt: DateTimeType;
  style?: StyleProp<ViewStyle>;
}

export const NotificationActions: React.FC<Props> = ({ notificationData, style, createdAt }) => {
  const [loading, setLoading] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const { acceptAssociationRequest, declineAssociationRequest, validateAssociation } = useHandleAssociation();

  const handleAction = async (action: () => Promise<any>) => {
    try {
      setLoading(true);

      await action();
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setLoading(false);
    }
  };

  const checkShowAction = async () => {
    try {
      if (
        'notification_code' in notificationData &&
        'token' in notificationData &&
        notificationData.notification_code === NotificationCodeEnum.ASSOCIATION_REQUEST
      ) {
        const timeDifference = getDifferenceFromTime(new Date(), createdAt, { parseFormat: LARAVEL_DATE_TIME_SHORT });
        if (timeDifference <= ASSOCIATION_TOKEN_VALIDITY_MINS) {
          const status = await validateAssociation(notificationData.token);
          setShowAction(status === AssociationVerificationStatusEnum.VALID);
        } else {
          setShowAction(false);
        }
      }
    } catch {
      setShowAction(false);
    }
  };

  useEffect(() => {
    checkShowAction();
  }, [notificationData]);

  if (!showAction || !('notification_code' in notificationData)) return null;

  switch (notificationData?.notification_code) {
    case NotificationCodeEnum.ASSOCIATION_REQUEST: {
      if ('token' in notificationData) {
        return (
          <View style={[styles.web, style]}>
            <View style={[mh10, { justifyContent: 'center' }]}>
              <OutlineButton
                style={{ paddingHorizontal: 20 }}
                label="Decline"
                funCallback={() => {
                  handleAction(() => declineAssociationRequest(notificationData.token).then(checkShowAction));
                }}
                danger
                disabled={loading}
              />
            </View>
            <View style={[mh10, { justifyContent: 'center' }]}>
              <Button
                style={{ paddingHorizontal: 20 }}
                success
                label="Approve"
                funCallback={() => {
                  handleAction(() => acceptAssociationRequest(notificationData.token).then(checkShowAction));
                }}
                disabled={loading}
              />
            </View>
          </View>
        );
      }
    }
  }
  return null;
};

const styles = StyleSheet.create({
  web: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
