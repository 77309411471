import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { H5TtmRegularBlack } from '../../commonText';
import { ModalName } from '../../modals/constants/ModalNames';
import { CalendarWithCross } from '../../svgImages';

import { CalendarEventModel } from '~/api/models/preferences/models/CalendarEventModel';
import { mr10 } from '~/common/commonStyles';
import { ModalContainer } from '~/components/modals/ModalContainer';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { calendarLabels } from '~/utils/labels/calendar';

interface Props {
  events?: CalendarEventModel[];
  setUnavailable?: () => void;
  deleteUnavailable?: () => void;
}

export const DoctorAvailabilityModal: React.FC<Props> = ({ events = [], setUnavailable, deleteUnavailable }) => {
  const { closeModalByName } = useModalManager();

  return (
    <ModalContainer
      hideOnBackground
      onHide={() => {
        closeModalByName(ModalName.DoctorAvailabilityModal);
      }}>
      <View style={{ paddingHorizontal: 20 }}>
        {events.length === 0 ? (
          <TouchableOpacity
            style={{
              alignItems: 'center',
              flexDirection: 'row',
            }}
            onPress={setUnavailable}>
            <CalendarWithCross height={25} width={25} />
            <View style={mr10} />
            <H5TtmRegularBlack>{calendarLabels.unavailable}</H5TtmRegularBlack>
          </TouchableOpacity>
        ) : events[0].event_type === 'unavailability' ? (
          <TouchableOpacity
            style={{
              alignItems: 'center',
              flexDirection: 'row',
            }}
            onPress={deleteUnavailable}>
            <CalendarWithCross height={25} width={25} />
            <View style={mr10} />
            <H5TtmRegularBlack>{calendarLabels.imAvailable}</H5TtmRegularBlack>
          </TouchableOpacity>
        ) : (
          <></>
        )}
      </View>
    </ModalContainer>
  );
};
