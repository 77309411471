import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { FormErrorMessage } from '../commonText';
import { StarIcon } from '../svgImages';

import { mr10 } from '~/common/commonStyles';
import { colors } from '~/utils/colors';

interface Props {
  value: number;
  onChange: (value: number) => void;
  error?: string;
}

export const RatingInput: React.FC<Props> = ({ value, onChange, error }) => {
  return (
    <View>
      <View style={styles.ratings}>
        {[...Array(5)].map((_, i) => (
          <TouchableOpacity key={i} onPress={() => onChange(i + 1)} style={mr10}>
            <StarIcon width={20} height={20} color={i < value ? colors.warning : colors.transparent} error={!!error} />
          </TouchableOpacity>
        ))}
      </View>
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  ratings: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 7,
  },
});
