import { Route } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { View } from 'react-native';

import { useDependant } from '~/api/hooks/dependants/DependantHook';
import { mt15 } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { DependentTurnedNotMinor } from '~/components/patient/dependants/components/modals/DependentTurnedNotMinor';
import { EditImageComponent } from '~/components/profile/EditImageComponent';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { DependantProfileNavigation } from '~/navigation/authorised/DependantProfileNavigation';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { labels } from '~/utils/labels';
import { dependantsLabels } from '~/utils/labels/dependantsLabels';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { screenName } from '~/utils/screenName';

interface Props {
  route: Route<string, { id: string }>;
}

export const DependantDetailsWebScreen: React.FC<Props> = ({ route }) => {
  const id = route.params.id;
  const { dependant, getCurrentDependant, showOver18Button } = useDependant({ id });
  const { registerModal, openModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.ModalTurnedNotMinor, DependentTurnedNotMinor);
  }, []);

  return (
    <PageLayout
      title={labels.profile}
      subtitle={getAccountName(dependant?.account) || ' '}
      extraHeaderDetails={
        showOver18Button ? (
          <View style={mt15}>
            <Button
              label="Add Details"
              funCallback={() => {
                openModal(ModalName.ModalTurnedNotMinor, { dependent: dependant });
              }}
            />
          </View>
        ) : null
      }
      back={{
        to: { screen: screenName.Dependants },
        title: dependantsLabels.dependantsTitle,
      }}
      headerRight={<EditImageComponent account={dependant?.account} onUpdate={getCurrentDependant} isDependant />}
      scrollContentNode={<DependantProfileNavigation route={route} />}
    />
  );
};
