import { Route, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';

import { OTPTextInput, OTPTextInputRef } from '../../components/inputs/OtpTextInput';
import { PassCodeInputComponent } from '../../components/passCode/PassCodeInputComponent';
import { GeneralProps } from '../../interfaces/generalProps';
import { requiredValidation, passcodeLengthValidation } from '../../services/validationConfig';
import { colors } from '../../utils/colors';
import { labels } from '../../utils/labels';

import { setToken } from '~/api/axios';
import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { useLoginDetails } from '~/api/hooks/accounts/LoginDetails';
import { apiResetPasscode, apiUseRole } from '~/api/services/authorisation';
import { SuccessAlert } from '~/common/commonMethods';
import { mb10 } from '~/common/commonStyles';
import { Button, TextButton } from '~/components/commonButton';
import { H6NsRegularSecondaryBlack } from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { UPDATE_LOGIN_DETAILS } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { appUserRole } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

interface Props extends GeneralProps {
  route: Route<
    string,
    | (object & {
        showCreatePassCode?: boolean;
      })
    | undefined
  >;
  errorFlag?: boolean;
}

const ForgotPasscodeScreen: React.FC<Props> = ({ route }, props): JSX.Element => {
  const [otpClearFlag, setOtpClearFlag] = useState<boolean>(false);
  const loginDetails = useLoginDetails();
  const dispatch = useAppDispatch();
  const { updateRegistrationStatus } = useRegistrationSteps();
  const { navigate } = useNavigation();
  const otpBoxRef = useRef<OTPTextInputRef>();
  const [waiting, setWaiting] = useState(false);

  const { control, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      passcode: '',
      otp: '',
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setOtpClearFlag(false);
    }, 300);
  }, [otpClearFlag]);

  const onSubmit = async ({ otp, passcode }) => {
    try {
      setWaiting(true);
      const response = await apiResetPasscode({
        country_code: loginDetails.details?.country_code,
        number: loginDetails.details?.number,
        email: loginDetails.details?.email,
        otp,
        passcode,
      });

      setToken(response.data.data);
      const role = appUserRole();
      await apiUseRole({ role });
      dispatch(UPDATE_LOGIN_DETAILS({ ...loginDetails.details, steppedUp: true }));
      await updateRegistrationStatus();

      SuccessAlert(['Passcode changed successfully!'], 'Success');
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const buttons = [
    <Button funCallback={handleSubmit(onSubmit)} label={labels.resetPasscode} disabled={waiting} />,
    <TextButton
      funCallback={() => navigate(screenName.LoginScreen as never)}
      label={labels.back.toUpperCase()}
      disabled={waiting}
    />,
  ];

  return (
    <EnhancedRegistrationLayout title="Reset your passcode" buttons={buttons} hideProgress compressed>
      <View style={[mb10, { alignSelf: 'flex-start' }]}>
        <H6NsRegularSecondaryBlack>
          {labels.weSentSixDigitOneTimePassword} {labels.EnterTheCodeBelow}
        </H6NsRegularSecondaryBlack>
      </View>
      <View style={{ height: 100 }}>
        <Controller
          name="otp"
          control={control}
          render={({ field: { onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
            <OTPTextInput // Todo: check why onChange was needed
              ref={otpBoxRef}
              handleTextChange={onChange}
              onEnter={handleSubmit(onSubmit)}
              inputCount={6}
              inputCellLength={1}
              keyboardType="numeric"
              tintColor={props.errorFlag ? colors.danger : colors.info}
              offTintColor={props.errorFlag ? colors.danger : colors.lightPurple}
              errorMessage={isSubmitted && error?.message}
              loading={waiting}
            />
          )}
          rules={{
            minLength: passcodeLengthValidation(6),
            required: requiredValidation('One time passcode'),
          }}
        />
      </View>
      <View style={[mb10, { alignSelf: 'flex-start' }]}>
        <H6NsRegularSecondaryBlack>{labels.enterNewPasscode}:</H6NsRegularSecondaryBlack>
      </View>
      <Controller
        name="passcode"
        control={control}
        render={({ field: { onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
          <PassCodeInputComponent
            hasTouchFaceId={false}
            loginWithTouchId={undefined}
            onEnter={() => handleSubmit(onSubmit)()}
            onChange={onChange}
            autoFocus={false}
            forceWeb
            errorMessage={isSubmitted && error?.message}
            loading={waiting}
          />
        )}
        rules={{
          minLength: passcodeLengthValidation(4),
          required: requiredValidation('New passcode'),
        }}
      />
    </EnhancedRegistrationLayout>
  );
};

export default ForgotPasscodeScreen;
