import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { apiGetTaxons } from '~/api/services/faq';
import { RootState } from '~/redux/reducers';
import { SET_TAXONS } from '~/redux/reducers/helpReducer';
import { useAppDispatch } from '~/redux/store';
import { whenAppType } from '~/utils/buildConfig';

const FAQ_TAXONOMY_ID = whenAppType({
  whenDoctor: 2,
  whenPatient: 3,
});

export function useFaqTaxons() {
  const [loading, setLoading] = useState(false);
  const taxons = useSelector((state: RootState) => state.helpReducer.taxons?.data);

  const dispatch = useAppDispatch();

  const getTaxons = () => {
    if (!FAQ_TAXONOMY_ID) return;
    setLoading(true);
    return apiGetTaxons({ taxonomyId: FAQ_TAXONOMY_ID, page: 1, limit: 50 })
      .then((res) => {
        dispatch(SET_TAXONS(res.data));
        return res;
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!taxons) {
      getTaxons();
    }
  }, []);

  return { taxons, getTaxons, loading };
}
