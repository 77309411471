import React from 'react';

import { NotificationsTable } from '../../../components/doctor/notifications/NotificationsTable';
import { RequestsLayout } from '../../../layouts/RequestsLayout';

import { useNotificationsAutoMarkAsRead } from '~/api/hooks/notifications/Notifications';

export const NotificationsListing: React.FC = () => {
  useNotificationsAutoMarkAsRead();

  return (
    <RequestsLayout rowStyle={{ marginHorizontal: 0 }}>
      <NotificationsTable />
    </RequestsLayout>
  );
};
