import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { PrescriptionTypeLabelMapping } from '~/api/models/consultations/constants/PrescriptionTypeEnum';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { mb20 } from '~/common/commonStyles';
import { H4TtmSemiBoldBlack, SmallNsRegularDisableColor, SmallNsRegularBlack } from '~/components/commonText';
import { PrescriptionFrequencyList } from '~/constants/PrescriptionFrequencyList';
import { Container, Row, Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { parseDateTime, DATE_FORMAT } from '~/utils/dateAndTime';
import { generateDurationString } from '~/utils/prescriptionUtil';

const PrescriptionDetails = [
  [
    { title: 'Prescription code', key: 'reference_number' },
    {
      title: 'Date prescribed',
      key: 'created_at',
      format: (value) => parseDateTime(value, { outputFormat: DATE_FORMAT }),
    },
    {
      title: 'Type',
      key: 'type',
      format: (value) => PrescriptionTypeLabelMapping[value] || '',
    },
  ],
  [
    {
      title: 'Product name',
      key: 'product_name',
      format: (_, item: PrescriptionModel) => {
        return item.product?.snomed_index?.term || item.product?.clinical_term_description?.description;
      },
    },
    { title: 'Frequency', key: 'frequency' },
    {
      title: 'Time',
      key: 'frequencyDetailed',
      format: (_, item: PrescriptionModel) => {
        const details = PrescriptionFrequencyList.find((frequency) => frequency.id === item.frequency);
        return details?.times || '';
      },
    },
    { title: 'Dose', key: 'dose' },
    {
      title: 'Duration',
      key: 'duration_interval_amount',
      format: (value, item: PrescriptionModel) => generateDurationString(value, item.duration_interval_type),
    },
    { title: 'Route', key: 'route' },
    { title: 'Other comments', key: 'other_comments' },
  ],
];

interface Props {
  prescription: PrescriptionModel;
  style?: StyleProp<ViewStyle>;
}

export const PrescriptionDetailComponent: React.FC<Props> = ({ prescription, style }) => {
  return (
    <Container style={[styles.details, style]}>
      <Row style={mb20}>
        <Column>
          <H4TtmSemiBoldBlack>Prescription details</H4TtmSemiBoldBlack>
        </Column>
      </Row>
      <Row>
        {PrescriptionDetails.map((column, index) => (
          <Column span={{ xs: 12, md: 6 }} key={index}>
            {column.map((detail) => (
              <Row key={detail.key} style={{ marginBottom: 8 }}>
                <Column>
                  <SmallNsRegularDisableColor>{detail.title}</SmallNsRegularDisableColor>
                </Column>
                <Column>
                  <SmallNsRegularBlack>
                    {detail.format ? detail.format(prescription[detail.key], prescription) : prescription[detail.key]}
                  </SmallNsRegularBlack>
                </Column>
              </Row>
            ))}
          </Column>
        ))}
      </Row>
    </Container>
  );
};

const styles = StyleSheet.create({
  profiles: {
    backgroundColor: colors.lightPurple,
    borderRadius: 6,
    padding: 8,
    marginBottom: 16,
  },
  details: {
    borderColor: colors.lightPurple,
    borderRadius: 6,
    padding: 8,
    borderWidth: 1,
  },
});
