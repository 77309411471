import React, { useEffect, useRef } from 'react';
import { Easing, Animated, Pressable, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { ButtonText } from '~/components/commonViews';
import { isWeb } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';

interface Props {
  disabled?: boolean;
  loading?: boolean;
  onPress: () => void;
  children?: React.ReactNode | ((disabled?: boolean) => React.ReactNode);
  label?: string;
  style?: StyleProp<ViewStyle>;
  backgroundStyle?: StyleProp<ViewStyle>;
}

export const AnimatedButton: React.FC<Props> = ({
  disabled,
  loading,
  children,
  label,
  style,
  backgroundStyle,
  onPress,
}) => {
  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: disabled ? 0 : 1,
      duration: disabled ? 300 : 800,
      easing: disabled ? Easing.linear : Easing.bounce,
      useNativeDriver: !isWeb(),
    }).start();
  }, [disabled]);

  const scale = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0.8, 1],
  });

  return (
    <Pressable
      onPress={onPress}
      disabled={disabled || loading}
      style={[styles.container, disabled || loading ? styles.disabled : null, style]}>
      <Animated.View
        style={[
          styles.background,
          backgroundStyle,
          {
            opacity,
            transform: [
              {
                scale,
              },
            ],
          },
        ]}
      />
      {!children ? <ButtonText>{label}</ButtonText> : typeof children === 'function' ? children(disabled) : children}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    backgroundColor: colors.purple,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
  disabled: {
    opacity: 0.5,
  },
  enabled: {},
});
