import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../redux/reducers';
import { apiLanguages } from '../../services/accounts/preferences';

import { UPDATE_LANGUAGE_LIST } from '~/redux/reducers/referenceDataReducer';
import { useAppDispatch } from '~/redux/store';

export function useLanguageListing() {
  const languageData = useSelector((state: RootState) => state.referenceDataReducer.languageList);
  const dispatch = useAppDispatch();
  const loading = useRef(false);

  const getLanguages = async () => {
    try {
      loading.current = true;
      await apiLanguages().then((res) => {
        const languages = res.data;
        for (let i = 0; i < languages.length; i++) {
          if (languages[i].code === 'en' || languages[i].code === 'mt') {
            const plucked = languages.splice(i, 1);
            languages.unshift(...plucked);
          }
        }

        dispatch(UPDATE_LANGUAGE_LIST(languages));
      });
    } catch {}
    loading.current = false;
  };

  useEffect(() => {
    if (!languageData?.length && !loading.current) {
      getLanguages();
    }
  }, [languageData]);

  return languageData;
}
