import React, { ReactNode, useMemo } from 'react';
import { ScrollView, StyleProp, View, ViewStyle } from 'react-native';

import { mt10, mt20, pb15 } from '~/common/commonStyles';
import { TitledLinkButton } from '~/components/buttons/TitledLinkButton';
import { Gradient } from '~/components/common/Gradient';
import { OutlineButton } from '~/components/commonButton';
import { H4TtmRegularSecondaryBlack } from '~/components/commonText';
import { Splitter } from '~/components/misc/Splitter';
import { PatientConsultationInformation } from '~/components/patient/consultations/PatientConsultationInformation';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { Row, Column } from '~/theme/components/grid';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useAppointmentState } from '~/utils/hooks/appointments/AppointmentStateHook';
import { labels } from '~/utils/labels';
import { consultationsLabels } from '~/utils/labels/consultations';
import { screenName } from '~/utils/screenName';

interface Props {
  hideEndButton?: boolean;
  whiteBackground?: boolean;
  contentStyling?: StyleProp<ViewStyle>;
  children?: ReactNode;
  viewStyle?: StyleProp<ViewStyle>;
  scrollStyle?: StyleProp<ViewStyle>;
}
export const DoctorAppointmentPatientInfo: React.FC<Props> = ({
  hideEndButton,
  contentStyling,
  children,
  whiteBackground,
  viewStyle,
  scrollStyle,
}) => {
  const { consultation, endConsultation, cancelAppointment } = useAppointmentContext();
  const { notStarted, started } = useAppointmentState(consultation);
  const { isMobile } = useBreakpoints();

  const showEndButton = useMemo(() => !hideEndButton && started, [started, hideEndButton]);

  const endConsultationButton = (
    <View style={{ marginTop: isMobile ? 20 : 5 }}>
      <OutlineButton danger label={consultationsLabels.endConsultation} funCallback={endConsultation} />
    </View>
  );
  return (
    <>
      <View style={[{ position: 'relative' }, viewStyle]}>
        {isMobile ? (
          <>
            <Row style={{ paddingHorizontal: 20, paddingBottom: 10 }}>
              <Column>
                <H4TtmRegularSecondaryBlack>{consultationsLabels.consultationDetails}</H4TtmRegularSecondaryBlack>
              </Column>
            </Row>
            <Splitter secondaryColor spacing={1} />
          </>
        ) : null}
        <ScrollView contentContainerStyle={[pb15, contentStyling]} style={scrollStyle}>
          <PatientConsultationInformation
            consultation={consultation}
            theme={whiteBackground ? 'white' : undefined}
            hideTitle={isMobile}
          />
          <TitledLinkButton
            style={mt10}
            title="Health Profile"
            target="_blank"
            path={`/patients/${consultation.patient.id}/health-profile`}
            to={{
              screen: screenName.PatientHealthProfile as never,
              params: { id: consultation.patient.id } as never,
            }}
          />

          <TitledLinkButton
            style={mt10}
            title="Existing Diagnoses"
            target="_blank"
            path={`/patients/${consultation.patient.id}`}
            to={{ screen: screenName.PatientDetails as never, params: { id: consultation.patient.id } as never }}
          />

          {children}

          {notStarted ? (
            <View style={mt20}>
              <OutlineButton danger label={labels.cancelAppointment} funCallback={cancelAppointment} />
            </View>
          ) : null}
          {showEndButton && isMobile ? endConsultationButton : null}
        </ScrollView>
        <Gradient />
      </View>
      {showEndButton && !isMobile ? endConsultationButton : null}
    </>
  );
};
