import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Call } from './Call';

import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const DoctorCall: React.FC = () => {
  const { startConsultation } = useAppointmentContext();
  const { isDesktop } = useBreakpoints();

  return (
    <View style={{ flex: 1 }}>
      <View style={[isDesktop ? styles.container : { maxHeight: '100%' }, { height: '100%' }]}>
        <Call small beforeJoin={startConsultation} />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    maxHeight: 500,
  },
  mobileHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
