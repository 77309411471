export const DocumentTypes = {
  pdf: 'application/pdf', // DocumentPicker.types.pdf
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DocumentPicker.types.docx
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // DocumentPicker.types.xlsx
};

export const ImageTypes = {
  png: 'image/png', // DocumentPicker.types.png
  jpg: 'image/jpg', // DocumentPicker.types.jpg
  jpeg: 'image/jpeg', // DocumentPicker.types.jpeg
};

export const ImageFormats = Object.values(ImageTypes);
export const DocumentFormats = Object.values(DocumentTypes);
export const ImagePdfFormats = [ImageTypes.png, ImageTypes.jpg, ImageTypes.jpeg, DocumentTypes.pdf];

export const DefaultAllowedFormats = [
  ImageTypes.png,
  ImageTypes.jpg,
  ImageTypes.jpeg,
  DocumentTypes.pdf,
  DocumentTypes.docx,
  DocumentTypes.xlsx,
];

export const AllAllowedFormats = [...Object.values(ImageTypes), ...Object.values(DocumentTypes)];

export const DocumentsAllowedFormats = [ImageTypes.png, ImageTypes.jpg, ImageTypes.jpeg, DocumentTypes.pdf];
