import React, { useRef } from 'react';

import { useOnDemandAvailability } from '~/api/hooks/preferences/OnDemandAvailabilityHook';
import { OnDemandAvailabilities, OnDemandAvailabilitiesRef } from '~/components/availabilities/OnDemandAvailabilities';
import { Button } from '~/components/commonButton';
import RegistrationLayout from '~/layouts/RegistrationLayout';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const OnDemandRegistration: React.FC = () => {
  const onDemandRef = useRef<OnDemandAvailabilitiesRef>(null);
  const { saveOnDemandAvailability, onDemandAvailability, isAvailable } = useOnDemandAvailability({ immediate: true });
  const { isMobile } = useBreakpoints();

  const buttons = (
    <Button label={labels.save} funCallback={() => onDemandRef.current?.submit(saveOnDemandAvailability)()} />
  );
  return (
    <RegistrationLayout
      title={isAvailable ? labels.youAreAvailable : labels.youAreNotAvailable}
      subtitle={isAvailable ? labels.doctorOnDemandSubMessageAvailable : labels.doctorOnDemandSubMessageNotAvailable}
      backLink={{ screen: screenName.AvailabilitiesScreen }}
      backTitle={labels.availabilities.toUpperCase()}
      buttons={buttons}
      childrenMarginTop={20}
      hideSubtitle
      noHorizontalPadding={!isMobile}>
      <OnDemandAvailabilities ref={onDemandRef} value={onDemandAvailability} />
    </RegistrationLayout>
  );
};
