import { StyleSheet } from 'react-native';

import { colors } from '../utils/colors';

const styles = StyleSheet.create({
  flex1: {
    flex: 1,
  },
  flex075: {
    flex: 0.75,
  },
  flex07: {
    flex: 0.7,
  },

  flex050: {
    flex: 0.5,
  },
  flex03: {
    flex: 0.3,
  },
  flex025: {
    flex: 0.25,
  },

  flexRow: {
    flexDirection: 'row',
  },

  flex01: {
    flex: 0.1,
  },

  flex015: {
    flex: 0.15,
  },
  flex02: {
    flex: 0.2,
  },
  flex09: {
    flex: 0.9,
  },
  flex085: {
    flex: 0.85,
  },
  flex08: {
    flex: 0.8,
  },
  m5: {
    margin: 5,
  },
  m10: {
    margin: 10,
  },
  m15: {
    margin: 15,
  },
  mt0: {
    marginTop: 0,
  },
  mt25: {
    marginTop: 25,
  },
  mb0: {
    marginBottom: 0,
  },
  ml0: {
    marginLeft: 0,
  },
  ml5: {
    marginLeft: 5,
  },
  ml10: {
    marginLeft: 10,
  },
  ml15: {
    marginLeft: 15,
  },
  ml20: {
    marginLeft: 20,
  },
  ml25: {
    marginLeft: 25,
  },
  mr0: {
    marginRight: 0,
  },
  mr5: {
    marginRight: 5,
  },
  mr10: {
    marginRight: 10,
  },
  mr15: {
    marginRight: 15,
  },
  mr25: {
    marginRight: 25,
  },
  mr30: {
    marginRight: 30,
  },
  mh1: {
    marginHorizontal: 1,
  },
  mh5: {
    marginHorizontal: 5,
  },
  mh10: {
    marginHorizontal: 10,
  },
  mh15: {
    marginHorizontal: 15,
  },
  mh20: {
    marginHorizontal: 20,
  },
  mv5: {
    marginVertical: 5,
  },
  mv10: {
    marginVertical: 10,
  },
  mv15: {
    marginVertical: 15,
  },
  mv20: {
    marginVertical: 20,
  },
  mt15: {
    marginTop: 15,
  },
  mt10: {
    marginTop: 10,
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  mb25: {
    marginBottom: 25,
  },
  mb30: {
    marginBottom: 30,
  },
  mb20: {
    marginBottom: 20,
  },
  mb15: {
    marginBottom: 15,
  },
  mb10: {
    marginBottom: 10,
  },
  mb5: {
    marginBottom: 5,
  },
  p5: {
    padding: 5,
  },
  p10: {
    padding: 10,
  },
  p15: {
    padding: 15,
  },
  pt0: {
    paddingTop: 0,
  },
  pt15: {
    paddingTop: 15,
  },
  pt30: {
    paddingTop: 30,
  },
  pb0: {
    paddingBottom: 0,
  },
  pb10: {
    paddingBottom: 10,
  },
  pb15: {
    paddingBottom: 15,
  },
  pb20: {
    paddingBottom: 20,
  },
  pl0: {
    paddingLeft: 0,
  },
  p20: {
    padding: 20,
  },
  pr0: {
    paddingRight: 0,
  },
  pv5: {
    paddingVertical: 5,
  },
  pv10: {
    paddingVertical: 10,
  },
  pv15: {
    paddingVertical: 15,
  },
  pv20: {
    paddingVertical: 20,
  },
  pv25: {
    paddingVertical: 25,
  },
  pv30: {
    paddingVertical: 30,
  },
  pv50: {
    paddingVertical: 50,
  },
  ph1: {
    paddingHorizontal: 1,
  },
  ph5: {
    paddingHorizontal: 5,
  },
  ph10: {
    paddingHorizontal: 10,
  },
  ph15: {
    paddingHorizontal: 15,
  },
  ph30: {
    paddingHorizontal: 30,
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsLeft: {
    alignItems: 'flex-start',
  },
  alignItemsRight: {
    alignItems: 'flex-end',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  alignSelfLeft: {
    alignSelf: 'flex-start',
  },
  alignSelfRight: {
    alignSelf: 'flex-end',
  },
  justyfyCenter: {
    justifyContent: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyStart: {
    justifyContent: 'flex-start',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  txtCenter: {
    textAlign: 'center',
  },
  txtLeft: {
    textAlign: 'left',
  },
  txtRight: {
    textAlign: 'right',
  },
  border0: {
    borderWidth: 0,
  },
  border1: {
    borderWidth: 1,
  },
  borderTop1: {
    borderTopWidth: 1,
  },
  borderBottom1: {
    borderBottomWidth: 1,
  },
  borderLeft1: {
    borderLeftWidth: 1,
  },
  borderRight1: {
    borderRightWidth: 1,
  },
  borderColor: {
    borderColor: colors.lightPurple2,
  },
  borderSecondaryColor: {
    borderColor: colors.info,
  },
  borderDangerColor: {
    borderColor: colors.danger,
  },
  borderRadius6: {
    borderRadius: 6,
  },
  borderRadius0: {
    borderRadius: 0,
  },
  bgSecondary: {
    backgroundColor: colors.lightPurple,
  },
  bgPrimary: {
    backgroundColor: colors.white,
  },
  elevation0: {
    elevation: 0,
  },
  elevation1: {
    elevation: 2,
  },
  elevation2: {
    elevation: 2,
  },
  elevation3: {
    elevation: 3,
  },

  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  lineHeight22: {
    lineHeight: 22,
  },
  lineHeight6: {
    lineHeight: 6,
  },
  safeAreaStyle: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.white,
    position: 'relative',
  },
});

export const {
  flexRow,
  flex01,
  flex015,
  flex02,
  flex025,
  flex03,
  flex050,
  flex07,
  flex075,
  flex08,
  flex085,
  flex09,
  flex1,
} = styles;
export const {
  borderRadius0,
  borderRadius6,
  border0,
  border1,
  borderTop1,
  borderBottom1,
  borderLeft1,
  borderRight1,
  borderColor,
  borderDangerColor,
  borderSecondaryColor,
} = styles;
export const {
  m5,
  m10,
  m15,
  mv5,
  mv10,
  mv15,
  mv20,
  mh1,
  mh5,
  mh10,
  mh15,
  mt0,
  mt20,
  mt35,
  mb0,
  ml0,
  ml10,
  ml25,
  mh20,
  mr0,
  mr5,
  mr10,
  mr15,
  mr25,
  mr30,
  mt15,
  mb20,
  mb15,
  mb25,
  mb30,
  mt10,
  ml15,
  ml5,
  mt25,
  mb5,
  mb10,
  ml20,
} = styles;
export const {
  p5,
  p10,
  p15,
  pv5,
  pv10,
  pv15,
  pv20,
  pv25,
  pv30,
  pv50,
  ph1,
  pb10,
  pb15,
  pb20,
  ph5,
  ph10,
  ph15,
  ph30,
  pb0,
  pt0,
  pt15,
  pt30,
  pl0,
  pr0,
  p20,
} = styles;
export const { alignItemsCenter, alignItemsLeft, alignItemsRight } = styles;
export const { alignSelfCenter, alignSelfLeft, alignSelfRight } = styles;
export const { justifyEnd, justifyStart, justifyCenter, spaceBetween } = styles;
export const { txtCenter, txtLeft, txtRight, bgSecondary, bgPrimary } = styles;
export const { elevation3, elevation0, elevation1, elevation2 } = styles;
export const { textRight, textLeft, textCenter } = styles;
export const { lineHeight22, lineHeight6 } = styles;
export const { safeAreaStyle } = styles;

// To Remove
export const { justyfyCenter } = styles;

export const wizardStyle = StyleSheet.create({
  container: { flex: 1, maxWidth: 430, width: '100%', alignSelf: 'center', maxHeight: 900 },
});

export const cardStyle = StyleSheet.create({
  cardContainer: {
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.lightPurple,
    backgroundColor: colors.lightPurple,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
});
