import React, { useContext, useState } from 'react';

import { usePageFocus } from '~/utils/hooks/FocusHook';

export interface IAccordionGroupContext {
  expanded: string;
  setExpanded: (value?: string | null) => void;
  isExpanded: (value?: string) => boolean;
}

export const AccordionGroupContext = React.createContext<IAccordionGroupContext>({} as IAccordionGroupContext);
export const useAccordionGroupContext = (): IAccordionGroupContext => useContext(AccordionGroupContext);

interface Props {
  children: React.ReactNode;
  keepState?: boolean;
}

export const AccordionGroupProvider: React.FC<Props> = ({ children, keepState }) => {
  const [expanded, setExpanded] = useState<string>(null);

  const isExpanded = (value: string) => value === expanded;

  usePageFocus(() => {
    if (!keepState) setExpanded(null);
  });

  return (
    <AccordionGroupContext.Provider value={{ expanded, setExpanded, isExpanded }}>
      {children}
    </AccordionGroupContext.Provider>
  );
};
