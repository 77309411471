import React from 'react';
import { StyleSheet, View } from 'react-native';

import { mb10 } from '~/common/commonStyles';
import { H2TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { CreateDiagnosisEditingComponent } from '~/components/doctor/patients/consultation/diagnosis/CreateDiagnosisEditingComponent';
import { colors } from '~/utils/colors';

export const CreateDiagnosisComponentMobile: React.FC = () => {
  return (
    <View style={styles.container}>
      <View style={mb10}>
        <H2TtmSemiBoldBlack>Add Consultation</H2TtmSemiBoldBlack>
      </View>
      <H6NsRegularBlack>
        Kindly fill-in the following details and submit the report for the patient to receive the necessary information
        about the consultation.
      </H6NsRegularBlack>
      <View style={styles.divider} />
      <CreateDiagnosisEditingComponent hideButtons />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    paddingBottom: 10,
  },
  divider: {
    width: '100%',
    backgroundColor: colors.lightPurple2,
    height: 1,
    marginVertical: 25,
  },
});
