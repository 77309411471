import Constants from 'expo-constants';
import React from 'react';

import { StripeProvider as LocalStripeProvider } from './IntegratedStripeProvider';

const publishableKey = Constants.expoConfig.extra.stripePublishableKey;

export const StripeProvider: React.FC<{ children: React.ReactElement | React.ReactElement[] }> = (props) => {
  return (
    <LocalStripeProvider publishableKey={publishableKey} urlScheme={Constants.expoConfig.scheme}>
      {props.children}
    </LocalStripeProvider>
  );
};
