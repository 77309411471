import { differenceInMinutes } from 'date-fns';

import { stringToDate } from '../dateAndTime';
import isString from '../types/isString';

import { DateTimeType } from '~/api/models/common/types/DateType';

interface Props<T> {
  timeInMinutes?: number;
  timeKey?: string;
  timeFormat?: string;
  extraPredicate?: (item: T) => boolean;
}

const DefaultValues: Props<any> = {
  timeInMinutes: 720,
  timeKey: 'created_at',
};

export function useNewBadge<T = any>(props?: Props<T>) {
  const showNewBadge = (localProps: Props<T> & { value: DateTimeType | any }) => {
    const options = { ...DefaultValues, ...props, ...localProps, value: localProps.value };

    const itemTime = stringToDate(isString(options.value) ? options.value : options.value[options.timeKey], {
      parseFormat: options.timeFormat,
    });
    if (!itemTime) return false;

    const difference = differenceInMinutes(new Date(), itemTime);
    return difference <= options.timeInMinutes && (!options.extraPredicate || options.extraPredicate(options.value));
  };

  return { showNewBadge };
}
