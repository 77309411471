import { get } from '../../axios';

import { ConsultationChannelResponse } from '~/api/models/accounts/responses/ConsultationChannelResponse';
import { LanguagesResponse } from '~/api/models/referenceData/responses/LanguagesResponse';

export const apiOnlineConsultationTypes = () => {
  return get<ConsultationChannelResponse>('/consultation-channels');
};

export const apiLanguages = () => {
  return get<LanguagesResponse>('/languages');
};

export const apiGetMePreferredChannels = () => {
  return get<ConsultationChannelResponse>('/me/consultation-channels');
};

export const apiGetMePreferredLanguages = () => {
  return get<LanguagesResponse>('/me/preferred-language');
};
