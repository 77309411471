import { useEffect, useState } from 'react';

import { ConsultationTypeModel } from '~/api/models/consultations/models/ConsultationTypeModel';
import { apiGetConsultationTypes } from '~/api/services/consultations';

export function useConsultationTypeListing() {
  const [consultationTypes, setConsultationTypes] = useState<ConsultationTypeModel[]>();

  const getConsultationTypes = () => {
    apiGetConsultationTypes().then((res) => setConsultationTypes(res.data));
  };

  useEffect(() => {
    getConsultationTypes();
  }, []);

  return { consultationTypes };
}
