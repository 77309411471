import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { HealthProfileEditHeader } from './HealthProfileEditHeader';
import { useProsthesisListing } from '../../../../api/hooks/ProsthesisListing';
import { Row, Column } from '../../../../theme/components/grid';
import { Checkbox } from '../../../inputs/Checkbox';

import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { useHealthProfileProsthesisContext } from '~/providers/healthProfile/HealthProfileProsthesisProvider';

interface Props {
  locked?: boolean;
  onStartEdit?: () => void;
}

export const PatientProsthesis: React.FC<Props> = ({ locked, onStartEdit }) => {
  const { prosthesisData } = useProsthesisListing();
  const { healthProfile, controlProsthesis } = useHealthProfileDataContext();
  const { getData } = useHealthProfileProsthesisContext();

  useEffect(() => {
    if (healthProfile) {
      getData().catch(() => {});
    }
  }, [healthProfile?.id]);

  return (
    <Row>
      <Column width={720}>
        <HealthProfileEditHeader title="Prosthesis/Implants/Grafts" onEdit={onStartEdit} hideEdit={!locked} />
      </Column>
      <Column span={12}>
        <Row>
          <Column width={320}>
            <Row>
              <Controller
                name="prosthesis"
                control={controlProsthesis}
                render={({ field: { onChange, value } }) => (
                  <>
                    {prosthesisData.map((prosthesisList) => {
                      return (
                        <Column key={prosthesisList.id} span={{ xs: 12, md: 6 }}>
                          <Checkbox
                            onChange={(newValue) => {
                              let newValues;
                              if (newValue) {
                                newValues = [...value, prosthesisList.id];
                              } else {
                                const index = value.findIndex((id) => id === prosthesisList.id);
                                if (index >= 0) {
                                  newValues = [...value];
                                  newValues.splice(index, 1);
                                } else newValues = value;
                              }

                              onChange(newValues);
                            }}
                            checked={!!value.find((id) => id === prosthesisList.id)}
                            label={prosthesisList.name}
                            disabled={locked}
                          />
                        </Column>
                      );
                    })}
                  </>
                )}
              />
            </Row>
          </Column>
        </Row>
      </Column>
    </Row>
  );
};
