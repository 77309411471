import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Platform, View } from 'react-native';

import { PharmacyPrescriptionsTable } from './PharmacyPrescriptionsTable';
import { Button, OutlineButton } from '../commonButton';
import { FormErrorMessage, H3TtmRegularBlack, H3TtmSemiBoldBlack, H6NsSemiBoldTheme } from '../commonText';
import { ModalContainer } from '../modals/ModalContainer';
import { BaseModalProps } from '../modals/interfaces/ModalProps';
import { SearchBox, SearchBoxRef } from '../searchBox';

import { usePrescriptionSearch } from '~/api/hooks/pharmacy/PharmacyPrescriptionsHook';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { requiredValidation } from '~/services/validationConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  onSelect: (prescription: PrescriptionModel) => void;
}

export const PharmacySearchModal: React.FC<Props> = ({ onHide, onSelect }) => {
  const { loading, searchByPartialReference, data, pagination, setPagination } = usePrescriptionSearch();
  const { isMobile } = useBreakpoints();
  const [noResults, setNoResults] = useState('');
  const [showTable, setShowTable] = useState(false);

  const searchBoxRef = useRef<SearchBoxRef>();
  const { handleSubmit, control, getValues } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      search: '',
    },
  });

  useEffect(() => {
    const search = getValues().search;
    if (!search || loading) return;
    if (!pagination?.total) {
      setNoResults(search);
    } else if (pagination?.total === 1) {
      selectPrescription(data[0]);
    } else {
      setShowTable(true);
    }
  }, [loading, pagination]);

  const selectPrescription = (prescription: PrescriptionModel) => {
    onHide();

    if (onSelect) onSelect(prescription);
  };
  const submit = async ({ search }: { search: string }) => {
    setNoResults('');

    searchByPartialReference({ query: search });
  };

  const buttons = !showTable
    ? [
        <Button label={labels.search} funCallback={handleSubmit(submit)} disabled={loading} />,
        <OutlineButton label={labels.close} funCallback={onHide} disabled={loading} />,
      ]
    : [
        <View style={isMobile ? { paddingHorizontal: MOBILE_HORIZONTAL_SPACE, paddingBottom: 10 } : null}>
          <OutlineButton label={labels.close} funCallback={onHide} disabled={loading} />
        </View>,
      ];

  return (
    <ModalContainer
      hideOnBackground
      onHide={onHide}
      showInfoLine
      buttons={buttons}
      fullScreen={showTable && isMobile}
      wide={showTable}
      padding={showTable ? 'sm' : undefined}
      edge={showTable && isMobile}
      noScroll={showTable && isMobile}
      onVisibleChanged={(visible) => {
        if (visible) setTimeout(() => searchBoxRef.current?.focus(), 500);
      }}>
      <View style={{ flex: 1 }}>
        {!showTable ? (
          <>
            <H3TtmSemiBoldBlack style={{ marginBottom: 20, textAlign: 'center' }}>
              Search Patients Prescriptions
            </H3TtmSemiBoldBlack>
            <Controller
              name="search"
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <View>
                  <SearchBox
                    ref={searchBoxRef}
                    onTextChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    onSubmit={handleSubmit(submit)}
                    placeholder="Search by reference number"
                    keyboardType="search"
                  />
                  {isSubmitted && error?.message ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                </View>
              )}
              rules={{
                required: requiredValidation('Search query'),
              }}
            />

            {noResults ? <H6NsSemiBoldTheme>No results found for query "{noResults}"</H6NsSemiBoldTheme> : null}
          </>
        ) : (
          <>
            <H3TtmRegularBlack
              style={[
                { marginBottom: 20 },
                isMobile ? { paddingHorizontal: MOBILE_HORIZONTAL_SPACE, marginTop: 20 } : null,
              ]}>
              Search results:{' '}
              <H3TtmSemiBoldBlack>
                {pagination.total} prescription{pagination?.total === 1 ? '' : 's'} found
              </H3TtmSemiBoldBlack>
            </H3TtmRegularBlack>
            <View style={{ minHeight: 400, flex: 1 }}>
              <PharmacyPrescriptionsTable
                prescriptions={data}
                pagination={pagination}
                loading={loading}
                onPageChanged={setPagination}
                onSelect={selectPrescription}
                allowRowPress
                emptyNote="No prescriptions found"
                hideNewBadge
              />
            </View>
          </>
        )}
      </View>
    </ModalContainer>
  );
};
