import React from 'react';
import { View } from 'react-native';

import { ml10 } from '~/common/commonStyles';
import { H6NsSemiBoldBlack } from '~/components/commonText';
import { StarIcon } from '~/components/svgImages';

interface Props {
  rating?: string;
}

export const RatingViewComponent: React.FC<Props> = ({ rating }) => {
  return (
    <View style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
      <>
        <StarIcon width={20} height={20} />
        <H6NsSemiBoldBlack style={ml10} accessibilityLabel={`${rating ?? 'Not available'}`}>
          {rating || 'N/A'}
        </H6NsSemiBoldBlack>
      </>
    </View>
  );
};
