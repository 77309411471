import { Route } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { View } from 'react-native';

import { useDependant } from '~/api/hooks/dependants/DependantHook';
import { mb10, mt15 } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { MobileMenuPageComponent } from '~/components/navigation/MobileMenuPageComponent';
import { DependentTurnedNotMinor } from '~/components/patient/dependants/components/modals/DependentTurnedNotMinor';
import { EditImageComponent } from '~/components/profile/EditImageComponent';
import { dependantsProfileMenu } from '~/constants/dependantsProfileMenu';
import { TitleLayout } from '~/layouts/TitleLayout';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props {
  route: Route<string, { id: string }>;
}
export const DependantDetailsMobileScreen: React.FC<Props> = ({ route }) => {
  const id = route.params.id;
  const { dependant, getCurrentDependant, showOver18Button } = useDependant({ id });

  const { registerModal, openModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.ModalTurnedNotMinor, DependentTurnedNotMinor);
  }, []);

  return (
    <TitleLayout
      title={labels.profile}
      subtitle={getAccountName(dependant?.account) || ' '}
      style={{ backgroundColor: colors.lightPurple }}>
      <EditImageComponent account={dependant?.account} onUpdate={getCurrentDependant} isDependant />
      {showOver18Button ? (
        <View style={mt15}>
          <Button
            label="Add Details"
            funCallback={() => {
              openModal(ModalName.ModalTurnedNotMinor, { dependent: dependant });
            }}
          />
        </View>
      ) : null}
      <View style={mb10} />
      <MobileMenuPageComponent
        menu={dependantsProfileMenu.map((item) => ({ ...item, link: { screen: item.link.screen, params: { id } } }))}
      />
    </TitleLayout>
  );
};
