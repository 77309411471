import React from 'react';
import { StyleSheet, View } from 'react-native';
import { List } from 'react-native-paper';

import { FaqModel } from '~/api/models/faq/models/FaqModel';
import { HTMLViewerComponent } from '~/components/commonComponents';
import { H5TtmSemiBoldBlack } from '~/components/commonText';
import { AccordionPaperTheme } from '~/theme/paper/AccordionPaperTheme';
import { colors } from '~/utils/colors';

interface Props {
  faq: FaqModel;
  borderTop?: boolean;
}

export const FaqQuestion: React.FC<Props> = ({ faq, borderTop }) => {
  return (
    <View style={[styles.border, borderTop ? styles.borderTop : null]}>
      <List.Accordion
        id={faq.id}
        theme={AccordionPaperTheme}
        titleNumberOfLines={0}
        title={<H5TtmSemiBoldBlack>{faq.question}</H5TtmSemiBoldBlack>}>
        <View style={styles.question}>
          <HTMLViewerComponent content={faq.answer} baseFont={12} />
        </View>
      </List.Accordion>
    </View>
  );
};

const styles = StyleSheet.create({
  question: {
    padding: 16,
  },
  border: {
    borderBottomColor: colors.lightPurple2,
    borderBottomWidth: 1,
    borderStyle: 'solid',
  },
  borderTop: {
    borderTopColor: colors.lightPurple2,
    borderTopWidth: 1,
  },
});
