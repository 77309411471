import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { DashboardCard } from '../../common/DashboardCard';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { H4TtwRegularSemiBold } from '~/components/commonText';
import { NavType } from '~/navigation/types';
import { SET_BOOKING_TYPE } from '~/redux/reducers/patient/appointmentReducer';
import { useAppDispatch } from '~/redux/store';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

interface Props {
  consultationType: ConsultationTypeEnum;
  icon: React.ReactNode;
  title: string;
}
export const ActionButton: React.FC<Props> = ({ consultationType, icon, title }): JSX.Element => {
  const { navigate } = useNavigation<NavType>();
  const dispatch = useAppDispatch();

  const onPress = () => {
    dispatch(SET_BOOKING_TYPE(consultationType));
    navigate(screenName.AppointmentsBooking);
  };
  const [cardWidth, setCardWidth] = useState<number>(0);
  const minCardWidth = 98;
  const { isMobile } = useBreakpoints();
  const onCardLayout = (event: any) => {
    const { width } = event.nativeEvent.layout;
    setCardWidth(width);
  };
  const shouldShowText = cardWidth > minCardWidth;
  return (
    <>
      {isMobile ? (
        <DashboardCard white style={[styles.mobileCard]} onPress={onPress}>
          <View style={styles.columnStyle}>
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>{icon}</View>
            <H4TtwRegularSemiBold style={styles.mobileTitle}>{title}</H4TtwRegularSemiBold>
          </View>
        </DashboardCard>
      ) : (
        <DashboardCard white style={[styles.dashboardCard]} onPress={onPress}>
          {shouldShowText ? (
            <View style={styles.rowStyle} onLayout={onCardLayout}>
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>{icon}</View>
              <View style={{ flex: 1, justifyContent: 'center' }}>
                <H4TtwRegularSemiBold style={styles.title}>{title}</H4TtwRegularSemiBold>
              </View>
            </View>
          ) : (
            <View style={styles.iconRowStyle} onLayout={onCardLayout}>
              <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>{icon}</View>
            </View>
          )}
        </DashboardCard>
      )}
    </>
  );
};
const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  dashboardCard: {
    width: '100%',
    maxHeight: 60,
    flex: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
  },
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    flex: 1,
  },
  iconRowStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    flex: 1,
  },
  title: {
    marginLeft: 10,
    color: colors.darkPurple,
  },
  mobileTitle: {
    marginTop: 8,
    textAlign: 'center',
    color: colors.darkPurple,
    lineHeight: 18,
  },
  mobileCard: {
    minWidth: 100,
    minHeight: 115,
    flexDirection: 'column',
    flex: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
  },
  columnStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    flex: 1,
    justifyContent: 'center',
  },
});
