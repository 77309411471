export const privateSubscriptionsChannel = (userId: number) => `private-subscription.${userId}`;

export const privateConsultationGeneralChannel = (accountId: number) => `private-consultation.${accountId}`;

export const privateConsultationIdChannel = (consultationId: number) => `private-consultation-id.${consultationId}`;

export const privateConsultationChatChannel = (consultationId: number) => `private-consultation-chat.${consultationId}`;

export const privateAssociationRequestChannel = (requestId: number) => `private-association-request.${requestId}`;

export const privatePharmacyChannel = (pharmacyId: number) => `private-pharmacy.${pharmacyId}`;

export const privatePrescriptionsChannel = (accountId: number) => `private-prescription.${accountId}`;

export const privateGeneralChannel = (accountId: number) => `private-general.${accountId}`;
