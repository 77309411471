import React, { useState } from 'react';
import { View } from 'react-native';

import { mt10 } from '../../../common/commonStyles';
import { labels } from '../../../utils/labels';

import { StripeBankTextComponent } from '~/components/stripe/StripeBankTextComponent';
import { StripeConnect } from '~/components/stripe/StripeConnect';
import { StripeLogo } from '~/components/svgImages';
import RegistrationLayout from '~/layouts/RegistrationLayout';
import { Button } from '~/components/commonButton';
import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';

export const SetupBankDetails: React.FC = () => {
  const { updateRegistrationStatus } = useRegistrationSteps();
  const [waiting, setWaiting] = useState(false);

  const onContinue = async () => {
    try {
      setWaiting(true);
      await updateRegistrationStatus();
    } catch {
    } finally {
      setWaiting(false);
    }
  };
  const continueButton = <Button funCallback={onContinue} label={labels.continue} disabled={waiting} />;

  return (
    <RegistrationLayout title={labels.addYourBankDetails} buttons={<StripeConnect alreadyOnBoarded={continueButton} />}>
      <View style={{ width: '100%' }}>
        <StripeBankTextComponent />
        <View style={mt10}>
          <StripeLogo width={51} height={21} />
        </View>
      </View>
    </RegistrationLayout>
  );
};
