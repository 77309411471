import React from 'react';

import { AmountPaidStep } from '../steps/AmountPaidStep';
import { ClaimDescriptionStep } from '../steps/ClaimDescriptionStep';
import { PatientDeclarationStep } from '../steps/PatientDeclarationStep';
import { ClaimDescriptionMobile } from '../steps/mobileSteps/ClaimDescriptionMobile';
import { ClaimDescriptionMobileForm } from '../steps/mobileSteps/ClaimDescriptionMobileForm';
import { InsuranceClaimFormMobile } from '../steps/mobileSteps/InsuranceClaimFormMobile';

import { WizardSteps } from '~/utils/hooks/WizardStepsHook';

export const InsuranceClaimFormWizardSteps = (): WizardSteps => {
  return [
    { key: 'claim_description', component: <ClaimDescriptionStep /> },
    { key: 'claim_amount_form', component: <AmountPaidStep /> },
    { key: 'policy_declaration', component: <PatientDeclarationStep /> },
  ];
};

export const InsuranceClaimFormMobileWizardSteps = (): WizardSteps => {
  return [
    { key: 'claim_description', component: <ClaimDescriptionMobile /> },
    { key: 'claim_details_form', component: <ClaimDescriptionMobileForm /> },
    { key: 'claim_amount_form', component: <InsuranceClaimFormMobile /> },
    { key: 'policy_declaration', component: <PatientDeclarationStep /> },
  ];
};
