import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { InsuranceClaimItem } from './InsuranceClaimItem';
import { STATUS_COLOR_MAPPING } from './constants/StatusColorMapping';
import { useInsuranceClaims } from './hooks/useInsuranceClaimsList';

import { ConsultationTypeMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { InsuranceClaimStatusEnum } from '~/api/models/insurance/models/enums/InsuranceClaimStatusEnum';
import { getInsuranceClaimMedia } from '~/api/services/insurance';
import { flex1, mb5 } from '~/common/commonStyles';
import { DataTable, DataTableColumns } from '~/components/common/DataTable/DataTable';
import { ConsultationTypeIcon } from '~/components/common/consultations/ConsultationTypeIcon';
import { OutlineButton } from '~/components/commonButton';
import {
  H5TtmRegularBlack,
  H6NsRegularBlack,
  H6NsRegularDisableColor,
  H6NsSemiBoldBlack,
} from '~/components/commonText';
import { ConsultationViewItem } from '~/components/doctor/patients/consultation/ConsultationViewItem';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { NavType } from '~/navigation/types';
import { isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

const getStatusBadge = (consultation: ConsultationModel) => {
  if (
    consultation?.insurance_claim_status?.state === InsuranceClaimStatusEnum.draft ||
    consultation?.insurance_claim_status == null
  ) {
    return null;
  }

  return (
    <StatusBadge
      label={STATUS_COLOR_MAPPING[consultation.state]?.label}
      color={STATUS_COLOR_MAPPING[consultation.state]?.color}
    />
  );
};

const downloadInsuranceClaim = async (insuranceClaimId: number): Promise<MediaModel> => {
  const res = await getInsuranceClaimMedia(insuranceClaimId);
  if (res.data?.media?.length) return res.data.media[0];
  else throw new Error('We were not able to retrieve the insurance claim pdf at this time. Please try again later.');
};

export const InsuranceClaimsList: React.FC = () => {
  const { insuranceClaims, setPage, loading } = useInsuranceClaims();
  const { navigate } = useNavigation<NavType>();
  const { isMobile } = useBreakpoints();
  const getActionByConsultationState = (consultation: ConsultationModel) => {
    switch (consultation.state) {
      case ConsultationStateEnum.Submitted: {
        if (
          consultation?.insurance_claim_status?.state === InsuranceClaimStatusEnum.draft ||
          consultation?.insurance_claim_status == null
        ) {
          return (
            <OutlineButton
              funCallback={() => {
                navigate(screenName.InsuranceClaimForm, { consultationId: consultation.id });
              }}
              label="CLAIM"
              style={{ width: 140 }}
            />
          );
        }

        return (
          <ConsultationViewItem
            withBottomBorder={false}
            style={{ paddingVertical: 0, paddingHorizontal: 0 }}
            removeIconBorder
            getMediaModel={() => {
              return downloadInsuranceClaim(consultation.insurance_claim_status.id);
            }}
          />
        );
      }
      case ConsultationStateEnum.Ended:
        return <H6NsRegularDisableColor>AWAITING REPORT FROM DOCTOR</H6NsRegularDisableColor>;
    }

    return null;
  };

  const columns: DataTableColumns = [
    {
      key: 'start_date',
      title: 'Date and time',
      columnStyle: {
        maxWidth: 100,
      },
      cell: (_, itemData: ConsultationModel) => {
        return (
          <View style={flex1}>
            <H6NsSemiBoldBlack>{parseDateTime(itemData.start_at, { outputFormat: DATE_FORMAT })}</H6NsSemiBoldBlack>
            <H6NsRegularBlack>{parseDateTime(itemData.start_at, { outputFormat: TIME_FORMAT })}</H6NsRegularBlack>
          </View>
        );
      },
    },

    {
      key: 'doctor',
      title: 'Specialist',
      cellStyle: {
        minWidth: 200,
      },
      headerStyle: {
        minWidth: 200,
      },
      cell: (_, itemData: ConsultationModel) => (
        <ProfileDetailsComponent account={itemData.doctor} theme="table" isDoctor style={flex1} />
      ),
      hide: () => isDoctorVersion(),
    },

    {
      key: 'patient',
      title: 'Patient',
      cellStyle: {
        minWidth: 200,
      },
      headerStyle: {
        minWidth: 200,
      },
      cell: (_, itemData: ConsultationModel) => (
        <ProfileDetailsComponent account={itemData.patient} theme="table" style={flex1} />
      ),
    },
    {
      key: 'type',
      title: 'Consultation type',
      columnStyle: {
        maxWidth: 160,
      },

      cell: (_, itemData: ConsultationModel) => (
        <View style={{ display: 'flex' }}>
          <H5TtmRegularBlack style={mb5}> {ConsultationTypeMapping[itemData.type]}</H5TtmRegularBlack>
          <ConsultationTypeIcon consultation={itemData} />
        </View>
      ),
    },
    {
      key: 'status',
      title: 'Status',

      cell: (_, itemData: ConsultationModel) => getStatusBadge(itemData),
      hide: () => isDoctorVersion(),
      columnStyle: {
        maxWidth: 120,
      },
    },

    {
      key: 'actions',
      columnStyle: {
        maxWidth: 250,
      },
      cell: (_, itemData: ConsultationModel) => getActionByConsultationState(itemData),
      contentNumeric: true,
    },
  ];

  return (
    <View style={{ flex: 1, backgroundColor: colors.white }}>
      <DataTable
        tableId="insuranceClaims"
        columns={columns}
        items={!isMobile ? (loading ? [] : insuranceClaims?.data ?? []) : insuranceClaims?.data ?? []}
        page={insuranceClaims?.meta?.current_page || 1}
        totalCount={insuranceClaims?.meta?.total || 0}
        numberOfItemsPerPage={insuranceClaims?.meta?.per_page || 0}
        infiniteScroll={isMobile ? { loading, estimatedItemSize: { desktop: 60, mobile: 110 } } : null}
        emptyNote="No insurance claims are available"
        loading={loading}
        onPageChanged={setPage}
        mobileBreakpoint={DeviceSizeDefaults.tablet}
        mobileRenderItem={(itemData: ConsultationModel) => (
          <InsuranceClaimItem itemData={itemData}>{getActionByConsultationState(itemData)}</InsuranceClaimItem>
        )}
      />
    </View>
  );
};
