import React, { useRef } from 'react';

import { PaymentCards } from '../../../components/cards/PaymentCards';
import { Button } from '../../../components/commonButton';
import { H3TtmSemiBoldBlack } from '../../../components/commonText';
import { NestedPageInternalLayout } from '../../../layouts/NestedPageInternalLayout';
import { Column, Row } from '../../../theme/components/grid';
import { labels } from '../../../utils/labels';

import { usePaymentCards } from '~/api/hooks/cards/PaymentCardsHook';
import { mt10, mt25 } from '~/common/commonStyles';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { StripeBankTextComponent } from '~/components/stripe/StripeBankTextComponent';
import { StripeConnect } from '~/components/stripe/StripeConnect';
import { StripePaymentMethodRef } from '~/integrations/stripe/interface';
import { StripePaymentMethod } from '~/integrations/stripe/paymentMethod';
import { StripePaymentMethodTypeEnum } from '~/integrations/stripe/paymentMethod/constants/StripePaymentMethodTypeEnum';
import { isClinicVersion, isDoctorVersion, isPatientVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

export const ProfilePaymentDetails: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const paymentMethod = useRef<StripePaymentMethodRef>();
  const { loading, cardInfo, addCard, removeCard } = usePaymentCards({ paymentMethodRef: paymentMethod });

  return (
    <>
      <NestedPageInternalLayout
        backTitle="Profile"
        backLink={{ screen: screenName.ProfileScreen }}
        title={labels.paymentDetails}>
        <Row>
          <Column span={{ xs: 12, sm: 8, lg: 6 }}>
            {isMobile ? null : <H3TtmSemiBoldBlack>{labels.paymentDetails}</H3TtmSemiBoldBlack>}
            <StripeBankTextComponent />
          </Column>
          {isPatientVersion() ? (
            <Column span={{ xs: 8, sm: 4, lg: 2 }} style={[mt10]}>
              <Button funCallback={addCard} disabled={loading} label="Add Card" />
            </Column>
          ) : null}
        </Row>

        {isDoctorVersion() || isClinicVersion() ? (
          <Row style={mt25}>
            <Column>
              <StripeConnect limitWidth />
            </Column>
          </Row>
        ) : null}

        {isPatientVersion() ? (
          <Row style={[mt25]}>
            {loading && !cardInfo ? (
              <Column>
                <LoadingActivityIndicator />
              </Column>
            ) : (
              <PaymentCards cardInfo={cardInfo} removeCard={removeCard} />
            )}
          </Row>
        ) : null}
      </NestedPageInternalLayout>

      <StripePaymentMethod
        ref={paymentMethod}
        type={StripePaymentMethodTypeEnum.SETUP}
        title={isDoctorVersion() || isClinicVersion() ? 'Setup account' : 'Add card'}
      />
    </>
  );
};
