import { useMemo, useState } from 'react';

import { useReduxTablePagination } from '~/api/hooks/common/useReduxTablePagination';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ConsultationsPaginatedIndexResponse } from '~/api/models/consultations/responses/ConsultationsIndexResponse';
import { apiGetConsultationsIndex } from '~/api/services/consultations';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useBreakpoints } from '~/utils/hooks/GridHook';

const TABLE_NAME = 'insuranceClaims';
export const useInsuranceClaims = () => {
  const [loading, setLoading] = useState(true);
  const { isMobile } = useBreakpoints();

  const { items, pagination, setPagination, updateData, resetTable } = useReduxTablePagination<
    ConsultationModel,
    ConsultationsPaginatedIndexResponse
  >({
    isInfiniteScroll: isMobile,
    tableName: TABLE_NAME,
    getItems: async (params) => {
      setLoading(true);

      const response = await apiGetConsultationsIndex({
        filter: {
          state: [ConsultationStateEnum.Submitted, ConsultationStateEnum.Ended],
        },
        page: params.page,
        sort: '-created_at',
      });
      setLoading(false);
      return response;
    },
  });

  const insuranceClaims = useMemo(
    () => ({
      data: items,
      meta: pagination,
      links: [],
    }),
    [items, pagination]
  );

  const submitQuery = async () => {
    try {
      setLoading(true);

      await resetTable();
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setLoading(false);
    }
  };

  const setPage = (pageInfo: TablePaginationInfo) => {
    if (!items || pageInfo.page === 0) return;
    setPagination(pageInfo);
  };

  return { insuranceClaims, setPage, pagination, loading };
};
