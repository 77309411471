import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { ProgressBars } from '~/components/commonComponents';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { BackNavigation } from '~/components/navigation/BackNavigation';
import { PageLayout } from '~/layouts/PageLayout';
import { NavType } from '~/navigation/types';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { patientLabels } from '~/utils/labels/patient';
import { screenName } from '~/utils/screenName';

export const AppointmentBookingWizard: React.FC = () => {
  const {
    loading,
    wizard: {
      progress,
      state,
      actions: { previous },
    },
  } = useAppointmentBookingContext();

  const { isMobile } = useBreakpoints();
  const navigation = useNavigation<NavType>();

  const back = () => {
    if (!previous()) {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.navigate(screenName.Appointments);
      }
    }
  };

  const header = (
    <View style={[{ flexBasis: 315, flexGrow: isMobile ? 1 : 0, flexShrink: 1 }]}>
      <BackNavigation
        black
        title={patientLabels.bookAppointment}
        onPress={back}
        titleStyle={{ flex: 1, alignItems: isMobile ? 'center' : undefined, display: 'flex', marginRight: 14 }}
      />
      <ProgressBars progress={progress.percentile} noSpacing />
    </View>
  );

  return (
    <PageLayout
      header={header}
      noHeaderPadding={!isMobile}
      scrollContentNode={
        loading ? (
          <View>
            <LoadingActivityIndicator />
          </View>
        ) : (
          state.component
        )
      }
    />
  );
};
