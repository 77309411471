import { SubscriptionFeatureModel } from './SubscriptionFeatureModel';
import { SubscriptionFeeModel } from './SubscriptionFeeModel';
import { SubscriptionPriceWithMobilePricingModel } from './SubscriptionPriceModel';

export interface SubscriptionPlanModel {
  plan_title: string;
  type: SubscriptionTypeEnum;
  description?: string;
  price?: SubscriptionPriceWithMobilePricingModel[];
  subscription_features?: SubscriptionFeatureModel[];
  subscription_platform_fee?: SubscriptionFeeModel[];
}

export enum SubscriptionTypeEnum {
  FREEDOCTOR = 'free_doctor',
  PREMIUMDOCTOR = 'premium_doctor',
  FREEPATIENT = 'free_patient',
  PREMIUMPATIENT = 'premium_patient',
}
