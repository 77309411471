import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useMemo, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { DashboardCard } from '../common/DashboardCard';

import { useClinicRequestsList } from '~/api/hooks/patientDataHandling/ClinicRequestsListHook';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { flex1, mr10 } from '~/common/commonStyles';
import { DataTable, DataTableColumns } from '~/components/common/DataTable/DataTable';
import { ExtraSmallNsRegularBlack, SmallNsRegularSecondaryBlack, SmallNsSemiBoldBlack } from '~/components/commonText';
import { ColouredStatusBadge } from '~/components/misc/ColouredStatusBadge';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { ArrowRight } from '~/components/svgImages';
import { ClinicConsultationAssignBadgeStatus } from '~/constants/clinic/ClinicStatus';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { parseDateTime, DAY_MONTH_FORMAT, displayTimeRange, TIME_FORMAT } from '~/utils/dateAndTime';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

const REQUEST_LIST_MINIMUM = 1;
const RECORD_HEIGHT = 85;
const EXTRA_CONTENT_HEIGHT = 60;

const Columns: DataTableColumns = [
  {
    key: 'profile',
    title: 'Profile',
    columnStyle: { maxWidth: 400, minWidth: 180 },
    cell: (_, itemData: ConsultationModel) => <ProfileDetailsComponent account={itemData.patient} theme="table" />,
  },
  {
    key: 'reason',
    title: 'Reason',
    columnStyle: { maxWidth: 400 },
    cell: (_, itemData: ConsultationModel) => (
      <SmallNsRegularSecondaryBlack>{itemData.reason}</SmallNsRegularSecondaryBlack>
    ),
  },
  {
    key: 'date',
    title: 'Appointment',
    columnStyle: { maxWidth: 400, minWidth: 100 },
    cell: (_, itemData: ConsultationModel) => {
      if (itemData.type === ConsultationTypeEnum.ON_DEMAND) {
        return (
          <View style={flex1}>
            <SmallNsSemiBoldBlack>{`${
              parseDateTime(itemData.start_at, {
                outputFormat: DAY_MONTH_FORMAT,
              }) ?? ''
            }`}</SmallNsSemiBoldBlack>
            <SmallNsRegularSecondaryBlack style={{ textWrap: 'wrap' }}>
              {displayTimeRange(
                parseDateTime(itemData.start_at || itemData.request_submitted_at, { outputFormat: TIME_FORMAT }),
                parseDateTime(itemData.end_at, { outputFormat: TIME_FORMAT })
              )}
            </SmallNsRegularSecondaryBlack>
          </View>
        );
      } else {
        const firstAvailability = itemData?.patient_availabilities[0];
        return (
          <View style={flex1}>
            <SmallNsSemiBoldBlack>{`${
              parseDateTime(firstAvailability.from, {
                outputFormat: DAY_MONTH_FORMAT,
              }) ?? ''
            }`}</SmallNsSemiBoldBlack>
            <SmallNsRegularSecondaryBlack style={{ textWrap: 'wrap' }}>
              from {parseDateTime(firstAvailability.from, { outputFormat: TIME_FORMAT })}
            </SmallNsRegularSecondaryBlack>
          </View>
        );
      }
    },
  },
  {
    key: 'state',
    cell: (state: ConsultationStateEnum) => <ColouredStatusBadge {...ClinicConsultationAssignBadgeStatus[state]} />,
    columnStyle: { maxWidth: 150, minWidth: 90 },
    contentNumeric: true,
  },
  {
    key: 'actions',
    cell: () => <ArrowRight height={10} width={5} />,
    columnStyle: { maxWidth: 50, display: 'flex', flexDirection: 'row-reverse' },
  },
];

export const ClinicRequestsList: React.FC = () => {
  const [containerHeight, setContainerHeight] = useState<number>(undefined);
  const { requests, loading, getRequests } = useClinicRequestsList();
  const { navigate } = useNavigation<NavType>();

  const recordCount = useMemo(
    () => Math.max(REQUEST_LIST_MINIMUM, Math.floor((containerHeight - EXTRA_CONTENT_HEIGHT) / RECORD_HEIGHT)),
    [requests, containerHeight]
  );

  const slicedRequests = useMemo(() => {
    return requests?.slice(0, recordCount) || [];
  }, [requests, recordCount]);

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, getRequests);
    return removeListener;
  }, []);

  const onRowPress = (_: string, consultation: ConsultationModel) => {
    navigate(screenName.ClinicConsultationRequest, { id: consultation.id });
  };

  const { showNewBadge } = useNewBadge<ConsultationModel>({
    timeKey: 'request_submitted_at',
    extraPredicate: (item) => item.state === ConsultationStateEnum.RequestSubmitted,
  });

  const onLayout = (event) => {
    const { height } = event.nativeEvent.layout;
    setContainerHeight(height);
  };

  usePageFocus(() => {
    getRequests();
  });
  return (
    <View style={flex1} onLayout={onLayout}>
      <DashboardCard title={labels.requests} viewAllLink={screenName.Requests} style={flex1}>
        <DataTable
          tableId="clinicRequests"
          columns={Columns}
          items={slicedRequests}
          onRowPress={onRowPress}
          page={1}
          hideHeaders
          hidePagination
          rowIsNewIdentifier={(item) => showNewBadge({ value: item })}
          style={flex1}
          wrapperStyle={styles.table}
          rowStyle={styles.rowStyle}
          rowHoverStyle={styles.rowHoverStyle}
          loading={loading}
          emptyNote={labels.noRequests}
          scrollEnabled={slicedRequests?.length ? false : undefined}
          mobileBreakpoint={DeviceSizeDefaults.tablet}
          mobileRenderItem={(item: ConsultationModel, content) => {
            return (
              <View style={{ width: '100%' }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  <ProfileDetailsComponent account={item.patient} theme="table" />
                  <ColouredStatusBadge {...ClinicConsultationAssignBadgeStatus[item.state]} />
                </View>
                <View
                  style={{
                    width: '100%',
                    borderColor: colors.lightPurple2,
                    borderTopWidth: 1,
                    marginTop: 10,
                    paddingTop: 10,
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                  <View style={[flex1, { paddingRight: 10, borderRightWidth: 1, borderColor: colors.lightPurple2 }]}>
                    <ExtraSmallNsRegularBlack>Reason</ExtraSmallNsRegularBlack>
                    <SmallNsRegularSecondaryBlack>{item.reason || 'No reason specified'}</SmallNsRegularSecondaryBlack>
                  </View>
                  <View style={[flex1, { paddingLeft: 10 }]}>
                    <ExtraSmallNsRegularBlack>Appointment date</ExtraSmallNsRegularBlack>
                    {content.date.content}
                  </View>
                </View>
              </View>
            );
          }}
        />
      </DashboardCard>
    </View>
  );
};

const styles = StyleSheet.create({
  rowStyle: {
    backgroundColor: colors.white,
    marginVertical: 5,
    borderBottomColor: colors.transparent,
    borderRadius: 6,
    paddingVertical: 5,
    marginHorizontal: 2,
  },
  rowHoverStyle: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  table: {
    borderColor: colors.transparent,
    paddingRight: 5,
  },
});
