import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/reducers';
import { UPDATE_DIETARY_RESTRICTIONS_LIST } from '../../redux/reducers/consultationDetailsReducer';
import { useAppDispatch } from '../../redux/store';
import { apiGetAllDietaryRestrictions } from '../services/healthProfile/dietaryRestrictions';

export function useDietaryRestrictionsListing() {
  const dietaryData = useSelector((state: RootState) => state.consultationDetailsReducer.dietaryRestrictionsList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!dietaryData?.length) {
      apiGetAllDietaryRestrictions().then((res) => {
        dispatch(UPDATE_DIETARY_RESTRICTIONS_LIST(res.data));
      });
    }
  }, [dietaryData]);

  const filteredDietaryData = useMemo(() => {
    return dietaryData?.filter((item) => item.name.toLowerCase() !== 'none');
  }, [dietaryData]);

  return { dietaryData: filteredDietaryData };
}
