import React, { useEffect, useMemo } from 'react';
import { Linking } from 'react-native';

import { useAccountDetails } from '~/api/hooks/accounts/AccountDetails';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { mb15 } from '~/common/commonStyles';
import { SmallNsRegularBlack, SmallTtmSemiBoldDisabled } from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { parseDateTime, DATE_FORMAT, DATE_TIME_FORMAT } from '~/utils/dateAndTime';
import { getAccountID, getAccountName, getAccountPhone } from '~/utils/personalDetailsUtils';

interface Props {
  consultation: ConsultationModel;
}

export const ClinicRequestDetails: React.FC<Props> = ({ consultation }) => {
  const { address, getAddressDetails } = useAccountDetails({ id: consultation?.patient?.id });
  const details = useMemo(() => {
    const phone = consultation && getAccountPhone(consultation.patient);
    return consultation
      ? [
          {
            label: 'Patient Name',
            value: getAccountName(consultation.patient),
            weight: '600',
          },
          {
            label: 'Patient ID',
            value: getAccountID(consultation.patient),
          },
          {
            label: 'Patient Phone',
            value: phone,
            link: phone ? `tel:${phone}` : undefined,
            color: colors.info,
            weight: '600',
          },
          {
            label: 'Patient E-mail',
            value: consultation.patient.email,
            link: consultation.patient.email ? `mailto:${consultation.patient.email}` : undefined,
            color: colors.info,
            weight: '600',
          },
          {
            label: 'Date of Birth',
            value: parseDateTime(consultation.patient.date_of_birth, { outputFormat: DATE_FORMAT }),
          },
          {
            label: 'Address',
            value: [address?.line_1, address?.post_code, address?.country?.name].filter((item) => item).join('\n'),
          },
          {
            label: 'Reason',
            value: consultation.reason || 'No reason was given',
            color: consultation.reason ? colors.black : colors.placeholderGrey,
          },
          {
            label: 'Date',
            value:
              parseDateTime(consultation.start_at || consultation.created_at, { outputFormat: DATE_FORMAT }) || 'N/A',
            weight: '600',
          },
          {
            label: 'Requested at',
            value: parseDateTime(consultation.request_submitted_at, { outputFormat: DATE_TIME_FORMAT }) || 'N/A',
            weight: '600',
          },
        ]
      : [];
  }, [consultation, address]);

  useEffect(() => {
    getAddressDetails();
  }, [consultation?.patient?.id]);

  const handleLink = (link: string) => {
    if (!link) return;

    Linking.canOpenURL(link)
      .then(() => {
        return Linking.openURL(link);
      })
      .catch(ErrorAlert);
  };
  return (
    <Grid grid={{ gutters: 4 }}>
      <Container>
        <Row>
          <Column>
            {details.map((item, index) => (
              <Row key={index} style={mb15}>
                <Column width={100}>
                  <SmallTtmSemiBoldDisabled>{item.label}</SmallTtmSemiBoldDisabled>
                </Column>
                <Column>
                  <SmallNsRegularBlack
                    style={{ color: item.color || colors.black, fontWeight: item.weight }}
                    onPress={item.link ? () => handleLink(item.link) : null}>
                    {item.value}
                  </SmallNsRegularBlack>
                </Column>
              </Row>
            ))}
          </Column>
          <Column>
            <Row justifyContent="center">
              <Column span="auto">
                <ProfileImageComponent size={130} account={consultation.patient} />
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </Grid>
  );
};
