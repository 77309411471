import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';

import { apiUseRole } from '../../api/services/authorisation';
import { apiSubmitPasscode } from '../../api/services/registration';
import { apiAddRole } from '../../api/services/roles';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import { PassCodeInputComponent } from '../../components/passCode/PassCodeInputComponent';
import { requiredValidation, passcodeLengthValidation } from '../../services/validationConfig';
import { appUserRole, isClinicVersion, isNative, isWeb } from '../../utils/buildConfig';
import { labels } from '../../utils/labels';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { useLoginDetails } from '~/api/hooks/accounts/LoginDetails';
import { Button } from '~/components/commonButton';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { UPDATE_LOGIN_DETAILS } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { useLoadReferrer } from '~/utils/hooks/StoreReferrerHook';
import { registrationLabels } from '~/utils/labels/registrationLabels';

interface Props {
  title?: string;
  subtitle?: string;
  onBeforeCreatePasscode?: () => Promise<void>;
}

export const PassCodeRegisterScreen: React.FC<Props> = ({ title, subtitle, onBeforeCreatePasscode }): JSX.Element => {
  // const navigation = useNavigation();
  const [loading, setLoading] = React.useState(false);
  const loginDetails = useLoginDetails();
  const { getReferrer } = useLoadReferrer();

  const { updateRegistrationStatus } = useRegistrationSteps();

  const dispatch = useAppDispatch();

  const { control, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      passcode: '',
    },
  });

  const createPasscode = async (details: { passcode: string }) => {
    try {
      setLoading(true);
      if (onBeforeCreatePasscode) await onBeforeCreatePasscode();
      await apiSubmitPasscode({ passcode: details.passcode });
      const role = appUserRole();
      if (!isClinicVersion()) {
        const referrer = await getReferrer();
        await apiAddRole({ role, referral_link: referrer });
      }

      await apiUseRole({ role });

      dispatch(UPDATE_LOGIN_DETAILS({ ...loginDetails.details, steppedUp: true }));

      await updateRegistrationStatus();
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setLoading(false);
    }
  };

  const buttons = isWeb() ? [<Button funCallback={handleSubmit(createPasscode)} label={labels.continue} />] : null;

  return (
    <EnhancedRegistrationLayout
      title={title || registrationLabels.registerPasscodeTitle}
      subtitle={subtitle || registrationLabels.registerPasscodeSubtitle}
      buttons={buttons}
      compressed={!isNative()}
      flexContent>
      <View
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-around',
          width: '100%',
          flexDirection: 'column',
        }}>
        <Controller
          name="passcode"
          control={control}
          render={({ field: { onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
            <PassCodeInputComponent
              onEnter={() => handleSubmit(createPasscode)()}
              onChange={onChange}
              creating
              errorMessage={isSubmitted && error?.message}
              loading={loading}
            />
          )}
          rules={{
            minLength: passcodeLengthValidation(4),
            required: requiredValidation('Passcode'),
          }}
        />
      </View>
    </EnhancedRegistrationLayout>
  );
};
