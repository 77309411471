import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { View } from 'react-native';

import { CancelContinueFooter } from '../buttons/CancelContinueFooter';
import { H3TtmSemiBoldBlack } from '../commonText';
import FloatingInput from '../floatingInputBox';
import { Dropdown } from '../inputs/dropdown/Dropdown';
import { ErrorAlert } from '../modals/ErrorAlert';

import { useUserAddressListing } from '~/api/hooks/accounts/UserAddressListing';
import { useCountryListing, CountryListEnum } from '~/api/hooks/referenceData/CountryListing';
import { MaltaId } from '~/api/hooks/referenceData/constants/CountryConstants';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { FormAddressModel } from '~/api/models/addresses/models/AddressModel';
import { apiDeleteAddress, apiAddAddress, apiUpdateAddress } from '~/api/services/addresses';
import { SuccessAlert } from '~/common/commonMethods';
import { mt10, mb10 } from '~/common/commonStyles';
import { INavigate } from '~/common/types/navigation/navigate';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import {
  requiredValidation,
  validationSchema,
  alphaNumericValidationWithSpace,
  minLengthValidation,
} from '~/services/validationConfig';
import { Grid, Container, Row, Column } from '~/theme/components/grid';
import { getChanges } from '~/utils/diffUtil';
import { ChangeReturn, ChangeResultType } from '~/utils/diffUtil/types';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props {
  backTitle?: string;
  backLink?: INavigate;
  account: AccountModel;
  title?: string;
}

interface Form {
  address: FormAddressModel[];
}

export const AccountAddressManagementComponent: React.FC<Props> = ({ account, backLink, backTitle, title }) => {
  const { isMobile } = useBreakpoints();
  const [waiting, setWaiting] = useState(false);
  const countryItems = useCountryListing(CountryListEnum.COUNTRY_NAME);

  const { formAddresses, getAddresses } = useUserAddressListing({
    accountId: account?.id,
    requireAccount: true,
  });

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = useForm<Form>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      address: [
        {
          country_id: MaltaId,
          post_code: '',
          line_1: '',
        },
      ],
    },
  });

  const resetAddress = () => {
    if (formAddresses) {
      reset({
        address: [...formAddresses],
      });
    } else {
      reset({
        address: [
          {
            country_id: MaltaId,
            post_code: '',
            line_1: '',
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (formAddresses) {
      resetAddress();
    }
  }, [formAddresses]);

  const showSuccessAlert = (changes: ChangeReturn) => {
    let message = '';
    switch (changes.change) {
      case ChangeResultType.CREATED:
        {
          const plural = changes.created.length > 1;
          message = `New ${plural ? 'addresses' : 'address'} successfully created.`;
        }
        break;
      case ChangeResultType.UPDATED:
        {
          const plural = Object.keys(changes.updated).length > 1;
          message = `${plural ? 'Addresses' : 'Address'} successfully updated.`;
        }
        break;
      case ChangeResultType.DELETED:
        {
          const plural = changes.deleted.length > 1;
          message = `${plural ? 'Addresses' : 'Address'} successfully removed.`;
        }
        break;
      default:
        message = 'Addresses have been successfully updated.';
        break;
    }

    SuccessAlert([message]);
  };

  const submit = async (details: Form) => {
    try {
      setWaiting(true);
      const changes = getChanges<FormAddressModel>(formAddresses, details.address);

      if (changes.deleted?.length) {
        for (const id of changes.deleted) {
          await apiDeleteAddress({ id: +id });
        }
      }
      if (changes.created?.length) {
        for (const address of changes.created) {
          await apiAddAddress({ account_id: account.id, ...(address as FormAddressModel) });
        }
      }
      if (changes.updated) {
        for (const updateKey of Object.keys(changes.updated)) {
          const address = changes.updated[updateKey].full;
          await apiUpdateAddress({
            id: +updateKey,
            data: {
              account_id: account.id,
              country_id: address.country_id,
              line_1: address.line_1,
              post_code: address.post_code,
            },
          });
        }
      }

      await getAddresses();

      showSuccessAlert(changes);
      reset(details);
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const buttons = (
    <View style={mt10}>
      <CancelContinueFooter
        onConfirm={handleSubmit(submit)}
        confirmTitle={labels.update}
        onCancel={resetAddress}
        disabled={!isDirty}
        waiting={waiting}
      />
    </View>
  );

  return (
    <NestedPageInternalLayout backTitle={backTitle} backLink={backLink} title={labels.homeAddress} footer={buttons}>
      <Grid>
        <Container alignSelf="flex-start">
          {isMobile ? null : (
            <Row style={mb10} justifyContent="space-between">
              <Column>
                <H3TtmSemiBoldBlack>{title || labels.homeAddress}</H3TtmSemiBoldBlack>
              </Column>
            </Row>
          )}
          <Row>
            <Column>
              <Row>
                <Column span={{ xs: 12, md: 8, lg: 5 }}>
                  <Controller
                    name={`address.${0}.country_id`}
                    control={control}
                    render={({ field: { onChange, value }, formState: { isSubmitted }, fieldState: { error } }) => (
                      <Dropdown
                        list={countryItems.map((item) => ({ label: item.name, value: item.id }))}
                        setValue={onChange}
                        value={value}
                        error={isSubmitted && !!error}
                        errorMessage={isSubmitted && error?.message}
                        label={labels.country}
                        showMandatory
                        searchable
                      />
                    )}
                    rules={{
                      required: requiredValidation(labels.country),
                    }}
                  />
                </Column>
              </Row>
              <Row>
                <Column span={{ xs: 12, md: 8, lg: 5 }}>
                  <Controller
                    name={`address.${0}.post_code`}
                    control={control}
                    render={({ field: { onChange, value }, formState: { isSubmitted }, fieldState: { error } }) => (
                      <FloatingInput
                        showMandatory
                        maxLength={validationSchema.postCode.maxLength}
                        error={isSubmitted && !!error}
                        errorMessage={isSubmitted && error?.message}
                        value={value}
                        onChangeValue={onChange}
                        label={labels.postCode}
                        disabled={waiting}
                      />
                    )}
                    rules={{
                      validate: {
                        alphaNumericValidationWithSpace,
                      },
                      required: requiredValidation(labels.postCode),
                      minLength: minLengthValidation(validationSchema.postCode.minLength),
                    }}
                  />
                </Column>
              </Row>
              <Row>
                <Column span={{ xs: 12, md: 8, lg: 5 }}>
                  <Controller
                    name={`address.${0}.line_1`}
                    control={control}
                    render={({ field: { onChange, value }, formState: { isSubmitted }, fieldState: { error } }) => (
                      <FloatingInput
                        showMandatory
                        error={isSubmitted && !!error}
                        errorMessage={isSubmitted && error?.message}
                        value={value}
                        maxLength={validationSchema.address.maxLength}
                        onChangeValue={onChange}
                        label={labels.address}
                        disabled={waiting}
                      />
                    )}
                    rules={{
                      required: requiredValidation(labels.address),
                      minLength: minLengthValidation(validationSchema.address.minLength),
                    }}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </Grid>
    </NestedPageInternalLayout>
  );
};
