import { EventApi, EventInput } from '@fullcalendar/core';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CalendarEventResponse } from '~/api/models/calendar/responses/CalendarEventResponse';
import { CalendarEventModel } from '~/api/models/preferences/models/CalendarEventModel';
import { colors } from '~/utils/colors';

interface IState {
  calendarEvents: EventInput[];
  calendarEventsForSelectedDate: EventApi[];
  eventsForSelectedDate: CalendarEventModel[];
  currentSelectedDate: Date;
}

const initialState: IState = {
  calendarEvents: undefined,
  calendarEventsForSelectedDate: undefined,
  eventsForSelectedDate: undefined,
  currentSelectedDate: new Date(),
};

const calendarEventsSlice = createSlice({
  name: 'calendarAppointments',
  initialState,
  reducers: {
    ADD_CALENDAR_EVENTS: (state, action: PayloadAction<CalendarEventResponse>) => {
      return {
        ...state,
        calendarEvents: [...convertResponseToEventSource(action.payload)],
      };
    },
    CLEAR_CALENDAR_EVENTS: (state) => {
      return {
        ...state,
        calendarEvents: undefined,
      };
    },
    ADD_CALENDAR_EVENTS_FOR_SELECTED_DATE: (state, action: PayloadAction<EventApi[]>) => {
      return {
        ...state,
        calendarEventsForSelectedDate: [...action.payload],
      };
    },
    ADD_EVENTS_FOR_SELECTED_DATE: (state, action: PayloadAction<CalendarEventModel[]>) => {
      return {
        ...state,
        eventsForSelectedDate: [...action.payload],
      };
    },
    ADD_CURRENT_SELECTED_DATE: (state, action: PayloadAction<Date>) => {
      return {
        ...state,
        currentSelectedDate: action.payload,
      };
    },
    LOGOUT: () => ({
      ...initialState,
    }),
  },
});

const convertResponseToEventSource = (response: CalendarEventResponse) => {
  const eventInputList: EventInput[] = [];
  response.forEach((event) => {
    event.events.forEach((e) => {
      const isUnavailable = e.event_type === 'unavailability';
      const obj = {
        id: event.date,
        start: e.starts_at,
        end: e.ends_at,
        date: event.date,
        allDay: isUnavailable,
        extendedProps: e,
        display: isUnavailable ? 'background' : '',
        backgroundColor: isUnavailable ? colors.lightPurple2 : undefined,
        title: isUnavailable ? 'Unavailable' : '',
      } as EventInput;

      eventInputList.push(obj);
    });
  });
  return eventInputList;
};

export const {
  ADD_CALENDAR_EVENTS,
  CLEAR_CALENDAR_EVENTS,
  ADD_CALENDAR_EVENTS_FOR_SELECTED_DATE,
  ADD_EVENTS_FOR_SELECTED_DATE,
  ADD_CURRENT_SELECTED_DATE,
  LOGOUT,
} = calendarEventsSlice.actions;

export default calendarEventsSlice.reducer;
