import React from 'react';

import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { InsuranceNavigation } from '~/navigation/authorised/InsuranceNavigation';
import { insuranceLabels } from '~/utils/labels/insurance';

export const InsurancePage: React.FC = () => {
  return (
    <PageLayout
      hideBack
      title={insuranceLabels.insuranceTitle}
      hideGradient
      scrollContentNode={<InsuranceNavigation />}
    />
  );
};
