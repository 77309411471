import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';

import { useLoginDetails } from '../../api/hooks/accounts/LoginDetails';
import ForgotPasscodeScreen from '../../pages/login/ForgotPasscodeScreen';
import { LoginPasscodeScreen } from '../../pages/login/LoginPasscodeScreen';
import LoginPhoneNumberScreen from '../../pages/login/LoginPhoneNumberScreen';
import EmailScreen from '../../pages/registration/EmailScreen';
import HomeAddress from '../../pages/registration/HomeAddress';
import OtpEmailValidationScreen from '../../pages/registration/OtpEmailValidationScreen';
import OtpForgotPasscodeValidationScreen from '../../pages/registration/OtpForgotPasscodeValidationScreen';
import OtpMobileValidationScreen from '../../pages/registration/OtpMobileValidationScreen';
import OtpTwoStepValidationScreen from '../../pages/registration/OtpTwoStepValidationScreen';
import { PassCodeRegisterScreen } from '../../pages/registration/PassCodeRegisterScreen';
import PersonalDetails from '../../pages/registration/PersonalDetails';
import RegisterPhoneNumberScreen from '../../pages/registration/RegisterPhoneNumberScreen';
import RegistrationStepperScreen from '../../pages/registration/RegistrationStepperScreen';
import { RegistrationTermsAndConditionsScreen } from '../../pages/registration/RegistrationTermsAndConditionsScreen';
import { TwoStepSelectionScreen } from '../../pages/registration/TwoStepSelectionScreen';
import UploadIdentificationScreen from '../../pages/registration/UploadIdentificationScreen';
import UploadProfileScreen from '../../pages/registration/UploadProfileScreen';
import { AvailabilitiesScreen } from '../../pages/registration/doctor/AvailabilitiesScreen';
import { SetupBankDetails } from '../../pages/registration/doctor/BankDetails';
import { ConsultationChannelPreferences } from '../../pages/registration/doctor/ConsultationChannelPreferences';
import DoctorVerificationScreen from '../../pages/registration/doctor/DoctorVerificationScreen';
import { TypeOfConsultationScreen } from '../../pages/registration/doctor/TypeOfConsultationScreen';
import { isDoctorVersion, isPharmacyVersion, whenAppType } from '../../utils/buildConfig';
import { screenName } from '../../utils/screenName';

import { hasToken } from '~/api/axios/interceptors';
import { usePharmacyLinkingAction } from '~/api/hooks/pharmacy/PharmacyLinkingAction';
import { ActionHandlerScreen } from '~/pages/actions/ActionHandlerScreen';
import { PermanentlyDeleteAccountGuest } from '~/pages/doctor/settings/PermanentlyDeleteAccountGuest';
import { LoginUserIdentityScreen } from '~/pages/login/LoginUserIdentityScreen';
import { RegisterClinicScreen } from '~/pages/registration/RegisterClinicScreen';
import { RegisterPharmacyScreen } from '~/pages/registration/RegisterPharmacyScreen';
import { ConsultationPreferences } from '~/pages/registration/doctor/ConsultationPreferences';
import { CostPerConsultationTypeScreen } from '~/pages/registration/doctor/CostPerConsultationTypeScreen';
import { HomeVisitRegistration } from '~/pages/registration/doctor/availabilities/HomeVisitRegistration';
import { OnDemandRegistration } from '~/pages/registration/doctor/availabilities/OnDemandRegistration';
import { ScheduledAppointmentsRegistration } from '~/pages/registration/doctor/availabilities/ScheduledAppointmentsRegistration';
import { PharmacyRegistrationDetailsScreen } from '~/pages/registration/pharmacy/PharmacyRegistrationDetailsScreen';
import { PharmacyRegistrationLocationScreen } from '~/pages/registration/pharmacy/PharmacyRegistrationLocationScreen';
import { useBreakpoints } from '~/utils/hooks/GridHook';

const Stack = createNativeStackNavigator();
export const RegistrationStack: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [hasAuthToken, setHasAuthToken] = React.useState(false);
  const loginDetails = useLoginDetails();
  const { isMobile } = useBreakpoints();
  const { validateLinkingDetails } = usePharmacyLinkingAction();

  const checkHasAuthToken = () => {
    setLoading(true);
    hasToken()
      .then(setHasAuthToken)
      .then(() => setLoading(false));
  };
  React.useEffect(() => {
    checkHasAuthToken();
  }, []);

  React.useEffect(() => {
    if (isPharmacyVersion() && loginDetails?.details?.steppedUp) validateLinkingDetails();

    if (loginDetails?.details?.steppedUp) checkHasAuthToken();
  }, [loginDetails?.details?.steppedUp]);

  const initialRouteName = loginDetails.details ? screenName.PassCodeLoginScreen : screenName.RegisterScreen;

  if (loading) return null;

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={initialRouteName}>
      <Stack.Screen
        name={screenName.RegisterScreen}
        component={whenAppType({
          else: RegisterPhoneNumberScreen,
          whenPharmacist: RegisterPharmacyScreen, // TO CHECK
          whenClinic: RegisterClinicScreen, // TO CHECK
        })}
      />
      <Stack.Screen
        name={screenName.LoginScreen}
        component={whenAppType({
          else: LoginPhoneNumberScreen,
          whenPharmacist: LoginUserIdentityScreen, // TO CHECK
          whenClinic: LoginUserIdentityScreen, // TO CHECK
        })}
      />
      <Stack.Screen name={screenName.RegistrationMobileOtp} component={OtpMobileValidationScreen} />
      <Stack.Screen name={screenName.PassCodeRegisterScreen} component={PassCodeRegisterScreen} />
      <Stack.Screen name={screenName.RegistrationEmailOtp} component={OtpEmailValidationScreen} />
      <Stack.Screen name={screenName.ForgotPasscodeScreen} component={ForgotPasscodeScreen} />
      <Stack.Screen name={screenName.ForgotPasscodeOtp} component={OtpForgotPasscodeValidationScreen} />

      {loginDetails?.details?.number || loginDetails?.details?.email ? (
        <>
          <Stack.Screen name={screenName.PassCodeLoginScreen} component={LoginPasscodeScreen} />
          <Stack.Screen name={screenName.OTPScreen_2FA} component={OtpTwoStepValidationScreen} />
        </>
      ) : null}

      {hasAuthToken ? (
        <>
          <Stack.Screen name={screenName.RegisterTerms} component={RegistrationTermsAndConditionsScreen} />
          {!isPharmacyVersion() ? <Stack.Screen name={screenName.EmailScreen} component={EmailScreen} /> : null}
          <Stack.Screen name={screenName.TwoStepSelection} component={TwoStepSelectionScreen} />
          <Stack.Screen name={screenName.UploadIdentificationScreen} component={UploadIdentificationScreen} />
          <Stack.Screen name={screenName.PersonalDetails} component={PersonalDetails} />
          <Stack.Screen name={screenName.HomeAddress} component={HomeAddress} />
          <Stack.Screen name={screenName.UploadProfileScreen} component={UploadProfileScreen} />

          {isMobile ? (
            <Stack.Screen name={screenName.RegistrationStepperScreen} component={RegistrationStepperScreen} />
          ) : null}

          {isDoctorVersion() ? (
            <>
              <Stack.Screen name={screenName.DoctorVerification} component={DoctorVerificationScreen} />

              <Stack.Screen name={screenName.TypeOfConsultationScreen} component={TypeOfConsultationScreen} />
              <Stack.Screen name={screenName.AddBankDetails} component={SetupBankDetails} />
              <Stack.Screen name={screenName.PreferencesScreen} component={ConsultationPreferences} />
              <Stack.Screen name={screenName.CostPerConsultationType} component={CostPerConsultationTypeScreen} />
              <Stack.Screen
                name={screenName.ConsultationChannelPreferences}
                component={ConsultationChannelPreferences}
              />
              <Stack.Screen name={screenName.AvailabilitiesScreen} component={AvailabilitiesScreen} />
              <Stack.Screen
                name={screenName.HomeVisitPreferencesAvailabilitiesScreen}
                component={HomeVisitRegistration}
              />
              <Stack.Screen
                name={screenName.DoctorOnDemandPreferencesAvailabilitiesScreen}
                component={OnDemandRegistration}
              />
              <Stack.Screen
                name={screenName.ScheduledAppointmentsPreferencesAvailabilitiesScreen}
                component={ScheduledAppointmentsRegistration}
              />
            </>
          ) : null}

          {isPharmacyVersion() ? (
            <>
              <Stack.Screen name={screenName.RegisterPharmacyDetails} component={PharmacyRegistrationDetailsScreen} />
              <Stack.Screen
                name={screenName.RegisterPharmacyLocations}
                component={PharmacyRegistrationLocationScreen}
              />
            </>
          ) : null}
        </>
      ) : null}
      <Stack.Screen name={screenName.Action} component={ActionHandlerScreen} />
      <Stack.Screen name={screenName.PermanentlyDeleteAccount} component={PermanentlyDeleteAccountGuest} />
    </Stack.Navigator>
  );
};
