import React from 'react';
import { StyleSheet, View } from 'react-native';

import { AutoSaveState } from './AutoSaveState';

import { mb15 } from '~/common/commonStyles';
import { H6NsSemiBoldBlack } from '~/components/commonText';

interface Props {
  title: string;
  noAutoSave: boolean;
}

export const ConsultationReportMobileHeader: React.FC<Props> = ({ title, noAutoSave }) => {
  return (
    <View style={[mb15, styles.content]}>
      <H6NsSemiBoldBlack>{title}</H6NsSemiBoldBlack>
      {noAutoSave ? <AutoSaveState /> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
