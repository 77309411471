import React from 'react';
import { View, ViewProps, ViewStyle } from 'react-native';

import { IGridContext, useGridContext } from './Grid';
import getSpan from './utils/getSpan';

import { IBreakpoints, BreakpointString } from '~/constants/grid/types';

interface Props {
  children?: React.ReactNode;
  style?: ViewProps['style'];
  component?: React.FC<ViewProps>;
  alignSelf?: ViewStyle['alignSelf'];
  offset?: number;
  span?: boolean | number | string | IBreakpoints | BreakpointString;
  width?: number;
}

export const Column: React.FC<Props> = ({ children, style, component = View, alignSelf, offset, span, width }) => {
  const grid = useGridContext();

  const baseStyle = {
    flexDirection: 'column',
    minHeight: 1,
    position: 'relative',
  };

  const getSelfAlignment = (alignSelf) => {
    if (typeof alignSelf === 'string') {
      return {
        alignSelf,
      };
    }
    return [];
  };

  const getGutterStyle = ({ gutter }: IGridContext) => {
    if (typeof gutter === 'undefined') {
      return [];
    }

    return {
      paddingHorizontal: gutter / 2,
    };
  };

  const getSpanStyle = (grid: IGridContext, span: Props['span']) => {
    if (span === false || span === 0) {
      return;
    }

    if (typeof span === 'object') {
      span = getSpan(grid.breakpoints, span, grid.width);
    }

    if (typeof span === 'string' && span === 'auto') {
      return null;
    }

    if (typeof span === 'number') {
      return {
        width: (100 * span) / grid.columns + '%',
      };
    }

    if (width) return { maxWidth: width, width: '100%' };

    return {
      flex: 1,
    };
  };

  const getOffsetStyle = (grid: IGridContext, offset: Props['offset']) => {
    if (offset && typeof offset === 'number') {
      return {
        marginLeft: (100 * offset) / grid.columns + '%',
      };
    }

    return {};
  };

  const isHidden = (grid: IGridContext, span: Props['span']) => {
    if (span === false || span === 0) {
      return false;
    }

    return span === 'hide' || (typeof span === 'object' && getSpan(grid.breakpoints, span, grid.width) === 'hide');
  };

  if (isHidden(grid, span)) return null;

  const spanStyle = getSpanStyle(grid, span);

  const styles = [].concat(
    baseStyle,
    getGutterStyle(grid),
    getSelfAlignment(alignSelf),
    getOffsetStyle(grid, offset),
    spanStyle,
    style
  );

  return React.createElement(component, { style: styles }, children);
};
