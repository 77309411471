import { Camera } from 'expo-camera';
import * as MediaLibrary from 'expo-media-library';
import { PermissionStatus } from 'expo-modules-core';

export const cameraPermission = async () => {
  const query = await navigator?.permissions
    ?.query({
      name: 'camera' as PermissionName,
    })
    .catch(() => {});
  if (!query) return { state: PermissionStatus.UNDETERMINED };
  const getPermission = await Camera.getCameraPermissionsAsync();
  return getPermission;
};

export const checkCameraPermission = async () => {
  const hasPermission = await Camera.requestCameraPermissionsAsync();
  return hasPermission;
};

export const checkMediaLibraryPermission = async () => {
  const hasPermission = await MediaLibrary.requestPermissionsAsync();
  return hasPermission;
};

export const mediaLibraryPermission = async () => {
  const getPermission = await MediaLibrary.getPermissionsAsync();
  return getPermission;
};

export const microphonePermission = async () => {
  const query = await navigator?.permissions
    ?.query({
      name: 'microphone' as PermissionName,
    })
    .catch(() => {});
  if (!query) return { state: PermissionStatus.UNDETERMINED };
  const getPermission = await Camera.getMicrophonePermissionsAsync();
  return getPermission;
};

export const checkMicrophonePermission = async () => {
  const hasPermission = await Camera.requestMicrophonePermissionsAsync();
  return hasPermission;
};
