import React, { useEffect, useMemo, useRef } from 'react';
import { Dimensions, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

import { H5NsSemiBoldSecondaryBlack } from '../../commonText';

import { textCenter } from '~/common/commonStyles';
import { colors } from '~/utils/colors';

interface Props {
  items: { title: string; key: string | number; focused: boolean }[];
  tabWidth?: number;
  onPress: (key: string | number) => void;
}

const { width } = Dimensions.get('screen');
export const TabSelectionMobileComponent: React.FC<Props> = ({ items, tabWidth, onPress }) => {
  const scrollRef = useRef<ScrollView>();

  const calculatedTabWidth = useMemo(() => {
    return tabWidth ?? Math.max((width * 0.95) / items.length, 120);
  }, [width, tabWidth, items?.length]);
  useEffect(() => {
    const index = items.findIndex((item) => item.focused);
    scrollRef.current?.scrollTo({ y: 0, x: calculatedTabWidth * index, animated: true });
  }, [items]);

  return (
    <View style={{ paddingHorizontal: 10 }}>
      <ScrollView
        ref={scrollRef}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollContainer}>
        <View style={styles.borderBottom} />
        {items.map((item, index) => {
          const isFocused = item.focused;

          return (
            <TouchableOpacity
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              onPress={() => onPress(item.key)}
              style={[{ flexGrow: 1, alignItems: 'center' }]}
              key={index}>
              <View
                style={[
                  styles.tabContainer,
                  isFocused ? styles.activeTab : null,
                  { maxWidth: calculatedTabWidth, width: '100%' },
                ]}>
                <H5NsSemiBoldSecondaryBlack
                  maxFontSizeMultiplier={1.2}
                  style={[textCenter, !isFocused ? styles.inactiveTab : null, { fontSize: 12 }]}>
                  {item.title}
                </H5NsSemiBoldSecondaryBlack>
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  tabContainer: {
    display: 'flex',
    padding: 10,
    borderBottomColor: colors.lightPurple2,
    borderBottomWidth: 2,
    minHeight: 50,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  activeTab: {
    borderBottomColor: colors.purple,
  },
  inactiveTab: {
    color: colors.lightPurple3,
  },
  scrollContainer: {
    marginBottom: 10,
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    minWidth: '100%',
    position: 'relative',
  },
  borderBottom: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 2,
    backgroundColor: colors.lightPurple2,
  },
});
