import React, { useContext } from 'react';
import { Control } from 'react-hook-form';

import { BaseConsultationDataContext } from './BaseConsultationDataContext';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { PrivateNoteModel } from '~/api/models/consultations/models/PrivateNoteModel';

export interface IPrivateNoteDataContext extends BaseConsultationDataContext {
  control: Control<PrivateNoteModel>;
  consultation: ConsultationModel;
}

export const PrivateNoteDataContext = React.createContext<IPrivateNoteDataContext>({} as IPrivateNoteDataContext);
export const usePrivateNoteDataContext = (): IPrivateNoteDataContext => useContext(PrivateNoteDataContext);
