import React, { useMemo, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { flex1, textCenter } from '~/common/commonStyles';
import { SmallNsSemiBoldBlack } from '~/components/commonText';
import { OTVideoState, OTConnectionState } from '~/integrations/openTok/types/OTConnectionState';
import { isWeb, isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  style?: StyleProp<ViewStyle>;
  subscriberConnectionState: OTConnectionState;
  subscriberVideoState: OTVideoState;
}

export const SubscriberConnectionStateComponent: React.FC<Props> = ({
  subscriberConnectionState,
  subscriberVideoState,
  style,
}) => {
  const { isMobile } = useBreakpoints();
  const [center, setCenter] = useState(false);

  const subscriberConnectionMessage = useMemo(() => {
    switch (subscriberVideoState) {
      case OTVideoState.NotSupported:
        setCenter(true);
        return `Video is not supported on this ${isWeb() ? 'browser' : 'device'}`;
      case OTVideoState.PoorQuality:
        setCenter(true);
        return 'Video disabled due to poor connection';
      case OTVideoState.Warning:
        setCenter(false);
        return 'Video quality is poor';
    }

    setCenter(true);
    if (isDoctorVersion()) {
      switch (subscriberConnectionState) {
        case OTConnectionState.Initial:
          return 'Waiting for patient to join';
        case OTConnectionState.Disconnected:
          return 'The patient has left the call';
      }
    } else {
      switch (subscriberConnectionState) {
        case OTConnectionState.Initial:
          return 'Waiting for doctor to join';
        case OTConnectionState.Disconnected:
          return 'The doctor has left the call';
      }
    }
    return undefined;
  }, [subscriberConnectionState, subscriberVideoState]);

  if (!subscriberConnectionMessage) return;
  return (
    <View
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: center ? 'center' : 'flex-start',
        alignItems: center ? 'center' : 'flex-start',
        padding: 10,
      }}>
      <View style={[styles.container, isMobile ? styles.containerMobile : null, style]}>
        <SmallNsSemiBoldBlack style={[flex1, textCenter, isMobile ? styles.textColorMobile : styles.textColor]}>
          {subscriberConnectionMessage}
        </SmallNsSemiBoldBlack>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    borderWidth: 2,
    padding: 10,
    margin: 10,
    maxWidth: 200,
    display: 'flex',
    flexDirection: 'row',
    borderColor: colors.info,
    backgroundColor: `${colors.lightGrey}99`,
  },
  containerMobile: {
    borderColor: colors.lightGrey,
    backgroundColor: `${colors.lightGrey}99`,
  },
  textColor: {
    color: colors.info,
  },
  textColorMobile: {
    color: colors.white,
  },
});
