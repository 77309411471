import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

import { H6NsSemiBoldBlack, H5TtmSemiBoldInfo, H6NsRegularBlack } from '../commonText';

import { SubscriptionTypeEnum } from '~/api/models/subscriptions/models/SubscriptionPlanModel';
import { SubscriptionPlanMeResponse } from '~/api/models/subscriptions/responses/SubscriptionPlanMeResponse';
import { NavType } from '~/navigation/types';
import { Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';
interface Props {
  style?: StyleProp<ViewStyle>;
  currentPlan: SubscriptionPlanMeResponse;
}
export const SubscriptionTypeCard: React.FC<Props> = ({ style, currentPlan }) => {
  const { isMobile } = useBreakpoints();
  const { navigate } = useNavigation<NavType>();
  return (
    <TouchableOpacity
      onPress={() => navigate(screenName.ProfileScreen, { screen: screenName.ProfileSubscriptionPlan })}>
      <View style={[style, styles.container, isMobile ? null : { width: 380 }]}>
        <View>
          <View style={[styles.topRow, { borderTopLeftRadius: 10 }]}>
            <H6NsSemiBoldBlack>{labels.subscriptionPlan}</H6NsSemiBoldBlack>
          </View>
          <View style={styles.bottomRow}>
            <H5TtmSemiBoldInfo>
              {currentPlan?.subscription_type === SubscriptionTypeEnum.PREMIUMPATIENT
                ? labels.premiumPlan
                : labels.freePlan}
            </H5TtmSemiBoldInfo>
          </View>
        </View>
        <Column>
          <View style={[styles.topRow, { borderTopRightRadius: 10 }]}>
            <H6NsSemiBoldBlack>{labels.nextDue}</H6NsSemiBoldBlack>
          </View>
          <View style={styles.bottomRow}>
            <H6NsRegularBlack>
              {parseDateTime(currentPlan?.valid_until, { outputFormat: DATE_FORMAT })}
            </H6NsRegularBlack>
          </View>
        </Column>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: { borderRadius: 10, flexDirection: 'row', borderColor: colors.lightPurple, borderWidth: 1 },
  topRow: {
    backgroundColor: colors.lightPurple2,
    paddingHorizontal: 25,
    paddingVertical: 7,
  },
  bottomRow: { paddingHorizontal: 25, paddingVertical: 7 },
});
