import { useEffect, useRef, useState } from 'react';

import { MediaWithStatusModel } from '~/api/models/common/models/MediaWithStatusModel';
import { MedicalNoteModel } from '~/api/models/consultations/models/MedicalNoteModel';
import { SickLeaveFormModel } from '~/api/models/consultations/models/SickLeaveFormModel';
import {
  apiCreateMedicalNote,
  apiDeleteMedicalNote,
  apiGetMedicalNote,
  apiGetMedicalNoteMedia,
  apiUpdateMedicalNote,
} from '~/api/services/consultations/medicalNotes';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { ErrorAlert } from '~/components/modals/ErrorAlert';

interface Props {
  consultationId: number;
}
export function useMedicalNote({ consultationId }: Props) {
  const [loading, setLoading] = useState(false);
  const [saving, setSavingLocally] = useState(false);
  const [error, setError] = useState<ErrorResponse>();
  const [data, setData] = useState<MedicalNoteModel>();
  const [generatedFiles, setGeneratedFiles] = useState<MediaWithStatusModel>();

  const setSaving = (saving: boolean, error?: ErrorResponse) => {
    setSavingLocally(saving);
    if (!saving) setError(error);
  };

  const getMedicalNote = async () => {
    setLoading(true);
    try {
      const res = await apiGetMedicalNote({ consultationId });
      setData(res.data);
      return res;
    } catch {
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  const getMedicalNoteMedia = async (medicalNoteId: number) => {
    if (medicalNoteId) {
      setLoading(true);
      await apiGetMedicalNoteMedia({ id: medicalNoteId })
        .then((res) => {
          setGeneratedFiles(res.data);
        })
        .catch(ErrorAlert);
      setLoading(false);
    }
  };

  const setMedicalNote = async (formData: { note?: string; medicalNoteId?: number }) => {
    try {
      setSaving(true);
      if (formData.medicalNoteId) {
        if (!formData.note) {
          await apiDeleteMedicalNote({ id: formData.medicalNoteId });

          setData(null);
        } else {
          const res = await apiUpdateMedicalNote({
            id: formData.medicalNoteId,
            params: { note: formData.note, consultation_id: consultationId },
          });

          setData(res.data);
          return res;
        }
      } else if (formData.note.length > 0) {
        const res = await apiCreateMedicalNote({ note: formData.note, consultation_id: consultationId });
        setData(res.data);
        return res;
      }
    } catch (e) {
      setSaving(false);
      throw e;
    }
    setSaving(false);
  };

  return { data, loading, saving, error, setMedicalNote, getMedicalNote, getMedicalNoteMedia, generatedFiles };
}
