import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ClinicalTermLabelValue } from '../clinicalTerms/ClinicalTermsSearchHook';

import { ClinicalTermModel } from '~/api/models/clinicalTerms/models/ClinicalTermModel';
import { BaseHealthRecordAnswerModel } from '~/api/models/consultations/models/HealthRecordAnswerModel';
import { HealthRecordTemplateQuestionModel } from '~/api/models/consultations/requests/HealthRecordTemplateQuestionModel';
import {
  apiGetHealthRecordAnswers,
  apiGetHealthRecordTemplateQuestions,
  apiGetHealthRecordTemplateSymptoms,
  apiUpdateHealthRecordAnswers,
  apiUpdateHealthRecordTemplateSymptom,
} from '~/api/services/consultations/healthRecord';
import { useHealthRecordDataContext } from '~/providers/consultation/HealthRecordDataContext';
import { RootState } from '~/redux/reducers';
import { isDoctorVersion } from '~/utils/buildConfig';

interface TemplateProps {
  templateId: number;
}
export function useHealthRecordTemplate({ templateId }: TemplateProps) {
  const [loading, setLoading] = useState(false);
  const [templateQuestions, setTemplateQuestions] = useState<HealthRecordTemplateQuestionModel[]>(null);
  const { setTemplateQuestions: setTemplateQuestionsInForm } = useHealthRecordDataContext();

  const template = useSelector((state: RootState) =>
    state.healthRecordReducer.templates?.find((template) => template.id === templateId)
  );

  const getHealthRecordTemplate = () => {
    setLoading(true);
    return apiGetHealthRecordTemplateQuestions({ id: templateId })
      .then((res) => {
        setTemplateQuestions(res.data);
        setTemplateQuestionsInForm(res.data);
        setLoading(false);
        return res;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (templateId) {
      getHealthRecordTemplate();
    }
  }, [templateId]);

  return { templateQuestions, template, loading, getHealthRecordTemplate };
}

export function useHealthRecordAnswers() {
  const [loading, setLoading] = useState(false);

  const updateTemplateAnswers = (healthRecordId: number, answers: BaseHealthRecordAnswerModel[]) => {
    return apiUpdateHealthRecordAnswers({ healthRecordId, params: { answers } });
  };

  const getTemplateAnswers = (healthRecordId: number) => {
    setLoading(true);
    return apiGetHealthRecordAnswers({ id: healthRecordId }).finally(() => setLoading(false));
  };

  return { updateTemplateAnswers, getTemplateAnswers, loading };
}

export function useHealthRecordTemplateSymptoms() {
  const [loading, setLoading] = useState(false);
  const [symptoms, setSymptoms] = useState<ClinicalTermModel[]>([]);

  const getTemplateSymptoms = (healthRecordId: number) => {
    if (healthRecordId && isDoctorVersion()) {
      setLoading(true);
      return apiGetHealthRecordTemplateSymptoms({ id: healthRecordId })
        .then((res) => {
          setSymptoms(res.data);
          return res;
        })
        .finally(() => setLoading(false));
    }
  };

  const updateTemplateSymptoms = async (params: { healthRecordId: number; terms: ClinicalTermLabelValue[] }) => {
    await apiUpdateHealthRecordTemplateSymptom({
      id: params.healthRecordId,
      params: { term_ids: params.terms.map((item) => item.value) },
    });
    return await getTemplateSymptoms(params.healthRecordId);
  };

  return { symptoms, updateTemplateSymptoms, getTemplateSymptoms, loading };
}
