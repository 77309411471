import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { View } from 'react-native';

import { Button, TextButton } from '../../../../components/commonButton';
import { H3TtmSemiBoldBlack, H6NsSemiBoldRed } from '../../../../components/commonText';
import { ModalContainer } from '../../../../components/modals/ModalContainer';
import { BaseModalProps } from '../../../../components/modals/interfaces/ModalProps';
import { StripePaymentMethodTypeEnum } from '../constants/StripePaymentMethodTypeEnum';

import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { mb10, mt10 } from '~/common/commonStyles';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { stripePromise } from '~/constants/stripe/stripePromise';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { labels } from '~/utils/labels';
import { PaymentIntentOrSetupIntentResult } from '@stripe/stripe-js';

interface StripeSetupFormRef {
  handleSubmit: () => Promise<PaymentIntentOrSetupIntentResult>;
}

interface Props extends BaseModalProps {
  clientSecret: string;
  onComplete: () => void;
  onError: (error?: string | ErrorResponse) => void;
  type: StripePaymentMethodTypeEnum;
  title: string;
}

export const StripePaymentMethodModal: React.FC<Props> = ({
  onHide,
  clientSecret,
  title,
  onComplete,
  onError,
  type,
}) => {
  const ref = useRef<StripeSetupFormRef>(null);
  const [loading, setLoading] = useState(false);
  const { closeModalByName } = useModalManager();
  const [latestError, setLatestError] = useState('');
  const [elementsLoaded, setElementsLoaded] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setLatestError('');
    try {
      const res = await ref.current.handleSubmit();
      if (res.error) {
        if (res.error.type !== 'validation_error') setLatestError(res.error?.message);
        setLoading(false);
        return;
      }
      if (onComplete) onComplete();
      closeModalByName(ModalName.StripeCheckout);
    } catch (e) {
      if (onError) onError(e);
    }
    setLoading(false);
  };

  const handleClose = () => {
    if (onError) onError(new ErrorResponse({ message: 'Canceled' }, 0));
    onHide();
  };

  const buttons = [
    <Button
      key="submit"
      funCallback={handleSubmit}
      label={type === StripePaymentMethodTypeEnum.PAYMENT ? 'PAY' : 'SAVE PAYMENT METHOD'}
      disabled={loading || !elementsLoaded}
    />,
    <TextButton funCallback={handleClose} label={labels.cancel} disabled={loading} key="cancel" />,
  ];

  return (
    <ModalContainer hideOnBackground onHide={handleClose} buttons={buttons}>
      <View>
        <Elements stripe={stripePromise} options={{ clientSecret, appearance: { theme: 'stripe' } }}>
          <H3TtmSemiBoldBlack style={[{ textAlign: 'center' }, mb10]}>{title}</H3TtmSemiBoldBlack>
          <StripeSetupForm ref={ref} type={type} onElementsLoaded={() => setElementsLoaded(true)} />
          {latestError ? <H6NsSemiBoldRed style={mt10}>{latestError}</H6NsSemiBoldRed> : null}
        </Elements>
      </View>
    </ModalContainer>
  );
};

interface FormProps {
  type: StripePaymentMethodTypeEnum;
  onElementsLoaded: () => void;
}
export const StripeSetupForm = forwardRef<StripeSetupFormRef, FormProps>(({ type, onElementsLoaded }, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({
    handleSubmit: async () => {
      if (type === StripePaymentMethodTypeEnum.PAYMENT) {
        return await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
        });
      } else if (type === StripePaymentMethodTypeEnum.SETUP) {
        return await stripe.confirmSetup({
          elements,
          redirect: 'if_required',
        });
      }
    },
  }));

  return (
    <form id="payment-form">
      <PaymentElement
        id="payment-element"
        options={{ layout: 'tabs' }}
        onReady={() => {
          onElementsLoaded();
        }}
      />
    </form>
  );
});
