import { useState } from 'react';

import { DashboardStatsResponse } from '~/api/models/dashboard/responses/DashboardStatsResponse';
import { apiGetPatientDashboardStats } from '~/api/services/dashboard';
import { usePageFocus } from '~/utils/hooks/FocusHook';

export const usePatientDashboardStats = () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<DashboardStatsResponse>([{}, {}]);

  const getStats = () => {
    setLoading(true);
    apiGetPatientDashboardStats()
      .then((res) => setStats(res.data))
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  usePageFocus(() => {
    getStats();
  });

  return { stats, loading };
};
