import { useSelector } from 'react-redux';

import { useCurrentPharmacy } from './PharmacyHook';
import { useFetchLoginDetails } from '../accounts/LoginDetails';

import { PharmacyLinkingInfoModel } from '~/api/models/pharmacy/models/PharmacyLinkingInfoModel';
import { apiApprovePharmacyInvitation, apiHasPharmacyRole, apiPharmacyInvitationValid } from '~/api/services/pharmacy';
import { getParsedAsyncItem, storageKeys } from '~/common/asyncStorage';
import { SuccessAlert } from '~/common/commonMethods';
import { useAuthDeepLinkClear } from '~/navigation/hooks/useAuthDeepLink';
import { UserLoginDetailsModel } from '~/redux/models/userDetails/UserLoginDetailsModel';
import {
  CLEAR_PHARMACY_LINKING_DETAILS,
  SET_PHARMACY_LINKING_DETAILS,
  pharmacyLinkingSelector,
} from '~/redux/reducers/tokenInviteReducer';
import { useAppDispatch } from '~/redux/store';
import { isPharmacyVersion } from '~/utils/buildConfig';

export const usePharmacyStoredLinkingDetails = () => {
  const linkingDetails = useSelector(pharmacyLinkingSelector);

  return { linkingDetails, hasLinkingDetails: !!linkingDetails };
};

export const usePharmacyLinkingAction = () => {
  const { getLoginDetails } = useFetchLoginDetails();
  const { linkingDetails } = usePharmacyStoredLinkingDetails();
  const { removeDeepLink } = useAuthDeepLinkClear();

  const dispatch = useAppDispatch();
  const { updatePharmacies } = useCurrentPharmacy();

  const approve = async (token: string) => {
    await apiApprovePharmacyInvitation({ token });
    await updatePharmacies();
  };

  const validateToken = async (token: string) => {
    return apiPharmacyInvitationValid({ token }).then((res) => {
      if (!res.data.is_valid) {
        throw new Error(
          'We were unable to validate your invitation to the pharmacy.\nYour token either expired after 7 days or the invitation was removed.'
        );
      }
    });
  };

  const handlePharmacistLinkAction = async (token: string, loginDetails?: UserLoginDetailsModel) => {
    if (!isPharmacyVersion() || !token) return;

    if (!loginDetails) loginDetails = await getLoginDetails();
    const isLoggedIn = !!loginDetails?.steppedUp;

    if (isLoggedIn) {
      await validateToken(token).catch((e) => {
        clearStoredToken();
        throw e;
      });

      const hasPharmacyRole = await apiHasPharmacyRole();
      if (hasPharmacyRole.data.is_pharmacist) {
        await approve(token);

        clearStoredToken();

        SuccessAlert(["You've successfully accepted the invitation to join a new pharmacy"], 'Congratulations!');
      } else {
        throw new Error(
          'Something went wrong while we were adding you to the pharmacy.\nPlease try again or contact your Pharmacy administrator if problems persist'
        );
      }
    } else {
      dispatch(SET_PHARMACY_LINKING_DETAILS({ token }));
    }
  };

  const validateLinkingDetails = async (): Promise<PharmacyLinkingInfoModel | undefined> => {
    if (!linkingDetails) return;
    return await validateToken(linkingDetails.token)
      .then(() => linkingDetails)
      .catch(() => {
        clearStoredToken();
        return undefined;
      });
  };

  const clearStoredToken = () => {
    removeDeepLink();
    dispatch(CLEAR_PHARMACY_LINKING_DETAILS());
  };

  return {
    handlePharmacistLinkAction,
    validateLinkingDetails,
  };
};

export const usePharmacyLinkingState = () => {
  const dispatch = useAppDispatch();

  const loadLinkingToken = async () => {
    if (!isPharmacyVersion()) return;
    const res = await getParsedAsyncItem<PharmacyLinkingInfoModel>(storageKeys.pharmacyLink);
    if (!res) return;

    dispatch(SET_PHARMACY_LINKING_DETAILS(res));
  };

  const clearLinkingToken = () => {
    dispatch(CLEAR_PHARMACY_LINKING_DETAILS());
  };

  return { clearLinkingToken, loadLinkingToken };
};
