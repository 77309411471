import React from 'react';

import { OnBoardingContentPlaceholder } from '../onBoardingContentPlaceholder';

import { onboardingLabels } from '~/utils/labels/onboardingLabels';
interface Props {
  width: number;
}
export const OnBoardingPharmacyPage2: React.FC<Props> = ({ width }) => {
  return (
    <OnBoardingContentPlaceholder
      width={width}
      left
      maskUp
      title={onboardingLabels.pharmacy[1].title}
      subtitle={onboardingLabels.pharmacy[1].subTitle}
      image={require('~/../assets/img/onboarding/pharmacy_2.png')}
    />
  );
};
