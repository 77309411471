import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import { ListItemModel } from '../../api/models/common/models/ListItemModel';
import { ConsultationModel } from '../../api/models/consultations/models/ConsultationModel';

interface IState {
  consultationSaving?: boolean;
  consultationDetails?: ConsultationModel;
  dietaryRestrictionsList: ListItemModel[];
  recreationalDrugsList: ListItemModel[];
  alcoholConsumptionsList?: ListItemModel[];
  tobaccoUsesList?: ListItemModel[];
  prosthesisList?: ListItemModel[];
  allergiesList?: ListItemModel[];
  medicalConditionsList?: ListItemModel[];
  medicationsList?: ListItemModel[];
  proceduresList?: ListItemModel[];
  vaccinationsList?: ListItemModel[];
  prescriptionFrequencyList;
  durationIntervalTypeList;
  prescriptionTypeList;
  examinationTypesList: ListItemModel[];
}

const initialState: IState = {
  consultationSaving: false,
  consultationDetails: undefined,
  dietaryRestrictionsList: [],
  recreationalDrugsList: [],
  alcoholConsumptionsList: [],
  tobaccoUsesList: [],
  prosthesisList: [],
  allergiesList: undefined,
  medicalConditionsList: undefined,
  medicationsList: undefined,
  proceduresList: undefined,
  vaccinationsList: undefined,
  prescriptionFrequencyList: [],
  durationIntervalTypeList: [],
  prescriptionTypeList: [],
  examinationTypesList: [],
};

const consultationDetailsSlice = createSlice({
  name: 'consultationDetails',
  initialState,
  reducers: {
    CONSULTATION_DETAILS: (state, action: PayloadAction<ConsultationModel | undefined>) => ({
      ...state,
      consultationDetails: action.payload,
    }),
    UPDATE_DIETARY_RESTRICTIONS_LIST: (state, action: PayloadAction<ListItemModel[]>) => ({
      ...state,
      dietaryRestrictionsList: action.payload,
    }),
    UPDATE_RECREATIONAL_DRUGS_LIST: (state, action: PayloadAction<ListItemModel[]>) => ({
      ...state,
      recreationalDrugsList: action.payload,
    }),
    UPDATE_ALCOHOL_CONSUMPTIONS_LIST: (state, action: PayloadAction<ListItemModel[]>) => ({
      ...state,
      alcoholConsumptionsList: action.payload,
    }),
    UPDATE_TOBACCO_USES_LIST: (state, action: PayloadAction<ListItemModel[]>) => ({
      ...state,
      tobaccoUsesList: action.payload,
    }),
    UPDATE_PROSTHESIS_LIST: (state, action: PayloadAction<ListItemModel[]>) => ({
      ...state,
      prosthesisList: action.payload,
    }),
    UPDATE_ALLERGIES_LIST: (state, action: PayloadAction<ListItemModel[]>) => ({
      ...state,
      allergiesList: action.payload,
    }),
    UPDATE_MEDICAL_CONDITIONS_LIST: (state, action: PayloadAction<ListItemModel[]>) => ({
      ...state,
      medicalConditionsList: action.payload,
    }),
    UPDATE_MEDICATIONS_LIST: (state, action: PayloadAction<ListItemModel[]>) => ({
      ...state,
      medicationsList: action.payload,
    }),
    UPDATE_PROCEDURES_LIST: (state, action: PayloadAction<ListItemModel[]>) => ({
      ...state,
      proceduresList: action.payload,
    }),
    UPDATE_VACCINATIONS_LIST: (state, action: PayloadAction<ListItemModel[]>) => ({
      ...state,
      vaccinationsList: action.payload,
    }),
    UPDATE_EXAMINATION_TYPES_LIST: (state, action: PayloadAction<ListItemModel[]>) => ({
      ...state,
      examinationTypesList: action.payload,
    }),
    SET_CONSULTATION_SAVING: (state, action: PayloadAction<boolean>) => ({
      ...state,
      consultationSaving: action.payload,
    }),
  },
});

export const {
  CONSULTATION_DETAILS,
  UPDATE_DIETARY_RESTRICTIONS_LIST,
  UPDATE_RECREATIONAL_DRUGS_LIST,
  UPDATE_ALCOHOL_CONSUMPTIONS_LIST,
  UPDATE_TOBACCO_USES_LIST,
  UPDATE_PROSTHESIS_LIST,
  UPDATE_ALLERGIES_LIST,
  UPDATE_MEDICAL_CONDITIONS_LIST,
  UPDATE_MEDICATIONS_LIST,
  UPDATE_PROCEDURES_LIST,
  UPDATE_VACCINATIONS_LIST,
  UPDATE_EXAMINATION_TYPES_LIST,
  SET_CONSULTATION_SAVING,
} = consultationDetailsSlice.actions;
export default consultationDetailsSlice.reducer;

export const consultationSavingSelector = (state: RootState) => state.consultationDetailsReducer.consultationSaving;
