import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { DoctorClinicName } from './DoctorClinicName';
import { DoctorFavouriteButton } from './DoctorFavouriteButton';
import { DoctorOnDemandIndicator } from './DoctorOnDemandIndicator';
import { RatingViewComponent } from './RatingViewComponent';
import { DoctorBadgesComponent } from './badges/DoctorBadgesComponent';

import {
  AvailableDoctorModel,
  AvailableDoctorModelWithQueue,
} from '~/api/models/appointments/models/AvailableDoctorModel';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { apiGetDoctor } from '~/api/services/accounts/doctor';
import { flex1, mb10, mb20, mb30, mb5, ml10, mt10 } from '~/common/commonStyles';
import { ElevatedCard } from '~/components/cards/ElevatedCard';
import { Button } from '~/components/commonButton';
import {
  H6NsSemiBoldBlack,
  H6NsSemiBoldLink,
  H3TtmSemiBoldBlack,
  H6NsRegularBlack,
  ExtraSmallNsRegularInfo,
  ExtraSmallNsRegularWarning,
  SmallNsRegularBlack,
  H6NsSemiBoldInfo,
} from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { ModalContainer } from '~/components/modals/ModalContainer';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { VideoCallIcon, AudioCallIcon, ChatIcon } from '~/components/svgImages';
import { CommunicationChannels } from '~/services/config';
import { formatAmount } from '~/utils/amountUtil';
import { idNameArrayToString } from '~/utils/arrayUtil';
import { isClinicVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { getQueueInfo } from '~/utils/consultationUtils';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { getAccountName, getInitials } from '~/utils/personalDetailsUtils';

const ConsultationChannelToIcon = {
  [CommunicationChannels.VideoCall]: <VideoCallIcon height={20} width={20} />,
  [CommunicationChannels.AudioCall]: <AudioCallIcon height={20} width={20} />,
  [CommunicationChannels.Chat]: <ChatIcon height={20} width={20} />,
};
interface Props {
  doctor: AvailableDoctorModelWithQueue;
  consultationType: ConsultationTypeEnum;
  okTitle?: string;
  onContinue?: (
    doctor: AvailableDoctorModelWithQueue,
    consultationType?: ConsultationTypeEnum,
    consultationChannelId?: number
  ) => void;
  onHide?: () => void;
}

export const DoctorDetailModal: React.FC<Props> = ({ doctor, consultationType, okTitle, onHide, onContinue }) => {
  const [selectedChannel, setChannel] = useState<number>();
  const [showDoctor, setShowDoctor] = useState<AvailableDoctorModel>();
  const { isMobile } = useBreakpoints();

  const queueInfoRes =
    consultationType === ConsultationTypeEnum.ON_DEMAND && !!doctor.queue && getQueueInfo(doctor.queue);

  const buttons = consultationType
    ? [
        consultationType !== ConsultationTypeEnum.HOME_VISIT ? (
          <View>
            <H6NsSemiBoldBlack style={mb5}>Select a channel for your consultation</H6NsSemiBoldBlack>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {doctor?.consultation_channels?.map((channel, index, arr) => (
                <View style={flex1} key={channel.id}>
                  <ElevatedCard
                    selected={channel.id === selectedChannel}
                    style={{
                      marginLeft: index > 0 ? 5 : 0,
                      marginRight: index < arr.length - 1 ? 5 : 0,
                    }}
                    cardStyle={{
                      minHeight: 60,
                    }}
                    onPress={() => setChannel(channel.id)}
                    icon={ConsultationChannelToIcon[channel.id]}
                    label={channel.name}
                    center
                  />
                </View>
              ))}
            </View>
          </View>
        ) : null,
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <Button
              label={okTitle || labels.continue}
              disabled={!selectedChannel && consultationType !== ConsultationTypeEnum.HOME_VISIT}
              funCallback={() => {
                if (onContinue) onContinue(doctor, consultationType, selectedChannel);
                onHide();
              }}
            />
          </View>
          <View>
            <DoctorFavouriteButton doctor={doctor} style={ml10} />
          </View>
        </View>,
      ]
    : [
        <Button
          label={okTitle || labels.continue}
          funCallback={() => {
            if (onContinue) onContinue(doctor, undefined, undefined);
            onHide();
          }}
        />,
      ];

  useEffect(() => {
    setShowDoctor(null);
    if (!doctor?.id) return;
    apiGetDoctor({ id: doctor.id })
      .then((res) => {
        setShowDoctor(res.data);
      })
      .catch(() => {});
  }, [doctor?.id]);

  const profileImage = useMemo(() => {
    return showDoctor?.profile_image || doctor?.profile_image;
  }, [doctor, showDoctor]);

  const consultationPrice = useMemo(() => {
    if (!consultationType) return '';
    const price = doctor?.consultation_prices.find((price) => price.type === consultationType);
    if (!price) return 'Price not available';
    return `${formatAmount(price.amount, price.currency)}/consultation`;
  }, [consultationType, doctor?.consultation_prices]);

  return (
    <ModalContainer
      hideOnBackground
      paddedEdge
      onHide={onHide}
      showInfoLine
      buttons={buttons}
      fullScreen={isMobile}
      buttonSizeOverride={175}>
      <View>
        <View style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
          <H3TtmSemiBoldBlack>Profile</H3TtmSemiBoldBlack>
        </View>
        <View style={[styles.profileContainer, mb20]}>
          <View style={{ alignSelf: 'flex-start' }}>
            <ProfileImageComponent
              size={90}
              src={profileImage?.conversions?.avatar ?? profileImage?.url}
              initials={getInitials(doctor)}
            />
            {consultationType === ConsultationTypeEnum.ON_DEMAND ? (
              <DoctorOnDemandIndicator doctor={doctor} backgroundColor={colors.lightPurple} />
            ) : null}
          </View>
          <View style={[flex1, { display: 'flex', justifyContent: 'space-between' }]}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 10,
              }}>
              <View style={{ flex: 1 }}>
                {queueInfoRes?.noQueue ? (
                  <ExtraSmallNsRegularInfo>
                    {doctor.queue ? `No one is currently waiting` : ' '}
                  </ExtraSmallNsRegularInfo>
                ) : (
                  <ExtraSmallNsRegularWarning>{queueInfoRes.description}</ExtraSmallNsRegularWarning>
                )}
                <H6NsSemiBoldBlack style={{ whiteSpace: 'pre-wrap', marginBottom: 5 }}>
                  {getAccountName(doctor)}
                </H6NsSemiBoldBlack>
                <SmallNsRegularBlack>{idNameArrayToString(doctor?.specialisations, 4)}</SmallNsRegularBlack>
                {!isClinicVersion() ? <DoctorClinicName doctor={doctor} /> : null}
              </View>
              <View style={ml10}>
                <RatingViewComponent rating={doctor.rating} />
              </View>
            </View>
            <View style={[ml10]}>
              <H6NsSemiBoldLink>{consultationPrice}</H6NsSemiBoldLink>
            </View>
          </View>
        </View>

        {!showDoctor ? (
          <LoadingActivityIndicator style={{ minHeight: 200 }} />
        ) : (
          <>
            <DoctorBadgesComponent style={mb30} doctor={showDoctor} />

            <View style={mb20}>
              <H6NsSemiBoldBlack style={mb10}>Languages spoken</H6NsSemiBoldBlack>
              <H6NsRegularBlack>{idNameArrayToString(doctor?.languages) || 'No languages listed'}</H6NsRegularBlack>
            </View>
            <View>
              <H6NsSemiBoldBlack style={mb10}>
                About {doctor?.clinic_name ? 'clinic' : 'practitioner'}
              </H6NsSemiBoldBlack>
              {showDoctor?.biography ? <H6NsRegularBlack style={mb10}>{showDoctor?.biography}</H6NsRegularBlack> : null}
              {doctor?.clinic_name ? (
                <>
                  <H6NsSemiBoldInfo>
                    Once the clinic receives your request, you will be assigned a doctor to handle your appointment.
                  </H6NsSemiBoldInfo>
                  <H6NsSemiBoldInfo style={mt10}>
                    Please note that you may need to wait in our virtual queue until a doctor is available in order to
                    take your consultation
                  </H6NsSemiBoldInfo>
                </>
              ) : null}
            </View>
          </>
        )}
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  profileContainer: {
    borderRadius: 6,
    backgroundColor: colors.lightPurple,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
  },
});
