import React, { useContext } from 'react';

import { IHealthProfileItemContext } from '../HealthProfileItemContextTypes';

import { HealthProfileClinicalTermResponse } from '~/api/models/healthProfile/responses/HealthProfileClinicalTermResponse';

export const HealthProfileHistoryItemContext = React.createContext<
  IHealthProfileItemContext<HealthProfileClinicalTermResponse>
>({} as IHealthProfileItemContext<HealthProfileClinicalTermResponse>);

export const useHealthProfileHistoryItemContext = (): IHealthProfileItemContext<HealthProfileClinicalTermResponse> =>
  useContext(HealthProfileHistoryItemContext);
