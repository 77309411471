import React, { useEffect } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useFaqTaxons } from '~/api/hooks/help/TaxonsHook';
import { H6NsRegularBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { ArrowRight } from '~/components/svgImages';
import { colors } from '~/utils/colors';

interface Props {
  searching?: boolean;
  selected?: string;
  onSelect?: (selected: string) => void;
  mobileView?: boolean;
}

export const FaqCategories: React.FC<Props> = ({ searching, selected, mobileView, onSelect }) => {
  const { taxons, loading } = useFaqTaxons();

  useEffect(() => {
    if (!mobileView && taxons?.length && (!selected || !taxons.find((taxon) => taxon.slug === selected))) {
      onSelect(taxons[0].slug);
    }
  }, [taxons]);

  if (loading) return <LoadingActivityIndicator />;

  return (
    <View style={mobileView ? styles.mobileContainer : null}>
      {loading
        ? null
        : taxons?.map((taxon, index) => (
            <View
              key={taxon.slug}
              style={[
                mobileView ? styles.categoryMobile : styles.category,
                index === taxons.length - 1 ? styles.categoryLast : null,
              ]}>
              <TouchableOpacity
                disabled={searching}
                style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}
                onPress={() => {
                  if (searching) return;
                  onSelect(taxon.slug);
                }}>
                <H6NsRegularBlack
                  style={[
                    mobileView ? null : styles.categoryText,
                    selected && selected === taxon.slug ? styles.categoryTextSelected : null,
                    searching ? styles.categoryTextSearching : null,
                  ]}>
                  {taxon.name}
                </H6NsRegularBlack>
                {mobileView ? <ArrowRight width={12} height={12} /> : null}
              </TouchableOpacity>
            </View>
          ))}
    </View>
  );
};

const styles = StyleSheet.create({
  mobileContainer: {
    borderRadius: 6,
    overflow: 'hidden',
    paddingHorizontal: 8,
    backgroundColor: colors.white,
  },
  category: {
    marginBottom: 10,
  },
  categoryMobile: {
    paddingVertical: 12,
    borderBottomColor: colors.lightPurple2,
    borderBottomWidth: 1,
  },
  categoryLast: {
    borderBottomWidth: 0,
  },
  categoryText: {
    color: colors.purple,
  },
  categoryTextSelected: {
    color: colors.info,
  },
  categoryTextSearching: {
    color: colors.lightPurple2,
  },
});
