export class MissingConsultationRequestError extends Error {
  constructor(consultationId: number) {
    super(
      `We were not able to get the information for consultation #${consultationId}. The consultation may have already been handled or has expired.`
    );
  }
}
export class ExpiredConsultationRequestError extends Error {
  constructor(consultationId: number) {
    super(
      `We were not able to get the information for consultation #${consultationId}. The consultation request may have expired.`
    );
  }
}
