import { useEffect, useMemo, useState } from 'react';

import { AddressModel } from '../../models/addresses/models/AddressModel';
import { apiGetAddressIndex } from '../../services/addresses';

import { ErrorAlert } from '~/components/modals/ErrorAlert';

interface Props {
  accountId?: number;
  requireAccount?: boolean;
}

export function useUserAddressListing(props?: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [addresses, setAddresses] = useState<AddressModel[]>([]);

  const getAddresses = async () => {
    if (props?.requireAccount && !props.accountId) return;
    try {
      const response = await apiGetAddressIndex({ page: 1, limit: 10, filter: { account_id: props?.accountId } });
      setAddresses(response.data.data.sort((a, b) => a.id - b.id));
    } catch (e) {
      ErrorAlert(e);
    }
  };

  useEffect(() => {
    if (!props.accountId) return;
    setLoading(true);
    getAddresses().then(() => {
      setLoading(false);
    });
  }, [props.accountId]);

  const formAddresses = useMemo(
    () =>
      addresses.map((address) => ({
        id: address.id,
        country_id: address.country_id,
        post_code: address.post_code,
        line_1: address.line_1,
      })),
    [addresses]
  );
  return { addresses, formAddresses, loading, getAddresses };
}
