import { Route } from '@react-navigation/native';
import React, { useState } from 'react';
import { View } from 'react-native';

import { useConsultationDetails } from '~/api/hooks/consultations/ConsultationDetails';
import { apiSubmitConsultation } from '~/api/services/consultations';
import { InprogressAlert, SuccessAlert } from '~/common/commonMethods';
import { mb10, mt20 } from '~/common/commonStyles';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { H6NsRegularBlack } from '~/components/commonText';
import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { Checkbox } from '~/components/inputs/Checkbox';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { MobileMenuPageComponent } from '~/components/navigation/MobileMenuPageComponent';
import { DOCTOR_CONSULTATION_PAGES, PATIENT_CONSULTATION_PAGES } from '~/constants/patients/ConsultationMobilePages';
import { isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { errorMessages } from '~/utils/labels/errorMessages';
import { patientManagementLabels } from '~/utils/labels/patientManagement';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { id: string; diagnosisId: string; consultationId: string }>;
}

export const ConsultationMobilePage: React.FC<Props> = ({ route }) => {
  const { consultation, getConsultation, isEditing, loading } = useConsultationDetails({
    consultationId: +route.params.consultationId,
  });
  const [waiting, setWaiting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [includeSignature, setIncludeSignature] = useState(false);

  const handleSubmit = async () => {
    try {
      setWaiting(true);
      setIsSubmitted(true);
      if (!includeSignature) {
        throw new Error(errorMessages.signatureRequiredModalMessage);
      }
      InprogressAlert(['Saving your latest changes'], { title: 'Submitting consultation', block: true });

      await apiSubmitConsultation({ id: consultation.id, req: { include_signature: includeSignature } });

      await getConsultation();

      SuccessAlert(['Consultation successfully submitted']);
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const footer =
    !consultation || consultation.submitted_at ? null : (
      <CancelContinueFooter hideCancel confirmTitle="Submit" onConfirm={handleSubmit} waiting={waiting} />
    );
  return (
    <PatientsLayout
      title="Consultation Report"
      hideHeaderRight
      style={{ backgroundColor: colors.white }}
      back={{
        to: {
          screen: screenName.PatientDiagnosis,
          params: { id: route.params.id, diagnosisId: route.params.diagnosisId },
        },
      }}
      flexContent={false}
      footer={footer}>
      <MobileMenuPageComponent
        menu={isDoctorVersion() ? DOCTOR_CONSULTATION_PAGES : PATIENT_CONSULTATION_PAGES}
        params={{ ...route.params, edit: !consultation?.submitted_at } as never}
        spacedOut
      />
      {isEditing && !loading ? (
        <>
          <View style={[mt20, mb10]}>
            <Checkbox
              checked={includeSignature}
              onChange={setIncludeSignature}
              label={patientManagementLabels.includeSignature}
              error={isSubmitted && !includeSignature}
              errorMessage={isSubmitted && !includeSignature ? errorMessages.signatureRequired : null}
              showMandatory
            />
          </View>
          <H6NsRegularBlack>{patientManagementLabels.consultationEnsureDetailsCorrect}</H6NsRegularBlack>
        </>
      ) : null}
    </PatientsLayout>
  );
};
