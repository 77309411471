import { useEffect } from 'react';

import { useHandleAppointmentScheduled } from './handlers/HandleAppointmentScheduled';
import { useHandleAppointmentStarted } from './handlers/HandleAppointmentStarted';
import { useHandleConsultationApproved } from './handlers/HandleConsultationApproved';
import { useHandleConsultationCancelled } from './handlers/HandleConsultationCancelled';
import { useHandleConsultationDoctorChanged } from './handlers/HandleConsultationDoctorChanged';
import { useHandleConsultationEnded } from './handlers/HandleConsultationEnded';
import { useHandleConsultationIgnoredOrRejected } from './handlers/HandleConsultationIgnoredOrRejected';
import { useHandleConsultationRequestSubmitted } from './handlers/HandleConsultationRequestSubmitted';
import { useHandleHomeVisitStarting } from './handlers/HandleHomeVisitStarting';
import { useGeneralConsultationChannel } from '../channels/useGeneralConsultationChannel';

import { ConsultationRescheduleDetailsModel } from '~/api/models/appointments/models/ConsultationRescheduleDetailsModel';
import { ConsultationEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { AppointmentNotificationModal } from '~/components/AppointmentNotificationModal';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { isDoctorVersion, isPatientVersion } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';
import { useHandleDebug } from './handlers/HandleDebug';

export const useAppointmentRequestHandling = () => {
  const { showSnackbar } = useSnackbarManager();
  const { registerModal, openModal } = useModalManager();

  const { handleNotification: handleConsultationIgnoredOrRejected } = useHandleConsultationIgnoredOrRejected();
  const { handleNotification: handleConsultationApproved } = useHandleConsultationApproved();
  const { handleNotification: handleConsultationDoctorChanged } = useHandleConsultationDoctorChanged();
  const { handleNotification: handleConsultationCancelled } = useHandleConsultationCancelled();
  const { handleNotification: handleConsultationEnded } = useHandleConsultationEnded();
  const { handleNotification: handleHomeVisitStarting } = useHandleHomeVisitStarting();
  const { handleNotification: handleRequestSubmitted } = useHandleConsultationRequestSubmitted();
  const { handleNotification: handleAppointmentScheduled } = useHandleAppointmentScheduled();
  const { handleNotification: handleAppointmentStarted } = useHandleAppointmentStarted();
  const { handleNotification: handleDebug } = useHandleDebug();

  useEffect(() => {
    registerModal(ModalName.AppointmentNotification, AppointmentNotificationModal);
  }, []);

  const handleGeneralConsultationEvent = (
    event,
    data: {
      consultation_id?: number;
      consultationRescheduleRequest?: ConsultationRescheduleDetailsModel;
    }
  ) => {
    switch (event) {
      case ConsultationEventsEnum.CONSULTATION_REQUEST_SUBMITTED:
        handleRequestSubmitted(data.consultation_id);
        break;
      case ConsultationEventsEnum.CONSULTATION_ASSIGNED:
      case ConsultationEventsEnum.CONSULTATION_APPOINTMENT_SCHEDULED:
        handleAppointmentScheduled(data.consultation_id);
        break;
      case ConsultationEventsEnum.CONSULTATION_SCHEDULED_APPOINTMENT_STARTING:
        if (isDoctorVersion()) {
          openModal(ModalName.AppointmentNotification, {
            consultationId: data.consultation_id,
          });
        }
        break;
      case ConsultationEventsEnum.CONSULTATION_RESCHEDULE_REQUEST_ACCEPTED:
        showSnackbar(labels.patientApprovedReschedule, { keyRef: `${event}.${data.consultation_id}` });
        break;
      case ConsultationEventsEnum.CONSULTATION_RESCHEDULE_REQUEST_REJECTED:
        showSnackbar(labels.patientRejectedReschedule, { keyRef: `${event}.${data.consultation_id}` });
        break;
      case ConsultationEventsEnum.CONSULTATION_RESCHEDULE_REQUEST:
        if (isPatientVersion() && data.consultationRescheduleRequest?.state === 'pending') {
          openModal(ModalName.PatientAppointmentRescheduleModal, {
            consultationRescheduleRequest: data.consultationRescheduleRequest,
          });
        }
        break;
      case ConsultationEventsEnum.CONSULTATION_PATIENT_REQUEST_IGNORED:
      case ConsultationEventsEnum.CONSULTATION_REQUEST_REJECTED:
        handleConsultationIgnoredOrRejected(data.consultation_id);
        break;
      case ConsultationEventsEnum.CONSULTATION_REQUEST_APPROVED:
        handleConsultationApproved(data.consultation_id);
        break;
      case ConsultationEventsEnum.CONSULTATION_DOCTOR_CHANGED:
        handleConsultationDoctorChanged(data.consultation_id);
        break;
      case ConsultationEventsEnum.CONSULTATION_CANCELLED:
        handleConsultationCancelled(data?.consultation_id);
        break;
      case ConsultationEventsEnum.CONSULTATION_STATE_ENDED:
        handleConsultationEnded(data.consultation_id);
        break;
      case ConsultationEventsEnum.CONSULTATION_HOME_VISIT_APPOINTMENT_STARTING:
        handleHomeVisitStarting(data?.consultation_id);
        break;
      case ConsultationEventsEnum.CONSULTATION_STATE_STARTED:
        handleAppointmentStarted(data?.consultation_id);
        break;
      case ConsultationEventsEnum.DEBUG:
        handleDebug(data);
        break;
    }
  };

  useGeneralConsultationChannel({ eventHandler: handleGeneralConsultationEvent });
};
