import React, { useMemo } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { useStoredRoute } from '~/redux/hooks/StoredRoute';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

interface Props {
  noPadding?: boolean;
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
}
export const EducationCentreLayout: React.FC<Props> = ({ children, style, noPadding }) => {
  const { route } = useStoredRoute();
  const { isMobile } = useBreakpoints();

  const padded = useMemo(() => {
    return !noPadding && isMobile && route?.name !== screenName.Dashboard;
  }, [route, noPadding, isMobile]);
  return <View style={[styles.container, padded ? styles.contentPadding : null, style]}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  contentPadding: {
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
    paddingTop: 20,
  },
});
