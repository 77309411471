import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Menu } from 'react-native-paper';

import { DataTable, DataTableColumns } from '../../common/DataTable/DataTable';
import {
  H6NsRegularBlack,
  H6TtmSemiBoldTheme,
  SmallNsRegularSecondaryBlack,
  SmallNsSemiBoldBlack,
} from '../../commonText';

import { useClinicPastRequestsList } from '~/api/hooks/patientDataHandling/ClinicPastRequestsListHook';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { flex1, ml10, mr10 } from '~/common/commonStyles';
import { STATUS_COLOR_MAPPING } from '~/components/common/appointments/appointmentListing/constants/StatusColorMapping';
import { Button } from '~/components/commonButton';
import { CheckboxGroup } from '~/components/inputs/CheckboxGroup';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { ArrowRight, FilterIcon } from '~/components/svgImages';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { DAY_MONTH_FORMAT, TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

const FilterTypes = [
  {
    label: <H6NsRegularBlack style={ml10}>Request Declined</H6NsRegularBlack>,
    value: ConsultationStateEnum.RequestRejected,
  },
  {
    label: <H6NsRegularBlack style={ml10}>Request Ignored</H6NsRegularBlack>,
    value: ConsultationStateEnum.RequestIgnored,
  },
  {
    label: <H6NsRegularBlack style={ml10}>Cancelled</H6NsRegularBlack>,
    value: ConsultationStateEnum.Cancelled,
  },
  {
    label: <H6NsRegularBlack style={ml10}>Started</H6NsRegularBlack>,
    value: ConsultationStateEnum.Started,
  },
  {
    label: <H6NsRegularBlack style={ml10}>Pending Report</H6NsRegularBlack>,
    value: ConsultationStateEnum.Ended,
  },
  {
    label: <H6NsRegularBlack style={ml10}>Completed</H6NsRegularBlack>,
    value: ConsultationStateEnum.Submitted,
  },
];

export const ClinicPastRequestsTable: React.FC = () => {
  const { requests, loading, pagination, setPagination, updateData, control, resetTable } = useClinicPastRequestsList();
  const { showNewBadge } = useNewBadge({
    timeKey: 'updated_at',
  });
  const { navigate } = useNavigation<NavType>();
  const { isMobile } = useBreakpoints();
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, updateData);
    return removeListener;
  }, []);

  const filter = (
    <Controller
      name="stateFilter"
      control={control}
      render={({ field: { onChange, value } }) => (
        <TouchableOpacity onPress={() => setShowFilters(true)}>
          <Menu
            visible={showFilters}
            onDismiss={() => {
              setShowFilters(false);
            }}
            anchor={
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <H6TtmSemiBoldTheme style={mr10}>State</H6TtmSemiBoldTheme>
                <View style={{ position: 'relative' }}>
                  <FilterIcon width="12" height="12" />
                  {value?.length ? (
                    <View
                      style={{
                        width: 8,
                        height: 8,
                        backgroundColor: colors.danger,
                        position: 'absolute',
                        right: 0,
                        bottom: -4,
                        borderRadius: 10,
                      }}
                    />
                  ) : null}
                </View>
              </View>
            }>
            <View style={[{ width: 250, alignItems: 'flex-start' }]}>
              <SmallNsSemiBoldBlack style={{ marginLeft: 10, marginBottom: 10 }}>State Filter</SmallNsSemiBoldBlack>
              <CheckboxGroup onChange={onChange} values={value ?? []} items={FilterTypes} />

              <View style={{ width: '100%', paddingHorizontal: 10, marginTop: 20 }}>
                <Button
                  label="Apply"
                  funCallback={() => {
                    resetTable();
                    setShowFilters(false);
                  }}
                />
              </View>
            </View>
          </Menu>
        </TouchableOpacity>
      )}
    />
  );

  const columns: DataTableColumns = [
    {
      key: 'patient',
      title: 'Patient',
      columnStyle: { maxWidth: 400 },
      cell: (patient: AccountModel) => <ProfileDetailsComponent account={patient} theme="table" style={flex1} />,
    },
    {
      key: 'doctor',
      title: 'Doctor',
      columnStyle: { maxWidth: 400 },
      cell: (doctor: AccountModel) => <ProfileDetailsComponent account={doctor} theme="table" isDoctor style={flex1} />,
    },
    {
      key: 'updated_at',
      title: 'Last updated',
      columnStyle: { width: 120, maxWidth: 120 },
      cell: (dateTime: string) => (
        <View style={styles.info}>
          <SmallNsSemiBoldBlack>{parseDateTime(dateTime, { outputFormat: DAY_MONTH_FORMAT })}</SmallNsSemiBoldBlack>
          <SmallNsRegularSecondaryBlack>
            {parseDateTime(dateTime, { outputFormat: TIME_FORMAT })}
          </SmallNsRegularSecondaryBlack>
        </View>
      ),
    },
    {
      key: 'state',
      cell: (state: ConsultationStateEnum) => (
        <StatusBadge
          label={STATUS_COLOR_MAPPING[state]?.label}
          color={STATUS_COLOR_MAPPING[state]?.color}
          textStyle={{ textTransform: 'capitalize' }}
        />
      ),
      columnStyle: { maxWidth: 150 },
      contentNumeric: true,
      headerChildren: filter,
      headerStyle: { justifyContent: 'flex-end' },
    },
    {
      key: 'actions',
      title: '',
      columnStyle: { maxWidth: 40, minWidth: 40 },
      cell: () => <ArrowRight width={12} height={12} />,
    },
  ];

  const onRowPress = (_, item: ConsultationModel) => {
    navigate(screenName.ClinicConsultationRequest, { id: item.id });
  };
  return (
    <>
      <DataTable
        tableId="clinicPastAppointments"
        columns={columns}
        totalCount={pagination?.total || 0}
        items={requests}
        emptyNote={labels.noRequests}
        loading={loading}
        infiniteScroll={isMobile ? { loading, estimatedItemSize: { desktop: 60, mobile: 158 } } : null}
        onPageChanged={setPagination}
        onRowPress={onRowPress}
        rowIsNewIdentifier={(item) => showNewBadge({ value: item })}
        page={pagination?.current_page || 1}
        numberOfItemsPerPage={pagination?.per_page || 0}
        dataTableCardStyle={{
          backgroundColor: colors.lightPurple,
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  iconBackground: {
    backgroundColor: colors.white,
    padding: 10,
    borderRadius: 30,
    marginRight: 10,
  },
  patientCell: {
    minWidth: 205,
  },
  patientContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    flexDirection: 'column',
    flex: 1,
  },
  reasonHeader: {
    minWidth: 110,
    padding: 5,
  },
  reasonCell: {
    minWidth: 110,
  },
  typeHeader: {
    padding: 5,

    minWidth: 150,
  },
  typeCell: {
    minWidth: 150,
  },
  consultationContainer: {
    flexDirection: 'column',
  },
  type: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  typeInfo: {
    flexDirection: 'row',
  },
  actionContainer: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  header2: {
    minWidth: 200,
    justifyContent: 'center',
  },
});
