import React, { Component, ErrorInfo, ReactNode, useEffect } from 'react';
import { Image, View } from 'react-native';

import { mt35 } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { H2TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { SadHeart } from '~/components/svgImages';
import { isNative } from '~/utils/buildConfig';
import log from '~/utils/logger';

interface Props {
  children?: ReactNode;
  onErrorRecovery?: () => void;
  label?: string;
  hideButton?: boolean;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log.error('Error:', error, errorInfo);
  }

  removeError = () => {
    console.log('REMOVE ERROR');
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <View
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, paddingHorizontal: 15 }}>
          <SadHeartComponent />
          <H2TtmSemiBoldBlack style={{ fontWeight: 'bold', fontSize: 24, marginBottom: 10 }}>
            Oops, how did you end up here?
          </H2TtmSemiBoldBlack>
          <H6NsRegularBlack>Something went wrong with the navigation</H6NsRegularBlack>
          {this.props.hideButton === true ? null : (
            <View style={mt35}>
              <Navigate
                hasError={this.state.hasError}
                removeError={this.removeError}
                label={this.props.label}
                onErrorRecovery={this.props.onErrorRecovery}
              />
            </View>
          )}
        </View>
      );
    }

    return this.props.children;
  }
}

interface NavigateProps {
  hasError: boolean;
  removeError: () => void;
  label: string;
  onErrorRecovery?: () => void;
}

const Navigate: React.FC<NavigateProps> = ({ hasError, removeError, onErrorRecovery, label }) => {
  useEffect(() => {
    if (hasError === false) {
      onErrorRecovery();
    }
  }, [hasError]);

  return (
    <Button
      label={label}
      style={{ minWidth: 200 }}
      funCallback={() => {
        removeError();
      }}
    />
  );
};

const SadHeartComponent: React.FC = () => {
  if (isNative()) {
    return <Image source={require('~/../assets/img/sadHeart.png')} />;
  }
  return <SadHeart height={40} width={40} />;
};
