import React, { useState } from 'react';

import { isHoverEnabled } from './HoverState';

interface Props {
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  children?: React.ReactNode | ((hovering: boolean) => React.ReactNode);
}
export const Hoverable: React.FC<Props> = ({ onHoverIn, onHoverOut, children }) => {
  const [isHovered, setHovered] = useState(false);
  const [showHover, setShowHover] = useState(false);

  function handleMouseEnter() {
    if (isHoverEnabled() && !isHovered) {
      if (onHoverIn) onHoverIn();
      setHovered(true);
    }
  }

  function handleMouseLeave() {
    if (isHovered) {
      if (onHoverOut) onHoverOut();
      setHovered(false);
    }
  }

  function handleGrant() {
    setShowHover(false);
  }

  function handleRelease() {}

  const child = typeof children === 'function' ? children(showHover && isHovered) : children;

  return React.cloneElement(React.Children.only(child as never), {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    // prevent hover showing while responder
    onResponderGrant: () => setShowHover(false),
    onResponderRelease: () => setShowHover(true),
    // if child is Touchable
    onPressIn: handleGrant,
    onPressOut: handleRelease,
  });
};
