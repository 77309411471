import React from 'react';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { AccountAddressManagementComponent } from '~/components/profile/AccountAddressManagementComponent';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const SettingsClinicAddress: React.FC = () => {
  const { userDetails } = useUserDetails({ allowStale: true });

  return (
    <AccountAddressManagementComponent
      account={userDetails?.clinic_account}
      backLink={{ screen: screenName.ProfileScreen }}
      backTitle={labels.profile}
      title="Clinic Address"
    />
  );
};
