import { useEffect } from 'react';

import { useStoredDoctorIds } from '../doctors/StoredDoctorIdsHook';

import { FavouriteDoctorsListedIndexResponse } from '~/api/models/favouriteDoctors/responses/FavouriteDoctorsIndexResponse';
import { apiGetFavouriteDoctors } from '~/api/services/favouriteDoctors';
import { SET_FAVOURITES_LOADING, SET_FAVOURITES } from '~/redux/reducers/patient/doctorListingReducer';
import { useAppDispatch } from '~/redux/store';

export function useFavouriteDoctors() {
  const dispatch = useAppDispatch();

  const { allDoctorIds } = useStoredDoctorIds();

  const getFavouriteDoctors = async (doctorIds: number[]) => {
    if (!allDoctorIds) return;
    try {
      dispatch(SET_FAVOURITES_LOADING(true));
      const res = await apiGetFavouriteDoctors<FavouriteDoctorsListedIndexResponse>({
        limit: -1,
        filter: { doctor_id: doctorIds },
      });

      dispatch(SET_FAVOURITES(res.data));
    } catch {
      dispatch(SET_FAVOURITES_LOADING(false));
    }
  };

  const updateFavouriteDoctors = () => {
    return getFavouriteDoctors(allDoctorIds);
  };

  return { updateFavouriteDoctors, allDoctorIds };
}

export function useFavouriteDoctorsWithEffect() {
  const { updateFavouriteDoctors, allDoctorIds } = useFavouriteDoctors();

  useEffect(() => {
    if (!allDoctorIds?.length) return;
    updateFavouriteDoctors();
  }, allDoctorIds);

  return { updateFavouriteDoctors };
}
