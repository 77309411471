import { get, post } from '~/api/axios';
import { RescheduleAppointmentRequest } from '~/api/models/appointments/requests/RescheduleAppointmentRequest';
import { IdModel } from '~/api/models/common/models/IdModel';
import { DeclineConsultationRequest } from '~/api/models/consultations/requests/DeclineConsultationRequest';
import { GetOnDemandDoctorsQueueResponse } from '~/api/models/consultations/responses/GetOnDemandDoctorsQueueResponse';

export const apiPatientCancelAppointment = ({ id }: IdModel) => {
  return post(`/patient/consultations/${id}/cancel`);
};

export const apiDoctorCancelAppointment = (req: DeclineConsultationRequest) => {
  return post(`/consultations/${req.id}/cancel`, {
    cancellation_reason_note: req.cancellation_reason_note,
  });
};

export const apiRescheduleAppointment = (req: RescheduleAppointmentRequest) => {
  return post(`/consultations/${req.id}/reschedule-request`, req.params);
};
export const apiDoctorCancelWaitingRoom = () => {
  return post(`/doctor/waiting-room/cancel`);
};
export const apiDoctorWaitingRoomStatus = () => {
  return get<GetOnDemandDoctorsQueueResponse>(`/doctor/waiting-room/status`);
};
