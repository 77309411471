import { Route } from '@react-navigation/native';
import React, { useEffect, useMemo } from 'react';

import { AppointmentPageEnum } from './constants/AppointmentPageEnum';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { AppointmentConsultationView } from '~/components/common/appointments/appointmentListing/components/AppointmentConsultationView';
import { ConsultationLoading } from '~/components/common/consultations/ConsultationLoading';
import { ConsultationNotFound } from '~/components/common/consultations/ConsultationNotFound';
import { PatientCall } from '~/components/common/consultations/call/PatientCall';
import { PatientChat } from '~/components/common/consultations/chat/PatientChat';
import { ConsultationWaitingRoom } from '~/components/common/consultations/waitingRoom/ConsultationWaitingRoom';
import { ChannelTypeEnum } from '~/constants/channelTypeEnum';
import { AppointmentChatProvider } from '~/providers/appointment/AppointmentChatProvider';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { AppointmentProvider } from '~/providers/appointment/AppointmentProvider';

interface Props {
  route: Route<string, { consultationId: string }>;
}

const AppointmentShowPage: React.FC = () => {
  const { consultation, loading, isTimeForConsultation } = useAppointmentContext();

  const getCallType = () => {
    if (consultation.channel?.id === ChannelTypeEnum.Chat) {
      return AppointmentPageEnum.InConsultation_Chat;
    } else {
      return AppointmentPageEnum.InConsultation_Call;
    }
  };

  const pageState = useMemo((): AppointmentPageEnum => {
    if (loading) {
      return AppointmentPageEnum.Loading;
    }
    switch (consultation?.state) {
      case ConsultationStateEnum.PendingPayment:
      case ConsultationStateEnum.Scheduled:
      case ConsultationStateEnum.Started:
        switch (consultation.type) {
          case ConsultationTypeEnum.HOME_VISIT:
            if (consultation.state !== ConsultationStateEnum.Started) {
              return AppointmentPageEnum.WaitingRoom;
            }
            return AppointmentPageEnum.Details;
          case ConsultationTypeEnum.ON_DEMAND:
            if (isTimeForConsultation) {
              return getCallType();
            } else {
              return AppointmentPageEnum.WaitingRoom;
            }
          case ConsultationTypeEnum.SCHEDULED_APPOINTMENT:
            if (isTimeForConsultation) {
              return getCallType();
            } else {
              return AppointmentPageEnum.WaitingRoom;
            }
          default:
            return AppointmentPageEnum.NotFound;
        }

      case ConsultationStateEnum.Ended:
      case ConsultationStateEnum.Submitted:
        return AppointmentPageEnum.Details;
      default:
        if (loading) return AppointmentPageEnum.Loading;
        else return AppointmentPageEnum.NotFound;
    }
  }, [consultation, loading, isTimeForConsultation]);

  const pageComponent = useMemo(() => {
    switch (pageState) {
      case AppointmentPageEnum.Loading:
        return <ConsultationLoading />;
      case AppointmentPageEnum.InConsultation_Call:
        return <PatientCall />;
      case AppointmentPageEnum.InConsultation_Chat:
        return (
          <AppointmentChatProvider consultation={consultation}>
            <PatientChat />
          </AppointmentChatProvider>
        );
      case AppointmentPageEnum.WaitingRoom:
        return <ConsultationWaitingRoom consultation={consultation} />;
      case AppointmentPageEnum.Details:
        return <AppointmentConsultationView consultation={consultation} />;
      case AppointmentPageEnum.NotFound:
        return <ConsultationNotFound />;
    }
  }, [pageState, consultation]);

  return pageComponent;
};

export const PatientAppointmentShowPage: React.FC<Props> = ({ route }) => {
  const consultationId = Number(route.params.consultationId);

  return (
    <AppointmentProvider consultationId={consultationId}>
      <AppointmentShowPage />
    </AppointmentProvider>
  );
};
