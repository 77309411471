import { useNavigation } from '@react-navigation/native';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { useCallback } from 'react';

import { useBreakpoints } from './GridHook';
import { isDoctorVersion } from '../buildConfig';
import { screenName } from '../screenName';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { useDependantsHook } from '~/api/hooks/dependants/DependantsHook';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { NavType } from '~/navigation/types';

export const useNavigationHealthProfile = () => {
  const { navigate } = useNavigation<NavType>();
  const { isMobile } = useBreakpoints();
  const { userDetails } = useUserDetails({ allowStale: true });
  const { dependants } = useDependantsHook({ allowStale: true });

  const patientHealthProfileNavigation = () => {
    if (isMobile) {
      navigate(screenName.HealthProfile);
    } else {
      navigate(screenName.ProfileScreen, {
        screen: screenName.HealthProfile,
      });
    }
  };

  const link = useCallback(
    (patientId: number): To<ReactNavigation.RootParamList> => {
      if (isDoctorVersion()) {
        return {
          screen: screenName.PatientHealthProfile,
          params: {
            id: patientId,
          },
        } as never;
      }
      if (userDetails?.account?.id === patientId) {
        if (isMobile) {
          return { screen: screenName.HealthProfile } as never;
        } else {
          return {
            screen: screenName.ProfileScreen as never,
            params: {
              screen: screenName.HealthProfile,
            } as never,
          };
        }
      } else {
        const dependant = dependants?.find((d) => d.account.id === patientId);
        if (dependant)
          if (isMobile) {
            return {
              screen: screenName.DependantHealthProfile as never,
              params: {
                id: dependant.id,
              } as never,
            };
          } else {
            return {
              screen: screenName.DependantsShow as never,
              params: {
                screen: screenName.DependantHealthProfile,
                id: dependant.id,
              } as never,
            };
          }
      }

      return {} as never;
    },
    [userDetails?.account, isMobile]
  );

  return {
    link,
    navigate: (consultation: ConsultationModel) => {
      const patientId = consultation.patient.id;
      if (isDoctorVersion()) {
        navigate({
          screen: screenName.PatientHealthProfile,
          params: {
            id: patientId,
          },
        });
      } else if (userDetails?.account.id === patientId) {
        patientHealthProfileNavigation();
      } else {
        const dependant = dependants?.find((d) => d.account.id === patientId);
        if (dependant)
          if (isMobile) {
            navigate(screenName.DependantHealthProfile, {
              id: dependant.id,
            });
          } else {
            navigate(screenName.DependantsShow, {
              screen: screenName.DependantHealthProfile,
              id: dependant.id,
            });
          }
      }
    },

    generateUrl: (consultation: ConsultationModel) => {
      const patientId = consultation.patient.id;
      if (isDoctorVersion()) {
        return `patients/${patientId}/health-profile`;
      } else if (userDetails?.account.id === patientId) {
        return '/profile/health-profile';
      } else {
        const dependant = dependants?.find((d) => d.account.id === patientId);
        if (dependant) return `/family/${dependant.id}/health-profile`;
      }
    },
    patientHealthProfileNavigation,
  };
};

export const useNavigationAppointments = () => {
  const navigation = useNavigation<NavType>();

  return {
    navigate: () => {
      if (isDoctorVersion()) {
        navigation.navigate(screenName.CalendarPage, { screen: screenName.Appointments });
      } else {
        navigation.navigate(screenName.Appointments);
      }
    },
  };
};
