import { useNavigation, useRoute } from '@react-navigation/native';
import Constants from 'expo-constants';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Dimensions, LayoutRectangle, StyleSheet, View } from 'react-native';
import { Menu } from 'react-native-paper';

import { CallIconButton } from './CallIconButton';
import { ConnectionStateComponent } from './ConnectionStateComponent';
import { SubscriberConnectionStateComponent } from './SubscriberConnectionStateComponent';
import { useCallSettingsHook } from './hooks/CallSettingsHook';
import { useConnectionStateHook } from './hooks/ConnectionStateHook';
import { ChannelMeansHeaders } from '../ChannelMeansHeaders';

import { useConsultationCall } from '~/api/hooks/consultations/ConsultationCallHook';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { AudioVideoTokenResponse } from '~/api/models/consultations/responses/AudioVideoTokenResponse';
import { apiGetAudioVideoToken } from '~/api/services/consultations/channels';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { flexRow, justifyCenter, mb10, textCenter } from '~/common/commonStyles';
import {
  cameraPermission,
  checkCameraPermission,
  checkMicrophonePermission,
  microphonePermission,
} from '~/common/permissions';
import { IconButton } from '~/components/buttons/IconButton';
import { Button } from '~/components/commonButton';
import { H3TtmSemiBoldBlack, H6NsRegularBlack, H6NsRegularTheme, SmallNsRegularBlack } from '~/components/commonText';
import { webMenuStyles } from '~/components/doctor/notifications/DotMenuCommonStyles';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { CallSettingsModal } from '~/components/modals/CallSettingsModal';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import {
  AudioCallIcon,
  BackArrow,
  EndCall,
  Maximize,
  MicOffIcon,
  MoreIcon,
  Settings,
  SwitchCamera,
  VideoCallIcon,
  VideoOffIcon,
} from '~/components/svgImages';
import { ChannelTypeEnum } from '~/constants/channelTypeEnum';
import { toggleFullScreen } from '~/integrations/fullscreen/UseFullScreen';
import { OTPublisher, OTSession, OTSubscriber } from '~/integrations/openTok/OpenTok';
import { OTConnectionState } from '~/integrations/openTok/types/OTConnectionState';
import { OTSessionErrorCode } from '~/integrations/openTok/types/OTErrors';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { SET_HIDE_MOBILE_MENU } from '~/redux/reducers/overlayReducer';
import { useAppDispatch } from '~/redux/store';
import { isAndroid, isDoctorVersion, isNative, isPatientVersion, isWeb } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { usePageFocusState } from '~/utils/hooks/FocusHook';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { usePreventUnload } from '~/utils/hooks/unload/PreventUnloadHook';
import { ChannelMeansMobile } from '../ChannelMeansMobile';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { CallMenuButton } from './CallMenuButton';

const openTokKey = Constants.expoConfig.extra.openTokKey;

interface OTMixedError {
  code?: string;
  name?: string;
  message: string;
}
interface Props {
  small?: boolean;
  beforeJoin?: () => Promise<void>;
  onJoin?: () => void;
}

export const Call: React.FC<Props> = ({ small, onJoin, beforeJoin }) => {
  const { setFullScreen, consultation, isTimeForConsultation } = useAppointmentContext();
  const { bottom } = useSafeAreaInsets();

  const route = useRoute();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessionInfo, setSessionInfo] = useState<AudioVideoTokenResponse>();
  const [sessionSize, setSessionSize] = useState<LayoutRectangle>();

  const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>();
  const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState<string>();
  const dimensions = Dimensions.get('screen');
  const { isDesktop, isMobile } = useBreakpoints();

  const { canGoBack, goBack } = useNavigation();
  const { registerModal, openModal } = useModalManager();
  const {
    connectionState,
    joinedCall,
    transitionConnectionState,
    setJoinedCall,
    sessionEventHandlers,
    subscriberEventHandlers,
    publisherEventsHandler,
    subscriberConnectionState,
    subscriberVideoState,
  } = useConnectionStateHook();

  const { endConsultation } = useAppointmentContext();

  const {
    disconnect: callSettingsDisconnect,
    audioEnabled,
    videoEnabled,
    cameraPosition,
    toggleCameraPosition,
    setAudioEnabled,
    setVideoEnabled,
  } = useCallSettingsHook();

  const dispatch = useAppDispatch();

  usePreventUnload(joinedCall);
  useConsultationCall(consultation?.id);

  useEffect(() => {
    if (joinedCall && consultation.state !== ConsultationStateEnum.Started) {
      disconnect();
    }
  }, [consultation?.state]);

  usePageFocusState((focused) => {
    if (joinedCall && !focused && (!isMobile || (route.name !== 'consultation' && route.name !== 'details'))) {
      disconnect();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(SET_HIDE_MOBILE_MENU(true));
    }, 500);

    const appointmentEnded = AppEventHandler.addListener(AppEvents.DOCTOR_ENDED_APPOINTMENT, disconnect);
    return () => {
      dispatch(SET_HIDE_MOBILE_MENU(false));
      appointmentEnded();
    };
  }, []);

  const isVideoCall = useMemo(() => consultation.channel.id === ChannelTypeEnum.Video, [consultation]);

  const canJoinCall = useMemo(
    () =>
      consultation?.state === ConsultationStateEnum.Started ||
      (consultation?.state === ConsultationStateEnum.Scheduled && isDoctorVersion() && isTimeForConsultation),
    [consultation, isTimeForConsultation]
  );

  const callFinished = useMemo(
    () =>
      consultation?.state === ConsultationStateEnum.Ended || consultation?.state === ConsultationStateEnum.Submitted,
    [consultation]
  );

  const disconnect = () => {
    callSettingsDisconnect();
    transitionConnectionState(OTConnectionState.Disconnected);
  };

  const endCall = () => {
    if (isDoctorVersion()) {
      const patientName = getAccountName(consultation?.patient);
      endConsultation({
        overrideMessage: [
          `You have hung up from your call with ${patientName}.`,
          'Would you also like to end your consultation?',
        ],
      });
    }
    disconnect();
  };

  const joinCall = async () => {
    try {
      if (!consultation) throw new Error('We could not determine what consultation you want to join');
      setLoading(true);
      const isVideo = consultation.channel.id === ChannelTypeEnum.Video;
      let cameraAccess = isVideo && (await cameraPermission());
      if (isVideo && cameraAccess.status !== 'granted') {
        await checkCameraPermission();
      }

      let micAccess = await microphonePermission();
      if (micAccess.status !== 'granted') {
        micAccess = await checkMicrophonePermission();
      }

      if (micAccess.status !== 'granted') {
        throw new Error('We are unable to join the call, please allow access to you microphone');
      }

      if (beforeJoin) await beforeJoin();
      await getSessionInfo();
      setJoinedCall(true);

      if (onJoin) onJoin();
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const getSessionInfo = async (noToken?: boolean) => {
    setSessionInfo(null);
    const res = await apiGetAudioVideoToken({ id: consultation.id });
    setSessionInfo({ session_id: res.data.session_id, token: noToken ? undefined : res.data.token });
  };

  const shouldShowError = (error: OTMixedError) => {
    if (!error?.code) return true;
    const code = Number(error.code);

    if (isNaN(code)) return true;

    switch (code) {
      case OTSessionErrorCode.OTSessionSuccess:
      case OTSessionErrorCode.OTNullOrInvalidParameter:
      case OTSessionErrorCode.OTNoMessagingServer:
      case OTSessionErrorCode.OTSessionUnableToForceMute:
      case OTSessionErrorCode.OTConnectionDropped:
        return false;
    }
    return true;
  };

  const handleSessionError = (error: OTMixedError) => {
    if (!error) return;
    handleOTError(error);
  };

  const handlePublisherError = (error: OTMixedError) => {
    if (!error) return;
    handleOTError(error);
  };

  const handleSubscriberError = (error: OTMixedError) => {
    if (!error) return;
    handleOTError(error);
  };

  const handleOTError = (error: OTMixedError) => {
    console.error('Issue with OT:', error);
    if (!shouldShowError(error)) {
      return;
    }

    if (isWeb()) {
      ErrorAlert(
        'We had an issue connecting you to your call, please try joining again or refreshing your page',
        'Oops! Something went wrong',
        {
          errorCode: error.code || error.name || error.message,
        }
      );
    } else {
      ErrorAlert(
        'We seem to be having issues with the call, please try joining again or contact us if the problem persists',
        'Oops! Something went wrong',
        {
          errorCode: error.code || error.name || error.message,
        }
      );
    }
  };

  useEffect(() => {
    setFullScreen(isMobile);
  }, [isMobile]);

  const publisherDimensions = useMemo(() => {
    const widthToHeight = dimensions.width / (dimensions.height - 50);

    if (dimensions.width > dimensions.height) {
      return { width: 100, height: 100 / widthToHeight };
    } else {
      return { width: 80, height: 80 / widthToHeight };
    }
  }, [dimensions]);

  const publisherContainer = (child?: React.ReactNode) => {
    const showInScreen = isNative() || small || !isDesktop;
    return (
      <View style={[styles.publisherContainer, showInScreen ? styles.publisherContainerSmall : null]}>
        <View style={[styles.publisher, showInScreen ? publisherDimensions : null]}>{child}</View>
        {!showInScreen ? (
          <View style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', zIndex: 10 }}>
            <ConnectionStateComponent connectionState={connectionState} style={{ backgroundColor: 'white' }} />
          </View>
        ) : null}
      </View>
    );
  };

  const openSettings = () => {
    openModal(ModalName.CallSettings, {
      setSelectedVideoDevice,
      setSelectedAudioInputDevice,
    });
  };

  const publisher = () => {
    return publisherContainer(
      joinedCall ? (
        <OTPublisher
          style={[styles.ot]}
          eventHandlers={{
            ...publisherEventsHandler,
            error: handlePublisherError,
          }}
          properties={{
            publishAudio: audioEnabled && joinedCall,
            publishVideo: isVideoCall && videoEnabled && joinedCall,
            cameraPosition: isVideoCall ? cameraPosition : undefined,
            videoTrack: isVideoCall ? true : undefined,
            videoSource: isVideoCall ? selectedVideoDevice : undefined,
            audioInputSource: selectedAudioInputDevice,
          }}
        />
      ) : null
    );
  };

  const button = ({ title, icon }) => {
    return (
      <View style={flexRow} key={title}>
        <View style={[justifyCenter, { marginRight: 15 }]}>{icon}</View>
        <View>
          <H6NsRegularTheme key={title}>{title}</H6NsRegularTheme>
        </View>
      </View>
    );
  };

  const header = useMemo(
    () =>
      !isMobile ? (
        <ChannelMeansHeaders consultation={consultation} joinedCall={joinedCall} small={small} />
      ) : (
        <View style={styles.mobileBack}>
          {canGoBack() && !joinedCall ? (
            <IconButton
              style={{ backgroundColor: colors.white, paddingVertical: 4, paddingHorizontal: 6 }}
              onPress={goBack}>
              <BackArrow width={15} height={15} color={colors.purple} />
            </IconButton>
          ) : null}
        </View>
      ),
    [isMobile, consultation, joinedCall, small]
  );

  const swipeLeftForDetails =
    isMobile && isDoctorVersion() ? (
      <View style={styles.swipeLeftDetailView}>
        <SmallNsRegularBlack style={{ color: colors.white, textAlign: 'center' }}>
          Swipe left for more details
        </SmallNsRegularBlack>
      </View>
    ) : null;
  return (
    <View
      style={[styles.container, isMobile ? [styles.containerMobile, { paddingBottom: bottom }] : null]}
      nativeID="digimed-call">
      <View style={styles.feeds}>
        {!joinedCall || !sessionInfo?.token || loading ? (
          <>
            <View style={[styles.subscriber, isMobile ? styles.subscriberBorderMobile : styles.subscriberBorder]}>
              <View style={isMobile ? styles.mobileBackContainer : null}>{header}</View>

              <View
                style={[
                  styles.sessionView,
                  !joinedCall ? { flexDirection: 'row', justifyContent: 'center' } : null,
                  !isMobile ? { backgroundColor: colors.lightPurple } : null,
                ]}
                onLayout={(e) => setSessionSize(e.nativeEvent?.layout)}>
                <View style={styles.joinButton}>
                  {loading ? (
                    <LoadingActivityIndicator />
                  ) : canJoinCall ? (
                    <Button label="Join call" funCallback={joinCall} style={{ minWidth: 150 }} />
                  ) : !isTimeForConsultation ? (
                    callFinished ? (
                      <H6NsRegularBlack>Call ended</H6NsRegularBlack>
                    ) : (
                      <View style={[styles.pendingJoinCall, { backgroundColor: colors.transparent }]}>
                        <H3TtmSemiBoldBlack
                          style={[textCenter, mb10, isMobile ? { color: colors.white } : { color: colors.black }]}>
                          Still early to start
                        </H3TtmSemiBoldBlack>
                        <H6NsRegularBlack
                          style={[textCenter, mb10, isMobile ? { color: colors.white } : { color: colors.black }]}>
                          It's still early for the appointment to start.
                        </H6NsRegularBlack>
                      </View>
                    )
                  ) : isPatientVersion() ? (
                    <View style={styles.pendingJoinCall}>
                      <H3TtmSemiBoldBlack style={[textCenter, mb10]}>Waiting for call to start</H3TtmSemiBoldBlack>
                      <H6NsRegularBlack>
                        We are waiting for your physician to start the call. Once the call has started you can join.
                      </H6NsRegularBlack>
                    </View>
                  ) : null}
                </View>
                {swipeLeftForDetails}
              </View>
            </View>
            {isDesktop && !small ? publisherContainer() : null}
          </>
        ) : (
          <OTSession
            apiKey={openTokKey}
            sessionId={sessionInfo?.session_id}
            token={sessionInfo?.token}
            options={
              isAndroid()
                ? {
                    androidOnTop: 'publisher',
                    androidZOrder: 'onTop',
                    useTextureViews: true,
                  }
                : null
            }
            eventHandlers={{
              ...sessionEventHandlers,
              error: handleSessionError,
            }}
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              width: '100%',
              height: '100%',
            }}>
            <View style={[styles.feeds, { width: '100%' }]}>
              <View style={[styles.subscriber, isMobile ? styles.subscriberBorderMobile : styles.subscriberBorder]}>
                {header}
                <View style={[styles.sessionView]} onLayout={(e) => setSessionSize(e.nativeEvent?.layout)}>
                  <OTSubscriber
                    eventHandlers={{
                      ...subscriberEventHandlers,
                      error: handleSubscriberError,
                    }}
                    style={[
                      {
                        position: 'absolute',
                        width: '100%',
                        height: sessionSize?.height ?? 600, //'100%',
                      },
                    ]}
                  />
                  <SubscriberConnectionStateComponent
                    subscriberConnectionState={subscriberConnectionState}
                    subscriberVideoState={subscriberVideoState}
                  />
                </View>

                {isMobile ? (
                  <>
                    <View style={[styles.channelMeansMobileView, { right: publisherDimensions.width + 50 }]}>
                      <ChannelMeansMobile consultation={consultation} joinedCall={joinedCall} />
                    </View>

                    {swipeLeftForDetails}
                  </>
                ) : null}
              </View>
              {publisher()}
            </View>
          </OTSession>
        )}
        {isMobile ? (
          <View style={{ position: 'absolute', left: 10, bottom: 10, width: '100%' }}>
            <ConnectionStateComponent
              connectionState={connectionState}
              style={{ backgroundColor: colors.lightPurple }}
            />
          </View>
        ) : null}
      </View>
      <View
        style={{
          marginRight: isDesktop && !small ? 310 : 0,
          marginTop: 20,
          marginBottom: 20,
          display: 'flex',
          flexDirection: 'row',
        }}>
        <>
          {isVideoCall ? (
            <CallIconButton
              disabled={!joinedCall}
              value={videoEnabled}
              onChange={setVideoEnabled}
              iconOn={(color) => <VideoCallIcon width={30} height={30} color={color} />}
              iconOff={(color) => <VideoOffIcon width={30} height={30} color={color} />}
            />
          ) : null}
          <CallIconButton
            disabled={!joinedCall}
            value={audioEnabled}
            onChange={setAudioEnabled}
            iconOn={(color) => <AudioCallIcon width={30} height={30} color={color} />}
            iconOff={(color) => <MicOffIcon width={30} height={30} color={color} />}
          />
          <CallIconButton
            disabled={!joinedCall}
            value={joinedCall}
            onChange={endCall}
            color={isMobile ? colors.white : colors.danger}
            backgroundColor={isMobile ? colors.danger : colors.white}
            iconOn={(color) => <EndCall width={30} height={30} color={color} />}
            iconOff={(color) => <EndCall width={30} height={30} color={color} />}
          />
          {isVideoCall && isNative() ? (
            <CallIconButton
              disabled={!joinedCall}
              value={cameraPosition === 'front'}
              onChange={toggleCameraPosition}
              backgroundColor={colors.grey}
              iconOn={() => <SwitchCamera width={30} height={30} color={colors.lightGrey} />}
              iconOff={() => <SwitchCamera width={30} height={30} color={colors.lightGrey} />}
            />
          ) : null}

          <CallMenuButton
            onChangeSelectedAudioDevice={setSelectedAudioInputDevice}
            onChangeSelectedVideoDevice={setSelectedVideoDevice}
            disabled={!isTimeForConsultation || callFinished}
          />
        </>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  containerMobile: {
    backgroundColor: colors.black,
  },
  feeds: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  subscriber: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    position: 'relative',
  },
  subscriberBorder: {
    borderWidth: 1,
    borderColor: colors.lightPurple2,
    borderRadius: 10,
  },
  subscriberBorderMobile: {
    borderWidth: 1,
    borderColor: colors.grey,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
  },
  publisherContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 10,
    position: 'relative',
  },
  publisherContainerSmall: {
    position: 'absolute',
    right: 20,
    top: 0,
    bottom: isDoctorVersion() ? 40 : 20,
  },
  publisher: {
    width: 300,
    height: 200,
    backgroundColor: colors.lightPurple,
    borderRadius: 10,
    overflow: 'hidden',
    borderColor: colors.info,
    borderWidth: 2,
    zIndex: 2,
  },
  sessionView: {
    display: 'flex',
    flex: 1,
    alignContent: 'center',
    position: 'relative',
  },
  ot: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    right: 0,
  },
  joinButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  mobileBack: {
    position: 'absolute',
    left: 25,
    right: 25,
    top: 25,
    height: 30,
    display: 'flex',
    flexDirection: 'row',
    zIndex: 2,
  },
  mobileBackContainer: {
    height: 80,
  },
  pendingJoinCall: {
    borderRadius: 10,
    backgroundColor: colors.white,
    padding: 40,
    maxWidth: 480,
    margin: 10,
  },
  channelMeansMobileView: {
    position: 'absolute',
    left: 20,
    bottom: isDoctorVersion() ? 40 : 20,
  },
  swipeLeftDetailView: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 16,
    bottom: 10,
  },
});
