import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useRegistrationSteps } from '../../api/hooks/RegistrationSteps';
import { useLoginDetails } from '../../api/hooks/accounts/LoginDetails';
import { useUserDetails } from '../../api/hooks/accounts/UserDetails';
import { OtpChannelEnum } from '../../api/models/otp/constants/OtpChannelEnum';
import { apiSubmit2FAChallenge } from '../../api/services/authorisation';
import { apiSendOtp } from '../../api/services/otp';
import { SuccessAlert } from '../../common/commonMethods';
import { H6NsRegularSecondaryBlack, H6NsSemiBoldSecondaryBlack } from '../../components/commonText';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import OtpScreenComponent from '../../components/otp/OtpScreenComponent';
import { UPDATE_LOGIN_DETAILS } from '../../redux/reducers/userDetailsReducer';
import { useAppDispatch } from '../../redux/store';
import { requiredValidation, passcodeLengthValidation } from '../../services/validationConfig';
import { labels } from '../../utils/labels';

import { useSiteRole } from '~/api/hooks/roles/UseRoleHook';
import { useNavigation } from '@react-navigation/native';
import { NavType } from '~/navigation/types';
import { screenName } from '~/utils/screenName';

const OtpTwoStepValidationScreen: React.FC = (): JSX.Element => {
  const { userDetails } = useUserDetails();
  const { updateRegistrationStatus } = useRegistrationSteps();
  const loginDetails = useLoginDetails();
  const dispatch = useAppDispatch();
  const { execute } = useSiteRole();
  const [waiting, setWaiting] = useState(false);
  const { navigate } = useNavigation<NavType>();

  const preferredOtpChannel = useMemo(
    () => userDetails?.two_step_verification_type || OtpChannelEnum.SMS,
    [userDetails]
  );

  const { control, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      otp: '',
    },
  });

  const resendOTP = () => {
    setWaiting(true);
    apiSendOtp({ channel: preferredOtpChannel })
      .then(() => {
        SuccessAlert(
          preferredOtpChannel === OtpChannelEnum.SMS
            ? ['An sms was sent to you with a one time passcode']
            : ['An email was sent to you with a one time passcode']
        );
      })
      .catch(ErrorAlert)
      .finally(() => {
        setWaiting(false);
      });
  };

  const twoStepOtpValidation = async (details: { otp: string }) => {
    try {
      setWaiting(true);
      await apiSubmit2FAChallenge({ otp: details.otp, channel: preferredOtpChannel });

      await execute();

      dispatch(UPDATE_LOGIN_DETAILS({ ...loginDetails.details, steppedUp: true }));
      await updateRegistrationStatus();

      //After login, will navigate automatically
    } catch (e) {
      if (e.message?.includes('Unauthenticated') || e.message?.includes('Invalid ability provided')) {
        ErrorAlert(
          'Something went wrong and we were unable to authenticate your account.\n\nSorry for the inconvenience but please try and login again.'
        );
        navigate(screenName.LoginScreen);
      } else {
        ErrorAlert(e);
      }
    }
    setWaiting(false);
  };

  const mobile = userDetails?.account
    ? `+${userDetails?.account?.country_code} ${userDetails?.account?.mobile_number}`
    : '';

  return (
    <Controller
      name="otp"
      control={control}
      render={({ field: { onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
        <OtpScreenComponent
          compressed
          title={labels.twoStepVerification}
          onChange={onChange}
          onPress={handleSubmit(twoStepOtpValidation)}
          onResend={resendOTP}
          hideProgressBar
          errorMessage={isSubmitted && error?.message}
          hideResendButton={preferredOtpChannel === OtpChannelEnum.GOOGLE}
          waiting={waiting}
          subTitle={
            userDetails?.account ? (
              preferredOtpChannel === OtpChannelEnum.SMS ? (
                <>
                  <H6NsRegularSecondaryBlack>
                    {labels.weSentTheOtpTo}
                    <H6NsSemiBoldSecondaryBlack>{mobile}</H6NsSemiBoldSecondaryBlack>
                  </H6NsRegularSecondaryBlack>
                </>
              ) : preferredOtpChannel === OtpChannelEnum.EMAIL ? (
                <>
                  <H6NsRegularSecondaryBlack>
                    {labels.weSentTheOtpToEmail}
                    <H6NsSemiBoldSecondaryBlack>{userDetails.account.email}</H6NsSemiBoldSecondaryBlack>
                  </H6NsRegularSecondaryBlack>
                </>
              ) : (
                <>
                  <H6NsRegularSecondaryBlack>{labels.getOtpFromAuthenticator}</H6NsRegularSecondaryBlack>
                </>
              )
            ) : null
          }
        />
      )}
      rules={{
        minLength: passcodeLengthValidation(6),
        required: requiredValidation('One time passcode'),
      }}
    />
  );
};

//make this component available to the app
export default OtpTwoStepValidationScreen;
