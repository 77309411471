import { IBreakpoints } from '~/constants/grid/types';

export default function getBreakpoint(breakpoints: IBreakpoints, width: number) {
  let breakpointName: keyof IBreakpoints = 'xs';
  let breakpointWidth = 0;

  for (const name in breakpoints) {
    if (name in breakpoints) {
      const value = breakpoints[name];

      if (width >= value && value >= breakpointWidth) {
        breakpointName = name as keyof IBreakpoints;
        breakpointWidth = value;
      }
    }
  }

  return breakpointName;
}
