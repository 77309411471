export const helpLabels = {
  faqTitle: 'FAQs',
  howCanWeHelp: 'How can we help you?',
  selectAnIssue: 'Select an issue',
  ical: {
    google: {
      title: 'Google Calendar',
      content: [
        `Open your Google Calendar in a web browser.`,
        `Look for the option to add a new calendar in the left-hand menu (usually a plus sign next to "Other calendars"). Click the plus sign.`,
        `Select "From URL" or "Add by URL" from the menu.`,
        `Paste the copied Digimed URL into the provided field.`,
        `Click "Add Calendar" or "Add" to integrate your Digimed appointments into Google Calendar.`,
      ],
    },
    apple: {
      title: 'Apple Calendar (iCal)',
      content: [
        `Open the Calendar app on your Apple device.`,
        `Click on "Calendar" in the top left-hand corner of the Calendar window. (This might vary based on your device or version of the Calendar app.)`,
        `Select "Add Subscription Calendar."`,
        `Paste the copied Digimed URL into the field.`,
        `Click "Subscribe."`,
        `Click "OK" or "Subscribe" to sync your Digimed appointments with Apple Calendar.`,
      ],
    },
    microsoft: {
      title: 'Microsoft Outlook Calendar',
      content: [
        `Open Outlook Calendar on your computer.`,
        `Look for "Add Calendar" generally found on the menu bar, indicated by three dots (more commands). `,
        `Click the three dots. (This might vary based on your device or version of the Calendar app.)`,
        `Choose "From Internet" or "Subscribe from Web."`,
        `Paste the Digimed URL into the provided field.`,
        `Click "OK" or "Subscribe" to link Digimed appointments to your Outlook Calendar.`,
      ],
    },
  },
  markNotCollectableInfo: 'Function to be used when in person prescription has already been issued.',
};
