import React, { useEffect, useState } from 'react';

import { ScheduledAppointmentSlot } from '~/api/models/appointments/requests/PatientConsultationRequest';
import { ScheduledSlotsResponse } from '~/api/models/appointments/responses/ScheduledSlotsResponse';
import { apiGetDoctorSlots } from '~/api/services/appointments/availabilities';
import { AvailableSlotsSelection } from '~/components/common/booking/AvailableSlotsSelection';
import { ErrorAlert } from '~/components/modals/ErrorAlert';

interface Props {
  doctorId: number;
  date: string;
  selected?: ScheduledAppointmentSlot;
  onChange: (value: ScheduledAppointmentSlot) => void;
  errorMessage?: string;
}

export const ScheduledSlotSelectionComponent: React.FC<Props> = ({
  doctorId,
  date,
  selected,
  onChange,
  errorMessage,
}) => {
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState<ScheduledSlotsResponse>();

  useEffect(() => {
    if (date && doctorId) {
      setLoading(true);
      apiGetDoctorSlots({ doctorId, params: { from: date, to: date } })
        .then((res) => setSlots(res.data))
        .catch(ErrorAlert)
        .finally(() => setLoading(false));
    }
  }, [date]);

  return (
    <AvailableSlotsSelection
      loading={loading}
      errorMessage={errorMessage}
      onChange={(value) => {
        onChange({ end_at: value.to, start_at: value.from });
      }}
      slots={slots}
      selected={{ from: selected?.start_at, to: selected?.end_at }}
    />
  );
};
