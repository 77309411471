import { NavigationContainer, NavigationState } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { BackHandler } from 'react-native';
import { useSelector } from 'react-redux';

import { useLinking } from './hooks/useLinking';
import { MainNavigation } from './mainNavigation';
import { navigationRef, rootNavigate } from './rootNavigate';
import { ModalAlertTypeEnum } from '../components/modals/ModalEnums';
import { SuccessOrErrorPopup } from '../components/modals/SuccessOrErrorPopup';
import { LoadingScreen } from '../pages/loading/Loading';
import { UPDATE_ROUTE } from '../redux/reducers/routeReducer';

import { systemLogout } from '~/api/axios';
import { useCheckAppVersion } from '~/api/hooks/CheckAppVersion';
import { useGeoLocation } from '~/api/hooks/GeoLocationHook';
import { useRegistrationStepsScreenGuard } from '~/api/hooks/RegistrationSteps';
import { useLoginDetails } from '~/api/hooks/accounts/LoginDetails';
import { usePharmacyLinkingState } from '~/api/hooks/pharmacy/PharmacyLinkingAction';
import { useFeatureAccessPerUser } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { ConfirmationAlert } from '~/common/commonMethods';
import { jailBreakCheck } from '~/integrations/jailBreak';
import ErrorBoundary from '~/pages/ErrorBoundary/ErrorBoundary';
import { ModalManagementProvider } from '~/providers/modal/ModalManagementProvider';
import { SnackbarManagementProvider } from '~/providers/snackbar/SnackbarManagementProvider';
import { RootState } from '~/redux/reducers';
import { useAppDispatch } from '~/redux/store';
import { isClinicVersion, isNative } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';
import { consultationsLabels } from '~/utils/labels/consultations';
import { screenName } from '~/utils/screenName';
import { windowTitle } from '~/utils/windowTitle';

const RootNavigation: React.FC = () => {
  useCheckAppVersion();
  useFeatureAccessPerUser();
  const { loadLinkingToken } = usePharmacyLinkingState();

  const currentRoute = useSelector((state: RootState) => state.routeReducer.currentRoute);
  const userDetails = useSelector((state: RootState) => state.userDetailsReducer.userDetails);
  const dispatch = useAppDispatch();
  const registrationGuard = useRegistrationStepsScreenGuard();
  const loginDetails = useLoginDetails();

  const [deviceRooted, setDeviceRooted] = useState<boolean>(false);

  const { permissionRequired, acceptPermissionRequired } = useGeoLocation();

  const getCurrentRoute = (state: NavigationState | Required<NavigationState['routes'][0]>['state']) => {
    if (state.index === undefined || state.index < 0) {
      return undefined;
    }

    const nestedState = state.routes[state.index].state;
    if (nestedState) {
      return getCurrentRoute(nestedState);
    }
    return state.routes[state.index];
  };

  const onNavigationStateChanged = (state: NavigationState | undefined) => {
    const route = state ? getCurrentRoute(state) : undefined;
    dispatch(UPDATE_ROUTE(route));
  };

  useEffect(() => {
    if (isNative() && !__DEV__) {
      setDeviceRooted(jailBreakCheck());
    }

    loadLinkingToken();
  }, []);

  useEffect(() => {
    const loggedIn = !!loginDetails.details?.steppedUp;
    if (isClinicVersion()) {
      if (loggedIn && !registrationGuard.completed) {
        rootNavigate(screenName.RegisterScreen);
      }
    } else {
      if (loggedIn && !registrationGuard.completed && registrationGuard.currentScreen) {
        rootNavigate(registrationGuard.currentScreen.name as never, registrationGuard.currentScreen.params as never);
      }
    }
  }, [registrationGuard.completed, registrationGuard.currentScreen, loginDetails.details?.steppedUp]);

  useEffect(() => {
    if (currentRoute?.name !== 'Login' && currentRoute?.name !== 'Register' && permissionRequired && userDetails) {
      ConfirmationAlert(consultationsLabels.outsideEU.message, {
        title: consultationsLabels.outsideEU.title,
        type: ModalAlertTypeEnum.WARNING,
        okFunction: () => {
          acceptPermissionRequired();
        },
        okTitle: labels.okay,
        cancelFunction: systemLogout,
        cancelTitle: labels.logout,
      });
    }
  }, [currentRoute, permissionRequired, userDetails]);

  const { linking, loading } = useLinking();

  return (
    <ErrorBoundary hideButton>
      {deviceRooted ? (
        <SuccessOrErrorPopup
          popupFlag
          popupMessage={['']}
          popupType={ModalAlertTypeEnum.ERROR}
          popupTitle="Device is Rooted or Jail Broken"
          popupButtonText="Exit"
          popupBlockButtons={false}
          popupOptionalFields={{
            popupHideOnBackground: false,
            popupFunction: () => {
              BackHandler.exitApp();
            },
          }}
          onHide={() => {}}
        />
      ) : loading ? (
        <LoadingScreen />
      ) : (
        <NavigationContainer
          ref={navigationRef}
          linking={linking}
          fallback={<LoadingScreen />}
          documentTitle={{
            formatter: (options, route) => {
              return windowTitle[route?.name] ? `${windowTitle[route.name]} | Digimed` : 'Digimed';
            },
          }}
          onStateChange={onNavigationStateChanged}>
          <ModalManagementProvider>
            <SnackbarManagementProvider>
              <MainNavigation />
            </SnackbarManagementProvider>
          </ModalManagementProvider>
        </NavigationContainer>
      )}
    </ErrorBoundary>
  );
};

export default RootNavigation;
