export const dependantsLabels = {
  dependantsTitle: 'Dependants',
  dependantsSubtitle:
    'Below is a list of dependents. You can book appointments for someone in the list below. Only the admin can add dependants.',
  addToFamily: 'Add people to your family',
  addToFamilySubtitle: [
    'You may add a dependant to your Digimed account.',
    'A dependant may be a child, a person with special needs, or an elderly person, for whom you are authorised to act on behalf of.',
  ],
  addDependant: 'Add a family member',
  addDependantTitle: 'What are their personal details?',
  addDependantSubtitle: 'Please provide the details below',
  relationshipToHolder: 'Your Relationship with this Dependent',
  authenticateRelationship: 'Authenticate relationship',
  authenticateRelationshipSubtitle: 'Click on the learn more button below for the list of documents required.',
  sameAddress: 'Use same address as account holder',
  learnMore: {
    title: 'Relationship documents',
    subtitle: 'Required documents to upload according to the relationship with the dependent.',
    typeA: 'A. Parent / Legal Guardian of the Dependent\n',
    childUnder16: 'If Child is Under 16',
    childOver16: 'If Child is Over 16',
    typeAChildUnder16: [
      'Confirmation that you are the parent / legal guardian of this child.',
      'A copy of the dependent’s ID Card if available, or Birth Certificate (or similar proof of relationship) if no ID Card has been issued yet.',
    ],
    typeAChildOver16: [
      'Signed & Dated declaration from the dependent confirming that you may administer their Digimed account.',
      'A photo of the dependent holding the signed declaration.',
      'A copy of the dependent’s ID card.',
    ],
    typeB: 'B. Legal Guardian / Representative of a person with a disability\n',
    typeBDocs: [
      'Confirmation that you are the legal guardian / representative of this dependent.',
      'A copy of the dependent’s ID card.',
      'A copy of an official document proving evidence of Guardianship / Power of Attorney.',
    ],
    typeC: 'C. Spouse / Partner of the Dependent\n',
    dependantAbleToSign: 'If the Dependent is able to sign documents',
    dependantNotAbleToSign: 'If the Dependent is unable to sign documents',
    typeCAbleToSign: [
      'Signed & Dated declaration from the dependent confirming that you may administer their Digimed account.',
      'A photo of the dependent holding the signed declaration.',
      'A copy of the dependent’s ID card.',
    ],
    typeCNotAbleToSign: [
      'Confirmation of your relationship with the dependent, that you are entrusted by them to administer their Digimed account, and confirming that they are unable to sign documents.',
      'A copy of the dependent’s ID card.',
      'A copy of an official document proving evidence of Guardianship / Power of Attorney.',
    ],
    typeD: 'D. Child of the Dependent\n',
    typeDAbleToSign: [
      'Signed & Dated declaration from the dependent confirming that you may administer their Digimed account.',
      'A photo of the dependent holding the signed declaration.',
      'A copy of the dependent’s ID card.',
    ],
    typeDNotAbleToSign: [
      'Confirmation of your relationship with the dependent, that you are entrusted by them to administer their Digimed account, and confirming that they are unable to sign documents.',
      'A copy of the dependent’s ID card.',
      'A copy of an official document proving evidence of Guardianship / Power of Attorney.',
    ],
    typeE: 'E. Other Relative of the Dependent\n',
    typeEAbleToSign: [
      'Signed & Dated declaration from the dependent confirming that you may administer their Digimed account.',
      'A photo of the dependent holding the signed declaration.',
      'A copy of the dependent’s ID card.',
    ],
    typeENotAbleToSign: [
      'Confirmation of your relationship with the dependent, that you are entrusted by them to administer their Digimed account, and confirming that they are unable to sign documents.',
      'A copy of the dependent’s ID card.',
      'A copy of an official document proving evidence of Guardianship / Power of Attorney.',
    ],
    typeF: 'F. Unrelated Representative of the Dependent (Carer, Nurse, Friend, Employer)\n',
    typeFDocs: [
      'Signed & Dated declaration from the dependent confirming that you may administer their Digimed account.',
      'A photo of the dependent holding the signed declaration.',
      'A copy of the dependent’s ID card.',
    ],
  },
  pendingExistsTitle: 'Profile already exists',
  pendingExistsMessage:
    'This profile is already linked to another account. Kindly add your proof of relationship so that we can process your request.',
  pendingAwaitingApprovalTitle: 'Awaiting approval',
  pendingAwaitingApprovalMessage:
    'Our team is currently reviewing your account. Please allow 2-3 working days for us to review your account. You will receive a notification once this has been approved.',
  failedExistsTitle: 'Profile account already exists',
  failedExistsMessage:
    'This profile is already linked to a patient account. The account cannot be added as a dependant family member.',
  successAddedDependantTitle: 'Dependant has been added to your account',
  relationshipDocument: 'Relationship document',
  returnDependants: 'Return To Dependants',
  addNewFamilyMember: 'Add a new family member',
};
