import { useNavigation } from '@react-navigation/native';
import { formatDistance } from 'date-fns';
import React, { useEffect } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { DataTable, DataTableColumns } from '../../common/DataTable/DataTable';
import { H5TtmRegularBlack, H5TtmSemiBoldBlack, SmallNsRegularSecondaryBlack } from '../../commonText';

import { useClinicRequestsList } from '~/api/hooks/patientDataHandling/ClinicRequestsListHook';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { flex1, mt10 } from '~/common/commonStyles';
import { ColouredStatusBadge } from '~/components/misc/ColouredStatusBadge';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { ArrowRight, Home, Stethoscope } from '~/components/svgImages';
import { ClinicConsultationAssignBadgeStatus } from '~/constants/clinic/ClinicStatus';
import { DeviceSizeDefaults } from '~/constants/grid/defaults';
import { NavType } from '~/navigation/types';
import { ConsultationTypeName } from '~/services/config';
import { colors } from '~/utils/colors';
import {
  DAY_MONTH_FORMAT,
  LARAVEL_DATE_TIME_SHORT,
  TIME_FORMAT,
  displayTimeRange,
  parseDateTime,
  stringToDate,
} from '~/utils/dateAndTime';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useNewBadge } from '~/utils/hooks/NewBadgeHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const ClinicRequestsTable: React.FC = () => {
  const { requests, loading, getRequests } = useClinicRequestsList();
  const { showNewBadge } = useNewBadge({
    timeKey: 'updated_at',
    extraPredicate: (item) => item.state === ConsultationStateEnum.RequestSubmitted,
  });
  const { navigate } = useNavigation<NavType>();

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.DOCTOR_APPOINTMENTS_CHANGED, getRequests);
    return removeListener;
  }, []);

  usePageFocus(() => {
    getRequests();
  }, []);

  const columns: DataTableColumns = [
    {
      key: 'patient profile',
      title: 'Patient',
      columnStyle: { maxWidth: 400 },
      cell: (_, itemData: ConsultationModel) => (
        <ProfileDetailsComponent account={itemData.patient} theme="table" style={flex1} />
      ),
    },
    {
      key: 'reason',
      title: 'Reason',
      columnStyle: { maxWidth: 400 },
      cell: (_, itemData: ConsultationModel) => (
        <SmallNsRegularSecondaryBlack>{itemData.reason}</SmallNsRegularSecondaryBlack>
      ),
    },
    {
      key: 'date',
      title: 'Requested on',
      columnStyle: { maxWidth: 400 },
      selector: (itemData: ConsultationModel) =>
        itemData?.start_at
          ? `${
              parseDateTime(itemData?.start_at || itemData?.updated_at, {
                outputFormat: DAY_MONTH_FORMAT,
              }) ?? ''
            } ${displayTimeRange(
              parseDateTime(itemData.start_at || itemData?.updated_at, { outputFormat: TIME_FORMAT }),
              parseDateTime(itemData.end_at, { outputFormat: TIME_FORMAT })
            )}`
          : itemData.updated_at
          ? formatDistance(stringToDate(itemData.updated_at, { parseFormat: LARAVEL_DATE_TIME_SHORT }), new Date()) +
            ' ago'
          : '',
      cell: (cell) => (
        <View style={styles.info}>
          <SmallNsRegularSecondaryBlack>{cell}</SmallNsRegularSecondaryBlack>
        </View>
      ),
    },
    {
      key: 'state',
      cell: (state: ConsultationStateEnum) => <ColouredStatusBadge {...ClinicConsultationAssignBadgeStatus[state]} />,
      columnStyle: { maxWidth: 150 },
      contentNumeric: true,
    },
    {
      key: 'actions',
      title: '',
      columnStyle: { maxWidth: 40 },
      cell: () => <ArrowRight width={12} height={12} />,
    },
  ];

  const onRowPress = (_, item: ConsultationModel) => {
    navigate(screenName.ClinicConsultationRequest, { id: item.id });
  };
  return (
    <>
      <DataTable
        tableId="approvalRequests"
        columns={columns}
        totalCount={requests.length || 0}
        items={requests}
        emptyNote={labels.noRequests}
        loading={loading}
        infiniteScroll={{ loading }}
        onPageChanged={() => null}
        onRowPress={onRowPress}
        rowIsNewIdentifier={(item) => showNewBadge({ value: item })}
        dataTableCardStyle={{
          backgroundColor: colors.lightPurple,
        }}
        mobileBreakpoint={DeviceSizeDefaults.tablet}
        mobileRenderItem={(item: ConsultationModel, content) => {
          return (
            <View
              style={[
                {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                },
              ]}>
              <View style={{ ...styles.iconBackground }}>
                {item.type === ConsultationTypeEnum.HOME_VISIT ? (
                  <Home color={colors.info} height={20} width={20} />
                ) : (
                  <Stethoscope color={colors.info} height={20} width={20} />
                )}
              </View>
              <View style={{ flex: 1 }}>
                <View>
                  <Text style={{ flexWrap: 'wrap' }}>
                    <H5TtmSemiBoldBlack>{`${item.patient.first_name}  ${item.patient.last_name}`}</H5TtmSemiBoldBlack>
                    <H5TtmRegularBlack>
                      {item.type === ConsultationTypeEnum.HOME_VISIT
                        ? ` has requested a `
                        : ` has requested an appointment for `}
                    </H5TtmRegularBlack>
                    <H5TtmSemiBoldBlack>{`${ConsultationTypeName[item?.type]}`}</H5TtmSemiBoldBlack>
                  </Text>
                </View>
                {content?.date?.cellData ? (
                  <SmallNsRegularSecondaryBlack style={mt10}>{content.date.cellData}</SmallNsRegularSecondaryBlack>
                ) : null}
              </View>
              <View>
                <TouchableOpacity
                  onPress={() => {
                    onRowPress(undefined, item);
                  }}
                  key={item.id}>
                  <ArrowRight height={16} width={12} color={colors.purple} />
                </TouchableOpacity>
              </View>
            </View>
          );
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  iconBackground: {
    backgroundColor: colors.white,
    padding: 10,
    borderRadius: 30,
    marginRight: 10,
  },
  patientCell: {
    minWidth: 205,
  },
  patientContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    flexDirection: 'column',
    flex: 1,
  },
  reasonHeader: {
    minWidth: 110,
    padding: 5,
  },
  reasonCell: {
    minWidth: 110,
  },
  typeHeader: {
    padding: 5,

    minWidth: 150,
  },
  typeCell: {
    minWidth: 150,
  },
  consultationContainer: {
    flexDirection: 'column',
  },
  type: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  typeInfo: {
    flexDirection: 'row',
  },
  actionContainer: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  header2: {
    minWidth: 200,
    justifyContent: 'center',
  },
});
