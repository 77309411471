import { Link } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import { NotificationIcon } from '~/components/svgImages';
import { RootState } from '~/redux/reducers';
import { whenAppType } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { screenName } from '~/utils/screenName';

export const NotificationBell: React.FC = () => {
  const hasUnreadNotifications = useSelector((state: RootState) =>
    whenAppType({
      whenDoctor: state.userDetailsReducer.hasUnreadNotifications || !!state.requestsReducer.requests?.length,
      whenPatient: state.userDetailsReducer.hasUnreadNotifications,
      whenPharmacist: !!state.pharmacyPrescriptionsReducer.prescriptionRequests?.data.length,
    })
  );

  return (
    <Link to={{ screen: screenName.Requests, params: {} } as never}>
      <View style={{ position: 'relative' }}>
        <NotificationIcon height={26} width={22} />
        {hasUnreadNotifications ? <View style={styles.redDot} /> : null}
      </View>
    </Link>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingVertical: 25,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.lightPurple,
    minHeight: 50,
  },
  redDot: {
    position: 'absolute',
    backgroundColor: colors.danger,
    width: 10,
    height: 10,
    borderRadius: 10,
    right: 0,
    top: -2,
  },
});
