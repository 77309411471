import Feather from '@expo/vector-icons/Feather';
import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { View } from 'react-native';

import { ScheduledSlotDateSelectionComponent } from '../components/ScheduledSlotDateSelectionComponent';
import { ScheduledSlotSelectionComponent } from '../components/ScheduledSlotSelectionComponent';

import { mt10, mt20, wizardStyle } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { H6NsRegularBlack } from '~/components/commonText';
import { Splitter } from '~/components/misc/Splitter';
import { WizardLayout } from '~/layouts/WizardLayout';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { requiredValidation } from '~/services/validationConfig';
import { NUM_DATE_FORMAT } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';

export const ScheduledSlotStep: React.FC = () => {
  const { loading, formControl, validateRequest } = useAppointmentBookingContext();

  const { available_time_date, doctor_id } = useWatch({ control: formControl });
  const buttons = [<Button style={mt10} label={labels.next} funCallback={validateRequest} disabled={loading} />];

  return (
    <View style={wizardStyle.container}>
      <WizardLayout title={appointmentLabels.chooseDateTime} buttons={buttons}>
        <View style={{ width: '100%' }}>
          <Controller
            control={formControl}
            name="available_time_date"
            render={({ field: { value, onChange } }) => (
              <ScheduledSlotDateSelectionComponent
                doctorId={doctor_id}
                onChange={onChange}
                value={value}
                minDate={new Date()}
                dateFormat={NUM_DATE_FORMAT}
              />
            )}
          />

          <Splitter />

          <H6NsRegularBlack>
            <Feather name="clock" size={14} />
            {' ' + appointmentLabels.availableSlots}
          </H6NsRegularBlack>
          <Controller
            control={formControl}
            name="scheduled"
            render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
              <View style={mt20}>
                <ScheduledSlotSelectionComponent
                  date={available_time_date}
                  doctorId={doctor_id}
                  selected={value}
                  onChange={onChange}
                  errorMessage={isSubmitted && error?.message}
                />
              </View>
            )}
            rules={{
              required: requiredValidation('Time slot'),
            }}
          />
        </View>
      </WizardLayout>
    </View>
  );
};
