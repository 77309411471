import { deleteReq, get, post, put } from '~/api/axios';
import { IdModel } from '~/api/models/common/models/IdModel';
import { PrescriptionsPharmacyIndexRequest } from '~/api/models/consultations/requests/PrescriptionsIndexRequest';
import { PrescriptionResponse } from '~/api/models/consultations/responses/PrescriptionResponse';
import { PrescriptionIndexResponse } from '~/api/models/consultations/responses/PrescriptionsIndexResponse';
import { PrescriptionRequestModel } from '~/api/models/pharmacy/models/PrescriptionRequestModel';
import { AcceptPrescriptionRequestRequest } from '~/api/models/pharmacy/requests/AcceptPrescriptionRequestRequest';
import { CreatePharmacyRequest } from '~/api/models/pharmacy/requests/CreatePharmacyRequest';
import { CreatePrescriptionRequestRequest } from '~/api/models/pharmacy/requests/CreatePrescriptionRequestRequest';
import { ExportPrescriptionRequest } from '~/api/models/pharmacy/requests/ExportPrescriptionRequest';
import { GetPharmacyUsersRequest } from '~/api/models/pharmacy/requests/GetPharmacyUsersRequest';
import { InvitePharmacyUserRequest } from '~/api/models/pharmacy/requests/InvitePharmacyUserRequest';
import { PharmacyDispenseHistoryIndexRequest } from '~/api/models/pharmacy/requests/PharmacyDispenseHistoryIndexRequest';
import { PrescriptionCollectRequest } from '~/api/models/pharmacy/requests/PrescriptionCollectRequest';
import { PrescriptionDispenseHistoryIndexRequest } from '~/api/models/pharmacy/requests/PrescriptionDispenseHistoryIndexRequest';
import { PrescriptionDispenseRequest } from '~/api/models/pharmacy/requests/PrescriptionDispenseRequest';
import { PrescriptionsRequestsIndexRequest } from '~/api/models/pharmacy/requests/PrescriptionsRequestsIndexRequest';
import { RejectPrescriptionRequestRequest } from '~/api/models/pharmacy/requests/RejectPrescriptionRequestRequest';
import { UpdatePharmacyRequest } from '~/api/models/pharmacy/requests/UpdatePharmacyRequest';
import { UploadPharmacyImageRequest } from '~/api/models/pharmacy/requests/UploadPharmacyImageRequest';
import { PharmaciesMeResponse } from '~/api/models/pharmacy/responses/PharmaciesMeResponse';
import { PharmacyDispenseHistoryIndexResponse } from '~/api/models/pharmacy/responses/PharmacyDispenseHistoryIndexResponse';
import { PharmacyIndexResponse } from '~/api/models/pharmacy/responses/PharmacyIndexResponse';
import { PharmacyResponse } from '~/api/models/pharmacy/responses/PharmacyResponse';
import { PrescriptionDispenseHistoryIndexResponse } from '~/api/models/pharmacy/responses/PrescriptionDispenseHistoryIndexResponse';
import { PrescriptionRequestsPaginatedIndexResponse } from '~/api/models/pharmacy/responses/PrescriptionRequestsIndexResponse';
import fileSystem from '~/integrations/fileSystem';

export const apiMePharmacies = () => {
  return get<PharmaciesMeResponse>('me/pharmacies', { limit: -1 });
};
export const apiCreatePharmacy = (req: CreatePharmacyRequest) => {
  return post<PharmacyResponse>('pharmacies', req);
};
export const apiPatientListPharmacies = () => {
  return get<PharmacyIndexResponse>('pharmacies');
};

export const apiUpdatePharmacy = (req: UpdatePharmacyRequest) => {
  return put<PharmacyResponse>(`pharmacies/${req.id}`, req.params);
};

export const apiUpdatePharmacyImage = (req: UploadPharmacyImageRequest) => {
  return fileSystem.postDocumentResult(`/pharmacies/${req.id}/logo`, 'logo', req.document);
};

export function apiGetPharmacyUsers<T = any>(req: GetPharmacyUsersRequest) {
  return get<T>('pharmacy-invitations', req);
}

export function apiInvitePharmacyUser(req: InvitePharmacyUserRequest) {
  return post('pharmacy-invitations', req);
}

export function apiDeletePharmacyInvitation(req: IdModel) {
  return deleteReq(`pharmacy-invitations/${req.id}`);
}

export function apiDeletePharmacyMember(req: { user_id: number; pharmacy_id: number }) {
  return deleteReq(`pharmacy-members`, req);
}

export function apiApprovePharmacyInvitation(req: { token: string }) {
  return post(`pharmacy-invitations/${req.token}/accept`);
}

export function apiPharmacyInvitationValid(req: { token: string }) {
  return get<{ is_valid: boolean }>(`pharmacy-invitations/${req.token}/is-valid`);
}

export function apiHasPharmacyRole() {
  return get<{ is_pharmacist: boolean }>('me/has-pharmacist-account');
}

export function apiGetPrescriptionRequests<T = PrescriptionRequestsPaginatedIndexResponse>(
  req: PrescriptionsRequestsIndexRequest
) {
  return get<T>('prescription-requests', req);
}

export function apiGetPrescriptionRequest(req: { prescriptionRequestId: number }) {
  return get<PrescriptionRequestModel>(`prescription-requests/${req.prescriptionRequestId}`);
}

export function apiCreatePrescriptionRequest(req: CreatePrescriptionRequestRequest) {
  return post('prescription-requests', req);
}

export const apiManualCollectPrescription = (req: PrescriptionCollectRequest) => {
  return post(`prescriptions/${req.prescriptionId}/collect`, req.params);
};

export const apiAcceptPrescriptionRequest = (req: AcceptPrescriptionRequestRequest) => {
  return post(`prescription-requests/${req.requestId}/approve`, req.params);
};

export const apiRejectPrescriptionRequest = (req: RejectPrescriptionRequestRequest) => {
  return post(`prescription-requests/${req.requestId}/reject`, req.params);
};

export const apiGetDispenseHistory = (req: PharmacyDispenseHistoryIndexRequest) => {
  return get<PharmacyDispenseHistoryIndexResponse>(`pharmacies/${req.pharmacyId}/dispense-history`, req);
};

export const apiGetPrescriptionDispenseHistory = (req: PrescriptionDispenseHistoryIndexRequest) => {
  return get<PrescriptionDispenseHistoryIndexResponse>(
    `pharmacy/prescription/${req.req.prescriptionId}/dispense-history`,
    req.params
  );
};

export const apiDispensePrescription = (req: PrescriptionDispenseRequest) => {
  return post(`prescriptions/${req.prescriptionId}/dispense`, req.params);
};

export const apiShowPrescription = ({ reference }: { reference: string }) => {
  return get<PrescriptionResponse>(`pharmacy/prescriptions/${reference}`);
};

export const apiSearchPrescription = (req: PrescriptionsPharmacyIndexRequest) => {
  return get<PrescriptionIndexResponse>('pharmacy/prescriptions', req);
};

export const apiExportDispenseHistory = (req: ExportPrescriptionRequest) => {
  return post(`/pharmacies/${req.id}/dispense-history/export`);
};
export const apiExportRequestHistory = (req: ExportPrescriptionRequest) => {
  return post(`/pharmacies/${req.id}/request-history/export`);
};
