import { get } from '~/api/axios';
import { AvailableDoctorsHomeVisitRequest } from '~/api/models/appointments/requests/AvailableDoctorsHomeVisitRequest';
import { AvailableDoctorsRequest } from '~/api/models/appointments/requests/AvailableDoctorsRequest';
import { ScheduledSlotsRequest } from '~/api/models/appointments/requests/ScheduledSlotsRequest';
import { AvailableDoctorsResponse } from '~/api/models/appointments/responses/AvailableDoctorsResponse';
import { PatientHomeVisitSlotsResponse } from '~/api/models/appointments/responses/PatientHomeVisitSlotsResponse';
import {
  ScheduledSlotDaysResponse,
  ScheduledSlotsResponse,
} from '~/api/models/appointments/responses/ScheduledSlotsResponse';
import { IdModel } from '~/api/models/common/models/IdModel';

export const apiGetDoctorSlots = (req: ScheduledSlotsRequest) => {
  return get<ScheduledSlotsResponse>(`patient/available-doctors/${req.doctorId}/scheduled-appointment`, req.params);
};

export const apiGetDoctorSlotDays = (req: ScheduledSlotsRequest) => {
  return get<ScheduledSlotDaysResponse>(
    `patient/available-doctors/${req.doctorId}/scheduled-appointment/dates`,
    req.params
  );
};

export const apiGetAvailableDoctorsHomeVisit = (req: AvailableDoctorsHomeVisitRequest) => {
  return get<AvailableDoctorsResponse>('available-doctors/home-visit', req);
};

export const apiGetAvailableDoctorsOnDemand = (req: AvailableDoctorsRequest) => {
  return get<AvailableDoctorsResponse>('available-doctors/on-demand', req);
};

export const apiGetAvailableDoctorsScheduled = (req: AvailableDoctorsRequest) => {
  return get<AvailableDoctorsResponse>('available-doctors/scheduled-appointment', req);
};

export const apiGetPatientAvailabilities = (req: IdModel) => {
  return get<PatientHomeVisitSlotsResponse>(`consultations/${req.id}/patient-availability`);
};
