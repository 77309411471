import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { StyleProp, View, ViewStyle } from 'react-native';

import { RowView } from '../commonViews';
import FloatingInput from '../floatingInputBox';

import { flex1 } from '~/common/commonStyles';
import {
  requiredValidation,
  validationSchema,
  minLengthValidation,
  emailValidation,
} from '~/services/validationConfig';
import { labels } from '~/utils/labels';

interface Props {
  control: Control<{ email?: string; [x: string]: any }, any>;
  style?: StyleProp<ViewStyle>;
  onSubmit?: () => void;
}

export const EmailInputComponent: React.FC<Props> = ({ control, style, onSubmit }) => {
  return (
    <RowView style={style}>
      <View style={[flex1]}>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, onBlur, value }, formState: { isSubmitted }, fieldState: { error } }) => (
            <FloatingInput
              showMandatory
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              keyboardType="email"
              label={labels.email}
              onSubmitEditing={onSubmit}
            />
          )}
          rules={{
            validate: { emailValidation },
            required: requiredValidation(labels.email),
            minLength: minLengthValidation(validationSchema.email.minLength),
          }}
        />
      </View>
    </RowView>
  );
};
