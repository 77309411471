import {
  addYears,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
  subWeeks,
} from 'date-fns';

import { DateRangeOptions } from '~/components/patient/insurance/constants/DateRangeOptionsEnum';

interface Range {
  startDate: Date;
  endDate: Date;
}
interface DataRangeOptionsMappingProps {
  range: Range;
  label: string;
}

export const DataRangeOptionsMapping: Record<DateRangeOptions, DataRangeOptionsMappingProps> = {
  [DateRangeOptions.TODAY]: {
    range: { startDate: startOfDay(new Date()), endDate: endOfDay(new Date()) },
    label: 'Today',
  },
  [DateRangeOptions.TODATE]: {
    range: { startDate: null, endDate: new Date() },
    label: 'To Date',
  },
  [DateRangeOptions.THISWEEK]: {
    range: { startDate: startOfWeek(new Date()), endDate: endOfWeek(new Date()) },
    label: 'This week',
  },
  [DateRangeOptions.LASTWEEK]: {
    range: { startDate: startOfWeek(subWeeks(new Date(), 1)), endDate: endOfWeek(subWeeks(new Date(), 1)) },
    label: 'Last week',
  },

  [DateRangeOptions.LASTMONTH]: {
    range: { startDate: startOfMonth(subMonths(new Date(), 1)), endDate: endOfMonth(subMonths(new Date(), 1)) },
    label: 'Last month',
  },

  [DateRangeOptions.THISMONTH]: {
    range: { startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()) },
    label: 'This month',
  },

  [DateRangeOptions.THISYEAR]: {
    range: { startDate: startOfYear(new Date()), endDate: endOfYear(new Date()) },
    label: 'This year',
  },
  [DateRangeOptions.LASTYEAR]: {
    range: { startDate: startOfYear(addYears(new Date(), -1)), endDate: endOfYear(addYears(new Date(), -1)) },
    label: 'Last year',
  },

  [DateRangeOptions.CUSTOM]: {
    range: { startDate: null, endDate: null },
    label: 'Custom',
  },
};

export const DateRangeOptionItems = [
  {
    label: DataRangeOptionsMapping[DateRangeOptions.TODAY].label,
    value: DateRangeOptions.TODAY,
  },
  {
    label: DataRangeOptionsMapping[DateRangeOptions.TODATE].label,
    value: DateRangeOptions.TODATE,
  },
  {
    label: DataRangeOptionsMapping[DateRangeOptions.THISWEEK].label,
    value: DateRangeOptions.THISWEEK,
  },

  {
    label: DataRangeOptionsMapping[DateRangeOptions.LASTWEEK].label,
    value: DateRangeOptions.LASTWEEK,
  },
  {
    label: DataRangeOptionsMapping[DateRangeOptions.LASTMONTH].label,
    value: DateRangeOptions.LASTMONTH,
  },

  {
    label: DataRangeOptionsMapping[DateRangeOptions.THISMONTH].label,
    value: DateRangeOptions.THISMONTH,
  },
  {
    label: DataRangeOptionsMapping[DateRangeOptions.LASTYEAR].label,
    value: DateRangeOptions.LASTYEAR,
  },

  {
    label: DataRangeOptionsMapping[DateRangeOptions.THISYEAR].label,
    value: DateRangeOptions.THISYEAR,
  },
  {
    label: DataRangeOptionsMapping[DateRangeOptions.CUSTOM].label,
    value: DateRangeOptions.CUSTOM,
  },
];
