import React from 'react';

export function withIAPContext<T>(Component: React.ComponentType<T>) {
  return function WrapperComponent(props: T) {
    return <Component {...props} />;
  };
}

export const useIAP = () => {
  return {};
};

export const setup = (_?: any) => {};
