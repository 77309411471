import { Image } from 'expo-image';
import React, { useMemo } from 'react';
import { Dimensions, ImageSourcePropType, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Path, Svg } from 'react-native-svg';

import { AnimatedCircle } from './AnimatedCircle';
import { H2TtmSemiBoldBlack, black, h6, nsRegular } from '../commonText';

import { txtCenter, mb20 } from '~/common/commonStyles';
import { colors } from '~/utils/colors';

interface Props {
  width: number;
  centre?: boolean;
  left?: boolean;
  right?: boolean;
  maskUp?: boolean;
  maskDown?: boolean;
  noMask?: boolean;

  image?: ImageSourcePropType;
  title: string;
  subtitle: string;
}

const screenDimensions = Dimensions.get('screen');

export const OnBoardingContentPlaceholder: React.FC<Props> = ({
  width,
  centre,
  left,
  right,
  maskUp,
  maskDown,
  noMask,
  title,
  subtitle,
  image,
}) => {
  const isMaskUp = !!maskUp || (!maskDown && !noMask);
  const isLeft = !!left || !right;
  const { bottom } = useSafeAreaInsets();

  const height = screenDimensions.height;

  const maximumDimension = useMemo(() => {
    const percentageHeight = height * (isMaskUp ? 0.15 : 0.75);
    return Math.max(percentageHeight, width);
  }, [isMaskUp, height, width]);

  const imageSize = useMemo(
    () => Math.min(width, height * (centre ? 0.55 : isMaskUp ? 0.45 : noMask ? 0.5 : 0.4)),
    [isMaskUp, centre, height, width]
  );
  const textContent = (
    <View
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom,
        height: '35%',
        paddingHorizontal: 20,
        paddingBottom: 80,
      }}>
      <H2TtmSemiBoldBlack style={[txtCenter, mb20]}>{title}</H2TtmSemiBoldBlack>
      <Text adjustsFontSizeToFit style={[txtCenter, nsRegular, black, h6]} numberOfLines={5}>
        {subtitle}
      </Text>
    </View>
  );

  const imageContent = image ? (
    <Image
      source={image}
      style={{
        position: 'absolute',
        left: width * 0.1,
        right: width * 0.1,
        top: width * 0.1,
        bottom: width * 0.1,
        width: width * 0.8,
        height: width * 0.8,
      }}
      contentFit="contain"
    />
  ) : null;

  return (
    <View style={{ position: 'relative', width, height: '100%', overflow: 'hidden' }}>
      <View
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          top: '58%',
          backgroundColor: colors.lightPurple,
        }}
      />
      {isMaskUp ? (
        <View style={{ backgroundColor: colors.lightPurple, width: '100%', height: '100%' }}>
          <View
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: '45%',
              overflow: 'hidden',
            }}>
            <View
              style={{
                backgroundColor: colors.lightPurple2,
                width: centre ? width : width * 2,
                height: maximumDimension * 2,
                borderRadius: maximumDimension * 2,
                position: 'absolute',
                bottom: 0,
                left: centre ? 0 : isLeft ? -width : undefined,
                right: centre ? undefined : !isLeft ? -width : undefined,
                overflow: 'hidden',
              }}>
              <View
                style={{
                  position: 'absolute',
                  left: centre ? 0 : isLeft ? undefined : 0,
                  right: centre ? undefined : !isLeft ? undefined : 0,
                  bottom: 20,
                  width,
                  height: imageSize,
                }}>
                {centre ? null : (
                  <>
                    <AnimatedCircle
                      size={width * 0.5}
                      diameter={width * 0.5}
                      color={!isLeft ? colors.lightTurquoise : colors.info}
                      position={{
                        right: 0,
                        top: !isLeft ? width * 0.2 : 0,
                      }}
                      disabled
                    />
                    <AnimatedCircle
                      size={width * 0.5}
                      diameter={width * 0.5}
                      color={isLeft ? colors.lightTurquoise : colors.info}
                      position={{
                        left: 0,
                        top: isLeft ? width * 0.2 : 0,
                      }}
                      disabled
                    />
                  </>
                )}
                {imageContent}
              </View>
            </View>
          </View>

          {textContent}
        </View>
      ) : (
        <View style={{ backgroundColor: colors.transparent, width: '100%', height: '100%' }}>
          <View
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              height: imageSize,
            }}>
            {imageContent}
          </View>
          {noMask ? (
            textContent
          ) : (
            <>
              <View style={{ position: 'absolute', left: 0, right: 0, height: maximumDimension * 0.3, bottom: '42%' }}>
                <Svg
                  viewBox="0 0 500 500"
                  preserveAspectRatio="none"
                  style={{ position: 'absolute', width: '100%', height: '100%', left: 0, right: 0 }}>
                  <Path
                    fill={colors.lightPurple}
                    d="M 500 500 C 445.296 283.304 -0.86 0.084 -0.86 0.084 L 0 500 L 500 500 Z"
                  />
                </Svg>
              </View>
              {textContent}
            </>
          )}
        </View>
      )}
    </View>
  );
};
