import React from 'react';

import { OnBoardingContentPlaceholder } from '../onBoardingContentPlaceholder';

import { onboardingLabels } from '~/utils/labels/onboardingLabels';
interface Props {
  width: number;
}
export const OnBoardingPharmacyPage4: React.FC<Props> = ({ width }) => {
  return (
    <OnBoardingContentPlaceholder
      width={width}
      noMask
      title={onboardingLabels.pharmacy[3].title}
      subtitle={onboardingLabels.pharmacy[3].subTitle}
      image={require('~/../assets/img/onboarding/money.png')}
    />
  );
};
