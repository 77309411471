import { useEffect } from 'react';
import { Platform } from 'react-native';
import { deepLinkToSubscriptions, finishTransaction } from 'react-native-iap';

import { getProductId } from './SubscriptionListingHook';
import { useUserDetails } from '../accounts/UserDetails';

import { SubscriptionIntervalEnum } from '~/api/models/subscriptions/constants/SubscriptionIntervalEnum';
import { SubscriptionPriceModel } from '~/api/models/subscriptions/models/SubscriptionPriceModel';
import { SubscriptionPlanMeResponse } from '~/api/models/subscriptions/responses/SubscriptionPlanMeResponse';
import { apiResumeSubscription, apiSubscriptionSetup, apiSubscriptionUpgrade } from '~/api/services/subscriptions';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { NotImplementedError } from '~/classes/errors/NotImplemented';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { useIAP } from '~/integrations/inAppPurchases';
import { StripePaymentMethodRef } from '~/integrations/stripe/interface';
import { isWeb } from '~/utils/buildConfig';

interface Props {
  stripeRef: StripePaymentMethodRef;
}
export const useSubscriptionManagement = ({ stripeRef }: Props) => {
  useSubscriptionLoading();

  const { userDetails } = useUserDetails({ allowStale: true });
  const { requestSubscription, subscriptions } = useIAP();

  const handleErrorMessage = (error: ErrorResponse) => {
    Platform.select({
      web: () => {
        if (error.message === 'Canceled') {
          return;
        }
        ErrorAlert(error);
      },
      ios: () => {
        return ErrorAlert(error);
      },
      android: () => {
        ErrorAlert(error);
      },
    })();
  };

  const getOfferToken = (productId: string) => {
    const subscription = subscriptions.find((sub) => sub.productId === productId);
    if (subscription?.platform === 'android') {
      return subscription.subscriptionOfferDetails.find((offer) => offer.basePlanId && !offer.offerId).offerToken;
    }
  };

  const purchasePlan = async (planPrice: SubscriptionPriceModel) => {
    try {
      await Platform.select({
        native: async () => {
          const offerToken = getOfferToken(planPrice.product_id);

          const res = await requestSubscription({
            sku: planPrice.product_id,
            appAccountToken: userDetails.uuid,
            obfuscatedAccountIdAndroid: userDetails.uuid,
            subscriptionOffers: [
              {
                sku: planPrice.product_id,
                offerToken,
              },
            ],
          });

          if (!res) return;

          const purchase = Array.isArray(res) ? res[0] : res;

          const finishRes = await finishTransaction({
            purchase,
            isConsumable: false,
            developerPayloadAndroid: userDetails.uuid,
          });
          console.log('finishTransaction', finishRes);

          if (res && Platform.OS === 'ios' && 'appAccountToken' in res) {
            if (
              res.appAccountToken?.toLowerCase() !== userDetails.uuid.toLowerCase() &&
              res.transactionReasonIOS === 'RENEWAL'
            ) {
              throw new Error('App store account is already subscribed with a different Digimed account');
            }
          }
        },
        web: async () => {
          const res = await apiSubscriptionSetup({
            stripe_price_id: planPrice.stripe_price_id,
          });
          await stripeRef.initializePaymentSheet({ clientSecret: res.data.client_secret });
        },
      })();
    } catch (e) {
      handleErrorMessage(e);
      throw e;
    }
  };

  const upgradePlan = async (planPrice: SubscriptionPriceModel, subscriptionId: string) => {
    try {
      await Platform.select({
        native: async () => {
          const offerToken = getOfferToken(planPrice.product_id);
          const res = await requestSubscription({
            sku: planPrice.product_id,
            appAccountToken: userDetails.uuid,
            obfuscatedProfileIdAndroid: userDetails.uuid,
            subscriptionOffers: [
              {
                sku: planPrice.product_id,
                offerToken,
              },
            ],
          });
          console.log('Ret', res);
          if (!res) return;

          const purchase = Array.isArray(res) ? res[0] : res;

          const finishRes = await finishTransaction({
            purchase,
            isConsumable: false,
            developerPayloadAndroid: userDetails.uuid,
          });

          console.log('Finish', finishRes);

          if (res && Platform.OS === 'ios' && 'appAccountToken' in res) {
            if (
              res.appAccountToken?.toLowerCase() !== userDetails.uuid.toLowerCase() &&
              res.transactionReasonIOS === 'RENEWAL'
            ) {
              throw new Error('App store account is already subscribed with a different Digimed account');
            }
          }
        },
        web: async () => {
          await apiSubscriptionUpgrade(subscriptionId, {
            stripe_price_id: planPrice.stripe_price_id,
          });
          // SuccessAlert(['You have successfully upgraded your Digimed subscription'], '', '', onSuccess);
        },
      })();
    } catch (e) {
      console.log('Subscription upgrade error', e.statusCode);
      handleErrorMessage(e);
      throw e;
    }
  };

  const resumeSubscription = async (planPrice: SubscriptionPriceModel, subscriptionId: string) => {
    return Platform.select({
      native: () => purchasePlan(planPrice),
      web: async () => {
        try {
          await apiResumeSubscription(subscriptionId);
          // SuccessAlert(['Your subscription has been resumed'], '', '', onSuccess);
        } catch (e) {
          handleErrorMessage(e);
          throw e;
        }
      },
    })();
  };

  const manageSubscription = (currentPlan?: SubscriptionPlanMeResponse) => {
    Platform.select({
      native: () => deepLinkToSubscriptions({ sku: currentPlan?.product_id }),
      web: () => {
        throw new NotImplementedError();
      },
    })();
  };

  return { purchasePlan, upgradePlan, resumeSubscription, manageSubscription, subscriptions };
};

export const useSubscriptionLoading = () => {
  const { getSubscriptions, connected } = useIAP();
  useEffect(() => {
    if (isWeb() || !connected) return;

    getSubscriptions({
      skus: [getProductId(SubscriptionIntervalEnum.MONTH), getProductId(SubscriptionIntervalEnum.YEAR)],
    });
  }, [getSubscriptions, connected]);
};
