import { deleteReq, get } from '../../axios';
import { AddSickLeaveCertificateRequest } from '../../models/consultations/requests/AddSickLeaveCertificateRequest';
import { DeleteSickLeaveCertificateRequest } from '../../models/consultations/requests/DeleteSickLeaveCertificateRequest';
import { GetSickLeaveCertificatesRequest } from '../../models/consultations/requests/GetSickLeaveCertificatesRequest';
import { SickLeaveCertificatesIndexResponse } from '../../models/consultations/responses/SickLeaveCertificatesIndexResponse';

import { GetSickLeaveCertificatesMediaRequest } from '~/api/models/consultations/requests/GetSickLeaveCertificateMediaRequest';
import { GetSickLeaveCertificatesMediaResponse } from '~/api/models/consultations/responses/GetSickLeaveCertificatesMediaResponse';
import fileSystem from '~/integrations/fileSystem';

export const apiGetSickLeaveCertificates = (params: GetSickLeaveCertificatesRequest) => {
  return get<SickLeaveCertificatesIndexResponse>('/sick-leave-certificates', params);
};

export const apiGetSickLeaveCertificateMedia = (req: GetSickLeaveCertificatesMediaRequest) => {
  return get<GetSickLeaveCertificatesMediaResponse>(`/sick-leave-certificates/${req.id}/media/download`);
};

export const apiDeleteSickLeaveCertificate = (params: DeleteSickLeaveCertificateRequest) => {
  return deleteReq(`/sick-leave-certificates/${params.id}`);
};

export const apiAddSickLeaveCertificate = (params: AddSickLeaveCertificateRequest) => {
  return fileSystem.postDocumentResult('/sick-leave-certificates', 'file', params.document, {
    type: params.type,
    consultation_id: params.consultationId.toString(),
  });
};
