export enum PrescriptionTypeEnum {
  ONETIME = 'onetime',
  ONGOING = 'ongoing',
}

export const PrescriptionTypeLabelMapping = {
  [PrescriptionTypeEnum.ONETIME]: 'One-time',
  [PrescriptionTypeEnum.ONGOING]: 'Ongoing',
};

export const PrescriptionTypeMapping = [
  { id: PrescriptionTypeEnum.ONETIME, name: PrescriptionTypeLabelMapping[PrescriptionTypeEnum.ONETIME] },
  { id: PrescriptionTypeEnum.ONGOING, name: PrescriptionTypeLabelMapping[PrescriptionTypeEnum.ONGOING] },
];
