import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { HealthProfileHistoryItemContext } from './HealthProfileHistoryItemContext';
import { useHealthProfileDataContext } from '../HealthProfileDataContext';

import { apiGetAllergiesForProfile } from '~/api/services/healthProfile/allergies';
import { RootState } from '~/redux/reducers';
import { SET_ALLERGIES_PROFILE_LIST } from '~/redux/reducers/healthProfileReducer';
import { useAppDispatch } from '~/redux/store';

interface Props {
  children: React.ReactNode;
}
export const HealthProfileAllergiesProvider: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { healthProfile, accountId } = useHealthProfileDataContext();
  const dispatch = useAppDispatch();

  const getData = async () => {
    if (!healthProfile?.id) return;
    try {
      setLoading(true);
      const res = await apiGetAllergiesForProfile({ id: healthProfile.id });
      dispatch(SET_ALLERGIES_PROFILE_LIST(res.data));
      setLoading(false);
      return res.data;
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  return (
    <HealthProfileHistoryItemContext.Provider
      value={{
        accountId,
        healthProfileId: healthProfile?.id,
        data: [],
        getData,
        loading,
        hasData: useSelector((state: RootState) => !!state.healthProfileReducer.allergiesProfileList),
      }}>
      {children}
    </HealthProfileHistoryItemContext.Provider>
  );
};
