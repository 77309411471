import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import { AppVersionModel } from '../models/AppVersionsModel';

interface IState {
  latestVersions: AppVersionModel[];
  minorUpdateModalShown: boolean;
  onboardingComplete: boolean;
  deepLinkUrl?: string;
}

const initialState: IState = {
  latestVersions: undefined,
  minorUpdateModalShown: false,
  onboardingComplete: undefined,
};

const appDetailsSlice = createSlice({
  name: 'appDetails',
  initialState,
  reducers: {
    LATEST_APP_VERSIONS: (state, action: PayloadAction<AppVersionModel[]>) => ({
      ...state,
      latestVersions: action.payload,
    }),
    MINOR_UPDATE_MODAL_SHOWN: (state, action: PayloadAction<boolean>) => ({
      ...state,
      minorUpdateModalShown: action.payload,
    }),
    SET_ONBOARDING_COMPLETE: (state, action: PayloadAction<boolean>) => ({
      ...state,
      onboardingComplete: action.payload,
    }),
    SET_DEEP_LINK: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      deepLinkUrl: action.payload,
    }),
  },
});

export const { LATEST_APP_VERSIONS, MINOR_UPDATE_MODAL_SHOWN, SET_ONBOARDING_COMPLETE, SET_DEEP_LINK } =
  appDetailsSlice.actions;

export default appDetailsSlice.reducer;

export const isOnboardingComplete = (state: RootState) => state.appDetailsReducer.onboardingComplete;

export const selectDeepLink = (state: RootState) => state.appDetailsReducer.deepLinkUrl;
