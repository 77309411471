import React from 'react';
import { View } from 'react-native';

import { DotWithText } from './DotWithText';
import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { Button } from '../commonButton';
import { H3TtmSemiBoldBlack, H6NsRegularSecondaryBlack, H6NsSemiBoldBlack } from '../commonText';

import { mb20, mt10, textCenter } from '~/common/commonStyles';
import { labels } from '~/utils/labels';
import { dependantsLabels } from '~/utils/labels/dependantsLabels';

export const DependantLearnMoreModal: React.FC<BaseModalProps> = ({ onHide }) => {
  const buttons = [<Button label={labels.close} funCallback={onHide} />];
  return (
    <ModalContainer hideOnBackground onHide={onHide} buttons={buttons} wide showInfoLine>
      <View>
        <H3TtmSemiBoldBlack style={[textCenter, mb20]}>{dependantsLabels.learnMore.title}</H3TtmSemiBoldBlack>
        <H6NsRegularSecondaryBlack>{dependantsLabels.learnMore.subtitle}</H6NsRegularSecondaryBlack>
        <View style={{ marginTop: 10 }}>
          <H6NsSemiBoldBlack style={mt10}>{dependantsLabels.learnMore.typeA}</H6NsSemiBoldBlack>
          <H6NsRegularSecondaryBlack>{dependantsLabels.learnMore.childUnder16}</H6NsRegularSecondaryBlack>
          <DotWithText text={dependantsLabels.learnMore.typeAChildUnder16} />
          <H6NsRegularSecondaryBlack style={mt10}>{dependantsLabels.learnMore.childOver16}</H6NsRegularSecondaryBlack>
          <DotWithText text={dependantsLabels.learnMore.typeAChildOver16} />

          <H6NsSemiBoldBlack style={mt10}>{dependantsLabels.learnMore.typeB}</H6NsSemiBoldBlack>
          <DotWithText text={dependantsLabels.learnMore.typeBDocs} />

          <H6NsSemiBoldBlack style={mt10}>{dependantsLabels.learnMore.typeC}</H6NsSemiBoldBlack>
          <H6NsRegularSecondaryBlack>{dependantsLabels.learnMore.dependantAbleToSign}</H6NsRegularSecondaryBlack>
          <DotWithText text={dependantsLabels.learnMore.typeCAbleToSign} />
          <H6NsRegularSecondaryBlack style={mt10}>
            {dependantsLabels.learnMore.dependantNotAbleToSign}
          </H6NsRegularSecondaryBlack>
          <DotWithText text={dependantsLabels.learnMore.typeCNotAbleToSign} />

          <H6NsSemiBoldBlack style={mt10}>{dependantsLabels.learnMore.typeD}</H6NsSemiBoldBlack>
          <H6NsRegularSecondaryBlack>{dependantsLabels.learnMore.dependantAbleToSign}</H6NsRegularSecondaryBlack>
          <DotWithText text={dependantsLabels.learnMore.typeDAbleToSign} />
          <H6NsRegularSecondaryBlack style={mt10}>
            {dependantsLabels.learnMore.dependantNotAbleToSign}
          </H6NsRegularSecondaryBlack>
          <DotWithText text={dependantsLabels.learnMore.typeDNotAbleToSign} />

          <H6NsSemiBoldBlack style={mt10}>{dependantsLabels.learnMore.typeE}</H6NsSemiBoldBlack>
          <H6NsRegularSecondaryBlack>{dependantsLabels.learnMore.dependantAbleToSign}</H6NsRegularSecondaryBlack>
          <DotWithText text={dependantsLabels.learnMore.typeEAbleToSign} />
          <H6NsRegularSecondaryBlack style={mt10}>
            {dependantsLabels.learnMore.dependantNotAbleToSign}
          </H6NsRegularSecondaryBlack>
          <DotWithText text={dependantsLabels.learnMore.typeENotAbleToSign} />

          <H6NsSemiBoldBlack style={mt10}>{dependantsLabels.learnMore.typeF}</H6NsSemiBoldBlack>
          <DotWithText text={dependantsLabels.learnMore.typeFDocs} />
        </View>
      </View>
    </ModalContainer>
  );
};
