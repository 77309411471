import React from 'react';
import { StyleSheet, View } from 'react-native';

import { colors } from '~/utils/colors';

export const EndOfList: React.FC = () => {
  return (
    <View style={styles.container}>
      <View style={styles.endOfList} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
  },
  endOfList: {
    width: 50,
    height: 5,
    borderRadius: 4,
    backgroundColor: colors.lightGrey,
  },
});
