import React from 'react';

import { OnBoardingContentPlaceholder } from '../onBoardingContentPlaceholder';

import { onboardingLabels } from '~/utils/labels/onboardingLabels';
interface Props {
  width: number;
}
export const OnBoardingPharmacyPage3: React.FC<Props> = ({ width }) => {
  return (
    <OnBoardingContentPlaceholder
      width={width}
      noMask
      title={onboardingLabels.pharmacy[2].title}
      subtitle={onboardingLabels.pharmacy[2].subTitle}
      image={require('~/../assets/img/onboarding/pharmacy_3.png')}
    />
  );
};
