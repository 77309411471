import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { ActionButton } from './ActionButton';

import { useSubscriptions } from '~/api/hooks/subscriptions/SubscriptionHook';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { SubscriptionTypeEnum } from '~/api/models/subscriptions/models/SubscriptionPlanModel';
import { Calendar, Home, Stethoscope } from '~/components/svgImages';
import { Column } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { consultationsLabels } from '~/utils/labels/consultations';

export const HeaderActions: React.FC = (): JSX.Element => {
  const { isMobile } = useBreakpoints();
  const { currentPlan } = useSubscriptions();
  const isFreePlan = useMemo(() => currentPlan?.subscription_type === SubscriptionTypeEnum.FREEPATIENT, [currentPlan]);

  return (
    <>
      <Column span={{ sm: 4, md: 3, xl: 3 }} style={[isMobile ? styles.mobileCard : styles.card]}>
        <ActionButton
          consultationType={ConsultationTypeEnum.SCHEDULED_APPOINTMENT}
          icon={
            isMobile ? (
              <Calendar width={39} height={39} color={colors.info} />
            ) : (
              <Calendar width={27} height={27} color={colors.info} />
            )
          }
          title={consultationsLabels.scheduleForLater}
        />
      </Column>
      <Column span={{ sm: 4, md: 3, xl: 3 }} style={[isMobile ? styles.mobileCard : styles.card]}>
        <ActionButton
          consultationType={ConsultationTypeEnum.ON_DEMAND}
          icon={
            isMobile ? (
              <Stethoscope width={39} height={39} color={colors.info} />
            ) : (
              <Stethoscope width={27} height={27} color={colors.info} />
            )
          }
          title={consultationsLabels.doctorAvailableNow}
        />
      </Column>
      {isFreePlan ? null : (
        <Column span={{ sm: 4, md: 3 }} style={[isMobile ? styles.mobileCard : styles.card]}>
          <ActionButton
            consultationType={ConsultationTypeEnum.HOME_VISIT}
            icon={
              isMobile ? (
                <Home width={37} height={37} color={colors.info} />
              ) : (
                <Home width={27} height={27} color={colors.info} />
              )
            }
            title={consultationsLabels.homeVisit}
          />
        </Column>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    maxHeight: 60,
  },
  mobileCard: {
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});
