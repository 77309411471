import React, { useEffect, useImperativeHandle, useState } from 'react';
import { View, TextInput, StyleSheet, StyleProp, ViewStyle, InputModeOptions, ActivityIndicator } from 'react-native';
import { TextInputProps } from 'react-native-paper';

import { colors } from '../../utils/colors';
import { fontFamily } from '../../utils/fontFamily';
import { FormErrorMessage } from '../commonText';

import { alignItemsCenter, mv10, ph5 } from '~/common/commonStyles';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { LoadingActivityIndicator } from '../loading/LoadingActivityIndicator';

const getInputChunks = (inputCount: number, inputCellLength: number, text: string) => {
  const otpText = text.match(new RegExp('.{1,' + inputCellLength + '}', 'g')) || [];

  return otpText.slice(0, inputCount);
};

interface InputProps {
  inputCount: number;
  inputCellLength: number;
  defaultValue?: string;
  handleTextChange: CallableFunction;
  offTintColor?: string;
  tintColor?: string;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  textInputStyle?: StyleProp<TextInputProps> | any;
  keyboardType?: InputModeOptions;
  secureTextEntry?: boolean;
  autofocus?: boolean;
  editable?: boolean;
  loading?: boolean;
  onEnter?: () => void;
  errorMessage?: string;
}
export type OTPTextInputRef = { clearAll: CallableFunction };

export const OTPTextInput = React.forwardRef((props: InputProps, ref) => {
  const { isMobile } = useBreakpoints();
  const [textInputFontSize, setTextInputFontSize] = useState<number>();

  const onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    if (width < 35) {
      setTextInputFontSize(15);
    } else {
      setTextInputFontSize(24);
    }
  };

  useImperativeHandle(ref, () => ({
    clearAll: () => {
      setOtpText([]);
      if (props.autofocus) inputs[0].focus();
      setFocusedInput(0);
    },
  }));
  const [focusedInput, setFocusedInput] = useState(0);
  const [otpText, setOtpText] = useState(
    getInputChunks(props.inputCount, props.inputCellLength, props.defaultValue ? props.defaultValue : '')
  );

  let inputs: TextInput[] = [];

  usePageFocus(() => {
    setOtpText([]);
    if (props.autofocus) inputs[0].focus();
    setFocusedInput(0);
  });

  useEffect(() => {
    props.handleTextChange(otpText.join(''));
  }, [otpText]);

  const keyboardTypeValidation = (text: string) => {
    switch (keyboardType) {
      case 'numeric':
        return /^[0-9]+$/.test(text);
      default:
        return /^[0-9a-zA-Z]+$/.test(text);
    }
  };

  const onTextChange = async (fullText: string, i: number) => {
    const { inputCellLength, inputCount } = props;

    if (fullText && !keyboardTypeValidation(fullText)) {
      return;
    }

    const trimmedText = fullText.trim() || '';
    if (trimmedText.length >= inputCellLength * inputCount) {
      setOtpText(getInputChunks(inputCount, inputCellLength, trimmedText));
      inputs[inputCount - 1].focus();
      setFocusedInput(inputCount - 1);
      return;
    }

    const text = trimmedText.slice(0, inputCellLength);

    setValue(text, i);

    if (text.length === inputCellLength && i !== inputCount - 1) {
      inputs[i + 1].focus();
    }
  };

  const onInputFocus = (i: number) => {
    const prevIndex = i - 1;

    if (prevIndex > -1 && !otpText[prevIndex] && !otpText.join('')) {
      inputs[prevIndex].focus();
      return;
    }
    setFocusedInput(i);
  };

  interface OtpTextProps {
    nativeEvent: {
      key: string;
    };
  }
  const onKeyPress = (e: OtpTextProps, i: number) => {
    const val = otpText[i] || '';
    if (e.nativeEvent.key === 'Backspace' && i !== 0 && !val.length) {
      setValue('', i - 1);
      inputs[i - 1].focus();
    } else if ((props.onEnter && e.nativeEvent.key === 'Enter') || e.nativeEvent.key === 'Submit') {
      props.onEnter();
    }
  };

  const setValue = (value: string, i: number) => {
    const { inputCount, inputCellLength } = props;
    setOtpText(getInputChunks(inputCount, inputCellLength, value));
    const tempOTPText = otpText;
    tempOTPText[i] = value;
    setOtpText([...tempOTPText]);
  };

  const {
    inputCount,
    offTintColor,
    tintColor,
    defaultValue,
    inputCellLength,
    containerStyle,
    textInputStyle,
    keyboardType,
    style,
    ...textInputProps
  } = props;

  const TextInputs = [];

  for (let i = 0; inputCount && i < inputCount; i += 1) {
    const inputStyle = [
      styles.textInput,
      textInputStyle,
      { borderColor: offTintColor },
      focusedInput === i ? { backgroundColor: colors.white } : null,
      isMobile ? { width: '12%' } : { width: 45 },
    ];

    if (focusedInput === i) {
      inputStyle.push({ borderColor: tintColor });
    }

    TextInputs.push(
      <TextInput
        ref={(e) => {
          if (e) inputs[i] = e;
        }}
        onLayout={onLayout}
        key={i}
        autoCorrect={false}
        inputMode={keyboardType}
        verticalAlign="middle"
        value={otpText[i] || ''}
        style={[inputStyle, isMobile ? { width: '15%' } : null, { fontSize: textInputFontSize }]}
        // maxLength={props.inputCellLength}
        onFocus={() => onInputFocus(i)}
        onChangeText={(text) => onTextChange(text, i)}
        multiline={false}
        onKeyPress={(e) => onKeyPress(e, i)}
        editable={props.editable ?? true}
        {...textInputProps}
      />
    );
  }

  return (
    <View style={[mv10, style]}>
      <View style={[styles.container, containerStyle, { flexDirection: 'row' }]}>
        {/* <WebForm> */}
        <>{TextInputs}</>
        {/* </WebForm> */}
        {props.loading ? (
          <View style={[styles.loadingContainer]}>
            <LoadingActivityIndicator />
          </View>
        ) : null}
      </View>
      {props.errorMessage ? (
        <View style={[ph5, alignItemsCenter]}>
          <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
        </View>
      ) : null}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInput: {
    maxWidth: 45,
    minHeight: 20,
    minWidth: 20,
    aspectRatio: 1,
    margin: 5,
    padding: 0,
    borderRadius: 6,
    textAlign: 'center',
    fontSize: 24,
    backgroundColor: colors.lightPurple,
    fontFamily: fontFamily.fontSemiBold,
    color: colors.grey,
    borderWidth: 1,
  },
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `${colors.white}aa`,
  },
});
