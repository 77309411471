import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useController } from 'react-hook-form';
import { View } from 'react-native';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';

import { flex1, mb10, textCenter } from '~/common/commonStyles';
import { ButtonLink } from '~/components/commonButton';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { DoctorListingAndFiltering } from '~/components/patient/doctorListing/pages/DoctorListingAndFiltering';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { NavType } from '~/navigation/types';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { AppointmentBookingProvider } from '~/providers/appointment/AppointmentBookingProvider';
import { DoctorListingProvider } from '~/providers/appointment/DoctorListingProvider';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { SET_BOOKING_PHYSICIAN } from '~/redux/reducers/patient/appointmentReducer';
import { useAppDispatch } from '~/redux/store';
import { Column, Row } from '~/theme/components/grid';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { patientLabels } from '~/utils/labels/patient';
import { screenName } from '~/utils/screenName';

export const DoctorListingPage: React.FC = () => {
  const { isMobile } = useBreakpoints();

  return (
    <PageLayout
      hideBack
      headerRight={
        !isMobile ? (
          <Row justifyContent="flex-end">
            <Column span="auto">
              <ButtonLink
                style={[mb10, textCenter]}
                label={patientLabels.bookAppointment}
                screenName={screenName.AppointmentsBooking}
              />
            </Column>
          </Row>
        ) : null
      }
      title={patientLabels.doctors}
      footer={
        isMobile ? (
          <ButtonLink
            style={[mb10, textCenter]}
            label={patientLabels.bookAppointment}
            screenName={screenName.AppointmentsBooking}
          />
        ) : null
      }
      scrollContentNode={
        <View style={[flex1]}>
          <AppointmentBookingProvider>
            <DoctorListingSubPage />
          </AppointmentBookingProvider>
        </View>
      }
    />
  );
};

const DoctorListingSubPage: React.FC = () => {
  const { formControl } = useAppointmentBookingContext();
  const { navigate } = useNavigation<NavType>();
  const dispatch = useAppDispatch();
  const { hasFeatureAccess } = useFeatureAccess();
  const { openModal } = useModalManager();
  const {
    field: { onChange },
  } = useController({ control: formControl, name: 'type' });
  return (
    <DoctorListingProvider
      isListing
      onSelectDoctorOrClinic={(doctorOrClinic, consultationType, channel) => {
        onChange(consultationType);

        if (consultationType === ConsultationTypeEnum.HOME_VISIT && !hasFeatureAccess(FeatureAccessEnum.HOME_VISITS)) {
          openModal(ModalName.PaywallModal, {
            title: 'Upgrade plan to book Home Visits',
          });
        } else {
          dispatch(
            SET_BOOKING_PHYSICIAN({
              doctorOrClinic,
              channel,
              consultationType,
            })
          );
          navigate(screenName.AppointmentsBooking);
        }
      }}>
      <DoctorListingAndFiltering hideTitle />
    </DoctorListingProvider>
  );
};
