import React from 'react';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { INavigate } from '~/common/types/navigation/navigate';
import { HealthProfileHistoryItemMobile } from '~/components/doctor/patients/healthProfile/HealthProfileHistoryItemMobile';
import { HealthProfileMedicationsProvider } from '~/providers/healthProfile/history/HealthProfileMedicationsProvider';

interface Props {
  edit?: boolean;
  backLink: INavigate;
}

export const HealthProfileMedicationsMobile: React.FC<Props> = ({ edit, backLink }) => {
  return (
    <HealthProfileMedicationsProvider>
      <HealthProfileHistoryItemMobile
        edit={edit}
        type={{ label: 'Medications', value: ClinicalTermTypeEnum.MEDICATIONS }}
        backLink={backLink}
      />
    </HealthProfileMedicationsProvider>
  );
};
