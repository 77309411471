import React, { useMemo, useRef, useState } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';

import { DoctorAppointmentPatientInfo } from './DoctorAppointmentPatientInfo';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { flex1, mr10, ph15, pv10 } from '~/common/commonStyles';
import { AppointmentShowPageLayout } from '~/components/common/appointments/AppointmentShowPageLayout';
import { DoctorAppointmentReportView } from '~/components/common/appointments/DoctorAppointmentReportView';
import { ConsultationLoading } from '~/components/common/consultations/ConsultationLoading';
import { H3TtmRegularSecondaryBlack } from '~/components/commonText';
import { PrivateNoteEdit } from '~/components/doctor/patients/consultation/tabs/PrivateNote/PrivateNoteEdit';
import { BottomSheet, BottomSheetRef } from '~/components/modals/BottomSheet';
import { BackNavigation } from '~/components/navigation/BackNavigation';
import { NoteIcon } from '~/components/svgImages';
import { ChannelTypeEnum } from '~/constants/channelTypeEnum';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { Column, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import {
  isCallOrChatConsultation,
  isConsultationScheduledOrStarted,
  isPastConsultation,
} from '~/utils/consultationUtils';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';
import { useNavigation } from '@react-navigation/native';
import { NavType } from '~/navigation/types';
import { usePrivateNoteDataContext } from '~/providers/consultation/PrivateNoteDataContext';

interface Props {
  children?: React.ReactNode;
  fluid?: boolean;
}

export const DoctorAppointmentShowContent: React.FC<Props> = ({ children, fluid }) => {
  const { consultation, loading } = useAppointmentContext();
  const { isXl, isMobile } = useBreakpoints();
  const bottomSheetRef = useRef<BottomSheetRef>();
  const snapPoints = useRef([100, '90%']);
  const [sheetSnapIndex, setSheetSnapIndex] = useState(0);
  const { navigate, canGoBack, goBack } = useNavigation<NavType>();
  const { loadData } = usePrivateNoteDataContext();

  const showCallOrChat = useMemo(
    () =>
      isCallOrChatConsultation(consultation) &&
      (isConsultationScheduledOrStarted(consultation) || consultation.channel?.id === ChannelTypeEnum.Chat),
    [consultation?.channel, consultation?.state]
  );

  const showPatientInfo = useMemo(
    () => !isMobile || isConsultationScheduledOrStarted(consultation),
    [consultation, isMobile]
  );

  const showReport = useMemo(
    () =>
      !isMobile ||
      consultation?.state === ConsultationStateEnum.Ended ||
      consultation?.state === ConsultationStateEnum.Submitted,
    [consultation, children, isMobile]
  );

  const toggleBottomSheet = () => {
    const newIndex = (sheetSnapIndex + 1) % 2;
    setSheetSnapIndex(newIndex);
    loadData();
  };

  const smallMiddleColumn = useMemo(() => {
    return (
      (isPastConsultation(consultation) || consultation?.state === ConsultationStateEnum.Ended) &&
      consultation?.channel?.id !== ChannelTypeEnum.Chat
    );
  }, [consultation?.state, consultation?.channel]);

  const showBottomSheet = isMobile && !showReport;

  const handleBack = () => {
    if (showCallOrChat) {
      navigate('consultation');
    } else if (canGoBack()) {
      goBack();
    } else {
      navigate(screenName.CalendarPage);
    }
  };

  if (loading || !consultation) {
    return <ConsultationLoading />;
  }

  return (
    <AppointmentShowPageLayout
      fluid={fluid}
      noPadding={showPatientInfo}
      flexContent={isXl}
      noScrollWhenMobile={showBottomSheet}>
      <>
        <Row style={[flex1, { padding: 5 }]}>
          {showPatientInfo ? (
            <Column
              style={{
                maxWidth: !showReport ? undefined : 340,
                marginBottom: 20,
                maxHeight: isXl || isMobile ? '100%' : undefined,
                height: isXl || isMobile ? '100%' : undefined,
              }}>
              <View style={{ display: 'flex', flex: 1 }}>
                {isMobile ? (
                  <View style={{ display: 'flex', flex: 1 }}>
                    <View style={[ph15, pv10]}>
                      <BackNavigation onPress={handleBack} />
                    </View>
                    <View style={styles.mobileContainer}>
                      <DoctorAppointmentPatientInfo
                        whiteBackground
                        contentStyling={{
                          paddingHorizontal: 10,
                          paddingBottom: showBottomSheet ? 120 : undefined,
                          overflow: 'hidden',
                        }}
                        viewStyle={{ height: '100%' }}
                      />
                    </View>
                  </View>
                ) : isXl ? (
                  <View style={{ flex: 1, display: 'flex' }}>
                    <DoctorAppointmentPatientInfo scrollStyle={flex1} viewStyle={{ display: 'flex', flex: 1 }} />
                  </View>
                ) : (
                  <DoctorAppointmentPatientInfo />
                )}
              </View>
            </Column>
          ) : null}

          {children && !isMobile && showCallOrChat ? (
            <Column style={{ maxWidth: smallMiddleColumn ? 300 : 500, maxHeight: 600, marginBottom: 20 }}>
              {children}
            </Column>
          ) : null}

          {showReport ? (
            <Column span={{ xs: 12, lg: 6 }} style={{ minHeight: 500, maxHeight: isXl ? '100%' : undefined }}>
              <DoctorAppointmentReportView noScroll={!isXl} />
            </Column>
          ) : null}
        </Row>
        {showBottomSheet ? (
          <BottomSheet
            ref={bottomSheetRef}
            toggleOnBackground={sheetSnapIndex === 1}
            snapIndex={sheetSnapIndex}
            snapPoints={snapPoints.current}
            onPressDragLine={toggleBottomSheet}>
            <View>
              <TouchableWithoutFeedback onPress={toggleBottomSheet}>
                <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 60, alignItems: 'center' }}>
                  <View style={[mr10]}>
                    <NoteIcon width={16} height={16} />
                  </View>
                  <H3TtmRegularSecondaryBlack>Private Notes</H3TtmRegularSecondaryBlack>
                </View>
              </TouchableWithoutFeedback>
              <PrivateNoteEdit editable noPadding />
            </View>
          </BottomSheet>
        ) : null}
      </>
    </AppointmentShowPageLayout>
  );
};

const styles = StyleSheet.create({
  mobileContainer: {
    flexDirection: 'column',

    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    borderWidth: 2,
    borderColor: colors.lightPurple2,
    paddingTop: 8,
    flex: 1,
  },
});
