import { useState } from 'react';
import { useSelector } from 'react-redux';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationsIndexResponse } from '~/api/models/consultations/responses/ConsultationsIndexResponse';
import { apiGetConsultationsIndex } from '~/api/services/consultations';
import { RootState } from '~/redux/reducers';
import { APPEND_REQUESTS } from '~/redux/reducers/requestsReducer';
import { useAppDispatch } from '~/redux/store';
import { isDoctorVersion } from '~/utils/buildConfig';

// TO ADD QUERY FILTER
export function useApprovalRequestsList() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const approvalRequests = useSelector((state: RootState) => state.requestsReducer.requests);

  const getRequests = () => {
    if (!isDoctorVersion()) return;
    setLoading(true);
    return apiGetConsultationsIndex<ConsultationsIndexResponse>({
      limit: -1,
      filter: { state: [ConsultationStateEnum.RequestSubmitted] },
    }).then((res) => {
      dispatch(APPEND_REQUESTS(res.data));
      setLoading(false);
    });
  };

  return { approvalRequests, loading, getRequests };
}
