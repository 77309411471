import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { BillingHistoryFilters } from './components/BillingHistoryFilters';
import { BillingRequestForm } from './contexts/BillingHistoryProvider';

import { mb15 } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { H5TtmSemiBoldBlack } from '~/components/commonText';
import { ModalContainer } from '~/components/modals/ModalContainer';

interface Props {
  onHide?: () => void;
  form: UseFormReturn<BillingRequestForm, any>;
}

export const BillingHistoryModal: React.FC<Props> = ({ onHide, form }) => {
  return (
    <ModalContainer onHide={onHide} hideOnBackground buttons={[<Button label="Close" funCallback={onHide} />]}>
      <View style={styles.container}>
        <H5TtmSemiBoldBlack>Filter</H5TtmSemiBoldBlack>
        <View style={mb15} />
        <BillingHistoryFilters form={form} />
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
  },
});
