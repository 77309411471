import React from 'react';
import { View } from 'react-native';

import { PatientInformation } from '../../PatientInformation';

import { flex1, mb10, mt10 } from '~/common/commonStyles';
import { TitledLinkButton } from '~/components/buttons/TitledLinkButton';
import { CreateDiagnosisEditingComponent } from '~/components/doctor/patients/consultation/diagnosis/CreateDiagnosisEditingComponent';
import { useDiagnosisContext } from '~/providers/diagnosis/DiagnosisContext';
import { Grid, Container, Row, Column } from '~/theme/components/grid';
import { screenName } from '~/utils/screenName';

export const CreateDiagnosisComponentWeb: React.FC = () => {
  const { patientId } = useDiagnosisContext();
  return (
    <Grid grid={{ noOuterGutter: true }}>
      <Container alignSelf="flex-start" style={flex1}>
        <Row>
          <Column span={{ xs: 12, md: 3 }} style={mb10}>
            <PatientInformation id={patientId} />

            <TitledLinkButton
              style={mt10}
              title="Health Profile"
              to={{ screen: screenName.PatientHealthProfile as never, params: { id: patientId } as never }}
            />

            <TitledLinkButton
              style={mt10}
              title="Existing Diagnoses"
              to={{ screen: screenName.PatientDetails as never, params: { id: patientId } as never }}
            />
          </Column>
          <Column span={{ xs: 12, md: 9 }} style={mb10}>
            <Row style={flex1}>
              <Column>
                <View style={flex1}>
                  <CreateDiagnosisEditingComponent />
                </View>
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </Grid>
  );
};
