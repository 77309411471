import { FlashList } from '@shopify/flash-list';
import React, { useEffect, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiGetConsultationsIndex } from '~/api/services/consultations';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { LinkButton } from '~/components/buttons/LinkButton';
import ConsultationAgendaItem from '~/components/calendar/ConsultationAgendaItem';
import ConsultationAgendaItemLarge from '~/components/calendar/ConsultationAgendaItemLarge';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { whenAppType } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

interface Props {
  title: string;
  style?: StyleProp<ViewStyle>;
  states?: ConsultationStateEnum[];
  hideArrow?: boolean;
  hideIndicator?: boolean;
}

export const AppointmentDashboardListing: React.FC<Props> = ({ style, states, title, hideArrow, hideIndicator }) => {
  const { isMobile, isTablet } = useBreakpoints();
  const [consultations, setConsultations] = useState<ConsultationModel[]>();
  const [hideHeaderAction, setHideHeaderAction] = useState<boolean>();

  const getEvents = async () => {
    try {
      const res = await apiGetConsultationsIndex({
        filter: {
          state: states,
        },
        limit: 10,
        sort: '-request_submitted_at',
      });

      setConsultations(res.data.data);
      setHideHeaderAction(res.data.meta.total <= res.data.meta.per_page);
    } catch {}
  };

  usePageFocus(() => {
    getEvents();
  }, []);

  useEffect(() => {
    const removeListener = AppEventHandler.addListener(AppEvents.PATIENT_APPOINTMENTS_CHANGED, () => {
      getEvents();
    });
    return removeListener;
  }, []);

  if (!consultations?.length) return;

  return (
    <View style={style}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <H3TtmSemiBoldBlack style={{ marginBottom: 15 }}>{title}</H3TtmSemiBoldBlack>
        {hideHeaderAction ? null : (
          <LinkButton
            to={whenAppType({
              whenDoctor: { screen: screenName.CalendarPage } as never,
              whenPatient: { screen: screenName.Appointments } as never,
            })}
            title={labels.viewAll}
          />
        )}
      </View>
      <View
        style={{ backgroundColor: colors.lightPurple, paddingHorizontal: 16, paddingVertical: 12, borderRadius: 6 }}>
        <FlashList<ConsultationModel>
          data={consultations}
          estimatedItemSize={100}
          keyExtractor={(item) => `${item.id}`}
          ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
          renderItem={({ item }) =>
            isMobile || isTablet ? (
              <ConsultationAgendaItem
                showStartDate
                consultation={item}
                noHorizontalPadding
                hideArrow={hideArrow}
                hideIndicator={hideIndicator}
              />
            ) : (
              <ConsultationAgendaItemLarge showStartDate consultation={item} noHorizontalPadding />
            )
          }
        />
      </View>
    </View>
  );
};
