import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { NoPatientsComponent } from './NoPatientsComponent';

import { usePatientsList } from '~/api/hooks/patients/PatientsListHook';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { flex1, justifyCenter, mb5, mr15 } from '~/common/commonStyles';
import { DataTableColumns, DataTable } from '~/components/common/DataTable/DataTable';
import { H3TtmSemiBoldBlack, SmallNsRegularBlack, SmallNsRegularSecondaryBlack } from '~/components/commonText';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';
import { parseDateTime, DATE_FORMAT } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { screenName } from '~/utils/screenName';

interface Props {
  query?: string;
}
export const PatientsTable: React.FC<Props> = ({ query }) => {
  const navigation = useNavigation<NavType>();
  const { pagination, setPagination, patients, loading } = usePatientsList({ query });
  const { isMobile } = useBreakpoints();

  const columns: DataTableColumns = [
    {
      key: 'patient',
      title: 'Patient',
      cell: (_, itemData) => <ProfileDetailsComponent account={itemData} theme="table" style={flex1} />,
    },
    {
      key: 'gender',
      title: 'Gender',
      cell: (cellData) => <SmallNsRegularBlack style={{ textTransform: 'capitalize' }}>{cellData}</SmallNsRegularBlack>,
      columnStyle: { maxWidth: 80 },
    },
    {
      key: 'date_of_birth',
      title: 'Date of birth',
      selector: (item) => {
        return parseDateTime(item.date_of_birth, { outputFormat: DATE_FORMAT }) || '';
      },
      columnStyle: { maxWidth: 100 },
    },
  ];

  const rowPress = (key: number) => {
    navigation.navigate(screenName.PatientDetails, { id: key });
  };

  return (
    <DataTable
      tableId="patients"
      columns={columns}
      items={patients}
      totalCount={pagination?.total || 0}
      onRowPress={rowPress}
      page={pagination?.current_page}
      onPageChanged={setPagination}
      onItemsPerPageChange={setPagination}
      infiniteScroll={{ loading, estimatedItemSize: { desktop: 60, mobile: 110 } }}
      numberOfItemsPerPage={pagination?.per_page}
      emptyNote={isMobile ? labels.noPatients : <NoPatientsComponent query={query} />}
      mobileRenderItem={(account: AccountModel, item) => (
        <View style={[{ display: 'flex', flexDirection: 'row' }]}>
          <View style={[mr15, justifyCenter]}>
            <ProfileImageComponent account={account} size={80} />
          </View>
          <View style={flex1}>
            <H3TtmSemiBoldBlack style={mb5}>{getAccountName(account)}</H3TtmSemiBoldBlack>
            <View>
              <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 2 }}>
                <SmallNsRegularSecondaryBlack style={{ width: 75, color: colors.purpleGrey, marginRight: 5 }}>
                  Patient ID
                </SmallNsRegularSecondaryBlack>
                <SmallNsRegularBlack style={flex1}>
                  {account.passport_number || account.national_id_number}
                </SmallNsRegularBlack>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 2 }}>
                <SmallNsRegularSecondaryBlack style={{ width: 75, color: colors.purpleGrey, marginRight: 5 }}>
                  Gender
                </SmallNsRegularSecondaryBlack>
                <SmallNsRegularBlack style={[{ textTransform: 'capitalize' }, flex1]}>
                  {account.gender}
                </SmallNsRegularBlack>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 2 }}>
                <SmallNsRegularSecondaryBlack style={{ width: 75, color: colors.purpleGrey, marginRight: 5 }}>
                  DOB
                </SmallNsRegularSecondaryBlack>
                <SmallNsRegularBlack style={flex1}>{item.date_of_birth.content}</SmallNsRegularBlack>
              </View>
            </View>
          </View>
        </View>
      )}
    />
  );
};
