import React from 'react';
import { Controller } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';

import { PrescriptionsCommentsInput } from './PrescriptionsCommentsInput';
import { PrescriptionsDoseInput } from './PrescriptionsDoseInput';
import { PrescriptionsFrequencyDropdown } from './PrescriptionsFrequencyDropdown';
import { PrescriptionsRemoveItem } from './PrescriptionsRemoveItem';
import { PrescriptionsRouteInput } from './PrescriptionsRouteInput';
import { PrescriptionsType } from './PrescriptionsType';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { LabelValuePair } from '~/common/types/LabelValuePair';
import { ClinicalTermSearchableList } from '~/components/inputs/searchable/ClinicalTermSearchableList';
import { usePrescriptionDataContext } from '~/providers/consultation/PrescriptionDataContext';
import { Row, Column, Grid, Container } from '~/theme/components/grid';
import { colors } from '~/utils/colors';

interface Props {
  index: number;
}

export const PrescriptionsForm: React.FC<Props> = ({ index }) => {
  const {
    form: { control, rulesArray },
  } = usePrescriptionDataContext();

  return (
    <View>
      <Grid grid={{ gutters: 10 }}>
        <Container>
          <Row>
            <Column>
              <Controller
                name={`prescriptions.${index}.product`}
                control={control}
                render={({ field: { value, onChange, onBlur }, fieldState: { error }, formState: { isSubmitted } }) => {
                  return (
                    <ClinicalTermSearchableList
                      type={ClinicalTermTypeEnum.PRESCRIPTION_PRODUCT}
                      values={value ? [value] : []}
                      setValues={(values: LabelValuePair<string | number>[]) => {
                        onChange(values.length ? values[0] : null);
                      }}
                      onBlur={onBlur}
                      title="Product name"
                      error={!!error?.message}
                      errorMessage={error?.message}
                      singleSelect
                      allowFreeText
                    />
                  );
                }}
                rules={rulesArray?.prescriptions.product}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <PrescriptionsFrequencyDropdown index={index} />
            </Column>
          </Row>
          <Row>
            <Column>
              <PrescriptionsDoseInput index={index} />
            </Column>
          </Row>
          <Row>
            <Column>
              <PrescriptionsRouteInput index={index} />
            </Column>
          </Row>

          <Row>
            <Column>
              <PrescriptionsCommentsInput index={index} />
            </Column>
          </Row>

          <Row>
            <Column>
              <PrescriptionsType index={index} />
            </Column>
          </Row>

          <PrescriptionsRemoveItem index={index} />
          <View style={styles.horizontalSeparator} />
        </Container>
      </Grid>
    </View>
  );
};

const styles = StyleSheet.create({
  horizontalSeparator: {
    marginVertical: 8,
    height: 1,
    width: '100%',
    backgroundColor: colors.lightPurple2,
  },
});
