import AsyncStorage from '@react-native-async-storage/async-storage';

import EncryptedStore from '~/integrations/encryptedStore';
import log from '~/utils/logger';

export const storageKeys = {
  authToken: 'auth',
  countries: 'countries',
  geoPermission: 'geoPermission',
  lastPharmacy: 'lastPharmacy',
  locations: 'locations',
  loginDetails: 'cred',
  newFeature: 'newFeature1',
  onboarding: 'onboarding',
  pharmacyLink: 'pharmacyLink',
  sidebar: 'sidebar',
  tableDetails: 'table',
  uploadedDocuments: 'ALREADY_UPLOADED_DOCUMENTS', // Doctor Verification Uploaded images
  userDetails: 'USER_DETAILS',
  userSettings: 'USER_SETTINGS',
  biometrics: 'link_biometric',
};

export const setAsyncItem = async (key: string, value: string) => {
  try {
    await EncryptedStore.setAsyncItem(key, value);
  } catch (e) {
    log.warn(e);
  }
};

export const setUnencryptedAsyncItem = async (key: string, value: string) => {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (e) {
    log.warn(e);
  }
};

export async function setObjectAsyncItem<T = any>(key: string, value: T) {
  try {
    await EncryptedStore.setAsyncItem(key, JSON.stringify(value));
  } catch (e) {
    log.warn(e);
  }
}

export async function getAsyncItem(key: string, defaultData?: string) {
  try {
    return await EncryptedStore.getAsyncItem(key, defaultData);
  } catch (e) {
    log.warn(e);
  }
}

export async function getParsedAsyncItem<T = any>(key: string, defaultData?: T) {
  try {
    const value = await EncryptedStore.getAsyncItem(key);
    try {
      if (typeof value === 'string') {
        return JSON.parse(value) as T;
      }
    } catch {}
    return (value as T) || defaultData;
  } catch (e) {
    log.warn(e);
  }
}

export async function getUnencryptedAsyncItem(key: string, defaultData?: string) {
  try {
    const loadedData = await AsyncStorage.getItem(key);
    return loadedData ?? defaultData;
  } catch (e) {
    log.warn(e);
  }
}

export async function getUnencryptedParsedAsyncItem<T = any>(key: string, defaultData?: T) {
  try {
    const value = await getUnencryptedAsyncItem(key);
    try {
      if (typeof value === 'string') {
        return JSON.parse(value) as T;
      }
    } catch {}
    return (value as T) || defaultData;
  } catch (e) {
    log.warn(e);
  }
}

export const removeAsyncItem = async (key: string) => {
  try {
    await EncryptedStore.removeAsyncItem(key);
  } catch (e) {
    log.warn(e);
  }
};
