import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { apiGetHealthProfile } from '~/api/services/healthProfile';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import {
  CLEAR_HEALTH_PROFILE,
  selectHealthProfileAccountId,
  selectHealthProfileId,
  SET_HEALTH_PROFILE,
  SET_HEALTH_PROFILE_ACCOUNT,
} from '~/redux/reducers/healthProfileReducer';
import { useAppDispatch } from '~/redux/store';

interface Props {
  accountId: number;
}
export function useHealthProfile({ accountId }: Props) {
  const [loading, setLoading] = useState(true);

  const currentHealthProfileId = useSelector(selectHealthProfileId);
  const currentAccountId = useSelector(selectHealthProfileAccountId);

  const dispatch = useAppDispatch();

  const getHealthProfile = async () => {
    setLoading(true);
    try {
      const healthProfile = await apiGetHealthProfile({ id: accountId }).catch((res: AxiosError) => {
        if (res?.status !== 404) {
          return res;
        }
      });
      if (!healthProfile || !('data' in healthProfile)) {
        dispatch(CLEAR_HEALTH_PROFILE());
        setLoading(false);
        return;
      }

      dispatch(SET_HEALTH_PROFILE_ACCOUNT(accountId));

      dispatch(SET_HEALTH_PROFILE(healthProfile.data));
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Clear health profile, if the account id has changed
    if (!currentAccountId || !accountId || currentAccountId !== accountId) {
      dispatch(CLEAR_HEALTH_PROFILE());
      if (accountId) getHealthProfile();
    } else if (accountId) {
      setLoading(false);
    }
  }, [accountId]);

  return {
    loading,
    healthProfileId: currentHealthProfileId,
    getHealthProfile,
  };
}
