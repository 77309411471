import React, { useMemo } from 'react';
import { View } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { Button } from '../commonButton';
import PolicyContent from '../policies/PolicyContent';

import { PageTypesEnum } from '~/api/models/pages/constants/PageTypesEnum';
import { HIDE_PAGE_INFO_MODAL } from '~/redux/reducers/modalReducer';
import { useAppDispatch } from '~/redux/store';
import { labels } from '~/utils/labels';

const SlugToPageMapping = {
  [PageTypesEnum.REDUCE_FILE_SIZE]: PageTypesEnum.REDUCE_FILE_SIZE,
  [PageTypesEnum.PATIENT_TERM_AND_CONDITIONS]: PageTypesEnum.PATIENT_TERM_AND_CONDITIONS,
  [PageTypesEnum.DOCTOR_TERM_AND_CONDITIONS]: PageTypesEnum.DOCTOR_TERM_AND_CONDITIONS,
  [PageTypesEnum.PHARMACY_TERM_AND_CONDITIONS]: PageTypesEnum.PHARMACY_TERM_AND_CONDITIONS,
};

interface Props extends BaseModalProps {
  pageType: PageTypesEnum;
}
export const PageModal: React.FC<Props> = ({ pageType, onHide }) => {
  const contentType = useMemo(() => {
    return SlugToPageMapping[pageType];
  }, [pageType]);

  const dispatch = useAppDispatch();

  const hide = () => {
    dispatch(HIDE_PAGE_INFO_MODAL());
    if (onHide) onHide();
  };

  const buttons = [<Button label={labels.continue} funCallback={hide} />];
  return (
    <ModalContainer hideOnBackground onHide={hide} showInfoLine wide fullScreen buttons={buttons}>
      <View style={{ display: 'flex', flex: 1 }}>
        <PolicyContent contentType={contentType} />
      </View>
    </ModalContainer>
  );
};
