import { Route } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { View } from 'react-native';

import { ConsultationReportMobileHeader } from '../ConsultationReportMobileHeader';

import { useConsultationDetails } from '~/api/hooks/consultations/ConsultationDetails';
import { flex1 } from '~/common/commonStyles';
import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { PrivateNoteEdit } from '~/components/doctor/patients/consultation/tabs/PrivateNote/PrivateNoteEdit';
import { ConsultationReportProvider } from '~/contexts/ConsultationReportProvider';
import { usePrivateNoteDataContext } from '~/providers/consultation/PrivateNoteDataContext';
import { PrivateNoteDataProvider } from '~/providers/consultation/PrivateNoteDataProvider';
import { Grid, Container } from '~/theme/components/grid';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { id: string; diagnosisId: string; consultationId: string; edit?: boolean }>;
}

const Content: React.FC<Props & { isEditing: boolean }> = ({ route, isEditing }) => {
  const { loadData } = usePrivateNoteDataContext();

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Grid>
      <Container style={{ height: '100%' }}>
        <PatientsLayout
          hideHeaderRight
          back={{ to: { screen: screenName.PatientConsultation, params: route.params } }}
          flexContent={false}>
          <ConsultationReportMobileHeader title="Private Note" noAutoSave={isEditing} />
          <View style={[flex1]}>
            <PrivateNoteEdit editable={isEditing} />
          </View>
        </PatientsLayout>
      </Container>
    </Grid>
  );
};

export const ConsultationPrivateNoteMobile: React.FC<Props> = (props) => {
  const { consultation, isEditing } = useConsultationDetails({ consultationId: +props?.route.params.consultationId });

  return (
    <PrivateNoteDataProvider consultation={consultation}>
      <ConsultationReportProvider>
        <Content {...props} isEditing={isEditing} />
      </ConsultationReportProvider>
    </PrivateNoteDataProvider>
  );
};
