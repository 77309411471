import { get, post, put } from '../../axios';
import { IdModel } from '../../models/common/models/IdModel';
import { ConsultationsIndexRequest } from '../../models/consultations/requests/ConsultationsIndexRequest';
import { CreateConsultationRequest } from '../../models/consultations/requests/CreateConsultationRequest';
import { CreateConsultationResponse } from '../../models/consultations/responses/CreateConsultationResponse';
import { GetConsultationResponse } from '../../models/consultations/responses/GetConsultationResponse';

import { UpdateConsultationRequest } from '~/api/models/consultations/requests/UpdateConsultationRequest';
import { ConsultationTypeIndexResponse } from '~/api/models/consultations/responses/ConsultationTypeIndexResponse';
import { ConsultationsPaginatedIndexResponse } from '~/api/models/consultations/responses/ConsultationsIndexResponse';

export const apiCreateConsultation = (data: CreateConsultationRequest) => {
  return post<CreateConsultationResponse>('/consultations', data);
};

export const apiUpdateConsultation = (req: UpdateConsultationRequest) => {
  return put<CreateConsultationResponse>(`/consultations/${req.id}`, req.params);
};

export const apiGetConsultationsIndex = <TResponse = ConsultationsPaginatedIndexResponse>(
  params: ConsultationsIndexRequest
) => {
  return get<TResponse>('/consultations', params);
};

export const apiGetConsultation = (params: IdModel) => {
  return get<GetConsultationResponse>(`/consultations/${params.id}`);
};

export const apiSubmitConsultation = (params: IdModel & { req: { include_signature?: boolean } }) => {
  return post(`/consultations/${params.id}/submit`, params.req);
};

export const apiGetConsultationTypes = () => {
  return get<ConsultationTypeIndexResponse>('/consultation-types');
};
