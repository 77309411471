import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PageTypesEnum } from '~/api/models/pages/constants/PageTypesEnum';

interface IState {
  pageInfoModal?: PageTypesEnum;
}
const initialState: IState = {
  pageInfoModal: undefined,
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    HIDE_PAGE_INFO_MODAL: () => {
      return {
        ...initialState,
      };
    },
    SHOW_PAGE_INFO_MODAL: (state, action: PayloadAction<PageTypesEnum>) => {
      return {
        ...state,
        pageInfoModal: action.payload,
      };
    },
  },
});

export const { HIDE_PAGE_INFO_MODAL, SHOW_PAGE_INFO_MODAL } = modalsSlice.actions;
export default modalsSlice.reducer;
