import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { ScheduledAppointmentsModelWithId } from '~/api/models/preferences/models/ScheduledAppointmentsWithIdModel';
import { mr10, mr25, mr5 } from '~/common/commonStyles';
import { LabelValuePair } from '~/common/types/LabelValuePair';
import { BaseCard } from '~/components/common/BaseCard';
import { H5TtmRegularBlack, SmallNsRegularPurpleGrey, SmallNsRegularBlack } from '~/components/commonText';
import { RowView } from '~/components/commonViews';
import { PencilEditIcon, DeleteIcon, Calendar } from '~/components/svgImages';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

interface Props {
  id: number;
  items: LabelValuePair<string>[];
  itemData: ScheduledAppointmentsModelWithId;
  removeScheduledAppointment: (id: number) => Promise<void>;
  updateScheduledAppointment: (data: ScheduledAppointmentsModelWithId) => void;
  disabled?: boolean;
}

export const ScheduledAppointmentCard: React.FC<Props> = ({
  id,
  items,
  itemData,
  disabled,
  removeScheduledAppointment,
  updateScheduledAppointment,
}) => {
  const cardTitle = (
    <RowView>
      <RowView style={mr25}>
        <View style={mr10}>
          <Calendar width={15} height={15} />
        </View>
        <H5TtmRegularBlack>{labels.scheduledAppointments}</H5TtmRegularBlack>
      </RowView>
      {disabled ? null : (
        <RowView>
          <TouchableOpacity style={[styles.iconBorder, mr5]} onPress={() => updateScheduledAppointment(itemData)}>
            <PencilEditIcon width={15} height={15} />
          </TouchableOpacity>
          <TouchableOpacity style={styles.iconBorder} onPress={() => removeScheduledAppointment(id)}>
            <DeleteIcon width={15} height={15} color={colors.danger} />
          </TouchableOpacity>
        </RowView>
      )}
    </RowView>
  );

  const cardContent = (
    <>
      {items?.map((element, index) => {
        return (
          <View key={index} style={styles.rowItem}>
            <View style={styles.itemKey}>
              <SmallNsRegularPurpleGrey>{element.label}</SmallNsRegularPurpleGrey>
            </View>
            <View style={styles.valueKey}>
              <SmallNsRegularBlack>{element.value}</SmallNsRegularBlack>
            </View>
          </View>
        );
      })}
    </>
  );
  return <BaseCard title={cardTitle} content={cardContent} />;
};

const styles = StyleSheet.create({
  iconBorder: {
    padding: 8,
    borderColor: colors.lightPurple,
    borderWidth: 1,
    borderRadius: 6,
  },
  rowItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  itemKey: {
    minWidth: 125,
  },
  valueKey: {
    width: 150,
  },
});
