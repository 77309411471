import { DocumentPickerAsset } from 'expo-document-picker';
import { Image } from 'expo-image';
import * as WebBrowser from 'expo-web-browser';
import React, { useMemo, useState } from 'react';
import { ActivityIndicator, StyleSheet, View, Pressable } from 'react-native';

import { TypingDots } from '../../TypingDots';
import { Hoverable } from '../../hover/Hoverable';

import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { DateTimeType } from '~/api/models/common/types/DateType';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { checkIfImage } from '~/common/commonMethods';
import { mb20, ml5, mr5, p10, ph10 } from '~/common/commonStyles';
import { IconButton } from '~/components/buttons/IconButton';
import {
  ExtraSmallNsRegularDanger,
  ExtraSmallNsRegularInfo,
  ExtraSmallNsRegularPlaceholder,
  SmallNsRegularBlack,
} from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { AlertCircle, DownloadIcon, EyeIcon } from '~/components/svgImages';
import fileSystem from '~/integrations/fileSystem';
import { useAppointmentChatContext } from '~/providers/appointment/AppointmentChatContext';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { isNative } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { DependantProfileImageComponent } from '~/components/profile/DependantProfileImageComponent';
import { ChatMessageModel } from '~/api/models/consultations/models/ChatMessageModel';
import { getInitials } from '~/utils/personalDetailsUtils';

interface Props {
  model?: ChatMessageModel;
  message?: string;
  media?: MediaModel[];
  documentResult?: DocumentPickerAsset;
  typing?: boolean;
  isSender?: boolean;
  sender?: AccountModel;
  time?: DateTimeType;
  sending?: boolean;
  error?: ErrorResponse;
  localId?: number;
}

export const ChatMessageBubble: React.FC<Props> = ({
  model,
  message,
  media,
  sender,
  isSender,
  time,
  typing,
  documentResult,
  sending,
  error,
  localId,
}) => {
  const [hovering, setHovering] = useState(false);
  const { retrySend } = useAppointmentChatContext();
  const { openModal } = useModalManager();

  const download = () => {
    fileSystem.downloadMedia(media[0]).catch(ErrorAlert);
  };
  const fullScreen = () => {
    openModal(ModalName.ImagePreview, {
      image: media[0],
      account: sender,
    });
  };

  const preview = async (media: MediaModel) => {
    try {
      await WebBrowser.openBrowserAsync(media.url);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const mediaIsImage = useMemo(() => {
    if (media?.length) return checkIfImage(media[0].extension);
    if (documentResult) {
      const fileName = documentResult.name || documentResult.file?.name || documentResult.uri;
      if (!fileName) return false;
      const extension = fileName.slice(fileName.lastIndexOf('.') + 1);
      return checkIfImage(extension);
    }
  }, [media, documentResult]);

  const mediaUri = useMemo(() => {
    if (media?.length) {
      return media[0].conversions?.avatar || media[0].url;
    } else if (documentResult) {
      return documentResult.uri;
    }
  }, [media, documentResult]);

  const mediaName = useMemo(() => (media?.length ? media[0].name || media[0].file_name : ''), [media]);

  const dependantMedia = useMemo(() => model?.dependant_profile_image, [model]);
  const dependantInitials = useMemo(() => {
    if (model?.dependant_first_name && model?.dependant_last_name) {
      return getInitials({ first_name: model.dependant_first_name, last_name: model.dependant_last_name });
    }
    return undefined;
  }, [model]);

  const content = (
    <View style={[styles.container, isSender ? styles.containerSender : null]}>
      <View style={typing || (sending && !error?.message) ? styles.profileTyping : styles.profile}>
        {sending ? (
          !error ? (
            <View style={[styles.sendingBubble, styles.sending]}>
              <ActivityIndicator size={22} color={colors.white} />
            </View>
          ) : (
            <View style={[styles.sendingBubble, styles.sendingError]}>
              <AlertCircle width={22} height={22} color={colors.danger} />
            </View>
          )
        ) : (
          <DependantProfileImageComponent
            account={sender}
            dependant={dependantMedia}
            dependantInitials={dependantInitials}
          />
        )}
      </View>
      <View
        style={[
          styles.bubbleContainer,
          isSender ? styles.senderBubbleContainer : styles.otherBubbleContainer,
          sending ? styles.sending : null,
        ]}>
        {mediaUri ? (
          <Hoverable onHoverIn={() => setHovering(true)} onHoverOut={() => setHovering(false)}>
            <View style={styles.imageContainer}>
              <Image source={{ uri: mediaUri }} contentFit="cover" style={[styles.absolute]} />
              {(hovering || !mediaIsImage || isNative()) && !sending ? (
                <View style={[styles.absolute, styles.imageActionsContainer]}>
                  {!mediaIsImage && mediaName ? (
                    <View style={[p10]}>
                      <ExtraSmallNsRegularInfo>{mediaName}</ExtraSmallNsRegularInfo>
                    </View>
                  ) : null}
                  <View style={styles.imageActions}>
                    <IconButton transparent style={mr5} onPress={download}>
                      <DownloadIcon width={20} height={20} color={colors.purple} />
                    </IconButton>

                    <IconButton
                      transparent
                      style={ml5}
                      onPress={() => (mediaIsImage ? fullScreen() : preview(media[0]))}>
                      <EyeIcon width={20} height={20} color={colors.purple} />
                    </IconButton>
                  </View>
                </View>
              ) : null}
            </View>
          </Hoverable>
        ) : (
          <View style={[styles.bubbleView, isSender ? styles.senderBubbleView : styles.otherBubbleView]}>
            {typing ? (
              <View style={ph10}>
                <TypingDots />
              </View>
            ) : (
              <SmallNsRegularBlack style={[isSender ? styles.senderBubbleText : styles.otherBubbleText]}>
                {message}
              </SmallNsRegularBlack>
            )}
          </View>
        )}

        {!typing && !sending ? (
          <View>
            <ExtraSmallNsRegularPlaceholder>
              {parseDateTime(time, { outputFormat: TIME_FORMAT })}
            </ExtraSmallNsRegularPlaceholder>
          </View>
        ) : null}
        {sending && error ? (
          <View>
            <ExtraSmallNsRegularDanger>Error sending. Tap to try again</ExtraSmallNsRegularDanger>
          </View>
        ) : null}
      </View>
    </View>
  );

  if (sending && error) {
    return <Pressable onPress={() => retrySend(localId)}>{content}</Pressable>;
  }
  return content;
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: 15,
  },
  containerSender: {
    flexDirection: 'row-reverse',
  },
  profile: {
    marginBottom: 28,
  },
  profileTyping: {
    marginBottom: 14,
  },
  bubbleContainer: {
    flex: 1,
    display: 'flex',
    marginBottom: 10,
    maxWidth: '75%',
  },
  bubbleView: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    marginBottom: 3,
  },
  senderBubbleContainer: {
    marginRight: 15,
    alignItems: 'flex-end',
  },
  senderBubbleView: {
    backgroundColor: colors.purple,
    borderBottomRightRadius: 0,
  },
  senderBubbleText: {
    color: colors.white,
  },
  otherBubbleContainer: {
    marginLeft: 15,
    alignItems: 'flex-start',
  },
  otherBubbleView: {
    backgroundColor: colors.lightPurple,
    borderBottomLeftRadius: 0,
  },
  otherBubbleText: {
    color: colors.black,
  },
  imageContainer: {
    maxWidth: 200,
    maxHeight: 200,
    marginBottom: 3,
    borderRadius: 6,
    overflow: 'hidden',
    width: '100%',
    height: 150,
    position: 'relative',
    backgroundColor: colors.lightPurple,
  },
  absolute: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  imageActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageActionsContainer: {
    backgroundColor: '#F5F6FA88',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sendingBubble: {
    borderRadius: 8,
    width: 36,
    height: 36,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: colors.purple,
  },
  sendingError: {
    backgroundColor: colors.lightPurple,
  },
  sending: {
    opacity: 0.5,
  },
});
