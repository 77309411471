import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { AppointmentDashboardListing } from './AppointmentDashboardListing';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { labels } from '~/utils/labels';

interface Props {
  style?: StyleProp<ViewStyle>;
}

export const AwaitingConfirmationAppointments: React.FC<Props> = ({ style }) => {
  return (
    <AppointmentDashboardListing
      title={labels.awaitingConfirmation}
      states={[ConsultationStateEnum.RequestSubmitted]}
      style={style}
      hideArrow
      hideIndicator
    />
  );
};
