import React from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { ChannelTypeFilterComponent } from './ChannelTypeFilterComponent';
import { ConsultationTypeFilterComponent } from './ConsultationTypeFilterComponent';
import { StateTypeFilterComponent } from './StateTypeFilterComponent';

import { mb10 } from '~/common/commonStyles';
import { H3TtmSemiBoldBlack, H5TtmSemiBoldBlack, SmallNsRegularDisableColor } from '~/components/commonText';
import { useAppointmentListingContext } from '~/providers/appointment/AppointmentListingContext';

interface Props {
  largeHeader?: boolean;
}
export const AppointmentListFilterComponent: React.FC<Props> = ({ largeHeader }) => {
  const { filterControl } = useAppointmentListingContext();

  return (
    <View>
      <View style={mb10}>
        {largeHeader ? (
          <H3TtmSemiBoldBlack>Filter</H3TtmSemiBoldBlack>
        ) : (
          <H5TtmSemiBoldBlack>Filter</H5TtmSemiBoldBlack>
        )}
      </View>

      <SmallNsRegularDisableColor>Type of consultation</SmallNsRegularDisableColor>
      <View style={mb10}>
        <Controller
          control={filterControl}
          name="filter.type"
          render={({ field: { value, onChange } }) => (
            <ConsultationTypeFilterComponent value={value} setValue={onChange} />
          )}
        />
      </View>

      <SmallNsRegularDisableColor>Channels used for consultation</SmallNsRegularDisableColor>
      <View style={mb10}>
        <Controller
          control={filterControl}
          name="filter.channel_id"
          render={({ field: { value, onChange } }) => <ChannelTypeFilterComponent value={value} setValue={onChange} />}
        />
      </View>

      <SmallNsRegularDisableColor>Status</SmallNsRegularDisableColor>

      <Controller
        control={filterControl}
        name="filter.state"
        render={({ field: { value, onChange } }) => {
          return (
            <StateTypeFilterComponent
              value={value[value.current]}
              onChange={(v) => {
                onChange({
                  ...value,
                  [value.current]: v,
                });
              }}
            />
          );
        }}
      />
    </View>
  );
};
