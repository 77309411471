import React, { useEffect, useState } from 'react';

import { ActivityDataModel } from '~/api/models/audit/models/ActivityDataModel';
import { PaginationMetaModel } from '~/api/models/common/models/PaginationMetaModel';
import { apiGetHealthProfileAuditLogs } from '~/api/services/healthProfile';
import { AuditTable } from '~/components/audit/AuditTable';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';
import { ErrorAlert } from '~/components/modals/ErrorAlert';

interface Props {
  id: number;
  setAuditLogsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HealthProfileAuditLog: React.FC<Props> = ({ id, setAuditLogsLoading }) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationMetaModel>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [auditLogs, setAuditLogs] = useState<ActivityDataModel[]>([]);

  const pageChanged = (pageInfo: TablePaginationInfo) => {
    setCurrentPage(pageInfo.page);
  };

  const getAuditLogs = () => {
    setAuditLogsLoading(true);
    setLoading(true);
    apiGetHealthProfileAuditLogs({ id, req: { page: currentPage, limit: 6, sort: '-created_at' } })
      .then((res) => {
        setPagination(res.data.meta);
        setAuditLogs(res.data.data);
      })
      .catch(ErrorAlert)
      .finally(() => {
        setLoading(false);
        setAuditLogsLoading(false);
      });
  };

  useEffect(() => {
    if (currentPage) getAuditLogs();
  }, [currentPage]);

  return <AuditTable data={auditLogs} pagination={pagination} onPageChanged={pageChanged} loading={loading} />;
};
