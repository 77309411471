import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { MediaStatusEnum } from '~/api/models/common/constants/MediaStatusEnum';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { InprogressAlert } from '~/common/commonMethods';
import { flex1, mr10 } from '~/common/commonStyles';
import { IconButton } from '~/components/buttons/IconButton';
import { H6NsSemiBoldTheme } from '~/components/commonText';
import { LoadingFetchingList } from '~/components/loading/LoadingFetchingList';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { DownloadIcon, EyeIcon } from '~/components/svgImages';
import fileSystem from '~/integrations/fileSystem';
import { checkIsDigimedFile } from '~/utils/checkIsDigimedFile';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useOpenUrl } from '~/utils/hooks/OpenUrlHook';

interface Props {
  value?: MediaModel;
  title?: string;
  style?: StyleProp<ViewStyle>;
  status?: MediaStatusEnum;
  getMediaModel?: () => Promise<MediaModel>;
  onView?: () => void;
  onDownload?: () => void;
  disableView?: boolean;
  withBottomBorder?: boolean;
  removeIconBorder?: boolean;
  showTitle?: boolean;
  size?: number;
  disabled?: boolean;
}

export const ConsultationViewItem: React.FC<Props> = ({
  title,
  value,
  style,
  status,
  getMediaModel,
  onView,
  onDownload,
  disableView = false,
  withBottomBorder = true,
  removeIconBorder = false,
  showTitle = true,
  size = 20,
  disabled,
}) => {
  const { isMobile } = useBreakpoints();
  const { openUrl } = useOpenUrl();

  const tryGetMediaModel = async () => {
    if (typeof getMediaModel !== 'function') throw new Error('Unable to retrieve the media file at this time.');
    return await getMediaModel();
  };

  const handleViewAsync = async () => {
    if (onView) {
      onView();
      return;
    }
    try {
      let close = () => {};
      let mediaModel = value;
      if (!value) {
        close = InprogressAlert(['Please wait while we redirect you to your document']);
        mediaModel = await tryGetMediaModel();
      }
      await checkIsDigimedFile(mediaModel);
      await openUrl(mediaModel.url);
      close();
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const handleDownloadAsync = async () => {
    if (onDownload) {
      onDownload();
      return;
    }
    try {
      let mediaModel = value;
      if (!value) mediaModel = await tryGetMediaModel();
      await checkIsDigimedFile(mediaModel);
      await fileSystem.downloadMedia(mediaModel);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const fileName = useMemo(() => {
    if (title) return title;
    if (value) {
      if (value.extension && value.file_name.endsWith(value.extension)) {
        return value?.file_name.slice(0, value.file_name?.length - (value.extension?.length ?? 0) - 1);
      }
      return value.file_name;
    }
  }, [value]);

  return (
    <View
      style={[
        withBottomBorder ? { borderBottomWidth: 1 } : null,
        styles.content,
        isMobile ? styles.contentMobile : null,
        style,
      ]}>
      {showTitle ? <H6NsSemiBoldTheme style={[flex1, mr10]}>{fileName}</H6NsSemiBoldTheme> : null}
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        {status === MediaStatusEnum.COMPLETED || !status ? (
          <>
            {disableView === false ? (
              <IconButton onPress={handleViewAsync} style={{ marginRight: 5 }} transparent={removeIconBorder}>
                <EyeIcon width={size} height={size} color={colors.purple} />
              </IconButton>
            ) : null}
            <IconButton onPress={handleDownloadAsync} transparent={removeIconBorder} disabled={disabled}>
              <DownloadIcon width={size} height={size} color={colors.purple} />
            </IconButton>
          </>
        ) : status === MediaStatusEnum.PENDING || status === MediaStatusEnum.PROCESSING ? (
          <LoadingFetchingList />
        ) : status === MediaStatusEnum.FAILED ? (
          <StatusBadge label={status} color={colors.danger} textStyle={{ textTransform: 'capitalize' }} />
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 18,
    paddingHorizontal: 10,
    borderBottomColor: colors.lightPurple2,
    borderStyle: 'solid',
  },
  contentMobile: {
    paddingHorizontal: 0,
  },
});
