import React from 'react';
import { StyleSheet, View } from 'react-native';

import { CalendarViewEnum } from '../../../constants/calendarEnums';
import { colors } from '../../../utils/colors';

import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  title?: string;
  children: React.ReactNode;
  view?: CalendarViewEnum;
  footer?: React.ReactNode;
}

export const CalendarLayout: React.FC<Props> = ({ title, children, footer }) => {
  const { isMobile } = useBreakpoints();
  return (
    <View style={[styles.container, isMobile ? { paddingHorizontal: MOBILE_HORIZONTAL_SPACE } : null]}>
      {children}
      {footer ? <View style={styles.footer}>{footer}</View> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
    display: 'flex',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  footer: {
    marginHorizontal: -30,
    paddingHorizontal: 30,
    backgroundColor: colors.white,

    shadowColor: colors.white,
    shadowOffset: {
      width: 0,
      height: -15,
    },
    shadowOpacity: 1,
    shadowRadius: 30,

    elevation: 4,
    paddingVertical: 10,
  },
});
