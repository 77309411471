import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useUserDetailsInfo } from '~/api/hooks/accounts/UserDetails';
import { flex1 } from '~/common/commonStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { Person } from '~/components/svgImages';
import { isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { screenName } from '~/utils/screenName';

interface Props {
  expanded: boolean;
}
export const ProfileItem: React.FC<Props> = ({ expanded }) => {
  const userDetailsInfo = useUserDetailsInfo();
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      {userDetailsInfo.userDetails ? (
        <View style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate(screenName.ProfileScreen as never);
            }}
            style={flex1}>
            <View style={[styles.profile, { alignItems: 'center' }]}>
              <ProfileImageComponent
                account={userDetailsInfo.userDetails?.account}
                size={20}
                fallback={<Person height={20} width={20} color={colors.purple} />}
              />

              {expanded ? (
                <View style={styles.nameContainer}>
                  {isDoctorVersion() && userDetailsInfo.userDetails.account.title ? (
                    <View style={{ width: 'auto' }}>
                      <H6NsRegularBlack style={[styles.name]}>
                        {userDetailsInfo.userDetails.account.title}.{' '}
                      </H6NsRegularBlack>
                    </View>
                  ) : null}

                  <View style={[{ maxWidth: '100%', paddingRight: 10 }]}>
                    <H6NsRegularBlack numberOfLines={2} style={[styles.name]}>
                      {userDetailsInfo.userDetails.account.first_name} {userDetailsInfo.userDetails.account.last_name}
                    </H6NsRegularBlack>
                  </View>
                </View>
              ) : null}
            </View>
          </TouchableOpacity>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  profile: {
    flexDirection: 'row',
    marginRight: 16,
    marginLeft: 18,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 15,
    flex: 1,
  },
  name: {
    fontWeight: 'bold',
    color: colors.purple,
  },
  arrow: {
    height: 15,
    width: 15,
  },
  text: {
    color: 'red',
  },
});
