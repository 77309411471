import React from 'react';
import { View } from 'react-native';
import WebView from 'react-native-webview';
import { WebViewErrorEvent, WebViewNavigationEvent } from 'react-native-webview/lib/WebViewTypes';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';

interface Props extends BaseModalProps {
  uri: string;
  onLoadStart?: (ev: WebViewNavigationEvent) => void;
  onLoadEnd?: (ev: WebViewNavigationEvent | WebViewErrorEvent) => void;
}

export const WebViewModal: React.FC<Props> = ({ uri, onHide, onLoadEnd, onLoadStart }) => {
  return (
    <ModalContainer noScroll edge fullScreen onHide={onHide} hideOnBackground showInfoLine>
      <View style={{ width: '100%', height: '100%', backgroundColor: 'blue' }}>
        <WebView
          allowFileAccess
          style={{ width: '100%', height: '100%' }}
          source={{ uri }}
          onLoadEnd={onLoadEnd}
          onLoadStart={onLoadStart}
        />
      </View>
    </ModalContainer>
  );
};
