import Axios from 'axios';
import Constants from 'expo-constants';
import QueryString from 'qs';

const BaseUrl = Constants.expoConfig.extra.baseUrl;

const instance = Axios.create({
  baseURL: BaseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: (params) => QueryString.stringify(params),
});

export const axiosClient = instance;
