import React, { useEffect, useRef } from 'react';
import { Animated, StyleProp, ViewStyle } from 'react-native';

import { Dot } from './svgImages';

import { isWeb } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';

interface Props {
  animatedValue: Animated.Value;
  delay: number;
  style?: StyleProp<ViewStyle>;
}

export const FadingDot: React.FC<Props> = ({ animatedValue, delay, style }) => {
  const springValue = useRef(new Animated.Value(0)).current;

  const fadeInAndOut = Animated.sequence([
    Animated.timing(animatedValue, {
      delay,
      toValue: 1,
      duration: 250,
      useNativeDriver: !isWeb(),
    }),
    Animated.timing(animatedValue, {
      toValue: 0,
      duration: 250,
      useNativeDriver: !isWeb(),
    }),
  ]);

  useEffect(() => {
    Animated.loop(
      Animated.parallel([
        fadeInAndOut,
        Animated.timing(springValue, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: !isWeb(),
        }),
      ])
    ).start();
  }, []);

  return (
    <Animated.View
      style={[
        {
          opacity: animatedValue,
        },
        style,
      ]}>
      <Dot width={8} height={8} color={colors.purple} />
    </Animated.View>
  );
};
