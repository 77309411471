import React from 'react';
import { StyleProp, ViewStyle, View } from 'react-native';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { Button, OutlineButton } from '~/components/commonButton';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { labels } from '~/utils/labels';

interface Props {
  consultation: ConsultationModel;
  style?: StyleProp<ViewStyle>;
}

export const DoctorJoinConsultationButton: React.FC<Props> = ({ consultation, style }) => {
  const { isMobile } = useBreakpoints();

  const { showDetails } = useShowAppointment(consultation);

  const onPress = () => {
    showDetails({ consultation });
  };

  return (
    <View>
      {!isMobile ? (
        <Button style={style} label={labels.join} funCallback={onPress} />
      ) : (
        <OutlineButton style={style} label={labels.join} funCallback={onPress} />
      )}
    </View>
  );
};
