import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import {
  SmallNsRegularBlack,
  SmallNsRegularDisableColor,
  SmallNsSemiBoldBlack,
  SmallNsSemiBoldInfo,
} from '~/components/commonText';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { FULL_DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { labels } from '~/utils/labels';
import { getAccountName, getAccountPhone } from '~/utils/personalDetailsUtils';

interface Props {
  consultation?: ConsultationModel;
  extraDetails?: {
    label: string;
    value: string;
  }[];
}

export const RequestConsultationDetails: React.FC<Props> = ({ consultation, extraDetails }) => {
  const isOnDemand = useMemo(() => consultation?.type === ConsultationTypeEnum.ON_DEMAND, [consultation]);
  const requestDate = useMemo(() => {
    if (consultation?.type === ConsultationTypeEnum.HOME_VISIT && consultation.patient_availabilities.length) {
      const firstAvailability = consultation.patient_availabilities[0];
      return parseDateTime(firstAvailability.from, { outputFormat: FULL_DATE_FORMAT });
    }
  }, [consultation]);

  return (
    <Grid>
      <Container>
        <Row style={styles.row}>
          <Column style={styles.title}>
            <SmallNsRegularDisableColor>{labels.patientName}</SmallNsRegularDisableColor>
          </Column>
          <Column style={styles.details}>
            <SmallNsSemiBoldBlack>{getAccountName(consultation?.patient)}</SmallNsSemiBoldBlack>
          </Column>
        </Row>
        <Row style={styles.row}>
          <Column style={styles.title}>
            <SmallNsRegularDisableColor>{labels.patientID}</SmallNsRegularDisableColor>
          </Column>
          <Column style={styles.details}>
            <SmallNsRegularBlack>
              {consultation?.patient?.national_id_number ?? consultation?.patient?.passport_number}
            </SmallNsRegularBlack>
          </Column>
        </Row>
        <Row style={styles.row}>
          <Column style={styles.title}>
            <SmallNsRegularDisableColor>{labels.phone}</SmallNsRegularDisableColor>
          </Column>
          <Column style={styles.details}>
            <SmallNsSemiBoldInfo>{getAccountPhone(consultation?.patient)}</SmallNsSemiBoldInfo>
          </Column>
        </Row>
        <Row style={styles.row}>
          <Column style={styles.title}>
            <SmallNsRegularDisableColor>{labels.sickLeaveCertificateRequired}</SmallNsRegularDisableColor>
          </Column>
          <Column style={styles.details}>
            <SmallNsRegularBlack>{consultation?.is_sick_leave_certificate_required ? 'Yes' : 'No'}</SmallNsRegularBlack>
          </Column>
        </Row>
        {isOnDemand ? null : (
          <>
            <Row style={styles.row}>
              <Column style={styles.title}>
                <SmallNsRegularDisableColor>{labels.address}</SmallNsRegularDisableColor>
              </Column>
              <Column style={styles.details}>
                <SmallNsRegularBlack>{consultation?.patient_location?.formatted_address}</SmallNsRegularBlack>
              </Column>
            </Row>
            <Row style={styles.row}>
              <Column style={styles.title}>
                <SmallNsRegularDisableColor>{labels.date}</SmallNsRegularDisableColor>
              </Column>
              <Column style={styles.details}>
                <SmallNsSemiBoldBlack>{requestDate}</SmallNsSemiBoldBlack>
              </Column>
            </Row>
          </>
        )}

        {extraDetails
          ?.filter((item) => !!item?.value)
          .map((item, index) => (
            <Row style={styles.row} key={`${item.label}-${index}`}>
              <Column style={styles.title}>
                <SmallNsRegularDisableColor>{item.label}</SmallNsRegularDisableColor>
              </Column>
              <Column style={styles.details}>
                <SmallNsSemiBoldBlack>{item.value}</SmallNsSemiBoldBlack>
              </Column>
            </Row>
          ))}
      </Container>
    </Grid>
  );
};

const styles = StyleSheet.create({
  title: {
    flex: 1,
  },
  details: {
    flex: 2,
  },
  row: {
    marginBottom: 8,
  },
});
