import { OTError, Subscriber, Stream } from '@opentok/client';
import React, { useEffect, useRef, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { useOpenTokSession } from './OTSession';
import { OTSubscriberEvents } from '../types/OTEvents';

import log from '~/utils/logger';

interface Props {
  style?: StyleProp<ViewStyle>;
  eventHandlers?: OTSubscriberEvents;
  nativeID?: string;
}

export const OTSubscriber: React.FC<Props> = ({ nativeID = 'ot-subscriber', style, eventHandlers }) => {
  const subscriber = useRef<Subscriber>();
  const [subscriberMuted, setSubscriberMuted] = useState<boolean>();
  const { session } = useOpenTokSession();
  const streamsRef = useRef<{ [streamId: string]: Stream }>({});
  const subscriberEventsRef = useRef<Omit<OTSubscriberEvents, 'error'>>();

  const tryHandleError = (e: OTError) => {
    if (eventHandlers?.error) eventHandlers.error(e);
    else log.error(e);
  };

  useEffect(() => {
    if (subscriberMuted) {
      subscriber.current?.setStyle('audioLevelDisplayMode', 'off');
    } else {
      subscriber.current?.setStyle('audioLevelDisplayMode', 'on');
    }
  }, [subscriberMuted]);

  useEffect(() => {
    if (!session) return;

    // Subscribe to a newly created stream
    session.on('streamCreated', (event) => {
      if (streamsRef.current[event.stream.streamId]) return;
      streamsRef.current = { ...streamsRef.current, [event.stream.streamId]: event.stream };

      const sessionSubscriber = session.subscribe(
        event.stream,
        nativeID,
        {
          fitMode: 'contain',
          insertMode: 'append',
          width: '100%',
          height: '100%',
          style: {
            buttonDisplayMode: 'off',
          },
        },

        (e?: OTError) => {
          if (e) {
            tryHandleError(e);
          }
        }
      );

      subscriberEventsRef.current = {
        connected: eventHandlers?.connected,
        disconnected: eventHandlers?.disconnected,
        videoDisabled: eventHandlers?.videoDisabled,
        videoEnabled: eventHandlers?.videoEnabled,
        videoDisableWarning: eventHandlers?.videoDisableWarning,
        videoDisableWarningLifted: eventHandlers?.videoDisableWarningLifted,
      };
      sessionSubscriber.on(subscriberEventsRef.current);
      subscriber.current = sessionSubscriber;
    });

    session.on('streamDestroyed', (event) => {
      if (event.stream.streamId) {
        const currentStreams = { ...streamsRef.current };
        delete currentStreams[event.stream.streamId];
        streamsRef.current = currentStreams;
      }
    });

    session.on('streamPropertyChanged', (event) => {
      streamsRef.current[event.stream.streamId] = event.stream;

      if (streamsRef.current[session.getSubscribersForStream(event?.stream)[0]?.stream.streamId]?.hasAudio === false) {
        setSubscriberMuted(true);
      } else if (
        streamsRef.current[session.getSubscribersForStream(event?.stream)[0]?.stream.streamId]?.hasAudio === true
      ) {
        setSubscriberMuted(false);
      }
    });

    return () => {
      session?.off('streamCreated streamDestroyed streamPropertyChanged');

      if (subscriberEventsRef.current && subscriber.current) subscriber.current.off(subscriberEventsRef.current);
    };
  }, [session]);

  return <View style={[style]} nativeID={nativeID} />;
};
