export default {
  awaitingApproval: 'Awaiting approval',
  awaitingApprovalMessages: [
    'Our team is currently reviewing your account. Please allow up to 5 working days for us to review your account. You will received a notification once this has been approved.',
    '1. All approved applications are subject to review, from time to time or as may be necessary, by Digimed Medical Committee;',
    '2. Please notify Digimed Medical Committee at your earliest of any changes to the above that may occur.',
  ],
  awaitingVerification: 'Awaiting verification',
  awaitingVerificationMessages: [
    'Your ID card or passport are currently being reviewed. You will be notified once this is verified.',
  ],
  rejectedDoctorTitle: `Doctor's role declined`,
  rejectedPharmacistTitle: `Pharmacist role declined`,
  rejectedPharmacyTitle: `Pharmacy declined`,
  rejectedClinicAdminTitle: `Clinic Administrator role declined`,
  rejectedDoctorMessage:
    'Your doctor profile was declined, if you wish to have any further clarification or have any questions do not hesitate to contact us.',
  rejectedPatientTitle: `Patient's role declined`,
  rejectedPatientMessage:
    'Your patient profile was declined, if you wish to have any further clarification or have any questions do not hesitate to contact us.',
  rejectedPharmacistMessage:
    'Your pharmacist profile was declined, if you wish to have any further clarification or have any questions do not hesitate to contact us.',
  rejectedPharmacyMessage:
    'Your linked pharmacy was declined, if you wish to have any further clarification or have any questions do not hesitate to contact us.',
  rejectedClinicAdminMessage:
    'Your clinic admin profile was declined, if you wish to have any further clarification or have any questions do not hesitate to contact us.',
  disabledDoctorTitle: `Doctor's role disabled`,
  disabledPharmacistTitle: `Pharmacist role disabled`,
  disabledPharmacyTitle: `Pharmacy disabled`,
  disabledClinicAdminTitle: `Clinic Administrator role disabled`,
  disabledDoctorMessage:
    'Your doctor profile was disabled, if you wish to have any further clarification or have any questions do not hesitate to contact us.',
  disabledPatientTitle: `Patient's role disabled`,
  disabledPatientMessage:
    'Your patient profile was disabled, if you wish to have any further clarification or have any questions do not hesitate to contact us.',
  disabledPharmacistMessage:
    'Your pharmacist profile was disabled, if you wish to have any further clarification or have any questions do not hesitate to contact us.',
  disabledPharmacyMessage:
    'Your pharmacy is disabled, if you wish to have any further clarification or have any questions do not hesitate to contact us.',
  disabledClinicAdminMessage:
    'Your clinic admin profile was disabled, if you wish to have any further clarification or have any questions do not hesitate to contact us.',
  notificationPendingAdminTitle: 'Identification verified',
  notificationAcceptedTitle: 'Application accepted',
  notificationRejectedTitle: 'Application declined',
  notificationDisabledTitle: 'Account disabled',
  notificationPendingAdminMessage: [
    'Your identity was successfully verified.',
    'Please continue to update you account details!',
  ],
  notificationAcceptedMessage: [
    'You were successfully approved and can start using Digimed applications.',
    'Thank you for being part of Digimed!',
  ],
  notificationRejectedMessage: [
    'Thank you for your interest in joining the Digimed platform. We regret to inform you that your application has been declined.',
    'For any questions or concerns regarding the rejection, please do not hesitate to contact us.',
  ],
  notificationDisabledMessage: [
    'Your account was disabled, if you wish to have any further clarification or have any questions do not hesitate to contact us.',
  ],
};
