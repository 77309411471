import React from 'react';

import { PageTypesEnum } from '~/api/models/pages/constants/PageTypesEnum';
import PolicyContent from '~/components/policies/PolicyContent';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { Column, Container, Row } from '~/theme/components/grid';
import { whenAppType } from '~/utils/buildConfig';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

export const HelpTermsAndConditionsPage: React.FC = () => {
  return (
    <NestedPageInternalLayout backTitle={labels.help} backLink={{ screen: screenName.Help }}>
      <Container fluid>
        <Row justifyContent="center">
          <Column width={800} alignSelf="center">
            <PolicyContent
              contentType={whenAppType({
                whenClinic: PageTypesEnum.CLINIC_TERM_AND_CONDITIONS,
                whenDoctor: PageTypesEnum.DOCTOR_TERM_AND_CONDITIONS,
                whenPatient: PageTypesEnum.PATIENT_TERM_AND_CONDITIONS,
                whenPharmacist: PageTypesEnum.PHARMACY_TERM_AND_CONDITIONS,
              })}
            />
          </Column>
        </Row>
      </Container>
    </NestedPageInternalLayout>
  );
};
