import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationIndexForm } from '~/api/models/consultations/requests/ConsultationsIndexRequest';
import { CheckboxGroup } from '~/components/inputs/CheckboxGroup';
import { AppointmentFilter, AppointmentStateToName } from '~/constants/appointments/appointmentStateMapping';
import { useAppointmentListingContext } from '~/providers/appointment/AppointmentListingContext';

interface Props {
  value: ConsultationStateEnum[];
  onChange: (value: ConsultationStateEnum[]) => void;
}

const AppointmentStateToNameLong = {
  [ConsultationStateEnum.RequestIgnored]: 'Missed request',
  [ConsultationStateEnum.RequestRejected]: 'Declined request',
};

export const StateTypeFilterComponent: React.FC<Props> = ({ value, onChange }) => {
  const { filterControl } = useAppointmentListingContext();

  const {
    filter: { state },
  } = useWatch<ConsultationIndexForm>({ control: filterControl });

  const options = useMemo(() => {
    return (
      Object.keys(AppointmentFilter[state.current].filters).map((key) => ({
        label: AppointmentStateToNameLong[key] ?? AppointmentStateToName[key],
        value: key,
      })) ?? []
    );
  }, [state]);

  return <CheckboxGroup items={options} values={value ?? []} onChange={onChange} />;
};
