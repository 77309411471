import React, { useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { useDependantsHook } from '~/api/hooks/dependants/DependantsHook';
import { DependantStateEnum } from '~/api/models/dependants/constants/DependantStateEnum';
import { SmallNsRegularDisableColor } from '~/components/commonText';
import { CheckboxGroup } from '~/components/inputs/CheckboxGroup';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props {
  style?: StyleProp<ViewStyle>;
  singlePatient?: number;
  value: number[];
  onChange: (value: number[]) => void;
}
export const DoctorListFilterFavouritedByComponent: React.FC<Props> = ({ style, value, singlePatient, onChange }) => {
  const { userDetails } = useUserDetails({ allowStale: true });
  const { dependants } = useDependantsHook({ state: DependantStateEnum.Approved });

  const dependantValues = useMemo(() => {
    if (!userDetails?.account) return [];
    if (singlePatient) {
      if (singlePatient === userDetails?.account.id)
        return [{ label: getAccountName(userDetails.account), value: userDetails.account.id }];

      const dependant = dependants.find((d) => d.account.id === singlePatient);
      if (dependant) {
        return [{ label: getAccountName(dependant.account), value: dependant.account.id }];
      }
    }
    return [
      { label: getAccountName(userDetails?.account), value: userDetails?.account.id },
      ...(dependants?.map((d) => ({ label: getAccountName(d.account), value: d.account.id })) ?? []),
    ];
  }, [dependants, userDetails]);

  return (
    <View style={style}>
      <SmallNsRegularDisableColor>Favourited by</SmallNsRegularDisableColor>
      <View>
        <CheckboxGroup items={dependantValues ?? []} values={value ?? []} onChange={onChange} />
      </View>
    </View>
  );
};
