import React, { useState } from 'react';

import { SuccessAlert } from '~/common/commonMethods';
import { INavigate } from '~/common/types/navigation/navigate';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { PatientProsthesis } from '~/components/doctor/patients/healthProfile/PatientProsthesis';
import { GridLoadingWrapper } from '~/components/loading/GridLoadingWrapper';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { HealthProfileProsthesisProvider } from '~/providers/healthProfile/HealthProfileProsthesisProvider';
import { Container, Grid } from '~/theme/components/grid';
import { labels } from '~/utils/labels';

interface Props {
  edit?: boolean;
  backLink: INavigate;
}

export const HealthProfileProsthesisMobile: React.FC<Props> = ({ edit, backLink }) => {
  const { submit, loading, waiting, reset } = useHealthProfileDataContext();

  const [editing, setEditing] = useState(edit);

  // Buttons to edit cancel and save
  const footer = (
    <CancelContinueFooter
      waiting={waiting}
      onCancel={() => {
        reset();
        setEditing(false);
      }}
      onConfirm={() => {
        if (!editing) {
          setEditing(true);
        } else {
          submit().then(() => setEditing(false));
        }
      }}
      hideCancel={!editing}
      confirmTitle={editing ? labels.update : labels.edit}
    />
  );

  return (
    <Grid>
      <Container style={{ height: '100%' }}>
        <GridLoadingWrapper loading={loading}>
          <PatientsLayout hideHeaderRight footer={footer} flexContent={false} back={{ to: backLink }}>
            <HealthProfileProsthesisProvider>
              <PatientProsthesis locked={!editing} />
            </HealthProfileProsthesisProvider>
          </PatientsLayout>
        </GridLoadingWrapper>
      </Container>
    </Grid>
  );
};
