import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  TouchableWithoutFeedback,
  Platform,
  Modal,
  View,
  StyleProp,
  ViewStyle,
  StyleSheet,
  Animated,
  KeyboardAvoidingView,
  ScrollView,
} from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import { useSafeAreaFrame, useSafeAreaInsets } from 'react-native-safe-area-context';

import { modalStyles } from './ModalStyling';

import { flex1, p20 } from '~/common/commonStyles';
import { DefaultPaperTheme } from '~/theme/paper/DefaultPaperTheme';
import { isWeb } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

const HORIZONTAL_PADDING_LG = 90;
const HORIZONTAL_PADDING_MD = 40;
const HORIZONTAL_PADDING_SM = 20;
const VERTICAL_PADDING = 40;
const MOBILE_VERTICAL_PADDING = 30;
const SCROLL_PADDING = 20;
const SHADOW_HEIGHT = 2;
const BUTTON_HEIGHT = 50;

export enum ModalPaddingEnum {
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
}

export interface ModalContainerProps {
  animationType?: 'none' | 'fade' | 'slide';
  children?: React.ReactNode;
  buttonSizeOverride?: number;
  buttons?: React.ReactNode[];
  buttonsHorizontal?: boolean;
  show?: boolean;
  hideOnBackground?: boolean;
  hideNotch?: boolean;
  onVisibleChanged?: (visible: boolean) => void;
  onHide?: () => void;
  modalStyle?: StyleProp<ViewStyle>;
  centerModal?: boolean;
  fullScreen?: boolean;
  showInfoLine?: boolean;
  wide?: boolean;
  edge?: boolean;
  paddedEdge?: boolean;
  bounces?: boolean;
  noScroll?: boolean;
  padding?: ModalPaddingEnum | 'lg' | 'md' | 'sm';
}
export const ModalContainer: React.FC<ModalContainerProps> = ({
  animationType,
  children,
  buttonSizeOverride,
  buttons,
  buttonsHorizontal,
  show = true,
  hideOnBackground,
  hideNotch,
  modalStyle,
  fullScreen,
  onVisibleChanged,
  onHide,
  centerModal,
  showInfoLine,
  wide,
  edge,
  paddedEdge,
  bounces,
  noScroll,
  padding = ModalPaddingEnum.LG,
}) => {
  const [visible, setVisible] = useState(false);

  const { isMobile } = useBreakpoints();
  const safeArea = useSafeAreaInsets();
  const safeAreaFrame = useSafeAreaFrame();

  const [safeAreaHeight, setSafeAreaHeight] = useState(safeAreaFrame.height);
  const [visibleHeight, setVisibleHeight] = useState(0);
  const [wholeHeight, setWholeHeight] = useState(1);
  const indicator = useRef(new Animated.Value(0, { useNativeDriver: !isWeb() })).current;

  const requestHide = () => {
    setVisible(false);
    if (onVisibleChanged) onVisibleChanged(false);
    if (onHide) onHide();
  };

  useEffect(() => {
    if (show !== visible) {
      setVisible(show);
      if (onVisibleChanged) onVisibleChanged(show);
    }
  }, [show]);

  const calculatedAnimationType = useMemo(
    () => animationType || (Platform.OS === 'web' && !isMobile ? 'fade' : 'slide'),
    [animationType]
  );

  const displayedButtons = useMemo(() => buttons?.filter((button) => button), [buttons]);
  const indicatorSize = useMemo(
    () => (wholeHeight > visibleHeight ? (visibleHeight * visibleHeight) / wholeHeight : visibleHeight),
    [wholeHeight, visibleHeight]
  );
  const difference = useMemo(
    () => (visibleHeight > indicatorSize ? visibleHeight - indicatorSize : 1),
    [visibleHeight, indicatorSize]
  );
  const totalVerticalPadding = useMemo(() => (isMobile ? MOBILE_VERTICAL_PADDING : VERTICAL_PADDING) * 2, [isMobile]);

  const minHeight = useMemo(
    () =>
      fullScreen
        ? safeAreaHeight * 0.9
        : Math.min(
            safeAreaHeight * 0.9,
            wholeHeight +
              SCROLL_PADDING +
              (edge || paddedEdge ? 0 : totalVerticalPadding) +
              (buttonSizeOverride ||
                BUTTON_HEIGHT * (displayedButtons?.length && !buttonsHorizontal ? displayedButtons.length - 1 : 0)) +
              SHADOW_HEIGHT +
              (showInfoLine ? 12 : 0) +
              20
          ),
    [safeAreaHeight, totalVerticalPadding, wholeHeight, buttonsHorizontal, fullScreen]
  );

  const paddings = useMemo(() => {
    if (edge) return { scroll: styles.paddedModal_edge, buttons: styles.buttonPaddedModal_edge };
    if (isMobile || padding === ModalPaddingEnum.SM)
      return {
        scroll: styles.paddedModal_sm,
        buttons: [styles.buttonPaddedModal_sm, fullScreen ? { paddingBottom: safeArea.bottom } : null],
      };

    if (padding === ModalPaddingEnum.MD) return { scroll: styles.paddedModal_md, buttons: styles.buttonPaddedModal_md };
    return { scroll: styles.paddedModal_lg, buttons: styles.buttonPaddedModal_lg };
  }, [edge, isMobile, padding, paddedEdge, safeArea.bottom]);

  const content = (
    <>
      {!hideNotch && calculatedAnimationType === 'slide' ? (
        <View style={[centerModal ? null : (modalStyles.typesOfConsultationsModalTopNotch, { alignSelf: 'center' })]} />
      ) : null}
      <TouchableWithoutFeedback touchSoundDisabled>{children}</TouchableWithoutFeedback>
    </>
  );
  return (
    <Modal
      animationType={calculatedAnimationType}
      transparent
      visible={visible}
      onRequestClose={hideOnBackground ? requestHide : null}>
      <KeyboardAvoidingView
        style={{ display: 'flex', flex: 1 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <TouchableWithoutFeedback
          onPress={() => {
            if (hideOnBackground) requestHide();
          }}>
          <View
            style={[
              modalStyles.modalWrapper,
              calculatedAnimationType !== 'slide' || centerModal ? modalStyles.modalWrapperWeb : null,
            ]}
            onLayout={({
              nativeEvent: {
                layout: { height },
              },
            }) => setSafeAreaHeight(height - (noScroll ? 0 : SCROLL_PADDING))}>
            {/* Touchable used to block presses on the inner view from triggering the hide function */}
            <PaperProvider
              theme={DefaultPaperTheme}
              settings={{
                icon: (props) => (
                  <MaterialCommunityIcons
                    color={props.color}
                    name={props.name as any}
                    size={props.size}
                    testID={props.testID}
                    allowFontScaling={props.allowFontScaling}
                  />
                ),
              }}>
              <View
                style={[
                  flex1,
                  !edge && !paddedEdge ? p20 : null,
                  { display: 'flex', height: '100%' },
                  calculatedAnimationType !== 'slide' || centerModal
                    ? { justifyContent: 'center' }
                    : { justifyContent: 'flex-end' },
                ]}>
                <TouchableWithoutFeedback onPress={() => {}} touchSoundDisabled>
                  <View
                    pointerEvents="auto"
                    style={[
                      modalStyles.modalContentContainer,
                      wide ? { maxWidth: 768 } : null,
                      showInfoLine ? { overflow: 'hidden' } : null,
                      modalStyle,
                      {
                        minHeight,
                        overflow: 'hidden',
                      },
                      isMobile && (fullScreen || edge || paddedEdge) ? styles.fullScreenBorder : null,
                    ]}>
                    {showInfoLine ? <View style={{ width: '100%', height: 12, backgroundColor: colors.info }} /> : null}
                    <View
                      style={[
                        { flex: 1, display: 'flex' },
                        fullScreen && isMobile && !displayedButtons?.length
                          ? [
                              {
                                marginBottom: -safeArea.bottom,
                                paddingBottom: safeArea.bottom + 20,
                              },
                            ]
                          : undefined,
                      ]}>
                      {noScroll ? (
                        <View
                          style={{ flex: 1 }}
                          onLayout={({
                            nativeEvent: {
                              layout: { height },
                            },
                          }) => setVisibleHeight(height)}>
                          {content}
                        </View>
                      ) : (
                        <ScrollView
                          contentContainerStyle={[paddings.scroll, displayedButtons ? { paddingBottom: 20 } : null]}
                          showsVerticalScrollIndicator={false}
                          onContentSizeChange={(width, height) => {
                            setWholeHeight(height - SCROLL_PADDING);
                          }}
                          onLayout={({
                            nativeEvent: {
                              layout: { x, y, width, height },
                            },
                          }) => setVisibleHeight(height - SCROLL_PADDING)}
                          scrollEventThrottle={16}
                          onScroll={(ev) =>
                            Animated.event([{ nativeEvent: { contentOffset: { y: indicator } } }], {
                              useNativeDriver: false,
                            })(ev)
                          }
                          bounces={bounces}>
                          {content}
                        </ScrollView>
                      )}
                      {visibleHeight < wholeHeight ? (
                        <View style={styles.indicatorWrapper}>
                          <Animated.View
                            style={[
                              styles.indicator,
                              {
                                height: indicatorSize,
                                transform: [
                                  {
                                    translateY: Animated.multiply(indicator, visibleHeight / wholeHeight).interpolate({
                                      inputRange: [0, difference],
                                      outputRange: [0, difference],
                                      extrapolate: 'extend',
                                    }),
                                  },
                                ],
                              },
                            ]}
                          />
                        </View>
                      ) : null}
                    </View>

                    {displayedButtons?.length ? (
                      <View
                        style={[
                          styles.buttons,
                          paddings.buttons,
                          buttonsHorizontal ? { display: 'flex', flexDirection: 'row', marginHorizontal: -10 } : null,
                        ]}>
                        {displayedButtons.map(
                          (button, index, arr) => (
                            <Fragment key={index}>
                              {button}
                              {index < arr.length - 1 ? <View style={{ height: 10, width: 20 }} /> : null}
                            </Fragment>
                          )
                          // <View
                          //   key={index}
                          //   style={[buttonsHorizontal && arr.length > 1 ? mh10 : index > 0 ? mt10 : null, { flex: 1 }]}>
                          //   {button}
                          // </View>
                        )}
                      </View>
                    ) : null}
                  </View>
                </TouchableWithoutFeedback>
              </View>
            </PaperProvider>
          </View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  paddedModal_lg: {
    paddingHorizontal: HORIZONTAL_PADDING_LG,
    paddingVertical: VERTICAL_PADDING,
  },
  buttonPaddedModal_lg: {
    paddingHorizontal: HORIZONTAL_PADDING_LG,
  },
  paddedModal_md: {
    paddingHorizontal: HORIZONTAL_PADDING_MD,
    paddingVertical: VERTICAL_PADDING,
  },
  buttonPaddedModal_md: {
    paddingHorizontal: HORIZONTAL_PADDING_MD,
  },
  paddedModal_sm: {
    paddingHorizontal: HORIZONTAL_PADDING_SM,
    paddingVertical: MOBILE_VERTICAL_PADDING,
  },
  buttonPaddedModal_sm: {
    paddingHorizontal: HORIZONTAL_PADDING_SM,
  },
  paddedModal_edge: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  buttonPaddedModal_edge: {
    paddingHorizontal: 0,
  },
  fullScreenBorder: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  buttons: {
    borderTopColor: colors.lightPurple,
    borderTopWidth: 1,
    paddingVertical: 10,
  },
  indicatorWrapper: {
    width: 6,
    position: 'absolute',
    bottom: 4,
    right: 8,
    top: 20,
    overflow: 'hidden',
    borderRadius: 6,
  },
  indicator: {
    backgroundColor: colors.lightPurple,
    width: 8,
    borderRadius: 8,
  },
});
