import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/reducers';
import { UPDATE_PROSTHESIS_LIST } from '../../redux/reducers/consultationDetailsReducer';
import { useAppDispatch } from '../../redux/store';
import { apiGetAllProsthesisImplantsGrafts } from '../services/healthProfile/prosthesis';

export function useProsthesisListing() {
  const prosthesisData = useSelector((state: RootState) => state.consultationDetailsReducer.prosthesisList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!prosthesisData?.length) {
      apiGetAllProsthesisImplantsGrafts().then((res) => {
        dispatch(UPDATE_PROSTHESIS_LIST(res.data));
      });
    }
  }, [prosthesisData]);

  const filteredProsthesisData = useMemo(() => {
    return prosthesisData?.filter((item) => item.name.toLowerCase() !== 'none');
  }, [prosthesisData]);

  return { prosthesisData: filteredProsthesisData };
}
