import { useConsultationHelper } from '../helpers/HelperConsultation';

import { ConsultationEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { isPatientVersion } from '~/utils/buildConfig';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { getAccountName } from '~/utils/personalDetailsUtils';

export const useHandleConsultationDoctorChanged = () => {
  const { showSnackbar } = useSnackbarManager();
  const { getConsultation } = useConsultationHelper();
  const { showDetails: showAppointmentDetails } = useShowAppointment();

  const execute = async (consultationId: number) => {
    const consultation = await getConsultation(consultationId);
    if (!consultation?.clinic) return;

    const message = `${getAccountName(consultation.clinic)} has made changes to your appointment. ${getAccountName(
      consultation.doctor
    )} will now be handling your consultation.`;

    showSnackbar(message, {
      keyRef: `${ConsultationEventsEnum.CONSULTATION_DOCTOR_CHANGED}.${consultationId}`,
      actionTitle:
        consultation.type === ConsultationTypeEnum.ON_DEMAND
          ? 'Click here to go to the appointment.'
          : 'View appointment',
      onPress: () => {
        showAppointmentDetails({
          consultation,
        });
      },
    });
  };

  const emitEvent = (consultationId?: number) => {
    AppEventHandler.emit(AppEvents.PATIENT_APPOINTMENTS_CHANGED, [consultationId]);
  };

  const validate = () => {
    return isPatientVersion();
  };

  const handleNotification = async (consultationId: number) => {
    if (!validate()) return;

    await execute(consultationId);
    emitEvent(consultationId);
  };

  return { handleNotification };
};
