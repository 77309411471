import React from 'react';

import { BillingHistoryProvider } from '../common/billing/contexts/BillingHistoryProvider';
import { PatientBillingHistory } from './PatientBillingHistory';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';

export enum SubscriptionTypeFilterEnum {
  STRIPESUBSCRIPTION = 'stripe-subscription',
}

export type BillingTypeFilterEnum = SubscriptionTypeFilterEnum | ConsultationTypeEnum;

export const PatientBillingHistoryPage: React.FC = () => {
  return (
    <BillingHistoryProvider>
      <PatientBillingHistory />
    </BillingHistoryProvider>
  );
};
