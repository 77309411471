import React, { useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { OutlineButton } from '../commonButton';
import { H3TtmSemiBoldBlack, H6NsSemiBoldTheme } from '../commonText';
import { ErrorAlert } from '../modals/ErrorAlert';
import { ModalContainer } from '../modals/ModalContainer';
import { BaseModalProps } from '../modals/interfaces/ModalProps';
import { SearchBoxRef } from '../searchBox';

import { usePrescriptionSearch } from '~/api/hooks/pharmacy/PharmacyPrescriptionsHook';
import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { BarCodeScanner } from '~/integrations/barcodeScanner';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  onSelect: (prescription: PrescriptionModel) => void;
}

export const PharmacySearchQrCodeModal: React.FC<Props> = ({ onHide, onSelect }) => {
  const { loading, searchBySpecificReference } = usePrescriptionSearch();

  const [noResults, setNoResults] = useState('');
  const [scanned, setScanned] = useState(false);

  const searchBoxRef = useRef<SearchBoxRef>();

  const selectPrescription = (prescription: PrescriptionModel) => {
    onHide();

    if (onSelect) onSelect(prescription);
  };
  const submit = async ({ data }: { type: string; data: string }) => {
    try {
      setScanned(true);
      setNoResults('');
      const res = await searchBySpecificReference({ query: data });

      if (res) {
        selectPrescription(res);
      } else {
        setNoResults(data);
      }
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const resetScanned = () => {
    setScanned(false);
    setNoResults('');
  };

  const buttons = [
    scanned ? <OutlineButton label="Scan again" funCallback={resetScanned} disabled={loading} /> : null,
    <OutlineButton label={labels.close} funCallback={onHide} disabled={loading} />,
  ];

  return (
    <ModalContainer
      hideOnBackground
      fullScreen
      paddedEdge
      onHide={onHide}
      showInfoLine
      buttons={buttons}
      noScroll
      onVisibleChanged={(visible) => {
        if (visible) setTimeout(() => searchBoxRef.current?.focus(), 500);
      }}>
      <View style={{ display: 'flex', height: '100%', padding: 10 }}>
        <H3TtmSemiBoldBlack style={{ marginBottom: 20, textAlign: 'center' }}>
          Search Patients Prescriptions
        </H3TtmSemiBoldBlack>
        <View style={{ flex: 1, width: '100%', position: 'relative' }}>
          <BarCodeScanner onBarCodeScanned={scanned ? undefined : submit} style={StyleSheet.absoluteFillObject} />
        </View>

        <H6NsSemiBoldTheme style={{ paddingTop: 10 }}>
          {loading
            ? 'Searching for prescription'
            : noResults
            ? 'No results found for the scanned code'
            : 'Scan Prescription QR code'}
        </H6NsSemiBoldTheme>
      </View>
    </ModalContainer>
  );
};
