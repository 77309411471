import Constants from 'expo-constants';
import Pusher, { Channel } from 'pusher-js';

import { hasToken } from '~/api/axios/interceptors';
import { apiBroadcastChannelAuth } from '~/api/services/broadcast';

const channelKey: string = Constants.expoConfig.extra.pusher.channelKey;
const channelCluster: string = Constants.expoConfig.extra.pusher.channelCluster;

let pusher: Pusher;

export function initChannels(): Promise<void> {
  return new Promise((resolve, reject) => {
    pusher = new Pusher(channelKey, {
      cluster: channelCluster,
      channelAuthorization: {
        transport: 'ajax',
        endpoint: '/broadcasting/auth',
        customHandler: (params, callback) => {
          hasToken().then((userHasToken) => {
            if (!userHasToken) return callback(new Error('No token present'), null);

            apiBroadcastChannelAuth({ socket_id: params.socketId, channel_name: params.channelName })
              .then((res) => {
                callback(null, res.data);
              })
              .catch((e) => callback(e, null));
          });
        },
      },
    });
    if (!pusher) reject(new Error('Unable to initialise the channel'));
    resolve();
  });
}

export function channelSubscribe(
  channelName: string,
  eventBinding: Function
): Promise<{ channel: Channel; unsubscribe: () => void }> {
  return new Promise((resolve, reject) => {
    if (!pusher) reject(new Error('Pusher channel instance not initialised'));
    const channel = pusher.subscribe(channelName);
    const method = (eventName: string, data: object) => {
      eventBinding(eventName, data);
    };
    channel.bind_global(method);
    resolve({
      channel,
      unsubscribe: () => {
        channel.unbind_global(method);
      },
    });
  });
}

export function channelUnsubscribe(
  channelName: string,
  eventBinding: Function
): Promise<{ channel: Channel; unsubscribe: () => void }> {
  return new Promise((resolve, reject) => {
    if (!pusher) reject(new Error('Pusher channel instance not initialised'));
    pusher.unsubscribe(channelName);
  });
}

export function channelUnsubscribeAll(channelName): Promise<void> {
  return new Promise((resolve) => {
    pusher.unsubscribe(channelName);
    resolve();
  });
}

export function triggerEvent(channelName: string, eventName: string, data?: any): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!pusher) reject(new Error('Pusher channel instance not initialised'));
    const channel = pusher.channel(channelName);
    if (!channel) reject(new Error(`Channel '${channelName}' not found`));
    if (channel.trigger(eventName, data)) {
      resolve();
    } else {
      reject(new Error('Failed to trigger pusher event'));
    }
  });
}
