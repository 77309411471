import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { ConsultationTypeIcon } from '../common/consultations/ConsultationTypeIcon';
import { Button } from '../commonButton';
import { ProfileDetailsComponent } from '../profile/ProfileDetailsComponent';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { CalendarEventModel } from '~/api/models/preferences/models/CalendarEventModel';
import { ml10, textCenter } from '~/common/commonStyles';
import { ExtraSmallNsRegularBlack, H5TtmRegularBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { ForwardArrow } from '~/components/svgImages';
import { ConsultationTypeName } from '~/services/config';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { DATE_FORMAT, parseDateTime } from '~/utils/dateAndTime';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';

interface Props {
  calendarEvent?: CalendarEventModel;
  consultation?: ConsultationModel;
  noHorizontalPadding?: boolean;
  showStartDate?: boolean;
}

const ConsultationAgendaItemLarge: React.FC<Props> = ({
  consultation,
  calendarEvent,
  noHorizontalPadding,
  showStartDate,
}) => {
  const currentConsultation = useMemo(
    () => consultation || calendarEvent?.eventable,
    [consultation, calendarEvent?.eventable]
  );
  const { showDetails } = useShowAppointment(currentConsultation);

  const startedAt = useMemo(
    () =>
      showStartDate && currentConsultation?.started_at
        ? parseDateTime(currentConsultation.started_at, { outputFormat: DATE_FORMAT })
        : null,
    [currentConsultation, showStartDate]
  );

  const isOngoing = useMemo(
    () => [ConsultationStateEnum.Started, ConsultationStateEnum.Ended].includes(currentConsultation.state),
    [currentConsultation?.state]
  );
  const isEnded = useMemo(
    () => currentConsultation?.state === ConsultationStateEnum.Ended,
    [currentConsultation?.state]
  );

  const isStarted = useMemo(
    () => currentConsultation?.state === ConsultationStateEnum.Started,
    [currentConsultation?.state]
  );

  const onPress = () => {
    if (currentConsultation)
      showDetails({
        consultation: currentConsultation,
      });
  };

  const isWaitingForDoctor = useMemo(
    () =>
      consultation?.type === ConsultationTypeEnum.ON_DEMAND &&
      consultation?.state === ConsultationStateEnum.Scheduled &&
      !!consultation?.doctor,
    [consultation]
  );

  return (
    <TouchableOpacity onPress={onPress}>
      <Grid grid={{ gutters: 5 }}>
        <Container fluid>
          <View style={[styles.container, isOngoing ? styles.itemOngoing : null]}>
            <Row style={[styles.item, noHorizontalPadding ? null : styles.itemPadding]}>
              <View style={[styles.verticalBar, isOngoing ? styles.verticalBarOngoing : null]} />
              <Column alignSelf="center" width={220}>
                <ProfileDetailsComponent account={currentConsultation?.patient} style={ml10} />
              </Column>
              <Column alignSelf="center">
                <H5TtmRegularBlack>
                  {startedAt ? startedAt : ConsultationTypeName[currentConsultation?.type]}
                </H5TtmRegularBlack>
                <ConsultationTypeIcon
                  consultation={currentConsultation}
                  color={isOngoing ? colors.darkWarning : colors.info}
                />
              </Column>
              {isStarted ? (
                <Column alignSelf="center">
                  <View style={{ paddingHorizontal: 10 }}>
                    <Button
                      label="Join"
                      style={{ backgroundColor: colors.darkWarning, width: 100 }}
                      funCallback={onPress}
                    />
                  </View>
                </Column>
              ) : isWaitingForDoctor ? (
                <Column span="auto" alignSelf="center">
                  <ExtraSmallNsRegularBlack style={{ maxWidth: 70, textAlign: 'center' }}>
                    Waiting for Doctor to Join
                  </ExtraSmallNsRegularBlack>
                </Column>
              ) : isEnded ? (
                <Column alignSelf="center">
                  <View>
                    <H6NsSemiBoldBlack style={[{ color: colors.darkWarning }, textCenter]}>
                      Pending Report Submission
                    </H6NsSemiBoldBlack>
                  </View>
                </Column>
              ) : null}
              <Column span="auto" alignSelf="center">
                <ForwardArrow width={15} height={15} color={isOngoing ? colors.darkWarning : colors.info} />
              </Column>
            </Row>
          </View>
        </Container>
      </Grid>
    </TouchableOpacity>
  );
};

export default React.memo(ConsultationAgendaItemLarge);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    backgroundColor: colors.white,
    paddingHorizontal: 8,
    borderRadius: 8,
  },
  itemOngoing: {
    backgroundColor: colors.lightWarning,
  },
  item: {
    minHeight: 75,
    flexGrow: 1,
    justifyContent: 'flex-start',
  },
  itemPadding: {
    paddingHorizontal: 5,
  },
  verticalBar: {
    borderRightColor: colors.info,
    borderRightWidth: 4,
    marginVertical: 5,
    marginRight: 15,
  },
  verticalBarOngoing: {
    borderRightColor: colors.darkWarning,
  },
});
