import { useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { mt10, mv10 } from '../common/commonStyles';
import { H3TtmSemiBoldBlack } from '../components/commonText';
import { Grid } from '../theme/components/grid';
import { colors } from '../utils/colors';
import { labels } from '../utils/labels';

import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { INavigate } from '~/common/types/navigation/navigate';
import { Gradient } from '~/components/common/Gradient';
import { KeyboardAScrollView } from '~/components/commonViews';
import { BackNavigation } from '~/components/navigation/BackNavigation';
import { useBreakpoints } from '~/utils/hooks/GridHook';

interface Props {
  title?: string;
  backTitle?: string;
  backLink?: INavigate;
  children: React.ReactNode;
  footer?: React.ReactNode;
  alwaysShowBack?: boolean;
  style?: StyleProp<ViewStyle>;
  hideGradient?: boolean;
}

export const NestedPageInternalLayout: React.FC<Props> = ({
  title,
  backTitle,
  children,
  footer,
  style,
  alwaysShowBack,
  backLink,
  hideGradient,
}) => {
  const { isMobile } = useBreakpoints();
  const navigation = useNavigation();

  const allowedGoBack = useMemo(() => {
    return backLink?.screen || navigation.canGoBack();
  }, [navigation]);
  const backAndTitle =
    isMobile || alwaysShowBack ? (
      <View>
        {allowedGoBack ? (
          <BackNavigation
            title={backTitle?.toUpperCase() || labels.back.toUpperCase()}
            backLink={backLink}
            style={mt10}
          />
        ) : null}
        {title ? <H3TtmSemiBoldBlack style={allowedGoBack ? mv10 : null}>{title}</H3TtmSemiBoldBlack> : null}
      </View>
    ) : null;

  return (
    <Grid grid={{ noOuterGutter: true }}>
      <View style={[styles.container, style]}>
        <View style={styles.padded}>{backAndTitle}</View>
        <KeyboardAScrollView contentContainerStyle={[styles.scrollView, isMobile ? styles.padded : null]}>
          {children}
        </KeyboardAScrollView>
        {footer ? (
          <>
            <View>{hideGradient ? null : <Gradient />}</View>

            <View style={[{ paddingTop: 5 }, isMobile ? styles.padded : null]}>{footer}</View>
          </>
        ) : null}
      </View>
    </Grid>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
  scrollView: {
    paddingBottom: 15,
  },
  padded: {
    paddingHorizontal: MOBILE_HORIZONTAL_SPACE,
  },
});
