import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { apiGetPrescription } from '~/api/services/consultations/prescriptions';
import { flex1 } from '~/common/commonStyles';
import { DashboardMobileHeader } from '~/components/dashboard/doctor/DashboardHeader/DashboardMobileHeader';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { PrescriptionsTable } from '~/components/prescriptions/PrescriptionsTable';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { RootState } from '~/redux/reducers';
import { CLEAR_NAVIGATION_DATA } from '~/redux/reducers/routeReducer';
import { useAppDispatch } from '~/redux/store';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

export const PatientPrescriptionsScreen: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const { openModal } = useModalManager();
  const dispatch = useAppDispatch();

  const navigationPrescriptionId = useSelector(
    (state: RootState) => state.routeReducer.navigationData?.prescription_id
  );

  useEffect(() => {
    if (navigationPrescriptionId) {
      apiGetPrescription({ prescriptionId: navigationPrescriptionId })
        .then((res) => {
          const prescription = res.data;
          openModal(ModalName.PrescriptionDetailsModal, {
            prescription,
            doctor: prescription.doctor,
            patient: prescription.patient,
          });
        })
        .catch(() => {});

      dispatch(CLEAR_NAVIGATION_DATA());
    }
  }, [navigationPrescriptionId]);

  return (
    <View style={{ flex: 1 }}>
      {isMobile ? (
        <DashboardMobileHeader title={labels.prescriptions} hideImage backgroundColor={colors.white} />
      ) : null}
      <PageLayout
        hideBack
        hideHeaderRight
        hideGradient
        title={isMobile ? null : labels.prescriptions}
        contentStyling={flex1}
        scrollContentNode={<PrescriptionsTable />}
      />
    </View>
  );
};
