import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { useInsuranceDetails } from '../hooks/useInsuranceDetails';

import { CancelContinueFooterArray } from '~/components/buttons/CancelContinueFooterArray';
import { HTMLViewerComponent } from '~/components/commonComponents';
import { WizardLayout } from '~/layouts/WizardLayout';
import { NavType } from '~/navigation/types';
import { useInsuranceClaimFormContext } from '~/providers/insurance/InsuranceClaimFormContext';
import { screenName } from '~/utils/screenName';

export const PatientDeclarationStep: React.FC = () => {
  const { loading, submitClaim } = useInsuranceClaimFormContext();
  const { insuranceDetails } = useInsuranceDetails();
  const { navigate } = useNavigation<NavType>();
  const buttons = CancelContinueFooterArray({
    waiting: loading,
    onCancel: () => navigate(screenName.Insurance, { screen: screenName.InsuranceClaims }),
    onConfirm: submitClaim,
    confirmTitle: 'Agree and Submit',
  });

  return (
    <WizardLayout title="Patient Declaration" buttons={buttons} wide>
      <View style={{ width: '100%' }}>
        <HTMLViewerComponent content={insuranceDetails.insurance_company.patient_declaration} />
      </View>
    </WizardLayout>
  );
};
