import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { Button } from '../commonButton';
import { H3TtmSemiBoldBlack } from '../commonText';
import FloatingInput from '../inputs/FloatingInput';
import { ErrorAlert } from '../modals/ErrorAlert';
import { ModalContainer } from '../modals/ModalContainer';
import { BaseModalProps } from '../modals/interfaces/ModalProps';

import { apiInvitePharmacyUser } from '~/api/services/pharmacy';
import { mb20, textCenter } from '~/common/commonStyles';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { emailValidation, requiredValidation } from '~/services/validationConfig';

interface Form {
  email: string;
}

interface Props extends BaseModalProps {
  onComplete: (options: Form) => void;
}

export const InvitePharmacyUserModal: React.FC<Props> = ({ onHide, onComplete }) => {
  const [loading, setLoading] = useState(false);
  const currentPharmacy = useSelector(currentPharmacySelector);

  const { handleSubmit, control } = useForm<{ email: string }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: Form) => {
    try {
      setLoading(true);
      await apiInvitePharmacyUser({
        email: data.email,
        pharmacy_id: currentPharmacy.pharmacy.id,
      });
      if (onComplete) onComplete(data);
      onHide();
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };
  return (
    <ModalContainer
      onHide={onHide}
      hideOnBackground={!loading}
      showInfoLine
      buttons={[<Button funCallback={handleSubmit(onSubmit)} label="Send invite" disabled={loading} />]}>
      <View>
        <H3TtmSemiBoldBlack style={[mb20, textCenter]}>Add new user</H3TtmSemiBoldBlack>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingInput
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error?.message}
              errorMessage={isSubmitted && error?.message}
              label="Email address"
              showMandatory
            />
          )}
          rules={{
            required: requiredValidation('Email address'),
            validate: {
              email: emailValidation,
            },
          }}
        />
      </View>
    </ModalContainer>
  );
};
