// https://tokbox.com/developer/sdks/windows/reference/namespace_open_tok.html#a593c40ac342238827568a038ac099d94a657e2b48763e25e642476288ff9ce54f
// https://tokbox.com/developer/sdks/ios/reference/Classes/OTError.html

export enum OTSessionErrorCode {
  OTSessionSuccess = 0,
  OTAuthorizationFailure = 1004,
  OTErrorInvalidSession = 1005,
  OTConnectionFailed = 1006,
  OTNullOrInvalidParameter = 1011,
  OTNotConnected = 1010,
  OTSessionIllegalState = 1015,
  OTNoMessagingServer = 1503,
  OTConnectionRefused = 1023,
  OTSessionStateFailed = 1020,
  OTP2PSessionMaxParticipants = 1403,
  OTSessionConnectionTimeout = 1021,
  OTSessionInternalError = 2000,
  OTSessionInvalidSignalType = 1461,
  OTSessionSignalDataTooLong = 1413,
  OTSessionUnableToForceMute = 1540,
  OTConnectionDropped = 1022,
  OTSessionSubscriberNotFound = 1112,
  OTSessionPublisherNotFound = 1113,
  OTSessionBlockedCountry = 1026,
  OTSessionConnectionLimitExceeded = 1027,
  OTSessionDisconnectedInternetDown = 4020,
  OTSessionEncryptionSecretMissing = 6000,
  OTSessionInvalidEncryptionSecret = 6004,
}

export enum OTPublisherErrorCode {
  OTPublisherSuccess = 0,
  OTSessionDisconnected = 1010,
  OTUnableToPublish = 1500,
  OTPublisherInternalError = 2000,
  OTPublishFailed = 2001,
  OTPublisherWebRTCError = 1610,
  OTPublisherEncryptionInternalError = 6001,
}

export enum OTSubscriberErrorCode {
  OTSubscriberSuccess = 0,
  OTConnectionTimedOut = 1542,
  OTSubscriberSessionDisconnected = 1010,
  OTSubscriberWebRTCError = 1600,
  OTSubscriberServerCannotFindStream = 1604,
  OTSubscriberStreamLimitExceeded = 1605,
  OTSubscriberInternalError = 2000,
  OTSubscriberDecryptionInternalError = 6002,
  EncryptionSecretMismatch = 6003,
}
