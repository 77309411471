import { Linking } from 'react-native';

import { ModalAlertTypeEnum } from '../components/modals/ModalEnums';
import { ModalFunctionReturn, ModalOverlayProperties } from '../interfaces/overlayInterface';
import { HIDE_SUCCESS_ERROR_POPUP, SUCCESS_ERROR_POPUP_DETAILS } from '../redux/reducers/overlayReducer';
import store from '../redux/store';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ImageFileExtensions } from '~/services/config';

export const openLink = async (link: string) => {
  Linking.canOpenURL(link).then(() => {
    return Linking.openURL(link);
  });
};

export function extractSymptoms(item: ConsultationModel) {
  const symptomsArray: string[] = [];

  if (item.template_symptoms) {
    item.template_symptoms?.map((element, index) => {
      symptomsArray[index] = element.snomed_index.term;
    });
  } else if (item.patient_symptoms) {
    item.patient_symptoms?.map((element, index) => {
      symptomsArray[index] = element.snomed_index.term;
    });
  } else {
    return 'N/A';
  }

  return symptomsArray.join(', ');
}

export const checkIfImage = (mediaExtension: string) => {
  return Object.values(ImageFileExtensions).includes(mediaExtension);
};

export const SuccessAlert = (
  message: string[],
  title?: string,
  buttonText?: string,
  buttonFunction?: () => ModalFunctionReturn
) => {
  successOrErrorPopup(message, true, ModalAlertTypeEnum.SUCCESS, title, buttonText, null, buttonFunction);
};
export const InprogressAlert = (
  message: string[],
  options?: {
    title?: string;
    buttonText?: string;
    buttonFunction?: () => ModalFunctionReturn;
    secondaryButtonText?: string;
    secondaryButtonAction?: () => ModalFunctionReturn;
    block?: boolean;
    preventHideOnSecondary?: boolean;
    hideOnBackground?: boolean;
  }
) => {
  successOrErrorPopup(
    message,
    true,
    ModalAlertTypeEnum.IN_PROGRESS,
    options?.title,
    options?.buttonText,
    options?.block,
    options?.buttonFunction,
    options?.hideOnBackground,
    undefined,
    options?.secondaryButtonText,
    options?.secondaryButtonAction,
    {
      preventHideOnSecondary: options?.preventHideOnSecondary,
    }
  );
  return () => {
    store.dispatch(HIDE_SUCCESS_ERROR_POPUP());
  };
};
export const ConfirmationAlert = (
  message: string[],
  options?: {
    title?: string;
    okTitle?: string;
    type?: ModalAlertTypeEnum;
    okFunction?: () => ModalFunctionReturn;
    cancelTitle?: string;
    cancelFunction?: () => ModalFunctionReturn;
    additionalTitle?: string;
    additionalFunction?: () => ModalFunctionReturn;
  }
) => {
  successOrErrorPopup(
    message,
    true,
    options?.type ?? ModalAlertTypeEnum.NONE,
    options?.title,
    options.okTitle ?? 'Confirm',
    false,
    options?.okFunction,
    false,
    undefined,
    options?.cancelTitle ?? 'Cancel',
    options?.cancelFunction ? options.cancelFunction : () => {},
    undefined,
    options?.additionalTitle,
    options?.additionalFunction ? options.additionalFunction : () => {}
  );
  return () => {
    store.dispatch(HIDE_SUCCESS_ERROR_POPUP());
  };
};

export const hideSuccessOrErrorPopup = () => {
  store.dispatch(HIDE_SUCCESS_ERROR_POPUP());
};
export const successOrErrorPopup = (
  message: string[],
  flag: boolean,
  type: ModalAlertTypeEnum,
  title?: string,
  buttonText?: string,
  block?: boolean,
  functionExecute?: () => ModalFunctionReturn,
  hideOnBackground?: boolean,
  center?: boolean,
  secondaryButtonTitle?: string,
  secondaryButtonFunction?: () => void,
  options?: {
    preventHideOnSecondary?: boolean;
    errorCode?: string;
  },
  additionalTitle?: string,
  additionalFunction?: () => void
) => {
  const data: ModalOverlayProperties = {
    popupFlag: flag,
    popupMessage: message,
    popupType: type,
    popupTitle: title,
    popupButtonText: buttonText,
    popupBlockButtons: block || false,
    popupOptionalFields: {
      popupFunction: functionExecute,
      popupHideOnBackground: hideOnBackground,
      centerModal: center,
      secondaryButtonTitle,
      secondaryButtonFunction,
      preventHideOnSecondary: options?.preventHideOnSecondary,
      errorCode: options?.errorCode,
      additionalTitle,
      additionalFunction,
    },
  };
  store.dispatch(SUCCESS_ERROR_POPUP_DETAILS(data));
};
