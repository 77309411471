import { Route } from '@react-navigation/native';
import React from 'react';

import { HealthProfileMedicalConditionsMobile } from '~/pages/common/healthProfile/mobile/history/HealthProfileMedicalConditionsMobile';
import { HealthProfileDataProvider } from '~/providers/healthProfile/HealthProfileDataProviders';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { id: string; edit?: boolean }>;
}

export const PatientHealthProfileMedicalConditionsMobile: React.FC<Props> = ({ route }) => {
  const patientId = +route.params.id;
  return (
    <HealthProfileDataProvider accountId={patientId}>
      <HealthProfileMedicalConditionsMobile
        edit={!!route.params.edit}
        backLink={{ screen: screenName.PatientHealthProfile, params: { id: patientId } }}
      />
    </HealthProfileDataProvider>
  );
};
