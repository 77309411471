import React from 'react';
import { Controller } from 'react-hook-form';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { H4TtmSemiBoldBlack } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import DatePicker from '~/components/inputs/dateTime/DatePicker';
import { ClinicalTermSearchableList } from '~/components/inputs/searchable/ClinicalTermSearchableList';
import { useReferralDataContext } from '~/providers/consultation/ReferralDataContext';
import { validationSchema } from '~/services/validationConfig';

export const RequestFor: React.FC = () => {
  const {
    form: { control, rules },
  } = useReferralDataContext();

  return (
    <>
      <H4TtmSemiBoldBlack>Request for</H4TtmSemiBoldBlack>

      <Controller
        name="request"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
          <ClinicalTermSearchableList
            type={ClinicalTermTypeEnum.REFERRAL_REQUEST}
            values={value}
            setValues={onChange}
            title="Request"
            error={isSubmitted && !!error?.message}
            errorMessage={isSubmitted && error?.message}
          />
        )}
        rules={rules.request as any}
      />

      <Controller
        name="special_comment"
        control={control}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <FloatingInput
            maxLength={validationSchema.string.maxLength}
            error={!!error}
            errorMessage={error?.message}
            value={value}
            onChangeValue={onChange}
            onBlur={onBlur}
            label="Special comments"
          />
        )}
        rules={rules.special_comment}
      />

      <Controller
        name="last_menstrual_period"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
          <DatePicker
            error={isSubmitted && !!error}
            errorMessage={isSubmitted && error?.message}
            onChangeValue={onChange}
            value={value}
            label="Last menstrual period"
          />
        )}
        rules={rules.last_menstrual_period}
      />
    </>
  );
};
