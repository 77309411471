import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { View } from 'react-native';

import { HealthRecordCondition } from './HealthRecordCondition';
import { HealthRecordTemplateEditor } from './HealthRecordTemplateEditor';

import { useHealthRecordTemplate } from '~/api/hooks/consultations/HealthRecordTemplateHook';
import { useHealthRecordTemplates } from '~/api/hooks/consultations/HealthRecordTemplatesHook';
import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { mb10, mt25 } from '~/common/commonStyles';
import { LabelValuePair } from '~/common/types/LabelValuePair';
import { UploadDocumentComponent } from '~/components/buttons/UploadDocumentComponent';
import { H4TtmSemiBoldBlack, H6NsRegularBlack, SmallNsRegularBlack } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { ClinicalTermSearchableList } from '~/components/inputs/searchable/ClinicalTermSearchableList';
import { LoadingFetchingList } from '~/components/loading/LoadingFetchingList';
import { useHealthRecordDataContext } from '~/providers/consultation/HealthRecordDataContext';
import { validationSchema } from '~/services/validationConfig';
import { ImagePdfFormats } from '~/constants/documentUploadsConstants';

export const HealthRecordEdit: React.FC = () => {
  const {
    form: { control, rules },
    loading,
    addFile,
    removeFile,
  } = useHealthRecordDataContext();

  const { template_id } = useWatch({ control });
  const { templates } = useHealthRecordTemplates();
  const { template, templateQuestions } = useHealthRecordTemplate({
    templateId: template_id,
  });

  if (loading) return <LoadingFetchingList fullWidth />;

  return (
    <View>
      <View>
        <H4TtmSemiBoldBlack>Symptoms</H4TtmSemiBoldBlack>
        <SmallNsRegularBlack>
          Kindly choose from one of the templates below and add the relative symptoms and other information to create a
          health record.
        </SmallNsRegularBlack>
      </View>
      <View nativeID="formWithDropdown">
        <View>
          <Controller
            name="template_id"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Dropdown
                value={value}
                setValue={onChange}
                list={templates.map((template) => ({ value: template.id, label: template.title }))}
                placeholder="Select a template"
                label="Template"
              />
            )}
            rules={rules.template_id}
          />

          <HealthRecordTemplateEditor template={template} questions={templateQuestions} />
        </View>
        <View>
          <Controller
            name="diagnosis"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <ClinicalTermSearchableList
                type={ClinicalTermTypeEnum.DIAGNOSES}
                values={value ? [value] : []}
                setValues={(values: LabelValuePair<number>[]) => {
                  onChange(values.length ? values[0] : null);
                }}
                title="Diagnosis"
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
                singleSelect
                showMandatory={!!rules.diagnosis.required}
              />
            )}
            rules={rules.diagnosis}
          />
          <Controller
            name="differential_diagnosis"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <ClinicalTermSearchableList
                type={ClinicalTermTypeEnum.DIFFERENTIAL_DIAGNOSIS}
                values={value ? [value] : []}
                setValues={(values: LabelValuePair<number>[]) => {
                  onChange(values.length ? values[0] : null);
                }}
                title="Differential Diagnosis"
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
                singleSelect
              />
            )}
            rules={rules.differential_diagnosis}
          />
          <Controller
            name="condition"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <HealthRecordCondition
                value={value}
                onChange={onChange}
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
              />
            )}
            rules={rules.condition}
          />
          <Controller
            name="treatment_given"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <FloatingInput
                value={value}
                onChangeValue={onChange}
                onBlur={onBlur}
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
                label="Treatment given"
                maxLength={validationSchema.string.maxLength}
                showMandatory={!!rules.treatment_given?.required}
                multiline
              />
            )}
            rules={rules.treatment_given}
          />
          <Controller
            name="recommended_follow_up"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <FloatingInput
                value={value}
                onChangeValue={onChange}
                onBlur={onBlur}
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
                label="Recommended follow-up"
                maxLength={validationSchema.string.maxLength}
                multiline
              />
            )}
            rules={rules.recommended_follow_up}
          />
          <Controller
            name="other_comments"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
              <FloatingInput
                value={value}
                onChangeValue={onChange}
                onBlur={onBlur}
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
                label="Other comments"
                maxLength={validationSchema.string.maxLength}
                multiline
              />
            )}
            rules={rules.other_comments}
          />

          <View style={[mt25, mb10]}>
            <View style={mb10}>
              <H6NsRegularBlack>Upload any relevant documents</H6NsRegularBlack>
            </View>
            <Controller
              name="media"
              control={control}
              render={({ field: { value }, fieldState: { error }, formState: { isSubmitted } }) => (
                <UploadDocumentComponent
                  hideState
                  hidePreview
                  uploadedFiles={value}
                  add={addFile}
                  remove={removeFile}
                  error={isSubmitted && !!error}
                  allowedFormats={ImagePdfFormats}
                  noFilesText="No documents currently uploaded"
                />
              )}
            />
          </View>
        </View>
      </View>
    </View>
  );
};
