import React, { useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, Modal, Pressable } from 'react-native';

import { MobileFooterMenuList } from './MobileFooterMenuList';
import { SlideMenu } from './SlideMenu';

import { BaseModalProps } from '~/components/modals/interfaces/ModalProps';
import { colors } from '~/utils/colors';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { modalStyles } from '~/components/modals/ModalStyling';

interface Props extends BaseModalProps {
  show: boolean;
  onVisibleChanged?: (visible: boolean) => void;
}
export const MobileMenuModal: React.FC<Props> = ({ show, onVisibleChanged, onHide }) => {
  const [visible, setVisible] = useState(false);
  const { bottom } = useSafeAreaInsets();

  useEffect(() => {
    if (show !== visible) {
      setVisible(show);
      if (onVisibleChanged) onVisibleChanged(show);
    }
  }, [show]);

  const handleHide = () => {
    setVisible(false);
    if (onVisibleChanged) onVisibleChanged(false);
    if (onHide) onHide();
  };

  const content = useMemo(
    () => (
      <View style={styles.slideMenuStyle}>
        <View style={{ paddingHorizontal: 20 }}>
          <SlideMenu canClose={onHide} />
        </View>
        <View
          style={{
            backgroundColor: 'white',
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            paddingBottom: bottom,
          }}>
          <MobileFooterMenuList showSideMenu setSideMenu={onHide} inModal />
        </View>
      </View>
    ),
    []
  );

  return (
    <Modal animationType="slide" visible={visible} onDismiss={handleHide} transparent>
      <Pressable
        onPress={handleHide}
        style={[modalStyles.modalWrapper, { flexDirection: 'column', justifyContent: 'flex-end' }]}>
        {content}
      </Pressable>
    </Modal>
  );
};

const styles = StyleSheet.create({
  slideMenuStyle: {
    display: 'flex',
    backgroundColor: colors.white,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
});
