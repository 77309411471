import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { AppointmentStateToName } from '~/constants/appointments/appointmentStateMapping';
import { colors } from '~/utils/colors';

export const STATUS_COLOR_MAPPING = {
  [ConsultationStateEnum.Cancelled]: {
    color: colors.danger,
    label: AppointmentStateToName[ConsultationStateEnum.Cancelled],
  },
  [ConsultationStateEnum.RequestSubmitted]: {
    color: colors.warning,
    label: AppointmentStateToName[ConsultationStateEnum.RequestSubmitted],
  },

  [ConsultationStateEnum.Scheduled]: {
    color: colors.warning,
    label: AppointmentStateToName[ConsultationStateEnum.Scheduled],
  },
  [ConsultationStateEnum.PendingPayment]: {
    color: colors.warning,
    label: AppointmentStateToName[ConsultationStateEnum.PendingPayment],
  },
  [ConsultationStateEnum.Started]: {
    color: colors.success,
    label: AppointmentStateToName[ConsultationStateEnum.Started],
  },
  [ConsultationStateEnum.Ended]: { color: colors.purple, label: AppointmentStateToName[ConsultationStateEnum.Ended] },
  [ConsultationStateEnum.Submitted]: {
    color: colors.success,
    label: AppointmentStateToName[ConsultationStateEnum.Submitted],
  },

  [ConsultationStateEnum.RequestIgnored]: {
    color: colors.grey,
    label: AppointmentStateToName[ConsultationStateEnum.RequestIgnored],
  },
  [ConsultationStateEnum.RequestRejected]: {
    color: colors.grey,
    label: AppointmentStateToName[ConsultationStateEnum.RequestRejected],
  },
};
