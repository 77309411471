import React from 'react';

import { LinkButton } from '../buttons/LinkButton';
import { H6NsRegularSecondaryBlack } from '../commonText';

import { openLink } from '~/common/commonMethods';
import { isNative, isWeb } from '~/utils/buildConfig';
import { appointmentLabels } from '~/utils/labels/appointments';
import { screenName } from '~/utils/screenName';

interface Props {
  consultationFee: string;
}
export const BookingPriceTextComponent: React.FC<Props> = () => {
  return (
    <>
      <H6NsRegularSecondaryBlack style={{ marginBottom: 20 }}>
        {appointmentLabels.bookingPriceText}
      </H6NsRegularSecondaryBlack>
      <LinkButton
        style={{ padding: 0, marginBottom: 20 }}
        to={
          isNative()
            ? ({ screen: screenName.Help, params: { screen: screenName.HelpCancellationPolicy } } as never)
            : null
        }
        title={appointmentLabels.bookingCancellationLearnMore}
        onPress={
          isWeb()
            ? () => {
                openLink('/help/cancellation-policy');
              }
            : null
        }
      />
    </>
  );
};
