import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InsuranceDetailsResponse } from '~/api/models/insurance/responses/InsuranceDetailsResponse';

interface IState {
  insuranceDetails?: InsuranceDetailsResponse;
  lastUpdated?: Date;
}

const initialState: IState = {
  insuranceDetails: undefined,
  lastUpdated: undefined,
};

const insuranceDetailsSlice = createSlice({
  name: 'insuranceDetails',
  initialState,
  reducers: {
    SET_INSURANCE_DETAILS: (state, action: PayloadAction<InsuranceDetailsResponse>) => {
      return {
        insuranceDetails: action.payload,
        lastUpdated: new Date(),
      };
    },
    LOGOUT: () => ({
      ...initialState,
    }),
  },
});

export const { SET_INSURANCE_DETAILS, LOGOUT } = insuranceDetailsSlice.actions;
export default insuranceDetailsSlice.reducer;
