import React from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';

import { wizardStyle } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { RadioButtons } from '~/components/inputs/RadioButtons';
import { RadioButtonsYesNo } from '~/constants/radioButtons/radioButtonOptions';
import { WizardLayout } from '~/layouts/WizardLayout';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';

export const SickLeaveStep: React.FC = () => {
  const {
    formControl,
    loading,
    wizard: {
      actions: { next },
    },
  } = useAppointmentBookingContext();

  const buttons = [<Button label={labels.next} funCallback={next} disabled={loading} />];

  return (
    <View style={wizardStyle.container}>
      <WizardLayout title={appointmentLabels.sickLeaveTitle} buttons={buttons}>
        <View style={{ width: '100%' }}>
          <Controller
            control={formControl}
            name="is_sick_leave_certificate_required"
            render={({ field: { value, onChange }, formState: { isSubmitted }, fieldState: { error } }) => (
              <RadioButtons
                items={RadioButtonsYesNo}
                selected={value}
                onValueChange={onChange}
                error={isSubmitted && !!error?.message}
                errorMessage={isSubmitted && error?.message}
                showMandatory
              />
            )}
          />
        </View>
      </WizardLayout>
    </View>
  );
};
