import { useNavigation } from '@react-navigation/native';
import React from 'react';

import { CreateDiagnosisComponentMobile } from './CreateDiagnosisComponentMobile';
import { CreateDiagnosisComponentWeb } from './CreateDiagnosisComponentWeb';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { apiCreateConsultation } from '~/api/services/consultations';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { NavType } from '~/navigation/types';
import { useDiagnosisContext } from '~/providers/diagnosis/DiagnosisContext';
import { DiagnosisProvider } from '~/providers/diagnosis/DiagnosisProvider';
import { currentDateTime } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { screenName } from '~/utils/screenName';

const CreateDiagnosisComponentProvided: React.FC = () => {
  const { isDesktop } = useBreakpoints();

  const { submit, cancel, loading, patientId } = useDiagnosisContext();
  return (
    <PatientsLayout
      hideHeaderRight
      children={!isDesktop ? <CreateDiagnosisComponentMobile /> : <CreateDiagnosisComponentWeb />}
      footer={
        !isDesktop ? (
          <CancelContinueFooter onConfirm={submit} confirmTitle="Create" onCancel={cancel} waiting={loading} />
        ) : null
      }
      flexContent={false}
      back={{ to: { screen: screenName.PatientDetails, params: { id: patientId } } }}
    />
  );
};

interface Props {
  patientId: number;
}
export const CreateDiagnosisComponent: React.FC<Props> = ({ patientId }) => {
  const { navigate } = useNavigation<NavType>();

  const onSubmit = async (diagnosisId: number, reason: string) => {
    try {
      const consultationRes = await apiCreateConsultation({
        account_id: patientId,
        diagnosis_id: diagnosisId,
        type: ConsultationTypeEnum.CLINIC,
        reason,
        start_at: currentDateTime(),
      });

      await navigate(
        screenName.PatientConsultation as never,
        {
          id: patientId,
          diagnosisId,
          consultationId: consultationRes.data.id,
        } as never
      );
    } catch (e) {
      ErrorAlert(e);
    }
  };

  return (
    <DiagnosisProvider
      patientId={patientId}
      onSubmit={onSubmit}
      onCancel={async () => {
        navigate(screenName.PatientDetails, {
          id: patientId,
        });
      }}>
      <CreateDiagnosisComponentProvided />
    </DiagnosisProvider>
  );
};
