import { logger, configLoggerType } from 'react-native-logs';
import { mapConsoleTransport } from 'react-native-logs/dist/transports/mapConsoleTransport';

import { getSeverity } from './severity';

const production = !__DEV__;
const defaultConfig: configLoggerType = {
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
  severity: getSeverity(),
  async: production,
  transport: mapConsoleTransport,
};

const log = logger.createLogger<'debug' | 'info' | 'warn' | 'error'>(defaultConfig);
const axiosLogger = log.extend('AXIOS');
const integrationLogger = log.extend('INTEGRATION');

log.debug('Logging enabled');

export default log;

export { axiosLogger, integrationLogger };
