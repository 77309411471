import snsWebSdk from '@sumsub/websdk';

import { SumSubApplicantStatus, SumSubOptions } from './interfaces';

import { integrationLogger } from '~/utils/logger';

const refreshToken = () => {
  return Promise.resolve('newToken');
};

export default ({
  accessToken,
  element,
  applicantEmail,
  applicantPhone,
  onCompleted,
  onApplicantStatus,
}: SumSubOptions) => {
  const snsWebInstance = snsWebSdk
    .init(accessToken, refreshToken)
    .withConf({
      email: applicantEmail,
      phone: applicantPhone,
    })
    .withOptions({ addViewportTag: false, adaptIframeHeight: true })
    .on('idCheck.onApplicantStatusChanged', (payload) => {
      if (onApplicantStatus) onApplicantStatus(payload);
      if (onCompleted) onCompleted(true, payload as SumSubApplicantStatus);
    })
    .on('idCheck.onError', (error) => {
      integrationLogger.debug('onError', error);
      onCompleted(false);
    })
    .onMessage((type, payload) => {
      integrationLogger.debug('onMessage', type, payload);
    })
    .build();

  snsWebInstance.launch(element);
};
