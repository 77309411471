import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PharmacyUserModel } from '~/api/models/pharmacy/models/PharmacyUserModel';
import { apiGetPharmacyUsers } from '~/api/services/pharmacy';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { RootState } from '~/redux/reducers';
import { SET_PHARMACY_USERS, currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { useAppDispatch } from '~/redux/store';

interface Props {
  sort?: '+created_at' | '-created_at';
}
export function usePharmacyUsersList(options?: Props) {
  const [loading, setLoading] = useState(true);

  const currentPharmacy = useSelector(currentPharmacySelector);
  const pharmacyUsers = useSelector((state: RootState) => state.pharmacyReducer.pharmacyUsers);

  const dispatch = useAppDispatch();

  const getListOfUsers = () => {
    if (!currentPharmacy?.is_admin) return;

    setLoading(true);

    apiGetPharmacyUsers<PharmacyUserModel[]>({
      filter: {
        pharmacy_id: currentPharmacy.pharmacy.id,
      },
      sort: options?.sort,
      limit: -1,
    })
      .then((res) => {
        dispatch(SET_PHARMACY_USERS(res.data));
      })
      .catch(ErrorAlert)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getListOfUsers();
  }, [options?.sort, currentPharmacy?.pharmacy.id, currentPharmacy?.is_admin]);

  return { getListOfUsers, pharmacyUsers, loading };
}
