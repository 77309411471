import React, { useEffect, useState } from 'react';

import { useOnDemandAvailability } from '~/api/hooks/preferences/OnDemandAvailabilityHook';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { mb15 } from '~/common/commonStyles';
import { OnDemandAvailabilities } from '~/components/availabilities/OnDemandAvailabilities';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { consultationsLabels } from '~/utils/labels/consultations';

export const OnDemandAvailabilitiesMobile: React.FC = () => {
  const [hasAccess, setHasAccess] = useState(false);
  const { isFeatureActive } = useFeatureAccess();
  const { saveOnDemandAvailability, onDemandAvailability } = useOnDemandAvailability({ immediate: true });

  const checkAccess = async () => {
    setHasAccess(await isFeatureActive(FeatureAccessEnum.CONSULTATION_TYPES));
  };

  useEffect(() => {
    checkAccess();
  }, []);

  if (!hasAccess) return null;
  return (
    <>
      <H3TtmSemiBoldBlack style={mb15}>{consultationsLabels.doctorAvailableNow}</H3TtmSemiBoldBlack>
      <OnDemandAvailabilities
        value={onDemandAvailability}
        saveOnDemandAvailability={saveOnDemandAvailability}
        autoSave
      />
    </>
  );
};
