import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';

import { parseMoment } from '~/utils/dateAndTime';

interface Props {
  consultation: ConsultationModel;
  joinedCall: boolean;
}
export const useChannelMeans = ({ consultation, joinedCall }: Props) => {
  const timerRef = useRef(null);
  const [showTimer, setShowTimer] = useState(false);
  const [timeOver, setTimeOver] = useState(false);
  const [time, setTime] = useState('');

  const updateTime = (endAtTime: moment.Moment) => {
    const currentTime = moment();
    const duration = moment.duration(endAtTime.diff(currentTime));
    setTimeOver(duration.asSeconds() < 0);
    const absDuration = duration.abs();
    setTime(
      `${absDuration.hours() ? String(absDuration.hours()).padStart(2, '0') + ':' : ''}${String(
        absDuration.minutes()
      ).padStart(2, '0')}:${String(absDuration.seconds()).padStart(2, '0')}`
    );
  };

  useEffect(() => {
    const difference = moment.duration(parseMoment(consultation?.end_at).diff(consultation?.start_at));
    const endAtTime = moment(consultation?.started_at).add(difference);
    const showTimer = (joinedCall && endAtTime?.isSame(new Date(), 'day')) ?? false;
    setShowTimer(showTimer);
    if (timerRef.current) clearInterval(timerRef.current);

    if (showTimer) {
      timerRef.current = setInterval(() => {
        updateTime(endAtTime);
      }, 1000);
      updateTime(endAtTime);
    } else {
      timerRef.current = null;
    }
  }, [consultation?.started_at, joinedCall]);

  return {
    showTimer,
    timeOver,
    time,
  };
};
