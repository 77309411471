declare const window: Window & {
  enableDebug: () => void;
  disableDebug: () => void;
};

if (window) {
  window.enableDebug = () => {
    window.localStorage.setItem('digimed_debug', 'true');
  };
  window.disableDebug = () => {
    window.localStorage.removeItem('digimed_debug');
  };
}

export const getSeverity = () => {
  let debug = __DEV__;
  if (!debug && window && window.localStorage?.getItem) {
    debug = window.localStorage.getItem('digimed_debug') === 'true';
  }
  return debug ? 'debug' : 'error';
};
