import React from 'react';

import { ExportTypesEnum } from '~/api/models/common/constants/ExportTypesEnum';
import { apiExportData } from '~/api/services/exports';
import { apiGenerateIncomeDownloadExcel } from '~/api/services/income';
import { InprogressAlert } from '~/common/commonMethods';
import { mb15 } from '~/common/commonStyles';
import { AccordionGroupProvider } from '~/components/buttons/Accordions/AccordionGroupProvider';
import { AccordionWithAction } from '~/components/buttons/Accordions/AccordionWithAction';
import { H6NsRegularBlack } from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { Container } from '~/theme/components/grid';
import { appName } from '~/utils/buildConfig';
import { DATE_FORMAT_2DIGIT_YEAR, parseDateTime } from '~/utils/dateAndTime';
import { transformLabel } from '~/utils/labelUtil';
import { accountDeletionLabels } from '~/utils/labels/accountDeletionLabels';
import { incomeLabels } from '~/utils/labels/income';
import { patientManagementLabels } from '~/utils/labels/patientManagement';
import { DigimedMilestones, digimedMilestoneEnabled } from '~/utils/milestoneUtil';

interface Props {
  exportText?: boolean;
  isGuest?: boolean;
}
export const DoctorExportAccountDataComponent: React.FC<Props> = ({ exportText, isGuest }) => {
  const getPatientsExport = () => {
    apiExportData(ExportTypesEnum.PATIENTS_EXPORT)
      .then(() => {
        InprogressAlert(patientManagementLabels.exportPatientsInProgress, {
          title: patientManagementLabels.exportPatientsTitle,
        });
      })
      .catch(ErrorAlert);
  };

  const generateIncomeDownload = () => {
    const todayDate = new Date();
    const req = {
      filter: {
        date_to: parseDateTime(todayDate, {
          outputFormat: DATE_FORMAT_2DIGIT_YEAR,
        }),
      },
    };
    return apiGenerateIncomeDownloadExcel(req)
      .then(() => {
        InprogressAlert(incomeLabels.exportMessageExcel, {
          title: incomeLabels.exportTitle,
        });
      })
      .catch(ErrorAlert);
  };

  const generateAppointmentsDownload = () => {
    apiExportData(ExportTypesEnum.APPOINTMENT_EXPORT)
      .then(() => {
        InprogressAlert(['Your appointments export are in progress, we will let you know once they are done'], {
          title: 'Exporting your appointments',
        });
      })
      .catch(ErrorAlert);
  };

  const doctorExportOptions = () => (
    <AccordionGroupProvider>
      <AccordionWithAction
        groupValue="patients"
        title={accountDeletionLabels.exportPatientListTitle}
        message={accountDeletionLabels[exportText ? 'export' : 'delete'].exportPatientsListMessage}
        buttonTitle={isGuest ? null : accountDeletionLabels.exportPatientListButtonLabel}
        buttonAction={getPatientsExport}
      />
      {digimedMilestoneEnabled(DigimedMilestones.DoctorPatientApps) ? (
        <>
          <AccordionWithAction
            groupValue="appointments"
            title={accountDeletionLabels.exportAppointmentsListTitle}
            message={accountDeletionLabels[exportText ? 'export' : 'delete'].exportAppointmentsListMessage}
            buttonTitle={isGuest ? null : accountDeletionLabels.exportAppointmentsButtonLabel}
            buttonAction={generateAppointmentsDownload}
          />
          <AccordionWithAction
            groupValue="financials"
            title={accountDeletionLabels.exportFinancialDataTitle}
            message={accountDeletionLabels[exportText ? 'export' : 'delete'].exportFinancialDataMessage}
            buttonTitle={isGuest ? null : accountDeletionLabels.exportFinancialDataTitle}
            buttonAction={generateIncomeDownload}
          />
        </>
      ) : null}
    </AccordionGroupProvider>
  );

  return (
    <Container alignSelf="flex-start" style={mb15}>
      <H6NsRegularBlack>
        {transformLabel(accountDeletionLabels[exportText ? 'export' : 'delete'].subtitle, {
          appName,
        })}
      </H6NsRegularBlack>
      {doctorExportOptions()}
    </Container>
  );
};
