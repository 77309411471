import React from 'react';
import { Controller } from 'react-hook-form';

import { H4TtmSemiBoldBlack } from '~/components/commonText';
import FloatingInput from '~/components/floatingInputBox';
import { useReferralDataContext } from '~/providers/consultation/ReferralDataContext';
import { validationSchema } from '~/services/validationConfig';

export const ReferralForm: React.FC = () => {
  const {
    form: { control, rules },
  } = useReferralDataContext();
  return (
    <>
      <H4TtmSemiBoldBlack>Digimed Referral Form</H4TtmSemiBoldBlack>

      <Controller
        name="presenting_complaint"
        control={control}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <FloatingInput
            showMandatory
            maxLength={validationSchema.string.maxLength}
            error={!!error}
            errorMessage={error?.message}
            value={value}
            onChangeValue={onChange}
            onBlur={onBlur}
            label="Presenting Complaint(s)"
          />
        )}
        rules={rules.presenting_complaint as any}
      />

      <Controller
        name="indications"
        control={control}
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <FloatingInput
            maxLength={validationSchema.string.maxLength}
            error={!!error}
            errorMessage={error?.message}
            value={value}
            onChangeValue={onChange}
            onBlur={onBlur}
            label="Indications"
          />
        )}
        rules={rules.indications as any}
      />
    </>
  );
};
