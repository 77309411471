import React from 'react';
import { Controller, useFormState } from 'react-hook-form';
import { View } from 'react-native';

import { AppointmentListSortingComponent } from '../../components/AppointmentListSortingComponent';

import { Button, TextButton } from '~/components/commonButton';
import { NonRegisteredModalContainer } from '~/components/modals/NonRegisteredModalContainer';
import { useAppointmentListingContext } from '~/providers/appointment/AppointmentListingContext';
import { labels } from '~/utils/labels';

interface Props {
  show: boolean;
  onHide: () => void;
}
export const AppointmentListSortingModal: React.FC<Props> = ({ show, onHide }) => {
  const { filterControl, submitQuery } = useAppointmentListingContext();
  const { isDirty } = useFormState({ control: filterControl });
  const buttons = [
    <Button
      label={labels.apply}
      funCallback={() => {
        submitQuery();
        onHide();
      }}
      disabled={!isDirty}
    />,
    <TextButton label={labels.close} funCallback={onHide} />,
  ];
  return (
    <NonRegisteredModalContainer onHide={onHide} buttons={buttons} show={show} hideOnBackground>
      <View>
        <Controller
          name="sort"
          control={filterControl}
          render={({ field: { value, onChange } }) => (
            <AppointmentListSortingComponent value={value} onValueChanged={onChange} largeHeader />
          )}
        />
      </View>
    </NonRegisteredModalContainer>
  );
};
