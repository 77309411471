export enum ExportTypesEnum {
  PATIENTS_EXPORT = 'patients_export',
  INFORMATION_EXPORT = 'information_export',
  APPOINTMENT_EXPORT = 'appointment_export',
  INCOME_EXPORT = 'income_export',
  DISPENSE_HISTORY = 'dispense_history',
  REQUEST_HISTORY = 'request_history',
  BILLING_EXPORT = 'billing_export',
}

export const ExportTypeMapping = {
  [ExportTypesEnum.PATIENTS_EXPORT]: 'Patients data export',
  [ExportTypesEnum.INFORMATION_EXPORT]: 'Bulk data export',
  [ExportTypesEnum.INCOME_EXPORT]: 'Income data export',
  [ExportTypesEnum.APPOINTMENT_EXPORT]: 'Appointments export',
  [ExportTypesEnum.DISPENSE_HISTORY]: 'Dispense history export',
  [ExportTypesEnum.REQUEST_HISTORY]: 'Request history export',
  [ExportTypesEnum.BILLING_EXPORT]: 'Billing export',
};
