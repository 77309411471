import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ConsultationModel } from '../../../../api/models/consultations/models/ConsultationModel';
import { cardStyle, mb10, mb20, mt10, mt20 } from '../../../../common/commonStyles';
import { Column, Container, Grid, Row } from '../../../../theme/components/grid';
import { colors } from '../../../../utils/colors';
import { DATE_FORMAT, TIME_FORMAT, parseDateTime } from '../../../../utils/dateAndTime';
import { H3TtmRegularBlack, H6NsRegularBlack, H6NsSemiBoldBlack } from '../../../commonText';
import { ProfileDetailsComponent } from '../../../profile/ProfileDetailsComponent';

import { ConsultationTypeMapping } from '~/api/models/common/constants/ConsultationTypeEnum';
import { labels } from '~/utils/labels';

interface Props {
  consultation: ConsultationModel;
  whiteBackground?: boolean;
  hideHeader?: boolean;
}
export const ConsultationDetailsComponent: React.FC<Props> = ({
  consultation,
  whiteBackground = true,
  hideHeader = false,
}) => {
  if (!consultation) return null;

  const date = (consultation && parseDateTime(consultation.start_at, { outputFormat: DATE_FORMAT })) || 'N/A';
  const time = (consultation && parseDateTime(consultation.start_at, { outputFormat: TIME_FORMAT })) || 'N/A';
  return (
    <>
      {hideHeader ? null : <H3TtmRegularBlack style={mb10}>Consultation details</H3TtmRegularBlack>}
      <View style={[cardStyle.cardContainer, { backgroundColor: 'white' }]}>
        <Grid grid={{ gutters: 4 }}>
          <Container>
            <Row>
              <Column style={styles.horizontalLine}>
                <ProfileDetailsComponent
                  account={consultation.doctor}
                  clinic={consultation.clinic}
                  isDoctor
                  theme="table"
                />
                <View style={mt10}>
                  <ProfileDetailsComponent account={consultation.patient} theme="table" />
                </View>
              </Column>
            </Row>

            <Row style={styles.horizontalLine}>
              <Column span="auto">
                <H6NsRegularBlack>Type of consultation:</H6NsRegularBlack>
              </Column>
              <Column>
                <H6NsSemiBoldBlack>{ConsultationTypeMapping[consultation.type]}</H6NsSemiBoldBlack>
              </Column>
            </Row>

            <Row style={styles.horizontalLine} justifyContent="space-between">
              <Column span="auto">
                <Row>
                  <Column span="auto">
                    <H6NsRegularBlack>Date:</H6NsRegularBlack>
                  </Column>
                  <Column span="auto">
                    <H6NsSemiBoldBlack>{date}</H6NsSemiBoldBlack>
                  </Column>
                </Row>
              </Column>
              <Column span="auto" style={{ position: 'relative' }}>
                <View style={styles.verticalLine} />
              </Column>
              <Column span="auto">
                <Row>
                  <Column span="auto">
                    <H6NsRegularBlack>Time:</H6NsRegularBlack>
                  </Column>
                  <Column span="auto">
                    <H6NsSemiBoldBlack>{time}</H6NsSemiBoldBlack>
                  </Column>
                </Row>
              </Column>
            </Row>
            <Row style={mb20}>
              <Column>
                <H6NsSemiBoldBlack>Reason for consultation</H6NsSemiBoldBlack>
                <H6NsRegularBlack>{consultation.reason ?? 'No reason given'}</H6NsRegularBlack>
              </Column>
            </Row>
            <Row style={mb10}>
              <Column>
                <H6NsSemiBoldBlack>{labels.sickLeaveCertificate}</H6NsSemiBoldBlack>
                <H6NsRegularBlack>{consultation?.is_sick_leave_certificate_required ? 'Yes' : 'No'}</H6NsRegularBlack>
              </Column>
            </Row>
          </Container>
        </Grid>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  horizontalLine: {
    borderBottomColor: colors.lightPurple2,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    paddingBottom: 16,
    marginBottom: 16,
  },
  verticalLine: {
    width: 1,
    backgroundColor: colors.lightPurple2,
    height: 20,
  },
});
