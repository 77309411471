import React from 'react';
import { StyleSheet, View } from 'react-native';

import { colors } from '~/utils/colors';

interface Props {
  title: React.ReactNode;
  content: React.ReactNode;
}

export const BaseCard: React.FC<Props> = ({ title, content }) => {
  return (
    <View style={styles.card}>
      <View style={styles.internal}>
        <View style={styles.title}>{title}</View>
        <View>{content}</View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    minHeight: 200,
    maxWidth: 350,
    borderRadius: 10,
    borderColor: colors.lightPurple,
    borderWidth: 1,
    marginRight: 10,
  },
  internal: {
    padding: 15,
  },
  title: {
    marginBottom: 20,
  },
});
