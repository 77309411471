import React, { useEffect, useRef } from 'react';
import { TouchableOpacity, TouchableOpacityProps } from 'react-native';

interface Props extends TouchableOpacityProps {
  title?: string;
}

export const TitledTouchableOpacity: React.FC<Props> = (props) => {
  const ref = useRef<TouchableOpacity>();

  // TODO: find workaround
  // useEffect(() => {
  //   ref.current?.setNativeProps({ title: props.title });
  // }, [props.title]);
  return <TouchableOpacity ref={ref} {...props} />;
};
