import React, { useContext } from 'react';
import { Control, UseFieldArrayReturn, UseFormGetValues, UseFormHandleSubmit, UseFormWatch } from 'react-hook-form';

import { PriceModel } from '~/api/models/common/models/PriceModel';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { InsuranceClaimFormRequest } from '~/api/models/insurance/requests/InsuranceClaimFormRequest';
import { UseWizard } from '~/utils/hooks/WizardStepsHook';

export interface InsuranceClaimFormContext {
  control: Control<InsuranceClaimFormRequest>;
  wizard: UseWizard;
  nextPage: () => Promise<void>;
  loading?: boolean;
  consultationId?: number;
  consultation: ConsultationModel;
  insuranceClaimsFieldArray: UseFieldArrayReturn<InsuranceClaimFormRequest, 'insurance_claim_documents', 'fieldId'>;
  getValues: UseFormGetValues<InsuranceClaimFormRequest>;
  handleSubmit: UseFormHandleSubmit<InsuranceClaimFormRequest>;
  watch: UseFormWatch<InsuranceClaimFormRequest>;
  createInsuranceClaim: () => Promise<void>;
  updateInsuranceDocuments: () => Promise<void>;
  removeDocument: (index: number) => Promise<void>;
  submitClaim: () => Promise<void>;
  totalClaimedAmount: number;
  consultationFee: PriceModel;
  digimedFees: PriceModel[];
  digimedFeesTotal: string;
}

export const InsuranceClaimConsultationFormContext = React.createContext<InsuranceClaimFormContext>(
  {} as InsuranceClaimFormContext
);
export const useInsuranceClaimFormContext = (): InsuranceClaimFormContext =>
  useContext(InsuranceClaimConsultationFormContext);
