import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { whenAppType } from '~/utils/buildConfig';

export const AppointmentsPastState = {
  [ConsultationStateEnum.Cancelled]: { filter: true },
  [ConsultationStateEnum.RequestIgnored]: { filter: true },
  [ConsultationStateEnum.RequestRejected]: { filter: true },
  [ConsultationStateEnum.Submitted]: { filter: true },
};

export const AppointmentsUpcomingState = whenAppType({
  else: {
    [ConsultationStateEnum.Ended]: { filter: true },
    [ConsultationStateEnum.Scheduled]: { filter: true },
    [ConsultationStateEnum.Started]: { filter: true },
  },
  whenPatient: {
    [ConsultationStateEnum.Ended]: { filter: true },
    [ConsultationStateEnum.Scheduled]: { filter: true },
    [ConsultationStateEnum.Started]: { filter: true },
    [ConsultationStateEnum.RequestSubmitted]: { filter: true },
  },
});

export const AppointmentsToday = [ConsultationStateEnum.Scheduled, ConsultationStateEnum.PendingPayment];

export const AppointmentRejectedState = [
  ConsultationStateEnum.Cancelled,
  ConsultationStateEnum.RequestIgnored,
  ConsultationStateEnum.RequestRejected,
];

export const AppointmentStateToName = {
  [ConsultationStateEnum.Cancelled]: 'Cancelled',
  [ConsultationStateEnum.Draft]: 'Draft',
  [ConsultationStateEnum.Ended]: 'Pending report',
  [ConsultationStateEnum.RequestIgnored]: 'Missed',
  [ConsultationStateEnum.RequestRejected]: 'Declined',
  [ConsultationStateEnum.RequestSubmitted]: 'Pending',
  [ConsultationStateEnum.Scheduled]: 'Scheduled',
  [ConsultationStateEnum.PendingPayment]: 'Scheduled',
  [ConsultationStateEnum.Started]: 'Started',
  [ConsultationStateEnum.Submitted]: 'Completed',
};

export enum AppointmentsFilterEnum {
  UPCOMING = 'Upcoming',
  PAST = 'Past',
}

export const AppointmentFilter = {
  [AppointmentsFilterEnum.UPCOMING]: { label: 'Upcoming', filters: AppointmentsUpcomingState },
  [AppointmentsFilterEnum.PAST]: { label: 'Past', filters: AppointmentsPastState },
};

export const processAppointmentStateFilters = (states: ConsultationStateEnum[]) => {
  if (states.includes(ConsultationStateEnum.Scheduled) && !states.includes(ConsultationStateEnum.PendingPayment)) {
    return [...states, ConsultationStateEnum.PendingPayment];
  }
  return [...states];
};
