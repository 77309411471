import React, { useContext } from 'react';
import { Control } from 'react-hook-form';

import { CreateDiagnosisFormModel } from '~/components/doctor/patients/consultation/diagnosis/types/CreateDiagnosisFormModel';

export interface IDiagnosisContext {
  patientId: number;
  formControl: Control<CreateDiagnosisFormModel>;
  loading?: boolean;
  hideCancel?: boolean;
  submit: () => Promise<void>;
  cancel: () => Promise<void>;
}

export const DiagnosisContext = React.createContext<IDiagnosisContext>({} as IDiagnosisContext);
export const useDiagnosisContext = (): IDiagnosisContext => useContext(DiagnosisContext);
