import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { mb15 } from '~/common/commonStyles';
import { INavigate } from '~/common/types/navigation/navigate';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { H6NsSemiBoldBlack } from '~/components/commonText';
import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { PatientHistoryItem } from '~/components/doctor/patients/healthProfile/PatientHistoryItem';
import { DropDownItem } from '~/components/inputs/dropdown/interfaces';
import { ClinicalTermSearchableList } from '~/components/inputs/searchable/ClinicalTermSearchableList';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { useHealthProfileHistoryItemContext } from '~/providers/healthProfile/history/HealthProfileHistoryItemContext';
import { labels } from '~/utils/labels';

interface Props {
  type: DropDownItem<ClinicalTermTypeEnum>;
  edit?: boolean;
  backLink: INavigate;
}

export const HealthProfileHistoryItemMobile: React.FC<Props> = ({ type, edit, backLink }) => {
  const [editing, setEditing] = useState(edit);
  const { healthProfile, setPatientHistory, reset, submit, waiting } = useHealthProfileDataContext();
  const { getData, loading } = useHealthProfileHistoryItemContext();

  useEffect(() => {
    if (healthProfile) {
      getData();
    }
  }, [healthProfile]);

  const footer = (
    <CancelContinueFooter
      onCancel={() => {
        reset();
        setEditing(false);
      }}
      onConfirm={() => {
        if (!editing) {
          setEditing(true);
        } else {
          submit()
            .then(() => setEditing(false))
            .catch(() => {});
        }
      }}
      hideCancel={!editing}
      confirmTitle={editing ? labels.update : labels.edit}
      waiting={waiting}
    />
  );

  return (
    <PatientsLayout title="Health Profile" back={{ to: backLink }} hideHeaderRight footer={footer} flexContent={false}>
      <View>
        <View style={mb15}>
          <H6NsSemiBoldBlack>{type.label}</H6NsSemiBoldBlack>
        </View>
        {editing ? (
          <View style={styles.search}>
            <ClinicalTermSearchableList
              singleSelect
              type={type.value}
              title={type.label}
              setValues={(values) => {
                setPatientHistory(type.value, values[0], []);
              }}
            />
          </View>
        ) : null}
        <View style={mb15}>
          <PatientHistoryItem title={type.label} type={type.value} locked={!editing} loading={loading} />
        </View>
      </View>
    </PatientsLayout>
  );
};
const styles = StyleSheet.create({
  search: {
    width: '100%',
    paddingBottom: 8,
  },
});
