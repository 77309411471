import React from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

import { H6NsSemiBoldRed, H6NsRegularRed } from '../commonText';
import { AlertCircle, ArrowRight } from '../svgImages';

import { flex1, mr10, ml10 } from '~/common/commonStyles';
import { colors } from '~/utils/colors';

interface Props {
  onPress?: () => void;
  title?: string;
  message?: string;
  style?: StyleProp<ViewStyle>;
}

export const AlertCard: React.FC<Props> = ({ onPress, title, message, style }) => {
  const content = (
    <View style={[styles.container, style]}>
      <View style={flex1}>
        <H6NsSemiBoldRed style={styles.title}>
          <View style={mr10}>
            <AlertCircle width={20} height={20} color={colors.danger} />
          </View>
          {title}
        </H6NsSemiBoldRed>
        <H6NsRegularRed>{message}</H6NsRegularRed>
      </View>
      {onPress ? (
        <View style={ml10}>
          <ArrowRight width={14} height={14} color={colors.danger} />
        </View>
      ) : null}
    </View>
  );

  if (!onPress) return content;
  return <TouchableOpacity onPress={onPress}>{content}</TouchableOpacity>;
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    borderColor: colors.danger,
    borderWidth: 1,
    padding: 10,
    maxWidth: 523,
    backgroundColor: `${colors.danger}08`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
});
