import React from 'react';
import { View } from 'react-native';

import { mb10, mt10, mt20 } from '~/common/commonStyles';
import { HomeVisitMapView } from '~/components/availabilities/homeVisit/HomeVisitMapView';
import { HomeVisitNavigationButton } from '~/components/common/consultations/buttons/HomeVisitNavigationButton';
import { H3TtmRegularBlack, SmallNsRegularBlack, SmallNsSemiBoldBlack } from '~/components/commonText';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';

export const DoctorAppointmentExtraDetails: React.FC = () => {
  const { consultation } = useAppointmentContext();

  return (
    <View>
      <H3TtmRegularBlack style={[mb10, mt10]}>Appointment Details</H3TtmRegularBlack>
      <HomeVisitMapView
        hideRadius
        latLng={{ lat: consultation?.patient_location?.lat, lng: consultation?.patient_location?.lng }}
      />
      <View style={[mt20, mb10]}>
        <SmallNsSemiBoldBlack>Patient address</SmallNsSemiBoldBlack>
        <SmallNsRegularBlack>{consultation?.patient_location?.formatted_address}</SmallNsRegularBlack>
      </View>
      <HomeVisitNavigationButton />
    </View>
  );
};
