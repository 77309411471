import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';

import { ConsultationBillingCardSelection } from '../components/ConsultationBillingCardSelection';
import { ConsultationFeesComponent } from '../components/ConsultationFeesComponent';

import { ConsultationFeeTypeEnum } from '~/api/models/appointments/enums/ConsultationFeeTypeEnum';
import { mb20, mt10 } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { BookingPriceTextComponent } from '~/components/stripe/BookingPriceTextComponent';
import { WizardLayout } from '~/layouts/WizardLayout';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { requiredValidation } from '~/services/validationConfig';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { appointmentLabels } from '~/utils/labels/appointments';

export const ConsultationBillingBreakdownStep: React.FC = () => {
  const { consultationRequest, submitRequest } = useAppointmentBookingContext();
  const [loading, setLoading] = useState(false);
  const { isDesktop } = useBreakpoints();

  const authorisePayment = async (params: { paymentMethodId: string }) => {
    try {
      setLoading(true);
      await submitRequest(params);
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const { control, handleSubmit } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      paymentMethodId: '',
    },
  });
  return (
    <WizardLayout
      hideRequired
      buttons={
        !isDesktop
          ? [<Button label="Pre-Authorise payment" funCallback={handleSubmit(authorisePayment)} disabled={loading} />]
          : null
      }>
      <Grid>
        <Container alignSelf="flex-start">
          <Row>
            <Column span={{ xs: 12, lg: 6 }}>
              <View>
                <H3TtmSemiBoldBlack>{appointmentLabels.preAuthoriseTitle}</H3TtmSemiBoldBlack>
              </View>
              <View style={mt10}>
                <BookingPriceTextComponent
                  consultationFee={
                    consultationRequest?.price?.fees.find(
                      (fee) => fee.type === ConsultationFeeTypeEnum.CONSULTATION_FEE
                    )?.price.formatted_amount
                  }
                />
              </View>
            </Column>
            <Column span={{ xs: 12, lg: 6 }}>
              <View>
                <H3TtmSemiBoldBlack>{appointmentLabels.billingDetailsTitle}</H3TtmSemiBoldBlack>
                <H6NsRegularBlack>{appointmentLabels.billingDetailsSubtitle}</H6NsRegularBlack>
              </View>

              <View style={[mb20, mt10]}>
                <Controller
                  control={control}
                  name="paymentMethodId"
                  render={({ field: { value, onChange }, fieldState: { error }, formState: { isSubmitted } }) => (
                    <ConsultationBillingCardSelection
                      selected={value}
                      setSelected={onChange}
                      error={isSubmitted && error?.message}
                    />
                  )}
                  rules={{
                    required: requiredValidation('Payment card'),
                  }}
                />
              </View>
              <View style={mb20}>
                <H3TtmSemiBoldBlack>{appointmentLabels.paymentDetailsTitle}</H3TtmSemiBoldBlack>
              </View>

              <View style={mb20}>
                <ConsultationFeesComponent price={consultationRequest?.price} />
              </View>
              {isDesktop ? (
                <Button label="Pre-Authorise payment" funCallback={handleSubmit(authorisePayment)} disabled={loading} />
              ) : null}
            </Column>
          </Row>
        </Container>
      </Grid>
    </WizardLayout>
  );
};
