import { Route } from '@react-navigation/native';
import React, { useEffect } from 'react';

import { ConsultationReportMobileHeader } from '../ConsultationReportMobileHeader';

import { useConsultationDetails } from '~/api/hooks/consultations/ConsultationDetails';
import { PatientsLayout } from '~/components/doctor/patients/PatientsLayout';
import { PrescriptionsEditMode } from '~/components/doctor/patients/consultation/tabs/Prescriptions/Edit/PrescriptionsEditMode';
import { PrescriptionsViewMode } from '~/components/doctor/patients/consultation/tabs/Prescriptions/View/PrescriptionsViewMode';
import { GridLoadingWrapper } from '~/components/loading/GridLoadingWrapper';
import { ConsultationReportProvider } from '~/contexts/ConsultationReportProvider';
import { usePrescriptionDataContext } from '~/providers/consultation/PrescriptionDataContext';
import { PrescriptionDataProvider } from '~/providers/consultation/PrescriptionDataProvider';
import { Grid, Container } from '~/theme/components/grid';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { id: string; diagnosisId: string; consultationId: string }>;
  isEditing?: boolean;
}

export const Content: React.FC<Props> = ({ route, isEditing }) => {
  const { loadData } = usePrescriptionDataContext();

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Grid>
      <Container style={{ height: '100%' }}>
        <GridLoadingWrapper loading={false}>
          <PatientsLayout
            hideHeaderRight
            back={{ to: { screen: screenName.PatientConsultation, params: route.params } }}
            flexContent={false}>
            <ConsultationReportMobileHeader title="Prescription" noAutoSave={isEditing} />
            {isEditing ? <PrescriptionsEditMode /> : <PrescriptionsViewMode />}
          </PatientsLayout>
        </GridLoadingWrapper>
      </Container>
    </Grid>
  );
};

export const ConsultationPrescriptionMobile: React.FC<Props> = ({ route }) => {
  const { consultation, isEditing } = useConsultationDetails({ consultationId: +route.params.consultationId });

  return (
    <PrescriptionDataProvider consultation={consultation}>
      <ConsultationReportProvider>
        <Content route={route} isEditing={isEditing} />
      </ConsultationReportProvider>
    </PrescriptionDataProvider>
  );
};
