import { LocationObject } from 'expo-location';
import { useEffect, useRef } from 'react';

import { apiUpdateDoctorLocation } from '~/api/services/consultations/navigation';
import AppEventHandler, { AppEvents } from '~/classes/events/AppEventHandler';

interface Props {
  consultationId: number;
}

export function useLocationData({ consultationId }: Props) {
  const locations = useRef<LocationObject[]>([]);

  useEffect(() => {
    if (!consultationId) return;
    const unsubscribe = AppEventHandler.addListener(AppEvents.DOCTOR_LOCATION_UPDATE, handleLocationUpdate);

    return unsubscribe;
  }, [consultationId]);

  // const [count, setCount] = useState(0); // count state is only used as rerender trigger, from timer callback

  const handleLocationUpdate = (stored: LocationObject[]) => {
    // check if data was changed using ref data.
    // this method is called from outside react, so we can't use state data without reinitializing it
    if (
      stored[0]?.coords?.latitude &&
      stored[0]?.coords?.longitude &&
      (stored[0].coords.latitude !== locations?.current[0]?.coords?.latitude ||
        stored[0].coords.longitude !== locations?.current[0]?.coords?.longitude)
    ) {
      // update the locations, but this won't trigger a rerender or update
      locations.current = stored;

      // update the state value, triggering a rerender
      // setCount(locations.current.length);

      apiUpdateDoctorLocation({
        consultationId,
        data: {
          lat: stored[0].coords.latitude,
          lng: stored[0]?.coords?.longitude,
        },
      });
    }
  };
}
