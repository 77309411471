import React, { useEffect } from 'react';
import { Control, Controller, UseFormReset, useWatch } from 'react-hook-form';

import { lineHeight22, mb10, mb15, mb30 } from '../../../../common/commonStyles';
import { Column, Container, Row } from '../../../../theme/components/grid';
import { labels } from '../../../../utils/labels';
import { H3TtmSemiBoldBlack, SmallNsRegularBlack } from '../../../commonText';
import { VideoCallIcon, AudioCallIcon, ChatIcon } from '../../../svgImages';

import { useConsultationPreferences } from '~/api/hooks/accounts/ConsultationPreferencesHook';
import { ConsultationTypeFormModel } from '~/api/models/consultations/models/ConsultationTypeFormModel';
import { SelectableCard } from '~/components/cards/SelectableCard';
import { AlertCard } from '~/components/cards/AlertCard';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';

interface Props {
  control: Control<ConsultationTypeFormModel>;
  reset: UseFormReset<ConsultationTypeFormModel>;
  disabled?: boolean;
}

export const SettingsConsultationMeans: React.FC<Props> = ({ control, reset, disabled }) => {
  const { consultationTypes, userPreferences, loading } = useConsultationPreferences();
  const { isMobile } = useBreakpoints();

  const watch = useWatch({ control });

  useEffect(() => {
    reset({
      types: watch.types,
      languages: userPreferences?.languages?.map((language) => language.id) ?? [],
      channels: userPreferences?.consultation_channels?.map((channel) => channel.id) ?? [],
    });
  }, [userPreferences]);

  return (
    <Container alignSelf="flex-start">
      {loading ? (
        <LoadingActivityIndicator style={{ minHeight: 170 }} />
      ) : (
        <>
          <Row>
            <Column>
              <H3TtmSemiBoldBlack style={[mb10]}>{labels.changeYourOnlinePreferedConsultation}</H3TtmSemiBoldBlack>
            </Column>
          </Row>
          <Row>
            <Column span={{ xs: 12, md: 6 }}>
              <SmallNsRegularBlack style={[mb10, lineHeight22]}>
                {labels.preferredOnlineConsultationSubMessage}
              </SmallNsRegularBlack>
              {!watch.channels?.length ? (
                <AlertCard
                  title={'Your preferred consultation channels are not set'}
                  message={
                    'Please set your preferred means of online consultation, otherwise you will not be seen as available for consultations.'
                  }
                  style={[mb30, isMobile ? { marginHorizontal: MOBILE_HORIZONTAL_SPACE } : null]}
                />
              ) : null}
            </Column>
            <Column span={{ xs: 12, md: 6 }}>
              <Controller
                control={control}
                name="channels"
                render={({ field: { value, onChange } }) => (
                  <>
                    {consultationTypes?.map((item, index) => (
                      <SelectableCard
                        key={index}
                        disabled={disabled}
                        icon={(color) =>
                          item.id === 1 ? (
                            <ChatIcon height={22} width={22} color={color} />
                          ) : item.id === 2 ? (
                            <AudioCallIcon height={22} width={22} color={color} />
                          ) : item.id === 3 ? (
                            <VideoCallIcon height={22} width={22} color={color} />
                          ) : null
                        }
                        label={item.name}
                        selected={value.includes(item.id)}
                        center
                        centerVertical
                        style={mb15}
                        onPress={() => {
                          let updatedConsultationMethods: number[];

                          if (value.includes(item.id)) {
                            updatedConsultationMethods = value.filter(
                              (consultationTypeId) => consultationTypeId !== item.id
                            );
                          } else {
                            updatedConsultationMethods = [...value, item.id];
                          }
                          onChange(updatedConsultationMethods);
                        }}
                      />
                    ))}
                  </>
                )}
              />
            </Column>
          </Row>
        </>
      )}
    </Container>
  );
};
