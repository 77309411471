import React from 'react';
import { View } from 'react-native';

import { PatientSearch } from './PatientSearch';
import { mb5, ml10 } from '../../../common/commonStyles';
import { labels } from '../../../utils/labels';
import { screenName } from '../../../utils/screenName';
import { ButtonLink } from '../../commonButton';

import { PageLayout, PageLayoutProps } from '~/layouts/EnhancedPageLayout';
import { Column, Row } from '~/theme/components/grid';
import { isClinicVersion, isDoctorVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const PatientsLayout: React.FC<PageLayoutProps> = (props) => {
  const { isMobile } = useBreakpoints();
  const headerRight =
    isDoctorVersion() || isClinicVersion() ? (
      <Row justifyContent="flex-end">
        <Column span={{ xs: 12, md: 'auto' }} style={mb5}>
          <PatientSearch />
        </Column>
        {isDoctorVersion() ? (
          <Column span={{ xs: 'hide', md: 'auto' }} style={[{ display: 'flex', justifyContent: 'center' }, mb5]}>
            <View>
              <ButtonLink
                style={{ marginVertical: 0 }}
                label={labels.addNewPatient}
                screenName={screenName.PatientAddNew}
              />
            </View>
          </Column>
        ) : null}
      </Row>
    ) : null;

  return (
    <PageLayout
      {...props}
      contentStyling={[props.contentStyling, { paddingBottom: 30 }]}
      headerRight={headerRight}
      hideHeaderRight={isMobile || props?.hideHeaderRight}
    />
  );
};
