import React, { useMemo } from 'react';
import { View, StyleSheet, Text } from 'react-native';

import WebSidebarMenu from '../components/navigation/WebSidebarMenu';
import { colors } from '../utils/colors';
import { labels } from '../utils/labels';

import { DESKTOP_HORIZONTAL_SPACE } from '~/common/desktopStyles';

interface Props {
  children: React.ReactNode;
}
export const DashboardWebLayout: React.FC<Props> = ({ children }) => {
  const year = useMemo(() => new Date().getFullYear(), []);
  return (
    <View style={styles.container}>
      <WebSidebarMenu />
      <View style={styles.content}>
        <>{children}</>

        <View style={styles.footer}>
          <Text style={{ fontSize: 12 }}>
            {labels.watermark} {year}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: colors.white,
  },
  content: {
    flex: 1,
    backgroundColor: colors.white,
    paddingHorizontal: DESKTOP_HORIZONTAL_SPACE,
    paddingVertical: 15,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingVertical: 8,
  },
});
