import React from 'react';
import { View } from 'react-native';

import { mt35 } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { H6NsSemiBoldBlack } from '~/components/commonText';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';

interface Props {
  title?: string;
  hideEdit?: boolean;
  onEdit?: () => void;
}

export const HealthProfileEditHeader: React.FC<Props> = ({ title, hideEdit, onEdit }) => {
  const healthProfileDataContext = useHealthProfileDataContext();
  return (
    <View
      style={[
        {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 20,
          minHeight: 40,
        },
        mt35,
      ]}>
      <H6NsSemiBoldBlack>{title}</H6NsSemiBoldBlack>
      {!hideEdit && onEdit && healthProfileDataContext.hasWriteAccess ? (
        <View>
          <Button label="Edit" funCallback={onEdit} style={{ minWidth: 100 }} />
        </View>
      ) : null}
    </View>
  );
};
