import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { KeyboardAvoidingView, Modal, Platform, StyleSheet, View } from 'react-native';

import { RescheduleAppointmentMobileModal } from './RescheduleAppointmentMobileModal';
import { CancelContinueFooter } from './buttons/CancelContinueFooter';
import TimePicker from './common/TimePicker';
import { Button, OutlineButton } from './commonButton';
import { H6NsRegularBlack, H6NsSemiBoldBlack } from './commonText';
import { modalStyles } from './modals/ModalStyling';
import { ModalName } from './modals/constants/ModalNames';
import { BaseModalProps } from './modals/interfaces/ModalProps';
import { NotificationIcon } from './svgImages';

import { useAppointmentReschedule } from '~/api/hooks/consultations/AppointmentRescheduleHook';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiGetConsultation } from '~/api/services/consultations';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { flex1 } from '~/common/commonStyles';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { greaterThanValidation, requiredValidation } from '~/services/validationConfig';
import { Column, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { LARAVEL_ATOM, parseDateTime, TIME_FORMAT } from '~/utils/dateAndTime';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  consultationId?: number;
  consultation?: ConsultationModel;
  onHide: () => void;
}

export const AppointmentNotificationModal: React.FC<Props> = ({ onHide, consultationId, consultation }) => {
  const [isLate, setIsLate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useBreakpoints();
  const { registerModal, openModal } = useModalManager();
  const [localConsultation, setConsultation] = useState<ConsultationModel>();
  const { control, submitRescheduleAppointment, handleSubmit } = useAppointmentReschedule();
  const [errorResponse, setErrorResponse] = useState<ErrorResponse>();
  const { closeModalByName } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.RescheduleAppointmentMobile, RescheduleAppointmentMobileModal);
  }, []);

  useEffect(() => {
    if (consultation) {
      setConsultation(consultation);
    } else if (consultationId) {
      apiGetConsultation({
        id: consultationId,
      })
        .then((res) => setConsultation(res.data))
        .catch(() => {
          onHide();
        });
    }
  }, [consultationId, consultation]);

  const message = useMemo(() => {
    if (!localConsultation?.start_at) return ['', ''];
    const difference = moment().diff(localConsultation.start_at, 'minutes');
    const absDifference = Math.abs(difference);
    if (difference < 0) {
      return [
        `Your scheduled appointment is starting in ${absDifference} ${absDifference === 1 ? 'minute' : 'minutes'}`,
        'Will you be on time?',
      ];
    } else {
      return [
        `Your scheduled appointment should have started ${absDifference} ${
          absDifference === 1 ? 'minute' : 'minutes'
        } ago`,
        'Are you still on time?',
      ];
    }
  }, [localConsultation?.start_at]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      setErrorResponse(null);
      await submitRescheduleAppointment(localConsultation);
      closeModalByName(ModalName.AppointmentNotification);
    } catch (e) {
      setErrorResponse(e);
    } finally {
      setLoading(false);
    }
  };

  if (!localConsultation) return null;

  return (
    <Modal animationType="fade" transparent visible onRequestClose={null}>
      <KeyboardAvoidingView
        style={{ display: 'flex', flex: 1, top: 30 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <View style={[modalStyles.modalWrapper]}>
          <View style={[flex1, { display: 'flex', height: '100%', paddingHorizontal: 20 }]}>
            <View pointerEvents="auto" style={[styles.contentContainer]}>
              <Grid>
                <Row style={styles.row}>
                  <View style={{ marginLeft: 10 }}>
                    <NotificationIcon width={30} height={30} />
                  </View>
                  <Column span={{ xs: 10, md: 6 }} style={isMobile ? { marginBottom: 15 } : null}>
                    <H6NsRegularBlack>{message[0]}</H6NsRegularBlack>
                    <H6NsSemiBoldBlack>{message[1]}</H6NsSemiBoldBlack>
                  </Column>
                  <Column span={{ xs: 3, md: 2 }}>
                    <Button
                      funCallback={() => {
                        setIsLate(false);
                        onHide();
                      }}
                      label="Yes"
                    />
                  </Column>
                  <Column span={{ xs: 3, md: 2 }}>
                    <OutlineButton
                      funCallback={() => {
                        if (isMobile)
                          openModal(ModalName.RescheduleAppointmentMobile, { consultation: localConsultation });
                        else setIsLate(true);
                      }}
                      label="No"
                    />
                  </Column>
                </Row>
                {isLate && !isMobile ? (
                  <>
                    <Column style={{ marginBottom: 15 }}>
                      <Controller
                        name="time"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TimePicker
                            label="Choose time"
                            value={value}
                            onChangeValue={onChange}
                            errorMessage={error?.message || errorResponse?.message}
                            error={!!error || !!errorResponse}
                          />
                        )}
                        rules={{
                          required: requiredValidation('Appointment time'),
                          validate: {
                            greaterThanValidation: greaterThanValidation(
                              () =>
                                parseDateTime(localConsultation.start_at, {
                                  parseFormat: LARAVEL_ATOM,
                                  outputFormat: TIME_FORMAT,
                                }),
                              'Appointment Start Time',
                              'Selected Time'
                            ),
                          },
                        }}
                      />
                    </Column>
                    <CancelContinueFooter
                      style={{ alignSelf: 'center', flex: 1, marginTop: 10 }}
                      confirmTitle={labels.update}
                      onCancel={() => setIsLate(false)}
                      onConfirm={handleSubmit(onSubmit)}
                      disabled={loading}
                    />
                  </>
                ) : null}
              </Grid>
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    width: '100%',
    maxWidth: 548,
    maxHeight: '100%',
    alignSelf: 'center',
    borderRadius: 5,
    backgroundColor: colors.lightPurple2,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    display: 'flex',
  },
  row: { alignItems: 'center', justifyContent: 'center', marginHorizontal: 0, marginVertical: 10 },
});
