import { get, put } from '../../axios';

import { UpdateMeConsultationTypeRequest } from '~/api/models/consultations/requests/UpdateMeConsutlationTypeRequest';
import { ConsultationTypePriceIndexResponse } from '~/api/models/consultations/responses/ConsultationTypePriceIndexResponse';
import { ConsultationTypeResponse } from '~/api/models/consultations/responses/ConsultationTypeResponse';

export const apiGetConsultationTypePriceIndex = () => {
  return get<ConsultationTypePriceIndexResponse>('/me/consultation-prices');
};

export const apiGetConsultationTypePrice = (consultationType: number) => {
  return get<ConsultationTypeResponse>(`/me/consultation-prices/${consultationType}`);
};

export const apiUpdateConsultationTypePrice = (params: UpdateMeConsultationTypeRequest) => {
  return put(`/me/consultation-types`, params);
};
