import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, View } from 'react-native';

import { ExtraSmallNsRegularBlack } from '../commonText';

import { isWeb } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';

export const ConsultationStartedBlinker: React.FC = () => {
  const animated = useRef(new Animated.Value(0.2)).current;

  useEffect(() => {
    const interval = 800;
    Animated.loop(
      Animated.sequence([
        Animated.timing(animated, {
          toValue: 1,
          duration: interval,
          useNativeDriver: !isWeb(),
        }),
        Animated.timing(animated, {
          toValue: 0.2,
          duration: interval,
          useNativeDriver: !isWeb(),
        }),
        Animated.timing(animated, {
          toValue: 1,
          duration: interval,
          useNativeDriver: !isWeb(),
        }),
        Animated.timing(animated, {
          toValue: 0.2,
          duration: interval,
          useNativeDriver: !isWeb(),
        }),
      ])
    ).start();
  }, []);

  return (
    <View style={{ display: 'flex', alignItems: 'center' }}>
      <ExtraSmallNsRegularBlack style={{ maxWidth: 70, marginBottom: 10, textAlign: 'center' }}>
        Consultation has started
      </ExtraSmallNsRegularBlack>
      <Animated.View style={[styles.container, { opacity: animated }]}>
        <View style={styles.content} />
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 15,
    height: 15,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: colors.danger,
    backgroundColor: colors.transparent,
    position: 'relative',
  },
  content: {
    position: 'absolute',
    left: 3,
    right: 3,
    top: 3,
    bottom: 3,
    borderRadius: 12,
    backgroundColor: colors.danger,
  },
});
