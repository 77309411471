import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { ConfirmationAlert, openLink } from '~/common/commonMethods';
import { mb10, mb20, mt20 } from '~/common/commonStyles';
import { AppointmentDetails } from '~/components/common/appointments/AppointmentDetails';
import { Button, OutlineButton } from '~/components/commonButton';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import { WizardLayout } from '~/layouts/WizardLayout';
import { useAppointmentBookingContext } from '~/providers/appointment/AppointmentBookingContext';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { isWeb } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { transformLabel } from '~/utils/labelUtil';
import { appointmentLabels } from '~/utils/labels/appointments';
import { healthProfileLabels } from '~/utils/labels/healthProfileLabels';
import { getAccountName } from '~/utils/personalDetailsUtils';

export const ConsultationConfirmationStep: React.FC = () => {
  const {
    consultationRequest,
    formControl,
    updateRequest,
    wizard: {
      actions: { next },
    },
    saving,
    generateHealthProfileUrl,
  } = useAppointmentBookingContext();
  const { isDesktop } = useBreakpoints();
  const { userDetails } = useUserDetails({ allowStale: true });
  const [loading, setLoading] = useState(true);
  const {
    field: { value: formattedAddress },
  } = useController({ control: formControl, name: 'formattedAddress' });

  const book = () => {
    const patientName =
      consultationRequest.consultation.patient.id === userDetails?.account.id
        ? 'your'
        : `${getAccountName(consultationRequest.consultation.patient)}'s`;
    ConfirmationAlert(
      [
        transformLabel(appointmentLabels.requestDataAccess, {
          patientName,
        }),
      ],
      {
        title: appointmentLabels.accessNeeded,
        okTitle: appointmentLabels.acceptAndContinue,
        type: ModalAlertTypeEnum.IN_PROGRESS,
        okFunction: next,
        cancelTitle: 'CANCEL',
      }
    );
  };

  useEffect(() => {
    setLoading(true);
    updateRequest().finally(() => setLoading(false));
  }, []);

  return (
    <WizardLayout hideRequired buttons={!isDesktop ? [<Button label="Book appointment" funCallback={book} />] : null}>
      <Grid>
        <Container alignSelf="flex-start">
          <Row style={mb10}>
            <Column span={{ xs: 12, lg: 8 }}>
              <Row style={mb20}>
                <Column>
                  <H3TtmSemiBoldBlack>Consultation Details</H3TtmSemiBoldBlack>
                </Column>
              </Row>
              <Row style={mb10}>
                <Column>
                  <AppointmentDetails
                    loading={loading}
                    consultation={consultationRequest?.consultation}
                    formattedAddress={formattedAddress}
                  />
                </Column>
              </Row>
            </Column>
            {isWeb() ? (
              <Column span={{ xs: 12, lg: 4 }}>
                {isDesktop ? (
                  <Row style={mb20}>
                    <Column>
                      <H3TtmSemiBoldBlack>Health Profile</H3TtmSemiBoldBlack>
                    </Column>
                  </Row>
                ) : null}
                <Row style={[mb10, !isDesktop ? mt20 : null]}>
                  <Column>
                    <OutlineButton
                      label={healthProfileLabels.view}
                      funCallback={() => {
                        const url = generateHealthProfileUrl();
                        if (url) {
                          openLink(url);
                        }
                      }}
                    />
                  </Column>
                </Row>
              </Column>
            ) : null}
          </Row>
          {isDesktop ? (
            <Row>
              <Column
                span={{ xs: 12, lg: 8 }}
                alignSelf="flex-end"
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button
                  label="Book appointment"
                  funCallback={book}
                  style={{ width: '100%', maxWidth: 300 }}
                  disabled={saving}
                />
              </Column>
            </Row>
          ) : null}
        </Container>
      </Grid>
    </WizardLayout>
  );
};
