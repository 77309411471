import { get, post } from '../../axios';

import { IncomeExportRequest } from '~/api/models/income/requests/IncomeExportRequest';
import { IncomeGraphRequest } from '~/api/models/income/requests/IncomeGraphRequest';
import { IncomeGraphResponse } from '~/api/models/income/responses/IncomeGraphResponse';
import { IncomeResponse } from '~/api/models/income/responses/IncomeResponse';

export const apiGetIncomeGraph = (req: IncomeGraphRequest) => {
  return get<IncomeGraphResponse>('/me/income/graph', req);
};

export const apiGetIncome = (req: IncomeGraphRequest) => {
  return get<IncomeResponse>('/me/income', req);
};

export const apiGenerateIncomeDownloadExcel = (req?: IncomeExportRequest) => {
  return post('me/income/export', req);
};

export const apiGenerateIncomeDownloadPdf = (req?: IncomeExportRequest) => {
  return post('me/income/export/pdf', req);
};
