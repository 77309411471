import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

import { HomeVisitWithIdModel } from '~/api/models/preferences/models/HomeVisitWithIdModel';
import { OnDemandAvailabilityEventModel } from '~/api/models/preferences/models/OnDemandAvailabilityEventModel';
import { ScheduledAppointmentsModelWithId } from '~/api/models/preferences/models/ScheduledAppointmentsWithIdModel';

interface IState {
  homeVisits?: HomeVisitWithIdModel[];
  onDemandAvailability?: OnDemandAvailabilityEventModel;
  scheduledAvailability?: ScheduledAppointmentsModelWithId[];
}

const initialState: IState = {
  homeVisits: undefined,
  onDemandAvailability: undefined,
  scheduledAvailability: undefined,
};

const availabilitiesSlice = createSlice({
  name: 'availabilities',
  initialState,
  reducers: {
    SET_HOME_VISIT_AVAILABILITIES: (state, action: PayloadAction<HomeVisitWithIdModel[]>) => ({
      ...state,
      homeVisits: action.payload,
    }),
    SET_ON_DEMAND_AVAILABILITY: (state, action: PayloadAction<OnDemandAvailabilityEventModel | undefined>) => ({
      ...state,
      onDemandAvailability: action.payload,
    }),
    SET_SCHEDULED_AVAILABILITY: (state, action: PayloadAction<ScheduledAppointmentsModelWithId[]>) => ({
      ...state,
      scheduledAvailability: action.payload,
    }),
    LOGOUT: () => ({
      ...initialState,
    }),
  },
});

export const { SET_HOME_VISIT_AVAILABILITIES, SET_ON_DEMAND_AVAILABILITY, SET_SCHEDULED_AVAILABILITY, LOGOUT } =
  availabilitiesSlice.actions;

export default availabilitiesSlice.reducer;

export const homeVisitAvailabilitiesSelector = (state: RootState) => state.availabilitiesReducer.homeVisits;
export const onDemandAvailabilitySelector = (state: RootState) => state.availabilitiesReducer.onDemandAvailability;
export const scheduledAvailabilitiesSelector = (state: RootState) => state.availabilitiesReducer.scheduledAvailability;
