import React, { useContext } from 'react';
import { Control } from 'react-hook-form';

import { ConsultationIndexForm } from '~/api/models/consultations/requests/ConsultationsIndexRequest';
import { ConsultationsPaginatedIndexResponse } from '~/api/models/consultations/responses/ConsultationsIndexResponse';
import { TablePaginationInfo } from '~/components/common/DataTable/DataTableTypes';

export interface IAppointmentListingContext {
  consultations: ConsultationsPaginatedIndexResponse;
  loading: boolean;
  filterControl: Control<ConsultationIndexForm>;
  setPage: (pageInfo: TablePaginationInfo) => void;
  submitQuery: () => Promise<void>;
}

export const AppointmentListingContext = React.createContext<IAppointmentListingContext>(
  {} as IAppointmentListingContext
);

export const useAppointmentListingContext = (): IAppointmentListingContext => useContext(AppointmentListingContext);
