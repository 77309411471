import { useEffect, useState } from 'react';

import { useFeatureAccess } from '../subscriptions/FeatureAccessHook';

import { ConsultationChannelsModel } from '~/api/models/accounts/models/ConsulationChannelsModel';
import { ConsultationPreferencesModel } from '~/api/models/registration/models/ConsultationPreferencesModel';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import {
  apiGetMePreferredChannels,
  apiGetMePreferredLanguages,
  apiOnlineConsultationTypes,
} from '~/api/services/accounts/preferences';

export function useConsultationPreferences() {
  const [consultationTypes, setConsultationTypes] = useState<ConsultationChannelsModel[]>(null);
  const [userPreferences, setUserPreferences] = useState<ConsultationPreferencesModel>(null);
  const { isFeatureActive } = useFeatureAccess();
  const [loading, setLoading] = useState(true);

  const getConsultationTypes = async () => {
    const response = await apiOnlineConsultationTypes();
    setConsultationTypes(response.data);
  };

  const getUserPreferences = async () => {
    const retrievedUserPreferences: ConsultationPreferencesModel = { consultation_channels: null, languages: null };
    if (await isFeatureActive(FeatureAccessEnum.CONSULTATION_CHANNELS)) {
      await apiGetMePreferredChannels().then((channels) => {
        retrievedUserPreferences.consultation_channels = channels.data;
      });
    }

    await apiGetMePreferredLanguages().then((languages) => {
      retrievedUserPreferences.languages = languages.data;
    });

    setUserPreferences(retrievedUserPreferences);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      await Promise.all([getConsultationTypes(), getUserPreferences()]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { consultationTypes, userPreferences, getUserPreferences, loading };
}
