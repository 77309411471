import React from 'react';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { Button, OutlineButton } from '~/components/commonButton';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { isPatientVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { labels } from '~/utils/labels';

interface Props {
  onPress?: () => void;
}

export const ScheduledAndOnDemandNavigationButton: React.FC<Props> = ({ onPress }) => {
  const { isTimeForConsultation, consultation } = useAppointmentContext();
  const { goToAppointment } = useShowAppointment();
  const { isMobile } = useBreakpoints();

  const view = () => {
    goToAppointment(consultation);

    if (onPress) onPress();
  };

  if (isPatientVersion() && consultation?.state === ConsultationStateEnum.RequestSubmitted) return null;
  if (isTimeForConsultation) {
    return <Button label={labels.join} funCallback={view} />;
  } else {
    return (
      <OutlineButton
        label={isMobile ? labels.viewConsultation.toUpperCase() : labels.view.toUpperCase()}
        funCallback={view}
      />
    );
  }
};
