import React from 'react';
import { Controller } from 'react-hook-form';

import { HealthProfileEditHeader } from './HealthProfileEditHeader';
import { mb10 } from '../../../../common/commonStyles';
import { Column, Row } from '../../../../theme/components/grid';
import { MetricInput } from '../../../inputs/MetricInput';

import { BmiUI } from '~/components/BmiUI';
import { useHealthProfileDataContext } from '~/providers/healthProfile/HealthProfileDataContext';
import { isWeb } from '~/utils/buildConfig';

interface Props {
  locked?: boolean;
  onStartEdit?: () => void;
}

export const PatientHealthMetrics: React.FC<Props> = ({ locked, onStartEdit }) => {
  const { healthProfile, controlMetrics } = useHealthProfileDataContext();

  return (
    <Row>
      <Column width={720}>
        <HealthProfileEditHeader title="Health Metrics" hideEdit={!locked} onEdit={onStartEdit} />

        <Row>
          <Column span={{ xs: 12, md: 6 }}>
            <Controller
              name="height"
              control={controlMetrics}
              render={({ field: { onChange, value } }) => (
                <MetricInput
                  style={mb10}
                  label="Height"
                  value={value}
                  onChange={onChange}
                  placeholder="00"
                  unit="cm"
                  locked={locked}
                />
              )}
            />

            <Controller
              name="weight"
              control={controlMetrics}
              render={({ field: { onChange, value } }) => (
                <MetricInput
                  style={mb10}
                  label="Weight"
                  value={value}
                  onChange={onChange}
                  placeholder="00"
                  unit="kg"
                  locked={locked}
                />
              )}
            />

            {locked ? (
              isWeb() ? (
                <MetricInput style={mb10} label="BMI" value={healthProfile.bmi} locked />
              ) : (
                <BmiUI style={mb10} bmiValue={healthProfile.bmi} />
              )
            ) : null}
            <Controller
              name="glucose"
              control={controlMetrics}
              render={({ field: { onChange, value } }) => (
                <MetricInput
                  style={mb10}
                  label="Glucose"
                  value={value}
                  onChange={onChange}
                  placeholder="00"
                  unit="mmol/L"
                  locked={locked}
                />
              )}
            />
            <Controller
              name="blood_pressure_systolic"
              control={controlMetrics}
              render={({ field: { onChange, value } }) => (
                <MetricInput
                  style={mb10}
                  label="Blood Pressure (Systolic)"
                  value={value}
                  onChange={onChange}
                  placeholder="00"
                  unit="mmHg"
                  locked={locked}
                />
              )}
            />
            <Controller
              name="blood_pressure_diastolic"
              control={controlMetrics}
              render={({ field: { onChange, value } }) => (
                <MetricInput
                  style={mb10}
                  label="Blood Pressure (Diastolic)"
                  value={value}
                  onChange={onChange}
                  placeholder="00"
                  unit="mmHg"
                  locked={locked}
                />
              )}
            />
          </Column>
          <Column span={{ xs: 12, md: 6 }}>
            <Controller
              name="total_cholesterol"
              control={controlMetrics}
              render={({ field: { onChange, value } }) => (
                <MetricInput
                  style={mb10}
                  label="Total Cholesterol"
                  value={value}
                  onChange={onChange}
                  placeholder="00"
                  unit="mmol/L"
                  locked={locked}
                />
              )}
            />
            <Controller
              name="ldl_cholesterol"
              control={controlMetrics}
              render={({ field: { onChange, value } }) => (
                <MetricInput
                  style={mb10}
                  label="LDL Cholesterol"
                  value={value}
                  onChange={onChange}
                  placeholder="00"
                  unit="mmol/L"
                  locked={locked}
                />
              )}
            />
            <Controller
              name="hdl_cholesterol"
              control={controlMetrics}
              render={({ field: { onChange, value } }) => (
                <MetricInput
                  style={mb10}
                  label="HDL Cholesterol"
                  value={value}
                  onChange={onChange}
                  placeholder="00"
                  unit="mmol/L"
                  locked={locked}
                />
              )}
            />
            <Controller
              name="triglycerides"
              control={controlMetrics}
              render={({ field: { onChange, value } }) => (
                <MetricInput
                  style={mb10}
                  label="Triglycerides"
                  value={value}
                  onChange={onChange}
                  placeholder="00"
                  unit="mmol/L"
                  locked={locked}
                />
              )}
            />
            <Controller
              name="hdl_ratio"
              control={controlMetrics}
              render={({ field: { onChange, value } }) => (
                <MetricInput
                  style={mb10}
                  label="Cholesterol/HDL Ratio"
                  value={value}
                  onChange={onChange}
                  locked={locked}
                />
              )}
            />
          </Column>
        </Row>
      </Column>
    </Row>
  );
};
