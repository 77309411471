import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useEffect, useRef } from 'react';
import { useFormState } from 'react-hook-form';

import { ReferralDataContext } from './ReferralDataContext';

import { useReferrals } from '~/api/hooks/consultations/ReferralHook';
import { useReferralMedia } from '~/api/hooks/consultations/ReferralMediaHook';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ReferralFormModel, ReferralModel } from '~/api/models/consultations/models/ReferralModel';
import { dateValidation, getRequiredMessage, maxLengthValidation, validationSchema } from '~/services/validationConfig';
import { clinicalTermToLabelValue } from '~/utils/clinicalTerms';
import { useAutoSaveForm } from '~/utils/hooks/AutoSaveFormHook';
import { useFormWithRules } from '~/utils/hooks/FormWithRulesHook';
import isNullOrUndefined from '~/utils/types/isNullOrUndefined';

interface Props {
  consultation: ConsultationModel;
  children: React.ReactNode;
}

export const ReferralDataProvider: React.FC<Props> = ({ consultation, children }) => {
  const { referral, referralExamination, referralFor, loading, setReferral, getReferral } = useReferrals({
    consultationId: consultation.id,
  });
  const { referralMediaFiles, addFile, removeFile, getFiles, getReferralsMedia } = useReferralMedia();

  const referralId = useRef<number>();
  const form = useFormWithRules<ReferralFormModel>({
    mode: 'all',
    reValidateMode: 'onBlur',
    shouldUnregister: false,
    defaultValues: {
      formId: undefined,
      consultation_id: undefined,
      presenting_complaint: '',
      indications: '',
      special_comment: '',
      last_menstrual_period: '',
      refer_patient: false,
      referral_media_type: '',
      additional_comments: '',
      request: undefined,
      examination: undefined,
    },
    rules: {
      presenting_complaint: {
        maxLength: maxLengthValidation(validationSchema.string.maxLength),
        validate: {
          requiredIf: (value) => {
            const dependentValue = form.getValues('refer_patient');
            if (
              !isNullOrUndefined(dependentValue) &&
              dependentValue === true &&
              (isNullOrUndefined(value) || value === '')
            ) {
              return getRequiredMessage('Presenting complaint');
            }
            return null;
          },
        },
      },
      indications: {
        maxLength: maxLengthValidation(validationSchema.string.maxLength),
      },
      request: {},
      special_comment: {
        maxLength: maxLengthValidation(validationSchema.string.maxLength),
      },
      last_menstrual_period: {
        validate: { dateValidation: dateValidation() },
      },
      additional_comments: {
        maxLength: maxLengthValidation(validationSchema.string.maxLength),
      },
    },
  });

  const { dirtyFields, isDirty } = useFormState({ control: form.control });

  const setReferralForm = (referral?: ReferralModel, keepValues?: boolean) => {
    referralId.current = referral?.id ?? null;
    form.reset(
      {
        ...form.getValues(),
        consultation_id: consultation.id || undefined,
        presenting_complaint: referral?.presenting_complaint || '',
        indications: referral?.indications || '',
        special_comment: referral?.special_comment || '',
        last_menstrual_period: referral?.last_menstrual_period || '',
        additional_comments: referral?.additional_comments || '',
        refer_patient: !!referral,
      },
      { keepValues }
    );
  };

  const clearReferralForm = () => {
    referralId.current = null;
    form.reset(
      {
        ...form.getValues(),
        consultation_id: consultation.id || undefined,
        presenting_complaint: '',
        indications: '',
        special_comment: '',
        last_menstrual_period: '',
        additional_comments: '',
        refer_patient: false,
        examination: undefined,
        request: undefined,
      },
      { keepValues: true }
    );
  };

  const submit = async () => {
    const formData = form.getValues();

    const res = await setReferral(
      { ...formData },
      {
        referral: isDirty,
        examination: !!dirtyFields.examination,
        requestFor: !!dirtyFields.request,
      },
      referralId.current
    ).catch(() => {});

    if (!res) {
      clearReferralForm();
    } else {
      referralId.current = res;
      form.reset({ ...formData }, { keepValues: true });
    }
  };

  const { saving } = useAutoSaveForm({
    control: form.control,
    autoSave: submit,
  });

  useEffect(() => {
    if (consultation) {
      form.reset({
        ...form.getValues(),
        consultation_id: consultation.id,
      });
    }
  }, [consultation]);

  useEffect(() => {
    if (referralFor) {
      form.reset({
        ...form.getValues(),
        request: referralFor.map((item) => clinicalTermToLabelValue(item)),
      });
    }
  }, [referralFor]);

  useEffect(() => {
    if (referralExamination) {
      form.reset({
        ...form.getValues(),
        examination: referralExamination.map((item) => clinicalTermToLabelValue(item)),
      });
    }
  }, [referralExamination]);

  const verifyForm = () => {
    return form.triggerCustomValidation();
  };

  const loadData = async () => {
    const referral = await getReferral();
    if (referral?.id) {
      await getFiles(referral.id)?.catch(() => {});
      await getReferralsMedia(referral.id).catch(() => {});
    }

    setReferralForm(referral);
  };

  const addReferralFile = async (document: DocumentPickerAsset) => {
    const formData = form.getValues();
    await addFile({
      document,
      referral_id: referralId.current,
      referral_media_type: formData.referral_media_type,
    });
  };

  const removeReferralFile = async (document: MediaModel) => {
    await removeFile({
      document,
      referral_id: referralId.current,
    });
  };

  return (
    <ReferralDataContext.Provider
      value={{
        form,
        loading,
        referral,
        consultation,
        referralMediaFiles,
        addFile: addReferralFile,
        removeFile: removeReferralFile,
        verifyForm,
        submit,
        loadData,
        saving,
      }}>
      {children}
    </ReferralDataContext.Provider>
  );
};
