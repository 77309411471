import { useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { H6NsRegularBlack } from '../commonText';
import { ArrowRight } from '../svgImages';

import { mb10, mr10 } from '~/common/commonStyles';
import { NavType } from '~/navigation/types';
import { colors } from '~/utils/colors';

interface Props {
  menu: {
    title: string;
    link: { screen: string; params?: Record<string, any> };
    icon?: React.ReactNode;
    hidden?: boolean;
  }[];
  params?: Record<string, never>;
  spacedOut?: boolean;
}

export const MobileMenuPageComponent: React.FC<Props> = ({ menu, params, spacedOut }) => {
  const navigation = useNavigation<NavType>();

  const filteredMenu = useMemo(() => menu.filter((item) => !item.hidden), [menu]);
  return (
    <View style={[styles.list, spacedOut ? styles.listSpaced : null]}>
      {filteredMenu.map((item, index) => (
        <View key={item.title} style={[spacedOut ? mb10 : null]}>
          <TouchableOpacity
            onPress={() => navigation.navigate(item.link.screen, item.link.params || params)}
            style={[
              styles.item,
              index === menu.length - 1 ? styles.itemNoBorder : null,
              spacedOut ? styles.itemSpaced : null,
            ]}>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {item.icon ? <View style={mr10}>{item.icon}</View> : null}
              <H6NsRegularBlack>{item.title}</H6NsRegularBlack>
            </View>
            <ArrowRight width={12} height={12} />
          </TouchableOpacity>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  list: {
    backgroundColor: colors.white,
    borderRadius: 6,
    paddingHorizontal: 10,
    paddingTop: 1,
  },
  listSpaced: {
    borderRadius: 0,
    backgroundColor: colors.transparent,
    paddingHorizontal: 0,
  },
  item: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderBottomColor: colors.lightPurple,
    paddingVertical: 12,
    paddingHorizontal: 12,
  },
  itemSpaced: {
    backgroundColor: colors.lightPurple,
    borderRadius: 6,
    borderBottomWidth: 0,
  },
  itemNoBorder: {
    borderBottomWidth: 0,
  },
});
