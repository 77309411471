import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { AbstractChart } from 'react-native-chart-kit';
import { AbstractChartProps, AbstractChartState } from 'react-native-chart-kit/dist/AbstractChart';
import { G, Line, Rect, Svg, Text } from 'react-native-svg';

import { assign } from '~/utils/polyfills/object';

interface GroupedBarChartData {
  labels: string[];
  legend: string[];
  data: number[][];
  barColors: string[];
}
interface GroupedBarChartState extends AbstractChartState {}
interface GroupedBarChartProps extends AbstractChartProps {
  width: number;
  height: number;
  style?: StyleProp<ViewStyle>;
  data: GroupedBarChartData;
  onGroupClick?: (index: number, values: number[]) => void;
  nativeID?: string;
}

const VERTICAL_HEIGHT_PERCENTAGE = 0.8;
const LINE_COLOR = '#E6E9F4';

export class GroupedBarChart extends AbstractChart<GroupedBarChartProps, GroupedBarChartState> {
  getLocalPropsForHorizontalLabels() {
    const config = this.props.chartConfig,
      propsForHorizontalLabels = !config.propsForHorizontalLabels ? {} : config.propsForHorizontalLabels,
      color = config.color,
      labelColor = !config.labelColor ? color : config.labelColor;
    return { fill: labelColor(0.8), ...propsForHorizontalLabels };
  }

  localRenderHorizontalLabels({
    data,
    count,
    height,
    paddingTop,
    paddingRight,
    yAxisLabel = '',
    yAxisSuffix = '',
    yLabelsOffset = 5,
    formatYLabel,
  }) {
    formatYLabel = !formatYLabel
      ? function (yLabel) {
          return yLabel;
        }
      : formatYLabel;
    return new Array(count === 1 ? 1 : count).fill(1).map(function (_, index) {
      const i = index + 1;
      let yLabel = String(i * count);
      const label = (data[1] / count) * i;
      yLabel = '' + yAxisLabel + formatYLabel(label.toFixed(2)) + yAxisSuffix;
      const basePosition = height * VERTICAL_HEIGHT_PERCENTAGE;
      const x = paddingRight - yLabelsOffset;
      const y = height * VERTICAL_HEIGHT_PERCENTAGE - (basePosition / count) * i + paddingTop;
      return (
        <Text origin={x + ', ' + y} key={Math.random()} x={x} textAnchor="end" y={y} fontSize={10} fill="black">
          {yLabel}
        </Text>
      );
    });
  }

  localRenderHorizontalLines({ height, paddingRight, count }) {
    const basePosition = height * VERTICAL_HEIGHT_PERCENTAGE;
    return new Array(count + 1).fill(1).map((_, index) => {
      const y = (basePosition / count) * index;
      return <Line key={Math.random()} x1={paddingRight - 5} x2={paddingRight} y1={y} y2={y} stroke="#E6E9F4" />;
    });
  }

  renderBars({ data, width, height, paddingTop, paddingRight, border, colors }) {
    const onPressGroup = (index: number, values: number[]) => {
      if (this.props.onGroupClick) this.props.onGroupClick(index, values);
    };
    return data.map(function (item, index) {
      const barWidth = 14;
      const barSpace = 2;
      const ret = [];
      let h = 0;
      const sum = border;
      const barsAreaHeight = height * VERTICAL_HEIGHT_PERCENTAGE - paddingTop;
      const barCount = item.length;
      const groupWidth = barCount * barWidth + (barCount - 1) * 2;
      let localXC = 0;
      const xC = paddingRight + (index * (width - paddingRight)) / data.length;
      ret.push(
        <Rect
          key={Math.random()}
          x={xC + localXC - groupWidth / 2}
          y={0}
          rx={0}
          ry={0}
          width={groupWidth}
          height={barsAreaHeight}
          fill="transparent"
        />
      );
      for (let z = 0; z < barCount; z++) {
        h = barsAreaHeight * (item[z] / sum);
        const y = barsAreaHeight - h + paddingTop;
        ret.push(
          <Rect
            key={Math.random()}
            x={xC + localXC - groupWidth / 2}
            y={y}
            rx={0}
            ry={0}
            width={barWidth}
            height={h}
            fill={colors[z]}
          />
        );
        localXC += barWidth + barSpace;
      }

      return (
        <G key={'G' + index + '-' + Math.random()} onClick={() => onPressGroup(index, item)}>
          {ret}
        </G>
      );
    });
  }

  render() {
    const { style, height, width, data, yAxisLabel, yAxisSuffix } = this.props;

    const paddingTop = 15;
    const paddingRight = 50;
    const config = {
      width,
      height,
    };

    let max = 0;
    for (let i = 0; i < data.data.length; i++) {
      const actual = Math.max(...data.data[i]);
      if (actual > max) {
        max = actual;
      }
    }

    const contentHeight = height * VERTICAL_HEIGHT_PERCENTAGE;
    return (
      <View style={style} nativeID={this.props.nativeID}>
        <Svg height={height} width={width}>
          <G>
            <Line x1={paddingRight} y1={contentHeight} x2={width} y2={contentHeight} stroke={LINE_COLOR} />
            <Line x1={paddingRight} y1={contentHeight} x2={paddingRight} y2={0} stroke={LINE_COLOR} />
          </G>
          <G>
            {this.localRenderHorizontalLabels(
              assign({}, config, {
                count: 5,
                data: [0, max],
                paddingRight,
                paddingTop,
                yAxisLabel,
                yAxisSuffix,
              })
            )}
          </G>
          <G>
            {this.localRenderHorizontalLines(
              assign({}, config, {
                count: 5,
                data: [0, max],
                paddingRight,
                paddingTop,
              })
            )}
          </G>
          <G>
            {this.renderVerticalLabels(
              assign(assign({}, config), {
                labels: data.labels,
                paddingRight: paddingRight + 38,
                paddingTop: paddingTop + 20,
                horizontalOffset: 0,
              })
            )}
          </G>

          <G>
            {this.renderBars(
              assign(assign({}, config), {
                data: data.data,
                paddingTop,
                paddingRight: paddingRight + 38,
                border: max,
                colors: data.barColors,
              })
            )}
          </G>
        </Svg>
      </View>
    );
  }
}
