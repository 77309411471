import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { fullLogout, userLogout } from '~/api/axios';
import { usePolling } from '~/api/hooks/Polling';
import { useVerificationStatus } from '~/api/hooks/accounts/VerificationStatus';
import { Submit2FAChallengeRequest } from '~/api/models/authorisation/requests/Submit2FAChallengeRequest';
import { apiAccountDeletion } from '~/api/services/authorisation';
import { flex1, mb10, mt35, mv20 } from '~/common/commonStyles';
import { mobileContentPadding } from '~/common/mobileStyles';
import { OutlineButton } from '~/components/commonButton';
import { DashboardMobileHeader } from '~/components/dashboard/doctor/DashboardHeader/DashboardMobileHeader';
import { DashboardWebHeader } from '~/components/dashboard/doctor/DashboardHeader/DashboardWebHeader';
import { ContactUsComponent } from '~/components/help/contactUs/ContactUsComponent';
import { FaqComponent } from '~/components/help/faq/FaqComponent';
import { DeleteAccountConfirmationModal } from '~/components/modals/DeleteAccountConfirmationModal';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { VerificationStatusComponent } from '~/components/verification/VerificationStatusComponent';
import { TitleLayout } from '~/layouts/TitleLayout';
import { infoModal, useModalManager } from '~/providers/modal/ModalManagementContext';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { isPharmacyVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

export const VerificationPendingDashboard: React.FC = () => {
  const { isMobile } = useBreakpoints();
  const { registerModal, openModal, closeModalByName } = useModalManager();
  const { getVerificationStatus } = useVerificationStatus();

  const { startPolling, stopPolling } = usePolling(() => {
    getVerificationStatus();
  }, 60000);

  const deleteAccount = async (otpData: Submit2FAChallengeRequest) => {
    try {
      await apiAccountDeletion(otpData);
      fullLogout();
      infoModal({
        message: ['Sorry to see you go, your account was successfully deleted.'],
        type: ModalAlertTypeEnum.SUCCESS,
      });
      closeModalByName(ModalName.DeleteAccountConfirmation);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  useEffect(() => {
    registerModal(ModalName.DeleteAccountConfirmation, DeleteAccountConfirmationModal);

    startPolling({ immediate: true });

    return () => {
      stopPolling();
    };
  }, []);

  return (
    <View style={styles.container}>
      {!isMobile ? <DashboardWebHeader /> : <DashboardMobileHeader hideNotifications hideImage />}

      <ScrollView
        style={[flex1, { display: 'flex', backgroundColor: isMobile ? colors.lightPurple : colors.white }]}
        contentContainerStyle={[isMobile ? mobileContentPadding : null, { display: 'flex' }]}>
        <Grid>
          <Container alignSelf="flex-start" style={{ flex: 1, maxWidth: 648 }}>
            <Row style={mv20}>
              <Column>
                <VerificationStatusComponent />
              </Column>
            </Row>
            {!isPharmacyVersion() ? (
              <Row style={mv20}>
                <Column>
                  <TitleLayout
                    noContentPadding={isMobile}
                    noHeaderPadding={isMobile}
                    title="How can we help you?"
                    style={isMobile ? { backgroundColor: colors.lightPurple } : null}>
                    <FaqComponent />
                  </TitleLayout>
                </Column>
              </Row>
            ) : null}
            <Row>
              <Column>
                <ContactUsComponent whiteInputs={isMobile} />
              </Column>
            </Row>

            <Row style={mt35}>
              <Column span="auto" style={mb10}>
                <View>
                  <OutlineButton style={{ minWidth: 180 }} funCallback={userLogout}>
                    Logout
                  </OutlineButton>
                </View>
              </Column>
              {!isPharmacyVersion() ? (
                <Column span="auto" style={mb10}>
                  <View>
                    <OutlineButton
                      danger
                      style={{ minWidth: 180 }}
                      funCallback={() => {
                        openModal(ModalName.DeleteAccountConfirmation, {
                          onDelete: deleteAccount,
                          verificationPending: true,
                        });
                      }}
                      disabled={false}
                      label={labels.deleteAccount.toUpperCase()}
                    />
                  </View>
                </Column>
              ) : null}
            </Row>
          </Container>
        </Grid>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    flex: 1,
    display: 'flex',
  },
});
