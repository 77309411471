import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useOnDemandDoctorsQueue } from '../consultations/OnDemandDoctorHook';

import { AvailableDoctorModelWithQueue } from '~/api/models/appointments/models/AvailableDoctorModel';
import { apiGetClinicDoctors } from '~/api/services/clinics';
import { clinicDoctorsListing, SET_CLINIC_DOCTORS } from '~/redux/reducers/patient/doctorListingReducer';
import { useAppDispatch } from '~/redux/store';
import { getAccountName } from '~/utils/personalDetailsUtils';

export const useClinicDoctorListing = () => {
  const [loading, setLoading] = useState(true);
  const doctors = useSelector(clinicDoctorsListing);
  const { onDemandQueues } = useOnDemandDoctorsQueue({
    isOnDemand: true,
  });
  const dispatch = useAppDispatch();

  const doctorsWithQueue = useMemo<AvailableDoctorModelWithQueue[]>(() => {
    if (!doctors) return [];
    return doctors
      ?.map((doctor) => ({
        ...doctor,
        full_name: getAccountName(doctor),
        queue: onDemandQueues?.find((item) => item.doctor_account_id === doctor.id),
      }))
      .sort((a, b) => {
        if (a.queue?.queue === b.queue?.queue) return 0;
        if (!a.queue?.queue) return 1;
        if (!b.queue?.queue) return -1;
        return b.queue.queue - a.queue.queue;
      });
  }, [doctors, onDemandQueues]);

  const getDoctors = async () => {
    try {
      const res = await apiGetClinicDoctors();
      dispatch(SET_CLINIC_DOCTORS(res.data));
    } catch {
      dispatch(SET_CLINIC_DOCTORS(undefined));
    }
    setLoading(false);
  };

  return { getDoctors, doctors, doctorsWithQueue, loading };
};
