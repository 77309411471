import React from 'react';
import { useFormState } from 'react-hook-form';
import { View } from 'react-native';

import { mv15 } from '../../../common/commonStyles';
import { SettingsConsultationCosts } from '../../../components/doctor/settings/consultations/SettingsConsultationCosts';
import { SettingsConsultationMeans } from '../../../components/doctor/settings/consultations/SettingsConsultationMeans';
import { NestedPageInternalLayout } from '../../../layouts/NestedPageInternalLayout';
import { Container } from '../../../theme/components/grid';
import { labels } from '../../../utils/labels';
import { theme } from '../../../utils/theme';

import { useSettingsConsultationTypePrice } from '~/api/hooks/consultations/SettingsConsultationTypePriceHook';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import { SettingsConsultationLanguage } from '../../../components/doctor/settings/consultations/SettingsConsultationLanguage';
import { screenName } from '../../../utils/screenName';
import { LoadingActivityIndicator } from '../../../components/loading/LoadingActivityIndicator';
import { useConsultationPreferences } from '~/api/hooks/accounts/ConsultationPreferencesHook';

export const SettingsConsultations: React.FC = () => {
  const { hasFeatureAccess, isFeatureDisabled } = useFeatureAccess();
  const { control, submitConsultationPrices, reset, loading } = useSettingsConsultationTypePrice({
    saveChannelsAndLanguages: true,
  });

  const { isDirty } = useFormState({ control });
  const buttons = (
    <CancelContinueFooter
      onCancel={reset}
      onConfirm={submitConsultationPrices}
      confirmTitle={labels.update}
      disabled={!isDirty}
    />
  );

  return (
    <NestedPageInternalLayout
      backTitle="Settings"
      backLink={{ screen: screenName.Settings }}
      title={labels.consultations}
      footer={buttons}>
      {loading ? (
        <LoadingActivityIndicator style={{ minHeight: 170 }} />
      ) : (
        <>
          {hasFeatureAccess(FeatureAccessEnum.CONSULTATION_TYPES) ? (
            <>
              <SettingsConsultationCosts control={control} loading={loading} />
              <Container alignSelf="flex-start">
                <View style={[theme.borderLine, mv15]} />
              </Container>
            </>
          ) : null}
          {hasFeatureAccess(FeatureAccessEnum.CONSULTATION_CHANNELS) ? (
            <SettingsConsultationMeans
              control={control}
              reset={reset}
              disabled={isFeatureDisabled(FeatureAccessEnum.CONSULTATION_CHANNELS)}
            />
          ) : null}
          <SettingsConsultationLanguage control={control} reset={reset} />
        </>
      )}
    </NestedPageInternalLayout>
  );
};
