import React, { useEffect } from 'react';
import { View } from 'react-native';

import { mb10, textCenter } from '~/common/commonStyles';
import { ICALUrlModal } from '~/components/calendar/modals/ICALUrlModal';
import { AppointmentListingAndFiltering } from '~/components/common/appointments/appointmentListing/AppointmentListingAndFiltering';
import { AppointmentsFilterStrip } from '~/components/common/appointments/appointmentListing/components/AppointmentsFilterStrip';
import { AppointmentListMobileHeader } from '~/components/common/appointments/appointmentListing/mobile/AppointmentListMobileHeader';
import { ButtonLink, OutlineButton } from '~/components/commonButton';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { AppointmentListingProvider } from '~/providers/appointment/AppointmentListingProvider';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { Column, Row } from '~/theme/components/grid';
import { isPatientVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { calendarLabels } from '~/utils/labels/calendar';
import { patientLabels } from '~/utils/labels/patient';
import { screenName } from '~/utils/screenName';

export const AppointmentsScreen: React.FC = () => {
  const { registerModal, openModal } = useModalManager();
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    registerModal(ModalName.ICALUrlModal, ICALUrlModal);
  }, []);

  return (
    <AppointmentListingProvider>
      <PageLayout
        contentStyling={{ flex: 1 }}
        hideBack
        hideGradient
        noScrollPadding
        title={isPatientVersion() ? patientLabels.appointments : null}
        footer={
          isMobile ? (
            <View>
              <ButtonLink
                style={[mb10, textCenter]}
                label={patientLabels.bookAppointment}
                screenName={screenName.AppointmentsBooking}
              />
              <OutlineButton
                style={mb10}
                funCallback={() => {
                  openModal(ModalName.ICALUrlModal);
                }}
                label={calendarLabels.addToMyCalendar}
              />
            </View>
          ) : null
        }
        headerRight={
          isPatientVersion() ? (
            isMobile ? (
              <AppointmentListMobileHeader />
            ) : (
              <Row justifyContent="flex-end">
                <Column span="auto">
                  <OutlineButton
                    style={mb10}
                    funCallback={() => {
                      openModal(ModalName.ICALUrlModal);
                    }}
                    label={calendarLabels.addToMyCalendar}
                  />
                </Column>
                <Column span="auto">
                  <ButtonLink
                    style={mb10}
                    label={patientLabels.bookAppointment}
                    screenName={screenName.AppointmentsBooking}
                  />
                </Column>
              </Row>
            )
          ) : null
        }
        scrollContentNode={
          <>
            <AppointmentsFilterStrip />
            <AppointmentListingAndFiltering />
          </>
        }
      />
    </AppointmentListingProvider>
  );
};
