import { Route } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import { alignItemsRight, flex1, justifyEnd, mb10, mt10 } from '../../../common/commonStyles';
import { PatientDiagnosesList } from '../../../components/doctor/patients/PatientDiagnosesList';
import { PatientsLayout } from '../../../components/doctor/patients/PatientsLayout';
import { Column, Container, Grid, Row } from '../../../theme/components/grid';

import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { PatientDetailsMenu } from '~/components/PatientDetailsMenu';
import { TitledLinkButton } from '~/components/buttons/TitledLinkButton';
import { ButtonLink } from '~/components/commonButton';
import { H5TtmSemiBoldBlack } from '~/components/commonText';
import { PatientInformation } from '~/components/doctor/patients/PatientInformation';
import { Person } from '~/components/svgImages';
import { isClinicVersion, isDoctorVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { id: string }>;
}
export const PatientDetails: React.FC<Props> = ({ route }) => {
  const patientId = +route?.params?.id;
  const { isDesktop, isMobile } = useBreakpoints();

  const addConsultationButton = isDoctorVersion() ? (
    <ButtonLink
      label="ADD NEW CONSULTATION"
      to={{ screen: screenName.PatientNewConsultation, params: { id: patientId } } as never}
    />
  ) : null;
  const headerIcon = isMobile ? (
    <View style={[{ display: 'flex', flex: 1, alignItems: 'flex-end' }]}>
      <View>{addConsultationButton}</View>
    </View>
  ) : !isClinicVersion() ? (
    <PatientDetailsMenu />
  ) : null;
  const healthProfileLink = (
    <TitledLinkButton
      style={mt10}
      icon={isMobile ? <Person width={20} height={20} /> : null}
      title="Health Profile"
      to={{ screen: screenName.PatientHealthProfile as never, params: { id: patientId } as never }}
    />
  );

  const desktopContent = isDesktop ? (
    <Grid grid={{ noOuterGutter: true }}>
      <Container alignSelf="flex-start" style={flex1}>
        <Row>
          <Column span={{ xs: 12, lg: 3 }} style={mb10}>
            <PatientInformation id={patientId} />
            {healthProfileLink}
          </Column>
          <Column span={{ xs: 12, lg: 9 }} style={mb10}>
            <Row style={mb10}>
              <Column>
                <H5TtmSemiBoldBlack>{labels.diagnoses}</H5TtmSemiBoldBlack>
              </Column>
            </Row>

            <Row style={flex1}>
              <Column>
                <View style={flex1}>
                  <PatientDiagnosesList id={patientId} forceInfiniteScroll />
                </View>
              </Column>
            </Row>

            <Row justifyContent="flex-end" style={mt10}>
              <Column span="auto" children={addConsultationButton} />
            </Row>
          </Column>
        </Row>
      </Container>
    </Grid>
  ) : null;

  const mobileContent = !isDesktop ? (
    <PatientDiagnosesList
      id={patientId}
      forceInfiniteScroll
      contentStyle={[{ paddingHorizontal: isMobile ? MOBILE_HORIZONTAL_SPACE : 0 }]}
      listHeaders={
        <View style={[mb10]}>
          <PatientInformation id={patientId} />

          {healthProfileLink}
          <View
            style={[
              mt10,
              { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
            ]}>
            <H5TtmSemiBoldBlack>{labels.diagnoses}</H5TtmSemiBoldBlack>

            {!isMobile ? <View>{addConsultationButton}</View> : null}
          </View>
        </View>
      }
    />
  ) : null;

  return (
    <PatientsLayout
      back={{
        title: 'All patients',
        to: { screen: screenName.PatientManagement },
      }}
      headerIcon={headerIcon}
      children={!isDesktop ? null : desktopContent}
      scrollContentNode={!isDesktop ? mobileContent : null}
      flexContent={false}
    />
  );
};
