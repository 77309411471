import { get } from '~/api/axios';
import { IdModel } from '~/api/models/common/models/IdModel';
import { DoctorDashboardIncomeTotalRequest } from '~/api/models/dashboard/requests/DoctorDashboardIncomeTotalRequest';
import { DashboardStatsResponse } from '~/api/models/dashboard/responses/DashboardStatsResponse';
import { DoctorDashboardIncomeTotalResponse } from '~/api/models/dashboard/responses/DoctorDashboardIncomeTotalResponse';

export const apiGetPatientDashboardStats = () => {
  return get<DashboardStatsResponse>('patient/stats/totals');
};

export const apiGetPharmacyDashboardStats = (req: IdModel) => {
  return get<DashboardStatsResponse>(`pharmacy/${req.id}/stats/totals`);
};

export const apiGetDoctorDashboardIncomeTotals = (req: DoctorDashboardIncomeTotalRequest) => {
  return get<DoctorDashboardIncomeTotalResponse>(`/me/income/total`, req);
};
