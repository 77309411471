import React from 'react';

import { AddDependantWizard } from '~/components/patient/dependants/AddDependantWizard';
import { AddDependantProvider } from '~/providers/dependants/AddDependantProvider';

export const DependantsAddNewScreen: React.FC = () => {
  return (
    <AddDependantProvider>
      <AddDependantWizard />
    </AddDependantProvider>
  );
};
