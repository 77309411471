export const doctorLabels = {
  verification: {
    waitForVerification: [
      'Our team is currently reviewing your account. Please allow 2-3 working days for us to review your account. You will received a notification once this has been approved.',
      ' ',
      '1. All approved applications are subject to review, from time to time or as may be necessary, by Digimed Medical Committee; ',

      '',
      '2. Please notify Digimed Medical Committee at your earliest of any changes to the above that may occur.',
    ],
  },
  availabilities: {
    setHomeVisits: 'Set your availabilities for home visits to be able to start getting requests.',
    setScheduledAppointments:
      'Set your availabilities for scheduled appointments to be able to start getting requests.',
  },
};
