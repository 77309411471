import React, { ReactNode } from 'react';
import { View } from 'react-native';

import { STATUS_COLOR_MAPPING } from './constants/StatusColorMapping';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { mb10, mr10 } from '~/common/commonStyles';
import { H5TtmRegularInfo, H6NsRegularSecondaryBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { StatusBadge } from '~/components/misc/StatusBadge';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { idNameArrayToString } from '~/utils/arrayUtil';
import { parseDateTime } from '~/utils/dateAndTime';
import { getAccountName, getInitials } from '~/utils/personalDetailsUtils';

interface Props {
  itemData?: ConsultationModel;
  children: ReactNode;
}
export const InsuranceClaimItem: React.FC<Props> = ({ itemData, children }) => {
  return (
    <View style={{ flex: 1, width: '100%' }}>
      <View style={[{ ...mb10, flexDirection: 'row', flex: 1, flexGrow: 1, justifyContent: 'space-between' }]}>
        <View style={mr10}>
          <ProfileImageComponent
            src={itemData.doctor.profile_image?.conversions?.avatar ?? itemData.doctor.profile_image?.url}
            initials={getInitials({ first_name: itemData.doctor.first_name, last_name: itemData.doctor.last_name })}
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}>
            <StatusBadge
              style={{ alignSelf: 'flex-start' }}
              label={STATUS_COLOR_MAPPING[itemData.state]?.label}
              color={STATUS_COLOR_MAPPING[itemData.state]?.color}
              textStyle={{ textTransform: 'capitalize' }}
            />
            <H6NsSemiBoldBlack
              style={{ whiteSpace: 'pre-wrap', marginBottom: 5, marginRight: 5, flex: 1, minWidth: 80 }}>
              {getAccountName(itemData.doctor)}
            </H6NsSemiBoldBlack>
          </View>
          <H6NsRegularSecondaryBlack>
            {idNameArrayToString(itemData.doctor.areas_of_specialisation, 2)}
          </H6NsRegularSecondaryBlack>
        </View>
      </View>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
        <H5TtmRegularInfo>{parseDateTime(itemData.start_at, { outputFormat: 'D MMMM, HH:mm' })}</H5TtmRegularInfo>
        <H5TtmRegularInfo>{parseDateTime(itemData.end_at, { outputFormat: ' - HH:mm' })}</H5TtmRegularInfo>
      </View>
      <View style={{ alignItems: 'center' }}>{children}</View>
    </View>
  );
};
