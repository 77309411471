import { useEffect } from 'react';

import { useVerificationStatus } from '../accounts/VerificationStatus';
import { useGeneralChannel } from '../channels/useGeneralChannel';

import { AuthVerificationEnum } from '~/api/models/authorisation/constants/AuthVerificationEnum';
import { GeneralEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { successOrErrorPopup } from '~/common/commonMethods';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import PushNotifications from '~/integrations/pushNotifications/PushNotifications';
import { NotificationTypeEnum } from '~/integrations/pushNotifications/enums/NotificationTypeEnum';
import verificationPendingLabels from '~/utils/labels/verificationPendingLabels';

export function useVerificationStatusCheck() {
  const { getVerificationStatus, statusEnum } = useVerificationStatus();

  const getTitle = (status: AuthVerificationEnum) => {
    switch (status) {
      case AuthVerificationEnum.PENDING_ADMIN:
        return verificationPendingLabels.notificationPendingAdminTitle;
      case AuthVerificationEnum.APPROVED:
        return verificationPendingLabels.notificationAcceptedTitle;
      case AuthVerificationEnum.DISABLED:
        return verificationPendingLabels.notificationDisabledTitle;
      case AuthVerificationEnum.REJECTED:
        return verificationPendingLabels.notificationRejectedTitle;
    }
  };

  const getMessage = (status: AuthVerificationEnum) => {
    switch (status) {
      case AuthVerificationEnum.PENDING_ADMIN:
        return verificationPendingLabels.notificationPendingAdminMessage;
      case AuthVerificationEnum.APPROVED:
        return verificationPendingLabels.notificationAcceptedMessage;
      case AuthVerificationEnum.DISABLED:
        return verificationPendingLabels.notificationDisabledMessage;
      case AuthVerificationEnum.REJECTED:
        return verificationPendingLabels.notificationRejectedMessage;
    }
  };

  const checkForStatusChange = () => {
    const currentStatus = statusEnum;
    getVerificationStatus().then((latestStatus) => {
      if (latestStatus !== currentStatus && latestStatus !== AuthVerificationEnum.PENDING_KYI) {
        const success =
          latestStatus === AuthVerificationEnum.APPROVED || latestStatus === AuthVerificationEnum.PENDING_ADMIN;
        successOrErrorPopup(
          getMessage(latestStatus),
          true,
          success ? ModalAlertTypeEnum.SUCCESS : ModalAlertTypeEnum.ERROR,
          getTitle(latestStatus)
        );
      }
    });
  };

  const handleGeneralChannel = (event: GeneralEventsEnum) => {
    switch (event) {
      case GeneralEventsEnum.SUMSUB_ACCOUNT_UPDATED:
      case GeneralEventsEnum.USER_ROLE_STATUS_UPDATED:
        checkForStatusChange();
        break;
    }
  };

  useGeneralChannel({ onEvent: handleGeneralChannel });

  useEffect(() => {
    const removeRoleListener = PushNotifications.addNotificationTypeListener(
      NotificationTypeEnum.ROLE,
      checkForStatusChange
    );

    const removeKyiListener = PushNotifications.addNotificationTypeListener(
      NotificationTypeEnum.KYI,
      checkForStatusChange
    );

    return () => {
      if (removeKyiListener) removeKyiListener();
      if (removeRoleListener) removeRoleListener();
    };
  }, []);
}
