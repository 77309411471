import React, { useMemo } from 'react';
import { View } from 'react-native';
import { AppointmentShowPageLayout } from '../appointments/AppointmentShowPageLayout';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { mb20, justifyCenter, mt10 } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { Row, Column } from '~/theme/components/grid';
import { whenAppType } from '~/utils/buildConfig';
import { useNavigationAppointments } from '~/utils/hooks/AppNavigationHook';
import { DAY_MONTH_YEAR_TIME_FOMRAT, parseDateTime } from '~/utils/dateAndTime';
import { screenName } from '~/utils/screenName';
import { useNavigation } from '@react-navigation/native';
import { NavType } from '~/navigation/types';
import { CancellationReasonEnum } from '~/api/models/consultations/enums/CancellationReasonEnum';
import { getAccountName } from '~/utils/personalDetailsUtils';

interface Props {}

const getCancellationTitle = (cancellationReason: string, consultationEntity: string, date: string) => {
  switch (cancellationReason) {
    case CancellationReasonEnum.UNRESPONSIVE:
      return `Your consultation with ${consultationEntity} on ${date} has been cancelled due to the practitioner's unresponsiveness. We apologise for any inconvenience caused.`;
    case CancellationReasonEnum.CANCELLED_BY_ADMIN:
      return `Your consultation with ${consultationEntity} on ${date} has been cancelled by the Digimed administration. We apologise for any inconvenience caused.`;
    case CancellationReasonEnum.CANCELLED_BY_DOCTOR:
    case CancellationReasonEnum.CANCELLED_BY_CLINIC:
      return `Your consultation with ${consultationEntity} has been cancelled. We apologise for any inconvenience caused.`;
    case CancellationReasonEnum.CANCELLED_BY_PATIENT:
      return `Your consultation with ${consultationEntity} was successfully cancelled`;
    case CancellationReasonEnum.RESCHEDULED:
      return `Your consultation with ${consultationEntity} was rescheduled`;
    case CancellationReasonEnum.EXPIRED_PAYMENT_METHOD:
      return `We regret to inform you that your consultation request with ${consultationEntity} was cancelled due to an expired payment method.`;
    case CancellationReasonEnum.INSUFFICIENT_FUNDS:
      return `We regret to inform you that your consultation request with ${consultationEntity} was cancelled due to insufficient funds.`;
    case CancellationReasonEnum.CONSULTATION_RESCHEDULING_REJECTED:
      return `We regret to inform you that your consultation with ${consultationEntity} was rejected as the practitioner was unable to make it on time.`;
    default:
      return '';
  }
};

const getCancellationDescription = (cancellationReason: string, consultationEntity: string) => {
  switch (cancellationReason) {
    case CancellationReasonEnum.CANCELLED_BY_ADMIN:
    case CancellationReasonEnum.CANCELLED_BY_DOCTOR:
    case CancellationReasonEnum.CANCELLED_BY_CLINIC:
    case CancellationReasonEnum.UNRESPONSIVE:
      return `Your pre-authorised payment has been fully refunded to your card. \n\nPlease re-schedule your appointment with ${consultationEntity} or find another doctor on Digimed that can assist you immediately by clicking on the button below.`;
    case CancellationReasonEnum.CANCELLED_BY_PATIENT:
      return `If you would like to reschedule or create a new appointment, please feel free to explore alternative options.`;
    case CancellationReasonEnum.EXPIRED_PAYMENT_METHOD:
      return `Please see that your payment method is valid when booking your next consultation.`;
    case CancellationReasonEnum.INSUFFICIENT_FUNDS:
      return `Please ensure sufficient funds are available on your payment method when scheduling your next consultation.`;
    case CancellationReasonEnum.CONSULTATION_RESCHEDULING_REJECTED:
      return `Please re-schedule your appointment or find another doctor on Digimed that can assist you immediately by clicking on the button below.\n\nWe apologise for any inconvenience caused.`;
    default:
      return '';
  }
};

export const ConsultationNotFound: React.FC<Props> = () => {
  const { navigate } = useNavigationAppointments();
  const appointmentContext = useAppointmentContext();
  const navigation = useNavigation<NavType>();

  const doctor = useMemo(() => appointmentContext?.consultation?.doctor, [appointmentContext?.consultation?.doctor]);
  const clinic = useMemo(() => appointmentContext?.consultation?.clinic, [appointmentContext?.consultation?.clinic]);
  const patient = useMemo(() => appointmentContext?.consultation?.patient, [appointmentContext?.consultation?.patient]);
  const date = useMemo(
    () => parseDateTime(appointmentContext?.consultation?.start_at, { outputFormat: DAY_MONTH_YEAR_TIME_FOMRAT }),
    [appointmentContext?.consultation?.start_at]
  );
  const cancellationReason = useMemo(
    () => appointmentContext?.consultation?.cancellation_reason,
    [appointmentContext?.consultation?.cancellation_reason]
  );

  const consultationEntity = clinic
    ? `${getAccountName(appointmentContext?.consultation?.clinic)}`
    : `${getAccountName(appointmentContext?.consultation?.doctor)}`;

  const { message, buttonLabel, navigateCallback } = useMemo(() => {
    const state = appointmentContext?.consultation?.state;
    let buttonLabelContent = 'Back to appointments';
    let navigateContent: () => void = navigate;

    if (state === ConsultationStateEnum.Cancelled) {
      buttonLabelContent = 'Doctors Available Now';
      navigateContent = () => navigation.navigate(screenName.Doctors);

      const cancellationTitle = getCancellationTitle(cancellationReason, consultationEntity, date);
      const cancellationDescription = getCancellationDescription(cancellationReason, consultationEntity);

      return {
        message: (
          <>
            <H3TtmSemiBoldBlack>
              {whenAppType({
                whenPatient: cancellationTitle,
                whenDoctor: `Your consultation with ${patient} was cancelled. We apologise for any inconvenience`,
              })}
            </H3TtmSemiBoldBlack>
            <H6NsRegularBlack style={mt10}>
              {whenAppType({
                whenPatient: cancellationDescription,
                whenDoctor: '',
              })}
            </H6NsRegularBlack>
          </>
        ),
        buttonLabel: buttonLabelContent,
        navigateCallback: navigateContent,
      };
    }

    switch (state) {
      case ConsultationStateEnum.RequestIgnored:
        return {
          message: (
            <>
              <H3TtmSemiBoldBlack>
                {whenAppType({
                  whenPatient:
                    'We regret to inform you that your consultation request was not acknowledged by the recipient.',
                  whenDoctor: 'The request was not handled in time, and the request was marked as ignored.',
                })}
              </H3TtmSemiBoldBlack>
              <H6NsRegularBlack style={mt10}>
                {whenAppType({
                  whenPatient: 'Please try scheduling with alternative doctors and thank you for your understanding.',
                  whenDoctor: 'Please try to reply to the requests promptly.',
                })}
              </H6NsRegularBlack>
            </>
          ),
          buttonLabel: buttonLabelContent,
          navigateCallback: navigateContent,
        };
      case ConsultationStateEnum.RequestRejected:
        return {
          message: (
            <>
              <H3TtmSemiBoldBlack>
                {whenAppType({
                  whenPatient: 'Unfortunately, your consultation request has been declined by the recipient.',
                  whenDoctor: 'The consultation request was rejected.',
                })}
              </H3TtmSemiBoldBlack>
              <H6NsRegularBlack style={mt10}>
                {whenAppType({
                  whenPatient: `Your pre-authorised payment has been fully refunded to your card. \n\nPlease re-schedule your appointment with ${consultationEntity} or find another doctor on Digimed that can assist you immediately by clicking on the button below.`,
                  else: '',
                })}
              </H6NsRegularBlack>
            </>
          ),
          buttonLabel: buttonLabelContent,
          navigateCallback: navigateContent,
        };
      default:
        return {
          message: (
            <>
              <H3TtmSemiBoldBlack>We were unable to get your consultation information at this time.</H3TtmSemiBoldBlack>
              <H6NsRegularBlack style={mt10}>
                Please try again later or contact us if the problem persists.
              </H6NsRegularBlack>
            </>
          ),
          buttonLabel: buttonLabelContent,
          navigateCallback: navigateContent,
        };
    }
  }, [
    appointmentContext?.consultation?.state,
    appointmentContext?.consultation?.cancellation_reason,
    doctor,
    date,
    navigate,
    navigation,
  ]);

  return (
    <AppointmentShowPageLayout flexContent>
      <Row style={{ flex: 1 }} justifyContent="center">
        <Column alignSelf="center" style={{ maxWidth: 600 }}>
          <View style={{ alignItems: 'center' }}>
            <View style={[mb20, justifyCenter]}>{message}</View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Button label={buttonLabel} funCallback={navigateCallback} />
            </View>
          </View>
        </Column>
      </Row>
    </AppointmentShowPageLayout>
  );
};
