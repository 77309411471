import React from 'react';
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native';

import { BaseCard } from '../../common/BaseCard';
import { H5TtmRegularBlack, SmallNsRegularBlack, SmallNsRegularPurpleGrey } from '../../commonText';
import { RowView } from '../../commonViews';
import { DeleteIcon, Home, PencilEditIcon } from '../../svgImages';

import { HomeVisitWithIdModel } from '~/api/models/preferences/models/HomeVisitWithIdModel';
import { flex1, mr10, mr5 } from '~/common/commonStyles';
import { LabelValuePair } from '~/common/types/LabelValuePair';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';

interface Props {
  id: number;
  items: LabelValuePair<string>[];
  itemData: HomeVisitWithIdModel;
  removeHomeVisit: (id: number) => Promise<void>;
  updateHomeVisit: (data: HomeVisitWithIdModel) => void;
  disabled?: boolean;
}

export const HomeVisitCard: React.FC<Props> = ({ id, items, itemData, disabled, removeHomeVisit, updateHomeVisit }) => {
  const cardTitle = (
    <RowView>
      <RowView>
        <View style={mr10}>
          <Home width={15} height={15} />
        </View>
        <H5TtmRegularBlack>{labels.homeVisit}</H5TtmRegularBlack>
      </RowView>
      {disabled ? null : (
        <RowView>
          <TouchableOpacity style={[styles.iconBorder, mr5]} onPress={() => updateHomeVisit(itemData)}>
            <PencilEditIcon width={15} height={15} />
          </TouchableOpacity>
          <TouchableOpacity style={styles.iconBorder} onPress={() => removeHomeVisit(id)}>
            <DeleteIcon width={15} height={15} color={colors.danger} />
          </TouchableOpacity>
        </RowView>
      )}
    </RowView>
  );

  const cardContent = (
    <>
      {items?.map((element, index) => {
        return (
          <View key={index} style={styles.rowItem}>
            <View style={styles.itemKey}>
              <SmallNsRegularPurpleGrey>{element.label}</SmallNsRegularPurpleGrey>
            </View>
            <View style={styles.valueKey}>
              <SmallNsRegularBlack ellipsizeMode="tail" numberOfLines={2}>
                {element.value}
              </SmallNsRegularBlack>
            </View>
          </View>
        );
      })}
    </>
  );
  return <BaseCard title={cardTitle} content={cardContent} />;
};

const styles = StyleSheet.create({
  iconBorder: {
    padding: 8,
    borderColor: colors.lightPurple,
    borderWidth: 1,
    borderRadius: 6,
  },
  rowItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  itemKey: {
    minWidth: 125,
  },
  valueKey: {
    width: 150,
  },
});
