import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useState } from 'react';
import { View } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { UploadDocumentComponent } from '../buttons/UploadDocumentComponent';
import { Button, TextButton } from '../commonButton';
import { H3TtmSemiBoldBlack } from '../commonText';
import { AddImage } from '../svgImages';

import { mb20 } from '~/common/commonStyles';
import { labels } from '~/utils/labels';

interface Props extends BaseModalProps {
  savePhoto: (file?: DocumentPickerAsset) => void;
  waiting?: boolean;
}

export const ProfileImageUpload: React.FC<Props> = ({ savePhoto, waiting, onHide }) => {
  const [profileImage, setProfileImage] = useState<DocumentPickerAsset>(null);
  const [profileImageUrl, setProfileImageUrl] = useState<string>(null);

  const updateLocalProfileImage = async (documentResults: DocumentPickerAsset[]) => {
    const documentResult = documentResults.length ? documentResults[0] : undefined;
    setProfileImage(documentResult);
    if (documentResult) setProfileImageUrl(documentResult.uri);
    else setProfileImageUrl(null);
  };

  const save = async () => {
    savePhoto(profileImage);
  };

  const buttons = [
    <Button funCallback={save} label={labels.save} disabled={!profileImage || waiting} />,
    <TextButton label={labels.cancel} funCallback={onHide} />,
  ];
  return (
    <ModalContainer hideOnBackground={!waiting} onHide={onHide} buttons={buttons}>
      <View>
        <View style={[mb20, { display: 'flex', alignItems: 'center' }]}>
          <H3TtmSemiBoldBlack>Upload Image</H3TtmSemiBoldBlack>
        </View>
        <UploadDocumentComponent
          uploadedFiles={profileImage ? [profileImage] : null}
          uploadTitle={labels.uploadPhoto}
          icon={<AddImage height={30} width={30} />}
          hideState
          hideFileList
          setUploadedFiles={updateLocalProfileImage}
          square
          imageUri={profileImageUrl}
          resizeMode="cover"
          imagesOnly
        />
      </View>
    </ModalContainer>
  );
};
