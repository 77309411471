import { DocumentPickerAsset } from 'expo-document-picker';

import { get } from '../../axios';

import { UploadPatientDataIndexRequest } from '~/api/models/patientData/requests/UploadPatientDataIndexRequest';
import { UploadPatientErrorRequest } from '~/api/models/patientData/requests/UploadPatientErrorRequest';
import { UploadPatientDataIndexResponse } from '~/api/models/patientData/responses/UploadPatientDataIndexResponse';
import { UploadPatientErrorResponse } from '~/api/models/patientData/responses/UploadPatientErrorResponse';
import fileSystem from '~/integrations/fileSystem';

export const apiUploadPatientData = (params: { data: DocumentPickerAsset; send_email_to_patient: boolean }) => {
  return fileSystem.postDocumentResult(`/imports`, 'file', params.data, {
    send_email_to_patient: params.send_email_to_patient ? '1' : '0',
  });
};

export const apiPatientDataDownload = () => {
  return fileSystem.downloadStream(`/imports/sample-file`, { fileName: `patients_data.xlsx` });
};

export const apiGetUploadedPatientDataIndex = (req: UploadPatientDataIndexRequest) => {
  return get<UploadPatientDataIndexResponse>('/imports', req);
};

export const apiGetUploadPatientDataErrors = ({ id, req }: UploadPatientErrorRequest) => {
  return get<UploadPatientErrorResponse>(`/imports/${id}/validation-errors`, req);
};
