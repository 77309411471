import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { View } from 'react-native';

import { apiAddAccount } from '../../../api/services/accounts';
import { apiAddAddress } from '../../../api/services/addresses';
import { ProgressBars } from '../../../components/commonComponents';
import { PatientEditableDetails } from '../../../components/doctor/patients/details/PatientEditableDetails';
import { PatientEditableDetailsFormInterface } from '../../../components/doctor/patients/details/PatientEditableDetailsFormInterface';
import { PatientEditableHealthProfile } from '../../../components/doctor/patients/healthProfile/PatientEditableHealthProfile';
import { ErrorAlert } from '../../../components/modals/ErrorAlert';
import { IdentificationDocumentsEnum } from '../../../constants/documents';
import { labels } from '../../../utils/labels';

import { DoctorStartAssociationResponse } from '~/api/models/accounts/responses/DoctorStartAssociationResponse';
import { HasDigimedAccountResponse } from '~/api/models/accounts/responses/HasDigimedAccountResponse';
import { AssociationEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { privateAssociationRequestChannel } from '~/api/models/channel/enum/channelNames';
import { apiDoctorStartAssociation, apiGetHasDigimedAccount } from '~/api/services/accounts/association';
import { ErrorResponse } from '~/classes/errors/ErrorResponse';
import { ConfirmationAlert, InprogressAlert, SuccessAlert, successOrErrorPopup } from '~/common/commonMethods';
import { mb20 } from '~/common/commonStyles';
import { H2TtmSemiBoldBlack } from '~/components/commonText';
import { PatientAddNewHealthProfile } from '~/components/doctor/patients/healthProfile/PatientAddNewHealthProfile';
import { ModalAlertTypeEnum } from '~/components/modals/ModalEnums';
import { channelSubscribe, channelUnsubscribeAll } from '~/integrations/channels/PusherChannels';
import { NavType } from '~/navigation/types';
import { HealthProfileDataProvider } from '~/providers/healthProfile/HealthProfileDataProviders';
import { CLEAR_HEALTH_PROFILE } from '~/redux/reducers/healthProfileReducer';
import { useAppDispatch } from '~/redux/store';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { patientManagementLabels } from '~/utils/labels/patientManagement';
import { screenName } from '~/utils/screenName';

type LinkResolution = 'approved' | 'exists' | 'no_link' | 'declined' | 'skipped' | 'error';
export const PatientAddNew: React.FC = () => {
  const [addedAccount, setAddedAccount] = useState<number>();
  const [waiting, setWaiting] = useState(false);
  const { isMobile } = useBreakpoints();

  const navigation = useNavigation<NavType>();
  const dispatch = useAppDispatch();

  const requestAssociation = async (
    accountId: number
  ): Promise<{ success: boolean; error?: ErrorResponse; data?: DoctorStartAssociationResponse }> => {
    try {
      const res = await apiDoctorStartAssociation(accountId);
      return { success: true, data: res.data };
    } catch (e) {
      return { success: false, error: e };
    }
  };

  const linkAccount = async (
    details: PatientEditableDetailsFormInterface
  ): Promise<{
    result: LinkResolution;
    accountExists?: boolean;
    error?: ErrorResponse;
    account_id?: number;
  }> => {
    let accountCheck: HasDigimedAccountResponse;
    try {
      const res = await apiGetHasDigimedAccount({
        national_id_number:
          details.document_type === IdentificationDocumentsEnum.IDENTITY_CARD ? details.document_number : undefined,
        passport_number:
          details.document_type === IdentificationDocumentsEnum.PASSPORT ? details.document_number : undefined,
        date_of_birth: details.dob,
      });

      if (res.data.has_access && res.data.account_id)
        return { result: 'exists', account_id: res.data.account_id, accountExists: true };
      if (!res.data.is_user || !res.data.account_id) return { result: 'no_link', accountExists: false };
      accountCheck = res.data;
    } catch {
      ErrorAlert(patientManagementLabels.linkingErrorMessage);
      return { result: 'error', accountExists: false };
    }

    const requested = await requestAssociation(accountCheck.account_id);
    if (!requested.success) return { result: 'error', accountExists: true, error: requested.error };

    const resolved = await new Promise<LinkResolution>((resolve) => {
      let previousId: number = undefined;

      const unsubscribe = async () => {
        if (previousId) {
          await channelUnsubscribeAll(privateAssociationRequestChannel(previousId));
        }
      };

      const subscribe = async (requestId: number) => {
        await unsubscribe();
        previousId = requestId;
        await channelSubscribe(privateAssociationRequestChannel(requestId), handleNotification);
      };

      const handleNotification = (eventName: string, data: object) => {
        if (eventName === AssociationEventsEnum.ASSOCIATION_REQUEST_APPROVED) {
          resolve('approved');
          unsubscribe();
        } else if (eventName === AssociationEventsEnum.ASSOCIATION_REQUEST_DECLINED) {
          resolve('declined');
          unsubscribe();
        }
      };

      const showInProgress = () => {
        InprogressAlert(
          [
            `A notification has been sent to ${details.document_number}, please wait.`,
            ' ',
            'Once the patient confirms, you will have access to their personal details and health records.',
            'You can also skip this step if the patient is unable to confirm at this time.',
          ],
          {
            title: patientManagementLabels.alreadyExists,
            buttonText: labels.ok,
            buttonFunction: () => {
              setTimeout(() => showError(), 1500);
            },
            secondaryButtonText: 'RE-SEND NOTIFICATION',
            preventHideOnSecondary: true,
            secondaryButtonAction: async () => {
              const res = await requestAssociation(accountCheck.account_id);
              if (res.success) {
                await subscribe(res.data.id);
              }
            },
            hideOnBackground: false,
          }
        );
      };

      const showError = () => {
        successOrErrorPopup(
          patientManagementLabels.accountAlreadyExistsError,
          true,
          ModalAlertTypeEnum.ERROR,
          patientManagementLabels.awaitingApproval,
          patientManagementLabels.resendNotification,
          false,
          async () => {
            const res = await requestAssociation(accountCheck.account_id);
            if (res.success) {
              await subscribe(res.data.id);
              setTimeout(showInProgress, 1500);
            }
          },
          false,
          undefined,
          'Skip this stage',
          () =>
            unsubscribe().then(() => {
              resolve('skipped');
            })
        );
      };

      subscribe(requested.data.id);

      showInProgress();
    });

    return { result: resolved, accountExists: true, account_id: accountCheck.account_id };
  };

  const createAccount = async (details: PatientEditableDetailsFormInterface) => {
    try {
      const response = await apiAddAccount({
        email: details.email,
        country_code: details.country_code as string,
        mobile_number: details.mobile_number,
        gender: details.gender,
        first_name: details.firstName,
        last_name: details.lastName,
        date_of_birth: details.dob,
        national_id_number:
          details.document_type === IdentificationDocumentsEnum.IDENTITY_CARD ? details.document_number : undefined,
        passport_number:
          details.document_type === IdentificationDocumentsEnum.PASSPORT ? details.document_number : undefined,
        send_email_to_patient: details.send_email_to_patient,
      });
      const id = response.data.id;

      if (details.address && details.postcode && details.country) {
        await apiAddAddress({
          account_id: response.data.id,
          line_1: details.address,
          post_code: details.postcode,
          country_id: details.country as number,
        });
      }
      setAddedAccount(id);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const onSubmit = async (details: PatientEditableDetailsFormInterface) => {
    setWaiting(true);

    const linkResponse = await linkAccount(details);
    if (linkResponse.result === 'approved') {
      SuccessAlert(
        [`Patient ${details.document_number} has been added to your Patient Management System.`],
        patientManagementLabels.accountApproved,
        patientManagementLabels.viewPatientDetails,
        () => {
          navigation.navigate(screenName.PatientDetails, { id: linkResponse.account_id });
        }
      );
      return;
    } else if (linkResponse.result !== 'no_link') {
      const continueCreating = await new Promise<boolean>((resolve) => {
        switch (linkResponse.result) {
          case 'exists':
            ConfirmationAlert(
              [
                'A patient with the same identification and date of birth is already in your records.',
                'Press continue below to see their details',
              ],
              {
                title: patientManagementLabels.alreadyExists,
                okTitle: labels.continue,
                type: ModalAlertTypeEnum.WARNING,
                okFunction: () => {
                  navigation.navigate(screenName.PatientDetails, { id: linkResponse.account_id });
                },
                cancelFunction: () => {
                  resolve(false);
                },
              }
            );
            break;
          case 'error':
            ErrorAlert(linkResponse.error);
            resolve(false);
            break;
          case 'declined':
            ConfirmationAlert(patientManagementLabels.declinedLinkAccountMessage, {
              title: patientManagementLabels.declinedLinkAccountTitle,
              type: ModalAlertTypeEnum.ERROR,
              okFunction: () => resolve(true),
              okTitle: labels.continue,
              cancelFunction: () => resolve(false),
            });
            break;
          case 'skipped':
          default:
            resolve(true);
            break;
        }
      });
      if (!continueCreating) {
        setWaiting(false);
        return;
      }
    }

    await createAccount(details);
    setWaiting(false);
  };

  const header = (
    <View style={[mb20, isMobile ? { width: '100%' } : null]}>
      <H2TtmSemiBoldBlack style={mb20}>{addedAccount ? 'Edit patient' : 'Add patient'}</H2TtmSemiBoldBlack>
      <ProgressBars progress={addedAccount ? 1 : 0.5} noSpacing />
    </View>
  );

  usePageFocus(() => {
    setAddedAccount(null);
    dispatch(CLEAR_HEALTH_PROFILE());
  });

  const cancel = () => {
    navigation.navigate(screenName.PatientManagement);
  };

  const saved = () => {
    navigation.navigate(screenName.PatientDetails, { id: addedAccount } as never);
  };
  return !addedAccount ? (
    <PatientEditableDetails onSubmit={onSubmit} waiting={waiting} header={header} onCancel={cancel} />
  ) : isMobile ? (
    <PatientAddNewHealthProfile patientId={addedAccount} header={header} onComplete={saved} />
  ) : (
    <HealthProfileDataProvider accountId={addedAccount} onSaved={saved}>
      <PatientEditableHealthProfile editing header={header} onCancel={cancel} />
    </HealthProfileDataProvider>
  );
};
