import React, { useMemo, useRef, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { Gradient } from '../Gradient';
import {
  ConsultationReportComponent,
  ConsultationReportComponentRef,
} from '../consultations/ConsultationReportComponent';

import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { apiSubmitConsultation } from '~/api/services/consultations';
import { InprogressAlert, SuccessAlert } from '~/common/commonMethods';
import { m10, mb10, mt10 } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { H3TtmRegularBlack, SmallNsRegularBlack } from '~/components/commonText';
import { CreateDiagnosisEditingComponent } from '~/components/doctor/patients/consultation/diagnosis/CreateDiagnosisEditingComponent';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { AutoSaveState } from '~/pages/doctor/patients/consultation/AutoSaveState';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { DiagnosisProvider } from '~/providers/diagnosis/DiagnosisProvider';
import { isDoctorVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { errorMessages } from '~/utils/labels/errorMessages';
import { patientManagementLabels } from '~/utils/labels/patientManagement';

export interface Props {
  noScroll?: boolean;
}
export const DoctorAppointmentReportView: React.FC<Props> = ({ noScroll }) => {
  const { consultation, updateConsultationDiagnosis, updateConsultation } = useAppointmentContext();
  const consultationReportRef = useRef<ConsultationReportComponentRef>();
  const [waiting, setWaiting] = useState<boolean>(false);
  const { isMobile } = useBreakpoints();

  const validate = async () => {
    const valid = await consultationReportRef.current.verify();
    if (!valid) throw new Error('Some fields are not valid, can you please check them out to be able to save');
  };

  const showDiagnosisInput = useMemo(
    () => consultation?.state === ConsultationStateEnum.Ended && !consultation.diagnosis,
    [consultation]
  );

  const submitDiagnosis = async (diagnosisId: number) => {
    try {
      await updateConsultationDiagnosis(diagnosisId);
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const handleSubmit = async () => {
    try {
      setWaiting(true);
      // setIsSubmitted(true);
      await validate();

      const includeSignature = consultationReportRef.current.getIncludeSignature();
      if (!includeSignature) {
        throw new Error(errorMessages.signatureRequiredModalMessage);
      }

      InprogressAlert(['Saving your latest changes'], { title: 'Submitting consultation', block: true });
      await consultationReportRef.current.submit();

      await apiSubmitConsultation({
        id: consultation.id,
        req: { include_signature: consultationReportRef.current.getIncludeSignature() },
      });

      await updateConsultation();

      SuccessAlert(['Consultation successfully submitted']);
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const editable = useMemo(() => {
    return (consultation?.state !== ConsultationStateEnum.Submitted && isDoctorVersion()) ?? false;
  }, [consultation]);

  const canSubmit = useMemo(
    () => consultation?.state === ConsultationStateEnum.Ended && isDoctorVersion(),
    [consultation]
  );

  const content = showDiagnosisInput ? (
    <DiagnosisProvider patientId={consultation.patient.id} onSubmit={submitDiagnosis} hideCancel>
      <CreateDiagnosisEditingComponent hideReason />
    </DiagnosisProvider>
  ) : (
    <>
      <ConsultationReportComponent ref={consultationReportRef} consultation={consultation} hideTitle appointmentView />

      {isMobile || canSubmit ? (
        <View style={{ maxWidth: 350, marginTop: 20 }}>
          <SmallNsRegularBlack>{patientManagementLabels.consultationEnsureDetailsCorrect}</SmallNsRegularBlack>
        </View>
      ) : null}
    </>
  );
  return (
    <View
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: noScroll ? 'flex-start' : 'space-between',
      }}>
      <H3TtmRegularBlack style={[mb10, mt10]}>{labels.consultationReport}</H3TtmRegularBlack>
      {noScroll ? (
        <View style={{ flex: 1, paddingBottom: 20 }}>{content}</View>
      ) : (
        <View style={{ flex: 1, display: 'flex' }}>
          <ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingRight: 10, paddingBottom: 20 }}>
            {content}
          </ScrollView>
          <Gradient />
        </View>
      )}
      {!showDiagnosisInput && editable ? (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: isMobile ? 10 : 0,
          }}>
          {!isMobile && !canSubmit ? (
            <View style={{ display: 'flex', alignItems: 'center', paddingHorizontal: 10, maxWidth: 350, flex: 1 }}>
              <SmallNsRegularBlack>{patientManagementLabels.consultationEnsureDetailsCorrect}</SmallNsRegularBlack>
            </View>
          ) : null}
          <AutoSaveState style={m10} />
          {canSubmit ? (
            <View>
              <Button label="Submit" funCallback={handleSubmit} style={{ minWidth: 180 }} disabled={waiting} />
            </View>
          ) : null}
        </View>
      ) : null}
    </View>
  );
};
