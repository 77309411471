export const insuranceLabels = {
  insuranceTitle: 'Insurance',
  insurancePolicyHolderNumber: 'Insurance policy number',
  policyNumber: 'Policy number',
  groupNameIfApplicable: 'Group / Company name (if applicable)',
  groupName: 'Group / Company name',

  companyName: 'Insurance company name',
  coverPeriodFrom: 'From DD/MM/YYY',
  coverPeriodTo: 'To DD/MM/YYY',
  periodToLabel: 'To',
  periodFromLabel: 'From',
  policyHolder: 'Policy holder',
  dependent: 'Dependent',
  insuranceCompanyName: 'Insurance company name',
  beneficiary: 'Beneficiary',
  iban: 'IBAN',
  bic: 'BIC',
  insuranceDetails: 'Insurance details',
  insuranceDetailsDescription:
    'Save your insurance details in your Digimed Profile. This will allow you to generate insurance claim forms for any consultations on Digimed and submit these to your insurer.\nIf you cannot find your insurer in the list below, this means that they have not yet signed up to accept claim forms from Digimed.',
  coverPeriod: 'Cover period',
  policyHolderDetails: 'Add the Main Policyholder personal details',
  policyHolderDetailsDescription: 'Please provide the information below',
  bankDetails: 'Bank Details',
  bankDetailsDescription: 'Enter the details of the bank account you wish to receive claim reimbursements to.',
  type: 'Type',
  insuranceClaimForm: 'Insurance claim form',
};
