import { Route, useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { View } from 'react-native';

import { apiUpdateAccounts } from '~/api/services/accounts';
import { apiAddAddress, apiUpdateAddress } from '~/api/services/addresses';
import { H2TtmSemiBoldBlack } from '~/components/commonText';
import { PatientEditableDetails } from '~/components/doctor/patients/details/PatientEditableDetails';
import { PatientEditableDetailsFormInterface } from '~/components/doctor/patients/details/PatientEditableDetailsFormInterface';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { IdentificationDocumentsEnum } from '~/constants/documents';
import { NavType } from '~/navigation/types';
import { labels } from '~/utils/labels';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { id: string }>;
}
export const PatientEditDetails: React.FC<Props> = ({ route }) => {
  const patientId = +route.params.id;

  const [waiting, setWaiting] = useState(false);

  const navigation = useNavigation<NavType>();

  const onSubmit = async (details: PatientEditableDetailsFormInterface) => {
    try {
      setWaiting(true);
      await apiUpdateAccounts({
        id: patientId,
        data: {
          email: details.email,
          country_code: details.country_code as string,
          mobile_number: details.mobile_number,
          gender: details.gender,
          first_name: details.firstName,
          last_name: details.lastName,
          date_of_birth: details.dob,
          national_id_number:
            details.document_type === IdentificationDocumentsEnum.IDENTITY_CARD ? details.document_number : undefined,
          passport_number:
            details.document_type === IdentificationDocumentsEnum.PASSPORT ? details.document_number : undefined,
        },
      });

      const addressData = {
        account_id: patientId,
        line_1: details.address,
        post_code: details.postcode,
        country_id: details.country as number,
      };
      if (details.addressId) {
        await apiUpdateAddress({
          id: details.addressId,
          data: addressData,
        });
      } else if (details.postcode && details.country) {
        await apiAddAddress(addressData);
      }

      navigation.navigate(screenName.PatientDetails, { id: patientId });
    } catch (e) {
      ErrorAlert(e);
    }
    setWaiting(false);
  };

  const header = (
    <View>
      <H2TtmSemiBoldBlack>{labels.editPatient.toUpperCase()}</H2TtmSemiBoldBlack>
    </View>
  );

  const cancel = () => {
    navigation.navigate(screenName.PatientDetails, { id: patientId });
  };

  return (
    <PatientEditableDetails
      onSubmit={onSubmit}
      waiting={waiting}
      header={header}
      onCancel={cancel}
      patientId={patientId}
    />
  );
};
