import { DocumentPickerAsset } from 'expo-document-picker';
import { ImagePickerAsset } from 'expo-image-picker';

import { isMediaModel } from './isMediaModel';

import { MediaModel } from '~/api/models/common/models/MediaModel';
import { ErrorMoreInfoType } from '~/classes/errors/ErrorMoreInfoType';
import { ErrorWithMoreInfo } from '~/classes/errors/ErrorWithMoreInfo';

const FILE_SIZE_MB_LIMIT = 4;
const FILE_SIZE_LIMIT = FILE_SIZE_MB_LIMIT * 1024 * 1024;

export const imagePickerAssetToDocumentResult = (image: ImagePickerAsset): DocumentPickerAsset => {
  return {
    name: image.fileName,
    size: image.fileSize,
    uri: image.uri,
  };
};

export const imagePickersAssetsToDocumentResults = (images: ImagePickerAsset[]): DocumentPickerAsset[] => {
  return images.map((image) => imagePickerAssetToDocumentResult(image));
};

const getFileSize = (file: ImagePickerAsset | DocumentPickerAsset) => {
  return 'size' in file ? file.size : 'fileSize' in file ? file.fileSize : 0;
};
export const fileSizeCheck = (files: (ImagePickerAsset | DocumentPickerAsset)[], isImage: boolean) => {
  const totalSize = files.reduce((total, file) => total + getFileSize(file), 0);
  if (totalSize > FILE_SIZE_LIMIT) {
    const fileType = isImage ? 'image' : 'file';
    throw new ErrorWithMoreInfo(
      `The ${fileType} you are trying to upload is too big.\nPlease use ${
        isImage ? 'an' : 'a'
      } ${fileType} with a total size of up to ${FILE_SIZE_MB_LIMIT}MB.\n\nFor more information on how you can reduce your file size, please click on learn more below.`,
      ErrorMoreInfoType.ReduceFileSize
    );
  }
};

export const imageUri = (image: MediaModel | DocumentPickerAsset) => {
  if (isMediaModel(image)) {
    return image?.url ?? '';
  } else if (image) {
    return image?.uri ?? '';
  }
  return '';
};
