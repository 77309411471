import { isBefore, isToday as isTodayDateFns, startOfDay } from 'date-fns';
import moment, { Moment } from 'moment';

export const CURRENT_DATE = moment().format('DD/MM/YYYY');

export const getYearWithChange = (
  yearChange: number,
  options: { outputFormat: string } = { outputFormat: DATE_FORMAT }
) => {
  const setYearChange = moment(new Date().setFullYear(new Date().getFullYear() + yearChange)).format(
    options.outputFormat
  );
  return setYearChange;
};
export const momentObj = moment();
export const sameOrAfter = (from, to) => {
  return moment(from).isSameOrAfter(to);
};
export const getDifferenceFromTime = (fromTime, toTime, options?: { parseFormat?: string }) => {
  const from = moment(fromTime, options?.parseFormat);
  const to = moment(toTime, options?.parseFormat);
  return from.diff(to, 'minutes');
};

export const parseDateTime = (
  date: Date | Moment | string | null | '',
  options?: { parseFormat?: string; outputFormat: string }
) => {
  if (date) {
    return moment(date, options?.parseFormat).format(options?.outputFormat);
  }
  return null;
};
export const parseMoment = (date: Date | string | null | '', options?: { parseFormat?: string }) => {
  if (date) {
    return moment(date, options?.parseFormat);
  }
  return null;
};
export const stringToDate = (date: Date | string | null | '', options?: { parseFormat?: string }) => {
  if (date) {
    return moment(date, options?.parseFormat).toDate();
  }
  return null;
};

export const calculateAge = (date: Date | string) => {
  if (!date) return 0;
  return moment().diff(date, 'years');
};

export const currentDateTime = (format = LARAVEL_DATE_TIME) => {
  return moment().format(format);
};

export const displayTimeRange = (startDate?: string, endDate?: string) => {
  if (startDate && endDate && startDate === endDate) return startDate;
  else if (startDate && endDate) return `${startDate} - ${endDate}`;
  else if (startDate) return startDate;
  else if (endDate) return endDate;
  return '';
};

export const isPastDate = (date: Date | string) => {
  return isBefore(startOfDay(stringToDate(date)), startOfDay(new Date()));
};
export const isPastDateTime = (date: Date | string) => {
  return isBefore(stringToDate(date), new Date());
};
export const isToday = (date: Date | string) => {
  if (!date) return false;
  return isTodayDateFns(stringToDate(date));
};

//Format Constants
export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_2DIGIT_YEAR = 'DD/MM/YY';
export const DAY_MONTH_FORMAT = 'D, MMMM';
export const DAY_MONTH_YEAR_FORMAT = 'D MMMM YYYY';
export const DAY_MONTH_YEAR_TIME_FOMRAT = 'D MMMM YYYY HH:mm';
export const DAY_MONTH_LONG_FORMAT = 'ddd D MMMM';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const STRING_DATE_FORMAT = 'YYYY-MM-dd';
export const NUM_DATE_FORMAT = 'YYYY-MM-DD';
export const LARAVEL_DATE_TIME_SHORT = 'YYYY-MM-DD HH:mm';
export const LARAVEL_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
export const FULL_DATE_FORMAT = 'dddd, MMMM Do YYYY';
export const SHORT_DATE_FORMAT = 'ddd, MMMM Do';
export const MONTH_YEAR_FORMAT = 'MMMM yyyy';
export const LARAVEL_ATOM = 'YYYY-MM-DD[T]HH:mm:ssZ';
