import React from 'react';
import { ScrollView, StyleSheet, ViewStyle } from 'react-native';

import { flex1 } from '~/common/commonStyles';
import { DashboardCard } from '~/components/dashboard/common/DashboardCard';
import { PatientWebHeader } from '~/components/dashboard/patient/DashboardHeader/PatientWebHeader';
import { DetailsList } from '~/components/dashboard/patient/DetailsList';
import { DiagnosisList } from '~/components/dashboard/patient/Diagnosis/DiagnosisList';
import { DoctorsDashboardListing } from '~/components/dashboard/patient/Doctor/DoctorsDashboardListing';
import { EducationCentreList } from '~/components/dashboard/patient/EducationCentreList';
import { CompleteProfile } from '~/components/dashboard/patient/completion/CompleteProfile';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const PatientWebDashboard: React.FC = () => {
  const { breakpoint } = useBreakpoints();
  let columnMarginStyle: ViewStyle = { marginBottom: 20 };

  if (breakpoint === 'xl') {
    columnMarginStyle = { height: '100%', maxHeight: '100%' };
  }

  return (
    <ScrollView
      style={{ backgroundColor: colors.white, paddingRight: 5 }}
      contentContainerStyle={{ height: '100%', padding: 5 }}
      showsVerticalScrollIndicator={false}
      scrollEnabled={breakpoint !== 'xl'}>
      <Grid>
        <Container alignSelf="flex-start" style={{ height: '100%' }}>
          <Row>
            <Column>
              <PatientWebHeader />
            </Column>
          </Row>
          <DetailsList />
          <Row style={[flex1, styles.row]}>
            <Column span={{ xs: 12, lg: 12, xl: 4 }} style={columnMarginStyle}>
              <DoctorsDashboardListing />
            </Column>
            <Column span={{ xs: 12, lg: 12, xl: 4 }} style={columnMarginStyle}>
              <DiagnosisList />
            </Column>
            <Column span={{ xs: 12, lg: 12, xl: 4 }} style={columnMarginStyle}>
              <CompleteProfile
                nodeWhenComplete={
                  <DashboardCard style={{ height: '100%', display: 'flex' }}>
                    <EducationCentreList scrollable />
                  </DashboardCard>
                }
              />
            </Column>
          </Row>
        </Container>
      </Grid>
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  row: {
    marginTop: 22,
  },
});
