import { Route, useNavigation } from '@react-navigation/native';
import React from 'react';

import { mv10 } from '../../../common/commonStyles';
import { OutlineButton } from '../../../components/commonButton';
import { H3TtmSemiBoldBlack, SmallNsRegularSecondaryBlack } from '../../../components/commonText';
import { GeneralProps } from '../../../interfaces/generalProps';
import { NestedPageInternalLayout } from '../../../layouts/NestedPageInternalLayout';
import { Container, Row, Column } from '../../../theme/components/grid';
import { labels } from '../../../utils/labels';

import { Splitter } from '~/components/misc/Splitter';
import { NavType } from '~/navigation/types';
import { whenAppType } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

interface Props extends GeneralProps {
  route: Route<string, (object & { specialvalue: string; name: string }) | undefined>;
}

export const SettingsAccount: React.FC<Props> = ({ navigation }) => {
  const { navigate } = useNavigation<NavType>();

  const hideExport = whenAppType({
    else: false,
    whenPharmacist: true,
    whenClinic: true,
  });

  return (
    <NestedPageInternalLayout backTitle="Settings" backLink={{ screen: screenName.Settings }} title={labels.account}>
      <Container alignSelf="flex-start">
        {hideExport ? null : (
          <>
            <Row>
              <Column span={{ xs: 12, lg: 6 }}>
                <H3TtmSemiBoldBlack>{labels.requestAccountInfo}</H3TtmSemiBoldBlack>
                <SmallNsRegularSecondaryBlack style={mv10}>
                  {labels.requestAccountInfoDesc}
                </SmallNsRegularSecondaryBlack>
                <OutlineButton
                  style={{ width: '50%', marginVertical: 15 }}
                  funCallback={() => {
                    navigate(screenName.SettingsExportData);
                  }}
                  disabled={false}
                  label={labels.requestReport.toUpperCase()}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Splitter />
              </Column>
            </Row>
          </>
        )}
        <Row>
          <Column span={{ xs: 12, lg: 6 }}>
            <H3TtmSemiBoldBlack>{labels.deleteAccount}</H3TtmSemiBoldBlack>
            <SmallNsRegularSecondaryBlack style={mv10}>{labels.deleteAccountInfo}</SmallNsRegularSecondaryBlack>
            <OutlineButton
              danger
              style={{ width: '50%', marginVertical: 15 }}
              funCallback={() => {
                navigate(screenName.PermanentlyDeleteAccount);
              }}
              disabled={false}
              label={labels.deleteAccount.toUpperCase()}
            />
          </Column>
        </Row>
      </Container>
    </NestedPageInternalLayout>
  );
};
