import { Link } from '@react-navigation/native';
import { View, Text, Platform, SafeAreaView, TouchableOpacity, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Surface } from 'react-native-paper';
import styled from 'styled-components/native';

import { colors } from '../utils/colors';
import { fontFamily } from '../utils/fontFamily';

const backgroundColor = 'backgroundColor';
const justifyContent = 'justifyContent';
const paddingVertical = 'paddingVertical';
const paddingHorizontal = 'paddingHorizontal';
const marginHorizontal = 'marginHorizontal';
const marginVertical = 'marginVertical';
const borderRadius = 'borderRadius';
// define your styles
const rawStyles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mainContainer: {
    flex: 1,
  },
  subContainer: {
    flex: 1,
    paddingHorizontal: 20,
  },
  commonButtonTextStyle: {
    fontFamily: fontFamily.fontSemiBold,
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.white,
  },
  TextButtonFontStyle: {
    fontFamily: fontFamily.fontSemiBold,
    fontSize: 14,
    textAlign: 'center',
    color: colors.purple,
    paddingVertical: 10,
  },
  outlineButtonStyle: {
    flexDirection: 'row',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.purple,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    width: '100%',
  },
  outlineButtonTextStyle: {
    fontSize: 14,
    // paddingVertical: 9,
    // marginVertical: 15,
    fontFamily: fontFamily.fontSemiBold,
    color: colors.purple,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  commonButtonStyle: {
    backgroundColor: colors.purple,
    width: '100%',
    padding: 9,
    borderRadius: 5,
    alignSelf: 'center',
    overflow: 'hidden',
  },
  smallButtonStyle: {
    backgroundColor: colors.purple,
    paddingVertical: 9,
    marginVertical: 15,
    borderRadius: 5,
    alignSelf: 'center',
    paddingHorizontal: 55,
  },
  shadowButtonStyle: {
    backgroundColor: colors.white,
    color: colors.black,
    borderRadius: 6,
    paddingVertical: 9,
    paddingHorizontal: 8,
    shadowColor: colors.purple,
    shadowOffset: 0,
    shadowRadius: 4,
    alignSelf: 'center',
  },
};

const {
  row,
  mainContainer,
  subContainer,
  smallButtonStyle,
  commonButtonStyle,
  commonButtonTextStyle,
  outlineButtonTextStyle,
  outlineButtonStyle,
  TextButtonFontStyle,
  shadowButtonStyle,
} = rawStyles;

export const RowView = styled(View)<{ height?: number }>`
  ${row};
  ${(props) => (props.height ? 'height:' + props.height + 'px;' : '')}
`;

export const KeyboardAScrollView = styled(KeyboardAwareScrollView).attrs({
  showsVerticalScrollIndicator: false,
  keyboardShouldPersistTaps: 'handled',
  enableAutomaticScroll: true,
  extraHeight: Platform.OS === 'ios' ? 90 : 75,
})``;

export const TopNotch = styled(SafeAreaView)<{ secondary?: boolean }>`
  flex: 0;
  ${backgroundColor}: ${(props) => (props.secondary ? colors.lightPurple : colors.white)};
`;

export const MainContainer = styled(SafeAreaView)<{ secondary?: boolean }>`
  ${mainContainer};
  ${backgroundColor}: ${(props) => (props.secondary ? colors.lightPurple : colors.white)};
`;

export const SubContainer = styled(View)<{
  secondary?: boolean;
  space_between?: boolean;
}>`
  ${subContainer};
  ${backgroundColor}: ${(props) => (props.secondary ? colors.lightPurple : colors.white)};
  ${(props) => (props.space_between ? 'justifyContent:space-between' : '')}
`;

export const CardSurface = styled(Surface)<{
  width?: number;
  height?: number;
  ph?: number;
  pv?: number;
  mh?: number;
  mv?: number;
  elevation?: number;
}>`
  ${justifyContent}: center;
  ${borderRadius}: 6px;
  ${marginVertical}: ${(props) => (props.mv !== undefined && props.mv !== null ? props.mv : '10px')};
  ${marginHorizontal}: ${(props) => (props.mh !== undefined && props.mh !== null ? props.mh : '1px')};
  ${paddingHorizontal}: ${(props) => (props.ph !== undefined && props.ph !== null ? props.ph : '10px')};
  ${paddingVertical}: ${(props) => (props.pv !== undefined && props.pv !== null ? props.pv : '10px')};
  ${(props) => (props.height ? 'height:' + props.height : '')}
  ${(props) => (props.width ? 'width:' + props.width : '')}
    elevation: ${(props) => props.elevation ?? 4};
`;

export const ButtonText = styled(Text)`
  ${commonButtonTextStyle};
`;
export const TextButtonText = styled(Text)<{ style: StyleProp<TextStyle> }>`
  ${TextButtonFontStyle};
  ${(props) => props.style};
`;
export const OutlineButtonText = styled(Text)`
  ${outlineButtonTextStyle};
`;

export const CommonSmallButton = styled.TouchableOpacity`
  ${smallButtonStyle};
`;
export const CommonButton = styled(TouchableOpacity)<{
  style: StyleProp<ViewStyle>;
}>`
  ${commonButtonStyle};
  ${(props) => props.style};
`;
export const CommonOutlineButton = styled(TouchableOpacity)`
  ${outlineButtonStyle};
`;
export const CommonTextButton = styled(TouchableOpacity)``;

export const CommonButtonLink = styled(Link)<{
  style: StyleProp<TextStyle>;
}>`
  ${commonButtonStyle};
  ${(props) => props.style};
`;

export const CommonOutlineButtonLink = styled(Link)<{
  style: StyleProp<TextStyle>;
}>`
  ${outlineButtonStyle};
  ${(props) => props.style};
`;

export const CommonShadowButton = styled(TouchableOpacity)<{
  style: StyleProp<ViewStyle>;
}>`
  shadow-opacity: 0.16;
  ${shadowButtonStyle};
  ${(props) => props.style};
`;
