import { DocumentPickerAsset } from 'expo-document-picker';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { HealthProfileDataContext } from './HealthProfileDataContext';
import { HealthMetricsFormModel } from './forms/HealthMetricsFormModel';
import {
  DietaryRestrictionsFormModel,
  LifestyleRadioFormsModel,
  ProsthesisFormModel,
  RecreationalDrugsFormModel,
  SocialFactorsFormModel,
} from './forms/HealthProfileFormModels';

import { useHasWriteAccess } from '~/api/hooks/accounts/HasWriteAccessHook';
import { ClinicalTermLabelValue } from '~/api/hooks/clinicalTerms/ClinicalTermsSearchHook';
import { useHealthProfile } from '~/api/hooks/healthProfile/HealthProfileHook';
import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { HealthProfileModel } from '~/api/models/healthProfile/Models/HealthProfileModel';
import { HealthProfileClinicalTermResponse } from '~/api/models/healthProfile/responses/HealthProfileClinicalTermResponse';
import { apiUpdateOrCreateHealthProfile } from '~/api/services/healthProfile';
import { apiUpdateDietaryRestrictions } from '~/api/services/healthProfile/dietaryRestrictions';
import { apiUpdateProsthesisImplantsGrafts } from '~/api/services/healthProfile/prosthesis';
import { apiUpdateRecreationalDrugs } from '~/api/services/healthProfile/recreationalDrugs';
import { apiUpdateSocialFactors } from '~/api/services/healthProfile/socialFactors';
import { setFileChanges } from '~/api/utils/healthProfile/profileHistoryFileHandler';
import { InprogressAlert, SuccessAlert } from '~/common/commonMethods';
import { PatientHistoryData } from '~/components/doctor/patients/healthProfile/models/PatientHistoryData';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import {
  selectHealthProfile,
  selectProsthesisImplantsGrafts,
  selectDietaryRestrictions,
  selectRecreationalDrugs,
  selectAllergiesProfileList,
  selectMedicalConditionsProfileList,
  selectMedicationsProfileList,
  selectProceduresProfileList,
  selectVaccinationsProfileList,
  selectSocialFactorsProfileList,
  selectHealthProfileAccountId,
} from '~/redux/reducers/healthProfileReducer';
import { clinicalTermToLabelValue, clinicalTermResponseToPatientHistoryDataItem } from '~/utils/clinicalTerms';

interface Props {
  accountId: number;
  onSaved?: () => void;
  children: React.ReactNode;
}
export const HealthProfileDataProvider: React.FC<Props> = ({ accountId, onSaved, children }) => {
  const [waiting, setWaiting] = useState(false);
  const { hasWriteAccess } = useHasWriteAccess(accountId);
  const currentAccountId = useSelector(selectHealthProfileAccountId);

  const { loading, getHealthProfile } = useHealthProfile({ accountId });

  const patientHealthProfileData = useSelector(selectHealthProfile);
  const prosthesisImplantsGrafts = useSelector(selectProsthesisImplantsGrafts);
  const dietaryRestrictions = useSelector(selectDietaryRestrictions);
  const recreationalDrugs = useSelector(selectRecreationalDrugs);

  const allergies = useSelector(selectAllergiesProfileList);
  const medicalConditions = useSelector(selectMedicalConditionsProfileList);
  const medications = useSelector(selectMedicationsProfileList);
  const procedures = useSelector(selectProceduresProfileList);
  const vaccinations = useSelector(selectVaccinationsProfileList);
  const socialFactors = useSelector(selectSocialFactorsProfileList);

  const [localPatientHistory, setLocalPatientHistory] = useState<PatientHistoryData>({});

  const [localHealthProfile, setLocalHealthProfile] = useState<HealthProfileModel>({} as HealthProfileModel);

  const {
    control: controlMetrics,
    formState: { isDirty: isDirtyMetrics },
    reset: resetMetrics,
    getValues: getValuesMetrics,
  } = useForm<HealthMetricsFormModel>({
    defaultValues: {},
  });

  const {
    control: controlLifestyleRadioOptions,
    formState: { isDirty: isDirtyLifestyleRadioOptions },
    reset: resetLifestyleRadioOptions,
    getValues: getValuesLifestyleRadioOptions,
  } = useForm<LifestyleRadioFormsModel>({
    defaultValues: {},
  });

  const {
    control: controlProsthesis,
    formState: { isDirty: isDirtyProsthesis },
    reset: resetProsthesis,
    getValues: getValuesProsthesis,
  } = useForm<ProsthesisFormModel>({
    defaultValues: {
      prosthesis: [],
    },
  });

  const {
    control: controlDietaryRestrictions,
    formState: { isDirty: isDirtyDietaryRestrictions },
    reset: resetDietaryRestrictions,
    getValues: getValuesDietaryRestrictions,
  } = useForm<DietaryRestrictionsFormModel>({
    defaultValues: {
      dietaryRestrictions: [],
    },
  });

  const {
    control: controlRecreationalDrugs,
    formState: { isDirty: isDirtyRecreationalDrugs },
    reset: resetRecreationalDrugs,
    getValues: getValuesRecreationalDrugs,
  } = useForm<RecreationalDrugsFormModel>({
    defaultValues: {
      recreationalDrugs: [],
      otherText: '',
    },
  });

  const {
    control: controlSocialFactors,
    formState: { isDirty: isDirtySocialFactors },
    reset: resetSocialFactors,
    getValues: getValuesSocialFactors,
  } = useForm<SocialFactorsFormModel>({
    defaultValues: {
      socialFactors: [],
    },
  });

  const clearLocalData = () => {
    setLocalHealthProfile({} as HealthProfileModel);

    resetMetrics({});
    resetLifestyleRadioOptions({});
    resetProsthesis({ prosthesis: [] });
    resetDietaryRestrictions({ dietaryRestrictions: [] });
    resetRecreationalDrugs({ recreationalDrugs: [], otherText: '' });
    resetSocialFactors({ socialFactors: [] });

    setLocalPatientHistory({});
  };

  useEffect(() => {
    if (patientHealthProfileData) {
      setLocalHealthProfile(patientHealthProfileData);

      resetMetrics({
        height: patientHealthProfileData.height,
        weight: patientHealthProfileData.weight,
        glucose: patientHealthProfileData.glucose,
        blood_pressure_diastolic: patientHealthProfileData.blood_pressure_diastolic,
        blood_pressure_systolic: patientHealthProfileData.blood_pressure_systolic,
        total_cholesterol: patientHealthProfileData.total_cholesterol,
        ldl_cholesterol: patientHealthProfileData.ldl_cholesterol,
        hdl_cholesterol: patientHealthProfileData.hdl_cholesterol,
        triglycerides: patientHealthProfileData.triglycerides,
        hdl_ratio: patientHealthProfileData.hdl_ratio,
      });

      resetLifestyleRadioOptions({
        alcoholConsumption: patientHealthProfileData.alcohol_consumption_id,
        tobaccoUse: patientHealthProfileData.tobacco_use_id,
      });
    }
  }, [patientHealthProfileData]);
  useEffect(() => {
    if (prosthesisImplantsGrafts) {
      resetProsthesis({ prosthesis: prosthesisImplantsGrafts.map((item) => item.id) });
    }
  }, [prosthesisImplantsGrafts]);
  useEffect(() => {
    if (dietaryRestrictions) {
      resetDietaryRestrictions({ dietaryRestrictions: dietaryRestrictions.map((item) => item.id) });
    }
  }, [dietaryRestrictions]);
  useEffect(() => {
    if (recreationalDrugs) {
      const hasOtherText = recreationalDrugs.find((drug) => drug.name === 'Other')?.other_text;
      resetRecreationalDrugs({
        recreationalDrugs: recreationalDrugs.map((item) => item.id),
        otherText: hasOtherText || '',
      });
    }
  }, [recreationalDrugs]);
  useEffect(() => {
    if (socialFactors) {
      resetSocialFactors({ socialFactors: socialFactors.map((item) => clinicalTermToLabelValue(item)) });
    }
  }, [socialFactors]);

  useEffect(() => {
    const patientHistoryData: PatientHistoryData = {
      ...localPatientHistory,
      [ClinicalTermTypeEnum.ALLERGIES]: {
        ...clinicalTermResponseToPatientHistoryDataItem(allergies),
        ...localPatientHistory?.allergies,
      },
    };

    setLocalPatientHistory(patientHistoryData);
  }, [allergies]);
  useEffect(() => {
    const patientHistoryData: PatientHistoryData = {
      ...localPatientHistory,
      [ClinicalTermTypeEnum.MEDICAL_CONDITIONS]: {
        ...clinicalTermResponseToPatientHistoryDataItem(medicalConditions),
        ...localPatientHistory.medicalConditions,
      },
    };

    setLocalPatientHistory(patientHistoryData);
  }, [medicalConditions]);
  useEffect(() => {
    const patientHistoryData: PatientHistoryData = {
      ...localPatientHistory,
      [ClinicalTermTypeEnum.MEDICATIONS]: {
        ...clinicalTermResponseToPatientHistoryDataItem(medications),
        ...localPatientHistory.medications,
      },
    };

    setLocalPatientHistory(patientHistoryData);
  }, [medications]);
  useEffect(() => {
    const patientHistoryData: PatientHistoryData = {
      ...localPatientHistory,
      [ClinicalTermTypeEnum.PROCEDURES]: {
        ...clinicalTermResponseToPatientHistoryDataItem(procedures),
        ...localPatientHistory.procedures,
      },
    };

    setLocalPatientHistory(patientHistoryData);
  }, [procedures]);
  useEffect(() => {
    const patientHistoryData: PatientHistoryData = {
      ...localPatientHistory,
      [ClinicalTermTypeEnum.VACCINATIONS]: {
        ...clinicalTermResponseToPatientHistoryDataItem(vaccinations),
        ...localPatientHistory.vaccinations,
      },
    };

    setLocalPatientHistory(patientHistoryData);
  }, [vaccinations]);

  const setPatientHistoryData = (
    type: ClinicalTermTypeEnum,
    termLabelValuePair: ClinicalTermLabelValue,
    documents: DocumentPickerAsset[]
  ) => {
    const patientHistory = { ...localPatientHistory };
    if (!patientHistory[type]) patientHistory[type] = {};
    else patientHistory[type] = { ...patientHistory[type] };

    const termId = termLabelValuePair.value;
    if (!patientHistory[type][termId])
      patientHistory[type][termId] = { term: termLabelValuePair, media: [...documents] };
    else {
      patientHistory[type][termId].media = [...patientHistory[type][termId].media, ...documents];
    }

    setLocalPatientHistory({ ...patientHistory });
  };

  const removePatientHistoryDataType = (type: ClinicalTermTypeEnum, termId: string) => {
    if (localPatientHistory[type] && localPatientHistory[type][termId]) {
      const patientHistory = { ...localPatientHistory };
      patientHistory[type] = { ...patientHistory[type] };
      delete patientHistory[type][termId];
      setLocalPatientHistory({ ...patientHistory });
    }
  };

  const removePatientHistoryDataFile = (type: ClinicalTermTypeEnum, termId: string, document: DocumentPickerAsset) => {
    if (localPatientHistory[type] && localPatientHistory[type][termId]) {
      const patientHistory = { ...localPatientHistory };
      const termIdFiles = [...patientHistory[type][termId].media];
      const fileIndex = termIdFiles.findIndex((typeFile) => typeFile === document);
      if (fileIndex > -1) termIdFiles.splice(fileIndex, 1);
      patientHistory[type][termId].media = termIdFiles;
      setLocalPatientHistory(patientHistory);
    }
  };

  const reset = () => {
    if (patientHealthProfileData) {
      setLocalHealthProfile(patientHealthProfileData);
    } else {
      setLocalHealthProfile({} as HealthProfileModel);
    }

    resetMetrics();
    resetLifestyleRadioOptions();
    resetProsthesis();
    resetDietaryRestrictions();
    resetRecreationalDrugs();
    resetSocialFactors();

    const patientHistoryData: PatientHistoryData = {
      [ClinicalTermTypeEnum.ALLERGIES]: clinicalTermResponseToPatientHistoryDataItem(allergies),
      [ClinicalTermTypeEnum.MEDICAL_CONDITIONS]: clinicalTermResponseToPatientHistoryDataItem(medicalConditions),
      [ClinicalTermTypeEnum.MEDICATIONS]: clinicalTermResponseToPatientHistoryDataItem(medications),
      [ClinicalTermTypeEnum.PROCEDURES]: clinicalTermResponseToPatientHistoryDataItem(procedures),
      [ClinicalTermTypeEnum.VACCINATIONS]: clinicalTermResponseToPatientHistoryDataItem(vaccinations),
    };

    setLocalPatientHistory(patientHistoryData);
  };

  const onSubmit = async () => {
    try {
      InprogressAlert(['Please wait while we update the health profile'], {
        title: 'Updating Health Profile',
        block: true,
      });
      setWaiting(true);

      let healthProfileId = patientHealthProfileData?.id;
      if (!patientHealthProfileData || isDirtyMetrics || isDirtyLifestyleRadioOptions) {
        const lifestyleValues = getValuesLifestyleRadioOptions();
        const metricData = getValuesMetrics();

        const res = await apiUpdateOrCreateHealthProfile({
          id: accountId,
          data: {
            ...metricData,
            tobacco_use_id: lifestyleValues.tobaccoUse,
            alcohol_consumption_id: lifestyleValues.alcoholConsumption,
          },
        });

        healthProfileId = res.data.id;
      }

      if (isDirtyProsthesis) {
        await apiUpdateProsthesisImplantsGrafts({
          id: healthProfileId,
          data: { prosthesis_implant_graft_ids: getValuesProsthesis().prosthesis },
        });
      }

      if (isDirtyDietaryRestrictions) {
        await apiUpdateDietaryRestrictions({
          id: healthProfileId,
          data: { dietary_restriction_ids: getValuesDietaryRestrictions().dietaryRestrictions },
        });
      }

      if (isDirtyRecreationalDrugs) {
        const values = getValuesRecreationalDrugs();
        await apiUpdateRecreationalDrugs({
          id: healthProfileId,
          data: { recreational_drug_ids: values.recreationalDrugs, other_text: values.otherText },
        });
      }

      if (isDirtySocialFactors) {
        await apiUpdateSocialFactors({
          id: healthProfileId,
          data: { term_ids: getValuesSocialFactors().socialFactors.map((item) => item.value) },
        });
      }

      const patientHistoryItemKeys = Object.keys(localPatientHistory);
      for (const itemKey of patientHistoryItemKeys) {
        let clinicalTermResponse: HealthProfileClinicalTermResponse = null;
        switch (itemKey) {
          case ClinicalTermTypeEnum.ALLERGIES: {
            clinicalTermResponse = allergies;
            break;
          }
          case ClinicalTermTypeEnum.MEDICAL_CONDITIONS: {
            clinicalTermResponse = medicalConditions;
            break;
          }
          case ClinicalTermTypeEnum.MEDICATIONS: {
            clinicalTermResponse = medications;
            break;
          }
          case ClinicalTermTypeEnum.PROCEDURES: {
            clinicalTermResponse = procedures;
            break;
          }
          case ClinicalTermTypeEnum.VACCINATIONS: {
            clinicalTermResponse = vaccinations;
            break;
          }
        }

        const previousData = clinicalTermResponseToPatientHistoryDataItem(clinicalTermResponse);
        await setFileChanges(
          healthProfileId,
          itemKey as ClinicalTermTypeEnum,
          previousData,
          localPatientHistory[itemKey]
        );
      }
      setLocalPatientHistory({});
      await getHealthProfile();

      setWaiting(false);
      SuccessAlert(['Health profile was successfully updated']);
      if (onSaved) onSaved();
    } catch (e) {
      ErrorAlert(e);
      setWaiting(false);
    }
  };

  useEffect(() => {
    // Clear health profile, if the account id has changed
    if (!currentAccountId || !accountId || currentAccountId !== accountId) {
      clearLocalData();
    }
  }, [accountId]);

  return (
    <HealthProfileDataContext.Provider
      value={{
        accountId,
        healthProfile: localHealthProfile,
        setHealthProfile: setLocalHealthProfile,

        patientHistory: localPatientHistory,
        setPatientHistory: setPatientHistoryData,
        removePatientHistoryDataType,
        removePatientHistoryDataFile,
        getHealthProfile,
        loading,
        waiting,
        submit: onSubmit,
        reset,
        hasWriteAccess,

        controlMetrics,
        controlLifestyleRadioOptions,
        controlProsthesis,
        controlDietaryRestrictions,
        controlRecreationalDrugs,
        controlSocialFactors,
      }}>
      {children}
    </HealthProfileDataContext.Provider>
  );
};
