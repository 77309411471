import { useState } from 'react';
import { useSelector } from 'react-redux';

import { hasToken } from '~/api/axios/interceptors';
import { AuthVerificationEnum } from '~/api/models/authorisation/constants/AuthVerificationEnum';
import { SumSubReviewAnswerEnum } from '~/api/models/authorisation/constants/SumSubReviewAnswerEnum';
import { SumSubReviewStatusEnum } from '~/api/models/authorisation/constants/SumSubReviewStatusEnum';
import { AuthVerificationStatusResponse } from '~/api/models/authorisation/responses/AuthVerificationStatusResponse';
import { RoleStateEnum } from '~/api/models/roles/constants';
import { apiGetVerificationStatus } from '~/api/services/authorisation/status';
import { RootState } from '~/redux/reducers';
import { accountApprovedSelector, SET_APPROVAL_STATUS } from '~/redux/reducers/accountsReducer';
import { UPDATE_VERIFICATION_STATUS } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { isPharmacyVersion } from '~/utils/buildConfig';

export const useVerificationStatus = () => {
  const [loading, setLoading] = useState(false);

  const status = useSelector((state: RootState) => state.userDetailsReducer.verificationStatus);
  const statusEnum = useSelector((state: RootState) => state.accountsReducer.accountStatus);
  const approved = useSelector(accountApprovedSelector);

  const dispatch = useAppDispatch();

  const updateStatusEnum = (s?: AuthVerificationEnum) => {
    dispatch(SET_APPROVAL_STATUS(s));
  };

  const getKyiStatusEnum = (verificationStatus: AuthVerificationStatusResponse) => {
    if (isPharmacyVersion()) {
      return AuthVerificationEnum.APPROVED;
    }
    if (!verificationStatus) {
      return null;
    }

    // Review to be skipped, if reviewAnswer is null
    const reviewAnswer = verificationStatus.reviewAnswer;
    const reviewStatus = verificationStatus.reviewStatus;
    const hasUniqueIdentification = verificationStatus.hasUniqueIdentification;

    if (
      !reviewAnswer ||
      (reviewAnswer === SumSubReviewAnswerEnum.GREEN &&
        reviewStatus === SumSubReviewStatusEnum.COMPLETED &&
        hasUniqueIdentification)
    ) {
      return AuthVerificationEnum.APPROVED;
    } else if (reviewStatus === SumSubReviewStatusEnum.COMPLETED && reviewAnswer === SumSubReviewAnswerEnum.RED) {
      return AuthVerificationEnum.REJECTED;
    } else {
      return AuthVerificationEnum.PENDING_KYI;
    }
  };

  const getOverallStatusEnum = (verificationStatus: AuthVerificationStatusResponse) => {
    if (!verificationStatus) {
      return null;
    }

    const kyiStatusEnum = getKyiStatusEnum(verificationStatus);
    const digimedStatus = verificationStatus.digimed;

    if (kyiStatusEnum === AuthVerificationEnum.APPROVED) {
      switch (digimedStatus) {
        case RoleStateEnum.REJECTED:
          return AuthVerificationEnum.REJECTED;
        case RoleStateEnum.DISABLED:
          return AuthVerificationEnum.DISABLED;
        case RoleStateEnum.PENDING:
          return AuthVerificationEnum.PENDING_ADMIN;
      }
    }
    return kyiStatusEnum;
  };

  const getVerificationStatus = async () => {
    setLoading(true);
    const hasAuthToken = await hasToken();
    if (!hasAuthToken) {
      setLoading(false);
      return;
    }

    try {
      const response = await apiGetVerificationStatus();
      const verificationStatus = response.data;
      dispatch(UPDATE_VERIFICATION_STATUS(verificationStatus));

      const status = getOverallStatusEnum(verificationStatus);
      updateStatusEnum(status);
      setLoading(false);
      return status;
    } catch {
      updateStatusEnum(AuthVerificationEnum.PENDING_ADMIN);
      setLoading(false);
      return AuthVerificationEnum.PENDING_ADMIN;
    }
  };

  return { status, statusEnum, loading, approved: !!approved, getVerificationStatus };
};
