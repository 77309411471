import React, { useEffect, useRef } from 'react';

import { MetaTagProps } from './interfaces';

export const MetaTagComponent: React.FC<MetaTagProps> = ({ name, content }) => {
  const ref = useRef<HTMLMetaElement>();

  const setMetaTag = () => {
    if (!name || !content) return;

    if (!ref.current) {
      ref.current = document.createElement('meta');
      ref.current.name = name;
      ref.current.content = content;
      document.head.appendChild(ref.current);
    } else {
      ref.current.name = name;
      ref.current.content = content;
    }
  };

  useEffect(() => {
    setMetaTag();
  }, [name, content]);
  return null;
};
