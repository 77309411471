import React from 'react';
import { View } from 'react-native';

import { mb20 } from '~/common/commonStyles';
import { Checkbox } from '~/components/inputs/Checkbox';
import { labels } from '~/utils/labels';

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const SendEmailNotifCheckBox: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <View style={mb20}>
      <Checkbox label={labels.sendEmailNotification} checked={checked} onChange={onChange} />
    </View>
  );
};
