import React from 'react';
import { View } from 'react-native';

import { useRegistrationSteps } from '../../api/hooks/RegistrationSteps';
import { H3TtmRegularBlack, H6NsRegularBlack } from '../../components/commonText';
import { theme } from '../../utils/theme';

import { digimedFeatureEnabled, DigimedFeatures } from '~/utils/milestoneUtil';

const RegistrationStepsComponent: React.FC = () => {
  const { steps } = useRegistrationSteps();

  if (!steps) return null;
  return (
    <View style={{ maxHeight: 350, marginTop: 15 }}>
      <View style={theme.registrationStepperScreenverticalLine} />
      <View style={theme.registrationStepperScreenverticalWrap}>
        {steps?.map((step) => {
          const completed = digimedFeatureEnabled(DigimedFeatures.Consultations)
            ? step.completion.all
            : step.required && step.completion.required;
          return (
            <View style={theme.registrationStepperScreenitemWrap} key={step.label}>
              <View
                style={[
                  theme.registrationStepperScreenpointWrap,
                  completed ? theme.registrationStepperScreencompletedPoint : theme.registrationStepperScreenpoint,
                ]}>
                <H6NsRegularBlack style={[completed ? theme.registrationStepperScreenmarkerText : null]}>
                  {step.order}
                </H6NsRegularBlack>
              </View>
              <View style={{ marginLeft: 20, flex: 1 }}>
                <H3TtmRegularBlack>
                  {step.label}
                  {step.required ? <H3TtmRegularBlack style={{ color: 'red' }}>*</H3TtmRegularBlack> : null}
                </H3TtmRegularBlack>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default RegistrationStepsComponent;
