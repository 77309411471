import React from 'react';
import { useFormState } from 'react-hook-form';
import { View } from 'react-native';

import { DoctorListFilterComponent } from '../components/DoctorListFilterComponent';

import { Button, TextButton } from '~/components/commonButton';
import { NonRegisteredModalContainer } from '~/components/modals/NonRegisteredModalContainer';
import { useDoctorListingContext } from '~/providers/appointment/DoctorListingContext';
import { labels } from '~/utils/labels';

interface Props {
  show: boolean;
  onHide: () => void;
}
export const DoctorListFilterModal: React.FC<Props> = ({ show, onHide }) => {
  const { filterControl, submitQuery } = useDoctorListingContext();
  const { isDirty } = useFormState({ control: filterControl });
  const buttons = [
    <Button
      label={labels.apply}
      funCallback={() => {
        submitQuery();
        onHide();
      }}
      disabled={!isDirty}
    />,
    <TextButton label={labels.close} funCallback={onHide} />,
  ];
  return (
    <NonRegisteredModalContainer onHide={onHide} buttons={buttons} show={show} hideOnBackground>
      <View>
        <DoctorListFilterComponent largeHeader />
      </View>
    </NonRegisteredModalContainer>
  );
};
