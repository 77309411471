import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React from 'react';

import { screenName } from '../../utils/screenName';

import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { CustomMaterialTabNavigation } from '~/components/navigation/CustomMaterialTabNavigation';
import { InsuranceClaimsListScreen } from '~/pages/patient/insurance/InsuranceClaimsListingScreen';
import { InsuranceDetailsScreen } from '~/pages/patient/insurance/InsuranceDetailsScreen';
import { InsurancePaywallScreen } from '~/pages/patient/insurance/InsurancePaywallScreen';
import { isNative } from '~/utils/buildConfig';

const Tab = createMaterialTopTabNavigator();

export const insuranceMenu = [
  { title: 'Insurance Details', link: { screen: screenName.InsuranceDetails, params: {} } },
  { title: 'Pending Claims', link: { screen: screenName.InsuranceClaims, params: {} } },
];

export const InsuranceNavigation = () => {
  const { hasFeatureAccess } = useFeatureAccess();
  return (
    <Tab.Navigator
      initialRouteName={screenName.HelpFaq}
      screenOptions={{ swipeEnabled: isNative() }}
      tabBar={(props) => <CustomMaterialTabNavigation tabProps={props} />}>
      <Tab.Screen
        name={screenName.InsuranceDetails}
        component={InsuranceDetailsScreen}
        options={{ title: 'Insurance Details' }}
      />

      {hasFeatureAccess(FeatureAccessEnum.INSURANCE) ? (
        <Tab.Screen
          name={screenName.InsuranceClaims}
          component={InsuranceClaimsListScreen}
          options={{ title: 'Pending Claims' }}
        />
      ) : (
        <Tab.Screen
          name={screenName.InsuranceClaims}
          component={InsurancePaywallScreen}
          options={{ title: 'Pending Claims' }}
        />
      )}
    </Tab.Navigator>
  );
};
