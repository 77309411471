import { Route } from '@react-navigation/native';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { View } from 'react-native';

import { useUserDetails } from '../../api/hooks/accounts/UserDetails';
import { CountryListEnum, useCountryListing } from '../../api/hooks/referenceData/CountryListing';
import { apiAddAddress } from '../../api/services/addresses';
import { Button } from '../../components/commonButton';
import FloatingTextBox from '../../components/floatingInputBox';
import { ErrorAlert } from '../../components/modals/ErrorAlert';
import { GeneralProps } from '../../interfaces/generalProps';
import {
  minLengthValidation,
  requiredValidation,
  validationSchema,
  alphaNumericValidationWithSpace,
} from '../../services/validationConfig';
import { labels } from '../../utils/labels';
import { errorMessages } from '../../utils/labels/errorMessages';

import { useRegistrationSteps } from '~/api/hooks/RegistrationSteps';
import { useFinaliseRegistration } from '~/api/hooks/accounts/FinaliseRegistration';
import { MaltaId } from '~/api/hooks/referenceData/constants/CountryConstants';
import { GenderTypeEnum } from '~/api/models/common/constants/GenderTypeEnum';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { EnhancedRegistrationLayout } from '~/layouts/EnhancedRegistrationLayout';
import { isPatientVersion } from '~/utils/buildConfig';

type personalDetails = {
  first_name: string;
  last_name: string;
  dob: string;
  gender: GenderTypeEnum;
  passport_number: string;
};

interface Props extends GeneralProps {
  route: Route<string, (object & { personalDetails: personalDetails; name: string }) | undefined>;
}

const HomeAddress: React.FC<Props> = ({ navigation, route }) => {
  const { userDetails } = useUserDetails({ allowStale: true });
  const { updateRegistrationStatus } = useRegistrationSteps();
  const { finaliseRegistration } = useFinaliseRegistration();
  const [loading, setLoading] = useState(false);

  const countryList = useCountryListing(CountryListEnum.COUNTRY_NAME);
  const { handleSubmit, control } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      postcode: '',
      address: '',
      country: MaltaId,
    },
  });

  const saveHomeAddress = async (homeAddress: { country: number; postcode: string; address: string }) => {
    try {
      setLoading(true);
      await apiAddAddress({
        account_id: userDetails.account.id,
        country_id: homeAddress.country,
        post_code: homeAddress.postcode,
        line_1: homeAddress.address,
      });

      if (isPatientVersion()) {
        await finaliseRegistration();
      } else {
        await updateRegistrationStatus();
      }
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  const buttons = [<Button funCallback={handleSubmit(saveHomeAddress)} label={labels.continue} disabled={loading} />];
  return (
    <EnhancedRegistrationLayout
      buttons={buttons}
      title={labels.homeAddressScreenTitle}
      subtitle={labels.homeAddressScreenMessage}>
      <View style={{ width: '100%' }}>
        <Controller
          name="country"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <Dropdown
              list={countryList.map((item) => ({ label: item.name, value: item.id }))}
              setValue={onChange}
              value={value}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
              searchable
              label={labels.country}
              showMandatory
            />
          )}
          rules={{
            required: {
              value: true,
              message: errorMessages.pleaseSelectYourCountry,
            },
          }}
        />
        <Controller
          name="postcode"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingTextBox
              label={labels.postCode}
              showMandatory
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
              maxLength={validationSchema.postCode.maxLength}
            />
          )}
          rules={{
            validate: { alphaNumericValidationWithSpace },
            required: requiredValidation(labels.postCode),
            minLength: minLengthValidation(validationSchema.postCode.minLength),
          }}
        />

        <Controller
          name="address"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error }, formState: { isSubmitted } }) => (
            <FloatingTextBox
              maxLength={validationSchema.address.maxLength}
              label={labels.address}
              showMandatory
              value={value}
              onChangeValue={onChange}
              onBlur={onBlur}
              error={isSubmitted && !!error}
              errorMessage={isSubmitted && error?.message}
            />
          )}
          rules={{
            required: requiredValidation(labels.address),
            minLength: minLengthValidation(validationSchema.address.minLength),
          }}
        />
      </View>
    </EnhancedRegistrationLayout>
  );
};
export default HomeAddress;
