import { Moment } from 'moment';
import React from 'react';
import CalendarPicker, {
  CustomDateStyle,
  CustomDatesStylesFunc,
  DateChangedCallback,
  DisabledDatesFunc,
} from 'react-native-calendar-picker';

import { BackArrow, ForwardArrow } from '~/components/svgImages';
import { colors } from '~/utils/colors';
import { NUM_DATE_FORMAT, stringToDate } from '~/utils/dateAndTime';
import { fontFamily } from '~/utils/fontFamily';

interface Props {
  onChange?: (date: string) => void;
  onChangeMoment?: (date: Moment) => void;
  onChangeMonth?: DateChangedCallback;
  value: string;
  height?: number;
  width?: number;
  minDate?: Date;
  maxDate?: Date;
  customDatesStyles?: CustomDateStyle[] | CustomDatesStylesFunc;
  disabledDates?: Date[] | DisabledDatesFunc;
}

export const CalendarPickerInput: React.FC<Props> = ({
  onChange,
  onChangeMoment,
  onChangeMonth,
  value,
  height = 300,
  width = 300,
  minDate,
  maxDate,
  customDatesStyles,
  disabledDates,
}) => {
  return (
    <CalendarPicker
      initialDate={value ? stringToDate(value) : undefined}
      onDateChange={(date) => {
        if (onChange) onChange(date.format(NUM_DATE_FORMAT));
        if (onChangeMoment) onChangeMoment(date);
      }}
      onMonthChange={onChangeMonth}
      selectedStartDate={stringToDate(value)}
      textStyle={{ fontSize: 12, fontFamily: fontFamily.fontRegular, fontWeight: '600' }}
      minDate={minDate}
      maxDate={maxDate}
      customDatesStyles={customDatesStyles}
      selectedDayStyle={{ backgroundColor: colors.info }}
      selectedDayTextColor={colors.white}
      todayBackgroundColor={colors.fadedTurquoise}
      todayTextStyle={{ color: colors.black }}
      disabledDatesTextStyle={{ fontSize: 12, fontFamily: fontFamily.fontRegular, fontWeight: '300' }}
      disabledDates={disabledDates}
      height={height}
      width={width}
      nextComponent={<ForwardArrow height={12} width={12} />}
      previousComponent={<BackArrow height={12} width={12} />}
      dayLabelsWrapper={{ borderWidth: 1, borderRadius: 6, borderColor: colors.lightPurple2 }}
    />
  );
};
