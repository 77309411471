import React, { useContext } from 'react';

export interface SnackbarOptions {
  keyRef?: string;
  actionTitle?: string;
  noTimer?: boolean;
  onPress?: () => void;
  isError?: boolean;
}

export interface ISnackbarContext {
  showSnackbar: (message: string, options?: SnackbarOptions) => Promise<void>;
}

export const SnackbarManagementContext = React.createContext<ISnackbarContext>({} as ISnackbarContext);
export const useSnackbarManager = (): ISnackbarContext => useContext(SnackbarManagementContext);
