import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { HomeVisitFormModel, HomeVisitModel } from '~/api/models/preferences/models/HomeVisitModel';
import { HomeVisitWithIdModel } from '~/api/models/preferences/models/HomeVisitWithIdModel';
import {
  apiAddHomeVisit,
  apiDeleteHomeVisit,
  apiGetHomeVisitsIndex,
  apiUpdateHomeVisit,
} from '~/api/services/preferences/homeVisits';
import { SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import {
  SET_HOME_VISIT_AVAILABILITIES,
  homeVisitAvailabilitiesSelector,
} from '~/redux/reducers/doctor/availabilitiesReducer';
import { useAppDispatch } from '~/redux/store';
import { DATE_FORMAT, LARAVEL_DATE_TIME, TIME_FORMAT, parseDateTime } from '~/utils/dateAndTime';

interface Props {
  immediate?: boolean;
}
export function useHomeVisitsAvailability(options?: Props) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(!!options?.immediate);

  const homeVisitsRaw = useSelector(homeVisitAvailabilitiesSelector);
  const homeVisits = useMemo(() => homeVisitsRaw ?? [], [homeVisitsRaw]);

  // Helper function to format the time ranges
  const formatTimeRanges = (timeRanges: HomeVisitModel[] | HomeVisitWithIdModel[]): HomeVisitWithIdModel[] => {
    return timeRanges.map((timeRange) => ({
      ...timeRange,
      starts_at: parseDateTime(timeRange?.starts_at, { parseFormat: LARAVEL_DATE_TIME, outputFormat: TIME_FORMAT }),
      starts_on: parseDateTime(timeRange?.starts_at, { parseFormat: LARAVEL_DATE_TIME, outputFormat: DATE_FORMAT }),
      ends_at: parseDateTime(timeRange?.ends_at, { parseFormat: LARAVEL_DATE_TIME, outputFormat: TIME_FORMAT }),
    }));
  };
  const setHomeVisits = (homeVisits: HomeVisitWithIdModel[]) => {
    const formattedAppointments = formatTimeRanges(homeVisits);
    dispatch(SET_HOME_VISIT_AVAILABILITIES(formattedAppointments));
  };

  const getHomeVisitAvailabilities = async () => {
    setLoading(true);
    await apiGetHomeVisitsIndex()
      .then((response) => {
        setHomeVisits(response.data.data);
      })
      .catch(ErrorAlert);
    setTimeout(() => setLoading(false), 500);
  };

  const addHomeVisit = async (data: HomeVisitModel) => {
    const newData = {
      ...data,
      starts_at: parseDateTime(data?.starts_at, { parseFormat: TIME_FORMAT, outputFormat: LARAVEL_DATE_TIME }),
      ends_at: parseDateTime(data?.ends_at, { parseFormat: TIME_FORMAT, outputFormat: LARAVEL_DATE_TIME }),
    };

    await apiAddHomeVisit(newData).then((result) => {
      const newDataArray = Array.isArray(result.data) ? result.data : [result.data];
      // Parse format of time range in the response before updating the state
      const formattedNewCards = formatTimeRanges(newDataArray);
      dispatch(SET_HOME_VISIT_AVAILABILITIES([...homeVisits, ...formattedNewCards]));
      SuccessAlert(['Home visit availability added']);
    });
  };

  const updateHomeVisit = async (data: HomeVisitWithIdModel) => {
    if (data.id) {
      // Remove the id field from the data without modifying the original data object
      const { id, ...newData } = data;
      const updatedData = {
        ...newData,
        starts_at: parseDateTime(newData?.starts_at, { parseFormat: TIME_FORMAT, outputFormat: LARAVEL_DATE_TIME }),
        ends_at: parseDateTime(newData?.ends_at, { parseFormat: TIME_FORMAT, outputFormat: LARAVEL_DATE_TIME }),
      };

      await apiUpdateHomeVisit({ calendarEventId: data.id, data: updatedData }).then((response) => {
        const homeVisitIndex = homeVisits.findIndex((homeVisit) => homeVisit.id === data.id);
        if (homeVisitIndex !== -1) {
          // Parse format of time range in the response before updating the state
          const formattedResponseData = formatTimeRanges([response.data]);

          const newHomeVisits = [...homeVisits];
          newHomeVisits[homeVisitIndex] = formattedResponseData[0];
          dispatch(SET_HOME_VISIT_AVAILABILITIES(newHomeVisits));
          SuccessAlert(['Home visit availability successfully updated']);
        } else {
          ErrorAlert('Home Visit appointment not found for update');
        }
      });
    }
  };

  const removeHomeVisit = async (id: number) => {
    await apiDeleteHomeVisit(id)
      .then(() => {
        dispatch(SET_HOME_VISIT_AVAILABILITIES(homeVisits.filter((homeVisit) => homeVisit.id !== id)));
        SuccessAlert(['Home visit availability successfully removed']);
      })
      .catch(ErrorAlert);
  };

  const saveHomeVisit = async (formData: HomeVisitFormModel) => {
    const data: HomeVisitModel = {
      ...formData,
      lat: formData.latLng.lat.toString(),
      lng: formData.latLng.lng.toString(),
    };
    if ('id' in data && data.id) {
      await updateHomeVisit(data as HomeVisitWithIdModel);
    } else {
      await addHomeVisit(data);
    }
  };

  useEffect(() => {
    if (!homeVisitsRaw && options?.immediate) getHomeVisitAvailabilities();
    else setLoading(false);
  }, []);

  return {
    homeVisits: homeVisitsRaw,
    removeHomeVisit,
    saveHomeVisit,
    loading,
  };
}
