import { useNavigation } from '@react-navigation/native';
import { useEffect, useState } from 'react';

import { useUserDetails } from '../accounts/UserDetails';

import { PrescriptionEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { privatePrescriptionsChannel } from '~/api/models/channel/enum/channelNames';
import { channelSubscribe } from '~/integrations/channels/PusherChannels';
import { NavType } from '~/navigation/types';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { SET_NAVIGATION_DATA } from '~/redux/reducers/routeReducer';
import { useAppDispatch } from '~/redux/store';
import { isPatientVersion } from '~/utils/buildConfig';
import { screenName } from '~/utils/screenName';

export const usePrescriptionRequestsChannel = () => {
  const { userDetails } = useUserDetails({ allowStale: true });
  const { showSnackbar } = useSnackbarManager();
  const { navigate } = useNavigation<NavType>();
  const dispatch = useAppDispatch();

  const eventHandler = (
    event: PrescriptionEventsEnum,
    data: { prescription_request_id: number; prescription_id: number; prescription_reference?: string }
  ) => {
    const prescriptionDetails = data?.prescription_reference ? `with reference ${data?.prescription_reference} ` : '';
    switch (event) {
      case PrescriptionEventsEnum.PRESCRIPTION_REQUEST_APPROVED:
        showSnackbar(`Your prescription ${prescriptionDetails}has been approved`, {
          onPress: () => {
            if (data.prescription_id) dispatch(SET_NAVIGATION_DATA({ prescription_id: data.prescription_id }));
            navigate(screenName.PrescriptionScreen);
          },
          keyRef: `${event}.${data.prescription_request_id}`,
        });
        break;
      case PrescriptionEventsEnum.PRESCRIPTION_REQUEST_REJECTED:
        showSnackbar(`Your prescription ${prescriptionDetails}has been rejected`, {
          onPress: () => {
            if (data.prescription_id) dispatch(SET_NAVIGATION_DATA({ prescription_id: data.prescription_id }));
            navigate(screenName.PrescriptionScreen);
          },
          keyRef: `${event}.${data.prescription_request_id}`,
        });
        break;
    }
  };

  const subscribe = async () => {
    const res = await channelSubscribe(privatePrescriptionsChannel(userDetails.account.id), eventHandler);
    return res.unsubscribe;
  };

  useEffect(() => {
    if (!userDetails || !isPatientVersion()) return;

    let unsubscribe: () => void;
    subscribe().then((res) => (unsubscribe = res));

    return unsubscribe;
  }, [userDetails]);
};
