import { MD2Theme } from 'react-native-paper';

import { DefaultPaperTheme } from './DefaultPaperTheme';
import { colors } from '../../utils/colors';

export const AccordionPaperTheme: MD2Theme = {
  ...DefaultPaperTheme,
  roundness: 2,
  colors: {
    ...DefaultPaperTheme.colors,
    primary: colors.purple,
    background: 'transparent',
    accent: colors.grey,
    error: colors.danger,
    text: colors.black,
  },
};
