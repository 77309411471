import Feather from '@expo/vector-icons/Feather';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { BadgeTypeEnum } from './constants/BadgeTypeEnum';

import { AvailableDoctorModel } from '~/api/models/appointments/models/AvailableDoctorModel';
import { H6NsRegularBlack, H6NsSemiBoldBlack } from '~/components/commonText';
import { PatientGroup } from '~/components/svgImages';
import { colors } from '~/utils/colors';
import { getDoctorExperience } from '~/utils/personalDetailsUtils';

interface Props {
  doctor: AvailableDoctorModel;
  type: BadgeTypeEnum;
  style?: StyleProp<ViewStyle>;
}

const BadgeTypeMapping = {
  [BadgeTypeEnum.PATIENT_COUNT]: {
    icon: <PatientGroup width={20} height={20} color={colors.black} />,
    label: 'Patients',
  },
  [BadgeTypeEnum.EXPERIENCE]: {
    icon: <Feather name="award" size={20} color={colors.black} />,
    label: 'Experience',
  },
  [BadgeTypeEnum.CLINIC]: {
    icon: <FontAwesome5 name="clinic-medical" size={20} color={colors.black} />,
    label: '',
  },
};

const PatientCountBadges = [1000, 750, 500, 250, 100, 50];

export const DoctorBadge: React.FC<Props> = ({ doctor, type, style }) => {
  const label = useMemo(() => {
    switch (type) {
      case BadgeTypeEnum.EXPERIENCE: {
        return getDoctorExperience(doctor?.medical_council_registration);
      }
      case BadgeTypeEnum.PATIENT_COUNT:
        if (!doctor?.patient_count || doctor.patient_count < PatientCountBadges[PatientCountBadges.length - 1]) return;
        return `${PatientCountBadges.find((count) => doctor.patient_count > count)}+`;
      case BadgeTypeEnum.CLINIC:
        return doctor?.clinic_name ? 'Clinic' : undefined;
    }
  }, [doctor]);

  if (!label) return null;

  return (
    <View style={[styles.container, style]}>
      <View style={styles.badge}>{BadgeTypeMapping[type].icon}</View>
      <View style={styles.label}>
        <H6NsSemiBoldBlack>{label}</H6NsSemiBoldBlack>
        <H6NsRegularBlack>{BadgeTypeMapping[type].label}</H6NsRegularBlack>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  badge: {
    borderRadius: 6,
    width: 50,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.lightPurple,
  },
  label: {
    marginLeft: 10,
  },
});
