import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PharmacyMemberModel } from '~/api/models/pharmacy/models/PharmacyMemberModel';
import { PharmaciesMeResponse } from '~/api/models/pharmacy/responses/PharmaciesMeResponse';
import { apiMePharmacies } from '~/api/services/pharmacy';
import { getAsyncItem, storageKeys } from '~/common/asyncStorage';
import {
  SET_CURRENT_PHARMACY,
  SET_PHARMACIES,
  adminPharmaciesSelector,
  currentPharmacySelector,
  pharmaciesSelector,
} from '~/redux/reducers/pharmacy/pharmacyReducer';
import { useAppDispatch } from '~/redux/store';
import { isPharmacyVersion } from '~/utils/buildConfig';

export const useMyPharmacies = () => {
  const [loading, setLoading] = useState(true);
  const currentPharmacy = useSelector(currentPharmacySelector);
  const pharmacies = useSelector(pharmaciesSelector);
  const adminPharmacies = useSelector(adminPharmaciesSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    apiMePharmacies()
      .then((res) => {
        dispatch(SET_PHARMACIES(res.data));
      })
      .finally(() => setLoading(false));
  }, []);

  const setCurrentPharmacy = (pharmacy: PharmacyMemberModel) => {
    dispatch(SET_CURRENT_PHARMACY(pharmacy));
  };
  return { pharmacies, adminPharmacies, pharmacy: currentPharmacy, setCurrentPharmacy, loading };
};

export const useCurrentPharmacy = () => {
  const [loading, setLoading] = useState(true);
  const currentPharmacy = useSelector(currentPharmacySelector);
  const pharmacies = useSelector(pharmaciesSelector);
  const adminPharmacies = useSelector(adminPharmaciesSelector);

  const dispatch = useAppDispatch();

  const loadCurrentPharmacy = async (pharmacyList?: PharmaciesMeResponse) => {
    if (!pharmacyList) pharmacyList = pharmacies;
    if (!pharmacyList) {
      pharmacyList = (await apiMePharmacies()).data;
    }

    let storedPharmacy = currentPharmacy;
    if (storedPharmacy) {
      // Verify current pharmacy still active
      storedPharmacy = pharmacyList.find((item) => item.pharmacy.id === storedPharmacy.pharmacy.id);
    }

    if (!storedPharmacy) {
      // Load from localStorage
      const pharmacyIdStr = await getAsyncItem(storageKeys.lastPharmacy);
      const pharmacyId = Number(pharmacyIdStr);
      if (pharmacyId && !isNaN(pharmacyId)) {
        storedPharmacy = pharmacyList.find((item) => item.pharmacy.id === pharmacyId);
      }
    }

    const selectedPharmacy = storedPharmacy || pharmacyList[0];
    setCurrentPharmacy(selectedPharmacy);

    return selectedPharmacy;
  };

  const updatePharmacies = async () => {
    try {
      setLoading(true);
      const res = await apiMePharmacies();

      dispatch(SET_PHARMACIES(res.data));
      await loadCurrentPharmacy(res.data);
      setLoading(false);

      return res.data;
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  const setCurrentPharmacy = (pharmacy: PharmacyMemberModel) => {
    dispatch(SET_CURRENT_PHARMACY(pharmacy));
  };

  return {
    pharmacies,
    adminPharmacies,
    currentPharmacy,
    setCurrentPharmacy,
    updatePharmacies,
    loadCurrentPharmacy,
    loading,
  };
};

export const useSetCurrentPharmacy = () => {
  const currentPharmacy = useSelector(currentPharmacySelector);
  const pharmacies = useSelector(pharmaciesSelector);

  const { loadCurrentPharmacy } = useCurrentPharmacy();

  useEffect(() => {
    if (!isPharmacyVersion()) return;
    if (!currentPharmacy && pharmacies?.length) {
      loadCurrentPharmacy();
    }
  }, [pharmacies]);
};
