import React from 'react';

import { OnDemandAvailabilitiesMobile } from '../OnDemandAvailabilitiesMobile';

import { flex1, mb20 } from '~/common/commonStyles';
import { ClinicDoctorListing } from '~/components/clinic/doctors/ClinicDoctorListing';
import { ClinicDashboardHeader } from '~/components/clinic/headers/ClinicDashboardHeader';
import { ClinicRequestsList } from '~/components/dashboard/clinic/ClinicRequestsList';
import { DashboardCard } from '~/components/dashboard/common/DashboardCard';
import { PageLayout } from '~/layouts/EnhancedPageLayout';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const ClinicDashboard: React.FC = () => {
  const { isDesktop, isTablet, isMobile } = useBreakpoints();

  return (
    <PageLayout hideBack hideGradient header={<ClinicDashboardHeader />} contentStyling={!isMobile ? flex1 : null}>
      <Grid grid={{ noOuterGutter: isDesktop || isTablet, gutters: isDesktop ? 30 : 10 }}>
        <Container style={[flex1]} alignSelf="flex-start">
          <Row style={!isMobile ? flex1 : null}>
            <Column span={{ xs: 12, lg: 8 }} style={[!isMobile ? flex1 : null, isDesktop ? null : mb20]}>
              <ClinicRequestsList />
            </Column>
            <Column span={{ xs: 12, lg: 4 }}>
              <Row style={mb20}>
                <Column>
                  <DashboardCard white>
                    <OnDemandAvailabilitiesMobile />
                  </DashboardCard>
                </Column>
              </Row>
              {isDesktop ? (
                <Row style={flex1}>
                  <Column style={flex1}>
                    <DashboardCard white title="Clinic Doctors" style={{ minHeight: 250 }}>
                      <ClinicDoctorListing />
                    </DashboardCard>
                  </Column>
                </Row>
              ) : null}
            </Column>
          </Row>
        </Container>
      </Grid>
    </PageLayout>
  );
};
