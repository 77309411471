import { get, put } from '../../axios';
import { HealthProfileRequest } from '../../models/healthProfile/requests/HealthProfileRequest';
import { HealthProfileResponse } from '../../models/healthProfile/responses/HealthProfileResponse';

import { AuditLogsResponse } from '~/api/models/audit/responses/AuditLogsResponse';
import { IdModel } from '~/api/models/common/models/IdModel';
import { HealthProfileAuditLogsRequest } from '~/api/models/healthProfile/requests/HealthProfileAuditLogsRequest';

export const apiAddHealthProfile = (params: { id: number; data: HealthProfileRequest }) => {
  return put<HealthProfileResponse>(`/accounts/${params.id}/health-profile`, params.data);
};

export const apiUpdateOrCreateHealthProfile = (params: { id: number; data: HealthProfileRequest }) => {
  return put<HealthProfileResponse>(`/accounts/${params.id}/health-profile`, params.data);
};

export const apiGetHealthProfile = (params: IdModel) => {
  return get<HealthProfileResponse>(`/accounts/${params.id}/health-profile`);
};

export const apiGetHealthProfileAuditLogs = (params: HealthProfileAuditLogsRequest) => {
  return get<AuditLogsResponse>(`/health-profile/${params.id}/audit-logs`, params.req);
};
