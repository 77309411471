import { PrescriptionCollectionStatusEnum } from '~/api/models/consultations/enums/PrescriptionCollectionStatusEnum';
import { colors } from '~/utils/colors';

export const PRESCRIPTION_COLLECTION_STATUS_COLOR_MAPPING = {
  [PrescriptionCollectionStatusEnum.Active]: colors.success,
  [PrescriptionCollectionStatusEnum.Collected]: colors.success,
  [PrescriptionCollectionStatusEnum.Uncollected]: colors.purple,
  [PrescriptionCollectionStatusEnum.Draft]: colors.grey,
  [PrescriptionCollectionStatusEnum.Used]: colors.grey,
};
