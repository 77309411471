import React from 'react';

import { TitleLayout } from '../../../layouts/TitleLayout';
import { labels } from '../../../utils/labels';

import { MobileMenuPageComponent } from '~/components/navigation/MobileMenuPageComponent';
import { settingsMenu } from '~/constants/settingsMenu';
import { colors } from '~/utils/colors';

export const SettingsMobilePage: React.FC = () => {
  return (
    <TitleLayout title={labels.settings} style={{ backgroundColor: colors.lightPurple }}>
      <MobileMenuPageComponent menu={settingsMenu.filter((item) => !item.hidden)} />
    </TitleLayout>
  );
};
