import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { apiGetDependantRelationships } from '~/api/services/dependants';
import { RootState } from '~/redux/reducers';
import { UPDATE_RELATIONSHIPS_LIST } from '~/redux/reducers/referenceDataReducer';
import { useAppDispatch } from '~/redux/store';

export function useDependantsRelationshipListing() {
  const relationshipData = useSelector((state: RootState) => state.referenceDataReducer.dependantRelationships);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!relationshipData) {
      apiGetDependantRelationships()
        .then((res) => dispatch(UPDATE_RELATIONSHIPS_LIST(res.data)))
        .catch(() => {});
    }
  }, []);

  return { relationshipItems: relationshipData ?? [] };
}
