import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

import { BreakpointDefaults, DeviceSizeDefaults } from '~/constants/grid/defaults';
import getBreakpoint from '~/theme/components/grid/utils/getBreakpoint';

export function useBreakpoints() {
  const windowWidth = useWindowDimensions().width;

  const isMobile = useMemo(() => windowWidth < DeviceSizeDefaults.mobile, [windowWidth]);
  const isTablet = useMemo(
    () => windowWidth >= DeviceSizeDefaults.mobile && windowWidth <= DeviceSizeDefaults.tablet,
    [windowWidth]
  );
  const isDesktop = useMemo(() => windowWidth > DeviceSizeDefaults.tablet, [windowWidth]);

  const breakpoint = useMemo(() => getBreakpoint(BreakpointDefaults, windowWidth), [windowWidth]);

  const isXl = useMemo(() => breakpoint === 'xl', [breakpoint]);

  const getByDeviceSize = <T = any>(items: { mobile?: T; tablet?: T; desktop?: T }, options?: { up: boolean }) => {
    if (!options?.up) {
      if (isDesktop) {
        return items.desktop || items.tablet || items.mobile;
      }
      if (isTablet) {
        return items.tablet || items.mobile;
      }
      if (isMobile) {
        return items.mobile;
      }
    } else {
      if (isMobile) {
        return items.mobile || items.tablet || items.desktop;
      }
      if (isTablet) {
        return items.tablet || items.desktop;
      }
      if (isDesktop) {
        return items.desktop;
      }
    }
  };

  const getByBreakpoint = <T = any>(items: { xs?: T; sm?: T; md?: T; lg?: T; xl?: T }, options?: { up: boolean }) => {
    switch (getBreakpoint(BreakpointDefaults, windowWidth)) {
      case 'xs':
        if (!options?.up) {
          return items.xs;
        } else {
          return items.xs || items.sm || items.md || items.lg || items.xl;
        }
      case 'sm':
        if (!options?.up) {
          return items.sm || items.xs;
        } else {
          return items.sm || items.md || items.lg || items.xl;
        }
      case 'md':
        if (!options?.up) {
          return items.md || items.sm || items.xs;
        } else {
          return items.md || items.lg || items.xl;
        }
      case 'lg':
        if (!options?.up) {
          return items.lg || items.md || items.sm || items.xs;
        } else {
          return items.lg || items.xl;
        }
      case 'xl':
        if (!options?.up) {
          return items.xl || items.lg || items.md || items.sm || items.xs;
        } else {
          return items.xl;
        }
    }
  };

  return { windowWidth, isMobile, isTablet, isDesktop, breakpoint, getByBreakpoint, getByDeviceSize, isXl };
}
