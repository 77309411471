import React, { useMemo } from 'react';
import { View } from 'react-native';

import { DoctorListingComponent } from './DoctorListingComponent';

import { ConsultationTypeEnum } from '~/api/models/common/constants/ConsultationTypeEnum';
import { flex1, pb20, textCenter } from '~/common/commonStyles';
import { AccordionGroupProvider } from '~/components/buttons/Accordions/AccordionGroupProvider';
import { SmallNsRegularBlack } from '~/components/commonText';
import { LoadingActivityIndicator } from '~/components/loading/LoadingActivityIndicator';
import { useDoctorListingContext } from '~/providers/appointment/DoctorListingContext';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const DoctorListingAccordions: React.FC = () => {
  const { doctors, loading, consultationTypes, setPage } = useDoctorListingContext();
  const { isMobile } = useBreakpoints();

  const hasOnDemand = useMemo(
    () =>
      !!doctors?.on_demand?.meta?.total &&
      (!consultationTypes || consultationTypes.includes(ConsultationTypeEnum.ON_DEMAND)),
    [doctors?.on_demand?.meta?.total, consultationTypes]
  );
  const hasScheduled = useMemo(
    () =>
      !!doctors?.scheduled_appointment?.meta?.total &&
      (!consultationTypes || consultationTypes.includes(ConsultationTypeEnum.SCHEDULED_APPOINTMENT)),
    [doctors?.scheduled_appointment?.meta?.total, consultationTypes]
  );
  const hasHomeVisit = useMemo(
    () =>
      !!doctors?.home_visit?.meta?.total &&
      (!consultationTypes || consultationTypes.includes(ConsultationTypeEnum.HOME_VISIT)),
    [doctors?.home_visit?.meta?.total, consultationTypes]
  );

  return (
    <AccordionGroupProvider keepState>
      <View style={[{ gap: 10 }, isMobile ? pb20 : flex1]}>
        {hasOnDemand ? (
          <DoctorListingComponent
            consultationType={ConsultationTypeEnum.ON_DEMAND}
            doctors={doctors.on_demand}
            setPage={(page) => setPage(page, ConsultationTypeEnum.ON_DEMAND)}
            initialExpanded
          />
        ) : null}
        {hasScheduled ? (
          <DoctorListingComponent
            consultationType={ConsultationTypeEnum.SCHEDULED_APPOINTMENT}
            doctors={doctors.scheduled_appointment}
            setPage={(page) => setPage(page, ConsultationTypeEnum.SCHEDULED_APPOINTMENT)}
            initialExpanded={!hasOnDemand}
          />
        ) : null}
        {hasHomeVisit ? (
          <DoctorListingComponent
            consultationType={ConsultationTypeEnum.HOME_VISIT}
            doctors={doctors.home_visit}
            setPage={(page) => setPage(page, ConsultationTypeEnum.HOME_VISIT)}
            initialExpanded={!hasOnDemand && !hasScheduled}
          />
        ) : null}
        {!hasOnDemand && !hasHomeVisit && !hasScheduled ? (
          loading ? (
            <LoadingActivityIndicator style={flex1} />
          ) : (
            <View>
              <SmallNsRegularBlack style={textCenter}>No doctors are available</SmallNsRegularBlack>
            </View>
          )
        ) : null}
      </View>
    </AccordionGroupProvider>
  );
};
