import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { Column, Container, Grid, Row } from '../../../theme/components/grid';
import { colors } from '../../../utils/colors';
import { labels } from '../../../utils/labels';
import { H5TtmRegularSecondaryBlack, H5TtmSemiBoldBlack, H5TtmSemiBoldDisableColor } from '../../commonText';

import { useAccountDetails } from '~/api/hooks/accounts/AccountDetails';
import { LinkButton } from '~/components/buttons/LinkButton';
import { PatientListedDetails } from '~/components/common/patient/PatientListedDetails';
import { usePageFocus } from '~/utils/hooks/FocusHook';
import { screenName } from '~/utils/screenName';

interface Props {
  id: number;
}

export const PatientInformation: React.FC<Props> = ({ id }) => {
  const { address, account, getAccountDetails } = useAccountDetails({ id });

  usePageFocus(() => {
    getAccountDetails();
  });

  const canEdit = useMemo(() => !account?.is_user, [account]);
  const addressString = useMemo(() => {
    if (address) {
      return [address.line_1, address.post_code, address.country?.name].filter((item) => item).join('\n');
    } else {
      return '\n\n\n';
    }
  }, [address]);
  return (
    <Grid grid={{ noOuterGutter: true, gutters: 6 }}>
      <Container style={styles.container}>
        <Row>
          <Column>
            <H5TtmSemiBoldBlack>{labels.patientInformation}</H5TtmSemiBoldBlack>
          </Column>
          {canEdit ? (
            <Column alignSelf="flex-end" span="auto">
              <LinkButton
                title="Edit"
                to={{ screen: screenName.PatientEditDetails as never, params: { id } as never }}
              />
            </Column>
          ) : null}
        </Row>
        <PatientListedDetails account={account} />
        <Row>
          <Column width={100}>
            <H5TtmSemiBoldDisableColor>{labels.address}</H5TtmSemiBoldDisableColor>
          </Column>
          <Column>
            <H5TtmRegularSecondaryBlack>{addressString}</H5TtmRegularSecondaryBlack>
          </Column>
        </Row>
      </Container>
    </Grid>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.lightPurple2,
    padding: 10,
  },
  profileImage: {
    width: 80,
    height: 80,
    borderRadius: 6,
    overflow: 'hidden',
  },
});
