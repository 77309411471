import download from 'downloadjs';
import Constants from 'expo-constants';
import { DocumentPickerAsset } from 'expo-document-picker';

import { DownloadOptionsInterface } from './interfaces/DownloadOptionsInterface';

import { getToken, postFormData, putFormData } from '~/api/axios';
import { MediaModel } from '~/api/models/common/models/MediaModel';

const BaseUrl = Constants.expoConfig.extra.baseUrl;

const downloadMedia = (media: MediaModel, options?: DownloadOptionsInterface) => {
  return fetch(media.url, { method: 'GET', headers: { 'Cache-Control': 'no-cache' } })
    .then((res) => {
      if (res.status >= 400) {
        throw new Error('Unable to download media at this time');
      }
      return res.blob();
    })
    .then((blob) => download(blob, media.file_name));
};

const downloadStream = (url: string, options: { fileName: string; method?: string; data: any; mimeType: string }) => {
  const token = getToken();
  return fetch(`${BaseUrl}${url}`, {
    method: options?.method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: options?.data ? JSON.stringify(options.data) : undefined,
  })
    .then((response) => {
      console.log(response.status, typeof response.status);
      if (response.status >= 400) {
        throw new Error('Unable to retrieve your file at this time');
      } else return response.body;
    })
    .then((rb) => {
      const reader = rb.getReader();

      return new ReadableStream({
        start(controller) {
          // The following function handles each data chunk
          function push() {
            // "done" is a Boolean and value a "Uint8Array"
            reader.read().then(({ done, value }) => {
              // If there is no more data to read
              if (done) {
                controller.close();
                return;
              }
              // Get the data and send it to the browser via the controller
              controller.enqueue(value);
              // Check chunks by logging to the console
              push();
            });
          }

          push();
        },
      });
    })
    .then((stream) =>
      // Respond with our stream
      new Response(stream, { headers: { 'Content-Type': 'application/octet-stream' } }).blob()
    )
    .then((blob) => download(blob, options?.fileName));
};

const extractFormData = async (field: string, document?: DocumentPickerAsset, otherData?: Record<string, any>) => {
  if (!document) throw new Error('Failed to read the file. Document result was not successful');
  if (!document.file) throw new Error('Document result does not contain a file');
  const formData = new FormData();

  formData.append(field, document.file);
  if (otherData) {
    for (const key of Object.keys(otherData)) {
      formData.append(key, otherData[key]);
    }
  }

  return formData;
};

async function postDocumentResult<T = any>(
  url: string,
  field: string,
  document: DocumentPickerAsset,
  otherData: Record<string, any>
) {
  const formData = await extractFormData(field, document, otherData);
  return postFormData<T>(url, formData);
}

async function putDocumentResult<T = any>(
  url: string,
  field: string,
  document: DocumentPickerAsset,
  otherData: Record<string, any>
) {
  const formData = await extractFormData(field, document, otherData);
  return putFormData<T>(url, formData);
}

export default {
  downloadMedia,
  downloadStream,
  postDocumentResult,
  putDocumentResult,
};
