import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { useLocalityListing } from '~/api/hooks/referenceData/LocalityListing';
import { UpdatePharmacyRequest } from '~/api/models/pharmacy/requests/UpdatePharmacyRequest';
import { apiUpdatePharmacy } from '~/api/services/pharmacy';
import { CancelContinueFooter } from '~/components/buttons/CancelContinueFooter';
import FloatingInput from '~/components/floatingInputBox';
import { Dropdown } from '~/components/inputs/dropdown/Dropdown';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { NestedPageInternalLayout } from '~/layouts/NestedPageInternalLayout';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { validationSchema, alphaNumericValidationWithSpace, minLengthValidation } from '~/services/validationConfig';
import { Grid, Container, Row, Column } from '~/theme/components/grid';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

export const PharmacyLocationScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { localities } = useLocalityListing();
  const { isMobile } = useBreakpoints();

  const currentPharmacy = useSelector(currentPharmacySelector);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<UpdatePharmacyRequest>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      id: undefined,
      params: {
        address: '',
        post_code: '',
        locality_id: undefined,
      },
    },
  });

  useEffect(() => {
    if (!currentPharmacy) return;
    reset({
      id: currentPharmacy.pharmacy.id,
      params: {
        address: currentPharmacy.pharmacy.address,
        post_code: currentPharmacy.pharmacy.post_code,
        locality_id: currentPharmacy.pharmacy.locality.id,
      },
    });
  }, [currentPharmacy?.pharmacy.id]);

  const onSubmit = async (data: UpdatePharmacyRequest) => {
    try {
      setLoading(true);
      await apiUpdatePharmacy(data);
    } catch (e) {
      ErrorAlert(e);
    }
    setLoading(false);
  };

  return (
    <NestedPageInternalLayout
      title={isMobile ? 'Pharmacy location' : null}
      footer={
        <CancelContinueFooter
          disabled={!isDirty || loading}
          onConfirm={handleSubmit(onSubmit)}
          onCancel={reset}
          confirmTitle={labels.update}
        />
      }>
      <Grid>
        <Container alignSelf="flex-start" maxContainerWidths={380}>
          <Row>
            <Column>
              <View>
                <Controller
                  name="params.address"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                    formState: { isSubmitted },
                  }) => (
                    <FloatingInput
                      value={value}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      error={isSubmitted && !!error?.message}
                      errorMessage={isSubmitted && error?.message}
                      label="Pharmacy address"
                    />
                  )}
                />
                <Controller
                  name="params.locality_id"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
                    <Dropdown
                      list={localities.map((item) => ({ label: item.name, value: item.id }))}
                      setValue={onChange}
                      value={value}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      searchable
                      label="Locality"
                    />
                  )}
                />

                <Controller
                  name="params.post_code"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                    formState: { isSubmitted },
                  }) => (
                    <FloatingInput
                      label={labels.postCode}
                      value={value}
                      onChangeValue={onChange}
                      onBlur={onBlur}
                      error={isSubmitted && !!error}
                      errorMessage={isSubmitted && error?.message}
                      maxLength={validationSchema.postCode.maxLength}
                    />
                  )}
                  rules={{
                    validate: { alphaNumericValidationWithSpace },
                    minLength: minLengthValidation(validationSchema.postCode.minLength),
                  }}
                />
              </View>
            </Column>
          </Row>
        </Container>
      </Grid>
    </NestedPageInternalLayout>
  );
};
