import { useNavigation } from '@react-navigation/native';
import React, { ReactNode, useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { flex1, mb5, mt10 } from '~/common/commonStyles';
import { MOBILE_HORIZONTAL_SPACE } from '~/common/mobileStyles';
import { Gradient } from '~/components/common/Gradient';
import { H2TtmSemiBoldBlack, H3TtmSemiBoldTheme } from '~/components/commonText';
import { KeyboardAScrollView } from '~/components/commonViews';
import { BackNavigation } from '~/components/navigation/BackNavigation';
import { Column, Container, Grid, Row } from '~/theme/components/grid';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export interface PageLayoutProps {
  back?: {
    to: { screen: string; params?: Record<string, any> };
    title?: string;
  };
  children?: React.ReactNode;
  scrollStyling?: StyleProp<ViewStyle>;
  contentStyling?: StyleProp<ViewStyle>;
  footerStyling?: StyleProp<ViewStyle>;
  flexContent?: boolean;
  absoluteFooter?: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  headerIcon?: React.ReactNode;
  headerRight?: React.ReactElement;
  hideBack?: boolean;
  hideHeaderRight?: boolean;
  hideGradient?: boolean;
  scrollContentNode?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  title?: string;
  subtitle?: string;
  extraHeaderDetails?: ReactNode;
  noScrollPadding?: boolean;
  noTopPadding?: boolean;
}
export const PageLayout: React.FC<PageLayoutProps> = ({
  back,
  children,
  scrollStyling,
  contentStyling,
  flexContent,
  footer,
  footerStyling,
  absoluteFooter,
  header,
  headerIcon,
  headerRight,
  hideBack,
  hideHeaderRight,
  hideGradient,
  scrollContentNode,
  style,
  title,
  subtitle,
  extraHeaderDetails,
  noScrollPadding,
  noTopPadding,
}) => {
  const { isMobile } = useBreakpoints();
  const navigation = useNavigation();

  const hasTopPadding = isMobile && !noTopPadding;
  const headerFooterPadding = useMemo(() => (isMobile ? MOBILE_HORIZONTAL_SPACE : 0), [isMobile]);

  const showGoBack = useMemo(() => {
    return !hideBack && (!!back?.to || navigation.canGoBack());
  }, [back?.to, navigation, hideBack]);

  return (
    <View style={[styles.container, style, hasTopPadding ? { paddingTop: 20 } : null]}>
      {header || title || headerIcon || !hideBack ? (
        <View style={[styles.header, { paddingHorizontal: headerFooterPadding, marginBottom: !isMobile ? 15 : 0 }]}>
          {header ? (
            header
          ) : (
            <Grid grid={{ gutters: 15 }}>
              <Container fluid>
                <Row justifyContent="space-between" alignItems="flex-start">
                  {hideBack && !title && !headerIcon ? null : (
                    <Column style={styles.headerLeft} span="auto">
                      <View style={{ maxWidth: '100%' }}>
                        {showGoBack ? <BackNavigation title={back?.title} backLink={back?.to} /> : null}
                        {title ? (
                          <H2TtmSemiBoldBlack style={[showGoBack ? mt10 : null, mb5, { minHeight: 45 }]}>
                            {title}
                          </H2TtmSemiBoldBlack>
                        ) : null}
                        {subtitle ? <H3TtmSemiBoldTheme style={[mt10]}>{subtitle}</H3TtmSemiBoldTheme> : null}
                        {extraHeaderDetails}
                      </View>
                      {headerIcon && (isMobile || hideHeaderRight) ? headerIcon : null}
                    </Column>
                  )}
                  {hideHeaderRight ? null : (
                    <Column style={styles.headerRight}>
                      <Row justifyContent="flex-end">
                        <Column>{headerRight}</Column>
                        {!isMobile ? headerIcon : null}
                      </Row>
                    </Column>
                  )}
                </Row>
              </Container>
            </Grid>
          )}
        </View>
      ) : null}

      <View style={[styles.container, style]}>
        {scrollContentNode ? (
          <View style={[styles.content]}>
            {scrollContentNode}
            {hideGradient || !footer ? null : <Gradient />}
          </View>
        ) : (
          <>
            <KeyboardAScrollView
              style={[styles.content, scrollStyling]}
              contentContainerStyle={[
                contentStyling,
                footer ? styles.contentFooterPadding : null,
                flexContent ? flex1 : null,
                noScrollPadding || !isMobile ? null : { paddingHorizontal: MOBILE_HORIZONTAL_SPACE },
              ]}>
              {children}
            </KeyboardAScrollView>
            {hideGradient ? null : <Gradient />}
          </>
        )}
        {absoluteFooter}
      </View>
      {footer ? (
        <View style={[styles.footer, { paddingHorizontal: headerFooterPadding }, footerStyling]}>{footer}</View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    backgroundColor: colors.white,
  },
  scrollContainer: {
    backgroundColor: colors.transparent,
    flex: 1,
    display: 'flex',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    minHeight: 45,
  },
  headerRight: {
    marginBottom: 10,
  },
  content: {
    flex: 1,
    display: 'flex',
  },
  contentFooterPadding: {
    paddingBottom: 30,
  },
  footer: {
    backgroundColor: 'white',
    paddingBottom: 10,
  },
});
