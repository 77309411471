export const patientManagementLabels = {
  exportPatientsAction: 'Export patient details',
  exportPatientsInProgress: [
    'Exporting your patient details, this may take a while.',
    'We will notify you when it is done.',
  ],
  exportPatientsTitle: 'Exporting patients',
  downloadTemplateAction: 'Download template',
  uploadPatientAction: 'Upload patient details',
  consultationEnsureDetailsCorrect:
    'Please ensure that all details are correct. Once the consultation report is submitted it cannot be edited.',
  includeSignature: 'Include signature',
  useBulkImport:
    'or use our bulk import functionality by downloading the excel template and uploading your patient details',
  addNewOrBulkImport:
    "Click on 'Add a New Patient' or use our bulk import functionality by downloading the excel template and uploading your patient details",
  documentImportDetails: 'Document import details',
  documentImportSubtitle: 'We ran into some issues while importing your data from',

  linkingErrorMessage:
    'We were unable to check if the patient has an active account with Digimed we can link you with. Please try linking the patient at a later date.',
  declinedLinkAccountMessage: [
    'We were not able to complete your request at this time.',
    "You can continue creating the patient's account or cancel and try again next time.",
  ],
  declinedLinkAccountTitle: 'Unable to link existing account',
  accountApproved: 'Account approved',
  viewPatientDetails: 'View Patient Details',
  accountAlreadyExistsError: [
    'The account already exists. In order to have access to the patient details and health records the patient would need to confirm the notification.',
  ],
  awaitingApproval: 'Awaiting approval',
  resendNotification: 'Re-send Notification',
  alreadyExists: 'Account already exists',
};
