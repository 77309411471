import { useConsultationHelper } from '../helpers/HelperConsultation';

import { ConsultationEventsEnum } from '~/api/models/channel/enum/channelEventsEnum';
import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { useSnackbarManager } from '~/providers/snackbar/SnackbarManagementContext';
import { isDoctorVersion } from '~/utils/buildConfig';
import { useShowAppointment } from '~/utils/hooks/appointments/AppointmentShowHook';
import { getAccountName } from '~/utils/personalDetailsUtils';

export const useHandleHomeVisitStarting = () => {
  const { showSnackbar } = useSnackbarManager();
  const { getConsultation } = useConsultationHelper();
  const { showDetails: showAppointmentDetails } = useShowAppointment();

  const execute = async (consultationId: number) => {
    const consultation = await getConsultation(consultationId);
    if (!consultation || consultation.state !== ConsultationStateEnum.Scheduled) return;

    showSnackbar(`It's time to get going for your consultation with ${getAccountName(consultation.patient)}`, {
      keyRef: `${ConsultationEventsEnum.CONSULTATION_HOME_VISIT_APPOINTMENT_STARTING}.${consultationId}`,
      actionTitle: 'Click here for navigation.',
      onPress: () => {
        showAppointmentDetails({
          consultation,
        });
      },
    });
  };
  const validate = () => {
    return isDoctorVersion();
  };

  const handleNotification = async (consultationId: number) => {
    if (!validate()) return;

    await execute(consultationId);
  };

  return { handleNotification };
};
