export const colors = {
  // welcomeblack: '#131523',
  // onBoardingMessageColor: '#354052',
  // onBoardingBackgroundColor: '#F5F6FA',
  // onBoardingDotColor: '#CAD0ED',

  // buttonColor: '#433489',
  // themeColor: '#433489',
  // // black: '#131523',
  // secondaryBlack: '#354052',
  // red: '#F0142F',
  // // Blue
  // linkTextColor: '#5EC6CD',
  // green: '#00DB47',
  // successGreen: '#17D053',
  // // grey
  // // borderLineColor: #E6E9F4,
  // // darket grey of dots
  // hintColor: '#7E84A3',
  // disabledButtonColor: '#7E84A3',
  // placeholderTextColor: '#7E84A3',
  // yellow: '#FFBC00',
  // barColor: '#606060',
  // lightBlue: '#B8E7E8',

  //New colours:
  white: '#FFFFFF',
  lightPurple: '#F5F6FA',
  lightPurple2: '#E6E9F4',
  lightPurple3: '#CAD0ED',
  purpleGrey: '#7E84A3',
  darkPurple: '#5C5694',
  purple: '#433489',
  grey: '#354052',
  black: '#131523',
  darkGrey: '#606060',
  lightGrey: '#E8E8E8',
  info: '#1F6B6B',
  lightBlue: '#5EC6CD',
  lightTurquoise: '#B8E7E8',
  fadedTurquoise: '#d8f2f3',
  success: '#17D053',
  danger: '#F0142F',
  warning: '#FFBC00',
  darkWarning: '#C65300',
  lightWarning: '#FFFBF0',
  lightYellow: '#FFF1CC',
  placeholderGrey: '#949494',
  borderGrey: '#DDE1F3',
  transparent: 'transparent',
  onDemandGreen: '#0A5C25',
  onDemandGreenBackground: '#EDFDF2',
  homeVisitGreen: '#116668',
  homeVisitGreenBackground: '#ECFFFF',
};
