import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFetchRegistrationStatus } from '../RegistrationSteps';
import { useFetchLoginDetails } from '../accounts/LoginDetails';
import { useUserDetails, useUserDetailsRequest } from '../accounts/UserDetails';
import { useVerificationStatus } from '../accounts/VerificationStatus';
import { usePharmacyStoredLinkingDetails } from '../pharmacy/PharmacyLinkingAction';

import { clearToken, hasToken } from '~/api/axios/interceptors';
import { AuthVerificationEnum } from '~/api/models/authorisation/constants/AuthVerificationEnum';
import { getParsedAsyncItem, storageKeys } from '~/common/asyncStorage';
import { NavStacks } from '~/constants/NavStacksEnum';
import { SET_ONBOARDING_COMPLETE, isOnboardingComplete } from '~/redux/reducers/appDetailsReducer';
import { registrationCompletedSelector } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { isClinicVersion, isPharmacyVersion } from '~/utils/buildConfig';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import log from '~/utils/logger';
import {
  registrationPreKyiCompleted,
  registrationPreDigimedApprovalCompleted,
  registrationAllCompleted,
} from '~/utils/registration/registrationCompletion';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';
import { useCurrentPharmacy } from '../pharmacy/PharmacyHook';

export function useNavigationGuardHook() {
  const [loading, setLoading] = useState<boolean>(undefined);
  const { isMobile } = useBreakpoints();
  const [stack, setStack] = useState(NavStacks.Loading);
  const dispatch = useAppDispatch();

  const fetchLoginDetails = useFetchLoginDetails();
  const fetchRegistrationStatus = useFetchRegistrationStatus();
  const fetchVerificationStatus = useVerificationStatus();
  const fetchUserDetails = useUserDetailsRequest();
  const currentPharmacy = useSelector(currentPharmacySelector);
  const registrationCompleted = useSelector(registrationCompletedSelector);
  const onboardingCompleted = useSelector(isOnboardingComplete);
  const { hasLinkingDetails } = usePharmacyStoredLinkingDetails();
  const { updatePharmacies } = useCurrentPharmacy();

  useUserDetails({ allowStale: true });

  const verificationStatus = async () => {
    let status = await fetchVerificationStatus.getVerificationStatus();
    return status;
  };

  const fetchData = async () => {
    if (isPharmacyVersion()) {
      await updatePharmacies();
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchVerificationStatus.statusEnum]);

  const processNavigationGuardHook = async () => {
    // Check token is present
    const hasAuthToken = await hasToken();
    const loginDetails = await fetchLoginDetails.getLoginDetails();
    const onboardingComplete = await getParsedAsyncItem<boolean>(storageKeys.onboarding);
    dispatch(SET_ONBOARDING_COMPLETE(onboardingComplete));

    if (!onboardingComplete && isMobile && !isClinicVersion()) {
      return NavStacks.Onboarding;
    }

    if (!hasAuthToken) {
      return NavStacks.Registration;
    }

    // Check stored login credentials
    if (!loginDetails?.steppedUp) {
      if (!loginDetails) await clearToken();
      return NavStacks.Registration;
    }

    // Check registration steps are completed
    const registrationStatus = await fetchRegistrationStatus.updateRegistrationStatus();
    if (!registrationStatus) {
      log.warn('Missing registration status, could be already being retrieved');
    }

    if (
      !registrationPreKyiCompleted(
        registrationStatus?.data,
        isPharmacyVersion() && !hasLinkingDetails && !registrationStatus?.is_completed
      )
    ) {
      return NavStacks.Registration;
    }

    // Check account is verified
    const status = await verificationStatus();
    if (status !== AuthVerificationEnum.APPROVED && status !== AuthVerificationEnum.PENDING_ADMIN) {
      return NavStacks.Pending;
    }

    if (!(await registrationPreDigimedApprovalCompleted(registrationStatus.data))) {
      return NavStacks.Registration;
    }

    if (status !== AuthVerificationEnum.APPROVED) {
      return NavStacks.Pending;
    }

    const userDetails = await fetchUserDetails
      .getUserDetails()
      .then((res) => res.data)
      .catch(() => undefined);

    if (!(await registrationAllCompleted(registrationStatus.data, userDetails, registrationStatus?.is_completed))) {
      return NavStacks.Registration;
    }

    if (status !== AuthVerificationEnum.APPROVED) {
      return NavStacks.Pending;
    }

    return NavStacks.Authorised;
  };

  const handleNavigationGuard = async () => {
    if (loading === true) return;

    setLoading(true);

    const navStack = await processNavigationGuardHook();
    setStack(navStack);
    setLoading(false);
  };

  useEffect(() => {
    handleNavigationGuard();
  }, [
    fetchLoginDetails.details?.steppedUp,
    registrationCompleted.all,
    registrationCompleted.preDigimed,
    registrationCompleted.preKyi,
    fetchVerificationStatus.statusEnum,
    onboardingCompleted,
    currentPharmacy?.pharmacy?.id,
  ]);

  return { loading, stack };
}
