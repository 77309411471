import {
  MaterialTopTabDescriptorMap,
  MaterialTopTabNavigationEventMap,
} from '@react-navigation/material-top-tabs/lib/typescript/src/types';
import { NavigationHelpers, ParamListBase, TabNavigationState } from '@react-navigation/native';
import React, { useMemo } from 'react';

import { TabSelectionMobileComponent } from './TabSelectionMobileComponent';

interface Props {
  state: TabNavigationState<ParamListBase>;
  navigation: NavigationHelpers<ParamListBase, MaterialTopTabNavigationEventMap>;
  descriptors: MaterialTopTabDescriptorMap;
}

export const TabNavigationComponent: React.FC<Props> = ({ state, descriptors, navigation }) => {
  const onPress = (routeKey: string) => {
    const event = navigation.emit({
      type: 'tabPress',
      target: routeKey,
      canPreventDefault: true,
    });

    const routeIndex = state.routes.findIndex((route) => route.key === routeKey);
    if (state.index !== routeIndex && !event.defaultPrevented) {
      navigation.navigate(state.routes[routeIndex].name);
    }
  };

  const items = useMemo(() => {
    return state.routes.map((route, index) => {
      const { options } = descriptors[route.key];

      return {
        key: route.key,
        title: (typeof options.tabBarLabel === 'string' ? options.tabBarLabel : '') || options.title || route.name,
        focused: state.index === index,
      };
    });
  }, [state.routes]);

  return <TabSelectionMobileComponent items={items} onPress={onPress} />;
};
