export const consultationsLabels = {
  consultationReference: 'Consultation Ref#',
  consultationDetails: 'Consultation Details',
  chat: 'Chat',
  chatConsultation: 'Chat Consultation',
  doctorAvailableNow: 'Doctors available now',
  scheduleForLater: 'Schedule for later',
  scheduleAnAppointment: 'Schedule an appointment',
  homeVisit: 'Home visit',

  doctorAvailableNowDescription: {
    doctor:
      'This feature will allow patients to search for doctors available for an immediate telemedicine consultation. You can easily control when you are online or offline to accept requests, or otherwise, as well as amend your fees, in your Settings.',
    patient:
      'This feature will allow patients to search for doctors available for an immediate telemedicine consultation.',
  },
  scheduleForLaterDescription: {
    doctor:
      'This feature will allow patients to book telemedicine consultations with you, using your programmed schedule/booking calendar. You may amend your fees, schedule, or create new/multiple schedules at any time in your Settings.',
    patient:
      'This feature will allow patients to book telemedicine consultations with doctors, using their programmed schedule/booking calendar.',
  },
  homeVisitDescription: {
    doctor:
      'Some patients will still prefer a Home Visit from their doctors. Digimed can facilitate this by showing patients a list of doctors available for a Home Visit as they require it. You may set and amend your availabilities and fees for home visits, as well as maximum distance from your location at any time in your Settings.',
    patient:
      'This feature will allow patients to book a home visit with a doctor from the list of doctors who have marked themselves available for home visits.',
  },
  outsideEU: {
    title: 'Attention: Access Outside EU',
    message: [
      'You are currently accessing Digimed from outside the European Union (EU). Please be aware that while you can view previous records, consultations are not available. Press "Okay" to proceed.',
      'Thank you for your understanding.',
    ],
  },
  timeRemaining: 'Time Remaining',
  timeOver: 'Time Over',
  endConsultation: 'End Consultation',
  endCall: 'End Call',
};
