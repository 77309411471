import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { apiGetHealthRecordTemplates } from '~/api/services/consultations/healthRecord';
import { RootState } from '~/redux/reducers';
import { SET_TEMPLATES } from '~/redux/reducers/healthRecordReducer';
import { useAppDispatch } from '~/redux/store';

export const TEMPLATE_ID_DEFAULT = 13;

export function useHealthRecordTemplates() {
  const [loading, setLoading] = useState(true);
  const templates = useSelector((state: RootState) => state.healthRecordReducer.templates || []);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!templates.length) {
      setLoading(true);

      apiGetHealthRecordTemplates({ limit: 100 })
        .then((res) => {
          if (res.data.data.length) {
            const templateOther = res.data.data.find((template) => template.id === TEMPLATE_ID_DEFAULT);
            const templates = [
              templateOther,
              ...res.data.data.filter((template) => template.id !== TEMPLATE_ID_DEFAULT),
            ];
            dispatch(SET_TEMPLATES(templates));
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);
  return { templates, loading };
}
