import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { HealthRecordQuestionEditor } from './HealthRecordQuestionEditor';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { IdTitleModel } from '~/api/models/common/models/IdTitleModel';
import { BaseHealthRecordAnswerModel } from '~/api/models/consultations/models/HealthRecordAnswerModel';
import { HealthRecordTemplateQuestionModel } from '~/api/models/consultations/requests/HealthRecordTemplateQuestionModel';
import { mt10 } from '~/common/commonStyles';
import { H3TtmSemiBoldBlack } from '~/components/commonText';
import { ClinicalTermSearchableList } from '~/components/inputs/searchable/ClinicalTermSearchableList';
import { useHealthRecordDataContext } from '~/providers/consultation/HealthRecordDataContext';
import { colors } from '~/utils/colors';

interface Props {
  questions: HealthRecordTemplateQuestionModel[];
  template: IdTitleModel;
}

export type HealthRecordTemplateEditorSubmitItem = {
  answers?: BaseHealthRecordAnswerModel[];
  symptoms?: { label: string; value: string }[];
};
export type HealthRecordTemplateEditorRef = { trySubmit: () => Promise<HealthRecordTemplateEditorSubmitItem> };

export const HealthRecordTemplateEditor: React.FC<Props> = ({ questions, template }) => {
  const {
    form: { control, rules },
  } = useHealthRecordDataContext();

  if (!template) return null;
  return (
    <View style={styles.container}>
      <H3TtmSemiBoldBlack>{template.title}</H3TtmSemiBoldBlack>

      <Controller
        name="symptoms"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error }, formState: { isSubmitted } }) => (
          <ClinicalTermSearchableList
            type={ClinicalTermTypeEnum.TEMPLATE_SYMPTOMS}
            values={value}
            setValues={onChange}
            title="Symptoms"
            error={isSubmitted && !!error?.message}
            errorMessage={isSubmitted && error?.message}
            showMandatory={!!rules.symptoms.required}
          />
        )}
        rules={rules.symptoms}
      />

      {questions?.length ? (
        <View style={mt10}>
          {questions?.map((question) => (
            <View key={question.id} style={mt10}>
              <HealthRecordQuestionEditor question={question} control={control} />
            </View>
          )) || null}
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 18,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: colors.lightPurple,
    marginTop: 8,
    borderRadius: 6,
  },
});
