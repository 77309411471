import { useEffect, useState } from 'react';

import {
  InsuranceClaimFormMobileWizardSteps,
  InsuranceClaimFormWizardSteps,
} from '../constants/insuranceClaimFormWizardSteps';

import { useBreakpoints } from '~/utils/hooks/GridHook';
import { WizardSteps } from '~/utils/hooks/WizardStepsHook';

interface Props {
  consultationId: number;
}

export function useInsuranceClaimFormSteps({ consultationId }: Props) {
  const { isMobile } = useBreakpoints();
  const [insuranceWizardSteps, setInsuranceWizardSteps] = useState<WizardSteps>();

  useEffect(() => {
    if (isMobile) {
      setInsuranceWizardSteps(InsuranceClaimFormMobileWizardSteps(consultationId));
    } else {
      setInsuranceWizardSteps(InsuranceClaimFormWizardSteps(consultationId));
    }
  }, [isMobile]);

  return { insuranceWizardSteps };
}
