import React, { useEffect } from 'react';
import { View } from 'react-native';

import { ConsultationViewItem } from '../../../ConsultationViewItem';

import { apiGetPrescriptionMedia } from '~/api/services/consultations/prescriptions';
import { pv20, ph10 } from '~/common/commonStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import { LoadingFetchingList } from '~/components/loading/LoadingFetchingList';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { PrescriptionModal } from '~/components/modals/prescriptions/PrescriptionModal';
import { usePrescriptionDataContext } from '~/providers/consultation/PrescriptionDataContext';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { useBreakpoints } from '~/utils/hooks/GridHook';

export const PrescriptionsViewMode: React.FC = () => {
  const { prescriptions, loading, consultation } = usePrescriptionDataContext();
  const { isMobile } = useBreakpoints();

  const { registerModal, openModal } = useModalManager();

  useEffect(() => {
    registerModal(ModalName.PrescriptionDetailsModal, PrescriptionModal);
  }, []);
  return (
    <View>
      {loading ? (
        <LoadingFetchingList fullWidth />
      ) : (
        prescriptions?.map((prescription, index, arr) => {
          const isLast = index === arr.length - 1;

          return (
            <ConsultationViewItem
              key={prescription.id}
              title={`Prescription ${prescription.reference_number}`}
              onView={() => {
                openModal(ModalName.PrescriptionDetailsModal, {
                  prescription,
                  doctor: consultation.doctor,
                  patient: consultation.patient,
                });
              }}
              getMediaModel={async () => {
                if (prescription.is_collectable === false) {
                  throw new Error(
                    'Prescription PDF is not available. This prescription cannot be collected from a pharmacy'
                  );
                }
                const res = await apiGetPrescriptionMedia({ id: prescription.id });
                if (res.data?.media?.length) return res.data.media[0];
                else throw new Error('We were not able to retrieve the prescription pdf at this time');
              }}
              style={isLast ? { borderBottomWidth: 0 } : null}
              status={null}
            />
          );
        })
      )}

      {!loading && !prescriptions?.length ? (
        <View style={isMobile ? pv20 : [ph10, pv20]}>
          <H6NsRegularBlack>No prescriptions were written</H6NsRegularBlack>
        </View>
      ) : null}
    </View>
  );
};
