import React from 'react';
import { View } from 'react-native';
import { Title } from 'react-native-paper';

import { PrescriptionFrequencyEnum } from '~/api/models/consultations/constants/PrescriptionFrequencyEnum';
import { pv10 } from '~/common/commonStyles';
import { H4NsRegularBlack } from '~/components/commonText';
import { PrescriptionFrequencyList } from '~/constants/PrescriptionFrequencyList';

interface Props {
  frequencyValue: PrescriptionFrequencyEnum;
}

export const SelectedFrequencyText: React.FC<Props> = ({ frequencyValue }) => {
  return frequencyValue ? (
    <View style={pv10}>
      <Title>
        <H4NsRegularBlack>Time</H4NsRegularBlack>
      </Title>

      <Title>
        <H4NsRegularBlack>
          {PrescriptionFrequencyList.find((item) => item.id === frequencyValue)?.radioButtonValue || ''}
        </H4NsRegularBlack>
      </Title>
    </View>
  ) : null;
};
