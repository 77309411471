const notSupported = async () => {
  throw new Error('Biometrics are not supported');
};

export default {
  sensorAvailable: () => Promise.resolve(false),
  sensorType: () => Promise.resolve(undefined),
  enroll: notSupported,
  signPayload: notSupported,
  isEnrolled: () => Promise.resolve(false),
  removeEnrolment: notSupported,
  showPrompt: notSupported,
};
