import React, { useContext } from 'react';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { ConsultationWaitTimeResponse } from '~/api/models/consultations/responses/ConsultationWaitTimeResponse';

export interface EndConsultationOptions {
  overrideMessage?: string[];
  overrideTitle?: string;
}
export interface IAppointmentContext {
  consultation?: ConsultationModel;
  estimatedWait?: ConsultationWaitTimeResponse;
  fullScreen?: boolean;
  setFullScreen: (fullScreen?: boolean) => void;
  updateConsultationDiagnosis: (diagnosisId: number) => Promise<void>;
  updateConsultation: (withLoading?: boolean) => Promise<void>;
  updateEstimate: () => Promise<ConsultationWaitTimeResponse>;
  startConsultation: () => Promise<void>;
  endConsultation: (options?: EndConsultationOptions) => Promise<void>;
  cancelAppointment: () => void;
  loading?: boolean;
  startNavigation: () => Promise<void>;
  pauseNavigation: () => Promise<void>;
  endNavigation: () => Promise<void>;
  isTimeForConsultation: boolean;
}

export const AppointmentContext = React.createContext<IAppointmentContext>({} as IAppointmentContext);
export const useAppointmentContext = (): IAppointmentContext => useContext(AppointmentContext);
