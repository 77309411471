import { ConsultationStateEnum } from '~/api/models/consultations/constants/ConsultationStateEnum';
import { colors } from '~/utils/colors';

export const ClinicConsultationAssignBadgeStatus = {
  [ConsultationStateEnum.RequestSubmitted]: {
    label: 'Unassigned',
    color: colors.darkWarning,
    backgroundColor: colors.lightWarning,
  },
  [ConsultationStateEnum.PendingPayment]: {
    label: 'Assigned',
    color: colors.success,
  },
  [ConsultationStateEnum.Scheduled]: { label: 'Assigned', color: colors.success },
};
