import React, { useState } from 'react';

import { mt15 } from '../../common/commonStyles';
import { SmallNsBoldLink, SmallNsRegularSecondaryBlack } from '../../components/commonText';
import { colors } from '../../utils/colors';
import { labels } from '../../utils/labels';

export const ConsultationTypeSubtitle = () => {
  const [showMoreFlag, setShowMoreFlag] = useState<boolean>(false);

  return (
    <>
      <SmallNsRegularSecondaryBlack style={mt15}>
        {labels.standardCostPerConsultation}
        {!showMoreFlag ? (
          <SmallNsBoldLink
            style={{ color: colors.info }}
            onPress={() => {
              setShowMoreFlag(!showMoreFlag);
            }}>
            {'  ' + labels.showMore}
          </SmallNsBoldLink>
        ) : null}
      </SmallNsRegularSecondaryBlack>
      {showMoreFlag ? (
        <SmallNsRegularSecondaryBlack style={mt15}>
          {labels.standardCostPerConsultationSubMsg}
          {showMoreFlag ? (
            <SmallNsBoldLink
              style={{ color: colors.info }}
              onPress={() => {
                setShowMoreFlag(!showMoreFlag);
              }}>
              {'  ' + labels.showLess}
            </SmallNsBoldLink>
          ) : null}
        </SmallNsRegularSecondaryBlack>
      ) : null}
    </>
  );
};
