import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

import { AvailableDoctorModelWithQueue } from '~/api/models/appointments/models/AvailableDoctorModel';
import { flex1, p5 } from '~/common/commonStyles';
import {
  H5TtmRegularBlack,
  H5TtmSemiBoldDarkWarning,
  H5TtmSemiBoldSuccess,
  SmallTtmRegularTheme,
} from '~/components/commonText';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { ProfileDetailsComponent } from '~/components/profile/ProfileDetailsComponent';
import { InfoCircle } from '~/components/svgImages';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { colors } from '~/utils/colors';
import { getQueueInfo } from '~/utils/consultationUtils';
import { labels } from '~/utils/labels';

interface Props {
  doctor: AvailableDoctorModelWithQueue;
  isAssigned?: boolean;
  isSelected?: boolean;
  onPress: (doctor: AvailableDoctorModelWithQueue) => void;
  style?: StyleProp<ViewStyle>;
}

export const ClinicDoctorAssignComponent: React.FC<Props> = ({ doctor, isAssigned, isSelected, style, onPress }) => {
  const queueInfo = useMemo(() => getQueueInfo(doctor.queue), [doctor?.queue]);
  const { openModal } = useModalManager();

  const showDoctorDetails = () => {
    openModal(ModalName.DoctorInfoModal, {
      doctor,
      okTitle: labels.close,
    });
  };

  const content = (
    <>
      <ProfileDetailsComponent
        account={doctor}
        style={flex1}
        theme="default"
        extraDetails={
          <View>
            {queueInfo.noQueue ? (
              <H5TtmSemiBoldSuccess>Not in call</H5TtmSemiBoldSuccess>
            ) : (
              <View style={{ display: 'flex', alignItems: 'flex-end' }}>
                <H5TtmSemiBoldDarkWarning>In a call</H5TtmSemiBoldDarkWarning>
                <H5TtmRegularBlack>{queueInfo.description}</H5TtmRegularBlack>
              </View>
            )}
          </View>
        }
      />
      <View style={{ paddingLeft: 20, minWidth: 70, height: '100%', display: 'flex', justifyContent: 'space-between' }}>
        {onPress || isAssigned || isSelected ? (
          <SmallTtmRegularTheme
            style={{
              fontWeight: isAssigned || isSelected ? '600' : undefined,
              textAlign: 'right',
              color: isAssigned ? colors.success : isSelected ? colors.warning : colors.purple,
            }}>
            {isAssigned ? 'Assigned' : isSelected ? 'Selected' : 'Assign'}
          </SmallTtmRegularTheme>
        ) : null}
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <TouchableOpacity onPress={showDoctorDetails} style={p5}>
            <InfoCircle width={14} height={14} color={colors.purple} />
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
  if (!onPress) {
    return (
      <View style={[styles.container, isAssigned || isSelected ? styles.containerAssigned : null, style]}>
        {content}
      </View>
    );
  }
  return (
    <TouchableOpacity
      style={[styles.container, isAssigned || isSelected ? styles.containerAssigned : null, style]}
      onPress={onPress ? () => onPress(doctor) : null}>
      {content}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerAssigned: {
    borderRadius: 8,
    backgroundColor: colors.lightPurple2,
  },
});
