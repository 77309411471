import { DocumentPickerAsset } from 'expo-document-picker';

import { ClinicalTermTypeEnum } from '~/api/models/clinicalTerms/constants/ClinicalTermTypeEnum';
import { MediaModel } from '~/api/models/common/models/MediaModel';
import { ClinicalTermTypeToHealthProfileHistoryTypeMapping } from '~/api/models/healthProfile/constants/HealthProfileHistoryTypeEnum';
import { apiUpdateAllergies } from '~/api/services/healthProfile/allergies';
import { apiUpdateMedicalConditions } from '~/api/services/healthProfile/medicalConditions';
import { apiUpdateMedications } from '~/api/services/healthProfile/medications';
import { apiUpdateProcedures } from '~/api/services/healthProfile/procedures';
import { apiDeleteMedia, apiUploadMedia } from '~/api/services/healthProfile/uploadMedia';
import { apiUpdateVaccinations } from '~/api/services/healthProfile/vaccinations';
import { PatientHistoryDataItem } from '~/components/doctor/patients/healthProfile/models/PatientHistoryData';
import { getChanges } from '~/utils/diffUtil';
import { isDocumentResult } from '~/utils/files/isDocumentResult';

const updateTermIds = (healthProfileId: number, type: ClinicalTermTypeEnum, ids: number[]) => {
  switch (type) {
    case ClinicalTermTypeEnum.ALLERGIES:
      return apiUpdateAllergies({ id: healthProfileId, data: { term_ids: ids } });
    case ClinicalTermTypeEnum.MEDICAL_CONDITIONS:
      return apiUpdateMedicalConditions({ id: healthProfileId, data: { term_ids: ids } });
    case ClinicalTermTypeEnum.MEDICATIONS:
      return apiUpdateMedications({ id: healthProfileId, data: { term_ids: ids } });
    case ClinicalTermTypeEnum.PROCEDURES:
      return apiUpdateProcedures({ id: healthProfileId, data: { term_ids: ids } });
    case ClinicalTermTypeEnum.VACCINATIONS:
      return apiUpdateVaccinations({ id: healthProfileId, data: { term_ids: ids } });
    default:
      return Promise.reject(new Error('Unknown history section type'));
  }
};

export async function setFileChanges(
  healthProfileId: number,
  type: ClinicalTermTypeEnum,
  savedData: PatientHistoryDataItem<MediaModel>,
  currentData: PatientHistoryDataItem
) {
  const savedTypeIds = Object.keys(savedData);
  const currentTypeIds = Object.keys(currentData);

  const { deleted, created } = getChanges(savedTypeIds, currentTypeIds);

  if (deleted?.length || created?.length) {
    await updateTermIds(
      healthProfileId,
      type,
      currentTypeIds.map((id) => +id)
    );
  }

  for (const id of currentTypeIds) {
    const change = getChanges(savedData[id]?.media || [], currentData[id]?.media || []);

    if (change.created?.length) {
      const uploadDocuments = currentData[id].media.filter((document) =>
        isDocumentResult(document)
      ) as DocumentPickerAsset[];

      if (uploadDocuments.length) {
        for (const document of uploadDocuments) {
          await apiUploadMedia({
            healthProfileId,
            data: document,
            medical_history_type: ClinicalTermTypeToHealthProfileHistoryTypeMapping[type],
            snomed_index_id: id,
          });
        }
      }
    }

    if (change.deleted?.length) {
      await apiDeleteMedia({
        id: healthProfileId,
        data: { media_ids: change.deleted.map((id) => +id) },
      });
    }
  }
}
