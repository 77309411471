import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IdTitleModel } from '~/api/models/common/models/IdTitleModel';

interface State {
  templates?: IdTitleModel[];
}

const initialState: State = {};

const healthRecordSlice = createSlice({
  name: 'healthRecord',
  initialState,
  reducers: {
    SET_TEMPLATES: (state, action: PayloadAction<IdTitleModel[]>) => ({
      ...state,
      templates: action.payload,
    }),
    LOGOUT: () => ({
      ...initialState,
    }),
  },
});

export const { SET_TEMPLATES, LOGOUT } = healthRecordSlice.actions;
export default healthRecordSlice.reducer;
