import React from 'react';
import { View } from 'react-native';

import { H6NsRegularSecondaryBlack } from '../commonText';
import { Dot } from '../svgImages';

import { colors } from '~/utils/colors';

interface Props {
  text: string[];
}

export const DotWithText: React.FC<Props> = ({ text }) => {
  return (
    <View>
      {text?.map((element, index) => {
        return (
          <View style={{ flexDirection: 'row' }} key={index}>
            <View style={{ top: 7 }}>
              <Dot width={6} height={6} color={colors.info} />
            </View>
            <H6NsRegularSecondaryBlack style={{ paddingLeft: 10 }}>{element}</H6NsRegularSecondaryBlack>
          </View>
        );
      })}
    </View>
  );
};
