import React, { useEffect, useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useSelector } from 'react-redux';

import { PrescriptionModalDispenseHistory } from './PrescriptionModalDispenseHistory';

import { PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { PrescriptionDispenseHistoryRedactedModel } from '~/api/models/pharmacy/models/PrescriptionDispenseHistoryModel';
import { apiGetPrescriptionDispenseHistory } from '~/api/services/pharmacy';
import { currentPharmacySelector } from '~/redux/reducers/pharmacy/pharmacyReducer';

interface Props {
  prescription: PrescriptionModel;
  style?: StyleProp<ViewStyle>;
}

export const PharmacyPrescriptionDispenseHistory: React.FC<Props> = ({ prescription, style }) => {
  const [loading, setLoading] = useState(true);
  const [collections, setCollections] = useState<PrescriptionDispenseHistoryRedactedModel[]>();
  const currentPharmacy = useSelector(currentPharmacySelector);

  const getDispenseHistory = async (prescriptionId: number) => {
    if (!prescriptionId) return;
    try {
      setLoading(true);
      const res = await apiGetPrescriptionDispenseHistory({
        req: { prescriptionId },
        params: { sort: '-created_at', limit: -1 },
      });
      setCollections(res.data);
    } catch {}
    setLoading(false);
  };

  useEffect(() => {
    const id = prescription?.id;

    if (id) getDispenseHistory(id);
  }, [prescription?.id]);

  return (
    <PrescriptionModalDispenseHistory
      loading={loading}
      collections={collections}
      currentPharmacy={currentPharmacy?.pharmacy}
      style={style}
    />
  );
};
