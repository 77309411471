import React from 'react';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { EditImageComponent } from '~/components/profile/EditImageComponent';
import { TitleLayout } from '~/layouts/TitleLayout';
import { SettingsClinicNavigation } from '~/navigation/authorised/SettingsClinicNavigation';

export const SettingsClinic: React.FC = () => {
  const { userDetails, getUserDetails } = useUserDetails();

  const clinicImageEdit = (
    <EditImageComponent
      onUpdate={getUserDetails}
      account={userDetails?.clinic_account}
      title={`Edit the profile image of\n${userDetails?.clinic_account?.clinic_name}`}
    />
  );
  return (
    <TitleLayout title="Clinic Settings" noContentPadding noHeaderPadding headerRight={clinicImageEdit}>
      <SettingsClinicNavigation />
    </TitleLayout>
  );
};
