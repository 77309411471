import { Route, useNavigation } from '@react-navigation/native';
import React from 'react';

import { PatientEditableHealthProfile } from '~/components/doctor/patients/healthProfile/PatientEditableHealthProfile';
import { NavType } from '~/navigation/types';
import { HealthProfileDataProvider } from '~/providers/healthProfile/HealthProfileDataProviders';
import { screenName } from '~/utils/screenName';

interface Props {
  route?: Route<string, { id: string }>;
}

export const PatientHealthProfile: React.FC<Props> = ({ route }) => {
  const patientId = +route.params.id;
  const { navigate } = useNavigation<NavType>();

  return (
    <HealthProfileDataProvider accountId={patientId}>
      <PatientEditableHealthProfile
        onStartEditing={(accountId) => {
          navigate(screenName.PatientHealthProfileEdit, { id: accountId });
        }}
      />
    </HealthProfileDataProvider>
  );
};
