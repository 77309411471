import { useState } from 'react';

import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { AddressModel } from '~/api/models/addresses/models/AddressModel';
import { apiGetAccount } from '~/api/services/accounts';
import { apiGetAddressIndex } from '~/api/services/addresses';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { promiseAllSettledHelper } from '~/utils/promiseUtil';

interface Props {
  id: number;
}
export function useAccountDetails({ id }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<AccountModel>(null);
  const [address, setAddress] = useState<AddressModel>(null);

  const getAccountDetails = () => {
    if (id) {
      setLoading(true);
      const accountPromise = apiGetAccount({ id }).then((response) => {
        setAccount(response.data);
      });
      const addressPromise = apiGetAddressIndex({ filter: { account_id: id }, limit: 1 }).then((response) => {
        if (response.data.data.length) setAddress(response.data.data[0]);
      });

      promiseAllSettledHelper([accountPromise, addressPromise])
        .catch(ErrorAlert)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getAddressDetails = () => {
    if (id) {
      setLoading(true);
      apiGetAddressIndex({ filter: { account_id: id }, limit: 1 })
        .then((response) => {
          if (response.data.data.length) setAddress(response.data.data[0]);
        })
        .catch(ErrorAlert)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return { address, account, loading, getAccountDetails, getAddressDetails };
}
