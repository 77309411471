import { Route } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';
import { useSelector } from 'react-redux';

import { alignItemsCenter, flex1, mr10, pv15 } from '../../../common/commonStyles';
import { Button } from '../../../components/commonButton';
import { H6NsRegularSecondaryBlack } from '../../../components/commonText';
import { CardSurface } from '../../../components/commonViews';
import { ArrowRight, Verified } from '../../../components/svgImages';
import { GeneralProps } from '../../../interfaces/generalProps';
import { labels } from '../../../utils/labels';
import { screenName } from '../../../utils/screenName';

import { useFinaliseRegistration } from '~/api/hooks/accounts/FinaliseRegistration';
import RegistrationLayout from '~/layouts/RegistrationLayout';
import { registrationStatusSelector } from '~/redux/reducers/userDetailsReducer';
import { arrayToObject } from '~/utils/arrayUtil';

type PreferencesListProps = {
  title: string;
  key: string;
  keys?: string[];
  screen: string;
};
interface Props extends GeneralProps {
  route: Route<string, (object & { isLogin: boolean; name: string }) | undefined>;
}

const preferencesList: PreferencesListProps[] = [
  {
    key: 'consultation_prices',
    title: 'Cost per consultation type',
    screen: screenName.CostPerConsultationType,
  },
  {
    key: 'preferred_consultation_channel',
    keys: ['preferred_consultation_channel', 'preferred_language'],
    title: 'Preferred means for online consultations',
    screen: screenName.ConsultationChannelPreferences,
  },
  {
    key: 'consultation_availabilities',
    title: 'Availabilities',
    screen: screenName.AvailabilitiesScreen,
  },
];

export const ConsultationPreferences: React.FC<Props> = ({ navigation }) => {
  const registrationSteps = useSelector(registrationStatusSelector);
  const registrationPreferencesSteps = useMemo(() => {
    return arrayToObject(registrationSteps?.find((step) => step.label === 'Preferences')?.steps, 'key');
  }, [registrationSteps]);
  const { finaliseRegistration } = useFinaliseRegistration();

  return (
    <RegistrationLayout
      title={labels.preferences}
      subtitle={labels.preferencesSubMessage}
      buttons={<Button funCallback={finaliseRegistration} label={labels.continue} />}>
      <FlatList
        style={{ width: '100%' }}
        data={preferencesList}
        keyExtractor={(item) => item.key}
        renderItem={({ item }) => {
          const keys = item.keys || [item.key];
          const isDone = keys.every((key) => !!registrationPreferencesSteps[key]?.done);
          return (
            <View style={{ paddingHorizontal: 2 }}>
              <CardSurface>
                <TouchableOpacity
                  style={[pv15]}
                  onPress={() => {
                    navigation.navigate(item.screen);
                  }}>
                  <View style={[{ flexDirection: 'row', paddingLeft: 10, paddingRight: 15 }, flex1, alignItemsCenter]}>
                    <View style={[mr10, { minWidth: 12 }]}>{isDone ? <Verified width={12} height={12} /> : null}</View>
                    <View style={{ flex: 1, alignItems: 'flex-start' }}>
                      <H6NsRegularSecondaryBlack>{item.title}</H6NsRegularSecondaryBlack>
                    </View>
                    <View
                      style={{
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        marginLeft: 5,
                      }}>
                      <ArrowRight width={14} height={14} />
                    </View>
                  </View>
                </TouchableOpacity>
              </CardSurface>
            </View>
          );
        }}
      />
    </RegistrationLayout>
  );
};
