import * as Application from 'expo-application';
import Constants from 'expo-constants';
import { Platform } from 'react-native';

import { UnknownAppTypeError } from '../classes/errors/UnknownAppTypeError';

import { RoleEnum } from '~/api/models/common/constants';

export enum AppType {
  DOCTOR = 'doctor',
  PATIENT = 'patient',
  PHARMACY = 'pharmacy',
  CLINIC = 'clinic',
}

export function getAppType() {
  switch (Constants.expoConfig.extra.appType) {
    case AppType.DOCTOR:
      return AppType.DOCTOR;
    case AppType.PATIENT:
      return AppType.PATIENT;
    case AppType.PHARMACY:
      return AppType.PHARMACY;
    case AppType.CLINIC:
      return AppType.CLINIC;
    default:
      throw new UnknownAppTypeError();
  }
}

export function isDoctorVersion() {
  return getAppType() === AppType.DOCTOR;
}

export function isPatientVersion() {
  return getAppType() === AppType.PATIENT;
}

export function isPharmacyVersion() {
  return getAppType() === AppType.PHARMACY;
}

export function isClinicVersion() {
  return getAppType() === AppType.CLINIC;
}

export function isAppType(...appType: AppType[]) {
  return appType.includes(getAppType());
}

export function whenAppType<T = any>(options: {
  whenDoctor?: T;
  whenPharmacist?: T;
  whenPatient?: T;
  whenClinic?: T;
  else?: T;
}) {
  switch (getAppType()) {
    case AppType.DOCTOR:
      return options.whenDoctor ?? options.else;
    case AppType.PATIENT:
      return options.whenPatient ?? options.else;
    case AppType.PHARMACY:
      return options.whenPharmacist ?? options.else;
    case AppType.CLINIC:
      return options.whenClinic ?? options.else;
    default:
      if (options.else) return options.else;
      throw new UnknownAppTypeError();
  }
}

export function isWeb() {
  return Platform.OS === 'web';
}

export function isNative() {
  return !isWeb();
}

export function isAndroid() {
  return Platform.OS === 'android';
}

export function getCurrentAppVersion() {
  return Constants.expoConfig.version;
}

export function getCurrentPlatform() {
  return Platform.OS;
}

export function appUserRole() {
  switch (getAppType()) {
    case AppType.DOCTOR:
      return RoleEnum.DOCTOR;
    case AppType.PATIENT:
      return RoleEnum.PATIENT;
    case AppType.PHARMACY:
      return RoleEnum.PHARMACY;
    case AppType.CLINIC:
      return RoleEnum.CLINIC;
    default:
      throw new UnknownAppTypeError();
  }
}

export function appTypeApiPrefix() {
  return whenAppType({
    whenClinic: 'clinic',
    whenDoctor: 'doctor',
    whenPatient: 'patient',
    whenPharmacist: 'pharmacy',
  });
}

export const appName =
  Application.applicationName ||
  whenAppType({
    whenClinic: 'Clinic Digimed',
    whenDoctor: 'Doctor Digimed',
    whenPharmacist: 'Pharmacy Digimed',
    whenPatient: 'Digimed',
  });
