export const onboardingLabels = {
  doctor: [
    {
      title: 'Full Access to Patient Health Records, Wherever You Are',
      subTitle:
        'Manage all of your patients’ health records through the Patient Management System, improving your workflow efficiency while saving time to see more patients.',
    },
    {
      title: 'Deliver better patient care',
      subTitle:
        'Through Digimed, all EHRs will be shared with your patients. If you are a favourite Doctor, you will also have access to all EHRS under your patients (including those completed by other doctors).',
    },
    {
      title: 'Expand your network',
      subTitle:
        'Grow your medical practice with Digimed’s extensive patient network, allowing patients to book an online or in-person medical consultation with you straight through the app.',
    },
    {
      title: 'Increase Revenue, Not Paperwork',
      subTitle:
        'Support your traditional care delivery by adding a new revenue stream. With Digimed, get paid in real time and expand your patient base effortlessly.',
    },
  ],
  patient: [
    {
      title: 'Your Health - Your Way',
      subTitle:
        'All in one, 24/7 access to medical care. Get access to the best medical professionals, get dedicated care advice and book your consultations online or in-person - anywhere, anytime.',
    },
    {
      title: 'Your medical history in your pocket, finally!',
      subTitle:
        'Your Health Wallet will grant you instant, simple access to your full medical history. Your personal health records are safe and secure, fully encrypted and for your eyes only.',
    },
    {
      title: 'Care for Yourself & Your Family',
      subTitle:
        'Stay on top of your family’s healthcare by scheduling appointments, managing prescriptions and payments all from your own device.',
    },
    {
      title: 'Your nurse in your pocket',
      subTitle:
        'Digimed will never leave your side. From alerting you to take your medicine, to sending out reminders to never miss any booked appointments, you can always count on your personal health buddy.',
    },
  ],
  pharmacy: [
    {
      title: 'Digital prescriptions on Demand',
      subTitle:
        'No more deciphering other people’s notes! When doctors’ prescribe medication, e-prescriptions will be submitted electronically to the desired pharmacy chosen by the patient.',
    },
    {
      title: 'Improved customer service',
      subTitle: 'Notify customers when their prescriptions are ready to be collected, saving your time and theirs!',
    },
    {
      title: 'Receive a free pharmacy patient management system',
      subTitle: 'View and manage your prescription dispense history in one place!',
    },
    {
      title: 'Capture the market',
      subTitle:
        'Obtain new clients and hold on to loyal ones when customers select your pharmacy to collect their medicine.',
    },
  ],
  welcome: {
    title: 'Welcome to Digimed',
    subTitle: 'Get started by creating an account.',
  },
};
