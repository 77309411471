import { AxiosRequestConfig } from 'axios';

import { axiosClient } from './client';

export async function post<T = object, R = object>(url: string, data?: R) {
  return axiosClient.post<T>(url, data);
}

export async function put<T = object, R = object>(url: string, data?: R) {
  return axiosClient.put<T>(url, data);
}

export async function putFormData<T = object>(url: string, data: FormData) {
  const headersConfig: Record<string, string> = {
    'Content-Type': 'multipart/form-data',
  };
  return axiosClient.put<T>(url, data, { headers: headersConfig });
}

export async function deleteReq<T = object, R = object>(url: string, params?: R) {
  return axiosClient.delete<T>(url, {
    params,
  });
}

export async function get<T = object>(url: string, params?: AxiosRequestConfig['params']) {
  return axiosClient.get<T>(url, {
    params,
  });
}

export async function postFormData<T = object>(url: string, data: FormData) {
  const headersConfig: Record<string, string> = {
    'Content-Type': 'multipart/form-data',
  };
  return axiosClient.post<T>(url, data, { headers: headersConfig });
}
