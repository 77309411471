import React from 'react';

import { UserEditImageComponent } from '~/components/profile/UserEditImageComponent';
import { TitleLayout } from '~/layouts/TitleLayout';
import { ProfileNavigation } from '~/navigation/authorised/ProfileNavigation';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';

export const ProfileWebPage: React.FC = () => {
  const { isMobile } = useBreakpoints();

  return (
    <TitleLayout
      title={labels.profile}
      noContentPadding={!isMobile}
      alignHeader="flex-start"
      noHeaderPadding={!isMobile}
      headerRight={<UserEditImageComponent />}>
      <ProfileNavigation />
    </TitleLayout>
  );
};
