import { MD2LightTheme, MD2Theme } from 'react-native-paper';

import { colors } from '../../utils/colors';

import { fontFamily } from '~/utils/fontFamily';

export const DefaultPaperTheme: MD2Theme = {
  ...MD2LightTheme,
  roundness: 6,
  fonts: {
    regular: {
      fontFamily: fontFamily.fontRegular,
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: fontFamily.fontRegular,
      fontWeight: '600',
    },
    light: {
      fontFamily: fontFamily.fontRegular,
      fontWeight: '300',
    },
    thin: {
      fontFamily: fontFamily.fontRegular,
      fontWeight: '200',
    },
  },
  colors: {
    ...MD2LightTheme.colors,
    primary: colors.purple,
    background: colors.lightPurple,
    accent: colors.grey,
    error: colors.danger,
    text: colors.black, // Overriding the hover color
  },
};
