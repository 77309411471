import React from 'react';

import { CalendarPageLayout } from './CalendarPageLayout';

import { CalendarNavigation } from '~/navigation/authorised/CalendarNavigation';

export const CalendarPageMobile = (props) => {
  return (
    <CalendarPageLayout>
      <CalendarNavigation />
    </CalendarPageLayout>
  );
};
