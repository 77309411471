import React from 'react';

import { mb15, mt10 } from '~/common/commonStyles';
import { H6NsRegularBlack } from '~/components/commonText';
import { Container } from '~/theme/components/grid';
import { appName } from '~/utils/buildConfig';

interface Props {
  isGuest?: boolean;
}

export const ClinicExportAccountDataComponent: React.FC<Props> = ({ isGuest }) => {
  return (
    <Container alignSelf="flex-start" style={mb15}>
      <H6NsRegularBlack>
        By permanently deleting your account you will no longer have access to {appName}.
      </H6NsRegularBlack>
      {isGuest ? (
        <H6NsRegularBlack style={mt10}>
          Should you wish to proceed with the deletion, login and request your account is deleted.
        </H6NsRegularBlack>
      ) : (
        <H6NsRegularBlack style={mt10}>
          Should you wish to proceed with the deletion, press the Continue button below.
        </H6NsRegularBlack>
      )}
    </Container>
  );
};
