import React from 'react';
import { View, FlatList, StyleSheet } from 'react-native';

import { ModalContainer } from './ModalContainer';
import { BaseModalProps } from './interfaces/ModalProps';
import { Button } from '../commonButton';
import { H3TtmSemiBoldBlack, H6NsRegularSecondaryBlack } from '../commonText';
import { Calendar, Stethoscope, Home } from '../svgImages';

import { mb20, textCenter } from '~/common/commonStyles';
import { isDoctorVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { consultationsLabels } from '~/utils/labels/consultations';

const data = [
  {
    icon: <Stethoscope height={20} width={20} color={colors.grey} />,
    title: consultationsLabels.doctorAvailableNow,
    description: consultationsLabels.doctorAvailableNowDescription[isDoctorVersion() ? 'doctor' : 'patient'],
  },
  {
    icon: <Calendar height={20} width={20} color={colors.grey} />,
    title: consultationsLabels.scheduleForLater,
    description: consultationsLabels.scheduleForLaterDescription[isDoctorVersion() ? 'doctor' : 'patient'],
  },
  {
    icon: <Home height={20} width={20} color={colors.grey} />,
    title: consultationsLabels.homeVisit,
    description: consultationsLabels.homeVisitDescription[isDoctorVersion() ? 'doctor' : 'patient'],
  },
];

export const ConsultationTypeLearnMoreModal: React.FC<BaseModalProps> = ({ onHide }) => {
  const buttons = [<Button label={labels.close} funCallback={onHide} />];
  return (
    <ModalContainer hideOnBackground onHide={onHide} buttons={buttons} showInfoLine>
      <View>
        <H3TtmSemiBoldBlack style={[textCenter, mb20]}>{labels.consultationTypes}</H3TtmSemiBoldBlack>
        <FlatList
          style={{ marginTop: 10 }}
          data={data}
          renderItem={({ item, index }) => {
            return (
              <View style={[index < data.length - 1 ? styles.border : null]}>
                <View style={styles.title}>
                  {item.icon}
                  <H6NsRegularSecondaryBlack style={{ marginLeft: 10 }}>{item.title}</H6NsRegularSecondaryBlack>
                </View>
                <H6NsRegularSecondaryBlack>{item.description}</H6NsRegularSecondaryBlack>
              </View>
            );
          }}
        />
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  border: {
    paddingBottom: 20,
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightPurple2,
  },
});
