import React from 'react';
import { StyleSheet, View } from 'react-native';

import { DoctorFavouriteButton } from './DoctorFavouriteButton';

import { useUserDetails } from '~/api/hooks/accounts/UserDetails';
import { useDependantsHook } from '~/api/hooks/dependants/DependantsHook';
import { AccountModel } from '~/api/models/accounts/models/AccountModel';
import { DependantStateEnum } from '~/api/models/dependants/constants/DependantStateEnum';
import { FavouriteDoctorModel } from '~/api/models/favouriteDoctors/models/FavouriteDoctorModel';
import { mb20, ml10, mt20 } from '~/common/commonStyles';
import { Button } from '~/components/commonButton';
import { H6NsSemiBoldBlack, H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { ModalContainer } from '~/components/modals/ModalContainer';
import { BaseModalProps } from '~/components/modals/interfaces/ModalProps';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { colors } from '~/utils/colors';
import { labels } from '~/utils/labels';
import { getAccountName, getInitials } from '~/utils/personalDetailsUtils';

interface Props extends BaseModalProps {
  doctor: FavouriteDoctorModel;
}

export const DoctorToggleFavouriteModal: React.FC<Props> = ({ doctor, onHide }) => {
  const { userDetails } = useUserDetails({ allowStale: true });
  const { dependants } = useDependantsHook({ state: DependantStateEnum.Approved, allowStale: true });

  const favouriteButton = (account: AccountModel) => (
    <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }} key={account.id}>
      <DoctorFavouriteButton doctor={doctor.doctor_profile} patientId={account.id} />
      <View style={[ml10, { display: 'flex', justifyContent: 'center' }]}>
        <H6NsSemiBoldBlack>{getAccountName(account)}</H6NsSemiBoldBlack>
      </View>
    </View>
  );

  return (
    <ModalContainer
      hideOnBackground
      onHide={onHide}
      showInfoLine
      buttons={[<Button label={labels.continue} funCallback={onHide} />]}
      padding="md">
      <View>
        <View style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
          <H3TtmSemiBoldBlack>{labels.favouriteDoctor}</H3TtmSemiBoldBlack>
        </View>
        <View style={[styles.profileContainer, mb20]}>
          <ProfileImageComponent
            size={90}
            src={doctor.doctor_profile?.profile_image?.conversions?.avatar ?? doctor.doctor_profile.profile_image?.url}
            initials={getInitials(doctor.doctor_profile)}
          />
          <View style={{ flex: 1 }}>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                display: 'flex',
                marginLeft: 10,
              }}>
              <View style={{ flex: 1 }}>
                <H6NsSemiBoldBlack style={{ whiteSpace: 'pre-wrap', marginBottom: 5 }}>
                  {getAccountName(doctor.doctor_profile)}
                </H6NsSemiBoldBlack>
              </View>
            </View>
          </View>
        </View>

        <View>
          <H6NsRegularBlack>{labels.favouriteDoctorDescription}</H6NsRegularBlack>

          <View style={mt20}>
            <>{favouriteButton(userDetails?.account)}</>
            <>{dependants.map((dependant) => favouriteButton(dependant.account))}</>
          </View>
        </View>
      </View>
    </ModalContainer>
  );
};

const styles = StyleSheet.create({
  profileContainer: {
    borderRadius: 6,
    borderWidth: 1,
    backgroundColor: colors.lightPurple,
    borderColor: colors.info,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
  },
});
