import isNullOrUndefined from './types/isNullOrUndefined';

export const trimNullOrUndefined = (value: object) => {
  if (!value) return value;
  const newVal = { ...value };
  Object.keys(newVal).forEach((key) => {
    if (isNullOrUndefined(newVal[key])) delete newVal[key];
  });

  return newVal;
};
