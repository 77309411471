import React, { useMemo } from 'react';
import { TouchableOpacity, View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { Menu } from 'react-native-paper';
import { useSelector } from 'react-redux';

import { PharmacyMemberModel } from '~/api/models/pharmacy/models/PharmacyMemberModel';
import { ml10 } from '~/common/commonStyles';
import {
  H6NsRegularBlack,
  H6NsRegularDisableColor,
  H6NsRegularTheme,
  H6NsSemiBoldTheme,
} from '~/components/commonText';
import { ProfileImageComponent } from '~/components/profile/ProfileImageComponent';
import { DownArrow, UpArrow } from '~/components/svgImages';
import {
  SET_CURRENT_PHARMACY,
  currentPharmacySelector,
  pharmaciesSelector,
} from '~/redux/reducers/pharmacy/pharmacyReducer';
import { useAppDispatch } from '~/redux/store';
import { colors } from '~/utils/colors';

interface Props {
  large?: boolean;
  style?: StyleProp<ViewStyle>;
}
export const PharmacySelection: React.FC<Props> = ({ large, style }) => {
  const currentPharmacy = useSelector(currentPharmacySelector);
  const pharmacies = useSelector(pharmaciesSelector);
  const dispatch = useAppDispatch();

  const [visible, setVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  const unselectedPharmacy = useMemo(
    () => pharmacies?.filter((pharmacy) => pharmacy.pharmacy.id !== currentPharmacy?.pharmacy?.id) || [],
    [currentPharmacy?.pharmacy?.id, pharmacies]
  );

  const setSelected = (pharmacy: PharmacyMemberModel) => {
    dispatch(SET_CURRENT_PHARMACY(pharmacy));
    closeMenu();
  };

  const pharmaButton = (pharmacy: PharmacyMemberModel) => {
    return (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View>
          <ProfileImageComponent
            src={pharmacy.pharmacy.logo?.url}
            size={20}
            style={{ borderWidth: 1, borderColor: colors.purple }}
          />
        </View>
        <H6NsRegularTheme style={ml10}>{pharmacy.pharmacy.name}</H6NsRegularTheme>
      </View>
    );
  };

  if (!currentPharmacy) return null;
  return (
    <View style={style}>
      <TouchableOpacity
        onPress={openMenu}
        disabled={!unselectedPharmacy?.length}
        style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        <View style={[styles.profile]}>
          <ProfileImageComponent src={currentPharmacy?.pharmacy.logo?.url} size={large ? 50 : 20} shadow />

          <View style={styles.nameContainer}>
            <View style={[{ maxWidth: '100%', paddingRight: 10 }]}>
              <H6NsRegularBlack
                numberOfLines={2}
                style={[
                  styles.name,
                  large ? { color: colors.black, fontSize: 18 } : { color: colors.purple, fontSize: 12 },
                ]}>
                {currentPharmacy?.pharmacy.name}
              </H6NsRegularBlack>
              {large ? <H6NsSemiBoldTheme>{currentPharmacy?.pharmacy.locality.name}</H6NsSemiBoldTheme> : null}
            </View>
          </View>
        </View>
        {unselectedPharmacy?.length ? (
          <View style={styles.arrow}>
            <Menu
              visible={visible}
              onDismiss={closeMenu}
              anchor={
                large ? (
                  <DownArrow height={15} width={15} color={colors.purple} />
                ) : (
                  <UpArrow height={15} width={15} color={colors.purple} />
                )
              }>
              {unselectedPharmacy?.length ? (
                <>
                  <Menu.Item title={<H6NsRegularDisableColor>Choose pharmacy</H6NsRegularDisableColor>} disabled />
                  {unselectedPharmacy.map((pharmacy) => (
                    <Menu.Item
                      key={pharmacy.pharmacy.id}
                      title={pharmaButton(pharmacy)}
                      onPress={() => setSelected(pharmacy)}
                    />
                  ))}
                </>
              ) : null}
            </Menu>
          </View>
        ) : null}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  profile: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginLeft: 18,
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 15,
    flex: 1,
  },
  name: {
    fontWeight: 'bold',
  },
  arrow: {
    height: 15,
    width: 15,
  },
  text: {
    color: 'red',
  },
});
