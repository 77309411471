import React from 'react';
import { useFormState } from 'react-hook-form';

import { PrivateNoteEdit } from './PrivateNoteEdit';
import { mb10 } from '../../../../../../common/commonStyles';
import { Accordion } from '../../../../../buttons/Accordions/Accordion';
import { NoteIcon } from '../../../../../svgImages';

import { usePrivateNoteDataContext } from '~/providers/consultation/PrivateNoteDataContext';

interface Props {
  editable?: boolean;
}

export const PrivateNote: React.FC<Props> = ({ editable }) => {
  const { control, loadData } = usePrivateNoteDataContext();

  const { isSubmitted, isValid } = useFormState({ control });

  return (
    <Accordion
      title="Private Notes"
      left={<NoteIcon width={16} height={16} />}
      style={mb10}
      error={editable && isSubmitted && !isValid}
      onExpandedChanged={(expanded) => {
        if (expanded) loadData();
      }}>
      <PrivateNoteEdit editable={editable} />
    </Accordion>
  );
};
