import React from 'react';

import { OnBoardingContentPlaceholder } from '../onBoardingContentPlaceholder';

import { onboardingLabels } from '~/utils/labels/onboardingLabels';

interface Props {
  width: number;
}
export const OnBoardingDoctorPage4: React.FC<Props> = ({ width }) => {
  return (
    <OnBoardingContentPlaceholder
      width={width}
      left
      maskDown
      title={onboardingLabels.doctor[3].title}
      subtitle={onboardingLabels.doctor[3].subTitle}
      image={require('~/../assets/img/onboarding/money.png')}
    />
  );
};
