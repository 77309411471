import React from 'react';

import { TitleLayout } from '../../../layouts/TitleLayout';
import { SettingsNavigation } from '../../../navigation/authorised/SettingsNavigation';
import { labels } from '../../../utils/labels';

export const SettingsWebPage: React.FC = () => {
  return (
    <TitleLayout title={labels.settings} noContentPadding noHeaderPadding>
      <SettingsNavigation />
    </TitleLayout>
  );
};
