import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AvailableDoctorModel } from '~/api/models/appointments/models/AvailableDoctorModel';
import { doctorsListing } from '~/redux/reducers/patient/doctorListingReducer';

export const useStoredDoctorIds = () => {
  const doctors = useSelector(doctorsListing);
  const allDoctorIds = useMemo(() => {
    const doctorIds: number[] = [];

    const extractIds = (doctor: AvailableDoctorModel) => {
      if (!doctorIds.includes(doctor.id)) {
        doctorIds.push(doctor.id);
      }
    };

    doctors?.home_visit?.data?.forEach(extractIds);
    doctors?.on_demand?.data?.forEach(extractIds);
    doctors?.scheduled_appointment?.data?.forEach(extractIds);
    return doctorIds;
  }, [doctors?.home_visit?.data, doctors?.on_demand?.data, doctors?.scheduled_appointment?.data]);

  return { allDoctorIds, doctors };
};
