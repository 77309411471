import React, { useState } from 'react';
import { Menu } from 'react-native-paper';
import { IconButton } from '~/components/buttons/IconButton';
import { InfoCircle, MoreIcon } from '~/components/svgImages';
import Feather from '@expo/vector-icons/Feather';
import { colors } from '~/utils/colors';
import { ExtraSmallNsRegularPlaceholder, SmallNsSemiBoldBlack } from '~/components/commonText';
import { useAppointmentContext } from '~/providers/appointment/AppointmentContext';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { NavType } from '~/navigation/types';

interface Props {
  canEnd: boolean;
}

export const DoctorChatMenu: React.FC<Props> = ({ canEnd }) => {
  const [visible, setVisible] = useState(false);
  const { endConsultation } = useAppointmentContext();
  const { navigate } = useNavigation<NavType>();

  const hideMenu = () => {
    setVisible(false);
  };

  const showMenu = () => {
    setVisible(true);
  };

  const handleEndConsultation = () => {
    endConsultation();
    hideMenu();
  };

  const showMoreDetails = () => {
    navigate('details');
    hideMenu();
  };

  return (
    <Menu
      visible={visible}
      onDismiss={hideMenu}
      anchor={
        <IconButton onPress={showMenu}>
          <MoreIcon height={20} width={20} />
        </IconButton>
      }>
      <Menu.Item
        disabled={!canEnd}
        title={
          <View style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
            <Feather name="x-octagon" size={22} color={colors.danger} />
            <SmallNsSemiBoldBlack style={{ color: colors.danger }}>End Consultation</SmallNsSemiBoldBlack>
          </View>
        }
        onPress={handleEndConsultation}></Menu.Item>
      <Menu.Item
        title={
          <View style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
            <InfoCircle width={22} height={22} color={colors.purple} />
            <View>
              <SmallNsSemiBoldBlack>More details</SmallNsSemiBoldBlack>
              <ExtraSmallNsRegularPlaceholder>You can also swipe left for more details</ExtraSmallNsRegularPlaceholder>
            </View>
          </View>
        }
        onPress={showMoreDetails}></Menu.Item>
    </Menu>
  );
};
