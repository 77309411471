import React from 'react';

import { OnBoardingContentPlaceholder } from './onBoardingContentPlaceholder';

import { onboardingLabels } from '~/utils/labels/onboardingLabels';

interface Props {
  width: number;
}
export const OnBoardingWelcome: React.FC<Props> = ({ width }) => {
  return (
    <OnBoardingContentPlaceholder
      width={width}
      centre
      maskUp
      title={onboardingLabels.welcome.title}
      subtitle={onboardingLabels.welcome.subTitle}
      image={require('~/../assets/img/onboarding/logo_3d.png')}
    />
  );
};
