import { useState } from 'react';

import { PrescriptionFormModel, PrescriptionModel } from '~/api/models/consultations/models/PrescriptionModel';
import { PrescriptionRequest } from '~/api/models/consultations/requests/PrescriptionRequest';
import { PrescriptionsIndexRequest } from '~/api/models/consultations/requests/PrescriptionsIndexRequest';
import {
  apiAddPrescription,
  apiDeletePrescription,
  apiGetPrescriptionIndex,
  apiUpdatePrescription,
} from '~/api/services/consultations/prescriptions';
import removeEmpty from '~/utils/processing/removeEmpty';

interface Props {
  consultationId: number;
  sort?: PrescriptionsIndexRequest['sort'];
}

export function usePrescriptions({ consultationId, sort }: Props) {
  const [prescriptions, setPrescriptions] = useState<PrescriptionModel[]>([]);
  const [loading, setLoading] = useState(false);

  const getPrescriptions = () => {
    setLoading(true);
    return apiGetPrescriptionIndex({ filter: { consultation_id: consultationId }, limit: 100, sort })
      .then((response) => {
        setPrescriptions(response.data.data);
      })
      .catch(() => {
        setPrescriptions(null);
      })
      .finally(() => setLoading(false));
  };

  const updatePrescription = async (details?: PrescriptionFormModel, id?: number) => {
    if (id && !details?.product) {
      await deletePrescription(id);
      return undefined;
    } else if (details) {
      const data = removeEmpty<PrescriptionRequest>({
        product_id: typeof details?.product?.value === 'number' ? details.product.value : undefined,
        product_name: typeof details?.product?.value === 'string' ? details.product.value : undefined,
        consultation_id: details?.consultation_id,
        frequency: details?.frequency,
        dose: details?.dose,
        duration_interval_amount: details?.duration_interval_amount,
        duration_interval_type: details?.duration_interval_type,
        other_comments: details?.other_comments,
        route: details?.route,
        type: details?.type,
        is_collectable: details?.is_collectable ?? true,
      });

      if (id) {
        return await apiUpdatePrescription({
          id,
          params: data,
        }).then((res) => res.data.id);
      } else if (Object.keys(data).length && (data.product_id || data.product_name)) {
        return await apiAddPrescription(data).then((res) => res.data.id);
      } else {
        return false;
      }
    }
    return false;
  };

  const deletePrescription = (id: number) => {
    if (id) {
      return apiDeletePrescription({ id }).then(() => setPrescriptions(null));
    } else {
      return Promise.reject(new Error('No current prescription to delete'));
    }
  };

  return { loading, prescriptions, updatePrescription, getPrescriptions };
}
